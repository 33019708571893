import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/styles'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import {useHttp} from 'api/core'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import EllipsisText from 'react-ellipsis-text'
import {palette} from 'theme'
import ScrollableContainer from 'components/core/ScrollableContainer'

const styles = theme => ({
  linkToRoom: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 6,
  },
  container: {
    position: 'absolute',
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.4)',
    minHeight: '120px',
    zIndex: 9,
    width: '100%'
  },
  roomTitle: {
    fontWeight: 'bold',
    padding: theme.spacing(0,1)
  },
  title: {
    padding: theme.spacing(2,1,1,4),
    fontWeight: 'bold',
    color: 'white'
  },
  roomsBox: {
    // paddingTop: theme.spacing(1),
  },
  roomBox: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '180px',
    height: '40px',
    borderRadius: '8px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    cursor: 'pointer',
    overflow:'hidden',
  },
  box: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '80px',
    zIndex: 5,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '8px',
    padding: theme.spacing(1),
    color: '#FFFFFF',
    height: '100%',
    textShadow: '1px 1px #00001111',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  roomsPrompt: {
    fontSize: 23,
    position: 'absolute',
    textAlign:'center',
    width: '100%',
    top: -92,
    fontWeight: 'bold',
    color: 'white',
    textShadow: '1px 1px #00001111',
    zIndex: 1
  }
})

const Room = withStyles(styles)(({classes, title, id}) => {
  return <Grid item container className={clsx(classes.box)}>
    <Link to={`/virtual-room/${id}`} className={classes.linkToRoom}> </Link>
    <Grid item xs={12} className={classes.roomTitle}> <EllipsisText length={20} text={title} /> </Grid>
  </Grid>
})


const RoomsBanner = withStyles(styles)(({classes, sessionId})=>{

  const [rooms, setRooms] = useState()
  const [getRoomData, roomData ] = useHttp()

  useEffect(()=>{
    // getData and set rooms
      getRoomData(api.entities.virtualRooms.getAllBySession(null,null,{id:sessionId}))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (roomData) {
      setRooms(roomData)
    }
  },[roomData])

  if (!rooms) {
    return <Grid item container spacing={1} className={classes.container} justify={'center'}>
      <Grid item>
          <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height={'100%'}>
        <Box display='flex' p={3}>
          <CircularProgress size={'4rem'} color='secondary' />
        </Box></Box>
      </Grid>
    </Grid>
  }

  if (rooms && rooms.length===0) {
    return <> </>
  }

  return  <Grid item container spacing={1} className={classes.container}>
    <Grid item container>
      <Grid item className={classes.title}>
        Please select one of the breakout rooms below and click to enter
      </Grid>
    </Grid>
    <ScrollableContainer items={rooms.map((room)=>{
        return <Grid item >
          <Box className={classes.roomBox} >
            <Room id={room.code} title={room.label} room={room} />
          </Box>
        </Grid>
      })} />
  </Grid>
})

export default RoomsBanner
