import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
// import {format} from "date-fns"
// import parseISO from "date-fns/parseISO"
import EllipsisText from 'react-ellipsis-text'
import BlockText from 'components/core/BlockText'
import FieldText from 'components/core/FieldText'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import DescriptionIcon from '@material-ui/icons/Description'
import TreeItem from '@material-ui/lab/TreeItem'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeView from '@material-ui/lab/TreeView'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import ParticipantChip from 'components/core/ParticipantChip'
import {Link} from 'react-router-dom'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    position: 'relative',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(8)
    }
  },
  SessionCard: {
    maxWidth: '960px'
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white'
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px'
  },
  sessionBox: {
    minWidth: '135px',
    height: '60px',
    backgroundColor: palette['pale-sky-blue'],
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown']
  },
  sessionTitle: {
    color: palette['cerulean-blue'],
    fontWeight: 'bold'
  },
  sessionsBox: {
    overflowX: 'scroll'
  },
  blockTitle: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    width: '100%',
    height: '100%'
  },
  fieldTitleBlock: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: palette['brown-grey']
  },
  fieldValueBlock: {
    fontSize: '14px',
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  },
  participantsList: {},
  sessionTimes: {
    fontSize: '14px'
  },
  arrowButton: {
    opacity: 0.2
  },
  enabled: {
    cursor: 'pointer',
    opacity: 0.7,
    '&:hover' : {
      opacity: 1
    }
  },
  arrowBtns: {
    position: 'absolute',
    left: theme.spacing(1.5),
    top: theme.spacing(2)
  },
  list: {
    marginTop: '0',
    '&>li' : {
      marginBottom: theme.spacing(1)
    }
  },
  participants: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    '& > *': {
      color: 'white',
      margin: theme.spacing(0.5),
      borderColor: 'white'
    }
  },
  avatar: {
    color: 'white !important',
    background: 'none',
    '&> img':{
      maxWidth: '100%'
    }
  },
  fileList: {
    margin: 0,
    color: 'white'
  },
  listItem: {
    paddingLeft: 0,
    '&>div': {
      minWidth: '30px',
      color: 'white'
    },
    '&>a':{
      color: 'white'
    },
    '&>div>a':{
      color: 'white'
    }
  },
  root: {
    '&:focus > *': {
      background: 'none !important',
    },
    paddingBottom: theme.spacing(1)
  },
  group: {
    marginLeft: 0,
    paddingLeft: theme.spacing(3)
  },
  treeItem: {
    background: 'none',
    backgroundColor: 'none',
    borderRadius: theme.spacing(1),
  },
  treeItemExpanded: {
    background: 'none',
    borderRadius: theme.spacing(1),
    '&:focus > $content': {
      backgroundColor: 'transparent',
    },
  },
  treeItemSelected: {
    borderRadius: theme.spacing(1)
  },
  treeItemIcon: {
    alignSelf: 'flex-start'
  },
  dragGrip: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(4),
    width: theme.spacing(5),
    height: theme.spacing(6)
  },
  sessionUrl: {
    position: 'absolute',
    right: '15px',
    top: '19px',
    color: palette['brown-grey'],
    '&:hover': {
      color: palette['cerulean-blue']
    }
  }
})

const SessionCard = withStyles(styles)(({classes, event, dragHandleProps, participants=[], presentations=[], session, prevSession, nextSession, day, editAction, deleteAction, swapSessions, index, ...props}) => {
  if (event&&!session) {
    session = event.session
  }


  return <Box className={classes.SessionCard} {...props} key={index}>
    <Box p={2} className={classes.container} spacing={1}>

      <Box className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>

      <Grid container spacing={1} alignItems='stretch' justify={'flex-start'} alignContent={'flex-start'}>

        <Grid item container spacing={2}>
          {session.code&&<Grid item xs={12} sm={3}>
            <BlockText title={`Code`} value={session.code?<>
              {session.code}              
            </>:''}/>
          </Grid>}

          {session.title&&<Grid item xs>
            <BlockText title={`Session title`} value={session.title?<>
              {session.title} {session.isPrivate &&
            <Box ml={1} fontWeight = 'bold'>Private</Box>
            }
              <Link to={`/session/${session.id}`}><Box position={'absolute'} top={8} right={8} color={'#d9d9d9'} fontWeight={'bold'}>  {session.id}  </Box> </Link>
            </>:''}/>
          </Grid>}

          {session.rsvp!==null&&<Grid item xs={2}>
            <BlockText title='RSVP' value={session.rsvp!==null?session.rsvp:'-'}/>
          </Grid>}

          {session.room&&<Grid item xs={2}>
            <BlockText title='Room' value={<EllipsisText text={session.room.name} length={27} />
              }/>
          </Grid>}

          {session.track&&<Grid item xs={2}>
            <BlockText title='Track' value={<><EllipsisText text={session.track.name} length={27} /> <Box ml={4} style={{backgroundColor:session.track.color,width:'20px',height:'20px',borderRadius:'4px'}}/></>}/>
          </Grid>}

          {session.recordingUrl&&<Grid item xs={3}>
            <BlockText title={'Recording Link'} value={<a href={session.recordingUrl}> <EllipsisText text={session.recordingUrl} length={27} /> </a>} />
          </Grid>}

        </Grid>

        {session.description&&<Grid item xs={12}>
          <FieldText title='Description' value={session.description?<EllipsisText text={session.description} length={160}/>:'-'}/>
        </Grid>}

        {session.participants&&session.participants.length!==0&&<Grid item xs={12} sm={6}>
          <FieldText title='Speakers' value={<Box className={classes.participants}>
            {session.participants.map((participant,i) => {
              const participantObj = participants.find((s)=>s.id===participant.id)
              return <>{participantObj&&<ParticipantChip participantObj={participantObj} />}</>
            })}
          </Box>}/>
        </Grid>}

        {session.files&&session.files.length!==0&&<Grid item xs={12} sm={6}>
          <FieldText title='Files' value={<Box className={classes.participants}>
            {session.files.map((file,i) => {
              return <>
                <Chip
                  avatar={<Avatar className={classes.avatar}> <DescriptionIcon/> </Avatar>}
                  variant="outlined"
                  label={`${file.filename}`}
                  component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                />
              </>
            })}
          </Box>}/>
        </Grid>}

        {session.presentations&&session.presentations.length!==0&&<Grid item xs={12}>
          <FieldText title='Oral/Poster Presentations' value={<>
            {/*<ul className={classes.list}>*/}
              <TreeView
                className={classes.root}
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
              >
              {session.presentations.map((presentation,i) => {
                const presentationObj = presentations.find((p)=>p.id===presentation.id)
                const presenterObj = participants.find((s)=>s.id===presentationObj.presenterId)

                if (!presentationObj||!presenterObj) return <></>
                return <>
                  <TreeItem nodeId={`${i}`} label={`${presentationObj.title}`} classes={{ group: classes.group, iconContainer:classes.treeItemIcon, root:classes.root, content: classes.treeItem, selected: classes.treeItemSelected, expanded: classes.treeItemExpanded}}><>
                    <Box p={1} pl={1}>
                      {presentationObj.abstract}
                    </Box>
                    <Box pl={1} className={classes.participants}>
                    {presenterObj&&<ParticipantChip participantObj={presenterObj}  presenter={true} />}
                  {presentationObj.participants.filter((a)=>a!==presentationObj.presenterId).map((author,i) => {
                    const participantObj = participants.find((s)=>s.id===author)
                    return <><ParticipantChip participantObj={participantObj} /></>
                  })}
                  </Box>
                  {presentationObj.files&&presentationObj.files.length!==0&&<Box className={classes.participants} pl={1}>
                      {presentationObj.files&&presentationObj.files.map((file,i) => {
                        return <>
                          <Chip
                            avatar={<Avatar className={classes.avatar}> <DescriptionIcon/> </Avatar>}
                            variant="outlined"
                            label={`${file.filename}`}
                            component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                          />
                        </>
                      })}
                    </Box>}</>
                  </TreeItem></>

              })}
            {/*</ul>*/}
              </TreeView>
          </>} />
        </Grid>}

        <IconNotesBorderLight className={classes.editIcon} title='Edit session' onClick={() => {
          editAction && editAction(session.id)
        }}/>
        <IconXBorderLight className={classes.deleteIcon} title='Delete session' onClick={() => {
          deleteAction && deleteAction(session.id)
        }}/>
      </Grid>
    </Box>
  </Box>
})

export default SessionCard
