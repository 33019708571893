import React, {useCallback, useEffect, useRef, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {withStyles} from '@material-ui/core/styles'
import {Field, Form, Formik} from 'formik'
import Button from 'components/core/Button'
import {useAuth} from 'auth/AuthController'
import {palette, theme} from 'theme'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import {FormHelperText, Typography} from '@material-ui/core'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {useHttp} from 'api/core'
import api from 'api/index'
import {useSnackbar} from 'notistack'
import FormControl from '@material-ui/core/FormControl'
import {TITLES} from 'config'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {COUNTRIES} from 'assets/countries'
import Dialog from '@material-ui/core/Dialog'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = () => ({
  title: {
    marginBottom: '5px',
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',

  },
  container: {
    position: 'relative',
  },
  waitingLoader: {
    userSelect: 'none'
  },
  subtleLink: {
    display: 'inline-block',
    fontSize: '14px',
    textDecoration: 'underline',
    color: palette['brown-grey'],
    textAlign: 'right'
  },
  loginForm: {
    margin: '0 auto',
    maxWidth: '450px',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    }
  },
})

// Form Schema Validation
const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('This field is required'),
  password: Yup.string().required('This field is required')
})

const LoginSignupDialog = withStyles(styles)(({classes, onClose, open, creds, token=false}) => {

  const [isAuthenticated, login] = useAuth()
  const [fetchData, data, isLoadingData] = useHttp()
  const [fetchActivationData, activationData, isLoadingActivationData] = useHttp()
  const { enqueueSnackbar } = useSnackbar()
  const [credentials, setCredentials] = useState(creds)
  const [isLogin, setIsLogin] = useState(!creds)
  const [fetchLoginData, loginData, isSubmitting, hasError] = useHttp()
  const [getActivationStatus, activateData, isActivating] = useHttp()

  const [activationStatusToken, setActivationStatusToken] = useState(token)
  const poller = useRef()

  // Start poller
  const startPoller = (token) => {
    clearInterval(poller.current)
    poller.current = setInterval(() => {
      getActivationStatus(api.entities.account.activationStatus(null, {token} ))
    }, 4000)
  }

  useEffect(()=>{
    if (activateData) {
      if (activateData.activated === true) {
        clearInterval(poller.current)
        fetchLoginData(api.entities.account.login(credentials))
      }
    }
    //eslint-disable-next-line
  },[activateData])

  useEffect(()=>{
    if (token) {
      startPoller(token)
    }
    return ()=>{
      clearInterval(poller.current)
    }
    //eslint-disable-next-line
  },[])

  const Schema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    title: Yup.string().required('This field is required'),
    countryId: Yup.string().required('This field is required'),
    email: Yup.string().email().required('This field is required'),
    password: Yup.string().min(8,'Password cannot be less than 8 characters').required('This field is required'),
    confirmpassword: Yup.string().required('This field is required').when('password', {
      is: val => (!!(val && val.length > 0)),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Password doesn\'t match'
      )
    })
  });

  // If login data received, handle in Auth Controller
  useEffect(()=>{
    if (!isAuthenticated&&loginData) {
      login(loginData)
      onClose()
    }
    //eslint-disable-next-line
  },[isAuthenticated, loginData, login])

  useEffect(()=>{
    if (data) {
      if (data&&data.status===204&&data.sendEmailFailed) {
        enqueueSnackbar('It seems were unable to send the activation e-mail. Please try again.', {variant:'error', action:resendActivation})
      } else {
        enqueueSnackbar('Almost done...\n' +
          `We've sent you an email. Open it up to activate your account.`, {variant: 'success'})
        setActivationStatusToken(data.activationStatusToken)
        startPoller(data.activationStatusToken)
      }
    }
    //eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (activationData) {
      if (activationData.sendEmailFailed) {
        enqueueSnackbar('It seems were unable to send the activation e-mail. Please try again.', {variant:'error', action:resendActivation})
      } else {
        enqueueSnackbar(`We've sent you an email. You'll have to open it up to activate your account.`, {variant: 'success'})
      }
    }
    // eslint-disable-next-line
  },[activationData])

  useEffect(()=>{
    if (hasError) {
      if (hasError.message) {
        if (hasError.message==='User is disabled') {
          enqueueSnackbar('It seems like you haven\'t activated your account just yet.', {variant:'error', action:resendActivation})
        }
      }
    }
    // eslint-disable-next-line
  },[hasError])

  const requestActivation = (email) => {
    fetchActivationData(api.entities.account.resendConfirmation({email}))
  }
  // eslint-disable-next-line
  const resendActivation = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={()=>{requestActivation(credentials.email)}}> Send activation e-mail </Button>,[credentials])

  const goCreateAccount = () => {
    setIsLogin(false)
  }

  const goSignIn = () => {
    setIsLogin(true)
  }

  const isBusy = isSubmitting||isActivating||isLoadingData||isLoadingActivationData

  return <Dialog onClose={onClose} open={open} fullWidth maxWidth='sm' disableEscapeKeyDown={!!activationStatusToken} disableBackdropClick={!!activationStatusToken}>

    {isLogin&&<>
      <Grid container justify='center' alignItems='center' className={classes.container}>

        <Grid item xs={12} sm={8}>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={values => {
              setCredentials({...values})
              fetchLoginData(api.entities.account.login(values))
            }}
          >
            {() => (
              <Form>
                <Box className={classes.loginForm}>
                  <Typography component='h2' variant='h5' className={classes.title}>Sign in</Typography>
                  <Box mb={2} mt={4}>
                    <Field type='email' name='email' component={TextField} label='Email' variant='filled' fullWidth disabled={isSubmitting}/>
                  </Box>
                  <Box mb={2}>
                    <Field type='password' name='password' component={TextField} label='Password' variant='filled' fullWidth disabled={isSubmitting}/>
                    <Box mt={1} mb={4} textAlign='right'> <Link to='/forgot-password' className={classes.subtleLink}>Forgot Password</Link> </Box>
                  </Box>
                  <Box display='flex' justifyContent='center' alignContent='center'>
                    <Button type='submit' color='primary' disabled={isSubmitting}> Submit </Button>
                  </Box>
                  {/* <Box mt={4} mb={4} textAlign='center' width={'100%'}>
                    New around here? <br/>
                    <Link to='#' className={classes.subtleLink} onClick={goCreateAccount}>Create an account</Link>
                  </Box> */}
                </Box>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>

    </>}

    {!isLogin&&<> <Grid container justify='center' alignItems='center' alignContent='center' className={classes.container}>
      {activationStatusToken&&<Box className={classes.waitingLoader} display='flex' justifyContent='center' alignItems='center' alignContent={'center'} flexDirection={'column'}>
        <Box display='flex' pt={4}>
          <Typography variant='h6'>Your sign up has been successful.</Typography>
        </Box>
        <Box display='flex' p={1}>
          <Typography variant='body1'>To complete the process please check your email for a validation request. </Typography>
        </Box>
        <Box display='flex' p={4}>
          <Typography variant='h6'>Waiting for email validation...</Typography>
        </Box>
        <Box display='flex' p={4}>
          <CircularProgress disableShrink />
        </Box>
        <Box display='flex' p={2} pt={3}>
          <Typography variant='body2'>If the email doesn't appear shortly, please be sure to check your spam / junk mail folder. </Typography>
        </Box>
      </Box>}
      {!activationStatusToken&&<Grid item xs={12}>
          <Formik
            initialValues={{ firstName: '', email: '', password: '' , confirmpassword: '', lastName: '', title: '', countryId:''}}
            onSubmit={(values, { setSubmitting }) => {

              setCredentials({
                email: values.email,
                password: values.password
              })

              fetchData(api.entities.account.register({
                firstName: values.firstName,
                lastName: values.lastName,
                title: values.title,
                countryId: values.countryId,
                email: values.email,
                password: values.password
              }))

              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            }}
            validationSchema={Schema}
          >
            {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <Box className={classes.loginForm}>
                    <Typography component='h2' variant='h5' className={classes.title}>Sign up</Typography>
                    <Box className={classes.subtitle} textAlign='center' mb={4}>
                      join events, save favorites & more!
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <Field type='select' labelId='titleLabel' displayEmpty name='title' component={Select} label='Category' variant='filled' fullWidth disabled={isBusy} defaultValue=''>
                            {TITLES.map((title, i)=>{
                              return <MenuItem value={title} key={i}>{title}</MenuItem>
                            })}
                          </Field>
                          <InputLabel variant='filled' required>Title</InputLabel>
                          {errors&&errors.title&&touched.title&&<FormHelperText error variant='filled'> {errors.title} </FormHelperText>}

                        </FormControl>
                      </Grid>
                      <Grid item xs={9}>
                        <Field type='text' name='firstName' component={TextField} label='First name' variant='filled' required fullWidth disabled={isBusy}/>
                      </Grid>
                      <Grid item xs={3}>

                      </Grid>
                      <Grid item xs={9}>
                        <Field type='text' name='lastName' component={TextField} label='Last name' variant='filled' required fullWidth disabled={isBusy}/>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field type='select' labelId='countryIdLabel' displayEmpty name='countryId' component={Select} label='Category' variant='filled' fullWidth disabled={isBusy} defaultValue=''>
                            {COUNTRIES.map((country, i)=>{
                              return <MenuItem value={country.id} key={i}>{country.name}</MenuItem>
                            })}
                          </Field>
                          <InputLabel variant='filled' required>Country</InputLabel>
                          {errors&&errors.countryId&&touched.countryId&&<FormHelperText error variant='filled'> {errors.countryId} </FormHelperText>}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='email' name='email' required component={TextField} label='Email' variant='filled' fullWidth disabled={isBusy}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='password' name='password' component={TextField} label='Password' variant='filled' required fullWidth disabled={isBusy}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='password' name='confirmpassword' component={TextField} label='Confirm Password' variant='filled' required fullWidth disabled={isBusy}/>
                      </Grid>

                    </Grid>

                    <Box m={4} textAlign='center'>
                      By signing up, you agree with our <br/>
                      <Link to='/terms' className={classes.subtleLink}>terms of service</Link>&nbsp;
                      and &nbsp;
                      <Link to='/privacy' className={classes.subtleLink}>privacy policy.</Link>
                    </Box>
                      <Box display='flex' justifyContent='center' alignContent='center'>
                        <Button type='submit' color='primary' disabled={isBusy}> Create account </Button>
                      </Box>

                    <Box mt={4} mb={4} textAlign='center' width={'100%'}>
                      Already have an account? <br/>
                      <Link to='#' className={classes.subtleLink} onClick={!isBusy&&goSignIn}>Sign in</Link>
                    </Box>
                    </Box>
                  </Form>
            )}
          </Formik>
        </Grid>}
      </Grid> </>}

  </Dialog>
})

export default LoginSignupDialog
