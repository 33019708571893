import { Grid, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import api from 'api'
import Edit from '@material-ui/icons/Edit'
import { palette } from 'theme'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Link } from '@material-ui/icons'
import Button from 'components/core/Button'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { Redirect } from 'react-router-dom'
import { useHttp } from 'api/core'
import withStyles from '@material-ui/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { SERVICE_URL } from 'config'

const styles = theme => ({
    container: {
    }
})

const Emails = withStyles(styles)(({ classes }) =>
{

    // Hooks
    const [getData, data, , hasError] = useHttp()
    const resolveRef = useRef()
    const tableRef = useRef()
    const [emails, setEmails] = useState()


    useEffect(() =>
    {
        getData(api.entities.admin.getEmails(null, null, {}))
        // eslint-disable-next-line
    }, [])

    useEffect(() =>
    {
        if (data)
        {
            setEmails(data)
        }
    }, [data])

    useEffect(() =>
    {
        if (emails)
        {
            resolveRef.current({
                page: emails.pageable.pageNumber,
                totalCount: emails.totalElements,
                data: emails.content
            })
        }
    }, [emails])

    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    }



    if (hasError)
    {
        return <Redirect to={'/'} />
    }

    return <>


        <Grid item xs={12} sm={12} md={4}>
            <Typography component='h2' variant='h5' className={classes.title}>
                Emails
            </Typography>
        </Grid>

        <Grid item xs>

        </Grid>

        <Grid item>
        </Grid>

        <Grid item>
        </Grid>

        <Grid item xs={12}>
            <MaterialTable
                key={emails}
                icons={tableIcons}
                tableRef={tableRef}
       
                columns={[
                    { title: 'Date', field: 'date', cellStyle: { width: "5%" }, width: "5%", headerStyle: { width: "5%" } },
                    { title: 'Total Emails', field: 'totalEmails', cellStyle: { width: "30%" }, width: "30%", headerStyle: { width: "30%" } },                    
                ]}
                data={query =>
                    new Promise((resolve, reject) =>
                    {
                        getData(api.entities.admin.getEmails(null, { page: query.page + 1, items: query.pageSize, term: query.search },))
                        resolveRef.current = resolve
                    })}
                title=''
                
                options={{
                    pageSize: 25,
                    actionsColumnIndex: 999,
                    headerStyle: {
                        backgroundColor: palette['brown-grey'],
                        color: '#FFF',
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    rowStyle: {
                        fontSize: 12,
                        padding: 0
                    }
                }}
            />
        </Grid>
    </>
})

export default Emails
