import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState, useCallback} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import SponsorCard from 'components/core/SponsorCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import SponsorEditNew from 'components/core/SponsorEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN, creditsPriceList} from "config";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import AddCreditsDialog from "components/pages/ManageEvent/Plans/AddCreditsDialog";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

const styles = (theme) => ({
  sponsorsContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    maxWidth: "1000px",
  },
  noSponsorsYet: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "130px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
    paddingBottom: theme.spacing(2),
    color: "#444",
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },  
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-23px",
    "& Button": {
      minWidth: "170px"
    }
  },  
});

const Sponsors = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack, history, eventInfo, content, flow})=>{

  const [getData, data, isLoadingData] = useHttp()
  const [getSessionsData, sessionsRespData, isLoadingSessions] = useHttp()
  const [delRequest, delData, isLoadingDelete] = useHttp()
  const [sendData] = useHttp()
  const [openSponsorDialog, setOpenSponsorDialog] = useState(false)
  const [editingSponsor, setEditingSponsor] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingSponsor, setDeletingSponsor] = useState()
  const [sponsorsData, setSponsorsData] = useState()
  const [sessionsData, setSessionsData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [creditsPlan, setCreditsPlan] = useState();
  const [getCredits, credits] = useHttp();
  const [eventDuration, setEventDuration] = useState(0);
  const [complete, setComplete] = useState(false)
  const [getDataPublish, dataPublish] = useHttp();

  
  useEffect(() => {
    getData(api.entities.manage.getSponsors(null, null, { id: eventId }));
    getCredits(api.entities.credits.getEventCredits(null, null, { id: eventId }));
    getSessionsData(api.entities.manage.getSessions(null, null, {id: eventId}));    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  // useEffect(()=>{
  //   getSessionsData(api.entities.manage.getSessions(null,null,{id:eventId}))
  // },[getData, eventId, getSessionsData])

  useEffect(() => {
    if (sessionsData) {
      const startDate = new Date(sessionsData.eventInfo.startDate);
      const endDate = new Date(sessionsData.eventInfo.endDate);
      let timeDifference = endDate.getTime() - startDate.getTime();
      let daysDifference = timeDifference / (1000 * 3600 * 24);
      setEventDuration(Math.ceil(daysDifference));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsData]);  
  
  useEffect(() => {
    if (complete) {
      getData(api.entities.manage.getSponsors(null, null, {id: eventId}));
    }
    getCredits(api.entities.credits.getEventCredits(null, null, { id: eventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  useEffect(()=>{
    if (data) {
      setSponsorsData(data.sponsors)
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({path:'/manage-events',name:'events'})
    }
    if (sessionsRespData) {
      setSessionsData(sessionsRespData)
    }
  },[data, sessionsRespData, setEventInfo, setNavBack, setPageTitle])

  const handleClose = (shouldReload) => {
    setOpenSponsorDialog(false)
    if (shouldReload===true) {
      getData(api.entities.manage.getSponsors(null,null,{id:eventId}))
    }
  }

  const handleOpen = (sponsor) => {
    setOpenSponsorDialog(true)
    if (sponsor) {
      setEditingSponsor(sponsor)
    } else {
      setEditingSponsor(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingSponsor(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingSponsor) {
      delRequest(api.entities.sponsors.delete(null,null,{id:id}))
      setDeletingSponsor(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted sponsor', {variant: 'success'})
      getData(api.entities.manage.getSponsors(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getData])

  const onDragEnd = (r) => {
    if (!r.destination) return

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    // arrayHelpers.move(r.source.index,r.destination.index)
    let newArr = [...sponsorsData]

    array_move(newArr, r.source.index, r.destination.index)

    setSponsorsData(newArr)

    const newArrOrder = [...newArr.map((sF)=>sF.id)]

    sendData(api.entities.sponsors.reorder(newArrOrder, null, {id: eventId}))
  }

    const handleOpenAddCredits = (plan) => {
      setCreditsPlan(plan);
    };

    const handleCloseAddCredits = (success) => {
      setCreditsPlan(null);
      if (success === true) {
        enqueueSnackbar("Credits added successfully.", {variant: "success"});
      }
  };
  
  const publishEvent = () =>
  {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
  };

  const ViewEvent = useCallback(
    () => (
      <Button
        size="smaller"
        variant="outlined"
        colour="white"
        onClick={() => {
          history.push(`/event/${eventId}`);
        }}>
        {" "}
        View Event{" "}
      </Button>
    ),
    [eventId, history],
  );

  useEffect(() => {
    if (dataPublish) {
      if (dataPublish.status && dataPublish.status === 201) {
        enqueueSnackbar("Your event was successfully published!", { variant: "success", action: ViewEvent });      
        getData(api.entities.manage.getSponsors(null, null, { id: eventId }));
      } else {
        enqueueSnackbar("Unexpected Error", {variant: "error"});
      }
    }
    // eslint-disable-next-line
  }, [dataPublish]);
  
  if (!sponsorsData||!sessionsData||isLoadingData) return <> </>

  return (
    <Grid container>
      <Dialog onClose={handleClose} open={openSponsorDialog} maxWidth="md">
        <SponsorEditNew sponsor={editingSponsor} handleClose={handleClose} eventId={eventId} sessions={sessionsData.sessions} setSuccessful={setSuccessful} />
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete sponsor</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete sponsor?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteClose()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteClose(deletingSponsor);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <Fade in={true} timeout={TIME_FADE_IN}>
        <Grid container className={classes.sponsorsContainer} spacing={content ? 0 : 2}>
          <Grid container item xs={12}>
            <Grid item xs={5}>
              {content ? (
                <Typography component="h2" variant="h5" className={classes.subtitle}>
                  Sponsors list
                </Typography>
              ) : (
                <Typography component="h2" variant="h5" className={classes.title}>
                  Sponsors
                </Typography>
              )}
            </Grid>
            {data.sponsorMessage && (
              <Grid item xs={7} style={{textAlign: "right"}}>
                <Typography component="h6" variant="h6" className={classes.creditsTitle}>
                  {data.sponsorMessage}
                </Typography>{" "}
                <Box display="inline-block" ml={3} mb={1}>
                  <Button autoWidth size="smaller" onClick={() => handleOpenAddCredits("sponsors")}>
                    {" "}
                    Unlock more sponsors{" "}
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            {sponsorsData && sponsorsData.length !== 0 && (
              <>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={`${0}`}>
                    {(provided) => {
                      return (
                        <Grid item container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                          {sponsorsData.map((sponsor, index) => (
                            <Draggable key={index} draggableId={`${index}`} index={index}>
                              {(provided) => (
                                <Grid item xs={12} {...provided.draggableProps} ref={provided.innerRef} width={"100%"}>
                                  <SponsorCard
                                    dragHandleProps={provided.dragHandleProps}
                                    sponsor={sponsor}
                                    key={index}
                                    editAction={() => {
                                      handleOpen(sponsor);
                                    }}
                                    deleteAction={() => {
                                      handleDeleteOpen(sponsor.id);
                                    }}
                                    mb={content ? 0 : 2}
                                    content={content}
                                  />
                                </Grid>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
              </>
            )}

            {/*{sponsorsData.map((sponsor,i)=>{*/}
            {/*  return <SponsorCard sponsor={sponsor} key={i} editAction={()=>{handleOpen(sponsor)}} deleteAction={()=>{handleDeleteOpen(sponsor.id)}} mb={2}/>*/}
            {/*})}*/}
            {sponsorsData.length === 0 ? (
              <Grid item container className={classes.noSponsorsYet}>
                <Grid item>No sponsors yet!</Grid>
              </Grid>
            ) : (
              ""
            )}
            <Box display="flex" justifyContent="flex-end" pt={3}>
              <Button
                variant="outlined"
                icon={<IconAddBorderBlue />}
                size="small"
                color="primary"
                onClick={() => {
                  // if (eventInfo.upgraded === true)
                  handleOpen();
                }}
                disabled={isLoadingData || isLoadingDelete || isLoadingSessions}
                // upgradeTier={eventInfo.upgraded === false}
              >
                Add Sponsor
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Fade>
      {eventInfo.dirty && (                    
        <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons}  style={{position: flow ? "absolute" : null, top: flow ? "0" : null}}>
        {eventInfo && eventInfo.dirty ? (
          <Alert className={classes.topPublish} severity="warning">
            <AlertTitle>Unpublished changes</AlertTitle>
            You are viewing an event with unpublished changes.
          </Alert>
        ) : (
          <Alert className={classes.topPublish} severity="warning">
            <AlertTitle>Save changes</AlertTitle>
            Remember to save your changes before you leave this page.
          </Alert>
        )}
        &nbsp;&nbsp;&nbsp;
        <Box display="inline-block" mt={1} mb={1}>
          {eventInfo.dirty ? (
            <Button
              onClick={() => {
                publishEvent();
              }}>
              Publish
            </Button>
          ) : (
            ""
          )}
        </Box>
      </Grid>
      )}       
      {creditsPlan && (
        <AddCreditsDialog
          handleClose={handleCloseAddCredits}
          creditsPriceList={creditsPriceList}
          eventDuration={eventDuration}
          eventCredits={credits}
          open={creditsPlan}
          creditsType={creditsPlan}
          event={{...eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}
    </Grid>
  );
})

export default withRouter(Sponsors)
