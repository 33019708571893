import {NavLink, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import React from 'react'
import {palette} from 'theme'
import {useAuth} from 'auth/AuthController'
import {checkHost} from 'utils'

const styles = theme => ({
    selected: {
        color: `${palette['cerulean-blue']} !important`,
        '& div': {
            backgroundColor: palette['cerulean-blue'],
            color: 'white'
        }
    },
    navItem: {
        fontWeight: 'bold',
        color: palette['greyish-brown'],
        cursor: 'pointer',
        '& div': {
            width: '100%',
            minWidth: '80px',
            cursor: 'pointer'
        }
    },
    bar: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
})

const CenterMenu = withRouter(withStyles(styles)(({classes, event, previewSuffix, extras}) => {

    const [isAuthenticated] = useAuth()

    const {slug} = useParams()
    const host = checkHost()

    let baseURL = `/event/${event.eventInfo.id}`

    if (slug) {
        baseURL = `/${slug}`
    } else if (host) {
        baseURL = ``
    }

    return <><Hidden lgUp><Grid container spacing={3} className={classes.bar} justify="center">
        <Grid item xs>
            <NavLink to={`${baseURL}/info${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> <Chip label='Info'/> </NavLink>
        </Grid>

        {event.sessionsCount !== 0 && <Grid item xs>
            <NavLink to={`${baseURL}/agenda${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> <Chip label='Agenda'/> </NavLink>
        </Grid>}

        {event.participantsCount !== 0 && <Grid item xs>
            <NavLink to={`${baseURL}/participants${previewSuffix}`} className={classes.navItem} activeClassName={classes.selected}> <Chip label='Speakers'/> </NavLink>
        </Grid>}

        {event.keynoteSpeakers.length !== 0 && <Grid item xs>
            <NavLink to={`${baseURL}/keynotespeakers${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> <Chip label='Keynote speakers'/> </NavLink>
        </Grid>}

        {((event.eventInfo.showProceedings  === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) && <Grid item xs>
            <NavLink to={`${baseURL}/proceedings${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> <Chip label='Proceedings'/> </NavLink>
        </Grid>}

        {event.sponsors.length !== 0 && <Grid item xs>
            <NavLink to={`${baseURL}/sponsors${previewSuffix}`} className={classes.navItem} activeClassName={classes.selected}> <Chip label='Sponsors'/> </NavLink>
        </Grid>}

        {/*{event.eventInfo.hasExtras && <Grid item xs>*/}
        {/*    <NavLink to={`${baseURL}/extras${previewSuffix}`} activeClassName={classes.navItem}*/}
        {/*             className={classes.navItem}> <Chip label='Extras'/> </NavLink>*/}
        {/*</Grid>}*/}

        {event.eventInfo.hasExtras && extras && extras.map((extra) =>
            <Grid item xs>
                <NavLink to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
                         className={classes.navItem}
                         activeClassName={classes.selected}> <Chip label={extra.title}/> </NavLink>
            </Grid>)}

        {event.eventInfo.hasHotels && <Grid item xs>
            <NavLink to={`${baseURL}/hotels${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> <Chip label='Hotels'/> </NavLink>
        </Grid>}

        {event.eventInfo.hasRestaurants && <Grid item xs>
            <NavLink to={`${baseURL}/restaurants${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> <Chip label='Restaurants'/> </NavLink>
        </Grid>}

        {isAuthenticated&&<Grid item xs>
          <NavLink to={`${baseURL}/social-feed${previewSuffix}`} activeClassName={classes.selected}
                   className={classes.navItem}> <Chip label='Social Feed' /> </NavLink>
        </Grid>}

    </Grid></Hidden></>

}))

export default CenterMenu
