import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {useHttp} from 'api/core'
import api from 'api'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import {imageFile} from 'utils'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import {Typography} from '@material-ui/core'
import { palette } from 'theme'
import { useSnackbar } from "notistack";


const styles = theme => ({
  formControl: {
    width: '100%'
  },
  container: {
    // paddingTop: theme.spacing(6)
  },
  recipientsContainer: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '330px',
    overflowY: 'auto'
  }
})

const Recipients = withStyles(styles)(({classes, state, setState, defaultGroup, credits, hasCredits,setHasCredits}) => {
  const [groups, setGroups] = useState(state.groups);
  const {enqueueSnackbar} = useSnackbar();
  const [getData, data] = useHttp();
  const [getRecData, recData, isLoadingRec] = useHttp();

  const groupId = (data) => {
    return defaultGroup ? defaultGroup : data[0].groups[0].id;
  };

  const forcedGroup = !!defaultGroup;

  useEffect(() => {
    if (!state.groups) getData(api.entities.communication.getGroups());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setGroups(data);
      // setState({...state, })
      setState({...state, groups: data, group: groupId(data), groupRecipients: []});
      getRecData(api.entities.communication.getGroupRecipients(null, null, {id: state.eventId, groupId: groupId(data)}));
    }
    //eslint-disable-next-line
  }, [data]);

  const selectGroup = (e) => {
    const value = e.target.value;
    setState({...state, group: parseInt(value), groupRecipients: []});
  };

  useEffect(() => {
    if (state.group && !forcedGroup) {
      getRecData(api.entities.communication.getGroupRecipients(null, null, {id: state.eventId, groupId: state.group}));
    }
    //eslint-disable-next-line
  }, [state.group]);

  useEffect(() => {
    if (recData) {
      setState({...state, groupRecipients: recData.participantsToEmail});
    }
    //eslint-disable-next-line
  }, [recData]);

  useEffect(() =>
  {
    if (credits && recData){
      const availableCredits = credits.emailsBought - credits.emailsSent;
      if ((availableCredits) <= recData.participantsToEmail.length)
      {
        setHasCredits(true);
        enqueueSnackbar("You do not have enough credits to send the emails. Please add credits or select another group.", { variant: "warning", preventDuplicate: true });
      }
       else
      {
      setHasCredits(false);
      }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[credits,recData])

  if (!groups) {
    return (
      <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" height="100%" width="100%">
        <Box display="flex" p={6}>
          <CircularProgress size={"5rem"} color="secondary" />
        </Box>
      </Box>
    );
  }

  return (
    <Grid item container justify="center" alignContent="center">
      <Grid item xs={6}>
        <Grid item container spacing={2} className={classes.container}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {" "}
              Select the recipients group{" "}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <Select disabled={forcedGroup} native labelId="groupIdLabel" displayEmpty name="groupId" label="Group" variant="filled" fullWidth defaultValue="" value={state.group} onChange={selectGroup}>
                {groups.map((category, i) => {
                  return (
                    <optgroup label={category.category}>
                      {category.groups.map((group, y) => {
                        return (
                          <option value={group.id} key={`${category.category}-${y}`}>
                            {group.name}
                          </option>
                        );
                      })}
                    </optgroup>
                  );
                })}
              </Select>
              <InputLabel variant="filled">Group</InputLabel>
              {/*<FormHelperText error variant='filled'> {errors.general.category} </FormHelperText>*/}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Box className={classes.recipientsContainer}>
              {isLoadingRec && (
                <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" height="100%" width="100%">
                  <Box display="flex" p={6}>
                    <CircularProgress size={"5rem"} color="secondary" />
                  </Box>
                </Box>
              )}

              {state.groupRecipients.length === 0 && !isLoadingRec && (
                <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" height="300px" width="100%">
                  <Box display="flex"> No recipients in group </Box>
                </Box>
              )}

              <List dense className={classes.root}>
                {state.groupRecipients.map((member) => {
                  return (
                    <ListItem key={member.id}>
                      <ListItemAvatar>
                        <Avatar alt={`${member.title ? member.title : ""} ${member.firstName} ${member.lastName}`} src={imageFile(member.image)} />
                      </ListItemAvatar>
                      <ListItemText primary={`${member.title ? member.title : ""} ${member.firstName} ${member.lastName}`} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Grid>
          <Grid item container spacing={2} justify="flex-end">
            <Grid item>
              {state.groupRecipients.length !== 0 && !isLoadingRec && (
                <>
                  <p style={{margin: "0", textAlign: "right"}}>{state.groupRecipients.length} found</p>
                  {/*found in {[].concat.apply([], state.groups.map(item => item.groups)).find((g)=>g.id===state.group).name}*/}
                  {hasCredits && <p style={{margin: "0", textAlign: "justify", fontSize: "14px"}}>You do not have enough credits to send the emails. Please add credits or select another group.</p>}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Recipients
