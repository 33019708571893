import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import SessionTypeCard from 'components/core/Sessions/SessionTypeCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import SessionTypeEditNew from 'components/core/Sessions/SessionTypeEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'

const styles = theme => ({
  sessionTypesContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noSessionTypesYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
   addBtn: {
    textAlign: 'right'
   },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(3)
  }
})

const SessionTypes = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack, setSessionTypesLoaded})=>{

  const [getData, data] = useHttp()
  const [delRequest, delData] = useHttp()
  const [openSessionTypeDialog, setOpenSessionTypeDialog] = useState(false)
  const [editinSessionType, setEditingSessionType] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingSessionType, setDeletingSessionType] = useState()
  const [sessionTypesData, setSessionTypesData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getData(api.entities.manage.getSessionTypes(null,null,{id:eventId}))
  },[getData, eventId])

  useEffect(()=>{
    if (data) {
      setSessionTypesData(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
      if(setSessionTypesLoaded){setSessionTypesLoaded(true)}
    }
  },[data, setEventInfo, setNavBack, setPageTitle])

  const handleClose = (shouldReload) => {
    setOpenSessionTypeDialog(false)
    if (shouldReload) {
      getData(api.entities.manage.getSessionTypes(null,null,{id:eventId}))
    }
  }

  const handleOpen = (sessionType) => {
    setOpenSessionTypeDialog(true)
    if (sessionType) {
      setEditingSessionType(sessionType)
    } else {
      setEditingSessionType(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingSessionType(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingSessionType) {
      delRequest(api.entities.sessionTypes.delete(null,null,{id:id}))
      setDeletingSessionType(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted sessionType', {variant: 'success'})
      getData(api.entities.manage.getSessionTypes(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getData])

  if (!sessionTypesData) return <> </>

  return <>
    <Dialog onClose={handleClose} open={openSessionTypeDialog} maxWidth='md'>
      <SessionTypeEditNew sessionType={editinSessionType} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete sessionType</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete sessionType?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
        <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
          No
        </Button>
        </Box>
        <Box p={1}>
        <Button onClick={()=>{handleDeleteClose(deletingSessionType)}}  size='small' autoFocus color='primary'>
          Yes
        </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid container className={classes.sessionTypesContainer} spacing={2}>
        <Grid item xs={12}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Session types
          </Typography>
        </Grid>
          <Grid item xs={12} md={12}>
            {sessionTypesData.sessionTypes.map((sessionType,i)=>{
              return <SessionTypeCard sessionType={sessionType} key={i} editAction={()=>{handleOpen(sessionType)}} deleteAction={()=>{handleDeleteOpen(sessionType.id)}} mb={2}/>
            })}
            {sessionTypesData.sessionTypes.length===0?<Grid item container className={classes.noSessionTypesYet}>
             <Grid item>No session types yet!</Grid>
            </Grid>:''}
            </Grid>
          <Grid item xs={12} className={classes.addBtn}>
            <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen()}}>Add Session type</Button>
          </Grid>
        </Grid>
    </Fade>
  </>
})

export default withRouter(SessionTypes)
