import {withStyles} from '@material-ui/core/styles'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import Box from '@material-ui/core/Box'
import RootRef from '@material-ui/core/RootRef'
import Button from 'components/core/Button'
import {IconUploadBorderDark} from 'components/core/icons'
import React, {useState} from 'react'
import {palette} from 'theme'
import CropImage from 'components/core/CropImage'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {FILES_URL} from 'config'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  note: {
    fontSize: '12px'
  },
  dropBox: {
    backgroundColor: 'white',
    borderRadius: '6px',
    color: palette['greyish-brown'],
    overflow: 'hidden',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  formControl: {
    width: '100%'
  }
})

const UploadImage = withStyles(styles)(({formData, setFormData, name, fieldName, images, existingFieldName, size, placeholderSizer, contained, showSize=false, deleteBtn=true, deleteKey='', color='primary', classes}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [cropImage, setCropImage] = useState(false)

  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width/2) && (img.height < size.height/2)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }

    fr.readAsDataURL(acceptedFiles[0])
  }

  const deleteFile = () => {
    setFormData({...formData, [existingFieldName]:null, [fieldName]: null, [deleteKey]: true})
  }

  const setImage = (image) => {
    setFormData({...formData, [fieldName]: image})
    setCropImage(false)
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const handleChangeImage = event => {

    const imageIndex = event.target.value

    if (imageIndex==='') {
      setFormData({...formData, [fieldName]: ''})
      return
    }

    const imageObj = images[imageIndex]

    fetch(`${FILES_URL}/${imageObj.id}`)
      .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], imageObj.filename, blob)
          setFormData({...formData, [fieldName]: file})
        })
  }

  return <>
    {cropImage&&<CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage} setOpen={setCropImage} setImage={setImage} />}
    <Box display='flex' flexDirection='column' alignContent='center' mt={1} mb={1}>
      {images&&images.length!==0&&<Box width={340}>
        <FormControl variant='filled' className={classes.formControl}>
        <InputLabel id='selectImage' >Select a template image</InputLabel>
        <Select
          labelId='selectImage'
          id='selectImage'
          showEmpty
          onChange={handleChangeImage}
          defaultValue=''
          // label='Select a template'
        >
          {images.map((image,i)=><MenuItem value={i}> {image.name} </MenuItem>)}
          <MenuItem value=''>
            None
          </MenuItem>
        </Select>
        </FormControl></Box>}
    </Box>
    <Box mt={1} mb={1}><Typography component='p' variant='p' className={classes.title}> or upload a custom image</Typography></Box>
      <RootRef rootRef={ref}>
        <Box {...rootProps} width={size.width / placeholderSizer} height={size.height / placeholderSizer}
             className={classes.dropBox}>
          <input type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
          <Box display={'flex'} justifyContent='center' flexDirection='column' alignItems='center' height='100%'>
            {formData[fieldName]||formData[existingFieldName] ?
              <img src={formData[fieldName]&&(typeof formData[fieldName].name == 'string')?URL.createObjectURL(formData[fieldName]):formData[existingFieldName]} width={size.width / placeholderSizer}
                   height={size.height / placeholderSizer} alt={name}/> : <>
                <Box display={'flex'} mb={2}><IconUploadBorderDark/></Box>
                <Box display={'flex'}>Drag & Drop Image</Box>
                {showSize?<Box className={classes.note} pt={2}>Image should be at least {size.width}x{size.height}px</Box>:''}
              </>}
          </Box>
        </Box>
    </RootRef>    
      <RootRef rootRef={ref}>
        <Box  display='flex' flexDirection='row' alignItems='center' alignContent='center' mt={2} mb={4}>
          <Box {...rootProps}>
            <input name={fieldName} type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
            <Button size='smaller' variant={contained?'contained':'outlined'} colour={color}>Select File</Button>
          </Box>
          {deleteBtn&&<Box display='flex' ml={2}><Button size='smaller' variant={'outlined'} colour='red' onClick={()=>{deleteFile()}}>Delete File</Button></Box>}
        </Box>
      </RootRef>    
  </>
})

export default UploadImage
