import { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {imageFile} from 'utils'
import IconButton from '@material-ui/core/IconButton'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import DotsVerticalIcon from "@icons/material/DotsVerticalIcon";
import language from "components/language";


const styles = (theme) => ({
  container: {
    borderRadius: "6px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.16)",
    border: "solid 1px #e6e6e6",
    color: palette["greyish-brown"],
    overflow: "hidden",
    backgroundColor: "white",
    width: "250px",
    minHeight: "150px",
    margin: theme.spacing(2),
    position: "relative",
    "& a": {
      color: palette.aquamarine,
    },
  },
  userPhoto: {
    display: "flex",
    width: "84px",
    height: "84px",
    borderRadius: "50%",
    backgroundColor: "white",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    "& > img": {
      width: "100%",
      height: "auto",
      backgroundColor: palette.imageBackgroundColor,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  dots: {
    position: "absolute",
    right: "10px",
    bottom: "15px",
    cursor: "pointer",
    "&:hover": {
      color: "#d4a11d",
    },
  }
});

const KeynoteSpeaker = withStyles(styles)(({classes, participant}) => {
  const [viewDesc, setViewDesc] = useState(false);

  return <Grid item className={classes.container}>

    <Grid item container justify='center' spacing={2} alignContent='center' alignItems='center' direction='column'>
      <Grid item>
        <Box className={classes.userPhoto}><img src={imageFile(participant.image)} alt={`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`} /></Box>
      </Grid>
      <Grid item>
        <Box display='flex' fontSize={17} fontWeight='bold' p={1} textAlign='center'>
          {`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`}
        </Box>
      </Grid>
      </Grid>

      {!viewDesc && (<><Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'} p={1}>
          <Box fontSize={14}>{language.position}</Box>
          <Box fontSize={16} fontWeight={'bold'} textAlign='center'> {participant.profession} </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'} p={1}>
          <Box fontSize={14}>{language.affiliationCompany}</Box>
          <Box fontSize={16} fontWeight={'bold'} textAlign='center'> {participant.instituteOrCompany} </Box>
        </Box>
      </Grid></>)}

      {viewDesc && <><Grid item xs={12}>
        <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'} mb={1}>
          <Box fontSize={14}>{language.description}</Box>
          <Box fontSize={14} fontWeight={'normal'} textAlign='center'> {participant.summary}</Box>
        </Box>
        </Grid>      
      </>} 
    
      <DotsVerticalIcon className={classes.dots} onClick={()=>setViewDesc(prevViewDesc => !prevViewDesc)}/>
    {participant.linkedinUrl&&<Grid item xs={12}>
      <Box ml={-2}><IconButton href={participant.linkedinUrl} target='_blank'><LinkedInIcon /></IconButton></Box>
    </Grid>}

  </Grid>
})

export default KeynoteSpeaker
