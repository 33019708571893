import {withStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {IconThumbsDownDark, IconThumbsUpDark} from 'components/core/icons'
import {Pie} from 'react-chartjs-2'
import clsx from 'clsx'
import {useHttp} from 'api/core'
import api from 'api'
import Fab from '@material-ui/core/Fab'
import Badge from '@material-ui/core/Badge'
import Speedometer from 'components/core/OpenTok/Components/Emotions/Speedometer'

const styles = theme => ({
  voting: {
    minHeight: '110px',
    paddingTop: 0,
    // borderLeft: `0.5px solid ${palette['very-light-pink-two']}`
  },
  title: {
    padding: theme.spacing(1,0),
    fontSize: '18px',
    fontWeight: 'bold',
    color: palette['greyish-brown']
  },
  voteBtn: {
    // width: '24px',
    // height: '24px',
    // padding: theme.spacing(2),
    // border: `1px solid ${palette['very-light-pink']}`,
    // borderRadius: '50%',
    transition: 'opacity 0.3s',
    boxShadow: 'none !important',
    backgroundColor: palette.aquamarine,
    '&:hover': {
      transform: 'scale(1.1)',
      backgroundColor: palette.aquamarine,
      transition: 'all 200ms ease-in'
    }
  },
  voteContainer: {
    // height: '80%'
  },
  thumbsUp: {
    // color: '#2ecc71'
    color: 'white',
  },
  thumbsDown: {
    // color: '#e74c3c'
    color: 'white',
  },
  disabledVoting: {
    opacity: 0.3
  },
  badgeRootLike: {
    // border: `1px solid ${palette['greyish-brown']}`,
    backgroundColor: '#2ecc71',
    color: 'white',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.1)',

  },
  badgeRootDisLike: {
    // border: `1px solid ${palette['greyish-brown']}`,
    backgroundColor: '#c0392b',
    color: 'white',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.1)',

  },
  fabRoot: {
    boxShadow: 'none',
    width: 48,
    height: 48,
    marginBottom: 10
  },
  vote: {
    height: 100
  },
  emotion: {
    height: 100
  },
  participation: {
    // height: 100
  }
})


const Emotions = withStyles(styles)(({classes, sessionState}) => {

  const {
    roomId,
    audienceTotalJoined,
    audienceParticipated,
    likeClicks,
    dislikeClicks
  } = sessionState

  // eslint-disable-next-line
  const [getLike, likeData, isLoadingLike] = useHttp(true)
  // eslint-disable-next-line
  const [getDisLike, dislikeData, isLoadingDisLike] = useHttp(true)
  const [disabledVote, setDisabledVote] = useState(false)

  const totalNum = (audienceParticipated*100)/audienceTotalJoined

  const data = {
    labels: ['', ''],
    datasets: [ {
      // { barPercentage: 1.0,
      //   categoryPercentage: 1.0,
      //   maxBarThickness: 10,
      //   minBarLength: 0,
      backgroundColor: [palette.aquamarine,palette.graphColors[0]],
      maintainAspectRatio: false,
      data: [totalNum, 100-totalNum]
    }]
  }

  const voteUp = () => {
    setDisabledVote(true)
    getLike(api.entities.virtualRoom.like(null,null,{id:roomId}))
    setTimeout(()=>setDisabledVote(false),10000)
  }

  const voteDown = () => {
    setDisabledVote(true)
    getDisLike(api.entities.virtualRoom.dislike(null,null,{id:roomId}))
    setTimeout(()=>setDisabledVote(false),10000)
  }
  //
  // useEffect(()=>{
  //   if (likeData&&likeData.status&&likeData.status===204) {
  //     // cancelHeartbeat()
  //     // setSession(f=>({...f, status: {...f.status, likeClicks: f.status.likeClicks+1 }}))
  //   }
  //   // eslint-disable-next-line
  // },[likeData])
  //
  // useEffect(()=>{
  //   if (dislikeData&&dislikeData.status&&dislikeData.status===204) {
  //     // cancelHeartbeat()
  //     // setSession(f=>({...f, status: {...f.status, dislikeClicks: f.status.dislikeClicks+1 }}))
  //   }
  //   // eslint-disable-next-line
  // },[dislikeData])

  const isLoading = isLoadingDisLike||isLoadingLike

  return <Grid item container spacing={2}>

    <Grid item>
      <Grid item container justify='space-between' alignItems='center' alignContent='center' direction='column' className={classes.participation}>
        <Grid item>
          <Box p={1.1} pt={0} color={palette['greyish-brown']} fontWeight='bold' fontSize={14}>
            Participation
          </Box>
        </Grid>
        <Grid item>
          <Box maxWidth={80} display='flex' pt={1}>
            <Pie
              data={data}
              height={64}
              width={64}
              options={{ layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                  }
                },
                tooltips: {
                  enabled: false
                },
                legend:{display:false},scales:{xAxes:[{    barPercentage: 1,
                    categoryPercentage: 1,
                    display:false,ticks:{beginAtZero:true},gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false}}]}}} />
          </Box>
        </Grid>
      </Grid>
    </Grid>

    <Grid item>
      <Grid item container justify='space-between' alignItems='center' alignContent='center' direction='column' className={classes.participation}>
        <Grid item>
          <Box p={1.1} pt={0} color={palette['greyish-brown']} fontWeight='bold' fontSize={14}>
            Emotion
          </Box>
        </Grid>
        <Grid item>
          <Speedometer sessionState={sessionState} />
        </Grid>
      </Grid>
    </Grid>

    <Grid item>
      <Grid item container alignItems='center' alignContent='center' direction='column' justify={'space-between'} className={classes.vote}>
        <Grid item>
          <Box p={1.1} pt={0} color={palette['greyish-brown']} fontWeight='bold' fontSize={14}>
            Totals
          </Box>
        </Grid>
        <Grid item>
          <Grid item container spacing={2} alignContent='center' justify='center' alignItems='center' className={classes.voteContainer}>

            <Grid item xs={6}>
              <Badge badgeContent={likeClicks} color='secondary' overlap='circle' classes={{colorSecondary:classes.badgeRootLike}}>
                <Fab aria-label='like' disabled={(disabledVote||isLoading)} onClick={voteUp} classes={{root:classes.fabRoot}} className={clsx(classes.voteBtn, (disabledVote||isLoading)&&classes.disabledVoting)}>
                  <IconThumbsUpDark className={classes.thumbsUp} />
                </Fab>
              </Badge>
            </Grid>

            <Grid item xs={6}>
              <Badge badgeContent={dislikeClicks} color='secondary' overlap='circle' classes={{colorSecondary:classes.badgeRootDisLike}}>
                <Fab aria-label='dislike' disabled={(disabledVote||isLoading)} onClick={voteDown} classes={{root:classes.fabRoot}} className={clsx(classes.voteBtn, (disabledVote||isLoading)&&classes.disabledVoting)}>
                  <IconThumbsDownDark className={classes.thumbsDown} />
                </Fab>
              </Badge>
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Grid>

  </Grid>
})

export default Emotions
