import {withStyles} from '@material-ui/core/styles'
import {FIELD_TYPES, FIELD_TYPES_LIST} from "config";
import Grid from '@material-ui/core/Grid'
import {Select, TextField} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import {IconAddBorderLight, IconXBorderDark} from 'components/core/icons'
import Button from 'components/core/Button'
import Box from '@material-ui/core/Box'
import DeleteIcon from '@material-ui/icons/Delete'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import clsx from 'clsx'
import React, {useEffect} from 'react'
import {palette} from 'theme'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import {useHttp} from 'api/core'
import api from 'api'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  addNewItem:{
    border: '2px dashed !important',
    opacity: 0.5,
    cursor: 'pointer',
    margin: 0,
    transition: 'all 0.4s',
    '&:hover': {
      opacity: 0.8
    }
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid',
    borderRadius: '6px',
    borderColor: palette['very-light-pink'],
    // backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  formControl: {
    width: '100%'
  },
  toolbar: {
    padding: theme.spacing(2),
    borderTop: '1px solid',
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
    borderColor: palette['brown-grey']
  },
  editor: {
    color: palette['greyish-brown'],
    borderRadius: '6px',
    minHeight: '300px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  dialogActions: {
    marginTop: theme.spacing(2)
  },
  darkMode: {
    backgroundColor: palette['cerulean-blue']
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
})

const FormEditor = withStyles(styles)(({classes, fields=[], setFields, name, setName, darkMode=false, eventId, flowType}) => {

  const [getThematicCodes, thematicCodes] = useHttp()

  useEffect(()=>{
    getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:eventId}))
    //eslint-disable-next-line
  }, [])
  
  //adds title, abstract, author if not exists in submission flow
  useEffect(() =>
  {
    //add fields only in submission flow exclude from review flows
   if(flowType!=="review"){ 
    
    const newFields = [...fields];
    const requiredTitle = newFields.filter((field)=> field.type === 13)
    if (requiredTitle.length===0){
      newFields.push({
        type: FIELD_TYPES.TITLE,
        label: "Title",
        required: true,
      });
    }
    const requiredAbstract = newFields.filter((field) => field.type === 14);  
    if (requiredAbstract.length === 0){
      newFields.push({
        type: FIELD_TYPES.ABSTRACT,
        label: "Abstract",
        required: true,
      });}
    const requiredAuthors = newFields.filter((field) => field.type === 11);  
    if (requiredAuthors.length === 0){
      newFields.push({
        type: FIELD_TYPES.AUTHORS,
        label: "Authors",
        required: true,
      });}
    setFields(newFields); }   
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // reorder util
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  // Handle re-ordering on dnd
  const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      setFields(reorder(
        fields,
        result.source.index,
        result.destination.index
      ))
  }

  // Handle re-ordering on dnd for options
  const onDragEndOptions = (result) => {

    const fieldPos = parseInt(result.draggableId.split('-')[0])

    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newFields = [...fields]

    newFields[fieldPos].options = reorder(
      fields[fieldPos].options,
      result.source.index,
      result.destination.index
    )

    setFields(newFields)
  }

  // Handle field type change
  const changeType = (e,i) => {
    const value =  parseInt(e.target.value)
    const newFields = [...fields]
    newFields[i] = {
      type: value,
      "label": newFields[i].label,
    }
    if (value===FIELD_TYPES.SINGLE_SELECT||value===FIELD_TYPES.MULTI_SELECT||value===FIELD_TYPES.GRADE) {
      newFields[i].options = [
        {
          "option" : ''
        }
      ]

      if (value===FIELD_TYPES.MULTI_SELECT) newFields[i].value = [] // fix for multiple select- requires array

    }

    if (value===FIELD_TYPES.THEMATIC_CODE) { // fix for empty value
      newFields[i].min = 1
      newFields[i].max = 1
      newFields[i].listId = thematicCodes[0].id
    }

    setFields(newFields)
  }

  // Handle label change
  const changeLabel = (e,i) => {
    const value = e.target.value
    const newFields = [...fields]
    newFields[i].label = value
    setFields(newFields)
  }

  // const changeMarkAs = (e, i) =>{
  //   const value = e.target.value;
  //   const newFields = [...fields];
  //   newFields[i].markAs = value;
  //   setFields(newFields);
  // }

  // Handle Min change
  const handleChangeMin = (e,i) => {
    const value = e.target.value
    const newFields = [...fields]
    newFields[i].min = +value
    setFields(newFields)
  }

  // Handle Max change
  const handleChangeMax = (e,i) => {
    const value = e.target.value
    const newFields = [...fields]
    newFields[i].max = +value
    setFields(newFields)
  }

  // Handle bucket
  const handleBucket = (e,i) => {
    const value = e.target.value
    const newFields = [...fields]
    newFields[i].listId = value
    setFields(newFields)
  }

  // Handle description change
  const changeDescription = (e,i) => {
    const value = e.target.value
    const newFields = [...fields]
    newFields[i].description = value
    setFields(newFields)
  }

  // Handle field required
  const changeRequired = (i) => {
    const newFields = [...fields]
    newFields[i].required = !newFields[i].required
    setFields(newFields)
  }

  // Handle option change
  const changeOption = (e,i,oi) => {
    const value = e.target.value
    const newFields = [...fields]

    newFields[i].options[oi] = {...newFields[i].options[oi], option: value}

    setFields(newFields)
  }

  // Delete field
  const deleteField = (i) => {
    const newFields = [...fields]
    newFields.splice(i,1)
    setFields(newFields)
  }

  // Add new field
  const addNew = () => {
    const newFields = [...fields]
    newFields.push({
      type: FIELD_TYPES.LABEL,
      label: ''
    })
    setFields(newFields)
  }

  // Field specific actions
  /////////

  // Add new option (selects)
  const addOption = (i) => {
    const newFields = [...fields]
    newFields[i].options.push({option:''})
    setFields(newFields)
  }

  // Remove option (selects)
  const removeOption = (i,oi) => {
    const newFields = [...fields]
    newFields[i].options.splice(oi,1)
    setFields(newFields)
  }


  return <>
      <Grid container spacing={2}>

        <Grid item xs={12}>
          <TextField variant='filled' value={name} label='Form name' onChange={(e)=>setName&&setName(e.target.value)}fullWidth required />
        </Grid>

        <Divider/>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Grid item container {...provided.droppableProps} ref={provided.innerRef}>

                {fields.map((field,i)=>{
                  const specialField = field.type === FIELD_TYPES.GRADE

                  return <Draggable key={`${field.type}-${i}`} draggableId={`${field.type}-${i}`} index={i}>
                  {(provided, snapshot) => (
                  <Grid item container key={i} spacing={2} className={clsx(classes.field,darkMode&&classes.darkMode)} ref={provided.innerRef} {...provided.draggableProps}>

                  <Grid item {...provided.dragHandleProps}>
                    <DragIndicatorIcon/>
                  </Grid>

                  <Grid item xs>
                    <Grid item container spacing={2}>

                      <Grid item xs={8}>
                        <TextField variant='filled' label='Label' fullWidth value={field.label} onChange={(e)=>changeLabel(e,i)} />
                      </Grid>

                      {(!specialField)&&<Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                          <Select labelId={`type-label-${i}`} label='Field type' variant='filled' displayEmpty fullWidth value={field.type} onChange={(e)=>changeType(e,i)}>
                            {Object.keys(FIELD_TYPES_LIST).filter((t)=>parseInt(t)!==FIELD_TYPES.GRADE).map((key, i)=>{
                              return <MenuItem value={key} key={i}>{FIELD_TYPES_LIST[key]}</MenuItem>
                            })}
                          </Select>
                          <InputLabel id={`type-label-${i}`} variant='filled'>Field type</InputLabel>
                        </FormControl>
                      </Grid>}

                      <Grid item xs={8}>
                        <TextField variant='filled' label='Description' fullWidth value={field.description} onChange={(e)=>changeDescription(e,i)}/>
                            </Grid>
                            
                       {/* {(field.type === 2 || field.type === 5 || field.type === 6)  &&<Grid item xs={4}>
                        <FormControl className={classes.formControl}>
                          <Select labelId={`mark-type-label-${i}`} label='Mark field as' variant='filled' displayEmpty fullWidth value={field.markAs} onChange={(e)=>changeMarkAs(e,i)}>
                            {Object.keys(FIELD_PAPERSUBMISSION_FIELD_TYPE).map((key, i)=>{
                              return <MenuItem value={key} key={i}>{FIELD_PAPERSUBMISSION_FIELD_TYPE[key]}</MenuItem>
                            })}
                          </Select>
                          <InputLabel id={`mark-type-label-${i}`} variant='filled'>Mark field as</InputLabel>
                        </FormControl>
                      </Grid>}                      */}
                            
                      <DragDropContext onDragEnd={onDragEndOptions}>
                        <Droppable droppableId={`droppable-${i}`}>
                          {(provided, snapshot) => (
                              <Grid item container spacing={2} {...provided.droppableProps} ref={provided.innerRef}>
                        {/* SINGLE SELECT */}
                        {(field.type===FIELD_TYPES.SINGLE_SELECT||field.type===FIELD_TYPES.MULTI_SELECT)&&<>
                          {field.options.map((option,oi)=>{
                            return <Draggable key={`${field.type}-${oi}-${i}`} draggableId={`${field.type}-${oi}`} index={oi}>
                              {(provided, snapshot) => (<Grid item container ref={provided.innerRef} {...provided.draggableProps}>
                              <Grid item {...provided.dragHandleProps}>
                                <DragIndicatorIcon/>
                              </Grid>
                              <Grid item xs>
                                <TextField key={`${oi}-${i}`} variant='filled' label={`Option #${oi+1}`} fullWidth value={option.option || ''} onChange={(e)=>changeOption(e,i,oi)} InputProps={{
                                  endAdornment: <InputAdornment position='end'>
                                    <IconButton aria-label='delete option' onClick={()=>removeOption(i,oi)}>
                                      <IconXBorderDark/>
                                    </IconButton>
                                  </InputAdornment>}}
                                />
                              </Grid>
                            </Grid>)}
                            </Draggable>
                          })}
                          <Grid item xs={12} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                            <Grid item>
                              <Button size='small' variant='outlined' onClick={()=>addOption(i)}>
                                <IconAddBorderLight/> Add Option
                              </Button>
                            </Grid>
                          </Grid>
                        </>}

                        {/* Thematic code */}
                        {thematicCodes&&field.type===FIELD_TYPES.THEMATIC_CODE&&<>
                          <Grid item container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl className={classes.formControl}>
                                <Select labelId={`type-label-${i}`} label='Bucket' variant='filled' displayEmpty fullWidth value={field.listId||field.listId===0?field.listId:thematicCodes[0].id} onChange={(e)=>handleBucket(e,i)}>
                                  {thematicCodes.map((bucket, i)=>{
                                    return <MenuItem value={bucket.id} key={i}>{bucket.name}</MenuItem>
                                  })}
                                </Select>
                                <InputLabel id={`type-label-${i}`} variant='filled'>Bucket</InputLabel>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField variant='filled' label='Min' fullWidth defaultValue={1} value={field.min} onChange={(e)=>handleChangeMin(e,i)}/>
                            </Grid>
                            <Grid item xs={3}>
                              <TextField variant='filled' label='Max' fullWidth value={field.max} onChange={(e)=>handleChangeMax(e,i)}/>
                            </Grid>
                          </Grid>
                        </>}

                                {/* GRADE */}
                                {(field.type===FIELD_TYPES.GRADE)&&<>
                                  {field.options.map((option,oi)=>{
                                    return <Draggable key={`${field.type}-${oi}-${i}`} draggableId={`${field.type}-${oi}`} index={oi}>
                                      {(provided, snapshot) => (<Grid item container ref={provided.innerRef} {...provided.draggableProps}>
                                        <Grid item {...provided.dragHandleProps}>
                                          <DragIndicatorIcon/>
                                        </Grid>
                                        <Grid item xs>
                                          <TextField key={`${oi}-${i}`} variant='filled' label={`Grade #${oi+1}`} fullWidth value={option.option || ''} onChange={(e)=>changeOption(e,i,oi)} InputProps={{
                                            endAdornment: <InputAdornment position='end'>
                                              <IconButton aria-label='delete grade' onClick={()=>removeOption(i,oi)}>
                                                <IconXBorderDark/>
                                              </IconButton>
                                            </InputAdornment>}}
                                          />
                                        </Grid>
                                      </Grid>)}
                                    </Draggable>
                                  })}
                                  <Grid item xs={12} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                                    <Grid item>
                                      <Button size='small' variant='outlined' onClick={()=>addOption(i)}>
                                        <IconAddBorderLight/> Add Grade
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </>}

                      </Grid>
                            )}
                        </Droppable>
                      </DragDropContext>
                      {provided.placeholder}
                    </Grid>
                  </Grid>

                  <Grid item container className={classes.toolbar} justify={'flex-end'} alignContent='center' alignItems='center' spacing={1}>
                    {(!specialField)&&<Grid item>
                      <IconButton aria-label="delete"  disabled={specialField || field.type=== 11 || field.type===14 || field.type===13}>
                        <Box display='flex' color={palette['brown-grey']}>
                          <DeleteIcon color={'inherit'} onClick={()=>deleteField(i)}/>
                        </Box>
                      </IconButton>
                    </Grid>}
                    {field.type!==FIELD_TYPES.LABEL&&field.type!==FIELD_TYPES.BOOLEAN&&<Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={field.required || field.type=== 11 || field.type===14 || field.type===13}
                            color="secondary"
                            onClick={()=>changeRequired(i)}
                            disabled={specialField || (field.required && (field.type=== 11 || field.type===14 || field.type===13))}
                          />
                        }
                        label="Required"
                      />
                    </Grid>}
                  </Grid>
                </Grid>)}
                </Draggable>
                  })}
                <Grid item container spacing={2} className={clsx(classes.field,classes.addNewItem)} justify='center' alignContent='center' alignItems='center' onClick={addNew}>
                  <Grid item>
                    <IconAddBorderLight/>
                  </Grid>
                  <Grid item>
                  Add new field {" "}
                  {name && <Tooltip classes={{tooltip: classes.tooltip}} title="Fields cannot be changed as long as a reviewer has included them in their response." placement="top" arrow>
                    <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                  </Tooltip>}                   
                  </Grid>
                </Grid>
                {provided.placeholder}
              </Grid>)}
          </Droppable>
        </DragDropContext>


      </Grid>
  </>

})

export default FormEditor
