import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {PRICING_VALUES} from 'config'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  HotelCard: {},
  fieldTitle: {
    fontSize: '14px'
  },
  fieldValue: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {

  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}> {value} </Box>
  </Box>
})

const HotelCard = withStyles(styles)(({classes, hotel, categories, viewOnly = false, editAction, deleteAction,content, ...props}) => {
  return <Box className={classes.HotelCard} {...props}>
    <Box p={2} className={classes.container}>

      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={7}>
          <FieldText title={'Name'} value={`${hotel.name ? hotel.name : ''}`}/>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FieldText title='Category' value={categories.find(category=>category.id===hotel.categoryId).category}/>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <Box pr={2}>
            <FieldText title='Price' value={PRICING_VALUES[hotel.price]}/>
          </Box>
        </Grid>
        {!content && <Grid item xs={12}>
          <FieldText title='Address'
                     value={`${hotel.address} ${hotel.city} ${hotel.zipCode} ${hotel.country}`}/>
        </Grid>}
      </Grid>

      {!viewOnly && <Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end'>
        <IconNotesBorderLight title='Edit Hotel' className={classes.editIcon} onClick={() => {
          editAction && editAction(hotel.id)
        }}/>
        <IconXBorderLight title='Delete Hotel' className={classes.deleteIcon} onClick={() => {
          deleteAction && deleteAction(hotel.id)
        }}/>
      </Box>}

    </Box>

  </Box>
})

export default HotelCard
