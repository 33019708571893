import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {Typography} from '@material-ui/core'
import { imageFile, withHttp } from 'utils'
import language from "components/language";


const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    '& a': {
      color: palette['cerulean-blue']
    },
    width: '100%',
    margin: '8px',
    padding: '16px'
  },
  ParticipantCardView: {
    transition: 'opacity 0.7s'
  },
  logo: {
    display: 'inline-block',
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    borderRadius: '6px',
    verticalAlign: 'middle',
    padding: theme.spacing(1),
    '& img': {
      display: 'flex',
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
      , [theme.breakpoints.down('sm')]: {
        margin: '0 auto'
      }
    }
  },
  fieldTitle: {
    fontSize: '14px',
  },
  fieldValue: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  sessionBox: {
    minHeight: '60px',
    backgroundColor: palette['very-light-pink'],
    borderRadius: '8px',
    padding: theme.spacing(1),
    color: palette['greyish-brown'],
    height: '100%'
  },
  sessionTitle: {
    fontWeight: 'bold'
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  summary: {
    marginTop: -theme.spacing(2)
  },
  sponsorUrl: {
    color: `${palette.aquamarine} !important`
  },
  link: {
    cursor: 'pointer'
  }
})


const OrganizerCardView = withStyles(styles)(({classes, organizer, event, index, plain, linkToPage=true, ...props}) => {


  return <Box className={classes.container} {...props} key={index}>
    {/*<Box p={2} className={clsx(classes.container,plain&&classes.plain)}>*/}

    <Grid container spacing={2}>

      <Grid item  container spacing={2} xs={12}>
             <Grid item container lg={4} sm={12} xs={12} > 
                  <img src={imageFile(organizer.logoId)} alt={`${ organizer.name }`} className={classes.logo} /> 
             </Grid> 
             <Grid item container lg={8} sm={12} xs={12} >
              <Grid item lg={12} xs={12}>
                  <Box fontSize={22} mt={2} fontWeight={'bold'} >{organizer.name} </Box>
              </Grid>
              <Grid item p={1} pl={0} lg={12} xs={12}>
                {organizer.url && (<a href={withHttp(organizer.url)} className={classes.organizerUrl}> {language.visitUs} </a>)}
              </Grid>
            </Grid>  
      </Grid>

      <Grid item xs={12}>
        <Box display='flex' p={2} pl={0}>
            <Typography variant='body2'>
                {organizer.summary}
            </Typography>                         
        </Box>
      </Grid>
    </Grid>

    {/*</Box>*/}

  </Box>
})

export default OrganizerCardView
