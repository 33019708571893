import React from "react";
import {withStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { palette } from "theme";
import Submission from "assets/ui-submission.png";
import Reviewers from "assets/ui-reviewers.png";
import Assignment from "assets/ui-reviewer-assignment.png";


const styles = (theme) => ({
	howItWorksTimeLine: {
		padding: "65px 10px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#f0f9ff",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "left bottom",
		backgroundSize: "contain",
	},
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
			//height: "calc(100vh - 64px)",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "800px",
		color: "#666666",
		fontSize: "28px",
		textAlign: "center",
		fontWeight: "normal",
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(1),
			marginBottom: 0,
		},
	},
	subtitle2: {
		maxWidth: "800px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "normal",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "25px",
			padding: theme.spacing(2),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "21px",
		fontFamily: "Karla",
		letterSpacing: "-1.05px",
		textTransform: "capitalize",
		lineHeight: "21px",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		letterSpacing: "-0.75px",
		textTransform: "capitalize",
		lineHeight: "14px",
	},
	testimonialsText: {
		width: "535px",
		margin: "64px auto 10px",
		padding: "20px 5px 20px 5px",
		fontFamily: "Karla",
		fontSize: "36px",
		letterSpacing: "-1.8px",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	Submissions: {
		margin: "20px 10px 20px 10px",
		padding: "5px 0px 6px 0",
		fontFamily: "Karla",
		fontSize: "36px",
		letterSpacing: "-1.8px",
		color: "#000",
		display: "block",
	},
	AcceptSubmissions: {
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		color: "#000",
	},
	tickIcon: {
		width: "15px",
		lineHeight: "15px",
		verticalAlign: "bottom",
		marginRight: "10px",
	},
	subList: {
		"& li": {
			listStyle: "none",
			maxWidth: "350px",
			marginBottom: "30px",
			fontFamily: "Karla",
			fontWeight: "500",
		},
		"& ul": {
			paddingLeft: "12px",
		},
	},
	subCircles: {
		//backgroundImage: "url(" + SubmissionsCircles + ")",
		backgroundSize: "contain",
		height: "350px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			height: "260px",
		},
	},
	reviewingCircles: {
		//backgroundImage: "url(" + ReviewingCircles + ")",
		backgroundSize: "contain",
		height: "410px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	timelineRow: {
		//backgroundImage: "url(" + SubmissionsCircles + ")",
		backgroundSize: "450px",
		//minHeight: "610px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		textAlign: "center",
		minHeight: "180px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
			textAlign: "left",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "0px auto",
			fontFamily: "Karla",
			textAlign: "center",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
		[theme.breakpoints.down("xs")]: {
			width: "unset",
		},
	},
	tabImages: {
		width: "80%",
		maxWidth: "450px",
		margin: "30px auto",
		borderRadius: "20px",
		display: "block",
	},
});

const HowItWorksTimeLine = withStyles(styles)(({classes}) => {
	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.howItWorksTimeLine}
		>
			<Grid
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.hero}
			>
				<Grid container>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Submissions</span>
						<span className={classes.AcceptSubmissions}>
							<p>
								Take care of all your submission needs through our fully
								customizable submission forms! You can create multiple
								submission forms and set different deadlines for each.
							</p>
						</span>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subCircles}>
						<img
							className={classes.tabImages}
							src={Submission}
							alt="Anymeets Submissions"
							style={{
								boxShadow: "0px 0px 50px 0px #ccc",
							}}
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subCircles}>
						<img
							className={classes.tabImages}
							src={Reviewers}
							alt="Anymeets Submissions"
							style={{
								boxShadow: "0px 0px 50px 0px #ccc",
							}}
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Reviewers</span>
						<span className={classes.AcceptSubmissions}>
							<p>
								Add and manage reviewers, send reminders with one click, and
								track the status of the review process with our “at a glance”
								dashboard.
							</p>
						</span>
					</Grid>
				</Grid>
				<Grid container className={classes.timelineRow}>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Grades &amp; Decisions</span>
						<span className={classes.AcceptSubmissions}>
							<p>
								Set up grading forms to allow your reviewers to evaluate
								submissions and make comments. You can use single- or
								double-blind reviewing. Once all the grades are in, you can
								accept, reject, or waitlist submissions.
							</p>
						</span>
					</Grid>

					<Grid item lg={6} sm={6} sx={12} className={classes.subCircles}>
						<img
							className={classes.tabImages}
							src={Assignment}
							alt="Anymeets Submissions"
							style={{
								boxShadow: "0px 0px 50px 0px #ccc",
							}}
						/>
					</Grid>
					<Grid item lg={12} sm={12}></Grid>
				</Grid>
			</Grid>
		</Grid>
	);
});

export default HowItWorksTimeLine;
