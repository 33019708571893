import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {FormHelperText, Select, TextField} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import React, {useEffect, useState} from 'react'
import {palette, theme} from 'theme'
import Typography from '@material-ui/core/Typography'
import RichTextEditor from 'react-rte'
import Box from '@material-ui/core/Box'
import {FIELD_TYPES} from 'config'
import Chip from '@material-ui/core/Chip'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
import api from 'api'
import FindUserDialog from 'components/core/FindUserDialog'
import ProfileView from 'components/core/ProfileView'
import RootRef from '@material-ui/core/RootRef'
import {IconUploadBorderDark} from 'components/core/icons'
import {useDropzone} from 'react-dropzone'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Dialog from '@material-ui/core/Dialog'
import AuthorEditNew from 'components/core/AuthorEditNew'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import language from "components/language";
import BlockText from "components/core/BlockText";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
  INLINE_STYLE_BUTTONS: [
    {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'}
  ],
  BLOCK_TYPE_DROPDOWN: [
    {label: 'Normal', style: 'unstyled'},
    {label: 'Heading Large', style: 'header-one'},
    {label: 'Heading Medium', style: 'header-two'},
    {label: 'Heading Small', style: 'header-three'}
  ],
  BLOCK_TYPE_BUTTONS: [
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'}
  ]
}

const styles = (theme) => ({
  addNewItem: {
    border: "2px dashed !important",
    opacity: 0.3,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: "1px solid",
    borderRadius: "6px",
    borderColor: "white",
    backgroundColor: "rgba(0,0,0,0.1)",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  formControl: {
    width: "100%",
  },
  toolbar: {
    padding: theme.spacing(2),
    borderTop: "1px solid",
    color: "white",
    marginTop: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
  editor: {
    color: palette["greyish-brown"],
    borderRadius: "6px",
    minHeight: "300px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  dialogActions: {
    marginTop: theme.spacing(2),
  },
  darkMode: {
    backgroundColor: palette["cerulean-blue"],
  },
  box: {
    border: "1px solid",
    borderRadius: "6px",
    borderColor: palette["very-light-pink"],
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    boxSizing: "border-box",
    padding: theme.spacing(1),
    height: 150,
    overflowY: "scroll",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  dropBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    border: "2px dashed #ddd !important",
    color: palette["greyish-brown"],
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
    minHeight: 130,
  },
  draggableChip: {
    display: "inline-flex",
  },
});

const FieldError = ({field}) => {

  if (field.error) {
    return <FormHelperText error variant='filled'> {field.error} </FormHelperText>
  } else {
    return <> </>
  }

}

const decodeToken = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const FieldRenderer = withStyles(styles)(({classes, field, index, changeValue, thematicCodes}) => {
  const [authorsInfo, setAuthorsInfo] = useState();
  const [thematicLabels, setThematicLabels] = useState();
  const [thematicCodesCount, setThematicCodesCount] = useState();
  const [editorValue, setEditorValue] = useState({value: RichTextEditor.createValueFromString(field.type === 6 && field.value ? field.value : "", "html")});
  const [openUser, setOpenUser] = useState(false);
  const [user, setUser] = useState();
  const [getUserInfo, userData] = useHttp();

  const addNewUser = (saveUser) => {
    const newField = {...field};
    newField.value.newAuthors.push(saveUser);
    newField.value.order.push(saveUser.email);

    let newUserInfo = {...authorsInfo};
    newUserInfo[saveUser.email] = saveUser;

    setAuthorsInfo(newUserInfo);
    setOpenUser(false);
    setUser(false);
  };

  const addAuthor = () => {
    if (!user) return false;

    if (field.value.authors.find((f) => f === user.email)) {
      setOpenUser(false);
      setUser(false);
      return false;
    }

    const newField = {...field};
    newField.value.authors.push(user.email);
    newField.value.order.push(user.email);

    let newUserInfo = {...authorsInfo};
    newUserInfo[user.email] = user;

    setAuthorsInfo(newUserInfo);
    setOpenUser(false);
    setUser(false);
  };

  const onEditorChange = (value) => {
    setEditorValue({value});
    changeValue({target: {value: value.toString("html")}}, i);
  };

  const handleDeleteThematicCode = (i, fi) => {
    const newField = {...field};
    newField.value.splice(fi, 1);
    changeValue({target: {value: newField.value}}, i);
    calcThematicCounts(newField.value);
  };

  const handleAddThematicCode = (code, bucketId) => {
    const newField = {...field};
    if (!newField.value) {
      newField.value = [];
    }
    if (newField.value.find((tcode) => tcode === code)) {
      return;
    }
    if (thematicCodesCount[bucketId] === field.max) {
      return;
    }
    newField.value.push(code);
    changeValue({target: {value: newField.value}}, i);
    calcThematicCounts(newField.value);
  };

  const calcThematicLabels = () => {
    const labels = {};
    thematicCodes.forEach((bucket) => {
      bucket.codes.forEach((code) => {
        labels[code.id] = code.label;
      });
    });
    setThematicLabels(labels);
  };

  const calcThematicCounts = (values) => {
    const thematicCounts = {};
    thematicCodes.forEach((bucket) => {
      thematicCounts[bucket.id] = 0;
      bucket.codes.forEach((code) => {
        if (values && values.find((c) => c === code.id)) thematicCounts[bucket.id]++;
      });
    });
    setThematicCodesCount(thematicCounts);
  };

  useEffect(() => {
    if (field.type === FIELD_TYPES.THEMATIC_CODE) {
      calcThematicLabels();
      calcThematicCounts(field.value);
    }

    if (field.type === FIELD_TYPES.AUTHORS) {
      const myEmail = decodeToken(localStorage.getItem("access_token")).email;

      const newField = {...field};

      if (!newField.value.authors) {
        newField.value.authors = [myEmail];
      }

      if (!newField.value.newAuthors) {
        newField.value.newAuthors = [];
      }

      if (!newField.value.presenter) {
        newField.value.presenter = myEmail;
      }

      if (!newField.value.submitter) {
        newField.value.submitter = myEmail;
      }

      newField.value = {...newField.value, order: [...newField.value.authors]};

      // ?
      changeValue({target: {value: newField.value}}, index);

      // GET authors
      getUserInfo(api.entities.user.getByEmailMultiple(null, {email: newField.value.authors.join(",")}));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (userData) {
      let obj = {};
      userData.forEach((user, i) => {
        obj[user.email] = user;
      });
      setAuthorsInfo(obj);
    }
  }, [userData]);

  const handleDeleteAuthors = (email) => {
    // New author
    if (field.value.newAuthors && field.value.newAuthors.length) {
      const ind = field.value.newAuthors.findIndex((a) => a === email);
      if (ind !== -1) handleDeleteNewAuthor(ind);
    }

    // Normal author
    const ind = field.value.authors.findIndex((a) => a === email);
    if (ind !== -1) handleDeleteAuthor(ind);

    // Remove from combined list order
    const orderInd = field.value.order.findIndex((a) => a === email);
    if (orderInd !== -1) {
      const newField = {...field};

      if (field.value.presenter === field.value.order[orderInd].email) {
        newField.value.presenter = field.value.order[0];
      }

      newField.value.order.splice(orderInd, 1);
      changeValue({target: {value: newField.value}}, index);
    }
  };

  const handleDeleteAuthor = (ind) => {
    const newField = {...field};

    if (field.value.presenter === field.value.authors[ind]) {
      newField.value.presenter = field.value.order[0];
    }

    newField.value.authors.splice(ind, 1);
    changeValue({target: {value: newField.value}}, index);
  };

  const handleDeleteNewAuthor = (ind) => {
    const newField = {...field};

    if (field.value.presenter === field.value.newAuthors[ind].email) {
      newField.value.presenter = field.value.order[0];
    }

    newField.value.newAuthors.splice(ind, 1);
    changeValue({target: {value: newField.value}}, index);
  };

  const onDrop = (acceptedFiles) => {
    const newField = {...field};
    // const fr = new FileReader()

    // fr.onload = () => {
    //   newField.value = { ...newField.value, file: fr.result}
    //   changeValue({target: {value : newField.value}},index)
    // }
    //
    // fr.readAsDataURL(acceptedFiles[0])

    newField.value = {file: acceptedFiles[0]};
    changeValue({target: {value: newField.value}}, index);
  };

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();

  const i = index;
  switch (field.type) {
    case FIELD_TYPES.LABEL: {
      return (
        <>
          <Typography variant={"h6"}>{field.label}</Typography>
        </>
      );
    }

    case FIELD_TYPES.TEXT: {
      return (
        <>
          <TextField required={field.required} variant="filled" label={field.label} fullWidth value={field.value} onChange={(e) => changeValue(e, i)} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }

    case FIELD_TYPES.SINGLE_SELECT: {
      return (
        <>
          <Select
            required={field.required}
            labelId={`select-label-${i}`}
            label={`${field.label} ${field.required ? "*" : ""}`}
            variant="filled"
            displayEmpty
            fullWidth
            value={field.value}
            onChange={(e) => changeValue(e, i)}>
            {field.options.map((option, i) => {
              return (
                <MenuItem value={option.id} key={i}>
                  {option.option}
                </MenuItem>
              );
            })}
          </Select>
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
          <InputLabel id={`select-label-${i}`} variant="filled">{`${field.label} ${field.required ? "*" : ""}`}</InputLabel>
        </>
      );
    }

    case FIELD_TYPES.MULTI_SELECT: {
      return (
        <>
          <Select
            required={field.required}
            multiple
            labelId={`multi-select-label-${i}`}
            label={`${field.label} ${field.required ? "*" : ""}`}
            variant="filled"
            displayEmpty
            fullWidth
            value={field.value ? field.value : []}
            onChange={(e) => changeValue(e, i)}>
            {field.options.map((option, i) => {
              return (
                <MenuItem value={option.id} key={option.id}>
                  {option.option}
                </MenuItem>
              );
            })}
          </Select>
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
          <InputLabel id={`multi-select-label-${i}`} variant="filled">{`${field.label} ${field.required ? "*" : ""}`}</InputLabel>
        </>
      );
    }

    case FIELD_TYPES.TEXT_AREA: {
      return (
        <>
          <TextField required={field.required} multiline rows={4} variant="filled" label={field.label} fullWidth value={field.value} onChange={(e) => changeValue(e, i)} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }

    case FIELD_TYPES.RICH_TEXT: {
      return (
        <>
          <Box fontSize={14} mb={1} mt={1}>
            {field.label}
            {field.required ? "*" : ""}
          </Box>
          <RichTextEditor className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v) => onEditorChange(v)} />
          {field.description && (
            <Box fontSize={11} ml={1} mb={1} mt={0}>
              {field.description}
            </Box>
          )}
        </>
      );
    }

    case FIELD_TYPES.BOOLEAN: {
      return (
        <>
          <FormControlLabel control={<Checkbox required={field.required} checked={field.value} onChange={(e) => changeValue({target: {value: !field.value}}, i)} />} label={field.label} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }

    case FIELD_TYPES.NUMBER: {
      return (
        <>
          <TextField required={field.required} variant="filled" label={field.label} fullWidth value={field.value} onChange={(e) => changeValue(e, i)} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }

    case FIELD_TYPES.THEMATIC_CODE: {
      return (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
              {field.required ? "*" : ""}{" "}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Box fontSize={14}>{language.selectedCodes}</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.box}>
                  {field.value &&
                    field.value.map((value, fi) => {
                      return <Chip key={fi} size="small" label={thematicLabels && thematicLabels[value]} clickable color="primary" className={classes.chip} onDelete={() => handleDeleteThematicCode(i, fi)} />;
                    })}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Grid item container spacing={2}>
              {thematicCodes
                .filter((b) => b.id === field.listId)
                .map((bucket, bi) => (
                  <>
                    <Grid item xs={12}>
                      <Box fontSize={14}>
                        {bucket.name} ({language.selectBetween} {field.min} {language.eos} {field.max} {language.choices})
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box className={classes.box}>
                        {bucket.codes.map((code, fi) => {
                          return (
                            <Chip
                              key={fi}
                              size="small"
                              label={code.label}
                              clickable
                              color="default"
                              className={classes.chip}
                              disabled={!!code.group}
                              onClick={() => (code.group ? false : handleAddThematicCode(code.id, bucket.id))}
                            />
                          );
                        })}
                      </Box>
                      {thematicCodesCount && thematicCodesCount[bucket.id] === field.max && (
                        <Box fontSize={12} color={"red"} ml={1} mt={1}>
                          {" "}
                          Reached max number of codes{" "}
                        </Box>
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
            <Grid item xs={12}>
              {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      );
    }
    case FIELD_TYPES.AUTHORS: {
      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }

        const newOrder = reorder(field.value.order, result.source.index, result.destination.index);

        const newField = {...field};
        newField.value = {...newField.value, order: newOrder};

        changeValue({target: {value: newField.value}}, index);
      };

      return (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
              {field.required ? "*" : ""}{" "}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Box fontSize={14}>{language.authors}</Box>
              </Grid>
              <Grid item xs={12}>
                <Box className={classes.box}>
                  {authorsInfo && (
                    <>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable" direction="horizontal">
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {field.value.order
                                .filter((a) => !!a)
                                .map((author, i) => {
                                  const user = authorsInfo[author] ? authorsInfo[author] : author;
                                  const key = user.firstName ? `${user.firstName}${user.lastName}` : author;
                                  return (
                                    <Draggable key={key} draggableId={key} index={i}>
                                      {(provided, snapshot) => (
                                        <div
                                          className={classes.draggableChip}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          // style={getItemStyle(
                                          //   snapshot.isDragging,
                                          //   provided.draggableProps.style
                                          // )}
                                        >
                                          <Chip
                                            key={i}
                                            size="small"
                                            label={`${user.title ? user.title : "-"} ${user.firstName} ${user.lastName}`}
                                            clickable
                                            color="primary"
                                            className={classes.chip}
                                            disabled={author === field.value.submitter}
                                            onDelete={() => handleDeleteAuthors(user.email)}
                                          />
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                })}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </>
                  )}
                  {/*{authorsInfo&&field.value&&field.value.authors&&authorsInfo&&field.value.authors.map((author,ind)=>{*/}
                  {/*  const user = authorsInfo[author]*/}
                  {/*  return <Chip*/}
                  {/*    key={i}*/}
                  {/*    size="small"*/}
                  {/*    label={`${user.title?user.title:'-'} ${user.firstName} ${user.lastName}`}*/}
                  {/*    clickable*/}
                  {/*    color="primary"*/}
                  {/*    className={classes.chip}*/}
                  {/*    disabled={author===field.value.submitter}*/}
                  {/*    onDelete={()=>handleDeleteAuthor(ind)}*/}
                  {/*  />*/}
                  {/*})}*/}
                  {/*{field.value.newAuthors&&field.value.newAuthors.map((author,ind)=>{*/}
                  {/*  return <Chip*/}
                  {/*    key={i}*/}
                  {/*    size="small"*/}
                  {/*    label={`${author.title?author.title:''} ${author.firstName} ${author.lastName}`}*/}
                  {/*    clickable*/}
                  {/*    color="primary"*/}
                  {/*    className={classes.chip}*/}
                  {/*    onDelete={()=>handleDeleteNewAuthor(ind)}*/}
                  {/*  />*/}
                  {/*})}*/}
                </Box>
              </Grid>

              <Grid item container justify="space-between">
                {authorsInfo && (
                  <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <Select
                        disabled={!field.value || !field.value.authors || !field.value.authors.length}
                        required={true}
                        labelId={`select-label-${i}`}
                        label={"Presenter"}
                        variant="filled"
                        displayEmpty
                        fullWidth
                        value={field.value.presenter}
                        onChange={(e) => {
                          changeValue({target: {value: {...field.value, presenter: e.target.value}}}, i);
                        }}>
                        {field.value &&
                          field.value.authors &&
                          field.value.authors.map((author, i) => {
                            return <MenuItem value={author} key={i}>{`${authorsInfo[author].title ? authorsInfo[author].title : "-"} ${authorsInfo[author].firstName} ${authorsInfo[author].lastName}`}</MenuItem>;
                          })}
                      </Select>
                      <InputLabel id={`select-label-${i}`} variant="filled">
                        {language.presenter}
                      </InputLabel>
                      {(!field.value || !field.value.authors || !field.value.authors.length) && <FormHelperText variant="filled"> {language.youNeedToAddAnAuthorFirst}</FormHelperText>}
                    </FormControl>
                  </Grid>
                )}
                <Grid item>
                  <Button size="smaller" onClick={() => setOpenUser(true)}>
                    {language.addAuthor}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {openUser && <FindUserDialog open={openUser} setOpenAssociate={setOpenUser} setUser={setUser} />}
          {user && user.found && <ProfileView user={user} setUser={setUser} open={user} associateUser={addAuthor} />}
          <Dialog
            onClose={() => {
              setUser(false);
              setOpenUser(false);
            }}
            open={user && !user.found}>
            <AuthorEditNew
              handleClose={() => {
                setOpenUser(false);
                setUser(false);
              }}
              email={user && user.email}
              addNewUser={addNewUser}
            />
          </Dialog>
        </Grid>
      );
    }

    case FIELD_TYPES.FILE: {
      return (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
              {field.required ? "*" : ""}{" "}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <RootRef rootRef={ref}>
              <Box {...rootProps} className={classes.dropBox}>
                <input type="file" {...getInputProps()} />
                <Box display={"flex"} flexDirection="column" justifyContent="center" alignItems="center" alignContent="center" height="130px">
                  {(field.value && field.value.file) || (field.value && field.value.name) ? (
                    <>
                      <Box display={"flex"} justifyContent="center" alignItems="center" alignContent="center">
                        <Box display={"flex"} mr={1}>
                          {" "}
                          <AttachFileIcon />{" "}
                        </Box>
                        <Box display={"flex"}> {field.value.name ? field.value.name : field.value.file.name} </Box>
                      </Box>
                    </>
                  ) : (
                    <Box display={"flex"} flexDirection={"column"} justifyContent="center" alignItems="center" alignContent="center">
                      <Box display={"flex"} mb={2}>
                        <IconUploadBorderDark />
                      </Box>
                      <Box display={"flex"}>{language.dragAndDropFile}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </RootRef>
          </Grid>
          <Grid item container spacing={2} justify="space-between">
            <Grid item>{field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}</Grid>
            <Grid item>
              <Grid item container spacing={2}>
                {field.value && field.value.name && (
                  <Grid item>
                    <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                      {language.downloadFile}
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button size="smaller" onClick={() => changeValue({target: {value: null}}, i)}>
                    {language.removeFile}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    case FIELD_TYPES.ABSTRACT: {
      return (
        <>
          <TextField required={field.required} multiline rows={4} variant="filled" label={field.label} fullWidth value={field.value} onChange={(e) => changeValue(e, i)} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }
    case FIELD_TYPES.TITLE: {
      return (
        <>
          <TextField required={field.required} variant="filled" label={field.label} fullWidth value={field.value} onChange={(e) => changeValue(e, i)} />
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
        </>
      );
    }
    case FIELD_TYPES.FILE_CAMERA_READY: {
      return (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
              {field.required ? "*" : ""}{" "}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <RootRef rootRef={ref}>
              <Box {...rootProps} className={classes.dropBox}>
                <input type="file" {...getInputProps()} />
                <Box display={"flex"} flexDirection="column" justifyContent="center" alignItems="center" alignContent="center" height="130px">
                  {(field.value && field.value.file) || (field.value && field.value.name) ? (
                    <>
                      <Box display={"flex"} justifyContent="center" alignItems="center" alignContent="center">
                        <Box display={"flex"} mr={1}>
                          {" "}
                          <AttachFileIcon />{" "}
                        </Box>
                        <Box display={"flex"}> {field.value.name ? field.value.name : field.value.file.name} </Box>
                      </Box>
                    </>
                  ) : (
                    <Box display={"flex"} flexDirection={"column"} justifyContent="center" alignItems="center" alignContent="center">
                      <Box display={"flex"} mb={2}>
                        <IconUploadBorderDark />
                      </Box>
                      <Box display={"flex"}>{language.dragAndDropFile}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </RootRef>
          </Grid>
          <Grid item container spacing={2} justify="space-between">
            <Grid item>{field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}</Grid>
            <Grid item>
              <Grid item container spacing={2}>
                {field.value && field.value.name && (
                  <Grid item>
                    <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                      {language.downloadFile}
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button size="smaller" onClick={() => changeValue({target: {value: null}}, i)}>
                    {language.removeFile}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    case FIELD_TYPES.COPYRIGHT: {
      return (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
              {field.required ? "*" : ""}{" "}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <RootRef rootRef={ref}>
              <Box {...rootProps} className={classes.dropBox}>
                <input type="file" {...getInputProps()} />
                <Box display={"flex"} flexDirection="column" justifyContent="center" alignItems="center" alignContent="center" height="130px">
                  {(field.value && field.value.file) || (field.value && field.value.name) ? (
                    <>
                      <Box display={"flex"} justifyContent="center" alignItems="center" alignContent="center">
                        <Box display={"flex"} mr={1}>
                          {" "}
                          <AttachFileIcon />{" "}
                        </Box>
                        <Box display={"flex"}> {field.value.name ? field.value.name : field.value.file.name} </Box>
                      </Box>
                    </>
                  ) : (
                    <Box display={"flex"} flexDirection={"column"} justifyContent="center" alignItems="center" alignContent="center">
                      <Box display={"flex"} mb={2}>
                        <IconUploadBorderDark />
                      </Box>
                      <Box display={"flex"}>{language.dragAndDropFile}</Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </RootRef>
          </Grid>
          <Grid item container spacing={2} justify="space-between">
            <Grid item>{field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}</Grid>
            <Grid item>
              <Grid item container spacing={2}>
                {field.value && field.value.name && (
                  <Grid item>
                    <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                      {language.downloadFile}
                    </Button>
                  </Grid>
                )}

                <Grid item>
                  <Button size="smaller" onClick={() => changeValue({target: {value: null}}, i)}>
                    {language.removeFile}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    case FIELD_TYPES.GRADE: {
      return (
        <>
          <Select
            required={field.required}
            labelId={`select-label-${i}`}
            label={`${field.label} ${field.required ? "*" : ""}`}
            variant="filled"
            displayEmpty
            fullWidth
            value={field.value}
            onChange={(e) => changeValue(e, i)}>
            {field.options.map((option, i) => {
              return (
                <MenuItem value={option.id} key={i}>
                  {option.option}
                </MenuItem>
              );
            })}
          </Select>
          {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
          <InputLabel id={`select-label-${i}`} variant="filled">{`${field.label} ${field.required ? "*" : ""}`}</InputLabel>
        </>
      );
    }

    default: {
      return <> </>;
    }
    // 1: 'Label',
    //   2: 'Text',
    //   3: 'Single select',
    //   4: 'Multi select',
    //   5: 'Text area',
    //   6: 'Rich text',
    //   7: 'Boolean',
    //   8: 'Number',
    //   9: 'Thematic code'
  }
});

const FormDropZone = withStyles(styles)(({classes, fields = [], setFields, valid, setValid, thematicCodes, darkMode = false, cameraReadyStatus}) => {
  // useEffect(()=>{
  //   if (fields.find((f)=>f.value)) {
  //     setValid&&setValid(true)
  //   }
  //   // eslint-disable-next-line
  // },[])

  // Handle change value
  const changeValue = (e, i) => {
    const value = e.target.value;
    const newFields = [...fields];
    newFields[i].value = newFields[i].type === FIELD_TYPES.NUMBER ? parseFloat(value) : value;
    newFields[i].error = false;

    // Required error
    if (newFields[i].required && value === "" && newFields[i].type !== FIELD_TYPES.BOOLEAN) {
      newFields[i].error = "This field is required";
    }

    // Number error
    if (newFields[i].type === FIELD_TYPES.NUMBER && isNaN(parseFloat(value))) {
      newFields[i].value = null;
      newFields[i].error = "This field should contain only numbers";
    }

    setFields(newFields);

    // Validate form

    // Check required
    //////////////////
    let valid = true;
    const field = fields.filter((f) => f.type !== FIELD_TYPES.BOOLEAN).find((f) => f.required && !f.value); // Find

    if (field) {
      valid = false;
    }

    // Check constraints
    //////////////////////

    // Thematic codes
    newFields
      .filter((f) => f.type === FIELD_TYPES.THEMATIC_CODE)
      .forEach((field) => {
        if (field.value && (field.value.length > field.max || field.value.length < field.min)) {
          valid = false;
        }

        // thematicCodes.forEach((bucket)=>{
        //   let count = 0
        //   bucket.codes.forEach((code=>{
        //     if (field.value&&field.value.find((c)=>c===code.id)) count++
        //   }))
        //   if ((count>field.max) || (count<field.min)) {
        //     valid = false
        //   }
        // })
      });

    // All errors handling
    newFields.forEach((field) => {
      if (field.error) valid = false;
    });

    if (setValid) {
      setValid(valid);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        {fields.map((field, i) => {
          return cameraReadyStatus === field.type ? (
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <FieldRenderer key={i} field={field} index={i} changeValue={changeValue} thematicCodes={thematicCodes} />
                <FieldError field={field} />
              </FormControl>
            </Grid>
          ) : (
            <Grid item xs={12} style={{display:"none"}}>
              <FormControl className={classes.formControl}>
                <FieldRenderer key={i} field={field} index={i} changeValue={changeValue} thematicCodes={thematicCodes} />
                <FieldError field={field} />
              </FormControl>
            </Grid>
          );
     

        })}
      </Grid>
    </>
  );
});

export default FormDropZone
