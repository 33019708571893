import React from 'react'
import {Field, Form, Formik} from 'formik'
import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { TextField} from 'formik-material-ui'
import Button from 'components/core/Button'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
  // country: Yup.string().required('This field is required'),
  // email: Yup.string().email('Invalid email address').required('This field is required'),
 password: Yup.string().min(8,'Password cannot be less than 8 characters').required('This field is required'),
 passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('This field is required')
})


const ProfilePasswordChange = ({classes,formData,setFormData,setFormSave,isSubmitting,profile,...props}) =>{
  return <Grid item md={4} xs={12}>
    <Formik
      initialValues={{
        email: '',
        password: '',
        ...formData, title: formData.title === null ? '-' : formData.title
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {
          ...formData, ...values,
          password: values.password === '' ? null : values.password
        }
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form>

          <Grid item container className={classes.row}>
            <Typography variant='h5' component='h1'>Change Password</Typography>
          </Grid>
          <Box mt={2}>
          <Grid item container className={classes.passwordRow} spacing={1}>
            <Grid item xs={12}>
              <Field type='password' name='password' component={TextField} label='Password' variant='filled'
                     fullWidth
                     disabled={isSubmitting} autocomplete='off' autoComplete='off'
                     inputProps={{autocomplete: 'off'}}/>
            </Grid>
            <Grid item xs={12}>
              <Field type='password' name='passwordConfirmation' component={TextField} label='Password Confirmation' variant='filled'
                     fullWidth
                     disabled={isSubmitting} autocomplete='off' autoComplete='off'
                     inputProps={{autocomplete: 'off'}}/>
            </Grid>
          </Grid>

          <Grid item container spacing={1} className={classes.rowLast} direction={'column'} justify='center'
                alignItems='center'
                alignContent='center'>
            <Grid item xs={12}>
              <Box mt={4}>
                <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
              </Box>
            </Grid>
          </Grid>
          </Box>
        </Form>
      )}
    </Formik>
  </Grid>
}

export default  ProfilePasswordChange
