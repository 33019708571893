import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {imageFile} from 'utils'
import IconButton from '@material-ui/core/IconButton'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    position: 'relative',
    maxWidth: 350
  },
  ParticipantToolTipView: {

  },
  userPhoto: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img' : {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    },    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px'
  },
  sessionBox: {
    minHeight: '60px',
    // backgroundColor: palette['pale-sky-blue'],
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown']
  },
  sessionTitle: {
    // color: palette['cerulean-blue'],
    fontWeight: 'bold'
  },
   sessionsBox: {
    paddingTop: theme.spacing(1),
   },
  sessionTimes: {
    fontSize: '14px'
  }
})

// const Session = withStyles(styles)(({classes, day, ordinal, startDate, endDate, ...restProps})=>{
//   return <Box className={classes.sessionBox} {...restProps}>
//      <Box className={classes.sessionTitle}>Session {ordinal} </Box>
//       <Box className={classes.sessionTimes}>
//         {format(parseISO(startDate), 'LLL do y')} <br/>
//         {format(parseISO(startDate), 'hh:mmaa')} - {format(parseISO(endDate), 'hh:mmaa')} <br/>
//       </Box>
//     </Box>
// })

const ParticipantToolTipView = withStyles(styles)(({classes, participant, ...props}) => {
  return <Box className={classes.ParticipantToolTipView} {...props}><ThemeProvider theme={theme}>
    <Box p={2} className={classes.container}>

          <Grid container spacing={1}>
            <Grid item xs={12} justify='center'>
              <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'}>
                <Box className={classes.userPhoto}>
                  <img src={participant.imageId?`${imageFile(participant.imageId)}`:participant.image?imageFile(participant.image):''} alt={`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`} />
                </Box>
                <Box display='flex' fontSize={18} fontWeight='bold' p={2} textAlign='center'>
                  {`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`}
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box fontSize={14}>Position</Box>
              <Box fontSize={16} fontWeight={'bold'}> {participant.profession?participant.profession:'-'} </Box>
            </Grid>

            <Grid item xs={12}>
              <Box fontSize={14}>Affiliation / Company</Box>
              <Box fontSize={16} fontWeight={'bold'}> {participant.instituteOrCompany?participant.instituteOrCompany:'-'} </Box>
            </Grid>

            {participant.linkedinUrl&&<Grid item xs={12}>
              {/*<Box fontSize={14}>LinkedIn Profile</Box>*/}
              {/*<Box fontSize={16} fontWeight={'bold'}> {participant.linkedinUrl} </Box>*/}
              <Box ml={-2}><IconButton href={participant.linkedinUrl} target='_blank'><LinkedInIcon /></IconButton></Box>
            </Grid>}

          </Grid>

        {/*<Grid item container className={classes.sessionsBox} spacing={1}>*/}
        {/*      {participant.sessions.map((session,i)=>{*/}
        {/*        return <Grid item xs={12}><Session day={session.day} ordinal={session.ordinal} startDate={session.startDate} endDate={session.endDate} key={i}/></Grid>*/}
        {/*      })}*/}
        {/*</Grid>*/}

      </Box>


  </ThemeProvider>
    </Box>
 })

      export default ParticipantToolTipView
