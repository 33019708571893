import { withStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import React, {useEffect, useState} from 'react';
import {
  IconCalendarBorderDark,
  IconMapPinBorderDark
} from "components/core/icons";
import { format } from "date-fns";
import { palette } from "theme";
import parseISO from "date-fns/parseISO";
import Button from "components/core/Button";
import {useAuth} from 'auth/AuthController'
import LoginSignupDialog from 'components/core/LoginSignupDialog'
import { isPast } from 'date-fns'
import AddToCalendarButton from 'components/core/AddToCalendarButton'
import differenceInHours from 'date-fns/differenceInHours'
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";

const styles = (theme) => ({
  info: {
    backgroundColor: theme.palette.primary.light,
    color: palette["greyish-brown"],
    fontWeight: "bold",
    borderRadius: "12px",
  },
});

const ListItem = withStyles(styles)(({ classes, icon, text }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexBasis={"100%"}
      width="100%"
      mb={2}
      alignItems="center"
      justifyContent="flex-start"
      className={classes.listRow}
    >
      <Box display="flex">{icon}&nbsp;&nbsp;</Box>
      <Box display="flex">{text}</Box>
    </Box>
  );
});

const EventInfo = withStyles(styles)(
  ({
    title,
    value,
    event,
    classes,
    isLeaving,
    isLoading,
    isJoining,
    leaveEvent,
    joinEvent,
    hideJoin
  }) => {
    const [isAuthenticated] = useAuth()
    const [eventJoin, setEventJoin] = useState()
    const [openLoginSignup, setOpenLoginSignup] = useState()

    const showLoginSignup = () => {
      setEventJoin(false)
      setOpenLoginSignup(true)
    }

    const handleClose = () => {
      setOpenLoginSignup(false)
      setEventJoin(true)
    }

    useEffect(()=>{
      if (isAuthenticated&&eventJoin) {
        return joinEvent()
      }
      //eslint-disable-next-line
    },[isAuthenticated, eventJoin])

    const eventIsOver = isPast(parseISO(event.eventInfo.endDate))

    const duration = differenceInHours(parseISO(event.eventInfo.startDate),parseISO(event.eventInfo.endDate))
    const eventInfo = {
        description: event.description,
        duration,
        endDatetime: format(parseISO(event.eventInfo.endDate),"yyyyMMdd'T'HHmmss"),
        location: event.venueDetails,
        startDatetime: format(parseISO(event.eventInfo.startDate),"yyyyMMdd'T'HHmmss"),
        title: event.eventInfo.name,
    }
    const dateLocale = [{ locale: enUS }, { locale: enUS }, { locale: el }, { locale: de }, { locale: fr }, { locale: it }];
    
    return (
      <>
        {openLoginSignup&&<LoginSignupDialog open={openLoginSignup} onClose={handleClose}/>}
        <Box
          display="flex"
          className={classes.info}
          p={palette.infoPadding}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          m={palette.infoPadding}
        >
          <ListItem
            icon={<IconCalendarBorderDark />}
            text={<>

              {format(
                parseISO(event.eventInfo.startDate),
                "d LLL", dateLocale[event.eventInfo.eventLanguage]
              )} - {format(
                parseISO(event.eventInfo.endDate),
              "d LLL", dateLocale[event.eventInfo.eventLanguage]
              )}

            </>}
          />
          <ListItem
            icon={<IconMapPinBorderDark />}
            text={event.online?'Online only':event.eventInfo.venueName}
          />
          {!hideJoin&&<Box display="flex">
            {(event.eventInfo.joined && (!event.eventInfo.registered)) && (
              <Button
                disabled={isLeaving || isLoading}
                size="smaller"
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => {
                  leaveEvent()
                }}
              >
                {language.leaveTheEvent}
              </Button>
            )}
            {!event.eventInfo.joined && !eventIsOver && <>

              {isAuthenticated&&<Button
                disabled={isJoining || isLoading}
                size="smaller"
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => {
                  joinEvent()
                }}
              >
                {language.joinTheEvent}
              </Button>}

              {!isAuthenticated&&<Button
                disabled={isJoining || isLoading}
                size="smaller"
                fullWidth
                color="primary"
                variant="outlined"
                onClick={() => {
                  showLoginSignup()
                }}
              >
                 {language.joinTheEvent}
              </Button>}
            </>}
          </Box>}
        </Box>
        <Box
          display="flex"
          p={palette.infoPadding}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"                        
        >
          <AddToCalendarButton withIcon event={eventInfo} />    
        </Box>
      </>
    );
  }
);

export default EventInfo;
