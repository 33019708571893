import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import BlockText from 'components/core/BlockText'
import Divider from '@material-ui/core/Divider'
import {DUMMY_CHART_DATA} from 'config'
import {Bar, Pie} from 'react-chartjs-2'
import {useHttp} from 'api/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import EllipsisText from 'react-ellipsis-text'

const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    backgroundColor: 'white',
    // color: 'white',
    borderRadius: '6px'
  },
  previewFeedback: {
    maxWidth: '640px',
    transition: 'all 0.5s'
  },
  row: {
    // marginBottom: theme.spacing(2)
  },
  contentBox: {

  },
  previewBox: {
    position: 'relative',
  backgroundColor: 'white',
  width: '100%',
  minHeight: '250px',
  borderRadius: '8px',
  boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
    border: `1px solid ${palette['brown-grey']}`
},
  divider: {
    backgroundColor: palette['greyish-brown'],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  noResponses: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    fontWeight: 'bold',
    fontSize: '16px',
    textShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',

  }
})

const transformDataToChart = (question) => {
  let answers = question.options.reduce((a,b)=>a+b.total,0)

  let chartData = question.chartType===1?question.options.map((o, i) =>o.total):question.options.map((o, i) => ((o.total * question.options.length)))
  let labelData = question.options.map((o, i) =>o.option)

  if (answers===0) {
    labelData=['Option 1','Option 2','Option 3']
    chartData=DUMMY_CHART_DATA[question.chartType]
  }

  return {
    labels: labelData,
    datasets: [{
      barPercentage: 0.5,
      // barThickness: 6,
      maxBarThickness: 80,
      minBarLength: 2,
      data: chartData,
      backgroundColor: palette.graphColors
    }]
  }

}

const Preview = withStyles(styles)(({classes, question, noData})=>{
  const [stats, setStats] = useState()
  const [legend, setLegend] = useState()
  const pie = useRef()
  const bar = useRef()

  useEffect(()=>{
    setStats(transformDataToChart(question))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (stats&&pie.current) {
      // pie.current.chartInstance.generateLegend()
      // console.log('generate')
      setLegend(pie.current.chartInstance.legend.legendItems)
    }
  },[stats,pie])

  return  <Box display='flex' className={classes.previewBox}>
    <Box display='flex'>
      {noData&&<Box className={classes.noResponses}> No responses </Box>}
      {stats&&question.chartType===2&&
      <Pie
        ref={pie}
        data={stats}
        options={{
          layout: {
            padding: {
              left:0,
              right: 0,
              top: 0,
              bottom: 0
            }
          },
          legend:{display:false},
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                // console.log(data)
                // console.log(tooltipItem)
                return `${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}%`;
              }
            }
          }
        }}
      />}
      {stats&&question.chartType===1&&<Bar
        ref={bar}
        data={stats}
        // height={'98%'}
        options={
          {
            legend:{display:false},
            scales:{
              xAxes:[{display:false,gridLines:{display:false}}],
              yAxes:[{display:false, gridLines:{display:false}}]
            },
            tooltips: {
              callbacks: {
                title: function(tooltipItem, data) {
                  return ''
                },
                label: function(tooltipItem, data) {
                  // console.log(data)
                  // console.log(tooltipItem)
                  return `${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]}`;
                }
              }
            }
          }
        }
      />}
    </Box>
      {/*fillStyle: "#0078ad"*/}
      {/*index: 0*/}
      {/*lineWidth: 2*/}
      {/*strokeStyle: "#fff"*/}
      {/*text: "legend text here"*/}

    {stats&&question.chartType===2&&<Grid container spacing={2} direction='column'>
        {legend&&legend.map((label)=>{
          return <Grid item container alignContent='center'>
            <Grid item>
              <Box width={15} height={15} style={{backgroundColor:label.fillStyle}} mr={1}> &nbsp; </Box>
            </Grid>
            <Grid item>
              <Box fontSize={14}>
                {label.text&&<EllipsisText text={label.text} length={33} />}
              </Box>
            </Grid>
          </Grid>
        })}
      </Grid>}

    {stats&&question.chartType===1&&<Grid container spacing={2} direction='column'>
      {stats.labels.map((label,i)=>{
        return <Grid item container alignContent='center'>
          <Grid item>
            <Box width={15} height={15} style={{backgroundColor:palette.graphColors[i]}} mr={1}> &nbsp; </Box>
          </Grid>
          <Grid item>
            <Box fontSize={14}>
              {label&&<EllipsisText text={label} length={33} />}
            </Box>
          </Grid>
        </Grid>
      })}
    </Grid>}

    {/*</Box>*/}
  </Box>
})

const ResultsDialog = withStyles(styles)(({classes, feedbackCode, handleClose, ...props}) => {
  const [feedback, setFeedback] = useState()
  const [getData, data, isLoading, hasError] = useHttp()

  useEffect(()=>{
    getData(api.entities.feedback.getAllAnswers(null,null,{code:feedbackCode}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    setFeedback(data)
  },[data])

  useEffect(()=>{
    if (hasError) {
      handleClose()
    }
    //eslint-disable-next-line
  },[hasError])

  if (!feedback||isLoading) {
    return <Box className={classes.previewFeedback}>
      <Box display='flex' flexDirection='column'>
        <Box className={classes.container}>
          <CircularProgress size={'8rem'} color='secondary' />
        </Box>
      </Box>
    </Box>
  }

  return <Box className={classes.previewFeedback}>

    <Box display='flex' flexDirection='column'>

      <Box className={classes.container}>

        <Grid container spacing={1} className={classes.row}>
          <Box pb={1}>
            <Typography variant='h5' component='h1'> Feedback Results </Typography>
          </Box>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={12} className={classes.row}>
            <BlockText title={'Title'} value={feedback.title} withBorder />
          </Grid>

          <Grid item xs={12}>
            <Box pt={1} pb={1} fontWeight='bold'>
              Questions
            </Box>
          </Grid>

          {feedback.questions.map((question,i)=>{
            return <React.Fragment key={i}>
              <Grid item xs={12} className={classes.row}>
                <BlockText title={`Question ${i+1}.`} value={`${question.question}`} withBorder />
                <Box pt={1}>
                  <Typography variant='body1' component='h5'>{`${question.options.reduce((a,b)=>a+b.total,0)} Responses`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <Preview question={question} noData={question.options.reduce((a,b)=>a+b.total,0)===0}/>
              </Grid>
                {i+1!==feedback.questions.length&&<Grid item xs={12}>
                <Divider classes={{root:classes.divider}} />
              </Grid>}
            </React.Fragment>
          })}

        </Grid>

      </Box>
    </Box>
  </Box>
})

export default ResultsDialog
