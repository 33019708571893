import {withStyles} from '@material-ui/core/styles'
import {useLocation, useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useState} from 'react'
import {Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import UploadExcel from 'components/core/UploadExcel'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IconButton from '@material-ui/core/IconButton'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
// import InfoIcon from '@material-ui/icons/Info'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import Dialog from '@material-ui/core/Dialog'
import ReviewersForm from 'components/pages/ReviewersForm'
import Chip from '@material-ui/core/Chip'

const styles = theme => ({
  reviewersContainer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: 'calc(100vw - 300px)'
  },
  noReviewersYet: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '145px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  fieldTitle: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  addBtn: {
    textAlign: 'right'
  },
  navTabs: {
    position: 'relative',
    marginBottom: theme.spacing(3)
  },
  downloadBtn: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(3)
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3)
  },
  totalStats: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2,2,4,2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold'
  },
})
const tableIcons = {
  Add: forwardRef((props, ref) => <><AddBox {...props} ref={ref} /> <span style={{ color: "#000", fontSize: 13,marginLeft: 5}}>Add Reviewer</span></>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Reviewers = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {

  // Hooks
  const [getData, data, dataLoading] = useHttp()
  const [getExcel, dataExcel] = useHttp()
  // eslint-disable-next-line
  const [setPcm, pcmData, pcmLoading, pcmError] = useHttp()
  // eslint-disable-next-line
  const [delPcm, delPcmData, delPcmLoading, delPcmError] = useHttp()
  // eslint-disable-next-line
  const [setAccepted, acceptedData, acceptedLoading, acceptedError] = useHttp()
  // eslint-disable-next-line
  const [delAccepted, delAcceptedData, delAcceptedLoading, delAcceptedError] = useHttp()
  // eslint-disable-next-line
  const [sendRequest, putData, isLoading, hasError] = useHttp()

  // State
  const [reviewers, setReviewers] = useState()
  const [CsvData, setCsvData] = useState()
  const [formPreview, setFormPreview] = useState()

  // Deps
  const {eventId} = useParams()
  const q = useQuery()
  const filter = q.get('filter')

  const [defaultFilter] = useState(filter?[filter]:[])

  useEffect(() => {
    getData(api.entities.manage.getReviewers(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [delPcmData,pcmData])

  useEffect(() => {
    if (putData) {
      getData(api.entities.manage.getReviewers(null, null, {id: eventId}))
    }
    // eslint-disable-next-line
  }, [putData])

  const refresh = () => {
    getData(api.entities.manage.getReviewers(null, null, {id: eventId}))
  }

  useEffect(() => {
    if (data) {
      let reviewersTransformed = []
      data.reviewers.forEach((r)=>{
        let accepted = 0 // pending
        if (r.accepted === true) {
          accepted = 1
        }
        if (r.accepted === false) {
          accepted = 2
        }
        reviewersTransformed.push({
          ...r,
          acceptEnum: accepted
        })
      })
      setReviewers(reviewersTransformed)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  const downloadReviewers = () => {
    getExcel(api.entities.manage.downloadReviewers(null, null, {id: eventId}))
  }

  const handleChangePcm = (item) => {
    let items = [...reviewers]
    let itemIndex = items.findIndex((e)=>e.id===item.id)

    items[itemIndex].pcm = !!item.pcm
    setReviewers([...items])

    if (item.pcm) {
      delPcm(api.entities.manage.delPcm(null, null, {id: item.id}))
    } else {
      setPcm(api.entities.manage.setPcm(null, null, {id: item.id}))
    }
  }

  const handleChangeAcceptance = (e, id) => {
    let items = [...reviewers]
    let itemIndex = items.findIndex((e)=>e.id===id)
    const value = e.target.value

    items[itemIndex].acceptedByManager = value
    items[itemIndex].accepted = value
    items[itemIndex].acceptEnum = value?3:4
    setReviewers([...items])

    if (!value) {
      delAccepted(api.entities.manage.delAccepted(null, null, {id: id}))
    } else {
      setAccepted(api.entities.manage.setAccepted(null, null, {id: id}))
    }

  }

  useEffect(() => {
    if (dataExcel) {
      setCsvData(dataExcel)
    }
  }, [dataExcel])

  useEffect(() => {
    if (CsvData) {
      let downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(CsvData[0])
      downloadLink.download = `${data.eventInfo.name} - reviewers.xlsx`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
  }, [CsvData, data, dataExcel])

  const handleCloseFormPreview = () => {
    setFormPreview(false)
  }

  if (!reviewers) return <> </>

  const totalNum = reviewers.length
  const acceptedNum = reviewers.filter((r)=>r.acceptEnum===1).length
  const rejectedNum = reviewers.filter((r)=>r.acceptEnum===2).length
  const pendingNum = reviewers.filter((r)=>r.acceptEnum===0).length

  return <>
    <Fade in={true} timeout={1200}>
      <>

        {formPreview&&<Dialog onClose={handleCloseFormPreview} open={!!formPreview} fullWidth maxWidth='md'>
          <ReviewersForm handleClose={handleCloseFormPreview} formPreview={formPreview} eventId={eventId}/>
        </Dialog>}

        <Grid container spacing={2} className={classes.reviewersContainer}>

        <Grid item xs={12} sm={12} md={4}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Reviewers
          </Typography>
        </Grid>

          <Grid item xs>
            <Grid item container justify='flex-end' spacing={2}>
              <Grid item>
                <Chip label={`Total ${totalNum}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Accepted ${acceptedNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Rejected ${rejectedNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Pending ${pendingNum}`} variant='outlined'/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button size='smaller' color='primary' onClick={() => {
              downloadReviewers()
            }}> Download Excel file </Button>
          </Grid>

          <Grid item>
            {/*<Button size='smaller' onClick={() => {*/}
            {/*  uploadExcel()*/}
            {/*}}> Upload Excel file </Button>*/}
            <UploadExcel eventId={eventId} refresh={refresh}/>
          </Grid>

        {/*<Grid item xs={12}>*/}

        {/*    <Grid container item className={classes.navTabs}>*/}
        {/*      <Grid item xs={12} sm={12}>*/}

        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*    /!*<Box className={classes.downloadBtn} >*!/*/}
        {/*    /!*  <Button size='smaller'  upgradeTier={!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)} onClick={() => {*!/*/}
        {/*    /!*    if (!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)) return false*!/*/}
        {/*    /!*    downloadReviewers()*!/*/}
        {/*    /!*  }}> Download Excel </Button>*!/*/}
        {/*    /!*</Box>*!/*/}
        {/*</Grid>*/}

        {reviewers&&<Grid item xs={12}>
          <MaterialTable
            key={reviewers}
            icons={tableIcons}
            columns={[
              { title: 'Title', field: 'title', editable: isLoading?'never':'always'},
              { title: 'First name', field: 'firstName' , editable: isLoading?'never':'always'},
              { title: 'Last name', field: 'lastName' , editable: isLoading?'never':'always'},
              { title: 'Email', field: 'email', editable: isLoading?'never':'always'},
              { title: 'Position', field: 'profession', editable: isLoading?'never':'always'},
              { title: 'Country code', field: 'countryCode', editable: isLoading?'never':'always'},
              { title: 'Affiliation / Company', field: 'institute', editable: isLoading?'never':'always'},
              { title: 'LinkedIn', field: 'linkedinUrl', editable: isLoading?'never':'always', render: rowData => <IconButton aria-label="linked in profile" href={rowData.linkedinUrl} target={'_blank'}> <LinkedInIcon /> </IconButton>},

              { title: 'Accepted', field: 'acceptEnum', type: 'boolean', editable: 'never',
                lookup: { 0: 'Pending', 1: 'Accepted', 2: 'Rejected'}, render: rowData => {

                  let color = 'inherit'
                  let message = ''

                  if (rowData.accepted === false) {
                    color = 'red'
                    message = 'Rejected by reviewer'
                    if (rowData.acceptedByManager) {
                      message = 'Rejected by manager'
                    }
                  }
                  if (rowData.accepted === true) {
                    color = 'green'
                    message = 'Accepted by reviewer'
                    if (rowData.acceptedByManager) {
                      color = 'blue'
                      message = 'Accepted by manager'
                    }
                  }
                return <>
                    <Grid container>
                      <Grid item xs={11}>
                        <FormControl className={classes.formControl}>
                          <Select type='select' name='accepted' fullWidth disabled={isLoading} value={rowData.accepted===null?'-':rowData.accepted} onChange={(e)=>handleChangeAcceptance(e,rowData.id)}>
                            <MenuItem value={'-'} key={null} disabled>Pending</MenuItem>
                            <MenuItem value={true} key={true}>Accepted</MenuItem>
                            <MenuItem value={false} key={false}>Rejected</MenuItem>
                          </Select>
                          {/*{rowData.acceptedByManager&&<FormHelperText> set by manager </FormHelperText>}*/}
                        </FormControl>
                      </Grid>
                      <Grid item xs={1}>
                        <Box mt={1} ml={1} fontSize={'1.2em'} color={color}>
                          <Tooltip title={message} aria-label={message}>
                            <FiberManualRecordIcon fontSize={'inherit'} />
                          </Tooltip>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                },defaultFilter: defaultFilter},
              { title: 'PCM', field: 'pcm', type: 'boolean', editable: isLoading?'never':'always', render: rowData => <>
                  <Checkbox
                    disabled={delPcmLoading||pcmLoading||isLoading||dataLoading}
                    checked={rowData.pcm}
                    onChange={()=>handleChangePcm(rowData)}
                    inputProps={{ 'aria-label': 'Change PCM' }}
                  />
                </>},
            ]}
            data={reviewers}
            title=''
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setReviewers([...reviewers, newData ])
                  const {valid, accepted, acceptedByManager, ...payLoad} = newData
                  sendRequest(api.entities.manage.addReviewer({...payLoad, eventId}))
                  resolve()
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...reviewers]
                  const index = oldData.tableData.id
                  dataUpdate[index] = {...newData}
                  setReviewers([...dataUpdate])
                  const {valid, accepted, acceptedByManager, ...payLoad} = newData
                  sendRequest(api.entities.manage.updateReviewer(payLoad,null,{id:oldData.id}))
                  resolve()
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  const dataDelete = [...reviewers]
                  const index = oldData.tableData.id
                  dataDelete.splice(index, 1)
                  setReviewers([...dataDelete])
                  sendRequest(api.entities.manage.deleteReviewer(null,null,{id:oldData.id}))
                  resolve()
                }),
            }}
            actions={[
              {
                icon: CallToActionIcon,
                tooltip: 'View form',
                onClick: (event, rowData) => setFormPreview(rowData)
              },
            ]}
            options={{
              filtering: true,
              actionsColumnIndex:999,
              addRowPosition: 'first',
              headerStyle: {
                backgroundColor: palette['brown-grey'],
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: 14
              },
              rowStyle: {
                fontSize: 12,
                padding: 0
              }
            }}
          />
        </Grid>}

        {/*{(reviewers&&reviewers.length===0)&&<Grid item container className={classes.noReviewersYet}>*/}
        {/*  <Grid item>No reviewers yet!</Grid>*/}
        {/*</Grid>}*/}

      </Grid>

        {/*<Grid container spacing={2} className={classes.totalStats}>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Typography component='h2' variant='h5' className={classes.title} align='left'>*/}
        {/*      Totals*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item container>*/}
        {/*    <Grid item xs> Reviewers </Grid>*/}
        {/*    <Grid item xs> Tickets </Grid>*/}
        {/*    <Grid item xs> Amount </Grid>*/}
        {/*  </Grid>*/}
        {/*  <Grid item container>*/}
        {/*    <Grid item xs>*/}
        {/*      <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalReviewers} </Typography>*/}
        {/*      </Grid>*/}
        {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalTickets} </Typography></Grid>*/}
        {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>${totals.ticketsValue} </Typography></Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
    </>
    </Fade>
  </>
})

export default withRouter(Reviewers)
