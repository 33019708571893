import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import Map from 'components/core/Map'
import language from "components/language";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '6px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    '& a': {
      color: palette.aquamarine
    }
  },
  ParticipantCardView: {
    transition: 'opacity 0.7s'
  },
  logo: {
    display: 'flex',
    width: '80px',
    height: '80px',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  fieldTitle: {
    fontSize: '14px',
  },
  fieldValue: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  sessionTitle: {
    fontWeight: 'bold'
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  summary: {
    marginTop: -theme.spacing(2)
  },
})

const HotelCardView = withStyles(styles)(({classes, hotel, event, categories, index, plain, ...props}) => {
  
  const typosHotel = (type) =>
  {
    let typos = "";
    switch (type) {
      case "Hotel":
        typos = language.hotel;
        break;
      case "Apartment":
        typos = language.apartment;
        break;
      case "Hostel":
        typos = language.hostel;
        break;
      case "B&B":
        typos = language.bnb;
        break;
      default:
        typos = "";
    }
    return typos;
  };
  return (
    <Box {...props} key={index}>
      <Box p={2} className={clsx(classes.container, plain && classes.plain)}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={7} md={9}>
            <Box fontSize={20} fontWeight={"bold"} pb={1}>
              {hotel.name}
            </Box>
            <Box fontSize={14} pb={2}>
              {typosHotel(categories.find((category) => category.id === hotel.categoryId).category)}
            </Box>
            <Box>
              {hotel.address} <br />
              {hotel.city} <br />
              {hotel.zipCode} <br />
              {hotel.country}
            </Box>
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
            <Map width="100%" height="180px" latLng={{lat: hotel.lat, lng: hotel.lon}} name={hotel.name} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
      })

      export default HotelCardView
