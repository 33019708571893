import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {palette} from "theme";
import {withRouter} from "react-router-dom";
import {useMetaData} from "components/core/MetaProvider";
import Button from "@material-ui/core/Button";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import EventsIcon from "assets/Events_icon.png";
import FlexibleIcon from "assets/Flexible_icon.png";
import MetricsIcon from "assets/Metrics_icon.png";
import SupportIcon from "assets/Support_icon.png";
import BottomHero from "components/core/BottomHero";

const styles = (theme) => ({
	hero: {
		minWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
			height: "calc(100vh - 64px)",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		color: "#000",
		fontSize: "45px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "-2px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#3d4d59",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		letterSpacing: "0.38px",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	subtitle: {
		width: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "22px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "16px",
		fontFamily: "Karla",
		letterSpacing: "-1.05px",
		textTransform: "capitalize",
		lineHeight: "16px",
		zIndex: "1",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		letterSpacing: "-0.75px",
		textTransform: "capitalize",
		lineHeight: "14px",
	},
	testimonialsText: {
		width: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "22px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
	},
	pageContent: {
		marginTop: "100px",
	},
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		textAlign: "center",
		minHeight: "200px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "0px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
		color: "#000",
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	textField: {
		display: "block",
		width: "100%",
		margin: "35px 0px 25px 0px",
		padding: "22px 0px",
		borderRadius: "5px",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
		border: "none",
		textIndent: "20px",
		"&:focus-visible": {
			outline: "0px",
		},
	},
	inputSubTag: {
		margin: "16px 10px 40px 10px",
		fontFamily: "Karla",
		fontSize: "14px",
		fontWeight: "normal",
		letterSpacing: "-0.7px",
		color: "#000",
	},
	buttonNext: {
		float: "right",
		minWidth: "170px",
		height: "50px",
	},
});

const Demo = withStyles(styles)(({classes, history}) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({title: "Demo Page"});
		// eslint-disable-next-line
	}, []);

	const [formInput, setFormInput] = useState({
		email: "",
	});

	const handleSubmit = (e) => {
		e.preventDefault();

		let data = {formInput};

		fetch("api/form", {
			method: "POST",
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((response) => console.log("Success:", JSON.stringify(response)))
			.catch((error) => console.error("Error:", error));
	};

	const handleInput = (e) => {
		const name = e.target.name;
		const newValue = e.target.value;
		setFormInput({[name]: newValue});
	};

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>Watch a 7-minute demo of Anymeets</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{maxWidth: "614px", display: "inline-block"}}
				>
					<form onSubmit={handleSubmit}>
						<input
							label="email"
							id="email"
							name="email"
							defaultValue={formInput.name}
							className={classes.textField}
							helperText="e.g. name@gmail.com"
							onChange={handleInput}
							placeholder="Business email"
						/>
						<p className={classes.inputSubTag}>
							By clicking "Next", I agree to receive content and marketing
							emails from Anymeets.
						</p>
						<Button
							type="submit"
							variant="contained"
							align="right"
							size="large"
							color="primary"
							className={classes.buttonNext}
						>
							Next
						</Button>
					</form>
				</Grid>
			</Grid>

			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.secondaryTitle}
					style={{color: "#3d4d59"}}
				>
					<Grid item>Why Anymeets</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{width: "768px"}}
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={FlexibleIcon} alt="Anymeets live event stream " />

								<h3 className={classes.iconTag}>Flexible site</h3>
								<p>Different colors and layouts</p>
								<p>All information in one place</p>
								<p>Interactive conference program</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={MetricsIcon} alt="Anymeets events metrics" />
								<h3 className={classes.iconTag}>Post-event metrics</h3>
								<p>Suggestions for future events</p>
								<p>Which sessions were the most popular?</p>
								<p>What did attendees like the most?</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={EventsIcon} alt="Anymeets Flexible site layouts" />
								<h3 className={classes.iconTag}>Online events</h3>
								<p>Live stream or record your sessions</p>
								<p>Attract a global audience</p>
								<p>Increase your participants</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<img src={SupportIcon} alt="Anymeets support" />
								<h3 className={classes.iconTag}>24/7 support</h3>
								<p>
									Our highly skilled team is available round-the-clock to help
									you with your conference!
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter(Demo);
