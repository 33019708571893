import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
// import api from 'api'
import {withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
const styles = theme => ({

})

const Notifications = withStyles(styles)(({classes, history}) => {

  const [getNotifications, notificationsData, isLoading] = useHttp()
  const [notifications, setNotifications] = useState({content:[]})

  useEffect(()=>{
    if (!isLoading&&!notificationsData) {
      // getNotifications(api.entities.notifications.manage(null,{}))
    }
  },[notifications, notificationsData, getNotifications, isLoading])

  useEffect(()=>{
    if (notificationsData) {
      setNotifications(notificationsData)
    }
  },[notificationsData])

  return <Page title='Notifications' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Notifications' navigation={{routes:[]}} >
      Notifications
    </Adrawer>
  </Page>
})

export default withRouter(Notifications)
