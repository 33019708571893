import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {IconUserPicAddLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {FILES_URL, SIZE_LENGTH_PARTICIPANT_SUMMARY, SIZE_ROOM_IMAGE} from 'config'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import api from 'api'
import {useHttp} from 'api/core'
import CropImage from 'components/core/CropImage'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newRoom: {
    // maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    // width: '320px',
    // height: '240px',
    borderRadius: theme.spacing(1),
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      display: 'flex',
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required')
})

const UploadRoomImage = withStyles(styles)(({setFormData, formData, values, room, size}) => {
  const [cropImage, setCropImage] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width/2) && (img.height < size.height/2)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }

    fr.readAsDataURL(acceptedFiles[0])
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const setImage = (image) => {
    setFormData({...formData, image: image})
    setCropImage(false)
  }

  return <RootRef rootRef={ref}>
    {cropImage&&<CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage} setOpen={setCropImage} setImage={setImage} />}
    <Box {...rootProps} display='flex' flexDirection='column'>
      <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
      <Button size='smaller' variant='outlined' colour='white'>{room ? 'Change' : 'Select'} Image</Button>
      {!room && <Box display='flex' mt={2} mb={2}> Image should be at least 800x600px </Box>}
    </Box>
  </RootRef>
})

const RoomImage = withStyles(styles)(({classes, formData, values}) => {

  return <>
    {formData.hasOwnProperty('image') && formData.image !== null ? <Box className={classes.userPhoto}><img
      src={(typeof formData.image.name == 'string') ? URL.createObjectURL(formData.image) : `${FILES_URL}/${formData.image}`}
      alt={``}/></Box> : !formData.image ? <Box pb={4}><IconUserPicAddLight/></Box> : ''}
  </>
})

const RoomEditNew = withStyles(styles)(({classes, room, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData, setFormData] = useState({...room, image: room?room.imageId:null})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(()=>{
    setIsSubmitting(isLoading&&!hasError)
  },[isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {
      let formDataObj = new FormData();

      const {image, ...formDataItems} = formSave

      if (image) {
        formDataObj.append('image', image)
      }

      const payLoad = {...formDataItems, eventId: eventId, title: formDataItems.title==='-'?null:formDataItems.title}

      const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'});
      formDataObj.append('room', blob)

      // If existing room
      if (room) {
        sendRequest(api.entities.rooms.update(formDataObj, null, {id:room.id}, true))
      } else { // else add new room
        sendRequest(api.entities.rooms.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, room])


  return <Box className={classes.newRoom}><ThemeProvider theme={theme}>
    <Formik
      initialValues={{
        name: '',
        description: '', ...formData
        }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, image:formData.image}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>

            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {room ? 'Room Information' : 'New Room'}</Typography>
              </Grid>

              <Grid container spacing={2} className={classes.row}>

                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Floor plan </Typography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <Box display='flex' alignSelf='center' flexDirection='column' alignItems='center' justifyContent='center'>
                    <Box display='flex' mb={2}>
                      <RoomImage formData={formData} values={values}/>
                    </Box>
                    <Box display='flex' mb={2}>
                      <UploadRoomImage setFormData={setFormData} setFieldValue={setFieldValue} values={values}
                                       room={room} size={SIZE_ROOM_IMAGE}/>

                    </Box>
                  </Box>

                </Grid>

                <Grid item xs={12} sm={7}>
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <Field type='text' name='name' component={TextField} label='Name' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Field type='text' name='description' component={TextField} id='Description' label='Details' variant='filled'
                             fullWidth multiline maxLength={SIZE_LENGTH_PARTICIPANT_SUMMARY}
                             rows={4} disabled={isSubmitting}/>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>

              <Grid container justify='center' alignContent='center' alignItems='center'>
                <Grid container item md={8} spacing={3} className={classes.btns}>
                  <Grid item xs={'auto'} md={6}>
                    <Button variant='outlined' size='small' colour='white' onClick={handleClose}
                            disabled={isSubmitting}> Cancel </Button>
                  </Grid>
                  <Grid item xs={'auto'} md={6}>
                    <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default RoomEditNew
