import {NavLink, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import React, {useEffect, useState} from 'react'
import {palette} from 'theme'
import Divider from '@material-ui/core/Divider'
import EllipsisText from 'react-ellipsis-text'
import {useAuth} from 'auth/AuthController'
import { checkHost } from 'utils'
import language from "components/language";
import { useHttp } from "api/core";
import api from "api";

const styles = theme => ({
    selected: {
        color: `${theme.palette.primary.main} !important`,
        '& div': {
            backgroundColor: palette['cerulean-blue'],
            color: 'white'
        }
    },
    navItem: {
        fontWeight: 'bold',
        color: palette['greyish-brown'],
        cursor: 'pointer',
        '& div': {
            width: '100%',
            minWidth: '80px',
            cursor: 'pointer'
        }
    },
    bar: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    }
})

const LeftSideMenu = withRouter(withStyles(styles)(({classes, event, previewSuffix, extras}) => {
    const [isAuthenticated] = useAuth()
    const [getData, data, isLoadingData] = useHttp();
    const [contentsData, setContentsData] = useState([])

  useEffect(() => {
    if (event) {
      getData(
        api.entities.events.getContents(null, null, {
          eventId: event.eventInfo.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

    useEffect(() => {
      if (data) {
        setContentsData(data.content);
      }
    }, [data]);    

    const {slug} = useParams()
    const host = checkHost()

    let baseURL = `/event/${event.eventInfo.id}`

    if (slug) {
        baseURL = `/${slug}`
    } else if (host) {
        baseURL = ``
    }

    function createNavLink(item) {
        switch (item.contentEnumType) {
          case 1:
                return (
                  <Grid item xs={12}>
                    <NavLink
                      to={`${baseURL}/info${previewSuffix}`}
                      activeClassName={classes.selected}
                      className={classes.navItem}
                    >
                      {" "}
                      {item.label}{" "}
                    </NavLink>
                  </Grid>
                );
          case 2:
                return (
                  event.sessionsCount !== 0 && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/agenda${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 3:
                return (
                  event.participantsCount >=0 && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/participants${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 4:
                return (
                  event.keynoteSpeakers.length !== 0 && event.participantsCount >=0 && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/keynotespeakers${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 5:
                return (
                  event.sponsors.length !== 0 && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/sponsors${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 6:
                return (
                  event.eventInfo.hasRestaurants && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/restaurants${previewSuffix}`}
                        activeClassName={classes.selected}
                        className={classes.navItem}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 7:
                return (
                  event.eventInfo.hasHotels && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/hotels${previewSuffix}`}
                        activeClassName={classes.selected}
                        className={classes.navItem}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 8:
                return (
                  event.organizers.length !== 0 && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/organizers${previewSuffix}`}
                        activeClassName={classes.selected}
                        className={classes.navItem}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );
          case 9:
                return (
                  event.eventInfo.hasExtras &&
                  extras.map((extra) => (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
                        activeClassName={classes.selected}
                        className={classes.navItem}
                      >
                        {" "}
                        <EllipsisText text={extra.title} length={23} />{" "}
                      </NavLink>
                    </Grid>
                  ))
                );
          case 10:
                return (
                  <Grid item xs={12}>
                    <NavLink
                      to={`${baseURL}/general-page/${item.id}${previewSuffix}`}
                      activeClassName={classes.selected}
                      className={classes.navItem}
                    >
                      {" "}
                      <EllipsisText text={item.label} length={23} />{" "}
                    </NavLink>
                  </Grid>
                );
          case 11:
                return (
                  (event.eventInfo.showProceedings === 1 ||
                    (event.eventInfo.showProceedings === 3 &&
                      event.eventInfo.registered)) && (
                    <Grid item xs={12}>
                      <NavLink
                        to={`${baseURL}/proceedings${previewSuffix}`}
                        activeClassName={classes.selected}
                        className={classes.navItem}
                      >
                        {" "}
                        {item.label}{" "}
                      </NavLink>
                    </Grid>
                  )
                );          
          default:
                return ("")
        }
    }



    return (
      <>
        {data &&
          contentsData.map((item) => {
            return createNavLink(item);
          })}
            
        {/* <Grid item xs={12}>
          <Divider classes={{ root: classes.divider }} />
        </Grid>
        <Grid item xs={12}>
            <NavLink to={`${baseURL}/info${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> {language.info} </NavLink>
        </Grid>

        {event.sessionsCount !== 0 && <Grid item xs={12}>
            <NavLink to={`${baseURL}/agenda${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> {language.agenda} </NavLink>
        </Grid>}

        {event.participantsCount !== 0 && <Grid item xs={12}>
            <NavLink to={`${baseURL}/participants${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> {language.speakers} </NavLink>
        </Grid>}

        {event.keynoteSpeakers.length !== 0 && <Grid item xs={12}>
            <NavLink to={`${baseURL}/keynotespeakers${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> {language.keynoteSpeakers} </NavLink>
        </Grid>}

        {event.sponsors.length !== 0 && <Grid item xs={12}>
            <NavLink to={`${baseURL}/sponsors${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> {language.sponsors} </NavLink>
        </Grid>}

        {((event.eventInfo.showProceedings  === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) &&  <Grid item xs={12}>
            <NavLink to={`${baseURL}/proceedings${previewSuffix}`} className={classes.navItem}
                     activeClassName={classes.selected}> {language.proceedings} </NavLink>
        </Grid>}

        {event.eventInfo.hasExtras && extras.map((extra) => <Grid item xs={12}>
            <NavLink to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
                     activeClassName={classes.selected}
                     className={classes.navItem}> <EllipsisText text={extra.title} length={23} /> </NavLink>
        </Grid>)}

        {event.eventInfo.hasHotels && <Grid item xs={12}>
            <NavLink to={`${baseURL}/hotels${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> {language.hotels} </NavLink>
        </Grid>}

        {event.eventInfo.hasRestaurants && <Grid item xs={12}>
            <NavLink to={`${baseURL}/restaurants${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> {language.restaurants} </NavLink>
        </Grid>} */}

        {/* {isAuthenticated&&<Grid item xs={12}>
            <NavLink to={`${baseURL}/social-feed${previewSuffix}`} activeClassName={classes.selected}
                     className={classes.navItem}> {language.socialFeed} </NavLink>
        </Grid>} */}

        <Grid item xs={12}>
          <Divider classes={{ root: classes.divider }} />
        </Grid>
      </>
    );

}))

export default LeftSideMenu
