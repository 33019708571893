import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Stage, Layer, Image, Text, Rect} from 'react-konva'
import useImage from 'use-image'
import {imageFile} from 'utils'
import {SIZE_COVER} from 'config'


const styles = theme => ({

})

const BannerImage = ({url}) => {
  const [image] = useImage(url)
  const width = (SIZE_COVER.width/720) * SIZE_COVER.width
  return <Image image={image} height={720} width={width} x={-width/2 + 740} />
}

const Canvas = withStyles(styles)(({classes, session, setCanvasVideoTrack}) => {
  const canvasRef = useRef()
  const refreshRef = useRef()
  const [imageURL, setImageURL] = useState()

  useEffect(()=>{

    fetch(imageFile(session.backgroundId))
      .then( response => response.blob() )
      .then( blob =>{
        var reader = new FileReader()
        reader.onload = function(){ setImageURL(this.result) } ; // <--- `this.result` contains a base64 data URI
        reader.readAsDataURL(blob)
      })

    const canvasVideoTrack = canvasRef.current.getCanvas()._canvas.captureStream(1).getVideoTracks()[0]
    setCanvasVideoTrack(canvasVideoTrack)

    refreshRef.current = setInterval(()=>{
      canvasRef.current.getStage().draw()
    },1000)

    return () => {
      clearInterval(refreshRef.current)
    }

    //eslint-disable-next-line
  },[canvasRef])

  return <Stage width={1280} height={720} style={{display:'none'}}>
        <Layer ref={canvasRef}>
          {imageURL&&<BannerImage url={imageURL} />}
          <Rect
            width={1280}
            height={720}
            fill='rgba(0,0,0,0.3)'
            x={0}
            y={0}
            />
          <Text
            text={session.status.layout.bannerText}
            x={1280 / 2 - (1280/1.5)/2 }
            y={720 / 3}
            width={1280 / 1.5}
            fontSize={64}
            fill='white'
            align='center'
            wrap='word'
            shadowColor='black'
            shadowBlur={1}
            shadowOffset={{x: 3, y: 3}}
            shadowOpacity={0.2}
          />
        </Layer>
    </Stage>
})

export default Canvas
