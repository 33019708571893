import {withStyles} from "@material-ui/core/styles";
import {useParams, withRouter} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useHttp} from "api/core";
import api from "api";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {palette} from "theme";
import Fade from "@material-ui/core/Fade";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import AddCreditsCardView from "components/core/AddCreditsCardView";
import AddBallanceToEvent from "components/core/AddBalanceToEvent";
import BuyCreditsDialog from "components/pages/ManageEvent/Plans/BuyCreditsDialog";
import AddCreditsDialog from "components/pages/ManageEvent/Plans/AddCreditsDialog";
import {creditsPriceList} from "config";

const styles = (theme) => ({
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  form: {
    color: palette["greyish-brown"],
  },
  dropBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    color: palette["greyish-brown"],
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  note: {
    fontSize: "12px",
  },
  actionButtons: {
    textAlign: "center",
  },
  title: {
    fontWeight: "bolder",
  },
  editor: {
    minHeight: 220,
    backgroundColor: "white",
    color: palette["greyish-brown"],
    borderRadius: "6px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(2),
  },
  noPlans: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "100px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
});

    
const BuyCredits = withStyles(styles)(({classes, setPageTitle, setEventInfo, eventInfo, setNavBack, history}) => {
  const [formData, setFormData] = useState();
  const [getData, data] = useHttp();
  const [getCredits, credits] = useHttp();
  const [eventDuration, setEventDuration] = useState(0)
  const {eventId} = useParams();
  // eslint-disable-next-line
  const {enqueueSnackbar} = useSnackbar();

  const [creditsPlan, setCreditsPlan] = useState();
  const [openBalance, setOpenBalance] = useState();
  const [complete, setComplete] = useState(false);
  
  useEffect(() =>
    {
    getData(api.entities.events.getInfo(null, null, {id: eventId}));
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);
       
    useEffect(() => {
      if (data) {
        const startDate = new Date(data.eventInfo.startDate);
        const endDate = new Date(data.eventInfo.endDate);
        let timeDifference = endDate.getTime() - startDate.getTime();
        let daysDifference = timeDifference / (1000 * 3600 * 24);
        setEventDuration(Math.ceil(daysDifference));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);    
    
   useEffect(() => {
     if (data) {
       setPageTitle(data.eventInfo.name);
       setEventInfo(data.eventInfo);
       setNavBack({path: "/manage-events", name: "events"});
     }
   }, [data, setEventInfo, setNavBack, setPageTitle, eventInfo]);   
     
  
 const handleOpenBuyCredits = (plan) => {
      setOpenBalance(plan);
  };

  const handleCloseBuyCredits = (success) => {
    setOpenBalance(null);
      if (success === true){
        setTimeout(() => {
            enqueueSnackbar("Credits added successfully.", {variant: "success"});
            getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));   
        }, 2000);
      } else
      {
        setTimeout(() => {
          getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
        }, 2000);          
      }
    };

  const handleOpenAddCredits = (plan) => {
    setCreditsPlan(plan);
  };

  const handleCloseAddCredits = (success) => {
    setCreditsPlan(null);
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    if (success === true) {
      enqueueSnackbar("Credits added successfully.", {variant: "success"});
    }
  };    


  return (
    <Box name={formData?.name} navBack={{path: "/manage-events", name: "events"}}>
      {openBalance && (
        <BuyCreditsDialog
          handleClose={handleCloseBuyCredits}
          creditsPriceList={creditsPriceList}
          eventCredits={credits}
          open={openBalance}
          creditsType={openBalance}
          event={{...eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}
      {creditsPlan && (
        <AddCreditsDialog
          handleClose={handleCloseAddCredits}
          creditsPriceList={creditsPriceList}
          eventDuration={creditsPlan === "sponsors" ? eventDuration : 1}
          eventCredits={credits}
          open={creditsPlan}
          creditsType={creditsPlan}
          event={{...eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}

      <Fade in={true} timeout={1200}>
        <Box mt={4} maxWidth={1024}>
          <Grid container spacing={2} className={classes.section}>
            {/* <Grid item xs={12}>
              <Typography component="h2" variant="h5" className={classes.title}>
                Buy Credits
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <AddBallanceToEvent current={"balance"} creditsPriceList={creditsPriceList[0]} data={data} credits={credits} event={{...eventInfo, id: eventId}} handleOpen={handleOpenBuyCredits} />
            </Grid>
            <Grid item xs={12}>
              {" "}
            </Grid>
            <Grid item xs={12}>
              <Typography component="h2" variant="h6" className={classes.title}>
                Add credits
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <AddCreditsCardView current={"papers"} creditsPriceList={creditsPriceList[1]} credits={credits} event={{...eventInfo, id: eventId}} handleOpen={handleOpenAddCredits} />
              <AddCreditsCardView current={"posters"} creditsPriceList={creditsPriceList[2]} credits={credits} event={{...eventInfo, id: eventId}} handleOpen={handleOpenAddCredits} />
              <AddCreditsCardView current={"sponsors"} creditsPriceList={creditsPriceList[3]} eventDuration={eventDuration} credits={credits} event={{...eventInfo, id: eventId}} handleOpen={handleOpenAddCredits} />
              <AddCreditsCardView current={"emails"} creditsPriceList={creditsPriceList[4]} credits={credits} event={{...eventInfo, id: eventId}} handleOpen={handleOpenAddCredits} />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Box>
  );
});

export default withRouter(BuyCredits);
