import {Field, Form, Formik} from "formik";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {Switch, TextField} from "formik-material-ui";
import React, {useCallback, useEffect, useState} from "react";
import {withRouter, useParams} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import {palette} from "theme";
import * as Yup from "yup";
import {useHttp} from "api/core";
import api from "api";
import {useSnackbar} from "notistack";
import Button from "components/core/Button";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import "react-image-crop/dist/ReactCrop.css";
import language from "components/language";
import ThematicCodes from 'components/pages/ManageEvent/ThematicCodes'
import SubmissionFlows from 'components/pages/ManageEvent/SubmissionFlows'
import ReviewFlows from 'components/pages/ManageEvent/ReviewFlows'
import ReviewerInvites from 'components/pages/ManageEvent/ReviewerInvites'
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  form: {
    color: palette["greyish-brown"],
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-15px",
    "& Button": {
      minWidth: "170px",
    },
  },
  section: {
    paddingBottom: theme.spacing(4),
    maxWidth: "1200px",
  },
  title: {
    fontWeight: "bolder",
    fontSize: "1.1rem"
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginLeft: "10px",
  },
  ptitle: {
    fontSize: "0.8rem",
    marginLeft: "10px",
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },  
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
});


const validationSchema = (formData) =>
  Yup.object().shape({
    general: Yup.object().shape({
      enableSubmissions: Yup.boolean(),
      submissionFlowButtonLabel: Yup.string().nullable(),
      submissionFlowLabel:Yup.string().nullable()
    }),

  });


const SubmissionsSetup = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, history, eventInfo, flow}) => {
  const [formData, setFormData] = useState();
  const [formSave, setFormSave] = useState();
  const [getData, data] = useHttp();
  const [putRequest, putData, isLoading, hasError] = useHttp();
  const {eventId} = useParams();
  const {enqueueSnackbar} = useSnackbar();
  const [getDataPublish, dataPublish] = useHttp();
  const [reload, setReload] = useState(false)

  useEffect(() => {
    getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, [eventId]);


  useEffect(() => {
    if (data) {
      setFormData(data);
      setPageTitle(data.general.name);
      setEventInfo(data.eventInfo);
      setNavBack({ path: "/manage-events", name: "events" });    
    }
  }, [data, setEventInfo, setNavBack, setPageTitle]);


  useEffect(() =>
  {
    if (putData) {
      getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
      enqueueSnackbar("Successfully saved event", {variant: "success"});
    }
    // eslint-disable-next-line
  }, [putData]);

  useEffect(() => {
    if (formSave)
    {
      const payLoad = {
        ...formSave
      };

      let formDataObj = new FormData();
      const { ...formDataItems } = payLoad;

      const blob = new Blob([JSON.stringify(formDataItems)], {type: "application/json"});

      formDataObj.append("general", blob);
      putRequest(api.entities.manage.putGeneral(formDataObj, null, {id: eventId}, true));
      setFormSave(undefined);
    }
  }, [enqueueSnackbar, eventId, formSave, getData, putRequest]);

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
  };  

  const ViewEvent = useCallback(
    () => (
      <Button
        size="smaller"
        variant="outlined"
        colour="white"
        onClick={() => {
          history.push(`/event/${eventId}`);
        }}>
        {" "}
        View Event{" "}
      </Button>
    ),
    [eventId, history],
  );

  useEffect(() => {
    if (dataPublish) {
      if (dataPublish.status && dataPublish.status === 201) {
        enqueueSnackbar("Your event was successfully published!", {variant: "success", action: ViewEvent});
        setFormData((f) => ({...f, eventInfo: {...f.eventInfo, dirty: false}}));
        setEventInfo((f) => ({...f, dirty: false}));
      } else {
        enqueueSnackbar("Unexpected Error", {variant: "error"});
      }
    }
    // eslint-disable-next-line
  }, [dataPublish]);
  
  if (!formData) return <> </>;

  const isSubmitting = (isLoading && !hasError);
  
  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          ...formData,

        }}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting,resetForm}) => {
          let {eventInfo, ...restValues} = values;
          setFormSave({ ...restValues });
          resetForm();
        }}>
        {({dirty, errors, submitForm, setFieldValue, values, setFieldTouched, validateField, touched}) => (
          <Fade in={true} timeout={1200}>
            <Form className={classes.form}>
              <Box mt={4} minWidth={"100%"} maxWidth={1180}>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Enable submissions
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Enable paper submission notification card at event's page right column." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "3px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                        
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>                  
                    <Field type="checkbox" name="general.enableSubmissions" component={Switch} disabled={isSubmitting} />
                  </Grid>                  
                </Grid> 
                <Grid container spacing={2} className={classes.section} >
                  <Grid item xs={12} md={2} >
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Button
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Set labels for submission notification card and card button." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                          
                    </Typography>
                  </Grid>
                  <Grid container item xs={12} md={10} style={{paddingLeft: 0}}>
                    <Grid item container spacing={2} p={0} justify="flex-start" alignContent="left" alignItems="left">
                      <Grid item xs={4}>
                        <Field type="text" name="general.submissionFlowLabel" component={TextField} label={language.submissionFlowLabel} variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="general.submissionFlowButtonLabel" component={TextField} label={language.submissionFlowButtonLabel} variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>                      
                    </Grid>
                  </Grid>                                                           
                </Grid>
 
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Thematic Codes
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Thematic codes are a way to categorize papers and match a reviewer based on their expertise." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                      
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>                  
                    <ThematicCodes setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} eventInfo={eventInfo}/>
                  </Grid>                  
                </Grid>                   

                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Submission steps
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Setup the procedure of paper submissions." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                       
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>                  
                    <SubmissionFlows setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} eventInfo={eventInfo} setReload={setReload}/>
                  </Grid>                 
                </Grid>           
                {(dirty || eventInfo.dirty) && (   
                  <Grid container spacing={2} className={classes.section}> 
                    <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons}  style={{position: flow ? "absolute" : null, top: flow ? "0" : null}}>
                      {eventInfo && eventInfo.dirty ? (
                        <Alert className={classes.topPublish} severity="warning">
                          <AlertTitle>Unpublished changes</AlertTitle>
                          You are viewing an event with unpublished changes.
                        </Alert>
                      ) : (
                        <Alert className={classes.topPublish} severity="warning">
                          <AlertTitle>Save changes</AlertTitle>
                          Remember to save your changes before you leave this page.
                        </Alert>
                      )}
                      {dirty && <Box display="inline-block" mt={1} mb={1}>
                        <Button type="submit" variant="outlined" color="primary">
                          Save Changes
                        </Button>
                      </Box>}
                      &nbsp;&nbsp;&nbsp;
                      <Box display="inline-block" mt={1} mb={1}>
                        {eventInfo.dirty ? (
                          <Button
                            onClick={() => {
                              publishEvent();
                            }}>
                            Publish
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>              
            </Form>
          </Fade>
        )}
      </Formik>
     
    </Box>
  );
});

export default withRouter(SubmissionsSetup);