import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "components/core/Button";
import clsx from "clsx";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";
import {format} from "date-fns";
// import SubmissionResponseCardView from 'Components/core/SubmissionResponseCardView'
import EllipsisText from "react-ellipsis-text";
import Chip from "@material-ui/core/Chip";
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";
import parse from "html-react-parser";
import api from "api";
import {useHttp} from "api/core";
import moment from "moment";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = (theme) => ({
  submissionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "12px",
    border: "solid 1px #e6e6e6",
    position: "relative",
    padding: theme.spacing(2),
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  answeredSubmissions: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7,
  },
  button: {
    height: "100%",
    position: "relative",
    paddingTop: theme.spacing(1),
  },
  note: {
    left: 0,
    fontSize: "11px",
    position: "absolute",
    width: "100%",
    bottom: "-5px",
    textAlign: "center",
    color: palette["brown-grey"],
    fontWeight: "bold",
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  priceColor: {
    color: theme.palette.primary.main,
  },
  submissionsBox: {
    height: "calc(100% - 16px)",
    // minHeight: '60px',
    // backgroundColor: palette['pale-sky-blue'],
    borderRadius: "8px",
    border: "1px solid #ddd",
    // boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette["greyish-brown"],
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.1)",
    },
  },
  disabled: {
    cursor: "default",
    "&:hover": {
      boxShadow: "none",
    },
  },
  submissionsTitle: {
    color: "black",
    // color: palette['cerulean-blue'],
    fontWeight: "bold",
  },
  submissionsAuthors: {
    fontSize: 12,
    fontWeight: "normal",
  },
  cameraReadyButton: {
    fontSize: "13px",
    lineHeight: "20px",
    backgroundColor: palette.aquamarine,
    maxWidth: "250px",
    height: "35px",
    "&& .MuiButton-label": {
      fontSize: "13px",
      lineHeight: "20px",
    },
  },
});

const SubmissionBox = withStyles(styles)(({classes, response, submission, handleOpen}) => {
  let statusText = ``;

  switch (response.status) {
    case 0:
      statusText = `Pending`;
      break;
    case 1:
      statusText = `Accepted`;
      break;
    case 2:
      statusText = `Rejected`;
      break;
    case 3:
      statusText = `Waitlisted`;
      break;
    case 4:
      statusText = `Withdrawn`;
      break;
    default:
      statusText = `Pending`;
  }

  const isClosed = isPast(parseISO(submission.endDate));

  return (
    <Box className={clsx(classes.submissionsBox, isClosed && classes.disabled)} onClick={() => !isClosed && handleOpen(response)}>
      <Box className={classes.submissionsTitle} mb={1} p={1}>
        <EllipsisText text={response.title ? response.title : "-"} length={30} />{" "}
        <Box ml={1} style={{float: "right"}}>
          <Chip variant="outlined" size="small" label={`${statusText}${statusText === "Accepted" || statusText === "Waitlisted" ? ` for ${response.sessionType}` : ``}`} />
        </Box>{" "}
      </Box>
      <Box className={classes.submissionsAuthors} pl={1}>
        {response.authors && response.authors.length !== 0 && response.authors.join(",")}
        {response.authors && response.authors.length === 0 && <> - </>}
      </Box>
    </Box>
  );
});

const SubmissionCardView = withStyles(styles)(({classes, handleOpen, event, submission, viewOnly, selectedTimezone, ...props}) => {
  const willOpen = isFuture(parseISO(submission.openDate));
  const willClose = isFuture(parseISO(submission.endDate)) && isPast(parseISO(submission.openDate));
  const isClosed = isPast(parseISO(submission.endDate));

  const addLabel = event.eventInfo.submissionsButton ? event.eventInfo.submissionsButton : language.submit;

  return (
    <>
      {" "}
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} md={viewOnly ? 12 : 9}>
          <Grid
            item
            container
            spacing={1}
            alignContent="center"
            alignItems="center"
            justify="center"
            className={clsx(classes.submissionBox, submission.answered && classes.answeredSubmissions, (isClosed || willOpen) && classes.notAvailable)}>
            <Grid lg={7} xs={8} item>
              <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
                <Box display="flex" fontSize="14px">
                  {language.name}
                </Box>
                <Box display="flex" fontSize="20px" fontWeight="bold">
                  {submission.name}
                </Box>
                <Box display="flex" fontSize="16px" mt={1} width={"100%"}>
                  {submission.description}
                </Box>
              </Box>
            </Grid>
            <Grid xs item>
              <Grid item container justify="flex-end" alignContent="center" alignItems="center">
                <Grid item>
                  {event.eventInfo.id !== 147 && willClose && language.closesOn}
                  {parse("<br/>")}
                  {event.eventInfo.id !== 147 && willClose && format(parseISO(submission.endDate), "dd LLL yyyy hh:mm a", dateLocale[event.eventInfo.eventLanguage]) + " (" + moment.tz.guess().replace("_", " ") + ")"}
                  {parse("<br/>")}
                  {event.eventInfo.id !== 147 && willClose && selectedTimezone && moment(submission.endDate).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"}
                  {/*event.eventInfo.id === 138 || event.eventInfo.id === 141 ? "" : (willClose && `Closes on ${ format(parseISO(submission.endDate), 'LLL dd, yyyy') }`)*/}

                  {willOpen && !willClose && language.opensOn}
                  {parse("<br/>")}
                  {willOpen && !willClose && format(parseISO(submission.openDate), "dd LLL yyyy hh:mm a", dateLocale[event.eventInfo.eventLanguage]) + " (" + moment.tz.guess().replace("_", " ") + ")"}
                  {parse("<br/>")}
                  {willOpen && !willClose && moment(submission.openDate).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"}
                  {/*{isSameDay(parseISO(submission.endDate),parseISO(submission.openDate)) ?*/}
                  {/*  `Open from ${format(parseISO(submission.endDate), 'LLL dd, yyyy')} to ${format(parseISO(submission.endDate), 'LLL dd, yyyy')}`*/}
                  {/*  :*/}
                  {/*  `Open only on ${format(parseISO(submission.endDate), 'LLL dd, yyyy')}`*/}
                  {/*}*/}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2} style={{marginTop: 12}}>
              {submission.answers.map((response, i) => {
                // return <SubmissionResponseCardView response={response} index={i} submission={submission} handleOpen={handleOpen} />
                return (
                  <Grid item xs={12}>
                    <SubmissionBox response={response} submission={submission} handleOpen={handleOpen} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        {!viewOnly && (
          <Grid item xs={12} md={3}>
            <Grid item container justify="center" alignContent="flex-start" alignItems="center" className={classes.button}>
              <Grid item xs={12}>
                <Button disabled={isClosed || willOpen} autoWidth color={"secondary"} onClick={() => handleOpen({submissionId: submission.id, name: submission.name})}>
                  {" "}
                  {addLabel}{" "}
                </Button>
                {/*{submission.answered&&<Box className={classes.note}>Already added submission</Box>}*/}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
});

export default SubmissionCardView;
