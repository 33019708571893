import {makeStyles} from '@material-ui/styles'

export const discussion_1 = makeStyles({
  item_1: {
    top: '2%',
    left: '1%',
    width: '98%',
    height: '96%',
    opacity: '1 !important'
  }
})
