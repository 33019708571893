import React, {useEffect, useState} from 'react'
import {Grid, Typography, Box} from '@material-ui/core'
import Header from 'components/core/Header/Header'
import Page from 'components/core/Page'
import {FILES_URL} from 'config'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Avatar from '@material-ui/core/Avatar'
import {withStyles} from '@material-ui/core/styles'
import Button from 'components/core/Button'
import Chip from '@material-ui/core/Chip'
import RootRef from '@material-ui/core/RootRef'
import debounce from 'lodash.debounce'
import StickyBox from 'react-sticky-box'
import PresentationsBlock from 'components/pages/SessionViewer/PresentationBlock'
import {palette} from 'theme'
import {IconFileBorderDark} from 'components/core/icons'
// import GoogleDocsViewer from 'react-google-docs-viewer'
import {NotificationEventReceiver} from 'components/core/Notifications'

const styles = theme => ({
  slideCarousel: {
    width: '80%',
    height: '100%',
    zIndex: 2
  },
  chip: {
    margin: theme.spacing(1),
    maxWidth: '135px'
  },
  avatar: {
    background: 'none',
    '&> img': {
      maxWidth: '100%'
    }
  },
  media: {
    paddingTop: '78%',
    cursor: 'pointer',
    backgroundPosition: '0 0'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardStyle: {
    boxShadow: '1px 0px 9px 0px #90909036',
    borderRadius: '2px',
    border: '1px solid #cde3e673',
    minHeight: '500px',
    height: 'auto'
  },
  cardPresenters: {
    minHeight: '35px',
    height: 'auto',
    padding: '10px'
  },
  cardTitleBox:{
    padding: '10px',
    marginTop:theme.spacing(-9)
  },
  cardTitle:{
    color:'#fff',
    textShadow:'1px 2px 1px #000'
  },
  openButton: {
    margin:'0 auto'
  }
})

const PosterCarousel = withStyles(styles)(({classes, pictureId, presentation, openPosterView, setOpenPosterView, setSelectedPresentation}) => {
  if (!presentation) return <> </>
  return <>
    <Card className={classes.cardStyle} key={presentation.id}>
      <CardMedia
        className={classes.media}
        image={pictureId&&`${FILES_URL}/${pictureId}`}
        onClick={() => {
          setOpenPosterView(!openPosterView)
          setSelectedPresentation(presentation)
        }}
      >
        <Box className={classes.cardTitleBox}>
          <Typography variant={'h5'} className={classes.cardTitle}>{presentation.title}</Typography>
        </Box>
      </CardMedia>
      <CardContent>
        <Box mt={2} mb={2}>
          <Typography variant={'subtitle2'}>
            {presentation.participants.map((participant, indexParticipant) => {
              return <>
                {participant.firstName} {participant.lastName} {indexParticipant >= 0 && indexParticipant !== presentation.participants.length - 1 ? ' , ' : ''}
              </>
            })}
          </Typography>
        </Box>
        <Typography variant="body2" color="textSecondary" component="p">
          {presentation.abstract.slice(0, 30)}...
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Box style={{width:'100%',textAlign:'center'}} mb={2}>
          <Button size={'big'} autoWidth className={classes.openButton} onClick={() => {
            setOpenPosterView(!openPosterView)
            setSelectedPresentation(presentation)
          }}>Enter poster room</Button>
        </Box>
      </CardActions>
    </Card>

  </>
})

const PDFView = ({url}) => {
  const [file, setFile] = useState()

  useEffect(()=>{
    fetch(url).then(function(response) {
      return response.blob()
    }).then(function(response) {
      console.log(response)
      let newBlob = new Blob([response], {type: "application/pdf"})
      setFile(window.URL.createObjectURL(newBlob))
    });
    //eslint-disable-next-line
  },[])

  return <>
    {file&&<object data={file} type="application/pdf" width={'100%'} height={780}>
      <iframe src={file} width={'100%'} height={780} title={url}> </iframe>
    </object>}
    {/*{file&&<iframe src={file} />}*/}
    {/*{file&&<GoogleDocsViewer*/}
    {/*  width="100%"*/}
    {/*  height="780px"*/}
    {/*  fileUrl={file}*/}
    {/*  key={url}*/}
    {/*/>}*/}
  </>
}

const PosterViewer = ({selectedPresentation, classes, session, setOpenPosterView}) => {

  // const [presentationFile,setPresentationFile] = useState()
  const domRef = React.useRef()

  // useEffect(()=>{
  //     setPresentationFile(`${FILES_URL}/${selectedPresentation.files[0].id}`)
  //   // eslint-disable-next-line
  // },[])

  return <>
    <RootRef rootRef={domRef}>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <StickyBox offsetTop={80} offsetBottom={20}>
            <Grid container direction={'column'}>
              <Grid item>
                <Box p={2} mb={2}>
                  {session.files && session.files.length !== 0 && <>
                    <Box pb={1} fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']}>Files</Box>
                    {session.files.map((file, i) => {
                      return <Box display='inline-block' m={1} key={i}>
                        <Chip
                          avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                          variant="outlined"
                          label={`${file.filename}`}
                          component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                        />
                      </Box>
                    })}
                  </>
                  }
                  <Box>
                    <PresentationsBlock presentation={selectedPresentation} classes={classes}/>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </StickyBox>
        </Grid>
        <Grid item md={6}>
          {selectedPresentation.files&&selectedPresentation.files[0]&&<PDFView url={`${FILES_URL}/${selectedPresentation.files[0].id}`} />}
        </Grid>
      </Grid>
    </RootRef>
  </>
}

const PostersGrid = ({classes, session, setSelectedPresentation, setOpenPosterView, openPosterView}) => {
  const [feedSize, setFeedSize] = useState('auto')

  const domRef = React.useRef()

  const resizeFeed = () => {
    const currentPos = domRef.current.getBoundingClientRect()
    setFeedSize(window.innerHeight - currentPos.top)
  }
  const debounceResizeFeed = debounce(
    resizeFeed,
    200
  )
  useEffect(() => {
    //resize listener
    window.addEventListener('resize', debounceResizeFeed);
    resizeFeed()
    return () => {
      window.removeEventListener('resize', debounceResizeFeed);
    }
    // eslint-disable-next-line
  }, [])

  return <>
    <RootRef rootRef={domRef}>
      <Box display={'flex'} alignItems={'center'} style={{minHeight: feedSize + 70, height: 'auto'}}>
        <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'}  spacing={4}>
          {session.presentations.map((presentation, index) => {
            return <Grid item xs={12} md={4} lg={4} key={presentation.id}>
                    <PosterCarousel pictureId={presentation.files[0]&&presentation.files[0].thumb&&presentation.files[0].thumb.id}
                              openPosterView={openPosterView} setOpenPosterView={setOpenPosterView}
                              setSelectedPresentation={setSelectedPresentation} key={presentation.id}/>
                   </Grid>
          })}
        </Grid>
      </Box>
    </RootRef>
  </>
}

const SessionPosters = withStyles(styles)(({classes, setProfileDialogOpen, session}) => {
  const [openPosterView, setOpenPosterView] = useState(false)
  const [selectedPresentation, setSelectedPresentation] = useState()


  return <>
    <Header setProfileDialogOpen={setProfileDialogOpen} isSession={true} isPosterType={true}
            setOpenPosterView={setOpenPosterView}
            openPosterView={openPosterView}
            session={session}/>
    <NotificationEventReceiver topic={`/topic/online-room/${session.id}`} />
    <Page title='Session' p={2} noFooter>

      {!openPosterView &&
      <PostersGrid session={session} setSelectedPresentation={setSelectedPresentation}
                   setOpenPosterView={setOpenPosterView} openPosterView={openPosterView}/>
      }
      {openPosterView && selectedPresentation &&
      <PosterViewer selectedPresentation={selectedPresentation} session={session} classes={classes}
                    setOpenPosterView={setOpenPosterView}/>
      }
    </Page>
  </>
})

export default SessionPosters
