import React from 'react'
import {withStyles} from '@material-ui/core'
import Chip from 'components/core/Chip'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import clsx from 'clsx'
import {CATEGORIES,DOMAIN} from 'config'
import {format} from "date-fns"
import {withRouter} from 'react-router-dom'
import parseISO from 'date-fns/parseISO'
import Grid from '@material-ui/core/Grid'
import BookmarkButton from 'components/core/BookmarkButton'
import ShareButton from 'components/core/ShareButton'
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = theme => ({
  card: {
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    border: '2px solid rgba(0,0,0,0)',
    borderRadius: '5px'
  },
  bookmarkIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  title: {
    position: 'relative',
    fontWeight: 'bold',
    minHeight: '45px',
    marginTop: -theme.spacing(1),
    paddingRight: theme.spacing(10),
    '& a': {
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    }
  },
  titleEvent: {
    paddingRight: 0
  },
  listRow: {
    marginBottom: theme.spacing(1.5)
  },
  shareIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(7)
  },
  manageBtn: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  promotedLabel: {
    backgroundColor: palette.aquamarine,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white',
    borderRadius: '0 0 4px 4px',
    fontWeight: 'bold'
  },
  deEmphasized: {
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  hidden: {
    visibility: 'hidden'
  },
  shown: {
    visibility: 'visible'
  },
  download: {
    alignSelf: 'center',
    minWidth: '90px',
    minHeight: '80px',
    height: 'auto',
    border: `1px solid ${palette.aquamarine}`,
    borderRadius: '6px',
    color: palette.aquamarine,
    textAlign: 'center',
    fontSize: '12px',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.aquamarine,
      color: 'white'
    },
    '&:hover #download': {
      stroke: 'white'
    }
  },
  linkToEvent: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  viewOnly: {
    boxShadow: 'none',
    border: 'solid 1px #e6e6e6',
    cursor: 'default !important',
    '&:hover': {
      border: 'solid 1px #e6e6e6',
    },
    '& a': {
      // height: 0,
      cursor: 'default !important',
    }
  }
})

const ConferenceCardOverview = withStyles(styles)(({classes, staticContext, index, refreshList, viewOnly, promoted=false, joinEvents=false, manageEvents=false, event, history, hooks, onClick, key, ...restProps}) => {
  const cardStyle = clsx({
    [classes.card]: true,
    [classes.cardMyEvent]: joinEvents,
    [classes.viewOnly]: viewOnly
  })

  const titleStyle = clsx({
    [classes.title]: true,
    [classes.titleEvent]: joinEvents
  })

    const eventCategory = (type) => {
      let typos = "";
      switch (type) {
        case "Academic Conference":
          typos = language.academicConference;
          break;
        case "Conference":
          typos = language.conference;
          break;
        case "Seminar":
          typos = language.seminar;
          break;
        case "Meeting":
          typos = language.meeting;
          break;
        case "Team Building":
          typos = language.teamBuilding;
          break;
        case "Networking":
          typos = language.networking;
          break;
        case "Business":
          typos = language.business;
          break;
        case "Workshop":
          typos = language.workshop;
          break;
        default:
          typos = "";
      }
      return typos;
  };
  console.log(event)
  return <>
      <Grid container item spacing={2} className={cardStyle}>
          <Grid item xs={12} className={classes.cardInfo}>
            <Box fontWeight='bold' fontSize={21} pt={2} pl={1} pb={2} pr={10} className={titleStyle}>
              {event.name}
            </Box>
            {event.category&&<Box p={1} pt={0}><Chip label={eventCategory(CATEGORIES[event.category])} /></Box>}
            {event.startDate&&<Box p={1} pt={0} pb={0}>{format(parseISO(event.startDate), 'LLL do | hh:mmaa', dateLocale[event.eventLanguage])}</Box>}
            {event.venueName&&<Box p={1} pt={0} pb={1} className={classes.deEmphasized}>{event.venueName}</Box>}
          </Grid>
          <Box className={classes.shareIcon}>
              <ShareButton shareLink={`https://${DOMAIN}/event/${event.id}`} />
            </Box>
            <Box className={classes.bookmarkIcon}>
              <BookmarkButton event={event} refreshList={refreshList}/>
            </Box>
      </Grid>
  </>
})

export default withRouter(ConferenceCardOverview)
