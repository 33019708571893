import {withStyles} from '@material-ui/core/styles'
import React, {forwardRef, useEffect, useState} from "react";
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from "theme";
import Button from 'components/core/Button'
import Grid from '@material-ui/core/Grid'
import * as Yup from "yup";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import LoadingDialog from "components/core/LoadingDialog";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";

const styles = (theme) => ({
  note: {
    fontSize: "12px",
  },
  formControl: {
    width: "100%",
  },
  dialog: {
    padding: theme.spacing(3),
  },
  dialogPaper: {
    minHeight: "calc(90vh-90px)",
    maxHeight: "calc(90vh-100px)",
    padding: "40px 30px 40px 30px",
  },
  row: {
    marginBottom: theme.spacing(2),
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const ParticipantsBulkImport = withStyles(styles)(({ classes, eventId,handleClose, setSuccessful,refresh, file,allParticipants, ...props }) =>{
  const { enqueueSnackbar } = useSnackbar();
  
  const [putData, data, isLoading] = useHttp();
  const [saveData, dataSave, isLoadingSave, error] = useHttp();
  const [save,setSave]= useState(false)
  // eslint-disable-next-line
  const [showError,setShowError] = useState()

  const [participants, setParticipants] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [hasInvalid, setHasInvalid] = useState(false);

  useEffect(() => {
    if (file) {
      let formDataObj = new FormData();
      formDataObj.append("file", file);
      putData(api.entities.participants.uploadParticipantsPreview(formDataObj, null, {id: eventId}, true));
    }
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (data) {
      let participantsData = [];

      data.forEach((participant, index) =>
      { 
        const valid = isValid(participant);
        if (!valid) setHasInvalid(true);       
        participantsData.push({
          title: participant.title === null ? null : findUserTitle(participant.title),
          firstName: participant.firstName,
          id: participant.id,
          lastName: participant.lastName,
          email: participant.email,
          profession: participant.profession,
          instituteOrCompany: participant.instituteOrCompany,
          linkedinUrl: participant.linkedinUrl,
          summary: participant.summary,
          keynote: null,
          eventId: parseInt(eventId),
          sessions: null,
          image: null,
          valid,
        });
      });

      setParticipants(participantsData);
      setPreviewOpen(true);
    }
    // eslint-disable-next-line
  }, [data]);

  function findUserTitle(title) {
    switch (title) {
      case "Professor":
        return "Prof.";
      case "Prof.":
        return "Prof.";
      case "Prof":
        return "Prof.";
      case "Mr.":
        return "Mr.";
      case "mr.":
        return "Mr.";
      case "mr":
        return "Mr.";
      case "Mr":
        return "Mr.";
      case "Mx":
        return "Mx.";
      case "Ms":
        return "Ms.";
      case "Ms.":
        return "Ms.";
      case "ms.":
        return "Ms.";
      case "ms":
        return "Ms.";
      case "Dr":
        return "Dr.";
      case "Dr.":
        return "Dr.";
      case "dr":
        return "Dr.";
      default:
        return null;
    }
  }

  const isValidEmail = (email) => {
    return Yup.string().email().isValidSync(email);
  };
  const notUsedEmail = (email) =>{    
    const exists = allParticipants.participants.filter((participants) => participants.email === email);
    if (exists.length === 0)
    {
      return true;
    }
    else
    {
       enqueueSnackbar("Email " + email + " already in use.", {variant: "error", preventDuplicate: true});
      return false;
    }
  }

  // Validation check
  const isValid = (entry) => {
    let valid = false;
    let exists = false;
    if (entry.email && entry.firstName && entry.lastName && entry.profession && entry.instituteOrCompany) {
      valid = isValidEmail(entry.email);
      exists = notUsedEmail(entry.email);
      return valid&&exists;
    } else {
      return valid;
    }
  };

  useEffect(() => {
    if (participants) {
      let hasInvalidEntries = false;
      participants.forEach((entry) => {
        if (!isValid(entry)) {
          hasInvalidEntries = true;
        }
      });
      setHasInvalid(hasInvalidEntries);
    }
    // eslint-disable-next-line
  }, [participants]);


  useEffect(() => {
    if (dataSave) {
      handleClose(true);
      setSuccessful(true);
    }
    // eslint-disable-next-line
  }, [save]);

  const handleSave = () => {
    participants.forEach((a, index) =>
    {
      setTimeout(() => {
        let formDataObj = new FormData();
        const blob = new Blob([JSON.stringify(a)], {type: "application/json"});
        formDataObj.append("participant", blob);
        saveData(api.entities.participants.addNew(formDataObj, null, null, true));
      }, index * 500);    
    }); 
    setTimeout(() => {
      setSave((prevSave) => !prevSave);
    },participants.length*600);
  };


return (
  <>
    {(isLoading || isLoadingSave) && <LoadingDialog open={true} />}
    {participants && (
      <Dialog onClose={handleClose} open={participants && previewOpen} fullWidth maxWidth="lg" classes={{paper: classes.dialogPaper}} disableBackdropClick={isLoadingSave} disableEscapeKeyDown={isLoadingSave}>
        {hasInvalid && (
          <Alert severity="warning">
            <AlertTitle>Invalid records</AlertTitle>
            There are invalid records in this file. You won't be able to upload the file before fixing.
          </Alert>
        )}
        <Grid container className={classes.dialog}>
          <Grid item container spacing={1} className={classes.row}>
            <Typography variant="h5" component="h1">
              {"Upload participants"}
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <MaterialTable
                icons={tableIcons}
                columns={[
                  {title: "Title", field: "title", cellStyle: {width: "20px", maxWidth: "20px"}},
                  {title: "First name", field: "firstName"},
                  {title: "Last name", field: "lastName"},
                  {title: "Email", field: "email"},
                  {title: "Position", field: "profession"},
                  {title: "Country Code", field: "countryCode"},
                  {title: "Affiliation/ Company", field: "instituteOrCompany"},
                  {title: "LinkedIn", field: "linkedinUrl", cellStyle: {width: "80px", maxWidth: "80px", wordBreak: "break-all"}},
                  {title: "Summary", field: "summary", cellStyle: {whiteSpace: "nowrap", width: "300px", maxWidth: "500px", overflow: "hidden", textOverflow: "ellipsis"}},
                  {title: "Valid", field: "valid", type: "boolean", editable: "never", defaultSort: "asc"},
                ]}
                data={participants}
                title="Participants List"
                editable={{
                  onRowAdd: (newData) =>
                    new Promise((resolve, reject) => {
                      setParticipants([...participants, {...newData, valid: isValid(newData)}]);
                      resolve();
                    }),
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      const dataUpdate = [...participants];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = {...newData, valid: isValid(newData)};
                      setParticipants([...dataUpdate]);
                      resolve();
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      const dataDelete = [...participants];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setParticipants([...dataDelete]);
                      resolve();
                    }),
                }}
                options={{
                  actionsColumnIndex: 999,
                  headerStyle: {
                    backgroundColor: palette["brown-grey"],
                    color: "#FFF",
                    fontWeight: "bold",
                    fontSize: 14,
                  },
                  rowStyle: (rowData) => ({fontSize: 12, padding: 0, backgroundColor: rowData.valid ? "" : "rgb(255, 244, 229)"}),
                }}
              />
            </Grid>
            {/*<Grid item container justify='flex-end' alignItems='flex-end' alignContent='flex-end'>*/}
            {/*  <Grid item>*/}
            {/*    <Button size='smaller'> Save </Button>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
        <DialogActions>
          <Button size="smaller" onClick={handleClose} color="primary" disabled={isLoadingSave}>
            Cancel
          </Button>
          <Button size="smaller" onClick={handleSave} color="secondary" disabled={isLoadingSave || hasInvalid}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )}
  </>
);
})

export default ParticipantsBulkImport