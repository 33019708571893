import React from 'react'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {ReactComponent as Logo} from 'assets/anymeets-logo.svg'

const styles = theme => ({
    navLinks: {
        '& a': {
            textDecoration: 'none',
            color: palette['greyish-brown'],
            marginRight: theme.spacing(4),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
    hideMobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        }
    },
    centerMobile: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center'
        }
    },
    copyright: {
        position: 'absolute',
        right: theme.spacing(2),
        textAlign: 'right',
        width: '450px',
        '& img': {
            marginLeft: theme.spacing(1),
            width: '100px',
            height: 'auto'
        },
        [theme.breakpoints.down(990)]: {
            width: '150px',
            lineHeight: '25px'
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            width: '100%',
            textAlign: 'center',
            lineHeight: '30px',
        }
    }
})

const FooterEvent = withStyles(styles)(({classes, event}) => {
    const current_date = new Date()
    const current_year = current_date.getFullYear()

    return <>
        <Grid container className={classes.navLinks}
              direction='row'
              justify='space-between'
              alignItems='center'
              spacing={1}
        >
            <Grid item xs={4} sm='auto'>
                <Box component='div'>
                    <Box component='div' fontSize={15}
                         display='inline'>Copyright &copy; {current_year} | </Box> {event.organizers.map((organizer, index) => {
                    return (
                        <Box key={organizer.name} component='div'
                             display='inline'>{organizer.name}{event.organizers.length !== index + 1 ? ',' : ''} </Box>)
                })}</Box>
            </Grid>
            <Grid item xs={4} sm='auto'>
                <Link to='/'><Logo width='102px'/></Link>
            </Grid>
        </Grid>
    </>
})

export default FooterEvent
