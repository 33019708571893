import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import TrackCard from 'components/core/TrackCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import TrackEditNew from 'components/core/TrackEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'

const styles = theme => ({
  tracksContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noTracksYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
   addBtn: {
    textAlign: 'right'
   },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(3)
  }
})

const Tracks = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  const [getData, data] = useHttp()
  const [delRequest, delData] = useHttp()
  const [openTrackDialog, setOpenTrackDialog] = useState(false)
  const [editinTrack, setEditingTrack] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingTrack, setDeletingTrack] = useState()
  const [tracksData, setTracksData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getData(api.entities.manage.getTracks(null,null,{id:eventId}))
  },[getData, eventId])

  useEffect(()=>{
    if (data) {
      setTracksData(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
    }
  },[data, setEventInfo, setNavBack, setPageTitle])

  const handleClose = (shouldReload) => {
    setOpenTrackDialog(false)
    if (shouldReload) {
      getData(api.entities.manage.getTracks(null,null,{id:eventId}))
    }
  }

  const handleOpen = (track) => {
    setOpenTrackDialog(true)
    if (track) {
      setEditingTrack(track)
    } else {
      setEditingTrack(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingTrack(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingTrack) {
      delRequest(api.entities.tracks.delete(null,null,{id:id}))
      setDeletingTrack(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted track', {variant: 'success'})
      getData(api.entities.manage.getTracks(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getData])

  if (!tracksData) return <> </>

  return <>
    <Dialog onClose={handleClose} open={openTrackDialog} maxWidth='md'>
      <TrackEditNew track={editinTrack} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete track</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete track?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
        <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
          No
        </Button>
        </Box>
        <Box p={1}>
        <Button onClick={()=>{handleDeleteClose(deletingTrack)}}  size='small' autoFocus color='primary'>
          Yes
        </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid container className={classes.tracksContainer} spacing={2}>
        <Grid item xs={12}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Tracks
          </Typography>
        </Grid>
          <Grid item xs={12} md={12}>
            {tracksData.tracks.map((track,i)=>{
              return <TrackCard track={track} key={i} editAction={()=>{handleOpen(track)}} deleteAction={()=>{handleDeleteOpen(track.id)}} mb={2}/>
            })}
            {tracksData.tracks.length===0?<Grid item container className={classes.noTracksYet}>
             <Grid item>No tracks yet!</Grid>
            </Grid>:''}
            </Grid>
          <Grid item xs={12} className={classes.addBtn}>
            <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen()}}>Add Track</Button>
          </Grid>
        </Grid>
    </Fade>
  </>
})

export default withRouter(Tracks)
