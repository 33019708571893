
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PaperAcceptance from "components/pages/ManageEvent/PaperAcceptance";
import CommunicationEditNew from 'components/core/CommunicationEditNew'


function TabPanel(props)
{
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${ index }`}
            aria-labelledby={`vertical-tab-${ index }`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index)
{
    return {
        id: `vertical-tab-${ index }`,
        'aria-controls': `vertical-tabpanel-${ index }`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "auto",
    },
    tabs: {
        paddingTop: 67,
        borderRight: `1px solid ${ theme.palette.divider }`,
        textAlign: "right",
        "& span": {
            alignItems: "end",
            fontWeight: "bold"
        }
    },
    tabPanel: {
        // height: "calc(100vh - 406px)",
        overflowY: "auto",
        paddingTop: 35,
        width: "calc(100% - 190px)",
        "&& .actionButtons": {
            marginLeft: "-245px",
        }
    }
}));

export default function StepSix({ setEventInfo, eventInfo, pageTitle, setPageTitle })
{
    const { eventId } = useParams()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [navBack, setNavBack] = useState({ name: 'events', path: '/manage-events' })

    const handleChange = (event, newValue) =>
    {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Flow setup"
                className={classes.tabs}
            >
                <Tab label="Paper acceptance" {...a11yProps(0)} style={{ textAlign: "left" }} />
                <Tab label="Email Notification" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <PaperAcceptance setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack}/>
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <div style={{maxWidth:"1024px"}}>
                    <CommunicationEditNew eventId={eventId} eventInfo={eventInfo} />
                </div>
            </TabPanel>
        </div>
    );
}
