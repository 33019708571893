import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'

import api from 'api'
import {useHttp} from 'api/core'
import RichTextEditor from 'react-rte'

const styles = (theme) => ({
  container: {
    width: "100%",
    padding: "40px 50px 50px 50px",
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
  },
  newExtra: {
    // maxWidth: '640px'
  },
  editor: {
    color: palette["greyish-brown"],
    borderRadius: "6px",
    minHeight: "300px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    "&& .DraftEditor-editorContainer": {
      height: "230px",
    }
  },
  row: {
    marginBottom: theme.spacing(4),
  },
  contentBox: {},
  btns: {
    textAlign: "center",
  },
});

const validationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  content: Yup.string().required('This field is required'),
})

const ExtraEditNew = withStyles(styles)(({classes, extra, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData] = useState({...extra})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isSubmitting] = useHttp()
  const [editorValue, setEditorValue] = useState({    value: RichTextEditor.createValueFromString(extra?extra.content:'','html') })
  useEffect(() => {
    if (putData) {
      handleClose({
        type: 'extra',
        shouldReload : true
      })
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      let payLoad = {
        title: formSave.title,
        content: formSave.content
      }

      // If existing extra
      if (extra) {
        sendRequest(api.entities.extras.update(payLoad, null, {id:extra.id}))
      } else { // else add new extra
        payLoad.eventId = eventId
        sendRequest(api.entities.extras.addNew(payLoad, null, null))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, extra])

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  }

  const onEditorChange = (value, setFieldValue) => {
    setEditorValue({value})
    setFieldValue('content', value.toString('html'))
  }

  return <Box className={classes.newExtra}>
    <Formik
      initialValues={{
          title: '',
          content: '',
          ...extra
        }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>

            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {extra ? 'Extra Information' : 'New Extra'}</Typography>
              </Grid>

              <Grid container spacing={1} className={classes.row}>
                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Details </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name='title' component={TextField} label='Title' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={12}>
                  <RichTextEditor multiline className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v)=>onEditorChange(v,setFieldValue)}/>
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.btns}>
                <Grid item xs={12} md={6}>
                  <Button variant='outlined' size='small' colour='white' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                </Grid>
              </Grid>

            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </Box>
})

export default ExtraEditNew
