import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {IconUserPicAddLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, Switch, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {FILES_URL, SIZE_LENGTH_PARTICIPANT_SUMMARY, SIZE_PROFILE_IMAGE, TITLES} from 'config'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import api from 'api'
import {useHttp} from 'api/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {FormHelperText, TextField as MUITextField} from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';


import CropImage from 'components/core/CropImage'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newParticipant: {
    maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }
  },
  btns: {
    textAlign: 'center'
  },
  textFieldToInput: {
    borderRadius: '6px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    width: '100%',
    border: `1px solid #2c3d4f`,
    padding: theme.spacing(2)
  },
  userFound:
    {
      backgroundColor: 'white',
      height: '30px',
      color: 'black',
      zIndex: 1,
      borderLeft: `1px solid #2c3d4f`,
      borderRight: `1px solid #2c3d4f`,
      borderBottom: `1px solid #2c3d4f`,
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      padding: theme.spacing(2),
      userSelect:'none',
      cursor:'pointer',
      textAlign:'center'
    }
})

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  profession: Yup.string().required('This field is required'),
  instituteOrCompany: Yup.string().required('This field is required'),
  email: Yup.string().email('Invalid email address').required('This field is required')
})

const UploadProfileImage = withStyles(styles)(({setFormData, formData, values, participant, size}) => {
  const [cropImage, setCropImage] = useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width) && (img.height < size.height)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }

    fr.readAsDataURL(acceptedFiles[0])
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const setImage = (image) => {
    setFormData({...formData, image: image})
    setCropImage(false)
  }

  return <RootRef rootRef={ref}>
    {cropImage && <CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage}
                             setOpen={setCropImage} setImage={setImage}/>}
    <Box {...rootProps} display='flex' flexDirection='column'>
      <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
      <Button size='small' variant='outlined' colour='white'>{participant ? 'Change' : 'Select'} Image</Button>
      {!participant && <Box display='flex' mt={2} mb={2}> Image should be at least 180x180px </Box>}
    </Box>
  </RootRef>
})

const ProfileImage = withStyles(styles)(({classes, formData, values, refToImage}) => {

  return <>
    {formData.hasOwnProperty('image') && formData.image !== null ? <Box className={classes.userPhoto}><img
      src={(typeof formData.image.name == 'string') ? URL.createObjectURL(formData.image) : `${FILES_URL}/${formData.image}`}
      alt={``} ref={refToImage}/></Box> : !formData.image ? <IconUserPicAddLight/> : ''}
  </>
})

const ParticipantEditNew = withStyles(styles)(({classes, participant, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData, setFormData] = useState({...participant})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [user, setUser] = useState()
  const [getUserInfo, userData] = useHttp()
  const [showUserFound, setShowUserFound] = useState(false)
  const [userEmail, setUserEmail] = useState()
  const [formikValues, setFormikValues] = useState({
    firstName: '',
    lastName: '',
    image: '',
    profession: '',
    instituteOrCompany: '',
    email: '',
    summary: '',
    ...formData,
    title: formData.title === null ? '-' : findUserTitle(formData.title)
  })
  const searchTimeout = useRef(null);
  const imageRef = useRef(null)

  useEffect(() => {
    setIsSubmitting(isLoading && !hasError)
  }, [isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])


  useEffect(() => {
    if (userData) {
      setUser({...userData, "keynote": false})
      setShowUserFound(true);
    }
// eslint-disable-next-line
  }, [userData])

  useEffect(() => {
    if (formSave) {
      let formDataObj = new FormData();

      const {image, ...formDataItems} = formSave
      if (image) {
        formDataObj.append('image', image)
      }

      const payLoad = {
        ...formDataItems,
        eventId: eventId,
        title: formDataItems.title === '-' ? null : formDataItems.title
      }
console.log(payLoad);
      const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'});
      formDataObj.append('participant', blob)

      // If existing speaker
      if (participant) {
        sendRequest(api.entities.participants.update(formDataObj, null, {id: participant.id}, true))
      } else { // else add new speaker
        sendRequest(api.entities.participants.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, participant])

  const handleEmailFind = (email) => {
    setUserEmail(email)
    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(() => {
      getUserInfo(api.entities.user.getByEmail(null, {email: email, errorHandling: false}))
    }, 700)

  }

  function findUserTitle(title) {

    switch (title) {
      case 'Professor':
        return 'Prof.'
      case 'Prof.':
        return 'Prof.'
      case 'Mr.':
        return 'Mr.'
      case 'Mr':
        return 'Mr.'
      case 'Mx':
        return 'Mx.'
      case 'Ms':
        return 'Ms.'
      case 'Dr':
        return 'Dr.'
      case 'Dr.':
        return 'Dr.'
      default:
        return ''
    }
  }

  const associateUser = () => {
    setShowUserFound(false)
    setFormikValues(null)
    setFormData(null)
    if (user.image)
      fetch(`${FILES_URL}/${user.image}`)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], user.firstName, blob)
          setFormData({...formData, image: file})
        })

    const newUser = {
      ...formData,
      firstName: user.firstName,
      lastName: user.lastName,
      profession: user.profession !== null ? user.profession : '',
      instituteOrCompany: user.instituteOrCompany !== null ? user.instituteOrCompany : '',
      email: userEmail,
      summary: user.summary,
      title: findUserTitle(user.title),
      keynote: user.keynote !==null ? user.keynote : false
      
    }
    setFormikValues(newUser)
    setFormData(newUser)
    setUser(null)
  }

  return <Box className={classes.newParticipant}><ThemeProvider theme={theme}>
    <Formik
      initialValues={formikValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, image: formData.image}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, touched}) => {

        return <>
          <Form className={classes.form}>
            <Box display='flex' flexDirection='row'>

              <Box className={classes.container}>
                <Grid container spacing={1} className={classes.row}>
                  <Typography variant='h5'
                              component='h1'> {participant ? 'Speaker Information' : 'New Speaker'}</Typography>
                </Grid>

                <Box display='flex' alignSelf='center' flexDirection='column' alignItems='center'
                     justifyContent='center'>
                  <Box display='flex' mb={2}>
                    <ProfileImage formData={formData} refToImage={imageRef} values={values}/>
                  </Box>
                  <Box display='flex' mb={2}>
                    <UploadProfileImage setFormData={setFormData} setFieldValue={setFieldValue} values={values}
                                        participant={participant} size={SIZE_PROFILE_IMAGE}/>
                  </Box>
                </Box>
                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Details </Typography>
                </Grid>
                <Grid container spacing={1} className={classes.row}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field label='Email' component={MUITextField} type='text' name='email' required
                             variant='filled' autocomplete='off' autoComplete='off'
                             inputProps={{
                               autocomplete: 'off',
                               form: {
                                 autocomplete: 'off',
                               }
                             }}
                             onClick={()=>{user && setShowUserFound(!showUserFound)}}
                             disabled={isSubmitting} fullWidth value={values.email} onChange={(e) => {
                        setFieldValue('email', e.target.value)
                        !errors.email && touched && e.target.value.length > 1 && handleEmailFind(e.target.value)
                      }}
                      />
                      {errors && errors.email && touched &&
                      <FormHelperText error variant='filled'> {errors.email}</FormHelperText>}
                      {user && showUserFound &&
                      <ClickAwayListener onClickAway={()=>setShowUserFound(false)}>
                      <Grid item xs={12}>
                        <Box className={classes.userFound} onClick={associateUser}>
                          <Typography align='center' variant='subtitle2' color='primary'>User already exists</Typography>
                          <Typography align='center' variant='caption'>{user.title} {user.firstName} {user.lastName}</Typography>
                        </Box>
                      </Grid>
                      </ClickAwayListener>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      <Field type='select' labelId='categoryLabel' displayEmpty name='title' component={Select}
                             label='Title' variant='filled' required fullWidth disabled={isSubmitting} defaultValue=''>
                        {TITLES.map((title, i) => {
                          return <MenuItem value={title} key={i}>{title}</MenuItem>
                        })}
                      </Field>
                      <InputLabel variant='filled' required>Title</InputLabel>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={9}>
                    <Field type='text' name='firstName' component={TextField} required label='First Name'
                           variant='filled'
                           fullWidth disabled={isSubmitting}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field type='text' name='lastName' component={TextField} required label='Last Name' variant='filled'
                           fullWidth
                           disabled={isSubmitting}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field type='text' name='profession' component={TextField} required label='Position'
                           variant='filled'
                           fullWidth disabled={isSubmitting}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field type='text' name='instituteOrCompany' required component={TextField}
                           label='Affiliation / Company'
                           variant='filled' fullWidth disabled={isSubmitting}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Field type='text' name='summary' component={TextField} id='Summary' label='Summary'
                           variant='filled'
                           fullWidth multiline maxLength={SIZE_LENGTH_PARTICIPANT_SUMMARY}
                           rows={4} disabled={isSubmitting}/>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid item container justify='space-between' alignContent='center' alignItems={'center'}>
                      <Grid item>
                        Keynote Speaker
                      </Grid>
                      <Grid item>
                        <Field type="checkbox" name="keynote" component={Switch} disabled={isSubmitting}/>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

                <Grid container spacing={3} className={classes.btns}>
                  <Grid item xs={12} md={6}>
                    <Button variant='outlined' size='small' colour='white' onClick={handleClose}
                            disabled={isSubmitting}> Cancel </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Form>
        </>
      }}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default ParticipantEditNew
