import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import ReactPlayer from 'react-player'
import {imageFile} from 'utils'
import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import LaunchIcon from '@material-ui/icons/Launch'
import EllipsisText from 'react-ellipsis-text'
import debounce from 'lodash.debounce'
import FileCanvas from 'components/core/OpenTok/Components/FilePresenting/FileCanvas'
import QuestionCanvas from 'components/core/OpenTok/Components/QuestionCanvas'
import OpenTok from 'components/core/OpenTok/Components/OpenTok'
import SpeakerCanvas from 'components/core/OpenTok/Components/SpeakerCanvas'

const styles = theme => ({
  videoWrapper: {
    width: '100%',
    position: 'relative',
    paddingTop: '56.25%',
    backgroundColor: 'black',
    overflow: 'hidden',
    'transition-property': 'all',
    'transition-duration' : '0.5s',
  },
  videoContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1
  },
  videoWithToolbar: {
    height: '100%'
  },
  titleContainer: {},
  deviceButtons: {
    position: 'absolute',
    zIndex: 5,
    right: theme.spacing(1),
    bottom: theme.spacing(1)
  },
  toggleButton: {
    // backgroundColor: 'rgba(255,255,255,0.5)'
    backgroundColor: `${palette['very-light-pink']} `

  },
  toggleButtonSelected: {
    // backgroundColor: 'rgba(255,255,255,0.5)'
    // backgroundColor: 'rgba(13, 199, 229, 0.2) !important'
    // backgroundColor: `${palette['very-light-pink']} !important`
  },
  toggleButtonGroup: {
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  bannerContainer: {
    position: 'absolute',
    boxSizing: 'border-box',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    fontSize: '28px',
    fontWeight: 'bolder',
    textShadow: '3px 3px #00001111',
    color: 'white',
    padding: theme.spacing(4),
    textAlign: 'center',
  },
  previewMessage: {
    zIndex: 4,
    color: 'white',
    position: 'absolute',
    bottom: 8,
    left: 8,
    fontSize: '13'
  },
  bannerBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.3)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  externalLink: {
    color: 'white'
  },
  fullscreen: {
    width: '100vw',
    height: '100vh',
  },
  videoControlsFullscreen: {
    position: 'absolute',
    bottom: 0,
    zIndex: 9999
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1200
  },
  withToolbar: {
    paddingTop: 'calc(56.25% + 48px)',
  },
  videoControls: {
    'transition-property': 'all',
    'transition-duration' : '0.5s',
  },
  controlsToolbar: {
    position: 'absolute',
    zIndex: 1,
    bottom: 0,
    left: 0,
    width: '100%',
    height: 64,
    'transition-property': 'all',
    'transition-duration' : '0.5s',
    background: 'linear-gradient(to bottom, rgba(125,185,232,0) 0%,rgba(0,0,0,1) 100%)',
    opacity: 0.9,
    '&:hover' : {
      opacity: 1
    }
  }
})

// const OpenTokWrapper = (props) => {
//   const [showChildren,setShowChildren] = useState(true)
//
//   useEffect(()=>{
//     setShowChildren(false)
//   },[props.sessionState.token])
//
//   useEffect(()=>{
//     if (!showChildren) {
//       setShowChildren(true)
//     }
//   },[showChildren])
//
//   return showChildren&&<OpenTok {...props} />
// }

const VideoContainer = withStyles(styles)(({classes, raisedHands, sessionState, presentingQuestion, presentingFile, publishingCanvas, presentingFilePage, presentingSpeaker, setPresentingQuestion, setPresentingSpeaker, inCall, isFullscreen, selectedSpeaker, selectedQuestion, setIsFullscreen, canvasVideoTrack, setCanvasVideoTrack, presentationFile, setPresentationFile, deviceSetup, setDeviceSetup, allHandlers}) => {

  const {
    participants,
    participantId,
    isStarted,
    layoutMode,
    useHLS,
    useURL,
    useRecording,
    background,
    videoURL,
    roomText,
    recordingUrl,
    isExternalService,
    serviceName,
    useOpenTok,
    isBackstage,
    token
  } = sessionState

  // Participants required
  const speakerObj = participants.find((s)=>s.id===participantId)
  const canSpeak = speakerObj&&speakerObj.canBroadcast

  const showingBanner = layoutMode === 1
  const canBroadcast = isStarted && !showingBanner && canSpeak

  const calcMaxWidthRatio = () => {
    const topDiff = 128
    const bottomDiff=64+175
    let availableHeight = window.innerHeight - topDiff - bottomDiff - 16
    return availableHeight * 1.77777778
  }

  const [maxWidthRatio, setMaxWidthRatio] = useState()

  const resizeVideo = () => {
    setMaxWidthRatio(calcMaxWidthRatio())
  }
  const debounceresizeVideo = debounce(
    resizeVideo,
    200
  )

  const fullScreenModeSet = (event) => {
    if (document.fullscreenElement) {
      setIsFullscreen(true)
      // setStreaming({...streaming, fullscreenMode: true})
    } else {
      setIsFullscreen(false)
      // setStreaming({...streaming, fullscreenMode: false})
    }

  }

  // const goPrevPage = () => {
  //   const newPage = presentingFilePage-1
  //   if (presentationFile.pages&&presentationFile.pages[newPage+1]) {
  //     setStreaming({...streaming, presentingFilePage: newPage})
  //   }
  // }
  //
  // const goNextPage = () => {
  //   const newPage = presentingFilePage+1
  //   if (presentationFile.pages&&presentationFile.pages[newPage+1]) {
  //     setStreaming({...streaming, presentingFilePage: newPage})
  //   }
  // }

  // const handleKeyPress = (e) => {
  //   if (e.keyCode===39) {
  //     goNextPage()
  //   }
  //   if (e.keyCode===37) {
  //     goPrevPage()
  //   }
  // }

  useEffect(()=>{
      //resize listener
      window.addEventListener('resize', debounceresizeVideo);
      resizeVideo()
      document.addEventListener('fullscreenchange', fullScreenModeSet)
      // document.addEventListener("keydown", handleKeyPress)

      return () => {
        window.removeEventListener('resize', debounceresizeVideo)
        document.removeEventListener('fullscreenchange', fullScreenModeSet)
        // document.removeEventListener('keydown',handleKeyPress)
      }
    // eslint-disable-next-line
  },[])

  // useEffect(()=>{
  //   setMaxWidthRatio(calcMaxWidthRatio())
  //   // eslint-disable-next-line
  // },[fullscreenMode, presentingFile])

  useEffect(()=>{
    setMaxWidthRatio(calcMaxWidthRatio())
    // eslint-disable-next-line
  },[isFullscreen,presentingFile,presentingQuestion,presentingSpeaker])

  const videoMaxWidth = () => {
    let maxWidth = maxWidthRatio
    // if (isViewer) {
    //   return maxWidthRatio+130
    // }
    if (isFullscreen) {
      maxWidth+=200
    }
    if (!!presentingFile) {
      maxWidth-=340
    }
    if (!!presentingQuestion||!!presentingSpeaker) {
      maxWidth-=440
    }
    // if (session.status.videoStreamType!==STREAM_VIDEO_TYPES.ANYMEETS_BROADCAST) {
    //   maxWidth+=210
    // }
    return maxWidth
  }
  return <>
      <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' alignContent='center' height={'100%'} width={'100%'}>
        <Box display='flex' width={'100%'} maxWidth={videoMaxWidth()} style={{transition:'width 0.6s ease'}}>
          <Box display='flex' className={classes.videoWrapper}>

        <Box display='flex' className={clsx(classes.videoContainer, canBroadcast&&classes.videoWithToolbar)}
             justifyContent='center' alignContent='center' alignItems='center'>
          {publishingCanvas&&presentingFile&&presentationFile&&<FileCanvas setCanvasVideoTrack={setCanvasVideoTrack} presentationFile={presentationFile} pageNumber={presentingFilePage} />}
          {presentingQuestion&&<QuestionCanvas presentingSpeaker={presentingSpeaker} selectedQuestion={selectedQuestion} setCanvasVideoTrack={setCanvasVideoTrack} setPublishingCanvas={allHandlers.setPublishingCanvas} />}
          {presentingSpeaker&&<SpeakerCanvas presentingSpeaker={presentingSpeaker} selectedSpeaker={selectedSpeaker} setCanvasVideoTrack={setCanvasVideoTrack} setPublishingCanvas={allHandlers.setPublishingCanvas} />}

          {/*{useOpenTok && <OpenTokWrapper raisedHands={raisedHands} sessionState={sessionState} setPresentingSpeaker={setPresentingSpeaker} setPresentingQuestion={setPresentingQuestion} presentingSpeaker={presentingSpeaker} presentingQuestion={presentingQuestion} inCall={inCall} presentationFile={presentationFile} setStreaming={setStreaming} streaming={streaming} canvasVideoTrack={canvasVideoTrack} deviceSetup={deviceSetup} /> }*/}
          {useOpenTok && <OpenTok raisedHands={raisedHands} sessionState={sessionState} {...allHandlers} key={token}/> }

          {useHLS && (!useRecording) && (!useURL) && <>
            <ReactPlayer
              className={clsx(classes.reactPlayer)}
              url={videoURL}
              width='100%'
              height='100%'
              playing={true}
              controls={true}
            />

          </>}

          {useRecording &&
          <ReactPlayer
            key={recordingUrl}
            className={classes.reactPlayer}
            url={recordingUrl}
            width='100%'
            height='100%'
            playing={true}
            controls={true}
          />}

        </Box>

        {(!useHLS) && (!useURL) && (!useRecording) && <Box display='flex' style={{
           backgroundImage: `url(${((background&&!isStarted) || (background&&!speakerObj&&isBackstage) || showingBanner) ? imageFile(background) : ''})`
        }} className={classes.bannerContainer} alignContent='center' justifyContent='center' alignItems='center' key={videoURL}>
          {showingBanner &&
          <Box className={classes.bannerBackdrop}>
            <Box display='flex' zIndex={3}>
              {roomText}
            </Box>
          </Box>}
        </Box>}

        {useURL && (!useRecording) && <Box display='flex' style={{
          backgroundImage: `url(${background ? imageFile(background) : ''})`
        }} className={classes.bannerContainer} alignContent='center' justifyContent='center' alignItems='center'>
          <Box className={classes.bannerBackdrop}>
            <Box display='flex' zIndex={3}>
              {isExternalService&&<>
              <a href={videoURL} title={'External link'} target='_blank' rel="noopener noreferrer" className={classes.externalLink}> {serviceName} </a>
              </>}
              {!isExternalService&&<>
                <a href={videoURL} title={'External link'} target='_blank' rel="noopener noreferrer" className={classes.externalLink}> <EllipsisText text={videoURL} length={40} /> </a>
              </>}
            </Box>
            <Box display='flex' zIndex={3} pl={1} pt={1}>
              <a href={videoURL} title={'External link'} target='_blank' rel="noopener noreferrer" className={classes.externalLink}> <LaunchIcon /> </a>
            </Box>
          </Box>
        </Box>}

        </Box>

        </Box>
      </Box>
  </>
})

export default VideoContainer
