import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Switch as MuiSwitch} from '@material-ui/core'
import {theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'

const styles = theme => ({
  root: {

  },
  label: {

  }
})

const Switch = withStyles(styles)(({classes, children, ...restProps}) => {
  return <><ThemeProvider theme={theme}>
    <MuiSwitch {...restProps} classes={{label: classes.label }} className={classes.root} />
  </ThemeProvider>
  </>
})

export default Switch