import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import Hero from 'components/core/Hero'
import HomeSplashScreen from 'assets/home.png'
import {palette} from 'theme'
import * as Yup from 'yup'
import {useHttp} from 'api/core'
import {useSnackbar} from 'notistack'
import api from 'api'
import SubFooter from 'components/core/Footer/SubFooter'

const styles = theme => ({
  form: {
    width: '100%'
    // width: theme.spacing(84)
  },
  eventWebSection: {
    marginTop: theme.spacing(4),
    backgroundColor: 'white'
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  body: Yup.string().required('This field is required'),
  subject: Yup.string().required('This field is required'),
  from: Yup.string().email('Invalid email address').required('This field is required'),
})

const FormSection = withStyles(styles)(({classes, plan}) => {
  const plans = {1:'free',2:'xs',3:'sm',4:'md',5:'lg',6:'xl',7:'monthly'}
  const [sendData, data, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState()
  const {enqueueSnackbar} = useSnackbar()

  const handleSubmition = (values) => {
    const payLoad = {
      ...values
    }
    sendData(api.entities.contact.sendMessage(payLoad))
  }

  useEffect(() => {
    if (data) {
      setIsSubmitting(false)
      if (data && data.status === 204) {
        enqueueSnackbar(`Thank you for your interest! We will get in touch the soonest.`, {variant: 'success'})
      }
    }
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (hasError) {
      setIsSubmitting(false)
    }
  }, [hasError])

  const disabledForm = isSubmitting || isLoading

  return <Box className={classes.searchSection}>
    <Formik
      initialValues={{name: '', from: '', subject: `Interest on ${plans[plan] ? `${plans[plan]} plan` : 'all plans'}`, body: `I am interested on learning more about ${plans[plan] ? `${plans[plan]} plan ...` : 'all plans ...'}`}}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setIsSubmitting(true)
        handleSubmition(values)
      }}>
      {({errors}) => (
        <Form className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Field type='text' name='name' component={TextField} label='Name' variant='filled' fullWidth
                     disabled={disabledForm} required/>
            </Grid>
            <Grid item xs={12}>
              <Field type='email' name='from' component={TextField} label='Email' variant='filled' fullWidth
                     disabled={disabledForm} required/>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='subject' component={TextField} label='Subject' variant='filled' fullWidth
                     disabled={true}/>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='body' component={TextField} label='Message' variant='filled' fullWidth multiline
                     rows='7' disabled={disabledForm} required/>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign='center' mt={4}>
                <Button type='submit' color='secondary' disabled={disabledForm}>Send your message</Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
})

const GetThisPlan = withStyles(styles)(({classes}) => {
  const {plan} = useParams()
  return <>
    <Page title='Get in touch' backgroundColor={'#f8f8f8'}>
      <Hero tagLine={`Let's get in touch`} image={HomeSplashScreen} minHeight={280}/>
      <Container>

        <Grid container flexDirection='column' justify='center' spacing={2}>
          <Grid item container xs={12} sm={5}>
            <Grid item xs={12}>
              <Box color={palette['greyish-brown']} p={1} pt={6} textAlign='center' fontSize={36} fontWeight='bold'>Nice
                to meet you!</Box>
            </Grid>
            <Grid item xs={12}>
              <Box color={palette['greyish-brown']} p={2} pb={5} textAlign='center' fontSize={22}>We will be in touch to
                discuss your needs and arrange a demo</Box>
            </Grid>
            <Grid item>
              <Box className={classes.form}>
                <FormSection plan={plan}/>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {/*<Grid container className={classes.eventWebSection} justify='center' spacing={2}>*/}
      {/*  <Grid item container xs={12} sm={8}>*/}
      {/*    <Grid item xs={12}>*/}
      {/*      Content?*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Grid>*/}

    </Page>
    <SubFooter/>
  </>
})

export default GetThisPlan
