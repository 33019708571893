import React from 'react'
import {withStyles} from '@material-ui/core'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {imageFile} from 'utils'
import {Link} from 'react-router-dom'
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image'

const styles = theme => ({
  container: {
    position: 'relative',
    // width: '100%',
    // height: '100%',
    // minHeight: 'auto',
    margin: '5px auto',
    color: palette['greyish-brown'],
    // '& div': {
    //   width: '100%'
    // },
    // '& i::before': {
    //   borderWidth: `10px`
    // }
  },

  sponsorImage: {
    width: '120px',
    height: '120px',
    padding: theme.spacing(2)
  },
  // slider: {
  //   position: 'absolute',
  //   top: 0,
  //   left: 0,
  //   width: '100%',
  //   height: '100%'
  // },
  imgContainer: {
    width: 'initial !important',
    position: 'relative'
  },
  link: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 9
  }
})

const SponsorsCarousel = withStyles(styles)(({classes, sponsors, eventId}) => {
  return <Slide>
     {sponsors.map((sponsor,i)=><Grid item container justify='center' alignContent='center' alignItems='center' key={i}>
       <Grid item className={classes.imgContainer}>
         <img src={imageFile(sponsor.logoId)} alt={sponsor.name} className={classes.sponsorImage}/>
         <Link to={sponsor.online?`/sponsor/${sponsor.id}`:`/event/${eventId}/sponsors/${sponsor.id}`} className={classes.link} target='_blank'> </Link>
       </Grid>
     </Grid>)}
    </Slide>
})

export default SponsorsCarousel
