import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import {useParams, withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import {useAuth} from 'auth/AuthController'
import {useSnackbar} from 'notistack'
import Box from '@material-ui/core/Box'

import ProfileSettings from 'components/pages/MyProfile/ProfileSettings'
import ProfilePasswordChange from 'components/pages/MyProfile/ProfilePasswordChange'
import UserOccurrences from "components/pages/MyProfile/UserOccurrences";
import Plans from 'components/pages/MyProfile/Plans'

const styles = theme => ({
  ticketsList: {
    maxWidth: '1280px',
    marginBottom: theme.spacing(8)
  }
})


const MyProfile = withStyles(styles)(({classes, profile, history, ...props}) => {


  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isSubmitting] = useHttp()
  const [, , , userInfo, setUserInfo] = useAuth()
  const [formData, setFormData] = useState()
  const {enqueueSnackbar} = useSnackbar()
  const {profilePage} = useParams()
  const [getOccurences, occurrencesData] = useHttp([])

  useEffect(()=>{
    setFormData(userInfo.info)
    // eslint-disable-next-line
  }, [profilePage])
  
  useEffect(() => {
    getOccurences(api.entities.user.getUserOccurences())
    // eslint-disable-next-line
  }, [sendRequest]);


  useEffect(() => {
    if (putData) {
      if (putData.firstName) {
        enqueueSnackbar('Successfully updated your profile', {variant: 'success'})
        setUserInfo({...userInfo, info: {...userInfo.info, ...putData}})
      }
    }
// eslint-disable-next-line
  }, [putData])



  useEffect(() => {
    if (formSave) {
      let formDataObj = new FormData();

      const {pictureId, ...formDataItems} = formSave

      if (pictureId) {
        formDataObj.append('picture', pictureId)
      }

      const payLoad = {
        ...formDataItems,
        deletePicture: false,
        title: formDataItems.title === '-' ? null : formDataItems.title
      }

      const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'});
      formDataObj.append('user', blob)

      sendRequest(api.entities.user.updateInfo(formDataObj, null, null, true))

      setFormSave(undefined)
    }
  }, [formSave, sendRequest, profile])


  const navigation = {
    routes: [
      {
        name: "Profile Settings",
        path: "settings",
      },
      // {
      //   name: 'Privacy Settings',
      //   path: 'privacy'
      // },
      {
        name: "Change Password",
        path: "password",
      },
      {
        name: "Privacy",
        path: "user-occurrences",
      },
      {
        separator: true,
        path: "",
      },
      // {
      //   name: "Plans",
      //   path: "plans",
      //   // hidden: true
      //   // icon: AssessmentIcon
      // },
    ],
  };

  if (!formData) {
    return <> </>
  }

  return (
    <Page title="My Profile" display="flex" backgroundColor={palette["pale-grey"]}>
      <Adrawer title="My Profile" mobileTitle="My Profile" navigation={navigation}>
        <Box>
          <Grid container spacing={2} className={classes.ticketsList}>
            {profilePage === "settings" && <ProfileSettings classes={classes} formData={formData} setFormData={setFormData} setFormSave={setFormSave} isSubmitting={isSubmitting} profile={profile} {...props} />}
            {profilePage === "privacy" && <> </>}
            {profilePage === "password" && <ProfilePasswordChange classes={classes} formData={formData} setFormData={setFormData} setFormSave={setFormSave} isSubmitting={isSubmitting} profile={profile} {...props} />}
            {profilePage === "user-occurrences" && <UserOccurrences classes={classes} occurrencesData={occurrencesData?.occurrences} profile={profile} {...props} />}
            {profilePage === "plans" && <Plans> </Plans>}
          </Grid>
        </Box>
      </Adrawer>
    </Page>
  );
})

export default withRouter(MyProfile)
