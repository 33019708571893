import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {Box, Grid, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { palette } from "theme";
import { withRouter } from "react-router-dom";
import { useMetaData } from "components/core/MetaProvider";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import BottomHero from "components/core/BottomHero";
import CardMedia from '@material-ui/core/CardMedia';
import SponsorVideo from "assets/sponsor-video.mp4";
import SessionVideo from 'assets/session-info-chatvid.mp4'
import Tick from "assets/Tick_icon.svg";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = (theme) => ({
	hero: {
		//minWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		maxWidth: "768px",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "500px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "1.2em",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "40px",
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "36px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "1.2em",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "27px",
		},
	},
	paperTitle: {
		fontSize: "36px",
		textAlign: "center",
		color: "#3d4d59",
		marginBottom: "30px",
		fontFamily: "Nunito , Karla",
		fontWeight: "600",
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
		},
	},
	subtitle: {
		maxWidth: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Nunito , Karla",
		fontSize: "20px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#4a4a4a",
		display: "block",
		fontWeight: "600",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
			marginBottom: theme.spacing(1),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	pageContent: {
		marginTop: "50px",
	},
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 0px",
			marginTop: "30px",
		},
	},
	iconSet: {
		maxWidth: "580px",
		//		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "20px 25px",
		//textAlign: "center",
		height: "150px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
			textAlign: "left",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			//	width: "300px",
			//	margin: "10px auto",
			fontFamily: "Karla",
			//	textAlign: "center",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
		marginTop: "5px",
	},
	video: {
		maxWidth: "960px",
		borderRadius: "20px",
		marginTop: "20px",
		boxShadow: "0px 0px 50px 0px #ddd",
	},
	tickIcon: {
		width: "15px",
		lineHeight: "15px",
		margin: "0 4px 0 11px",
	},
});

const Events = withStyles(styles)(({ classes, history }) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({ title: "Virtual Events" });
		// eslint-disable-next-line
	}, []);
/*
	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};*/

	return (
		<>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>Amazing virtual experience</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						We put the session chair in the director’s seat and give you full
						control over your sessions.
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Box style={{width: "100%", typography: "body1"}}>
						<CardMedia
							id="video1"
							className={classes.video}
							component="video"
							src={SessionVideo}
							title="Virtual sessions"
							controls
							img={<PlayArrowIcon />}
						/>
					</Box>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					{/*<Box>
						<button className={classes.buttonTry}>Try now</button>
					</Box>*/}
				</Grid>
			</Grid>

			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.paperTitle}
				>
					<Grid item>Virtual sessions</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Mix-and-match</h3>
								<p>
									Use Anymeets, Zoom, Teams, Webex, YouTube or use a combination
									to hold your virtual sessions!
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Poster sessions</h3>
								<p>
									Give each poster presenter their own private virtual room to
									present and speak with participants.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Auto-recording</h3>
								<p>
									Automatically record sessions held via Anymeets. Make recorded
									sessions immediately available in the Agenda.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Chat and Q&amp;A</h3>
								<p>
									Increase engagement by offering a dedicated Q&A and Chat. For
									post-session discussions, presenters can use breakout rooms.
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				style={{padding: "50px 15px", backgroundColor: "#f0f9ff"}}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Grid item className={classes.secondaryTitle}>
						Does your event have sponsors? We’ve got you covered!
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>Our dynamic virtual sponsor booths include:</Grid>
				</Grid>
				<Grid item lg={12}></Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{maxWidth: "768px"}}
				>
					<Grid container item lg={12} sm={12} xs={12}
						justify="center"
						alignContent="center"
						alignItems="center"
					>
						<Grid item lg={6} mb={2} >
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>
								<span
									style={{
										display: "inline-block",
										width: "270px",
										verticalAlign: "top",
										marginLeft: "10px",
										marginBottom: "20px"
									}}
								>
									Multiple sponsor representatives
								</span>
							</Typography>
						</Grid>
						<Grid item lg={6} mb={2} >
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>
								<span
									style={{
										display: "inline-block",
										width: "270px",
										verticalAlign: "top",
										marginLeft: "10px",
										marginBottom: "20px"
									}}
								>
									One-on-one video chat
								</span>
							</Typography>
						</Grid>
						<Grid item lg={6} mb={2} >
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>
								<span
									style={{
										display: "inline-block",
										width: "270px",
										verticalAlign: "top",
										marginLeft: "10px",
										marginBottom: "20px"
									}}
								>
									Promo video when booth is "empty"
								</span>
							</Typography>
						</Grid>
						<Grid item lg={6} mb={2} >
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>
								<span
									style={{
										display: "inline-block",
										width: "270px",
										verticalAlign: "top",
										marginLeft: "10px",
										marginBottom: "20px"
									}}
								>
									Lead-retrieval tools and metrics
								</span>
							</Typography>
						</Grid>
					</Grid>
					{/* <Grid item lg={7} sm={7} xs={12}>
						<CardMedia
							className={classes.video}
							component="video"
							image={SponsorVideo}
							title="Call for Papers"
							controls
						/>
					</Grid> */}
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.paperTitle}
				>
					<Grid item>Support for live events</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Instructions and guides</h3>
								<p>
									Our extensive database of guides, videos, and instructions
									will get you started and help you discover our more unique
									features.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Training</h3>
								<p>
									We offer training to speakers and presenters to ensure that
									they can easily find their sessions and present.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Virtual event coordination</h3>
								<p>
									Virtual events are still new. Our experienced virtual event
									coordinators can advise you on the details of your event.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} sx={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Tech support</h3>
								<p>
									Our dedicated personnel is available during your virtual/live
									event to handle any troubleshooting needs that may arise.
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</>
	);
});

export default withRouter(Events);
