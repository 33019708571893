import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import VirtualSession from "assets/virtual-session.png";

const styles = (theme) => ({
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	title: {
		maxWidth: "600px",
		color: "#fff",
		fontSize: "36px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "0.3px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "27px",
			padding: theme.spacing(2),
		},
	},
	subtitle: {
		maxWidth: "642px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "20px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#fff",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "19px",
			padding: theme.spacing(1),
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
	},
});

const HowItWorksMiddle = withStyles(styles)(({classes}) => {
	return (
		<Grid container className={classes.horizontalHero}>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>The Anymeets Virtual Session</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Use our flagship virtual session modes, Anymeets Broadcast and
						Anymeets Live, to hold virtual presentations, panel discussions,
						lectures, and keynotes with an unlimited audience.
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Box style={{width: "100%", typography: "body1"}}>
						<Grid
							item
							container
							justify="center"
							alignContent="center"
							alignItems="center"
						>
							<Box style={{maxWidth: "768px", width: "100%"}}>
								<img
									style={{
										maxWidth: "768px",
										width: "100%",
										borderRadius: "20px",
									}}
									src={VirtualSession}
									alt="Anymeets Virtual Session"
								/>
							</Box>
						</Grid>
					</Box>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
					style={{marginTop: "15px"}}
				>
					<Grid item>
						For smaller sessions, workshops, tutorials, and trainings (up to 50
						participants), set up your sessions with Jitsi at no extra cost!
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
});

export default HowItWorksMiddle;
