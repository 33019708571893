import { Box, Grid, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import api from 'api'
import Edit from '@material-ui/icons/Edit'
import Info from '@material-ui/icons/Info'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { palette } from 'theme'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import UserEdit from 'components/core/UserEdit'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/core/Button'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { Redirect } from 'react-router-dom'
import { useHttp } from 'api/core'
import withStyles from '@material-ui/styles/withStyles'
import moment from "moment";

const styles = theme => ({
  container: {

  }
})

const Users = withStyles(styles)(({ classes }) =>
{

  // Hooks
  const [getData, data, , hasError] = useHttp()
  const [setActivate, activateData, ,] = useHttp()
  const [users, setUsers] = useState()
  const [editUser, setEditUser] = useState()
  const [getUserInfo, UserInfo, , hasErrors] = useHttp()
  const [openUserInfo, setOpenUserInfo] = useState(false)
  const [activateUser, setActivateUser] = useState()
  const resolveRef = useRef()
  const tableRef = useRef()

  useEffect(() =>
  {
    getData(api.entities.admin.getUsers(null, null, {}))
    // eslint-disable-next-line
  }, [])

  useEffect(() =>
  {
    if (data)
    {
      setUsers(data)
    }
  }, [data])

  useEffect(() =>
  {
    if (users)
    {
      resolveRef.current({
        page: users.pageable.pageNumber,
        totalCount: users.totalElements,
        data: users.content
      })
    }
  }, [users])

  const handleEditClose = () =>
  {
    setEditUser(false)
  }
  const handleViewUserInfo = async (user) =>
  {
    await getUserInfo(api.entities.admin.getJoindEvents(null, null, { id: user.id }))
    setOpenUserInfo(true)
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  const handleActivateClose = (activate) =>
  {
    if (activate === true)
    {
      setActivate(api.entities.admin.activateUser(null, null, { id: activateUser.id }))
    }
    setActivateUser(false)
    setOpenUserInfo(false)
  }

  useEffect(() =>
  {
    if (activateData)
    {
      tableRef.current && tableRef.current.onQueryChange()
    }
  }, [activateData])

  if (hasError)
  {
    return <Redirect to={'/'} />
  }

  return <>

    {editUser && <Dialog onClose={handleEditClose} open={!!editUser}>
      <UserEdit profile={editUser} handleClose={handleEditClose} setSuccessful={(a) => { console.log(a) }} />
    </Dialog>}
    {activateUser && <>
      <Dialog
        open={activateUser}
        onClose={handleActivateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Activate user</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to activate user?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleActivateClose()} size='small' variant='outlined' color='primary'>
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button onClick={() => { handleActivateClose(true) }} size='small' autoFocus color='primary'>
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>}
    {openUserInfo && UserInfo && <>
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={openUserInfo}
        onClose={setOpenUserInfo}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">User event info</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead style={{ textAlign: "left", backgroundColor: "rgb(155, 155, 155)", color: "rgb(255, 255, 255)", fontWeight: "bold", fontSize: "14px", boxSizing: "border-box" }}>
                  <th style={{ padding: "15px 20px" }}>Event Id</th>
                  <th style={{ padding: "15px 20px" }}>Event Name</th>
                  {/* <th>Start date</th>
                  <th>End date</th> */}
                  {/* <th>Published</th> */}
                </thead>
                <tbody>
                  {UserInfo.map((event, index) => (
                    <tr key={index} style={{ borderBottom: "1px solid rgba(224, 224, 224, 1)" }}>
                      <td style={{ padding: "15px 20px", fontSize: "14px" }}>{event.id}</td>
                      <td style={{ padding: "15px 20px", fontSize: "14px" }}>{event.name}</td>
                      {/* <td>{moment(event.startDate).tz("Europe/Athens").format("DD MMM yyyy hh:mm A")}</td> */}
                      {/* <td>{moment(event.endDate).tz("Europe/Athens").format("DD MMM yyyy hh:mm A")}</td> */}
                      {/* <td>{!!event.published}</td> */}

                    </tr>)

                  )}
                </tbody>
              </table>
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleActivateClose()} size='small' variant='outlined' color='primary'>
                Close
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </>}
    <Grid item xs={12} sm={12} md={4}>
      <Typography component='h2' variant='h5' className={classes.title}>
        Users
      </Typography>
    </Grid>

    <Grid item xs>

    </Grid>

    <Grid item>
    </Grid>

    <Grid item>
    </Grid>

    <Grid item xs={12}>
      <MaterialTable
        key={users}
        icons={tableIcons}
        tableRef={tableRef}
        columns={[
          { title: 'First name', field: 'firstName' },
          { title: 'Last name', field: 'lastName' },
          { title: 'Email', field: 'email' },
          { title: 'Created at', field: 'createdAt', render: (row) => (moment(row.createdAt).tz("Europe/Athens").format("DD MMM yyyy hh:mm A")) },

        ]}
        data={query =>
          new Promise((resolve, reject) =>
          {
            getData(api.entities.admin.getUsers(null, { page: query.page + 1, items: query.pageSize, term: query.search },))
            resolveRef.current = resolve
          })}
        title=''
        // editable={{
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       const dataUpdate = [...reviewers]
        //       const index = oldData.tableData.id
        //       dataUpdate[index] = {...newData}
        //       setReviewers([...dataUpdate])
        //       const {valid, accepted, acceptedByManager, ...payLoad} = newData
        //       console.log(payLoad)
        //       // sendRequest(api.entities.manage.updateReviewer(payLoad,null,{id:oldData.id}))
        //       resolve()
        //     })
        // }}
        actions={[
          rowData => ({
            icon: Info,
            tooltip: 'View event info',
            disabled: !rowData.hasJoinedEvents,
            onClick: (event, rowData) =>
            {
              handleViewUserInfo(rowData)
            }
          }),
          // {
          //   icon: Info,
          //   tooltip: 'View info',
          //   disabled: !!rowData.hasJoinedEvents,
          //   onClick: (event, rowData) => {
          //     handleViewUserInfo(rowData)
          //   }
          // },          
          {
            icon: Edit,
            tooltip: 'Edit user',
            onClick: (event, rowData) =>
            {
              setEditUser(rowData)
            }
          },
          rowData => ({
            icon: AssignmentTurnedInIcon,
            tooltip: 'Activate user',
            disabled: rowData.activated,
            onClick: (event, rowData) =>
            {
              setActivateUser(rowData)
            }
          })
        ]}
        options={{
          pageSize: 10,
          actionsColumnIndex: 999,
          headerStyle: {
            backgroundColor: palette['brown-grey'],
            color: '#FFF',
            fontWeight: 'bold',
            fontSize: 14
          },
          rowStyle: {
            fontSize: 12,
            padding: 0
          }
        }}
      />
    </Grid>
  </>
})

export default Users
