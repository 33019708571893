import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import FieldText from 'components/core/FieldText'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  MailListCard: {
    maxWidth: '50%',
    minWidth: '450px'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})


const MailListCard = withStyles(styles)(({classes, mailList, editAction, deleteAction, ...props}) => {
  return <Box className={classes.MailListCard} {...props}><ThemeProvider theme={theme}>
    <Box className={classes.container} display='flex' alignItems={'flex-start'}>
      <Box className={classes.mailListBox} display='flex' justifyContent='center' p={1}>
        <Box minWidth={200} p={1}>
          <FieldText title='Mailing list name' value={mailList.name}/>
        </Box>
      </Box>
      <Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end'>
        <IconNotesBorderLight title='Edit Mail List' className={classes.editIcon} onClick={() => {
          editAction && editAction(mailList.id)
        }}/>
        <IconXBorderLight title='Delete Mail List' className={classes.deleteIcon} onClick={() => {
          deleteAction && deleteAction(mailList.id)
        }}/>
      </Box>
    </Box>
  </ThemeProvider>
  </Box>
})

export default MailListCard
