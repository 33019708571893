import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {Switch, TextField} from 'formik-material-ui'
import NumberFormat from 'react-number-format'
import {FormHelperText} from '@material-ui/core'

import {
  IconAddBorderLight,
  IconCalendarBorderDark,
  IconXBorderDark,
} from 'components/core/icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {FastField, Field, FieldArray, Form, Formik} from 'formik'
import {DatePicker} from '@material-ui/pickers'
import {useHttp} from 'api/core'
import {TextField as MUITextField} from '@material-ui/core'
import api from 'api'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import ReceiptIcon from '@material-ui/icons/Receipt'
import LockIcon from '@material-ui/icons/Lock'
import IconButton from '@material-ui/core/IconButton'
import Divider from '@material-ui/core/Divider'
import parseISO from 'date-fns/parseISO'
import set from 'date-fns/set'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import moment from "moment";

const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newTicketType: {
    // width: '580px'
  },
  row: {
    marginBottom: theme.spacing(5)
  },
  tabsBar: {
    backgroundColor: palette['very-light-pink']
  },
  tab: {
    width: '100%',
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.17)',
    padding: theme.spacing(2)
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    '&:focus': {
      backgroundColor: palette.aquamarine
    }
  },
  tabLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  sessionsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'scroll'
  },
  chip: {
    margin: theme.spacing(1),
    // maxWidth: '135px'
  },
  sessionsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll'
  },
})

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function NumberFormatCustom(props) {
  const {inputRef, onChange, ...other} = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value:  values.value == "0" ? "1" : ( values.value>= "100" ? "100" : values.value),
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}


const CouponEdit = withStyles(styles)(({classes, coupon, values, arrayHelpers, index, setFieldValue, isSubmitting, codeError}) => {
  const handleChangeDiscount = (value, setFieldValue, field) =>
  {    
    setFieldValue(field, value)
  }

  return <Grid item container spacing={2} justify='center' alignItems='center' alignContent='center'>

    <Grid item xs={2}>
      <Field type='checkbox' name={`coupons[${index}].enabled`} component={Switch} disabled={isSubmitting}/>
    </Grid>
    <Grid item xs={2}>
      <FastField type='text' name={`coupons[${index}].discountPercentage`} onClick={event => event.stopPropagation()}
                 component={TextField} label='Discount'
                 variant='filled' fullWidth
                 value={values.numberformat}
                 onChange={(e) => handleChangeDiscount(e.target.value, setFieldValue, `coupons[${index}].discountPercentage`)}
                 id="formatted-numberformat-input" disabled={isSubmitting}
                 InputProps={{
                   inputComponent: NumberFormatCustom,
                 }}
      />
    </Grid>
    <Grid item xs={8}>
      <FastField type='text' name={`coupons[${ index }].code`}
                onClick={event => event.stopPropagation()}
                component={TextField} label='Code'
                variant='filled' fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: <InputAdornment position='end'>
                <IconButton aria-label='delete coupon' onClick={() => arrayHelpers.remove(index)}>
                  <IconXBorderDark/>
                </IconButton>
        </InputAdornment>
        }} />
        {codeError === true &&  (
            <FormHelperText error variant="filled">
                <span style={{ fontWeight: 600, fontSize: "14px" }}>Code must contain only letters and/or numbers.</span>
            </FormHelperText>
        )}      
    </Grid>
    <Grid item xs={12}>
      <Divider/>
    </Grid>
  </Grid>
})

const TicketTypeEditNew = withStyles(styles)(({classes, handleClose, unlimited, setSuccessful, ticket, eventId, freeOnly, sessions, eventInfo}) => {
  const [formSave, setFormSave] = useState();
  const [selectedPrivateSessions, setPrivateSessions] = useState([]);
  const [putData, data, isLoading, hasError] = useHttp();
  const [tab, setTab] = useState(0);
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  const [codeError, setCodeError] = useState([false])

  useEffect(() => {
    if (formSave) {
      let payLoad = {
        ...formSave,
        eventId: eventId,
        quantity: unlimited ? 100000 : formSave.quantity,
        privateSessionIds: formSave.privateSessionIds,
      };

      if (ticket) {
        putData(api.entities.tickets.update(payLoad, null, {id: ticket.id}));
      } else {
        putData(api.entities.tickets.addNew(payLoad));
      }
      setFormSave(undefined);
    }
  }, [unlimited, eventId, formSave, putData, ticket]);

  useEffect(() => {
    if (data) {
      handleClose();
      if (ticket) {
        setSuccessful();
      } else {
        setSuccessful(true);
      }
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sessions) {
      const totalPrivateSessions = [];
      if (ticket.privateSessionIds) {
        sessions.forEach((session) => {
          ticket.privateSessionIds.forEach((ticketSessionId) => {
            if (ticketSessionId === session.id) totalPrivateSessions.push(session);
          });
        });
        setPrivateSessions(totalPrivateSessions);
      }
    }
    //eslint-disable-next-line
  }, [sessions]);

  const addSession = (pos, setFieldValue) => {
    let newSessions = [...selectedPrivateSessions];
    if (newSessions.findIndex((session) => session.id === sessions[pos].id) === -1) {
      setPrivateSessions([...selectedPrivateSessions, sessions[pos]]);
      let fieldValuePrivateSessions = [];
      newSessions.forEach((session) => {
        fieldValuePrivateSessions.push(session.id);
      });
      fieldValuePrivateSessions.push(sessions[pos].id);
      setFieldValue("privateSessionIds", fieldValuePrivateSessions);
    }
  };

  const deleteSession = (pos, setFieldValue) => {
    let newSessions = [...selectedPrivateSessions];
    newSessions.splice(pos, 1);
    setPrivateSessions(newSessions);
    let fieldValuePrivateSessions = [];
    newSessions.forEach((session) => {
      fieldValuePrivateSessions.push(session.id);
    });
    setFieldValue("privateSessionIds", fieldValuePrivateSessions);
  };
  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (timeZones) {
      const zone = timeZones.find((x) => x.id === eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticket, timeZones]);

  const isSubmitting = isLoading && !hasError;

  return (
    <Box className={classes.newTicketType}>
      <ThemeProvider theme={theme}>
        <Formik
          initialValues={{
            ordinal: "",
            name: "",
            quantity: 1,
            minQuantityToBuy: 1,
            maxQuantityToBuy: 1,
            attendeesPerTicket: 1,
            privateSessionIds: ticket.privateSessionIds === null ? [] : ticket.privateSessionIds,
            price: 0,
            hidden: false,
            ...ticket,
            availableFrom: ticket ? parseISO(ticket.availableFrom) : new Date(new Date().setHours(0, 0, 0, 0)),
            availableTo: ticket ? parseISO(ticket.availableTo) : new Date(new Date().setHours(23, 59, 59, 0)),
            coupons: ticket.coupons ? ticket.coupons : [],
          }}
          // validationSchema={validationSchema}
          onSubmit={(values, {setSubmitting}) => {
            const newValues = { ...ticket, ...values };
            
            const errorArray = values.coupons.map((x, index) => {
              const codeType = /^[A-Za-z0-9-]+$/;
                return !codeType.test(x.code)
            })
            if (errorArray.includes(true))
            {
              setCodeError(errorArray)              
            } else
            {
              setFormSave(newValues);              
            }
          }}>
          {({errors, submitForm, setFieldValue, values}) => (
            <Form className={classes.form}>
              <Box className={classes.container}>
                <Grid item xs={12}>
                  <Grid container spacing={1} className={classes.row}>
                    <Typography variant="h5" component="h1">
                      {" "}
                      {ticket ? "Ticket" : "New Ticket"}
                    </Typography>
                  </Grid>

                  <Grid item container spacing={1} className={classes.Tabs}>
                    <Grid item xs>
                      <Chip
                        size="medium"
                        className={clsx(classes.tab, tab === 0 && classes.activeTab)}
                        onClick={() => {
                          setTab(0);
                        }}
                        label={
                          <Box className={classes.tabLabel}>
                            <ReceiptIcon /> &nbsp; Ticket info{" "}
                          </Box>
                        }
                        color={"secondary"}
                      />
                    </Grid>

                    <Grid item xs>
                      <Chip
                        size="medium"
                        className={clsx(classes.tab, tab === 1 && classes.activeTab)}
                        onClick={() => {
                          setTab(1);
                        }}
                        label={
                          <Box className={classes.tabLabel}>
                            <LocalOfferIcon /> &nbsp; Coupons{" "}
                          </Box>
                        }
                        color={"secondary"}
                      />
                    </Grid>

                    <Grid item xs>
                      <Chip
                        size="medium"
                        className={clsx(classes.tab, tab === 2 && classes.activeTab)}
                        onClick={() => {
                          setTab(2);
                        }}
                        label={
                          <Box className={classes.tabLabel}>
                            <LockIcon /> &nbsp; Private Sessions{" "}
                          </Box>
                        }
                        color={"secondary"}
                      />
                    </Grid>
                  </Grid>
                  {/*<Grid container spacing={1} className={classes.row}>*/}
                  {/*  <Typography variant='h5' component='h1'> {ticket ? 'Edit Ticket Type' : 'New Ticket Type'} </Typography>*/}
                  {/*</Grid>*/}
                  <TabPanel value={tab} index={0}>
                    <Grid container spacing={1} className={classes.row}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                          {" "}
                          Ticket Details{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Field type="text" name="ordinal" component={TextField} label="Order" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <Grid item xs>
                        <Field type="text" name="name" component={TextField} label="Name" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <br />
                    </Grid>

                    <Grid container spacing={1} className={classes.row}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                          {" "}
                          Price & Quantities{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="price" component={TextField} label="Price" variant="filled" fullWidth disabled={isSubmitting || freeOnly} />
                      </Grid>
                      <Grid item xs={4}>
                        {!unlimited && <Field type="text" name="quantity" component={TextField} label="Quantity" variant="filled" fullWidth disabled={isSubmitting} />}
                        {unlimited && <MUITextField variant="filled" fullWidth disabled value={"∞"} label="Quantity" />}
                      </Grid>
                      <Grid item xs={4}>
                        {" "}
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="minQuantityToBuy" component={TextField} label="Min Quantity to buy" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="maxQuantityToBuy" component={TextField} label="Max Quantity to buy" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="attendeesPerTicket" component={TextField} label="Attendees / ticket" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} className={classes.row}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                          {" "}
                          Availability timeframe{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          format="DD/MM/yyyy"
                          strictCompareDates={true}
                          name="availableFrom"
                          label="Available from"
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("availableFrom", moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT00:00:00.000Z"));
                            //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT00:00:00.000Z").toString());
                          }}
                          value={values.availableFrom}
                          fullWidth
                          disabled={isSubmitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconCalendarBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          format="DD/MM/yyyy"
                          strictCompareDates={true}
                          name="availableTo"
                          label="Available until"
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("availableTo", moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT23:59:59.000Z"));
                            //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT23:59:59.000Z").toString());
                          }}
                          value={values.availableTo}
                          fullWidth
                          disabled={isSubmitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconCalendarBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid container spacing={2} style={{paddingLeft: 8, paddingTop: 16}}>
                        <Grid item spacing={2}>
                          <Field type="checkbox" name="hidden" component={Switch} disabled={isSubmitting} />
                          Hidden Ticket
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <Grid container spacing={1} className={classes.row}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                          {" "}
                          Coupons{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FieldArray
                          name="coupons"
                          render={(arrayHelpers) => (
                            <Grid item container spacing={2}>
                              <Grid item xs={12}>
                                <Divider />
                              </Grid>
                              {values.coupons.length !== 0 && (
                                <>
                                  <Grid item container spacing={2}>
                                    <Grid item xs={2}>
                                      Enabled
                                    </Grid>
                                    <Grid item xs={2}>
                                      Discount
                                    </Grid>
                                    <Grid item xs={8}>
                                      Coupon code
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Divider />
                                  </Grid>
                                </>
                              )}
                              <Grid item xs={12}>
                                <Box minHeight={"330px"}>
                                  {values.coupons.length === 0 && (
                                    <Box height={320} justifyContent="center" alignContent="center" alignItems="center" display="flex">
                                      <Box display="flex">No coupons</Box>
                                    </Box>
                                  )}
                                  {values.coupons.length !== 0 && (
                                    <Grid item>
                                      {values.coupons.map((coupon, index) => (
                                        <CouponEdit setFieldValue={setFieldValue} coupon={coupon} arrayHelpers={arrayHelpers} values={values} index={index} isSubmitting={isSubmitting} codeError={codeError[index]}/>
                                      ))}
                                    </Grid>
                                  )}
                                </Box>
                              </Grid>
                              <Grid item container spacing={2} justify="flex-end" alignContent="flex-end" alignItems="flex-end">
                                <Grid item>
                                  <Button
                                    size="small"
                                    variant="outlined"
                                    colour="white"
                                    onClick={() =>
                                      arrayHelpers.push({
                                        label: "no-label",
                                        discountPercentage: 1,
                                        code: "",
                                        enabled: false,
                                      })
                                    }>
                                    <IconAddBorderLight /> Add Coupon
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <Grid container spacing={1} className={classes.row}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2">
                          {" "}
                          Private Sessions{" "}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={4}>
                            {sessions.length === 0 ? (
                              <Box display="flex" justifyContent="center" alignItems="center" className={classes.sessionsList} aria-label="sessions">
                                <Box display="flex">No private sessions available</Box>
                              </Box>
                            ) : (
                              <List component="nav" className={classes.sessionsList} aria-label="sessions">
                                {sessions.map((session, i) => {
                                  return (
                                    <ListItem
                                      button
                                      key={i}
                                      onClick={() => {
                                        addSession(i, setFieldValue);
                                      }}>
                                      <ListItemText primary={`${session.title}`} />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={8}>
                            <Box minHeight={149} width={"100%"} className={classes.sessionsBox}>
                              {selectedPrivateSessions &&
                                selectedPrivateSessions.length > 0 &&
                                selectedPrivateSessions.map((session, i) => {
                                  return (
                                    <Chip
                                      size="small"
                                      key={i}
                                      label={`${session.title}`}
                                      onDelete={() => {
                                        deleteSession(i, setFieldValue);
                                      }}
                                      className={classes.chip}
                                    />
                                  );
                                })}
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Button variant="outlined" size="small" colour="white" onClick={handleClose}>
                      {" "}
                      Cancel{" "}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button size="small" type="submit">
                      {" "}
                      {ticket ? "Save" : "Create"}{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    </Box>
  );
});

export default TicketTypeEditNew
