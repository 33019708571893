import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography} from '@material-ui/core'
import {SPONSOR_TYPES} from 'config'
import React from 'react'
import {palette} from 'theme'
import {Link, useParams} from 'react-router-dom'
import SponsorBox from 'components/core/SponsorBox'
import Grid from '@material-ui/core/Grid'
import { checkHost } from 'utils'
import language from "components/language";

const styles = theme => ({
    sponsorLogo: {
        overflow: 'hidden',
        '& img': {
            width: '100%',
            height: 'auto',
            // backgroundColor: palette.imageBackgroundColor
        }
    },
    sponsorName: {
        color: palette['greyish-brown']
    }
})

const SponsorsLogosList = withStyles(styles)(({classes, event}) => {

    if (!event.sponsors) return <></>
    if (event.sponsors && event.sponsors.length === 0) return <></>

    const sponsors = event.sponsors

    const sponsorsPerType = {11:[], 1: [], 2: [], 3: [], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[],12:[],13:[]}
    sponsors.forEach((sponsor) => sponsorsPerType[sponsor.type].push(sponsor))

    const {slug} = useParams()
    const host = checkHost()

    let baseURL = `/event/${event.eventInfo.id}`

    if (slug) {
        baseURL = `/${slug}`
    } else if (host) {
        baseURL = ``
    }

    return <Box p={4}><Grid container spacing={2}>
        <Grid item container>
            <Grid item xs={12}>
                <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.sponsors}</Box>
            </Grid>
        </Grid>

        {
            [11,1,2,3,4,5,6,7,8,9,10,12,13].map((key)=>{
                if (sponsorsPerType[key].length===0) return <></>
                return <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h6'> {SPONSOR_TYPES[key]}{key===11||key===10?'s':''} </Typography>
                    </Grid>

                    {sponsorsPerType[key].map((sponsor, y) =>
                      <Link to={sponsor.online?`/sponsor/${sponsor.id}`:`${baseURL}/sponsors/${sponsor.id}`} className={classes.link}>
                          <SponsorBox sponsor={sponsor} />
                      </Link>
                    )}
                </Grid>
            })
        }

    </Grid> </Box>
})

export default SponsorsLogosList
