import React, {useEffect, useRef, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {Box, Divider, Select} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import LinearProgress from '@material-ui/core/LinearProgress'
import VideocamIcon from '@material-ui/icons/Videocam'
import VideocamOffIcon from '@material-ui/icons/VideocamOff'
import MicIcon from '@material-ui/icons/Mic'
import MicOffIcon from '@material-ui/icons/MicOff'
import Switch from '@material-ui/core/Switch'
import Typography from '@material-ui/core/Typography'
import AccessChrome from 'assets/microphone_access_chrome.gif'
import AccessFirefox from 'assets/microphone_access_firefox.gif'
import {isChrome, isFirefox} from 'react-device-detect'
import Tooltip from '@material-ui/core/Tooltip'
import FormHelperText from '@material-ui/core/FormHelperText'
// import cameraAvatar from 'assets/cameraAvatar.svg'
import Button from '@material-ui/core/Button'
// import DialogTitle from '@material-ui/core/DialogTitle'

const styles = theme => ({
  paper: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& img': {
      width: '100%',
      height: 'auto'
    }
  },
  content: {
    color: palette['greyish-brown']
  },
  formControl: {
    width: '100%'
  },
  video: {
    width: '100%',
    backgroundColor: '#0c0c0c',
    borderRadius: '6px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    minHeight: 280,
    '& video': {
      display: 'flex',
      width: '100%',
      height: 'auto'
    }
  }
})
// Meter class that generates a number correlated to audio volume.
// The meter class itself displays nothing, but it makes the
// instantaneous and time-decaying volumes available for inspection.
// It also reports on the fraction of samples that were at or near
// the top of the measurement range.
function SoundMeter(context) {
  this.context = context;
  this.instant = 0.0;
  this.slow = 0.0;
  this.clip = 0.0;
  this.script = context.createScriptProcessor(2048, 1, 1);
  const that = this;
  this.script.onaudioprocess = function(event) {
    const input = event.inputBuffer.getChannelData(0);
    let i;
    let sum = 0.0;
    let clipcount = 0;
    for (i = 0; i < input.length; ++i) {
      sum += input[i] * input[i];
      if (Math.abs(input[i]) > 0.99) {
        clipcount += 1;
      }
    }
    that.instant = Math.sqrt(sum / input.length);
    that.slow = 0.95 * that.slow + 0.05 * that.instant;
    that.clip = clipcount / input.length;
  };
}

SoundMeter.prototype.connectToSource = function(stream, callback) {
  console.log('SoundMeter connecting');
  try {
    this.mic = this.context.createMediaStreamSource(stream);
    this.mic.connect(this.script);
    // necessary to make sample run, but should not be.
    this.script.connect(this.context.destination);
    if (typeof callback !== 'undefined') {
      callback(null);
    }
  } catch (e) {
    console.error(e);
    if (typeof callback !== 'undefined') {
      callback(e);
    }
  }
};

SoundMeter.prototype.stop = function() {
  console.log('SoundMeter stopping');
  this.mic.disconnect();
  this.script.disconnect();
};



const PreviewDialog = withStyles(styles)(({classes,open, handleClose, videoContainerRef, setAudioSource, setVideoSource, videoSource, audioSource,publishVideo, setPublishVideo, publishAudio, setPublishAudio, isViewer,inCall, isChair, isOnlinePoster, openDeviceSetup, closeDeviceSetup}) => {
  const [hasAudio, setHasAudio] = useState(null)
  const [hasVideo, setHasVideo] = useState(null)
  const [isAllowedAudio, setIsAllowedAudio] = useState(null)
  const [isAllowedVideo, setIsAllowedVideo] = useState(null)
  const [checkedAvailability, setCheckedAvailability] = useState()
  const [videoDevices, setVideoDevices] = useState([])
  const [audioDevices, setAudioDevices] = useState([])
  const [selectedAudioDevice, setSelectedAudioDevice] = useState(audioSource?audioSource:undefined)
  const [selectedVideoDevice, setSelectedVideoDevice] = useState(videoSource?videoSource:undefined)
  // eslint-disable-next-line
  const [audioContent, setAudioContent] = useState()
  // eslint-disable-next-line
  // const [meterRefresh, setMeterRefresh] = useState()
  const [instantMeter, setInstantMeter] = useState()
  const [slowMeter, setSlowMeter] = useState()
  // eslint-disable-next-line
  const [clipMeter, setClipMeter] = useState()
  const [cameraOn, setCameraOn] = useState(publishVideo||(!audioSource&&!videoSource))
  const [micOn, setMicOn] = useState(publishAudio||(!audioSource&&!videoSource))

  const poller = useRef(null)
  const streamRef = useRef()
  const soundMeter = useRef()
  const audioContext = useRef()
  const audioStream = useRef()
  const videoStream = useRef()

  useEffect(()=>{
    // const css = document.createElement('script')
    // css.rel = 'stylesheet'
    // css.type = 'text/css'
    // css.href = `/assets/opentok-hardware-setup.css`
    // document.getElementsByTagName('HEAD')[0].appendChild(css)
    //
    // const script = document.createElement('script')
    // script.onload = () => {
    //   setReady(true)
    // }
    // script.src = 'https://static.opentok.com/hardware-setup/v1/js/opentok-hardware-setup.js'
    // document.body.appendChild(script)


    // Older browsers might not implement mediaDevices at all, so we set an empty object first
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }

    // Some browsers partially implement mediaDevices. We can't just assign an object
    // with getUserMedia as it would overwrite existing properties.
    // Here, we will just add the getUserMedia property if it's missing.
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function(constraints) {

        // First get ahold of the legacy getUserMedia, if present
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        // Some browsers just don't implement it - return a rejected promise with an error
        // to keep a consistent interface
        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
        }

        // Otherwise, wrap the call to the old navigator.getUserMedia with a Promise
        return new Promise(function(resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      }
    }

    // Get Audio
    navigator.mediaDevices.getUserMedia({audio:true})
      .then(function(stream) {
        /* use the stream */
        if (stream) {
          setHasAudio(true)
          setIsAllowedAudio(true)
          audioStream.current = stream
        }

      })
      .catch(function(err) {

        console.log(err.name)

        /* handle the error */
        switch (err.name) {
          case 'NotReadableError': {
            setHasAudio(false)
            setIsAllowedAudio(false)
            break
          }
          case 'NotFoundError': {
            setHasAudio(false)
            setIsAllowedAudio(true)
            break
          }
          case 'NotAllowedError': {
            setIsAllowedAudio(false)
            break
          }
          case 'SecurityError': {
            setIsAllowedAudio(false)
            break
          }
          default:
            break
        }

      });

    // Get Video
    navigator.mediaDevices.getUserMedia({video:true})
      .then(function(stream) {
        /* use the stream */
        if (stream) {
          setHasVideo(true)
          setIsAllowedVideo(true)
          videoStream.current = stream
        }
      })
      .catch(function(err) {

        console.log(err.name)

        /* handle the error */
        switch (err.name) {
          case 'NotReadableError': {
            setHasVideo(false)
            setIsAllowedVideo(false)
            break
          }
          case 'NotFoundError': {
            setHasVideo(false)
            setIsAllowedVideo(true)
            break
          }
          case 'NotAllowedError': {
            setIsAllowedVideo(false)
            break
          }
          case 'SecurityError': {
            setIsAllowedVideo(false)
            break
          }
          default:
            break
        }

      })

  },[])

  // Update state of availabilitiy of devices (initialization)
  useEffect(()=>{
    if (( hasAudio!==null || isAllowedAudio!==null) && (hasVideo!==null || isAllowedVideo!==null) ) {
      setCheckedAvailability(true)
    }
  },[hasAudio,hasVideo,isAllowedVideo,isAllowedAudio])

  useEffect(()=>{
    if (checkedAvailability) {

      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.')
        return
      }

      // List cameras and microphones
      navigator.mediaDevices.enumerateDevices()
        .then(function(devices) {

          const videoDevices = []
          const audioDevices = []

          devices.forEach(function(device) {
            const mediaDevice = {
              id: device.deviceId,
              label: device.label
            }

            if (device.kind==='videoinput') {
              videoDevices.push(mediaDevice)
            } else if (device.kind==='audioinput') {
              audioDevices.push(mediaDevice)
            }

            // console.log(device.kind + ': ' + device.label +
            //   ' id = ' + device.deviceId)
          })

          if (isAllowedVideo) {
            setVideoDevices(videoDevices)
            if (videoDevices.length&&!videoSource) {
              setSelectedVideoDevice(videoDevices[0].id)
            }
          }


          if (isAllowedAudio) {
            setAudioDevices(audioDevices)
            if (audioDevices.length&&!audioSource) {
              console.log('selected '+audioDevices[0].id)
              setSelectedAudioDevice(audioDevices[0].id)
            }
          }


        })
        .catch(function(err) {
          console.log(err.name + ': ' + err.message)
        })
    }
    //eslint-disable-next-line
  },[checkedAvailability])


  const startPreview = (stream) => {

    streamRef.current = stream
    document.getElementById('video-preview-camera').srcObject = stream

    soundMeter.current = new SoundMeter(audioContext.current);
    soundMeter.current.connectToSource(stream, function(e) {
      if (e) {
        alert(e);
        return;
      }
      poller.current = setInterval(() => {
        setInstantMeter(soundMeter.current.instant.toFixed(2))
        setSlowMeter(soundMeter.current.slow.toFixed(2)*1000)
        setClipMeter(soundMeter.current.clip)
      }, 400)
    })

    return navigator.mediaDevices.enumerateDevices()
  }

  useEffect(()=>{
    if (selectedAudioDevice&&open) {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => {
          track.stop()
        })
        clearInterval(poller.current)
        soundMeter.current&&soundMeter.current.stop()
        audioContext.current&&audioContext.current.close()
      }

      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        audioContext.current = new AudioContext();
      } catch (e) {
        alert('Web Audio API not supported.');
      }


      const constraints = {}

      if (selectedVideoDevice) {
        constraints.video = {deviceId: {exact: selectedVideoDevice}}
      }

      if (selectedAudioDevice) {
        constraints.audio = {deviceId: {exact: selectedAudioDevice}}
      }

      navigator.mediaDevices.getUserMedia(constraints).then(startPreview).catch((e)=>console.log(e))

    }
    //eslint-disable-next-line
  },[selectedAudioDevice, selectedVideoDevice])

  const joinSession = () => {
    streamRef.current&&streamRef.current.getTracks().forEach(track => {
      track.stop()
    })
    audioStream.current&&audioStream.current.getTracks().forEach(track => {
      track.stop()
    })
    videoStream.current&&videoStream.current.getTracks().forEach(track => {
      track.stop()
    })
    clearInterval(poller.current)
    soundMeter.current&&soundMeter.current.stop()
    streamRef.current = null
    audioContext.current&&audioContext.current.close()

    // setDeviceSetup({
    //   microphone: selectedAudioDevice,
    //   camera: selectedVideoDevice?selectedVideoDevice:false,
    //   startCamera: cameraOn,
    //   startMicrophone: micOn
    // })

    // setStreaming({
    //   ...streaming, publishAudio: micOn
    // })

    setAudioSource(selectedAudioDevice?selectedAudioDevice:false)
    setVideoSource(selectedVideoDevice?selectedVideoDevice:false)
    setPublishVideo(cameraOn)
    setPublishAudio(micOn)

    closeDeviceSetup()
  }

  const showControls = checkedAvailability&&isAllowedAudio

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='visibility-selection'
      classes={{paper:classes.paper}}
      maxWidth={'sm'}
      fullWidth
      disableScrollLock
      container={videoContainerRef.current}
    >
      {/*<DialogTitle id='alert-dialog-title'>Welcome to the Anymeets virtual session!</DialogTitle>*/}
      <DialogContent className={classes.content}>
        <Box pt={2} pb={2} id='alert-dialog-description' className={classes.content}>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!checkedAvailability&&<Grid item container spacing={2} justify='center' alignContent='center' alignItems='center'>

                <Grid item xs={12}>
                  <Typography variant='h5' align='center'>
                    Camera and microphone access required
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.paper} p={2}>
                    <Box display='flex'>
                      <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='subtitle1' align='center'>
                    Select <b>Allow</b> when your browser asks for permissions.
                  </Typography>
                </Grid>

              </Grid>}

              {showControls&&<Grid item container spacing={2}>

                <Grid item container spacing={2}>

                  <Grid item xs={12}>
                    <Box className={classes.video} >
                      <video id="video-preview-camera" playsInline autoPlay muted controls={false} width={'100%'} height={'auto'} style={{'opacity':cameraOn?1:0}} key={selectedVideoDevice}> </video>
                      {(!cameraOn)&&<cameraAvatar/>}
                    </Box>
                  </Grid>

                  {isViewer&&<Grid item xs={12}>
                    <b>Welcome to the Anymeets virtual session!</b> <br/>
                    You can use the Q&A to ask questions via text but you are also able
                    to raise your hand to ask questions, live. Please make sure your
                    camera and microphone work, or else you will not be able to ask questions.
                  </Grid>}

                  {isChair&&isOnlinePoster&&<Grid item xs={12}>
                    <b>Welcome to your poster session!</b> <br/> Up to 8 people can enter at the same time. Once your
                    session is full, participants are added to a waitlist. Use the “Audience” tab on the right
                    Admit people who are on the waitlist.
                  </Grid>}

                  <Grid item xs={12}>
                    <FormControl className={classes.formControl} key={selectedVideoDevice}>
                      <Select disableScrollLock disabled={videoDevices.length===0} labelId={`select-label-video`} variant='filled' displayEmpty fullWidth value={selectedVideoDevice} onChange={(e)=>setSelectedVideoDevice(e.target.value)}>
                        {videoDevices.map((option, i)=>{
                          return <MenuItem value={option.id} key={i}>{option.label}</MenuItem>
                        })}
                      </Select>
                      {!isAllowedVideo&&
                      <Tooltip title={<>
                        {(isChrome||isFirefox)&&<img src={isChrome?AccessChrome:AccessFirefox} alt={'How to allow access to camera'}/>}
                      </>}><FormHelperText className={classes.helperText}>
                        How to allow access to camera
                      </FormHelperText>
                      </Tooltip>
                      }
                      <InputLabel id={`select-label-video`} variant='filled'>
                        {videoDevices.length===0&&isAllowedVideo&&`Camera not available`}
                        {!isAllowedVideo&&`Camera access blocked`}
                        {isAllowedVideo&&videoDevices.length!==0&&`Camera`}
                      </InputLabel>
                    </FormControl>
                  </Grid>

                </Grid>

                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl} key={selectedAudioDevice}>
                      <Select disableScrollLock disabled={audioDevices.length===0} labelId={`select-label-audio`} variant='filled' displayEmpty fullWidth value={selectedAudioDevice} onChange={(e)=>setSelectedAudioDevice(e.target.value)}>
                        {audioDevices.map((option, i)=>{
                          return <MenuItem value={option.id} key={i}>{option.label}</MenuItem>
                        })}
                      </Select>
                      {/*<FormHelperText className={classes.helperText}>Select your microphone</FormHelperText>*/}
                      <InputLabel id={`select-label-audio`} variant='filled'>{audioDevices.length===0?`Microphone not available`:`Microphone`}{audioDevices.length!==0&&!isAllowedAudio&&`Microphone access blocked`}</InputLabel>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} key={instantMeter}>
                    <LinearProgress variant="determinate" value={slowMeter?slowMeter>100?100:slowMeter:0} />
                  </Grid>

                </Grid>

              </Grid>}
            </Grid>

            {showControls&&<>
              <Grid item xs={12} style={{paddingTop:'16px'}}>
                <Divider/>
              </Grid>
              <Grid item container spacing={2} justify='center' alignItems='center' alignContent='center'>
                <Grid item>
                  {cameraOn?<VideocamIcon/>:<VideocamOffIcon/>}
                </Grid>
                <Grid item>
                  <Switch disabled={videoDevices.length===0} inputProps={{ 'aria-label': 'enable disable camera' }} checked={cameraOn} onChange={()=>setCameraOn(!cameraOn)}/>
                </Grid>
                <Grid item>
                  {micOn?<MicIcon/>:<MicOffIcon/>}
                </Grid>
                <Grid item>
                  <Switch disabled={audioDevices.length===0} inputProps={{ 'aria-label': 'enable disable microphone' }} checked={micOn} onChange={()=>setMicOn(!micOn)}/>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='secondary' disabled={audioDevices.length===0} onClick={joinSession}>{`Continue`}</Button>
                </Grid>
              </Grid></>}

            {checkedAvailability&&(!isAllowedAudio)&&<Grid item container spacing={2} justify='center' alignContent='center' alignItems='center'>

              <Grid item xs={12}>
                <Typography variant='h5' align='center'>
                  Camera and microphone access required
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.paper} p={2}>
                  <Box display='flex'>
                    {(isChrome||isFirefox)&&!isAllowedAudio&&<img src={isChrome?AccessChrome:AccessFirefox}  alt={'How to allow access for audio'} />}
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Typography variant='subtitle1' align='center'>
                  In order to continue you must allow this webpage access to your camera and microphone.
                </Typography>
              </Grid>

            </Grid>}

          </Grid>
        </Box>
      </DialogContent>
      {/*<DialogActions>*/}
      {/*  <Button onClick={handleDisagree} size='smaller' variant='contained'  color='primary' disabled={isLoadingDel||isLoadingSet}>*/}
      {/*    Disagree*/}
      {/*  </Button>*/}
      {/*  <Button onClick={handleAgree} size='smaller' color='secondary'  variant='contained' autoFocus disabled={isLoadingDel||isLoadingSet}>*/}
      {/*    Agree*/}
      {/*  </Button>*/}
      {/*</DialogActions>*/}
    </Dialog>
  )
})

export default PreviewDialog