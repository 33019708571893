import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Box, Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import {useHttp} from 'api/core'
import api from 'api'
import PlanCardView from 'components/core/PlanCardView'
import {palette} from 'theme'
import AnyTabs from 'components/core/AnyTabs'
import Pagination from '@material-ui/lab/Pagination'

const styles = theme => ({
  noPlans:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '100px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  }
})

const PlansListing = withStyles(styles)(({classes, used, plans})=>{
  return <>
    <Grid item md={9} sm={12} xs={12} lg={6}>

      {plans.map((plan, i)=>{
        return <PlanCardView plan={plan} existing withEvents />
      })}

      {plans&&plans.length===0&&<Grid item xs={12}>
        <Grid item container className={classes.noPlans}>
          <Grid item> No plans available </Grid>
        </Grid>
      </Grid>}

    </Grid>

  </>
})



const Plans = withStyles(styles)(({classes, profile, history, ...props}) => {

  const [getData, data] = useHttp()
  const [plans, setPlans] = useState()
  const [used, setUsed] = useState(null)

  useEffect(()=>{
    // getData(api.entities.plans.getSubscriptions())
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setPlans(data)
    }
  },[data])

  useEffect(()=>{
    const pagePayload = {
      page : plans?plans.pageable.pageNumber+1:1
    }
    if (used!==null) {
      pagePayload.used = used
    }
    getData(api.entities.plans.getSubscriptions(null,pagePayload))
    //eslint-disable-next-line
  },[used])

  const handleChange = (e, page) => {
    const pagePayload = {
      page
    }
    if (used!==null) {
      pagePayload.used = used
    }
    getData(api.entities.plans.getSubscriptions(null,pagePayload))
  }

  if (!plans) {
    return <> </>
  }

  return <>


    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant='h5' component='h1'>Plans </Typography>
      </Grid>

      <Grid container item className={classes.navTabs}>
        <Grid item xs={12} sm={12}>
          <AnyTabs tabs={
            [
              {
                label: 'All',
                component: <PlansListing used={null} plans={plans.content} />,
                onClick: ()=>{
                  setUsed(null)
                }
              },
              {label: 'Used', component: <PlansListing used={true} plans={plans.content} />,
                onClick: ()=>{
                  setUsed(true)
                }},
              {
                label: 'Available',
                component: <PlansListing used={false} plans={plans.content} />,
                onClick: ()=>{
                  setUsed(false)
                }
              },
            ]
          }/>
        </Grid>
        <Grid item md={9} sm={12} xs={12} lg={6}>
          <Box pt={4} display='flex' flexDirection='row' justifyContent='flex-end'>
            <Box display='flex'>
              <Pagination hideNextButton={plans.last} hidePrevButton={plans.first}
                          count={plans.totalPages} page={plans.pageable.pageNumber + 1}
                          onChange={handleChange}/>
            </Box>
          </Box>
        </Grid>
      </Grid>


    </Grid>

  </>
})

export default Plans
