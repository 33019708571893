import withStyles from '@material-ui/styles/withStyles'
import {useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import {IconSpeakersBorderDark} from 'components/core/icons'
import {useHttp} from 'api/core'
import api from 'api'
import {Bar} from 'react-chartjs-2'
import {TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import {format} from "date-fns"
import clsx from 'clsx'
// import Badge from '@material-ui/core/Badge'
// import Fab from '@material-ui/core/Fab'
import {differenceInMinutes, parseISO} from 'date-fns'
// import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import TimelapseIcon from '@material-ui/icons/Timelapse'
// import Tooltip from '@material-ui/core/Tooltip'
import MaterialTable from 'material-table'
import Edit from '@material-ui/icons/Edit'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
// import Button from '@material-ui/core/Button'
import {GetApp} from '@material-ui/icons'
// import ThumbUpIcon from '@material-ui/icons/ThumbUp';
// import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TimelineIcon from '@material-ui/icons/Timeline';
const styles = theme => ({
  statBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    position: 'relative',
    minWidth: '300px',
    minHeight: '300px',
    margin: theme.spacing(2)
  },
  statTitle: {
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
    color: palette.aquamarine,
    textTransform: 'uppercase'
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold'
  },
  graphContainer: {
    maxWidth: '1400px'
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  sessionsBox: {
    padding: theme.spacing(6,2,2,2),
    alignItems: 'flex-start',
  },
  normalPadding: {
    padding: theme.spacing(2,2,2,2),
  },
  sessionRow: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxSizing: 'border-box'
  },
  sessionStats: {
    padding: theme.spacing(1,0,0,0),
    textAlign: 'center'
  },
  voteBtn: {
    // width: '24px',
    // height: '24px',
    // padding: theme.spacing(2),
    // border: `1px solid ${palette['very-light-pink']}`,
    // borderRadius: '50%',
    transition: 'opacity 0.3s',
    boxShadow: 'none !important',
    // backgroundColor: palette.aquamarine,
    // '&:hover': {
    //   transform: 'scale(1.1)',
    //   backgroundColor: palette.aquamarine,
    //   transition: 'all 200ms ease-in'
    // }
  },
  voteContainer: {
    // height: '80%'
  },
  thumbsUp: {
    // color: '#2ecc71'
    color: 'white',
  },
  thumbsDown: {
    // color: '#e74c3c'
    color: 'white',
  },
  disabledVoting: {
    opacity: 0.3
  },
  badgeRootLike: {
    // border: `1px solid ${palette['greyish-brown']}`,
    backgroundColor: '#2ecc71',
    color: 'white',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.1)',

  },
  badgeRootDisLike: {
    // border: `1px solid ${palette['greyish-brown']}`,
    backgroundColor: '#c0392b',
    color: 'white',
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 -2px 2px 0 rgba(0, 0, 0, 0.1)',

  },
  fabRoot: {
    boxShadow: 'none'
  },
  vote: {
    height: '129px'
  },
  emotion: {
    height: '148px'
  },
})

const calcData = (data) => {

  const defaultsDataset = {
    barPercentage: 0.5,
    maxBarThickness: 40,
    minBarLength: 65,
    backgroundColor: palette.graphColors
  }

  let statData = {}

  Object.keys(data).forEach((key, keyIndex)=>{
    statData[key]={
      labels: data[key].map((week,i)=>`Week ${week.week}`),
      datasets: [{
        ...defaultsDataset,
        data: data[key].map((week,i)=>week.total)
      }],
      total: data[key].reduce((a,b)=>a+b.total,0)
    }
  })

  return statData
}

const Statistics = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{
  const [getUserData, userData] = useHttp()
  const [getData, data, isLoading] = useHttp()
  const [CsvData, setCsvData,] = useState()
  const [getCSV, dataCSV, , , , csvExtra] = useHttp()

  const [statData, setStatData] = useState()
  const [sessions, setSessions] = useState()
  const {eventId} = useParams()
  const [users, setUsers] = useState()
  const resolveRef = useRef()

  // For Sponsor booth
  const [getBoothData, boothData] = useHttp()
  const [boothParticipation, setBoothParticipation] = useState()
  const resolveBoothRef = useRef()

  useEffect(()=>{
    if (boothData) {
      setBoothParticipation(boothData)
    }
  },[boothData])

  useEffect(()=>{
    if (boothParticipation&&resolveRef.current) {
      resolveBoothRef.current({
        page: boothParticipation.pageable.pageNumber,
        totalCount: boothParticipation.totalElements,
        data: boothParticipation.content
      })
    }
  },[boothParticipation])

  const downloadCSVBooths = (id, name) => {
    getCSV(api.entities.manage.downloadCSVAttendeesPerBooth(null, null, {id},false,{id, name, type: 'perBooth'}))
  }

  // For Rooms
  const [getRoomData, roomData] = useHttp()
  const [roomParticipation, setRoomParticipation] = useState()
  const resolveRoomRef = useRef()

  useEffect(()=>{
    if (roomData) {
      setRoomParticipation(roomData)
    }
  },[roomData])

  useEffect(()=>{
    if (roomParticipation&&resolveRef.current) {
      resolveRoomRef.current({
        page: roomParticipation.pageable.pageNumber,
        totalCount: roomParticipation.totalElements,
        data: roomParticipation.content
      })
    }
  },[roomParticipation])

  const downloadCSVRooms = (code, label) => {
    getCSV(api.entities.manage.downloadCSVAttendeesPerRoom(null, null, {code},false,{code, label, type: 'perRoom'}))
  }

  useEffect(()=>{
    getData(api.entities.manage.getStatistics(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setStatData(calcData(data.statistics))
      setSessions(data.statistics)
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({path:'/manage-events',name:'events'})
    }
    //eslint-disable-next-line
  },[data])

  useEffect(() => {
    getUserData(api.entities.manage.getParticipation(null, null, {id:eventId}))
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (userData) {
      setUsers(userData)
    }
  },[userData])

  useEffect(()=>{
    if (users&&resolveRef.current) {
      resolveRef.current({
        page: users.pageable.pageNumber,
        totalCount: users.totalElements,
        data: users.content
      })
    }
  },[users])


  const downloadCSV = (id) => {
    getCSV(api.entities.manage.downloadCSVAttendeesPerSession(null, null, {id},false,{id, type:'perSession'}))

  }

  const downloadCSVPerMinute = (id) => {
    getCSV(api.entities.manage.downloadCSVAttendeesPerSessionPerMinute(null, null, {id},false,{id, type:'perMinute'}))
  }

  useEffect(() => {
    if (dataCSV) {
      setCsvData(dataCSV)
    }
  }, [dataCSV])

  useEffect(() => {
    if (CsvData) {
      let csvURI = window.URL.createObjectURL(dataCSV[0])
      let downloadLink = document.createElement('a')
      downloadLink.href = csvURI
      if (csvExtra.type==='perMinute') {
        downloadLink.download = `${csvExtra.id}_attendees_per_minute.xlsx`
      }
      if (csvExtra.type==='perSession') {
        downloadLink.download = `${csvExtra.id}_attendees.xlsx`
      }
      if (csvExtra.type==='perRoom') {
        downloadLink.download = `${csvExtra.label}_attendees.xlsx`
      }
      if (csvExtra.type==='perBooth' ) {
        downloadLink.download = `${csvExtra.name}_attendees.xlsx`
      }
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
    // eslint-disable-next-line
  }, [CsvData])

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  if (!statData||isLoading||(!sessions)) return <> </>

  return <Fade in={true} timeout={TIME_FADE_IN}><Box>
    <Grid container className={classes.graphContainer}>

      <Grid item xs={12}>
        <Typography component='h2' variant='h5' className={classes.title}>
          Statistics
        </Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Page views</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.statBox}>
              <Typography component='h3' variant='h6' className={classes.statTitle}>WEEKLY</Typography>
              <Box display='flex'>
                <Bar
                  data={statData.pageViews}
                  // height={'98%'}
                  options={{legend:{display:false},scales:{xAxes:[{gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false},ticks:{beginAtZero:true}}]}}}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.statBox}>
              <Typography component='h3' variant='h6' className={classes.statTitle}>TOTAL</Typography>
              <Box> <IconSpeakersBorderDark/> </Box>
              <Typography component='h4' variant='h4' className={classes.statValue}> {statData.pageViews.total} </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Joins</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.statBox}>
              <Typography component='h3' variant='h6' className={classes.statTitle}>WEEKLY</Typography>
              <Box display='flex'>
                <Bar
                  data={statData.joins}
                  // height={'98%'}
                  options={{legend:{display:false},scales:{xAxes:[{gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false},ticks:{beginAtZero:true}}]}}}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box className={classes.statBox}>
              <Typography component='h3' variant='h6' className={classes.statTitle}>TOTAL</Typography>
              <Box> <IconSpeakersBorderDark/> </Box>
              <Typography component='h4' variant='h4' className={classes.statValue}> {statData.joins.total} </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Attendee participation</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={clsx(classes.statBox,classes.sessionsBox,classes.normalPadding)}>

              <MaterialTable
                key={users}
                icons={tableIcons}
                columns={[
                  { title: 'First name', field: 'firstName' },
                  { title: 'Last name', field: 'lastName' },
                  { title: 'Email', field: 'email'},
                  { title: 'Number of sessions', field: 'sessionCount'}
                ]}
                data={query=>
                  new Promise((resolve,reject)=>{
                    getUserData(api.entities.manage.getParticipation(null, {page:query.page+1, items:query.pageSize, term:query.search},{id:eventId}))
                    resolveRef.current = resolve
                  })}
                title=''
                options={{
                  pageSize: 10,
                  actionsColumnIndex:999,
                  headerStyle: {
                    backgroundColor: palette['cerulean-blue'],
                    color: '#FFF',
                    fontWeight: 'bold',
                    fontSize: 14,
                    // borderRadius: 4
                  },
                  rowStyle: {
                    fontSize: 12,
                    padding: 0
                  }
                }}
                style={{width:'100%', boxShadow: 'none'}}
              />
            </Box>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Sessions</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={clsx(classes.statBox,classes.sessionsBox,classes.normalPadding)}>

              <MaterialTable
                key={sessions.sessions.length}
                icons={tableIcons}
                columns={[
                  { title: 'ID', field: 'sessionId', width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Title', field: 'title', width: 100, cellStyle: { minWidth: 200 }},
                  { title: 'Date', field: 'startDate' , render: rowData => <>
                       {format(parseISO(rowData.startDate), 'LLL, dd hh:mm aa')}
                     </> },
                  { title: <TimelapseIcon />, field: 'startDate', render: rowData => <>
                      {differenceInMinutes(
                        parseISO(rowData.endDate),
                        parseISO(rowData.startDate)
                      )}'
                     </> },
                  { title: 'RSVP', field: 'rsvp', width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Polls', field: 'polls',width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Questions', field: 'questions', width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Chat', field: 'socialFeed', width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Thumb up', field: 'thumbsUp',width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                  { title: 'Thumb down', field: 'thumbsDown',width: 10, cellStyle: { maxWidth: 10, whiteSpace: "nowrap" }},
                ]}
                data={sessions.sessions}
                title=''
                localization={{
                  header: {
                    actions: 'Download'
                  }}
                }
                actions={[
                  {
                    icon: AssignmentIndIcon,
                    tooltip: 'Download participation',
                    onClick: (event, rowData) => {
                      downloadCSV(rowData.sessionId)
                    }
                  },
                  {
                    icon: TimelineIcon,
                    tooltip: 'Download histogram',
                    onClick: (event, rowData) => {
                      downloadCSVPerMinute(rowData.sessionId)
                    }
                  },
                  // {
                  //   icon: DeleteIcon,
                  //   tooltip: 'Delete reviewer',
                  //   onClick: (event, rowData) => alert("You deleted " + rowData.firstName)
                  // }
                ]}
                options={{
                  pageSize: 5,
                  actionsColumnIndex:999,
                  headerStyle: {
                    backgroundColor: palette['cerulean-blue'],
                    color: '#FFF',
                    fontWeight: 'bold',
                    fontSize: 14,
                  },
                  rowStyle: {
                    fontSize: 12,
                  }
                }}
                style={{width:'100%', boxShadow: 'none'}}
              />
            </Box>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Sponsor Booth participation</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={clsx(classes.statBox,classes.sessionsBox,classes.normalPadding)}>

              <MaterialTable
                key={users}
                icons={tableIcons}
                columns={[
                  { title: 'Sponsor', field: 'name' },
                  { title: 'Number of attendees', field: 'attendeesCount'}
                ]}
                data={query=>
                  new Promise((resolve,reject)=>{
                    getBoothData(api.entities.manage.getBoothParticipation(null, {page:query.page+1, items:query.pageSize, term:query.search},{id:eventId}))
                    resolveBoothRef.current = resolve
                  })}
                title=''
                localization={{
                  header: {
                    actions: 'Download'
                  }}
                }
                actions={[
                  {
                    icon: GetApp,
                    tooltip: 'Download excel file',
                    onClick: (event, rowData) => {
                      downloadCSVBooths(rowData.sponsorId, rowData.name)
                    }
                  },
                  // {
                  //   icon: DeleteIcon,
                  //   tooltip: 'Delete reviewer',
                  //   onClick: (event, rowData) => alert("You deleted " + rowData.firstName)
                  // }
                ]}
                options={{
                  pageSize: 10,
                  actionsColumnIndex:999,
                  headerStyle: {
                    backgroundColor: palette['cerulean-blue'],
                    color: '#FFF',
                    fontWeight: 'bold',
                    fontSize: 14,
                    paddingRight: 32
                  },
                  rowStyle: {
                    fontSize: 12,
                    padding: 0,
                    paddingRight: 32
                  }
                }}
                style={{width:'100%', boxShadow: 'none'}}
              />
            </Box>
          </Grid>

        </Grid>
      </Grid>

      <Grid item xs={12} md={12} lg={2}>
        <Typography component='h2' variant='h5' className={classes.title}>Room participation</Typography>
      </Grid>

      <Grid item xs={12} md={12} lg={10}>
        <Grid container>
          <Grid item xs={12}>
            <Box className={clsx(classes.statBox,classes.sessionsBox,classes.normalPadding)}>

              <MaterialTable
                key={users}
                icons={tableIcons}
                columns={[
                  { title: 'Room', field: 'label' },
                  { title: 'Number of attendees', field: 'attendeesCount'}
                ]}
                data={query=>
                  new Promise((resolve,reject)=>{
                    getRoomData(api.entities.manage.getRoomParticipation(null, {page:query.page+1, items:query.pageSize, term:query.search},{id:eventId}))
                    resolveRoomRef.current = resolve
                  })}
                title=''
                localization={{
                  header: {
                    actions: 'Download'
                  }}
                }
                actions={[
                  {
                    icon: GetApp,
                    tooltip: 'Download excel file',
                    onClick: (event, rowData) => {
                      downloadCSVRooms(rowData.code, rowData.label)
                    }
                  },
                  // {
                  //   icon: DeleteIcon,
                  //   tooltip: 'Delete reviewer',
                  //   onClick: (event, rowData) => alert("You deleted " + rowData.firstName)
                  // }
                ]}
                options={{
                  pageSize: 10,
                  actionsColumnIndex:999,
                  headerStyle: {
                    backgroundColor: palette['cerulean-blue'],
                    color: '#FFF',
                    fontWeight: 'bold',
                    fontSize: 14,
                    paddingRight: 32
                  },
                  rowStyle: {
                    fontSize: 12,
                    padding: 0,
                    paddingRight: 32
                  }
                }}
                style={{width:'100%', boxShadow: 'none'}}
              />
            </Box>
          </Grid>

        </Grid>
      </Grid>

    </Grid>
  </Box></Fade>
})

export default withRouter(Statistics)
