import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Switch from '@material-ui/core/Switch'
import withStyles from '@material-ui/styles/withStyles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import Typography from '@material-ui/core/Typography'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box'
import LivePollEditNew from 'components/core/OpenTok/Components/Feedback/LivePollEditNew'
import AddIcon from '@material-ui/icons/Add'
import ResultsDialog from 'components/core/OpenTok/Components/Feedback/ResultsDialog'

const styles = theme => ({
  list: {
    width: '100%'
  },
  header: {
    backgroundColor: palette['very-light-pink'],
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    borderRadius: '6px',
  },
  name: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    '&:hover': {
      color: palette.aquamarine
    }
  },
  box: {
    width: '100%',
    // borderColor: palette['very-light-pink'],
    // border: '1px solid',
    // borderRadius: '6px',
    // borderTopLeftRadius: '0px',
    // borderTopRightRadius: '0px',
    // borderTop: 'none',
    // boxShadow: '0 0px 2px 0 rgba(0, 0, 0, 0.1), 0 -1px 2px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    overflow: 'hidden',
    padding: theme.spacing(2)
  },
  summary: {
    height: 32,
    minHeight: 32,
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    fontSize: 14,
    color: 'black',
  },
  heading:{
    fontSize: 14,
    color: 'black',
  },
  expansionPanel: {
    marginBottom: theme.spacing(1),
    boxShadow: 'none',
    '&:before': {
      opacity: 0
    }
  },
  content: {
    backgroundColor: '#eee',
    border: '1px solid #e6e6e6',
    borderTop: 'none'
  },
  details: {
    padding: theme.spacing(1),
    backgroundColor: '#eee',
    borderRadius: 4
  },
  actions: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: 'white'
  },
  controls: {
    padding: theme.spacing(2),
    fontSize: 12,
    '& span': {
      fontSize: 12
    }
  },
})

const ToggleFeedback = withStyles(styles)(({classes, feedback, popped, hasPopped, disabled, setSelectedFeedbackResult, setFeedbackEdit, sessionState}) => {

  const {
    roomSessionId,
  } = sessionState

  const [isEnabled, setIsEnabled] = useState(feedback.enabled)
  const [isPublicResults, setIsPublicResults] = useState(feedback.publicResults)
  const [setEnable, , isLoadingSet, hasErrorSet] = useHttp()
  const [delEnable, , isLoadingDel, hasErrorDel] = useHttp()
  const [setActive, , isLoadingActive, hasErrorActive] = useHttp()
  const [delActive, , isLoadingDelActive, hasErrorDelActive] = useHttp()
  const [setPublicResults, , , hasErrorPublicResults] = useHttp()
  const [delPublicResults, , , hasErrorDelPublicResults] = useHttp()

  const toggleFeedback = () => {
    if (isLoadingSet||isLoadingDel) return
    if (isEnabled) {
      setIsEnabled(false)
      delEnable(api.entities.feedback.delEnable(null, null,{code: feedback.code}))
    } else {
      setIsEnabled(true)
      setEnable(api.entities.feedback.setEnable(null, null,{code: feedback.code}))
    }
  }

  const toggleResultView = () => {
    if (isLoadingSet||isLoadingDel) return
    if (isPublicResults) {
      setIsPublicResults(false)
      delPublicResults(api.entities.feedback.delPublicResults(null, null,{code: feedback.code}))
    } else {
      setIsPublicResults(true)
      setPublicResults(api.entities.feedback.setPublicResults(null, null,{code: feedback.code}))
    }
  }

  useEffect(()=>{
    if (hasErrorPublicResults) {
      setIsPublicResults(false)
    }
  },[hasErrorPublicResults])

  useEffect(()=>{
    if (hasErrorDelPublicResults) {
      setIsPublicResults(true)
    }
  },[hasErrorDelPublicResults])

  useEffect(()=>{
    if (hasErrorSet) {
      setIsEnabled(false)
    }
  },[hasErrorSet])

  useEffect(()=>{
    if (hasErrorDel) {
      setIsEnabled(true)
    }
  },[hasErrorDel])

  const [isVisible, setIsVisible] = useState(feedback.visible)
  const [isPopped, setIsPopped] = useState(popped)
  const [setVisible, , isLoadingVisibleSet, hasErrorVisibleSet] = useHttp()
  const [delVisible, , isLoadingVisibleDel, hasErrorVisibleDel] = useHttp()

  const toggleVisible = () => {
    if (isLoadingVisibleSet||isLoadingVisibleDel) return
    if (isVisible) {
      setIsVisible(false)
      delVisible(api.entities.feedback.delVisible(null, null,{code: feedback.code}))
    } else {
      setIsVisible(true)
      setVisible(api.entities.feedback.setVisible(null, null,{code: feedback.code}))
    }
  }

  useEffect(()=>{
    if (hasErrorActive) {
      setIsPopped(false)
    }
  },[hasErrorActive])

  useEffect(()=>{
    if (hasErrorDelActive) {
      setIsPopped(true)
    }
  },[hasErrorDelActive])

  useEffect(()=>{
    if (hasErrorVisibleSet) {
      setIsVisible(false)
    }
  },[hasErrorVisibleSet])

  useEffect(()=>{
    if (hasErrorVisibleDel) {
      setIsVisible(true)
    }
  },[hasErrorVisibleDel])

  // useEffect(()=>{
  //   if (setData||delData) {
  //   }
  //   // eslint-disable-next-line
  // }, [setData, delData])

  const pop = () => {
    if (isLoadingActive||isLoadingDelActive) return
    if (isPopped) {
      setIsPopped(false)
      delActive(api.entities.feedback.delActive(null, null,{id: roomSessionId}))
    } else {
      setIsPopped(true)
      setActive(api.entities.feedback.setActive(null, null,{code: feedback.code}))
    }
  }

  return <Grid item container spacing={2} className={classes.content}>
    <Grid container spacing={1} alignItems='center' className={classes.controls}>

      <Grid item container justify={'space-between'} spacing={1} style={{padding:8}}>
        <Grid item>
          Enable live polling
        </Grid>
        <Grid item>
          <Switch size='small' color='secondary'
                  checked={isEnabled}
                  onClick={toggleFeedback}
          />
        </Grid>
      </Grid>

      <Grid item container justify={'space-between'} spacing={1} style={{padding:8}}>
        <Grid item>
          Set Live Polling Public
        </Grid>
        <Grid item>
          <Switch size='small' color='secondary'
                  onClick={toggleVisible}
                  checked={isVisible}
          />
        </Grid>
      </Grid>

      <Grid item container justify={'space-between'} spacing={1} style={{padding:8}}>
        <Grid item>
          Enable Results
        </Grid>
        <Grid item>
          <Switch size='small' color='secondary'
                  onClick={toggleResultView}
                  checked={isPublicResults}
          />
        </Grid>
      </Grid>

    </Grid>
    <Grid item container className={classes.actions} spacing={2}>
      <Grid item>
        <Button size='small' variant='contained' color='default' onClick={()=>setSelectedFeedbackResult(feedback.code)} disabled={isLoadingActive||isLoadingDelActive}> View results  </Button>
      </Grid>
      <Grid item>
        <Button size='small' variant='contained' color='default' onClick={()=>setFeedbackEdit(feedback.id)} disabled={isLoadingActive||isLoadingDelActive}> Edit poll </Button>
      </Grid>
      <Grid item>
        <Button size='small' variant='contained' color='default' onClick={()=>pop()} disabled={disabled||(hasPopped!==isPopped)||isLoadingActive||isLoadingDelActive}> {isPopped?'Close dialog':'Pop dialog'} </Button>
      </Grid>
    </Grid>
  </Grid>
})

const LivePollingControl = withStyles(styles)(({classes, sessionState})=>{

  const {
    roomSessionId,
    allFeedback,
    activeFeedback
  } = sessionState

  const [expanded, setExpanded] = React.useState(false);
  const [feedbackEdit, setFeedbackEdit] = useState()


  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [selectedFeedbackResult, setSelectedFeedbackResult] = useState()

  const handleCloseResults = () => {
    setSelectedFeedbackResult(false)
  }

  const handleCloseEdit = () => {
    setFeedbackEdit(false)
  }

  return <Grid item container spacing={2}>
    <Dialog
      open={!!selectedFeedbackResult}
      onClose={handleCloseResults}
      maxWidth='lg'
    >
      <ResultsDialog feedbackCode={selectedFeedbackResult} handleClose={handleCloseResults}/>
    </Dialog>

    <Grid item xs={12}>
      <Typography variant='h3'>Live Polls</Typography>
    </Grid>

    <Grid item>
      {allFeedback.map((f, i) =>

        <ExpansionPanel expanded={expanded === f.code} onChange={handleChange(f.code)} key={i} className={classes.expansionPanel}>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${f.code}-content`}
            id={`${f.code}-header`}
          >
            <Typography className={classes.heading}>{`${f.title} ( ${f.code} )`}</Typography>
            {/*<Typography className={classes.secondaryHeading}>I am an expansion panel</Typography>*/}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.details}>
            <ToggleFeedback sessionState={sessionState} setFeedbackEdit={setFeedbackEdit} setSelectedFeedbackResult={setSelectedFeedbackResult} feedback={f} disabled={!f.enabled} popped={activeFeedback===f.code} hasPopped={activeFeedback!==null} />
          </ExpansionPanelDetails>

        </ExpansionPanel>

      )}

    </Grid>

    <Box p={2} display='flex' width='100%'>
      <Grid container spacing={2} justify='flex-end'>
        <Grid item>
          <Button startIcon={<AddIcon />} size='small' color='default' variant='contained' onClick={()=>setFeedbackEdit(true)}> Add new </Button>
        </Grid>
      </Grid>
    </Box>

    <Dialog onClose={handleCloseEdit} open={!!feedbackEdit} maxWidth='lg'>
      <LivePollEditNew feedbackId={feedbackEdit} handleClose={handleCloseEdit} sessionId={roomSessionId}/>
    </Dialog>
  </Grid>
})

export default LivePollingControl
