import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import Grid from '@material-ui/core/Grid'
import {filterList} from 'utils'
import KeynoteSpeaker from 'components/core/KeynoteSpeaker'
import language from "components/language";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const participantStyles = theme => ({
  letter: {
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '50%',
    backgroundColor: 'white',
    fontWeight: 'bold'
  },
  selectedLetter : {
    backgroundColor: theme.palette.primary.main
  },
  editButton: {
    float: "right",
		width: 195,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

export const KeynoteSpeakers = withStyles(participantStyles)(({classes, event, isPreview, canManage=false}) => {
  const [participants, setParticipants] = useState()
  const [getParticipantsData, participantsData] = useHttp()
  // eslint-disable-next-line
  const [alphabet, setParticipantsAlphabet] = useState()
  // eslint-disable-next-line
  const [filter, setFilter] = useState('A-Z')
  // eslint-disable-next-line
  const [search, setSearch] = useState('')
  const history = useHistory();

  useEffect(() => {
    if (isPreview) {
      getParticipantsData(api.entities.preview.getParticipants(null, {keynote:true}, {id: event.eventInfo.id}))
    } else {
      getParticipantsData(api.entities.events.getParticipants(null, {keynote:true}, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (participantsData) {
      setParticipants(participantsData.participants)

      const participantsAlphabet = {}
      participantsData.participants.forEach((p)=>participantsAlphabet[p.lastName[0].toUpperCase()]=true)
      setParticipantsAlphabet(Object.keys(participantsAlphabet))
    }
  }, [participantsData])

  if (!participants) { // Loading
    return <> </>
  }

  if (participants&&participants.length===0) return <> </>

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0} pb={12} flexDirection='column'>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/participants`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Keynote Speakers</Button>}
            </Grid>         
        <Grid container>

          <Grid item xs={12} md={8}>
            <Box component='h1' fontWeight='bolder' fontSize={26} mb={3}>{language.keynoteSpeakers}</Box>
          </Grid>

          {/*<Grid item xs={12} md={4}>*/}
          {/*  <Box pb={2} pr={1}>*/}
          {/*    {participants&&participants.length!==1&&<TextField label='Search for a speaker' variant='outlined' value={search} onChange={(e)=>setSearch(e.target.value)} fullWidth />}*/}
          {/*  </Box>*/}
          {/*</Grid>*/}

          <Grid item xs={12}>
          {/*<ScrollableContainer items={*/}
          {/*  ['A-Z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'].map((letter)=>{*/}
          {/*    return <Chip label={letter} color={filter===letter?`primary`:`default`} onClick={()=>setFilter(letter)} disabled={!(alphabet.includes(letter))&&letter!=='A-Z'} classes={{root:classes.letter, colorPrimary:classes.selectedLetter}} />*/}
          {/*  })*/}
          {/*} />*/}
          </Grid>
          <Grid item container spacing={6}>
            {filterList(search,null,participants.filter((p)=>p.lastName[0].toUpperCase()===filter||!filter||filter==='A-Z')).sort((a,b)=>a.lastName.localeCompare(b.lastName, 'en', { sensitivity: 'base' })).sort((a,b)=>b.matched-a.matched).map((participant,i) => {
              return <KeynoteSpeaker participant={participant} event={event} p={1} index={i}/>
            })}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </>
})
