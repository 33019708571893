import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {Pie} from 'react-chartjs-2'
import {format,parseISO} from 'date-fns'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
import api from 'api'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import List from '@material-ui/core/List'
import {ListItem, ListItemText} from '@material-ui/core'

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(2),
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  fieldValue: {
    fontSize: '16px',
    overflow: 'hidden',
    overflowWrap: 'break-word'
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})

const FieldText = withStyles(styles)(({title, value, classes, centered}) => {
  return <Grid item container>
    <Grid item container className={classes.fieldTitle} justify={centered?'center':'flex-start'}>
      <Grid item>
        {title}
      </Grid>
    </Grid>
    <Grid item container className={classes.fieldValue} justify={centered?'center':'flex-start'}>
      <Grid item>
        {value}
      </Grid>
    </Grid>
  </Grid>
})

const CommunicationCard = withStyles(styles)(({classes, communication, viewOnly = false, editAction, deleteAction, ...props}) => {

  const [logsData, setlogsData,] = useState()
  const [getLogs, dataLogs, logsLoading, , , ,] = useHttp()

  useEffect(() => {
    if (dataLogs) {
      setlogsData(dataLogs)
    }
  }, [dataLogs])

  const downloadLogs = (id,name) => {
    getLogs(api.entities.communication.downloadLogs(null, null, {id},false,{name}))
  }

  const data = {
    labels: ['', ''],
      datasets: [ {
        // { barPercentage: 1.0,
        //   categoryPercentage: 1.0,
        //   maxBarThickness: 10,
        //   minBarLength: 0,
        backgroundColor: ['#2ecc71','#c0392b'],
        maintainAspectRatio: false,
      }
    ]
  }
  const handleLogsClose = () => {
    setlogsData(false)
  }

  return <Grid container item spacing={2} {...props} className={classes.container}>

    {logsData&&<Dialog
      open={logsData}
      onClose={handleLogsClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title">Failed e-mails</DialogTitle>
        <DialogContent>
          <Box pb={2} style={{maxHeight: 350, overflowY:'auto'}}>

            <List dense={true}>

              {logsData.map((email,i)=>{
                return <ListItem>
                  <ListItemText
                    primary={email}
                    key={i}
                  />
                </ListItem>
              })}

            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button onClick={() => handleLogsClose()} size='small' variant='outlined' color='primary'>
              Close
            </Button>
          </Box>
        </DialogActions>
      </Box>

    </Dialog>}

            <Grid item xs={9}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FieldText title={'Date sent'} value={format(parseISO(communication.sendAt), 'LLL do y hh:mmaa')}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FieldText title={'Group name'} value={communication.group}/>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FieldText title={'Number of recipients'} value={communication.recipientsCount}/>
                </Grid>
                <Grid item xs={12}>
                  <FieldText title={'Subject'} value={communication.label}/>
                </Grid>
              </Grid>

            </Grid>

            <Grid item xs={3}>
              <Grid item container spacing={1} justify='flex-end' direction='column' alignItems='center'>
                <Grid item xs>
                  <FieldText title={'Delivered / Failed'} centered value={<>
                    <Box maxWidth={60} display='flex'>
                      <Pie
                        data={{...data, labels: ['', ''], datasets: [{...data.datasets[0], backgroundColor: ['#c0392b','#2ecc71'], data: [communication.failed,communication.delivered]}]}}
                        // height={50}
                        width={80}
                        options={{ layout: {
                            padding: {
                              left: 2,
                              right: 2,
                              top: 2,
                              bottom: 2
                            }
                          },
                          tooltips: {
                            enabled: true
                          },
                          legend:{display:false},scales:{xAxes:[{    barPercentage: 1,
                              categoryPercentage: 1,
                              display:false,ticks:{beginAtZero:true},gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false}}]}}} />
                    </Box>
                  </>}/>
                </Grid>
                <Grid item justify='center'>
                  <Button variant='contained' size='smaller' onClick={()=>downloadLogs(communication.id, `${communication.groupName}-${communication.sendAt}`)} disabled={logsLoading}> View failed e-mails </Button>
                </Grid>
                {/*<Grid item xs={6} >*/}
                {/*  <FieldText title={'Failed'} centered value={<>*/}
                {/*    <Box maxWidth={60} display='flex'>*/}
                {/*      <Pie*/}
                {/*        data={{...data, labels: ['', ''], datasets: [{...data.datasets[0], data: [communication.failed, communication.recipientsCount-communication.failed]}]}}*/}
                {/*        // height={50}*/}
                {/*        width={80}*/}
                {/*        options={{ layout: {*/}
                {/*            padding: {*/}
                {/*              left: 2,*/}
                {/*              right: 2,*/}
                {/*              top: 2,*/}
                {/*              bottom: 2*/}
                {/*            }*/}
                {/*          },*/}
                {/*          tooltips: {*/}
                {/*            enabled: true*/}
                {/*          },*/}
                {/*          legend:{display:false},scales:{xAxes:[{    barPercentage: 1,*/}
                {/*              categoryPercentage: 1,*/}
                {/*              display:false,ticks:{beginAtZero:true},gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false}}]}}} />*/}
                {/*    </Box>*/}
                {/*  </>}/>*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>

  </Grid>
})

export default CommunicationCard
