import React from 'react';
import {Route, Redirect, withRouter} from 'react-router-dom';
import {useAuth} from 'auth/AuthController'
import Header from 'components/core/Header/Header'
import Footer from 'components/core/Footer/Footer'

/**
 * Custom component that handles private routes
 * will redirect to login if user is not authenticated
 * @param Component
 * @param location
 * @param rest
 * @returns {*}
 * @constructor
 */
const PrivateRoute = ({navHandler, component: Component, ...rest}) => {
  const [isAuthenticated] = useAuth()

  const from = rest.location.pathname // Identify if you're logging out
  const to = `/login/?returnUrl=${from}` // This helps login redirect the user after login
  return (
    // Show the page only when the user is logged in
    // Otherwise redirect to login page or home page (if logging out)
    <Route {...rest} render={props => (
      isAuthenticated ?<>
          {navHandler&&<Header />}
          <Component {...rest} {...props} />
          {navHandler&&<Footer/>}
        </>
        : (from==='/sign-out')?<Redirect to='/' />:<Redirect to={to} />
    )} />
  )
}

export default withRouter(PrivateRoute)
