import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Box, Container, Grid, Typography, Button, DialogActions, DialogContent, DialogTitle} from '@material-ui/core'
//import Hidden from '@material-ui/core/Hidden'
//import HomeSplashScreen from 'assets/home.png'
// import HomeHeroImg from 'assets/home_hero.png'
//import PhoneMockup from 'assets/phone.png'
//import Chat from 'assets/chat.png'
//import Coordinate from 'assets/coordinate.png'
//import Attenders from 'assets/attenders.png'
//import FeedbackCreation from 'assets/feedback_creation.png'
//import FeedbackResults from 'assets/feedback_results.png'
//import QnAModeration from 'assets/qna.png'
//import ConferenceWireframe from 'assets/conference-wireframe.jpg'
//import AcceptParticipants from 'assets/accept_participant.png'
//import CreateTickets from 'assets/create_ticket.png'
//import DistributeWorkload from 'assets/distribute.png'
//import AutomaticProceeding from 'assets/automatic.png'
//import CollectFeedback from 'assets/collect_feedback.png'
//import RecordedVideo from 'assets/recorder_session.png'
import {palette} from 'theme'
import {withRouter} from 'react-router-dom'
//import Hero from 'components/core/Hero'
//import Button from 'components/core/Button'
//import {useAuth} from 'auth/AuthController'
import {useMetaData} from 'components/core/MetaProvider'
//import AnyFeature from '../core/AnyFeature';
/*import {
  IconAttending,
  IconFeedback,
  IconPolls,
  IconChat,
  IconPresenters,
  IconQA,
  IconCheckoutForm,
  IconRegistrations,
  IconPricing,
  IconTickets,
  IconDistributeWorkload,
  IconNotifications,
  IconProceedings,
  IconSessions,
  IconStayInTouch,
  IconCheckCircled
} from '../core/icons'
*/
import SubFooter from 'components/core/Footer/SubFooter'
//import Footer from "components/core/Footer/Footer";
import Header from "components/core/Header/Header";
import HomeHero from "components/core/HomeHero";
import HomeTopTabs from "components/core/HomeTopTabs";
import HomeTimeLine from "components/core/HomeTimeLine";
import HomeMiddle from "components/core/HomeMiddle";

import Dialog from '@material-ui/core/Dialog'


// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = theme => ({
  searchSection: {
    minHeight: '160px',
    backgroundColor: palette['cerulean-blue']
  },
  form: {},
  searchBox: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '90%'
    }
  },
  linkCode: {
    color: 'white',
    fontWeight: 'bold'
  },
  contactTitle:{
    color:'#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing:'-0.5px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactMoto:{
    color:'#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing:'0.2px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  }
})


/*
const featureOptions_eventRegistration = {
  hasDivider: true,
  tagName: 'Event Registration',
  reverse: true,
  title: 'Registrations\n',
  subtitle: 'Start accepting registrations easily within minutes. Simply create your registration options, publish and you’re set.',
  sections: [{
    name: 'Accept participant registrations with just a few clicks',
    icon: <IconRegistrations/>,
    image: AcceptParticipants
  }, {
    name: 'Create your tickets, add coupon options',
    icon: <IconTickets/>,
    image: CreateTickets
  }, {
    name: 'Customize your checkout forms',
    icon: <IconCheckoutForm/>,
    image: PhoneMockup,
    isDisabled:true
  }, {
    name: 'Simple pricing model (starting from $1 per ticket)',
    icon: <IconPricing/>,
    image: PhoneMockup,
    isDisabled:true
  }]
}


const featureOptions_amazingSessionExperience = {
  hasDivider: true,
  tagName: 'Online Events',
  title: 'Amazing session experience',
  subtitle: 'Anymeets offers unparalleled broadcast features. We put the session chair in the director’s seat.',
  sections: [{
    name: 'Coordinate presenters',
    icon: <IconPresenters/>,
    image: Coordinate
  }, {
    name: 'Moderate the Q&A',
    icon: <IconQA/>,
    image: QnAModeration
  }, {
    name: 'See who is attending each session',
    icon: <IconAttending/>,
    image: Attenders
  }, {
    name: 'Administer live polls',
    icon: <IconPolls/>,
    image: FeedbackCreation
  },
    {
      name: 'Receive real-time feedback',
      icon: <IconFeedback/>,
      image: FeedbackResults
    },
    {
      name: 'Chat with participants in the social feed',
      icon: <IconChat/>,
      image: Chat
    }]
}


const featureOptions_postEvent = {
  hasDivider: false,
  tagName: 'Post Event',
  reverse: true,
  title: 'Post-Event\n',
  subtitle: 'Just because your event has ended, doesn’t mean it’s over.',
  sections: [{
    name: 'Collect feedback and statistics',
    icon: <IconFeedback/>,
    image: CollectFeedback
  }, {
    name: 'Keep recorded sessions available',
    icon: <IconSessions/>,
    image: RecordedVideo
  }, {
    name: 'Stay in touch with your attendees',
    icon: <IconStayInTouch/>,
    image: PhoneMockup,
    isDisabled:true
  }]
}


const featureOptions_reducedWorkload = {
  hasDivider: true,
  tagName: 'Event',
  title: 'Reduced workload\n',
  subtitle: 'Reduce organizers’ workload lets you focus on things that matter.',
  sections: [{
    name: 'Distribute workload among your sessions chairs/coaches',
    icon: <IconDistributeWorkload/>,
    image: DistributeWorkload
  }, {
    name: 'Automatic proceedings creation',
    icon: <IconProceedings/>,
    image: AutomaticProceeding
  }, {
    name: 'Automated notifications at session level',
    icon: <IconNotifications/>,
    image: PhoneMockup,
    isDisabled:true
  }]
}



const phoneOnlineStyles = theme => ({
  phoneImage: {
    width: '320px'
  },
  tagLine: {
    maxWidth: '590px',
    color: '#666666',
    fontSize: '36px',
    textAlign: 'left',
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: '32px',
      marginBottom: 0,
    }
  },
  subtitle: {
    maxWidth: '590px',
    color: '#666666',
    fontSize: '24px',
    textAlign: 'left',
    fontWeight: 'normal',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px'
    }
  },
  featureBox: {
    width: '56px',
    height: '56px',
    boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.08)',
    borderRadius: '50%',
    backgroundColor: 'white',
    lineHeight: '56px',
    textAlign: 'center',
    padding: '14px'
  }
})

const FeaturedOnlineEvents = withStyles(phoneOnlineStyles)(({classes, history}) => {
  return <Box mt={8} mb={4}>
    <Container>
      <Grid container justify={'center'} alignItems={'center'} direction={'row'}>
        <Hidden xsDown smDown>
          <Grid item lg={6}>
            <Box className={classes.phoneImage} mr={2}>
              <img src={ConferenceWireframe} alt={'Online session viewer'} width={'500px'}/>
            </Box>
          </Grid>
        </Hidden>
        <Grid item lg={6}>
          <Grid container direction={'column'}>
            <Grid item>
              <Typography variant={'subtitle2'} color={'secondary'}>FEATURED</Typography>
            </Grid>
            <Grid item className={classes.tagLine}>
              Why choose Anymeets?
            </Grid>
            <Grid item className={classes.subtitle}>
              Anymeets offers a centralized environment in which attendees can easily find sessions and each other.
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Create unlimited virtual rooms</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Create rooms for your sponsors</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Find each session with ease - no need for
                      links!</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Use integrated streaming - no external app or
                      hardware required</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Use a laptop or mobile device to broadcast or
                      attend sessions</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box mb={2}>
                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1}>
                  <Grid item>
                    <IconCheckCircled color={'secondary'}/>
                  </Grid>
                  <Grid item>
                    <Typography style={{color: '#666666'}}>Connect with attendees through the event social
                      feed</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </Box>
})
*/

const Home = withStyles(styles)(({classes, history}) => {
//  const [isAuthenticated] = useAuth()
  const [, setMetaData] = useMetaData()
  const [dialogOpen, setDialogOpen] = useState(true)
  useEffect(() => {
    setMetaData({title: 'Home'})
    // eslint-disable-next-line
  }, [])

  /*
  const goRegister = () => {
    history.push('/register')
  }
  const goCreateEvent = () => {
    history.push('/create-event')
  }
  */
  
  return <div>
    <Header noShadow />

      {/*<Hero animate={true} tagLine='Anymeets helps you create your event in minutes' image={HomeSplashScreen} minHeight={300} taglineMarginTop={12}>*/}
      {/*  <Hidden xsDown smDown>*/}
      {/*    <img src={HomeHero} alt='Live Polling Screenshot' width={'100%'}/>*/}
      {/*  </Hidden>*/}
      {/*</Hero>*/}
      <HomeHero />
      <HomeTopTabs />
      <HomeTimeLine />
    <HomeMiddle />
    <Dialog
          open={dialogOpen}
          onClose={()=>{setDialogOpen(false)}}
          aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth='md'
    >
<Box p={3} pt={1}>

    <DialogContent>
    <DialogTitle id="alert-dialog-title"><strong>Dear Valued Customers,</strong></DialogTitle>
      <Grid container spacing={1}>
        <Grid item xs={12} justify='center'>
          <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'}>
                <Box>
                <Typography variant='body1' align={'left'} style={{textIndent: "20px"}}>
We want to express our sincere gratitude to all of you who have been a part of the Anymeets community over the years. Your support and trust in our platform have been invaluable, and we are truly thankful for the opportunity to serve you.
          </Typography> <br/>                 
          <Typography variant='body1' align={'left'} style={{textIndent: "20px"}}>
However, after careful consideration, we have made the difficult decision to suspend the Anymeets platform. While this decision was not an easy one, it aligns with our strategic goals and allows us to focus our efforts on new endeavors.
          </Typography>     <br/>        
          <Typography variant='body1' align={'left'} style={{textIndent: "20px"}}>
Although we will no longer be providing the conference organization service, we want to assure you that all registered users will continue to have access to the previously posted materials and resources. You can still log in to your accounts and access any content or information you may need.<br/>
          </Typography>  <br/>
          <Typography variant='body1' align={'left'} style={{textIndent: "20px"}}>
We understand that this announcement may raise questions or concerns, and we want to assure you that we are here to support you during this transition. If you have any inquiries or need assistance, please do not hesitate to reach out to our support team at [<a href="mailto:info@anymeets.com" rel="noopener noreferrer" target="_blank">info@anymeets.com</a>].<br/>
          </Typography>  <br/>
          <Typography variant='body1' align={'left'} style={{textIndent: "20px"}}>
Once again, we extend our deepest gratitude to each and every one of you for being a part of the Anymeets journey. Your loyalty and support have been instrumental in our success, and we hope to have the opportunity to serve you again in the future.
          </Typography>  <br/>
          <Typography variant='body1' align={'left'}>
Thank you for your understanding,<br/>
<strong>The Anymeets Team</strong>
          </Typography>  
            </Box>

          </Box>
        </Grid>


      </Grid>

    </DialogContent>
    <DialogActions>
      <Grid container spacing={2} justifyContent={'flex-end'}>
        <Grid item>

        </Grid>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={()=>{setDialogOpen(false)}} >
            Close
          </Button>
        </Grid>
      </Grid>
      {/*<Box p={1}>*/}
      {/*  <Button onClick={()=>{handleDeleteClose(deletingCollaborator)}}  size='small' autoFocus color='primary'>*/}
      {/*    Yes*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </DialogActions>
  </Box>
    </Dialog>
    { /*
      <Hero tagLine='Create and manage your event hassle - free' image={HomeSplashScreen} minHeight={350}>
        <Box mt={3}>
          {!isAuthenticated && <Button color='primary' size='small' onClick={() => {
            goRegister()
          }}> Join Anymeets </Button>}
          {isAuthenticated && <Button color='primary' size='small' onClick={() => {
            goCreateEvent()
          }}> Create an event </Button>}
        </Box>
      </Hero>
    
    <AnyFeature options={featureOptions_amazingSessionExperience}/>
    <AnyFeature options={featureOptions_eventRegistration}/>
    <AnyFeature options={featureOptions_reducedWorkload} />
    */
    }
    {/*<CardSlider upcoming/>*/}
    {/*<Box textAlign='center' p={3} pb={6}> <Button variant='outlined' color='primary'>View more events</Button></Box>*/}
    {/*
    <AnyFeature options={featureOptions_postEvent} />
    <Hero backgroundColor={'#107AAD'} minHeight={400}>
      <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
        <Grid item lg={12}>
          <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
        </Grid>
        <Grid item lg={8}>
          <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a demo</Typography>
        </Grid>
        <Grid item>
          <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
        </Grid>
      </Grid>
    </Hero>
    */
    }
    <SubFooter/>
    {/*<Footer/>*/}
  </div>
})

export default withRouter(Home)
