import {makeStyles} from '@material-ui/styles'

export const presentation_vert_8 = makeStyles({
  item_1: {
  top: '1%',
  left: '14.14%',
  width: '85.36%',
  height: '98%',
    opacity: '1 !important'
},

item_2: {
  left: '0.5%',
  top: '1%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_3: {
  left: '0.5%',
  top: '15.14%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_4: {
  left: '0.5%',
  top: '29.28%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_5: {
  left: '0.5%',
  top: '43.42%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_6: {
  left: '0.5%',
  top: '57.57%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_7: {
  left: '0.5%',
  top: '71.71%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
},

item_8: {
  left: '0.5%',
  top: '85.85%',
  width: '13.14%',
  height: '13.14%',
  opacity: '1 !important'
}
})
