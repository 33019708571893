import React, {useEffect, useState } from "react";
import { useHttp } from "api/core";
import api from "api";
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {format} from "date-fns"
import {CURRENCY} from 'config'
import FieldText from 'components/core/FieldText'
import parseISO from 'date-fns/parseISO'
import moment from "moment";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  TicketCard: {
    maxWidth: '980px',
    minWidth: '700px'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})


const TicketCard = withStyles(styles)(({ classes, ticket, editAction, deleteAction, unlimited, selectedTimezone, ...props }) =>
{ 
  return <Box className={classes.TicketCard} {...props}><ThemeProvider theme={theme}>
    <Box className={classes.container} display='flex' justifyContent='center' pr={5}>
      <Box className={classes.ticketBox} display='flex' justifyContent='center' p={1}>
        <Box minWidth={200} p={1}>
          <FieldText title='Ticket Type' value={ticket.name} />
        </Box>
        <Box minWidth={70} p={1}>
          <FieldText title='Price' value={`${CURRENCY}${ticket.price}`} />
        </Box>
        <Box minWidth={80} p={1}>
          <FieldText title='Quantity' value={unlimited?'∞':ticket.quantity} />
        </Box>
        <Box minWidth={200} p={1}>
          <FieldText title='Available from' value={moment(ticket.availableFrom).tz(selectedTimezone).format("MMM Do")} />
        </Box>
        <Box minWidth={200} p={1}>
          <FieldText title='Available until' value={moment(ticket.availableTo).tz(selectedTimezone).format("MMM Do")} />
        </Box>
      </Box>
       <Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end' >
         <IconNotesBorderLight title='Edit Ticket' className={classes.editIcon} onClick={()=>{editAction&&editAction(ticket.id)}}/>
         <IconXBorderLight title='Delete Ticket' className={classes.deleteIcon} onClick={()=>{deleteAction&&deleteAction(ticket.id)}}/>
       </Box>
    </Box>
  </ThemeProvider>
  </Box>
})

export default TicketCard
