import React, {useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {NavLink, withRouter} from 'react-router-dom'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {IconArrowLeftBorderGray} from 'components/core/icons'
import Drawer from '@material-ui/core/Drawer'
import EllipsisText from 'react-ellipsis-text'
import Divider from '@material-ui/core/Divider'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    }
  },
  appBar: {
    top: '63px',
    backgroundColor: '#42495f',
    color: 'white',
    alignItems: 'flex-start',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#42495f',
    color: 'white',
    zIndex: 22,
    alignItems: 'flex-start',
    top: '0px',
    height: 'calc(100% - 128px)',
    [theme.breakpoints.up('md')]: {
      top: '63px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: 0,
    backgroundColor: palette['pale-grey'],
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(11)
    },
    color: palette['greyish-brown'],
    width: '100%'
  },
  pageTitle: {
    position: 'relative',
    fontSize: '17px',
    fontWeight: 'bolder',
    minHeight: '80px',
    width: '100%'
  },
  listItem: {
    width: '208px',
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  selected: {
    backgroundColor: palette['cerulean-blue'],
    '&:hover': {
      backgroundColor: palette['cerulean-blue'],
    }
  },
  navBack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    paddingRight: '50px'
  },
  divider: {
    margin: theme.spacing(2,0)
  }
}))

const Adrawer = ({name, history, navigation, backNav, mobileTitle='Manage events', title, children, ...props}) => {

  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [navBack] = useState(backNav)
  const [pageTitle] = useState(title)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawerContent = (
    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' width='100%'>
      {navBack &&
      <Box p={2} pb={0} className={classes.navBack} onClick={()=>{history.push(navBack.path)}}>
        <Box display='flex' mr={2}><IconArrowLeftBorderGray/></Box>
        <Box display='flex' mt={0.5}>Back to {navBack.name}</Box>
      </Box>}

      {pageTitle && <Box display='flex' justifyContent='center' alignItems='center' alignContent='center' className={classes.pageTitle}>
        <Box display='flex'>
          <EllipsisText text={pageTitle} length={45} />
        </Box>
      </Box>}

      <Box display='flex'>
        <List component="nav" aria-label="secondary mailbox folders">
          {navigation&&navigation.routes.map((route, i)=>{
            if (route.separator) {
              return <Divider className={classes.divider} />
            }
            return <ListItem button component={NavLink} to={route.path} key={i} className={classes.listItem} activeClassName={classes.selected}>
              <ListItemText primary={route.name} />
            </ListItem>
          })}
        </List>
      </Box>

    </Box>
  )
  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {mobileTitle}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      <Box component='main' className={classes.content} minHeight={window.innerHeight-210}>
        {children}
      </Box>
    </div>
  )

}

export default withRouter(Adrawer)
