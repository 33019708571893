import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {useHttp} from 'api/core'
import {Redirect, useLocation, useParams, withRouter} from 'react-router-dom'
import Header from 'components/core/Header/Header'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import api from 'api'
import FormViewer from 'components/core/Forms/FormViewer'
import {transformForm} from 'utils'
import FormReviewSubmission from 'components/core/Forms/FormReviewSubmission'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import {Button as MUIButton} from '@material-ui/core'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Box from '@material-ui/core/Box'
import { IconStatusOkFillGreen } from 'components/core/icons'
import language from "components/language";
import {LANG_LIST} from "config";

language.getLanguage(); 

const styles = theme => ({
  container: {
    minHeight: '50vh',
    padding: theme.spacing(3)
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Review = withStyles(styles)(({classes, history, setProfileDialogOpen}) => {

  // eslint-disable-next-line
  const [canManage, setCanManage] = useState(false)

  // eslint-disable-next-line
  const [getData, data, isLoadingData] = useHttp()
  const [getThematicCodes, thematicCodes, isLoadingThematicCodes] = useHttp()

  // eslint-disable-next-line
  const [putData, replyData, isLoading] = useHttp()

  // eslint-disable-next-line
  const [fields, setFields] = useState()
  const [fieldsReview, setFieldsReview] = useState()
  const [valid, setValid] = useState(false)
  const [redirect, setRedirect] = useState()
  const [success, setSuccess] = useState()
  const [redirectBack, setRedirectBack] = useState()

  const {submissionId} = useParams()

  // eslint-disable-next-line
  const q = useQuery()
  const uuid = q.get('token')

  // Initial Load
  useEffect(() => {
    getData(api.entities.reviewers.getReview(null, {token:uuid},{id: submissionId}))
    if (!submissionId) {
      setRedirect(true)
    }
    // getData(api.entities.manage.getSubmissionFlowResponse(null,null,{submissionFlowId:2, answerId:1}))

    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    if (data) {
      const selectedLang = LANG_LIST.find((lang) => lang.id === data.eventInfo.eventLanguage);
      language.setLanguage(selectedLang.slag);
    }
  }, [data]);    

  useEffect(()=>{
    if (data) {
      getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:data.eventInfo.id}))
      setFieldsReview(data.submissionForm.fields)
      setFields(data.reviewForm.fields)
    }
    // eslint-disable-next-line
  }, [data])
  

  const submitResponse = () => {

    let formDataObj = new FormData()
    const saveFields = transformForm(fields)

    const payLoad = { fields: [...saveFields.form] }

    const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'})
    formDataObj.append('form', blob)

    // Save files if any
    saveFields.files.forEach((file)=>formDataObj.append(file.key, file.data))

    const responsePayLoad = {
      token: uuid
    }

    const blobResponse = new Blob([JSON.stringify(responsePayLoad)],{type: 'application/json'})
    formDataObj.append('response', blobResponse)

    putData(api.entities.reviewers.submitReview(formDataObj,null,{id: submissionId},true))
  }

  useEffect(() => {
    if (replyData) {
      // enqueueSnackbar('Successfully saved your response', {variant:'success'})
      setSuccess(true)
    }
    // eslint-disable-next-line
  }, [replyData])

  if (redirect) return <Redirect to={data?`/event/${data.eventInfo.id}/`:`/`}/>

  if (redirectBack) {
    return <Redirect to={`/event/${data.eventInfo.id}/review-submissions`} />
  }

  if (success) return (
    <>
      <Box display="flex" justifyContent="center" alignContent="center" justifyItems="center" flexDirection="row" pt={6} style={{height: "calc(100vh - 400px)"}}>
        <Box display="flex" justifyContent="center" alignItems="center" alignContent="center" justifyItems="center" flexDirection="column">
          <IconStatusOkFillGreen />
          <Box display="flex" pt={2}>
            <Typography variant={"body1"}>{language.yourReviewWasSubmittedSuccessfully}</Typography>
          </Box>
          <Box display="flex" pt={2}>
            <MUIButton className={classes.margin} startIcon={<KeyboardBackspaceIcon />} onClick={() => setRedirectBack(true)}>
              {language.goBackToReviews}
            </MUIButton>
          </Box>
        </Box>
      </Box>
    </>
  );



  return <Page>
    <Header setProfileDialogOpen={setProfileDialogOpen} />
    <Grid item container className={classes.container} spacing={3}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <MUIButton size="small" className={classes.margin} startIcon={<KeyboardBackspaceIcon/>} onClick={()=>setRedirectBack(true)}>
            {language.goBackToReviews}
          </MUIButton>
        </Grid>
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={6}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'}> {language.reviewSubmissions} </Typography>
            </Grid>
          </Grid>
          {thematicCodes&&fields&&<Grid item container spacing={2}>
            <Grid item xs={12}>
              <FormViewer setFields={setFields} fields={fields} thematicCodes={thematicCodes} valid={valid} setValid={setValid} />
            </Grid>
            <Grid item container spacing={2} justify='center' alignContent='center' alignItems='center'>
              <Grid item>
                <Button onClick={submitResponse} disabled={isLoading||isLoadingData||isLoadingThematicCodes||!valid}> {language.submitResponse} </Button>
              </Grid>
            </Grid>
          </Grid>}
          {(isLoadingData||isLoadingThematicCodes)&&<Grid item container justify={'center'} alignContent={'center'} alignItems='center' style={{minHeight:'calc(100vh - 300px)'}}>
            <Grid item>
              <CircularProgress size={'6rem'} color='secondary' />
            </Grid></Grid>}
        </Grid>

        <Grid item xs={6}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h6'}> {language.paper}</Typography>
            </Grid>
          </Grid>
          {thematicCodes&&fieldsReview&& <Grid item container spacing={2}>
            <Grid item xs={12}>
              <FormReviewSubmission fields={fieldsReview} thematicCodes={thematicCodes} />
            </Grid>
          </Grid>}

          {(isLoadingData||isLoadingThematicCodes)&&<Grid item container justify={'center'} alignContent={'center'} alignItems='center' style={{minHeight:'calc(100vh - 300px)'}}>
            <Grid item>
              <CircularProgress size={'6rem'} color='secondary' />
            </Grid></Grid>}
        </Grid>

      </Grid>

    </Grid>
  </Page>
})

export default withRouter(Review)
