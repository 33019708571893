import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "components/core/Button";
import {useHttp} from "api/core";
import api from "api";
import {useSnackbar} from "notistack";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from "@material-ui/lab/Alert";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Switch from "@material-ui/core/Switch";

import PropTypes from "prop-types";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme) => ({
  container: {
    padding: "20px 0 30px",
    borderRadius: "5px",
  },
  row: {
    marginBottom: theme.spacing(1),
  },
  tabsBar: {
    backgroundColor: palette["very-light-pink"],
  },
  tab: {
    width: "100%",
    color: "white",
    backgroundColor: palette["cerulean-blue"],
    padding: theme.spacing(1),
  },
  Tabs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    "&:focus": {
      backgroundColor: palette.aquamarine,
    },
  },
  root: {
    width: "100%",
  },
  backButton: {
    margin: "auto",
    maxWidth: "100%",
    minWidth: "150px",
  },
  nextButton: {
    margin: "auto",
    maxWidth: "100%",
    minWidth: "150px",
  },
  resetButton: {
    margin: "auto",
    maxWidth: "100%",
    minWidth: "150px",
  },
  instructions: {
    margin: "25px 15px 30px",
    minHeight: "100px",
  },
  title: {
    verticalAlign: "middle",
    fontSize: "17px",
    lineHeight: "38px",
    fontWeight: "500",
    float: "right",
    letterSpacing: "-0.5px",
  },
  number: {
    verticalAlign: "middle",
    fontSize: "17px",
    lineHeight: "35px",
    fontWeight: "500",
  },
  secondaryTitle: {
    verticalAlign: "middle",
    fontSize: "15px",
    lineHeight: "38px",
    fontWeight: "500",
    float: "right",
    letterSpacing: "-0.5px",
  },
  secondaryNumber: {
    verticalAlign: "middle",
    fontSize: "15px",
    lineHeight: "35px",
    fontWeight: "500",
  },
});

const FormConvertToPresentation = withStyles(styles)(({classes, data, handleClose, eventInfo, sFlow}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [totalForConversion, setTotalForConversion] = useState();
  const [paperStatus, setPaperStatus] = useState({});
  const [convertedPapers, setConvertedPapers] = useState([]);
  const [unconvertedPapers, setUnconvertedPapers] = useState(data.flows);
  const [conversionStatus, setConversionStatus] = useState(0);
  const [progress, setProgress] = useState(0);
  const [startConversion, setStartConversion] = useState(true);
  const [includeConverted, setIncludeConverted] = useState(false);
  const [includeWithOutCR, setIncludeWithOutCR] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [putData, dataPut, isSaving] = useHttp();
  const [localFlow, setLocalFlow] = useState({
    acceptedPaperId: data.flows ? null : data.answerId,
    cameraReadyFileUploaded: false,
  });

  useEffect(() => {
    // console.log(data);
    if (data.flows) {
      const countTotal = data.flows.map((item) => item);
      const countUnconverted = data.flows.filter((item) => item.convertedStatus === false);
      const countWithCameraReadyFile = data.flows.filter((item) => item.cameraReadyFileUploaded === true);
      const countUnconvertedWithCameraReady = data.flows.filter((item) => item.convertedStatus === false && item.cameraReadyFileUploaded === true);
      const countUnconvertedWithOutCameraReady = data.flows.filter((item) => item.convertedStatus === false && item.cameraReadyFileUploaded === false);
      const getAnswersIdList = data.flows.map((item) => item.answerId);
      //setLocalFlow({...localFlow, answerId: getAnswersIdList});
      setLocalFlow({...localFlow, acceptedPaperId: getAnswersIdList});
      setPaperStatus({
        total: countTotal.length,
        unconverted: countUnconverted.length,
        haveCameraReady: countWithCameraReadyFile.length,
        unconvertedWithCameraReady: countUnconvertedWithCameraReady.length,
        unconvertedWithOutCameraReady: countUnconvertedWithOutCameraReady.length,
        toConvert: parseInt(countUnconvertedWithCameraReady.length),
      });
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (includeConverted && includeWithOutCR) {
      setTotalForConversion(paperStatus.total);
    } else if (includeConverted && !includeWithOutCR) {
      setTotalForConversion(paperStatus.haveCameraReady);
    } else if (!includeConverted && includeWithOutCR) {
      setTotalForConversion(paperStatus.unconvertedWithOutCameraReady + paperStatus.unconvertedWithCameraReady);
    } else if (!includeConverted && !includeWithOutCR) {
      setTotalForConversion(paperStatus.unconvertedWithCameraReady);
    }
  }, [paperStatus, includeConverted, includeWithOutCR]);

  useEffect(() => {
    if (totalForConversion === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, [totalForConversion]);
  function getSteps() {
    return ["Flow Info", "Add-ons", "Confirm"];
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Grid item container md={12} xs={12} style={{padding: "0px 10px"}}>
            {/* <Grid item container md={1} xs={12} ></Grid> */}
            <Grid item container md={12} xs={12} alignContent="center" alignItems="center" justify="flex-start" p={0}>
              {data.flows ? (
                <Grid container item md={12} xs={12}>
                  <Grid item container xs={12}>
                    <Grid item md={9} xs={12}>
                      <span className={classes.title}> Papers that will be converted to presentations:</span>
                    </Grid>
                    <Grid item md={3} xs={12} alignContent="center" alignItems="center" style={{fontSize: "20px", lineHeight: "40px", fontWeight: "500", textAlign: "center"}}>
                      <span className={classes.number}>
                        {" "}
                        {totalForConversion}
                        {/* {includeConverted
                          ? includeWithOutCR
                            ? paperStatus.total
                            : paperStatus.haveCameraReady
                          : includeWithOutCR
                          ? paperStatus.unconvertedWithOutCameraReady + paperStatus.unconvertedWithCameraReady
                          : paperStatus.unconvertedWithCameraReady} */}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item md={9} xs={12}>
                      <span className={classes.secondaryTitle}>Total accepted:</span>
                    </Grid>

                    <Grid item md={3} xs={12} alignContent="center" alignItems="center" style={{fontSize: "20px", lineHeight: "40px", fontWeight: "500", textAlign: "center"}}>
                      <span className={classes.secondaryNumber}>{paperStatus.total}</span>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item md={9} xs={12}>
                      <span className={classes.secondaryTitle}>Total accepted &amp; converted:</span>
                    </Grid>
                    <Grid item md={3} xs={12} alignContent="center" alignItems="center" style={{fontSize: "20px", lineHeight: "40px", fontWeight: "500", textAlign: "center"}}>
                      <span className={classes.secondaryNumber}>{paperStatus.total - paperStatus.unconverted}</span>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item md={9} xs={12}>
                      <span className={classes.secondaryTitle}> Include already converted papers:</span>
                    </Grid>
                    <Grid item md={3} xs={12} style={{textAlign: "center"}}>
                      <Switch inputProps={{"aria-label": "true/false"}} checked={includeConverted} onChange={() => setIncludeConverted((prevIncludeConverted) => !prevIncludeConverted)} />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item md={9} xs={12}>
                      <span className={classes.secondaryTitle}> Include papers that do not contain camera ready file:</span>
                    </Grid>
                    <Grid item md={3} xs={12} style={{textAlign: "center"}}>
                      <Switch inputProps={{"aria-label": "true/false"}} checked={includeWithOutCR} onChange={() => setIncludeWithOutCR((prevIncludeWithOutCR) => !prevIncludeWithOutCR)} />
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <Grid item container alignContent="center" md={12} xs={12} style={{padding: "0px 10px", textAlign: "center", display: "grid", margin: "auto"}}>
                  <Typography align="center" variant="p" component="p">
                    You selected to convert {data.title} to presentation
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Grid item container md={12} xs={12} style={{padding: "0px 10px"}}>
            <Grid item container alignContent="center" alignItems="center" justify="flex-start" p={0}>
              <Grid item md={10} xs={12}>
                Copy camera ready file to presentation
              </Grid>
              <Grid item md={2} xs={12}>
                <Switch inputProps={{"aria-label": "true/false"}} checked={localFlow.cameraReadyFileUploaded} onChange={() => setLocalFlow({...localFlow, cameraReadyFileUploaded: !localFlow.cameraReadyFileUploaded})} />
              </Grid>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid item container alignContent="center" md={12} xs={12} style={{padding: "0px 10px", textAlign: "center", display: "grid", margin: "auto"}}>
            <Typography align="center" variant="p" component="h4">
              Are you sure you want to convert these papers?
            </Typography>
          </Grid>
        );
      default:
        return (
          <Grid item container alignContent="center" md={12} xs={12} style={{padding: "0px 10px", textAlign: "center", display: "grid", margin: "auto"}}>
            <Grid item md={4} xs={12}>
              <Button disabled={isSaving} variant="outlined" onClick={handleReset} size="small" color="primary" style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                {" "}
                Close{" "}
              </Button>
            </Grid>
          </Grid>
        );
    }
  }
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    handleClose();
  };

  useEffect(() => {
    if (dataPut) {
      setConvertedPapers([...convertedPapers, dataPut]);
    }
    if (conversionStatus + 1 >= parseInt(paperStatus.toConvert)) {
      setStartConversion(false);
    }
    // eslint-disable-next-line
  }, [dataPut]);

  useEffect(() => {
    if (dataPut) {
      const unconverted = unconvertedPapers.filter((paper) => paper.answerId !== parseInt(dataPut.presentationCode));
      setUnconvertedPapers(unconverted);
      // console.log(unconverted);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  function convert() {
    handleNext();
    let selectedPapersToConvert;
    if (data.flows) {
      if (includeConverted === false && includeWithOutCR === false) {
        selectedPapersToConvert = data.flows.filter((flow) => flow.convertedStatus === false && flow.cameraReadyFileUploaded === true);
        setPaperStatus({...paperStatus, toConvert: parseInt(selectedPapersToConvert.length)});
        setUnconvertedPapers(selectedPapersToConvert);
      } else if (includeConverted === false && includeWithOutCR === true) {
        selectedPapersToConvert = data.flows.filter((flow) => flow.convertedStatus === false);
        setPaperStatus({...paperStatus, toConvert: parseInt(selectedPapersToConvert.length)});
        setUnconvertedPapers(selectedPapersToConvert);
      } else if (includeConverted === true && includeWithOutCR === false) {
        selectedPapersToConvert = data.flows.filter((flow) => flow.cameraReadyFileUploaded === true);
        setPaperStatus({...paperStatus, toConvert: parseInt(selectedPapersToConvert.length)});
        setUnconvertedPapers(selectedPapersToConvert);
      } else if (includeConverted === true && includeWithOutCR === true) {
        selectedPapersToConvert = data.flows.filter((flow) => flow);
        setPaperStatus({...paperStatus, toConvert: parseInt(selectedPapersToConvert.length)});
        setUnconvertedPapers(selectedPapersToConvert);
      } else {
        selectedPapersToConvert = data.flows.filter((flow) => flow);
        setPaperStatus({...paperStatus, toConvert: parseInt(selectedPapersToConvert.length)});
        setUnconvertedPapers(selectedPapersToConvert);
      }

      selectedPapersToConvert.forEach(async (paper, i) => {
        setTimeout(function () {
          try {
            const payLoad = {
              acceptedPaperId: paper.answerId,
              cameraReadyFileUploaded: localFlow.cameraReadyFileUploaded,
            };
            putData(api.entities.manage.convertSubmissionToPapers(payLoad, null, {id: eventInfo.id, paperId: paper.answerId, convertAll: includeWithOutCR}));
          } catch (error) {
            enqueueSnackbar(error, {variant: "error"});
          }
          setTimeout(
            function () {
              setConversionStatus(i);
              setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 100 / selectedPapersToConvert.length));
            },
            [300],
          );
        }, i * 700);
      });
    } else {
      setConversionStatus(-1);
      setPaperStatus({total: 1, unconverted: null, toConvert: 1});
      setIncludeConverted(true);
      const payLoad = {
        ...localFlow,
      };
      setUnconvertedPapers([]);
      setTimeout(function () {
        putData(api.entities.manage.convertSubmissionToPapers(payLoad, null, {id: eventInfo.id, paperId: localFlow.acceptedPaperId, convertAll: true}));
        setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 100));
        setConversionStatus(1);
      }, 1500);
    }
  }

  useEffect(() => {
    if (conversionStatus + 1 >= parseInt(paperStatus.toConvert)) {
      setStartConversion(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversionStatus]);

  function LinearProgressWithLabel(props) {
    return (
      <Box style={{display: "flex", alignItems: "center"}}>
        <Box style={{width: "90%", display: "inline-block", mr: 1}}>
          <LinearProgress variant="determinate" {...props} style={{height: "12px"}} />
        </Box>
        <Box style={{width: "5%", float: "right", minWidth: 35, marginLeft: "10px"}}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };

  return (
    <DialogContent>
      <Box className={classes.container}>
        <Grid container sm={12} xs={12} style={{padding: "0px 20px"}}>
          <Typography variant="h5" component="h1">
            {"Send to Agenda"}
          </Typography>
        </Grid>

        <Grid item container justify="center" alignContent="center" alignItems="center" style={{marginTop: "16px"}}>
          <Grid item container xs={12}>
            <Grid item container xs={12} className={classes.root}>
              <Grid item xs={12}>
                <Stepper activeStep={activeStep} className={classes.stepper}>
                  {steps.map((label, i) => (
                    <Step key={i}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
              <Grid item container xs={12} spacing={3}>
                {activeStep === steps.length ? (
                  <Grid item container md={12} xs={12}>
                    <Grid item md={12} xs={12} spacing={3} className={classes.instructions}>
                      {conversionStatus + 1 >= paperStatus.toConvert && startConversion === false && (
                        <Alert severity={unconvertedPapers.length === 0 ? "success" : !data.flows ? "success" : "info"}>
                          Conversion is completed. {convertedPapers.length} out of {paperStatus.toConvert} papers converted successfully.
                          <br />
                          {unconvertedPapers.length !== 0 && !data.flows && (
                            <span>
                              Some papers could not be converted. <br /> Check paper id{unconvertedPapers.length === 1 ? "" : "s"}:{" "}
                              {unconvertedPapers.map((paper) => {
                                return <>{paper.answerId}, </>;
                              })}
                            </span>
                          )}
                        </Alert>
                      )}
                      {conversionStatus + 1 <= paperStatus.toConvert && startConversion === true && (
                        <Alert severity="info">
                          Converting {conversionStatus + 1} out of {paperStatus.toConvert} papers ...
                        </Alert>
                      )}
                      <Box sx={{width: "100%", marginTop: "20px"}}>
                        <LinearProgressWithLabel value={progress} />
                      </Box>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {startConversion === false && (
                        <Button disabled={isSaving} onClick={handleReset} variant="outlined" size="small" color="primary" style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                          Close
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container md={12} xs={12}>
                    <Grid item container md={12} xs={12} spacing={3} className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </Grid>

                    <Grid item container xs={12}>
                      <Grid item md={4} xs={12}>
                        <Button disabled={isSaving} variant="outlined" onClick={handleReset} size="small" color="primary" style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                          {" "}
                          Close{" "}
                        </Button>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <Button disabled={activeStep === 0 || isSaving} onClick={handleBack} className={classes.backButton} style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                          Back
                        </Button>
                      </Grid>
                      <Grid item md={4} xs={12}>
                        {activeStep === steps.length - 1 ? (
                          <Button disabled={isSaving} variant="contained" color="primary" onClick={convert} className={classes.nextButton} style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                            Convert
                          </Button>
                        ) : (
                          <Button
                            disabled={isSaving || isEmpty}
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            className={classes.nextButton}
                            style={{maxWidth: "100%", minWidth: "150px", margin: "auto", display: "block"}}>
                            Next
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DialogContent>
  );
});

export default FormConvertToPresentation;
