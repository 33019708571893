import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useState} from 'react'
import {Menu, MenuItem, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette, theme} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import CommunicationEditNew from 'components/core/CommunicationEditNew'
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
// import Tooltip from "@material-ui/core/Tooltip";
// import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
// import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Avatar from "@material-ui/core/Avatar";
import ReviewForm from "./ReviewForm";
import { EMAIL_TEMPLATE } from "config";

import SubmissionFlowsGradesFilter from "components/core/SubmissionFlowsGradesFilter";

const styles = (theme) => ({
	PaperAcceptanceContainer: {
		position: "relative",
		backgroundColor: "white",
		padding: theme.spacing(2),
		borderRadius: "6px",
		// boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
		marginBottom: theme.spacing(4),
		maxWidth: "calc(100vw - 300px)",
	},
	noPaperAcceptanceYet: {
		display: "flex",
		backgroundColor: "white",
		padding: theme.spacing(2),
		borderRadius: "6px",
		border: "2px dashed",
		borderColor: palette["cerulean-blue"],
		minHeight: "145px",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
		color: palette["greyish-brown"],
		fontSize: "18px",
		opacity: 0.3,
	},
	fieldTitle: {
		fontWeight: "bold",
	},
	header: {
		backgroundColor: palette["cerulean-blue"],
		color: "white",
		borderRadius: "6px",
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down("xs")]: {
			display: "none",
		},
	},
	addBtn: {
		textAlign: "right",
	},
	navTabs: {
		position: "relative",
		marginBottom: theme.spacing(3),
	},
	downloadBtn: {
		position: "absolute",
		top: theme.spacing(4),
		right: theme.spacing(3),
	},
	title: {
		fontWeight: "bolder",
		paddingBottom: theme.spacing(4),
	},
	totals: {
		marginTop: theme.spacing(2),
		paddingRight: theme.spacing(3),
	},
	totalStats: {
		textAlign: "center",
		position: "relative",
		backgroundColor: "white",
		padding: theme.spacing(2, 2, 4, 2),
		borderRadius: "6px",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
		marginBottom: theme.spacing(4),
		maxWidth: "1000px",
	},
	statValue: {
		paddingTop: theme.spacing(1),
		fontWeight: "bold",
	},
	reviews: {
		display: "flex",
		// justifyContent: 'center',
		flexWrap: "wrap",
		"& > *": {
			margin: theme.spacing(0.5),
		},
	},
	selectItem: {
		padding: "5px",
	},
	filterTopTitle: {
		fontSize: "11px",
		marginBottom: "10px",
	},
	gradeFilterBtn: {
		border: "1px solid rgba(0, 0, 0, 0.23)",
		cursor: "pointer",
		padding: "5px 15px 5px 0",
		fontSize: "16px,",
		background: "transparent",
		borderRadius: "4px",
		height: "40px",
		width: "100%",
		color: "#4a4a4a",
		fontFamily: "inherit",
		textAlign: "left",
		textIndent: "15px",
	},
	selectRoot: {
		maxWidth: "100%",
		"&:focus": {
			backgroundColor: "transparent",
		},
	},
	maxWidth: {
		maxWidth: "100%",
		width: "100%",
	},
});



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// eslint-disable-next-line no-unused-vars
const StyledTabs = withStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    '&:focus': {
      opacity: 1,
    },
    '&:first-child': {
      minWidth: 100
    }
  },
  indicator: {
    display: 'none'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

// eslint-disable-next-line no-unused-vars
const StyledTab = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    // minHeight: theme.spacing(6),
    // minWidth: 114,
    minWidth: 'auto',
    minHeight: 40,
    // padding: 0,
    // margin: 0,
    // paddingLeft: theme.spacing(0.2),
    // paddingRight: theme.spacing(0.2),
    color: '#4a4a4a',
    borderRadius: theme.spacing(8),
    marginRight: theme.spacing(1),
    border: '1px solid rgb(239, 239, 239)',
    '&:hover': {
      backgroundColor: palette['cerulean-blue'],
      '& $wrapper': {
        color: 'white',
      },
    },
    '&$selected': {
      '& *': {
        color: 'white',
      },
    },
  },
  selected: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white'
  },
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 14,
    // fontWeight: 600,
    // color: palette['brown-grey'],
    color: '#4a4a4a',
  },
  typeColor: {
    display: 'inline-box',
    width: 10,
    height: 10
  }
}))((props) => <Tab disableRipple {...props} />);


const PaperAcceptance = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {

  // Hooks
  const [getData, data, isLoadingData] = useHttp()
  const [getSessionTypes, sessionTypesData] = useHttp()
  
  // eslint-disable-next-line
  const [setAccepted, acceptedData, isLoadingAccepted] = useHttp()
  const [formPreview, setFormPreview] = useState()
  const [sessionTypes, setSessionTypes] = useState()
  // const [getExcel, dataExcel] = useHttp()

  // State
  const [paperAcceptance, setPaperAcceptance] = useState()
  const [submissionFlows, setSubmissionFlows] = useState()
  const [tab, setTab] = useState(null)
  // const [CsvData, setCsvData] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [editCommunication, setEditCommunication] = useState(false)
  const [typeOfEmail, setTypeOfEmail] = useState(false)


  // Deps
  const {eventId} = useParams()

  const getPageData = () => {
    getData(api.entities.manage.getPaperAcceptanceReviews(null, null, {id: eventId}))
    getSessionTypes(api.entities.manage.getSessionTypes(null,null,{id:eventId}))
  }

  const handleClickNotify = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseNotify = (type) => {
    setAnchorEl(null)

    if (!type) {
      setTypeOfEmail(false)
      setEditCommunication(false)
      return
    }

    setTypeOfEmail(type)
    setEditCommunication(true)

  }

  const handleEditCloseCommunication = () => {
    setEditCommunication(false)
    setTypeOfEmail(false)
  }


  useEffect(()=>{
    getPageData()
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (sessionTypesData) {
      let lookUp = {}
      sessionTypesData.sessionTypes.forEach((type)=>{
        lookUp[type.id] = type.name
      })
      setSessionTypes(lookUp)
    }
  },[sessionTypesData])

  useEffect(() => {
    if (data) {
      // let reviewsTransformed = []
      // data.reviews.forEach((r)=>{
      //   reviewsTransformed.push({
      //     ...r,
      //     status: r.reviewId?'Completed':'Pending'
      //   })
      // })
      setSubmissionFlows(data.submissionFlows)
      setPaperAcceptance(data.papers)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)   
    const wantedGrades = data.papers.filter((paper)=> paper.submissionFlowId === newValue)
    //console.log(wantedGrades);
    setSubmissionGrades(wantedGrades);
  }
  //Define which submission to process/get grades from
  const [submissionGrades, setSubmissionGrades] = useState([]);

  // const downloadPaperAcceptance = () => {
  //   getExcel(api.entities.manage.downloadPaperAcceptance(null, null, {id: eventId}))
  // }

  // useEffect(() => {
  //   if (dataExcel) {
  //     setCsvData(dataExcel)
  //   }
  // }, [dataExcel])

  // useEffect(() => {
  //   if (CsvData) {
  //     let downloadLink = document.createElement('a')
  //     downloadLink.href = window.URL.createObjectURL(CsvData[0])
  //     downloadLink.download = `${data.eventInfo.name} - PaperAcceptance.xlsx`
  //     document.body.appendChild(downloadLink)
  //     downloadLink.click()
  //     document.body.removeChild(downloadLink)
  //     setCsvData(null)
  //   }
  // }, [CsvData, data, dataExcel])

  const handleChangeAcceptance = (e, id) => {
    let items = [...paperAcceptance]
    let itemIndex = items.findIndex((e)=>e.submissionId===id)
    const value = e.target.value

    // items[itemIndex].acceptedByManager = value
    items[itemIndex].status = value
    setPaperAcceptance([...items])

    setAccepted(api.entities.manage.setPaperAcceptance({status:value}, null, {id: id}))
  }

  const handleChangeSessionType = (e, id) => {
    let items = [...paperAcceptance]
    let itemIndex = items.findIndex((e)=>e.submissionId===id)
    const value = e.target.value

    // items[itemIndex].acceptedByManager = value
    items[itemIndex].sessionTypeId = value
    setPaperAcceptance([...items])

    setAccepted(api.entities.manage.setPaperSessionType({sessionTypeId:value}, null, {id: id}))
  }

  const openReview = (r) => {
    setFormPreview(r)
  }


  const handleCloseFormPreview = () => {
    setFormPreview(false)
  }

  const tableData = tab?paperAcceptance.filter((p)=>p.submissionFlowId===tab):paperAcceptance

  const total = tableData&&tableData.length
  const accepted = tableData?tableData.filter(r => r.status === 1).length:0
  const pending = tableData?tableData.filter(r => (r.status === 0)).length:0
  const rejected = tableData?tableData.filter(r => (r.status === 2)).length:0
  const waitlist = tableData?tableData.filter(r => (r.status === 3)).length:0

  const hasAccepted = accepted > 0
  const hasRejected = rejected > 0
  const hasWaitlisted = waitlist > 0

  // const pendingEmail = paperAcceptance.filter(r => (r.notified===true&&r.reviewId === null)).length
  // const newReviews = paperAcceptance.filter(r=>r.notified===false).length


  return <>

    {formPreview&&<Dialog onClose={handleCloseFormPreview} open={!!formPreview} fullWidth maxWidth='md'>
      <ReviewForm handleClose={handleCloseFormPreview} formPreview={formPreview} eventId={eventId}/>
    </Dialog>}

    <Fade in={true} timeout={1200}>
      <>

        <Grid container spacing={2} className={classes.PaperAcceptanceContainer}>

        <Grid item xs={12} sm={12} md={4}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Paper acceptance
          </Typography>
        </Grid>

          <Grid item xs>
            <Grid item container justify='flex-end' spacing={2}>
              <Grid item>
                <Chip label={`Total ${total?total:'-'}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Accepted ${accepted}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Rejected ${rejected}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Pending ${pending}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Waitlist ${waitlist}`} variant='outlined'/>
              </Grid>
            </Grid>
          </Grid>

          {/*<Grid item>*/}
          {/*  <Button size='smaller' color='primary' onClick={() => {*/}
          {/*    downloadPaperAcceptance()*/}
          {/*  }}> Download Excel file </Button>*/}
          {/*</Grid>*/}

          <Grid item>

            <Button size='smaller' color='secondary' onClick={handleClickNotify}>
               Notify submitters
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={()=>handleCloseNotify(false)}
              >
                <MenuItem onClick={()=>hasAccepted&&handleCloseNotify(EMAIL_TEMPLATE.ACCEPTED_SUBMITTERS)} disabled={!hasAccepted}>Only with accepted papers</MenuItem>
                <MenuItem onClick={()=>hasRejected&&handleCloseNotify(EMAIL_TEMPLATE.REJECTED_SUBMITTERS)} disabled={!hasRejected}>Only with rejected papers</MenuItem>
                <MenuItem onClick={()=>hasWaitlisted&&handleCloseNotify(EMAIL_TEMPLATE.WAIT_LISTED_SUBMITTERS)} disabled={!hasWaitlisted}>Only with waitlisted papers</MenuItem>
              </Menu>

          </Grid>
          <Grid item lg={12} md={12} xs={12}></Grid>
          {submissionFlows && <Grid container item lg={12} md={12} xs={12} spacing={1}>
            <Grid item lg={2} md={3} xs={12} >
              {/*<StyledTabs value={tab} onChange={handleChangeTab} aria-label="Flows"
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
            >
              <StyledTab label={'All'} value={null} />
              {submissionFlows.map((f)=><StyledTab label={f.name} {...a11yProps(f.id)} value={f.id} />)}
            </StyledTabs>*/}
              <Typography component={"p"} className={classes.filterTopTitle}>SELECT SUBMISSION FLOW</Typography>
              <FormControl variant='standard'  classes={{ root: classes.maxWidth }}>
                <Select classes={{ root: classes.selectRoot }} className={classes.gradeFilterBtn} style={{ minWidth: "150px", fontSize: "14px" }}
                  disableUnderline
                  displayEmpty
                  fullWidth
                  id="submissions-flows"
                  value={tab}
                  onChange={(e) => { handleChangeTab(e, e.target.value) }}
                  inputProps={{ padding: "0" }}
                  IconComponent={ExpandMoreIcon}                
                >
                  <MenuItem value={null} {...a11yProps(null)} key={0}>All</MenuItem>
                  {submissionFlows.map((f) => <MenuItem value={f.id} {...a11yProps(f.id)} key={f.id}>{f.name}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <SubmissionFlowsGradesFilter eventId={eventId} submissionGrades={submissionGrades} getPageData={getPageData} flowId={tab}/>
          </Grid>}

          {isLoadingData&&<Grid item container justify='center' alignContent='center' alignItems='center' style={{minHeight: '500px'}}>
            <Grid item >
              <CircularProgress size={'6rem'} color='secondary' />
            </Grid>
          </Grid>}
        {!isLoadingData&&paperAcceptance&&sessionTypes&&<Grid item xs={12}>
          <MaterialTable
            key={paperAcceptance}
            icons={tableIcons}
            columns={[
              { title: 'ID', field: 'submissionId' ,  width: 50, cellStyle: {width: 50} },
              { title: 'Title', field: 'title' },
              { title: 'Authors', field: 'authors',width: 0, cellStyle: {width: 350}, render: rowData => <>
                  {rowData.submitter?<><b>{rowData.submitter}</b>{rowData.authors?',':''}</>:''}
                  {rowData.authors&&rowData.authors.join(', ')}
                  </>
              },
              { title: 'Reviews', field: 'reviews', render: rowData => <Box className={classes.reviews}>

                {rowData.reviews.map((r)=><Chip
                    avatar={<Avatar>{r.grade}</Avatar>}
                    label={r.reviewer}
                    onClick={()=>openReview(r)}
                    variant="outlined"
                />)}

                </Box>},
              { title: 'Status', field: 'status', editable: 'never',
                lookup: { 0: 'Pending', 1: 'Accepted', 2: 'Rejected', 3: 'Waitlist', 4: 'Withdrawn'}, render: rowData => {
                  return <Box p={1}>
                    <Grid container>
                      <Grid item xs={11}>
                        <FormControl className={classes.formControl}>
                          <Select type='select' name='accepted' fullWidth disabled={isLoadingAccepted} value={rowData.status===null?'-':rowData.status} onChange={(e)=>handleChangeAcceptance(e,rowData.submissionId)}>
                            <MenuItem value={0} key={0}>Pending</MenuItem>
                            <MenuItem value={1} key={1}>Accepted</MenuItem>
                            <MenuItem value={2} key={2}>Rejected</MenuItem>
                            <MenuItem value={3} key={3}>Waitlist</MenuItem>
                            <MenuItem value={4} key={4}>Withdrawn</MenuItem>
                          </Select>
                          {/*{rowData.acceptedByManager&&<FormHelperText> set by manager </FormHelperText>}*/}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                }},
              { title: 'Session type', field: 'sessionTypeId', editable: 'never',
                lookup: sessionTypes, render: rowData => {
                  return <>
                    <Grid container>
                      <Grid item xs={11}>
                        <FormControl className={classes.formControl}>
                          <Select type='select' name='accepted' fullWidth disabled={isLoadingAccepted} value={rowData.sessionTypeId===null?'-':rowData.sessionTypeId} onChange={(e)=>handleChangeSessionType(e,rowData.submissionId)}>
                            {sessionTypesData.sessionTypes.map((type)=>{
                              return <MenuItem value={type.id} key={type.id}>
                              <Box p={1}>
                                <span style={{backgroundColor: type.color, width:10, height:10,  border:'1px solid #cdcdcd', display:'inline-block',marginRight:6}}> </span>
                                {type.name}
                              </Box>
                              </MenuItem>
                            })}
                          </Select>
                          {/*{rowData.acceptedByManager&&<FormHelperText> set by manager </FormHelperText>}*/}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                }}
            ]}
            data={tableData}
            title=''
            options={{
              filtering: true,
              actionsColumnIndex:999,
              addRowPosition: 'first',
              headerStyle: {
                backgroundColor: palette['brown-grey'],
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: 14
              },
              rowStyle: {
                fontSize: 12,
                padding: 0
              }
            }}
          />
        </Grid>}

      </Grid>

    </>
    </Fade>
    {paperAcceptance&&<Dialog onClose={handleEditCloseCommunication} open={editCommunication} fullWidth maxWidth='md'>
      <CommunicationEditNew handleClose={handleEditCloseCommunication} eventId={eventId} eventInfo={data.eventInfo} defaultGroup={typeOfEmail} />
    </Dialog>}
  </>
})

export default withRouter(PaperAcceptance)
