import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Hero from 'components/core/Hero'
import Page from 'components/core/Page'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import HomeSplashScreen from 'assets/home.png'

const styles = theme => ({
  root: {
    width: theme.spacing(92),
    fontSize: '1rem',
    fontFamily: "'Roboto','Karla', sans-serif;",
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    fontWeight: 400,
    color: palette['greyish-brown'],
    '& a': {
      color: palette['cerulean-blue']
    }
  }
})

const Privacy = withStyles(styles)(({classes}) => {

  return <Page title='Privacy'>

    <Hero tagLine='Privacy Policy' image={HomeSplashScreen} minHeight={300}/>
    <Box display='flex' justifyContent='center' mt={4} p={2}>
      <Box className={classes.root}>
        <p className="p1"><span
          className="s1">Your privacy is important to us. It is Datawise Data Engineering LLC&#39;s policy to respect your privacy regarding any information we may collect from you across our website, <a
          href="http://www.anymeets.com/"><span className="s2">https://www.anymeets.com</span></a>, and other sites we own and operate.</span>
        </p>
        <p className="p2"><span className="s1">1. Information we collect</span></p>
        <p className="p3"><span className="s1">Log data</span></p>
        <p className="p4"><span className="s1">When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your computer&rsquo;s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details.</span>
        </p>
        <p className="p3"><span className="s1">Device data</span></p>
        <p className="p4"><span className="s1">We may also collect data about the device you&rsquo;re using to access our website. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.</span>
        </p>
        <p className="p3"><span className="s1">Personal information</span></p>
        <p className="p3"><span className="s1">We may ask for personal information, such as your:</span></p>
        <ul className="ul1">
          <li className="li5"><span className="s4">Name</span></li>
          <li className="li5"><span className="s4">Email</span></li>
          <li className="li5"><span className="s4">Social media profiles</span></li>
          <li className="li5"><span className="s4">Phone/mobile number</span></li>
          <li className="li5"><span className="s4">Home/Mailing address</span></li>
          <li className="li5"><span className="s4">Work address</span></li>
          <li className="li6"><span className="s4">Payment information</span></li>
        </ul>
        <p className="p7"><span className="s1">2. Legal bases for processing</span></p>
        <p className="p3"><span className="s1">We will process your personal information lawfully, fairly and in a transparent manner. We collect and process information about you only where we have legal bases for doing so.</span>
        </p>
        <p className="p3"><span className="s1">These legal bases depend on the services you use and how you use them, meaning we collect and use your information only where:</span>
        </p>
        <ul className="ul1">
          <li className="li5"><span className="s4">it&rsquo;s necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract (for example, when we provide a service you request from us);</span>
          </li>
          <li className="li5"><span className="s4">it satisfies a legitimate interest (which is not overridden by your data protection interests), such as for research and development, to market and promote our services, and to protect our legal rights and interests;</span>
          </li>
          <li className="li5"><span className="s4">you give us consent to do so for a specific purpose (for example, you might consent to us sending you our newsletter); or</span>
          </li>
          <li className="li8"><span
            className="s4">we need to process your data to comply with a legal obligation.</span></li>
        </ul>
        <p className="p3"><span className="s1">Where you consent to our use of information about you for a specific purpose, you have the right to change your mind at any time (but this will not affect any processing that has already taken place).</span>
        </p>
        <p className="p1"><span className="s1">We don&rsquo;t keep personal information for longer than is necessary. While we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification. That said, we advise that no method of electronic transmission or storage is 100% secure and cannot guarantee absolute data security. If necessary, we may retain your personal information for our compliance with a legal obligation or in order to protect your vital interests or the vital interests of another natural person.</span>
        </p>
        <p className="p7"><span className="s1">3. Collection and use of information</span></p>
        <p className="p3"><span className="s1">We may collect, hold, use and disclose information for the following purposes and personal information will not be further processed in a manner that is incompatible with these purposes:</span>
        </p>
        <ul className="ul1">
          <li className="li5"><span className="s4">to enable you to customize or personalize your experience of our website;</span>
          </li>
          <li className="li5"><span className="s4">to enable you to access and use our website, associated applications and associated social media platforms;</span>
          </li>
          <li className="li5"><span className="s4">to contact and communicate with you;</span></li>
          <li className="li5"><span className="s4">for internal record keeping and administrative purposes;</span></li>
          <li className="li5"><span className="s4">for analytics, market research and business development, including to operate and improve our website, associated applications and associated social media platforms;</span>
          </li>
          <li className="li5"><span
            className="s4">to run competitions and/or offer additional benefits to you; and</span></li>
          <li className="li6"><span className="s4">to comply with our legal obligations and resolve any disputes that we may have.</span>
          </li>
        </ul>
        <p className="p7"><span className="s1">4. Disclosure of personal information to third parties</span></p>
        <p className="p3"><span className="s1">We may disclose personal information to:</span></p>
        <ul className="ul1">
          <li className="li5"><span className="s4">third party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, ad networks, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing or advertising providers, professional advisors and payment systems operators;</span>
          </li>
          <li className="li5"><span className="s4">our employees, contractors and/or related entities;</span></li>
          <li className="li5"><span className="s4">credit reporting agencies, courts, tribunals and regulatory authorities, in the event you fail to pay for goods or services we have provided to you; and</span>
          </li>
          <li className="li6"><span className="s4">courts, tribunals, regulatory authorities and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise or defend our legal rights.</span>
          </li>
        </ul>
        <p className="p7"><span className="s1">5. International transfers of personal information</span></p>
        <p className="p3"><span className="s1">The personal information we collect is stored and processed where we or our partners, affiliates and third-party providers maintain facilities. By providing us with your personal information, you consent to the disclosure to these overseas third parties.</span>
        </p>
        <p className="p3"><span className="s1">We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.</span>
        </p>
        <p className="p1"><span className="s1">Where we transfer personal information from a non-EEA country to another country, you acknowledge that third parties in other jurisdictions may not be subject to similar data protection laws to the ones in our jurisdiction. There are risks if any such third party engages in any act or practice that would contravene the data privacy laws in our jurisdiction and this might mean that you will not be able to seek redress under our jurisdiction&rsquo;s privacy laws.</span>
        </p>
        <p className="p7"><span className="s1">6. Your rights and controlling your personal information</span></p>
        <p className="p3"><span className="s1">Choice and consent: By providing personal information to us, you consent to us collecting, holding, using and disclosing your personal information in accordance with this privacy policy. If you are under 16 years of age, you must have, and warrant to the extent permitted by law to us, that you have your parent or legal guardian&rsquo;s permission to access and use the website and they (your parents or guardian) have consented to you providing us with your personal information. You do not have to provide personal information to us, however, if you do not, it may affect your use of this website or the products and/or services offered on or through it.</span>
        </p>
        <p className="p3"><span className="s1">Information from third parties: If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person&rsquo;s consent to provide the personal information to us.</span>
        </p>
        <p className="p3"><span className="s1">Restrict: You may choose to restrict the collection or use of your personal information. If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below. If you ask us to restrict or limit how we process your personal information, we will let you know how the restriction affects your use of our website or products and services.</span>
        </p>
        <p className="p3"><span className="s1">Access and data portability: You may request details of the personal information that we hold about you. You may request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may request that we erase the personal information we hold about you at any time. You may also request that we transfer this personal information to another third party.</span>
        </p>
        <p className="p3"><span className="s1">Correction: If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant or misleading, please contact us using the details below. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading or out of date.</span>
        </p>
        <p className="p3"><span className="s1">Notification of data breaches: We will comply with laws applicable to us in respect of any data breach.</span>
        </p>
        <p className="p3"><span className="s1">Complaints: If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</span>
        </p>
        <p className="p1"><span className="s1">Unsubscribe: To unsubscribe from our e-mail database or opt-out of communications (including marketing communications), please contact us using the details below or opt-out using the opt-out facilities provided in the communication.</span>
        </p>
        <p className="p7"><span className="s1">7. Cookies</span></p>
        <p className="p1"><span className="s1">We use &ldquo;cookies&rdquo; to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified. Please refer to our Cookie Policy for more information.</span>
        </p>
        <p className="p7"><span className="s1">8. Business transfers</span></p>
        <p className="p1"><span className="s1">If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may continue to use your personal information according to this policy.</span>
        </p>
        <p className="p7"><span className="s1">9. Limits of our policy</span></p>
        <p className="p1"><span className="s1">Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</span>
        </p>
        <p className="p7"><span className="s1">10. Changes to this policy</span></p>
        <p className="p3"><span className="s1">At our discretion, we may change our privacy policy to reflect current acceptable practices. We will take reasonable steps to let users know about changes via our website. Your continued use of this site after any changes to this policy will be regarded as acceptance of our practices around privacy and personal information.</span>
        </p>
        <p className="p3"><span className="s1">If we make a significant change to this privacy policy, for example changing a lawful basis on which we process your personal information, we will ask you to re-consent to the amended privacy policy.</span>
        </p>
        <p className="p9"><span className="s1">This policy is effective as of May 1, 2020.</span></p>
        <p className="p10"><br/></p>
        <p className="p11"><span className="s1">Cookie Policy</span></p>
        <p className="p3"><span className="s1">We use cookies to help improve your experience of <a
          href="http://www.anymeets.com/"><span className="s2">https://www.anymeets.com</span></a>. This cookie policy is part of Datawise Data Engineering LLC&#39;s privacy policy, and covers the use of cookies between your device and our site.</span>
        </p>
        <p className="p1"><span className="s1">If you don&rsquo;t wish to accept cookies from us, you should instruct your browser to refuse cookies from <a
          href="http://www.anymeets.com/"><span className="s2">https://www.anymeets.com</span></a>, with the understanding that we may be unable to provide you with some of your desired content and services.</span>
        </p>
        <p className="p7"><span className="s1">What is a cookie?</span></p>
        <p className="p3"><span className="s1">A cookie is a small piece of data that a website stores on your device when you visit, typically containing information about the website itself, a unique identifier that allows the site to recognize your web browser when you return, additional data that serves the purpose of the cookie, and the lifespan of the cookie itself.</span>
        </p>
        <p className="p3"><span className="s1">Cookies are used to enable certain features (eg. logging in), to track site usage (eg. analytics), to store your user settings (eg. timezone, notification preferences), and to personalize your content (eg. advertising, language).</span>
        </p>
        <p className="p1"><span
          className="s1">Cookies set by the website you are visiting are normally referred to as &ldquo;first-party cookies&rdquo;, and typically only track your activity on that particular site. Cookies set by other sites and companies (ie. third parties) are called &ldquo;third-party cookies&rdquo;, and can be used to track you on other websites that use the same third-party service.</span>
        </p>
        <p className="p2"><span className="s1">Types of cookies and how we use them</span></p>
        <p className="p3"><span className="s1">Essential cookies</span></p>
        <p className="p4"><span className="s1">Essential cookies are crucial to your experience of a website, enabling core features like user logins, account management, shopping carts and payment processing. We use essential cookies to enable certain functions on our website.</span>
        </p>
        <p className="p3"><span className="s1">Performance cookies</span></p>
        <p className="p4"><span className="s1">Performance cookies are used in the tracking of how you use a website during your visit, without collecting personal information about you. Typically, this information is anonymous and aggregated with information tracked across all site users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their audience&rsquo;s overall website experience. These cookies may be set by the website you&rsquo;re visiting (first-party) or by third-party services. We use performance cookies on our site.</span>
        </p>
        <p className="p3"><span className="s1">Functionality cookies</span></p>
        <p className="p4"><span className="s1">Functionality cookies are used in collecting information about your device and any settings you may configure on the website you&rsquo;re visiting (like language and time zone settings). With this information, websites can provide you with customized, enhanced or optimized content and services. These cookies may be set by the website you&rsquo;re visiting (first-party) or by third-party service. We use functionality cookies for selected features on our site.</span>
        </p>
        <p className="p3"><span className="s1">Targeting/advertising cookies</span></p>
        <p className="p1"><span className="s1">Targeting/advertising cookies are used in determining what promotional content is more relevant and appropriate to you and your interests. Websites may use them to deliver targeted advertising or to limit the number of times you see an advertisement. This helps companies improve the effectiveness of their campaigns and the quality of content presented to you. These cookies may be set by the website you&rsquo;re visiting (first-party) or by third-party services. Targeting/advertising cookies set by third-parties may be used to track you on other websites that use the same third-party service. We do not use this type of cookie on our site.</span>
        </p>
        <p className="p7"><span className="s1">How you can control or opt out of cookies</span></p>
        <p className="p3"><span className="s1">If you do not wish to accept cookies from us, you can instruct your browser to refuse cookies from our website. Most browsers are configured to accept cookies by default, but you can update these settings to either refuse cookies altogether, or to notify you when a website is trying to set or update a cookie.</span>
        </p>
        <p className="p3"><span className="s1">If you browse websites from multiple devices, you may need to update your settings on each individual device.</span>
        </p>
        <p className="p9"><span className="s1">Although some cookies can be blocked with little impact on your experience of a website, blocking all cookies may mean you are unable to access certain features and content across the sites you visit.</span>
        </p>
      </Box>
    </Box>
  </Page>
})

export default Privacy
