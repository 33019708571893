import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useState} from 'react'
import {Menu, MenuItem, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Chip from '@material-ui/core/Chip'
import Dialog from '@material-ui/core/Dialog'
import CommunicationEditNew from 'components/core/CommunicationEditNew'
import {EMAIL_TEMPLATE} from "config";

const styles = theme => ({
  paperReviewsContainer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: 'calc(100vw - 300px)'
  },
  noPaperReviewsYet: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '145px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  fieldTitle: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  addBtn: {
    textAlign: 'right'
  },
  navTabs: {
    position: 'relative',
    marginBottom: theme.spacing(3)
  },
  downloadBtn: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(3)
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3)
  },
  totalStats: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2,2,4,2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold'
  },
})
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

const PaperReviews = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {

  // Hooks
  const [getData, data] = useHttp()

  const [getExcel, dataExcel, isLoadingExcel] = useHttp()

  // State
  const [paperReviews, setPaperReviews] = useState()
  const [CsvData, setCsvData] = useState()
  const [anchorEl, setAnchorEl] = useState(null)
  const [editCommunication, setEditCommunication] = useState(false)
  const [typeOfEmail, setTypeOfEmail] = useState(false)


  // Deps
  const {eventId} = useParams()

  const getPageData = () => {
    getData(api.entities.manage.getPaperReviews(null, null, {id: eventId}))
  }

  const handleClickNotify = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseNotify = (type) => {
    setAnchorEl(null)

    switch (type) {
      case 0 : {
        setTypeOfEmail(EMAIL_TEMPLATE.REVIEWER_ASSIGNMENTS_ALL)
        setEditCommunication(true)
        break
      }
      case 1 : {
        setTypeOfEmail(EMAIL_TEMPLATE.REVIEWER_ASSIGNMENTS_PENDING)
        setEditCommunication(true)
        break
      }
      case 2 : {
        setTypeOfEmail(EMAIL_TEMPLATE.REVIEWER_ASSIGNMENTS_NEW)
        setEditCommunication(true)
        break
      }
      default: {
        setTypeOfEmail(false)
        setEditCommunication(false)
      }
    }
  }

  const handleEditCloseCommunication = () => {
    setEditCommunication(false)
    setTypeOfEmail(false)
  }


  useEffect(()=>{
    getPageData()
    //eslint-disable-next-line
  },[])

  useEffect(() => {
    if (data) {
      let reviewsTransformed = []
      data.reviews.forEach((r)=>{
        reviewsTransformed.push({
          ...r,
          status: r.reviewId?'Completed':'Pending'
        })
      })
      setPaperReviews(reviewsTransformed)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  const downloadPaperReviews = () => {
    getExcel(api.entities.manage.downloadPaperReviews(null, null, {id: eventId}))
  }

  useEffect(() => {
    if (dataExcel) {
      setCsvData(dataExcel)
    }
  }, [dataExcel])

  useEffect(() => {
    if (CsvData) {
      let downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(CsvData[0])
      downloadLink.download = `${data.eventInfo.name} - paperReviews.xlsx`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
  }, [CsvData, data, dataExcel])

  if (!paperReviews) return <> </>

  const total = paperReviews.length
  const completed = paperReviews.filter(r => r.reviewId !== null).length
  const pending = paperReviews.filter(r => (r.reviewId === null)).length
  const pendingEmail = paperReviews.filter(r => (r.notified===true&&r.reviewId === null)).length
  const newReviews = paperReviews.filter(r=>r.notified===false).length

  return <>
    <Fade in={true} timeout={1200}>
      <>

        <Grid container spacing={2} className={classes.paperReviewsContainer}>

          <Grid item xs={12} sm={12} md={4}>
            <Typography component='h2' variant='h5' className={classes.title}>
              Reviews
            </Typography>
          </Grid>

          <Grid item xs>
            <Grid item container justify='flex-end' spacing={2}>
              <Grid item>
                <Chip label={`Total ${total}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Completed ${completed}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Pending ${pending}`} variant='outlined'/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button size='smaller' color='primary' disabled={isLoadingExcel} onClick={() => {
              !isLoadingExcel&&downloadPaperReviews()
            }}> Download Excel file </Button>
          </Grid>

          <Grid item>

            <Button size='smaller' color='secondary' onClick={handleClickNotify}>
              Notify reviewers
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseNotify}
            >
              <MenuItem onClick={()=>handleCloseNotify(0)}>All</MenuItem>
              <MenuItem onClick={()=>pendingEmail!==0&&handleCloseNotify(1)} disabled={pendingEmail===0}>Only with pending reviews</MenuItem>
              <MenuItem onClick={()=>newReviews&&handleCloseNotify(2)} disabled={!newReviews}>Only with new assignments</MenuItem>
            </Menu>

          </Grid>

          {paperReviews&&<Grid item xs={12}>
            <MaterialTable
              key={paperReviews}
              icons={tableIcons}
              columns={[
                { title: 'ID', field: 'submissionId' , width: 50},
                { title: 'Title', field: 'title' },
                { title: 'Authors', field: 'authors', render: rowData => <> {rowData.authors&&rowData.authors.join(', ')} </>},
                { title: 'Reviewer', field: 'reviewer' },
                { title: 'Status', field: 'status' },
                { title: 'Grade', field: 'grade' , render: rowData => <> {rowData.grade?rowData.grade:'-'} </>},
              ]}
              data={paperReviews}
              title=''
              options={{
                filtering: true,
                actionsColumnIndex:999,
                addRowPosition: 'first',
                headerStyle: {
                  backgroundColor: palette['brown-grey'],
                  color: '#FFF',
                  fontWeight: 'bold',
                  fontSize: 14
                },
                rowStyle: {
                  fontSize: 12,
                  padding: 0
                }
              }}
            />
          </Grid>}

        </Grid>

      </>
    </Fade>
    <Dialog onClose={handleEditCloseCommunication} open={editCommunication} fullWidth maxWidth='md'>
      <CommunicationEditNew handleCloseNotify={handleEditCloseCommunication} eventId={eventId} eventInfo={data.eventInfo} defaultGroup={typeOfEmail} />
    </Dialog>
  </>
})

export default withRouter(PaperReviews)
