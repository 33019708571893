import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import Container from "@material-ui/core/Container";
import AnyHomeImage from 'assets/top_circles.svg'
import LeftFlowers from 'assets/top_graphic.svg'
import Button from "@material-ui/core/Button";
import HomeHeroImg from "assets/home-hero-pic.png";
import { Link } from "react-router-dom";

const styles = theme => ({
  hero: {
    maxWidth: '100%',
    minHeight: 600,
    backgroundSize: 'contain',
    backgroundImage: 'url(' + LeftFlowers + '), linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom left',
    marginBottom: '70px',
    marginTop: '-64px',
    [theme.breakpoints.down('xs')]: {
      minHeight: 'initial',
    }
  },
  tagLine: {
    maxWidth: '440px',
    color: '#fff',
    fontSize: '36px',
    fontFamily: 'Nunito,Karla',
    textAlign: 'left',
    fontWeight: '600',
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(4),
    letterSpacing: '0.3px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      padding: '0 0 24px 0px',
      marginBottom: 0,
      marginTop: '110px'
    } 
  },
  title: {
    maxWidth: '590px',
    color: '#fff',
    fontSize: '20px',
    textAlign: 'center',
    fontWeight: 'normal',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      padding: theme.spacing(1),
      marginBottom: 0,
    }
  },
  subtitle: {
    maxWidth: '400px',
    color: "#fff",
    fontSize: '20px',
    fontFamily: 'Karla',
    textAlign: 'left',
    fontWeight: '200',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      padding: '0 24px 0 0'
    }
  },
  anyHomeImage: {
    width: '100%',
    height: 'auto',
    maxWidth: "500px",
    position: "relative",
    borderRadius: "20px",
  },
  anyHomeImageBg: {
    width: '100%',
    height: '450px',
    maxWidth: "600px",
    backgroundImage: 'url(' + AnyHomeImage + ')',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },  
  buttonTry: {
    height: '50px',
    fontFamily: 'Karla',
    fontSize: '18px',
  //  backgroundColor: '#a3ce47',
   backgroundColor: '#03a496',
    color: '#fff',
    width: '170px !important',
    minWidth: 'unset !important',
  //  border: '1px solid #a3ce47',
   border: '1px solid #03a496',
    borderRadius: '50px',
    cursor: 'pointer',
    '&&:hover': {
        backgroundColor: 'transparent',
        border: '1px solid #fff',
    },
    marginRight: '30px'
  },
  buttonDemo: {
    height: '50px',
    fontFamily: 'Karla',
    fontSize: '18px',
    border: '1px solid #fff',
    backgroundColor: 'transparent !important',
    color: '#fff',
    width: '170px !important',
    minWidth: 'unset !important',
    borderRadius: '50px',
    cursor: 'pointer',
    '&&:hover': {
        backgroundColor: '#71912e',
        border: '1px solid #71912e',
        color: '#71912e'
    }

  }
})

const HomeHero =  withStyles(styles)(({classes}) => {


    return <Grid item container lg={12} className={classes.hero} justify='center' alignItems='center'>
      <Grid item container lg={12}>
        <Grid item lg={2} md={1} sm={1} xs={1}> </Grid>

        <Grid item lg={4} md={5} sm={5}  xs={11}>
          <Grid item style={{height:'100%'}}>

            <Grid item>
              <Grid item  className={classes.tagLine}>
                <Grid item>
                  Less time managing your event, more time participating in it!
                </Grid>
              </Grid>
              <Grid item  className={classes.subtitle}>
                <Grid item>
                  From Call for Papers to registration, Anymeets helps you manage your next conference, workshop or seminar.
                </Grid>
              </Grid>
              <Grid item  md={6} xs={12} style={{display: 'initial'}}>
                {/* <Button variant={'contained'} className={classes.buttonTry} component={Link} to={`/create-event`}>Try now for free</Button> */}
                {/* <Button variant={'contained'} className={classes.buttonTry} component={Link} to={`/select-package`}>Try now for free</Button> */}
                {/* <p style={{color:"#ffffff",fontSize:"0.7rem",marginLeft: "25px"}}>* No credit card needed</p> */}
              </Grid>
              {/* <Grid item  md={6} xs={12} style={{display: 'initial'}}>
                <Button variant={'contained'} className={classes.buttonDemo}>See demo</Button>
              </Grid> */}
            </Grid>


          </Grid>

        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Grid container item  justify='center' alignContent='center' alignItems='center' className={classes.anyHomeImageBg}>
            <img src={HomeHeroImg} className={classes.anyHomeImage} alt={'Anymeets is an all-inclusive web and mobile solution to managing your next conference, workshop or meeting.'}/>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

})

export default HomeHero
