import {Box, Typography} from '@material-ui/core'
import {withStyles} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import {palette} from 'theme'
import Tab from '@material-ui/core/Tab'
import React from 'react'
import clsx from 'clsx'
import {lightOrDark} from 'utils'

const styles = theme => ({
  navTab: {

  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  indicator: {
    display: 'none'
  },
})(props => <Tabs {...props} variant="scrollable"
                  scrollButtons="auto" TabIndicatorProps={{ children: <div /> }} />)

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: palette['greyish-brown'],
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.grey[300],
    borderRadius: theme.spacing(8),
    opacity: 1,
    minHeight: '36px',
    minWidth: 'auto',
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2)
  },
  selected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important'
  },
  white: {
    backgroundColor: 'white',
    border: `1px solid ${palette['very-light-pink']}`
  }
}))(props => <Tab disableRipple {...props} className={clsx(props.white&&props.classes.white)} />);

const AnyTabs = withStyles(styles)(({classes, tabs, index=0, white, onChange,hidePanel})=>{
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    onChange&&onChange(newValue)
    setValue(newValue)
  }


  return (
    <Box className={classes.tabNav} key={index}>
      <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
        {tabs.map((tab,i)=><StyledTab style={{backgroundColor:tab.track?tab.track:'white',border:`1px solid ${palette['very-light-pink']}`,color:tab.track?lightOrDark(tab.track):''}} disabled={tab.disabled} label={tab.icon?<>{tab.icon}{tab.label}</>:tab.track?<Box display='flex' flexDirection = 'row'><Box flexDirection = 'column' width='12px' height='12px' mt={'6px'} mr={'6px'} style={{borderRadius:'6px',backgroundColor:tab.track}}/><Box flexDirection = 'column'>{tab.label}</Box></Box>:<>{tab.label}</>} {...a11yProps(i)} onClick={tab.onClick&&tab.onClick}/>)}
      </StyledTabs>
      {tabs.map((tab,i)=><TabPanel value={value} style={{display: hidePanel ? "none":"block"}}index={i}>{tab.component}</TabPanel>)}
    </Box>
  );

})

export default AnyTabs
