import React, { useCallback, useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Link, useLocation, useParams, withRouter } from 'react-router-dom'
import { palette } from 'theme'
import Box from '@material-ui/core/Box'
import { IconArrowLeftBorderGray, IconViewBorderGreen } from 'components/core/icons'
// import drawerRoutes, {navigation} from 'components/pages/ManageEvent/routes'
import EllipsisText from 'react-ellipsis-text'
import { useMetaData } from 'components/core/MetaProvider'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import TreeViewMenu from 'components/core/TreeViewMenu'
import Alert from '@material-ui/lab/Alert'
import Button from 'components/core/Button'
import AlertTitle from '@material-ui/lab/AlertTitle'
import api from 'api'
import { useHttp } from 'api/core'
import { useSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import General from 'components/pages/ManageEvent/General'
import Layout from 'components/pages/ManageEvent/Layout'
import Participants from 'components/pages/ManageEvent/Participants'
import Sessions from 'components/pages/ManageEvent/Sessions'
import Sponsors from 'components/pages/ManageEvent/Sponsors'
import Tickets from 'components/pages/ManageEvent/Tickets'
import Collaborators from 'components/pages/ManageEvent/Collaborators'
import Users from 'components/pages/ManageEvent/Users'
import Extras from 'components/pages/ManageEvent/Extras'
import Content from "components/pages/ManageEvent/Content"
import RestaurantsAndHotels from 'components/pages/ManageEvent/RestaurantsAndHotels'
import Organizers from 'components/pages/ManageEvent/Organizers'
import SubmissionsSetup from 'components/pages/ManageEvent/SubmissionsSetup'
import ReviewsSetup from 'components/pages/ManageEvent/ReviewsSetup'
import SessionTypes from 'components/pages/ManageEvent/SessionTypes'
import Tracks from 'components/pages/ManageEvent/Tracks'
import Rooms from 'components/pages/ManageEvent/Rooms'
import Presentations from 'components/pages/ManageEvent/Presentations'
import Schedule from 'components/pages/ManageEvent/Schedule'
import Feedback from 'components/pages/ManageEvent/Feedback'
import Statistics from 'components/pages/ManageEvent/Statistics'
import Attendees from 'components/pages/ManageEvent/Attendees'
import Communication from 'components/pages/ManageEvent/Communication/Communication'
import Reviewers from 'components/pages/ManageEvent/Reviewers'
import Submissions from 'components/pages/ManageEvent/Submissions'
import AssignReviewers from 'components/pages/ManageEvent/AssignReviewers'
import PaperReviews from 'components/pages/ManageEvent/PaperReviews'
import PaperAcceptance from "./PaperAcceptance";
import AssignSessions from 'components/pages/ManageEvent/AssignSessions'
import Presenters from 'components/pages/ManageEvent/Presenters'
import AcceptedPapers from "components/pages/ManageEvent/AcceptedPapers"
import BuyCredits from "components/pages/ManageEvent/Plans/BuyCredits"
import EventFlow from "components/pages/ManageEvent/EventFlow"



const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    top: '63px',
    backgroundColor: '#42495f',
    color: 'white',
    alignItems: 'flex-start',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${ drawerWidth }px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#42495f',
    color: 'white',
    zIndex: 0,
    alignItems: 'flex-start',
    overflow: 'hidden',
    top: '0px',
    height: 'calc(100% - 128px)',
    [theme.breakpoints.up('md')]: {
      top: '63px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  content: {
    flexGrow: 1,
    backgroundColor: palette['pale-grey'],
    color: palette['greyish-brown'],
  },
  innerContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(5)
    },
    // paddingBottom: theme.spacing(12),
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7)
  },
  pageTitle: {
    fontSize: '17px',
    fontWeight: 'bolder',
    minHeight: '80px',
  },
  listItem: {
    // width: '180px',
    borderRadius: theme.spacing(3),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  selected: {
    backgroundColor: palette['cerulean-blue'],
    '&:hover': {
      backgroundColor: palette['cerulean-blue'],
    }
  },
  navBack: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: '14px',
    cursor: 'pointer',
    paddingRight: '50px'
  },
  dirtyTag: {
    position: 'absolute',
    top: '16px',
    color: palette.aquamarine,
    fontWeight: 'bold'
  },
  previewBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: palette.aquamarine,
    fontWeight: 'bold',
    '& > a': {
      display: 'flex',
      alignItems: 'center',
      color: palette.aquamarine,
      fontWeight: 'bold',
      textDecoration: 'none'
    }
  }
}))

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () =>
{
  return new URLSearchParams(useLocation().search);
}

const defaultPage = 'general'

const ManageEvent = ({ name, history, location, ...props }) =>
{
  const { container } = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [navBack, setNavBack] = useState({ name: 'events', path: '/manage-events' })
  const [pageTitle, setPageTitle] = useState(' ')
  const [eventInfo, setEventInfo] = useState()
  const [getDataUnpublish, dataUnpublish, isUnpublishing] = useHttp()
  const [getDataClearCache, dataClearCache] = useHttp()
  const [getData, data] = useHttp();
  const { eventId } = useParams()
  const [, setMetaData] = useMetaData()
  const [getDataPublish, dataPublish] = useHttp()
  const { enqueueSnackbar } = useSnackbar()

  const handleDrawerToggle = () =>
  {
    setMobileOpen(!mobileOpen)
  }

  useEffect(() =>
  {
    if (eventId)
    {
      getData(api.entities.manage.getGeneral(null, null, { id: eventId }));
    }
    // eslint-disable-next-line
  }, [eventId])

  useEffect(() =>
  {
    if (eventInfo)
    {
      setMetaData({ title: `Manage Event - ${ eventInfo.name }` })
    }
    // eslint-disable-next-line
  }, [eventInfo])

  const ViewEvent = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={() => { history.push(`/event/${ eventId }`) }}> View Event </Button>, [eventId, history])

  useEffect(() =>
  {
    if (dataPublish)
    {
      if (dataPublish.status && dataPublish.status === 201)
      {
        enqueueSnackbar('Your event was successfully published!', { variant: 'success', action: ViewEvent })
        setEventInfo(f => ({ ...f, dirty: false }))
      } else
      {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    }
    // eslint-disable-next-line
  }, [dataPublish, ViewEvent, eventId, setEventInfo])

  const publishEvent = () =>
  {
    getDataPublish(api.entities.events.publish(null, null, { id: eventId }))
  }

  useEffect(() =>
  {
    if (dataUnpublish)
    {
      handleUnpublishClose()
      if (dataUnpublish.status && dataUnpublish.status === 204)
      {
        enqueueSnackbar('Your event was successfully unpublished.', { variant: 'success', action: ViewEvent })
        setEventInfo(f => ({ ...f, dirty: false }))
      } else
      {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    }
    // eslint-disable-next-line
  }, [dataUnpublish])

  const handleUnpublishEvent = () =>
  {
    getDataUnpublish(api.entities.events.unpublish(null, null, { id: eventId }))
  }

  const handleUnpublishClose = () =>
  {
    history.push(location.pathname)
  }

  const q = useQuery()
  const action = q.get('action')

  useEffect(() =>
  {
    if (action === 'clearcache')
    {
      getDataClearCache(api.entities.events.clearcache(null, null, { eventId: eventId }))
    }
    else if (action === 'unpublish')
    {
      getDataUnpublish(api.entities.events.unpublish(null, null, { id: eventId }))
    }
    history.push(location.pathname)
    // eslint-disable-next-line
  }, [action])

  useEffect(() =>
  {
    if (dataClearCache && dataClearCache.status === 204)
    {
      enqueueSnackbar('Cache was successfully cleared', { variant: 'success', action: ViewEvent })
    }
    //eslint-disable-next-line
  }, [dataClearCache])

  let { page } = useParams();


  const [baseURL, setBaseURL] = useState(`/event/${ eventId }`)

  useEffect(() =>
  {
    if (eventInfo)
    {


      // alert(JSON.stringify(eventInfo))
      let baseURL = `/event/${ eventId }/`

      if (eventInfo?.slug)
      {
        baseURL = `/${ eventInfo.slug }`
      }

      setBaseURL(baseURL)

    }
    // eslint-disable-next-line
  }, [eventInfo])


  const navigation = {
    routes: [
      {
        name: "Event setup",
        path: false,
      },
      {
        name: "General",
        path: "general",
        component: General,
        parent: "Event setup",
      },
      {
        name: "Layout",
        path: "layout",
        component: Layout,
        parent: "Event setup",
      },
      {
        name: "Content",
        path: "content",
        component: Content,
        parent: "Event setup",
      },
      {
        name: "Submissions setup",
        path: "submissions-setup",
        component: SubmissionsSetup,
        parent: "Event setup",
        hidden: !data?.general?.activateSubmissions || false
      },      
      {
        name: "Reviews setup",
        path: "reviews-setup",
        component: ReviewsSetup,
        parent: "Event setup",
        hidden: !data?.general?.activateSubmissions || false
      },      
      {
        name: "Tickets & Registrations",
        path: "tickets",
        component: Tickets,
        parent: "Event setup",
      },
      // {
      //   name: "Extras",
      //   path: "extras",
      //   component: Extras,
      //   parent: "Event setup",
      // },
      {
        name: "Sponsors",
        path: "sponsors",
        component: Sponsors,
        parent: "Event setup",
      },      
      {
        name: "Restaurants & Hotels",
        path: "restaurants-hotels",
        component: RestaurantsAndHotels,
        parent: "Event setup",
      },
      {
        name: "Organizers",
        path: "organizers",
        component: Organizers,
        parent: "Event setup",
      },      
      {
        name: "Collaborators",
        path: "collaborators",
        component: Collaborators,
        parent: "Event setup",
      },
      {
        name: "Users",
        path: "users",
        component: Users,
        parent: "Event setup",
      },
      {
        name: "Schedule & Speakers",
        path: false,
      },
      {
        name: "Session types",
        path: "session-types",
        component: SessionTypes,
        parent: "Schedule & Speakers",
      },
      {
        name: "Sessions",
        path: "sessions",
        component: Sessions,
        parent: "Schedule & Speakers",
      },
      {
        name: "Schedule",
        path: "schedule",
        component: Schedule,
        parent: "Schedule & Speakers",
      },
      {
        name: "Speakers",
        path: "participants",
        component: Participants,
        parent: "Schedule & Speakers",
      },

      {
        name: "Presentations",
        path: "presentations",
        component: Presentations,
        parent: "Schedule & Speakers",
      },
      {
        name: "Tracks",
        path: "tracks",
        component: Tracks,
        parent: "Schedule & Speakers",
      },
      {
        name: "Rooms",
        path: "rooms",
        component: Rooms,
        parent: "Schedule & Speakers",
      },
      {
        name: "Paper management",
        path: false,
        hidden: !data?.general?.activateSubmissions || false
      },
      {
        name: "Submitted papers",
        path: "submissions",
        component: Submissions,
        parent: "Paper management",
      },
      {
        name: "Accepted papers",
        path: "accepted-papers",
        component: AcceptedPapers,
        parent: "Paper management",
      },
      {
        name: "Paper reviews",
        path: "reviews",
        component: PaperReviews,
        parent: "Paper management",
      },
      {
        name: "Reviewer assignments",
        path: "reviewer-assignments",
        component: AssignReviewers,
        parent: "Paper management",
      },      
      {
        name: "Paper acceptance",
        path: "paper-acceptance",
        component: PaperAcceptance,
        parent: "Paper management",
      },
      {
        name: "Papers to session assignments",
        path: "paper-assignments",
        component: AssignSessions,
        parent: "Paper management",
      },
      {
        name: "Participants management",
        path: false,
      },      
      {
        name: "Submitters",
        path: "submitters",
        component: Presenters,
        parent: "Participants management",
      },
      {
        name: "Presenters",
        path: "presenters",
        component: Presenters,
        parent: "Participants management",
      },
      {
        name: "Co-Authors",
        path: "coauthors",
        component: Presenters,
        parent: "Participants management",
      },
      {
        name: "Reviewers",
        path: "reviewers",
        component: Reviewers,
        parent: "Participants management",
      },
      {
        name: "Attendees",
        path: "attendees",
        component: Attendees,
        parent: "Participants management",        
      },  
      {
        name: "Email communication",
        path: "communication",
        component: Communication,
      },      
      {
        name: "Statistics",
        path: "statistics",
        component: Statistics,
      },
      {
        name: "Feedback",
        path: "feedback",
        component: Feedback,
      },      
      {
        separator: true,
      },
      {
        name: "Event Flow",
        path: "event-flow",
        component: EventFlow,        
      },      
      {
        separator: true,
      },
      // {
      //   name: "Buy Credits",
      //   path: "upgrade",
      //   component: BuyCredits,
      // },
      // {
      //   separator: true,
      // },

      {
        name: "Unpublish Event",
        path: "?action=unpublish",
      },
      {
        name: "Clear Cache",
        path: "?action=clearcache",
      },
      {
        separator: true,
      },
    ],
  };



  const drawerRoutes = function ()
  {
    let routes = []
    navigation.routes.forEach((entry, index) =>
    {
      if (entry.path === false)
      { // if no path, it's a parent, add all related children
        let children = navigation.routes.filter((child, i) => child.parent === entry.name)
        routes.push({ ...entry, children })
      } else if (!entry.parent)
      { // if not, and there's no parent assigned it's single entry - just add
        routes.push(entry)
      }
    })
    return routes
  }()

  // Load requested page, if not found load default page
  const componentPos = navigation.routes.map((o) => o.path).indexOf(page ? page : defaultPage) || navigation.routes.map((o) => o.path).indexOf(defaultPage)
  const PageComponent = navigation.routes[componentPos].component || navigation.routes[navigation.routes.map((o) => o.path).indexOf(defaultPage)].component



  const drawer = (<PerfectScrollbar>
    {navBack &&
      <Box p={2} pb={0} className={classes.navBack} onClick={() => { history.push(navBack.path) }}>
        <Box display='flex' mr={2}><IconArrowLeftBorderGray /></Box>
        <Box display='flex' mt={0.5}>Back to {navBack.name}</Box>
      </Box>}

    {pageTitle && <Box display='flex' justifyContent='center' alignItems='center' pt={1} pb={1} className={classes.pageTitle}>
      <Box display='flex' width={176} pr={4} pl={4}>
        <EllipsisText text={pageTitle} length={60} />
      </Box>
    </Box>
    }

    <Box className={classes.previewBtn} mr={2}>
      <Link to={`${ baseURL }?preview=true`}> <IconViewBorderGreen />&nbsp;&nbsp;PREVIEW </Link>
    </Box>

    <Box display='flex' justifyContent={'center'} alignItems={'center'} pt={1} pb={8}>
      <Box display='flex'>
        <TreeViewMenu navObj={drawerRoutes} eventData={data} pageName={navigation.routes[componentPos].name} upgraded={(eventInfo && eventInfo.upgraded)} />
      </Box>
    </Box>

  </PerfectScrollbar>
  )

  return (
    <PerfectScrollbar>
      <div className={classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography component='h2' variant='h6' className={classes.title}>
              <EllipsisText text={pageTitle} length={60} />
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden smDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <Box component='main' className={classes.content} minHeight={window.innerHeight - 128}>
          {/* {eventInfo&&eventInfo.dirty&&<Alert severity='warning' action={<>
          <Button color='primary' size='smaller' onClick={()=>publishEvent()}>
            PUBLISH
          </Button>
          &nbsp;</>}>
          <AlertTitle>Unpublished changes</AlertTitle>
          You are viewing an event with unpublished changes.
        </Alert>} */}

          <Dialog
            open={action === 'unpublish'}
            onClose={() => handleUnpublishClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          ><Box p={3} pt={1}>
              <DialogTitle id="alert-dialog-title">Unpublish event</DialogTitle>
              <DialogContent>
                <Box p={1} pl={0} pb={2}>
                  Are you sure you want to unpublish the event?
                </Box>
              </DialogContent>
              <DialogActions>
                <Box p={1}>
                  <Button onClick={() => handleUnpublishClose()} size='small' variant='outlined' color='primary' disabled={isUnpublishing}>
                    No
                  </Button>
                </Box>
                <Box p={1}>
                  <Button onClick={() => handleUnpublishEvent()} size='small' autoFocus color='primary' disabled={isUnpublishing}>
                    Yes
                  </Button>
                </Box>
              </DialogActions>
            </Box>
          </Dialog>

          <Box className={classes.innerContent}>
            <PageComponent setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />
          </Box>
        </Box>
      </div></PerfectScrollbar>
  )

}

export default withRouter(ManageEvent)
