import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import api from 'api'
import {useHttp} from 'api/core'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import Switch from '@material-ui/core/Switch'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Box from '@material-ui/core/Box'
import ParticipantToolTipView from 'components/core/ParticipantToolTipView'
import PanToolIcon from '@material-ui/icons/PanTool'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Button from '@material-ui/core/Button'
const styles = theme => ({
  controls: {
    fontSize: 12,
    '& span': {
      fontSize: 12
    }
  },
  chip: {
    backgroundColor: '#eee',
    height: 24,
    '& span': {
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  list: {
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4
  },
  raisedHand: {
    color: theme.palette.secondary.main,
    marginTop: 2,
    position: 'absolute',
    left: 38,
    background: 'white',
    borderRadius: '50%',
    padding: 3,
    bottom: -1,
    animationName: 'wobble',
    webkitAnimationName: 'wobble',
    webkitAnimationDuration:'1s',
    animationDuration: '1s',
    webkitAnimationFillMode: 'both',
    animationFillMode: 'both'
  },
  participantName: {
    maxWidth: 190,
    '& span': {
      fontSize: 13
    }
  }
})

const HostActions = withStyles(styles)(({classes, sessionState, attendee})=>{

  const {
    roomId,
    participantId
  } = sessionState

  const [reqAdmit, , isLoadingAdmit, hasErrorAdmit] = useHttp()
  const [reqRemove, , isLoadingRemove, hasErrorRemove] = useHttp()
  const [attendeeState, setAttendeeState] = useState(attendee.state)

  const removeAttendee = (id) => {
    reqRemove(api.entities.virtualRooms.remove(null,null,{code: roomId, id:attendee.id}))
    setAttendeeState(0)
  }

  useEffect(()=>{
    if (hasErrorRemove) {
      setAttendeeState(attendee.state)
    }
    //eslint-disable-next-line
  },[hasErrorRemove])

  const admit = () => {
    reqAdmit(api.entities.virtualRooms.admit(null,null,{code: roomId, id:attendee.id}))
    setAttendeeState(2)
  }

  useEffect(()=>{
    if (hasErrorAdmit) {
      setAttendeeState(attendee.state)
    }
    //eslint-disable-next-line
  },[hasErrorAdmit])

  const loading = isLoadingRemove || isLoadingAdmit

  return <>
    {attendeeState>1&&attendee.id!==participantId&&<Box display='flex' pr={1}>
      <Button color='primary' variant='outlined' size='small' onClick={removeAttendee} disabled={loading}> Kick </Button>
    </Box>}
    {attendeeState===1&&<Box display='flex'><Button color='secondary'  variant='outlined' size='small' onClick={admit} disabled={loading}> Admit </Button></Box>}
  </>
})

const Audience = withStyles(styles)(({classes, sessionState}) => {

  const {
    roomId,
    participants,
    isCoach,
    userVisibility,
    audience,
    audienceTotalJoined,
    audienceTotalOnline,
    isChair,
    hasWaitingList,
    participantId
  } = sessionState

  const [putRequest, , puttingData, hasError] = useHttp()
  const [setPublisher] = useHttp()
  const [delSpeaker] = useHttp()

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)
  const [audienceList, setAudienceList] = useState(audience)
  const [visible, setVisible] = useState(userVisibility)

  const handleClick = (event,participant) => {
    setAnchorEl(event.currentTarget)
    setOpen(participant)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(()=>{
    if (!anchorEl) {
      setTimeout(()=>setOpen(false),200)
    }
  },[anchorEl])

  useEffect(()=>{
    if (audience) {
      setAudienceList([...audience])
    }
  },[audience])

  const id = open ? 'simple-popover' : undefined

  const toggleVisibility = () => {
    if (userVisibility) {
      setVisible(false)
      putRequest(api.entities.virtualRoom.delVisible(null,null,{id:roomId}))
    } else {
      setVisible(true)
      putRequest(api.entities.virtualRoom.setVisible(null,null,{id:roomId}))
    }
  }

  useEffect(()=>{
    if (hasError) {
      setVisible(!visible)
    }
    //eslint-disable-next-line
  },[hasError])

  const handleHand = (participantId) => {
    if (!participants.find((p)=>p.id===participantId)) {
      setPublisher(api.entities.virtualRoom.setPublisher(null,null,{id:roomId,userId:participantId}))
    } else {
      delSpeaker(api.entities.virtualRoom.delSpeaker(null,null,{id:roomId,participantId:participantId}))
    }
  }

  return <Grid item container justify='center' alignContent='center' alignItems='center' spacing={2}>

    <Grid item xs={12}>
      <Typography variant='h3'>Audience</Typography>
    </Grid>

    <Grid item container spacing={1} className={classes.controls}>
      <Grid item>
        <Chip label={<>Total joined: <b>{audienceTotalJoined}</b></>} className={classes.chip}/>
      </Grid>
      <Grid item>
        <Chip label={<>Total online: <b>{audienceTotalOnline}</b></>} className={classes.chip}/>
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Switch size='small' color='secondary' checked={visible} onChange={()=>toggleVisibility()} disabled={puttingData||userVisibility!==visible} key={userVisibility}/>
          }
          label='Appear Online'
          labelPlacement='start'
        />
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Typography variant='h3'>Online now</Typography>
    </Grid>

        {audienceList && audienceList.filter((a)=>(a.state===1||a.online)).length !== 0 && <Grid item xs={12}>
          <List
            className={classes.list} dense
          >

            {audienceList.filter((a)=>(a.state===1||a.online)).map((participant, i) => {
              const raisedHand = participant.raisedHand
              const isSelf = participant.id === participantId
              return <ListItem key={participant.id} className={clsx(classes.speaker)}  button onClick={(e)=>handleClick(e,participant)}>
              <ListItemAvatar className={classes.avatar}>
                <Avatar alt={`${participant.lastName} ${participant.firstName}`}
                                       src={participant.imageId?`${imageFile(participant.imageId)}`:null} className={classes.smallAvatar} />
              </ListItemAvatar>
              <ListItemText key={participant.id} id="switch-list-label" className={classes.participantName}
                            primary={<>
                              {raisedHand&&<PanToolIcon fontSize='small' className={classes.raisedHand} />}
                              {`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}
                            </>}/>
                {(isCoach||isChair)&&<ListItemSecondaryAction>
                  {(isChair||isCoach)&&!isSelf&&<Button variant='outlined' size='small' onClick={()=>handleHand(participant.id)}> {participants.find((p)=>p.id===participant.id)?`Disallow`:`Allow`} </Button>}
                  {hasWaitingList&&<HostActions attendee={participant} sessionState={sessionState} key={participant.state}/>}
                </ListItemSecondaryAction>}
            </ListItem>})}
          </List>
          <Popover
            id={id}
            open={!!open&&anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <Box className={classes.tooltip}>
              <ParticipantToolTipView participant={open} />
            </Box>
          </Popover>

        </Grid>}

  </Grid>
})

export default Audience
