import React from 'react'
import {useHttp} from 'api/core'
import {useEffect} from 'react'
import api from 'api'
import {useAuth} from 'auth/AuthController'
import Dialog from '@material-ui/core/Dialog'
import Profile from 'components/core/Profile'

export const UserInfoProvider = ({setProfileDialogOpen, profileDialogOpen}) => {
  // eslint-disable-next-line
  const [
    isAuthenticated,
    ,
    ,
    userInfo,
    setUserInfo] = useAuth()
  const [getUserData, userData] = useHttp()

// Handle user info
  useEffect(()=>{
    if (isAuthenticated) getUserData(api.entities.user.getInfo())
    // eslint-disable-next-line
  },[isAuthenticated])

  useEffect(()=>{
    if (userData) {
      setUserInfo({...userInfo, info: userData})
    }
    // eslint-disable-next-line
  },[userData])

  return <>
    <Dialog
      open={profileDialogOpen}
      onClose={()=>{setProfileDialogOpen(false)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <>{userInfo&&<Profile handleClose={setProfileDialogOpen} profile={userInfo.info}/>}</>
  </Dialog>
  </>
}
