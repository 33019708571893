import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Box from "@material-ui/core/Box";
import {CURRENCY} from "config";
import Grid from "@material-ui/core/Grid";
import Button from "components/core/Button";
import clsx from "clsx";
// import InfoIcon from "@material-ui/icons/Info";
// import IconButton from "@material-ui/core/IconButton";
// import PlanEventOverview from "components/core/PlanEventOverview";
// import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  planBox: {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "solid 1px #e6e6e6",
    position: "relative",
    padding: theme.spacing(2),
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  purchasedPlan: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7,
  },
  button: {
    height: "100%",
    position: "relative",
  },
  note: {
    left: 0,
    fontSize: "11px",
    position: "absolute",
    width: "100%",
    bottom: "-5px",
    textAlign: "center",
    color: palette["brown-grey"],
    fontWeight: "bold",
  },
  // container: {
  //   marginBottom: theme.spacing(2)
  // },
  priceColor: {
    color: theme.palette.primary.main,
  },
});

const AddCreditsCardView = withStyles(styles)(({classes, handleOpen, event, credits, existing, busy, setBusy, eventDuration, creditsPriceList, current}) => {
  return (
    <Grid container spacing={2} className={classes.container} justify="space-between">
      <Grid item container className={clsx(classes.planBox)} justify="space-between" spacing={2}>
        <Grid item xs={12} sm={7} md={9} lg={10}>
          <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
            <Box display="flex" fontSize="17px" fontWeight="bold" alignItems="center">
              <Box display="flex" style={{textTransform: "capitalize"}}>
                {current}
              </Box>
            </Box>

            {current === "papers" && (
              <Box fontSize="14px" lineHeight="19px" fontWeight="normal" pt={1}>
                <span>
                  <p>
                    Available papers: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.papersBought} </span>
                    {/* Available papers:{" "}
                    <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.papersBought - credits?.papersTotal >= 0 ? credits?.papersBought - credits?.papersTotal : "0"} </span> out of{" "}
                    <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.papersBought} </span> in total */}
                    {/* and{" "} <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.papersTotal - credits?.papersBought >= 0 ? credits?.papersTotal - credits?.papersBought : "0"} </span> papers are pending
                    out of <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.papersTotal} </span> papers in total. */}
                  </p>
                </span>
              </Box>
            )}
            {current === "posters" && (
              <Box fontSize="14px" lineHeight="19px" fontWeight="normal" pt={1}>
                <p>
                  Available posters: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.postersBought} </span>
                  {/* Available posters: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.postersBought - credits?.postersTotal >= 0 ? credits?.postersBought - credits?.postersTotal : "0"} </span>{" "}
                  out of <span style={{ margin: "0 5px", fontSize: "18px", fontWeight: "bold" }}> {credits?.postersBought} </span> in total */}
                  {/* and{" "}<span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.postersTotal - credits?.postersBought >= 0 ? credits?.postersTotal - credits?.postersBought : "0"} </span> posters are
                  pending out of <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.postersTotal} </span> posters in total. */}
                </p>
              </Box>
            )}
            {current === "sponsors" && (
              <Box fontSize="14px" lineHeight="19px" fontWeight="normal" pt={1}>
                <p>
                  Available sponsors: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.sponsorsBought} </span>
                  {/* Available sponsors: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.sponsorsBought - credits?.sponsorsTotal >= 0 ? credits?.sponsorsBought - credits?.sponsorsTotal : "0"} </span>{" "} */}
                  {/* out of <span style={{ margin: "0 5px", fontSize: "18px", fontWeight: "bold" }}> {credits?.sponsorsBought} </span> in total */}
                  {/* and{" "}<span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.sponsorsTotal - credits?.sponsorsBought >= 0 ? credits?.sponsorsTotal - credits?.sponsorsBought : "0"} </span> sponsors are
                  pending out of <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.sponsorsTotal} </span> sponsors in total. */}
                </p>
                <p style={{display: "inline", fontSize: "13px"}}>
                  The duration of current event is {eventDuration} {eventDuration > 1 ? "days" : "day"}{" "}
                </p>
              </Box>
            )}
            {current === "emails" && (
              <Box fontSize="14px" lineHeight="19px" fontWeight="normal" pt={1}>
                <p>
                  Available emails: <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.emailsBought - credits?.emailsSent >= 0 ? credits?.emailsBought - credits?.emailsSent : "0"} </span>{" "}
                  {/* out of <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.emailsBought} </span> in total */}
                </p>
              </Box>
            )}
          </Box>
        </Grid>

        {current && (
          <Grid item xs={12} sm={5} md={3} lg={2}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              {creditsPriceList && (
                <Box display="flex" fontSize="14px" fontWeight="bold" justifyContent="center" className={classes.priceColor}>
                  {creditsPriceList.value} credits/{creditsPriceList.label.slice(0, -1)}
                </Box>
              )}
              {current === "sponsors" && (
                <Box display="flex" fontSize="14px" fontWeight="bold" justifyContent="center" className={classes.priceColor}>
                  /event day
                </Box>
              )}
              <Box display="flex" pt={1} justifyContent="center" alignContent="flex-end">
                <Button disabled={busy} autoWidth onClick={() => handleOpen(current)}>
                  Add {current}
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default AddCreditsCardView;
