import {withStyles} from "@material-ui/core/styles";
import {useParams, withRouter} from "react-router-dom";
import React, {forwardRef, useEffect, useState} from "react";
import {Typography, Box} from "@material-ui/core";
import {useHttp} from "api/core";
import api from "api";
import {palette, theme} from "theme";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useLocation} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Button from "components/core/Button";
import { useSnackbar } from "notistack";
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  submissionFlowsContainer: {
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "calc(100vw - 300px)",
  },
  noSubmissionsYet: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "145px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  fieldTitle: {
    fontWeight: "bold",
  },
  header: {
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  addBtn: {
    textAlign: "right",
  },
  navTabs: {
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  downloadBtn: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(3),
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  totalStats: {
    textAlign: "center",
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2, 2, 4, 2),
    borderRadius: "6px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "1000px",
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
  },
  chip: {
    color: "white",
    margin: theme.spacing(0.5),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem",
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    textTransform: "none",
    color: "#fff",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    "&:focus": {
      opacity: 1,
    },
    "&:first-child": {
      minWidth: 100,
    },
  },
  indicator: {
    display: "none",
  },
})((props) => <Tabs {...props} TabIndicatorProps={{children: <span />}} />);

const StyledTab = withStyles((theme) => ({
  root: {
    whiteSpace: "nowrap",
    // minHeight: theme.spacing(6),
    // minWidth: 114,
    minWidth: "auto",
    minHeight: 40,
    // padding: 0,
    // margin: 0,
    // paddingLeft: theme.spacing(0.2),
    // paddingRight: theme.spacing(0.2),
    color: "#4a4a4a",
    borderRadius: theme.spacing(8),
    marginRight: theme.spacing(1),
    border: "1px solid rgb(239, 239, 239)",
    "&:hover": {
      backgroundColor: palette["cerulean-blue"],
      "& $wrapper": {
        color: "white",
      },
    },
    "&$selected": {
      "& *": {
        color: "white",
      },
    },
  },
  selected: {
    backgroundColor: palette["cerulean-blue"],
    color: "white",
  },
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    textTransform: "none",
    fontSize: 14,
    // fontWeight: 600,
    // color: palette['brown-grey'],
    color: "#4a4a4a",
  },
}))((props) => <Tab disableRipple {...props} />);

const Users = withStyles(styles)(({classes, history, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {

  // Hooks
    const [getData, data, isLoadingData] = useHttp();
    const [selectedUser, setSelectedUser] = useState({})

  // eslint-disable-next-line
  const [sendRequest, putData, isLoading, hasError] = useHttp();
  const [isValid, setValid] = useState(true);

  // Deps
  const {eventId} = useParams();
  const q = useQuery();
  const subId = q.get("submission");

  // State

  // const [CsvData, setCsvData] = useState()
  const [formDelete, setFormDelete] = useState();

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    getData(api.entities.manage.getUsers(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);
    
  useEffect(() => {
    if (data) {
      setPageTitle("Users")
      setNavBack({path: '/manage-events', name: 'events'})
    }
    // eslint-disable-next-line
  }, [data])
    
    
    const deleteUser = (user) =>
    {    
        let formDataObj = new FormData();
        const blob = new Blob([{ eventId: eventId, userId: user.id }], {type: 'application/json'});
        formDataObj.append('user', blob)
        
        sendRequest(api.entities.manage.deleteUser(formDataObj, null, { eventId: eventId, userId: user.id }, true,));    
    }
    useEffect(() =>
    {
        if (putData)
        {
            handleCloseFormDelete(true)  
            setSelectedUser({})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [putData])


    const handleDeleteUser = (user) =>
    {
        setSelectedUser(user)
        setFormDelete(user)
    }

  const refresh = () => {
    getData(api.entities.manage.getUsers(null, null, {id: eventId}));
  };

  const handleCloseFormDelete = (reload) => {
    setFormDelete(null);
    if (reload) {
      refresh();
    }
  };


  if (!data) return <> </>;

  return (
    <>
      <Fade in={true} timeout={1200}>
        <>
          <Grid container spacing={2} className={classes.submissionFlowsContainer}>
            <Grid item container xs={12} justify="space-between" alignContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography component="h2" variant="h5" className={classes.title}>
                  Users
                  <Tooltip classes={{tooltip: classes.tooltip}} title="The list of Authors, Participants, Collaborators users." placement="top" arrow>
                     <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                  </Tooltip>                    
                </Typography>
              </Grid>
            </Grid>
            {!isLoadingData && (
              <Grid item xs={12}>
                <MaterialTable
                  key={data}
                  icons={tableIcons}
                  columns={[
                    {title: "ID", field: "id", editable: "never", width: 30},
                    {
                      title: "Name",
                      field: "firstName",
                      editable: "never",
                      render: (rowData) => {
                        return (
                          <>
                            {rowData.title} {rowData.firstName} {rowData.lastName}
                          </>
                        );
                      },
                    },
                    {title: "Email", field: "email", editable: "never"},
                    {title: "Occupation", field: "profession", editable: "never"},
                    // {title: "Activated", field: "activated", editable: "never"},
                    {title: "Created At", field: "createdAt", editable: "never",render: (row)=> (moment(row.createdAt).tz("Europe/Athens").format("DD MMM yyyy hh:mm A")) },
                    {title: "Deleted At", field: "deletedAt", editable: "never",render: (row)=> (row.deletedAt? moment(row.deletedAt).tz("Europe/Athens").format("DD MMM yyyy hh:mm A"): "") },
                  ]}
                  data={data.content}
                // data={query =>
                //     new Promise((resolve, reject) => {
                //       let url = 'https://reqres.in/api/users?'
                //       url += 'per_page=' + query.pageSize
                //       url += '&page=' + (query.page + 1)
                //       fetch(url)
                //         .then(response => response.json())
                //         .then(result => {
                //           resolve({
                //             data: result.content,
                //             page: result.page - 1,
                //             totalCount: result.totalElements,
                //           })
                //         })
                //     })
                //   }                                  
                  title={""}
                  actions={[
                      rowData => ({
                        icon: DeleteIcon,
                        tooltip: "Delete User",
                        onClick: (event, rowData) => handleDeleteUser(rowData),
                        disabled: rowData.deletedUser === true
                      })                      
                  ]}
                  style={{
                    border: "none",
                    boxShadow: "none",
                  }}
                  localization={{
                    header: {
                      actions: "Delete",
                    },
                  }}
                  options={{
                    pageSize: data.numberOfElements, //window.innerHeight > 1200 ? 10 : 5,
                    filtering: true,
                    actionsColumnIndex: 999,
                    addRowPosition: "first",
                    headerStyle: {
                      backgroundColor: palette["cerulean-blue"],
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: 14,
                      // borderRadius: 4
                    },
                    rowStyle: {
                      fontSize: 12,
                      padding: 1,
                      paddingRight: 32,
                      borderRadius: 4,
                    },
                  }}
                />
              </Grid>
            )}
          </Grid>
          {formDelete && (
            <Dialog onClose={handleCloseFormDelete} open={!!formDelete}  aria-labelledby="draggable-dialog-title">
              <DialogTitle style={{fontSize: "14px"}} id="draggable-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <span style={{color: "#000", fontSize: "16px", margin: "5px 20px"}}>
                    Are you sure you want to delete user {selectedUser.firstName} {selectedUser.lastName}
                    
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Box display="inline-block" pt={1} pb={2} mr={1} justifyContent="left" alignContent="flex-start">
                    <Button autoFocus autoWidth size="smaller" onClick={handleCloseFormDelete} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                  <Box display="inline-block" pt={1} pb={2} mr={1} justifyContent="right" alignContent="flex-end">
                    <Button autoWidth size="smaller" onClick={() => deleteUser(selectedUser)}>
                      Confirm
                    </Button>
                  </Box>
                </Grid>
              </DialogActions>
            </Dialog>
          )}
        </>
      </Fade>
    </>
  );
});

export default withRouter(Users);
