import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {
  LOCATION_DEFAULT_MAP_LOCATION, PRICING_CATEGORIES
} from 'config'
import api from 'api'
import {useHttp} from 'api/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import MapSearchBox from 'components/core/MapSearchBox'
import Map from 'components/core/Map'
import {FormHelperText} from '@material-ui/core'

const styles = theme => ({
  container: {
    width: '100%',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newHotel: {
    // maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  formControl: {
    width: '100%'
  },
  btns: {
    paddingTop: theme.spacing(3),
    textAlign: 'center'
  }
})

const initialAddress = {
  postal_code: '',
  address: '',
  country: '',
  city: ''
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  categoryId: Yup.string().required('This field is required'),
  price: Yup.string().required('This field is required'),
  address: Yup.string().required('This field is required'),
  city: Yup.string().required('This field is required'),
  zipCode: Yup.string().required('This field is required'),
  country: Yup.string().required('This field is required')
})

const HotelEditNew = withStyles(styles)(({classes, hotel, categories=[], handleClose, eventId, setSuccessful, ...props}) => {
  const [formData] = useState({...hotel})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isSubmitting] = useHttp()
  const [address, setAddress] = useState(initialAddress)
  const [latLng, setLatLng] = useState({lat: hotel?hotel.lat:LOCATION_DEFAULT_MAP_LOCATION.lat, lng: hotel?hotel.lon:LOCATION_DEFAULT_MAP_LOCATION.lng})

  useEffect(() =>
  {
    if (hotel)
    {
      setAddress({
        postal_code: hotel.zipCode || '',
        address: hotel.address || '',
        country: hotel.country || '',
        city: hotel.city || ''
      })
    }
  }, [hotel])
  
  console.log(hotel)
  
  useEffect(() => {
    if (putData) {
      handleClose({
        type: 'hotel',
        shouldReload: true
      })
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      let payLoad = {...formSave}

      // If existing hotel
      if (hotel) {
        sendRequest(api.entities.hotels.update(payLoad, null, {id:hotel.id}))
      } else { // else add new hotel
        payLoad.eventId = parseInt(eventId)
        sendRequest(api.entities.hotels.addNew(payLoad, null, null))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, hotel])

  const DummyUpdateComp = ({setFieldValue, setFieldTouched, values}) => {

    useEffect(()=>{
      if (latLng) {
        if (latLng.lat !== LOCATION_DEFAULT_MAP_LOCATION && latLng.lng !== LOCATION_DEFAULT_MAP_LOCATION.lng)
        {
          setFieldValue('lat',latLng.lat)
          setFieldValue('lon',latLng.lng)
          setFieldValue('address',address.address)
          setFieldValue('zipCode',address.zipCode)
          setFieldValue('city',address.city)
          setFieldValue('country', address.country)
        }
        // setFieldTouched('address', true)
        // setFieldTouched('zipCode', true)
        // setFieldTouched('city', true)
        // setFieldTouched('country', true)
      }
    },[setFieldTouched, setFieldValue, values.lat, values.lon])

    return <> </>
  }

  return <Box className={classes.newHotel}><ThemeProvider theme={theme}>
    <Formik
      initialValues={{
        name: '',
        categoryId: '',
        price: '',
        lat: latLng.lat,
        lon: latLng.lng,
        address: '',
        city: '',
        zipCode: '',
        country: '',
        ...hotel
        }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, setFieldTouched}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>
            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {hotel ? 'Hotel Information' : 'New Hotel'}</Typography>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Details </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name='name' component={TextField} label='Name' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>

                <Grid item xs={6}>
                    <FormControl className={classes.formControl}>
                      <Field type='select' labelId='categoryIdLabel' displayEmpty name='categoryId' component={Select} label='Category' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                        {categories.map((category, i)=>{
                          return <MenuItem value={category.id} key={i}>{category.category}</MenuItem>
                        })}
                      </Field>
                      <InputLabel variant='filled'>Category</InputLabel>
                      {errors&&errors.categoryId&&<FormHelperText error variant='filled'> {errors.categoryId} </FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <Field type='select' labelId='priceLabel' displayEmpty name='price' component={Select} label='Price' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                      {PRICING_CATEGORIES.map((category, i)=>{
                        return <MenuItem value={category.id} key={i}>{category.category}</MenuItem>
                      })}
                    </Field>
                    <InputLabel variant='filled'>Price</InputLabel>
                    {errors&&errors.price&&<FormHelperText error variant='filled'> {errors.price} </FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Box mb={2} className={classes.searchBox}>
                    <MapSearchBox setLatLng={setLatLng} setVenueName={(name) => { if (values.name === '') setFieldValue('name', name) }} setVenueAddress={(address) => { console.log(address); if (values.address === '') setFieldValue("address", address);}}/>
                  </Box>
                  <Box>
                    <Map latLng={latLng} width='100%' height='250px' setLatLng={setLatLng} setAddress={setAddress} address={address}/>
                    <DummyUpdateComp setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} values={values}/>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name='address' component={TextField} label='Address' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>

                <Grid item xs={12} md={7}>
                  <Field type='text' name='city' component={TextField} label='City'
                         variant='filled' fullWidth disabled={isSubmitting}/>
                </Grid>

                <Grid item xs={12} md={5}>
                  <Field type='text' name='zipCode' component={TextField} label='Zip Code' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>

                <Grid item xs={12} md={7}>
                  <Field type='text' name='country' component={TextField} label='Country'
                         variant='filled' fullWidth disabled={isSubmitting}/>
                </Grid>

              <Grid container spacing={3} className={classes.btns}>
                <Grid item xs={12} md={6}>
                  <Button variant='outlined' size='small' colour='white' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                </Grid>
              </Grid>
              </Grid>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default HotelEditNew
