import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import {IconFileBorderDark} from 'components/core/icons'
import ParticipantChipView from 'components/core/ParticipantChipView'
import {palette} from 'theme'
// import {FILES_URL} from 'config'


// const PDFView = ({url}) => {
//   const [file, setFile] = useState()
//
//   useEffect(()=>{
//     fetch(url).then(function(response) {
//       return response.blob()
//     }).then(function(response) {
//       console.log(response)
//       let newBlob = new Blob([response], {type: "application/pdf"})
//       setFile(window.URL.createObjectURL(newBlob))
//     });
//     //eslint-disable-next-line
//   },[])
//
//   return <>
//     {file&&<object data={file} type="application/pdf" width={'100%'} height={780}>
//       <iframe src={file} width={'100%'} height={'40vh'} title={url}> </iframe>
//     </object>}
//     {/*{file&&<iframe src={file} />}*/}
//     {/*{file&&<GoogleDocsViewer*/}
//     {/*  width="100%"*/}
//     {/*  height="780px"*/}
//     {/*  fileUrl={file}*/}
//     {/*  key={url}*/}
//     {/*/>}*/}
//   </>
// }

const PresentationBlock = props =>{
  const {classes, presentation} = props;
  return <>
    {presentation&&presentation.length!==0&&<Grid item xs={12}>
      <Box fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']} mb={2} >{presentation.title}</Box>
        <>
            <Box p={1} pl={1}>
              {presentation.abstract}
            </Box>
            <Box className={classes.participants} pl={1}>
              {presentation.participants&&presentation.participants.map((author,i) => {
                return <Box width={'100%'} key={i}><ParticipantChipView participantObj={author} /></Box>
              })}
            </Box>
            {presentation.files&&presentation.files.length!==0&&<Box className={classes.participants} pl={1}>
              {presentation.files&&presentation.files.map((file,i) => {
                return <Box display='inline-block' m={1} key={i}>
                  <Chip
                    avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                    variant="outlined"
                    label={`${file.filename}`}
                    component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                  />
                </Box>
              })}
            </Box>}
          {/*{presentation.files&&presentation.files[0]&&<PDFView url={`${FILES_URL}/${presentation.files[0].id}`} />}*/}
        </>
    </Grid>}
  </>
}

export default  PresentationBlock
