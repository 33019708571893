import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import General from 'components/pages/ManageEvent/General'
import Layout from 'components/pages/ManageEvent/Layout'
import Sponsors from 'components/pages/ManageEvent/Sponsors'
import Tickets from 'components/pages/ManageEvent/Tickets'
import Extras from 'components/pages/ManageEvent/Extras'
import Content from "components/pages/ManageEvent/Content"
import RestaurantsAndHotels from 'components/pages/ManageEvent/RestaurantsAndHotels'


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${ index }`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: "auto",
  },
  tabs: {
      paddingTop: 67,
      borderRight: `1px solid ${ theme.palette.divider }`,
      textAlign: "right",
      "& span": {
        alignItems: "end",
          fontWeight: "bold",
        },
      "& button": {
          fontSize: 13,
          padding: "0 10px"
    }
    },
    tabPanel: {
        // height: "calc(100vh - 406px)",
        overflowY: "auto",
      width: "calc(100% - 190px)",
      paddingTop: 35
    }
}));

export default function StepOne({setEventInfo,eventInfo,pageTitle, setPageTitle}) {
  const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [navBack, setNavBack] = useState({ name: 'events', path: '/manage-events' })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="General" {...a11yProps(0)}/>
        <Tab label="Layout" {...a11yProps(1)} />
        <Tab label="Content" {...a11yProps(2)} />
        <Tab label="Ticket & Registrations" {...a11yProps(3)} />
        <Tab label="Sponsors" {...a11yProps(5)} />
        <Tab label="Restaurant & Hotels" {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0} className={classes.tabPanel}>
<General setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
      <TabPanel value={value} index={1} className={classes.tabPanel}>
<Layout setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
      <TabPanel value={value} index={2} className={classes.tabPanel}>
<Content setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
      <TabPanel value={value} index={3} className={classes.tabPanel}>
<Tickets setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
      <TabPanel value={value} index={4} className={classes.tabPanel}>
<Sponsors setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
      <TabPanel value={value} index={5} className={classes.tabPanel}>
<RestaurantsAndHotels setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} flow/>
      </TabPanel>
    </div>
  );
}
