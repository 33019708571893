import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Settings from 'components/pages/ManageEvent/Communication/Steps/Settings'
import Recipients from 'components/pages/ManageEvent/Communication/Steps/Recipients'
import Body from 'components/pages/ManageEvent/Communication/Steps/Body'
import Preview from 'components/pages/ManageEvent/Communication/Steps/Preview'
import Send from 'components/pages/ManageEvent/Communication/Steps/Send'
import Button from 'components/core/Button'
import RichTextEditor from 'react-rte'
import {useHttp} from 'api/core'
import api from 'api'
import {useSnackbar} from 'notistack'

const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newCommunication: {
    minHeight: '60vh',
    marginTop: theme.spacing(2)
    // boxSizing: 'border-box'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  contentBox: {

  },
  btns: {
    textAlign: 'center'
  },
  stepper: {
    borderRadius: '6px',
  },
  label: {
    color: palette['greyish-brown']
  },
  activeLabel: {
    color: '#000 !important'
  }
})

function getSteps() {
  return ['Settings', 'Recipients', 'Template', 'Preview', 'Send']
}

// const validationSchema = Yup.object().shape({
//   role: Yup.string().required('This field is required'),
//   email: Yup.string().required('This field is required'),
// })

const CommunicationEditNew = withStyles(styles)(({classes, defaultGroup, invite, reminder, reviewerAssignmentsAll, reviewerAssignmentsPending, reviewerAssignmentsNew, collaborator, handleClose, eventId, eventInfo, setSuccessful,credits,handleOpenAddCredits, reload, ...props}) => {

  const [activeStep, setActiveStep] = useState(0)
  const [errors, setErrors] = useState({})
  const steps = getSteps()
  const [state, setState] = useState({
    from: '',
    replyTo: '',
    bcc: '',
    subject: '',
    body: '',
    bodyEditor: { value: RichTextEditor.createValueFromString('','html') },
    group: (defaultGroup?defaultGroup:1),
    groupRecipients: [],
    templateId : '',
    eventId,
    eventTitle: eventInfo.name
  })
  const {enqueueSnackbar} = useSnackbar()
  const [hasCredits, setHasCredits] = useState(false)

  const [putRequest, putData, isLoading] = useHttp()

  const sendEmail = () => {
    const payLoad = {
      from: state.from,
      replyTo: state.replyTo,
      bcc: state.bcc,
      subject: state.subject,
      template: state.bodyEditor.value.toString('html'),
      group: state.group,
      signature: state.signature,
      sourceTemplateId: state.templateId
    }

    putRequest(api.entities.communication.sendEmail(payLoad,null,{eventId:state.eventId}))
  }

  useEffect(()=>{
    if (putData) {
      if (putData.status===201) {
        enqueueSnackbar('Successfully started batch send of your e-mail', {variant: 'success'})
        handleClose()
        if (setSuccessful) {
          setSuccessful()
        }
      }
    }
    //eslint-disable-next-line
  },[putData])

  const testEmail = (email) => {
    // eslint-disable-next-line
    return (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)
  }

  const passedChecks = () => {

    if (activeStep===0) {

      const newErrors = {
        from: state.from==='' || !testEmail(state.from),
        replyTo: state.replyTo!==''&&!testEmail(state.replyTo),
        bcc: (state.bcc===''||state.bcc===null)?false:!testEmail(state.bcc)
      }

      setErrors({
        errors,
        ...newErrors
      })

      if (newErrors.bcc||newErrors.replyTo||newErrors.from) {
        return false
      }

    }

    if (activeStep===1) {
      setErrors({errors, groupRecipients: state.groupRecipients.length===0})
      if (state.groupRecipients.length===0) {
        return false
      }
    }

    if (activeStep===2) {
      setErrors({errors, subject: state.subject===''})
      if (state.subject==='') {
        return false
      }
    }

    return true
  }

  const handleNext = () => {
    if (passedChecks()) {
      if (activeStep===4) {
        sendEmail()
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
      }
    }
  }

  const handleBack = () => {
    if (passedChecks()) {
      setActiveStep(prevActiveStep => prevActiveStep - 1)
      setHasCredits(false);
    }
  }

  useEffect(() =>
  { 
    setActiveStep(0)
    setState({
      from: '',
      replyTo: '',
      bcc: '',
      subject: '',
      body: '',
      bodyEditor: { value: RichTextEditor.createValueFromString('','html') },
      group: (defaultGroup?defaultGroup:1),
      groupRecipients: [],
      templateId : '',
      eventId,
      eventTitle: eventInfo.name
    })
  }, [reload])
  
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Settings setState={setState} state={state} errors={errors} />
      case 1:
        return <Recipients setState={setState} state={state} credits={credits} hasCredits={hasCredits} setHasCredits={setHasCredits} errors={errors} defaultGroup={defaultGroup} />;
      case 2:
        return <Body setState={setState} state={state} errors={errors} defaultGroup={defaultGroup} key={state.group}/>
      case 3:
        return <Preview setState={setState} state={state} errors={errors} />
      case 4:
        return <Send setState={setState} state={state} errors={errors} />
      default:
        return 'Unknown step'
    }
  }

  return (
    <Grid container className={classes.container}>
      <Grid container spacing={1} className={classes.row}>
        <Typography variant="h5" component="h1">
          {" "}
          New email communication
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps} classes={{label: classes.label, active: classes.activeLabel}}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>

      <Grid item xs={12}>
        {activeStep === steps.length ? (
          <Grid item>
            <Typography className={classes.instructions}>All steps completed</Typography>
          </Grid>
        ) : (
          <Grid item container flexDirection="column" spacing={2}>
            <Grid item container spacing={2} alignContent="flex-start" className={classes.newCommunication}>
              {getStepContent(activeStep)}
            </Grid>
            <Grid item container alignContent="center" justify="center" spacing={2}>
              <Grid item>
                <Button size="small" variant="outlined" colour="white" disabled={isLoading} onClick={activeStep === 0 ? handleClose : handleBack} className={classes.button}>
                    {activeStep === 0 ? "Close" : "Back"}
                </Button>
              </Grid>
              <Grid item>
                <Button size="small" variant="contained" color="secondary" onClick={handleNext} className={classes.button} disabled={isLoading || hasCredits}>
                  {activeStep === steps.length - 1 ? "Send e-mail" : "Next"}
                </Button>
              </Grid>
              {hasCredits && (
                <Grid item>
                  <Button size="small" variant="contained" color="secondary" disabled={activeStep === 0 || isLoading} onClick={() => handleOpenAddCredits("emails")} className={classes.button}>
                    Add emails
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

})

export default CommunicationEditNew
