import {withStyles} from '@material-ui/core/styles'
import {useDropzone} from 'react-dropzone'
import Box from '@material-ui/core/Box'
import RootRef from '@material-ui/core/RootRef'
import Button from 'components/core/Button'
import React, {forwardRef, useEffect, useState} from 'react'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import Dialog from '@material-ui/core/Dialog'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Grid from '@material-ui/core/Grid'
import * as Yup from 'yup'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Alert from '@material-ui/lab/Alert'
import DialogActions from '@material-ui/core/DialogActions'
import Typography from '@material-ui/core/Typography';
import LoadingDialog from 'components/core/LoadingDialog'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const styles = theme => ({
  note: {
    fontSize: '12px'
  },
  formControl: {
    width: '100%'
  },
  dialog: {
    padding: theme.spacing(3)
  },
  dialogPaper: {
    minHeight: 'calc(90vh-90px)',
    maxHeight: 'calc(90vh-100px)',
    padding: '40px 50px 50px 50px',
  },
  row: {
    marginBottom: theme.spacing(2)
  }
})

const UploadExcel = withStyles(styles)(({classes, eventId, refresh}) => {

  const [putData, data, isLoading] = useHttp()
  const [saveData, dataSave, isLoadingSave] = useHttp()

  const [reviewers, setReviewers] = useState()
  const [previewOpen,setPreviewOpen] = useState(false)
  const [hasInvalid, setHasInvalid] = useState(false)

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0]
    let formDataObj = new FormData()

    if (!file) return false

    formDataObj.append('file', acceptedFiles[0])
    putData(api.entities.manage.uploadReviewers(formDataObj, null, {id: eventId}, true))
  }

  useEffect(()=>{
    if (data) {
      let reviewersData = []

      data.forEach((reviewer)=>{
        const valid = isValid(reviewer)
        if (!valid) setHasInvalid(true)
        reviewersData.push({...reviewer, valid})
      })

      setReviewers(reviewersData)
      setPreviewOpen(true)
    }
    // eslint-disable-next-line
  },[data])

  const isValidEmail = (email) => {
    return Yup.string().email().isValidSync(email)
  }

  // Validation check
  const isValid = (entry) => {
    let valid = false

    // Check email
    valid = isValidEmail(entry.email)

    return valid
  }

  useEffect(()=>{
    if (reviewers) {
      let hasInvalidEntries = false
      reviewers.forEach((entry) => {
        if (!isValid(entry)) {
          hasInvalidEntries = true
        }
      })
      setHasInvalid(hasInvalidEntries)
    }
    // eslint-disable-next-line
  },[reviewers])

  const handleClose = (reload) => {
    setPreviewOpen(false)
    if (reload&&refresh) {
      refresh()
    }
  }

  useEffect(()=>{
    if (dataSave) {
      handleClose(true)
    }
    // eslint-disable-next-line
  },[dataSave])

  const handleSave = () => {
    saveData(api.entities.manage.saveReviewers({reviewers:reviewers.filter((r)=>r.email!=='')},null,{id:eventId}))
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  return <>
      <RootRef rootRef={ref}>
        <Box {...rootProps} className={classes.dropBox}>
          <input type='file' {...getInputProps()} accept='.xls,.xlsx'/>
          <Button size='smaller'> Upload Excel file </Button>
        </Box>
      </RootRef>
    {(isLoading||isLoadingSave)&&<LoadingDialog open={true} />}
    {reviewers&&<Dialog onClose={handleClose} open={reviewers&&previewOpen} fullWidth maxWidth='xl' classes={{ paper: classes.dialogPaper }} disableBackdropClick={isLoadingSave} disableEscapeKeyDown={isLoadingSave}>
      {hasInvalid&&<Alert severity='warning'>
        <AlertTitle>Invalid records</AlertTitle>
        There are invalid records in this file. You won't be able to upload the file before fixing.
      </Alert>}
      <Grid container className={classes.dialog}>
        <Grid item container spacing={1} className={classes.row}>
          <Typography variant='h5'
                      component='h1'> {'Upload reviewers'}</Typography>
        </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <MaterialTable
            icons={tableIcons}
            columns={[
              { title: 'Title', field: 'title', cellStyle: {width: '20px', maxWidth: '20px'} },
              { title: 'First name', field: 'firstName' },
              { title: 'Last name', field: 'lastName' },
              { title: 'Email', field: 'email'},
              { title: 'Position', field: 'profession'},
              { title: 'Country code', field: 'countryCode'},
              { title: 'Affiliation / Company', field: 'institute'},
              { title: 'LinkedIn', field: 'linkedinUrl', cellStyle: {width: '80px', maxWidth: '80px',wordBreak: 'break-all'} },
              { title: 'PCM', field: 'pcm', type: 'boolean'},
              { title: 'Valid', field: 'valid', type: 'boolean', editable: 'never', defaultSort: 'asc'},

              // { title: 'profession', field: 'profession'},
              // { title: 'Doğum Yeri', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' } }
            ]}
            data={reviewers}
            title="Reviewers"
            editable={{
              onRowAdd: newData =>
                new Promise((resolve, reject) => {
                  setReviewers([...reviewers, {...newData, valid:isValid(newData)} ]);
                  resolve()
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...reviewers];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = {...newData, valid:isValid(newData)};
                  setReviewers([...dataUpdate]);
                  resolve()
                }),
              onRowDelete: oldData =>
                new Promise((resolve, reject) => {
                  const dataDelete = [...reviewers];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setReviewers([...dataDelete]);
                  resolve()
                }),
            }}
            options={{
              actionsColumnIndex:999,
              headerStyle: {
                backgroundColor: palette['brown-grey'],
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: 14
              },
              rowStyle: rowData => ({ fontSize: 12, padding:0, backgroundColor: rowData.valid ? '' : 'rgb(255, 244, 229)' })
            }}
          />
        </Grid>
        {/*<Grid item container justify='flex-end' alignItems='flex-end' alignContent='flex-end'>*/}
        {/*  <Grid item>*/}
        {/*    <Button size='smaller'> Save </Button>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
      </Grid>
      </Grid>
      <DialogActions>
        <Button size='smaller' onClick={handleClose} color="primary" disabled={isLoadingSave}>
          Cancel
        </Button>
        <Button size='smaller' onClick={handleSave} color="secondary" disabled={isLoadingSave||hasInvalid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>}
  </>
})

export default UploadExcel
