import {makeStyles} from '@material-ui/styles'

export const discussion_6 = makeStyles({

  item_1: {
    left: '1%',
    top: '2%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  },

  item_2: {
    left: '34%',
    top: '2%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  },

  item_3: {
    left: '67%',
    top: '2%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  },

  item_4: {
    left: '1%',
    top: '51%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  },

  item_5: {
    left: '34%',
    top: '51%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  },

  item_6: {
    left: '67%',
    top: '51%',
    width: '32%',
    height: '47%',
    opacity: '1 !important'
  }
})
