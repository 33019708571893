import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {format} from 'date-fns'
import {imageFile, whichDayInInterval} from 'utils'
import {parseISO, compareAsc} from 'date-fns'
import clsx from 'clsx'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import IconButton from '@material-ui/core/IconButton'
import EllipsisText from 'react-ellipsis-text'
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";

const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    '& a': {
      color: palette.aquamarine
    }
  },
  ParticipantCardView: {
    transition: 'opacity 0.7s'
  },
  userPhoto: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  fieldTitle: {
    fontSize: '14px',
  },
  fieldValue: {
    fontSize: '17px',
    fontWeight: 'bold'
  },
  sessionBox: {
    minHeight: '60px',
    backgroundColor: palette['very-light-pink'],
    borderRadius: '8px',
    padding: theme.spacing(1),
    color: palette['greyish-brown'],
    height: '100%'
  },
  sessionTitle: {
    fontWeight: 'bold',
    fontSize: 14,
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  summary: {
    marginTop: '-20px'
  },
  notMatched: {
    opacity:0.3
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {

  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}> {value?value:'-'} </Box>
  </Box>
})

const Session = withStyles(styles)(({classes, day, ordinal, startDate, endDate, title,event, ...restProps}) => {
  return (
    <Grid item container className={classes.sessionBox} {...restProps}>
      <Grid item xs={12} className={classes.sessionTitle}>
        {" "}
        <EllipsisText text={title} length={45} />{" "}
      </Grid>
      <Grid item xs={12} className={classes.sessionTimes}>
        {format(parseISO(startDate), "LLL do", dateLocale[event.eventInfo.eventLanguage])} <br />
        {format(parseISO(startDate), "hh:mmaa", dateLocale[event.eventInfo.eventLanguage])} - {format(parseISO(endDate), "hh:mmaa", dateLocale[event.eventInfo.eventLanguage])} <br />
      </Grid>
    </Grid>
  );
})

const ParticipantCardView = withStyles(styles)(({classes, participant, index, event, ...props}) => {
  return (
    <Box className={clsx(classes.ParticipantCardView, participant.matched === false && classes.notMatched)} key={index} {...props}>
      <ThemeProvider theme={theme}>
        <Box p={2} className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Box className={classes.userPhoto}>
                <img src={imageFile(participant.image)} alt={`${participant.title ? participant.title : ""} ${participant.firstName} ${participant.lastName}`} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Box fontSize={18} fontWeight={"bold"}>
                {
                  // ${ participant.title ? participant.title : "" }
                `${ participant.firstName } ${ participant.lastName }`}
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <FieldText title={language.position} value={participant.profession} />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Box pr={2}>
                <FieldText title={language.affiliationCompany} value={participant.instituteOrCompany} />
              </Box>
            </Grid>
            <Grid item sm={2} xs="auto">
              {" "}
            </Grid>
            <Grid item xs={12} sm={7} className={classes.summary}>
              {participant.summary ? <FieldText title={language.summary} value={participant.summary} /> : ""}
            </Grid>
            {participant.linkedinUrl && (
              <Grid item xs={12} sm={3} md={3}>
                <Box ml={-2}>
                  <IconButton href={participant.linkedinUrl} target="_blank">
                    <LinkedInIcon />
                  </IconButton>
                </Box>
              </Grid>
            )}
          </Grid>

          <Grid item container className={classes.sessionsBox} spacing={1}>
            {participant.sessions
              .sort((a, b) => compareAsc(parseISO(a.startDate), parseISO(b.startDate)))
              .sort((a, b) => parseInt(a.ordinal) - parseInt(b.ordinal))
              .map((session, i) => {
                return (
                  <Grid item xs={12} sm={6} md={4} lg={2} className={classes.sessionWrapper}>
                    <Session
                      day={whichDayInInterval(event.eventInfo.startDate, event.eventInfo.endDate, session.startDate)}
                      ordinal={session.ordinal}
                      startDate={session.startDate}
                      endDate={session.endDate}
                      key={i}
                      title={session.title}
                      event={event}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </ThemeProvider>
    </Box>
  );
})

export default ParticipantCardView
