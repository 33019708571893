import React, {useEffect} from 'react'
import {useAuth} from 'auth/AuthController'

const SignOut = () => {
  const [,,logout] = useAuth()
  useEffect(()=>{
    logout()
  })
  return <></>
}

export default SignOut
