import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState,useRef} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import {TextField} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import ProceedingCardView from 'components/core/ProceedingCardView'
import ScrollableContainer from 'components/core/ScrollableContainer'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import {ReactComponent as NoSearchResult} from 'assets/placeholders/no_search results.svg'
import Typography from '@material-ui/core/Typography'
import language from "components/language";

const proceedingStyles = theme => ({
  letter: {
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '50%',
    backgroundColor: 'white',
    fontWeight: 'bold'
  },
  selectedLetter : {
    backgroundColor: theme.palette.primary.main
  },
})

export const Proceedings = withStyles(proceedingStyles)(({classes, event, isPreview, canManage=false}) => {
  const [proceedings, setProceedings] = useState()
  const [getProceedingsData, proceedingsData] = useHttp()
  const [filter, setFilter] = useState('')
  const [search, setSearch] = useState('')
  const [type] = useState('')
  const PAGE_SIZE = 10

  const searchTimeout = useRef(null);

  useEffect(() => {
    if (isPreview) {
      getProceedingsData(api.entities.preview.getProceedings(null, {size: PAGE_SIZE, term:search, letter:filter, type:type}, {id: event.eventInfo.id}))
    } else {
      getProceedingsData(api.entities.events.getProceedings(null, {size: PAGE_SIZE, term:search, letter:filter, type:type}, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (proceedingsData) {
      setProceedings(proceedingsData)
    }
    // eslint-disable-next-line
  }, [proceedingsData])

  const handleChange = (e, page) => {
    if (isPreview) {
      getProceedingsData(api.entities.preview.getProceedings(null, {page:page, size: PAGE_SIZE, term:search, letter:filter, type:type}, {id: event.eventInfo.id}))
    } else {
      getProceedingsData(api.entities.events.getProceedings(null, {page:page, size: PAGE_SIZE, term:search, letter:filter, type:type}, {id: event.eventInfo.id}))
    }
  }

  useEffect(()=>{
    if (filter||filter===null) {
      if (isPreview) {
        getProceedingsData(api.entities.preview.getProceedings(null, {page:proceedings.pageable.pageNumber+1, size: PAGE_SIZE, term:search, letter:filter?filter:'', type:type}, {id: event.eventInfo.id}))
      } else {
        getProceedingsData(api.entities.events.getProceedings(null, {page:proceedings.pageable.pageNumber+1, size: PAGE_SIZE, term:search, letter:filter?filter:'', type:type}, {id: event.eventInfo.id}))
      }
    }
    //eslint-disable-next-line
  },[filter])

  const searchFor = (e) => {
    const value = e.target.value
    setSearch(value)

    clearTimeout(searchTimeout.current)
    searchTimeout.current = setTimeout(()=>{
      if (isPreview) {
        getProceedingsData(api.entities.preview.getProceedings(null, {page:proceedings.pageable.pageNumber+1, size: PAGE_SIZE, term:value, letter:filter, type:type}, {id: event.eventInfo.id}))
      } else {
        getProceedingsData(api.entities.events.getProceedings(null, {page:proceedings.pageable.pageNumber+1, size: PAGE_SIZE, term:value, letter:filter, type:type}, {id: event.eventInfo.id}))
      }
    },700)

  }

  if (!proceedings) { // Loading
    return <> </>
  }

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0}  pb={12} flexDirection='column'>
        <Grid container>

          <Grid item xs={12} md={8}>
            <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.proceeding}</Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box pb={2} pr={1}>
              <TextField label={language.searchForAPresentation} variant='outlined' value={search} onChange={searchFor} fullWidth />
            </Box>
          </Grid>

          <Grid item xs={12}>
          <ScrollableContainer items={
            ['A-Z','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'].map((letter,i)=>{
              return <Chip label={letter} color={filter===letter||(filter===null&&i===0)?`primary`:`default`} onClick={()=>setFilter(letter==='A-Z'?null:letter)} classes={{root:classes.letter, colorPrimary:classes.selectedLetter}} />
            })
          } />
          </Grid>

          <Grid item xs={12}>
            {proceedings.content.map((proceeding,i) => {
              return <ProceedingCardView presentation={proceeding} event={event} p={1} index={i}/>
            })}
            {proceedings.content.length===0&&<Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' height='100%' pt={9} alignContent='center' justifyContent='center' justifyItems='center' alignItems='center' flexDirection='column'>

              <Box display='flex'><NoSearchResult/></Box>

              <Box display='flex' pt={4}>
                <Typography variant={'h6'}>{language.noResultsFound}</Typography>
              </Box>

            </Box></Fade>}
          </Grid>

          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Grid item>
                <Pagination hideNextButton={proceedings.last} hidePrevButton={proceedings.first}
                            count={proceedings.totalPages} page={proceedings.pageable.pageNumber + 1}
                            onChange={(e)=>handleChange(e,proceedings.pageable.pageNumber + 1)}/>
              </Grid>
            </Grid>

          </Grid>

        </Grid>
      </Box>
    </Fade>
  </>
})
