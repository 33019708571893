import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {format} from "date-fns"
import parseISO from "date-fns/parseISO"
import {palette} from 'theme'
import { CURRENCY } from 'config'
import Switch from "@material-ui/core/Switch";

const styles = theme => ({
  container: {
    // marginBottom: theme.spacing(4),
    // backgroundColor: palette['cerulean-blue'],
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    // color: 'white',
    borderRadius: '6px',
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      backgroundColor: palette['cerulean-blue'],
      boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
      color: 'white',
      marginBottom: theme.spacing(2)
    }
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  fieldValue: {
    fontSize: '14px',
    overflow: 'hidden',
    overflowWrap: 'break-word'
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {
  return <Grid item container>
    <Grid item xs={12} className={classes.fieldTitle}> {title} </Grid>
    <Grid item xs={12} className={classes.fieldValue}> {value?value:'-'} </Grid>
  </Grid>
})

const UserJoinedCard = withStyles(styles)(({classes, user, hideInfo, index, registered,handleChangeAttendeeStatus, ...props}) => {
  return (
    <Grid container item spacing={2} key={index} {...props} className={classes.container}>
      <Grid item xs={12} sm={3} md={3}>
        <FieldText title={"Name"} value={hideInfo ? "-" : `${user.title ? user.title : ""} ${user.firstName} ${user.lastName}`} />
      </Grid>

      <Grid item xs={12} sm={3} md={3}>
        <FieldText title={"E-mail"} value={hideInfo ? "-" : user.email} />
      </Grid>

      <Grid item xs={12} sm={3} md={2}>
        <FieldText title={"Joined at"} value={`${format(parseISO(user.joinedAt), "LLL do | hh:mmaa")}`} />
      </Grid>

      <Grid item xs>
        <Grid item container justify="center">
          <Grid item>
            <FieldText title={"Attendees"} value={user.attendees} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs>
        <Grid item container justify="center">
          <Grid item>
            <FieldText title={"Total Tickets"} value={user.totalTickets} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs>
        <Grid item container justify="center">
          <Grid item>
            <FieldText title={"Total Price"} value={user.totalPrice !== null ? `${CURRENCY}${user.totalPrice}` : "-"} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs>
        <Grid item container justify="center">
          <Grid item>
            <Switch inputProps={{"aria-label": "true/false"}} checked={user.eventRegistrationStatus} onChange={() => handleChangeAttendeeStatus(user, index)} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
})

export default UserJoinedCard
