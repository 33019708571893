import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {format} from "date-fns"
import parseISO from 'date-fns/parseISO'
import Divider from '@material-ui/core/Divider'
import BlockText from 'components/core/BlockText'
import Button from 'components/core/Button'
import Switch from 'components/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {useHttp} from 'api/core'
import api from 'api'
import clsx from 'clsx'
import {Link, withRouter} from 'react-router-dom'
import SlideshowIcon from '@material-ui/icons/Slideshow'
import PollIcon from '@material-ui/icons/Poll'

const styles = theme => ({
  container: {
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative',
    width: '100%',
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(6)
    }
  },
  feedbackCard: {
    backgroundColor: palette['cerulean-blue'],
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  blockTitle: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    width: '100%',
    height: '100%'
  },
  fieldTitleBlock: {
    fontSize: '14px',
    color: palette['brown-grey']
  },
  fieldValueBlock: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  optionTitle: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    width: '100%',
    height: '100%'
  },
  fieldTitleOption: {
    fontSize: '14px',
    color: palette['brown-grey']
  },
  fieldValueOption: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  spaceRight: {
    width: `calc(100% - ${theme.spacing(9)}px)`
  },
  sessionBox: {
    minHeight: '60px',
    backgroundColor: palette['pale-sky-blue'],
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown']
  },
  sessionTitle: {
    color: palette['cerulean-blue'],
    fontWeight: 'bold'
  },
   sessionsBox: {
    paddingTop: theme.spacing(1),
   },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  fullOpacity: {
    opacity: 1
  },
  lowOpacity: {
    opacity: 0.1
  },
  divider: {
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  previewBox: {
    backgroundColor: 'white',
    width: '100%',
    minHeight: '250px',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden'
  },
  previewCode: {
    position: 'absolute',
    width: '100%',
    fontWeight: 'bold',
    textAlign: 'center',
    left: 0,
    bottom: 0,
    height: '24px',
    fontSize: '12px',
    lineHeight: '24px',
    padding: theme.spacing(1),
    backgroundColor: palette.aquamarine,
  }
})

const FeedbackCard = withStyles(styles)(({classes, handleOpenPreview, sessions, feedback, index, viewOnly=false, history, editAction, deleteAction, ...props}) => {
  const session = sessions.sessions.find((session)=>session.id===feedback.sessionId)
  const [enabled, setEnable] = useState(feedback.enabled)
  const [setEnabled,, isLoadingSet, hasErrorSet] = useHttp()
  const [delEnabled,, isLoadingDel, hasErrorDel] = useHttp()

  const enableFeedback = () => {
    if (isLoadingSet||isLoadingDel) return
    if (enabled) {
      setEnable(false)
      delEnabled(api.entities.feedback.disable(null, null,{id: feedback.id}))
    } else {
      setEnable(true)
      setEnabled(api.entities.feedback.enable(null,null,{id: feedback.id}))
    }
  }

  useEffect(()=>{
    if (hasErrorSet) {
      setEnable(false)
    }
    // eslint-disable-next-line
  },[hasErrorSet])

  useEffect(()=>{
    if (hasErrorDel) {
      setEnable(true)
    }
    // eslint-disable-next-line
  },[hasErrorDel])


  return <React.Fragment>
          <Grid item container spacing={2} className={classes.container}>

            <Grid item xs={12} md={8}>
                <BlockText title={'Questionnaire title'} value={feedback.title?feedback.title:''} />
            </Grid>

            <Grid item xs={12} md={2}>
              <BlockText title={'Questions'} value={feedback.questions.length} />
            </Grid>

            <Grid item xs={12} md={2}>
              <BlockText title={'Responses'} value={ feedback.questions[0].options.reduce((a,b)=>a+b.total,0) } />
            </Grid>

            {session&&<>
              <Grid item xs={12} md={8}>
                <BlockText title={'Session'} value={`#${session.ordinal}: ${session.title}`} />
              </Grid>
              <Grid item xs={12} md={3}>
                <BlockText title={'Time'} value={<Box className={classes.sessionTimes}>
                  {format(parseISO(session.startDate), 'hh:mmaa')} - {format(parseISO(session.endDate), 'hh:mmaa')} <br/>
                </Box>} />
              </Grid>
            </>}

            <Grid item xs={12} md={8}>
              <Box display='flex' pt={2}>
                <Box pr={4} pt={1}>
                  <FormControlLabel
                    control={
                      <Switch checked={enabled} onChange={()=>enableFeedback()}/>
                    }
                    label='Enabled'
                    labelPlacement='start'
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} md={2}>
              <Box className={clsx(enabled?classes.fullOpacity:classes.lowOpacity)}>
                <BlockText title='Code Number' value={feedback.code} centered />
              </Box>
            </Grid>

            <Grid item xs='auto' md={2}>

            </Grid>

            {!viewOnly&&<Box>
              <IconNotesBorderLight title='Edit Feedback' className={classes.editIcon} onClick={()=>{editAction&&editAction(feedback)}}/>
              <IconXBorderLight title='Delete Feedback' className={classes.deleteIcon} onClick={()=>{deleteAction&&deleteAction(feedback.id)}}/>
            </Box>}

          </Grid>

    <Grid item container spacing={2} className={classes.feedbackCard}>
      <Grid item xs={12}>
        <Divider classes={{root:classes.divider}} />
        <Box display='flex' justifyContent='space-between' pb={1}>
          <Button size='small' variant='outlined' colour='white' onClick={()=>handleOpenPreview(feedback)}> <PollIcon/> &nbsp; View responses </Button>
          <Link to={enabled?`/live-feedback/${feedback.id}`:'#'}><Button size='small' disabled={!enabled}> <SlideshowIcon/> &nbsp; Live results </Button></Link>
        </Box>
      </Grid>

    </Grid>


      </React.Fragment>
      })

      export default withRouter(FeedbackCard)
