import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {Typography} from '@material-ui/core'
import BlockText from 'components/core/BlockText'

const styles = theme => ({

})

const Send = withStyles(styles)(({classes, state, setState})=>{


return <Grid item container justify='center' alignContent='center'>

  <Grid item xs={6}>

    <Grid item container spacing={2} alignContent='center' className={classes.container}>

      <Grid item xs={12}>
        <Grid item container justify='center' spacing={2}>
          <Grid item xs={8}>
            <Typography variant='h6'> Your settings for this email </Typography>
          </Grid>
          <Grid item xs={8}>
            <BlockText title={'From'} value={state.from} />
          </Grid>
          <Grid item xs={8}>
            <BlockText title={'Reply-to'} value={state.replyTo} />
          </Grid>
          <Grid item xs={8}>
            <BlockText title={'BCC:'} value={state.bcc} />
          </Grid>
          <Grid item xs={8}>
            <BlockText title={'Subject:'} value={state.subject} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='h6' align='center'> Total recipients: {state.groupRecipients.length} </Typography>
      </Grid>

    </Grid>

  </Grid>

</Grid>
})

export default Send
