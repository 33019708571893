import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import api from 'api'
import {useHttp} from 'api/core'
import ChipInput from 'material-ui-chip-input'
import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import {useSnackbar} from "notistack";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  formControl: {
    width: '100%',
    textAlign: 'left'
  },
  newMailList: {
    maxWidth: '770px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  chipBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    color: 'white',
    '&> input':{
      color: 'white'
    }
  },
  chip: {
    margin: theme.spacing(1),
    maxWidth: '135px'
  },
  chipInput: {
    color: 'white',
    width: '100%'
  },
  sessionsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'scroll'
  },
  dropBox: {
    width: "100%",
    textAlign: "right",
    margin: "15px 0",
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required')
})

const MailListEditNew = withStyles(styles)(({classes, mailList, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData] = useState({...mailList})
  const [formSave, setFormSave] = useState()
  const [chips, setChips] = useState(mailList?[...mailList.emails]:[])
  const [sendRequest, putData, isSubmitting] = useHttp()

  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      let payLoad = {}
      // If existing mailList
      if (mailList) {

        let newData = new Set([...chips])
        let originalData = new Set([...mailList.emails])

        let newItems = new Set([...newData].filter(x => !originalData.has(x)))
        let removedItems = new Set([...originalData].filter(x => !newData.has(x)))

        payLoad = { name: formSave.name, added: [...newItems], deleted: [...removedItems]}
        sendRequest(api.entities.mailLists.update(payLoad, null, {id: mailList.id}))
      } else { // else add new mailList
        payLoad = { name: formSave.name, eventId, emails: [...chips]}
        sendRequest(api.entities.mailLists.addNew(payLoad, null, null))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, mailList, chips])

  const MailSchema = Yup.object().shape({email: Yup.string().email().required()})

  const handleAddChip = (chip) => {
    let newChips = []
    let verifiedChips = []

    if (chip.indexOf(',')!==-1) {
      newChips = [...chip.split(',')]
    } else {
      newChips = [chip]
    }

    newChips.forEach((chip)=> {
        if (MailSchema.isValidSync({email: chip.trim()})) {
          if ((chips.indexOf(chip) === -1) && (verifiedChips.indexOf(chips) === -1)) verifiedChips.push(chip)
        }
      })

    setChips([...chips, ...verifiedChips])
  }

  const handleDeleteChip = (chip, index) => {
    const newChips = [...chips]
    newChips.splice(index,1)
    setChips(newChips)
  }

  
  const onDrop = (acceptedFiles) =>
  {
    console.log(acceptedFiles)
    if (acceptedFiles[0].type === "text/csv")
    {
      
      const reader = new FileReader();
      reader.onload = () => {
        const list = reader.result.split("\r\n").filter(x=>x!=="")
        const newEmailList = [...chips, ...list]
        setChips([...new Set(newEmailList)]);
      };
      reader.readAsBinaryString(acceptedFiles[0]);
    } else
    {
      enqueueSnackbar("This is not a valid CSV file", {variant: "error"});
    }

  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const { ref, ...rootProps } = getRootProps()

  return <Box className={classes.newMailList}><ThemeProvider theme={theme}>
    <Formik
      initialValues={{
        name: '',
        ...formData
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, logo: formData.logo}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>

            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {mailList ? 'Mailing list' : 'New mailing list'}</Typography>
              </Grid>
                  <Grid container spacing={1} className={classes.row}>
                    <Grid item xs={12}>
                      <Box fontWeight='fontWeightBold'> Details </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Field type='text' name='name' component={TextField} label='Mailing List name' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>
                    <Grid item xs={12}>
                      <Box pt={2} fontWeight='fontWeightBold'> Registered address </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box display='flex' flexWrap='wrap'  alignContent='flex-start' width={'100%'} height={480} className={classes.chipBox}>
                        <Box p={2} width={'100%'}>
                        <ChipInput
                          disabled={isSubmitting}
                          classes={{input:classes.chipInput}}
                          value={chips}
                          onAdd={(chip) => handleAddChip(chip)}
                          onDelete={(chip, index) => handleDeleteChip(chip, index)}
                          disableUnderline
                          placeholder={`Start by typing any e-mail address...`}
                          newChipKeys={['enter',',',' ']}
                          fullWidth
                        />
                    </Box>                  
                      </Box>
                </Grid>
                <RootRef rootRef={ref}>
                  <Box {...rootProps} className={classes.dropBox}>
                    <input type='file' {...getInputProps()} accept='.csv'/>
                    <Button size='smaller'> Upload a CSV file </Button>
                  </Box>
                </RootRef>                
                  </Grid>
              <Box display='flex' p={3} pb={0} justifyContent='center' alignItems='center'>
                  <Box p={1}>
                  <Button variant='outlined' size='small' colour='white' onClick={handleClose}
                          disabled={isSubmitting}> Cancel </Button>
                  </Box>
                <Box p={1}>
                  <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                </Box>
              </Box>

            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default MailListEditNew
