import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import QRCode from 'qrcode.react'
import Button from 'components/core/Button'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0
  },
  paper: {
    backgroundColor: 'transparent',
    outline: 0
  },
}))


const QRDialog = ({open, qr, info, handleClose}) => {
  const classes = useStyles()

  const downloadQR = () => {
    const canvas = document.getElementById('qrCode')
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    let downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${info.replace(/[^a-z0-9]/gi, '_').toLowerCase()}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
              <Box display='flex'>
                <QRCode value={qr} size={360} id='qrCode'/>
              </Box>
              <Box display='flex' pt={2}>
                <Button colour='white' size='small' variant='outlined' onClick={()=>{downloadQR()}}>Download QR image</Button>
              </Box>
            </Box>
          </div>
        </Fade>
      </Modal>
  )
}

export default QRDialog
