import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {Redirect, useParams, withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import Fade from '@material-ui/core/Fade'
import Users from 'components/pages/Admin/Users'
import Sponsors from 'components/pages/Admin/Sponsors'
import Events from 'components/pages/Admin/Events'
import Emails from 'components/pages/Admin/Emails'
const styles = theme => ({
  container: {

  }
})


const Admin = withStyles(styles)(({classes, profile, history, ...props}) => {

  const {page} = useParams()

  const navigation = {
    routes: [
      {
        name: 'Users',
        path: 'users'
      },
      {
        name: 'Sponsors',
        path: 'sponsors'
      },
      {
        name: 'Events',
        path: 'events'
      },      
      {
        name: 'Emails',
        path: 'emails'
      },      
      // {
      //   name: 'Change Password',
      //   path: 'password'
      // },
      // {
      //   separator: true,
      //   path: ''
      // },
      // {
      //   name: 'Plans',
      //   path: 'plans',
      //   // hidden: true
      //   // icon: AssessmentIcon
      // }
    ]
  }

  if (!page) return <>
    <Redirect to='/admin-console/users' />
  </>

  return <Page title='Admin console' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Admin console' mobileTitle='Admin console' navigation={navigation}>

        <Grid container spacing={2} className={classes.container}>
          <Fade in={true} timeout={1200}>
            <>
              <Grid item container spacing={2} className={classes.innerContainer}>
                {page==='users'&&<Users />}
                {page ==='sponsors' && <Sponsors />}
                {page==='events'&&<Events/>}
                {page==='emails'&&<Emails/>}
              </Grid>

            </>
          </Fade>
        </Grid>
    </Adrawer>
  </Page>
})

export default withRouter(Admin)
