import {withStyles} from '@material-ui/core/styles'
import {useSnackbar} from 'notistack'
import Box from '@material-ui/core/Box'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import Button from 'components/core/Button'
import {IconShareBorderWhite} from 'components/core/icons'
import React from 'react'

const styles = theme => ({

})

const ShareEventButton = withStyles(styles)(({classes}) => {
  const {enqueueSnackbar} = useSnackbar()
  const handleShareLink = () => {
    enqueueSnackbar(`Link ${window.location.href} was copied to the clipboard`, {variant: 'success'});
  }
  return <Box display='flex' p={4} pb={12} flexDirection='column' justifyContent='center' alignItems='center'>
    <CopyToClipboard text={window.location.href} onCopy={handleShareLink}>
      <Button color='secondary' size='small'>Share &nbsp; <IconShareBorderWhite/> </Button>
    </CopyToClipboard>
  </Box>
})

export default ShareEventButton
