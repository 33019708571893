import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Info from 'components/core/OpenTok/Tabs/Info/Info'
import Polls from 'components/core/OpenTok/Tabs/Polls'
import Audience from 'components/core/OpenTok/Tabs/Audience'
import Qna from 'components/core/OpenTok/Tabs/QnA/QnA'
import Chat from 'components/core/OpenTok/Tabs/Chat'
import ChairControl from 'components/core/OpenTok/Tabs/ChairControls/ChairControls'
import InfoPoster from 'components/core/OpenTok/Tabs/Info/InfoPoster'

const styles = theme => ({

})

const ViewerTabs = withStyles(styles)(({classes, tab, sessionState, allHandlers, feedbackHandlers, chatHandlers }) => {

  const {hasPolls, hasChat, hasQnA, hasInfo, customTabs, isOnlinePoster} = sessionState

  const matchedCustomTab = customTabs.find((t,i)=>tab===`custom-tab-${i}`)
  const CustomTabComponent = matchedCustomTab&&matchedCustomTab.component

  return <>
    {tab==='controls'&&sessionState.hasChairControls&&
    <ChairControl sessionState={sessionState} allHandlers={allHandlers} />
    }
    {tab==='info'&&hasInfo&&!isOnlinePoster&&<Info sessionState={sessionState} />}
    {tab==='info'&&hasInfo&&isOnlinePoster&&<InfoPoster sessionState={sessionState} />}
    {tab==='polls'&&hasPolls&&<Polls sessionState={sessionState} feedbackHandlers={feedbackHandlers} />}
    {tab==='audience'&&<Audience sessionState={sessionState} key={sessionState.audience}/>}
    {tab==='qna'&&hasQnA&&<Qna sessionState={sessionState} />}
    {tab==='chat'&&hasChat&&<Chat {...chatHandlers} sessionState={sessionState} disabled={!chatHandlers.chatConnected} />}
    {matchedCustomTab&&<CustomTabComponent sessionState={sessionState} />}
  </>
})

export default ViewerTabs
