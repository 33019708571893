import {withStyles} from '@material-ui/core/styles'
import React, {useEffect} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import ScrollableContainer from 'components/core/ScrollableContainer'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import Tooltip from '@material-ui/core/Tooltip'


const styles = theme => ({
  container: {
    color: palette['greyish-brown'],
    overflow: 'hidden',
    minHeight: 200
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  innerContainer: {
    padding: theme.spacing(1)
  },
  item: {
    cursor: 'pointer',
    border: '2px solid transparent'
  },
  selected: {
    border: `2px solid red`
  },
  avatar: {
    color: `${palette['greyish-brown']} !important`,
    background: 'none',
    '&> img': {
      maxWidth: '100%'
    }
  },
  fileChip: {
    backgroundColor: 'transparent'
  }
})

const PreviewTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: 'white',
    maxWidth: '100%',
    overflow: 'hidden',
    border: '2px solid #e6e6e6',
    borderRadius: 4
  },
}))(Tooltip);

const FilePresenter = withStyles(styles)(({classes, setPresentingFile, setPublishingCanvas, setPresentingFilePage, sessionId, presentationFile, setPresentationFile, publishingCanvas, presentingFilePage, presentingFile}) => {
  const [getData, data, isLoading, hasError] = useHttp()

  useEffect(()=>{
    if (data) {
      setPresentationFile(data)
      // setStreaming({...streaming, publishingCanvas: true, presentingFilePage: publishingCanvas?0:presentingFilePage})
      setPublishingCanvas(true)
      setPresentingFilePage(publishingCanvas?0:presentingFilePage)
    }
    // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    // Get file data if not available
    if (presentingFile.id) {
      getData(api.entities.sessions.getPDFPages(null,null,{id:sessionId, fileId:presentingFile.id}))
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (hasError) {
      setPresentationFile(false)
      // setStreaming({...streaming, presentingFile: false})
      setPresentingFile(false)
    }
    //eslint-disable-next-line
  },[hasError])

  const loading = ((!presentationFile) || isLoading)

  const setPage = (i) => {
    // setStreaming({...streaming, presentingFilePage: i})
    setPresentingFilePage(i)
  }

  // const togglePresentation = () => {
  //   setStreaming({...streaming, publishingCanvas: !publishingCanvas, presentingFilePage: publishingCanvas?0:presentingFilePage})
  // }

  if (loading) return <Grid item container className={classes.container} alignContent='center' justify='center' spacing={2}>
    <Grid item>
      <CircularProgress size={'4rem'} color='secondary' />
    </Grid>
  </Grid>

  return <Grid item container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Box fontSize={14} fontWeight={600} color={palette['brown-grey']} pt={1.5}> {presentingFile.filename} </Box>
        </Grid>
        <Grid item xs={12}>
          <Box width={'100%'} style={{backgroundColor:'rgba(0, 119, 175, 0.2)', borderRadius:4}}>
            <ScrollableContainer items={
              presentationFile.thumbs.map((thumb,i)=><PreviewTooltip title={<><img key={i} src={thumb} alt={`Page ${i}`} height={300} width={'auto'}/></>} placement="top">
                <img key={i} src={thumb} alt={`Page ${i}`} height={100} width={'auto'}
                     onClick={()=>setPage(i)}
                     className={clsx(classes.item, presentingFilePage===i&&classes.selected)} />
              </PreviewTooltip>
)
            } />
          </Box>

        </Grid>
  </Grid>
})

export default FilePresenter
