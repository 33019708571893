import React, { useEffect, Fragment } from 'react'
import { withRouter } from 'react-router-dom'

function ScrollToTop({ history, children }) {

  useEffect(() => {
    const unlisten = history.listen((e) => {
      const eventNavigation = e.pathname&&e.pathname.split('/')[1]==='event'&&(e.pathname.split('/').length===4||e.pathname.split('/').length===5)
      if (!eventNavigation) window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return <Fragment>{children}</Fragment>
}

export default withRouter(ScrollToTop)
