import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import {useParams} from 'react-router-dom'
import api from 'api'
import 'react-perfect-scrollbar/dist/css/styles.css'
import SessionPosters from 'components/pages/SessionViewer/SessionPosters'
import SessionOnlinePosters from 'components/pages/SessionViewer/SessionOnlinePosters'
import {STREAM_VIDEO_TYPES, VIEWER_TYPES} from 'config'
import Header from 'components/core/Header/Header'
import NotFound from 'components/pages/NotFound'
import SessionAnymeetsLive from "./SessionAnymeetsLive";


const styles = theme => ({
  padded: {
    padding: theme.spacing(1),
    paddingRight: 0
  },
  videoContainer: {
    position: 'relative',
  },
  sponsorPlaceholder: {
    height: '100%',
    alignSelf: 'center'
  }
})


const SessionIndex = withStyles(styles)(({classes, setProfileDialogOpen}) => {

  // Session state
  const [session, setSession] = useState()
  //eslint-disable-next-line
  const [getData, sessionData, isLoading, hasError] = useHttp()

  // Route parameters
  const {sessionId, type} = useParams()

  // On mount, get Session
  useEffect(() => {
    getData(api.entities.sessions.get(null, null, {id: sessionId}))
    //eslint-disable-next-line
  }, [])

  // On Session Data, set Session data, start poller
  useEffect(() => {
    if (sessionData) {
      setSession({...sessionData, id: sessionId})
    }
    // eslint-disable-next-line
  }, [sessionData])

  // Show error page
  if (hasError) {
    return <>
      <Header setProfileDialogOpen={setProfileDialogOpen} isSession={true}/>
      <NotFound/>
    </>
  }

  if (!session) return <> </>

  return <>
    { (!Object.values(VIEWER_TYPES).includes(session.type)) && <SessionAnymeetsLive session={session} /> }

    {session.type === VIEWER_TYPES.STANDARD && session.status.videoStreamType !== STREAM_VIDEO_TYPES.ANYMEETS_LIVE && session.status.videoStreamType !== STREAM_VIDEO_TYPES.ANYMEETS_LIVE_NEW && <SessionAnymeetsLive session={session} type={type}  /> }
    {session.type === VIEWER_TYPES.STANDARD && session.status.videoStreamType === STREAM_VIDEO_TYPES.ANYMEETS_LIVE && <SessionAnymeetsLive session={session} type={type} isWorkShop={true} /> }
    {session.type === VIEWER_TYPES.STANDARD && session.status.videoStreamType === STREAM_VIDEO_TYPES.ANYMEETS_LIVE_NEW && <SessionAnymeetsLive session={session} type={type} isAnymeetsLive={true} /> }

    {session.type === VIEWER_TYPES.POSTER && session.status.videoStreamType !== STREAM_VIDEO_TYPES.ANYMEETS_LIVE && session.status.videoStreamType !== STREAM_VIDEO_TYPES.ANYMEETS_LIVE_NEW && <SessionPosters session={session}/>}
    {session.type === VIEWER_TYPES.POSTER && (session.status.videoStreamType === STREAM_VIDEO_TYPES.ANYMEETS_LIVE || session.status.videoStreamType === STREAM_VIDEO_TYPES.ANYMEETS_LIVE_NEW) && <SessionOnlinePosters session={session} />}

  </>
})

export default SessionIndex
