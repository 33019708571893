import React, {useEffect, useState} from 'react'
import {withStyles,ThemeProvider} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Page from 'components/core/Page'
import {palette, themesEvent} from 'theme'
import {useHttp} from 'api/core'
import {Redirect, useLocation, useParams, withRouter} from 'react-router-dom'
import api from 'api'
import {useSnackbar} from 'notistack'
import Grid from '@material-ui/core/Grid'
import {useMetaData} from 'components/core/MetaProvider'
import Container from '@material-ui/core/Container'
import StickyBox from 'react-sticky-box'
import AlertUnpublishedChanges from 'components/pages/Event/AlertUnpublishedChanges'
import AlertPreview from 'components/pages/Event/AlertPreview'
import {LAYOUT_TYPES} from 'config'
import Hidden from '@material-ui/core/Hidden'
import Header from 'components/core/Header/Header'
import Footer from 'components/core/Footer/Footer'
import WithMenu from 'components/pages/Event/Layouts/WithMenu/WithMenu'
import SinglePage from 'components/pages/Event/Layouts/SinglePage/SinglePage'
import CenterMenu from 'components/pages/Event/Layouts/WithMenu/CenterMenu'
import LeftSideMenu from 'components/pages/Event/Layouts/WithMenu/LeftSideMenu'
import EventHeader from 'components/pages/Event/EventHeader'
import InfoBar from 'components/pages/Event/InfoBar'
// import useExtras from 'components/pages/Event/useExtras'
import RegistrationInfo from 'components/pages/Event/RegistrationInfo'
import DrawerMenu from 'components/pages/Event/Layouts/WithDrawer/DrawerMenu'
import DrawerMenuContained from 'components/pages/Event/Layouts/WithDrawerContained/DrawerMenuContained'
import {useAuth} from 'auth/AuthController'
import {HappeningNow} from 'components/pages/HappeningNow'
import clsx from 'clsx'
import NotFound from 'components/pages/NotFound'
import SubmissionsInfo from 'components/pages/Event/SubmissionsInfo'
import CameraReadyInfo from "components/pages/Event/CameraReadyInfo";
import {NotificationEventReceiver} from 'components/core/Notifications'
import ReviewSubmissionInfo from 'components/pages/Event/ReviewSubmissionInfo'
import { checkHost, imageFile } from 'utils'
import {LANG_LIST} from "config";
import language from "components/language";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";


language.getLanguage(); 

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  },
  eventHeader: {
    minHeight: '200px',
    background: palette['pale-grey'],
    width: '100%'
  },
  mainPage: {
    color: palette['greyish-brown']
  },
  leftBar: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(0),
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    zIndex: 999
  },
  leftBarSingle: {
    zIndex: 999,
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2)
  },
  leftBarDrawer: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(2)
  },
  rightBar: {
    paddingTop: theme.spacing(2),
  },
  rightBarSingle: {
    paddingTop: theme.spacing(2)
  },
  hideMd: {
    [theme.breakpoints.up(1500)]: {
      display: 'none'
    }
  },
  divider: {
    background: palette['very-light-pink-two'],
    width: '90%'
  },
  socialBar: {
    paddingTop: 60
  }
})

// A custom hook that buclds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Event = withStyles(styles)(({classes, history, setProfileDialogOpen}) => {
  const [isAuthenticated] = useAuth();
  const [event, setEventData] = useState();
  const [canManage, setCanManage] = useState(false);
  const [getData, data, isLoading, hasError] = useHttp();
  // eslint-disable-next-line
  const [getEventId, dataEventId, isLoadingEventId, hasErrorEventId] = useHttp();
  const [sendReqJoin, joinData, isJoining] = useHttp();
  const [sendReqLeave, leaveData, isLeaving] = useHttp();
  const {enqueueSnackbar} = useSnackbar();
  const [, setMetaData] = useMetaData();
  const [openDrawer, setOpenDrawer] = useState(true);
  const [redirectLoginToJoin, setRedirectLoginToJoin] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState(0);
  const {pageId, eventPage, slug} = useParams();
  const params = useParams();
  const [eventId, setEventId] = useState(params?.eventId);


  //get submission for this event to find camera ready state
  const [hasCameraReady,setHasCameraReady] = useState(false)
  const [getSubmissionsData, submissionsData] = useHttp();

  useEffect(() =>
  {
    if(eventId){
      getSubmissionsData(api.entities.submissions.getSubmissions(null, null, {id: eventId}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  useEffect(() => {
    if (submissionsData) {
      
      //const flag = submissionsData.filter((flow) => flow.cameraReady === true && flow.cameraReadyEnabled !== 3 && isFuture(parseISO(flow.cameraReadyEndDate)) && isPast(parseISO(flow.cameraReadyStartDate)));
      const flag = submissionsData.filter((flow) => flow.cameraReady === true && ( (flow.cameraReadyEnabled === 1 && isFuture(parseISO(flow.cameraReadyEndDate)) && isPast(parseISO(flow.cameraReadyStartDate))) || flow.cameraReadyEnabled === 2) );
      //const inCameraReadyLimits = isFuture(parseISO(flow.cameraReadyEndDate)) && isPast(parseISO(flow.cameraReadyStartDate));
      if (flag.length > 0)
      {
        setHasCameraReady(true)
      }
      else 
      {
        setHasCameraReady(false);
      }
    }
  }, [submissionsData]);



  const q = useQuery();
  const isPreview = q.get("preview") === "true";
  const previewSuffix = isPreview ? "?preview=true" : "";

  // Handle Toggled Drawer state
  const handleDrawer = (drawerState) => {
    setOpenDrawer(drawerState);
  };

  // Get page data
  const loadLatestData = () => {
    if (isPreview) {
      getData(api.entities.preview.getInfo(null, null, {id: eventId}));
    } else {
      getData(api.entities.events.getInfo(null, null, {id: eventId}));
    }
    getExtras();
  };

  useEffect(() => {
    if (data && event) {
      const selectedLang = LANG_LIST.find((lang) => lang.id === event.eventLanguage);
      language.setLanguage(selectedLang.slag);
    }
  }, [event, data]);

  const getEventIdBySlug = () => {
    getEventId(api.entities.events.getIdBySlug(null, null, {eventSlug: slug}));
  };

  const getEventIdByHost = () => {
    getEventId(api.entities.events.getIdByHost(null, null, {eventHost: window.location.hostname}));
  };

  useEffect(() => {
    if (dataEventId) {
      setEventId(dataEventId.eventId);
    }
  }, [dataEventId]);

  const isUsingHost = checkHost();

  // Initial Load
  useEffect(() => {
    if (eventId) {
      loadLatestData();
    } else {
      // find out id
      if (isUsingHost) {
        getEventIdByHost();
      } else {
        getEventIdBySlug();
      }
    }
    // eslint-disable-next-line
  }, [eventId, isPreview, eventPage]);

  useEffect(() => {
    if (data) {
      setCanManage(data.eventInfo.role === 1 || data.eventInfo.role === 2);
      setEventData({...data, participantsCount: data.participantsCount - data.keynoteSpeakers.length});
      setMetaData({title: data.eventInfo.name, description: data.description, canonical: window.location.href, image: imageFile(data.eventInfo.coverId)});
      setSelectedTheme(themesEvent[`theme${data.eventInfo.theme}`]);
    }
    // eslint-disable-next-line
  }, [data]);

  // If user requested join
  const joinEvent = () => {
    if (!isAuthenticated) {
      setRedirectLoginToJoin(true);
    } else {
      sendReqJoin(api.entities.events.join(null, null, {id: eventId}));
    }
  };

  useEffect(() => {
    if (joinData) {
      if (joinData.status === 200) {
        loadLatestData();
        enqueueSnackbar(`You have joined the event.`, {variant: "success"});
      }
    }
    // eslint-disable-next-line
  }, [joinData]);

  // If user requested Leave
  const leaveEvent = () => {
    sendReqLeave(api.entities.events.leave(null, null, {id: eventId}));
  };

  useEffect(() => {
    if (leaveData) {
      if (leaveData.status === 204) {
        loadLatestData();
        enqueueSnackbar(`You have left the event.`, {variant: "success"});
      }
    }
    // eslint-disable-next-line
  }, [leaveData]);

  useEffect(() => {
    //eslint-disable-next-line
    zE("webWidget", "updateSettings", {
      webWidget: {
        position: {horizontal: "right", vertical: "bottom"},
        offset: {
          horizontal: "180px",
          vertical: "-4px",
        },
      },
    });
  });

  // Get extras
  // const [extras] = useExtras(eventId?eventId:false, isPreview)
  const [getExtrasData, extrasData] = useHttp();
  const [extras, setExtras] = useState(false);

  const getExtras = () => {
    if (isPreview) {
      getExtrasData(api.entities.preview.getExtras(null, null, {id: eventId}));
    } else {
      getExtrasData(api.entities.events.getExtras(null, null, {id: eventId}));
    }
  };

  useEffect(() => {
    if (extrasData) {
      setExtras(extrasData);
    }
  }, [extrasData]);

  if (redirectLoginToJoin) {
    return <Redirect to={`/login/?returnUrl=/event/${event.eventInfo.id}/info`} />;
  }

  if (!event || !extras) {
    return (
      <Page>
        <Header setProfileDialogOpen={setProfileDialogOpen} canManage={canManage} />
        {hasError && <NotFound />}
      </Page>
    );
  }

  return (
    <ThemeProvider theme={selectedTheme}>
      <Page noFooter>
        <Header setProfileDialogOpen={setProfileDialogOpen} event={event} isEvent extras={extras} canManage={canManage} />
        {isAuthenticated && <NotificationEventReceiver topic={`/topic/event/${event.eventInfo.id}`} />}
        {isPreview && <AlertPreview event={event} eventId={event.eventInfo.id} published={event.eventInfo.published} openDrawer={openDrawer} eventLayout={event.eventInfo.layout} />}
        {!isPreview && canManage && event.eventInfo.dirty && <AlertUnpublishedChanges eventId={event.eventInfo.id} openDrawer={openDrawer} eventLayout={event.eventInfo.layout} />}

        {/*<Box className={classes.eventHeader}> </Box>*/}

        <Box>
          <Grid container spacing={1}>
            <Grid item lg={12} className={classes.mainPage}>
              <EventHeader event={event} canManage={canManage} />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} lg={2}>
                {event.eventInfo.layout === LAYOUT_TYPES.WITH_DRAWER && (
                  <Grid container className={classes.leftBarDrawer} spacing={3} justify={"flex-start"} alignContent={"flex-start"} alignItems={"flex-start"}>
                    <DrawerMenu event={event} previewSuffix={previewSuffix} extras={extras} openDrawer={handleDrawer} />
                  </Grid>
                )}
                {event.eventInfo.layout === LAYOUT_TYPES.WITH_DRAWER_CONTAINED && (
                  <Grid container className={classes.leftBarDrawer} spacing={3} justify={"flex-start"} alignContent={"flex-start"} alignItems={"flex-start"}>
                    <DrawerMenuContained event={event} previewSuffix={previewSuffix} extras={extras} openDrawer={handleDrawer} />
                  </Grid>
                )}
                <StickyBox offsetTop={event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE ? 140 : eventPage === "social-feed" ? 60 : 140} style={{zIndex: 1}} offsetBottom={120}>
                  {event.eventInfo.layout === LAYOUT_TYPES.WITH_MENU && (
                    <Grid container className={clsx(classes.leftBar, eventPage === "social-feed" && classes.socialBar)} spacing={3} justify={"flex-start"} alignContent={"flex-start"} alignItems={"flex-start"}>
                      <LeftSideMenu event={event} previewSuffix={previewSuffix} extras={extras} />
                    </Grid>
                  )}

                  <Box flexDirection="column" style={{maxWidth: 300}}>
                    {/* {event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE && hasCameraReady && <CameraReadyInfo event={event} />} */}

                    {event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.registrationsOpenAt !== null && <RegistrationInfo event={event} />}

                    {event.eventInfo.enableSubmissions && event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.hasSubmissionFlows && <SubmissionsInfo event={event} />}

                    {event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.hasReviews && <ReviewSubmissionInfo event={event} />}
                  </Box>
                </StickyBox>
              </Grid>
            </Hidden>
            <Grid item sm={12} md={12} lg={8}>
              <Container style={{minHeight: "768px"}}>
                {eventPage !== "social-feed" && <HappeningNow event={event} />}
                {event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE && (
                  <SinglePage
                    slug={slug}
                    event={event}
                    isPreview={isPreview}
                    canManage={canManage}
                    previewSuffix={previewSuffix}
                    pageId={pageId}
                    joinEvent={joinEvent}
                    leaveEvent={leaveEvent}
                    isLoading={isLoading}
                    isLeaving={isLeaving}
                    isJoining={isJoining}
                  />
                )}
                {event.eventInfo.layout === LAYOUT_TYPES.WITH_DRAWER && (
                  <SinglePage
                    slug={slug}
                    event={event}
                    isPreview={isPreview}
                    canManage={canManage}
                    previewSuffix={previewSuffix}
                    pageId={pageId}
                    joinEvent={joinEvent}
                    leaveEvent={leaveEvent}
                    isLoading={isLoading}
                    isLeaving={isLeaving}
                    isJoining={isJoining}
                  />
                )}
                {event.eventInfo.layout === LAYOUT_TYPES.WITH_DRAWER_CONTAINED && (
                  <SinglePage
                    slug={slug}
                    event={event}
                    isPreview={isPreview}
                    canManage={canManage}
                    previewSuffix={previewSuffix}
                    pageId={pageId}
                    joinEvent={joinEvent}
                    leaveEvent={leaveEvent}
                    isLoading={isLoading}
                    isLeaving={isLeaving}
                    isJoining={isJoining}
                  />
                )}
                {event.eventInfo.layout === LAYOUT_TYPES.WITH_MENU && (
                  <>
                    <CenterMenu event={event} previewSuffix={previewSuffix} isPreview={isPreview} extras={extras} />
                    <InfoPlaceHolder isPreview={isPreview}>
                      <WithMenu event={event} previewSuffix={previewSuffix} isPreview={isPreview} canManage={canManage} pageId={pageId} slug={slug} />
                    </InfoPlaceHolder>
                  </>
                )}
              </Container>
            </Grid>
            <Hidden mdDown>
              <Grid item xs>
                <StickyBox style={{zIndex: 1}} offsetTop={140} offsetBottom={80}>
                  <InfoBar event={event} isLeaving={isLeaving} isJoining={isJoining} isLoading={isLoading} joinEvent={joinEvent} leaveEvent={leaveEvent} hideJoin={eventPage === "tickets"} hasCameraReady={hasCameraReady}/>
                </StickyBox>
              </Grid>
            </Hidden>
          </Grid>
        </Box>

        <Footer event={event} />
      </Page>
    </ThemeProvider>
  );
})

const InfoPlaceHolder = ({children}) => <Box minHeight={window.innerHeight - 128}>{children}</Box>

export default withRouter(Event)
