import React from 'react'
import {withStyles} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Chip from 'components/core/Chip'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import clsx from 'clsx'
import {CATEGORIES,/* CURRENCY,*/ DOMAIN} from 'config'
import {format} from "date-fns"
import {Link, withRouter} from 'react-router-dom'
import Button from 'components/core/Button'
import {imageFile} from 'utils'
import parseISO from 'date-fns/parseISO'
import Grid from '@material-ui/core/Grid'
import BookmarkButton from 'components/core/BookmarkButton'
import ShareButton from 'components/core/ShareButton'
import EllipsisText from 'react-ellipsis-text'

const styles = theme => ({
  card: {
    position: 'relative',
    backgroundColor: 'white',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    border: '2px solid rgba(0,0,0,0)',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      border: `2px solid ${palette.aquamarine}`
    }
  },
  bookmarkIcon: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2)
  },
  cardMyEvent: {
  },
  cardImage: {
    width: '100%',
    // height: '101px',
    borderRadius: '5px',
    backgroundColor: palette.imageBackgroundColor,
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  cardInfo:{
  },
  title: {
    position: 'relative',
    fontWeight: 'bold',
    paddingRight: theme.spacing(4),
    minHeight: '45px',
    marginTop: -theme.spacing(1),
    '& a': {
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    }
  },
  titleEvent: {
    paddingRight: 0
  },
  listRow: {
    marginBottom: theme.spacing(1.5)
  },
  shareIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(7)
  },
  manageBtn: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    alignItems: 'space-between'
  },
  promotedLabel: {
    backgroundColor: palette.aquamarine,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white',
    borderRadius: '0 0 4px 4px',
    fontWeight: 'bold'
  },
  deEmphasized: {
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  },
  hidden: {
    visibility: 'hidden'
  },
  shown: {
    visibility: 'visible'
  },
  download: {
    alignSelf: 'center',
    minWidth: '90px',
    minHeight: '80px',
    height: 'auto',
    border: `1px solid ${palette.aquamarine}`,
    borderRadius: '6px',
    color: palette.aquamarine,
    textAlign: 'center',
    fontSize: '12px',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: palette.aquamarine,
      color: 'white'
    },
    '&:hover #download': {
      stroke: 'white'
    }
  },
  linkToEvent: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  viewOnly: {
    boxShadow: 'none',
    border: 'solid 1px #e6e6e6',
    cursor: 'default !important',
    '&:hover': {
      border: 'solid 1px #e6e6e6',
    },
    '& a': {
      // height: 0,
      cursor: 'default !important',
    }
  }
})

// const DownloadCSV = withStyles(styles)(({classes})=>{
//   return <Box className={classes.download}>
//     <IconDownloadBorderGreen/> <br/>
//     Download registrations’ CSV file
//   </Box>
// })

const PromotedLabel = withStyles(styles)(({classes, promoted=false}) => {
  const promotedStyle = clsx({
    [classes.promotedLabel]: true,
    [classes.hidden]: !promoted,
    [classes.shown]: promoted
  })

  return <>
    <Box className={promotedStyle}> PROMOTED </Box>
  </>
})

const ConferenceCardList = withStyles(styles)(({classes, staticContext, index, refreshList, viewOnly, promoted=false, joinEvents=false, manageEvents=false, event, history, hooks, onClick, key, ...restProps}) => {
  const cardStyle = clsx({
    [classes.card]: true,
    [classes.cardMyEvent]: joinEvents,
    [classes.viewOnly]: viewOnly
  })

  const titleStyle = clsx({
    [classes.title]: true,
    [classes.titleEvent]: joinEvents
  })

  //const ticketPrice = (price) => price===0?'Free':`From ${CURRENCY}${price}`

  const canManage = event.role===1||event.role===2
  const linkTo = manageEvents?`/manage-event/${event.id}/general`:`/event/${event.id}`

  return <>
      <Grid container item spacing={2} className={cardStyle}>
        <Link to={linkTo} className={classes.linkToEvent}> </Link>
          <Grid item>
            <Box display='flex' position='position' style={{top:0,left:0}}>
              <img src={imageFile(event.coverId)} alt={event.name} title={event.name} className={classes.cardImage} />
            </Box>
            {/*<img src={imageFile(event.coverId)} alt={event.name} title={event.name} className={classes.cardImage} />*/}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={7} className={classes.cardInfo}>
            <Typography variant='h6' component='h2' className={titleStyle}>
              <Link to={linkTo}> <EllipsisText text={event.name} length={60} /></Link>
            </Typography>
            <Box p={1} pt={0} pl={0}><Chip label={CATEGORIES[event.category]} /></Box>
            <Typography variant='body2' >{format(parseISO(event.startDate), 'LLL do | hh:mmaa')}</Typography>
            <Typography variant='body2' className={classes.deEmphasized}>{event.venueName}</Typography>
            {/*<Typography variant='body2' className={classes.deEmphasized}>{ticketPrice(event.ticketsFrom)}</Typography>*/}
          </Grid>
          {!joinEvents&&<> <Box className={classes.shareIcon}>
              <ShareButton shareLink={`https://${DOMAIN}/event/${event.id}`} />
            </Box>
            <Box className={classes.bookmarkIcon}>
              <BookmarkButton event={event} refreshList={refreshList}/>
            </Box> </>}
          {canManage&&!viewOnly&&<Box className={classes.manageBtn} p={1}>
              <Box display='flex' pr={1}>
                {/* {event.upgraded!==null&&event.upgraded===false&&<Button size='smaller' variant='outlined' component={Link} to={`/pricing`}>Upgrade Tier</Button>} */}
              </Box>
              <Box display='flex'>
                 <Button size='smaller' variant='outlined' component={Link} to={`/manage-event/${event.id}/general`}> Manage</Button>
              </Box>
          </Box>}
          {/*{joinEvents||manageEvents?<DownloadCSV />:''}*/}
          {promoted&&!joinEvents&&!manageEvents&&<PromotedLabel promoted={promoted}/>}
      </Grid>
  </>
})

export default withRouter(ConferenceCardList)
