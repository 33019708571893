import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Redirect, useParams, withRouter} from 'react-router-dom'
import api from 'api'
import {useHttp} from 'api/core'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {RadioGroup, TextField} from 'formik-material-ui'
import Button from 'components/core/Button'
import Page from 'components/core/Page'
import {palette} from 'theme'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {IconStatusOkFillGreen} from 'components/core/icons'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import NotFound from 'components/pages/NotFound'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import {checkHost} from 'utils'

const styles = theme => ({
  form: {
    width: '100%',
    textAlign: 'center'
  },
  codeForm: {
    paddingTop: theme.spacing(7)
  }
})

const InputCodeNumber = withStyles(styles)(({classes, setCode}) => <Formik
  initialValues={{code: ''}}
  // validationSchema={Schema}
  onSubmit={(values, {setSubmitting}) => {
    setCode(values.code)
    setSubmitting(false)
  }}>
  {({isSubmitting, errors, submitForm}) => (
    <Form className={classes.form}>
      <Grid item xs={12}>
        <Field type='text' name='code' component={TextField} label='Type code number' variant='filled' fullWidth
               disabled={isSubmitting}/>
      </Grid>
      <Grid item xs={12}>
        <Box pt={4}>
          <Button type='submit' color='primary' size='small'>Submit code</Button>
        </Box>
      </Grid>
    </Form>
  )}
</Formik>)

const FillInYourCode = withStyles(styles)(({classes, setCode}) => <Fade in={true} timeout={TIME_FADE_IN}>

  <Grid container spacing={2} justify='center'
        alignContent='center' alignItems='center'
        className={classes.codeForm}>
    <Grid item xs='auto' md={4}> </Grid>
    <Grid item container md={4} justify='center' alignContent='center' alignItems='center'>
      <Box fontWeight='bold' pt={2} pb={1}>
        Please fill in your code
      </Box>
    </Grid>
    <Grid item xs='auto' md={4}> </Grid>
    <Grid item xs={12} sm={4}>
      <InputCodeNumber setCode={setCode}/>
    </Grid>
  </Grid></Fade>)

const questionStyles = theme => ({
  form: {
    width: '100%'
  },
  questionForm: {
    paddingTop: theme.spacing(7)
  },
  radioGroup: {
    maxWidth: '300px'
  }
})

const Question = withStyles(questionStyles)(({classes, question, code, goToNext, isLoadingNextQuestion}) => {
  const [getData, data, isLoading] = useHttp()
  const [answer, setAnswer] = useState()

  useEffect(() => {
    if (answer) {
      getData(api.entities.feedback.answer({optionId: answer}, {q: question.ordinal}, {code: code}))
    }
    // eslint-disable-next-line
  }, [answer])

  useEffect(() => {
    if (data) {
      goToNext()
    }
    // eslint-disable-next-line
  }, [data])

  return <Fade in={true} timeout={TIME_FADE_IN}><Grid container spacing={2} justify='center' alignContent='center'
                                             alignItems='center'
                                             className={classes.questionForm}>
    <Grid item xs='auto' md={3}> </Grid>
    <Grid item container md={6}>
      <Box fontWeight='bold' pt={2} pb={1}>
        <Typography variant={'h5'}>{question.ordinal}. {question.question}</Typography>
      </Box>
    </Grid>
    <Grid item xs='auto' md={3}> </Grid>
    <Grid item xs={12} md={6} key={question.ordinal}>
      <Formik
        initialValues={{answer: `${question.selectedOptionId}`}}
        onSubmit={(values) => {
          setAnswer(parseInt(values.answer))
        }}>
        {({setFieldValue, values}) => (
          <Form className={classes.form}>
            <Grid item xs={12}>
              <Field component={RadioGroup} name='answer' className={classes.radioGroup}>
                {question.options.map((option, i) => {
                  return <FormControlLabel key={i}
                                           value={`${option.id}`}
                                           control={<Radio color="primary"/>}
                                           label={option.option}
                                           labelPlacement='end'
                                           disabled={isLoading||isLoadingNextQuestion}
                  />
                })}
              </Field>
            </Grid>
            <Grid container item justify='center' alignContent='center' alignItems='center'>
              <Grid item>
                <Box pt={4}>
                  <Button type='submit' color='primary' size='small' disabled={isLoading||isLoadingNextQuestion}>Submit answer</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  </Grid></Fade>
})

const Completion = withStyles(styles)(({classes,event}) => {

  const [redirect, setRedirect] = useState(false)
  const {eventSlug, eventHost, eventId} = event

  useEffect(()=>{
    setTimeout(()=>{
      setRedirect(true)
    },2000)
  })

  let baseURL = `/event/${eventId}`
  const isUsingHost = checkHost()

  if (eventHost&&isUsingHost) {
    baseURL = ``
  } else if (eventSlug) {
    baseURL = `/${eventSlug}`
  }

  if (redirect) {
    return <Redirect to={`${baseURL}/info`}/>
  }

  return <Box display='flex' justifyContent='center' alignContent='center' justifyItems='center' flexDirection='row'
              pt={6}>
    <Box display='flex' justifyContent='center' alignItems='center' alignContent='center' justifyItems='center'
         flexDirection='column'>
      <IconStatusOkFillGreen/>
      <Box display='flex' pt={2}>
        <Typography variant={'h6'}>Thank you!</Typography>
      </Box>
      <Box display='flex' pt={2}>
        <Typography variant={'body1'}>Your answers were submitted successfully.</Typography>
      </Box>
    </Box>
  </Box>
})

const Feedback = withStyles(styles)(({classes}) => {
  const [question, setQuestion] = useState()
  const [code, setCode] = useState()
  const [questionNumber, setQuestionNumber] = useState()
  const [totalNumberOfQuestion, setTotalNumberOfQuestions] = useState(0)
  const [getData, data, isLoading, hasError] = useHttp()

  const {feedbackId} = useParams()

  useEffect(()=>{
    if (feedbackId) {
      setCode(feedbackId)
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (code) {
      setQuestionNumber(1)
    }
    // eslint-disable-next-line
  }, [code])

  useEffect(() => {
    if (data) {
      setQuestion(data)
      setTotalNumberOfQuestions(data.total+1)
    }
  }, [data])

  useEffect(() => {
    if (questionNumber && (questionNumber !== totalNumberOfQuestion)) {
      getData(api.entities.feedback.getQuestions(null, {q: questionNumber}, {code: code}))
    }
    // eslint-disable-next-line
  }, [questionNumber])

  const goToNext = () => {
    setQuestionNumber(questionNumber + 1)
  }


  if (hasError) {
    return <NotFound  />
  }

  return <Page title={question?`${question.eventTitle} - ${question.feedbackTitle}`:`Feedback`} backgroundColor={palette['pale-grey']}>
    {question&&(questionNumber !== totalNumberOfQuestion)&&question.selectedOptionId&&<Alert severity='warning'>
      <AlertTitle>Answered question</AlertTitle>
      You've already answered this question.
      </Alert>}
    <Container>
      {question&&<>
        <Box display='flex' p={4} className={classes.title}>
          <Box display='flex' alignContent='center' alignItems='center'>
            <Box display='flex'>
              <Typography variant={'h5'}> {question.eventTitle} / {question.feedbackTitle} </Typography>
            </Box>
          </Box>
        </Box>
        </>}
      {!feedbackId && !question && <FillInYourCode setCode={setCode}/>}
      {question && (questionNumber !== totalNumberOfQuestion) &&
      <Question question={question} goToNext={goToNext} code={code} isLoadingNextQuestion={isLoading}/>}
      {(questionNumber === totalNumberOfQuestion) && <Completion event={question} />}
    </Container>
  </Page>

})

export default withRouter(Feedback)
