import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import {imageFile} from 'utils'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import ParticipantToolTipView from 'components/core/ParticipantToolTipView'
import {Button} from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

const styles = theme => ({
  descriptionBox: {
    width: '100%',
    minHeight: 200,
    fontSize: 14,
    // maxHeight: 150,
    // overflowY: 'scroll',
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    mozHyphens: 'auto',
    webkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap'
  },
  list: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    // maxHeight: 300,
    overflow: 'hidden',
    border: '1px solid #e6e6e6',
    borderRadius: 4
  },
  listInner: {
    width: '100%'
  },
  chip: {
    backgroundColor: '#eee',
    border: 'none'
  },
  avatar: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#eee'
  },
  root: {
    fontSize: 14,
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5)
  },
  treeItem: {
    backgroundColor: 'white',
  },
  label: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 240,
    border: '1px solid #fff',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  }

})




const PDFView = ({url}) => {
  const [file, setFile] = useState()

  useEffect(()=>{
    fetch(url).then(function(response) {
      return response.blob()
    }).then(function(response) {
      let newBlob = new Blob([response], {type: "application/pdf"})
      setFile(window.URL.createObjectURL(newBlob))
    });
    //eslint-disable-next-line
  },[])

  return <>
    {file&&<object data={file} type="application/pdf" width={'100%'}  style={{height:'40vh'}}>
      <iframe src={file} width={'100%'} height={'100%'} title={url}> </iframe>
    </object>}
    {/*{file&&<iframe src={file} />}*/}
    {/*{file&&<GoogleDocsViewer*/}
    {/*  width="100%"*/}
    {/*  height="780px"*/}
    {/*  fileUrl={file}*/}
    {/*  key={url}*/}
    {/*/>}*/}
  </>
}

const Info = withStyles(styles)(({classes, sessionState}) => {

  const {
    posterPresentation
  } = sessionState

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)

  const handleClick = (event,participant) => {
    setAnchorEl(event.currentTarget)
    setOpen(participant)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(()=>{
    if (!anchorEl) {
      setTimeout(()=>setOpen(false),200)
    }
  },[anchorEl])

  // const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return <Grid item container justify='center' alignContent='center' alignItems='center' spacing={2}>

    {posterPresentation.selectedPresentation&&posterPresentation.selectedPresentation.length!==0&&<>

      {posterPresentation.presentationFile&&<PDFView url={`${posterPresentation.presentationFile}`} />}

      {posterPresentation.selectedPresentation.files&&posterPresentation.selectedPresentation.files.length!==0&&<Grid item container spacing={1} justify='center' alignContent='center' alignItems='center' direction='column'>
        {posterPresentation.selectedPresentation.files&&posterPresentation.selectedPresentation.files.map((file,i) => {
          return <Grid item key={i}>

            <Button component='a' href={`/files/${file.id}`} download={`${file.filename}`}
                    variant="contained"
                    color="secondary"
                    startIcon={<PictureAsPdfIcon />}
            >
              Download poster file
            </Button>

          </Grid>
        })}
      </Grid>}

      {/*{posterPresentation.selectedPresentation.files&&posterPresentation.selectedPresentation.files.length!==0&&<Box className={classes.participants} pl={1}>*/}
      {/*  {posterPresentation.selectedPresentation.files&&posterPresentation.selectedPresentation.files.map((file,i) => {*/}
      {/*    return <Box display='inline-block' m={1} key={i}>*/}

      {/*      <Button component='a' href={`/files/${file.id}`} download={`${file.filename}`}*/}
      {/*              variant="contained"*/}
      {/*              color="secondary"*/}
      {/*              startIcon={<PictureAsPdfIcon />}*/}
      {/*      >*/}
      {/*        Download poster file*/}
      {/*      </Button>*/}

      {/*    </Box>*/}
      {/*  })}*/}
      {/*</Box>}*/}

      {posterPresentation.selectedPresentation.title&&<Grid item xs={12}>
        <Typography variant='h3'>{posterPresentation.selectedPresentation.title}</Typography>
      </Grid>}

      {posterPresentation.selectedPresentation.abstract&&<Grid item xs={12}>
        <Grid item container spacing={1}>
          <Grid item  className={classes.descriptionBox}>
            {posterPresentation.selectedPresentation.abstract}
          </Grid>
        </Grid>
      </Grid>}

      {posterPresentation.selectedPresentation.participants && posterPresentation.selectedPresentation.participants.length !== 0 && <Grid item xs={12}>
        <Box width={'100%'} className={classes.list}>
          <List
            className={classes.listInner} dense
          >
            {posterPresentation.selectedPresentation.participants.sort().filter((s)=>!s.promoted).map((participant, i) => {
              return<ListItem key={i} className={clsx(classes.speaker)} button onClick={(e)=>handleClick(e,participant)}>
                <ListItemAvatar>
                  <Avatar alt={`${participant.lastName} ${participant.firstName}`}
                          src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />
                </ListItemAvatar>
                <ListItemText id="switch-list-label"
                              primary={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}/>
              </ListItem>})}
          </List>
          <Popover
            id={id}
            open={!!open&&anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
          >
            <Box className={classes.tooltip}>
              <ParticipantToolTipView participant={open} />
            </Box>
          </Popover>
        </Box>
      </Grid>}

        {/*<Box className={classes.participants} pl={1}>*/}
        {/*  {posterPresentation.selectedPresentation.participants&&posterPresentation.selectedPresentation.participants.map((author,i) => {*/}
        {/*    return <Box width={'100%'} key={i}><ParticipantChipView participantObj={author} /></Box>*/}
        {/*  })}*/}
        {/*</Box>*/}


      {/*</>*/}
    </>}

  </Grid>
})

export default Info
