import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {CURRENCY} from 'config'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import clsx from 'clsx'
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton'
import PlanEventOverview from 'components/core/PlanEventOverview'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
  container: {
    padding: theme.spacing(2)
  },
  planBox: {
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    padding: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main
    }
  },
  purchasedPlan: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7
  },
  button: {
    height: '100%',
    position: 'relative'
  },
  note: {
    left: 0,
    fontSize: '11px',
    position: 'absolute',
    width: '100%',
    bottom: '-5px',
    textAlign: 'center',
    color: palette['brown-grey'],
    fontWeight: 'bold'
  },
  // container: {
  //   marginBottom: theme.spacing(2)
  // },
  priceColor: {
    color:theme.palette.primary.main
  }
})

const PlanCardView = withStyles(styles)(({classes, handleOpen, event, plan, upgrade, existing, busy, setBusy, current }) => {


  return <Grid container spacing={2} className={classes.container} justify='space-between'>

           <Grid item container className={clsx(classes.planBox)} justify='space-between' spacing={2}>
             <Grid item>
             <Box display='flex' flexDirection='column' alignContent='center' justifyContent='center'>
               <Box display='flex' fontSize='14px'>
                 Plan
               </Box>
               <Box display='flex' fontSize='20px' fontWeight='bold' alignItems='center'>
                 <Box display='flex'>{plan.name}</Box>
                 <Box display='flex'> <IconButton color="primary" aria-label="View plan features" component='a' target='_blank' href={'/pricing'} alt='View plan features'>
                   <InfoIcon/>
                 </IconButton> </Box>
               </Box>
               {plan.billingPolicy===2&&<Box display='flex' fontSize='14px' fontWeight='bold' pt={1}>
                 All free features
               </Box>}
               <Box display='flex' fontSize='14px' fontWeight='bold' pt={1}>
                 {plan.billingPolicy===2?'10 one-day events':plan.name==='Free'?'Limited features':'All features included'}
               </Box>
             </Box>
           </Grid>

             {plan.maxAttendees!==null&&<Grid item>
               <Box display='flex' fontSize='14px'>
                 Max. Attendees
               </Box>
               <Box display='flex' fontSize='16px' fontWeight='bold'>
                 {plan.maxAttendees}
               </Box>
             </Grid>}

             {plan.maxBroadcastingHours!==null&&<Grid item>
               <Box display='flex' fontSize='14px'>
                 Max. Broadcast Hours
               </Box>
               {plan.maxBroadcastingHours&&<Box display='flex' fontSize='16px' fontWeight='bold'>
                 {plan.maxBroadcastingHours===null?'-':plan.maxBroadcastingHours}
               </Box>}
             </Grid>}

             {current&&plan.name!=='Free'&&<Grid item>
               <Box display='flex' flexDirection='column'  justifyContent='center'>
                 <Box display='flex' pt={2} justifyContent='center' alignContent='flex-end'>
                   <Button disabled={busy} autoWidth onClick={()=>handleOpen(plan, !!upgrade)}> Add-ons </Button>
                 </Box>
               </Box>
             </Grid>}

             {((!existing)||handleOpen)&&(!current)&&<Grid item>
             <Box display='flex' flexDirection='column'  justifyContent='center'>
               {!existing&&<Box display='flex' fontSize='14px' justifyContent='center'>
                 Price {plan.billingPolicy===2?'/mo':''}
               </Box>}
               {!existing&&<Box display='flex' fontSize='24px' fontWeight='bold' justifyContent='center' className={classes.priceColor}>
                 {plan.price?`${CURRENCY} ${plan.price}`:'-'}
               </Box>}
               <Box display='flex' pt={2} justifyContent='center' alignContent='flex-end'>
                 {handleOpen&&(!current)&&<Button disabled={busy} autoWidth onClick={()=>handleOpen(plan, !!upgrade)}> {upgrade?'Use':'Buy'} </Button>}
               </Box>
             </Box>
           </Grid>}

             {plan.events&&plan.events.length!==0&&<Grid item container>
               <Grid item xs={12}>
                 <Box pt={3} pb={3}>
                   <Divider />
                 </Box>
               </Grid>
               <Grid item container spacing={2}>
                 <Grid item xs={6}>
                   <Box fontWeight='bold'> Event </Box>
                 </Grid>
                 <Grid item xs={6}>
                   <Box fontWeight='bold'> Period active </Box>
                 </Grid>
               </Grid>
               {plan.events.map((event)=><PlanEventOverview event={event} />)}
             </Grid>}
           </Grid>

        {/*{handleOpen&&<Grid item xs={12} md={3}>*/}
        {/*  <Grid item container justify='center' alignContent='center' alignItems='center' className={classes.button}>*/}
        {/*    <Grid item>*/}
        {/*      <Button autoWidth onClick={()=>handleOpen(plan)}> Upgrade </Button>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>}*/}
      </Grid>
})

export default PlanCardView
