import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import RestaurantCard from 'components/core/RestaurantCard'
import HotelEditNew from 'components/core/HotelEditNew'
import HotelCard from 'components/core/HotelCard'
import RestaurantEditNew from 'components/core/RestaurantEditNew'
import {TIME_FADE_IN} from 'config'

const styles = theme => ({
  restaurantsAndHotelsContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noRestaurantsAndHotelsYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  addBtn: {
    textAlign: 'right'
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },  
  subtitle: {
    fontWeight: "500",
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
    paddingBottom: theme.spacing(2),
    color: "#444"
  },    
})

const RestaurantsAndHotels = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack, content, contentType})=>{

  // Hooks
  const [delRequestHotel, delDataHotel] = useHttp()
  const [delRequestRestaurant, delDataRestaurant] = useHttp()
  const [getHotels, hotelData] = useHttp()
  const [getRestaurants, restaurantsData] = useHttp()
  const [getRestaurantCategories, restaurantCategoriesData] = useHttp()
  const [getHotelsCategories, hotelsCategoriesData] = useHttp()

  // State
  const [hotels, setHotels] = useState()
  const [hotelCategories, setHotelCategories] = useState()
  const [restaurants, setRestaurants] = useState()
  const [restaurantCategories, setRestaurantCategories] = useState()
  
  const [openExtraDialog, setOpenExtraDialog] = useState(false)
  const [editingExtra, setEditingExtra] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingExtra, setDeletingExtra] = useState()

  // Deps
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getHotelsCategories(api.entities.options.getHotelCategories())
    getHotels(api.entities.manage.getHotels(null,null,{id:eventId}))
    getRestaurants(api.entities.manage.getRestaurants(null,null,{id:eventId}))
    getRestaurantCategories(api.entities.options.getRestaurantCategories())

    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (hotelsCategoriesData) {
      setHotelCategories(hotelsCategoriesData)
    }
  },[hotelsCategoriesData])

  useEffect(()=>{
    if (restaurantCategoriesData) {
      setRestaurantCategories(restaurantCategoriesData)
    }
  },[restaurantCategoriesData])

  useEffect(()=>{
    if (hotelData) {
      setPageTitle(hotelData.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(hotelData.eventInfo)
      setHotels(hotelData)
    }
    // eslint-disable-next-line
  },[hotelData])

  useEffect(()=>{
    if (restaurantsData) {
      setRestaurants(restaurantsData)
    }
  },[restaurantsData])

  const handleClose = (closeInfo) => {
    setOpenExtraDialog(false)
    if (closeInfo.shouldReload) {
      switch (closeInfo.type) {
        case 'hotel':
          getHotels(api.entities.manage.getHotels(null,null,{id:eventId}))
          break
        case 'restaurant':
          getRestaurants(api.entities.manage.getRestaurants(null,null,{id:eventId}))
          break
        default:
      }
    }
  }

  const handleOpen = (restaurantsAndHotel,type) => {
    setOpenExtraDialog({type})
    if (restaurantsAndHotel) {
      setEditingExtra(restaurantsAndHotel)
    } else {
      setEditingExtra(undefined)
    }
  }

  const handleDeleteOpen = (delInfo) => {
    setDeleteOpen(true)
    setDeletingExtra(delInfo)
  }

  const handleDeleteClose = (delInfo) => {
    setDeleteOpen(false)
    if (delInfo && delInfo.type===deletingExtra.type && delInfo.id === deletingExtra.id) {

      switch (delInfo.type) {
        case 'hotel':
          delRequestHotel(api.entities.hotels.delete(null,null,{id:delInfo.id}))
          break
        case 'restaurant':
          delRequestRestaurant(api.entities.restaurants.delete(null,null,{id:delInfo.id}))
          break
        default:
      }

      setDeletingExtra(undefined)
    }
  }

  useEffect(()=>{
    if (delDataHotel) {
      enqueueSnackbar('Successfully deleted restaurantsAndHotel', {variant: 'success'})
      getHotels(api.entities.manage.getHotels(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[delDataHotel])

  useEffect(()=>{
    if (delDataRestaurant) {
      enqueueSnackbar('Successfully deleted restaurantsAndHotel', {variant: 'success'})
      getRestaurants(api.entities.manage.getRestaurants(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[delDataRestaurant])

  if (!hotels||!restaurants||!restaurantCategories||!hotelCategories) return <> </>

  return <>

    <Dialog onClose={handleClose} open={openExtraDialog.type==='restaurant'} maxWidth='md' fullWidth>
      <RestaurantEditNew restaurant={editingExtra} categories={restaurantCategories} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog onClose={handleClose} open={openExtraDialog.type==='hotel'} maxWidth='md' fullWidth>
      <HotelEditNew hotel={editingExtra} categories={hotelCategories} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete this {deletingExtra?deletingExtra.type:'entry'}?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={()=>{handleDeleteClose(deletingExtra)}}  size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>

      <Box>
      {((content && contentType===6) || !content) && <Grid container className={classes.restaurantsAndHotelsContainer} spacing={content? 0 : 2}>
        <Grid item xs={12}>
          {content ?
            (<Typography component='h2' variant='h5' className={classes.subtitle}>
              Restaurants list
            </Typography>)
              :
            (<Typography component='h2' variant='h5' className={classes.title}>
              Restaurants
            </Typography>)
          }          
        </Grid>

        <Grid item xs={12}>
          {restaurants&&restaurants.restaurants.map((restaurant,i)=>{
            return <RestaurantCard restaurant={restaurant} categories={restaurantCategories} key={i} editAction={()=>{handleOpen(restaurant,'restaurant')}} deleteAction={()=>{handleDeleteOpen({type:'restaurant',id:restaurant.id})}} mb={2} content={content}/>
          })}
          {!restaurants||restaurants.restaurants.length===0?<Grid item container className={classes.noRestaurantsAndHotelsYet}>
            <Grid item> No restaurants yet! </Grid>
          </Grid>:''}
        </Grid>
        <Grid item xs={12} className={classes.addBtn}>
          <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen(undefined,'restaurant')}}>Add Restaurant</Button>
        </Grid>
      </Grid>}

      {((content && contentType===7) || !content) && <Grid container className={classes.restaurantsAndHotelsContainer} spacing={content? 0 : 2}>
        <Grid item xs={12}>
          {content ?
            (<Typography component='h2' variant='h5' className={classes.subtitle}>
              Hotels list
            </Typography>)
              :
            (<Typography component='h2' variant='h5' className={classes.title}>
              Hotels
            </Typography>)
          }          
        </Grid>

        <Grid item xs={12}>
          {hotels&&hotels.hotels.map((hotel,i)=>{
            return <HotelCard hotel={hotel} categories={hotelCategories} key={i} editAction={()=>{handleOpen(hotel,'hotel')}} deleteAction={()=>{handleDeleteOpen({type:'hotel',id:hotel.id})}} mb={2} content={content}/>
          })}
          {!hotels||hotels.hotels.length===0?<Grid item container className={classes.noRestaurantsAndHotelsYet}>
            <Grid item> No hotels yet! </Grid>
          </Grid>:''}
        </Grid>
        <Grid item xs={12} className={classes.addBtn}>
          <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen(undefined,'hotel')}}>Add Hotel</Button>
        </Grid>
      </Grid>}
      </Box>

  </>
})

export default withRouter(RestaurantsAndHotels)
