import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
import {TextField} from '@material-ui/core'
import clsx from 'clsx'
import FormEditor from 'components/core/Forms/FormEditor'
import Chip from '@material-ui/core/Chip'
import EventNoteIcon from '@material-ui/icons/EventNote'
import DoneIcon from '@material-ui/icons/Done'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import api from 'api'
import CircularProgress from '@material-ui/core/CircularProgress'
import {useSnackbar} from 'notistack'

const styles = theme => ({
    container: {
        padding: '40px 50px 50px 50px',
        // backgroundColor: palette['cerulean-blue'],
        // color: 'white',
        borderRadius: '6px'
    },
    newFormType: {
        // width: '580px'
    },
    row: {
        marginBottom: theme.spacing(5)
    },
    tabsBar: {
        backgroundColor: palette['very-light-pink']
    },
    tab: {
        width: '100%',
        color: 'white',
        backgroundColor: palette['cerulean-blue'],
        padding: theme.spacing(2)
    },
    Tabs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    activeTab: {
        backgroundColor: palette.aquamarine,
        '&:focus': {
            backgroundColor: palette.aquamarine
        }
    },
    tabLabel: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    addNewItem:{
        // backgroundColor: 'white',
        // padding: theme.spacing(2),
        // borderRadius: '6px',
        border: '2px dashed !important',
        // borderColor: palette['cerulean-blue'],
        // justifyContent: 'center',
        // alignItems: 'center',
        // fontWeight: 'bold',
        // color: palette['greyish-brown'],
        // fontSize: '18px',
        opacity: 0.5,
        margin: 0,
        cursor: 'pointer',
        transition: '0.4s all',
        '&:hover': {
            opacity: 0.8
        }
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid',
        borderRadius: '6px',
        borderColor: 'white',
        backgroundColor: 'rgba(0,0,0,0.1)',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    formControl: {
        width: '100%'
    },
    toolbar: {
        borderTop: '1px solid',
        color: 'white',
        marginTop: theme.spacing(2),
    },
    editor: {
        color: palette['greyish-brown'],
        borderRadius: '6px',
        minHeight: '300px',
        fontFamily: "'Roboto','Karla', sans-serif;",
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    dialogActions: {
        marginTop: theme.spacing(2)
    },
    btns: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
    mainBox: {
        marginTop: theme.spacing(3),
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: '350px',
        // overflowY: 'auto'
        // minHeight: 'calc(80vh - 300px)'
    }
})

// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//         suffix="%"
//       />
//     );
// }


const FormEditNew = withStyles(styles)(({classes, handleClose, unlimited, setSuccessful, message, forms, eventId }) => {
    const { enqueueSnackbar } = useSnackbar()

    //eslint-disable-next-line
    const [putData, dataPut, isSaving, hasErrorSave] = useHttp()

    const [tab, setTab] = useState(0)

    const [fieldsReject, setFieldsReject] = useState(forms.rejectForm.fields)
    const [nameReject, setNameReject] = useState(forms.rejectForm.name?forms.rejectForm.name:'Reviewer rejection response')
    const [nameAccept, setNameAccept] = useState(forms.acceptForm.name?forms.acceptForm.name:'Reviewer acceptance responce')
    const [msg, setMsg] = useState(message)
    const [fields, setFields] = useState(forms.acceptForm.fields)

    useEffect(()=>{
        if (dataPut) {
            enqueueSnackbar('Successfully saved', {variant:'success'})
            handleClose(true)
        }
        // eslint-disable-next-line
    },[dataPut])

    const saveForms = () => {
        let formDataObj  = new FormData();

        const payLoad = {
            message: msg,
            acceptForm: {name:nameAccept, fields:fields},
            rejectForm: {name:nameReject, fields:fieldsReject}
        }

        const dataObj = new Blob([JSON.stringify(payLoad)], { type: 'application/json'});
        formDataObj.append('data', dataObj)

        // const message = new Blob([JSON.stringify(msg)], { type: 'application/json'});
        // formDataObj.append('message', message)
        //
        // const aForm = new Blob([JSON.stringify({name:nameAccept, fields:fields})], { type: 'application/json'});
        // formDataObj.append('acceptForm', aForm)
        //
        // const rForm = new Blob([JSON.stringify({name:nameReject, fields:fieldsReject})], { type: 'application/json'});
        // formDataObj.append('rejectForm', rForm)

        putData(api.entities.manage.putForms(formDataObj, null, {id:eventId}, true))

    }

    // const isSubmitting = isLoading&&!hasError

    return <Box className={classes.container}>
        <Grid item container spacing={1} className={classes.row}>
            <Typography variant='h5' component='h1'> {'Review forms'}</Typography>
        </Grid>

        <Grid item container spacing={1} className={classes.Tabs}>

            <Grid item xs>
                <Chip size='medium' className={clsx(classes.tab, tab === 0 && classes.activeTab)} onClick={() => {
                    setTab(0)
                }} label={<Box className={classes.tabLabel}><EventNoteIcon/> &nbsp; Review invite </Box>}
                      color={'secondary'}/>
            </Grid>

            <Grid item xs>
                <Chip size='medium' className={clsx(classes.tab, tab === 1 && classes.activeTab)} onClick={() => {
                    setTab(1)
                }} label={<Box className={classes.tabLabel}><DoneIcon/> &nbsp; Reviewer acceptance responce </Box>}
                      color={'secondary'}/>
            </Grid>

            <Grid item xs>
                <Chip size='medium' className={clsx(classes.tab, tab === 2 && classes.activeTab)} onClick={() => {
                    setTab(2)
                }} label={<Box className={classes.tabLabel}><ThumbDownIcon/> &nbsp; Reviewer rejection response </Box>}
                      color={'secondary'}/>
            </Grid>

        </Grid>

        <Grid item container className={classes.mainBox}>

        {tab === 0 && <>
            <Grid item container spacing={2}>
                <Grid item xs={12}>
                    <TextField multiline rows={4} value={msg} variant={'filled'} fullWidth label={'Invitation message'} onChange={(e)=>setMsg(e.target.value)}/>
                </Grid>
            </Grid>
        </>}

        {tab === 1 && <>
            <Grid item container spacing={2}>
                {!fields&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                </Box>}
                {fields&&<FormEditor fields={fields} setFields={setFields} name={nameAccept} setName={setNameAccept} eventId={eventId} flowType={"review"}/>}
            </Grid>
        </>}

        {tab === 2 && <>
            <Grid item container spacing={2}>
                {!fieldsReject&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                </Box>}
                {fieldsReject&&<FormEditor fields={fieldsReject} setFields={setFieldsReject} name={nameReject} setName={setNameReject} eventId={eventId}  flowType={"review"} />}
            </Grid>
        </>}

        </Grid>
        {/*<FormEditor fields={fields} setFields={setFields} />*/}
        {/*<FormViewer fields={fields} setFields={setFields} />*/}
        <Grid item container spacing={3}>
            <Grid item md={3} xs='auto'> </Grid>
            <Grid item container md={6} spacing={3} className={classes.btns}>
                <Grid item xs={12} md={6}>
                    <Button variant='outlined' size='small' color='primary' onClick={handleClose}> Cancel </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button size='small' type='submit' onClick={saveForms} disable={isSaving}> Save Changes </Button>
                </Grid>
            </Grid>
        </Grid>
    </Box>
})

export default FormEditNew
