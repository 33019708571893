import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import ConferenceCard from 'components/core/ConferenceCard'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import {withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import NoManageEvents from 'components/placeholders/NoManageEvents'

const styles = theme => ({
  eventList: {
    maxWidth: '1280px'
  }
})

const ManageEvents = withStyles(styles)(({classes, history}) => {

  const [getEvents, eventsData] = useHttp()
  const [events, setEvents] = useState()

  useEffect(()=>{
      getEvents(api.entities.events.manage(null,{items:50}))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (eventsData) {
      setEvents(eventsData)
    }
  },[eventsData])

  return <Page title='Manage events' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Manage Events' navigation={{routes:[]}} >
      {events&&<Fade in={true} timeout={TIME_FADE_IN}>
        <Grid container spacing={5} className={classes.eventList} alignItems={'stretch'}>
          {events.content.map((event,i)=>{
            return event&&<Grid item xs={12} sm={6} key={i}><ConferenceCard list event={event} m={2} manageEvents={true} /></Grid>
          })}
        </Grid>
      </Fade>}
      {events&&events.content.length===0&&<NoManageEvents />}
    </Adrawer>
  </Page>
})

export default withRouter(ManageEvents)
