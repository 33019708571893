import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { palette } from "theme";
import { withRouter } from "react-router-dom"
import { useMetaData } from "components/core/MetaProvider";

import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import Tick from "assets/Tick_icon.svg";
import BottomHero from "components/core/BottomHero";
import CallForPapers from "assets/call-for-papers.mp4";
import CardMedia from "@material-ui/core/CardMedia";
//import { Media } from "docx";

// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = (theme) => ({
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		padding: "30px 20px",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
			//height: "calc(100vh - 64px)",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "1.2em",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "30px",
		},
	},
	subtitle: {
		maxWidth: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "20px",
		textAlign: "center",
		color: "#4a4a4a",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	testimonialsText: {
		width: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "22px",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
		boxShadow: "0px 0px 50px 0px #ddd",
	},
	pageContent: {
		marginTop: "50px",
	},
	homeMiddle: {
		padding: "15px 0px",
		marginTop: "5px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	iconSet: {
		maxWidth: "580px",
		height: "180px",
		//width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "10px 20px 10px 20px",
		margin: "5px 25px 15px",
		textAlign: "left",
		fontFamily: "Karla",
		fontSize: "15px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#858585",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "10px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
		[theme.breakpoints.down("xs")]: {
			height: "fit-content",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	tagTitle: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "36px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "42px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "30px",
			marginBottom: "10px",
			display: "block",
			padding: theme.spacing(3),
		},
	},
	tickIcon: {
		width: "15px",
		lineHeight: "15px",
		margin: "0 4px 0 11px",
	},
	subList: {
		color: "#4a4a4a",
		fontSize: "24px",
		fontFamily: "Karla",
		fontWeight: "normal",
		fontStretch: "normal",
		lineHeight: "normal",
		letterSpacing: "-1.2px",
		margin: "10px 15px 30px 0",
	},
	titleTag: {
		margin: "20px 10px 50px 10px",
		padding: "5px 0px 6px 0",
		fontFamily: "Nunito , Karla",
		fontSize: "36px",
		color: "#000",
		display: "block",
		maxWidth: "620px",
		fontWeight: "600",
		lineHeight: "normal",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			fontSize: "25px",
		},
	},
	video: {
		maxWidth: "960px",
		margin: "5px 15px 50px",
		borderRadius: "20px",
		boxShadow: "0px 0px 50px 0px #ddd",
		[theme.breakpoints.down("xs")]: {
			margin: "5px 0px 50px",
		},
	},
});

const Papers = withStyles(styles)(({ classes, history }) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({ title: "Papers" });
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>
						Accept any type <br />
						of submission
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>There are no limits with Anymeets.</Grid>
				</Grid>
			</Grid>
			<Grid container direction="column" justify="center" alignItems="center">
				<CardMedia
					className={classes.video}
					component="video"
					image={CallForPapers}
					title="Call for Papers"
					controls
				/>
			</Grid>
			<Grid
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				style={{padding: "5px 15px", backgroundColor: "#f0f9ff"}}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid item lg={3} sm={12}>
						<span className={classes.tagTitle}>Accept submissions</span>
					</Grid>

					<Grid container item lg={9} sm={12}>
						<Grid item lg={4}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Abstracts
							</Typography>
						</Grid>
						<Grid item lg={4}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Full papers
							</Typography>
						</Grid>
						<Grid item lg={4}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Poster
							</Typography>
						</Grid>
						<Grid item lg={4}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								E-posters
							</Typography>
						</Grid>
						<Grid item lg={4}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Latex files
							</Typography>
						</Grid>
						<Grid item lg={4}>
							<Typography className={classes.subList}>And more!</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{/* <Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
			>
				<Box>
					<button className={classes.buttonTry}>Try now</button>
				</Box>
			</Grid> */}
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Grid item className={classes.titleTag}>
						Create multiple submission forms to accept different types of
						submissions or submissions for different tracks.
					</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid
						container
						item
						lg={12}
						alignItems="center"
						style={{width: "768px"}}
					>
						<Grid item lg={4} md={6} xs={12} style={{textAlign: "center"}}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Submissions
							</Typography>
							<Paper className={classes.iconSet}>
								<h4>Custom-built forms</h4>
								<p>
									Create a submission form tailored to your needs. Require more
									or less information from your submitters.
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Multiple types of submissions</h4>
								<p>
									Keep separate lists for each submission type, making it easier
									to keep track of submissions.
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Thematic codes</h4>
								<p>
									Allow submitters and reviewers to select thematic codes that
									fit their area of expertise or subject matter.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={4} md={6} xs={12} style={{textAlign: "center"}}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>
								Reviews
							</Typography>
							<Paper className={classes.iconSet}>
								<h4>Reviewer dashboard</h4>
								<p>
									Control the review process with a dedicated reviewer
									dashboard. Automate invitations, reminders and grading.
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Auto and manual assignments</h4>
								<p>
									Match papers to reviewers and papers to sessions based on the
									thematic codes you choose. Make manual adjustments for optimum
									efficiency.
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Evaluation and grading</h4>
								<p>
									Set up your own grading forms, ask for comments, and allow
									reviewers the freedom to make suggestions.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={4} md={6} xs={12} style={{textAlign: "center"}}>
							<Typography className={classes.subList}>
								<img
									src={Tick}
									alt="anymeets tick icon"
									className={classes.tickIcon}
								/>{" "}
								Decisions
							</Typography>
							<Paper className={classes.iconSet}>
								<h4>Decision center</h4>
								<p>
									Make decisions with one click based on the grades received.
									Send emails to groups (accepted, rejected, waitlisted).
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Camera-ready</h4>
								<p>
									Submitters can make updates after acceptance, such as
									uploading final files or giving publishing permissions.
								</p>
							</Paper>
							<Paper className={classes.iconSet}>
								<h4>Exports</h4>
								<p>
									Easily export lists of submissions, submitters, grades,
									decisions etc., and work directly with the data.
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter(Papers);
