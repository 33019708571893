import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "components/core/Button";
import clsx from "clsx";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";
import {format} from "date-fns";
// import SubmissionResponseCardView from 'Components/core/SubmissionResponseCardView'
import EllipsisText from "react-ellipsis-text";
import Chip from "@material-ui/core/Chip";
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";
import parse from "html-react-parser";
import api from "api";
import {useHttp} from "api/core";
import moment from "moment";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = (theme) => ({
  submissionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "12px",
    border: "solid 1px #e6e6e6",
    position: "relative",
    padding: theme.spacing(2),
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  answeredSubmissions: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7,
  },
  button: {
    height: "100%",
    position: "relative",
    paddingTop: theme.spacing(1),
  },
  note: {
    left: 0,
    fontSize: "11px",
    position: "absolute",
    width: "100%",
    bottom: "-5px",
    textAlign: "center",
    color: palette["brown-grey"],
    fontWeight: "bold",
  },
  container: {
    marginBottom: theme.spacing(2),
  },
  priceColor: {
    color: theme.palette.primary.main,
  },
  submissionsBox: {
    height: "calc(100% - 16px)",
    // minHeight: '60px',
    // backgroundColor: palette['pale-sky-blue'],
    borderRadius: "8px",
    border: "1px solid #ddd",
    // boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette["greyish-brown"],
    //cursor: "pointer",
    "&:hover": {
      boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.1)",
    },
  },
  disabled: {
    cursor: "default",
    "&:hover": {
      boxShadow: "none",
    },
  },
  submissionsTitle: {
    color: "black",
    // color: palette['cerulean-blue'],
    fontWeight: "bold",
  },
  submissionsAuthors: {
    fontSize: 12,
    fontWeight: "normal",
  },
  cameraReadyButton: {
    fontSize: "13px",
    lineHeight: "20px",
    backgroundColor: palette.aquamarine,
    maxWidth: "250px",
    height: "35px",
    "&& .MuiButton-label": {
      fontSize: "13px",
      lineHeight: "20px",
    },
  },
  uploadButton: {
    backgroundColor: palette.graphColors[0],
    borderColor: palette.graphColors[0],
    padding: "16px 0px",
    fontWeight: "300",
    color: "#fff",
    "&&:hover": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
      color: "#fff",
    },
    "&&:focus": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
    },
  },
  editingButton: {
    backgroundColor: palette.graphColors[2],
    borderColor: palette.graphColors[2],
    padding: "16px 0px",
    fontWeight: "300",
    color: "#fff",
    "&&:hover": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
      color: "#fff",
    },
    "&&:focus": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
    },
  },
  copyRightButton: {
    backgroundColor: palette.graphColors[1],
    borderColor: palette.graphColors[1],
    padding: "16px 0px",
    fontWeight: "300",
    color: "#fff",
    "&&:hover": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
      color: "#fff",
    },
    "&&:focus": {
      backgroundColor: "#4a4a4a",
      borderColor: "#4a4a4a",
    },
  },
});

const SubmissionBox = withStyles(styles)(({ classes, response, handleOpen, submission }) =>
{
  const [getData, data] = useHttp();

  useEffect(() => {
    getData(api.entities.manage.getSubmissionFlow(null, null, {id: submission.id}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submission]);

    const hasCameraReadyField = data?.submissionForm.fields.find((field) => field.type === 15 )
    const hasCopyrightField = data?.submissionForm.fields.find((field) => field.type === 16);
 
  return (
    <Box className={classes.submissionsBox}>
      <Box className={classes.submissionsTitle} mb={1} p={1}>
        <EllipsisText text={response.title ? response.title : "-"} length={30} />{" "}
        <Box ml={1} style={{float: "right"}}>
          {!!hasCameraReadyField && <Chip variant="outlined" size="small" label={`Upload camera ready file`} className={classes.uploadButton} onClick={() => handleOpen({...response, edit: "upload"})} />}{" "}
          {submission.allowEditing && <Chip variant="outlined" size="small" className={classes.editingButton} label={`Edit submission flow`} onClick={() => handleOpen({...response, edit: "edit"})} />}{" "}
          {!!hasCopyrightField && submission.uploadCopyright && (
            <Chip variant="outlined" size="small" className={classes.copyRightButton} label={`Upload copyright form`} onClick={() => handleOpen({...response, edit: "copyright"})} />
          )}
        </Box>{" "}
      </Box>
      <Box className={classes.submissionsAuthors} pl={1}>
        {response.authors && response.authors.length !== 0 && response.authors.join(",")}
        {response.authors && response.authors.length === 0 && <> - </>}
      </Box>
    </Box>
  );
});

const CameraReadyAcceptedPapers = withStyles(styles)(({classes, handleOpen, event, submission, selectedTimezone, cameraReadyPapers, ...props}) => {

  let willOpen = false;
  let willClose = false;
  let isClosed = false;

  if (submission.cameraReadyEnabled !== 2){    
     willOpen = isFuture(parseISO(submission?.cameraReadyStartDate));
     willClose = isFuture(parseISO(submission?.cameraReadyEndDate)) && isPast(parseISO(submission?.cameraReadyStartDate));
     isClosed = isPast(parseISO(submission?.cameraReadyEndDate));
  } else
  {
     willOpen = isFuture(parseISO(submission?.cameraReadyStartDate));
     willClose = isFuture(parseISO(submission?.cameraReadyEndDate)) && isPast(parseISO(submission?.cameraReadyStartDate));;
     isClosed = false;
  }


  const answers = submission.answers?.map((answers) => answers.answerId);
  const SubmissionAcceptedPapers = cameraReadyPapers?.filter((paper) => paper.submissionFlowId === submission.id && paper.status === 1 && answers.includes(paper.submissionId));
  return (
    <>
      {" "}
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} md={12}>
          <Grid
            item
            container
            spacing={1}
            alignContent="center"
            alignItems="center"
            justify="center"
            className={clsx(classes.submissionBox, submission.answered && classes.answeredSubmissions, (isClosed || willOpen) && classes.notAvailable)}>
            <Grid lg={7} xs={8} item>
              <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
                <Box display="flex" fontSize="20px" fontWeight="bold">
                  {submission.name}
                </Box>
                <Box display="flex" fontSize="16px" mt={1} width={"100%"}>
                  {submission.description}
                </Box>
              </Box>
            </Grid>
            <Grid xs item>
              <Grid item container justify="flex-end" alignContent="center" alignItems="center">
                <Grid item>
                  {submission?.cameraReadyEndDate && language.cameraReady} {submission?.cameraReadyEndDate && language.closesOn}
                  {parse("<br/>")}
                  {submission?.cameraReadyEndDate && format(parseISO(submission?.cameraReadyEndDate), "dd LLL yyyy hh:mm a", dateLocale[event.eventInfo.eventLanguage]) + " (" + moment.tz.guess().replace("_", " ") + ")"}
                  {parse("<br/>")}
                  {submission?.cameraReadyEndDate && selectedTimezone && moment(submission?.cameraReadyEndDate).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"}
                  {/* {willOpen && !willClose && language.opensOn}
                  {parse("<br/>")}
                  {willOpen && !willClose && format(parseISO(submission.cameraReadyStartDate), "dd LLL yyyy hh:mm a", dateLocale[event.eventInfo.eventLanguage]) + " (" + moment.tz.guess().replace("_", " ") + ")"}
                  {parse("<br/>")}
                  {willOpen && !willClose && moment(submission.cameraReadyStartDate).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"} */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container spacing={2} style={{marginTop: 12}}>
              {SubmissionAcceptedPapers?.map((response, i) => {
                // return <SubmissionResponseCardView response={response} index={i} submission={submission} handleOpen={handleOpen} />
                return (
                  <Grid item xs={12}>
                    <SubmissionBox response={response} handleOpen={handleOpen} submission={submission} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
});

export default CameraReadyAcceptedPapers;
