import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {useHttp} from 'api/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import moment from 'moment'
import momentTz from 'moment-timezone'
import clsx from 'clsx'
import RootRef from '@material-ui/core/RootRef'
import {useAuth} from 'auth/AuthController'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {imageFile} from 'utils'
import api from 'api'
import {compareAsc} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import 'emoji-mart/css/emoji-mart.css'
import {Twemoji} from "react-emoji-render"
import InputEmoji from "react-input-emoji";

// import InputEmoji from "react-input-emoji"
// import AttachFileIcon from '@material-ui/icons/AttachFile'
import IconButton from "@material-ui/core/IconButton"
import {useDropzone} from "react-dropzone"
// import CircularProgress from "@material-ui/core/CircularProgress"
// import {IconUploadBorderDark} from "../../icons"
// import Button from "../../Button"
import {Dialog} from "@material-ui/core"
import AttachFileIcon from "@material-ui/icons/AttachFile"
import CancelIcon from '@material-ui/icons/Cancel'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import {UPLOAD_FILE_LIMIT} from "config";
import {useSnackbar} from "notistack";

const styles = theme => ({
  container: {
    borderRadius: 4,
    // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e6e6e6',
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    position: 'relative'
  },
  feed: {
    fontSize: '18px',
    fontWeight: 'bold',
    height: 'calc(100vh - 300px - 48px)',
    padding: 0,
  },
  uploadedMedia: {
    height: 'calc(100vh - 320px - 48px - 100px)',
  },
  feedWithoutTabs: {
    height: 'calc(100vh - 260px)',
  },
  titleBox: {
    border: 'solid 1px #e6e6e6',
    padding:'16px',
    borderRadius: '2px 2px 0px 0px'
  },
  disabled: {
    opacity: 0.5
  },
  chatInput: {
    position: 'relative',
    width: '100%'
  },
  inputElement: {
    border: 'none'
  },
  inputRoot: {
    border: 'none',
    borderTop: 'solid 1px #e6e6e6 !important',
    borderRadius: 0,
  },
  list: {
    color: 'rgba(0,0,0,0.6)'
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.6)'
  },
  message: {
    fontSize: 13,
    color: 'rgba(0,0,0,1)'
  },
  avatarRoot: {
    minWidth: 40
  },
  chatMessage: {
    overflowWap: 'break-word',
    wordWrap: 'break-word',
    msWorBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    mozHyphens: 'auto',
    webkitHyphens: 'auto',
    hyphens: 'auto'
  },
  dropInBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'rgba(0,0,0,0.5)',
    color: 'white',
    zIndex: 1,
    borderRadius: 4
  },
  thumbnail: {
    height: 90,
    width: 'auto',
    borderRadius: 4,
    margin: 4,
    display: 'flex'
  },
  thumbnailInChat: {
    position: 'relative',
    height: 90,
    width: 'auto',
    borderRadius: 4,
    margin: 0,
    display: 'flex'
  },
  thumbnailInChatBox: {
    position: 'relative',
    overflow: 'hidden',
    '&:hover #zoom': {
      opacity: 1
    }
  },
  thumbsBox: {
    marginBottom: 4
  },
  previewIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: 0,
    width: '100%',
    height: '100%',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.2)',
    cursor: 'pointer'
  },
  cancel: {
    position: 'absolute',
    top: 8,
    background: 'white',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    right: 8,
    '&:hover': {
      color: 'black',
      cursor: 'pointer'
    }
  },
  mediaBox: {
    width: '100%',
    height: 90
  },
  mediaLoading: {
    opacity: 0.3
  }
})

const StyledTabsSmall = withStyles({
  root: {
    minHeight: 30,
    fontWeight: 600
  },
  indicator: {
    display: 'none',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTabSmall = withStyles((theme) => ({
  root: {
    minWidth: 'auto',
    minHeight: 30,
    // padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#0077af',
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    // background: '#cdcdcd',
    opacity: 1,
  },
  selected: {
    // borderBottomWidth: 0,
    backgroundColor: 'rgba(0, 119, 175, 0.1)',
    color: '#0077af',
    fontWeight: 600,
    '& $wrapper': {
      opacity: 1,
    },
  },
  wrapper: {
    opacity: 0.7,
    textTransform: 'none'
  }
}))((props) => <Tab disableRipple {...props} />);

const Chat = withStyles(styles)(({classes, eventId, sessionState, clientHandler, messageReceived, setMessageReceived, messageReceivedSpeakers, setMessageReceivedSpeakers, setChatType, chatType}) => {

  const {
    roomId,
    isCoach,
    isViewer,
    isVirtualRoom,
    isOnlinePoster,
    // roomTitle,
    // videoContainerRef
  } = sessionState

  const { enqueueSnackbar } = useSnackbar()

  const [, , , userInfo] = useAuth()
  const [getHistory, historyData, isLoading] = useHttp()
  const [getHistorySpeakers, historyDataSpeakers, isLoadingSpeakers] = useHttp()
  const [previewImage, setPreviewImage] = useState()
  const [uploadedMedia, setUploadedMedia] = useState([])

  //eslint-disable-next-line
  const [postFileMessage, fileMessageData, isPostingFileMessage] = useHttp()

  //eslint-disable-next-line
  const [userId, setUserId] = useState('')
  const [currentPage,setCurrentPage] = useState(1)
  const [currentPageSpeakers,setCurrentPageSpeakers] = useState(1)
  const [totalPages,setTotalPages] = useState(0)
  const [totalPagesSpeakers,setTotalPagesSpeakers] = useState(0)

  const [messageSend, setMessageSend] = useState('')
  const [topReached,setTopReached] = useState(false)
  const [scrollBar, setScrollBar] = useState()
  const [localTimeZone, setLocalTimeZone] = useState()
  const domRef = React.useRef()
  const messageRef = React.useRef()

  const subscriptionEndpoint = `/app/online-room/${roomId}`
  const getChatHistory = chatType===0?api.entities.virtualRoom.getSocialFeedHistory:api.entities.virtualRoom.getSocialFeedHistorySpeakers

  useEffect(() => {
    const timeZone = momentTz.tz.guess()
    const timeZoneDifference = parseInt(moment.tz(timeZone).format('Z'))
    setLocalTimeZone(timeZoneDifference)

    // if (chatType===0) {
    //   getHistory(getChatHistory(null, null, {id: eventId?eventId:sessionId, page:currentPage}))
    // } else {
    //   getHistorySpeakers(getChatHistory(null, null, {id: eventId?eventId:sessionId, page:currentPage}))
    // }

    if (!isLoading&&!isLoadingSpeakers) {
      if (userInfo) {
        setUserId(parseInt(getUserId().sub))
      }
    }
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(()=>{
    if (scrollBar) scrollBar.scrollTop = scrollBar.scrollHeight
    if (chatType===0) {
      getHistory(getChatHistory(null, null, {id: eventId?eventId:roomId, page:currentPage}))
    } else {
      getHistorySpeakers(getChatHistory(null, null, {id: eventId?eventId:roomId, page:currentPageSpeakers}))
    }
    // eslint-disable-next-line
  },[chatType])

  useEffect(() => {
    if (historyData && historyData.content) {
      setTotalPages(historyData.totalPages)
      if(currentPage > 1) {
        const allMessages = [...messageReceived, ...historyData.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceived(allMessages)
        scrollBar.scrollTop = scrollBar.scrollTop + 10
      } else {
        const allMessages = [...historyData.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceived(allMessages)
      }
    }
    // eslint-disable-next-line
  }, [historyData])

  useEffect(() => {
    if (historyDataSpeakers && historyDataSpeakers.content) {
      setTotalPagesSpeakers(historyDataSpeakers.totalPages)
      if(currentPageSpeakers > 1) {
        const allMessages = [...messageReceivedSpeakers, ...historyDataSpeakers.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceivedSpeakers(allMessages)
        scrollBar.scrollTop = scrollBar.scrollTop + 10
      } else {
        const allMessages = [...historyDataSpeakers.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceivedSpeakers(allMessages)
      }
    }
    // eslint-disable-next-line
  }, [historyDataSpeakers])

  // const sendFileMessage = (message, files) => {
  //
  //   let formDataObj = new FormData()
  //
  //   // const payLoad = { message }
  //   // const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'})
  //
  //   // Add message
  //   formDataObj.append('message', message)
  //
  //   // Add files
  //   files.forEach((file)=>formDataObj.append('files',file))
  //
  //   postFileMessage(api.entities.sendFileMessage(formDataObj, null, {id:roomId},true))
  //
  // }

  function decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  function getUserId() {
    return decodeToken(localStorage.getItem('access_token'))
  }

  useEffect(() => {
    if (scrollBar && !topReached)
      scrollBar.scrollTop = scrollBar.scrollHeight
    // eslint-disable-next-line
  }, [messageReceived, messageReceivedSpeakers])

  useEffect(()=>{
    //When user scrolls to top old messages will be fetched
    if(topReached) {
      if (chatType===0) {
        getHistory(getChatHistory(null, null, {id: eventId?eventId:roomId, page:currentPage}))
      } else {
        getHistorySpeakers(getChatHistory(null, null, {id: eventId?eventId:roomId, page:currentPageSpeakers}))
      }
    }
    // eslint-disable-next-line
  },[topReached])


  // const sendMessage = (text) => {
  //   // e.preventDefault()
  //   if (text.length > 0) {
  //     const message = text
  //     if (chatType===0) {
  //       clientHandler.send(subscriptionEndpoint, {}, message)
  //     } else {
  //       clientHandler.send(`/app/online-room/${roomId}/speakers`, {}, message)
  //     }
  //     setMessageSend('')
  //     scrollBar.scrollTop = scrollBar.scrollHeight
  //   }
  // }

  useEffect(()=>{
    if (fileMessageData) {
      setUploadedMedia([])
    }
  },[fileMessageData])

  const sendMessage = (e) => {
    // e.preventDefault()

    if (messageSend.length>255) {
      enqueueSnackbar('Chat message is too long, shorten and retry.')
      return false
    }

    if ((messageSend.length > 0)||uploadedMedia) {
      const message = e

      if (uploadedMedia) {
        let formDataObj = new FormData()

        const blob = new Blob([JSON.stringify({message})], {type: 'application/json'});
        formDataObj.append('data', blob)

        uploadedMedia.forEach((media)=>{
          formDataObj.append('files', media.file)
        })

        if (chatType===0) {
          postFileMessage(api.entities.sessions.sendFileMessageChat(formDataObj, null, {id: roomId}, true))
        } else {
          postFileMessage(api.entities.sessions.sendFileMessageSpeaker(formDataObj, null, {id: roomId}, true))
        }

      } else {
        if (chatType===0) {
          clientHandler.send(subscriptionEndpoint, {}, message)
        } else {
          clientHandler.send(`/app/online-room/${roomId}/speakers`, {}, message)
        }
      }

      setMessageSend('')

      scrollBar.scrollTop = scrollBar.scrollHeight
    }
  }

  // const messageHandler = (event) => {
  //   setMessageSend(event.target.value)
  // }

  const handleScrollRef = (ref) => {
    setScrollBar(ref)
  }
  const handleOnScroll = (ref) => {
    if(ref.scrollTop === 0)
    {
      //new messages will be fetched from history only if there is another page with data
      if ((chatType===0)&&currentPage !== totalPages)
      {
        setCurrentPage(currentPage+1)
        setTopReached(true)
      }
      if ((chatType===1)&&currentPageSpeakers !== totalPagesSpeakers&&totalPagesSpeakers>currentPageSpeakers)
      {
        setCurrentPageSpeakers(currentPageSpeakers+1)
        setTopReached(true)
      }
    }
    else
      setTopReached(false)
  }

  const toggleChatType = (event,value) => {
    setChatType(value)
  }

  const onDrop = (acceptedFiles) => {

    const uploadedFiles = [...uploadedMedia]
    let totalSize = uploadedMedia.reduce((sum, value)=> sum+value.file.size,0)

    acceptedFiles.forEach((file)=>{

      if (file.size>UPLOAD_FILE_LIMIT) {
        enqueueSnackbar(`File ${file.name} omitted, exceeds file size upload limit of ${UPLOAD_FILE_LIMIT/1000000}MB`, {variant:'error'})
        return
      }

      if (totalSize+file.size>UPLOAD_FILE_LIMIT) {
        enqueueSnackbar(`File ${file.name} omitted, exceeded file size upload limit of ${UPLOAD_FILE_LIMIT/1000000}MB`, {variant:'error'})
        return
      }

      uploadedFiles.push({
        file: file,
        base64: null
      })
    })

    setUploadedMedia(uploadedFiles)

  }

  const removeMedia = (index) => {
    const alreadyUploaded = [...uploadedMedia]
    alreadyUploaded.splice(index,1)
    setUploadedMedia(alreadyUploaded)
  }

  useEffect(()=>{
    uploadedMedia.filter((f)=>f.base64===null).forEach((fileObj)=>{
      const fr = new FileReader()
      const fileIndex = uploadedMedia.findIndex((f=>f.file===fileObj.file))
      fr.readAsDataURL(fileObj.file)

      fr.onload = function(e) {
        const alreadyUploaded = [...uploadedMedia]
        alreadyUploaded[fileIndex].base64 = e.target.result
        setUploadedMedia(alreadyUploaded)
      }
    })
  },[uploadedMedia])

  const showTabs = ((!isViewer)||isCoach)&&!isOnlinePoster&&!isVirtualRoom
  const messages = chatType===0?messageReceived:messageReceivedSpeakers

  // eslint-disable-next-line
  const {getRootProps, getInputProps, isDragActive, open} = useDropzone({onDrop, noClick:true, noKeyboard: true});
  const {ref, ...rootProps} = getRootProps()

  return <>
    <RootRef rootRef={domRef}>
      <Grid item container
            className={clsx(classes.container)} >

        {showTabs&&<Grid item container spacing={2} justify='center' alignContent='center' alignItems='center' style={{marginTop: 16, marginBottom: 8}}>
          <Grid item style={{minWidth:130}}>
            <StyledTabsSmall
              variant='fullWidth'
              value={chatType}
              onChange={toggleChatType}
              scrollButtons='auto'
              // disabled={isLoadingSetAuto||isLoadingDelAuto||(sessionAuto!==session.status.auto)}
            >
              <StyledTabSmall label={`${isVirtualRoom?'Room':'Session'}`} value={0}/>
              <StyledTabSmall label='Speakers' value={1}/>
            </StyledTabsSmall>
          </Grid>
        </Grid>}

        <Grid item xs={12}>
          <PerfectScrollbar containerRef={handleScrollRef} onScrollY={handleOnScroll}>
            <Box className={clsx(classes.feed,!!uploadedMedia.length&&classes.uploadedMedia,(!showTabs)&&classes.feedWithoutTabs)} >
              <List dense>
                {messages.map((msgObjReceived,i) => {
                  return (

                    <RootRef rootRef={messageRef}  >

                      <ListItem alignItems="flex-start" className={classes.list}  key={msgObjReceived.message.id}>
                        <ListItemAvatar classes={{root:classes.avatarRoot}}>
                          <Avatar alt={msgObjReceived.sender.name} src={imageFile(msgObjReceived.sender.imageId ? msgObjReceived.sender.imageId : '')} style={{width:24,height:24}} />
                        </ListItemAvatar>
                        <ListItemText classes={{primary:classes.userName,secondary:classes.message}}
                                      primary={<>
                                        <Grid item container justify={'space-between'} alignItems='center' alignContent='center'>
                                          <Grid item >
                                            {msgObjReceived.sender.name}
                                          </Grid>
                                          <Grid item>
                                            <Box fontSize={10} style={{opacity:0.7}}>
                                              {moment(msgObjReceived.message.sentAt).add(localTimeZone,'hours').format('HH:mm')}
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      </>}
                                      secondary={<Box className={classes.chatMessage}>

                                        {!!msgObjReceived.message.files&&<Grid item container spacing={1} className={classes.thumbsBox}>
                                        {msgObjReceived.message.files.map((file)=>{
                                          return <Grid item className={classes.thumbnailInChatBox}>
                                              <Box position={'relative'}>
                                                <img src={imageFile(file.id)} className={classes.thumbnailInChat} alt={file.filename} />
                                                <Box display='flex' alignContent='center' alignItems='center' justifyContent='center' className={classes.previewIcon} id={'zoom'} onClick={()=>setPreviewImage(imageFile(file.id))} >
                                                  <ZoomInIcon fontSize='large' />
                                                </Box>
                                              </Box>
                                            </Grid>
                                        })}
                                        </Grid>}

                                        <Twemoji text={msgObjReceived.message.message} />

                                      </Box>
                                      }

                        />
                      </ListItem>
                      {i!==messages.length-1&&<Divider />}
                    </RootRef>

                  )
                })}
              </List>
            </Box>
          </PerfectScrollbar>
        </Grid>
        <Grid item className={classes.chatInput} {...rootProps} key={uploadedMedia}>
          {/*<TakeScreenshot screenRef={videoContainerRef} filename={roomTitle}/>*/}

          {uploadedMedia.length!==0&&<Box height={120} width='100%' className={clsx(isPostingFileMessage&&classes.mediaLoading)}><PerfectScrollbar><Grid item container classNames={clsx(classes.mediaBox)}>{uploadedMedia.map((media,i)=>
            <Grid item style={{position:'relative'}}>
              {media.base64&&<img src={media.base64} className={classes.thumbnail} alt={'Uploaded media'}/>}
              <Box className={classes.cancel} onClick={()=>removeMedia(i)}><CancelIcon /></Box>
            </Grid>

          )}</Grid></PerfectScrollbar></Box>}

          <RootRef rootRef={ref}>
            <Box display='flex' flexDirection='row' alignItems='center' alignContent='center' justifyContent={'flex-end'}>
              <Box {...rootProps}>
                <input name={'files'} type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
                    <IconButton onClick={open} disabled={isPostingFileMessage}>
                      <AttachFileIcon />
                    </IconButton>
              </Box>
              {isDragActive&&<Grid item container className={classes.dropInBox} alignContent={'center'} alignItems={'center'} justify={'center'} direction={'column'}>
                <Grid item>
                  <AttachFileIcon disabled={isPostingFileMessage} />
                </Grid>
                <Grid item>
                  Drop files here!
                </Grid>
              </Grid>}
            </Box>
          </RootRef>

          {/*<form onSubmit={sendMessage}>*/}
          {/*  <OutlinedInput disabled={isPostingFileMessage} classes={{input:classes.inputElement,notchedOutline:classes.inputRoot}} variant='filled' fullWidth placeholder={'Type a message...'} endAdornment={*/}
          {/*    <InputAdornment position='end'>*/}
          {/*      <IconButton*/}
          {/*          disabled={isPostingFileMessage}*/}
          {/*          aria-label='send message'*/}
          {/*          onClick={sendMessage}*/}
          {/*          edge='end'*/}
          {/*      >*/}
          {/*        <SendIcon/>*/}
          {/*      </IconButton>*/}
          {/*    </InputAdornment>*/}
          {/*  } onChange={messageHandler}*/}
          {/*    value={messageSend}*/}
          {/*   inputProps={{*/}
          {/*     maxLength: 500*/}
          {/*   }}*/}
          {/*  />*/}
          {/*</form>*/}

          <InputEmoji
              value={messageSend}
              onChange={setMessageSend}
              disabled={isPostingFileMessage}
              cleanOnEnter
              onEnter={sendMessage}
              placeholder="Type a message..."
              emojiSet='twitter'
          />

          <Dialog open={!!previewImage} onClose={()=>setPreviewImage(false)}><img src={previewImage} alt={'Uploaded media file'}/></Dialog>

        </Grid>
      </Grid>
    </RootRef>
  </>
})


export default Chat
