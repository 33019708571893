import {withStyles} from "@material-ui/core/styles";
import {useParams, withRouter} from "react-router-dom";
import React, {forwardRef, useEffect, useMemo, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import {useHttp} from "api/core";
import api from "api";
import {palette} from "theme";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import UserJoinedCard from "components/core/UserJoinedCard";
import Pagination from "@material-ui/lab/Pagination";
import Button from "components/core/Button";
import AnyTabs from "components/core/AnyTabs";
import {CURRENCY} from "config";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import {IconAddBorderBlue, IconUploadBorderDark} from "components/core/icons";
import Dialog from "@material-ui/core/Dialog";
import AttendeesAddNew from "components/core/AttendeesAddNew";
import AttendeesBulkImport from "components/core/AttendeesBulkImport";
import {useDropzone} from "react-dropzone";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import {format} from "date-fns";
import parseISO from "date-fns/parseISO";
import Switch from "@material-ui/core/Switch";

const styles = (theme) => ({
  attendeesContainer: {
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "1200px",
  },
  noAttendeesYet: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "145px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  fieldTitle: {
    fontWeight: "bold",
  },
  header: {
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  addBtn: {
    textAlign: "right",
  },
  navTabs: {
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  downloadBtn: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(3),
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  totalStats: {
    textAlign: "center",
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2, 2, 4, 2),
    borderRadius: "6px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "1000px",
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const AllListing = withStyles(styles)(({classes, attendees, totals, handleChange, handleChangeAttendeeStatus, hideInfo, isLoading, registered, attended}) => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoader(false), 500);
  }, []);

  if (isLoading || loader) {
    return (
      <Box position="relative" minHeight={300} justifyContent="center" alignContent="center" alignItems="center" display="flex">
        <Box display="flex">
          <CircularProgress size={"8rem"} color="secondary" />
        </Box>
      </Box>
    );
  }

  return (
    <Box position="relative" minHeight={300}>
      <Grid container item spacing={2} className={classes.header}>
        <Grid item xs={12} sm={3} md={3}>
          <Grid item xs={12} className={classes.fieldTitle}>
            {" "}
            Name{" "}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <Grid item xs={12} className={classes.fieldTitle}>
            {" "}
            Email{" "}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <Grid item xs={12} className={classes.fieldTitle}>
            {" "}
            Joined at{" "}
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid item xs={12} className={classes.fieldTitle}>
            <Grid item container justify="center">
              <Grid item>Attendees</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid item xs={12} className={classes.fieldTitle}>
            <Grid item container justify="center">
              <Grid item>Tickets</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid item xs={12} className={classes.fieldTitle}>
            <Grid item container justify="center">
              <Grid item>Total paid</Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <Grid item xs={12} className={classes.fieldTitle}>
            <Grid item container justify="center">
              <Grid item>Checked in</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {attendees &&
        attendees.content.map((user, i) => {
          return <UserJoinedCard user={user} index={i} hideInfo={hideInfo} registered={registered} handleChangeAttendeeStatus={handleChangeAttendeeStatus} />;
        })}

      {attendees && attendees.content.length === 0 ? (
        <Box display="flex" height={130} className={classes.noAttendeesYet}>
          No attendees
        </Box>
      ) : (
        <>
          {/*<Grid container justify='flex-end' className={classes.totals}>*/}
          {/*  <Grid item container xs={12} sm={3} md={3} spacing={2}>*/}
          {/*    <Grid item xs={8}>*/}
          {/*      Total attendees:*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs={4}>*/}
          {/*      {totals.totalAttendees&&<Box fontWeight='bold' align='right'> {totals.totalAttendees}</Box>}*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs={8}>*/}
          {/*      Total tickets:*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs={4}>*/}
          {/*      {totals.totalTickets&&<Box fontWeight='bold' align='right'> {totals.totalTickets} </Box>}*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs={8}>*/}
          {/*      Total amount:*/}
          {/*    </Grid>*/}
          {/*    <Grid item xs={4}>*/}
          {/*      {totals.ticketsValue!==null&&<Box fontWeight='bold' align='right'> ${totals.ticketsValue} </Box>}*/}
          {/*    </Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
          <Box pt={4} display="flex" flexDirection="row" justifyContent="flex-end">
            <Box display="flex">
              <Pagination hideNextButton={attendees.last} hidePrevButton={attendees.first} count={attendees.totalPages} page={attendees.pageable.pageNumber + 1} onChange={handleChange} />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
});

const Attendees = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {
  // Hooks
  const [getData, data, isLoading] = useHttp();
  const [getAllAttendees, allAttendees] = useHttp();
  const [getCSV, dataCSV] = useHttp();
  const [getTickets, dataTickets] = useHttp();
  const [putData, dataPut] = useHttp();
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false);
  // State
  const [attendees, setAttendees] = useState();
  const [checkInAttendees, setCheckInAttendees] = useState();
  const [CsvData, setCsvData] = useState();
  const [tickets, setTickets] = useState();
  const [registered, setRegistered] = useState(false);
  const [totals, setTotals] = useState();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);

  // Deps
  const {eventId} = useParams();
  useEffect(() => {
    getTickets(api.entities.manage.getTickets(null, null, {id: eventId}));
    getData(api.entities.manage.getAttendees(null, {page: 1, registered}, {id: eventId}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (registered !== null) {
      getAllAttendees(api.entities.manage.getAllAttendees(null, {registered}, {id: eventId}));
    }
    // eslint-disable-next-line
  }, [registered]);

  useEffect(() => {
    if (dataTickets && dataTickets.tickets) {
      setTickets(dataTickets.tickets);
    }
  }, [dataTickets]);

  useEffect(() => {
    if (data) {
      setTotals({...totals, ticketsValue: data.ticketsValue, totalAttendees: data.totalAttendees, totalTickets: data.totalTickets});
      setPageTitle(data.eventInfo.name);
      setNavBack({path: "/manage-events", name: "events"});
      setEventInfo(data.eventInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, setEventInfo, setNavBack, setPageTitle]);

  useEffect(() => {
    if (allAttendees) {
      setAttendees(allAttendees.attendees);
      if (!registered) {
        const attended = allAttendees.attendees.filter((user) => user.eventRegistrationStatus === true);
        setTotals({...totals, totalCheckIn: attended.length});
        setCheckInAttendees(attended);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAttendees]);

  const handleCheckedAttendees = () => {
    setAttendees(checkInAttendees);
  };

  const handleChangeAttendeeStatus = (user, index) => {
    if (user.eventRegistrationStatus === false) {
      putData(api.entities.manage.addUserAttendeed(null, null, { id: eventId, userId: user.id }));
      let newUser = {...user, eventRegistrationStatus: true}    
      const newUsers = [...checkInAttendees, newUser];
      setCheckInAttendees(newUsers);
      setTotals({...totals, totalCheckIn: totals.totalCheckIn + 1});
    } else if (user.eventRegistrationStatus === true) {
      putData(api.entities.manage.removeUserAttendeed(null, null, { id: eventId, userId: user.id }));
      // let newUser = {...user, eventRegistrationStatus: false};   
      const newCheckedUsers = checkInAttendees?.filter((users) => users.id !== user.id);
      setCheckInAttendees(newCheckedUsers);
      setTotals({...totals, totalCheckIn: totals.totalCheckIn - 1});
    }
    const newUser = attendees.map((obj) => {
      if (obj.id === user.id) {
        return {...obj, eventRegistrationStatus: !user.eventRegistrationStatus};
      }
      return obj;
    });
    setAttendees(newUser);
  };

  const downloadCSV = () => {
    getCSV(api.entities.manage.downloadCSV(null, null, {id: eventId}));
  };

  useEffect(() => {
    if (dataCSV) {
      setCsvData(dataCSV);
    }
  }, [dataCSV]);

  useEffect(() => {
    if (CsvData) {
      const csv = `data:text/csv;charset=utf-8,${dataCSV[0]}`;
      let csvURI = encodeURI(csv);
      let downloadLink = document.createElement("a");
      downloadLink.href = csvURI;
      downloadLink.download = `${data.eventInfo.name} - attendees.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setCsvData(null);
    }
  }, [CsvData, data, dataCSV]);

  const handleClose = (shouldReload) => {
    setOpenAddNew(false);
    if (shouldReload) {
      getData(api.entities.manage.getAttendees(null, {page: 1, registered}, {id: eventId}));
    }
  };

  const handleCloseBulk = (shouldReload) => {
    setOpenBulk(false);
    if (shouldReload) {
      getData(api.entities.manage.getAttendees(null, {page: 1, registered}, {id: eventId}));
    }
  };

  const showRegistered = (show) => {
    setRegistered(show);
  };

  const [fileData, setFileData] = useState([]);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) return false;

    setFileData(file);
    setOpenBulk(true);
    console.log(file);
  };

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();

  if (!attendees) return <> </>;

  return (
    <>
      <Fade in={true} timeout={1200}>
        <>
          <Grid container spacing={2} className={classes.attendeesContainer}>
            <Grid item xs={12} sm={12} md={3}>
              <Typography component="h2" variant="h5" className={classes.title}>
                Attendees
              </Typography>
            </Grid>

            <Grid item xs>
              <Grid item container justify="flex-end" spacing={2}>
                <Grid item>
                  <Chip label={`Attendees ${totals?.totalAttendees}`} variant="outlined" />
                </Grid>

                <Grid item>
                  <Chip label={`Total Tickets ${totals?.totalTickets}`} variant="outlined" />
                </Grid>

                <Grid item>
                  <Chip label={`Total Amount ${CURRENCY} ${totals?.ticketsValue}`} variant="outlined" />
                </Grid>
                {allAttendees && (
                  <Grid item>
                    <Chip label={`Total Checked in ${totals?.totalCheckIn}`} variant="outlined" />
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid item>
              <Button
                size="smaller"
                // upgradeTier={eventInfo.upgraded === false}
                onClick={() => {
                  // if (eventInfo.upgraded === false) return false;
                  downloadCSV();
                }}>
                {" "}
                Download CSV{" "}
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Grid container item className={classes.navTabs}>
                <Grid item xs={12} sm={12}>
                  <AnyTabs
                    hidePanel="true"
                    tabs={[
                      {
                        label: "All",
                        onClick: () => {
                          showRegistered(false);
                        },
                      },
                      {
                        label: "Registered",
                        onClick: () => {
                          showRegistered(true);
                        },
                      },
                      {
                        label: "Checked in",
                        onClick: () => {
                          showRegistered(null);
                          handleCheckedAttendees();
                        },
                      },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MaterialTable
                    icons={tableIcons}
                    columns={[
                      {
                        title: "Name",
                        field: "firstName",
                        width: "200",
                        render: (row) => (
                          <>
                            {row.firstName} {row.lastName}
                          </>
                        ),
                      },
                      {
                        title: "Name",
                        field: "lastName",
                        hidden: true,
                        searchable: true,
                        render: (row) => <>{row.lastName}</>,
                      },
                      {title: "Email", field: "email"},
                      {
                        title: "Joined at",
                        field: "joinedAt",
                        type: "datetime",
                        format: "LLL do | hh:mmaa",
                        cellStyle: {
                          cellWidth: "300",
                        },
                        render: (row) => <>{format(parseISO(row.joinedAt), "LLL do yyyy | hh:mmaa")}</>,
                      },
                      {title: "Attendees", field: "attendees"},
                      {title: "Tickets", field: "totalTickets"},
                      {title: "Total paid", field: "totalPrice"},
                      {
                        title: "Checked in",
                        field: "eventRegistrationStatus",
                        render: (row) => (
                          <>
                            <Switch inputProps={{"aria-label": "true/false"}} checked={row.eventRegistrationStatus} onChange={() => handleChangeAttendeeStatus(row)} />
                          </>
                        ),
                      },
                    ]}
                    data={attendees}
                    title=""
                    style={{
                      border: "none",
                      boxShadow: "none",
                    }}
                    options={{
                      pageSize: window.innerHeight > 1200 ? 15 : 10,
                      filtering: false,
                      actionsColumnIndex: 999,
                      addRowPosition: "first",
                      headerStyle: {
                        backgroundColor: palette["cerulean-blue"],
                        color: "#FFF",
                        fontWeight: "bold",
                        fontSize: 14,
                        padding: 10,
                        // borderRadius: 4
                      },
                      rowStyle: {
                        fontSize: 12,
                        padding: 5,
                        borderRadius: 4,
                      },
                      cellStyle: {
                        padding: "0 10px",
                      },
                    }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <AnyTabs
                    tabs={[
                      {
                        label: "All",
                        component: (
                          <AllListing
                            totals={totals}
                            registered={registered}
                            isLoading={isLoading}
                            attendees={attendees}
                            handleChange={handleChange}
                            hideInfo={false}
                            handleCheckedAttendees={handleCheckedAttendees}
                            handleChangeAttendeeStatus={handleChangeAttendeeStatus}
                          />
                        ),
                        onClick: () => {
                          showRegistered(false);
                        },
                      },
                      {
                        label: "Registered",
                        component: (
                          <AllListing
                            totals={totals}
                            registered={registered}
                            isLoading={isLoading}
                            attendees={attendees}
                            handleChange={handleChange}
                            hideInfo={false}
                            handleCheckedAttendees={handleCheckedAttendees}
                            handleChangeAttendeeStatus={handleChangeAttendeeStatus}
                          />
                        ),
                        onClick: () => {
                          showRegistered(true);
                        },
                      },
                      {
                        label: "Checked in",
                        component: (
                          <AllListing
                            totals={totals}
                            // registered={registered}
                            isLoading={isLoading}
                            attendees={attendees}
                            handleCheckedAttendees={handleCheckedAttendees}
                            handleChangeAttendeeStatus={handleChangeAttendeeStatus}
                            hideInfo={false}
                          />
                        ),
                        onClick: () => {
                          handleCheckedAttendees(1);
                        },
                      },
                    ]}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" pt={3}>
                    <Button
                      variant="outlined"
                      icon={<IconAddBorderBlue />}
                      size="small"
                      color="primary"
                      onClick={() => {
                        setOpenAddNew(true);
                      }}>
                      Add Attendee
                    </Button>
                    <Box {...rootProps} className={classes.dropBox} ml={1}>
                      <input type="file" {...getInputProps()} accept=".xls,.xlsx" />
                      <Button variant="outlined" icon={<IconUploadBorderDark />} size="small" color="primary">
                        Import from file
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {/*<Box className={classes.downloadBtn} >*/}
              {/*  <Button size='smaller'  upgradeTier={!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)} onClick={() => {*/}
              {/*    if (!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)) return false*/}
              {/*    downloadCSV()*/}
              {/*  }}> Download CSV </Button>*/}
              {/*</Box>*/}
            </Grid>
          </Grid>
          {/*<Grid container spacing={2} className={classes.totalStats}>*/}
          {/*  <Grid item xs={12}>*/}
          {/*    <Typography component='h2' variant='h5' className={classes.title} align='left'>*/}
          {/*      Totals*/}
          {/*    </Typography>*/}
          {/*  </Grid>*/}
          {/*  <Grid item container>*/}
          {/*    <Grid item xs> Attendees </Grid>*/}
          {/*    <Grid item xs> Tickets </Grid>*/}
          {/*    <Grid item xs> Amount </Grid>*/}
          {/*  </Grid>*/}
          {/*  <Grid item container>*/}
          {/*    <Grid item xs>*/}
          {/*      <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalAttendees} </Typography>*/}
          {/*      </Grid>*/}
          {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalTickets} </Typography></Grid>*/}
          {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>${totals.ticketsValue} </Typography></Grid>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </>
      </Fade>
      <Dialog onClose={handleClose} open={openAddNew} maxWidth={"md"}>
        <AttendeesAddNew handleClose={handleClose} eventId={eventId} eventInfo={data?.eventInfo} setSuccessful={setSuccessful} tickets={tickets} uploadFiles={true} />
      </Dialog>
      <Dialog onClose={handleCloseBulk} open={openBulk} fullWidth maxWidth="xl" classes={{paper: classes.dialogPaper}}>
        <AttendeesBulkImport handleClose={handleCloseBulk} eventId={eventId} eventInfo={data?.eventInfo} setSuccessful={setSuccessful} tickets={tickets} file={fileData} />
      </Dialog>
    </>
  );
});

export default withRouter(Attendees);
