import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Form, Formik} from 'formik'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {withRouter} from 'react-router-dom'
import {useHttp} from 'api/core'
import api from 'api'
import {SIZE_COVER, SIZE_LOGO, TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import UploadImage from 'components/core/UploadImage'

const styles = theme => ({
  title: {
    color: 'white',
    textAlign: 'center'
  },
  form: {
    width: '100%',
    color: 'white'
  },
  note: {
    fontSize: '12px'
  },
  dropBox: {
    backgroundColor: 'white',
    borderRadius: '6px',
    color: palette['greyish-brown'],
    overflow: 'hidden'
  },
  hideMobile: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  showMobile: {
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  uploaders: {
    paddingTop: theme.spacing(3)
  },
  uploaderTitle: {
    textAlign: 'left',
    fontWeight: 'bold'
  }
})

const CreateEventImages = withStyles(styles)(({classes, formData, setFormData, setEvent, prevRoute, nextRoute, history}) => {
  // const [putRequest, putData] = useHttp()
  //
  // useEffect(() => {
  //   if (putData) {
  //     setEvent(putData)
  //   }
  // }, [putData, setEvent])

  const [templateBackgrounds, setTemplateBackgrounds] = useState()
  const [getTemplateBackgrounds, backgroundsData] = useHttp()
  const [templateLogos, setTemplateLogos] = useState()
  const [getTemplateLogos, logosData] = useHttp()

  useEffect(()=>{
    getTemplateBackgrounds(api.entities.options.getBackgrounds())
  },[getTemplateBackgrounds])

  useEffect(()=>{
    if (backgroundsData) {
      setTemplateBackgrounds(backgroundsData.content)
    }
  },[backgroundsData])

  useEffect(()=>{
    getTemplateLogos(api.entities.options.getLogos())
  },[getTemplateLogos])

  useEffect(()=>{
    if (logosData) {
      setTemplateLogos(logosData.content)
    }
  },[logosData])

  if (!backgroundsData||!logosData) return <></>

  return <Fade in={true} timeout={TIME_FADE_IN}>
    <Formik
      initialValues={formData}
      // validationSchema={Schema}
      onSubmit={(values, {setSubmitting}) => {

        setFormData({...formData, ...values, logo:formData.logo, cover:formData.cover})
        history.push(nextRoute)

        // let formDataObj = new FormData();
        //
        // const {logo, cover, ...formDataItems} = {...formData, ...values, logo:formData.logo, cover:formData.cover}
        //
        // if (logo) {
        //   formDataObj.append('logo', logo)
        // }
        //
        // if (cover) {
        //   formDataObj.append('cover', cover)
        // }
        //
        // if (formDataItems.mailList.name==='') {
        //   formDataItems.mailList = null
        // }
        //
        // const blob = new Blob([JSON.stringify(formDataItems)], {type: 'application/json'});
        // formDataObj.append('event', blob)
        //
        // putRequest(api.entities.events.addNew(formDataObj, null, null, true))

      }}>
      {({submitForm, errors}) => (<>
          <Box className={classes.hideMobile}>
            <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
          </Box>
        <Fade in={true} timeout={TIME_FADE_IN}><Form className={classes.form}>
          <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={4} minHeight={window.innerHeight-280}>
            <Box display='flex' width='600px' flexDirection='column' pb={8}>
              <Typography component='h2' variant='h5' className={classes.title}>Event Images</Typography>


              <Grid container spacing={2} className={classes.uploaders}>
                <Grid item xs={12} md={4}>
                  <Typography component='h2' variant='h6' className={classes.uploaderTitle}>Logo Image</Typography>
                  <Box className={classes.note} pt={1}>Image should be at least {SIZE_LOGO.width}x{SIZE_LOGO.height}px</Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <UploadImage images={templateLogos} setFormData={setFormData} formData={formData} name={'Logo'} deleteBtn={false} color={'white'}
                               size={SIZE_LOGO} fieldName={'logo'} existingFieldName={'logo'} placeholderSizer={1} />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Typography component='h2' variant='h6' className={classes.uploaderTitle}>Cover Image</Typography>
                  <Box className={classes.note} pt={1}>Image should be at least {SIZE_COVER.width}x{SIZE_COVER.height}px</Box>
                </Grid>

                <Grid item xs={12} md={8}>
                  <UploadImage images={templateBackgrounds} setFormData={setFormData} formData={formData} name={'Cover Image'} deleteBtn={false} color={'white'}
                               size={SIZE_COVER} fieldName={'cover'} existingFieldName={'cover'} placeholderSizer={3} />
                </Grid>
              </Grid>

            </Box>
          </Box>
          <Box className={classes.showMobile}>
            <CreateEventNavigation submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
          </Box>
        </Form></Fade></>
      )}
    </Formik>
  </Fade>
})

export default withRouter(CreateEventImages)
