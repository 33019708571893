import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import Page from 'components/core/Page'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import { ReactComponent as NotFoundGraphic } from 'assets/404.svg'
import Button from 'components/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  message : {
    fontWeight: 'bold',
    color: palette['greyish-brown'],
  }
})

const NotFound = withStyles(styles)(({classes, history}) => {
  
  return <Page title='Oops! Page not found' display='flex' flexDirection='column' justifyContent='center' alignItems='center' pt={9}>
    <Box>
      <NotFoundGraphic/>
    </Box>
    <Box pt={8} className={classes.message}>
      <Typography variant='h5'>Oops… You seem to be lost in space!</Typography>
    </Box>
    <Box p={3} className={classes.message}>
      <Typography> Don’t worry, we’ll show you the way out. </Typography>
    </Box>
    <Box p={3} pt={6}>
      <Button size='small' color='primary' onClick={()=>history.push('/')}>Back to Homepage</Button>
    </Box>
  </Page>
})

export default withRouter(NotFound)
