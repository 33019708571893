import React from 'react'
import {withStyles} from '@material-ui/core'
import ButtonBase from '@material-ui/core/ButtonBase'
import {useSnackbar} from 'notistack'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {IconShareBorderGreen} from 'components/core/icons'

const styles = theme => ({
  iconColor: {
    '& > svg > g > g': {
      stroke: theme.palette.secondary.main
    }
  }
})

const ShareButton = withStyles(styles)(({classes,shareLink})=>{
  const {enqueueSnackbar} = useSnackbar()
  const handleShareLink = () => {
    enqueueSnackbar(`Link ${shareLink} was copied to the clipboard`, {variant: 'success'});
  }

  return <CopyToClipboard text={shareLink} onCopy={handleShareLink}>
    <ButtonBase className={classes.iconColor} centerRipple> <IconShareBorderGreen/> </ButtonBase>
  </CopyToClipboard>
})

export default ShareButton
