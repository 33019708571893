import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import {Redirect, useParams, withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import NoTickets from 'components/placeholders/NoTickets'
import moment from 'moment'
import MyTicket from 'components/pages/MyTickets/MyTicket'

const styles = theme => ({
  ticketsList: {
    maxWidth: '1280px'
  }
})

const MyTickets = withStyles(styles)(({classes, history}) => {

  const [tickets, setTickets] = useState([])
  const [futureTickets, setFuture] = useState([])
  const [currentTickets, setCurrent] = useState([])
  const [pastTickets, setPast] = useState([])
  const [getTickets, ticketsData, ticketsLoading] = useHttp()

  const {ticketType} = useParams()

  useEffect(() => {

    getTickets(api.entities.registrations.getAllTickets())
// eslint-disable-next-line
  }, [])

  const navigation = {
    routes: [
      {
        name: 'Future',
        path: 'future'
      },
      {
        name: 'Current',
        path: 'current'
      },
      {
        name: 'Past',
        path: 'past'
      }
    ]
  }

  useEffect(() => {
    const currentDate = moment().toISOString()
    let futureTemp = [...futureTickets]
    let currentTemp = [...currentTickets]
    let pastTemp = [...pastTickets]
    if (ticketsData) {
      ticketsData.forEach((ticket) => {
        if (moment(currentDate).isBefore(ticket.startDate))
          futureTemp.push(ticket)
        else if (moment(currentDate).isAfter(ticket.startDate))
          pastTemp.push(ticket)
        else currentTemp.push(ticket)
      })
      setFuture(futureTemp)
      setPast(pastTemp)
      setCurrent(currentTemp)
    }
    // eslint-disable-next-line
  }, [ticketsData])

  useEffect(() => {
    switch (ticketType) {
      case 'future':
        setTickets(futureTickets)
        break
      case 'current':
        setTickets(currentTickets)
        break
      case 'past':
        setTickets(pastTickets)
        break
      default:
    }
    // eslint-disable-next-line
  }, [ticketType, futureTickets, currentTickets, pastTickets])

  return <Page title='My tickets' display='flex' backgroundColor={palette['pale-grey']}>
    {!ticketType && <Redirect to={'my-tickets/current'}/>}
    <Adrawer title='My tickets' mobileTitle='My tickets' navigation={navigation}>
      {!ticketsLoading && tickets.length === 0 && <NoTickets/>}
      <Grid container justify={'flex-start'} alignItems={'flex-start'} direction={'row'} spacing={2} className={classes.ticketsList}>
        {tickets && tickets.map((ticket) => {
          return <Grid item xs={12} sm={12} md={12} lg={6} xl={6} key={ticket.id}>
            <MyTicket ticketInfo={ticket}/>
          </Grid>
        })}
      </Grid>
    </Adrawer>
  </Page>
})

export default withRouter(MyTickets)
