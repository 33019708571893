import React from "react"
import { ReactComponent as NoManageEvent } from 'assets/placeholders/no_events.svg'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from 'components/core/Button'
import {TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import {Link, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'

const styles = theme => ({
  note: {
    color: palette['greyish-brown']
  }
})

const NoManageEvents = withStyles(styles)(({classes}) => (<Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' height='100%' alignContent='center' justifyContent='center' justifyItems='center' alignItems='center' flexDirection='column'>

    <Box display='flex'><NoManageEvent/></Box>

    <Box display='flex' pt={2}>
      <Typography variant={'h6'}>No events yet</Typography>
    </Box>

    <Box display='flex' pt={1} className={classes.note}>

    </Box>

    {/* <Box display='flex' pt={3}>
      <Button variant='outlined' color='primary' component={Link} to={'/select-package'}>Create event</Button>
    </Box> */}

  </Box></Fade>
))

export default withRouter(NoManageEvents)
