import React from "react"
import { ReactComponent as NoEvent } from 'assets/placeholders/no_event.svg'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Button from 'components/core/Button'
import {TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import {Link} from 'react-router-dom'


const NoEvents = () => (<Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' height='100%' alignContent='center' justifyContent='center' justifyItems='center' alignItems='center' flexDirection='column'>

    <Box display='flex'><NoEvent/></Box>

    <Box display='flex' pt={2}>
      <Typography variant={'h6'}>No events yet</Typography>
    </Box>

    {/* <Box display='flex' pt={1}>
      You can select one from our list
    </Box>

    <Box display='flex' pt={3}>
      <Button variant='outlined' color='primary' component={Link} to={'/search'}>Search now</Button>
    </Box> */}

  </Box></Fade>
)

export default NoEvents
