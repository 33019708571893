import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography, Grid} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Page from 'components/core/Page'
import Hero from 'components/core/Hero'
import SubFooter from 'components/core/Footer/SubFooter'
import ListItem from '@material-ui/core/ListItem'
import {IconCheckCircled} from 'components/core/icons'
import ListItemText from '@material-ui/core/ListItemText'
import Button from 'components/core/Button'
import Header from "components/core/Header/Header";
import HomeSplashScreen from "assets/home.png";
//import Footer from "components/core/Footer/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import AnyFeatureList from 'components/core/AnyFeatureList'

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  },
  FeaturesImage: {
    width: '950px',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  manageImage: {
    width: '483px',
    boxShadow: '0 10px 35px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  sectionText: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },

  FeaturesTitle: {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '40px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4),
    marginTop: 64
  },
  FeaturesContent: {
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4)
  },
  featuresList: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactTitle:{
    color:'#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing:'-0.5px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactMoto:{
    color:'#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing:'0.2px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  featureGroup: {
    fontSize: 24
  }

})

const Features = withStyles(styles)(({classes}) => {

  return <div style={{marginTop: -64}}>
    <Header animate={true} />
    <Page title='Features' noFooter>
      <Hero image={HomeSplashScreen} minHeight={300} >
        <Grid container direction={'column'} justify={'center'} alignContent={'center'} alignItems={'center'}>
          <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={100} offset={0}>
            <Grid item lg={4}>
              <Typography align={'center'} className={classes.FeaturesTitle}>Features</Typography>
            </Grid>
          </ScrollAnimation>
          {/*<Grid item lg={5}>*/}
          {/*  <Box p={2}>*/}
          {/*    <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={200}>*/}
          {/*      <Typography align={'center'} className={classes.FeaturesContent}>*/}
          {/*        An all-inclusive solution to managing your next workshop, meeting or conference.*/}
          {/*        Spend less time organizing your event, and more time participating in it!*/}
          {/*      </Typography>*/}
          {/*    </ScrollAnimation>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Hero>

      <AnyFeatureList />

      {/*<Hero backgroundColor={'#ffffff'} minHeight={300}>*/}
      {/*  <Box pt={6}>*/}
      {/*    <Container>*/}
      {/*      <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}*/}
      {/*            spacing={2}>*/}
      {/*        <Grid item lg={6}>*/}
      {/*          <img src={ManageLayout} alt='ManageLayout' className={classes.manageImage}/>*/}
      {/*        </Grid>*/}
      {/*        <Grid item lg={6}>*/}
      {/*          <Box p={2}>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                Anymeets is a state-of-the-art event management software for any professional event. It supports*/}
      {/*                small*/}
      {/*                workshops, medium-sized events and large-scale conferences with thousands of participants.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                With anymeets you can keep track of your participants, share announcements and documents, collect*/}
      {/*                registration fees, create an event program and much more.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                Anymeets can also be utilized to conduct real-time surveys and polls to engage your audience and*/}
      {/*                to*/}
      {/*                provide you participant feedback to enhance future events.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*          </Box>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Container>*/}
      {/*  </Box>*/}
      {/*</Hero>*/}

      <Container fixed>

        <Box mb={6} mt={6}>
          <Grid container justify={'flex-start'} direction={'row'} alignContent={'center'}
                className={classes.featuresList}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={200}>

              {/*  <ListItem>*/}
              {/*  <ListItemText primary='Basic (free)' classes={{primary: classes.featureGroup}}/>*/}
              {/*</ListItem>*/}
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Branded event landing page'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Anymeets app (iOS and Android)'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Customizable layouts'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Speakers & keynote speakers'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Basic virtual sessions & posters (up to 50 participants)'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Manual session recordings'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Session Q&A and chat'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Live polls (in session and event-wide)'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Agenda & program'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Simple email notifications'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Statistics and feedback'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText
                  primary='Post-event metrics'/>
              </ListItem>
                </ScrollAnimation>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={300}>
              {/*  <ListItem>*/}
              {/*  <ListItemText primary='Advanced (see pricing)' classes={{primary: classes.featureGroup}}/>*/}
              {/*</ListItem>*/}
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Private & invitation-only event'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Participant registration'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Custom registration types'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Registration fee collection'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Advanced virtual sessions & posters (multiple layouts and unlimited participants)'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Automatic session recordings'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Virtual sponsor booths'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Private meeting rooms'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Event-wide breakout rooms'/>
              </ListItem>
              <ListItem>
                <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                <ListItemText primary='Advanced email notifications'/>
              </ListItem>
                </ScrollAnimation>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={400}>
                {/*  <ListItem>*/}
                {/*  <ListItemText primary='Scientific conferences' classes={{primary: classes.featureGroup}}/>*/}
                {/*</ListItem>*/}
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Submissions & multi-submissions flows'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Custom submission forms'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Add and invite reviewers'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Custom reviewer forms'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Robust reviewer tracking (reminders, responses, etc.)'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Manual and automatic reviewer assignment'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Custom grading and decision'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Manual and automatic program creation'/></ListItem>
                <ListItem>
                  <Box mr={2}><IconCheckCircled color={'inherit'}/> </Box>
                  <ListItemText primary='Email templates'/>
                </ListItem>
                </ScrollAnimation>
              </Grid>
          </Grid>
        </Box>
      </Container>


      <Box style={{backgroundColor: '#f7f8fa'}} p={8}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item lg={6}>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
              <Typography className={classes.sectionText} align={'center'}>
                Anymeets comes bundled with a mobile application with sharing and social features, which enables
                participants
                to check the progress of their submissions and gain instant access to the event program, encourages direct
                interaction between participants and allows organizers to send announcements and notifications.
              </Typography>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Box>

      <Hero backgroundColor={'#107AAD'} minHeight={400}>
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
            <Grid item lg={12}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
                <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item lg={8}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={300}>
                <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a demo</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
                <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
              </ScrollAnimation>
            </Grid>
        </Grid>
      </Hero>
      <SubFooter/>
      {/*<Footer />*/}
    </Page>
  </div>
})

export default Features
