import React, {useCallback, useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import SplashScreen from 'assets/splash-screen-illustration.png'
import {withStyles} from '@material-ui/core/styles'
import {Field, Form, Formik} from 'formik'
import Button from 'components/core/Button'
import {useAuth} from 'auth/AuthController'
import {palette, theme} from 'theme'
import Box from '@material-ui/core/Box'
import {Link, Redirect} from 'react-router-dom'
import {FormHelperText, Typography} from '@material-ui/core'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {useHttp} from 'api/core'
import api from 'api/index'
import {useSnackbar} from 'notistack'
import FormControl from '@material-ui/core/FormControl'
import {TITLES} from 'config'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {COUNTRIES} from 'assets/countries'
import Page from 'components/core/Page'
import LoginSignupDialog from 'components/core/LoginSignupDialog'

const styles = () => ({
  title: {
    marginBottom: '5px',
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',

  },
  page: {
    backgroundColor: palette['pale-grey'],
    minHeight: 'calc(100vh - 112px)'
  },
  subtleLink: {
    display: 'inline-block',
    fontSize: '14px',
    textDecoration: 'underline',
    color: palette['brown-grey'],
    textAlign: 'right'
  },
  loginForm: {
    margin: '0 auto',
    maxWidth: '450px',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    }
  },
  splashImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
      // position: 'fixed',
      // top: '80px',
      // left: '0',
      // zIndex: -1
    }
  }
})

const Register = withStyles(styles)(({classes}) => {

  const [isAuthenticated] = useAuth()
  const [fetchData, data] = useHttp()
  const [fetchActivationData, activationData] = useHttp()
  const { enqueueSnackbar } = useSnackbar()
  const [credentials, setCredentials] = useState()
  const [openSignupDialog, setOpenSignupDialog] = useState(false)
  const [activationStatusToken, setActivationStatusToken] = useState(false)

  const Schema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    title: Yup.string().required('This field is required'),
    countryId: Yup.string().required('This field is required'),
    email: Yup.string().email().required('This field is required'),
    password: Yup.string().min(8,'Password cannot be less than 8 characters').required('This field is required'),
    confirmpassword: Yup.string().required('This field is required').when('password', {
      is: val => (!!(val && val.length > 0)),
      then: Yup.string().oneOf(
        [Yup.ref('password')],
        'Password doesn\'t match'
      )
    })
  });

  useEffect(()=>{
    if (data) {
      if (data&&data.status===204&&data.sendEmailFailed) {
        enqueueSnackbar('It seems were unable to send the activation e-mail. Please try again.', {variant:'error', action:resendActivation})
      } else {
        // enqueueSnackbar('Almost done...\n' +
        //   `We've sent you an email. Open it up to activate your account.`, {variant: 'success'})
        setOpenSignupDialog(true)
        setActivationStatusToken(data.activationStatusToken)
      }
    }
    //eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (activationData) {
      if (activationData.sendEmailFailed) {
        enqueueSnackbar('It seems were unable to send the activation e-mail. Please try again.', {variant:'error', action:resendActivation})
      } else {
        // enqueueSnackbar(`We've sent you an email. You'll have to open it up to activate your account.`, {variant: 'success'})
        setOpenSignupDialog(true)
      }
    }
    // eslint-disable-next-line
  },[activationData])

  const requestActivation = (email) => {
    fetchActivationData(api.entities.account.resendConfirmation({email}))
  }
  // eslint-disable-next-line
  const resendActivation = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={()=>{requestActivation(credentials.email)}}> Send activation e-mail </Button>,[credentials])

  const handleClose = () => {
    setOpenSignupDialog(false)
  }

  if (isAuthenticated) {
    return <Redirect to='/'/>
  }

  return <Page title='Register' backgroundColor={palette['pale-grey']}>
    {openSignupDialog&&activationStatusToken&&<LoginSignupDialog open={openSignupDialog} onClose={handleClose} creds={credentials} token={activationStatusToken}/>}
    <Grid container justify='center' alignItems='center' alignContent='center'>
        <Grid item xs={12} md={4} lg={3}>
          <Formik
            initialValues={{ firstName: '', email: '', password: '' , confirmpassword: '', lastName: '', title: '', countryId:''}}
            onSubmit={(values, { setSubmitting }) => {

              setCredentials({
                email: values.email,
                password: values.password
              })

              fetchData(api.entities.account.register({
                firstName: values.firstName,
                lastName: values.lastName,
                title: values.title,
                countryId: values.countryId,
                email: values.email,
                password: values.password
              }))

              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            }}
            validationSchema={Schema}
          >
            {({ isSubmitting, errors, touched }) => (
                  <Form>
                    <Box className={classes.loginForm}>
                    <Typography component='h2' variant='h5' className={classes.title}>Sign up</Typography>
                    <Box className={classes.subtitle} textAlign='center' mb={4}>
                      join events, save favorites & more!
                    </Box>
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <Field type='select' labelId='titleLabel' displayEmpty name='title' component={Select} label='Category' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                            {TITLES.map((title, i)=>{
                              return <MenuItem value={title} key={i}>{title}</MenuItem>
                            })}
                          </Field>
                          <InputLabel variant='filled' required>Title</InputLabel>
                          {errors&&errors.title&&touched.title&&<FormHelperText error variant='filled'> {errors.title} </FormHelperText>}

                        </FormControl>
                      </Grid>
                      <Grid item xs={9}>
                        <Field type='text' name='firstName' component={TextField} label='First name' variant='filled' required fullWidth disabled={isSubmitting}/>
                      </Grid>
                      <Grid item xs={3}>

                      </Grid>
                      <Grid item xs={9}>
                        <Field type='text' name='lastName' component={TextField} label='Last name' variant='filled' required fullWidth disabled={isSubmitting}/>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <Field type='select' labelId='countryIdLabel' displayEmpty name='countryId' component={Select} label='Category' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                            {COUNTRIES.map((country, i)=>{
                              return <MenuItem value={country.id} key={i}>{country.name}</MenuItem>
                            })}
                          </Field>
                          <InputLabel variant='filled' required>Country</InputLabel>
                          {errors&&errors.countryId&&touched.countryId&&<FormHelperText error variant='filled'> {errors.countryId} </FormHelperText>}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='email' name='email' required component={TextField} label='Email' variant='filled' fullWidth disabled={isSubmitting}/>
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='password' name='password' component={TextField} label='Password' variant='filled' required fullWidth />
                      </Grid>

                      <Grid item xs={12}>
                        <Field type='password' name='confirmpassword' component={TextField} label='Confirm Password' variant='filled' required fullWidth />
                      </Grid>

                    </Grid>

                    <Box m={4} textAlign='center'>
                      By signing up, you agree with our <br/>
                      <Link to='/terms' className={classes.subtleLink}>terms of service</Link>&nbsp;
                      and &nbsp;
                      <Link to='/privacy' className={classes.subtleLink}>privacy policy.</Link>
                    </Box>
                      <Box display='flex' justifyContent='center' alignContent='center'>
                        <Button type='submit' color='primary'> Create account </Button>
                      </Box>

                    <Box m={4} textAlign='center'>
                      Already have an account? <br/>
                      <Link to='/login' className={classes.subtleLink}>Sign in</Link>
                    </Box>
                    </Box>
                  </Form>
            )}
          </Formik>
        </Grid>
        <Grid item className={classes.splashImage}>
          <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
            <Box display='flex'>
              <img src={SplashScreen}  alt={'Anymeets'}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
  </Page>
})

export default Register
