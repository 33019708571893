import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Box from "@material-ui/core/Box";
import {CURRENCY} from "config";
import Grid from "@material-ui/core/Grid";
import Button from "components/core/Button";
import clsx from "clsx";
// import InfoIcon from "@material-ui/icons/Info";
// import IconButton from "@material-ui/core/IconButton";
// import PlanEventOverview from "components/core/PlanEventOverview";
// import Divider from "@material-ui/core/Divider";

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  planBox: {
    backgroundColor: "white",
    borderRadius: "12px",
    border: "solid 1px #e6e6e6",
    position: "relative",
    padding: theme.spacing(2),
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  purchasedPlan: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7,
  },
  button: {
    height: "100%",
    position: "relative",
  },
  note: {
    left: 0,
    fontSize: "11px",
    position: "absolute",
    width: "100%",
    bottom: "-5px",
    textAlign: "center",
    color: palette["brown-grey"],
    fontWeight: "bold",
  },
  // container: {
  //   marginBottom: theme.spacing(2)
  // },
  priceColor: {
    color: theme.palette.primary.main,
  },
});

const AddBallanceToEvent = withStyles(styles)(({classes, handleOpen, event, credits, data, busy, setBusy, creditsPriceList, current}) => {
  return (
    <Grid container spacing={2} className={classes.container} justify="space-between">
      <Grid item container className={clsx(classes.planBox)} justify="space-between" spacing={2}>
        <Grid item xs={12} sm={7} md={9} lg={10}>
          <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
            <Box display="flex" fontSize="17px" fontWeight="bold" alignItems="center">
              <Box display="flex" style={{textTransform: "capitalize"}}>
                {current}
              </Box>
            </Box>
            <Box display="flex" fontSize="14px" lineHeight="19px" fontWeight="normal" pt={1}>
              <span>
                There are <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.balance} </span> credits available for this event
              </span>
            </Box>
          </Box>
        </Grid>

        {current && (
          <Grid item xs={12} sm={5} md={3} lg={2}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              {creditsPriceList && (
                <Box display="flex" fontSize="14px" justifyContent="center">
                  Price
                </Box>
              )}
              {creditsPriceList && (
                <Box display="flex" fontSize="20px" fontWeight="bold" justifyContent="center" className={classes.priceColor}>
                  {CURRENCY}
                  {creditsPriceList.value}/credit
                </Box>
              )}
              {/* <Box display="flex" pt={2} justifyContent="center" alignContent="flex-end">
                <Button disabled={busy} autoWidth onClick={() => handleOpen(current)}>
                  Buy credits
                </Button>
              </Box> */}
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

export default AddBallanceToEvent;
