import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import OrganizerCard from 'components/core/OrganizerCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import OrganizerEditNew from 'components/core/OrganizerEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'

const styles = theme => ({
  organizersContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(5),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    maxWidth: '1000px'
  },
  noOrganizersYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "1.1rem",
    lineHeight: "1.1rem",
    paddingBottom: theme.spacing(2),
    color: "#444"
  },   
})

const Organizers = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack, content})=>{

  const [getData, data, isLoadingData] = useHttp()
  const [delRequest, delData, isLoadingDelete] = useHttp()
  const [openOrganizerDialog, setOpenOrganizerDialog] = useState(false)
  const [editingOrganizer, setEditingOrganizer] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingOrganizer, setDeletingOrganizer] = useState()
  const [organizersData, setOrganizersData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [sendData] = useHttp()

  useEffect(()=>{
    getData(api.entities.manage.getOrganizers(null,null,{id:eventId}))
  },[getData, eventId])

  useEffect(()=>{
    if (data) {
      setOrganizersData(data.organizers)
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({path:'/manage-events',name:'events'})
    }
    //eslint-disable-next-line
  },[data])

  const handleClose = (shouldReload) => {
    setOpenOrganizerDialog(false)
    if (shouldReload===true) {
      getData(api.entities.manage.getOrganizers(null,null,{id:eventId}))
    }
  }

  const handleOpen = (organizer) => {
    setOpenOrganizerDialog(true)
    if (organizer) {
      setEditingOrganizer(organizer)
    } else {
      setEditingOrganizer(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingOrganizer(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingOrganizer) {
      delRequest(api.entities.organizers.delete(null,null,{id:id}))
      setDeletingOrganizer(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted organizer', {variant: 'success'})
      getData(api.entities.manage.getOrganizers(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getData])

  const onDragEnd = (r) => {
    if (!r.destination) return

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    // arrayHelpers.move(r.source.index,r.destination.index)
    let newArr = [...organizersData]

    array_move(newArr, r.source.index, r.destination.index)

    setOrganizersData(newArr)

    const newArrOrder = [...newArr.map((sF)=>sF.id)]

    sendData(api.entities.organizers.reorder(newArrOrder, null, {id: eventId}))
  }

  if (!organizersData) return <> </>

  return <Grid container>

    <Dialog onClose={handleClose} open={openOrganizerDialog} maxWidth='md'>
      <OrganizerEditNew organizer={editingOrganizer} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete organizer</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete organizer?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={()=>{handleDeleteClose(deletingOrganizer)}}  size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>
    <Fade in={true} timeout={content? 0 : TIME_FADE_IN}>
      <Grid container className={classes.organizersContainer} spacing={content? 0 : 2}>
        <Grid item xs={12}>
          {content ?
            (<Typography component='h2' variant='h5' className={classes.subtitle}>
              Organizers list
            </Typography>)
              :
            (<Typography component='h2' variant='h5' className={classes.title}>
              Organizers
            </Typography>)
          }
        </Grid>

        <Grid item xs={12}>

          {organizersData && organizersData.length!==0 && <>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId={`${0}`}>
                {(provided) => {
                  return <Grid item container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                    {organizersData.map((organizer,index)=>(
                      <Draggable key={index}
                                 draggableId={`${index}`}
                                 index={index}>
                        {(provided) => (
                          <Grid item xs={12} {...provided.draggableProps}
                                ref={provided.innerRef} width={'100%'}>
                            <OrganizerCard dragHandleProps={provided.dragHandleProps} organizer={organizer} key={index} editAction={()=>{handleOpen(organizer)}} deleteAction={()=>{handleDeleteOpen(organizer.id)}} mb={2} content={content}/>
                          </Grid>)
                        }
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Grid>
                }}
              </Droppable>
            </DragDropContext>
          </>}

          {organizersData.length===0?<Grid item container className={classes.noOrganizersYet}>
            <Grid item>No organizers yet!</Grid>
          </Grid>:''}
          <Box display='flex' justifyContent='flex-end' pt={3} >
            <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen()}} disabled={isLoadingData||isLoadingDelete}>Add Organizer</Button>
          </Box>
        </Grid>
      </Grid>
    </Fade>
  </Grid>
})

export default withRouter(Organizers)
