import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import SplashScreen from 'assets/splash-screen-illustration.png'
import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import {Field, Form, Formik} from 'formik'
import Button from 'components/core/Button'
import {palette, theme} from 'theme'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import {Typography} from '@material-ui/core'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {useHttp} from 'api/core'
import api from 'api/index'
import Page from 'components/core/Page'
import {useSnackbar} from 'notistack'
import {PASSWORD_CALLBACKS} from 'config'

const styles = () => ({
  title: {
    marginBottom: '5px',
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',

  },
  page: {
    backgroundColor: palette['pale-grey'],
    minHeight: 'calc(100vh - 128px)'
  },
  subtleLink: {
    display: 'inline-block',
    fontSize: '14px',
    textDecoration: 'underline',
    color: palette['brown-grey'],
    textAlign: 'right'
  },
  loginForm: {
    margin: '0 auto',
    maxWidth: '330px',
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4),
    }
  },
  splashImage: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  }
})

const ForgotPassword = withStyles(styles)(({classes}) => {

  const [fetchData, data] = useHttp()
  const { enqueueSnackbar } = useSnackbar()
  const [signInMessage, setSignInMessage] = useState()
  const Schema = Yup.object().shape({
    email: Yup.string().email().required('This field is required')
  })

  useEffect(()=>{
    setSignInMessage(PASSWORD_CALLBACKS[Math.floor(Math.random()*PASSWORD_CALLBACKS.length)])
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      if (data&&data.status===204) {
        enqueueSnackbar('Almost done...\n' +
          `We've sent you an email. Open it up to reset your password.`, {variant: 'success'})
      }
    }
  },[data, enqueueSnackbar])


  return <Page title='Forgot password' backgroundColor={palette['pale-grey']}>
    <ThemeProvider theme={theme}>
      <Grid container justify='center' alignItems='center' className={classes.page}>
        <Grid item xs={12} md={4} lg={3}>
          <Formik
            initialValues={{ email: ''}}
            onSubmit={(values, { setSubmitting }) => {

              fetchData(api.entities.account.forgotPassword({
                email: values.email
              }))

              setTimeout(() => {
                setSubmitting(false);
              }, 400);
            }}
            validationSchema={Schema}
          >
            {({ isSubmitting }) => (
                  <Form>
                    <Box className={classes.loginForm}>
                    <Typography component='h2' variant='h5' className={classes.title}>Forgot your password?</Typography>
                    <Box className={classes.subtitle} textAlign='center' mt={2} mb={2}>
                      Fill-in your e-mail and we'll send you a password reset link.
                    </Box>
                    <Box mb={3} mt={3}>
                      <Field type='email' name='email' component={TextField} label='Email' variant='filled' fullWidth disabled={isSubmitting}/>
                    </Box>
                    <Box display='flex' justifyContent='center' alignContent='center'>
                      <Button type='submit' color='primary'>Ask for a password reset</Button>
                    </Box>
                    <Box m={4} textAlign='center'>
                      {signInMessage} <br/>
                      <Link to='/login' className={classes.subtleLink}>Sign in</Link>
                    </Box>
                    </Box>
                  </Form>
            )}
          </Formik>
        </Grid>
        <Grid item className={classes.splashImage}>
          <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
            <Box display='flex'>
              <img src={SplashScreen}  alt={''}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  </Page>
})

export default ForgotPassword
