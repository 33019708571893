import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import api from 'api'
import {useHttp} from 'api/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {RadioGroup} from 'formik-material-ui'
import Button from 'components/core/Button'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {IconStatusOkFillGreen} from 'components/core/icons'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import {palette} from 'theme'
import CircularProgress from '@material-ui/core/CircularProgress'
import DialogTitle from '@material-ui/core/DialogTitle'
import {ReactComponent as NotFoundGraphic} from 'assets/404.svg'

const styles = theme => ({
  form: {
    width: '100%',
    textAlign: 'center'
  },
  codeForm: {
    paddingTop: theme.spacing(3)
  },
  header: {
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    '& a': {
      color: palette['cerulean-blue'],
      fontWeight: 'bolder',
    }
  }
})

const questionStyles = theme => ({
  form: {
    width: '100%'
  },
  questionForm: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(1)
  },
  radioGroup: {
    maxWidth: '100%',
    padding: 0,
    '& span' : {
      padding: '0',
      margin: theme.spacing(0,0,0,1)
    }
  }
})

const Question = withStyles(questionStyles)(({classes, question, code, goToNext, isLoadingNextQuestion}) => {
  const [getData, data, isLoading] = useHttp()
  const [answer, setAnswer] = useState()

  useEffect(() => {
    if (answer) {
      getData(api.entities.feedback.answer({optionId: answer}, {q: question.ordinal}, {code: code}))
    }
    // eslint-disable-next-line
  }, [answer])

  useEffect(() => {
    if (data) {
      goToNext()
    }
    // eslint-disable-next-line
  }, [data])

  return <Fade in={true} timeout={TIME_FADE_IN}><Grid container spacing={2} justify='center' alignContent='center'
                                                      alignItems='center'
                                                      className={classes.questionForm}>
    <Grid item xs={12}>
      <Box fontWeight='bold' pt={1} pb={1}>
        <Box fontSize={16} fontWeight='bold'>{question.ordinal}. {question.question}</Box>
      </Box>
    </Grid>

    <Grid item xs={12} key={question.ordinal}>
      <Formik
        initialValues={{answer: `${question.selectedOptionId}`}}
        onSubmit={(values) => {
          setAnswer(parseInt(values.answer))
        }}>
        {({setFieldValue, values}) => (
          <Form className={classes.form}>
            <Grid item xs={12}>
              <Field component={RadioGroup} name='answer' className={classes.radioGroup}>
                {question.options.map((option, i) => {
                  return <FormControlLabel key={i}
                                           value={`${option.id}`}
                                           control={<Radio color='primary'/>}
                                           label={option.option}
                                           labelPlacement='end'
                                           disabled={isLoading||isLoadingNextQuestion}
                  />
                })}
              </Field>
            </Grid>
            <Grid container item justify='flex-end' alignContent='center' alignItems='flex-end'>
              <Grid item>
                <Box pt={3}>
                  <Button type='submit' color='primary' size='smaller' disabled={isLoading||isLoadingNextQuestion}>Submit answer</Button>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>

  </Grid></Fade>
})

const Completion = withStyles(styles)(({classes, clearFeedback, hasFeedback}) => {
  useEffect(()=>{
    setTimeout(()=>{
      clearFeedback()
    },2500)
  })
  return <Box display='flex' justifyContent='center' alignContent='center' justifyItems='center' flexDirection='row'
              pt={6}>
    <Box display='flex' justifyContent='center' alignItems='center' alignContent='center' justifyItems='center'
         flexDirection='column'>
      <IconStatusOkFillGreen/>
      <Box display='flex' pt={2}>
        <Typography variant={'h6'}>Thank you!</Typography>
      </Box>
      <Box display='flex' pt={2}>
        <Typography variant={'body1'}>Your answers were submitted successfully.</Typography>
      </Box>
    </Box>
  </Box>
})

const Feedback = withStyles(styles)(({classes, feedbackCode, setAnsweredFeedback, handleClose}) => {
  const [question, setQuestion] = useState()
  const [code, setCode] = useState(feedbackCode)
  const [questionNumber, setQuestionNumber] = useState(feedbackCode?1:null)
  const [totalNumberOfQuestion, setTotalNumberOfQuestions] = useState(0)
  const [getData, data, isLoading, hasError] = useHttp()

  useEffect(() => {
    if (code) {
      setQuestionNumber(1)
    }
    // eslint-disable-next-line
  }, [code])

  useEffect(() => {
    if (data) {
      setQuestion(data)
      setTotalNumberOfQuestions(parseInt(data.total)+1)
    }
  }, [data])

  useEffect(() => {
    if (questionNumber && (questionNumber !== totalNumberOfQuestion)) {
      getData(api.entities.feedback.getQuestions(null, {q: questionNumber}, {code: code}))
    }
    // eslint-disable-next-line
  }, [questionNumber])

  useEffect(() => {
    // if (questionNumber && (questionNumber !== totalNumberOfQuestion)) {
    //   getData(api.entities.feedback.getQuestions(null, {q: questionNumber}, {code: code}))
    // }
    // setQuestionNumber(1)
    // eslint-disable-next-line
  }, [])


  // useEffect(()=>{
  //   if (feedback&&feedback.length===1&&feedback[0].enabled&&feedback[0].visible) {
  //     setCode(feedback[0].code)
  //   } else {
  //     clearFeedback()
  //   }
  // },[feedback, code])

  const goToNext = () => {
    setQuestionNumber(questionNumber + 1)
  }

  const clearFeedback = () => {
    setCode(undefined)
    setQuestion(undefined)
    setQuestionNumber(undefined)
    setTotalNumberOfQuestions(0)
    handleClose()
  }

  if (hasError) {
    return <Box display='flex' alignContent='center' justifyContent='center' alignItems='center' flexDirection='column'>
      <Box>
        <NotFoundGraphic/>
      </Box>
      <Box pt={8} className={classes.message}>
        <Typography variant='h5'>Oops… You seem to be lost in space!</Typography>
      </Box>
    </Box>
  }

  return <Box display='flex' alignContent='center' justifyContent='center' alignItems='center' flexDirection='column'>

    {!question && (questionNumber !== totalNumberOfQuestion) &&
      <Box position='absolute' width='100%' height='100%' display='flex' alignContent='center' justifyContent='center' alignItems='center' top={0} left={0}>
        <Box display='flex'>
          <CircularProgress size={60} />
        </Box>
      </Box>}
    {question&&<DialogTitle id="alert-dialog-title">{question.feedbackTitle}</DialogTitle>}
    {question&&(questionNumber !== totalNumberOfQuestion)&&<Box pb={1} fontSize={14}> {questionNumber}/{parseInt(question.total)} </Box>}
    <Box pl={3} pr={3}>
        {question && (questionNumber !== totalNumberOfQuestion) &&
        <Question question={question} goToNext={goToNext} code={code} isLoadingNextQuestion={isLoading}/>}
        {(questionNumber === totalNumberOfQuestion) && <Completion clearFeedback={clearFeedback}/>}
      </Box>
  </Box>

})

export default withRouter(Feedback)
