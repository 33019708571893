import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { palette } from "theme";
import SubmissionsCircles from "assets/Submissions_circles.svg";
import ReviewingCircles from "assets/Reviewing_circles.png";
import MIddlepart_graphic_left from "assets/MIddlepart_graphic_left.svg";
import Tick from "assets/Tick_icon.svg";
import Submissions from "assets/submissions.png";
import Reviewing from "assets/reviewing.png";
import ProgramCreation from "assets/programcreation.gif";
import Registration from "assets/registration.png";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = (theme) => ({
	homeTimeLine: {
		padding: "65px 10px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#f0f9ff",
		backgroundImage: "url(" + MIddlepart_graphic_left + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "left bottom",
		backgroundSize: "contain",
	},
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "800px",
		color: "#666666",
		fontSize: "28px",
		textAlign: "center",
		fontWeight: "normal",
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(1),
			marginBottom: 0,
		},
	},
	subtitle2: {
		maxWidth: "800px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "0.3px",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "36px",
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "21px",
		fontFamily: "Karla",
		textTransform: "capitalize",
		lineHeight: "21px",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		letterSpacing: "-0.75px",
		textTransform: "capitalize",
		lineHeight: "14px",
	},
	testimonialsText: {
		width: "535px",
		margin: "64px auto 10px",
		padding: "20px 5px 20px 5px",
		fontFamily: "Karla",
		fontSize: "36px",
		letterSpacing: "-1.8px",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		textAlign: 'center',
		height: '50px',
		fontFamily: 'Karla',
		fontSize: '18px',
	  //  backgroundColor: '#a3ce47',
	   backgroundColor: '#03a496',
		color: '#fff',
		width: '170px !important',
		minWidth: 'unset !important',
	  //  border: '1px solid #a3ce47',
	   border: '1px solid #03a496',
		borderRadius: '50px',
		cursor: 'pointer',
		'&&:hover': {
			backgroundColor: '#0990b1',
			border: '1px solid #0990b1',
		},
		marginRight: '30px'
	  },
	Submissions: {
		margin: "50px 10px 20px 10px",
		padding: "5px 0px 6px 0",
		fontFamily: "Nunito,Karla",
		fontSize: "36px",
		color: "#000",
		display: "block",
	},
	AcceptSubmissions: {
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		color: "#000",
		"& ul li span": {
			display: "inline-block",
			width: "91%",
		},
	},
	tickIcon: {
		width: "15px",
		lineHeight: "15px",
		verticalAlign: "top",
		marginRight: "10px",
		marginTop: "5px",
	},
	subList: {
		"& li": {
			listStyle: "none",
			maxWidth: "370px",
			marginBottom: "30px",
			fontFamily: "Karla",
			fontWeight: "500",
		},
		"& ul": {
			paddingLeft: "12px",
		},
	},
	subCircles: {
		backgroundImage: "url(" + SubmissionsCircles + ")",
		backgroundSize: "contain",
		height: "350px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			height: "260px",
		},
	},
	reviewingCircles: {
		backgroundImage: "url(" + ReviewingCircles + ")",
		backgroundSize: "contain",
		height: "410px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		textAlign: "center",
		[theme.breakpoints.down("xs")]: {
			height: "200px",
		},
	},
	timelineRow: {
		backgroundImage: "url(" + SubmissionsCircles + ")",
		backgroundSize: "450px",
		minHeight: "610px",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	tabImages: {
		width: "80%",
		maxWidth: "450px",
		margin: "30px auto",
		borderRadius: "20px",
		display: "block"
	},
	
});

const HomeTimeLine = withStyles(styles)(({ classes }) => {
	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.homeTimeLine}
		>
			<Grid
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.subtitle2}
			>
				<Grid item>How Anymeets works</Grid>
			</Grid>
			<Grid
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.hero}
			>
				<Grid container>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Submissions</span>
						<span className={classes.AcceptSubmissions}>
							<ul>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Accept submissions within minutes of setting up your event
										page.
									</span>
								</li>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Create multiple submission types (e.g., paper, abstract,
										poster, e-poster).
									</span>
								</li>
							</ul>
						</span>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subCircles}>
						<img
							className={classes.tabImages}
							src={Submissions}
							alt="Accept submissions within minutes of setting up your event page."
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.reviewingCircles}>
						<img
							className={classes.tabImages}
							src={Reviewing}
							style={{
								boxShadow: "5px 0px 0px 0px #0dc7e5,0px 0px 50px 0px #ccc",
							}}
							alt=" Track and monitor every step of the review process"
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Reviewing</span>
						<span className={classes.AcceptSubmissions}>
							<ul>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Track and monitor every step of the review process.
									</span>
								</li>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Customize your grading forms – ask for comments, include
										special instructions.
									</span>
								</li>
							</ul>
						</span>
					</Grid>
				</Grid>
				<Grid container className={classes.timelineRow}>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Program creation</span>
						<span className={classes.AcceptSubmissions}>
							<ul>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Use our smart algorithms to create sessions with optimal
										paper grouping.{" "}
									</span>
								</li>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Allow participants to view papers and abstracts directly from
										the Agenda.
									</span>
								</li>
							</ul>
						</span>
					</Grid>

					<Grid item lg={6} sm={6} sx={12}>
						<img
							className={classes.tabImages}
							src={ProgramCreation}
							style={{
								boxShadow: "-5px 0px 0px 0px #a3ce47,0px 0px 50px 0px #ccc",
							}}
							alt="Use our smart algorithms to create sessions with optimal paper grouping."
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12}>
						<img
							className={classes.tabImages}
							src={Registration}
							style={{
								boxShadow: "5px 0px 0px 0px #0dc7e5,0px 0px 50px 0px #ccc",
								maxWidth: "",
							}}
							alt="Offer multiple registration types and payment methods."
						/>
					</Grid>
					<Grid item lg={6} sm={6} sx={12} className={classes.subList}>
						<span className={classes.Submissions}>Registration</span>
						<span className={classes.AcceptSubmissions}>
							<ul>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Offer multiple registration types and payment methods.
									</span>
								</li>
								<li>
									<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />{" "}
									<span>
										Hold invite-only sessions with restricted registration.
									</span>
								</li>
							</ul>
						</span>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
			>
				<Box>
					{/* <span className={classes.testimonialsText}>
						<span className="text-style-1">“</span>I was able to double my client
						base after migrating to Anymeets.<span className="text-style-2">”</span>
					</span>
					<span className={classes.testimonialsName}>
						Ashley Madden, Helianthus Advising, LLC
					</span> */}<br/><br/>
					{/* <Button component={Link} to={`/create-event`} className={classes.buttonTry}>Try now</Button> */}
					{/* <Button component={Link} to={`/select-package`} className={classes.buttonTry}>Try now</Button> */}
				</Box>
			</Grid>			
		</Grid>
	);
});

export default HomeTimeLine;
