import {Field, Form, Formik} from "formik";
import Box from "@material-ui/core/Box";
import {FormHelperText, Typography} from "@material-ui/core";
import {Select, Switch, TextField} from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import {CATEGORIES, SIZE_LENGTH_DESCRIPTION, LANG_LIST} from "config";
import {DatePicker, TimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {IconCalendarBorderDark, IconClockBorderDark} from "components/core/icons";
import MapSearchBox from "components/core/MapSearchBox";
import Map from "components/core/Map";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {withRouter, useParams} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import {palette} from "theme";
import * as Yup from "yup";
import {useHttp} from "api/core";
import api from "api";
import {useSnackbar} from "notistack";
import Button from "components/core/Button";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import "react-image-crop/dist/ReactCrop.css";
import {boolean} from "yup";
import {format, parseISO} from "date-fns";
import differenceInDays from "date-fns/differenceInDays";
import {AlignmentType, Document, Footer, Header, HeadingLevel, Packer, Paragraph, TextRun} from "docx";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import {saveAs} from "file-saver";
import moment from "moment";
//import { date } from "date-arithmetic";
import language from "components/language";
import parse from "html-react-parser";
import OptionCard from 'components/core/OptionCard'
import { IconAddBorderBlue, IconLockBorderDark, IconMailBorderDark, IconSpeakersBorderDark } from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import MailListEditNew from 'components/core/MailListEditNew'
import TicketTypeEditNew from 'components/core/TicketTypeEditNew'
import { DOMAIN, TIME_FADE_IN } from 'config'
import MailListCard from 'components/core/MailListCard'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import clsx from 'clsx'
import RichTextEditor from 'react-rte'
import QRCode from 'qrcode.react'
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';


language.setLanguage("en");

const styles = (theme) => ({
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  form: {
    color: palette["greyish-brown"],
  },
  dropBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    color: palette["greyish-brown"],
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  note: {
    fontSize: "12px",
  },
  sideTitle: {
    // minWidth: '200px'
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-15px",
    "& Button": {
      minWidth: "170px"
    }
  },
  
  section: {
    paddingBottom: theme.spacing(4),
    maxWidth:"1200px"
  },
  searchBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  title: {
    fontWeight: "bolder",
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginLeft: "10px"
  },  
  ptitle: {
    fontSize: "0.8rem",
    marginLeft: "10px"    
  },
  hideSmall: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
	description: {
		width: "180px",
    padding: theme.spacing(1),
    fontSize: 14
	},
	switchDescription: {
		width: "100%",
		padding: theme.spacing(1),
	},
	eventInfo: {
		backgroundColor: palette.aquamarine,
		minHeight: "50px",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: "white",
	},
	eventCode: {
		width: "200px",
		height: "100px",
		backgroundColor: "white",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: palette["greyish-brown"],
		fontWeight: "bold",
		letterSpacing: "1px",
		fontSize: "20px",
	},
	eventQrCode: {
		width: "200px",
		height: "200px",
		backgroundColor: "white",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: palette["greyish-brown"],
	},
	noTicketsYet: {
		display: "flex",
		backgroundColor: "white",
		padding: theme.spacing(2),
		borderRadius: "6px",
		border: "2px dashed",
		borderColor: palette["cerulean-blue"],
		minWidth: "645px",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
		color: palette["greyish-brown"],
		fontSize: "18px",
		opacity: 0.3,
	},
	notApplicable: {
		opacity: 0.4,
	},
	animated: {
		transition: "all 0.5s",
	},
	editor: {
		color: palette["greyish-brown"],
		borderRadius: "6px",
		minHeight: "300px",
		fontFamily: "'Roboto','Karla', sans-serif;",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },  
  anyurl: {
    marginRight: "-3px",
    verticalAlign: "revert",
    fontWeight: "600",
    lineHeight: "41px",
    height: "39px",
    border: "1px solid #ddd",
    display: "inline-block",
    padding: "5px",
    background: "#ddd",
    position: "relative",
    boxShadow: "-3px 8px 12px 0 rgb(0 0 0 / 10%)",
    borderRadius: "5px 0 0 5px",
    zIndex: "1"    
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },
  eventMarker: {
    backgroundColor: "#fff",
    "& Svg": {
      fill: "#0078ad"
    }
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },  
});

const patterns = {
  domain: /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/,
  punycode: /^([A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?(?:\.[A-Za-z0-9](?:(?:[-A-Za-z0-9]){0,61}[A-Za-z0-9])?)*)(\.?)$/,
  cyrillicDomain: /^((http|https):\/\/)?[a-zа-я0-9]+([-.]{1}[a-zа-я0-9]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/i,
};

Yup.addMethod(Yup.string, "domain", function pattern(name, message = "This doesn't seem to be a valid domain name") {
  const domainRules = [patterns.domain, patterns.punycode, patterns.cyrillicDomain];

  return this.test({
    message,
    test: (value) => value === null || value === "" || value === undefined || domainRules.some((regex) => regex.test(value)),
  });
});

const validationSchema = (formData) =>
  Yup.object().shape({
    general: Yup.object().shape({
      online: boolean(),
      // venueName: Yup.string().required('This field is required'),
      venueName: Yup.string().when("online", {
        is: false,
        then: Yup.string(),
      }),
      venueDetails: Yup.string().when("online", {
        is: false,
        then: Yup.string(),
      }),
      address: Yup.string().when("online", {
        is: false,
        then: Yup.string(),
      }),
      city: Yup.string().when("online", {
        is: false,
        then: Yup.string(),
      }),
      country: Yup.string().when("online", {
        is: false,
        then: Yup.string(),
      }),
      // zipCode: Yup.string().required('This field is required'),
      name: Yup.string().required("This field is required"),
      acronym: Yup.string().required("This field is required"),
      description: Yup.string(),
      category: Yup.string().required("This field is required"),
      startDate: Yup.date().required("This field is required"),
      endDate: Yup.date()
        .when("startDate", (startDate, schema) => startDate && schema.min(startDate))
        .required("This field is required"),
      slug: Yup.string()
        .matches(/^[a-z0-9-]+$/gim, "Only latin characters, numbers and dashes are allowed.")
        .nullable(),
      host: Yup.string().nullable().domain(),
      //eventLanguage: Yup.number(),
      submissionFlowButtonLabel: Yup.string().nullable(),
      submissionFlowLabel: Yup.string().nullable(),
      hiddenEvent: boolean(),
      activateSubmissions: boolean(),
    }),
    communicationSettings: Yup.object().shape({
      sender: Yup.string().nullable(),
      from: Yup.string().email("Invalid email address").nullable(),
      replyTo: Yup.string().email("Invalid email address").nullable(),
      // bcc: Yup.string().email("Invalid email address").nullable(),

    }),
  });

const initialAddress = {
  postal_code: "",
  address: "",
  country: "",
  city: "",
};

const General = withStyles(styles)(({ classes, setPageTitle, setEventInfo, setNavBack, history, eventInfo, flow }) =>
{
  const currentDate = new Date();
  const [formData, setFormData] = useState();
  const [formSave, setFormSave] = useState();
  const [address, setAddress] = useState(initialAddress);
  const [latLng, setLatLng] = useState();
  const [getEventData, event] = useHttp();
  const [getData, data] = useHttp();

  const [getDataPublish, dataPublish] = useHttp();
  const [putRequest, putData, isLoading, hasError] = useHttp();
  const [getAgendaData, agendaData] = useHttp();
  const [getParticipantsData, participantsData] = useHttp();
  
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  const {eventId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const [acronymAvailability, setAcronymAvailability] = useState(null);
  const [getAvailability, availabilityData] = useHttp();
  const AvailabilityTimeout = useRef(null);

  const [editingMailList, setEditingMailList] = useState()
  const [mailListDialog, setMailListDialog] = useState(false)

  const [editingTicket, setEditingTicket] = useState()
  const [newTicketDialog, setTicketDialog] = useState(false)
  const [sessions, setSessions] = useState([])
  const [getSessions, sessionsData] = useHttp()
  const [delReqTicket, delTicketData, loadingDelTickets] = useHttp()
  const [delReqMailList, delMailListData, loadingDelMailList] = useHttp()
  const [deletingTicket, setDeletingTicket] = useState()  
  const [getMailListData, mailListData, loadingMailList] = useHttp()
  const [deleteOpenTickets, setDeleteOpenTickets] = useState()  
  const [deleteOpenMailList, setDeleteOpenMailList] = useState()   
  const [deletingMailList, setDeletingMailList] = useState()

  const [editorValue, setEditorValue] = useState({ value: RichTextEditor.createValueFromString(formData && formData.communicationSettings.signature ? formData.communicationSettings.signature : '', 'html') })
  
  const isAvailable = (value) => {
    if (acronymAvailability && acronymAvailability.acronym === value) {
      return;
    }
    clearTimeout(AvailabilityTimeout.current);
    setAcronymAvailability({acronym: value, available: null}); // we do not know yet
    if (value) {
      AvailabilityTimeout.current = setTimeout(() => {
        getAvailability(api.entities.events.getAcronymAvailability(null, {v: value}));
      }, 2000);
    }
  };

  useEffect(() => {
    if (availabilityData) {
      setAcronymAvailability({...acronymAvailability, available: availabilityData.available});
    }
    //eslint-disable-next-line
  }, [availabilityData]);

  useEffect(() => {
    getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, [eventId]);

  useEffect(() => {
    if (data && data.eventInfo.dirty === false) {
    getEventData(api.entities.events.getInfo(null, null, {id: eventId}));
    getAgendaData(api.entities.events.getAgenda(null, null, {id: eventId}));
    getParticipantsData(api.entities.events.getParticipants(null, {keynote: false}, {id: eventId}));
    }
    // eslint-disable-next-line
  }, [data]);  
  
  useEffect(() => {
    if (data && timeZones) {
      const zone = timeZones.find((x) => x.id === data.general.timezoneId);      
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.id)    
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, timeZones]);

  useEffect(() =>{
    if (data && timeZones && selectedTimezone) {
      const zone = timeZones.find((x) => x.id === selectedTimezone);
      moment.tz.setDefault(zone.name);
      //console.log(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone]);

  useEffect(() => {
    if (data && timeZones) {
      setFormData(data);
      setLatLng({lat: data.general.venueLat, lng: data.general.venueLon});
      setPageTitle(data.general.name);
      setEventInfo(data.eventInfo);
      setNavBack({ path: "/manage-events", name: "events" });    
    }
  }, [data, setEventInfo, setNavBack, setPageTitle, timeZones]);

  useEffect(() => {
    if (data) {
      setEditorValue({ value: RichTextEditor.createValueFromString(data.communicationSettings&&data.communicationSettings.signature?data.communicationSettings.signature:'','html') })
    }
  }, [data]);

  useEffect(() =>
  {
    if (putData) {
      getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
      enqueueSnackbar("Successfully saved event", {variant: "success"});
    }
    // eslint-disable-next-line
  }, [putData]);

  useEffect(() => {
    if (formSave)
    {
      const payLoad = {
        ...formSave,
        communicationSettings: {
          ...formSave.communicationSettings,
          signature: editorValue.value.toString('html')
        }
      };

      let formDataObj = new FormData();
      const { ...formDataItems } = payLoad;

      const blob = new Blob([JSON.stringify(formDataItems)], {type: "application/json"});

      formDataObj.append("general", blob);
      putRequest(api.entities.manage.putGeneral(formDataObj, null, {id: eventId}, true));
      setFormSave(undefined);
    }
  }, [enqueueSnackbar, eventId, formSave, getData, putRequest]);

  const DummyUpdateComp = ({setFieldValue, setFieldTouched, values}) => {
    useEffect(() => {
      if ((latLng.lat !== values.general.venueLat && latLng.lng !== values.general.venueLon)) {
        setFieldValue("general.venueLat", latLng.lat);
        setFieldValue("general.venueLon", latLng.lng);
        setFieldValue("general.address", address.address);
        setFieldValue("general.zipCode", address.zipCode);
        setFieldValue("general.city", address.city);
        setFieldValue("general.country", address.country);
        setFieldValue("general.venueDetails", "");
        setFieldTouched("general.address", true);
        setFieldTouched("general.zipCode", true);
        setFieldTouched("general.city", true);
        setFieldTouched("general.country", true);
      }
    }, [setFieldTouched, setFieldValue, values.general.venueLat, values.general.venueLon]);

    return <> </>;
  };

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
  };

  const ViewEvent = useCallback(
    () => (
      <Button
        size="smaller"
        variant="outlined"
        colour="white"
        onClick={() => {
          history.push(`/event/${eventId}`);
        }}>
        {" "}
        View Event{" "}
      </Button>
    ),
    [eventId, history],
  );

  useEffect(() => {
    if (dataPublish) {
      if (dataPublish.status && dataPublish.status === 201) {
        enqueueSnackbar("Your event was successfully published!", {variant: "success", action: ViewEvent});
        setFormData((f) => ({...f, eventInfo: {...f.eventInfo, dirty: false}}));
        setEventInfo((f) => ({...f, dirty: false}));
      } else {
        enqueueSnackbar("Unexpected Error", {variant: "error"});
      }
    }
    // eslint-disable-next-line
  }, [dataPublish]);


  //handle Event access mailing list
  const handleCloseMailList = () => {
    setMailListDialog(false)
  }
  const setSuccessful = () => {
    enqueueSnackbar('Mail list updated successfully!', {variant: 'success'})
    getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
  }   

  useEffect(() => {
    getSessions(api.entities.manage.getSessions(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sessionsData) {
      let privateSessions = []
      sessionsData.sessions.forEach((session) => {
        if (session.isPrivate)
          privateSessions.push(session)
      })
      setSessions(privateSessions)
    }
    //eslint-disable-next-line
  }, [sessionsData])
    
  const handleClose = () => {
    setEditingTicket(false)
    setTicketDialog(false)
    getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
  }

  const setSuccessfulTicket = (refresh) => {
    enqueueSnackbar('Ticket updated successfully!', {variant: 'success'})
    if (refresh) {
      getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
    }
  }

  const editMailList = (mailList) => {
    getMailListData(api.entities.mailLists.get(null, null, {id: mailList.id}))
  }

  useEffect(() => {
    if (mailListData) {
      setEditingMailList(mailListData)
      setMailListDialog(true)
    }
  }, [mailListData])

  const addMailList = () => {
    setEditingMailList(undefined)
    setMailListDialog(true)
  }
  
  const deleteMailList = (mailList) => {
    setDeleteOpenMailList(true)
    setDeletingMailList(mailList)
  }

  const handleDeleteCloseMailList = (mailList) => {
    if (mailList) {
      delReqMailList(api.entities.mailLists.delete(null, null, {id: mailList.id}))
    } else {
      setDeleteOpenMailList(false)
      setDeletingMailList(undefined)
    }
  }

  useEffect(() => {
    if (delMailListData) {
      enqueueSnackbar('Successfully deleted mail list', {variant: 'success'})
      setDeleteOpenMailList(false)
      setDeletingMailList(undefined)
      getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
    }
  }, [delMailListData, enqueueSnackbar, eventId, getData])  

  const handleDeleteCloseTickets = (ticket) => {
    if (ticket) {
      delReqTicket(api.entities.tickets.delete(null, null, {id: ticket.id}))
    } else {
      setDeleteOpenTickets(false)
      setDeletingTicket(undefined)
    }
  }

  useEffect(() => {
    if (delTicketData) {
      enqueueSnackbar('Successfully deleted ticket', {variant: 'success'})
      setDeleteOpenTickets(false)
      setDeletingTicket(undefined)
      getData(api.entities.manage.getGeneral(null, null, {id: eventId}));
    }
  }, [delTicketData, enqueueSnackbar, eventId, getData])

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS','BLOCK_TYPE_DROPDOWN','BLOCK_TYPE_BUTTONS','IMAGE_BUTTON','LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ],

  }

  const onEditorChange = (value) => {
    setEditorValue({value})
  }  

  const downloadQR = () => {
    const canvas = document.getElementById('qrCode')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    let downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${formData.code}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
  
  const generateDoc = (agenda) => {
    const startEndDates = `${format(
      parseISO(event.eventInfo.startDate),
      differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa",
    )} - ${format(parseISO(event.eventInfo.endDate), differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa")}`;

    const doc = new Document({
      sections: [
        // Page 1
        {
          headers: {
            default: new Header({
              // The standard default header
              children: [],
            }),
          },
          footers: {
            default: new Footer({
              // The standard default footer
              children: [
                new Paragraph({
                  text: `${event.eventInfo.name} - Anymeets.com`,
                  alignment: AlignmentType.RIGHT,
                }),
              ],
            }),
          },

          properties: {},
          children: [
            new Paragraph({
              text: event.eventInfo.name,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
              spacing: {
                before: 2500,
                after: 1000,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: startEndDates,
                  size: 28,
                }),
              ],
              spacing: {
                before: 1000,
                after: 2000,
              },
            }),

            ...event.description.split("\n").map((p) => {
              return new Paragraph({text: p, break: true});
            }),

            // new Paragraph({
            //   children: [
            //     new TextRun({
            //       text: event.description,
            //       size: 24}),
            //   ],
            //   spacing: {
            //     before:5
            //   },
            // }),
          ],
        },

        {
          properties: {},
          children: [
            new Paragraph({
              text: `Keynote Speaker${event.keynoteSpeakers.length > 1 ? "s" : ""}`,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 50,
              },
            }),

            ...event.keynoteSpeakers.map((speaker) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                    size: 21,
                  }),
                ],
              });
            }),
          ],
        },

        ...agenda.sessions.sort((a, b) => { return parseISO(a.startDate) - parseISO(b.startDate); }).map((session) => {
          const startEndDates = `${format(parseISO(session.startDate), "d MMMM yyyy, hh:mm aa")} - ${format(parseISO(session.endDate), "hh:mm aa")}`;
          const presentations = session.presentations
            ? session.presentations.map((presentation) => {
                const presenter = presentation.participants.find((p) => p.id === presentation.presenterId);

                return [
                  new Paragraph({
                    text: presentation.title,
                    heading: HeadingLevel.HEADING_3,
                    spacing: {
                      before: 500,
                      after: 50,
                    },
                  }),
                  new Paragraph({
                    text: `Author${presentation.participants.length > 1 ? "s" : ""}`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  ...presentation.participants.map((speaker) => {
                    return new Paragraph({
                      children: [
                        new TextRun({
                          text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                          size: 21,
                        }),
                      ],
                      spacing: {
                        after: 50,
                      },
                    });
                  }),

                  new Paragraph({
                    text: `Presenter`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  new Paragraph({
                    children: [
                      new TextRun({
                        text: presenter ? `${presenter.title ? presenter.title + " " : ""}${presenter.lastName}, ${presenter.firstName}, ${presenter.profession}, ${presenter.instituteOrCompany}` : "-",
                        size: 21,
                      }),
                    ],
                    spacing: {
                      after: 50,
                    },
                  }),

                  new Paragraph({
                    text: `Abstract`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  // new Paragraph({
                  //   spacing: {
                  //     before: 50,
                  //   },
                  //   children: [
                  //     new TextRun({
                  //       text: presentation.abstract.replace(/[^ -~][^\n]+/g, ""),
                  //       size: 24,
                  //     })
                  //   ],
                  // }),

                  ...presentation.abstract.split("\n").map((p) => {
                    return new Paragraph({
                      spacing: {
                        before: 50,
                      },
                      children: [
                        new TextRun({
                          // text: p.replace(/[^ -~][^\n]+/g, ""),
                          text:  p.replace(/[^ -~]+/g, " "),
                          size: 24,
                        }),
                      ],
                    });
                  }),
                ];
              })
            : [];

          return {
            properties: {},
            children: [
              new Paragraph({
                text: session.title,
                heading: HeadingLevel.HEADING_1,
              }),

              new Paragraph({
                spacing: {
                  before: 50,
                },
                children: [
                  new TextRun({
                    text: startEndDates,
                    size: 28,
                    bold: true,
                  }),
                ],
              }),

              new Paragraph({
                border: {
                  bottom: {
                    color: "auto",
                    space: 0,
                    value: "single",
                    size: 4,
                  },
                },
              }),

              new Paragraph({
                text: "Description",
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 300,
                },
              }),

              ...session.description.split("\n").map((p) => {
                return new Paragraph({text: p, break: true});
              }),

              // new Paragraph({
              //   spacing: {
              //     before: 50,
              //   },
              //   text: session.description.replace(/\r?\n/g, "\r\n"),
              //   // children: [
              //   //   // new TextRun({
              //   //   //   text: session.description.replace(/\r?\n/g, "\r\n"),
              //   //   //   size: 24,
              //   //   // })
              //   // ],
              // }),

              new Paragraph({
                text: "Speakers",
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 500,
                  after: 50,
                },
              }),

              ...session.participants.map((speaker) => {
                return new Paragraph({
                  children: [
                    new TextRun({
                      text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                      size: 21,
                    }),
                  ],
                  spacing: {
                    after: 50,
                  },
                });
              }),

              new Paragraph({
                text: `Presentation${session.presentations.length > 1 ? "s" : ""}`,
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 500,
                  after: 50,
                },
              }),

              new Paragraph({
                border: {
                  bottom: {
                    color: "auto",
                    space: 0,
                    value: "single",
                    size: 4,
                  },
                },
              }),

              ...[].concat.apply([], presentations),
            ],
          };
        }),

        {
          properties: {},
          children: [
            new Paragraph({
              text: `List of Speakers`,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 100,
              },
            }),

            ...participantsData.participants
              .sort(function (a, b) {
                let textA = a.lastName.toUpperCase();
                let textB = b.lastName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((speaker) => {
                return new Paragraph({
                  children: [
                    new TextRun({
                      text: `${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                      size: 21,
                    }),
                  ],
                  spacing: {
                    after: 100,
                  },
                });
              }),
          ],
        },

        //   {
        //   properties: {},
        //   children: [
        //     new Paragraph({
        //       children: [
        //         new TextRun("Hello World"),
        //         new TextRun({
        //           text: JSON.stringify(agenda),
        //           bold: true,
        //         }),
        //       ],
        //     }),
        //   ],
        // }
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${event.eventInfo.name}.docx`);
    });
  };


  if (!formData) return <> </>;

  const isSubmitting = (isLoading && !hasError) || loadingMailList || loadingDelTickets || loadingDelMailList;
  
  return (
    <Box>
      <Formik
        enableReinitialize
        initialValues={{
          ...formData,
          access: {
            ...formData.access,
            sessionViewerAccess: formData.access.sessionViewerAccess,
            proceedingsAccess: formData.access.proceedingsAccess,
          },
        }}
        validationSchema={validationSchema}
        onSubmit={(values, {setSubmitting,resetForm}) => {
          let {eventInfo, ...restValues} = values;
          setFormSave({ ...restValues });
           resetForm();
        }}>
        {({dirty, errors, submitForm, setFieldValue, values, setFieldTouched, validateField, touched}) => (
          <Fade in={true} timeout={1200}>
            <Form className={classes.form}>
              <Box mt={4} minWidth={"100%"} maxWidth={1180}>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      {language.details}
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Add basic details about the event. Name and Acronym are required." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={7}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field type="text" name="general.name" component={TextField} label="Event Name" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                      <Grid item xs={6}>
                        <Field type="text" name="general.acronym" component={TextField} label="Event Acronym" variant="filled" fullWidth disabled={isSubmitting} validate={(v) => isAvailable(v, validateField)} />
                        {/*{acronymAvailability&&acronymAvailability.available===false&&touched.general&&touched.general.acronym&&<FormHelperText error variant='filled'> Acronym is already in use </FormHelperText>}*/}
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl className={classes.formControl}>
                          <Field type="select" labelId="categoryLabel" displayEmpty name="general.category" component={Select} label="Category" variant="filled" fullWidth disabled={isSubmitting} defaultValue="">
                            {Object.keys(CATEGORIES).map((key, i) => {
                              return (
                                <MenuItem value={key} key={i}>
                                  {CATEGORIES[key]}
                                </MenuItem>
                              );
                            })}
                          </Field>
                          <InputLabel variant="filled">{language.category}</InputLabel>
                          {errors && errors.general && errors.general.category && touched.general && touched.general.category && (
                            <FormHelperText error variant="filled">
                              {" "}
                              {errors.general.category}{" "}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Field type="text" name="general.description" component={TextField} multiline rows="7" label="Description" variant="filled" fullWidth disabled={isSubmitting} maxLength={SIZE_LENGTH_DESCRIPTION} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Access
                    </Typography>
                    <p></p>
                    <Typography component="h2" variant="h6" className={classes.subtitle}>
                      Event Access
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    <Box display="flex" flexDirection="row" mt={6}>
                      <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                        <Box mr={2}>
                          <OptionCard
                            text="Public"
                            icon={<IconSpeakersBorderDark />}
                            onClick={() => {
                              setFieldValue("access.access", 1);
                            }}
                            selected={values.access.access === 1}
                          />
                          <Box className={classes.description}>This event is open to everyone
                          <Tooltip classes={{tooltip: classes.tooltip}} title="All users can access the event. The event is also visible on search results" placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                          
                          </Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Private"
                            icon={<IconLockBorderDark />}
                            onClick={() => {
                              setFieldValue("access.access", 2);
                            }}
                            selected={values.access.access === 2}
                          />
                          <Box className={classes.description}>Use the event code to access this event
                          <Tooltip classes={{tooltip: classes.tooltip}}  title="To access this event, the users must use the code provided on the bottom of this page. The event is not visible on search results." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                             
                          </Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Email List"
                            icon={<IconMailBorderDark />}
                            onClick={() => {
                              setFieldValue("access.access", 3);
                            }}
                            selected={values.access.access === 3}
                          />
                          <Box className={classes.description}>This type of event requires to sign up with email</Box>
                        </Box>
                      </Box>
                    </Box>
                    {values.access.access === 3 && (
                      <Fade in={true} timeout={TIME_FADE_IN}>
                        <Box display="flex" flexDirection="row" mt={8} mb={4}>
                          {/* <Box width={200} flexDirection="row">
                            <Typography component='h2' variant='h6' className={classes.subtitle}>Email list</Typography>
                         </Box> */}
                          <Box display="flex" flexDirection="column">
                          <Typography component='h2' variant='h6' className={classes.subtitle}>Email list</Typography><br/>
                            {formData.access.mailLists.map((mailList, i) => {
                              return <MailListCard mailList={mailList} editAction={() => editMailList(mailList)} deleteAction={() => deleteMailList(mailList)} key={i} pb={2} />;
                            })}
                            {formData.access.mailLists.length === 0 ? (
                              <Box display="flex" height={130} className={classes.noTicketsYet}>
                                No mailing lists yet!
                              </Box>
                            ) : (
                              ""
                            )}
                            <Box pt={1} width="100%" justifyContent="flex-end" display="flex">
                              <Box p={1}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    addMailList();
                                  }}
                                  icon={<IconAddBorderBlue />}>
                                  {" "}
                                  Add New{" "}
                                </Button>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Fade>
                    )}
                  </Grid>
                  {data?.general?.activateSubmissions && (<><Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2}>
                      <Typography component="h2" variant="h6" className={classes.subtitle}>
                        Show proceedings
                        <Tooltip classes={{tooltip: classes.tooltip}} title="By the term 'Proceedings' we refer to the list of available presentations, including information about their papers and authors" placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                          
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                          <Box mr={2}>
                            <OptionCard
                              text="Public"
                              icon={<IconSpeakersBorderDark />}
                              onClick={() => {
                                setFieldValue("access.showProceedings", 1);
                              }}
                              selected={values.access.showProceedings === 1}
                            />
                            <Box className={classes.description}>Proceedings are visible to everyone</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              text="Private"
                              icon={<IconLockBorderDark />}
                              onClick={() => {
                                setFieldValue("access.showProceedings", 2);
                              }}
                              selected={values.access.showProceedings === 2}
                            />
                            <Box className={classes.description}>Proceedings are not visible</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              text="Registered Only"
                              icon={<PermContactCalendarIcon />}
                              disabled={values.access.registration === 1}
                              onClick={() => {
                                values.access.registration === 1 ? setFieldValue() : setFieldValue("access.showProceedings", 3);
                              }}
                              selected={values.access.showProceedings === 3}
                            />
                            <Box className={classes.description}>Proceedings are open only to registered users                             
                            {values.access.registration === 1 && <Tooltip classes={{tooltip: classes.tooltip}} title="To unlock this option enable registrations on 'Tickets & Registrations page' " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                            </Tooltip>}                              
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2}>
                      <Typography component="h2" variant="h6" className={classes.subtitle}>
                        Proceedings files
                        <Tooltip classes={{tooltip: classes.tooltip}} title="Select if proceeding files will be visible to users. This doesn't affect presentation or author info available on the proceedings." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                        
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box display="flex" flexDirection="row" className={clsx(values.access.showProceedings === 2 && classes.notApplicable, classes.animated)}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                          <Box mr={2}>
                            <OptionCard
                              disabled={values.access.showProceedings===2 || values.access.showProceedings===3}
                              text="Public"
                              icon={<IconSpeakersBorderDark />}
                              onClick={() => {
                                setFieldValue("access.proceedingsAccess", 1);
                              }}
                              selected={values.access.proceedingsAccess === 1}
                            />
                            <Box className={classes.description}>Proceedings files are visible to everyone</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              text="Private"
                              disabled={values.access.showProceedings===2}
                              icon={<IconLockBorderDark />}
                              onClick={() => {
                                setFieldValue("access.proceedingsAccess", 2);
                              }}
                              selected={values.access.proceedingsAccess === 2}
                            />
                            <Box className={classes.description}>Proceedings files are not visible</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              text="Registered Only"
                              icon={<PermContactCalendarIcon />}
                              disabled={values.access.registration === 1 || values.access.showProceedings===2}
                              onClick={() => {
                                setFieldValue("access.proceedingsAccess", 3);
                              }}
                              selected={values.access.proceedingsAccess === 3}
                            />
                            <Box className={classes.description}>Proceedings files are open only to registered users
                            {values.access.registration === 1 && <Tooltip classes={{tooltip: classes.tooltip}} title="To unlock this option enable registrations on 'Tickets & Registrations page' " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>}                              
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid></>)
                  }
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2}>
                      <Typography component="h2" variant="h6" className={classes.subtitle}>
                        Show presentations list
                      </Typography>
                      <Typography component="p" variant="p" className={classes.ptitle}>
                        This affects the Agenda listing in your event page.
                        <Tooltip classes={{tooltip: classes.tooltip}} title="Select if you want the presentation's information to be visible at the session's card." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                          
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box display="flex" flexDirection="row">
                        <Box mr={2}>
                          <OptionCard
                            text="Public"
                            icon={<IconSpeakersBorderDark />}
                            onClick={() => {
                              setFieldValue("access.presentationsAccess", 1);
                            }}
                            selected={values.access.presentationsAccess === 1}
                          />
                          <Box className={classes.description}>Presentations are visible to everyone</Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Private"
                            icon={<IconLockBorderDark />}
                            onClick={() => {
                              setFieldValue("access.presentationsAccess", 2);
                            }}
                            selected={values.access.presentationsAccess === 2}
                          />
                          <Box className={classes.description}>Presentations are not visible</Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Registered Only"
                            icon={<PermContactCalendarIcon />}
                            disabled={values.access.registration === 1}
                            onClick={() => {
                              setFieldValue("access.presentationsAccess", 3);
                            }}
                            selected={values.access.presentationsAccess === 3}
                          />
                          <Box className={classes.description}>Presentations are open only to registered users
                          {values.access.registration === 1 && <Tooltip classes={{tooltip: classes.tooltip}} title="To unlock this option enable registrations on 'Tickets & Registrations page' " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>}                              
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2}>
                      <Typography component="h2" variant="h6" className={classes.subtitle}>
                        Presentation files
                      </Typography>
                      <Typography component="p" variant="p" className={classes.ptitle}>
                        This affects the Agenda listing in your event page.
                        <Tooltip classes={{tooltip: classes.tooltip}} title="Select if you want the presentation's files to be visible at the session's card." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                         
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box display="flex" flexDirection="row" className={clsx(values.access.presentationsAccess === 2 && classes.notApplicable, classes.animated)}>
                        <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center">
                          <Box mr={2}>
                            <OptionCard
                              disabled = {values.access.presentationsAccess ===2 || values.access.presentationsAccess===3}
                              text="Public"
                              icon={<IconSpeakersBorderDark />}
                              onClick={() => {
                                setFieldValue("access.showPresentationFiles", 1);
                              }}
                              selected={values.access.showPresentationFiles === 1}
                            />
                            <Box className={classes.description}>Presentation files are visible to everyone</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              disabled = {values.access.presentationsAccess ===2 }
                              text="Private"
                              icon={<IconLockBorderDark />}
                              onClick={() => {
                                setFieldValue("access.showPresentationFiles", 2);
                              }}
                              selected={values.access.showPresentationFiles === 2}
                            />
                            <Box className={classes.description}>Presentations files are not visible</Box>
                          </Box>
                          <Box mr={2}>
                            <OptionCard
                              text="Registered Only"
                              icon={<PermContactCalendarIcon />}
                              disabled={values.access.registration === 1 || values.access.presentationsAccess ===2}
                              onClick={() => {
                                setFieldValue("access.showPresentationFiles", 3);
                              }}
                              selected={values.access.showPresentationFiles === 3}
                            />
                            <Box className={classes.description}>Presentation files are open only to registered users
                            {values.access.registration === 1 && <Tooltip classes={{tooltip: classes.tooltip}} title="To unlock this option enable registrations on 'Tickets & Registrations page' " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>}                              
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className={classes.section}>
                    <Grid item xs={12} md={2}>
                      <Typography component="h2" variant="h6" className={classes.subtitle}>
                        Access to sessions
                        <Tooltip classes={{tooltip: classes.tooltip}} title="Select if users can access the sessions through the agenda calendar " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                        
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                      <Box display="flex" flexDirection="row">
                        <Box mr={2}>
                          <OptionCard
                            text="Open"
                            onClick={() => {
                              setFieldValue("access.sessionViewerAccess", 1);
                            }}
                            selected={values.access.sessionViewerAccess === 1}
                          />
                          <Box className={classes.description}>All users can access sessions page</Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Closed"
                            onClick={() => {
                              setFieldValue("access.sessionViewerAccess", 2);
                            }}
                            selected={values.access.sessionViewerAccess === 2}
                          />
                          <Box className={classes.description}>Users cannot access sessions page</Box>
                        </Box>
                        <Box mr={2}>
                          <OptionCard
                            text="Registered users only"
                            onClick={() => {
                              setFieldValue("access.sessionViewerAccess", 3);
                            }}
                            disabled={values.access.registration === 1}
                            selected={values.access.sessionViewerAccess === 3}
                          />
                          <Box className={classes.description}>Only registered users can access sessions page
                          {values.access.registration === 1 && <Tooltip classes={{tooltip: classes.tooltip}} title="To unlock this option enable registrations on 'Tickets & Registrations page' " placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip> }                           
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      {language.dateAndTime}
                      <Tooltip classes={{tooltip: classes.tooltip}} title="By changing event's timezone, all other time-based procedures like paper submissions timeline, sessions, tickets etc, will NOT be affected. Remember to MANUALLY update all related date-times." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                      
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} item xs={12} md={8}>
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl className={classes.formControl}>
                        <Field type="select" labelId="timezoneId" displayEmpty name="general.timezoneId" component={Select} label={language.timezone} variant="filled" fullWidth disabled={isSubmitting}>
                          {timeZones &&
                            timeZones.map((zone) => {
                              return (
                                <MenuItem
                                  key={zone.id}
                                  value={zone.id}
                                  onClick={() => {
                                    setSelectedTimezone(zone.id);
                                    setFieldValue("general.startDate", moment(values.general.startDate).tz(zone.name, true));
                                    setFieldValue("general.endDate", moment(values.general.endDate).tz(zone.name, true));
                                    //console.log(moment(values.general.startDate).tz(zone.name, true).toString());
                                    //console.log(moment(values.general.endDate).tz(zone.name, true).toString());
                                  }}>
                                  {zone.name.replace("_", " ")}
                                </MenuItem>
                              );
                            })}
                        </Field>
                        <InputLabel variant="filled">{language.timezone}</InputLabel>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} item xs={12} sm={12} md={12}>
                      <Grid item xs={12} sm={6} md={6}>
                        <DatePicker
                          format="DD/MM/yyyy"
                          autoOk={true}
                          okLabel=""
                          name="general.startDate"
                          label={language.startDate}
                          //label={values.general.startDate}
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("general.startDate", value);
                          }}
                          value={values.general.startDate}
                          fullWidth
                          disabled={isSubmitting}
                          // minDate={currentDate}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconCalendarBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TimePicker
                          name="general.startDate"
                          label={language.startTime}
                          autoOk={true}
                          okLabel=""
                          //label={values.general.startDate}
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("general.startDate", value.toISOString());
                            //console.log(value.toString());
                          }}
                          value={values.general.startDate}
                          fullWidth
                          disabled={isSubmitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconClockBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <DatePicker
                          format="DD/MM/yyyy"
                          minDate={values.general.startDate}
                          name="general.endDate"
                          label={language.endDate}
                          //label={values.general.endDate}
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("general.endDate", value);
                          }}
                          value={values.general.endDate}
                          fullWidth
                          autoOk={true}
                          okLabel=""
                          disabled={isSubmitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconCalendarBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TimePicker
                          minDate={values.general.startDate}
                          name="general.endDate"
                          label={language.endTime}
                          autoOk={true}
                          okLabel=""
                          //label={values.general.endDate}
                          inputVariant="filled"
                          onChange={(value) => {
                            setFieldValue("general.endDate", value.toISOString());
                            //console.log(value.toString());
                          }}
                          value={values.general.endDate}
                          fullWidth
                          disabled={isSubmitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconClockBorderDark />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm={2} md={4}>
                        {" "}
                      </Grid>
                      {errors && errors.general && errors.general.endDate && (
                        <>
                          <Grid item xs={2}>
                            {" "}
                          </Grid>
                          <Grid item xs={10}>
                            <FormHelperText error> {language.theEndingDateTimeMustBeLaterThanTheStartingDateTime}</FormHelperText>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Location
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Select if the users have to attend the event in a venue or is this on online event." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                        
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    {language.isThisAnOnlineOnlyEvent}
                    <Field type="checkbox" name="general.online" component={Switch} disabled={isSubmitting} />
                  </Grid>
                </Grid>

                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    {!values.general.online ? (
                      <>
                        <Typography component="p" variant="p" className={classes.subtitle}>
                          {language.venueLocation}
                        </Typography>
                        <Typography component="p" variant="p" className={classes.ptitle}>
                          {language.typeAddressCityOrZipCodeToLocatEvenueMap}
                        </Typography>
                      </>
                    ) : (
                      <>
                        {/* <Typography component="p" variant="p" className={classes.subtitle}>
                          {language.link}
                        </Typography>
                        <Box className={classes.note} pt={1} width={130}>
                          {language.addaConferenceLinkForAnOnlineMeeting}
                        </Box> */}
                      </>
                    )}
                  </Grid>

                  <Grid item xs={12} md={7}>
                    {!values.general.online && (
                      <>
                        <Box mb={2} className={classes.searchBox}>
                          <MapSearchBox
                            setLatLng={setLatLng}
                            setVenueName={(name) =>
                            {
                              setFieldValue("general.venueName", name);
                            }}
                            setVenueAddress={(address) =>
                            {
                              setFieldValue("general.address", address);                              
                            }}
                          />
                        </Box>
                        <Box>
                          <Map latLng={latLng} width="100%" height="300px" setLatLng={setLatLng} setAddress={setAddress} address={address} />
                          <DummyUpdateComp setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} values={values} />
                        </Box>
                      </>
                    )}
                    {/* {values.general.online && (
                      <Grid item md={7} xs={12}>
                        <Field type="text" name="general.conferenceLink" component={TextField} label="Conference link" variant="filled" fullWidth disabled={isSubmitting} />
                      </Grid>
                    )} */}
                  </Grid>

                  <Grid item md={3} xs={12}></Grid>

                  {!values.general.online && (
                    <>
                      <Grid item xs={12} md={2}>
                        <Typography component="p" variant="p" className={classes.ptitle}>
                          {language.fillinAddressDetailsIfNecessary}
                        </Typography>
                      </Grid>
                      <Grid container spacing={2} item xs={12} md={9}>
                        <Grid item xs={12} md={10}>
                          <Field type="text" name="general.venueName" component={TextField} label={language.venueName} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>

                        <Grid item xs={12} md={10}>
                          <Field type="text" name="general.venueDetails" component={TextField} label={language.venueDetails} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Field type="text" name="general.address" component={TextField} label={language.address} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>

                        <Grid item xs={12} md={3}>
                          <Field type="text" name="general.city" component={TextField} label={language.city} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>

                        <Grid item xs={12} md={2}>
                          <Field type="text" name="general.zipCode" component={TextField} label={language.zipCode} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>

                        <Grid item xs={12} md={5}>
                          <Field type="text" name="general.country" component={TextField} label={language.country} variant="filled" fullWidth disabled={isSubmitting} />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      {language.eventURL}                       
                    </Typography>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item xs={12} md={2}>
                    <Typography component="p" variant="p" className={classes.subtitle}>
                      Anymeets URL
                      <Tooltip classes={{tooltip: classes.tooltip}} title="This is the URL, users have to visit to access the event and all information. *Users have to register an account to be able to access." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                       
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} md={10}>
                    <Grid item xs={12} md={10}>
                      <Typography variant="p" className={classes.anyurl}>
                        https://www.anymeets.com/
                      </Typography>
                      <Field
                        type="text"
                        size="small"
                        name="general.slug"
                        component={TextField}
                        label={language.customURL}
                        variant="filled"
                        disabled={isSubmitting}
                        style={{maxWidth: "410px"}}
                        fullWidth
                        // InputProps={{
                        //   startAdornment: <InputAdornment position="start">https://www.anymeets.com/</InputAdornment>,
                        // }}
                      />
                      <p>
                        <i> {parse(language.yourEventWillBeAssignedTheURLAnymeetscom)}</i>
                      </p>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.subtitle}>
                      {language.webUrl}
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Enter an non Anymeets URL, as an alternative website for the event (optional)." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                          </Tooltip>                       
                    </Typography>
                  </Grid>

                  <Grid container item xs={12} md={10}>
                    <Grid item xs={12} md={7}>
                      <Field type="text" name="general.host" component={TextField} label={language.webUrl} variant="filled" fullWidth disabled={isSubmitting} />
                      <p>
                        <i> {parse(language.pleaseMakeSureYouOwnTheDomainYouWouldLikeToAssociateWithYourEvent)}</i>
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      {language.speakers}
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Select if regular and Keynote Speakers are visible to non Registered Users at the event's page." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                      
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    {language.showSpeakersForNonRegisteredUsers}
                    <Field type="checkbox" name="general.showSpeakers" component={Switch} disabled={isSubmitting} />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} sm={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Email setup
                      <Tooltip classes={{tooltip: classes.tooltip}} 
                      title={<>{["This information is used in Email Communication page, for sending emails to event users.",<br/>,<br/>, "  'Sender Name' is the name of the person who sends the email",<br/>,<br/>,  "  'Sender Email' is the email address that the recipients will see when they receive your email",<br/>,<br/>, "  'Reply to' is the email address in which the recipients will reply to your email."]}</>} 
                      placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                      
                    </Typography>
                  </Grid>
                  <Grid container spacing={1} item xs={12} md={10}>
                    <Grid item xs={12} sm={12} md={6}>
                      <Field type="text" name="communicationSettings.sender" component={TextField} label="Sender Name" variant="filled" fullWidth disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={12}>
                      {" "}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Field type="email" name="communicationSettings.from" component={TextField} label="Sender Email" variant="filled" fullWidth disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={12}>
                      {" "}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Field type="email" name="communicationSettings.replyTo" component={TextField} label="Reply to:" variant="filled" fullWidth disabled={isSubmitting} />
                    </Grid>

                    <Grid item xs={12}>
                      {" "}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      {/* <Field type='email' name='communicationSettings.bcc' component={TextField} label='BCC:' variant='filled' fullWidth disabled={isSubmitting}/> */}
                    </Grid>

                    <Grid item sm={8}>
                      {" "}
                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                      <Box mb={1} ml={0.5}>
                        {" "}
                        Email signature{" "}
                      </Box>
                      <RichTextEditor className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v) => onEditorChange(v)} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2} style={{paddingTop: 24}}>
                    <Typography component="h3" variant="h6" className={classes.title}>
                      Event Code
                      <Tooltip classes={{tooltip: classes.tooltip}} title="This code is used by the users to access private events and for easy access on public events." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                       
                    </Typography>
                    <Typography component="p" variant="p" className={classes.ptitle}>
                      Event codes are generated automatically and cannot be changed
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} item xs={12} md={10}>
                    <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
                      <Box display="flex" alignContent="center" justifyContent="center" flexDirection="column" className={classes.eventInfo} p={3}>
                        <Box display="flex" alignContent="center" justifyContent="center" flexDirection="row" mb={2}>
                          <Box width={250}>
                            <Box fontWeight="bold" fontSize="20px" mb={1}>
                              ID code
                            </Box>
                            <Box className={classes.description} pr={6}>
                              Share this code with your attendee list to help them join your event
                            </Box>
                          </Box>
                          <Box display="flex" alignContent="center" justifyContent="center">
                            <Box className={classes.eventCode} display="flex" alignItems="center" alignContent="center" justifyContent="center" flexDirection="column">
                              <Box display="flex">{formData.access.code}</Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box display="flex" alignContent="center" justifyContent="center" flexDirection="row">
                          <Box width={250}>
                            <Box fontWeight="bold" fontSize="20px" mb={1}>
                              QR code
                            </Box>
                            <Box className={classes.description} pr={6}>
                              Use this code to your promo material to help attendees find your event
                            </Box>
                            <Box mt={2}>
                              <Button
                                colour="white"
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  downloadQR();
                                }}>
                                Download
                              </Button>
                            </Box>
                          </Box>
                          <Box display="flex" alignContent="center" justifyContent="center">
                            <Box className={classes.eventQrCode} display="flex" alignItems="center" alignContent="center" justifyContent="center" flexDirection="column">
                              <Box display="flex">
                                <QRCode value={`https://${DOMAIN}/event/${formData.access.code}`} size={160} id="qrCode" />
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Hide Event
                      <Tooltip classes={{tooltip: classes.tooltip}} title="This option hides the event from search results and block access by the event code." placement="top" arrow>
                              <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                        </Tooltip>                       
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    Hide event from search results
                    <Field type="checkbox" name="general.hiddenEvent" component={Switch} disabled={isSubmitting} />
                  </Grid>
                </Grid> 
                <Grid container spacing={2} className={classes.section}>
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Upgrade to Full Package
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Full Package contains Paper Submissions and Paper Reviews functionality." aria-label="Full Package contains Paper Submissions and Paper Reviews functionality." arrow placement="top">
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large"  /></Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>
                    Upgrade
                    <Field type="checkbox" name="general.activateSubmissions" component={Switch} disabled={isSubmitting} />
                  </Grid>
                </Grid>                
                <Grid container spacing={2} className={classes.section}>
                  {/* <Grid item xs={12} md={2} style={{paddingTop: 24}}>
                    <Typography component="h3" variant="h6" className={classes.title}>
                      {language.submissions}
                    </Typography>
                  </Grid>


                  <Grid container item xs={12} md={10} style={{paddingTop: 24}}>
                    <Grid item container spacing={2} justify="flex-start" alignContent="center" alignItems="center">
                      <Grid item xs={4}>
                        <Field type="text" name="general.submissionFlowLabel" component={TextField} label={language.submissionFlowLabel} variant="filled" fullWidth disabled={isSubmitting} validate={(v) => isAvailable(v, validateField)} />
                      </Grid>
                      <Grid item xs={4}>
                        <Field type="text" name="general.submissionFlowButtonLabel" component={TextField} label={language.submissionFlowButtonLabel} variant="filled" fullWidth disabled={isSubmitting} validate={(v) => isAvailable(v, validateField)} />
                      </Grid>                      
                    </Grid>

                  {/* <Grid item xs={12} md={2} style={{paddingTop: 24}}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      {language.exportAgenda}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10} style={{paddingTop: 24}}>
                    <Grid item container justify="flex-start" alignContent="center" alignItems="center">
                      <Grid item>
                        <Button size="smaller" color="primary" onClick={() => generateDoc(agendaData)} disabled={!(agendaData && event && participantsData)}>
                          <DescriptionOutlinedIcon /> &nbsp; {language.exportAgendaAsDocx}
                        </Button>
                      </Grid>
                    </Grid>

                  </Grid>*/}
                  {(dirty || eventInfo.dirty) && (                    
                    <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons}  style={{position: flow ? "absolute" : null, top: flow ? "0" : null}}>
                    {eventInfo && eventInfo.dirty ? (
                      <Alert className={classes.topPublish} severity="warning">
                        <AlertTitle>Unpublished changes</AlertTitle>
                        You are viewing an event with unpublished changes.
                      </Alert>
                    ) : (
                      <Alert className={classes.topPublish} severity="warning">
                        <AlertTitle>Save changes</AlertTitle>
                        Remember to save your changes before you leave this page.
                      </Alert>
                    )}
                    {dirty && <Box display="inline-block" mt={1} mb={1}>
                      <Button type="submit" variant="outlined" color="primary">
                        Save Changes
                      </Button>
                    </Box>}
                    &nbsp;&nbsp;&nbsp;
                    <Box display="inline-block" mt={1} mb={1}>
                      {eventInfo.dirty ? (
                        <Button
                          onClick={() => {
                            publishEvent();
                          }}>
                          Publish
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>
                  )}                  
                </Grid>
              </Box>
              <Dialog onClose={handleClose} open={newTicketDialog} fullWidth maxWidth="sm">
                <TicketTypeEditNew unlimited={values.registration === 2} handleClose={handleClose} ticket={editingTicket} eventId={eventId} eventInfo={eventInfo} setSuccessful={setSuccessfulTicket} sessions={sessions} />
              </Dialog>

              <Dialog onClose={handleCloseMailList} open={mailListDialog}>
                <MailListEditNew unlimited={values.registration === 2} handleClose={handleCloseMailList} mailList={editingMailList} eventId={eventId} setSuccessful={setSuccessful} />
              </Dialog>
              {errors && errors.general && touched.general &&                 
                 enqueueSnackbar( Object.keys(errors.general) +  (Object.keys(errors.general).length < 2 ? ' is required ':' are required '), { variant: 'error', preventDuplicate: true })              
              }
            </Form>
          </Fade>
        )}
      </Formik>

      <Dialog
        open={deleteOpenMailList}
        onClose={() => {
          handleDeleteCloseMailList();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete mail list</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete the mailing list?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteCloseMailList()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteCloseMailList(deletingMailList);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={deleteOpenTickets} onClose={() => handleDeleteCloseTickets()} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete ticket</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete the ticket?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteCloseTickets()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteCloseTickets(deletingTicket);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
});

export default withRouter(General);
