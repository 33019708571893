import {makeStyles} from '@material-ui/styles'

export const discussion_4 = makeStyles({

  item_1: {
    left: '1%',
    top: '2%',
    width: '48.5%',
    height: '47%',
    opacity: '1 !important'
  },

  item_2: {
    left: '50.5%',
    top: '2%',
    width: '48.5%',
    height: '47%',
    opacity: '1 !important'
  },

   item_3: {
    left:' 1%',
    top: '51%',
    width: '48.5%',
    height: '47%',
     opacity: '1 !important'
  },

  item_4: {
    left: '50.5%',
    top: '51%',
    width: '48.5%',
    height: '47%',
    opacity: '1 !important'
  }

})
