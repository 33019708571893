import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import React from 'react'
// import {format} from 'date-fns'
import {palette} from 'theme'
// import parseISO from 'date-fns/parseISO'
import Button from 'components/core/Button'
import {Link, useParams} from 'react-router-dom'
// import PostAddIcon from '@material-ui/icons/PostAdd'
import NoteIcon from '@material-ui/icons/Note';
import Grid from '@material-ui/core/Grid'
import { checkHost } from 'utils'
import language from "components/language";

const styles = (theme) => ({
  info: {
    backgroundColor: theme.palette.background.primary,
    color: theme.dark ? "white" : theme.palette.text.primary,
    fontWeight: "bold",
    borderRadius: "12px",
    zIndex: 999,
    textAlign: "center",
  },
  submitButton: {
    lineHeight: "20px",
  },
});

// const ListItem = withStyles(styles)(({classes, icon, text}) => {
//     return (
//         <Box
//             display="flex"
//             flexDirection="row"
//             flexBasis={'100%'}
//             width="100%"
//             mb={2}
//             alignItems="center"
//             justifyContent="flex-start"
//             className={classes.listRow}
//         >
//             <Box display="flex">{icon}&nbsp&nbsp</Box>
//             <Box display="flex">{text}</Box>
//         </Box>
//     )
// })

const ReviewSubmissionInfo = withStyles(styles)(
    ({title, value, event, classes}) => {

      const {slug} = useParams()
      const host = checkHost()

      let baseURL = `/event/${event.eventInfo.id}`

      if (slug) {
        baseURL = `/${slug}`
      } else if (host) {
        baseURL = ``
      }

        return (
          <>
            <Box display="flex" className={classes.info} p={palette.infoPadding} flexDirection="column" justifyContent="center" alignItems="center" m={palette.infoPadding}>
              <Grid item container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item>
                  <NoteIcon />
                  &nbsp;
                </Grid>
                <Grid item style={{fontSize: 16, fontWeight: "bold", marginLeft: "8px"}}>
                  {language.reviewSubmissions}
                </Grid>
              </Grid>
              <Box display="flex" mt={2}>
                <Button size="smaller" style={{fontSize: "15px", textAlign: "center", lineHeight: "15px"}} color="secondary" component={Link} to={`${baseURL}/review-submissions`}>
                  {language.submitReviews}
                </Button>
              </Box>
            </Box>
          </>
        );
    }
)

export default ReviewSubmissionInfo
