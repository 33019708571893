import React, {useEffect,  useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {useHttp} from 'api/core'
import {useParams, withRouter} from 'react-router-dom'
import {useMetaData} from 'components/core/MetaProvider'
import Header from 'components/core/Header/Header'
import api from 'api'
import Box from '@material-ui/core/Box'
import RoomIntegration from 'components/core/RoomIntegration'
import SponsorCardView from 'components/core/SponsorCardView'
import Grid from '@material-ui/core/Grid'
import {checkHost, imageFile, withHttp} from 'utils'
import Chip from 'components/core/Chip'
import {SPONSOR_TYPES} from 'config'
import Typography from '@material-ui/core/Typography'
import { palette } from 'theme'
import language from "components/language";

const styles = theme => ({
    container: {
        display: 'flex',
        position: 'fixed',
        width: 'calc(100% - 354px)',
        height: 'calc(100vh - 64px)',
        borderRight: `1px solid #cdcdcd`
    },
    mainContainer: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: 'calc(100vh - 68px)',
    },
    sideBar: {
        position: 'absolute',
        top: '16px',
        right: 0,
        height: '100vh'
    },
    logo: {
        display: 'flex',
        width: '180px',
        height: '180px',
        backgroundColor: 'white',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: '6px',
        '& img': {
            display: 'flex',
            width: '100%',
            height: 'auto',
            backgroundColor: palette.imageBackgroundColor
            , [theme.breakpoints.down('sm')]: {
                margin: '0 auto'
            }
        }
    },
    sponsorUrl: {
        color: `${palette.aquamarine} !important`
    },
})


const Sponsor = withStyles(styles)(({classes, history, setProfileDialogOpen}) => {

    const [canManage, setCanManage] = useState(false)
    const [getData, data] = useHttp()
    const [, setMetaData] = useMetaData()

    const {id,slug} = useParams()

    const host = checkHost()

    let baseURL = `/event/${id}`

    if (slug) {
        baseURL = `/${slug}`
    } else if (host) {
        baseURL = ``
    }

    const redirectToInfo = (id) => {
        document.location.href=`${baseURL}/info`
    }

    // Initial Load
    useEffect(() => {
        getData(api.entities.sponsors.viewOne(null, null, {id}))

        //eslint-disable-next-line
        zE('webWidget', 'updateSettings',{
            webWidget: {
                offset: {
                    horizontal: '177px',
                    vertical: '6px'
                }
            }
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data) {
            setMetaData({title: data.eventInfo.name})
            setCanManage(data.eventInfo.role === 1 || data.eventInfo.role === 2)
        }
        // eslint-disable-next-line
    }, [data])

    const createMarkup = (value) => {
        return {__html: value}
    }

    if (!data) return <> </>
    const sponsor = data.sponsor
    return <Page noFooter>
        <Header setProfileDialogOpen={setProfileDialogOpen} event={data} isEvent extras={[]} canManage={canManage} isSponsor isSponsorHost={data.host} isHost={data.host} />

        {data.online&&<>
            {/*<Grid container>*/}
            {/*    <Grid item container spacing={2}>*/}
            {/*        <Grid item xs={12}>*/}
            {/*            <EventHeader event={data} canManage={false}/>*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
            <Box display='flex' className={classes.mainContainer}>

                <Box display='flex' height={90} width={'100%'} flexDirection={'column'}>
                    <Box display='flex' className={classes.container}>
                        {data&&data.online&&<RoomIntegration uuid={data.code} onExit={()=>redirectToInfo(data.eventInfo.id)} customWaitMessage={data.offlineBannerText} youtubeUrl={data.sponsor.youtubeUrl} isSponsor />}
                    </Box>
                </Box>

                {data.sponsor&&<Box display='flex' p={2} width={354-16-16} flexDirection={'column'} className={classes.sideBar}>
                    {/*<SponsorCardView sponsor={data.sponsor} event={{eventInfo:data.eventInfo}}/>*/}

                    <Box display={'flex'}>
                        <Grid container flexDirection='column'>
                            <Grid item container justify='center' alignContent='center' alignItems='center'>
                                <Grid item>
                                    <Box className={classes.logo}><img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} /> </Box>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid item xs={12}>
                                    <Box component='h1' fontWeight='bolder' fontSize={28} mb={1}>{sponsor.name}</Box>
                                </Grid>
                                <Grid item xs={12}>
                                    <Chip label={SPONSOR_TYPES[sponsor.type]}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Box p={1} pl={0} >
                                        <a href={withHttp(sponsor.url)} className={classes.sponsorUrl}> {language.visitUs} </a>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box display={'flex'} pt={2}>
                        <Typography variant={'h6'}> {language.summary} </Typography>
                    </Box>

                    <Box display={'flex'}>
                        <Typography variant={'body2'}>
                            <div dangerouslySetInnerHTML={createMarkup(sponsor.summary)} key={sponsor.summary} />
                        </Typography>
                    </Box>
                </Box>}
            </Box>
        </>}

        {!data.online&&<>
            {data.sponsor&&<Box display='flex' p={2} justifyContent='center' alignItems='center' alignContent='center' style={{height:'calc(100vh - 280px)'}}>
                <SponsorCardView sponsor={data.sponsor} event={{eventInfo:data.eventInfo}}/>
            </Box>}
        </>}

    </Page>
})

export default withRouter(Sponsor)
