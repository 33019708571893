import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import api from 'api'
import {useHttp} from 'api/core'
import FormControl from '@material-ui/core/FormControl'
import {ROLES} from 'config'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    width: '100%'
  },
  form: { width: '100%' },
  row: {
    marginBottom: theme.spacing(4)
  },
  contentBox: {

  },
  btns: {
    textAlign: 'center'
  }
})

const validationSchema = Yup.object().shape({
  role: Yup.string().required('This field is required'),
  email: Yup.string().required('This field is required'),
})

const CollaboratorEditNew = withStyles(styles)(({classes, collaborator, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData] = useState({...collaborator})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isSubmitting] = useHttp()

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      let payLoad = {
        role: formSave.role,
        email: formSave.email
      }

      // If existing collaborator
      if (collaborator) {
        sendRequest(api.entities.collaborations.update(payLoad, null, {id:collaborator.id}))
      } else { // else add new collaborator
        payLoad.eventId = eventId
        sendRequest(api.entities.collaborations.addNew(payLoad, null, null))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, collaborator])

  return <Box className={classes.newCollaborator}>
    <Formik
      initialValues={{
          role: '',
          email: '',
          title: '',
          firstName: '',
          lastName: '',
          ...collaborator
        }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>

            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {collaborator ? 'Collaborator Information' : 'New collaborator'}</Typography>
              </Grid>

              <Grid container spacing={1} className={classes.row}>
                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Details </Typography>
                </Grid>

                {collaborator&&<>
                <Grid item xs={12} sm={3}>
                  <Field type='text' name='title' component={TextField} label='Title' variant='filled'
                         fullWidth disabled={true} readOnly />
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Field type='text' name='firstName' component={TextField} label='First Name' variant='filled'
                         fullWidth disabled={true}/>
                </Grid>

                <Grid item sm={3} xs={12}> </Grid>

                <Grid item sm={9} xs={12}>
                  <Field type='text' name='lastName' component={TextField} label='Last Name' variant='filled' fullWidth
                         disabled={true}/>
                </Grid>
                </>}

                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Field type='select' labelId='roleLabel' displayEmpty name='role' component={Select} label='Role' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                        {ROLES.map((role, i)=>{
                          return <MenuItem value={role.id} key={i}>{role.name}</MenuItem>
                        })}
                      </Field>
                      <InputLabel variant='filled'>Role</InputLabel>
                      <FormHelperText>{values.role?ROLES.find((role)=>role.id===values.role).description:' '}</FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name='email' component={TextField} label='User Email' variant='filled' fullWidth
                         disabled={!!collaborator} />
                  {!collaborator&&<FormHelperText>User email must be a registered Anymeets account</FormHelperText>}
                </Grid>
              </Grid>

              <Grid container spacing={3} className={classes.btns}>
                <Grid item xs={12} md={6}>
                  <Button variant='outlined' size='small' colour='white' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                </Grid>
              </Grid>

            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </Box>
})

export default CollaboratorEditNew
