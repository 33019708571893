import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import {Link} from 'react-router-dom'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {format, parseISO} from 'date-fns'
import EllipsisText from 'react-ellipsis-text'
import ScrollableContainer from 'components/core/ScrollableContainer'
import {imageFile} from 'utils'
import clsx from 'clsx'

const intraTransitionStyles = theme => ({
  sessionBox: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
    height: '80px',
    borderRadius: '4px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    cursor: 'pointer',
    overflow:'hidden',
  },
  box: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '40px',
    zIndex: 5,
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: '4px',
    padding: theme.spacing(1),
    color: '#FFFFFF',
    height: '100%',
    textShadow: '1px 1px #00001111',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  boxWithoutImage: {
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    }
  },
  sessionTitle: {
    fontWeight: 'bold',
    padding: theme.spacing(0,1)
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  container: {
    position: 'relative'
  },
  backgroundImage: {
    position: 'absolute',
    width: 'auto',
    height: '100%',
    opacity: 1,
    left: '-50%',
    top: '0',
    zIndex:4,
    filter: 'blur(1px)'
  },
  linkToSession: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 6,
  },
  badge: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    position: 'relative',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    marginBottom: '1px',
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  startsSoon: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    fontWeight: 'bold',
    color: 'green'
  },
  disabled: {
    cursor: 'default'
  }
})


const Session = withStyles(intraTransitionStyles)(({classes, type, startDate, endDate, title, room, id, startsSoon, background}) => {

  const redirectToSession = (e) => {
    e.preventDefault()
    if ((type === 1 || type === 2)) {
      document.location.href=`/session/${id}`
    }
  }

  return <Grid item container className={clsx(classes.box, (!background)&&classes.boxWithoutImage)} >
    <Link to={(type===1||type===2)?`/session/${id}`:''} className={clsx(classes.linkToSession,(type!==1&&type!==2)&&classes.disabled)} onClick={redirectToSession}> </Link>
    {startsSoon&&<Box className={classes.startsSoon}>
      <div className="ribbon"><span>SOON</span></div>
    </Box>}
    <Grid item xs={12} className={classes.sessionTitle}> <EllipsisText length={40} text={title} /> </Grid>
    {room&&<Grid item xs={12} className={classes.sessionTitle}> at {room} </Grid>}
    <Grid item xs={12} className={classes.sessionTimes}>
      {/*{format(parseISO(startDate), 'LLL do y')} <br/>*/}
      {format(parseISO(startDate), 'hh:mmaa')} - {format(parseISO(endDate), 'hh:mmaa')} <br/>
    </Grid>
  </Grid>
})

export const IntraTransition = withStyles(intraTransitionStyles)(({classes, eventId, canManage=false, sessionId}) => {
  const [intraTransition, setIntraTransition] = useState()
  const [getIntraTransitionData, intraTransitionData, ,] = useHttp()

  useEffect(() => {
      getIntraTransitionData(api.entities.events.getHappeningNow(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (intraTransitionData) {
      setIntraTransition(intraTransitionData)
    }
  }, [intraTransitionData])

  if (!intraTransition || (intraTransition && !intraTransition.sessions)) {
    return <> </>
  }

  if (intraTransition.sessions.filter((s)=>s.id!==parseInt(sessionId)).length===0) {
    return <> </>
  }

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box p={2} pt={0} pb={0} position={'relative'} style={{width:'calc(100vw - 1100px)'}} >

        <Grid item container spacing={2}>
          <Grid item style={{position:'absolute',top:'-16px'}}>
            <Box component='h2' fontWeight='bolder' fontSize={16} mb={1} ml={3} color={'rgb(74, 74, 74)'}>
              <Box className={classes.badge}></Box>
                Next session{intraTransition.sessions.length>1?'s':''} coming up
              </Box>
          </Grid>
          <Grid item container spacing={1} style={{marginTop: 16}}>
            <ScrollableContainer items={intraTransition.sessions.filter((s)=>s.id!==parseInt(sessionId)).map((session)=>{
              return <Grid item className={classes.container}>
                <Box className={classes.sessionBox} >
                  <Session type={session.type} id={session.id} background={session.backgroundId} startsSoon={session.startsSoon} startDate={session.startDate} endDate={session.endDate} title={session.title} room={session.room?session.room.name:null} />
                  {session.backgroundId&&<img src={imageFile(session.backgroundId)} alt={session.title} className={classes.backgroundImage} />}
                </Box>
              </Grid>
            })} />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </>
})
