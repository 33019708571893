import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import FormEditNewReviewFlow from 'components/core/Forms/FormEditNewReviewFlow'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import ReviewFlowCard from 'components/core/ReviewFlowCard'

const styles = theme => ({
  reviewFlowsContainer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1200px'
  },
  noReviewFlowsYet: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '145px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  fieldTitle: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  addBtn: {
    marginTop: theme.spacing(4),
  },
  navTabs: {
    position: 'relative',
    marginBottom: theme.spacing(3)
  },
  downloadBtn: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(3)
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3)
  },
  totalStats: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2,2,4,2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold'
  },
  noFlowsYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
})

const ReviewFlows = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo, reload}) => {

  // Hooks
  const [getData, data] = useHttp()
  const [getSubmissionFlows, submissionFlows] = useHttp()

  //eslint-disable-next-line
  const [sendRequest, putData, isLoading, hasError] = useHttp()

  // State
  const [reviewFlows, setReviewFlows] = useState()
  // const [CsvData, setCsvData] = useState()
  const [formEdit, setFormEdit] = useState()
  const [deletingFlow, setDeletingFlow] = useState()
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [sendData] = useHttp()

  // Deps
  const {eventId} = useParams()

  useEffect(() => {
    getData(api.entities.manage.getReviewFlows(null, null, {id: eventId}))
    getSubmissionFlows(api.entities.manage.getSubmissionListing(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [])
  
  useEffect(() => {
    getData(api.entities.manage.getReviewFlows(null, null, {id: eventId}));
    getSubmissionFlows(api.entities.manage.getSubmissionListing(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, [reload]);

  const refresh = () => {
    getData(api.entities.manage.getReviewFlows(null, null, {id: eventId}))
  }

  const handleCloseFormEdit = (reload) => {
    setFormEdit(null)
    if (reload) {
      refresh()
    }
    //eslint-disable-next-line
  }

  const handleOpen = () => {
    setFormEdit({})
  }

  const handleDeleteClose = (submissionFlowIndex) => {
   /* if (!submissionFlowIndex) {
      setDeleteOpen(false)
      return
    }*/
    const dataDelete = [...reviewFlows]
    dataDelete.splice(submissionFlowIndex, 1)
    sendRequest(api.entities.manage.deleteReviewFlow(null,null,{id:reviewFlows[submissionFlowIndex].id}))
    //setReviewFlows([...dataDelete])
    setDeleteOpen(false)
  }

  useEffect(()=>{
    if (putData) {
      refresh()
    }
    //eslint-disable-next-line
  },[putData])

  // detailPanel={rowData => {
  //   return <Details rowData={rowData} />
  // }}
  //

  useEffect(() => {
    if (data) {
      // let reviewFlowsTransformed = []
      // data.reviewFlows.forEach((r)=>{
      //   let accepted = 0 // pending
      //   if (r.accepted === true) {
      //     accepted = 1
      //   }
      //   if (r.accepted === false) {
      //     accepted = 2
      //   }
      //   reviewFlowsTransformed.push({
      //     ...r,
      //     acceptEnum: accepted
      //   })
      // })
      // setReviewFlows(reviewFlowsTransformed)
      setReviewFlows(data.flows)
      // console.log(data.flows)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  const onDragEnd = (r) => {
    if (!r.destination) return

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    // arrayHelpers.move(r.source.index,r.destination.index)
    let newArr = [...reviewFlows]

    array_move(newArr, r.source.index, r.destination.index)

    setReviewFlows(newArr)

    const newArrOrder = [...newArr.map((sF)=>sF.id)]

    sendData(api.entities.reviews.reorder(newArrOrder, null, {id: eventId}))
  }

  if (!reviewFlows) return <> </>

  return <>
    <Fade in={true} timeout={1200}>
      <>
        <Grid container spacing={2} className={classes.reviewFlowsContainer}>

          {/* <Grid item xs={12} sm={12} md={4}>
            <Typography component='h2' variant='h5' className={classes.title}>
              Review flows
            </Typography>
          </Grid> */}


          <Dialog
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          ><Box p={3} pt={1}>
            <DialogTitle id="alert-dialog-title">Delete flow</DialogTitle>
            <DialogContent>
              <Box p={1} pl={0} pb={2}>
                Are you sure you want to delete this flow?
              </Box>
            </DialogContent>
            <DialogActions>
              <Box p={1}>
                <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
                  No
                </Button>
              </Box>
              <Box p={1}>
                <Button onClick={()=>{handleDeleteClose(deletingFlow)}}  size='small' autoFocus color='primary'>
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Box>
          </Dialog>

          <Grid item container spacing={2}>
            {reviewFlows && <>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${0}`}>
                  {(provided) => {
                    return <Grid item container spacing={2} ref={provided.innerRef} {...provided.droppableProps}>
                      {reviewFlows.map((reviewFlow, index) => (
                        <Draggable key={index}
                                   draggableId={`${index}`}
                                   index={index}>
                          {(provided) => (
                            <Grid item xs={12} {...provided.draggableProps}
                                  ref={provided.innerRef} width={'100%'}>
                              <ReviewFlowCard dragHandleProps={provided.dragHandleProps} eventId={eventId}
                                                  reviewFlow={reviewFlow}
                                                  editAction={() => setFormEdit(reviewFlow)} deleteAction={() => {
                                setDeletingFlow(index);
                                setDeleteOpen(true)
                              }}/></Grid>)
                          }
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Grid>
                  }}
                </Droppable>
              </DragDropContext>
            </>}
            {reviewFlows&&reviewFlows.length===0?<Grid item container className={classes.noFlowsYet}>
                <Grid item>No steps yet!</Grid>
              </Grid>:''}
          </Grid>

          <Grid item container justify='flex-end' className={classes.addBtn}>
            <Grid item>
              <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen()}} disabled={((!submissionFlows) || (submissionFlows&&submissionFlows.length===0))}>Add step</Button>
            </Grid>
          </Grid>
        </Grid>
        {formEdit&&<Dialog onClose={handleCloseFormEdit} open={formEdit} fullWidth maxWidth='md'>
          <FormEditNewReviewFlow handleClose={handleCloseFormEdit} eventId={eventId} sFlow={formEdit} submissionFlows={submissionFlows} data={data}/>
        </Dialog>}

      </>
    </Fade>
  </>
})

export default withRouter(ReviewFlows)
