import withStyles from '@material-ui/styles/withStyles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useState, useEffect} from 'react'
import Box from '@material-ui/core/Box'
import AnyTabs from 'components/core/AnyTabs'
import {useHttp} from 'api/core'
import api from 'api'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import FeedbackCard from 'components/core/FeedbackCard'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/core/Button'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import FeedbackEditNew from 'components/core/FeedbackEditNew'
import {IconAddBorderBlue} from 'components/core/icons'
import {TIME_FADE_IN} from 'config'
import {eachDayOfInterval, format, isSameDay } from 'date-fns'
import parseISO from "date-fns/parseISO"
import {Typography} from '@material-ui/core'
import PreviewFeedback from 'components/core/PreviewFeedback'

const styles = theme => ({
  previewsContainer: {
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  container: {
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1200px'
  },
  feedbackContainer: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(3)
  },
  feedbackBox: {
    maxWidth: '1200px'
  },
  noDataYet: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minWidth: '100%',
    minHeight: '160px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  hours: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    height: 'calc(100% - 15px)',
    position: 'relative',
    maxWidth: '100px',
    justifySelf: 'flex-end',
    // margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      height: 'auto',
      minHeight: theme.spacing(10),
      // padding: theme.spacing(3),
      // width: '100%',
      // textAlign: 'center'
    },
  },
  startTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
      position: 'relative',
      top:0
    },
  },
  endTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
      position: 'relative',
      bottom: 0,
    },
  },
  day: {
    borderRadius: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    maxHeight: '80px',
    maxWidth: '90px'
  },
  textDay: {
    maxWidth: '90px',
    fontSize: '13px'
  },
  textDate: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  textYear: {
    fontSize: '13px'
  },
  textDayTitle: {
    fontSize: '20px',
    fontWeight: 'bold',
    maxWidth: '90px',
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  inner: {
    maxWidth: '1200px'
  }
})



const NoDataYet = withStyles(styles)(({classes})=> <Box display='flex' className={classes.noDataYet}>
  No feedback yet!
</Box>)

const FeedbackList = withStyles(styles)(({classes, isLoading, handleOpenPreview, feedback, eventId, canManageFeedback, sessionsData, days, all, eventwide=false, eventWideNo, tier, handleOpen, handleDeleteOpen})=>{


    return<Fade in={true} timeout={TIME_FADE_IN}>
      <>
      {(all||eventwide) && <Grid container className={classes.feedbackBox} >
        <Grid container spacing={2} className={classes.feedbackContainer} justify='flex-end'>
          <Grid item xs={12} md={2}>
            <Typography component='h2' variant='h6' className={classes.title}>
              Event wide
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            {feedback.filter((f)=>{
              return (f.sessionId===null)
            }).map((f,i)=>{
              return <FeedbackCard sessions={sessionsData} feedback={f} index={i} handleOpenPreview={handleOpenPreview} editAction={(f)=>handleOpen(f)} deleteAction={(id)=>handleDeleteOpen(id)}/>
            })}
            {feedback.filter((f)=>{
                return (f.sessionId===null)
            }).length===0&&<NoDataYet />}
          </Grid>
          <Grid item xs={12} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
            <Grid item>
              <Button variant='outlined' eventId={eventId} upgradeTier={!((tier===true)||(tier===false&&eventWideNo===0))} icon={<IconAddBorderBlue/>} size='small' color='primary' disabled={isLoading} onClick={()=>{handleOpen()}}>Add Feedback</Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>}

        {(!eventwide)&&days.map((day, i) => {
          return <Grid container className={classes.feedbackBox} key={i}>
            <Grid container spacing={2} className={classes.feedbackContainer} justify='flex-end'>
              <Grid item xs={4} md={2}>

                <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'
                     flexDirection='column' className={classes.day}>
                  <Box display='flex' className={classes.textDay}> {format(day, 'LLL')} </Box>
                  <Box display='flex' className={classes.textDate}> {format(day, 'dd')} </Box>
                  <Box display='flex' className={classes.textYear}> {format(day, 'yyyy')} </Box>
                </Box>

                <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'
                     className={classes.textDayTitle} pt={2}>
                  Day {i + 1}
                </Box>

              </Grid>

              <Grid item xs={12} md={10}>
                {feedback.filter((f)=>{
                  if (f.sessionId===null) return false
                  const sessionObj = sessionsData.sessions.find((s)=>s.id===f.sessionId)
                  return sessionObj&&isSameDay(day, parseISO(sessionObj.startDate))
                }).map((f,i)=>{
                  return <FeedbackCard sessions={sessionsData} feedback={f} index={i} handleOpenPreview={handleOpenPreview} editAction={(f)=>handleOpen(f)} deleteAction={(id)=>handleDeleteOpen(id)}/>
                })}
                {feedback.filter((f)=>{
                  if (f.sessionId===null) return false
                  const sessionObj = sessionsData.sessions.find((s)=>s.id===f.sessionId)
                  return sessionObj&&isSameDay(day, parseISO(sessionObj.startDate))
                }).length===0&&<NoDataYet />}
              </Grid>
              <Grid item xs={12} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                <Grid item>
                  <Button variant='outlined' eventId={eventId} upgradeTier={!canManageFeedback} icon={<IconAddBorderBlue/>} size='small' color='primary' disabled={isLoading} onClick={()=>{handleOpen()}}>Add Feedback</Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        })}
        </>
    </Fade>
})

const Feedback = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack})=>{

  const {enqueueSnackbar} = useSnackbar()

  // Parameters
  const {eventId} = useParams()

  // Sessions
  const [getSessionsData, sessionsData, isLoadingSessions] = useHttp()
  const [sessions, setSessions] = useState()
  const [days, setDays] = useState()

  // Get Sessions
  useEffect(()=>{
    getSessionsData(api.entities.manage.getSessions(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (sessionsData) {
      setSessions(sessionsData)
      setDays(eachDayOfInterval({start: parseISO(sessionsData.eventInfo.startDate), end: parseISO(sessionsData.eventInfo.endDate)}))
    }
  },[sessionsData])

  // Feedback
  const [getFeedbackData, feedbackData, isLoadingData] = useHttp()
  const [feedback, setFeedback] = useState()
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false)
  const [editingFeedback, setEditingFeedback] = useState()
  const [delRequest, delData, isLoadingDelete] = useHttp()
  const [previewingFeedback, setPreviewingFeedback] = useState()

  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingFeedback, setDeletingFeedback] = useState()
  const [eventWideNo, setEventWideNo] = useState(0)

  // Get Feedback
  useEffect(()=>{
    getFeedbackData(api.entities.manage.getFeedback(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (feedbackData) {
      setFeedback(feedbackData)
      setPageTitle(feedbackData.eventInfo.name)
      setEventInfo(feedbackData.eventInfo)
      setNavBack({path:'/manage-events',name:'events'})
      setEventWideNo(feedbackData.feedback.filter((f)=>f.sessionId===null).length)
      console.log(feedbackData.feedback.filter((f)=>f.sessionId===null).length)
    }
    //eslint-disable-next-line
  },[feedbackData])

  const handleClose = (shouldReload) => {
    setOpenFeedbackDialog(false)
    if (shouldReload===true) {
      getFeedbackData(api.entities.manage.getFeedback(null,null,{id:eventId}))
    }
  }

  const handleOpen = (feedback) => {
    setOpenFeedbackDialog(true)
    if (feedback) {
      setEditingFeedback(feedback)
    } else {
      setEditingFeedback(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingFeedback(id)
  }

  const handleOpenPreview = (feedback) => {
    setPreviewingFeedback(feedback)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingFeedback) {
      delRequest(api.entities.feedback.delete(null,null,{id:id}))
      setDeletingFeedback(undefined)
    }
  }

  const handlePreviewClose = () => {
    setPreviewingFeedback(false)
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted feedback', {variant: 'success'})
      getFeedbackData(api.entities.manage.getFeedback(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getFeedbackData])

  if (!feedback||!sessions) {
    return <> </>
  }

  return <Fade in={true} timeout={TIME_FADE_IN}><Box>
    <Dialog onClose={handleClose} open={openFeedbackDialog} maxWidth='lg'>
      <FeedbackEditNew feedback={editingFeedback} handleClose={handleClose} eventId={eventId} sessions={sessions.sessions} setSuccessful={setSuccessful} />
    </Dialog>
    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete feedback</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete feedback?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={()=>{handleDeleteClose(deletingFeedback)}}  size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>

    {previewingFeedback&&<Dialog
      open={previewingFeedback}
      onClose={handlePreviewClose}
      maxWidth='lg'
    >
      <PreviewFeedback feedback={previewingFeedback} />
    </Dialog>}

    <Grid item xs={12}>
      <Typography component='h2' variant='h5' className={classes.title}>
        Feedback
      </Typography>
    </Grid>

    <Grid container>
      <Grid item xs className={classes.inner}>
        <AnyTabs tabs={[
          {
            label: 'All',
            component: <FeedbackList eventId={eventId} all days={days} canManageFeedback={feedback.eventInfo.upgraded===true} eventWideNo={eventWideNo} tier={feedback.eventInfo.upgraded} sessionsData={sessionsData} feedback={feedback.feedback} handleOpen={handleOpen}
                                     handleDeleteOpen={handleDeleteOpen} handleOpenPreview={handleOpenPreview} isLoading={isLoadingSessions||isLoadingData||isLoadingDelete}/>
          },
          {
            label: 'Event wide',
            component: <FeedbackList eventId={eventId} days={days} canManageFeedback={((feedback.eventInfo.upgraded===true)||(feedback.eventInfo.upgraded===false&&eventWideNo===0))} eventWideNo={eventWideNo} tier={feedback.eventInfo.upgraded} eventwide={true} sessionsData={sessionsData} feedback={feedback.feedback} handleOpen={handleOpen}
                                     handleDeleteOpen={handleDeleteOpen} handleOpenPreview={handleOpenPreview} isLoading={isLoadingSessions||isLoadingData||isLoadingDelete}/>,
          },
          ...days.map((day, i) => {
            return {
              index: i,
              label: `Day ${i + 1}`,
              component: <FeedbackList eventId={eventId} days={[day]} canManageFeedback={feedback.eventInfo.upgraded===true} sessionsData={sessionsData} feedback={feedback.feedback} eventWideNo={eventWideNo} tier={feedback.eventInfo.upgraded} handleOpen={handleOpen}
                                       handleDeleteOpen={handleDeleteOpen} handleOpenPreview={handleOpenPreview} isLoading={isLoadingSessions||isLoadingData||isLoadingDelete}/>,
            }
          })
        ]}/>
      </Grid>
    </Grid>
  </Box></Fade>
})

export default withRouter(Feedback)
