import {makeStyles} from '@material-ui/styles'

export const discussion_8 = makeStyles({
  item_1: {
  left: '1%',
  top: '2%',
  width: '32%',
  height: '30.6%',
    opacity: '1 !important'
},

item_2: {
  left: '34%',
  top: '2%',
  width: '32%',
  height: '30.6%',
  opacity: '1 !important'
},

item_3: {
  left: '67%',
  top: '2%',
  width: '32%',
  height: '30.6%',
  opacity: '1 !important'
},

item_4: {
  left: '1%',
  top: '34.6%',
  width: '32%',
  height: '30.7%',
  opacity: '1 !important'
},

item_5: {
  left: '34%',
  top: '34.6%',
  width: '32%',
  height: '30.7%',
  opacity: '1 !important'
},

item_6: {
  left: '67%',
  top: '34.6%',
  width: '32%',
  height: '30.7%',
  opacity: '1 !important'
},

item_7: {
  left: '17.5%',
  top: '67.3%',
  width: '32%',
  height: '30.6%',
  opacity: '1 !important'
},

item_8: {
  left: '50.5%',
  top: '67.3%',
  width: '32%',
  height: '30.6%',
  opacity: '1 !important'
}
})
