import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {IconUserPicAddLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {FILES_URL, SIZE_ORGANIZER_LOGO, SIZE_SPONSOR_LOGO} from 'config'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import api from 'api'
import {useHttp} from 'api/core'
import CropImage from 'components/core/CropImage'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  formControl: {
    width: '100%',
    textAlign: 'left'
  },
  newOrganizer: {
    maxWidth: '770px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto'
    }
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  summary: Yup.string().required('This field is required')
})

const UploadLogoImage = withStyles(styles)(({setFormData, formData, values, organizer, size}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [cropImage, setCropImage] = useState(false)

  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width) && (img.height < size.height)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }

    fr.readAsDataURL(acceptedFiles[0])
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const setImage = (image) => {
    setFormData({...formData, ...values, logo: image})
    setCropImage(false)
  }

  return <RootRef rootRef={ref}>
    {cropImage&&<CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage} setOpen={setCropImage} setImage={setImage} />}
    <Box {...rootProps} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
      <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
      <Button size='smaller' variant='outlined' colour='white'>{organizer ? 'Change' : 'Select'} Image</Button>
      <Box display='flex' mt={2} mb={2}> Image should be at least {SIZE_SPONSOR_LOGO.width}x{SIZE_SPONSOR_LOGO.height} </Box>
    </Box>
  </RootRef>
})

const LogoImage = withStyles(styles)(({classes, formData, values}) => {

  return <>
    {formData.hasOwnProperty('logo') && formData.logoId !== null ? <Box className={classes.userPhoto}><img
      src={(typeof formData.logo.name == 'string') ? URL.createObjectURL(formData.logo) : `${FILES_URL}/${formData.logoId}`}
      alt={``}/></Box> : !formData.logoId ? <IconUserPicAddLight/> : ''}
  </>
})

const OrganizerEditNew = withStyles(styles)(({classes, organizer, sessions=[], handleClose, eventId, setSuccessful, ...props}) => {
  const [formData, setFormData] = useState({...organizer, sessions:organizer?organizer.sessions:[], logo:{}})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(()=>{
    setIsSubmitting(isLoading&&!hasError)
  },[isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {
      let formDataObj = new FormData();

      const {logo, sessions, logoId, id,  ...formDataItems} = formSave

      if (typeof logo.name == 'string') formDataObj.append('logo', logo)

      let payLoad = {...formDataItems, deleteLogo: false, eventId}

      const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'});
      formDataObj.append('organizer', blob)

      // If existing organizer
      if (organizer) {
        sendRequest(api.entities.organizers.update(formDataObj, null, {id: organizer.id}, true))
      } else { // else add new organizer
        sendRequest(api.entities.organizers.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, organizer])



  return <Box className={classes.newOrganizer}>
    <Formik
      initialValues={{
        name: '',
        url: '',
        summary: '',
        ...formData
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, logo: formData.logo}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Grid container className={classes.container}>

              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {organizer ? 'Organizer Information' : 'New Organizer'}</Typography>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Box display='flex' alignSelf='center' flexDirection='column' alignItems='center'>
                    <Box display='flex' mb={2}>
                      <LogoImage formData={formData} values={values}/>
                    </Box>
                    <Box display='flex' mb={2}>
                      <UploadLogoImage setFormData={setFormData} setFieldValue={setFieldValue} values={values}
                                       organizer={organizer} size={SIZE_ORGANIZER_LOGO}/>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>

                  <Grid container spacing={1} className={classes.row}>
                    <Grid item xs={12} sm={12}>
                      <Field type='text' name='name' component={TextField} label='Name' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>
                   <Grid item xs={12} sm={12}>
                      <Field type='text' name='url' component={TextField} id="url" label='URL' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>                  
                    <Grid item xs={12} sm={12}>
                      <Field type='text' name='summary' component={TextField} id='Summary' label='Summary'
                             variant='filled'
                             fullWidth multiline
                             rows={4} disabled={isSubmitting}/>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>


            <Grid container spacing={3} className={classes.btns}>
              <Grid item lg={2} md={2}> </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Button variant='outlined' size='small' colour='white' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
              </Grid>
              <Grid item lg={2} md={2}> </Grid>
            </Grid>

          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
})

export default OrganizerEditNew
