import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import ScrollableContainer from 'components/core/ScrollableContainer'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import momentTz from 'moment-timezone'
import EllipsisText from 'react-ellipsis-text'

const styles = theme => ({
  container: {
    color: palette['greyish-brown'],
    overflow: 'hidden',
    minHeight: 200
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center'
  },
  innerContainer: {
    padding: theme.spacing(1)
  },
  item: {
    cursor: 'pointer',
    border: '2px solid transparent'
  },
  selected: {
    border: `2px solid red`
  },
  avatar: {
    color: `${palette['greyish-brown']} !important`,
    background: 'none',
    '&> img': {
      maxWidth: '100%'
    }
  },
  fileChip: {
    backgroundColor: 'transparent'
  },
  questionBox: {
    backgroundColor: '#ffffff',
    padding: '8px 8px 4px 8px',
    borderRadius: 4,
    minWidth:'100%',
    color: '#615c5c',
    height: 'auto',
    border: '0.03em solid #dadada',
    [theme.breakpoints.down('sm')]: {
      minWidth:'250px'
    }
  },
  answerBox: {
    backgroundColor: '#f9f9f9',
    padding: '8px',
    borderRadius: 4,
    color: '#615c5c',
    minWidth: '120px',
    height: 'auto',
    border: '0.03em solid #dadada'
  },
  userTitle: {
    fontWeight: 'bolder'
  },
  userCommentLink: {
    marginLeft: 60,
    fontWeight: '400',
    cursor: 'pointer',
    color: '#0DC7E5'
  },
  userDeleteQuestion: {
    fontWeight: '400',
    cursor: 'pointer',
    color: '#e50035'
  },
  userTimeStamp: {
    fontWeight: 100,
    fontSize: '10px',
    color: '#716b6f'
  },
  userPhoto: {
    display: 'flex',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.03em solid #dadada',
    alignContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  controls: {
    position: 'relative',
    right: theme.spacing(2),
    textAlign: 'right',
    width: '100%',
    '& img': {
      marginLeft: theme.spacing(1),
      width: '100px',
      height: 'auto'
    },
    // [theme.breakpoints.down(990)]: {
    //   width: '150px',
    //   lineHeight: '25px'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   position: 'relative',
    //   width: '100%',
    //   textAlign: 'center',
    //   lineHeight: '30px',
    // }
  },
  commentsBox: {
    minHeight: '100px'
  },
  list: {
    width: '100%',
    color: 'rgba(0,0,0,0.6)'
  },
  listItem: {
    width: 300,
    backgroundColor: 'white',
    borderRadius: 4,
    border: 'solid 2px #e6e6e6',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.6)'
  },
  message: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: theme.spacing(2),
    wordBreak: 'break-all'
  },
  avatarRoot: {
    minWidth: 40
  },
  votesBtn: {
    borderRadius: 16,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '2px 0px',
    minWidth: 46,

  },
  btnLbl: {
    width: 5
  },
  answer: {
    borderColor: '#d0d0d0',
    backgroundColor: '#e6e6e6',
    marginTop: -theme.spacing(1),
    // borderTop: 'none',
    borderRadius: 0,
    marginBottom: 0
  },
  messageAnswer: {
    color: 'rgba(0,0,0,0.5)',
    wordBreak: 'break-all'
  },
  answerInput: {
    backgroundColor: 'white'
  },
  buttonRoot: {
    backgroundColor: 'white'
  },
  selectedItem: {
    border: `2px solid ${palette.aquamarine}`
  }
})

const QuestionPresenter = withStyles(styles)(({classes, presentingSpeaker, setPresentingSpeaker, presentingQuestion, setPresentingQuestion, sessionId, setSelectedQuestion, selectedQuestion, roomId}) => {
  const [getQuestions, data, isLoading, hasError] = useHttp()
  const [localTimeZone, setLocalTimeZone] = useState()
  const [questions, setQuestions] = useState([])

  useEffect(()=>{
    if (data) {
      setQuestions(data)
    }
    // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    // Get data if not available
    getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    const timeZone = momentTz.tz.guess()
    const timeZoneDifference = parseInt(moment.tz(timeZone).format('Z'))
    setLocalTimeZone(timeZoneDifference)
    return ()=>{
      setSelectedQuestion(false)
      // setPresentingQuestion(false)
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (hasError) {
      setPresentingQuestion(false)
    }
    //eslint-disable-next-line
  },[hasError])

  const loading = isLoading

  // const setPage = (i) => {
  //   setStreaming({...streaming, presentingFilePage: i})
  // }

  // const togglePresentation = () => {
  //   setStreaming({...streaming, publishingCanvas: !publishingCanvas, presentingFilePage: publishingCanvas?0:presentingFilePage})
  // }

  if (loading) return <Grid item container className={classes.container} alignContent='center' justify='center' spacing={2}>
    <Grid item>
      <CircularProgress size={'4rem'} color='secondary' />
    </Grid>
  </Grid>

  return <Grid item container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <Box fontSize={14} fontWeight={600} color={palette['brown-grey']} pt={1.5}> Questions </Box>
        </Grid>
        <Grid item xs={12}>
          <Box width={'100%'} style={{backgroundColor:'rgba(0, 119, 175, 0.2)', borderRadius:4}}>
            <ScrollableContainer items={
              questions.map((question, index)=>{
                return <><ListItem onClick={()=>setSelectedQuestion(question)} alignItems="flex-start" className={clsx(classes.listItem, selectedQuestion&&selectedQuestion.id===question.id&&classes.selectedItem)}  key={index}>
                          <ListItemAvatar classes={{root:classes.avatarRoot}}>
                            <Avatar alt={question.firstName ? `${question.title?question.title:''} ${question.firstName} ${question.lastName}` : 'Anonymous'} src={imageFile(question.imageId ? question.imageId : '')} style={{width:24,height:24}} />
                          </ListItemAvatar>
                  {!presentingSpeaker&&<ListItemText classes={{primary:classes.userName}}
                                                              primary={<>
                                                                <Grid item container justify={'space-between'} alignItems='center' alignContent='center'>
                                                                  <Grid item>
                                                                    {question.firstName ? `${question.title?question.title:''} ${question.firstName} ${question.lastName}` : 'Anonymous'}
                                                                  </Grid>
                                                                  <Grid item>
                                                                    <Box fontSize={10} style={{opacity:0.7}}>
                                                                      {moment(question.updatedAt).add(localTimeZone,'hours').format('HH:mm')}
                                                                    </Box>
                                                                  </Grid>
                                                                </Grid>
                                                              </>}
                                                              secondary={<>
                                                                <Grid item container>

                                                                  <Grid item xs={12} className={classes.message}>
                                                                    <EllipsisText text={question.question} length={122} />
                                                                  </Grid>

                                                                </Grid>
                                                              </>}
                        />}
                      </ListItem>
                </>
                })
            }/>
          </Box>

        </Grid>
  </Grid>
})

export default QuestionPresenter
