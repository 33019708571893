import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import React from 'react'

const styles = theme => ({
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px',
    paddingLeft: theme.spacing(0.5)
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {

  return <Box display='flex' flexDirection='column' justifyContent='center'>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}> {value?value:'-'} </Box>
  </Box>
})

export default FieldText
