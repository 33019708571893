import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Link, useParams, withRouter} from 'react-router-dom'
import api from 'api'
import {useHttp} from 'api/core'
import Container from '@material-ui/core/Container'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {Bar, Pie, defaults} from 'react-chartjs-2'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {TIME_FADE_IN, TIME_GRAPH_POLLING_INTERVAL} from 'config'
import Grid from '@material-ui/core/Grid'
import {IconArrowLeftBorderDark, IconArrowRightBorderDark} from 'components/core/icons'
import Fade from '@material-ui/core/Fade'

const styles = theme => ({
  container: {
    textAlign: 'center',
    color: palette['greyish-brown'],
    paddingTop: theme.spacing(4)
  },
  title: {
    textAlign: 'left',
  },
  navigation: {
    position: 'fixed',
    bottom: '120px',
    left: 0,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  navLink: {
    cursor: 'pointer',
  },
  code: {
    backgroundColor: palette.aquamarine,
    fontSize: '48px',
    height: '100px',
    width: '100%',
    position: 'fixed',
    bottom: '0',
    zIndex: 49,
    left: 0,
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    lineHeight: '100px'
  }
})

const LiveFeedback = withStyles(styles)(({classes}) => {
  const [stats, setStats] = useState()
  const [results, setResults] = useState()
  const [getData, data] = useHttp(true)
  const [questionNumber, setQuestionNumber] = useState(1)
  const poller = useRef(null)

  const {feedbackId} = useParams()
  defaults.global.defaultFontSize = 18
  // defaults.global.legend.display = false

  useEffect(()=>{
    getData(api.entities.feedback.getAnswers(null,{q:questionNumber},{id:feedbackId}))
    return () => {
      clearInterval(poller.current)
    }
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    if (data&&data.ordinal===questionNumber) {
      setResults(data)
      setStats({
          labels: data.answers.map((answer, i) => answer.option),
          datasets: [{
            barPercentage: 0.5,
            // barThickness: 6,
            maxBarThickness: 80,
            minBarLength: 2,
            data: data.chartType===1?data.answers.map((answer, i) =>answer.total):data.answers.map((answer, i) => ((answer.total * data.answers.length) / 100)),
            backgroundColor: palette.graphColors
          }]
        }
      )
    }
    // eslint-disable-next-line
  }, [data])

  useEffect(()=>{
    if (questionNumber) {
      startPoller()
    }
    // eslint-disable-next-line
  },[questionNumber])

  const startPoller = () => {
    clearInterval(poller.current)
      poller.current = setInterval(()=>{
        getData(api.entities.feedback.getAnswers(null,{q:questionNumber},{id:feedbackId}))
      },TIME_GRAPH_POLLING_INTERVAL)
  }

  const goPrev = ()=> {
    getData(api.entities.feedback.getAnswers(null,{q:questionNumber-1},{id:feedbackId}))
    setQuestionNumber(questionNumber-1)
  }

  const goNext = ()=> {
    getData(api.entities.feedback.getAnswers(null,{q:questionNumber+1},{id:feedbackId}))
    setQuestionNumber(questionNumber+1)
  }

  if (!results) return <> </>

  return <Page title={results.feedbackTitle} backgroundColor={palette['pale-grey']}>
    <Container className={classes.container} maxWidth={'lg'}>
      <Box display='flex' pb={5} className={classes.title}>
        <Box display='flex' alignContent='center' alignItems='center'>
          <Box display='flex' pr={2}>
            <Link to={`/event/${results.eventId}`}><IconArrowLeftBorderDark title={'Go back to event'}/></Link>
          </Box>
          <Box display='flex'>
            <Typography variant={'h5'}> {results.eventTitle} / {results.feedbackTitle} </Typography>
          </Box>
        </Box>
      </Box>
      <Box pb={3}>
        <Typography variant={'h4'}>{results.ordinal}. {results.question} </Typography>
      </Box>
      <Fade in={true} time={TIME_FADE_IN} key={results.ordinal}>
        <Box>
        {stats&&results.chartType===2&&<Pie data={stats} />}
        {stats&&results.chartType===1&&<Bar
          data={stats}
          height={'98%'}
          options={{legend:{display:false},scales:{xAxes:[{gridLines:{display:false}}],yAxes:[{display:false,gridLines:{display:false},ticks:{beginAtZero:true}}]}}}
        />}
        </Box>
      </Fade>

      <Grid container justify='space-between' alignItems='center' className={classes.navigation}>
        <Grid item container justify='flex-start' alignItems='center'>
          <Grid item>
            {questionNumber!==1&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' onClick={()=>goPrev()} className={classes.navLink}>
              <IconArrowLeftBorderDark/> &nbsp; Previous
            </Box>}
          </Grid>
        </Grid>
        <Grid item container justify='flex-end' alignItems='center'>
          <Grid item>
            {questionNumber!==data.total&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' onClick={()=>goNext()} className={classes.navLink}>
              Next &nbsp; <IconArrowRightBorderDark/>
            </Box>}
          </Grid>
        </Grid>
      </Grid>

      <Box className={classes.code}>
        USE CODE NUMBER {results.code}
      </Box>

    </Container>
  </Page>

})

export default withRouter(LiveFeedback)
