import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
// import ConferenceCardOverview from "components/core/ConferenceCardOverview";
import Button from "components/core/Button";
import Divider from "@material-ui/core/Divider";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutForm from "components/pages/Event/Tickets/CheckoutForm";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useRef, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import api from "api";
import {loadStripe} from "@stripe/stripe-js/pure";
import {useHttp} from "api/core";
import {useSnackbar} from "notistack";
// import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import AccountBalanceWalletOutlined from "@material-ui/icons/AccountBalanceWalletOutlined";
import { CURRENCY } from "config";

const styles = (theme) => ({
  noOfTickets: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    borderRadius: "50%",
    fontSize: "24px",
    userSelect: "none",
  },
  noSelect: {
    userSelect: "none",
  },
  clickable: {
    cursor: "pointer",
  },
  dialog: {
    backgroundColor: "white",
  },
  disabled: {
    cursor: "default",
    opacity: 0.3,
  },
  couponBox: {
    padding: "0px 16px 32px",
  },
  textField: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  root: {
    width: "100%",
  },
  input: {
    width: "70px",
    textAlign: "right",
    border: "none",
    "&.MuiInput-underline:before": {
      borderBottom: "1px solid #ccc",
    },
  },
  checkoutForm: {
    marginTop: theme.spacing(2),
  },
});

const BuyCreditsDialog = withStyles(styles)(({classes, handleClose, open, event, creditsType, existing, creditsPriceList, setComplete, buySeparate}) => {
  const [publishableKey, setPublishableKey] = useState();
  const [clientSecret, setClientSecret] = useState();
  const [stripePromise, setStripePromise] = useState();
  const [reqUpgrade, upgradeData, upgradeLoading, hasErrorUpgrade] = useHttp();
  const {enqueueSnackbar} = useSnackbar();

  //const stripePromise = useRef()
  const [disabled, setDisabled] = useState(false);
  const [credits, setCredits] = useState({});
  const [value, setValue] = useState(1);
   
  useEffect(() => { 
    setComplete(false);
    setDisabled(false);
    setValue(1);
    if (creditsType) {
      const creditItem = creditsPriceList.find((item) => item.label === creditsType);
      setCredits(creditItem);
      setPublishableKey(null);
      setClientSecret(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsType]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    if(event.target.value.toString().length <= 7)
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 1) {
      setValue(1);
    }
  };

  const proceedPayment = () => {
      reqUpgrade(api.entities.credits.updateBalance(null, null, {id: event.id, addBalance: value}));
  };

  const initializeStripe = async (upgradeData, hasErrorUpgrade) => {
    console.log("use stripe");
    if (!upgradeData && hasErrorUpgrade) {
      handleClose(false);
      return;
    }
    if (upgradeData) {
      setPublishableKey(upgradeData.publishableKey);
      setClientSecret(upgradeData.clientSecret);
      if (!stripePromise) {
        setStripePromise(await loadStripe(upgradeData.publishableKey));
      }
    }

    if (upgradeData && existing) {
      handleClose(false);
    }
  };

  useEffect(() => {
    initializeStripe(upgradeData, hasErrorUpgrade);
    // eslint-disable-next-line
  }, [upgradeData, hasErrorUpgrade]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{paper: classes.dialog}}
      fullWidth
      disableBackdropClick={disabled}
      disableEscapeKeyDown={disabled}>
      <Box p={3} pt={1}>
        {/* <DialogTitle id="alert-dialog-title" style={{padding: "16px"}}>
          <Box display="flex" fontSize={18} fontWeight={"bold"}>
            {" "}
            Buy credits
          </Box>
        </DialogTitle> */}

        <DialogContent>
          <Grid container spacing={2} className={classes.planBuy}>
            {/* {event && (
              <Grid xs={12}>
                <Box p={2}>
                  <ConferenceCardOverview event={event} viewOnly={true} />
                </Box>
              </Grid>
            )} */}

            <Grid item xs={12}>
              <div className={classes.root}>
                {/* <Typography id="input-slider" gutterBottom bold>
                  Additional {creditsType}
                </Typography> */}
                <Grid container spacing={2} alignItems="center" justify="space-between">
                  <Grid item>
                    <AccountBalanceWalletOutlined />
                  </Grid>
                  <Grid item xs>
                    <Slider value={typeof value === "number" ? value : 0} max={"10000"} onChange={handleSliderChange} aria-labelledby="input-slider" disabled={publishableKey && clientSecret} />
                  </Grid>
                  <Grid item>
                    <Input
                      fullWidth
                      disabled={publishableKey && clientSecret}
                      className={classes.input}
                      value={value}
                      margin="dense"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: 1,
                        min: 1,
                        max: 10000,
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item container justify="space-between" alignItems="center" spacing={2}>
                <Grid item>
                  <Box>
                    Credits to buy{" "}
                    <Box fontWeight="bold">
                      {value} x {CURRENCY}
                      {credits?.value}
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  {credits !== null && (
                    <Box fontWeight="bolder" color="rgb(0, 120, 173)" fontSize={26}>
                      Total: {CURRENCY}
                      {Math.round(value * credits?.value * 100) / 100}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Divider />
                </Box>
              </Grid>
            </Grid>

            {!publishableKey && !clientSecret && (
              <>
                <Grid xs={12} className={classes.noSelect}>
                  <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" flexDirection="column">
                    <Box display="flex" pt={3} flexDirection="row" justifyContent="flex-end">
                      <Box display="flex">
                        <Button onClick={proceedPayment} disabled={upgradeLoading || credits?.value === null} size="small" color="secondary">
                          Proceed to payment
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>

          {publishableKey && clientSecret && (
            <>
              <Grid container className={classes.checkoutForm}>
                <Grid item xs={12}>
                  {stripePromise && (
                    <Elements stripe={stripePromise}>
                      <CheckoutForm clientSecret={clientSecret} handleClose={handleClose} setDisabled={setDisabled} disabled={disabled} setComplete={setComplete} />
                    </Elements>
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
      </Box>
    </Dialog>
  );
});

export default BuyCreditsDialog;
