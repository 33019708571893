import AddToCalendarHOC from 'react-add-to-calendar-hoc'
import Button from 'components/core/Button'
import List from '@material-ui/core/List'
import {ListItem, Menu} from '@material-ui/core'
import React, {useRef} from 'react'
import withStyles from '@material-ui/styles/withStyles'
import Box from '@material-ui/core/Box'
import EventIcon from '@material-ui/icons/Event'

const styles = theme => ({
  wrapper: {
    display: 'inline-block'
  },
  coverWrapper: {
    alignItems: 'flex-end',
    position: 'relative',
    minHeight: '290px',
    zIndex: 1,
    padding: 0,
    // maxHeight: '350px'
    transform: 'translateX(50%)'
  },
  link: {
    position: 'relative',
    padding: 0,
    margin: 0,
    '& a': {
      width: '100%',
      height: '100%',
      margin: '0 !important',
      padding: '8px 16px !important',
      color: theme.palette.text.primary,
    }
  }
})

// Example event duration is in hours
// const eventInfo = {
//   description: event.description,
//   duration,
//   endDatetime: format(parseISO(event.eventInfo.endDate),"yyyyMMdd'T'HHmmss'Z'"),
//   location: event.venueDetails,
//   startDatetime: format(parseISO(event.eventInfo.startDate),"yyyyMMdd'T'HHmmss'Z'"),
//   title: event.eventInfo.name,
// }




const AddToCalendarButton = withStyles(styles)(({classes,event, withIcon, onlyIcon}) => {

  const buttonRef = useRef()

  const CalendarDropdown = withStyles(styles)(({classes,children,isOpen,onRequestClose}) => {

    const dropDown = {

    }

    return <div style={dropDown}>
      <Menu
        id="simple-menu"
        keepMounted
        open={isOpen}
        onClose={onRequestClose}
        anchorEl={buttonRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <List dense disablePadding>
          {children.map((link)=>{
            return <ListItem disableGutters button className={classes.link}>
              {link}
            </ListItem>
          })}
        </List>
      </Menu>

    </div>
  })

  const SocialButton = ({children, ...props}) => {
    return <Button size='smaller' {...props} style={{margin:0}} onClick={(e)=>{e.stopPropagation(); props.onClick(e)}}>
      {(withIcon||onlyIcon)&&<><EventIcon/>  &nbsp;</>}
      {!onlyIcon&&children}
    </Button>
  }

  const AddToCalendarModal = AddToCalendarHOC(SocialButton, CalendarDropdown);

  if (!event) return <></>

  return <Box ref={buttonRef} className={classes.wrapper}>

    <AddToCalendarModal
      // className={componentStyles}
      // linkProps={{
      //     className: linkStyles,
      // }}
      event={event}
    />

  </Box>
})

export default AddToCalendarButton
