import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
// import {TextField} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import ParticipantCardView from 'components/core/ParticipantCardView'
import ScrollableContainer from 'components/core/ScrollableContainer'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import {filterList} from 'utils'
import {Pagination} from '@material-ui/lab'
import { SearchInput } from 'components/core/SearchInput'
import language from "components/language";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const participantStyles = theme => ({
  letter: {
    minWidth: '44px',
    minHeight: '44px',
    borderRadius: '50%',
    backgroundColor: 'white',
    fontWeight: 'bold'
  },
  selectedLetter : {
    backgroundColor: theme.palette.primary.main
  },
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

export const ParticipantsList = withStyles(participantStyles)(({classes, event, isPreview, canManage=false}) => {
  const [participants, setParticipants] = useState()
  const [getParticipantsData, participantsData] = useHttp()
  const [alphabet, setParticipantsAlphabet] = useState()
  const [filter, setFilter] = useState("A-Z Α-Ω");
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const history = useHistory();

  useEffect(() => {
    if (isPreview) {
      getParticipantsData(api.entities.preview.getParticipants(null, {keynote:false}, {id: event.eventInfo.id}))
    } else {
      getParticipantsData(api.entities.events.getParticipants(null, {keynote:false}, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (participantsData) {
      setParticipants(participantsData.participants)

      const participantsAlphabet = {}
      participantsData.participants.forEach((p)=>participantsAlphabet[p.lastName[0].toUpperCase()]=true)
      setParticipantsAlphabet(Object.keys(participantsAlphabet))
    }
  }, [participantsData])

  if (!participants) { // Loading
    return <> </>
  }

  if (participants&&participants.length===0) return <> </>

  const participantsFiltered = participants.filter((p) => p.lastName[0].toUpperCase() === filter || !filter || filter === "A-Z Α-Ω");
  const participantList = search?filterList(search,null,participantsFiltered).sort((a,b)=>a.lastName.localeCompare(b.lastName, 'en', { sensitivity: 'base' })).filter((s)=>s.matched===true):participantsFiltered.sort((a,b)=>a.lastName.localeCompare(b.lastName, 'en', { sensitivity: 'base' }))

  // FE Paging
  const participantsPerPage = 30
  const totalParticipants = participantList.length
  const totalPages = Math.ceil(totalParticipants/participantsPerPage)
  const handleChangePage = (event, value) => {
    setCurrentPage(value)
  }

  const from = (currentPage-1)*participantsPerPage
  const to = from + participantsPerPage
  const participantPagedList = participantList.slice(from,to)

  const doSearch = (value) => {
    setSearch(value)
    setCurrentPage(1)
  }

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0} pb={12} flexDirection='column'>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/participants`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Speakers</Button>}
            </Grid>         
        <Grid container>

          <Grid item xs={12} md={8}>
            <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.speakers}</Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box pb={2} pr={1}>
              {participants && participants.length !== 1 &&
                // <TextField label='Search for a speaker' variant='outlined' value={search} onChange={(e)=>setSearch(e.target.value)} fullWidth />
                <SearchInput doSearch={doSearch} label={language.searchForASpeaker + "..."} />
              }
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box pr={8}>
              <ScrollableContainer items={
                ['A-Z Α-Ω','A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','Α','Β','Γ','Δ','Ε','Ζ','Η','Θ','Ι','Κ','Λ','Μ','Ν','Ξ','Ο', 'Π','Ρ','Σ','Τ','Υ','Φ','Χ','Ψ','Ω'].map((letter)=>{
                  return <Chip label={letter} color={filter===letter?`primary`:`default`} onClick={()=>setFilter(letter)} disabled={!(alphabet.includes(letter))&&letter!=='A-Z Α-Ω'} classes={{root:classes.letter, colorPrimary:classes.selectedLetter}} />
                })
              } />
            </Box>

          </Grid>
          <Grid item xs={12}>
            {participantPagedList.map((participant,i) => {
              return <ParticipantCardView participant={participant} event={event} p={1} index={i}/>
            })}
          </Grid>
          <Grid item xs={12}>
            {participantPagedList.length!==0&&<Grid item container justify='center' alignContent='center' alignItems='center'>
              <Grid item>
                <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
              </Grid>
            </Grid>}
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </>
})
