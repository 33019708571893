import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import Grid from '@material-ui/core/Grid'
import {Typography} from '@material-ui/core'
import React from 'react'
import {palette} from 'theme'
import EventInfo from 'components/pages/Event/EventInfo'
import RegistrationInfo from 'components/pages/Event/RegistrationInfo'
import Hidden from '@material-ui/core/Hidden'
import FeedbackInfo from 'components/pages/Event/FeedbackInfo'
import SubmissionsInfo from 'components/pages/Event/SubmissionsInfo'
import ReviewSubmissionInfo from 'components/pages/Event/ReviewSubmissionInfo'
import CameraReadyInfo from "components/pages/Event/CameraReadyInfo";
import language from "components/language";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    description: {
        color: palette['greyish-brown'],
    },
    text: {
        whiteSpace: 'pre-line',
        [theme.breakpoints.down(1400)]: {
            paddingRight: theme.spacing(2)
        }
  },
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},    
})

const EventDescription = withStyles(styles)(({classes, event, joinEvent,isPreview, leaveEvent, isLeaving, isLoading, isJoining, hideJoin}) => {
  const history = useHistory();
  return (
      <Box>
        <Fade in={true} timeout={TIME_FADE_IN}>
        <Grid container spacing={2}>
        <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/general`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Information</Button>}
            </Grid>            
            <Grid item container className={classes.description}>
              <Grid item xs={12} md={8}>
                <Box component="h1" fontWeight="bolder" fontSize={26} mb={1} pt={0} mt={0}>
                  {language.information}
                </Box>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={12}>
                <Typography variant="body1" gutterBottom className={classes.text}>
                  {event.description}
                </Typography>
              </Grid>
              <Hidden lgUp>
                <Grid item xs={12} sm={4} md={4}>
                  <Box flexDirection="column" style={{maxWidth: 300}}>
                    <EventInfo event={event} joinEvent={joinEvent} leaveEvent={leaveEvent} isLeaving={isLeaving} isLoading={isLoading} isJoining={isJoining} hideJoin={hideJoin} />
               
                    {event.eventInfo.registrationsOpenAt !== null && (
                      <>
                        <RegistrationInfo event={event} />
                      </>
                    )}

                    {event.eventInfo.enableSubmissions && event.eventInfo.hasSubmissionFlows && (
                      <>
                        <SubmissionsInfo event={event} />
                      </>
                    )}

                    {event.eventInfo.hasReviews && (
                      <>
                        <ReviewSubmissionInfo event={event} />
                      </>
                    )}

                    {event.eventInfo && event.eventInfo.hasFeedback && <FeedbackInfo event={event} />}
                  </Box>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Fade>
      </Box>
    );
})

export default EventDescription
