import { Box, Grid, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import api from 'api'
import Edit from '@material-ui/icons/Edit'
import { palette } from 'theme'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Link } from '@material-ui/icons'
import Button from 'components/core/Button'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import Delete from '@material-ui/icons/Delete'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import Refresh from '@material-ui/icons/Refresh'
import ViewColumn from '@material-ui/icons/ViewColumn'
import { Redirect } from 'react-router-dom'
import { useHttp } from 'api/core'
import withStyles from '@material-ui/styles/withStyles'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { SERVICE_URL } from 'config'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = theme => ({
    container: {
    }
})

const Events = withStyles(styles)(({ classes }) =>
{

    // Hooks
    const [getData, data, , hasError] = useHttp()
    const [events, setEvents] = useState()
    const resolveRef = useRef()
    const tableRef = useRef()
    const [getDataUnpublish, dataUnpublish, isUnpublishing] = useHttp()
    const [deleteItem, setDeleteItem] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteEvent, dataEvent] = useHttp()

    useEffect(() =>
    {
        getData(api.entities.admin.getEvents(null, null, {}))
        // eslint-disable-next-line
    }, [])

    useEffect(() =>
    {
        if (data)
        {
            setEvents(data)
        }
    }, [data])
    
    useEffect(() =>
    {
        if (events)
        {
            resolveRef.current({
                page: events.pageable.pageNumber,
                totalCount: events.totalElements,
                data: events.content
            })
        }
    }, [events])


    const tableIcons = {
        Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
        Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
        Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
        DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
        Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
        Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
        FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
        LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
        NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
        PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
        ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
        Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
        SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
        ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
        ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
    }

    const publish = (e, id) =>
    {
        if (!e.target.value)
        {
            getDataUnpublish(api.entities.events.unpublish(null, null, { id: id }))
        } else
        {
            getDataUnpublish(api.entities.events.publish(null, null, { id: id }))
        }
    }

    if (hasError)
    {
        return <Redirect to={'/'} />
    }

    const handleDelete = (id) =>
    {
        setDeleteItem(id)
        setOpenDeleteModal(true)
    }
    const handleDeleteEvent = () =>
    {
        deleteEvent(api.entities.admin.deleteEvents(null, null, { id: deleteItem }))
        getData(api.entities.admin.getEvents(null, null, {}))   
        tableRef.current && tableRef.current.onQueryChange()
        setOpenDeleteModal(false)
    }

    return <>


        <Grid item xs={12} sm={12} md={4}>
            <Typography component='h2' variant='h5' className={classes.title}>
                Events
            </Typography>
        </Grid>

        <Grid item xs>

        </Grid>

        <Grid item>
        </Grid>

        <Grid item>
        </Grid>

        <Grid item xs={12}>
            <MaterialTable
                
                key={events}
                icons={tableIcons}
                tableRef={tableRef}
                columns={[
                    { title: 'Id', field: 'id', cellStyle: { width: "5%" }, width: "5%", headerStyle: { width: "5%" } },
                    { title: 'Cover', field: 'coverId', render: item => item.coverId ? <img src={`${ SERVICE_URL }/files/${ item.coverId }`} alt="coverimg" height="60" /> : "", cellStyle: { width: "30%" }, width: "30%", headerStyle: { width: "30%" } },
                    { title: 'Name', field: 'name' },
                    {
                        title: 'Publish/Unpublish', type: 'boolean', field: 'publish',
                        render: rowData =>
                        {
                            return (
                                <Grid container>
                                    <Grid item xs={11}>
                                        <FormControl className={classes.formControl}>
                                            <Select type='select' name='published' fullWidth value={rowData.published ? 1 : 0} onChange={(e) => publish(e, rowData.id)}>
                                                <MenuItem value={1} key={"Published"}>Published</MenuItem>
                                                <MenuItem value={0} key={"Unpublished"}>Unpublished</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            )
                        }
                    },
                ]}
                data={query =>
                    new Promise((resolve, reject) =>
                    {
                        getData(api.entities.admin.getEvents(null, { page: query.page + 1, items: query.pageSize, term: query.search },))
                        resolveRef.current = resolve
                    })}
                title=''
                actions={[
                    {
                        icon: Link,
                        tooltip: 'Manage Event',
                        onClick: (event, rowData) =>
                        {
                            window.open(`/manage-event/${ rowData.id }/general`, '_blank');
                        }
                    },
                    {
                        icon: Delete,
                        tooltip: 'Delete Event',
                        onClick: (event, rowData) =>
                        {
                            handleDelete(rowData.id);
                        }
                    },
                    {
                        icon: Refresh,
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                    }
                ]}
                options={{
                    pageSize: events?.size ? events?.size : 10,
                    actionsColumnIndex: 999,
                    headerStyle: {
                        backgroundColor: palette['brown-grey'],
                        color: '#FFF',
                        fontWeight: 'bold',
                        fontSize: 14
                    },
                    rowStyle: {
                        fontSize: 12,
                        padding: 0
                    }
                }}
            />
        </Grid>
        <Dialog
            open={openDeleteModal}
            onClose={() =>
            {
                setOpenDeleteModal((prevOpenDeleteModal) => !prevOpenDeleteModal);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <Box p={3} pt={1}>

                <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
                <DialogContent>
                    <Typography component="p">Are you sure you want to delete this event?</Typography>
                </DialogContent>
                <DialogActions>

                    <Grid xs={12}  p={1} display='flex' flexDirection='row' spacing={1}>
                      
                        <Button
                            size='small' variant='outlined' color='primary'
                            onClick={() =>
                            {
                                setOpenDeleteModal(false)
                            }}>
                            Close
                        </Button>
                            {" "}
                        <Button
                            size='small' color='primary'
                            onClick={() =>
                            {
                                handleDeleteEvent();
                            }}
                        >
                            Confirm
                            </Button>
     
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    </>
})

export default Events
