import React, {useEffect, useRef, useState} from 'react'
import debounce from 'lodash.debounce'
import RootRef from '@material-ui/core/RootRef'
import {withStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import ButtonBase from '@material-ui/core/ButtonBase'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import clsx from 'clsx'

const styles = theme => ({
  container: {
    overflow: 'hidden'
  },
  item: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginRight: theme.spacing(2),
  },
  left: {
    position: 'absolute',
    left: 0
  },
  right: {
    position: 'absolute',
    right: 0
  },
  navCol: {
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    alignContent: 'center',
    height: '100%'
  },
  button: {
    display: 'flex',
    borderRadius: '50%',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  disabled: {
    opacity: 0.4,
    cursor: 'default'
  }
})

const ScrollableContainer = withStyles(styles)(({classes, items}) => {
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(true)
  const domRef = useRef()


  const checkScrollPosition = () => {
    const {scrollLeft, scrollWidth, clientWidth} = domRef.current
    setCanScrollLeft(scrollLeft > 0)
    setCanScrollRight(scrollLeft !== scrollWidth - clientWidth)
  }

  const debounceCheckForScrollPosition = debounce(
    checkScrollPosition,
    200
  )

  const scrollByDistance = (distance) => {
    domRef.current.scrollBy({left: distance, behavior: 'smooth'})
  }

  useEffect(() => {
    if (items.length===0) return
    domRef.current.addEventListener('scroll', debounceCheckForScrollPosition)

    const domReference = domRef.current
    return () => {
      domReference.removeEventListener('scroll', debounceCheckForScrollPosition)
    }
    //eslint-disable-next-line
  }, [])

  if (items.length===0) return <></>

  return <Box display='grid' position='relative' pl={4} pr={4} width={'100%'} style={{boxSizing: 'border-box'}}>
    <RootRef rootRef={domRef}>
      <Box className={classes.container} display='flex' justifyContent='flex' alignItems='flex'>
        {items.map((item,i) => <Box display='flex' className={classes.item} key={i}> {item} </Box>)}
      </Box>
    </RootRef>

    <Box className={clsx(classes.navCol, classes.left)}>
      <ButtonBase disabled={!canScrollLeft} className={clsx(classes.button, !canScrollLeft && classes.disabled)}
                  onClick={() => scrollByDistance(-200)}> <ChevronLeftIcon fontSize='large'/> </ButtonBase>
    </Box>

    <Box className={clsx(classes.navCol, classes.right)}>
      <ButtonBase disabled={!canScrollRight} className={clsx(classes.button, !canScrollRight && classes.disabled)}
                  onClick={() => scrollByDistance(200)}> <ChevronRightIcon fontSize='large'/> </ButtonBase>
    </Box>
  </Box>
})

export default ScrollableContainer
