import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'

const styles = theme => ({
  previewContainer: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    minHeight: '380px',
    overflowY: 'auto'
  },
  recipientsContainer: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '348px',
    overflowY: 'auto'
  }
})

const Preview = withStyles(styles)(({classes, state, setState})=>{

  const [text,setText] = useState('')
  const [keywords, setKeywords] = useState({
    PersonTitle: '-',
    PersonFirstName: '-',
    PersonLastName: '-',
    EventAcronym:'',
    EventName: state.eventTitle,
    EventUrl: `https://www.anymeets.com/event/${state.eventId}/infο`,
    MessageSignature: state.signature,
    ReviewerReplyUrl: '-',
    AssignedPaperList: '-'
})
  const [selectedMember, setSelectedMember] = useState(0)
  const [search, setSearch] = useState('')

  useEffect(()=>{

    let template = state.bodyEditor.value.toString('html')
    var r = template.match(/\{[\w]+\}/g);
    r && r.forEach((state) => {
      var regex = new RegExp(state, 'g')
      var stateItem = state.split(/{|}/g)[1]
      template  = template.replace(regex, keywords[stateItem])
    })

    setText(template)

    //eslint-disable-next-line
  },[keywords])

  const setPreview = (member) => {
    setKeywords({
      ...keywords,
      PersonFirstName: member.firstName?member.firstName:'',
      PersonLastName: member.lastName?member.lastName:'',
      PersonTitle: member.title?member.title:'',
      AssignedPaperList: '-'
    })
  }

  useEffect(()=>{
    setPreview(state.groupRecipients[selectedMember])
    //eslint-disable-next-line
  },[selectedMember])

return <Grid item container spacing={2}>
    <Grid item xs={12} sm={8}>
      Body preview
    </Grid>
    <Grid item xs={12} sm={4}>
      Preview as
    </Grid>
    <Grid item xs={12} sm={8}>
      <Box display='flex' className={classes.previewContainer} p={2}>
        <Box display='flex'>
          <div dangerouslySetInnerHTML={{__html: text}}/>
        </Box>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Box pb={1}>
        <TextField type='text' name='search' label='Search recipients...' variant='filled' fullWidth value={search} onChange={(e)=>setSearch(e.target.value)}/>
      </Box>
      <Box className={classes.recipientsContainer}>

      <List dense className={classes.root}>
        {state.groupRecipients.filter((m)=>`${m.firstName} ${m.lastName}`.toLowerCase().includes(search.toLowerCase())).map((member,i) => {
          return (
            <ListItem key={i} button onClick={()=>setSelectedMember(i)} selected={selectedMember===i}>
              <ListItemAvatar>
                <Avatar
                  alt={`${member.title?member.title:''} ${member.firstName} ${member.lastName}`}
                  src={imageFile(member.image)}
                />
              </ListItemAvatar>
              <ListItemText primary={`${member.title?member.title:''} ${member.firstName} ${member.lastName}`} />
            </ListItem>
          );
        })}
      </List>

      </Box>

    </Grid>
  </Grid>
})

export default Preview
