import withStyles from '@material-ui/styles/withStyles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import api from 'api'
import {useHttp} from 'api/core'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import Dialog from '@material-ui/core/Dialog'
import SessionEditNew from 'components/core/Sessions/SessionEditNew'
import TimeGrid from 'react-big-calendar/lib/TimeGrid'
import * as dates from 'date-arithmetic'
import {eachDayOfInterval, format} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import Chip from '@material-ui/core/Chip'
import {palette} from 'theme'
import clsx from 'clsx'
import Button from "components/core/Button";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import language from "components/language";
import differenceInDays from "date-fns/differenceInDays";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { AlignmentType, Document, Footer, Header, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import {saveAs} from "file-saver";


const DnDCalendar = withDragAndDrop(Calendar)


// const locales = {
//   'en-US': require('date-fns/locale/en-US'),
// }

// const localizer = dateFnsLocalizer({
//   format,
//   parse,
//   startOfWeek,
//   getDay,
//   locales,
// })

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const styles = theme => ({
  scheduleContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),

    // marginBottom: theme.spacing(5),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    '& .rbc-time-header': {
      backgroundColor: palette['pale-grey'],
      paddingRight: '15px',
      marginRight: '0 !Important',
      borderRight: 0
    },
    '& .rbc-time-header-gutter':{
      backgroundColor: palette['pale-grey']
    },
    '& .rbc-time-content > * + * > *': {
      borderColor: 'rgba(0, 0, 0, 0.1)'
    },
    '& > *': {
      fontSize: '15px',
      fontWeight: 'bold'
    },
    '& .rbc-time-slot': {
      paddingTop: '5px'
    },
    '& .rbc-timeslot-group': {
      backgroundColor: 'white'
    },
    '& .rbc-time-column': {
      color: palette['brown-grey']
    },
    '& .rbc-event': {
      fontSize: '14px',
      backgroundColor: palette['cerulean-blue']
    },
    '& .rbc-agenda-table thead': {
      backgroundColor: palette['pale-grey'],
      // border: 'none',
      border: 'solid 1px rgba(0, 0, 0, 0.1)'
    },
    '& .rbc-agenda-table .rbc-header + .rbc-header':{
      borderLeft: 'none',
      height: '52px',
      lineHeight: '52px',
      textAlign: 'center',
    },
    '& .rbc-agenda-view table.rbc-agenda-table thead > tr > th': {
      textAlign: 'center'
    },
    '& .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-date-cell': {
      color: palette['brown-grey']
    },
    '& .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell': {
      color: palette['brown-grey']
    },
    '& .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-event-cell' : {
      color: palette['greyish-brown']
    },
    '& .rbc-agenda-view table.rbc-agenda-table tbody > tr > td': {
      padding: '16px 10px'
    },
    '& .rbc-agenda-view .rbc-agenda-content .rbc-agenda-table': {
      borderTop: 0
    },
    '& .rbc-time-header-cell > .rbc-header': {
      minHeight: '52px',
      lineHeight: '52px',
      borderBottom: 0
    },
    '& .rbc-time-header-content > .rbc-row.rbc-row-resource': {
      minHeight: '52px',
      lineHeight: '52px',
      borderBottom: 0
    },
    '& .rbc-time-header-content > .rbc-row.rbc-row-resource > .rbc-header': {
      borderBottom: 0
    },
    '& .rbc-allday-cell':{
      display: 'none'
    },
    '& .rbc-time-content': {
      borderTop: '1px solid #DDD'
    },
    '& .rbc-header + .rbc-header' : {
      border: 0
    },
    '& .rbc-time-header-content': {
      border: 0
    }
  },
  calendarContainer: {
    maxWidth: "calc(100vw - 340px)"
  },
  chip:{
      textTransform: 'none',
      color: palette['greyish-brown'],
      marginRight: theme.spacing(1),
      backgroundColor: theme.palette.grey[300],
      borderRadius: theme.spacing(8),
      opacity: 1,
      minHeight: '36px',
      minWidth: 'auto',
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
  selected: {
    backgroundColor: `${palette['cerulean-blue']} !important`,
    color: 'white'
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-23px",
    "& Button": {
      minWidth: "170px"
    }
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },  
})

const createEvents = (sessions) => {
  let events = []
  sessions.sort((a, b) => parseInt(a.ordinal) - parseInt(b.ordinal)).forEach((s) => events.push({
    session: s,
    start: new Date(s.startDate),
    end: new Date(s.endDate),
    title: s.title,
    resourceId: s.room?s.room.id:-1
  }))
  return events
}


class MyWeek extends React.Component {
  render() {
    let {date} = this.props
    let range = MyWeek.range(date)

    return <TimeGrid {...this.props} range={range}/>
  }
}

// const SessionTooltip = withStyles(theme => ({
//   tooltip: {
//     backgroundColor: 'transparent',
//     padding: 0,
//     // color: 'rgba(0, 0, 0, 0.87)',
//     maxWidth: 240,
//     // fontSize: theme.typography.pxToRem(12),
//     border: '1px solid #fff',
//     boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
//   },
// }))(Tooltip);

// const SessionSchedule = ({event}) => {
//   return <SessionTooltip
//     title={
//       <React.Fragment>
//         <SessionCard session={event}/>
//       </React.Fragment>
//     }
//   ><Box>{event.title}</Box></SessionTooltip>
// }

const Schedule = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {
  const [events, setEvents] = useState()
  const [days, setDays] = useState()
  const [day, setDay] = useState(-1)
  const [formSave, setFormSave] = useState()
  // const [sessionTypes, setSessionTypes] = useState()
  const [participants, setParticipants] = useState()
  const [presentations, setPresentations] = useState()
  const [sessionsData, setSessionsData] = useState()

  const [getDataPublish, dataPublish] = useHttp();

  const [rooms, setRooms] = useState()
  const [getTracks, trackData] = useHttp()
  const [tracks,setTracks] = useState()

  const [getSessionTypes, sessionTypesData] = useHttp()
  const [sessionTypes, setSessionTypes] = useState()

  const [getRooms, roomData] = useHttp()
  const [editingSession, setEditingSession] = useState()
  const [getData, data] = useHttp()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [getParticipantsData, participantsData] = useHttp()
  const [getPresentationData, presentationData] = useHttp()
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  // const [getSessionTypes, sessTypes] = useHttp()
  const [getAgendaData, agendaData] = useHttp();
  const [getEventData, event] = useHttp();
  const {eventId} = useParams()

  useEffect(() => {
    getData(api.entities.manage.getSessions(null, null, { id: eventId }))
    getEventData(api.entities.events.getInfo(null, null, {id: eventId}));    
    getAgendaData(api.entities.events.getAgenda(null, null, {id: eventId}));    
    getTimeZones(api.entities.timezones.getTimeZones());
    //eslint-disable-next-line
  }, [])

  useEffect(() =>
  {
    getData(api.entities.manage.getSessions(null, null, { id: eventId }))
    getEventData(api.entities.events.getInfo(null, null, {id: eventId}));    
    getAgendaData(api.entities.events.getAgenda(null, null, {id: eventId}));    
    //eslint-disable-next-line
  }, [dataPublish])

  useEffect(() => {
    if (trackData && timeZones) {
        const zone = timeZones.find((x) => x.id === trackData.eventInfo.timezoneId);
        moment.tz.setDefault(zone.name);
        setSelectedTimezone(zone.name);
      //console.log(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trackData, timeZones]);

  useEffect(() => {
    getRooms(api.entities.rooms.list(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getParticipantsData(api.entities.manage.getParticipants(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    // getSessionTypes(api.entities.options.getSessionTypes())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    getPresentationData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data) {
      setSessionsData(data.sessions)
      setEvents(createEvents(data.sessions))
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({path: '/manage-events', name: 'events'})
      setDays(eachDayOfInterval({start: parseISO(data.eventInfo.startDate), end: parseISO(data.eventInfo.endDate)}))

      MyWeek.range = date => {
        let start = data.eventInfo.startDate
        let end = data.eventInfo.endDate

        let current = start
        let range = []

        while (dates.lte(current, end, 'day')) {
          range.push(current)
          current = dates.add(current, 1, 'day')
        }

        return range
      }

    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  useEffect(() => {
    if (roomData) {
      setRooms([
        ...roomData,
        {
          id: -1,
          name: 'Unassigned'
        }
      ])
    }
  }, [roomData])

  useEffect(() => {
    getTracks(api.entities.manage.getTracks(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (trackData) {
      setTracks(trackData.tracks)
    }
  }, [trackData])

  useEffect(() => {
    getSessionTypes(api.entities.manage.getSessionTypes(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sessionTypesData) {
      setSessionTypes(sessionTypesData.sessionTypes)
    }
  }, [sessionTypesData])

  // useEffect(() => {
  //   if (sessTypes) {
  //     setSessionTypes(sessTypes)
  //   }
  // }, [sessTypes])

  useEffect(() => {
    if (presentationData) {
      setPresentations(presentationData)
    }
  }, [presentationData])

  useEffect(() => {
    if (formSave) {

      const {ordinal,code, title, type, location, description, startDate, endDate, id, roomId,trackId, rtmps, videoStreamType,isPrivate, playRecording, coachEmail, chairId, sessionTypeId, url, urlJson, recordingUrl} = formSave

      const participants = formSave.participants.map((participant) => participant.id)
      const presentations = formSave.presentations.map((presentation) => presentation.id)

      // WARNING: If payload changes, change in SessionEditNew.js too
      let sessionPayload = {
        order: ordinal,
        code,
				title,
				type,
				location,
				description,
				startDate,
				endDate,
				participants,
				presentations,
				id,
				chairId,
        roomId,
        trackId,
				isPrivate,
				deletedFiles: [],
				videoStreamType,
				rtmps,
				playRecording,
				coachEmail,
				sessionTypeId,
				url,
				urlJson,
				recordingUrl,
			};

      let formDataObj = new FormData()

      const blob = new Blob([JSON.stringify(sessionPayload)], {type: 'application/json'});
      formDataObj.append('session', blob)

      sendRequest(api.entities.sessions.update(formDataObj, null, {id: formSave.id}, true))

    }
    // eslint-disable-next-line
  }, [formSave])

  useEffect(() => {
    if (putData) {
      setFormSave(undefined)
      getData(api.entities.manage.getSessions(null, null, {id: eventId}))
    }
    // eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (hasError) {
      // handle error
    }
  })

  useEffect(() => {
    if (participantsData) {
      setParticipants(participantsData.participants)
    }
  }, [participantsData, setParticipants])

  const handleClose = (shouldReload) => {
    setEditingSession(false)
    if (shouldReload) {
      getPresentationData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}))
      getData(api.entities.manage.getSessions(null, null, {id: eventId}))
    }
  }

  const handleOpen = (session, day, start, end, roomId) => {
    if (session) {
      setEditingSession({session:session,startEnd:{start,end,roomId:roomId>=0?roomId:null}})
    } else {
      setEditingSession({startEnd:{start,end,roomId:roomId>=0?roomId:null}})
    }
  }

  const onDoubleClickEvent = (event) => {
    // console.log(event)
    handleOpen(event.session)
  }

  const onEventResize = ({event, start, end, allDay}) => {
    let newEvents = [...events]

    let changedEvent = newEvents.find((e) => e.session.id === event.session.id)
    changedEvent.start = start
    changedEvent.end = end
    changedEvent.session.startDate = start
    changedEvent.session.endDate = end

    setEvents(newEvents)
    setEventInfo({...data.eventInfo, dirty: true})
    setFormSave({...event.session, startDate: start, endDate: end})
  }

  const onEventDrop = (props) => {
    const {event,start,end, resourceId} = props
    // console.log(props)
    let newEvents = [...events]

    let changedEvent = newEvents.find((e) => e.session.id === event.session.id)
    changedEvent.start = start
    changedEvent.end = end
    changedEvent.session.startDate = start
    changedEvent.session.endDate = end
    changedEvent.session.roomId = resourceId?resourceId:event.session.room?.id
    changedEvent.resourceId = resourceId?resourceId:changedEvent.resourceId
    changedEvent.session.roomId = changedEvent.session.roomId===-1?null:changedEvent.session.roomId
    changedEvent.session.trackId = event.session.track ? event.session.track.id : event.session.trackId;
    
    setEvents(newEvents)
    setEventInfo({...data.eventInfo, dirty: true})
    setFormSave({...event.session, startDate: start, endDate: end, roomId: resourceId?resourceId===-1?null:resourceId:event.session.roomId})
  }

  const handleSelect = (e) => {
    const { start, end, resourceId } = e
    handleOpen(undefined, undefined, start, end, resourceId)
  }

  // let Components = {
  //   event: SessionSchedule
  // }

  MyWeek.navigate = (date, action) => {
    switch (action) {
      default:
        return date
    }
  }

  MyWeek.title = date => {
    return `${date.toLocaleDateString()}`
  }

  const generateDoc = (agenda) => {
    const startEndDates = `${format(
      parseISO(event.eventInfo.startDate),
      differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa",
    )} - ${format(parseISO(event.eventInfo.endDate), differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa")}`;

    const doc = new Document({
      sections: [
        // Page 1
        {
          headers: {
            default: new Header({
              // The standard default header
              children: [],
            }),
          },
          footers: {
            default: new Footer({
              // The standard default footer
              children: [
                new Paragraph({
                  text: `${event.eventInfo.name} - Anymeets.com`,
                  alignment: AlignmentType.RIGHT,
                }),
              ],
            }),
          },

          properties: {},
          children: [
            new Paragraph({
              text: event.eventInfo.name,
              heading: HeadingLevel.TITLE,
              alignment: AlignmentType.CENTER,
              spacing: {
                before: 2500,
                after: 1000,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: startEndDates,
                  size: 28,
                }),
              ],
              spacing: {
                before: 1000,
                after: 2000,
              },
            }),

            ...event.description.split("\n").map((p) => {
              return new Paragraph({text: p, break: true});
            }),
          ],
        },

        {
          properties: {},
          children: [
            new Paragraph({
              text: `Keynote Speaker${event.keynoteSpeakers.length > 1 ? "s" : ""}`,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 50,
              },
            }),

            ...event.keynoteSpeakers.map((speaker) => {
              return new Paragraph({
                children: [
                  new TextRun({
                    text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                    size: 21,
                  }),
                ],
              });
            }),
          ],
        },

        ...agenda.sessions.sort((a, b) => { return parseISO(a.startDate) - parseISO(b.startDate); }).map((session) => {
          const startEndDates = `${format(parseISO(session.startDate), "d MMMM yyyy, hh:mm aa")} - ${format(parseISO(session.endDate), "hh:mm aa")}`;
          const presentations = session.presentations
            ? session.presentations.map((presentation) => {
                const presenter = presentation.participants.find((p) => p.id === presentation.presenterId);

                return [
                  new Paragraph({
                    text: presentation.title,
                    heading: HeadingLevel.HEADING_3,
                    spacing: {
                      before: 500,
                      after: 50,
                    },
                  }),
                  new Paragraph({
                    text: `Author${presentation.participants.length > 1 ? "s" : ""}`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  ...presentation.participants.map((speaker) => {
                    return new Paragraph({
                      children: [
                        new TextRun({
                          text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                          size: 21,
                        }),
                      ],
                      spacing: {
                        after: 50,
                      },
                    });
                  }),

                  new Paragraph({
                    text: `Presenter`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  new Paragraph({
                    children: [
                      new TextRun({
                        text: presenter ? `${presenter.title ? presenter.title + " " : ""}${presenter.lastName}, ${presenter.firstName}, ${presenter.profession}, ${presenter.instituteOrCompany}` : "-",
                        size: 21,
                      }),
                    ],
                    spacing: {
                      after: 50,
                    },
                  }),

                  new Paragraph({
                    text: `Abstract`,
                    heading: HeadingLevel.HEADING_4,
                    spacing: {
                      before: 100,
                      after: 50,
                    },
                  }),

                  ...presentation.abstract.split("\n").map((p) => {
                    return new Paragraph({
                      spacing: {
                        before: 50,
                      },
                      children: [
                        new TextRun({
                          // text: p.replace(/[^ -~][^\n]+/g, ""),
                          text:  p.replace(/[^ -~]+/g, " "),
                          size: 24,
                        }),
                      ],
                    });
                  }),
                ];
              })
            : [];

          return {
            properties: {},
            children: [
              new Paragraph({
                text: session.title,
                heading: HeadingLevel.HEADING_1,
              }),

              new Paragraph({
                spacing: {
                  before: 50,
                },
                children: [
                  new TextRun({
                    text: startEndDates,
                    size: 28,
                    bold: true,
                  }),
                ],
              }),

              new Paragraph({
                border: {
                  bottom: {
                    color: "auto",
                    space: 0,
                    value: "single",
                    size: 4,
                  },
                },
              }),

              new Paragraph({
                text: "Description",
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 300,
                },
              }),

              ...session.description.split("\n").map((p) => {
                return new Paragraph({text: p, break: true});
              }),

              new Paragraph({
                text: "Speakers",
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 500,
                  after: 50,
                },
              }),

              ...session.participants.map((speaker) => {
                return new Paragraph({
                  children: [
                    new TextRun({
                      text: `${speaker.title ? speaker.title + " " : ""}${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                      size: 21,
                    }),
                  ],
                  spacing: {
                    after: 50,
                  },
                });
              }),

              new Paragraph({
                text: `Presentation${session.presentations.length > 1 ? "s" : ""}`,
                heading: HeadingLevel.HEADING_2,
                spacing: {
                  before: 500,
                  after: 50,
                },
              }),

              new Paragraph({
                border: {
                  bottom: {
                    color: "auto",
                    space: 0,
                    value: "single",
                    size: 4,
                  },
                },
              }),

              ...[].concat.apply([], presentations),
            ],
          };
        }),

        {
          properties: {},
          children: [
            new Paragraph({
              text: `List of Speakers`,
              heading: HeadingLevel.HEADING_1,
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 100,
              },
            }),

            ...participantsData.participants
              .sort(function (a, b) {
                let textA = a.lastName.toUpperCase();
                let textB = b.lastName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              })
              .map((speaker) => {
                return new Paragraph({
                  children: [
                    new TextRun({
                      text: `${speaker.lastName}, ${speaker.firstName}, ${speaker.profession}, ${speaker.instituteOrCompany}`,
                      size: 21,
                    }),
                  ],
                  spacing: {
                    after: 100,
                  },
                });
              }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${event.eventInfo.name}.docx`);
    });
  };  

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
  };

  if (!sessionsData || !events || !participants || !presentations || !rooms) return <> </>

  return <Grid container>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid container className={classes.scheduleContainer} spacing={2}>
        <Grid container item xs={12} direction="row" display="flex" justifyContent='flex-end' alignContent="right" alignItems="right">
          <Button size="smaller" color="primary" onClick={() => generateDoc(agendaData)} disabled={!(agendaData && event && participantsData)}>
            <DescriptionOutlinedIcon /> &nbsp; {language.exportAgendaAsDocx}
          </Button>    
        </Grid>        
        <Grid item xs={12}>
          <Box className={classes.calendarContainer}>
            <Dialog onClose={handleClose} open={!!editingSession} maxWidth={'md'} fullWidth>
              <SessionEditNew session={editingSession?editingSession.session:null} handleClose={handleClose} eventId={eventId}
                              eventInfo={data.eventInfo} editingStartEnd={editingSession?editingSession.startEnd:null} roomId={editingSession?editingSession.startEnd.roomId:null}
                              presentations={presentations.presentations.filter((a) => a.sessionId === null)}
                              participants={participants} editingDay={null}
                              totalSessions={events.length} rooms={rooms} tracks={tracks} uploadFiles={true}
                              sessionTypes={sessionTypes} selectedTimezone={selectedTimezone}
              />
            </Dialog>

            <Grid item container justify='space-between' alignContent='flex-end' spacing={2}>
              <Grid item xs={9} className={classes.navigation}>
                <Chip label={'All'} onClick={() => setDay(-1)} classes={{root:classes.chip}} className={clsx(day===-1&&classes.selected)}/>
                {days.map((d, i) => {
                  return <Chip key={i} label={`Day ${i + 1}`} onClick={() => setDay(i)}
                                classes={{root:classes.chip}} className={clsx(day===i&&classes.selected)}/>
                })}
              </Grid>
              <Grid item xs={3}>
                <Grid item container justify='flex-end'>
                  <Grid item>
                    <Chip label={'Agenda'} onClick={() => setDay(-2)} classes={{root:classes.chip}} className={clsx(day===-2&&classes.selected)}/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'>
              <Box display='flex' fontWeight='bold' fontSize='22px' p={3} pt={4}>
                {day>=0?format(days[day], 'LLL do y'):<>{format(parseISO(data.eventInfo.startDate), 'LLL do y')} - {format(parseISO(data.eventInfo.endDate), 'LLL do y')}</>}
              </Box>
            </Box>

            <DnDCalendar
              // Components={Components}
              view={day===-1?'week':day===-2?Views.AGENDA:Views.DAY}
              date={days[day]?days[day]:parseISO(eventInfo.startDate)}
              views={{day:true, agenda:true, week: MyWeek}}
              localizer={localizer}
              events={events}
              resources={day!==-1&&day!==-2?rooms.length?rooms:null:null}
              resourceIdAccessor='id'
              resourceTitleAccessor='name'
              startAccessor='start'
              endAccessor='end'
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              onDoubleClickEvent={onDoubleClickEvent}
              resizable
              disabled={isLoading}
              toolbar={false}
              style={{height: `calc(100vh - ${eventInfo.dirty ? 410 : 330}px)`}}
              selectable
              onSelectSlot={handleSelect}
            />

          </Box>
        </Grid>
      </Grid>
    </Fade>
     {eventInfo && eventInfo.dirty && <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons}  style={{position:"absolute" , top:  "0" }}>                   
                      <Alert className={classes.topPublish} severity="warning">
                        <AlertTitle>Unpublished changes</AlertTitle>
                        You are viewing an event with unpublished changes.
                      </Alert>                    
                    <Box display="inline-block" mt={1} mb={1}>
                      {eventInfo.dirty ? (
                        <Button
                          onClick={() => {
                            publishEvent();
                          }}>
                          Publish
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>}
  </Grid>
})

export default withRouter(Schedule)
