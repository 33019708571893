import {Link} from 'react-router-dom'
import Button from 'components/core/Button'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import Box from '@material-ui/core/Box'
import {LAYOUT_TYPES} from 'config'


const AlertUnpublishedChanges = ({eventId, openDrawer, eventLayout}) => {
    return <>
    <Box ml={(eventLayout === LAYOUT_TYPES.WITH_DRAWER) || (eventLayout === LAYOUT_TYPES.WITH_DRAWER_CONTAINED) ? openDrawer? 30 : 6 : 0}>
        <Alert severity='warning' action={<Link
            to={`/event/${eventId}/info?preview=true`}>
            <Button color='primary' size='smaller'>
                PREVIEW
            </Button>
            &nbsp;
        </Link>}>
            <AlertTitle>Published event</AlertTitle>
            You are viewing a published event but there are unpublished changes.
        </Alert>
    </Box>
        </>
}
export default AlertUnpublishedChanges
