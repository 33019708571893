import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import {
  IconArrowDownBorderDark,
  IconFileBorderDark,
} from 'components/core/icons'
import ParticipantChipView from 'components/core/ParticipantChipView'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import language from "components/language";

const styles = theme => ({
  container: {
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    padding: theme.spacing(2)
  },
  PresentationCard: {
    marginBottom: theme.spacing(2)
  },
  fieldTitle: {
    width: '100%',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    width: '100%',
    fontSize: '14px'
  },
  presentationBox: {
    backgroundColor: palette['pale-sky-blue'],
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown']
  },
  presentationTitle: {
    color: palette['cerulean-blue'],
    fontWeight: 'bold'
  },
  presentationsBox: {
    paddingTop: theme.spacing(1),
    overflowX: 'auto'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  },
  notMatched: {
    opacity: 0.3
  },
  participants: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    '& > *': {
      color: palette['greyish-brown'],
      margin: theme.spacing(0.5),
      borderColor: 'white'
    }
  },
  avatar: {
    color: `${palette['greyish-brown']} !important`,
    background: 'none',
    '&> img':{
      maxWidth: '100%'
    }
  },
  fileList: {
    margin: 0,
    color: palette['greyish-brown']
  },
  root: {
    fontSize: '14px',
    '&:focus > *': {
      background: 'none !important',
    },
    paddingBottom: theme.spacing(1)
  },
  listItem: {
    paddingLeft: 0,
    '&>div': {
      minWidth: '30px',
      color: palette['greyish-brown']
    },
    '&>a':{
      color: palette['greyish-brown']
    },
    '&>div>a':{
      color: palette['greyish-brown']
    }
  },
  treeItem: {
    background: 'none',
    backgroundColor: 'none',
    borderRadius: theme.spacing(1),
  },
  treeItemExpanded: {
    background: 'none',
    borderRadius: theme.spacing(1),
    '&:focus > $content': {
      backgroundColor: 'transparent',
    },
  },
  treeItemSelected: {
    // background: 'none',
    borderRadius: theme.spacing(1)
  },
  treeItemIcon: {
    alignSelf: 'flex-start'
  },
  presentationUrl: {
    position: 'absolute',
    right: '15px',
    top: '19px',
    color: palette['brown-grey'],
    '&:hover': {
      color: palette['cerulean-blue']
    }
  },
  expansionPanel: {
    background: 'white',
    border: 0,
    boxShadow: 'none'
  },
  expansionPanelSummary: {
    position: 'relative'
  },
  tab: {
    width: '100%',
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    padding: theme.spacing(2),
    border: `1px solid ${palette['very-light-pink-two']}`
  },
  tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  activeTab: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    '&:focus': {
      backgroundColor: palette['cerulean-blue'],
      color: 'white',
    }
  },
  tabLabel: {
    width: '100%',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  label: {
    background: 'transparent !important',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  chip: {
    border: 'none',
    color: palette['greyish-brown'],
  }
})


const PresentationCard = withStyles(styles)(({classes, presentation, viewOnly = false, editAction, deleteAction, ...props}) => {

  return <Box className={clsx(classes.PresentationCard,presentation.matched===false&&classes.notMatched)} {...props}>

      <Grid container spacing={2} className={classes.container}>

        <ExpansionPanel classes={{root:classes.expansionPanel}}>
          <ExpansionPanelSummary
            expandIcon={<IconArrowDownBorderDark />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{root:classes.expansionPanelSummary}}
          >

        <Grid item xs={12}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box fontSize={14}>
                {language.presentationTitle}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display='flex' fontSize={20} fontWeight={'bold'}>
                <Box fontWeight='bold' component={'span'}>
                  "{presentation.title}"<Box fontWeight='normal' component={'span'}>{presentation.participants.length!==0?'.':''}{presentation.participants.map((p,i)=><>{p.title?p.title:''} {p.firstName} {p.lastName} {i!==presentation.participants.length-1?',':''}</>)}</Box>
                </Box>
              </Box>
            </Grid>
          </Grid>

        </Grid>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails>

        {/*<Grid item xs={12}>*/}
        {/*  <Box display='flex' width='100%' justifyContent='space-between' alignContet='center' alignItems='center'>*/}
        {/*    <Box display='flex' flexDirection='column'>*/}
        {/*      <Box display='flex' fontSize={14}>*/}
        {/*        Session*/}
        {/*      </Box>*/}
        {/*      <Box display='flex' fontSize={20} fontWeight={'bold'}>*/}
        {/*        {presentation.sessionTitle?<>*/}
        {/*          {presentation.sessionTitle}*/}
        {/*        </>:'-'}*/}
        {/*      </Box>*/}
        {/*    </Box>*/}
        {/*  </Box>*/}
        {/*</Grid>*/}

        <Grid item container spacing={2}>

        {presentation.abstract&&<Grid item xs={12}>
          <Box display='flex' width='100%' justifyContent='space-between' alignContet='center' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' fontSize={14}>
                {language.abstract}
              </Box>
              <Box display='flex'>
                  {presentation.abstract}
              </Box>
            </Box>
          </Box>
        </Grid>}

        {presentation.participants&&presentation.participants.length!==0&&<Grid item xs={12} sm={6}>
          <Box display='flex' width='100%' justifyContent='space-between' alignContet='center' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' fontSize={14}>
               {language.authors}
              </Box>
              <Box display='flex' className={classes.participants}>
                {presentation.participants.map((participant,i) => {
                  return <ParticipantChipView participantObj={participant} />
                })}
              </Box>
            </Box>
          </Box>
        </Grid>}

        {(props.event.eventInfo.proceedingsAccess === 1 || (props.event.eventInfo.proceedingsAccess === 3 && props.event.eventInfo.registered === true) )&&
              presentation.files && presentation.files.length > 0 &&
          <Grid item xs={12} sm={6}>
              <Box display='flex' width='100%' justifyContent='space-between' alignContet='center' alignItems='center'>
                <Box display='flex' flexDirection='column'>
                  <Box display='flex' fontSize={14} pb={1}>
                    {language.files}
                  </Box>
                  <Box display='flex' flexDirection='column'>
                    {presentation.files.map((file,i) => {
                      return <Box display='flex' pb={1}>
                        <Chip
                          avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                          variant="filled"
                          label={`${file.filename}`}
                          className={classes.chip}
                          component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                        />
                      </Box>
                    })}
                  </Box>
                </Box>
              </Box>
          </Grid>              
        }

        {presentation.keywords&&<Grid item xs={12} sm={6}>
          <Box display='flex' width='100%' justifyContent='space-between' alignContet='center' alignItems='center'>
            <Box display='flex' flexDirection='column'>
              <Box display='flex' fontSize={14} pb={1}>
                {language.keywords}
              </Box>
              <Box display='flex' flexDirection='column'>
                <Box display='flex' pb={1}>
                  {presentation.keywords}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>}
        </Grid>
          </ExpansionPanelDetails>

        </ExpansionPanel>
      </Grid>

  </Box>
})

export default PresentationCard
