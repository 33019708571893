import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
// import FormEditor from 'Components/core/FormEditor'
import api from 'api'
import {useSnackbar} from 'notistack'
// import Chip from '@material-ui/core/Chip'
// import clsx from 'clsx'
// import EventNoteIcon from '@material-ui/icons/EventNote'
// import {TextField} from '@material-ui/core'
// import {DatePicker} from '@material-ui/pickers'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import {IconCalendarBorderDark} from 'Components/core/icons'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'
// import NumberFormat from "react-number-format"
// import LinearScaleIcon from '@material-ui/icons/LinearScale'
// import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormViewer from 'components/core/Forms/FormViewer'
import RootRef from '@material-ui/core/RootRef'
import DialogContent from '@material-ui/core/DialogContent'
import FormReview from 'components/core/Forms/FormReview'
import {transformForm} from 'utils'
const styles = theme => ({
    container: {
        padding: '40px 50px 50px 50px',
        // backgroundColor: palette['cerulean-blue'],
        // color: 'white',
        borderRadius: '6px'
    },
    newFormType: {
        // width: '580px'
    },
    row: {
        marginBottom: theme.spacing(5)
    },
    tabsBar: {
        backgroundColor: palette['very-light-pink']
    },
    tab: {
        width: '100%',
        color: 'white',
        backgroundColor: palette['cerulean-blue'],
        padding: theme.spacing(2)
    },
    Tabs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    activeTab: {
        backgroundColor: palette.aquamarine,
        '&:focus': {
            backgroundColor: palette.aquamarine
        }
    },
    tabLabel: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    addNewItem:{
        // backgroundColor: 'white',
        // padding: theme.spacing(2),
        // borderRadius: '6px',
        border: '2px dashed !important',
        // borderColor: palette['cerulean-blue'],
        // justifyContent: 'center',
        // alignItems: 'center',
        // fontWeight: 'bold',
        // color: palette['greyish-brown'],
        // fontSize: '18px',
        opacity: 0.5,
        margin: 0,
        cursor: 'pointer',
        transition: '0.4s all',
        '&:hover': {
            opacity: 0.8
        }
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid',
        borderRadius: '6px',
        borderColor: 'white',
        backgroundColor: 'rgba(0,0,0,0.1)',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    formControl: {
        width: '100%'
    },
    toolbar: {
        borderTop: '1px solid',
        color: 'white',
        marginTop: theme.spacing(2),
    },
    editor: {
        color: palette['greyish-brown'],
        borderRadius: '6px',
        minHeight: '300px',
        fontFamily: "'Roboto','Karla', sans-serif;",
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    dialogActions: {
        marginTop: theme.spacing(2)
    },
    btns: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
    mainBox: {
        marginTop: theme.spacing(3),
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: '350px',
        // overflowY: 'auto'
        // minHeight: 'calc(80vh - 300px)'
    }
})

// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//         suffix="%"
//       />
//     );
// }

const FormEditSubmission = withStyles(styles)(({classes, handleClose, sFlow, eventId, thematicCodes }) => {
    const { enqueueSnackbar } = useSnackbar()

    //eslint-disable-next-line
    const [getData, data, isLoading, hasError] = useHttp()
    //eslint-disable-next-line
    const [putData, dataPut, isSaving, hasErrorSave] = useHttp()
    const [name, setName] = useState()
    const [fields, setFields] = useState()
    const [valid, setValid] = useState(true)
    const [isReview, setReview] = useState(false)
    const domRef = React.useRef()

    useEffect(()=>{
        getData(api.entities.manage.getSubmissionFlowResponse(null,null,{submissionFlowId:sFlow.id, answerId:sFlow.answerId}))
        //eslint-disable-next-line
    },[])

    useEffect(()=>{
        if (data) {
            setName(data.name)
            setFields(data.fields)
        }
    },[data])

    useEffect(()=>{
        if (dataPut) {
            enqueueSnackbar('Successfully saved', {variant:'success'})
            handleClose(true)
        }
        // eslint-disable-next-line
    },[dataPut])

    useEffect(()=>{
        if (domRef.current) domRef.current.scrollTo(0,0)
    },[isReview])

    const saveForms = () => {

        let formDataObj = new FormData()

        const saveFields = transformForm(fields)

        const payLoad = { fields: [...saveFields.form] }

        const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'})
        formDataObj.append('form', blob)

        // Save files if any
        saveFields.files.forEach((file)=>formDataObj.append(file.key, file.data))

        putData(api.entities.manage.updateSubmission(formDataObj, null, {id:sFlow.id, answerId:sFlow.answerId},true))
    }

    // const isSubmitting = isLoading&&!hasError

    return <RootRef rootRef={domRef}>
        <DialogContent><Box className={classes.container}>
        <Grid item container spacing={1} className={classes.row}>
            <Typography variant='h5' component='h1'> {'Submission'}</Typography>
        </Grid>

        <Grid item container className={classes.mainBox}>


            <Grid item container spacing={2}>
                {!fields&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                </Box>}
                {!isReview&&<FormViewer fields={fields} setFields={setFields} name={name} setName={setName} valid={valid} setValid={setValid} thematicCodes={thematicCodes}/>}
                {isReview&&<FormReview fields={fields} setFields={setFields} name={name} setName={setName} valid={valid} setValid={setValid} thematicCodes={thematicCodes}/>}
            </Grid>

        </Grid>

        {/*<Grid item container spacing={3}>*/}
        {/*    <Grid item md={3} xs='auto'> </Grid>*/}
        {/*    <Grid item container md={6} spacing={3} className={classes.btns}>*/}
        {/*        <Grid item xs={12} md={6}>*/}
        {/*            <Button variant='outlined' size='small' color={'primary'} onClick={handleClose}> Cancel </Button>*/}
        {/*        </Grid>*/}
        {/*        <Grid item xs={12} md={6}>*/}
        {/*            <Button size='small' type='submit' onClick={saveForms} disable={isSaving}> Save Changes </Button>*/}
        {/*        </Grid>*/}
        {/*    </Grid>*/}
        {/*</Grid>*/}

            <Grid item container spacing={3} style={{marginTop: '16px'}}>
                <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center'>

                    <Grid item>
                        {isReview&&<Button size='small' color='primary' onClick={()=>setReview(false)} disabled={isSaving}> Go back </Button>}
                        {!isReview&&<Button size='small' onClick={handleClose} disabled={isSaving}> Close </Button>}
                    </Grid>
                    <Grid item>
                        {!isReview&&<Button size='small' color='primary' onClick={()=>setReview(true)} disabled={isSaving||!valid}> Review and submit </Button>}
                        {isReview&&<Button size='small' color='primary' onClick={saveForms} disabled={isSaving||!valid}> Submit </Button>}
                    </Grid>
                </Grid>
            </Grid>

        </Box></DialogContent></RootRef>
})

export default FormEditSubmission
