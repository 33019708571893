import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import {Redirect, useParams, withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import Fade from '@material-ui/core/Fade'
import ConferenceCard from 'components/core/ConferenceCard'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import NoEvents from 'components/placeholders/NoEvents'

const styles = theme => ({
  eventList: {
    maxWidth: '1280px',
    // [theme.breakpoints.up('sm')]: {
    //   paddingTop: theme.spacing(3)
    // }
  }
})

const JoinedEvents = withStyles(styles)(({classes, history}) => {

  const [getEvents, eventsData] = useHttp()
  const [events, setEvents] = useState()
  const {eventType} = useParams()

  useEffect(()=>{
    setEvents(undefined)
    switch (eventType) {
      case 'live':
        getEvents(api.entities.events.getPresent(null,{}))
        break
      // case 'upcoming':
      //   getEvents(api.entities.events.getFuture(null,{}))
      //   break
      case 'past':
        getEvents(api.entities.events.getPast(null,{}))
        break
      // case 'draft':
      //   getEvents(api.entities.events.getUpcoming(null,{}))
      //   break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[eventType])

  useEffect(()=>{
    if (eventsData) {
      setEvents(eventsData)
    }
  },[eventsData])

  if (!eventType) {
    return <Redirect to={'joined-events/live'} />
  }

  const navigation = {
    routes: [
      {
        name: 'Live',
        path: 'live'
      },
      // {
      //   name: 'Upcoming',
      //   path: 'upcoming'
      // },
      {
        name: 'Past',
        path: 'past'
      }
    ]
  }

  return <Page title='Joined Events' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Joined Events' mobileTitle='Joined Events' navigation={navigation} >
      {events&&<Fade in={true} timeout={TIME_FADE_IN}><Grid container spacing={5} className={classes.eventList}>
          {events.content.map((event,i)=>{
            return event&&<Grid item xs={12} sm={6} key={i}><ConferenceCard list event={event} m={2} /></Grid>
          })}
      </Grid>
      </Fade>}
      {events&&events.content.length===0&&<NoEvents />}
    </Adrawer>
  </Page>
})

export default withRouter(JoinedEvents)
