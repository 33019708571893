import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography, Grid} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Page from 'components/core/Page'
import Hero from 'components/core/Hero'
import SubFooter from 'components/core/Footer/SubFooter'
import Button from 'components/core/Button'
import Header from "components/core/Header/Header";
import HomeSplashScreen from "assets/home.png";
import Footer from "components/core/Footer/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import SettingUpWireframe from 'assets/ui-screens/ui-set-up-event.png'

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  },
  TheBasicsImage: {
    width: '950px',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  manageImage: {
    width: '483px',
    // boxShadow: '0 10px 35px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  sectionText: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },

  TheBasicsTitle: {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '40px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4),
    marginTop: 64
  },
  TheBasicsContent: {
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4)
  },
  featuresList: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    minHeight: 500
  },
  contactTitle:{
    color:'#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing:'-0.5px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactMoto:{
    color:'#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing:'0.2px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  featureGroup: {
    fontSize: 24
  }

})

const SettingUp = withStyles(styles)(({classes}) => {

  return <div style={{marginTop: -64}}>
    <Header animate={true} />
    <Page title='SettingUp' noFooter>
      <Hero image={HomeSplashScreen} minHeight={300} >
        <Grid container direction={'column'} justify={'center'} alignContent={'center'} alignItems={'center'}>
          <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={100} offset={0}>
            <Grid item lg={12}>
              <Typography align={'center'} className={classes.TheBasicsTitle}>Setting up your event</Typography>
            </Grid>
          </ScrollAnimation>
        </Grid>
      </Hero>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false} animateOnce={true} delay={300}>
        <Hero backgroundColor={'#ffffff'} minHeight={400}>
          <Box pt={6} pb={6}>
            <Container>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                    spacing={2}>
                <Grid item lg={6}>
                  <img src={SettingUpWireframe} alt='ManageLayout' className={classes.manageImage}/>
                </Grid>
                <Grid item lg={6}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant={'body1'} className={classes.sectionText}>
                        Planning and holding your event through Anymeets could not be simpler. Create vibrant landing page, choose from different layouts and color schemes, and add all the information you would like your event attendees to have.
                      </Typography>
                    </Box>

                    {/*<Box mb={2}>*/}
                    {/*  <Typography variant={'body1'} className={classes.sectionText}>*/}
                    {/*    Anymeets can also be utilized to conduct real-time surveys and polls to engage your audience and*/}
                    {/*    to*/}
                    {/*    provide you participant feedback to enhance future events.*/}
                    {/*  </Typography>*/}
                    {/*</Box>*/}
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Hero>
      </ScrollAnimation>




      <Hero backgroundColor={'#107AAD'} minHeight={400}>
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
            <Grid item lg={12}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
                <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item lg={8}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={300}>
                <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a demo</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
                <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
              </ScrollAnimation>
            </Grid>
        </Grid>
      </Hero>
      <SubFooter/>
      <Footer />
    </Page>
  </div>
})

export default SettingUp
