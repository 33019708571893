import React,{ useEffect } from "react";
import { withStyles } from "@material-ui/styles"
import CookieConsent, {getCookieConsentValue, Cookies} from "react-cookie-consent";
import { palette, theme } from "theme";

const styles = (theme) => ({
  container: {
    background: palette["cerulean-blue"],
    zIndex: "9999",
    alignItems: "baseline",
    color: "white",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    left: "0px",
    position: "fixed",
    width: "100%",
    bottom: "0px",
  },
  containerContent: {
    flex: "1 0 300px",
    margin: "15px",
  },
  declineButton: {
    border: "0px",
    boxShadow: "none",
    color: "rgb(229, 229, 229)",
    cursor: "pointer",
    flex: "0 0 auto0",
    margin: "15px",
    background: palette.graphColors[5],
    borderRadius: "50px",
    padding: "10px 25px",
    "&:hover": {
      background: "#42495f",
    },
  },
  acceptButton: {
    border: "0px",
    boxShadow: "none",
    color: "rgb(229, 229, 229)",
    cursor: "pointer",
    flex: "0 0 auto0",
    margin: "15px",
    background: "#04a496",
    borderRadius: "50px",
    padding: "10px 25px",
    "&:hover": {
      background: "#42495f",
    },
  },
});


const CookieComponent = withStyles(styles)(({classes}) =>{
        
    const handleAcceptCookie = () => {
    };

    const handleDeclineCookie = () => {
    //get and delete all cookies
        Object.keys(Cookies.get()).forEach(function (cookieName) {
        if (cookieName !== "CookieConsent") {
            Cookies.remove(cookieName);
        }
        });  
        //Set block google analytics cookies
        window["ga-disable-UA-163426672-1"] = true;
        
        //Set block zendesk cookies
        // eslint-disable-next-line no-undef
        zE("webWidget", "updateSettings", { cookies: false });
                    
        //Clear local Storage
        //localStorage.clear();
    };  

    useEffect(() => {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
            handleAcceptCookie();      
                 
        } else if(isConsent === "false"){           
            handleDeclineCookie();         
        }
        
    }, []);

    return (
      <CookieConsent
        disableStyles
        containerClasses={classes.container}
        enableDeclineButton
        declineButtonClasses={classes.declineButton}
        buttonClasses={classes.acceptButton}
        contentClasses={classes.containerContent}
        expires={7}
        location="bottom"
        buttonText="I accept"
        onDecline={handleDeclineCookie}
        onAccept={handleAcceptCookie}
    >
        This website uses cookies to enhance the user experience.{" "}
        <span>
          <a target="_blank" style={{fontSize: "12px", color: "#e5e5e5"}} href="/privacy">
            Read our privacy/cookie policy here
          </a>
        </span>
      </CookieConsent>
    );




})

export default CookieComponent