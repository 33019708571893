import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "components/core/Button";
import {useHttp} from "api/core";
import FormEditor from "components/core/Forms/FormEditor";
import api from "api";
import {useSnackbar} from "notistack";
import Chip from "@material-ui/core/Chip";
import clsx from "clsx";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {Select, TextField} from "@material-ui/core";
import {DatePicker, TimePicker, DateTimePicker} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import {IconCalendarBorderDark, IconClockBorderDark} from "components/core/icons";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

// import NumberFormat from "react-number-format"
// import LinearScaleIcon from '@material-ui/icons/LinearScale'
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import { Pageview, RestorePage } from "@material-ui/icons";
import Switch from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import {BorderColor} from "@material-ui/icons";

const styles = (theme) => ({
  container: {
    padding: "40px 50px 50px 50px",
    // backgroundColor: palette['cerulean-blue'],
    // color: 'white',
    borderRadius: "6px",
  },
  newFormType: {
    // width: '580px'
  },
  row: {
    marginBottom: theme.spacing(5),
  },
  tabsBar: {
    backgroundColor: palette["very-light-pink"],
  },
  tab: {
    width: "100%",
    color: "white",
    backgroundColor: palette["cerulean-blue"],
    padding: theme.spacing(2),
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    "&:focus": {
      backgroundColor: palette.aquamarine,
    },
  },
  tabLabel: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  addNewItem: {
    // backgroundColor: 'white',
    // padding: theme.spacing(2),
    // borderRadius: '6px',
    border: "2px dashed !important",
    // borderColor: palette['cerulean-blue'],
    // justifyContent: 'center',
    // alignItems: 'center',
    // fontWeight: 'bold',
    // color: palette['greyish-brown'],
    // fontSize: '18px',
    opacity: 0.5,
    margin: 0,
    cursor: "pointer",
    transition: "0.4s all",
    "&:hover": {
      opacity: 0.8,
    },
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: "1px solid",
    borderRadius: "6px",
    borderColor: "white",
    // backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  formControl: {
    width: "100%",
  },
  toolbar: {
    borderTop: "1px solid",
    color: "white",
    marginTop: theme.spacing(2),
  },
  editor: {
    color: palette["greyish-brown"],
    borderRadius: "6px",
    minHeight: "300px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  dialogActions: {
    marginTop: theme.spacing(2),
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  mainBox: {
    marginTop: theme.spacing(3),
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "350px",
    // overflowY: 'auto'
    // minHeight: 'calc(80vh - 300px)'
  },
  cameraReadylabel: {
    fontSize: "14px",
    padding: "5px",
    //maxWidth: "70px",
    paddingTop: "10px",
    display: "inline-block",
  },
  radioButton: {
    border: "none",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    fontSize: "9px",
    "&& .MuiToggleButton-label": {
      //background: "#efefef",
      color: "#777",
    },
    "&.Mui-selected": {
      backgroundColor: "#0dc7e5",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      boxShadow: "1px 1px 3px 0px #68737d",
      "&& .MuiToggleButton-label": {
        background: "#0dc7e5",
        borderRadius: "50px",
        color: "#fff",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      "&& .MuiToggleButton-label": {
        color: "#000",
        //background: "#0078ad",
        //borderRadius: "50px",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#0078ad",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      "&& .MuiToggleButton-label": {
        background: "#0078ad",
        borderRadius: "50px",
      },
    },
    "&&.MuiToggleButton-sizeSmall": {
      padding: "0px",
    },
  },
  radioButtonOff: {
    border: "none",
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    fontSize: "9px",
    boxShadow: "1px 1px 3px 0px #68737d",
    "&& .MuiToggleButton-label": {
      //background: "#efefef",
      color: "#777",
    },
    "&.Mui-selected": {
      backgroundColor: "#fff",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      "&& .MuiToggleButton-label": {
        background: "#fff",
        borderRadius: "50px",
        color: "#777",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      "&& .MuiToggleButton-label": {
        color: "#000",
        //background: "#0078ad",
        //borderRadius: "50px",
      },
    },
    "&.Mui-selected:hover": {
      backgroundColor: "#fff",
      color: "#fff",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      fontSize: "9px",
      "&& .MuiToggleButton-label": {
        borderRadius: "50px",
      },
    },
    "&&.MuiToggleButton-sizeSmall": {
      padding: "0px",
    },
  },
  ToggleButtonGroupOff: {
    // marginTop: "6px",
    background: "#d9d9d9",
    borderRadius: "60px",
    boxShadow: "0px 0px 0px 8px rgb(255 255 255) inset",
    padding: "5px 7px",
    float: "right",
  },
  ToggleButtonGroupOn: {
    // marginTop: "6px",
    borderRadius: "60px",
    boxShadow: "0px 0px 0px 8px rgb(255 255 255) inset",
    padding: "5px 7px",
    background: "#86e3f2 !important",
    float: "right",
  },
  ToggleButtonGroupAuto: {
    // marginTop: "6px",
    borderRadius: "60px",
    boxShadow: "0px 0px 0px 8px rgb(255 255 255) inset",
    padding: "5px 7px",
    background: "linear-gradient(to right, #86e3f2 10%, #ddd 75% ,#d9d9d9 100%)",
    float: "right",
  },
});

// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//         suffix="%"
//       />
//     );
// }
// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//       />
//     );
// }

const FormEditNewSubmissionFlow = withStyles(styles)(({classes, handleClose, sFlow, eventId, timezoneId, selectedTimezone}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [tab, setTab] = useState(0);

  //eslint-disable-next-line
  const [getData, data] = useHttp();
  const [putData, dataPut, isSaving] = useHttp();
  const [name, setName] = useState(sFlow.submissionForm ? sFlow.submissionForm.name : "");
  const [flowName, setFlowName] = useState(sFlow && sFlow.name ? sFlow.name : "");
  const [fields, setFields] = useState(sFlow.submissionForm ? sFlow.submissionForm.fields : []);
  const [sessionTypes, setSessionTypes] = useState();
  const [sessionType, setSessionType] = useState(sFlow.sessionTypeId);
  const [localFlow, setLocalFlow] = useState(sFlow);
  const [cameraReady, setCameraReady] = useState(false);
  const [cameraReadyState, setCameraReadyState] = useState(() => ["3"]);

  const handleCameraReadyState = (event, value) => {
    if (value !== null) {
      setCameraReadyState(value);
      setLocalFlow({...localFlow, cameraReadyEnabled: value});
    }
  };

  useEffect(() => {
    if (dataPut) {
      enqueueSnackbar("Successfully saved", {variant: "success"});
      handleClose(true);
    }
    // eslint-disable-next-line
  }, [dataPut]);

  useEffect(() => {
    getData(api.entities.manage.getSessionTypes(null, null, {id: eventId}));
    moment.tz.setDefault(selectedTimezone);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data) {
      setSessionTypes(data.sessionTypes);
    }
  }, [data]);

  const saveForms = () => {
    // let formDataObj  = new FormData();

    const payLoad = {
      ...localFlow,
      maxSubmissions: 0,
      name: flowName,
      submissionForm: {name: name, fields: fields},
      sessionTypeId: sessionType,
    };

    // const dataObj = new Blob([JSON.stringify(payLoad)], { type: 'application/json'});
    // formDataObj.append('data', dataObj)

    if (sFlow.submissionForm) {
      putData(api.entities.manage.updateSubmissionFlow(payLoad, null, {id: sFlow.id}));
    } else {
      putData(api.entities.manage.addSubmissionFlow({...payLoad, eventId}));
    }
  };

  useEffect(() => {
    if (!sFlow.submissionForm) {
      setLocalFlow({...localFlow, endDate: Date.now(), openDate: Date.now()});
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sFlow.cameraReady !== undefined) {
      setCameraReady(sFlow.cameraReady);
      let newState = [...cameraReadyState];
      newState[0] = `${sFlow.cameraReadyEnabled}`;
      setCameraReadyState(newState);
    } else {
      setLocalFlow({
        ...localFlow,
        cameraReadyStartDate: null,
        cameraReadyEndDate: null,
        cameraReady: false,
        cameraReadyEnabled: 3,
        allowEditing: false,
        uploadCopyright: false,
        openDate: new Date(),
        endDate: new Date(),
      });
    }

    //eslint-disable-next-line
  }, []);

  // const isSubmitting = isLoading&&!hasError
  // useEffect(() => {
  //   if (data && timeZones) {
  //     const zone = timeZones.find((x) => x.id === data.eventInfo.timezoneId);
  //     moment.tz.setDefault(zone.name);
  //     setSelectedTimezone(zone.name);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [data, timeZones]);

  return (
    <Box className={classes.container}>
      <Grid item container spacing={1} className={classes.row}>
        <Typography variant="h5" component="h1">
          {" "}
          {"Submission flow"}
        </Typography>
      </Grid>

      <Grid item container spacing={1} className={classes.Tabs}>
        <Grid item xs>
          <Chip
            size="medium"
            className={clsx(classes.tab, tab === 0 && classes.activeTab)}
            onClick={() => {
              setTab(0);
            }}
            label={
              <Box className={classes.tabLabel}>
                {" "}
                <DynamicFeedIcon /> &nbsp; Basic Info{" "}
              </Box>
            }
            color={"secondary"}
          />
        </Grid>
        <Grid item xs>
          <Chip
            size="medium"
            className={clsx(classes.tab, tab === 1 && classes.activeTab)}
            onClick={() => {
              setTab(1);
            }}
            label={
              <Box className={classes.tabLabel}>
                <EventNoteIcon /> &nbsp; Submission form{" "}
              </Box>
            }
            color={"secondary"}
          />
        </Grid>
        <Grid item xs>
          <Chip
            size="medium"
            className={clsx(classes.tab, tab === 2 && classes.activeTab)}
            onClick={() => {
              setTab(2);
            }}
            label={
              <Box className={classes.tabLabel}>
                {" "}
                <RestorePage /> &nbsp; Camera Ready{" "}
              </Box>
            }
            color={"secondary"}
          />
        </Grid>        
      </Grid>

      <Grid item container className={classes.mainBox}>
        {tab === 0 && (
          <Grid item container spacing={2}>
            <Grid item xs={8}>
              <TextField required variant="filled" label={"Name"} fullWidth value={flowName} onChange={(e) => setFlowName(e.target.value)} />
            </Grid>

            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="session-type-label" variant="filled">
                  Session type
                </InputLabel>
                <Select
                  labelId="session-type-label"
                  id="session-type"
                  value={sessionType}
                  onChange={(e) => {
                    setSessionType(e.target.value);
                  }}
                  label="SessionType"
                  variant="filled"
                  displayEmpty
                  fullWidth>
                  {sessionTypes &&
                    sessionTypes.map((type, index) => {
                      return (
                        <MenuItem key={index} value={type.id}>
                          {type.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <TextField rows={4} multiline required variant="filled" label={"Description"} fullWidth value={localFlow.description} onChange={(e) => setLocalFlow({...localFlow, description: e.target.value})} />
            </Grid>

            <Grid item md={4} xs={12}>
              <DateTimePicker
                format="DD/MM/yyyy hh:mm A"
                label="Open Date &amp; Time"
                inputVariant="filled"
                onChange={(value) => {
                  setLocalFlow({...localFlow, openDate: moment(value).tz(selectedTimezone, true)});
                  //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT00:00:00.000Z").toString());
                }}
                value={localFlow.openDate}
                fullWidth
                disabled={isSaving}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconCalendarBorderDark />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item md={4} xs={12}>
              <DateTimePicker
                format="DD/MM/yyyy hh:mm A"
                label="End Date &amp; Time"
                inputVariant="filled"
                onChange={(value) => {
                  setLocalFlow({...localFlow, endDate: moment(value).tz(selectedTimezone, true)});
                  //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDTHH:mm:ss.000Z").toString());
                }}
                value={localFlow.endDate}
                fullWidth
                disabled={isSaving}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconCalendarBorderDark />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={4}>
              <TimePicker
                label="End Time"
                value={localFlow.endDate}
                fullWidth
                disabled={isSaving}
                inputVariant="filled"
                renderInput={(params) => <TextField {...params} />}
                onChange={(value) => {
                  setLocalFlow({...localFlow, endDate: moment(value).tz(selectedTimezone, true)});
                  //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDTHH:mm:ss.000Z").toString());
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconClockBorderDark />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
            <Grid item md={4} xs={12} style={{paddingTop: "30px"}}>
              <Grid item container alignContent="center" alignItems="center" justify="flex-end">
                <Grid item>Published</Grid>
                <Grid item>
                  <Switch inputProps={{"aria-label": "publish/unpublish"}} checked={localFlow.published} onChange={() => setLocalFlow({...localFlow, published: !localFlow.published})} />
                </Grid>
              </Grid>
            </Grid>
            {/*<Grid item xs={6}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={<Checkbox required checked={localFlow.blindReview} disabled={isSaving} onChange={(e)=>setLocalFlow({...localFlow, blindReview: !localFlow.blindReview})} />}*/}
            {/*    label={'Blind review'}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        )}

        {tab === 1 && (
          <Grid item container spacing={2}>
            {/*{!fields&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>*/}
            {/*    <Box display='flex'>*/}
            {/*        <CircularProgress size={'8rem'} color='secondary' />*/}
            {/*    </Box>*/}
            {/*</Box>}*/}
            <FormEditor fields={fields} setFields={setFields} name={name} setName={setName} eventId={eventId} />
          </Grid>
        )}
        {tab === 2 && (
          <Grid item container spacing={2}>
            <Grid item xs={12} style={{marginTop: "8px"}}>
              <Grid item container alignContent="center" alignItems="center" justify="flex-end">
                <Grid item>This submission flow has a camera ready step</Grid>
                <Grid item>
                  <Switch
                    inputProps={{"aria-label": "Yes/No"}}
                    checked={localFlow.cameraReady}
                    onChange={() => {
                      setLocalFlow({...localFlow, cameraReady: !localFlow.cameraReady});
                      setCameraReady(!localFlow.cameraReady);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            {cameraReady && (
              <>
                <Grid item container xs={4}>
                  <Grid item container xs={12} style={{marginTop: "8px"}}>
                    <Grid item md={7} xs={12}>
                      <label id="stateLabel" className={classes.cameraReadylabel}>
                        Enable Camera Ready
                      </label>
                    </Grid>
                    <Grid item md={5} xs={12}>
                      <ToggleButtonGroup
                        disabled={isSaving}
                        className={
                          cameraReadyState[0] === "1"
                            ? classes.ToggleButtonGroupAuto
                            : cameraReadyState[0] === "2"
                            ? classes.ToggleButtonGroupOn
                            : cameraReadyState[0] === "3"
                            ? classes.ToggleButtonGroupOff
                            : classes.ToggleButtonGroupOff
                        }
                        value={cameraReadyState}
                        size="small"
                        exclusive
                        onChange={handleCameraReadyState}
                        aria-labelledby="stateLabel">
                        <ToggleButton value="3" aria-label="off" className={cameraReadyState === "3" ? classes.radioButtonOff : classes.radioButton}>
                          Off
                        </ToggleButton>
                        <ToggleButton value="1" aria-label="auto" className={classes.radioButton}>
                          Auto
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="on" className={classes.radioButton}>
                          On
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12} style={{marginTop: "8px"}}>
                  <Grid item container alignContent="center" alignItems="center" justify="flex-end">
                    <Grid item style={{fontSize: "14px"}}>
                      Allow editing submission form
                    </Grid>
                    <Grid item>
                      <Switch inputProps={{"aria-label": "Allow editing submission form"}} checked={localFlow.allowEditing} onChange={() => setLocalFlow({...localFlow, allowEditing: !localFlow.allowEditing})} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={4} xs={12} style={{marginTop: "8px"}}>
                  <Grid item container alignContent="center" alignItems="center" justify="flex-end">
                    <Grid item style={{fontSize: "14px"}}>
                      Upload copyright form
                    </Grid>
                    <Grid item>
                      <Switch inputProps={{"aria-label": "upload copyright form"}} checked={localFlow.uploadCopyright} onChange={() => setLocalFlow({...localFlow, uploadCopyright: !localFlow.uploadCopyright})} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <DateTimePicker
                    format="DD/MM/yyyy hh:mm A"
                    label="Open Date &amp; Time"
                    inputVariant="filled"
                    onChange={(value) => {
                      setLocalFlow({...localFlow, cameraReadyStartDate: moment(value).tz(selectedTimezone, true)});
                    }}
                    value={localFlow.cameraReadyStartDate}
                    fullWidth
                    disabled={isSaving || cameraReadyState[0] !== "1"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconCalendarBorderDark />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <DateTimePicker
                    format="DD/MM/yyyy hh:mm A"
                    label="End Date &amp; Time"
                    inputVariant="filled"
                    onChange={(value) => {
                      setLocalFlow({...localFlow, cameraReadyEndDate: moment(value).tz(selectedTimezone, true)});
                    }}
                    value={localFlow.cameraReadyEndDate}
                    fullWidth
                    disabled={isSaving || cameraReadyState[0] !== "1"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconCalendarBorderDark />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </>
            )}
            {/*<Grid item xs={6}>*/}
            {/*  <FormControlLabel*/}
            {/*    control={<Checkbox required checked={localFlow.blindReview} disabled={isSaving} onChange={(e)=>setLocalFlow({...localFlow, blindReview: !localFlow.blindReview})} />}*/}
            {/*    label={'Blind review'}*/}
            {/*  />*/}
            {/*</Grid>*/}
          </Grid>
        )}
      </Grid>
      {/*<FormEditor fields={fields} setFields={setFields} />*/}
      {/*<FormViewer fields={fields} setFields={setFields} />*/}
      <Grid item container spacing={3}>
        <Grid item md={3} xs="auto">
          {" "}
        </Grid>
        <Grid item container md={6} spacing={3} className={classes.btns}>
          <Grid item xs={12} md={6}>
            <Button variant="outlined" size="small" color="primary" onClick={handleClose}>
              {" "}
              Cancel{" "}
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button size="small" type="submit" onClick={saveForms} disable={isSaving}>
              {" "}
              Save Changes{" "}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

export default FormEditNewSubmissionFlow;
