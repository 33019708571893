import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import HotelCardView from 'components/core/HotelCardView'
import language from "components/language";
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const hotelStyles = theme => ({
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

export const HotelsList = withStyles(hotelStyles)(({ classes, event, isPreview, canManage }) =>
{
  const history = useHistory();
  const [hotels, setHotels] = useState()
  const [getHotelsData, hotelsData] = useHttp()
  const [getHotelsCategories, hotelsCategoriesData] = useHttp()
  const [hotelCategories, setHotelCategories] = useState()

  useEffect(() => {

    // Hotel categories
    getHotelsCategories(api.entities.options.getHotelCategories())

    if (isPreview) {
      getHotelsData(api.entities.preview.getHotels(null, null, {id: event.eventInfo.id}))
    } else {
      getHotelsData(api.entities.events.getHotels(null, null, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (hotelsCategoriesData) {
      setHotelCategories(hotelsCategoriesData)
    }
  },[hotelsCategoriesData])

  useEffect(() => {
    if (hotelsData) {
      setHotels(hotelsData)
    }
  }, [hotelsData])

  if (!hotels) {
    return <> </> // Loading
  }

  if ((hotels&&hotels.length===0)||!hotelCategories) return <> </>

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0} pb={12} flexDirection='column'>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/restaurants-hotels`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Hotels</Button>}
            </Grid>         
        <Box pb={2}>
          <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.hotels}</Box>
        </Box>
        {hotels.map((hotel,i) => {
          return <HotelCardView event={event} hotel={hotel} p={1} plain={hotel.type !== 1} index={i} categories={hotelCategories}/>
        })}
      </Box>
    </Fade>
  </>
})

export default HotelsList
