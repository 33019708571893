import Box from "@material-ui/core/Box";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import AdbIcon from "@material-ui/icons/Adb";
import Container from '@material-ui/core/Container'

const styles = theme => ({
  featureBox: {
    minHeight: 200,
    backgroundColor: 'white',
    border: '1px solid #f1f2f5',
    borderRadius: 16
  },
  featureIcon: {
    marginTop: 16,
    width: 64,
    height: 64,
    borderRadius: '50%',
    backgroundColor: '#cdcdcd'
  }
})

const featuresList = [
  {
    title: 'Branded landing page',
    icon: AdbIcon,
    text: 'No need to create and manage a website! Everything will be included on your event landing page.'
  },
  // {
  //   title: 'Mobile app',
  //   icon: AdbIcon,
  //   text: 'Event participants can access your landing page and agenda on-the-go!'
  // },
  {
    title: 'User-friendly dashboard',
    icon: AdbIcon,
    text: 'You don’t need to be a software engineer to run an Anymeets event! Our dashboard is self-explanatory and easy to use.'
  },
  // {
  //   title: 'Test',
  //   icon: AdbIcon,
  //   text: 'An all-inclusive solution to managing your next workshop, meeting or conference.'
  // },
  {
    title: 'Flexible online sessions',
    icon: AdbIcon,
    text: 'There are multiple session types to choose from, and you can mix-and-match according to your needs.'
  },
  {
    title: 'Poster sessions',
    icon: AdbIcon,
    text: 'Our poster session experience is unparalleled. We offer two types of poster sessions and give each presenter their own space.'
  },
  {
    title: 'Virtual sponsor booths',
    icon: AdbIcon,
    text: 'Does your event have sponsors? We’ve got you covered! Sponsors have their own virtual booths which include lead-retrieval tools and metrics.'
  },
  {
    title: 'Breakout rooms',
    icon: AdbIcon,
    text: 'At Anymeets we love breakout rooms! They can be created on the spot from within sessions for Q&A, or they can be event-wide to allow for virtual socializing.'
  },
  {
    title: 'Reviewing and decisions',
    icon: AdbIcon,
    text: 'Control the review process with a dedicated reviewer dashboard. Automate invitations, reminders and grading!'
  },
  {
    title: 'Auto and manual assignments',
    icon: AdbIcon,
    text: 'Our algorithm will match papers to reviewers and papers to sessions based on keywords you provide. Make manual adjustments for optimum efficiency.'
  },
  {
    title: 'Polls, surveys and feedback',
    icon: AdbIcon,
    text: 'Create polls and surveys within sessions and event-wide. No need to pre-plan! Create polls on the spot and see the results in real-time.'
  },
  {
    title: 'Event monitoring',
    icon: AdbIcon,
    text: 'Easily follow the progress of your event from the monitoring dashboard, which allows you to easily access, watch and control each of your sessions. '
  },
  {
    title: 'Post-event metrics and statistics',
    icon: AdbIcon,
    text: 'The future is data driven, which is why we offer extensive data that you can use to optimize future events.'
  },
  // {
  //   title: 'Excellent support',
  //   icon: AdbIcon,
  //   text: 'We pride ourselves on our quick and accurate support. Send us an email and see how quickly we respond!'
  // },
  // {
  //   title: 'Virtual conference coordination',
  //   icon: AdbIcon,
  //   text: 'Virtual events are new territory. If you are having a hard time deciding on the details of your event, our experienced virtual conference coordinators can advise you!'
  // },
  {
    title: 'Transparent pricing',
    icon: AdbIcon,
    text: 'Our pricing is upfront – and among the lowest on the market! There are no add-ons or hidden charges. You pay per attendee, and per session. See for yourself!'
  }
]

const FeatureBox = withStyles(styles)(({classes, icon, text,title})=>{
  //eslint-disable-next-line
  const Icon = icon
  return <Grid item xs={12} sm={6} md={4} lg={3}>

    <Grid item container justify='center' alignItems={'flex-start'} alignContent={'flex-start'} className={classes.featureBox}>

      {/*<Grid item container justify='center' alignItems={'flex-start'} alignContent={'center'}>*/}
      {/*    <Grid item>*/}
      {/*       <Box className={classes.featureIcon}>*/}
      {/*         <Grid item container justify='center' alignItems={'flex-start'} alignContent={'center'} style={{height:'100%'}}>*/}
      {/*           <Grid item>*/}
      {/*             <Icon fontSize={'large'} style={{color:'white'}}/>*/}
      {/*           </Grid>*/}
      {/*         </Grid>*/}
      {/*       </Box>*/}
      {/*    </Grid>*/}
      {/*</Grid>*/}

      <Grid item container justify='center' alignItems={'flex-start'} alignContent={'center'} >
        <Grid item xs={10}>
          {title&&<Box pt={2} pb={2}><Typography variant='h6' align={'center'} style={{lineHeight: 1.2}}>
            {title}
          </Typography></Box>}
        </Grid>
        <Grid item xs={10}>
          <Typography variant='body2' align={'center'}>
            {text}
          </Typography>
        </Grid>
      </Grid>

    </Grid>
  </Grid>

})

const AnyFeatureList =  withStyles(styles)(({classes})=>{
  return <Box style={{backgroundColor: '#f7f8fa'}} p={3} pt={8} pb={8}>

    <Grid container spacing={2} justify='center' alignContent='center' alignItems='center'  style={{minHeight:500}}>

      <Container>
        <Grid container justify='center' alignContent='center' alignItems='center'  style={{padding:16, paddingBottom: 48}}>
          <Grid item xs={8} style={{textAlign:'center'}}>
            <Grid item container justify='center' alignContent='center' alignItems='center'>
              <Grid item xs={8}>
                <Typography style={{marginBottom:16}} variant='h4'>Why use Anymeets</Typography>
                <Typography variant='h6'>
                  An all-inclusive solution to managing your next workshop, meeting or conference. Spend less time organizing your event, and more time participating in it!
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </Container>

      <Grid item xs={12}>
        <Container>
          <Grid item container justify='center' alignContent='flex-start' alignItems='center'>
            <Grid item container justify={'flex-start'} alignContent='flex-start' alignItems='center' spacing={2} >
              {featuresList.map((feature)=><FeatureBox title={feature.title} text={feature.text} icon={feature.icon} />)}
            </Grid>
          </Grid>
        </Container>

      </Grid>



    </Grid>

  </Box>

})

export default AnyFeatureList
