import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import FeedbackInfo from 'components/core/OpenTok/Components/Feedback/FeedbackInfo'

const styles = theme => ({

})


const Polls = withStyles(styles)(({classes, sessionState, feedbackHandlers}) => {


  return <Grid item container justify='center' alignContent='center' alignItems='center' spacing={4}>
    <Grid item>
      <FeedbackInfo sessionState={sessionState} feedbackHandlers={feedbackHandlers} />
    </Grid>
  </Grid>
})

export default Polls
