import {useParams, withRouter} from 'react-router-dom'
import Box from '@material-ui/core/Box'
import {imageFile} from 'utils'
import {CATEGORIES} from "config";
import React from 'react'
import Chip from 'components/core/Chip'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import {withStyles} from '@material-ui/core'
import {format} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'
import clsx from 'clsx'
import AddToCalendarButton from 'components/core/AddToCalendarButton'
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";

const styles = theme => ({
    coverWrapper: {
        alignItems: 'flex-end',
        position: 'relative',
        minHeight: '290px',
        zIndex: 1,
        // maxHeight: '350px',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'auto'
        },
        [theme.breakpoints.down('md')]: {
            minHeight: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            minHeight: '100%'
        }
    },
    coverImage: {
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        backgroundColor: palette.imageBackgroundColor,
        zIndex: -1,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '300px',
        },
        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '300px',
            paddingRight:theme.spacing(2)
        },
    },
    logo: {
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: '6px',
        backgroundColor: 'white',
        marginTop: -theme.spacing(12),
        marginBottom: theme.spacing(2),
        zIndex: 2,
        overflow: 'hidden',
        '& > img': {
            backgroundColor: palette.imageBackgroundColor
        }
    },
    logoImage: {
        width: '100%',
        height: 'auto'
    },
    mainText: {},
    manageBtn: {
        position: 'absolute',
        bottom: theme.spacing(5),
        right: theme.spacing(3),
        zIndex: 1,
        textAlign: 'right',
        '& button': {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`
        },
        '& a': {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`
        },
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center'
        }
    },
    eventTitle: {
        position: 'absolute',
        bottom: theme.spacing(12),
        left: theme.spacing(8),
        zIndex: 1,
        fontSize: '48px',
        textAlign: 'left',
        textShadow:'1px 1px #00001111',
        '& button': {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`
        },
        '& a': {
            margin: `0 ${theme.spacing(1)}px ${theme.spacing(2)}px`
        },
        [theme.breakpoints.only('xs')]: {
            fontSize:'30px',
            left:0,
            textAlign: 'center'
        },
        [theme.breakpoints.only('sm')]: {
            left:0,
            fontSize:'30px',
            textAlign: 'center'
        },
        [theme.breakpoints.only('md')]: {
            fontSize: '35px',
            left: theme.spacing(3),
            bottom: theme.spacing(8),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: '44px',
            left: theme.spacing(4),
            bottom: theme.spacing(6),
        }
    },
    coverBox: {
        position: 'relative'
    },
    onTop: {
        position: 'fixed',
        width: '100%'
    },
    hideTitle: { // TODO: CHANGE FOR SPECIFIC EVENTS VIA BE
        opacity: 0
    },

})



const EventHeader = withRouter(withStyles(styles)(({classes, event, canManage}) => {
    let { eventPage, eventId } = useParams()
    


    const eventCategory = (type) =>{
        
        let typos = "";
        switch (type){
        case "Academic Conference" :
            typos = language.academicConference   
        break;   
        case "Conference" :
            typos = language.conference            
        break;   
        case "Seminar" :
            typos = language.seminar
        break;   
        case "Meeting" :
            typos = language.meeting            
        break;   
        case "Team Building" :
            typos = language.teamBuilding            
        break;   
        case "Networking" :
            typos = language.networking            
        break;   
        case "Business" :
            typos = language.business            
        break;   
        case "Workshop" :
            typos = language.workshop            
        break;               
        default:
           typos = ""
        }
        return typos; 
    };

    // const AddToCalendarModal = AddToCalendarHOC(SocialButton, CalendarDropdown);
    const duration = differenceInHours(parseISO(event.eventInfo.startDate),parseISO(event.eventInfo.endDate))
    const eventInfo = {
      description: event.description,
      duration,
      endDatetime: format(parseISO(event.eventInfo.endDate), "yyyyMMdd'T'HHmmss"),
      location: event.venueDetails,
      startDatetime: format(parseISO(event.eventInfo.startDate), "yyyyMMdd'T'HHmmss"),
      title: event.eventInfo.name,
    };
    const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

    return (
      <>
        <Box display="flex" justifyContent="center" flexDirection="column" pb={6} className={clsx(classes.coverBox, eventPage === "social-feed" && classes.onTop)}>
          <Box display="flex" className={classes.coverWrapper}>
            <img src={imageFile(event.eventInfo.coverId, true)} alt={event.eventInfo.name} className={classes.coverImage} />
          </Box>

          {eventPage !== "social-feed" && (
            <Grid container justify="center" alignContent="center" alignItems="center" direction="row" className={classes.eventTitle}>
              <Grid item lg={8} md={12} sm={12} xs={12} className={clsx((eventId === "115" || eventId === "132" || !event.eventInfo.showTitle) && classes.hideTitle)}>
                <Box fontWeight="bolder" color={event.eventInfo.titleColor} mb={1}>
                  {event && event.eventInfo.name}
                </Box>
                <Box component="h2" fontWeight="bold" color={event.eventInfo.titleColor} fontSize={20} mt={0}>
                  {format(
                    parseISO(event.eventInfo.startDate),
                    differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa",
                    dateLocale[event.eventInfo.eventLanguage],
                  )}{" "}
                  -{" "}
                  {format(
                    parseISO(event.eventInfo.endDate),
                    differenceInDays(parseISO(event.eventInfo.startDate), parseISO(event.eventInfo.endDate)) !== 0 ? "d MMMM yyyy" : "d MMMM yyyy | hh:mm aa",
                    dateLocale[event.eventInfo.eventLanguage],
                  )}
                  {!event.online ? (event.venueDetails ? `, ${event.venueDetails}` : "") : ""}
                </Box>
                <Box mb={3}>
                  {" "}
                  <AddToCalendarButton withIcon event={eventInfo} buttonLabel="Put on my calendar" /> <Chip label={eventCategory(CATEGORIES[event.eventInfo.category])} />{" "}
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </>
    );
}))

export default EventHeader
