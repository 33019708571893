import {NavLink, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {palette} from 'theme'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import clsx from 'clsx'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import {
    IconAgendaBorderDark, IconArrowLeftDoubleGray, IconArrowRightDoubleGray,
    IconCalendarBorderDark,
    IconCoffeeBorderDark,
    IconFileBorderCurrentColor,
    IconInfoBorderDark,
    IconSpeakersBorderDark,
    IconStarBorderDark,
    IconTagBorderDark
} from 'components/core/icons'
import ListItemText from '@material-ui/core/ListItemText'
import DrawerFeedbackInfo from 'components/pages/Event/Layouts/WithDrawer/DrawerFeedbackInfo'
import ScrollBar from 'react-perfect-scrollbar'
import EllipsisText from 'react-ellipsis-text'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'
import {useAuth} from 'auth/AuthController'
import { checkHost } from 'utils'
import language from "components/language";
import { useHttp } from "api/core";
import api from "api";

const drawerWidth = 240;

const styles = theme => ({
    selected: {
        color: `${theme.palette.primary.main} !important`,
    },
    navItem: {
        fontWeight: 'bold',
        color: palette['greyish-brown'],
        cursor: 'pointer',
        bottom: 0,
    },
    paper: {
        background: '#ffffff'
    },
    '& a': {
        fontWeight: 'bold',
        textDecoration: 'none',
        color: theme.palette.text.primary,
        marginRight: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            marginRight: theme.spacing(2)
        }
    },
    [theme.breakpoints.down(1150)]: {
        display: 'none'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        overflowX:'hidden',
        ...theme.mixins.toolbar,
        marginTop: theme.spacing(10),
        marginBottom: 0,
        color: palette['greyish-brown'],
        '& a': {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        }
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        position: 'fi',
        zIndex: 1000,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
    },
    footerDrawer: {
        bottom: 80,
        right: 0,
        position: 'absolute'
    },
})

const DrawerMenu = withRouter(withStyles(styles)(({classes, event, previewSuffix, extras, openDrawer}) => {
    const [open, setOpen] = useState(true);
    const [isAuthenticated] = useAuth()
    const [getData, data, isLoadingData] = useHttp();
    const [contentsData, setContentsData] = useState([]);

  useEffect(() => {
    if (event) {
      getData(
        api.entities.manage.getContents(null, null, {
          eventId: event.eventInfo.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

    useEffect(() => {
      if (data) {
        setContentsData(data.content);
      }
    }, [data]); 

    useEffect(() => {
        openDrawer(open)
    }, [open,openDrawer])

    const {slug} = useParams()
    const host = checkHost()

    let baseURL = `/event/${event.eventInfo.id}`

    if (slug) {
        baseURL = `/${slug}`
    } else if (host) {
        baseURL = ``
    }

  function createNavLink(item) {
    switch (item.contentEnumType) {
      case 1:
        return (
            <ListItem key={'Info'} component={NavLink}
                        to={`${baseURL}/info${previewSuffix}`} className={classes.navItem}
                        activeClassName={classes.selected}>
                <Box pr={2}><IconInfoBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
        );
      case 2:
        return (
          event.sessionsCount !== 0 && (
            <ListItem key={'Agenda'} to={`${baseURL}/agenda${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconCalendarBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 3:
        return (
          event.participantsCount !== 0 && (
            <ListItem key={'Speakers'} component={NavLink}
                        to={`${baseURL}/participants${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}>
                <Box pr={2}><IconSpeakersBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 4:
        return (
          event.keynoteSpeakers.length !== 0 && (
            <ListItem key={'Keynote speakers'} component={NavLink}
                        to={`${baseURL}/keynotespeakers${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected}>
                <Box pr={2}><IconSpeakersBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 5:
        return (
          event.sponsors.length !== 0 && (
            <ListItem key={'Sponsors'} to={`${baseURL}/sponsors${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconStarBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 6:
        return (
          event.eventInfo.hasRestaurants && (
            <ListItem key={'Restaurants'} to={`${baseURL}/restaurants${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconCoffeeBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 7:
        return (
          event.eventInfo.hasHotels && (
            <ListItem key={'Hotels'} to={`${baseURL}/hotels${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconAgendaBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 8:
        return (
          event.organizers.length !== 0 && (
            <ListItem key={'Organizers'} to={`${baseURL}/organizers${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconSpeakersBorderDark/></Box>
                <ListItemText primary={item.label}/>
            </ListItem>
          )
        );
      case 9:
        return (
          event.eventInfo.hasExtras &&
          extras.map((extra) => (
            <ListItem key={extra.title}
                        to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconTagBorderDark/></Box>
                <ListItemText primary={<EllipsisText text={extra.title} length={23} />}/>
            </ListItem>

          ))
        );
      case 10:
        return (
            <ListItem key={`general-page-${item.id}`}
                        to={`${baseURL}/general-page/${item.id}${previewSuffix}`}  
                        className={classes.navItem}
                        activeClassName={classes.selected} component={NavLink}>
                <Box pr={2}><IconTagBorderDark/></Box>
                <ListItemText primary={<EllipsisText text={item.label} length={23} />}/>
            </ListItem>            
            );
      case 11:
        return (
          (event.eventInfo.showProceedings === 1 ||
            (event.eventInfo.showProceedings === 3 &&
              event.eventInfo.registered)) && (
            <ListItem
              key={"Proceedings"}
              to={`${baseURL}/proceedings${previewSuffix}`}
              className={classes.navItem}
              activeClassName={classes.selected}
              component={NavLink}
            >
              <Box pr={2}><IconFileBorderCurrentColor /></Box>
              <ListItemText primary={item.label} />
            </ListItem>
          )
        );        
      default:
        return "";
    }
  }
    
    return <>
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
            })}
            classes={{
                paper:
                    clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                        [classes.paper]: '#ffffff'
                    }),
            }}>
            <ScrollBar>
                <Box className={classes.toolbar}>

                    <List>
                        {data &&
                            contentsData.map((item) => {
                            return createNavLink(item);
                        })}  
                        {/* <ListItem key={'Info'} component={NavLink}
                                  to={`${baseURL}/info${previewSuffix}`} className={classes.navItem}
                                  activeClassName={classes.selected}>
                            <Box pr={2}><IconInfoBorderDark/></Box>
                            <ListItemText primary={language.info}/>
                        </ListItem>

                        {event.sessionsCount !== 0  &&
                        <ListItem key={'Agenda'} to={`${baseURL}/agenda${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><IconCalendarBorderDark/></Box>
                            <ListItemText primary={language.agenda}/>
                        </ListItem>}

                        {event.participantsCount !== 0 &&
                        <ListItem key={'Speakers'} component={NavLink}
                                  to={`${baseURL}/participants${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected}>
                            <Box pr={2}><IconSpeakersBorderDark/></Box>
                            <ListItemText primary={language.speakers}/>
                        </ListItem>}

                        {event.keynoteSpeakers.length !== 0 &&
                        <ListItem key={'Keynote speakers'} component={NavLink}
                                  to={`${baseURL}/keynotespeakers${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected}>
                            <Box pr={2}><IconSpeakersBorderDark/></Box>
                            <ListItemText primary={language.keynoteSpeakers}/>
                        </ListItem>}

                        {event.sponsors.length !== 0 &&
                        <ListItem key={'Sponsors'} to={`${baseURL}/sponsors${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><IconStarBorderDark/></Box>
                            <ListItemText primary={language.sponsors}/>
                        </ListItem>}

                        {((event.eventInfo.showProceedings  === 1) || (event.eventInfo.showProceedings === 3 && event.eventInfo.registered)) &&
                        <ListItem key={'Proceedings'} to={`${baseURL}/proceedings${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><IconFileBorderCurrentColor/></Box>
                            <ListItemText primary={language.proceedings}/>
                        </ListItem>}

                        {event.eventInfo.hasExtras && extras.map((extra) =>
                            <ListItem key={extra.title}
                                      to={`${baseURL}/extra/${extra.id}${previewSuffix}`}
                                      className={classes.navItem}
                                      activeClassName={classes.selected} component={NavLink}>
                                <Box pr={2}><IconTagBorderDark/></Box>
                                <ListItemText primary={<EllipsisText text={extra.title} length={23} />}/>
                            </ListItem>
                        )}

                        {event.eventInfo.hasHotels &&

                        <ListItem key={'Hotels'} to={`${baseURL}/hotels${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><IconAgendaBorderDark/></Box>
                            <ListItemText primary={language.hotels}/>
                        </ListItem>
                        }

                        {event.eventInfo.hasRestaurants &&
                        <ListItem key={'Restaurants'} to={`${baseURL}/restaurants${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><IconCoffeeBorderDark/></Box>
                            <ListItemText primary={language.restaurants}/>
                        </ListItem>} */}

                        {/* {isAuthenticated&&<ListItem key={`Social Feed`} to={`${baseURL}/social-feed${previewSuffix}`}
                                  className={classes.navItem}
                                  activeClassName={classes.selected} component={NavLink}>
                            <Box pr={2}><ChatBubbleOutlineIcon/></Box>
                            <ListItemText primary={language.socialFeed}/>
                        </ListItem>} */}

                    </List>
                </Box>
            </ScrollBar>
            <ScrollBar>
                <Box mt={2}>
                    <Divider classes={{root: classes.divider}}/>
                </Box>
                <Box>
                    <DrawerFeedbackInfo event={event} isCollapsed={!open}/>
                </Box>
                <Box p={2} className={classes.footerDrawer}>
                    <Box style={{cursor:'pointer'}} onClick={() => setOpen(!open)}>{open ? <IconArrowLeftDoubleGray/> :
                        <IconArrowRightDoubleGray/>}
                    </Box>
                </Box>
            </ScrollBar>
        </Drawer>
    </>

}))

export default DrawerMenu;
