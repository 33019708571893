import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import ParticipantCard from 'components/core/ParticipantCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue, IconUploadBorderDark} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import ParticipantEditNew from 'components/core/ParticipantEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import {filterList} from 'utils'
import {Pagination} from '@material-ui/lab'
import NoSearchResults from 'components/placeholders/NoSearchResults'
import { SearchInput } from 'components/core/SearchInput'
import { useDropzone } from 'react-dropzone'
import ParticipantsBulkImport from "components/core/ParticipantsBulkImport";

const styles = theme => ({
  participantsContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noParticipantsYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
   addBtn: {
    textAlign: 'right'
   },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  }
})

const Participants = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  const [getData, data] = useHttp()
  const [delRequest, delData] = useHttp()
  const [openParticipantDialog, setOpenParticipantDialog] = useState(false)
  const [editingParticipant, setEditingParticipant] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingParticipant, setDeletingParticipant] = useState()
  const [participantsData, setParticipantsData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [openBulk, setOpenBulk] = useState(false);

  useEffect(()=>{
    getData(api.entities.manage.getParticipants(null,null,{id:eventId}))
  },[getData, eventId])

  useEffect(()=>{
    if (data) {
      setParticipantsData(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
    }
  },[data, setEventInfo, setNavBack, setPageTitle])

  const handleClose = (shouldReload) => {
    setOpenParticipantDialog(false)
    if (shouldReload) {
      getData(api.entities.manage.getParticipants(null,null,{id:eventId}))
    }
  }

  const handleOpen = (participant) => {
    setOpenParticipantDialog(true)
    if (participant) {
      setEditingParticipant(participant)
    } else {
      setEditingParticipant(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingParticipant(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingParticipant) {
      delRequest(api.entities.participants.delete(null,null,{id:id}))
      setDeletingParticipant(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted speaker', {variant: 'success'})
      getData(api.entities.manage.getParticipants(null,null,{id:eventId}))
    }
  },[delData, enqueueSnackbar, eventId, getData])

  const doSearch = (value) => {
    setCurrentPage(1)
    setSearch(value)
  }

  const handleCloseBulk = (shouldReload) => {
    setOpenBulk(false);
    if (shouldReload) {
      getData(api.entities.manage.getParticipants(null, null, {id: eventId}));
    }
  };

  const [fileData, setFileData] = useState([]);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) return false;

    setFileData(file);
    setOpenBulk(true);
  //  console.log(file);
  };
  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();  

  if (!participantsData) return <> </>

  const participantList = search?filterList(search,null, participantsData.participants).filter((s)=>s.matched===true):participantsData.participants

  // FE Paging
  const participantsPerPage = 30
  const totalParticipants = participantList.length
  const totalPages = Math.ceil(totalParticipants/participantsPerPage)
  const handleChangePage = (event, value) => {
    setCurrentPage(value)
  }

  const from = (currentPage-1)*participantsPerPage
  const to = from + participantsPerPage
  const participantPagedList = participantList.slice(from,to)

  return (
    <>
      <Dialog onClose={handleClose} open={openParticipantDialog}>
        <ParticipantEditNew participant={editingParticipant} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete speaker</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete speaker?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteClose()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteClose(deletingParticipant);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <Fade in={true} timeout={TIME_FADE_IN}>
        <Grid container className={classes.participantsContainer} spacing={2}>
          <Grid item xs={9}>
            <Typography component="h2" variant="h5" className={classes.title}>
              Speakers
            </Typography>
          </Grid>

          <Grid item xs={3}>
            {/*<TextField value={search} onChange={(e)=>handleSearch(e.target.value)} title={'Find a speaker...'} placeholder={'Find a speaker'} fullWidth variant='filled' label='Search' />*/}
            <SearchInput doSearch={doSearch} label={"Find a speaker..."} />
          </Grid>

          <Grid item xs={12}>
            {participantPagedList.map((participant, i) => {
              return (
                <ParticipantCard
                  participant={participant}
                  key={i}
                  editAction={() => {
                    handleOpen(participant);
                  }}
                  deleteAction={() => {
                    handleDeleteOpen(participant.id);
                  }}
                  mb={2}
                />
              );
            })}
            {participantPagedList.length === 0 && (
              <Box pb={3}>
                <NoSearchResults note={false} />
              </Box>
            )}
            {participantsData.participants.length === 0 ? (
              <Grid item container className={classes.noParticipantsYet}>
                <Grid item>No speakers yet!</Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-end" pt={3}>
              <Button
                variant="outlined"
                icon={<IconAddBorderBlue />}
                size="small"
                color="primary"
                onClick={() => {
                  handleOpen();
                }}>
                Add Speaker
              </Button>

              <Box {...rootProps} className={classes.dropBox} ml={1}>
                <input type="file" {...getInputProps()} accept=".xls,.xlsx" />
                <Button variant="outlined" icon={<IconUploadBorderDark />} size="small" color="primary">
                  Import from file
                </Button>
              </Box>
            </Box>
          </Grid>
          {participantPagedList.length !== 0 && (
            <Grid item container justify="center" alignContent="center" alignItems="center">
              <Grid item>
                <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Fade>
      <Dialog onClose={handleCloseBulk} open={openBulk} fullWidth maxWidth="md" classes={{paper: classes.dialogPaper}}>
        <ParticipantsBulkImport handleClose={handleCloseBulk} eventId={eventId} eventInfo={data.eventInfo} setSuccessful={setSuccessful} file={fileData} allParticipants={data} />
      </Dialog>
    </>
  );
})

export default withRouter(Participants)
