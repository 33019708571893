import {Box, Grid, Typography} from '@material-ui/core'
import MaterialTable from 'material-table'
import api from 'api'
import Edit from '@material-ui/icons/Edit'
import {palette} from 'theme'
import React, {forwardRef, useEffect, useRef, useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/core/Button'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import {Redirect} from 'react-router-dom'
import {useHttp} from 'api/core'
import withStyles from '@material-ui/styles/withStyles'
import {GetApp} from '@material-ui/icons'

const styles = theme => ({
  container: {

  }
})

const Sponsors = withStyles(styles)(({classes}) => {

  // Hooks
  const [getData, data, , hasError] = useHttp()
  const [setActivate, activateData, , ] = useHttp()
  const [sponsors, setSponsors] = useState()
  const [activateSponsor, setActivateSponsor] = useState()
  const resolveRef = useRef()
  const tableRef = useRef()

  useEffect(() => {
    getData(api.entities.admin.getSponsors(null, null, {}))
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (data) {
      setSponsors(data)
    }
  },[data])

  useEffect(()=>{
    if (sponsors) {
      resolveRef.current({
        page: sponsors.pageable.pageNumber,
        totalCount: sponsors.totalElements,
        data: sponsors.content
      })
    }
  },[sponsors])

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  }

  const handleActivateClose = (activate) => {
    if (activate===true) {
      setActivate(api.entities.admin.activateSponsor(null,null,{id:activateSponsor.id}))
    }
    setActivateSponsor(false)
  }

  useEffect(()=>{
    if (activateData) {
      tableRef.current && tableRef.current.onQueryChange()
    }
  },[activateData])

  const [CsvData, setCsvData,] = useState()
  const [getCSV, dataCSV, csvLoading, , , csvExtra] = useHttp()

  useEffect(() => {
    if (dataCSV) {
      setCsvData(dataCSV)
    }
  }, [dataCSV])

  useEffect(() => {
    if (CsvData) {
      let csvURI = window.URL.createObjectURL(dataCSV[0])
      let downloadLink = document.createElement('a')
      downloadLink.href = csvURI
      downloadLink.download = `${csvExtra.name}_clicks.xlsx`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
    //eslint-disable-next-line
  },[CsvData])

  if (hasError) {
    return <Redirect to={'/'} />
  }

  return <>

    {activateSponsor&&<>
      <Dialog
        open={activateSponsor}
        onClose={handleActivateClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title">Activate user</DialogTitle>
        <DialogContent>
          <Box p={1} pl={0} pb={2}>
            Are you sure you want to activate user?
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button onClick={()=>handleActivateClose()}  size='small' variant='outlined' color='primary'>
              No
            </Button>
          </Box>
          <Box p={1}>
            <Button onClick={()=>{handleActivateClose(true)}}  size='small' autoFocus color='primary'>
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Box>
      </Dialog>
    </>}

    <Grid item xs={12} sm={12} md={4}>
      <Typography component='h2' variant='h5' className={classes.title}>
        Sponsors
      </Typography>
    </Grid>

    <Grid item xs>

    </Grid>

    <Grid item>
    </Grid>

    <Grid item>
    </Grid>

    <Grid item xs={12}>
      <MaterialTable
        key={sponsors}
        icons={tableIcons}
        tableRef={tableRef}
        columns={[
          { title: 'Name', field: 'name' },
          { title: 'Event', field: 'eventId' },
        ]}
        data={query=>
          new Promise((resolve,reject)=>{
            getData(api.entities.admin.getSponsors(null, {page:query.page+1, items:query.pageSize, term:query.search},))
            resolveRef.current = resolve
          })}
        title=''
        // editable={{
        //   onRowUpdate: (newData, oldData) =>
        //     new Promise((resolve, reject) => {
        //       const dataUpdate = [...reviewers]
        //       const index = oldData.tableData.id
        //       dataUpdate[index] = {...newData}
        //       setReviewers([...dataUpdate])
        //       const {valid, accepted, acceptedByManager, ...payLoad} = newData
        //       console.log(payLoad)
        //       // sendRequest(api.entities.manage.updateReviewer(payLoad,null,{id:oldData.id}))
        //       resolve()
        //     })
        // }}
        actions={[
          {
            icon: GetApp,
            tooltip: 'Export Click data as excel file',
            disabled: csvLoading,
            onClick: (event, rowData) => {
              getCSV(api.entities.admin.downloadSponsorData(null, null, {id:rowData.id},false,{id:rowData.id, name:rowData.name}))
            }
          }
        ]}
        options={{
          pageSize: 10,
          actionsColumnIndex:999,
          headerStyle: {
            backgroundColor: palette['brown-grey'],
            color: '#FFF',
            fontWeight: 'bold',
            fontSize: 14
          },
          rowStyle: {
            fontSize: 12,
            padding: 0
          }
        }}
      />
    </Grid>
  </>
})

export default Sponsors
