import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TreeView from '@material-ui/lab/TreeView'
import TreeItem from '@material-ui/lab/TreeItem'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import {palette} from 'theme'
import { NavLink, withRouter} from 'react-router-dom'
import clsx from 'clsx'
import Divider from '@material-ui/core/Divider'

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    color: 'white',
    width: '200px',
    '&:hover > $content': {
      backgroundColor: `var(--tree-view-bg-color, rgba(0, 0, 0, 0.04))`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content': {
      backgroundColor: `var(--tree-view-bg-color, rgba(0, 0, 0, 0.04))`,
      color: 'var(--tree-view-color)',
    },
    '&:hover $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent'
    },
    '&:focus $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent'
    },
    '& $selected > $content $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent'
    },
    '& $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent !important'
    }
  },
  content: {
    width: '180px',
    color: 'white',
    borderRadius: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightBold,
    // background: 'transparent',
    '$expanded > &': {
      // fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    // width: '185px',
    // paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& $content': {
      // width: '100%'
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0)
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
  selected: {
    '& $content': {
      backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
    },
    '& $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent'
    },
    '& $content > $label': {
      // backgroundColor: `var(--tree-view-bg-color, ${palette['cerulean-blue']}) !important`
      background: 'transparent'
    }
  },
  item: {
    display: 'flex',
    // width: '180px',
    margin: theme.spacing(0.5,0)
  },
  parent: {
    '&:focus > $content': {
      backgroundColor: 'none'
    },
    '&:hover > $content': {
      backgroundColor: 'none'
    }
  }
}))

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles()
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, path, parent, ...other } = props

  return (
    <NavLink to={path} className={clsx(classes.item,parent&&classes.parent)} activeClassName={clsx(path[0]!=='?'&&classes.selected)}><TreeItem
      label={
        <div className={classes.labelRoot}>
          {LabelIcon&&<LabelIcon color='inherit' className={classes.labelIcon} />}
            <Typography variant='body2' className={classes.labelText}>
              {labelText}
            </Typography>
            <Typography variant='caption' color='inherit'>
              {labelInfo}
            </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    /></NavLink>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color: 'white'
  },
  divider: {
    margin: theme.spacing(2,0)
  }
}))

const TreeViewMenu = ({navObj, pageName, eventData, upgraded}) => {
  const classes = useStyles()

  let parentExpanded = navObj.findIndex(
    (item)=>item.children&&item.children.find(
      (child)=>child.name===pageName)
  )

  const [expanded, setExpanded] = useState([parentExpanded])

  const handleChange = (event, nodes) => {
    setExpanded(nodes.filter((node)=>expanded.indexOf(node)===-1))
  }

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={[parentExpanded]}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      expanded={expanded} onNodeToggle={handleChange}
    >
      {
        navObj.filter((e)=>!e.hidden).map((entry, index) => {
          // if (upgraded===false) {
          //   if ((entry.name === 'Paper submissions') || (entry.parent === 'Paper submissions')) {
          //     return <></>
          //   }
          // }
          if (entry.separator) {
            return <Divider className={classes.divider} />
          }
          return <StyledTreeItem  parent={!entry.path&&!entry.parent} nodeId={index} labelText={entry.name} path={entry.path} labelIcon={entry.icon?entry.icon:null}>
            {!entry.path ? entry.children.filter((e)=>!e.hidden).map((child, i) =>
            {
              
              return <StyledTreeItem nodeId={i+navObj.length} labelText={child.name} path={child.path} labelIcon={child.icon?child.icon:null} />
            }):null}
          </StyledTreeItem>
        })
      }
    </TreeView>
  )
}

export default withRouter(TreeViewMenu)