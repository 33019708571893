import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Fade from '@material-ui/core/Fade'
import SpeakerControl from 'components/core/OpenTok/Tabs/ChairControls/SpeakerControl'
import LivePollingControl from 'components/core/OpenTok/Tabs/ChairControls/LivePollingControl'
import LayoutControl from 'components/core/OpenTok/Tabs/ChairControls/LayoutControl'

const styles = theme => ({

})


const ChairControls = withStyles(styles)(({classes, allHandlers, sessionState, raisedHands}) => {

  return <Fade in={true} timeout={200}><Grid item container spacing={2}>
      <LayoutControl sessionState={sessionState}/>
      <SpeakerControl {...allHandlers} sessionState={sessionState} raisedHands={allHandlers.raisedHands}/>
    {sessionState.hasPolls&&<LivePollingControl sessionState={sessionState} />}
  </Grid></Fade>
})

export default ChairControls
