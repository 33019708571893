import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useRef, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {STREAM_VIDEO_TYPES, TIME_AGENDA_INTERVAL, TIME_FADE_IN} from 'config'
import {Link} from 'react-router-dom'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {format, parseISO} from 'date-fns'
import EllipsisText from 'react-ellipsis-text'
import ScrollableContainer from 'components/core/ScrollableContainer'
import {imageFile} from 'utils'
import clsx from 'clsx'
import Dialog from '@material-ui/core/Dialog'
import SessionExternal from 'components/core/Sessions/SessionExternal'
import moment from "moment";


const happeningNowStyles = theme => ({
  sessionBox: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '220px',
    height: '100px',
    borderRadius: '8px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    cursor: 'pointer',
    overflow:'hidden',
  },
  box: {
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '60px',
    zIndex: 5,
    backgroundColor: 'rgba(0,0,0,0.4)',
    borderRadius: '8px',
    padding: theme.spacing(1),
    color: '#FFFFFF',
    height: '100%',
    textShadow: '1px 1px #00001111',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.2)'
    }
  },
  boxWithoutImage: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor:  theme.palette.secondary.light
    }
  },
  sessionTitle: {
    fontWeight: 'bold',
    padding: theme.spacing(0,1)
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  container: {
    position: 'relative'
  },
  backgroundImage: {
    position: 'absolute',
    width: 'auto',
    height: '100%',
    opacity: 1,
    left: '-50%',
    top: '0',
    zIndex:4,
    filter: 'blur(1px)'
  },
  linkToSession: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 6,
  },
  badge: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    position: 'relative',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    marginBottom: '1px',
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
  startsSoon: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    fontWeight: 'bold',
    color: 'green'
  },
  notClickable: {
    cursor: 'default !important',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    }
  },
  notClickableLink: {
    cursor: 'default !important'
  }
})


const Session = withStyles(happeningNowStyles)(({classes, session, startDate, endDate, title, room, id, startsSoon, background, videoStreamType, url, selectedTimezone}) => {

  const [showExternal, setShowExternal] = useState(false)

  let link =  videoStreamType===0?'#':`/session/${id}`

  if ([STREAM_VIDEO_TYPES.ZOOM,STREAM_VIDEO_TYPES.GOOGLE,STREAM_VIDEO_TYPES.OTHER].includes(videoStreamType)) {
    link = ''
  }

  const visitSession = (e) => {
    if ([STREAM_VIDEO_TYPES.ZOOM,STREAM_VIDEO_TYPES.GOOGLE,STREAM_VIDEO_TYPES.OTHER].includes(videoStreamType)) {
      e.preventDefault()
      e.stopPropagation()
      setShowExternal(true)
    }
  }

  const handleClose = ()=> { setShowExternal(false) }

  return <Grid item container className={clsx(classes.box, (!background)&&classes.boxWithoutImage, videoStreamType===0&&classes.notClickable)}>

    {showExternal&&<Dialog onClose={handleClose} open={showExternal} maxWidth='md'>
      <SessionExternal session={session} />
    </Dialog>}

    <Link to={link} className={clsx(classes.linkToSession,videoStreamType===0&&classes.notClickableLink)}  onClick={visitSession}> </Link>

    {startsSoon&&<Box className={classes.startsSoon}>
      <div className="ribbon"><span>SOON</span></div>
    </Box>}

    <Grid item xs={12} className={classes.sessionTitle}> <EllipsisText length={40} text={title} /> </Grid>
    {room&&<Grid item xs={12} className={classes.sessionTitle}> at {room} </Grid>}

    <Grid item xs={12} className={classes.sessionTimes}>
      {/*{format(parseISO(startDate), 'LLL do y')} <br/>*/}
      {format(parseISO(startDate), 'hh:mmaa')} - {format(parseISO(endDate), 'hh:mmaa')} {moment.tz.guess().replace("_", " ")} <br/>
      {moment(startDate).tz(selectedTimezone).format("hh:mmA")} - {moment(endDate).tz(selectedTimezone).format("hh:mmA")} {selectedTimezone.replace("_"," ")}  
    </Grid>

  </Grid>
})

export const HappeningNow = withStyles(happeningNowStyles)(({classes, event, canManage=false}) => {
  const [happeningNow, setHappeningNow] = useState()
  const [getHappeningNowData, happeningNowData, ,] = useHttp()

  const pollingRef = useRef()

  const refreshData = () => getHappeningNowData(api.entities.events.getHappeningNow(null, null, {id: event.eventInfo.id}))

  useEffect(() => {
      refreshData()
      pollingRef.current = setInterval(refreshData, TIME_AGENDA_INTERVAL)
    return () => {
        clearInterval(pollingRef.current)
    }
    // eslint-disable-next-line
  }, [])

  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (event&&timeZones) {
      const zone = timeZones.find((x) => x.id === event.eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timeZones]);


  useEffect(() => {
    if (happeningNowData) {
      setHappeningNow(happeningNowData)
    }
  }, [happeningNowData])

  if (!happeningNow || (happeningNow && !happeningNow.sessions)) {
    return <> </>
  }

  if (!happeningNow.sessions.length) {
    return <> </>
  }

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box p={2} pt={0} pb={0} position={'relative'}>

        <Grid item container spacing={2}>
          <Grid item>
            <Box component='h2' fontWeight='bolder' fontSize={24} mb={1} ml={3}>
              <Box className={classes.badge}></Box>
                Happening Now
              </Box>
          </Grid>
          <Grid item container spacing={1}>
            <ScrollableContainer items={happeningNow.sessions.map((session)=>{
              return (
                <Grid item className={classes.container}>
                  <Box className={clsx(classes.sessionBox, session.videoStreamType === 0 && classes.notClickable)}>
                    <Session
                      id={session.id}
                      background={session.backgroundId}
                      startsSoon={session.startsSoon}
                      startDate={session.startDate}
                      endDate={session.endDate}
                      title={session.title}
                      room={session.room ? session.room.name : null}
                      videoStreamType={session.videoStreamType}
                      url={session.url}
                      session={session}
                      selectedTimezone={selectedTimezone}
                    />
                    {session.backgroundId && <img src={imageFile(session.backgroundId)} alt={session.title} className={classes.backgroundImage} />}
                  </Box>
                </Grid>
              );
            })} />
          </Grid>
        </Grid>
      </Box>
    </Fade>
  </>
})
