import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHttp } from 'api/core'
import api from 'api'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import StepOne from './EventFlowSteps/stepOne';
import StepTwo from './EventFlowSteps/stepTwo';
import StepThree from './EventFlowSteps/stepThree';
import StepSix from './EventFlowSteps/stepSix';
import { useMetaData } from 'components/core/MetaProvider'

import AssignReviewers from 'components/pages/ManageEvent/AssignReviewers'
import PaperReviews from 'components/pages/ManageEvent/PaperReviews'
import AssignSessions from 'components/pages/ManageEvent/AssignSessions'


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  setUncompleted: {
    backgroundColor: "grey",
    fontSize: 11
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  currentStep: {
    "& span:first-child": {
      color: "#0dc7e5",
      fontSize: "14px",
      "& svg": {
        color: "#0dc7e5"      
      },
    },
  },
  contentsContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginTop: 0,
    marginBottom: theme.spacing(5),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
}));



export default function EventFlow()
{
  const classes = useStyles();
  const [pageTitle, setPageTitle] = useState('')
  const [eventInfo, setEventInfo] = useState()
  const [navBack, setNavBack] = useState({ name: 'events', path: '/manage-events' })
  const [, setMetaData] = useMetaData({ title: `Event Flow` })
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState(new Set());
  const [skipped, setSkipped] = React.useState(new Set());
  const [sendRequest, putData, isSubmitting] = useHttp()

  useEffect(() =>
  {
    if (eventInfo && eventInfo.flowState !== null)
    {
      const newState = eventInfo.flowState.split(',')
      const newCompleted = new Set(newState);
      const arrayOfStates = [...newCompleted]
      const numArrayOfStates = arrayOfStates.filter(x=> x !=="NaN").map(Number)  
      const newSet = new Set(numArrayOfStates);
      setCompleted(newSet);
    }
  }, [eventInfo])


  // useEffect(() =>
  // {

  //     console.log(completed)
  //     const start = [0, 1, 2, 3, 4, 5, 6].filter(x => !completed.includes(x))
  //     console.log(start)
  //     setActiveStep(start[0])
    
  // }, [setCompleted])

  useEffect(() =>
  {
    if (eventInfo)
    {
      setMetaData({ title: `Event Flow - ${ eventInfo.name }` })
    }
    // eslint-disable-next-line
  }, [eventInfo, setEventInfo])

  function getSteps()
  {
    return ['Event setup', 'Submissions setup', 'Reviewers setup', 'Assign reviewers', 'Reviewers scores', 'Paper acceptance/rejection & Email notification', 'Paper assignment to sessions'];
  }

  function getStepContent(step)
  {
    switch (step)
    {
      case 0:
        return <StepOne setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 1:
        return <StepTwo setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 2:
        return <StepThree setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 3:
        return <AssignReviewers setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 4:
        return <PaperReviews setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 5:
        return <StepSix setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      case 6:
        return <AssignSessions setPageTitle={setPageTitle} setEventInfo={setEventInfo} eventInfo={eventInfo} setNavBack={setNavBack} />;
      default:
        return 'Unknown step';
    }
  }

  const steps = getSteps();

  const totalSteps = () =>
  {
    return getSteps().length;
  };

  const isStepOptional = (step) =>
  {
    return step === 10;
  };

  const handleSkip = () =>
  {
    if (!isStepOptional(activeStep))
    {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) =>
    {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const skippedSteps = () =>
  {
    return skipped.size;
  };

  const completedSteps = () =>
  {
    return completed.size;
  };

  const allStepsCompleted = () =>
  {
    return completedSteps() === totalSteps() - skippedSteps();
  };

  const isLastStep = () =>
  {
    return activeStep === totalSteps() - 1;
  };

  const handleNext = () =>
  {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed
        // find the first step that has been completed
        steps.findIndex((step, i) => !completed.has(i))
        : activeStep + 1;

    setActiveStep(newActiveStep);
  };

  const handleBack = () =>
  {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () =>
  {
    setActiveStep(step);
  };

  const handleComplete = () =>
  {
    const newCompleted = new Set(completed);
    newCompleted.add(activeStep);
    setCompleted(newCompleted);
    const flowStates = [...newCompleted].toString()
    sendRequest(api.entities.manage.updateEventFlow(null, null, {id:eventInfo.id, states: flowStates}))
    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== totalSteps() - skippedSteps())
    {
      handleNext();
    }
  };

  const handleUnComplete = () =>
  {
    const newCompleted = new Set(completed);
    newCompleted.delete(activeStep);
    console.log(newCompleted)
    const newNewComplete = new Set(newCompleted)
    console.log(newNewComplete )
    setCompleted(newNewComplete );
    const flowStates = [...newCompleted].toString()

    sendRequest(api.entities.manage.updateEventFlow(null, null, {id:eventInfo.id, states: flowStates? flowStates : "null"}))
  };



  const handleReset = () =>
  {
    setActiveStep(0);
    setCompleted(new Set());
    setSkipped(new Set());
  };

  const isStepSkipped = (step) =>
  {
    return skipped.has(step);
  };

  function isStepComplete(step)
  {
    return completed.has(step);
  }

  return (
    <Box className={classes.contentsContainer}>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {steps.map((label, index) =>
        {
          const stepProps = {};
          const buttonProps = {};
          if (isStepOptional(index))
          {
            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index))
          {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={handleStep(index)}
                completed={isStepComplete(index)}
                {...buttonProps}
                className={activeStep===index ? classes.currentStep : ""}
              >
                {label}
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </div>
        ) : (
          <div>
            <div style={{ textAlign: "center", paddingBottom: 20 }}>
              <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
              {isStepOptional(activeStep) && !completed.has(activeStep) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSkip}
                  className={classes.button}
                >
                  Skip
                </Button>
              )}

              {activeStep !== steps.length &&
                (completed.has(activeStep) ? (
                  <>
                    <Typography variant="caption" className={classes.completed}>
                      Step {activeStep + 1} already completed
                    </Typography>{"  "}
                    <Button variant="contained" color="primary" className={classes.setUncompleted} onClick={handleUnComplete}>
                      Undo
                    </Button>                    
                  </>
                ) : (
                  <Button variant="contained" color="primary" onClick={handleComplete}>
                    {completedSteps() === totalSteps() - 1 ? 'Finish' : 'Complete Step'}
                  </Button>
                ))}
              </div>
              <div style={{position: "relative"}}>
                {getStepContent(activeStep)}
              </div>
          </div>
        )}
      </div>
    </Box>
  );
}
