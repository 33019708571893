import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
import FormEditor from 'components/core/Forms/FormEditor'
import api from 'api'
import {useSnackbar} from 'notistack'
import Chip from '@material-ui/core/Chip'
import clsx from 'clsx'
import EventNoteIcon from '@material-ui/icons/EventNote'
import {Select, TextField} from '@material-ui/core'
import {DatePicker,TimePicker} from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import {IconCalendarBorderDark, IconClockBorderDark} from "components/core/icons";

// import NumberFormat from "react-number-format"
// import LinearScaleIcon from '@material-ui/icons/LinearScale'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { FIELD_TYPES } from 'config'
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
import moment from "moment";
const dateLocale = [{ locale: enUS }, { locale: enUS }, { locale: el }, { locale: de }, { locale: fr }, { locale: it }];



const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    // backgroundColor: palette['cerulean-blue'],
    // color: 'white',
    borderRadius: '6px'
  },
  newFormType: {
    // width: '580px'
  },
  row: {
    marginBottom: theme.spacing(5)
  },
  tabsBar: {
    backgroundColor: palette['very-light-pink']
  },
  tab: {
    width: '100%',
    color: 'white',
    backgroundColor: palette['cerulean-blue'],
    padding: theme.spacing(2)
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    '&:focus': {
      backgroundColor: palette.aquamarine
    }
  },
  tabLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  addNewItem:{
    // backgroundColor: 'white',
    // padding: theme.spacing(2),
    // borderRadius: '6px',
    border: '2px dashed !important',
    // borderColor: palette['cerulean-blue'],
    // justifyContent: 'center',
    // alignItems: 'center',
    // fontWeight: 'bold',
    // color: palette['greyish-brown'],
    // fontSize: '18px',
    opacity: 0.5,
    margin: 0,
    cursor: 'pointer',
    transition: '0.4s all',
    '&:hover': {
      opacity: 0.8
    }
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid',
    borderRadius: '6px',
    borderColor: 'white',
    // backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  formControl: {
    width: '100%'
  },
  toolbar: {
    borderTop: '1px solid',
    color: 'white',
    marginTop: theme.spacing(2),
  },
  editor: {
    color: palette['greyish-brown'],
    borderRadius: '6px',
    minHeight: '300px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  dialogActions: {
    marginTop: theme.spacing(2)
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  mainBox: {
    marginTop: theme.spacing(3),
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '350px',
    // overflowY: 'auto'
    // minHeight: 'calc(80vh - 300px)'
  }
})

// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//         suffix="%"
//       />
//     );
// }
// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//       />
//     );
// }

const FormEditNewReviewFlow = withStyles(styles)(({classes, handleClose, sFlow, data, eventId, submissionFlows }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [tab, setTab] = useState(0)
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  
  useEffect(() =>
  {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data && timeZones) {
      const zone = timeZones.find((x) => x.id === data.eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, timeZones]);

  //eslint-disable-next-line
  const [putData, dataPut, isSaving, hasErrorSave] = useHttp()
  const [name, setName] = useState(sFlow.reviewForm?sFlow.reviewForm.name:'')
  const [flowName, setFlowName] = useState(sFlow&&sFlow.name?sFlow.name:'')
  const [fields, setFields] = useState(sFlow.reviewForm?sFlow.reviewForm.fields:[
    {
      label: language.grade,
      description: language.reviewGrade,
      type: FIELD_TYPES.GRADE,
      required: true,
      options: [
        {
          "option" : ''
        }
      ]
    }
  ])
  const [localFlow, setLocalFlow] = useState({
    ...sFlow,
    submissionFlowId: sFlow&&sFlow.reviewForm?sFlow.submissionFlowId:parseInt(submissionFlows[0].id)
  })

  useEffect(()=>{
    if (dataPut) {
      enqueueSnackbar('Successfully saved', {variant:'success'})
      handleClose(true)
    }
    // eslint-disable-next-line
  },[dataPut])

  const saveForms = () => {
    // let formDataObj  = new FormData();

    const payLoad = {
      ...localFlow,
      maxSubmissions: 0,
      name:flowName,
      reviewForm: {name:name, fields:fields}
    }

    // const dataObj = new Blob([JSON.stringify(payLoad)], { type: 'application/json'});
    // formDataObj.append('data', dataObj)

    if (sFlow.reviewForm) {
      putData(api.entities.manage.updateReviewFlow(payLoad, null, {id:sFlow.id}))
    } else {
      putData(api.entities.manage.addReviewFlow({...payLoad,eventId}))
    }
  }

  useEffect(()=>{
    if (!sFlow.reviewForm) {
      setLocalFlow({...localFlow, endDate: Date.now(), openDate: Date.now(), maxReviews: 0 })
    }
    //eslint-disable-next-line
  },[])

  // const isSubmitting = isLoading&&!hasError

  const changeValue = (e) => {
    setLocalFlow({...localFlow, submissionFlowId: parseInt(e.target.value) })
  }

  return <Box className={classes.container}>
    <Grid item container spacing={1} className={classes.row}>
      <Typography variant='h5' component='h1'> {'Review flow'}</Typography>
    </Grid>

    <Grid item container spacing={1} className={classes.Tabs}>

      <Grid item xs>
        <Chip size='medium' className={clsx(classes.tab, tab === 0 && classes.activeTab)} onClick={() => {
          setTab(0)
        }} label={<Box className={classes.tabLabel}> <DynamicFeedIcon/> &nbsp; Basic Info </Box>}
              color={'secondary'}/>
      </Grid>

      <Grid item xs>
        <Chip size='medium' className={clsx(classes.tab, tab === 1 && classes.activeTab)} onClick={() => {
          setTab(1)
        }} label={<Box className={classes.tabLabel}><EventNoteIcon/> &nbsp; Submission form </Box>}
              color={'secondary'}/>
      </Grid>

    </Grid>

    <Grid item container className={classes.mainBox}>

      {tab===0&&<Grid item container spacing={2}>

        <Grid item xs={12}>
          <TextField required variant='filled' label={'Name'} fullWidth value={flowName} onChange={(e)=>setFlowName(e.target.value)}/>
        </Grid>

        <Grid item xs={12}>
          <TextField rows={4} multiline required variant='filled' label={'Description'} fullWidth value={localFlow.description} onChange={(e)=>setLocalFlow({...localFlow, description: e.target.value})}/>
        </Grid>

        <Grid item xs={4}>
          <DatePicker format="DD/MM/yyyy" label='Open Date' inputVariant='filled' onChange={(value) =>
          {
            setLocalFlow({ ...localFlow, openDate: moment(value).tz(selectedTimezone, true) });
            //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDT00:00:00.000Z").toString());
          }} value={localFlow.openDate} fullWidth disabled={isSaving} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconCalendarBorderDark/>
              </InputAdornment>
            )
          }} />
        </Grid>

        <Grid item xs={4}>
          <DatePicker format="DD/MM/yyyy" label='End Date' inputVariant='filled' onChange={(value) =>
          {
            setLocalFlow({ ...localFlow, endDate: moment(value).tz(selectedTimezone, true) });
            //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDTHH:mm:ss.000Z").toString());
          }} value={localFlow.endDate} fullWidth disabled={isSaving} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconCalendarBorderDark/>
              </InputAdornment>
            )
          }} />
        </Grid>
        <Grid item xs={4}>
          <TimePicker
            label='End Time'
            value={localFlow.endDate}    
            fullWidth
            disabled={isSaving}
            inputVariant='filled'           
            renderInput={(params) => <TextField {...params} />}
            onChange={(value) => {
              setLocalFlow({...localFlow, endDate: moment(value).tz(selectedTimezone, true)});
              //console.log(moment(value).tz(selectedTimezone, true).format("YYYY-MM-DDTHH:mm:ss.000Z").toString());
                      }}
            InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconClockBorderDark/>
              </InputAdornment>
            )
             }}            
          />
        </Grid>        
        {/*<Grid item xs={6}>*/}
        {/*    <TextField required variant='filled' label={'Max. submissions'} fullWidth disabled={isSaving} value={localFlow.maxSubmissions} onChange={(e)=>{ setLocalFlow({...localFlow, maxSubmissions: e.target.value})}}                    InputProps={{*/}
        {/*        inputComponent: NumberFormatCustom,*/}
        {/*    }} />*/}
        {/*</Grid>*/}

        <Grid item xs={6}>
          <TextField required variant='filled' label={'Max reviews'} disabled={isSaving} fullWidth value={localFlow.maxReviews} onChange={(e)=>setLocalFlow({...localFlow, maxReviews: e.target.value})}/>

        </Grid>

        <Grid item xs={6}>
          <FormControl className={classes.formControl}>
            <Select required labelId={`select-label-submission`} disabled={isSaving} label={`Submission flow`} variant='filled' displayEmpty fullWidth value={localFlow.submissionFlowId} onChange={(e)=>changeValue(e)}>
              {submissionFlows.map((flow, i)=>{
                return <MenuItem value={flow.id} key={i}>{flow.name}</MenuItem>
              })}
            </Select>
            <InputLabel variant='filled'>Submission flow</InputLabel>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControlLabel
            control={<Checkbox required checked={localFlow.blindReview} disabled={isSaving} onChange={(e)=>setLocalFlow({...localFlow, blindReview: !localFlow.blindReview})} />}
            label={'Blind review'}
          />
        </Grid>

      </Grid>}

      {tab===1&&<Grid item container spacing={2}>
        {/*{!fields&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>*/}
        {/*    <Box display='flex'>*/}
        {/*        <CircularProgress size={'8rem'} color='secondary' />*/}
        {/*    </Box>*/}
        {/*</Box>}*/}
        <FormEditor fields={fields} setFields={setFields} name={name} setName={setName} eventId={eventId} flowType={"review"} />
      </Grid>}

    </Grid>
    {/*<FormEditor fields={fields} setFields={setFields} />*/}
    {/*<FormViewer fields={fields} setFields={setFields} />*/}
    <Grid item container spacing={3}>
      <Grid item md={3} xs='auto'> </Grid>
      <Grid item container md={6} spacing={3} className={classes.btns}>
        <Grid item xs={12} md={6}>
          <Button variant='outlined' size='small' color='primary' onClick={handleClose}> Cancel </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button size='small' type='submit' onClick={saveForms} disable={isSaving}> Save Changes </Button>
        </Grid>
      </Grid>
    </Grid>
  </Box>
})

export default FormEditNewReviewFlow
