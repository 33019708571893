import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import CollaboratorCard from 'components/core/CollaboratorCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import CollaboratorEditNew from 'components/core/CollaboratorEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'

import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  collaboratorsContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noCollaboratorsYet:{
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minWidth: '645px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  addBtn: {
    textAlign: 'right'
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
})

const Collaborators = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  // Hooks
  const [getData, data] = useHttp()
  const [delRequestCollaborator, delDataCollaborator] = useHttp()

  // State
  const [collaborations, setCollaborators] = useState()
  const [editingCollaborator, setEditingCollaborator] = useState()
  const [deletingCollaborator, setDeletingCollaborator] = useState()
  const [editCollaborator, setEditCollaborator] = useState(false)
  const [deleteCollaborator, setDeleteCollaborator] = useState(false)

  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)

  // Deps
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getData(api.entities.manage.getCollaborations(null,null,{id:eventId}))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setCollaborators(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
    }
  },[data, setEventInfo, setNavBack, setPageTitle])
  
  const handleEditOpen = (collaborator) => {
    setEditCollaborator(true)
    setEditingCollaborator(collaborator)
  }

  const handleEditClose = (shouldReload) => {
    setEditCollaborator(false)
    if (shouldReload) {
      getData(api.entities.manage.getCollaborations(null,null,{id:eventId}))
    }
  }
  
  const handleDeleteOpen = (collaborator) => {
    setDeleteCollaborator(true)
    setDeletingCollaborator(collaborator)
  }

  const handleDeleteClose = (collaborator) => {
    setDeleteCollaborator(false)
    if (collaborator) {
      delRequestCollaborator(api.entities.collaborations.delete(null,null,{id:collaborator.id}))
    }
  }

  useEffect(()=>{
    if (delDataCollaborator) {
      enqueueSnackbar('Successfully deleted collaborator', {variant: 'success'})
      getData(api.entities.manage.getCollaborations(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[delDataCollaborator])

  if (!collaborations) return <> </>

  return <>
    <Dialog onClose={handleEditClose} open={editCollaborator} fullWidth maxWidth='sm'>
      <CollaboratorEditNew collaborator={editingCollaborator} handleClose={handleEditClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteCollaborator}
      onClose={()=>{handleDeleteClose()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete collaborator</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete this collaborator?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={()=>{handleDeleteClose(deletingCollaborator)}}  size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>

    <Fade in={true} timeout={1200}>
      <Grid container spacing={2} className={classes.collaboratorsContainer}>

        <Grid item xs={12}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Collaborators
            <Tooltip classes={{tooltip: classes.tooltip}} title="The list of users with administration rights." placement="top" arrow>
                <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
            </Tooltip>             
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {collaborations.collaborations.map((collaborator,i)=>{
            return <CollaboratorCard collaborator={collaborator} key={i} editAction={()=>{handleEditOpen(collaborator)}} deleteAction={()=>{handleDeleteOpen(collaborator)}} mb={2}/>
          })}
          {collaborations.collaborations.length===0?<Grid item container height={130} className={classes.noCollaboratorsYet}>
            <Grid item> No collaborators yet! </Grid>
          </Grid>:''}
        </Grid>

        <Grid item xs={12} className={classes.addBtn}>
          <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleEditOpen()}}>Add collaborator</Button>
        </Grid>

      </Grid>
    </Fade>
  </>
})

export default withRouter(Collaborators)
