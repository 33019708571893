import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Stage, Layer, Image, Text, Group, Rect} from 'react-konva'
import useImage from 'use-image'
import {SIZE_CANVAS_HEIGHT, SIZE_CANVAS_WIDTH} from 'config'
import {imageFile} from 'utils'


const styles = theme => ({

})

const ImageFile = ({url}) => {
  const [image] = useImage(url)
  const SIZE_LOGO_WIDTH = 180
  const SIZE_LOGO_HEIGHT = 180

  if (!image) return <></>

  var wrh = image.width / image.height
  var newWidth = SIZE_LOGO_WIDTH
  var newHeight = newWidth / wrh
  if (newHeight > SIZE_LOGO_HEIGHT) {
    newHeight = SIZE_LOGO_HEIGHT
    newWidth = newHeight * wrh
  }
  var xOffset = newWidth < SIZE_CANVAS_WIDTH? ((SIZE_CANVAS_WIDTH - newWidth) / 2) : 0
  var yOffset = 110

  return <Image image={image} height={newHeight} width={newWidth} x={xOffset} y={yOffset} />
}

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

const SpeakerCanvas = withStyles(styles)(({classes, selectedSpeaker, setCanvasVideoTrack, setPublishingCanvas}) => {
  const canvasRef = useRef()
  const refreshRef = useRef()
  const textRef = useRef()
  const imageRef = useRef()
  const [avatar,setAvatar] = useState()

  useEffect(()=>{

    const canvasVideoTrack = canvasRef.current.getCanvas()._canvas.captureStream(1).getVideoTracks()[0]
    setCanvasVideoTrack(canvasVideoTrack)

    refreshRef.current = setInterval(()=>{
      canvasRef.current.getStage().draw()
    },41.6)

    setPublishingCanvas(true)

    return () => {
      clearInterval(refreshRef.current)
      setCanvasVideoTrack(false)
    }

    //eslint-disable-next-line
  },[canvasRef])

  useEffect(()=>{
    if (selectedSpeaker) {
      setAvatar(null)
      toDataURL(imageFile(selectedSpeaker.image ? selectedSpeaker.image : ''), function(dataUrl) {
        setAvatar(dataUrl)
      })
    }
  },[selectedSpeaker])

  // useEffect(()=>{
  //   if (imageRef.current) {
  //     imageRef.current.node.to({
  //       scaleX: Math.random() + 0.8,
  //       scaleY: Math.random() + 0.8,
  //       duration: 2
  //     })
  //   }
  // },[imageRef])

  const selectedSpeakerInfo = selectedSpeaker?(!selectedSpeaker.firstName)?'Anonymous':
    `${selectedSpeaker.title?selectedSpeaker.title:''} ${selectedSpeaker.firstName} ${selectedSpeaker.lastName}\n${selectedSpeaker.profession?selectedSpeaker.profession:''}${selectedSpeaker.profession?' ':''}${selectedSpeaker.institute?selectedSpeaker.institute:''}
    \n${selectedSpeaker.summary?selectedSpeaker.summary:''}
    `:''

  return <Stage width={SIZE_CANVAS_WIDTH} height={SIZE_CANVAS_HEIGHT} style={{display:'none'}}>
    <Layer ref={canvasRef}>
      {selectedSpeaker&&avatar&&<Group
        clipFunc= {function (ctx) {
        ctx.arc((SIZE_CANVAS_WIDTH / 2), 200, 90, 0, Math.PI * 2, false);
        }}
      >
        <ImageFile url={avatar} key={selectedSpeaker.id} ref={imageRef}/>
      </Group>}
      {textRef.current&&<Rect
        x={(SIZE_CANVAS_WIDTH-800)/2}
        y={((SIZE_CANVAS_HEIGHT)/2)+70}
        width={800} height={textRef.current.height()} fill={'#f9f9f9'}
        stroke={'#e6e6e6'}
        cornerRadius={4}
      /> }
      {selectedSpeaker&&<Text
        x={(SIZE_CANVAS_WIDTH-800)/2}
        y={(SIZE_CANVAS_HEIGHT / 2.92)+70}
        text={selectedSpeakerInfo}
        fontSize={32}
        fill={'#FFF'}
        fontStyle={'bold'}
        width={800}
        padding={20}
        align={'center'}
      />}
      {!selectedSpeaker&&<Text
        x={(SIZE_CANVAS_WIDTH-800)/2}
        y={(SIZE_CANVAS_HEIGHT-100)/2}
        text={'Speaker'}
        fontSize={64}
        fill={'#FFF'}
        fontStyle={'bold'}
        width={800}
        padding={20}
        align={'center'}
      />}
    </Layer>
  </Stage>
})

export default SpeakerCanvas
