import React, {useState} from 'react'
import Dialog from '@material-ui/core/Dialog'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import {IconFileBorderDark} from 'components/core/icons'
import UploadFile from 'components/core/OpenTok/Components/FilePresenting/UploadFile'


const styles = theme => ({
  paper: {
    backgroundColor: 'white',
    color: palette['greyish-brown']
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 40px 50px',
    borderRadius: '6px'
  },
  row: {
    marginBottom: theme.spacing(3)
  },
  avatar: {
    color: `${palette['greyish-brown']} !important`,
    background: 'none',
    '&> img': {
      maxWidth: '100%'
    }
  },
  fileChip: {
    backgroundColor: 'transparent'
  }
})

const FileSelectionDialog = withStyles(styles)(({classes, open, handleClose, files, setPresentationFile, sessionState}) => {

  const {canUploadFile,canSelectExistingFile, roomId} = sessionState

  const [isLoading, setIsLoading] = useState(false)

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="visibility-selection"
      classes={{paper:classes.paper}}
      disableBackdropClick={isLoading}
      disableEscapeKeyDown={isLoading}
    >
      <Box className={classes.content}>
        <Grid container spacing={1} className={classes.row}>
           <Box fontWeight='bold'> {canUploadFile?`Load or select`:`Select`} an existing presentation </Box>
        </Grid>

        {canUploadFile&&<Grid container spacing={1}>
          <UploadFile setPresentationFile={setPresentationFile} sessionId={sessionState.roomSessionId} handleClose={handleClose} isLoading={isLoading} setIsLoading={setIsLoading} roomId={roomId}/>
        </Grid>}

        {canSelectExistingFile&&<Grid container spacing={1} alignContent='center' justify='center' alignItems='center'>

            {files&&files.map((file,i) => {
              return <Grid item xs={12} key={i}>
                <Chip className={classes.fileChip}
                      avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                      label={`${file.filename}`}
                      onClick={() => handleClose(null, null, file)}
                />
                {/*<FileCard file={file} key={i} onClick={()=>handleClose(null,null,file)}/></Grid>*/}
              </Grid>
            })}
          </Grid>}
      </Box>
    </Dialog>
  )
})

export default FileSelectionDialog
