import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import FormViewer from 'components/core/Forms/FormViewer'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from 'api'
import {useHttp} from 'api/core'

const styles = theme => ({
    container: {
        padding: '40px 50px 50px 50px',
        // backgroundColor: palette['cerulean-blue'],
        // color: 'white',
        borderRadius: '6px'
    },
    newFormType: {
        // width: '580px'
    },
    row: {
        marginBottom: theme.spacing(5)
    },
    tabsBar: {
        backgroundColor: palette['very-light-pink']
    },
    tab: {
        width: '100%',
        color: 'white',
        backgroundColor: palette['cerulean-blue'],
        padding: theme.spacing(2)
    },
    Tabs: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    activeTab: {
        backgroundColor: palette.aquamarine,
        '&:focus': {
            backgroundColor: palette.aquamarine
        }
    },
    tabLabel: {
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    addNewItem:{
        // backgroundColor: 'white',
        // padding: theme.spacing(2),
        // borderRadius: '6px',
        border: '2px dashed !important',
        // borderColor: palette['cerulean-blue'],
        // justifyContent: 'center',
        // alignItems: 'center',
        // fontWeight: 'bold',
        // color: palette['greyish-brown'],
        // fontSize: '18px',
        opacity: 0.3,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5
        }
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid',
        borderRadius: '6px',
        borderColor: 'white',
        backgroundColor: 'rgba(0,0,0,0.1)',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    formControl: {
        width: '100%'
    },
    toolbar: {
        borderTop: '1px solid',
        color: 'white',
        marginTop: theme.spacing(2),
    },
    editor: {
        color: palette['greyish-brown'],
        borderRadius: '6px',
        minHeight: '300px',
        fontFamily: "'Roboto','Karla', sans-serif;",
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    dialogActions: {
        marginTop: theme.spacing(2)
    },
    btns: {
        marginTop: theme.spacing(2),
        textAlign: 'center'
    },
    mainBox: {
        marginTop: theme.spacing(3),
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        minHeight: '350px',
        // overflowY: 'auto'
        // minHeight: 'calc(80vh - 300px)'
    }
})


const FormPreview = withStyles(styles)(({classes, handleClose, unlimited, setSuccessful, forms, message, eventId, freeOnly }) => {
    const [tab, setTab] = useState(0)
    const [getThematicCodes, thematicCodes] = useHttp()

    const [fieldsReject, setFieldsReject] = useState(forms.rejectForm.fields)
    const [fields, setFields] = useState(forms.acceptForm.fields)

    useEffect(()=>{
        getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:eventId}))
        //eslint-disable-next-line
    },[])

    if (!thematicCodes) return <> </>

    return <Box className={classes.container}>
        <Grid item container spacing={1} className={classes.row}>
            <Typography variant='h5' component='h1'> {'Preview forms'}</Typography>
        </Grid>

        <Grid item container className={classes.mainBox}>

                {tab===0 && <Grid item container className={classes.container} spacing={3} justify='center' alignContent='center' alignItems='center'>
                    <Grid item>
                      <p>
                          {message?message:'No message set yet.'}
                      </p>
                    </Grid>
                    <Grid item container spacing={3}>
                        <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center'>
                            <Grid item>
                                <Button size='small' onClick={()=>setTab(1)}> Accept </Button>
                            </Grid>
                            <Grid item>
                                <Button size='small' colour={'red'} onClick={()=>setTab(2)}> Reject </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                }


        {tab === 1 && <>
            <Grid item container spacing={2}>
                {!fields&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                </Box>}
                {fields&&<FormViewer fields={fields} setFields={setFields} thematicCodes={thematicCodes} />}
                {fields&&fields.length===0&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        No form available
                    </Box>
                </Box>}
            </Grid>
        </>}

        {tab === 2 && <>
            <Grid item container spacing={2}>
                {!fieldsReject&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        <CircularProgress size={'8rem'} color='secondary' />
                    </Box>
                </Box>}
                {fieldsReject&&<FormViewer fields={fieldsReject} setFields={setFieldsReject} thematicCodes={thematicCodes} />}
                {fieldsReject&&fieldsReject.length===0&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
                    <Box display='flex'>
                        No form available
                    </Box>
                </Box>}
            </Grid>
        </>}

        </Grid>
            {/*<FormEditor fields={fields} setFields={setFields} />*/}
            {/*<FormViewer fields={fields} setFields={setFields} />*/}
        <Grid item container spacing={3}>
            <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center' className={classes.btns}>
                <Grid item>
                    <Button size='small' onClick={handleClose}> Close </Button>
                </Grid>
            </Grid>
        </Grid>
    </Box>
})

export default FormPreview
