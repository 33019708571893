import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {format} from "date-fns"
import {SPONSOR_TYPES} from 'config'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grid from '@material-ui/core/Grid'
import {imageFile, withHttp} from 'utils'
import parseISO from 'date-fns/parseISO'
import EllipsisText from 'react-ellipsis-text'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: palette["cerulean-blue"],
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
    color: "white",
    borderRadius: "6px",
    position: "relative",
    // maxWidth: "860px",
    "& a": {
      color: "white",
    },
  },
  sponsorContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: palette["brown-grey"],
    color: "white",
    borderRadius: "6px",
    position: "relative",
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(6),
    },
  },
  plain: {
    backgroundColor: "white",
    color: palette["greyish-brown"],
    "& a": {
      color: palette["greyish-brown"],
    },
  },
  SponsorCard: {},
  sponsorLogo: {
    width: "80px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "100%",
      height: "auto",
      backgroundColor: palette.imageBackgroundColor,
    },
  },
  fieldTitle: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  fieldValue: {
    fontSize: "14px",
    overflowWrap: "break-word",
    "& > a": {
      color: "white",
      maxWidth: "90%",
    },
  },
  sessionBox: {
    minWidth: "135px",
    height: "60px",
    backgroundColor: palette["pale-sky-blue"],
    borderRadius: "8px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(1),
    color: palette["greyish-brown"],
  },
  sessionTitle: {
    color: palette["cerulean-blue"],
    fontWeight: "bold",
  },
  sessionsBox: {
    overflowX: "auto",
  },
  editIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: "pointer",
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    },
  },
  deleteIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  hiddenIcon: {
    position: "absolute",
    top: theme.spacing(6),
    right: theme.spacing(2),
  },
  logoBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
  },
});

const FieldText = withStyles(styles)(({title, value, url, classes, ellipsis, size}) => {
  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}  >
      {url?<a href={withHttp(value)} target='_blank' rel='noopener noreferrer'>{ellipsis?<EllipsisText text={value} length={size} />:value}</a>:ellipsis?<EllipsisText text={value} length={size} />:value}
    </Box>
  </Box>
})

const Session = withStyles(styles)(({classes, day, ordinal, startDate, endDate, ...restProps})=>{
  return <Box mr={1} className={classes.sessionBox} {...restProps}>
     <Box className={classes.sessionTitle}>Session {ordinal} </Box>
    {format(parseISO(startDate), 'LLL do y')} <br/>
    {format(parseISO(startDate), 'hh:mmaa')} - {format(parseISO(endDate), 'hh:mmaa')} <br/>
    </Box>
})

const SponsorCard = withStyles(styles)(({classes, dragHandleProps, sponsor, viewOnly=false, index, plain, editAction, deleteAction, content, ...props}) => {

  const createMarkup = (value) => {
    return {__html: value}
  }
  
  return <Box className={classes.SponsorCard} {...props} key={index}><ThemeProvider theme={theme}>
    <Box p={2} className={sponsor.sponsorMessage === null ? clsx(classes.container,plain&&classes.plain) : classes.sponsorContainer }>

      {viewOnly&&<Box display='flex' pb={2} flexDirection='row' width={'100%'} fontWeight='bold' alignItems='center'>
        <Box width={100}>{SPONSOR_TYPES[sponsor.type]}</Box>
        <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' alignContent='center'>
          <Box display='flex'><Typography variant='h6' noWrap> {sponsor.name}</Typography></Box>
          <Box display='flex' pl={1} ><a href={withHttp(sponsor.url)} target='_blank' rel='noopener noreferrer' title={sponsor.name}><OpenInNewIcon fontSize='small'/></a></Box>
        </Box>
      </Box>}

      <Box display='flex' flexDirection='row' alignItems='flex-start'>

        {!viewOnly&&<Box display='flex' mb={2} >
          <Box mr={1} className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>
        </Box>}

        <Box width={100}>
          <Box display='flex'  className={clsx(classes.sponsorLogo,viewOnly&&classes.logoBox)}>
              <img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} />
          </Box>
        </Box>

        {!viewOnly&&<Box display='flex' p={2} pt={0} pb={0} flexDirection='column' width={'100%'}>

          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={6} md={4}>
              <FieldText title='Name' value={sponsor.name} />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
              <FieldText title='URL' value={sponsor.url}  url={true} ellipsis={true} size={80}/>
            </Grid>
          </Grid>

          <Grid container display='flex' alignItems='flex-start'>
            <Grid item xs={12} sm={6} md={4}>
              <FieldText title='Type' value={SPONSOR_TYPES[sponsor.type]} />
            </Grid>
            {!content && <Grid item xs={12} sm={6} md={8}>
              <FieldText title='Summary' value={<div dangerouslySetInnerHTML={createMarkup(sponsor.summary)} key={sponsor.summary} />} />
            </Grid>}
          </Grid>
        </Box>}

        {viewOnly&&<Box display='flex' p={2} pt={0} flexDirection='column' flexBasis={'100%'}>
          <Typography variant='body2'><div dangerouslySetInnerHTML={createMarkup(sponsor.summary)} key={sponsor.summary} /></Typography>
        </Box>
        }

        {!viewOnly&&<Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end' >
          <IconNotesBorderLight title='Edit Sponsor' className={classes.editIcon} onClick={()=>{editAction&&editAction(sponsor.id)}}/>
          <IconXBorderLight title='Delete Sponsor' className={classes.deleteIcon} onClick={() => { deleteAction && deleteAction(sponsor.id) }} />
          {sponsor.sponsorMessage!=null&&<VisibilityOffOutlinedIcon title="Hidden sponsor"className={classes.hiddenIcon}  />}
        </Box>}
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' width={270}>

        </Box>
        <Box display='flex' className={classes.sessionsBox}>
        {sponsor.sessions.map((session,i)=>{
          return <Session day={0} ordinal={session.ordinal} startDate={session.startDate} endDate={session.endDate} key={i}/>
        })}
        </Box>
      </Box>
    </Box>

  </ThemeProvider>
    </Box>
      })

      export default SponsorCard
