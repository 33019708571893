import {withStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useHttp} from "api/core";
import api from "api";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import {TIME_FADE_IN} from "config";
import CameraReadyAcceptedPapers from "components/core/CameraReadyAcceptedPapers";
import {useSnackbar} from "notistack";
import {Redirect} from "react-router-dom";
import LoginSignupDialog from "components/core/LoginSignupDialog";
import {useAuth} from "auth/AuthController";
import isPast from "date-fns/isPast";
import isFuture from "date-fns/isFuture";
import parseISO from "date-fns/parseISO";
// import {ReactComponent as NoSubmission} from 'assets/placeholders/no_submissions.png'
import {palette} from "theme";
import FormUploadCameraReady from "components/core/FormUploadCameraReady";
import language from "components/language";
import moment from "moment";

const submissionStyles = (theme) => ({
  noOfSubmissions: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    borderRadius: "50%",
    fontSize: "24px",
    userSelect: "none",
  },
  noSelect: {
    userSelect: "none",
  },
  clickable: {
    cursor: "pointer",
  },
  dialog: {
    backgroundColor: "white",
  },
  disabled: {
    cursor: "default",
    opacity: 0.3,
  },
});

export const CameraReady = withStyles(submissionStyles)(({classes, event, isPreview, canManage = false}) => {
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (event && timeZones) {
      const zone = timeZones.find((x) => x.id === event.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timeZones]);

  const [submissions, setSubmissions] = useState();
  const [getSubmissionsData, submissionsData, , hasError] = useHttp();
  const [cameraReadyPapers, setCameraReadyPapers] = useState();
  const [getCameraReadyPapers, cameraReadyData, , Error] = useHttp();
    
  useEffect(() => {
    if (isPreview) {
      getSubmissionsData(api.entities.submissions.getSubmissions(null, null, {id: event.eventInfo.id})); // TODO: PREVIEW ENDPOINT
      getCameraReadyPapers(api.entities.manage.getPaperAcceptanceReviews(null, null, {id: event.eventInfo.id}));
    } else {
      getSubmissionsData(api.entities.submissions.getSubmissions(null, null, {id: event.eventInfo.id}));
      getCameraReadyPapers(api.entities.manage.getPaperAcceptanceReviews(null, null, {id: event.eventInfo.id}));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cameraReadyPapers && submissionsData) {
      const cameraReadySubmissionsIdList = cameraReadyPapers.map((paper) => paper.submissionFlowId);

      const cameraReadySubmissions = submissionsData.filter(
        (submission) =>
          submission.cameraReady === true &&
          (
            (submission.cameraReadyEnabled === 1 && isFuture(parseISO(submission.cameraReadyEndDate)) && isPast(parseISO(submission.cameraReadyStartDate)))
            || submission.cameraReadyEnabled === 2
          ) &&
          submission.answers.length !== 0 &&
          cameraReadySubmissionsIdList.includes(submission.id),
      );

      setSubmissions(cameraReadySubmissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraReadyPapers, submissionsData]);

  useEffect(() => {
    if (cameraReadyData) {
        const AcceptedPapers = cameraReadyData.papers.filter((paper) => paper.status === 1);
      setCameraReadyPapers(AcceptedPapers);
    }
  }, [cameraReadyData]);

  useEffect(() => {
    if (hasError) {
      if (hasError.status === 401) {
      }
    }
  }, [hasError]);

  const [openDialog, setOpenDialog] = useState(false);
  const [addingSubmission, setAddingSubmission] = useState();
  const [openLoginSignup, setOpenLoginSignUp] = useState(false);
  const [isAuthenticated] = useAuth();
  const [cameraReadyStatus, setCameraReadyStatus] = useState("view");
  // eslint-disable-next-line
  const {enqueueSnackbar} = useSnackbar();

  const handleClose = (shouldReload) => {
    setOpenDialog(false);
    if (shouldReload === true) {
      if (isPreview) {
        getSubmissionsData(api.entities.submissions.getSubmissions(null, null, {id: event.eventInfo.id})); // TODO: PREVIEW ENDPOINT
      } else {
        getSubmissionsData(api.entities.submissions.getSubmissions(null, null, {id: event.eventInfo.id}));
      }
    }
    setAddingSubmission(null);
  };

  const handleOpen = (submission) => {
    setAddingSubmission(submission);
    if (!isAuthenticated) {
      setOpenLoginSignUp(true);
    } else {
      setOpenDialog(true);
    }
    setCameraReadyStatus(submission.edit);
  };

  const handleCloseLogin = () => {
    setOpenLoginSignUp(false);
  };

  useEffect(() => {
    if (isAuthenticated && addingSubmission) {
      setOpenDialog(true);
    }
  }, [addingSubmission, isAuthenticated]);

  if (hasError && hasError.status === 401) {
    return <Redirect to={`/login/?returnUrl=/event/${event.eventInfo.id}/camera-ready`} />;
  }

  if (!submissions) {
    // Loading
    return <> </>;
  }

  return (
    <>
      {openLoginSignup && <LoginSignupDialog open={openLoginSignup} onClose={handleCloseLogin} />}
      {openDialog && addingSubmission && (
        <FormUploadCameraReady eventId={event.eventInfo.id} open={openDialog} cameraReadyStatus={cameraReadyStatus} handleClose={handleClose} event={event} addingSubmission={addingSubmission} submissions={submissions} />
      )}

      <Fade in={true} timeout={TIME_FADE_IN}>
        <Box display="flex" p={4} pb={12} flexDirection="column">
          <Box pb={2}>
            <Box component="h2" fontWeight="bolder" fontSize={24} mb={1}>
              {event.eventInfo.submissionsTitle ? event.eventInfo.submissionsTitle : <>{language.cameraReadySubmissions}* <br/><small style={{fontWeight: 100,fontSize: "14px"}}> *only Submitters can upload camera ready files</small>  </>}
            </Box>
          </Box>
          {submissions.length === 0 && (
            <Box display="flex" justifyContent="center" alignContent="center" alignItems="center">
              <Box display="flex" flexDirection="column" justifyContent="center" alignContent="center" alignItems="center">
                {/*<Box display='flex'>*/}
                {/*  <NoSubmission/>*/}
                {/*</Box>*/}
                <Box display="flex" pt={4} color={palette["greyish-brown"]}>
                  {language.noSubmissionsAvailableYet}
                </Box>
              </Box>
            </Box>
          )}
          {submissions.map((submission, i) => {
            return (
              <CameraReadyAcceptedPapers
                event={event}
                cameraReadyPapers={cameraReadyPapers}
                submission={submission}
                handleOpen={(submission) => handleOpen(submission)}
                p={1}
                index={i}
                selectedTimezone={selectedTimezone}
              />
            );
          })}
        </Box>
      </Fade>
    </>
  );
});
