import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import React, {useEffect, useState} from 'react';
import {palette} from 'theme';
import {useHttp} from 'api/core';
import api from 'api';
import {Link, withRouter} from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Button from 'components/core/Button';
import TextField from '@material-ui/core/TextField';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { IconCommentBorderDark } from 'components/core/icons';
import language from "components/language";

const styles = (theme) => ({
    info: {
        fontWeight: 'bold',
        color: 'white',
        '& a': {
            color: palette['cerulean-blue'],
            fontWeight: 'bolder',
        },
    },
    form: {
        width: '100%',
    },
    inputCode:{
        color:theme.palette.primary.main,
        backgroundColor:theme.palette.primary.light
    }
});


const DrawerContainedFeedbackInfo = withStyles(styles)(({event, classes, history, isCollapsed}) => {
    const [feedbackData, setFeedbackData] = useState();
    const [code, setCode] = useState();
    const [getData, data] = useHttp();

    const AnymeetsListItem = withStyles(styles)(({ icon, text}) => {
        return (
            <Box
                display="flex"
                flexDirection="row"
                flexBasis={'100%'}
                width="100%"
                mb={2}
                alignItems="center"
                justifyContent="flex-start"
            >
                {isCollapsed &&  <Box display="flex">{icon}&nbsp;&nbsp;</Box>}
                {!isCollapsed &&<Box display="flex">{text}</Box>}
            </Box>
        );
    });

    useEffect(() => {
        if (event.eventInfo.joined)
            getData(
                api.entities.feedback.getAllFeedback(null, null, {
                    id: event.eventInfo.id,
                })
            );
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data) {
            setFeedbackData(data);
        }
        //eslint-disable-next-line
    }, [data]);

    const handleChange = (e) => {
        setCode(e.target.value);
    };

    // if (!feedbackData||feedbackData&&feedbackData.feedback.length===0) return <></>

    return (
        <>
            <Box
                display="flex"
                className={classes.info}
                p={2}
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Box display="flex" width='100%'>
                    <AnymeetsListItem icon={<IconCommentBorderDark/>} text={language.feedback}/>
                    {!isCollapsed && <Box display="flex" ml={1}>
                        <Tooltip
                            title={
                                <Box fontWeight="bold" fontSize={14}>
                                    {language.aCodeWillBeProvidedDuringTheSession}
                                </Box>
                            }
                        >
                            <InfoIcon fontSize="small"/>
                        </Tooltip>
                    </Box>}
                </Box>

                {!isCollapsed && feedbackData && feedbackData && feedbackData.feedback.length !== 0 && (
                    <Box pt={2}>
                        <List>
                            {feedbackData &&
                            feedbackData.feedback.map((f, i) => {
                                return (
                                    <Link to={`/feedback/${f.code}`}>
                                        <ListItem>
                                            <ListItemText primary={f.title}/>
                                        </ListItem>
                                    </Link>
                                );
                            })}
                        </List>
                    </Box>
                )}
                {!isCollapsed && (
                    <>
                        <Box pt={3}>
                            <TextField
                                variant="outlined"
                                label={language.insertCode}
                                onChange={handleChange}
                                fullWidth
                                className={classes.inputCode}
                            />
                        </Box>
                        <Box pt={1}>
                            <Button
                                fullWidth
                                size="smaller"
                                onClick={() => history.push(`/feedback/${code}`)}
                            >
                                &nbsp; {language.go} &nbsp;
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </>
    );
});

export default withRouter(DrawerContainedFeedbackInfo);
