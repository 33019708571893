import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {useHttp} from 'api/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import moment from 'moment'
import momentTz from 'moment-timezone'
import clsx from 'clsx'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import SendIcon from '@material-ui/icons/Send'
import * as SockJS from 'sockjs-client'
import * as StompJs from 'stompjs'
import debounce from 'lodash.debounce'
import RootRef from '@material-ui/core/RootRef'
import {useAuth} from 'auth/AuthController'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {imageFile} from 'utils'
import {DEV_MODE, SERVICE_URL} from 'config'
import api from 'api'
import {compareAsc} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import language from 'components/language'


const styles = theme => ({
  container: {
    borderRadius: '6px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    border: 'solid 1px #e6e6e6',
    color: palette['greyish-brown'],
    position: 'relative',
    backgroundColor: 'white',
  },
  feed: {
    fontSize: '18px',
    fontWeight: 'bold',
    padding: theme.spacing(1)
  },
  titleBox: {
    border: 'solid 1px #e6e6e6',
    padding:'16px',
    borderRadius: '2px 2px 0px 0px'
  },
  disabled: {
    opacity: 0.5
  },
  chatInput: {
    position: 'absolute',
    width: '100%',
    bottom: '-60px',
  },
  bubbleSent: {
    backgroundColor: '#3894d6',
    padding: '15px',
    borderRadius: '10px 10px 2px 10px',
    color: 'white',
    minWidth: '15px',
    height: 'auto'
    //boxShadow: 'rgba(35, 47, 53, 0.09) 0px 2px 8px 0px'
  },
  bubbleReceived: {
    //backgroundColor: '#005074',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    padding: '15px',
    borderRadius: '10px 10px 10px 2px',
    color: '#000000',
    minWidth: '15px',
    height: 'auto'
    //boxShadow: 'rgba(35, 47, 53, 0.09) 0px 2px 8px 0px'
  },
  userTitle: {
    fontWeight: 'bolder'
  },
  userTimeStamp: {
    fontWeight: 100,
    fontSize: '10px',
    color: '#716b6f'
  },
  userPhoto: {
    display: 'flex',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  }
})


const SocialFeed = withStyles(styles)(({classes, eventId, sessionId}) => {

  const [, , , userInfo] = useAuth()
  const [getHistory, historyData, isLoading] = useHttp()
  const [userId, setUserId] = useState('')
  const [messageReceived, setMessageReceived] = useState([])
  const [currentPage,setCurrentPage] = useState(1)
  const [totalPages,setTotalPages] = useState(0)
  const [messageSend, setMessageSend] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [clientHandler, setClientHandler] = useState({})
  const [feedSize, setFeedSize] = useState('auto')
  const [topReached,setTopReached] = useState(false)
  const [scrollBar, setScrollBar] = useState()
  const [localTimeZone, setLocalTimeZone] = useState()
  const domRef = React.useRef()
  const messageRef = React.useRef()

  const subscriptionTopic = eventId?`/topic/event/${eventId}`:`/topic/online-room/${sessionId}`
  const subscriptionEndpoint = eventId?`/app/event/${eventId}`:`/app/session/${sessionId}`
  const getSocialFeedHistory = eventId?api.entities.events.getSocialFeedHistory:api.entities.sessions.getSocialFeedHistory

  const headers = {
    'Content-Type': 'text/plain',
    'Authorization': `Bearer ${userInfo.access_token}`
  }

  const connectToWs = () => {
    const socket = new SockJS(`${SERVICE_URL}/stompws`)
    const client = StompJs.over(socket)
    if (!DEV_MODE) client.debug = null
    client.connect(headers, onConnected, onError)
    client.reconnect_delay = 2000;

    //TODO: change setClientHandler to a ref
    setClientHandler(client)
  }

  useEffect(() => {
    if (clientHandler.connected === true)
      clientHandler.subscribe(subscriptionTopic, onMessageReceived)
    // eslint-disable-next-line
  }, [clientHandler.connected])

  useEffect(() => {
    return () => {
      if (clientHandler.connected)
        clientHandler.disconnect(null, headers)
    }
    // eslint-disable-next-line
  }, [clientHandler])

  useEffect(() => {
    const timeZone = momentTz.tz.guess()
    const timeZoneDifference = parseInt(moment.tz(timeZone).format('Z'))

    setLocalTimeZone(timeZoneDifference)

    getHistory(getSocialFeedHistory(null, null, {id: eventId?eventId:sessionId, page:currentPage}))


    if (!isLoading) {
      if (userInfo) {
        setUserId(parseInt(getUserId().sub))
        connectToWs()
      }
    }
    // eslint-disable-next-line
  }, [userInfo])

  useEffect(() => {
    if (historyData && historyData.content) {
      setTotalPages(historyData.totalPages)
      if(currentPage > 1) {
        const allMessages = [...messageReceived, ...historyData.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceived(allMessages)
        scrollBar.scrollTop = scrollBar.scrollTop + 10
      }
      else {
        const allMessages = [...historyData.content]
        allMessages.sort((a, b) => compareAsc(parseISO(a.message.sentAt), parseISO(b.message.sentAt)))
        setMessageReceived(allMessages)
      }
    }
    // eslint-disable-next-line
  }, [historyData])

  const resizeFeed = () => {
    // const currentPos = domRef.current.getBoundingClientRect()
    setFeedSize(window.innerHeight - domRef.current.offsetTop - (eventId?230:140) )
  }
  const debounceResizeFeed = debounce(
    resizeFeed,
    200
  )

  function decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  function getUserId() {
    return decodeToken(localStorage.getItem('access_token'))
  }

  useEffect(() => {
    //resize listener
    window.addEventListener('resize', debounceResizeFeed);
    resizeFeed()
    return () => {
      window.removeEventListener('resize', debounceResizeFeed);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {

    if (scrollBar && !topReached)
      scrollBar.scrollTop = scrollBar.scrollHeight
    // eslint-disable-next-line
  }, [messageReceived])

  useEffect(()=>{
    //When user scrolls to top old messages will be fetched
    if(topReached) {
      getHistory(getSocialFeedHistory(null, null, {id: eventId?eventId:sessionId, page:currentPage}))
    }
    // eslint-disable-next-line
  },[topReached])


  const onConnected = () => {
    // on success enable chat input and feed
    setDisabled(false)
  }

  const onError = () => {
    // in case of error disable chat feed and input
    setDisabled(true)
    DEV_MODE&&console.log('error')
  }

  const onMessageReceived = (message) => {

    const newMessage = JSON.parse(message.body)
    setMessageReceived(oldArray => [...oldArray, newMessage])

  }

  const sendMessage = (e) => {
    e.preventDefault()
    if (messageSend.length > 0) {
      clientHandler.send(subscriptionEndpoint, {}, messageSend)
      setMessageSend('')
      scrollBar.scrollTop = scrollBar.scrollHeight
    }
  }

  const messageHandler = (event) => {
    setMessageSend(event.target.value)
  }

  const handleScrollRef = (ref) => {
    setScrollBar(ref)
  }
  const handleOnScroll = (ref) => {
    if(ref.scrollTop === 0)
    {
      //new messages will be fetched from history only if there is another page with data
      if (currentPage !== totalPages)
      {
        setCurrentPage(currentPage+1)
        setTopReached(true)
      }
    }
    else
      setTopReached(false)
  }
  return <>

    <Box>
      <RootRef rootRef={domRef}>
        <Grid item container style={{height: feedSize + 73, minHeight: 300}}
              className={clsx(classes.container, disabled && classes.disabled)}>
          <Grid item xs={12} className={classes.titleBox}>
                {language.socialFeed}
          </Grid>
          <Grid item xs={12}>
            <PerfectScrollbar containerRef={handleScrollRef} onScrollY={handleOnScroll}>
              <Box className={classes.feed} style={{height: feedSize, minHeight: feedSize}}>
                {messageReceived.filter((f)=>f.type!==2).map((msgObjReceived) => {
                  return (
                    <Box key={msgObjReceived.message.id} mb={2} mt={2}>
                      <RootRef rootRef={messageRef}>
                      <Grid container direction={msgObjReceived.sender.id === userId ? 'row-reverse' : 'row'}
                            justify='flex-start' alignItems='flex-end' alignContent={'stretch'}>
                        <Grid item>
                          <Box mt={1} ml={1} mr={1} className={classes.userPhoto}>
                            <img src={imageFile(msgObjReceived.sender.imageId ? msgObjReceived.sender.imageId : '')}
                                 alt={'Users profile'}/>
                          </Box>
                        </Grid>
                        <Grid item xs={10} sm={10} md={10} lg={6}>
                          <Box mb={2} component="div" display="inline">
                            <Typography variant={'subtitle2'}
                                        className={classes.userTitle} align={'left'}
                                        display={'inline'}>{msgObjReceived.sender.name}
                            </Typography>
                          </Box>
                          <Box mb={2} ml={2} component="div" display="inline">
                            <Typography className={classes.userTimeStamp} align={'left'} display={'inline'}>{
                              // format(
                              //   parseISO(msgObjReceived.message.sentAt),
                              //   'hh:mmaa'
                              // )
                              moment(msgObjReceived.message.sentAt).add(localTimeZone,'hours').format('HH:mm')
                            }
                            </Typography>
                          </Box>
                          <Box
                            className={msgObjReceived.sender.id === userId ? classes.bubbleSent : classes.bubbleReceived}>
                            <Typography variant={'subtitle1'}>{msgObjReceived.message.message}</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                      </RootRef>
                    </Box>
                  )
                })}
              </Box>
            </PerfectScrollbar>
          </Grid>
          <Grid item className={classes.chatInput}>
            <form onSubmit={sendMessage}>
              <OutlinedInput variant='filled' fullWidth placeholder={'Type a message...'} endAdornment={
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='send message'
                    onClick={sendMessage}
                    edge='end'
                    disabled={disabled}
                  >
                    <SendIcon/>
                  </IconButton>
                </InputAdornment>
              } onChange={messageHandler} value={messageSend} disabled={disabled}/>
            </form>
          </Grid>
        </Grid>
      </RootRef>
    </Box>
  </>
})


export default SocialFeed
