import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import { useParams, withRouter } from 'react-router-dom'
import PackageSelection from 'components/pages/CreateEvent/0-Packages'
import CreateEventInformation from 'components/pages/CreateEvent/1-Information'
import CreateEventDateTime from 'components/pages/CreateEvent/2-DateTime'
import CreateEventLocation from 'components/pages/CreateEvent/3-Location'
// import CreateEventAccess from 'Components/pages/CreateEvent/4-Access'
import CreateEventLayout from 'components/pages/CreateEvent/5-Layout'
import CreateEventImages from 'components/pages/CreateEvent/6-Images'
import Box from '@material-ui/core/Box'
import CreateEventPublish from 'components/pages/CreateEvent/Published'
import {DATE_DEFAULT_ENDDATE, DATE_DEFAULT_STARTDATE, LOCATION_DEFAULT_MAP_LOCATION} from 'config'
import {useSnackbar} from 'notistack'
import CreateEventTerms from 'components/pages/CreateEvent/8-Terms'
import CreateEventPlan from 'components/pages/CreateEvent/7-Plan'
import { useHttp } from "api/core";
import api from "api";

const styles = theme => ({
  page: {
    backgroundColor: palette['cerulean-blue'],
    minHeight: 'calc(100vh - 128px)'
  },
  navStop: {
    display: 'flex',
    width: '13px',
    height: '13px',
    borderRadius: '100%',
    backgroundColor: palette.aquamarine,
  },
  navLine: {
    display: 'flex',
    width: '22px',
    height: '3px',
    backgroundColor: palette.aquamarine
  },
  current: {
    display: 'flex',
    width: '25px',
    height: '25px',
    borderRadius: '100%',
    backgroundColor: palette.aquamarine,
    border: '3px solid white',
    color: 'white',
    justifyContent: 'center',
    alignContent: 'center',
    fontSize: '20px'
  },
  paging: {
    width: '100%',
  }
})

const CreateEvent = withStyles(styles)(({classes, history}) => {
  const [zone, setZone] = useState("UTC")
  const { enqueueSnackbar } = useSnackbar()
  const [sendRequest, putData, isLoading, hasError] = useHttp();
  const [event, setEvent] = useState({
    "general": {
      "name": "",
      "acronym": "",
      "description": "",
      "category": '',
      "startDate": DATE_DEFAULT_STARTDATE,
      "endDate": DATE_DEFAULT_ENDDATE,
      "online": false,
      "venueLat": LOCATION_DEFAULT_MAP_LOCATION.lat,
      "venueLon": LOCATION_DEFAULT_MAP_LOCATION.lng,
      "venueName": "",
      "venueDetails": "",
      "address": "",
      "zipCode": "",
      "city": "",
      "country": "",
      "onlineStream": 1,
      "eventLanguage": 1,
      "timezoneId": 590,

    },
    "layout": 1,
    "proceedingsAccess" : 1,
    "sessionViewerAccess": 3,
    "access": 1,
    "registration": 1,
    "maxRegistrations": null,
    "subscriptionId": null, // null = free / no plan
    "externalRegistration": null,
    "externalRegistrationUrl": null,
    "externalRegistrationButtonLabel": null,
    "registrationInfoDesc": null,
    "registrationInfoCost": null,
    "registrationInstructions": null,    
    "generateTitlePage": null,
    theme: 1,
    mailList: {
      "name" : '',
      "emails" : []
    }
  })

  const [newEvent, setNewEvent] = useState(null)
  const [visited, setVisited] = useState({0:true})

  const steps = [
    // { path : 'packages', component: PackageSelection },
    { path : 'information', component: CreateEventInformation },
    { path : 'date-and-time' , component: CreateEventDateTime },
    { path : 'location'  , component: CreateEventLocation },
    // { path : 'access' , component: CreateEventAccess },
    { path : 'layout' , component: CreateEventLayout },
    { path : 'images' , component: CreateEventImages },
    { path : 'plan', component: CreateEventPlan },
    { path : 'terms', component: CreateEventTerms },
    { path: 'publish', component: CreateEventPublish }
  ]

  // Forward user to initially requested page, defaults to first step
  let { step=steps[0].path } = useParams();

  const totalPages = steps.length
  let componentPos = steps.map((o)=>o.path).indexOf(step)
  let currentPage = componentPos+1

  useEffect(()=>{
    if (newEvent) {
      if ('id' in newEvent) {
        
        //create content info card
        const payLoad = {
          contentEnumType: 1,
          eventId: newEvent.id,
          label: "Info",
        }
				const formDataObj = new Blob([JSON.stringify(payLoad)], {
					type: "application/json",
        });
        sendRequest(api.entities.manage.addContent(formDataObj, null, { eventId: newEvent.id }, true));

        history.push('/create-event/publish')
      } else {
        enqueueSnackbar('Unexpected Error', {variant: 'error'})
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[enqueueSnackbar, history, newEvent])


  useEffect(()=> {
    setVisited({...visited, [currentPage]:true})
    // eslint-disable-next-line
  },[])

  // If you're not looking at the first page, and you haven't visited the previous one... redirect to first
  // eslint-disable-next-line
  useEffect(()=>{
    if (currentPage!==1&&!visited[currentPage-1]) {
      history.push('/create-event')
    }
    // eslint-disable-next-line
  },[])

  const PageComponent = steps[componentPos].component
  const prevRoute = currentPage!==1?`/create-event/${steps[componentPos-1].path}`:''
  const nextRoute = (currentPage+1!==totalPages&&steps[currentPage])?`/create-event/${steps[currentPage].path}`:''

  const StepCounter = () => {
    return <Box className={classes.paging} display='flex' flexDirection='row' justifyContent='center' alignItems='center' mt={3}>
      {steps.map((step,i)=>{
        return <React.Fragment key={i}>
          {currentPage-1===i?<Box className={classes.current}>{currentPage}</Box>:<Box className={classes.navStop}> </Box>}
          {i<totalPages-1?<Box className={classes.navLine}> </Box>:''}
        </React.Fragment>
      })}
    </Box>
  }

  return (
    <>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" className={classes.page}>
        <Grid item>
          {currentPage !== totalPages ? <StepCounter /> : ""}
          <PageComponent currentPage={currentPage} formData={event} setFormData={setEvent} prevRoute={prevRoute} nextRoute={nextRoute} event={newEvent} setEvent={setNewEvent} zone={zone} setZone={setZone}/>
        </Grid>
      </Grid>
    </>
  );
})

export default withRouter(CreateEvent)
