import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import parseISO from "date-fns/parseISO"
import differenceInMinutes from 'date-fns/differenceInMinutes'
import {IntraTransition} from 'components/core/OpenTok/Components/IntraTransition'
import Emotions from 'components/core/OpenTok/Components/Emotions/Emotions'
import Sponsors from 'components/core/OpenTok/Components/Sponsors'

const styles = theme => ({

})


const ViewerFooter = withStyles(styles)(({classes, sessionState}) => {

  const {hasSponsors} = sessionState

  return <>
    <Grid item>
      {hasSponsors&&<Sponsors {...sessionState} />}
    </Grid>
    <Grid item>
      {(differenceInMinutes(parseISO(sessionState.endDate),new Date())<=5)&&sessionState.roomSessionId&&<IntraTransition eventId={sessionState.eventId} sessionId={sessionState.roomSessionId}/>}
    </Grid>
    <Grid item>
       <Emotions sessionState={sessionState} />
    </Grid>
  </>
})

export default ViewerFooter
