import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import {IconFileBorderDark} from 'components/core/icons'
import ParticipantChipView from 'components/core/ParticipantChipView'
import {palette} from 'theme'

const PresentationBlock = ({classes,sessionState}) =>{
  const {
    presentations
  } = sessionState

  return <>
    {presentations&&presentations.length!==0&&<Grid item xs={12}>
      <Box fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']} mb={2} >Presentations</Box>
      <TreeView
        className={classes.rootRoot}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {presentations.map((presentationObj,i) => {
          return <TreeItem nodeId={`${i}`} key={i} label={<>
            <Box fontWeight='bold' fontSize={14} component={'span'}>
              {presentationObj.title}<Box fontWeight='normal' component={'span'}>{presentationObj.participants.length!==0?'.':''}{presentationObj.participants.map((p,i)=><>{p.title?p.title:''} {p.firstName} {p.lastName} {i!==presentationObj.participants.length-1?',':''}</>)}</Box>
            </Box> </>
          } classes={{ group: classes.group, iconContainer:classes.treeItemIcon, root:classes.root, content: classes.treeItem, selected: classes.treeItemSelected, expanded: classes.treeItemExpanded, label:classes.label}}><>
            <Box p={1} pl={1}>
              {presentationObj.abstract}
            </Box>
            <Box className={classes.participants} pl={1}>
              {presentationObj.participants.map((author,i) => {
                return <Box width={'100%'} key={i}><ParticipantChipView participantObj={author} /></Box>
              })}
            </Box>
            {presentationObj.files&&presentationObj.files.length!==0&&<Box className={classes.participants} pl={1}>
              {presentationObj.files&&presentationObj.files.map((file,i) => {
                return <Box display='inline-block' m={1} key={i}>
                  <Chip
                    avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                    variant="outlined"
                    label={`${file.filename}`}
                    component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                  />
                </Box>
              })}
            </Box>}
          </>
          </TreeItem>
        })}
      </TreeView>
    </Grid>}
    </>
}

export default  PresentationBlock
