import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import {withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import Grid from '@material-ui/core/Grid'
import ConferenceCard from 'components/core/ConferenceCard'
import NoFavorites from 'components/placeholders/NoFavorites'
const styles = theme => ({

})

const Favorites = withStyles(styles)(({classes, history}) => {

  const [getEvents, eventsData] = useHttp()
  const [events, setEvents] = useState()

  useEffect(()=>{
      getEvents(api.entities.events.getBookmarked(null,{}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (eventsData) {
      setEvents(eventsData)
    }
  },[eventsData])

  const refreshList = () => {
    getEvents(api.entities.events.getBookmarked(null,{}))
  }

  return <Page title='Favorites' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Favorites' navigation={{routes:[]}} >
      {events&&<Fade in={true} timeout={TIME_FADE_IN}><Grid container spacing={5} className={classes.eventList}>
        {events.content.map((event,i)=>{
          return event&&<Grid item xs={12} sm={6} key={i}><ConferenceCard list event={event} m={2} refreshList={refreshList}/></Grid>
        })}
      </Grid>
      </Fade>}
      {events&&events.content.length===0&&<NoFavorites />}
    </Adrawer>
  </Page>
})

export default withRouter(Favorites)
