import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette, theme} from 'theme'
import Button from 'components/core/Button'
import {IconAddBorderLight, IconXBorderDark} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import ChipsArray from 'components/core/ChipsArray'
import {FastField, Field, FieldArray, Form, Formik} from 'formik'
import {Checkbox, TextField} from 'formik-material-ui'
import Divider from '@material-ui/core/Divider'
import * as Yup from 'yup'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const styles = theme => ({
  thematicCodesContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noThematicCodesYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
   addBtn: {
    textAlign: 'right'
   },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(3)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4)
  },
  dragGrip: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(3.2),
    width: theme.spacing(5),
    height: theme.spacing(6),
    zIndex: 49
  },
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required'),
  codes: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string()
          .required('This field is required'),
        code: Yup.string()
          .required('This field is required')
      })
    )
})

const CodeEdit = withStyles(styles)(({classes, code, values, arrayHelpers, index, setFieldValue, isSaving, dragHandleProps}) => {

  const handleChangeDiscount = (value, setFieldValue,field) => {
    setFieldValue(field,value)
  }

  return <Grid item container spacing={2} justify='center' alignItems='center' alignContent='center' key={index} style={{position:'relative'}}>
    <Grid item xs={1}>
      <Box className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>
    </Grid>
    <Grid item xs={2}>
        <FormControlLabel control={<FastField type='checkbox' name={`codes[${index}].group`} value={values.codes[index].group} component={Checkbox} label='Group'
                 variant='filled' fullWidth disabled={isSaving} />}
           label='Group' />
    </Grid>
    <Grid item xs={3}>
      <FastField required type='text' name={`codes[${index}].code`} onClick={event => event.stopPropagation()} component={TextField} label='Code'
                 variant='filled' fullWidth disabled={isSaving} />
    </Grid>
    <Grid item xs={6}>
      <FastField required type='text' disabled={isSaving} name={`codes[${index}].label`} onClick={event => event.stopPropagation()} component={TextField} label='Label'
                 variant='filled' fullWidth
                 onChange={(e)=>handleChangeDiscount(e.target.value,setFieldValue,`codes[${index}].label`)}
                 InputProps={{
                   endAdornment: <InputAdornment position='end'>
                     <IconButton aria-label='delete code' onClick={() => arrayHelpers.remove(index)}>
                       <IconXBorderDark/>
                     </IconButton>
                   </InputAdornment>,
                 }}
      />
    </Grid>
    <Grid item xs={12}>
      <Divider/>
    </Grid>
  </Grid>
})

const ThematicCodes = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  const [getData, data] = useHttp()
  const [delRequest, delData] = useHttp()
  const [editingThematicCode, setEditingThematicCode] = useState(undefined)

  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingThematicCode, setDeletingThematicCode] = useState()
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, , hasError] = useHttp()
  const [thematicCodesData, setThematicCodesData] = useState()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [isSaving, setSaving] = useState(false)

  useEffect(()=>{
    getData(api.entities.manage.getThematicCodes(null,null,{id:eventId}))
  },[getData, eventId])

  useEffect(()=>{
    if (data) {
      setThematicCodesData(data)
      if (!editingThematicCode&&editingThematicCode!==null) setEditingThematicCode(data.lists[0]?data.lists[0]:null)
      if (editingThematicCode===null) {
        setEditingThematicCode(data.lists[data.lists.length-1])
      }
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
      setSaving(false)
    }
    // eslint-disable-next-line
  },[data])

  const handleOpen = (bucket) => {
    // setOpenThematicCodeDialog(true)
    if (bucket) {
      setEditingThematicCode(bucket)
    }
  }

  const handleDeleteOpen = (bucket) => {
    setDeleteOpen(true)
    setDeletingThematicCode(bucket.id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingThematicCode) {
      delRequest(api.entities.thematicCodes.delete(null,null,{id:id}))
      setDeletingThematicCode(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted bucket', {variant: 'success'})
      getData(api.entities.manage.getThematicCodes(null,null,{id:eventId}))
      setEditingThematicCode(null)
    }
  },[delData, enqueueSnackbar, eventId, getData])

  useEffect(() => {
    if (formSave) {

      // If existing thematicCode
      if (editingThematicCode) {
        sendRequest(api.entities.thematicCodes.update(formSave, null, {id:editingThematicCode.id}))
      } else { // else add new thematicCode
        sendRequest(api.entities.thematicCodes.addNew({...formSave, eventId: eventId}))
      }

      setFormSave(undefined)
      setSaving(true)
      // setEditingThematicCode()
    }
    // eslint-disable-next-line
  }, [formSave])

  useEffect(()=>{
    if (putData) {
      enqueueSnackbar('Successfully saved bucket', {variant: 'success'})
      getData(api.entities.manage.getThematicCodes(null,null,{id:eventId}))
      setSaving(false)
    }
    // eslint-disable-next-line
  },[putData])

  useEffect(()=>{
    if (hasError) {
      setSaving(false)
    }
  },[hasError])

  const onDragEnd = (r, arrayHelpers,values, setFieldValues) => {
    if (!r.destination) return

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    // arrayHelpers.move(r.source.index,r.destination.index)
    let newArr = [...values.codes]

    array_move(newArr, r.source.index, r.destination.index)
    // newArr.splice(r.source.index,1)
    // newArr.splice(r.destination.index, 0, r.draggableId)
    console.log(newArr)
    setFieldValues('codes', newArr)
  }


  if (!thematicCodesData) return <> </>

  return <>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    ><Box p={3} pt={1}>
      <DialogTitle id='alert-dialog-title'>Delete bucket</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete this bucket?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
        <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
          No
        </Button>
        </Box>
        <Box p={1}>
        <Button onClick={()=>{handleDeleteClose(deletingThematicCode)}}  size='small' autoFocus color='primary'>
          Yes
        </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid container className={classes.thematicCodesContainer} spacing={2}>
        {/* <Grid item xs={12}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Thematic codes
          </Typography>
        </Grid> */}

        <Grid item xs={12}>
          <ChipsArray selectedId={editingThematicCode?editingThematicCode.id:null} entries={thematicCodesData.lists} onClickHandler={handleOpen} onDeleteHandler={handleDeleteOpen} onNewHandler={()=>setEditingThematicCode(null)}/>
          {/*{thematicCodesData.lists.map((bucket,i)=>{*/}
          {/*  return <Grid item>*/}
          {/*    <Chip label={bucket.name} clickable color='primary' onClick={()=>handleOpen(bucket)}/>*/}
          {/*  </Grid>*/}
          {/*})}*/}
        </Grid>

        {/*<Grid container item spacing={2} className={classes.header}>*/}

        {/*  <Grid item xs={12} sm={3} md={3}>*/}
        {/*    <Grid item xs={12} className={classes.fieldTitle}> Code </Grid>*/}
        {/*  </Grid>*/}

        {/*  <Grid item xs={12} sm={3} md={3}>*/}
        {/*    <Grid item xs={12} className={classes.fieldTitle}> Label </Grid>*/}
        {/*  </Grid>*/}

        {/*</Grid>*/}

          <Grid item xs={12} md={12}>
            <ThemeProvider theme={theme}>
              <Formik
                enableReinitialize
                initialValues={{
                  name: '',
                  codes: [], ...editingThematicCode
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                  const newValues = {...editingThematicCode, ...values}
                  setFormSave(newValues)
                }}>
                {({errors, submitForm, setFieldValue, values}) => (
                  <Form className={classes.form}>

                        <Grid container spacing={2} className={classes.row}>
                          <Grid item xs={12}>
                            <Field required type='text' name='name' component={TextField} label='Name' variant='filled'
                                   fullWidth disabled={isSaving}/>
                          </Grid>
                          <Grid item xs={12}>
                            <FieldArray
                              name='codes'
                              render={arrayHelpers => (
                                <>
                                  <DragDropContext onDragEnd={(r)=>onDragEnd(r,arrayHelpers,values,setFieldValue)}>
                                    <Droppable droppableId={`${0}`}>
                                      {(provided) => {
                                        return <Grid item container spacing={2}
                                                     ref={provided.innerRef} {...provided.droppableProps}>
                                          <Grid item xs={12}>
                                            <Divider/>
                                          </Grid>
                                          <Grid item xs={12} key={isSaving}>
                                            {values.codes.length === 0 &&
                                            <Box height={200} justifyContent='center' alignContent='center'
                                                 alignItems='center' display='flex'>
                                              <Box display='flex'>No codes</Box>
                                            </Box>}
                                            {values.codes.length !== 0 && <Grid item>
                                              {values.codes.map((code, index) => <Draggable key={index}
                                                                                            draggableId={`${index}`}
                                                                                            index={index}>
                                                {(provided) => <Box {...provided.draggableProps}
                                                                      ref={provided.innerRef} width={'100%'}><CodeEdit setFieldValue={setFieldValue} code={code}
                                                                          arrayHelpers={arrayHelpers} values={values} index={index} isSaving={isSaving} dragHandleProps={provided.dragHandleProps}/></Box>
                                                }
                                              </Draggable>)}
                                              {provided.placeholder}
                                            </Grid>
                                            }
                                          </Grid>
                                        </Grid>
                                      }}
                                    </Droppable>
                                  </DragDropContext>

                                  <Grid item container spacing={2} justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                                    <Grid item>
                                      <Button size='small' variant='outlined' onClick={() => arrayHelpers.push({ label:'',code:'' })}>
                                        <IconAddBorderLight/> Add Code
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  </>
                              )}
                            />
                          </Grid>

                        </Grid>

                        {/*<Grid container justify='center' alignContent='center' alignItems='center' spacing={2}>*/}
                        {/*  <Grid item xs>*/}
                        {/*    <Grid item container justify='center' alignContent='center' alignItems='center'>*/}
                        {/*      <Grid item>*/}
                        {/*        <Button variant='outlined' size='small' colour='white' onClick={handleClose}*/}
                        {/*                disabled={isSubmitting}> Cancel </Button>*/}
                        {/*      </Grid>*/}
                        {/*    </Grid>*/}

                        {/*  </Grid>*/}
                          <Grid item xs={12}>
                            <Grid item container justify='flex-end' alignContent='center' alignItems='center'>
                              <Grid item>
                                <Box mt={2}>
                                  <Button size='small' type='submit' disabled={isSaving}> Save Thematic codes </Button>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        {/*</Grid>*/}
                  </Form>
                )}
              </Formik>
            </ThemeProvider>

            {/*{editingThematicCode&&editingThematicCode.codes.map((thematicCode,i)=>{*/}
            {/*  // return <ThematicCodeCard thematicCode={thematicCode} key={i} editAction={()=>{handleOpen(thematicCode)}} deleteAction={()=>{handleDeleteOpen(thematicCode.id)}} mb={2}/>*/}
            {/*  return <BucketRow thematicCode={thematicCode} index={i} />*/}
            {/*})}*/}
            {/*{thematicCodesData.lists.length===0&&editingThematicCode!==null?<Grid item container className={classes.noThematicCodesYet}>*/}
            {/* <Grid item>No buckets yet!</Grid>*/}
            {/*</Grid>:''}*/}
            </Grid>
        </Grid>
    </Fade>
  </>
})

export default withRouter(ThematicCodes)
