import { withStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useHttp } from "api/core";
import api from "api";
import Box from "@material-ui/core/Box";
import Fade from "@material-ui/core/Fade";
import { TIME_FADE_IN } from "config";
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const extraStyles = (theme) => ({
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	}
});

export const GeneralPage = withStyles(extraStyles)(
  ({ classes, event, isPreview, canManage, pageId }) =>
  {
    const history = useHistory();
    const [content, setContent] = useState();
    const [getContentData, contentData] = useHttp();
console.log(event)
    useEffect(() => {
      if (isPreview) {
        getContentData(api.entities.manage.getContent(null, null, { eventId: event.eventInfo.id, id: pageId }));
      } else {
        getContentData(api.entities.manage.getContent(null, null, {  eventId: event.eventInfo.id, id: pageId }));
      }

      // eslint-disable-next-line
    }, [pageId]);

    useEffect(() => {
      if (contentData) {
        setContent(contentData);
      }
    }, [contentData]);

    if (!content) {
      return <> </>; // Loading
    }

    if (content && content.length === 0) return <> </>;

    const createMarkup = () => {
      return { __html: content.content };
    };

    return (
      <>
        <Fade in={true} timeout={TIME_FADE_IN}>
          <Box display="flex" p={4} pt={0} pb={12} flexDirection="column">
          <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/general`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Event</Button>}
            </Grid>            
            <Box fontSize={20} fontWeight={"bold"} pb={3}>
              {content.title}
            </Box>
            <Box>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </Box>
          </Box>
        </Fade>
      </>
    );
  }
);

export default GeneralPage;
