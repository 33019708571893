import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import FooterBasic from 'components/core/Footer/FooterBasic'
import FooterEvent from 'components/core/Footer/FooterEvent'

const styles = theme => ({
  appBar: {
    top: 'auto',
    bottom: 0,
    backgroundColor: palette.white,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      justifyContent: 'center',
      justifyItems: 'center',
      alignContent: 'center',
      position: 'absolute'
    }
  }
})

const Footer = withStyles(styles)(({classes, event,isAbsolute}) => {

  return <>
    <AppBar position={isAbsolute === true?'absolute':'fixed'} color='secondary' className={classes.appBar} component={'footer'}>
      <Toolbar>
        {!event &&<FooterBasic/>}
        {event&&<FooterEvent event = {event}/>}
      </Toolbar>
    </AppBar>
  </>
})

export default Footer
