import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import FieldText from 'components/core/FieldText'
import Map from 'components/core/Map'
import React from 'react'
import language from "components/language";

const styles = theme => ({

})

const EventLocation = withStyles(styles)(({classes, event}) => {
  return <>{event.venueLat && <>
    <Box display='flex' p={4} pb={0} flexDirection='column'>
      <Typography component='h2' variant='h6'>{language.location}</Typography>
    </Box>

    <Box display='flex' p={4} pb={1}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.venue} value={event.eventInfo.venueName} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.venueDetails} value={event.venueDetails} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.address} value={event.address} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.zipCode} value={event.zipCode} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.city} value={event.city} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FieldText title={language.country} value={event.country} />
        </Grid>
      </Grid>
    </Box>

    <Box textAlign='center' mt={4} mb={4}>
      <Map width='100%' height='230px' latLng={{lat: event.venueLat, lng: event.venueLon}}
           name={event.eventInfo.venueName}/>
    </Box>
  </>}</>
})

export default EventLocation
