import React, {useRef, useState} from 'react'
import FormControl from '@material-ui/core/FormControl'
import {FilledInput, InputLabel} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Cancel'

export const SearchInput = ({doSearch, label='Search...', timeOut=620, charLengthSearch=2}) => {
  const [searchValue, setSearchValue] = useState('')
  const searchTimeout = useRef()

  const setSearch = (e) => {
    const value = e.target.value
    setSearchValue(value)
    clearTimeout(searchTimeout.current)

    if (value.length>=charLengthSearch||value.length===0) {
      searchTimeout.current = setTimeout(()=>{
        doSearch(value)
      },timeOut)
    }

  }

  return <FormControl variant="filled" style={{width:'100%'}}>
    <InputLabel htmlFor="filled-adornment-search">{label}</InputLabel>
    <FilledInput
      id="filled-adornment-search"
      type={'text'}
      value={searchValue}
      onChange={setSearch}
      fullwidth
      endAdornment={
        searchValue.length!==0&&<InputAdornment position="end">
          <IconButton
            aria-label="Clear"
            onClick={()=>setSearch({target:{value:''}})}
            onMouseDown={()=>setSearch({target:{value:''}})}
            edge="end"
          >
            <CancelIcon />
          </IconButton>
        </InputAdornment>
      }
    />
  </FormControl>

}
