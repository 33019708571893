import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { palette } from "theme";
import { withRouter } from "react-router-dom";
import { useMetaData } from "components/core/MetaProvider";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
//import EventsIcon from "assets/Events_icon.png";
//import FlexibleIcon from "assets/Flexible_icon.png";
//import MetricsIcon from "assets/Metrics_icon.png";
//import SupportIcon from "assets/Support_icon.png";
import BottomHero from "components/core/BottomHero";
import InfoTabimg from "assets/info.png";
import SpeakersTabimg from "assets/speakers.png";
import SponsorsTabimg from "assets/sponsors.png";
import AgendaTabimg from "assets/agendatab.png";
import HotelsTabimg from "assets/restaurants-hotels.png";
import CustomTabimg from "assets/custom-content.png";



// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = (theme) => ({
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: '1.2em',
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "38px",
			padding: theme.spacing(1),
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#3d4d59",
		fontSize: "36px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "34px",
			padding: theme.spacing(3),
		},
	},
	subtitle: {
		maxWidth: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "20px",
		textAlign: "center",
		color: "#4a4a4a",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: "768px",
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "16px",
		fontFamily: "Karla",
		textTransform: "initial",
		lineHeight: "16px",
		zIndex: "1",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		textTransform: "initial",
		lineHeight: "14px",
	},
	testimonialsText: {
		width: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "22px",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
		borderRadius: "20px",
		boxShadow: "0px 0px 50px 0px #ddd",
	},
	pageContent: {
		marginTop: "50px",
	},
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 0px",
			marginTop: "25px",
		},
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		minHeight: "100px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			width: "300px",
			margin: "10px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
		marginTop: "0px",
		color: "#000",
		textTransform: "initial"
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	MuiTabsResp: {
		display: "inline-block",
	},
});

const Conference = withStyles(styles)(({ classes, history }) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({ title: "Conference" });
		// eslint-disable-next-line
	}, []);

	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item className={classes.MuiTabsResp}>Easily set up a webpage for your event</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Use our intuitive dashboard to include all the information your
						attendees need.
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Box style={{width: "100%", typography: "body1"}}>
						<TabContext value={value} style={{width: "100%"}}>
							<Box style={{borderBottom: 0, borderColor: "divider"}}>
								<TabList
									style={{backgroundColor: "#efefef", borderRadius: "5px"}}
									onChange={handleChange}
									aria-label="Easily set up a webpage for your event"
									TabIndicatorProps={{
										style: {
											marginLeft: "10px",
											width: "140px",
											background: "#fff",
											height: "38px",
											top: "5px",
										},
									}}
								>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>Info</span>
											</React.Fragment>
										}
										value="1"
									/>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>Speakers</span>
											</React.Fragment>
										}
										value="2"
									/>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>Sponsors</span>
											</React.Fragment>
										}
										value="3"
									/>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>Agenda</span>
											</React.Fragment>
										}
										value="4"
									/>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>
													Restaurants <br />
													and Hotels
												</span>
											</React.Fragment>
										}
										value="5"
									/>
									<Tab
										label={
											<React.Fragment>
												<span className={classes.tabTitle}>
													Custom <br />
													Content
												</span>
											</React.Fragment>
										}
										value="6"
									/>
								</TabList>
							</Box>
							<TabPanel value="1">
								<img
									src={InfoTabimg}
									className={classes.tabImages}
									alt="Anymeets Intuitive interface, no need to for training"
								/>
							</TabPanel>
							<TabPanel value="2">
								<img
									src={SpeakersTabimg}
									className={classes.tabImages}
									alt="Anymeets Our algorithms help with reviewing and program creation"
								/>
							</TabPanel>
							<TabPanel value="3">
								<img
									src={SponsorsTabimg}
									className={classes.tabImages}
									alt="Anymeets Stream or record your conference"
								/>
							</TabPanel>
							<TabPanel value="4">
								<img
									src={AgendaTabimg}
									className={classes.tabImages}
									alt="Anymeets Stream or record your conference"
								/>
							</TabPanel>
							<TabPanel value="5">
								<img
									src={HotelsTabimg}
									className={classes.tabImages}
									alt="Anymeets Stream or record your conference"
								/>
							</TabPanel>
							<TabPanel value="6">
								<img
									src={CustomTabimg}
									className={classes.tabImages}
									alt="Anymeets Stream or record your conference"
								/>
							</TabPanel>
						</TabContext>
					</Box>
				</Grid>
				{/* <Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Box>
						<button className={classes.buttonTry}>Try now</button>
					</Box>
				</Grid> */}
			</Grid>

			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.secondaryTitle}
				>
					<Grid item>Why Anymeets</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Branded event page</h3>
								<p>
									Use your own URL to ensure that your event page is easily
									found and personalized to your needs.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>User-friendly dashboard</h3>
								<p>
									You don’t need to be a software engineer to run an event with
									Anymeets! Our dashboard is self-explanatory and easy to use.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>
									Speakers and keynote speakers
								</h3>
								<p>
									Add photos, bios, affiliations and contact information for
									your speakers.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>One website – one login</h3>
								<p>
									Offer all the information that your event attendees need in one
									place and cut down on constant logins and forgotten passwords.
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter(Conference);
