import { Field} from "formik";
import { Switch} from "formik-material-ui";
import { withStyles } from "@material-ui/core/styles";
import {useParams, withRouter } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useHttp } from "api/core";
import Grid from "@material-ui/core/Grid";
import api from "../../../api";
import Box from "@material-ui/core/Box";
import Button from "../../core/Button";
import Dialog from "@material-ui/core/Dialog";
import FormEditNew from "components/core/Forms/FormEditNew";
import FormPreview from "components/core/Forms/FormPreview";
import CommunicationEditNew from "components/core/CommunicationEditNew";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  section: {
    paddingBottom: theme.spacing(4),
    maxWidth: "1200px",
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1rem",
    // marginLeft: "10px",
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(3),
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4),
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
});

const ReviewerInvites = withStyles(styles)(
  ({ classes, setPageTitle, setEventInfo, setNavBack }) => {
    const [getData, data] = useHttp();
    const [reqInvites, , invitesLoading, invitesHasError] = useHttp();
    const [reqInvitesDel, , invitesLoadingDel, invitesHasErrorDel] = useHttp();

    // eslint-disable-next-line
    const [reviewerInvitesData, setReviewerInvitesData] = useState();
    const [formEdit, setFormEdit] = useState(false);
    const [formPreview, setFormPreview] = useState(false);
    const [editCommunication, setEditCommunication] = useState(false);
    const [typeOfEmail, setTypeOfEmail] = useState(false);

    const { eventId } = useParams();

    useEffect(() => {
      getData(api.entities.manage.getFlow(null, null, { id: eventId }));
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (data) {
        setReviewerInvitesData(data);
        setPageTitle(data.eventInfo.name);
        setNavBack({ path: "/manage-events", name: "events" });
        setEventInfo(data.eventInfo);
      }
      // eslint-disable-next-line
    }, [data]);

    const handleCloseFormEdit = (reload) => {
      setFormEdit(false);
      if (reload) {
        getData(api.entities.manage.getFlow(null, null, { id: eventId }));
      }
    };

    const handleCloseFormPreview = (reload) => {
      setFormPreview(false);
      if (reload) {
        getData(api.entities.manage.getFlow(null, null, { id: eventId }));
      }
    };

    const setInvites = (toggle) => {
      if (toggle) {
        reqInvites(
          api.entities.manage.setFlowOpen(null, null, { id: eventId })
        );
        setReviewerInvitesData({ ...reviewerInvitesData, open: true });
      } else {
        reqInvitesDel(
          api.entities.manage.delFlowOpen(null, null, { id: eventId })
        );
        setReviewerInvitesData({ ...reviewerInvitesData, open: false });
      }
    };

    const setOpen = () => {
      if (!reviewerInvitesData.open) {
        reqInvites(
          api.entities.manage.setFlowOpen(null, null, { id: eventId })
        );
        setReviewerInvitesData({ ...reviewerInvitesData, open: true });
      }
    };

    useEffect(() => {
      if (invitesHasError) {
        setReviewerInvitesData({ ...reviewerInvitesData, open: false });
      }
      // eslint-disable-next-line
    }, [invitesHasError]);

    useEffect(() => {
      if (invitesHasErrorDel) {
        setReviewerInvitesData({ ...reviewerInvitesData, open: true });
      }
      // eslint-disable-next-line
    }, [invitesHasErrorDel]);

    const handleEditCloseCommunication = () => {
      setEditCommunication(false);
      setTypeOfEmail(false);
    };

    if (!reviewerInvitesData) return <> </>;

    return (
      <>
          <Grid item xs={12} md={2}>
            <Typography component="h2" variant="h6" className={classes?.title}>
            Reviewer invitation
            <Tooltip classes={{tooltip: classes.tooltip}} title="Enable invitation of the reviewers" placement="top" arrow>
              <InfoIcon style={{ fontSize: "22px", marginLeft: "0px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
            </Tooltip>            
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Field
              type="checkbox"
              value={reviewerInvitesData.open}
              onClick={() => setInvites(!reviewerInvitesData.open)}
              component={Switch}
              disabled={invitesLoading || invitesLoadingDel}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Typography
              component="h2"
              variant="h6"
              className={classes?.subtitle}
              mt={4}
            >
            Reviewer invitation form
            <Tooltip classes={{tooltip: classes.tooltip}} title="Customize reviewer invitation form" placement="top" arrow>
              <InfoIcon style={{ fontSize: "22px", marginLeft: "3px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
            </Tooltip>            
            </Typography>
          </Grid>
          <Grid item container xs={12} md={10}>
            <Grid item>
              <Box mr={2}>
                <Button size="small" onClick={() => setFormEdit(true)}>
                  {" "}
                  Edit{" "}
                </Button>
              </Box>
              <Dialog
                onClose={handleCloseFormEdit}
                open={formEdit}
                fullWidth
                maxWidth="md"
              >
                <FormEditNew
                  handleClose={handleCloseFormEdit}
                  eventId={eventId}
                  forms={{
                    acceptForm: reviewerInvitesData.acceptForm,
                    rejectForm: reviewerInvitesData.rejectForm,
                  }}
                  message={reviewerInvitesData.message}
                />
              </Dialog>
            </Grid>
            <Grid item>
              <Box mr={2}>
                <Button size="small" onClick={() => setFormPreview(true)}>
                  {" "}
                  Preview{" "}
                </Button>
                <Dialog
                  onClose={handleCloseFormPreview}
                  open={formPreview}
                  fullWidth
                  maxWidth="md"
                >
                  <FormPreview
                    message={reviewerInvitesData.message}
                    handleClose={handleCloseFormPreview}
                    eventId={eventId}
                    forms={{
                      acceptForm: reviewerInvitesData.acceptForm,
                      rejectForm: reviewerInvitesData.rejectForm,
                    }}
                  />
                </Dialog>
              </Box>
            </Grid>
          </Grid>

        <Dialog
          onClose={handleEditCloseCommunication}
          open={editCommunication}
          fullWidth
          maxWidth="md"
        >
          <CommunicationEditNew
            setSuccessful={setOpen}
            handleClose={handleEditCloseCommunication}
            eventId={eventId}
            eventInfo={data.eventInfo}
            defaultGroup={typeOfEmail}
          />
        </Dialog>
      </>
    );
  }
);

export default withRouter(ReviewerInvites);
