import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { palette, theme } from "theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { IconNotesBorderLight, IconXBorderLight } from "components/core/icons";
import Box from "@material-ui/core/Box";
import { CONTENT_LIST } from "config";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: palette["cerulean-blue"],
    boxShadow:
      "0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
    color: "white",
    borderRadius: "6px",
    position: "relative",
    // maxWidth: "860px",
    "& a": {
      color: "white",
    },
  },
  plain: {
    backgroundColor: "white",
    color: palette["greyish-brown"],
    "& a": {
      color: palette["greyish-brown"],
    },
  },
  fieldTitle: {
    fontSize: "12px",
    // fontWeight: "bold",
  },
  fieldValue: {
    fontSize: "18px",
    overflowWrap: "break-word",
    "& > a": {
      color: "white",
      maxWidth: "90%",
    },
  },
  fieldType: {
    fontSize: "14px",
    marginTop: "5px",
    overflowWrap: "break-word",
    "& > a": {
      color: "white",
      maxWidth: "90%",
    },
  },
  editIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(6),
    cursor: "pointer",
    // [theme.breakpoints.down(1180)]: {
    // 	top: theme.spacing(6),
    // 	right: theme.spacing(2),
    // },
  },
  deleteIcon: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2),
    cursor: "pointer",
  },
});

const FieldText = withStyles(styles)(
  ({ title, value, url, classes, ellipsis, size, classStyle }) => {
    return (
      <Box display="flex" flexDirection="column" justifyContent="center" pr={1}>
        <Box
          display="flex"
          justifyContent={classStyle ? "right" : "left"}
          className={classes.fieldTitle}
        >
          {" "}
          {title}{" "}
        </Box>
        <Box
          display="flex"
          justifyContent={classStyle ? "right" : "left"}
          className={classStyle ? classes.fieldType : classes.fieldValue}
        >
          {value}
        </Box>
      </Box>
    );
  }
);

const ContentCard = withStyles(styles)(
  ({
    classes,
    dragHandleProps,
    content,
    index,
    plain,
    editAction,
    deleteAction,
    ...props
  }) => {
    return (
      <Box className={classes.ContentCard} {...props} key={index}>
        <ThemeProvider theme={theme}>
          <Box
            p={1}
            className={clsx(classes.container, plain && classes.plain)}
          >
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <Box display="flex">
                <Box mr={1} className={classes.dragGrip} {...dragHandleProps}>
                  <DragIndicatorIcon />
                </Box>
              </Box>

              <Box width={"100%"}>
                <Grid item container xs={12} direction="row">
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldText  value={content.label} />
                  </Grid>

                  <Grid item xs={12} sm={5} md={5}>
                    <FieldText                      
                      value={CONTENT_LIST[content.contentEnumType - 1].label}
                      classStyle={true}
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                display="flex"
                // pt={1}
                width={50}
                justifySelf="flex-end"
                justifyContent="flex-end"
              >
                <IconNotesBorderLight
                  title="Edit section"
                  className={classes.editIcon}
                  onClick={() => {
                    editAction && editAction(content.id);
                  }}
                />
                <IconXBorderLight
                  title="Delete section"
                  className={classes.deleteIcon}
                  onClick={() => {
                    deleteAction && deleteAction(content.id);
                  }}
                />
              </Box>
            </Box>
          </Box>
        </ThemeProvider>
      </Box>
    );
  }
);

export default ContentCard;
