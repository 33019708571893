import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import Page from "components/core/Page";
import { palette } from "theme";
import { useHttp } from "api/core";
import api from "api";
import { Redirect, useParams, Link, withRouter } from "react-router-dom";
import Adrawer from "components/core/Drawer";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import { TIME_FADE_IN } from "config";
import Button from "components/core/Button";
import List from "@material-ui/core/List";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {
  IconCheckCircleBorderDark,
  IconDollarSignBorderDark,
  IconHelpCircleBorderDark,
  IconInfoBorderDark,
  IconUserBorderDark,
} from "components/core/icons";

const styles = (theme) => ({
  eventList: {
    maxWidth: "1280px",
    // [theme.breakpoints.up('sm')]: {
    //   paddingTop: theme.spacing(3)
    // }
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  subtitle: {
    fontSize: "19px",
    fontWeight: "700",
    margin: "10px 0",
  },
  contact: {
    fontSize: "17px",
    fontWeight: "500",
    margin: "50px 0",
  },
});

const Dashboard = withStyles(styles)(({ classes, history }) => {
  const [getEvents, eventsData] = useHttp();
  const [events, setEvents] = useState();
  const { eventType } = useParams();

  useEffect(() => {
    setEvents(undefined);
    switch (eventType) {
      case "live":
        getEvents(api.entities.events.getPresent(null, {}));
        break;
      // case 'upcoming':
      //   getEvents(api.entities.events.getFuture(null,{}))
      //   break
      case "past":
        getEvents(api.entities.events.getPast(null, {}));
        break;
      // case 'draft':
      //   getEvents(api.entities.events.getUpcoming(null,{}))
      //   break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventType]);

  useEffect(() => {
    if (eventsData) {
      setEvents(eventsData);
    }
  }, [eventsData]);

  const navigation = {
    routes: [
      // { path: "/create-event", name: "Create new event" },
      // { path: "/select-package", name: "Create new event" },
      { path: "/joined-events", name: "Joined events" },
      { path: "/manage-events", name: "Manage events" },
      // { path: "/search", name: "Search an event" },
      // { path: "/my-tickets", name: "My tickets" },
      // { path: "/favorites", name: "Favorites" },
      // { path: "/profile/settings", name: "Profile" },
      { path: "/sign-out", name: "Sign Out" },
    ],
  };

  return (
    <Page
      title="Dashboard"
      display="flex"
      backgroundColor={palette["pale-grey"]}
    >
      <Adrawer
        title="Dashboard"
        mobileTitle="Dashboard"
        navigation={navigation}
      >
        <Fade in={true} timeout={TIME_FADE_IN}>
          <Box>
            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={5}
              className={classes.eventList}
              alignItems={"stretch"}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  component="h2"
                  variant="h5"
                  className={classes.title}
                >
                  Welcome to Anymeets
                </Typography>
                <Typography component="p" variant="p">
                  Last updated: March 2023
                </Typography>
                <br />
                <Typography
                  component="p"
                  variant="p"
                  className={classes.subtitle}
                >
                  What's new about Anymeets?
                </Typography>

                <List dense={true}>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconCheckCircleBorderDark />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#000",
                          fontSize: "16px",
                          textTransform: "initial",
                          fontWeight: "500",
                        },
                      }}
                      secondaryTypographyProps={{ style: { color: "#000" } }}
                      primary="Introduced new payment system"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconCheckCircleBorderDark />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#000",
                          fontSize: "16px",
                          textTransform: "initial",
                          fontWeight: "500",
                        },
                      }}
                      secondaryTypographyProps={{ style: { color: "#000" } }}
                      primary="Users can create their own event"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconCheckCircleBorderDark />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#000",
                          fontSize: "16px",
                          textTransform: "initial",
                          fontWeight: "500",
                        },
                      }}
                      primary="Event menu supports customization"
                    />
                  </ListItem>
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconCheckCircleBorderDark />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#000",
                          fontSize: "16px",
                          textTransform: "initial",
                          fontWeight: "500",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          color: "#000",
                          marginLeft: "20px",
                          textTransform: "initial",
                        },
                      }}
                      primary={`Change paper acceptance status of multiple submissions `}
                    />
                  </ListItem>{" "}
                  <ListItem disableGutters>
                    <ListItemIcon>
                      <IconCheckCircleBorderDark />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          color: "#000",
                          fontSize: "16px",
                          textTransform: "initial",
                          fontWeight: "500",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          color: "#000",
                          marginLeft: "20px",
                          textTransform: "initial",
                        },
                      }}
                      primary={`Fixes of minor issues with the previous release`}
                    />
                  </ListItem>{" "}
                </List>
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={5}
              className={classes.eventList}
              alignItems={"stretch"}
            >
              <Grid item xs={12} sm={12}>
                <Typography
                  component="p"
                  variant="p"
                  className={classes.contact}
                >
                  For more information please contact anymeets support at <a href="mailto:info@anymeets.com" target="_blank">info@anymeets.com</a>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Adrawer>
    </Page>
  );
});

export default withRouter(Dashboard);
