import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { palette } from "theme";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Simplyfy from "assets/simplify-the-complex.gif";
import Expandyourreach from "assets/expand-your-reach.png";
import SaveTime from "assets/save-time.gif";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

const styles = (theme) => ({
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "800px",
		color: "#666666",
		fontSize: "28px",
		textAlign: "center",
		fontWeight: "normal",
		lineHeight: "1.2em",
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(1),
			marginBottom: 0,
		},
	},
	subtitle: {
		maxWidth: "800px",
		color: "#3d4d59",
		fontSize: "45px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "1.2em",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "38px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "21px",
		fontFamily: "Karla",
		letterSpacing: "-1.05px",
		textTransform: "initial",
		lineHeight: "31px",
		marginTop: "6px",
		zIndex: "1",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		letterSpacing: "-0.75px",
		textTransform: "initial",
		lineHeight: "14px",
		zIndex: "1",
	},
	testimonialsText: {
		width: "535px",
		margin: "64px auto 10px",
		padding: "20px 5px 20px 5px",
		fontFamily: "Karla",
		fontSize: "36px",
		letterSpacing: "-1.8px",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		textAlign: 'center',
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#0078ad",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #0078ad",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#0990b1",
			border: "1px solid #0990b1",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
		boxShadow: "0px 0px 50px 0px #ddd",
		borderRadius: "20px",
	},
});

const HomeTopTabs = withStyles(styles)(({ classes }) => {
	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Grid container direction="column" justify="center" alignItems="center">
			<Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.subtitle}
			>
				<Grid item>Why choose Anymeets?</Grid>
			</Grid>

			<Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				className={classes.hero}
			>
				<Box style={{ width: "100%", typography: "body1" }}>
					<TabContext value={value} style={{ width: "100%" }}>
						<Box style={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                style={{backgroundColor: '#efefef', borderRadius: '5px', height: '93px'}}
								onChange={handleChange}
                aria-label="why choose anymeets one"
                TabIndicatorProps={{ style: { background: "#f0f9ff", height: 'calc(100% - 5px)', top: '0px', borderBottom: '5px solid #0078ad'} }}
							>
								<Tab
									label={
										<React.Fragment>
											<span className={classes.tabTitle}>Save Time</span>
											<span className={classes.tabDescription}>
												Intuitive interface,
												<br />
												no need for training
											</span>
										</React.Fragment>
									}
									value="1"
									style={{ minWidth: "33.33333%" }}
								/>
								<Tab
									label={
										<React.Fragment>
											<span className={classes.tabTitle}>
												Simplify the Complex
											</span>
											<span className={classes.tabDescription}>
												Our algorithms help with reviewing <br />
												and program creation{" "}
											</span>
										</React.Fragment>
									}
									value="2"
									style={{ minWidth: "33.33333%" }}
								/>
								<Tab
									label={
										<React.Fragment>
											<span className={classes.tabTitle}>
												Expand your Reach
											</span>
											<span className={classes.tabDescription}>
												Stream or record <br />
												your conference
											</span>
										</React.Fragment>
									}
									value="3"
									style={{ minWidth: "33.33333%" }}
								/>
							</TabList>
						</Box>
						<TabPanel value="1">
							<img
								className={classes.tabImages}
								src={SaveTime}
								alt="Anymeets Intuitive interface, no need to for training"
							/>
						</TabPanel>
						<TabPanel value="2">
							<img
								className={classes.tabImages}
								src={Simplyfy}
								alt="Anymeets Our algorithms help with reviewing and program creation"
							/>
						</TabPanel>
						<TabPanel value="3">
							<img
								className={classes.tabImages}
								src={Expandyourreach}
								alt="Anymeets Stream or record your conference"
							/>
						</TabPanel>
					</TabContext>
				</Box>
			</Grid>
			<Grid
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
			>
				<Box>
					{/* <span className={classes.testimonialsText}>
						<span className="text-style-1">“</span>I was able to double my client
						base after migrating to Anymeets.<span className="text-style-2">”</span>
					</span>
					<span className={classes.testimonialsName}>
						Ashley Madden, Helianthus Advising, LLC
					</span> */}
					{/* <Button component={Link} to={`/create-event`} className={classes.buttonTry}>Create your Event</Button> */}
					{/* <Button component={Link} to={`/select-package`} className={classes.buttonTry}>Create your Event</Button> */}
				</Box>
			</Grid>
		</Grid>
	);
});

export default HomeTopTabs;
