import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import {useSnackbar} from 'notistack'

import CardSection from './CardSection';
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import {DEV_MODE} from 'config'

export default function CheckoutForm({clientSecret, handleClose, setDisabled, disabled, setComplete}) {
  const stripe = useStripe();
  const elements = useElements();
  const {enqueueSnackbar} = useSnackbar();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setDisabled(true);

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (DEV_MODE) console.log(result);

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      enqueueSnackbar(result.error.message, {variant: "error"});
      setDisabled(false);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        handleClose(true);
        setComplete(true);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          Your credit card details:
        </Grid>

        <Grid item xs={12}>
          <CardSection />
        </Grid>

        <Grid item container justify="flex-end">
          <Grid item>
            <Button type="submit" color="secondary" variant="contained" disabled={!stripe || disabled}>
              Confirm order
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}
