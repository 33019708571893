import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import ExtraCard from 'components/core/ExtraCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import ExtraEditNew from 'components/core/ExtraEditNew'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import { TIME_FADE_IN } from 'config'
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

const styles = theme => ({
  extrasContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  noExtrasYet:{
    backgroundColor: 'white',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  addBtn: {
    textAlign: 'right'
  }
})

const Extras = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  // Hooks
  const [getData, data] = useHttp()
  const [delRequestExtra, delDataExtra] = useHttp()

  // State
  const [extras, setExtras] = useState()
  const [sendData] = useHttp();
  const [openExtraDialog, setOpenExtraDialog] = useState(false)
  const [editingExtra, setEditingExtra] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingExtra, setDeletingExtra] = useState()

  // Deps
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getData(api.entities.manage.getExtras(null,null,{id:eventId}))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setExtras(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
    }
  },[data, setEventInfo, setNavBack, setPageTitle])

  const handleClose = (closeInfo) => {
    setOpenExtraDialog(false)
    if (closeInfo.shouldReload) {
      switch (closeInfo.type) {
        case 'extra' :
          getData(api.entities.manage.getExtras(null,null,{id:eventId}))
          break
        default:
      }
    }
  }

  const handleOpen = (extra,type) => {
    setOpenExtraDialog({type})
    if (extra) {
      setEditingExtra(extra)
    } else {
      setEditingExtra(undefined)
    }
  }

  const handleDeleteOpen = (delInfo) => {
    setDeleteOpen(true)
    setDeletingExtra(delInfo)
  }

  const handleDeleteClose = (delInfo) => {
    setDeleteOpen(false)
    if (delInfo && delInfo.type===deletingExtra.type && delInfo.id === deletingExtra.id) {

      switch (delInfo.type) {
        case 'extra':
          delRequestExtra(api.entities.extras.delete(null,null,{id:delInfo.id}))
          break
        default:
      }

      setDeletingExtra(undefined)
    }
  }

  useEffect(()=>{
    if (delDataExtra) {
      enqueueSnackbar('Successfully deleted extra', {variant: 'success'})
      getData(api.entities.manage.getExtras(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[delDataExtra])

    const onDragEnd = (r) => {
      if (!r.destination) return;

      function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      // arrayHelpers.move(r.source.index,r.destination.index)
      let newArr = [...extras.extras];

      array_move(newArr, r.source.index, r.destination.index);

      setExtras({extras: newArr});

      const newArrOrder = [...newArr.map((sF) => sF.id)];

      sendData(
        api.entities.extras.reorderExtras(newArrOrder, null, {
          eventId: eventId,
        }),
      );
    };  

  if (!extras) return <> </>

  return <>
    <Dialog onClose={handleClose} open={openExtraDialog.type==='extra'} maxWidth='md' fullWidth>
      <ExtraEditNew extra={editingExtra} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} />
    </Dialog>

    <Dialog
      open={deleteOpen}
      onClose={handleDeleteClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete extra</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete this {deletingExtra?deletingExtra.type:'entry'}?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={()=>handleDeleteClose()}  size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={()=>{handleDeleteClose(deletingExtra)}}  size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>
    <Fade in={true} timeout={TIME_FADE_IN}><Box>

      <Grid container className={classes.extrasContainer} spacing={2}>
        <Grid item xs={12}>
          <Typography component='h2' variant='h5' className={classes.title}>
            Extras
          </Typography>
        </Grid>

        <Grid item xs={10}>
          {/* {extras.extras.map((extra,i)=>{
            return <ExtraCard extra={extra} key={i} editAction={()=>{handleOpen(extra,'extra')}} deleteAction={()=>{handleDeleteOpen({type:'extra',id:extra.id})}} mb={2}/>
          })} */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${0}`}>
                  {(provided) => {
                    return (
                      <Grid item container spacing={1} ref={provided.innerRef} {...provided.droppableProps}>
                        {extras.extras.map((extra, index) => (
                          <Draggable key={index} draggableId={`${index}`} index={index}>
                            {(provided) => (
                              <Grid item xs={12} {...provided.draggableProps} ref={provided.innerRef} width={"100%"}>
                                <ExtraCard
                                  dragHandleProps={provided.dragHandleProps}
                                  extra={extra}
                                  key={index}
                                  editAction={() => {
                                    handleOpen(extra, "extra");
                                  }}
                                  deleteAction={() => {
                                    handleDeleteOpen({type: "extra", id: extra.id});
                                  }}
                                  mb={0}
                                />
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Grid>
                    );
                  }}
                </Droppable>
              </DragDropContext>          
          {extras.extras.length===0?<Grid item container className={classes.noExtrasYet}>
            <Grid item> No extras yet! </Grid>
          </Grid>:''}
        </Grid>
        <Grid item xs={12} className={classes.addBtn}>
          <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={()=>{handleOpen(undefined,'extra')}}>Add Extra</Button>
        </Grid>
      </Grid>

      </Box>
    </Fade>
  </>
})

export default withRouter(Extras)
