import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import {palette, theme} from 'theme'
import Badge from '@material-ui/core/Badge'

const styles = theme => ({

})


const StyledTabs = withStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    // marginRight: theme.spacing(1),
    margin: 0,
    padding: 0,
    '&:focus': {
      opacity: 1,
    },
    '&:first-child': {
      minWidth: 100
    }
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 4,
    bottom: 6,
    '& > span': {
      borderRadius: '2px',
      display: 'block',
      maxWidth: 30,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    minHeight: theme.spacing(6),
    // minWidth: 114,
    minWidth: 'auto',
    padding: 0,
    margin: 0,
    // paddingLeft: theme.spacing(0.2),
    // paddingRight: theme.spacing(0.2),
    color: palette['brown-grey'],
    '&:hover': {
      // backgroundColor: 'rgba(29, 161, 242, 0.1)',
      '& $wrapper': {
        color: theme.palette.primary.main,
      },
    },
    '&$selected': {
      '& *': {
        color: theme.palette.primary.main,
      },
    },
  },
  selected: {},
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
    color: palette['brown-grey'],
  }
}))((props) => <Tab disableRipple {...props} />);


const Navigation = withStyles(styles)(({classes, setTab, tab, sessionState,hasChatMessage}) => {

  const {
    isCoach,
    hasChairControls,
    pollsActive,
    qaActive,
    chatActive,
    hasQnA,
    hasPolls,
    hasChat,
    hasInfo,
    customTabs
  } = sessionState

  return <StyledTabs
        variant='fullWidth'
        value={tab}
        onChange={(e,newValue)=>setTab(newValue)}
        scrollButtons='auto'
      >
    {hasChairControls&&<StyledTab label={`${isCoach?'Coach':'Chair'} Control`} style={{minWidth:90}} value='controls' key={'controls'}/>}
    {hasInfo&&<StyledTab label="Info"  value='info' key={'info'}/>}
    {hasPolls&&<StyledTab disabled={pollsActive===false} label="Polls"  value='polls' key={'polls'}/>}
    <StyledTab label="Audience" style={{minWidth:70}} value='audience' key={'audience'}/>}
    {hasQnA&&<StyledTab disabled={qaActive===false} label="Q & A" value='qna' key={'qna'}/>}
    {hasChat&&<StyledTab disabled={chatActive===false} label={ <Badge color="secondary" variant="dot" invisible={!hasChatMessage}>Chat</Badge>}  value='chat' key={'chat'}/>}
    {customTabs.map((tab,i)=>{
      return <StyledTab label={tab.label}  value={`custom-tab-${i}`} key={i} />
    })}
      </StyledTabs>
})

export default Navigation
