import React, {useState,useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'
import {format} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import {
  IconArrowDownBorderDark, IconFileBorderDark, IconMapPinBorderGray,
} from 'components/core/icons'
import GoingToggle from 'components/core/GoingToggle'
import ParticipantChipView from 'components/core/ParticipantChipView'
import ScrollableContainer from 'components/core/ScrollableContainer'
import {SERVICE_URL,VIEWER_TYPES} from 'config'
import ButtonBase from '@material-ui/core/ButtonBase'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import {withRouter} from 'react-router-dom'
import {lightOrDark} from 'utils'
import AddToCalendarButton from 'components/core/AddToCalendarButton'
import differenceInHours from 'date-fns/differenceInHours'
// import {useSnackbar} from 'notistack';
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { imageFile } from "utils";
import api from 'api'
import {useHttp} from "api/core";
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
import moment from 'moment'

const styles = (theme) => ({
	container: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		backgroundColor: "white",
		borderRadius: "12px",
		border: "solid 1px #e6e6e6",
		position: "relative",
		overflow: "hidden",
	},
	SessionCard: {},
	fieldTitle: {
		width: "100%",
		fontSize: "16px",
		fontWeight: "bold",
	},
	fieldValue: {
		width: "100%",
		fontSize: "14px",
	},
	sessionBox: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: "8px",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
		padding: theme.spacing(1),
		color: palette["greyish-brown"],
	},
	sessionTitle: {
		color: theme.palette.primary.main,
		fontWeight: "bold",
	},
	sessionsBox: {
		paddingTop: theme.spacing(1),
		overflowX: "auto",
	},
	editIcon: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(6),
		cursor: "pointer",
		[theme.breakpoints.down(1180)]: {
			top: theme.spacing(6),
			right: theme.spacing(2),
		},
	},
	deleteIcon: {
		position: "absolute",
		top: theme.spacing(2),
		right: theme.spacing(2),
		cursor: "pointer",
	},
	notMatched: {
		display: "none",
	},
	participants: {
		display: "flex",
		// justifyContent: 'center',
		flexWrap: "wrap",
		marginLeft: -theme.spacing(0.5),
		paddingTop: theme.spacing(1),
		"& > *": {
			color: palette["greyish-brown"],
			margin: theme.spacing(0.5),
			borderColor: "white",
			width: "auto",
		},
	},
	avatar: {
		color: `${palette["greyish-brown"]} !important`,
		background: "none",
		"&> img": {
			maxWidth: "100%",
		},
	},
	fileList: {
		margin: 0,
		color: palette["greyish-brown"],
	},
	root: {
		fontSize: "14px",
		"&:focus > *": {
			background: "none !important",
		},
		paddingBottom: theme.spacing(1),
	},
	listItem: {
		paddingLeft: 0,
		"&>div": {
			minWidth: "30px",
			color: palette["greyish-brown"],
		},
		"&>a": {
			color: palette["greyish-brown"],
		},
		"&>div>a": {
			color: palette["greyish-brown"],
		},
	},
	treeItem: {
		background: "none",
		backgroundColor: "none",
		borderRadius: theme.spacing(1),
	},
	treeItemExpanded: {
		background: "none",
		borderRadius: theme.spacing(1),
		"&:focus > $content": {
			backgroundColor: "transparent",
		},
	},
	treeItemSelected: {
		// background: 'none',
		borderRadius: theme.spacing(1),
	},
	treeItemIcon: {
		alignSelf: "flex-start",
	},
	sessionUrl: {
		position: "absolute",
		right: "15px",
		top: "19px",
		color: palette["brown-grey"],
		"&:hover": {
			color: theme.palette.primary.main,
		},
	},
	expansionPanel: {
		background: "white",
		border: 0,
		boxShadow: "none",
	},
	expansionPanelSummary: {
		position: "relative",
	},
	tab: {
		width: "100%",
		backgroundColor: "white",
		color: palette["greyish-brown"],
		padding: theme.spacing(2),
		border: `1px solid ${palette["very-light-pink-two"]}`,
		"&:hover": {
			color: "white",
		},
	},
	tabs: {
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	activeTab: {
		backgroundColor: theme.palette.primary.main,
		color: "white",
		"&:focus": {
			backgroundColor: theme.palette.primary.main,
			color: "white",
		},
	},
	tabLabel: {
		width: "100%",
		fontWeight: "bold",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		alignContent: "center",
	},
	label: {
		background: "transparent !important",
		// fontWeight: 'bold',
		fontSize: "14px",
	},
	goingToggle: {
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: palette.aquamarine,
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
			opacity: 1,
		},
		"& #status_ok_green": {
			display: "none",
		},
	},
	checked: {
		opacity: 1,
		"& #status_ok_green": {
			display: "initial",
		},
		"& #status_ok_grey": {
			display: "none",
		},
	},
	buttonBase: {
		borderRadius: "20.5px",
		overflow: "hidden",
		marginRight: theme.spacing(1),
	},
	roomImg: {
		maxWidth: "600px",
		width: "100%",
		borderRadius: "10px",
    margin: "20px auto",
    display: "block"
	},
  roomDesc: {
    color: "#000",
  }
});

const FieldText = withStyles(styles)(({title, value, classes}) => {

  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}> {value} </Box>
  </Box>
})

// const TimeBox = withStyles(styles)(({classes, day, ordinal, startDate, endDate, ...restProps}) => {
//   return <Box className={classes.sessionBox} {...restProps}>
//     <Box className={classes.sessionTitle}>Time </Box>
//     {startDate&&format(parseISO(startDate), 'eee, Do')} <br/>
//     {startDate&&format(parseISO(startDate), 'hh:mmaa')} - {endDate&&format(parseISO(endDate), 'hh:mmaa')} <br/>
//   </Box>
// })
//
// const LocationBox = withStyles(styles)(({classes, location, ...restProps}) => {
//   return <Box className={classes.sessionBox} {...restProps}>
//     <Box className={classes.sessionTitle}>Location </Box>
//     {location}
//   </Box>
// })

const SessionCard = withStyles(styles)(({classes, event, session, history, viewOnly = false,timeZones,selectedTimezone,setSelectedTimezone, editAction, deleteAction, reload, ...props}) => {
  
    // const [getTimeZones, timeZones] = useHttp();
    // const [selectedTimezone, setSelectedTimezone] = useState("UTC");

    // useEffect(() => {
    //   getTimeZones(api.entities.timezones.getTimeZones());
    //   // eslint-disable-next-line
    // }, []);

  // useEffect(() =>
  // {
  //     if (event && timeZones) {
  //       const zone = timeZones.find((x) => x.id === event.timezoneId);
  //       moment.tz.setDefault(zone.name);
  //       setSelectedTimezone(zone.name);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [event, timeZones]);
  
  // const {enqueueSnackbar} = useSnackbar()
  const [tab, setTab] = useState(1)
  const [isExpanded, setIsExpanded] = useState(false)
  const posters = []
  session.presentations.forEach((p) => p.files.filter((f) => f.thumb !== null).forEach((pf) => posters.push(pf)))

  const toggleExpansion = (e,expanded) => {
    setIsExpanded(expanded)
  }

  const startDate = new Date(session.startDate)
  const endDate = new Date(session.endDate)

  const duration = differenceInHours(startDate,endDate)

  const sessionInfo = {
    description: session.description,
    duration,
    endDatetime: format(endDate,"yyyyMMdd'T'HHmmss"),
    location: session.room?session.room:'',
    startDatetime: format(startDate,"yyyyMMdd'T'HHmmss"),
    title: session.title,
  }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };  
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];
  return (
    <Box className={clsx(classes.SessionCard, session.matched === false && classes.notMatched)} {...props} key={session.id}>
      <Box p={0} className={classes.container}>
        <ExpansionPanel classes={{root: classes.expansionPanel}} onChange={toggleExpansion} expanded={isExpanded}>
          <ExpansionPanelSummary expandIcon={<IconArrowDownBorderDark />} aria-controls="panel1a-content" id="panel1a-header" classes={{root: classes.expansionPanelSummary}}>
            <Box display="flex" width="100%" justifyContent="space-between" alignContet="center" alignItems="center">
              <Box display="flex" flexDirection="column">
                <Box display="flex" fontSize={14}>
                  {moment(session.startDate).tz(selectedTimezone).format("hh:mm A")} - {moment(session.endDate).tz(selectedTimezone).format("hh:mm A")} ({selectedTimezone.replace("_"," ")}) /{" "}
                  {format(parseISO(session.startDate), "hh:mm aa", dateLocale[event.eventInfo.eventLanguage])} - {format(parseISO(session.endDate), "hh:mm aa", dateLocale[event.eventInfo.eventLanguage])} (
                  {moment.tz.guess().replace("_", " ")})
                </Box>
                <Box display="flex" flexDirection="row" alignContent="center" alignItems="center">
                  {session.title ? (
                    <>
                      <Box display="flex" flexDirection="column" fontSize={16} fontWeight={"bold"} mr={2}>
                        {session.title}
                      </Box>
                    </>
                  ) : (
                    ""
                  )}
                  {session.track && (
                    <>
                      {/*<Box display='flex' width='10px' height='10px' mt='5px' flexDirection='column' mr={1} style={{backgroundColor: session.track.color,borderRadius:'10px'}}/>*/}
                      <Chip
                        label={session.track.name}
                        style={{
                          backgroundColor: session.track.color,
                          border: "0.09em solid rgb(216, 216, 216)",
                          color: lightOrDark(session.track.color),
                          minWidth: "90px",
                          width: "auto",
                          height: "20px",
                          fontSize: "0.8125rem",
                        }}
                      />
                      {/*<Box display='flex' flexDirection='column'>{session.track.name}</Box>*/}
                    </>
                  )}
                </Box>

                {/*<Box display='flex' fontSize={12}>*/}
                {/*  {session.rsvp&&session.rsvp!==0?`${session.rsvp} attending`:''}*/}
                {/*</Box>*/}
              </Box>
              <Box display="flex" justifyContent={"center"} alignContet="center" alignItems="center">
                {(event.eventInfo.sessionViewerAccess===1 || (event.eventInfo.sessionViewerAccess===3 && event.eventInfo.registered)) && session.type !== VIEWER_TYPES.BREAK && session.type !== VIEWER_TYPES.SOCIAL && (
                  <Box display="flex" pr={session.recordingUrl ? 0 : 3}>
                    <GoingToggle session={session} reload={reload} isRegistered={event.eventInfo.registered} key={session.live} />
                  </Box>
                )}

                {session.recordingUrl && (
                  <Box display="flex" pr={3}>
                    <ButtonBase
                      className={clsx(classes.buttonBase)}
                      href={`/session/${session.id}`}
                      onClick={(e) => {
                        // e.preventDefault()
                        e.stopPropagation();
                        // if (event.eventInfo.joined) {
                        //   history.push(`/session/${session.id}`)
                        // } else {
                        //   enqueueSnackbar('You have to join the event in order to view the recording', {variant: 'warning'})
                        // }
                      }}>
                      <Box display="flex" alignContent="center" alignItems="center" justifyContent="space-between" className={clsx(classes.goingToggle)}>
                        <Box display="flex" pl={1} pr={1}>
                          <VideoLibraryIcon />
                        </Box>
                        <Box display="flex" pr={1}>
                          {language.watchRecording}
                        </Box>
                      </Box>
                    </ButtonBase>
                  </Box>
                )}
              </Box>
            </Box>
          </ExpansionPanelSummary>

          <Box pl={2} pr={2}>
            <Grid container justify="flex-end">
              <Grid item>
                <AddToCalendarButton withIcon event={sessionInfo} />
              </Grid>
            </Grid>
          </Box>

          {isExpanded && (
            <ExpansionPanelDetails>
              <Grid container spacing={2} direction="row" justify="flex-start" alignItems="flex-start">
                <Box display={"flex"} justifyContent={"space-between"} width="100%" className={classes.tabs}>
                  <Box display={"flex"} p={1} width="100%">
                    <Chip
                      size="medium"
                      className={clsx(classes.tab, tab === 1 && classes.activeTab)}
                      onClick={() => {
                        setTab(1);
                      }}
                      label={<Box className={classes.tabLabel}> {language.info} </Box>}
                      color={"primary"}
                    />
                  </Box>
                  <Box display={"flex"} p={1} width="100%">
                    <Chip
                      disabled={!session.presentations || (session.presentations && session.presentations.length === 0)}
                      size="medium"
                      className={clsx(classes.tab, tab === 2 && classes.activeTab)}
                      onClick={() => {
                        setTab(2);
                      }}
                      label={<Box className={classes.tabLabel}> {language.presentations} </Box>}
                      color={"primary"}
                    />
                  </Box>
                  {/*<Box display={'flex'} p={1} width='100%'>*/}
                  {/*  <Chip disabled={true} size='medium' className={clsx(classes.tab,tab===3&&classes.activeTab)} onClick={()=>{setTab(3)}} label={<Box className={classes.tabLabel}> Audience </Box>} color={'primary'} />*/}
                  {/*</Box>*/}
                </Box>

                {tab === 1 && (
                  <>
                    {session.description && (
                      <Grid item xs={12}>
                        <Box fontSize={18} fontWeight={"bold"}>
                          {language.description}
                        </Box>
                        <Box style={{whiteSpace: "pre-wrap"}}>{session.description}</Box>
                      </Grid>
                    )}

                    {session.room && (
                      <Grid item xs={12}>
                        <Box pb={1} fontSize={18} fontWeight={"bold"}>
                          {language.room}
                        </Box>
                        <Box display="flex" alignContent="center" alignItems="center">
                          <Box display="flex" pr={1}>
                            <IconMapPinBorderGray />
                          </Box>
                          <Box display="flex">
                            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                              {session.room.name}
                            </Button>
                            <Dialog open={open} keepMounted onClose={handleClose} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description">
                              <DialogTitle id="alert-dialog-slide-title">{session.room.name}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                  <span className={classes.roomDesc}> {session.room.description}</span>
                                  <br />
                                  {session.room.imageId ? <img src={imageFile(session.room.imageId)} alt="session room" className={classes.roomImg} /> : ""}
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} color="secondary">
                                  {language.close}
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        </Box>
                      </Grid>
                    )}

                    {session.participants && session.participants.length !== 0 && (
                      <Grid item xs={12}>
                        <Box pb={1} fontSize={18} fontWeight={"bold"}>
                          {language.speakers}
                        </Box>
                        <Box className={classes.participants}>
                          {session.participants.map((participant, i) => {
                            return (
                              <Box width={"100%"}>
                                <ParticipantChipView participantObj={participant} chair={participant.id === session.chairId} />
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    )}

                    {session.files && session.files.length !== 0 && (
                      <Grid item xs={12} sm={6}>
                        <FieldText
                          title="Files"
                          value={
                            <Box className={classes.participants}>
                              {session.files.map((file, i) => {
                                return (
                                  <>
                                    <Chip
                                      avatar={
                                        <Avatar className={classes.avatar}>
                                          {" "}
                                          <IconFileBorderDark />{" "}
                                        </Avatar>
                                      }
                                      variant="filled"
                                      label={`${file.filename}`}
                                      className={classes.chip}
                                      component="a"
                                      href={`/files/${file.id}`}
                                      clickable
                                      download={`${file.filename}`}
                                    />
                                  </>
                                );
                              })}
                            </Box>
                          }
                        />
                      </Grid>
                    )}
                    {/*{session.recordingUrl&&<Grid item xs={12}>*/}
                    {/*  <Box pb={1}  fontSize={18} fontWeight={'bold'}>Recording Link</Box>*/}
                    {/*  <Box>{<a href={session.recordingUrl}> {session.recordingUrl} </a>}</Box>*/}
                    {/*</Grid>}*/}
                  </>
                )}

                {tab === 2 && (
                  <>
                    {session.presentations && session.presentations.length !== 0 && (
                      <Grid item xs={12}>
                        <Box pb={1} fontSize={18} fontWeight={"bold"}>
                          {language.presentations}
                        </Box>
                        <TreeView className={classes.root} defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}>
                          {session.presentations.map((presentationObj, i) => {
                            return (
                              <TreeItem
                                nodeId={`${i}`}
                                label={
                                  <>
                                    <Box fontWeight="bold" component={"span"} width={"100%"}>
                                      {presentationObj.code ? presentationObj.code + " - " : ""}
                                      {presentationObj.title}
                                      <Box fontWeight="normal" component={"span"} ml={0.5}>
                                        {presentationObj.participants.map((p, i) => (
                                          <>
                                            {/* {p.title ? p.title : ""} */}
                                            {p.firstName} {p.lastName}
                                            {i !== presentationObj.participants.length - 1 ? ", " : ""}
                                          </>
                                        ))}
                                      </Box>
                                    </Box>

                                    {/*{presentationObj.participants.map((participant, i) => {*/}
                                    {/*  return <Box display='inline-block'><ParticipantChipView participantObj={participant} chair={participant.id===session.chairId}/></Box>*/}
                                    {/*})}*/}
                                  </>
                                }
                                classes={{
                                  group: classes.group,
                                  iconContainer: classes.treeItemIcon,
                                  root: classes.root,
                                  content: classes.treeItem,
                                  selected: classes.treeItemSelected,
                                  expanded: classes.treeItemExpanded,
                                  label: classes.label,
                                }}>
                                <>
                                  <Box p={1} pl={1} style={{whiteSpace: "pre-wrap"}}>
                                    {presentationObj.abstract}
                                  </Box>
                                  <Box className={classes.participants} pl={1}>
                                    {presentationObj.participants.map((author, i) => {
                                      return (
                                        <Box width={"100%"}>
                                          <ParticipantChipView participantObj={author} presenter={presentationObj.presenterId === author.id} />
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                  {presentationObj.files && presentationObj.files.length !== 0 && (
                                    <Box className={classes.participants} pl={1}>
                                      {presentationObj.files &&
                                        presentationObj.files.map((file, i) => {
                                          return (
                                            <>
                                              <Chip
                                                avatar={
                                                  <Avatar className={classes.avatar}>
                                                    {" "}
                                                    <IconFileBorderDark />{" "}
                                                  </Avatar>
                                                }
                                                variant="outlined"
                                                label={`${file.filename}`}
                                                component="a"
                                                href={`/files/${file.id}`}
                                                clickable
                                                download={`${file.filename}`}
                                              />
                                            </>
                                          );
                                        })}
                                    </Box>
                                  )}
                                </>
                              </TreeItem>
                            );
                          })}
                        </TreeView>
                      </Grid>
                    )}
                  </>
                )}

                {/*{tab===3&&<>*/}
                {/*  <Grid item container>*/}
                {/*    <Grid item xs={12}>*/}
                {/*      <Box pb={1} fontSize={18} fontWeight={'bold'}>Audience</Box>*/}
                {/*      <Box pb={1} fontSize={14} >Attending from your network</Box>*/}
                {/*    </Grid>*/}
                {/*    {session.participants&&session.participants.length!==0&&<Grid item xs={12} sm={6}>*/}
                {/*      <Box className={classes.participants}>*/}
                {/*        {session.participants.map((participant,i) => {*/}
                {/*          return <Box width={'100%'}><ParticipantChipView participantObj={participant} /></Box>*/}
                {/*        })}*/}
                {/*      </Box>*/}
                {/*    </Grid>}*/}
                {/*    <Grid item xs={12}>*/}
                {/*      <Box pt={2} fontSize={14}>Others</Box>*/}
                {/*    </Grid>*/}
                {/*    {session.participants&&session.participants.length!==0&&<Grid item xs={12} sm={6}>*/}
                {/*      <Box className={classes.participants}>*/}
                {/*        {session.participants.map((participant,i) => {*/}
                {/*          return <ParticipantChipView participantObj={participant} />*/}
                {/*        })}*/}
                {/*      </Box>*/}
                {/*    </Grid>}*/}
                {/*  </Grid>*/}
                {/*</>}*/}

                {session.type === 2 && posters.length > 0 && (
                  <Grid item xs={12}>
                    <Box pt={1} pl={1}>
                      <Box pb={1} fontSize={18} fontWeight={"bold"}>
                        Posters
                      </Box>
                      <ScrollableContainer
                        items={posters.map((file) => (
                          <a href={`/files/${file.id}`} clickable download={`${file.filename}`} title={file.filename}>
                            <img src={`${SERVICE_URL}/files/${file.thumb.id}`} alt={file.thumb.filename} />
                          </a>
                        ))}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </ExpansionPanelDetails>
          )}
        </ExpansionPanel>
      </Box>
    </Box>
  );
})

export default withRouter(SessionCard)
