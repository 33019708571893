import React, {useEffect} from "react";
import {withStyles} from "@material-ui/core/styles";
import {Box, Grid} from "@material-ui/core";
import {palette} from "theme";
import {withRouter} from "react-router-dom";
import {useMetaData} from "components/core/MetaProvider";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import BottomHero from "components/core/BottomHero";
import HowItWorksTimeLine from "components/core/HowItWorksTimeLine";
import HowItWorksMiddle from "components/core/HowItWorksMiddle";

import SetUpEvent from "assets/ui-set-up-event.png";
import EventProgram from "assets/event-program.jpg";

import EventApps from "assets/ui-virtual-event-apps.png";




// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = (theme) => ({
	hero: {
		maxWidth: "960px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "-2px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "35px",
			padding: theme.spacing(2),
		},
	},
	subtitle: {
		maxWidth: "642px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "20px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#a4a4a",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	tabTitle: {
		color: "#000",
		fontStyle: "normal",
		fontSize: "16px",
		fontFamily: "Karla",
		letterSpacing: "-1.05px",
		textTransform: "capitalize",
		lineHeight: "16px",
		zIndex: "1",
	},
	tabDescription: {
		color: "#444",
		fontStyle: "normal",
		fontSize: "14px",
		fontFamily: "Karla",
		letterSpacing: "-0.75px",
		textTransform: "capitalize",
		lineHeight: "14px",
	},
	testimonialsText: {
		width: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "22px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#9b9b9b",
		display: "block",
	},
	testimonialsName: {
		width: "460px",
		height: "31px",
		fontFamily: "Karla",
		fontSize: "21px",
		letterSpacing: "-1.05px",
		textAlign: "center",
		color: "#000",
		display: "block",
		margin: "auto",
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		maxWidth: "100%",
		margin: "40px auto",
		display: "block",
	},
	pageContent: {
		marginTop: "100px",
	},
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			width: "300px",
			margin: "10px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#3d4d59",
		fontSize: "36px",
		fontWeight: "600",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		letterSpacing: "0.3px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "27px",
			padding: theme.spacing(1),
		},
	},
});

const HowItWorks = withStyles(styles)(({classes, history}) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({title: "How it Works"});
		// eslint-disable-next-line
	}, []);

	/*const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};*/

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>Setting Up</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Create a vibrant landing page, choose from different layouts and
						color schemes, and add all the information you would like your event
						attendees to have, including speakers, deadlines, organizers and
						more!
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Box style={{width: "100%", typography: "body1"}}>
						<Grid
							item
							container
							justify="center"
							alignContent="center"
							alignItems="center"
						>
							<Box style={{maxWidth: "768px", width: "100%"}}>
								<img
									style={{
										maxWidth: "768px",
										width: "100%",
									}}
									src={SetUpEvent}
									alt="Anymeets Setting up events"
								/>
							</Box>
						</Grid>
					</Box>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
					style={{marginTop: "60px"}}
				>
					<Grid item>Registration</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Provide multiple registration options and collect registration fees
						as soon as you set up your event webpage. Participants receive a QR
						code to facilitate on-site registration.
					</Grid>
				</Grid>
			</Grid>
			<HowItWorksTimeLine />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>Event Program</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid
						item
						container
						justify="center"
						alignContent="center"
						alignItems="center"
						className={classes.subtitle}
					>
						<Grid item>
							Update your program in real time – even during your event. Publish
							the program online or export it to create a program booklet.
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify="center"
						alignContent="center"
						alignItems="center"
						className={classes.hero}
					>
						<Box style={{width: "100%", typography: "body1"}}>
							<Grid
								item
								container
								justify="center"
								alignContent="center"
								alignItems="center"
							>
								<Box style={{maxWidth: "768px", width: "100%"}}>
									<img
										style={{
											maxWidth: "768px",
											width: "100%",
											borderRadius: "20px",
											boxShadow: "0px 0px 50px 0px #ddd",
										}}
										src={EventProgram}
										alt="Anymeets Event Program"
									/>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<HowItWorksMiddle />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.secondaryTitle}
				>
					<Grid item>Integrations</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid
						item
						container
						justify="center"
						alignContent="center"
						alignItems="center"
						className={classes.subtitle}
					>
						<Grid item>
							Do you have a preferred video conferencing platform? Do you have
							an organizational subscription to a video conferencing platform?
							Does one of your attendees want the comfort of using a platform
							they are familiar with?
						</Grid>
						<Grid item>
							Mix-and-match video conferencing options to set up the perfect
							event! Do away with the emails, links, and invitation as all
							sessions are accessed directly from the event Agenda.
						</Grid>
					</Grid>
					<Grid
						item
						container
						justify="center"
						alignContent="center"
						alignItems="center"
						className={classes.hero}
					>
						<Box style={{width: "100%"}}>
							<Grid
								item
								container
								justify="center"
								alignContent="center"
								alignItems="center"
							>
								<Box style={{maxWidth: "768px", width: "100%"}}>
									<img
										style={{
											maxWidth: "768px",
											width: "100%",
										}}
										src={EventApps}
										alt="Anymeets Event Apps"
									/>
								</Box>
							</Grid>
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.secondaryTitle}
				>
					<Grid item>Recorded Sessions</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Show recorded videos without ever leaving your event webpage. Use
						YouTube or Vimeo for convenience, or record and upload your own
						video directly to Anymeets.
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />
			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter(HowItWorks);
