import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {ROLES} from 'config'

const styles = theme => ({
  container: {
    marginBottom: theme.spacing(2),
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  fieldTitle: {
    fontSize: '14px'
  },
  fieldValue: {
    fontSize: '16px',
    fontWeight: 'bold',
    overflow: 'hidden',
    overflowWrap: 'break-word'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {
  return <Grid item container>
    <Grid item xs={12} className={classes.fieldTitle}> {title} </Grid>
    <Grid item xs={12} className={classes.fieldValue}> {value} </Grid>
  </Grid>
})

const CollaboratorCard = withStyles(styles)(({classes, collaborator, viewOnly = false, editAction, deleteAction, ...props}) => {
  return <Grid container item spacing={2} {...props} className={classes.container}>

      <Grid item xs={12} sm={7} md={4}>
              <FieldText title={'Name'} value={`${collaborator.title?collaborator.title:''} ${collaborator.firstName} ${collaborator.lastName}`}/>
          </Grid>
          <Grid item xs={12} sm={5} md={3}>
              <FieldText title={'Role'} value={`${ROLES.find(role=>role.id===collaborator.role).name}`}/>
          </Grid>
          <Grid item xs={12} sm={11} md={4}>
              <FieldText title={'E-mail'} value={`${collaborator.email}`}/>
          </Grid>

        {!viewOnly && <Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end'>
          <IconNotesBorderLight title='Edit Collaborator' className={classes.editIcon} onClick={() => {
            editAction && editAction(collaborator)
          }}/>
          <IconXBorderLight title='Delete Collaborator' className={classes.deleteIcon} onClick={() => {
            deleteAction && deleteAction(collaborator)
          }}/>
        </Box>}


  </Grid>
})

export default CollaboratorCard
