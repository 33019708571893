import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import QnAWall from 'components/core/OpenTok/Tabs/QnA/QnAWall'

const styles = theme => ({

})


const Qna = withStyles(styles)(({classes, sessionState}) => {
  return <QnAWall sessionState={sessionState} disabled={false}/>
})

export default Qna
