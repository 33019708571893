import {withStyles} from '@material-ui/core/styles'
import {useHttp} from 'api/core'
import React, {useEffect, useState} from 'react'
import api from 'api'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import {TextField as MUITextField} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import {palette} from 'theme'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  formControl: {
    width: '100%',
    textAlign: 'left'
  },
  newSponsor: {
    maxWidth: '770px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto'
    }
  },
  sessionsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll'
  },
  sessionsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'scroll'
  },
  chip: {
    margin: theme.spacing(1),
    // maxWidth: '135px'
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  userPhotoImage: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img' : {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    },    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
})

const FindUserDialog = withStyles(styles)(({open, setOpenAssociate, setUser})=>{
  const [getData, data, isLoading, hasError] = useHttp()
  const [userEmail, setUserEmail] = useState()

  const handleChangeEmail = (e) => {
    const value = e.target.value
    setUserEmail(value)
  }

  const findUser = (e) => {
    e.preventDefault()
    getData(api.entities.user.getByEmail(null,{email:userEmail}))
  }

  useEffect(()=>{
    if (data) {
      setUser({...data, found:true, email: userEmail})
    }
    // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (hasError) {
      setUser({email:userEmail})
    }
    // eslint-disable-next-line
  },[hasError])

  return <>
    <Dialog
      open={open}
      onClose={()=>{setOpenAssociate(false);setUser()}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Find user by e-mail address</DialogTitle>
      <form onSubmit={findUser} >
        <DialogContent>
          <Box p={1} pl={0} pb={2}>
            <MUITextField label='E-mail' variant='filled' fullWidth onChange={handleChangeEmail} value={userEmail} disabled={isLoading}/>
          </Box>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={2} justify={'center'}>
            <Grid item>
              <Button size='small' variant='outlined' color='primary' onClick={()=>setOpenAssociate(false)} disabled={isLoading} >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button size='small' variant='contained' color='primary' onClick={findUser} type='submit' disabled={isLoading} >
                Find user
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </Box>
    </Dialog></>
})

export default FindUserDialog
