import React from 'react'
import {withStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import clsx from 'clsx'
import {format} from "date-fns"
import {withRouter} from 'react-router-dom'
import parseISO from 'date-fns/parseISO'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  card: {
    marginTop: theme.spacing(0)
  },
  cardContent: {
    // padding: theme.spacing(2),
  },
  title: {
    position: 'relative',
    minHeight: '45px',
    paddingRight: theme.spacing(2),
    '& a': {
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    }
  },
  titleEvent: {
    paddingRight: 0
  },


  linkToEvent: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },

})

const PlanEventOverview = withStyles(styles)(({classes, staticContext, index, refreshList, viewOnly, promoted=false, joinEvents=false, manageEvents=false, event, history, hooks, onClick, key, ...restProps}) => {

  const titleStyle = clsx({
    [classes.title]: true,
    [classes.titleEvent]: joinEvents
  })

  return <>
      <Grid container item className={classes.card} spacing={2} justify={'center'}>
          <Grid item xs={6} className={classes.cardInfo}>
            <Box fontSize={18} className={titleStyle}>
              {event.name}
            </Box>
            {/*{event.category&&<Box p={1} pt={0}><Chip label={CATEGORIES[event.category]} /></Box>}*/}
            {/*{event.startDate&&<Box p={1} pt={0} pb={0}>{format(parseISO(event.startDate), 'LLL do | hh:mmaa')}</Box>}*/}
            {/*{event.venueName&&<Box p={1} pt={0} pb={1} className={classes.deEmphasized}>{event.venueName}</Box>}*/}
          </Grid>
        <Grid item xs={6}>
          {event.to?'':'Since'} {format(parseISO(event.from), 'LLL do yyyy')} {event.to?'to':''} {event.to?format(parseISO(event.to), 'LLL do yyyy'):''}
        </Grid>
      </Grid>
  </>
})

export default withRouter(PlanEventOverview)
