import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ReviewerInvites from 'components/pages/ManageEvent/ReviewerInvites'
import Reviewers from 'components/pages/ManageEvent/Reviewers'
import ReviewFlows from 'components/pages/ManageEvent/ReviewFlows'
import CommunicationEditNew from 'components/core/CommunicationEditNew'
import { Formik } from 'formik';
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';


function TabPanel(props)
{
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${ index }`}
            aria-labelledby={`vertical-tab-${ index }`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index)
{
    return {
        id: `vertical-tab-${ index }`,
        'aria-controls': `vertical-tabpanel-${ index }`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: "auto",
    },
    tabs: {
        paddingTop: 67,
        borderRight: `1px solid ${ theme.palette.divider }`,
        textAlign: "right",
        "& span": {
            alignItems: "end",
            fontWeight: "bold"
        }
    },
    tabPanel: {
        // height: "calc(100vh - 406px)",
        overflowY: "auto",
        paddingTop: 35,
        width: "calc(100% - 190px)",
        "&& .actionButtons": {
            marginLeft: "-245px",
        }
    },
    tooltip: {
        fontSize: "13px",
        lineHeight: "20px",
        fontWeight: 400
    }, 
    title: {
        fontWeight: "bolder",
        fontSize: "1.1rem"
    },
    section: {
        paddingBottom: theme.spacing(4),
        maxWidth: "1200px",
      },
}));

export default function StepTwo({ setEventInfo, eventInfo, pageTitle, setPageTitle })
{
    const { eventId } = useParams()
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [navBack, setNavBack] = useState({ name: 'events', path: '/manage-events' })
    const [reload, setReload] = useState(false)

    const handleChange = (event, newValue) =>
    {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Setup" {...a11yProps(0)} style={{ textAlign: "left" }} />
                <Tab label="Invitation" {...a11yProps(1)} />
                <Tab label="Reviewers list" {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <Grid container spacing={2} className={classes.section}>
                    <Formik>
                        <ReviewerInvites setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} eventInfo={eventInfo} flow />
                    </Formik>
                </Grid>
                <Grid container spacing={2} className={classes.section} >
                  <Grid item xs={12} md={2}>
                    <Typography component="h2" variant="h6" className={classes.title}>
                      Review steps
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Setup the procedure of paper reviews." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                       
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={10}>                  
                    <ReviewFlows setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} eventInfo={eventInfo} reload={reload}/>
                  </Grid>                 
                </Grid>                 
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <div style={{ maxWidth: "1024px" }}>
                    <CommunicationEditNew eventId={eventId} eventInfo={eventInfo} style={{width:"450px"}}/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={2} className={classes.tabPanel}>
                <Reviewers setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} eventInfo={eventInfo} />                    
            </TabPanel>
        </div>
    );
}
