import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {palette} from 'theme'
import {useHttp} from 'api/core'
// import api from 'api'
import {withRouter} from 'react-router-dom'
import Adrawer from 'components/core/Drawer'
const styles = theme => ({

})

const Notes = withStyles(styles)(({classes, history}) => {

  const [getEvents, eventsData, isLoading] = useHttp()
  const [events, setEvents] = useState({content:[]})

  useEffect(()=>{
    if (!isLoading&&!eventsData) {
      // getEvents(api.entities.events.manage(null,{}))
    }
  },[events, eventsData, getEvents, isLoading])

  useEffect(()=>{
    if (eventsData) {
      setEvents(eventsData)
    }
  },[eventsData])

  return <Page title='Notes' display='flex' backgroundColor={palette['pale-grey']}>
    <Adrawer title='Notes' navigation={{routes:[]}} >
      Notes
    </Adrawer>
  </Page>
})

export default withRouter(Notes)
