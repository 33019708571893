import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import FieldText from 'components/core/FieldText'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  SessionTypeCard: {

  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  },
  colorBox:{
    width:'20px',
    height:'20px',
    borderRadius:'4px',
    border:'1px solid white'
  }
})

const SessionTypeCard = withStyles(styles)(({classes, sessionType, index, viewOnly=false, editAction, deleteAction, ...props}) => {
  return <Box className={classes.SessionTypeCard} key={index} {...props}><ThemeProvider theme={theme}>
    <Box p={2} className={classes.container}>

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FieldText title={'Name'} value={sessionType.name} />
            </Grid>
            {sessionType.description&&<Grid item xs={12} sm={10}>
             <FieldText title='Description' value={sessionType.description} />
            </Grid>}
          </Grid>

        {!viewOnly&&<Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end' >
          <IconNotesBorderLight title='Edit sessionType' className={classes.editIcon} onClick={()=>{editAction&&editAction(sessionType.id)}}/>
          <IconXBorderLight title='Delete sessionType' className={classes.deleteIcon} onClick={()=>{deleteAction&&deleteAction(sessionType.id)}}/>
          <Box className={classes.colorBox} style={{backgroundColor:sessionType.color}}/>
        </Box>}
      </Box>
  </ThemeProvider>
    </Box>
      })

      export default SessionTypeCard
