import React from 'react'
import ReactSpeedometer from 'react-d3-speedometer'
import {palette} from 'theme'

const Speedometer = ({sessionState}) => {
  const {likes,dislikes} = sessionState

  const percentageOfLikes = (100 * likes) / (likes + dislikes)
  const percentageOfDislikes = (100 * dislikes) / (likes + dislikes)

return <ReactSpeedometer
  width={160}
  height={60}
  needleHeightRatio={0.7}
  value={(likes || dislikes) && (percentageOfLikes - percentageOfDislikes)}
  maxValue={100}
  minValue={-100}
  currentValueText=' '
  maxSegmentLabels={0}
  ringWidth={47}
  needleTransitionDuration={3333}
  needleTransition='easeElastic'
  needleColor={'#0077af'}
  textColor={palette['greyish-brown']}
/>
}

export default Speedometer
