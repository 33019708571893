import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import React from 'react'
import {palette} from 'theme'
import clsx from 'clsx'

const styles = theme => ({
  blockTitle: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    width: '100%',
    position: 'relative',
    // height: '100%'
    '& a': {
      color: palette['greyish-brown'],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& a:hover': {
      color: palette['cerulean-blue']
    }
  },
  fieldTitleBlock: {
    fontSize: '15px',
    fontWeight: 'bold',
    display: 'inline',
    overflowWrap: 'break-word',
    color: palette['brown-grey']
  },
  fieldValueBlock: {
    fontSize: '14px',
    paddingLeft: theme.spacing(1.5),
    overflowWrap: 'break-word',
  },
  centered: {
    justifyContent: 'center'
  },
  withBorder: {
    border: `1px solid #ddd`
  }
})

const BlockText = withStyles(styles)(({title, value, classes, centered=false, withBorder}) => {

    return <Box display='flex' flexDirection='column' justifyContent='center' position='relative' className={clsx(classes.blockTitle, withBorder&&classes.withBorder)}>
      <Box display='flex' className={classes.fieldTitleBlock} p={1} pb={0}> {title} </Box>
      <Box display='flex' className={clsx(classes.fieldValueBlock, centered&&classes.centered)} p={1} pt={0}> {value?value:'-'} </Box>
    </Box>
})

export default BlockText
