import React from "react"
import { ReactComponent as NoSearchResult } from 'assets/placeholders/no_search results.svg'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'

const styles = theme => ({
  note: {
    color: palette['greyish-brown']
  }
})

const NoSearchResults = withStyles(styles)(({classes, note='Please search again using different keywords or category'}) => (<Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' height='100%' pt={9} alignContent='center' justifyContent='center' justifyItems='center' alignItems='center' flexDirection='column'>

    <Box display='flex'><NoSearchResult/></Box>

    <Box display='flex' pt={4}>
      <Typography variant={'h6'}>No results found</Typography>
    </Box>

  {note&&<Box display='flex' pt={2} className={classes.note}>
    {note}
    </Box>}

  </Box></Fade>
))

export default NoSearchResults
