import {withStyles} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {MenuItem, Typography} from "@material-ui/core";
import {useHttp} from "api/core";
import api from "api";
import {palette} from "theme";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
//import ListItemButton from "@material-ui/core/ListItemButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const styles = (theme) => ({
  gradeFilterBtn: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    cursor: "pointer",
    padding: "5px 15px 5px 0",
    fontSize: "16px,",
    background: "transparent",
    borderRadius: "4px",
    height: "40px",
    width: "100%",
    color: "#4a4a4a",
    fontFamily: "inherit",
    textAlign: "left",
    textIndent: "15px",
  },
  filterBox: {
    padding: "0px",
    minWidth: "100%",
    height: "fit-content",
    //border: "1px solid rgba(0, 0, 0, 0.23)",
    position: "absolute",
    zIndex: "12",
  },
  paperFilter: {
    padding: "10px 15px 20px",
  },
  gradeFilterBtnApply: {
    border: "1px solid " + palette["cerulean-blue"],
    cursor: "pointer",
    padding: "5px 15px",
    margin: "5px 0 5px 5px",
    fontSize: "14px,",
    background: palette["cerulean-blue"],
    borderRadius: "4px",
    height: "40px",
    width: "calc(50% - 5px)",
    color: "White",
    fontFamily: "inherit",
    textAlign: "center",
    "&:hover": {
      background: "white",
      color: palette["cerulean-blue"],
    },
    "&[disabled]": {
      opacity: "0.5",
      pointerEvents: "none",
    },
  },
  gradeFilterBtnReset: {
    border: "1px solid " + palette["cerulean-blue"],
    cursor: "pointer",
    padding: "5px 15px",
    fontSize: "14px,",
    background: "white",
    borderRadius: "4px",
    height: "40px",
    width: "100%",
    color: "#4a4a4a",
    fontFamily: "inherit",
    textAlign: "center",
    "&:hover": {
      background: palette["cerulean-blue"],
      color: "white",
    },
  },
  gradeFilterBtnCancel: {
    border: "1px solid " + palette["cerulean-blue"],
    cursor: "pointer",
    padding: "5px 15px",
    margin: "5px 5px 5px 0",
    fontSize: "14px,",
    background: "white",
    borderRadius: "4px",
    height: "40px",
    width: "calc(50% - 5px)",
    color: "#4a4a4a",
    fontFamily: "inherit",
    textAlign: "center",
    "&:hover": {
      background: palette["cerulean-blue"],
      color: "white",
    },
  },
  gradeFilterBtnApplyAll: {
    border: "1px solid " + palette["cerulean-blue"],
    cursor: "pointer",
    padding: "5px 15px",
    margin: "0px 5px 5px",
    fontSize: "14px,",
    background: palette["cerulean-blue"],
    borderRadius: "4px",
    height: "40px",
    width: "100%",
    color: "White",
    fontFamily: "inherit",
    textAlign: "center",
    "&:hover": {
      background: "white",
      color: palette["cerulean-blue"],
    },
    "&[disabled]": {
      opacity: "0.5",
      pointerEvents: "none",
    },
  },
  moreIcon: {
    color: "rgba(0, 0, 0, 0.54)",
    right: "0",
    position: "absolute",
    pointerEvents: "none",
    bottom: "8px",
  },
  selectRoot: {
    maxWidth: "100%",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  maxWidth: {
    maxWidth: "100%",
    width: "100%",
  },
  filterTopTitle: {
    fontSize: "11px",
    marginBottom: "10px",
  },
  gradeListBtn: {
    border: "none",
    background: "transparent",
    width: "100%",
    display: "flex",
    textAlign: "left",
    cursor: "pointer",
  },
  gradeCheckbox: {
    color: "#9b9b9b",
    padding: "0px !important",
    marginTop: "2px",
    marginLeft: "-8px",
    "&.Mui-checked": {
      color: palette["cerulean-blue"],
    },
  },
  gradeCheckIcon: {
    minWidth: "25px",
  },
  noPadding: {
    padding: "0px",
  },
  selectedGradesTab: {
    borderBottom: "2px dashed #9b9b9b",
    paddingBottom: "5px",
  },
  counterBtn: {
    display: "initial",
    border: "none",
    cursor: "pointer",
    fontSize: "17px",
    lineHeight: "17px",
    width: "33.333%",
    padding: "4px",
  },
});

const SubmissionFlowsGradesFilter = withStyles(styles)(({classes, eventId, flowId, getPageData}) => {
  //Get all grades of papers
  const [getPaperGrades, paperGrades] = useHttp();
  //Open filter box
  const [openGradesFilter, setOpenGradesFilter] = useState(false);
  //Select grades to make the groups
  const [selectedGrades, setSelectedGrades] = useState([]);
  //Grade groups
  const [gradesGroups, setGradesGroups] = useState([]);
  //Status to apply in papers
  const [selectedStatus, setSelectedStatus] = useState(0);

  //Make filter group button state
  const [applyState, setApplyState] = useState(true);

  useEffect(() => {
    if (selectedGrades.length === 0 && gradesGroups.length === 0) {
      setApplyState(true);
    } else {
      setApplyState(false);
    }
  }, [selectedGrades, gradesGroups]);

  //Get Grades from API
  useEffect(() => {
    getPaperGrades(api.entities.grades.getPaperGrades(null, null, {eventId: eventId}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Paper update status approval
  const [applyStatus, setApplyStatus] = useState(true);

  //Disable/enable buttons if no items exists
  useEffect(() => {
    if (gradesGroups.length !== 0 && selectedStatus >= 1 && selectedStatus <= 3) {
      setApplyStatus(false);
    } else {
      setApplyStatus(true);
    }
  }, [selectedStatus, gradesGroups, selectedGrades]);

  //Select grade checkbox
  const handleGradeChecked = (value, index) => () => {
    const currentIndex = selectedGrades.indexOf(value);
    const newChecked = [...selectedGrades];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedGrades(newChecked);
  };

  //open grade filter dialog
  const handleSetOpenGradesfilter = () => {
    setOpenGradesFilter((prevOpenGradesFilter) => !prevOpenGradesFilter);
  };

  //Select status action to process with filters
  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  //Close filter dialog and reset filter selection
  const handleCancelGradesfilter = () => {
    setOpenGradesFilter(false);
    setSelectedGrades([]);
    setGradesGroups([]);
    setGradesMultiplier({});
  };

  //Reset filter selection
  const handleResetGradesfilter = () => {
    setSelectedGrades([]);
    setGradesGroups([]);
    setGradesMultiplier({});
  };

  //Delete grade chip from array
  const handleDeleteChip = (index) => {
    const newGroup = [...gradesGroups];
    newGroup.splice(index, 1);
    setGradesGroups(newGroup);

    //Also reset the
    setCountIds(0);
    setGradesMultiplier({});
  };
  //Make groups of grades
  const handleApplyGradesfilter = () => {
    const newSelectedGrades = [];

    if (
      selectedGrades.length >= 1 &&
      //Only one group of grades is allowed to process
      gradesGroups.length < 1
    ) {
      selectedGrades.forEach((grade) => {
        const gradeIndex = paperGrades.findIndex((g) => g === grade);
        const multiplier = gradesMultiplier[gradeIndex] ? gradesMultiplier[gradeIndex] : 1;
        let newGrade = grade;
        for (let i = 1; i < multiplier; i++) {
          newGrade = newGrade + "," + grade;
        }
        newSelectedGrades.push(newGrade);

      });

      gradesGroups.push(newSelectedGrades);

      setSelectedGrades([]);
      setGradesMultiplier({});
    }
    getPaperFilteredResults();
  };

  //Make group chip label
  const toLabel = (value, mark) => {
    let str = "";
    for (const [, val] of Object.entries(value)) {
      str += `${val}${mark}`;
    }
    var newStr = str.slice(0, -1);
    return newStr;
  };
  //Get the papers filtered
  const [getPaperFiltered, paperFiltered] = useHttp();

  //Get the papers matching the filter groups selected
  const getPaperFilteredResults = () => {
    const queryGrades = toLabel(gradesGroups[0], ",");
    getPaperFiltered(
      api.entities.manage.getPaperFiltered(null, null, {
        id: eventId,
        grades: queryGrades,
      }),
    );
  };

  //Number of papers to modify status
  const [countIds, setCountIds] = useState(0);

  //Extract paper Id to apply new status
  const [filteredPaperIds, setFilteredPaperIds] = useState("");

  const handleApplyStatusAll = () =>
  {    
    setOpenConfirmationDialog(true);
    let submissionIdList = "";
    
    const currentFlowPapers = paperFiltered.papers.filter( x => x.submissionFlowId === flowId)
    currentFlowPapers.map((paper, index) =>
    {
      submissionIdList += paper.submissionId + ",";
      setCountIds(index + 1);
      return submissionIdList;
    });
    var newSubmissionIdList = submissionIdList.slice(0, -1);
    setFilteredPaperIds(newSubmissionIdList);
    return;
  };

  // eslint-disable-next-line no-unused-vars
  const [setPaperStatus, data, isLoading, error] = useHttp();

  const handleUpdatePaperStatus = () => {
    setPaperStatus(
      api.entities.manage.setPaperStatus({status: selectedStatus}, null, {
        submissionIds: filteredPaperIds,
      }),
    );
    setOpenConfirmationDialog(false);
    getPageData();
  };

  // eslint-disable-next-line no-unused-vars
  const [gradesMultiplier, setGradesMultiplier] = useState({});

  //Grades counter
  const GradeCounter = ({multiplierIndex, setGradesMultiplier, gradesMultiplier}) => {
    const counter = gradesMultiplier[multiplierIndex] ? gradesMultiplier[multiplierIndex] : 1;

    return (
      <Grid item lg={3} md={4} sm={4} sx={6}>
        {
          <button
            className={classes.counterBtn}
            disabled={counter <= 1}
            onClick={() => {
              setGradesMultiplier({...gradesMultiplier, [multiplierIndex]: counter - 1});
            }}>
            -
          </button>
        }
        {
          <Box className={classes.counterBtn} disabled>
            <small>{counter}</small>
          </Box>
        }
        <button
          className={classes.counterBtn}
          disabled={counter >= 5}
          onClick={() => {
            setGradesMultiplier({...gradesMultiplier, [multiplierIndex]: counter + 1});
          }}>
          +
        </button>
      </Grid>
    );
  };

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  return (
    <>
      <Grid item lg={3} md={4} xs={12}>
        <Box style={{position: "relative"}}>
          <Typography component={"p"} className={classes.filterTopTitle}>
            GRADES FILTER
          </Typography>
          <button className={classes.gradeFilterBtn} onClick={handleSetOpenGradesfilter}>
            Grades Filter
            {openGradesFilter ? <ExpandLessIcon className={classes.moreIcon} /> : <ExpandMoreIcon className={classes.moreIcon} />}
          </button>
          {openGradesFilter && (
            <Box className={classes.filterBox}>
              <Paper className={classes.paperFilter}>
                <Box className={classes.selectedGradesTab}>
                  {gradesGroups &&
                    gradesGroups.map((grades, index) => {
                      return (
                        <Chip
                          key={index}
                          style={{margin: "1px"}}
                          label={toLabel(grades, ",")}
                          onDelete={() => {
                            handleDeleteChip(index);
                          }}
                        />
                      );
                    })}
                </Box>
                <Box>
                  <List
                    sx={{
                      width: "100%",
                      bgcolor: "background.paper",
                    }}
                    dense={true}>
                    {paperGrades.map((value, index) => {
                      const labelId = `grades-list-label-${value}`;

                      return (
                        <ListItem classes={{root: classes.noPadding}} key={index}>
                          <Grid item lg={9} md={8} sm={8} sx={6}>
                            <button className={classes.gradeListBtn} variant="text" role={undefined} onClick={handleGradeChecked(value, index)}>
                              <ListItemIcon
                                classes={{
                                  root: classes.gradeCheckIcon,
                                }}>
                                <Checkbox
                                  classes={{
                                    root: classes.gradeCheckbox,
                                  }}
                                  edge="start"
                                  checked={selectedGrades.indexOf(value) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={`${value}`} />
                            </button>
                          </Grid>
                          <GradeCounter gradesMultiplier={gradesMultiplier} setGradesMultiplier={setGradesMultiplier} multiplierIndex={index} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Box>
                <Box>
                  <button className={classes.gradeFilterBtnCancel} onClick={handleCancelGradesfilter}>
                    Cancel
                  </button>
                  <button className={classes.gradeFilterBtnApply} onClick={handleApplyGradesfilter} disabled={applyState}>
                    Apply
                  </button>
                  <button className={classes.gradeFilterBtnReset} onClick={handleResetGradesfilter}>
                    Reset
                  </button>
                </Box>
              </Paper>
            </Box>
          )}
        </Box>
      </Grid>

      <Grid item lg={2} md={3} xs={12}>
        <Typography component={"p"} className={classes.filterTopTitle}>
          SELECT STATUS
        </Typography>
        <FormControl variant="standard" classes={{root: classes.maxWidth}}>
          <Select
            classes={{root: classes.selectRoot}}
            className={classes.gradeFilterBtn}
            style={{minWidth: "150px", fontSize: "14px"}}
            disableUnderline
            displayEmpty
            fullWidth
            id="submissions-flows-status"
            value={selectedStatus}
            onChange={(e) => {
              handleStatusChange(e.target.value);
            }}
            inputProps={{padding: "0", border: "none"}}
            IconComponent={ExpandMoreIcon}>
            <MenuItem value={0}>-</MenuItem>
            <MenuItem value={1} key={1}>
              Accepted
            </MenuItem>
            <MenuItem value={2} key={2}>
              Rejected
            </MenuItem>
            <MenuItem value={3} key={3}>
              Waitlist
            </MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item lg={2} md={3} xs={12}>
        <Typography component={"p"} className={classes.filterTopTitle}>
          &nbsp;
        </Typography>
        <button
          className={classes.gradeFilterBtnApplyAll}
          disabled={applyStatus}
          onClick={() => {
            handleApplyStatusAll();
          }}>
          Apply to all
        </button>
      </Grid>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => {
          setOpenConfirmationDialog((prevOpenConfirmationDialog) => !prevOpenConfirmationDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {countIds >= 1 ? (
          <>
            {" "}
            <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
            <DialogContent>
              <Typography component="p">
                This filter will modify the status of {countIds} Papers to "{selectedStatus === 1 ? "Accepted" : selectedStatus === 2 ? "Rejected" : "Waitlist"}
                ".
              </Typography>
              <Typography component="p">Do you want to proceed?</Typography>
              <Typography component="p">&nbsp;</Typography>
            </DialogContent>
          </>
        ) : (
          <>
            {" "}
            <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
            <DialogContent>
              <Typography component="p">There aren't any Papers matching the selected grades</Typography>
              <Typography component="p">&nbsp;</Typography>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Grid item lg={6} md={6} xs={12}></Grid>
          <Grid item lg={6} md={6} xs={12}>
            {countIds >= 1 ? (
              <>
                <button
                  className={classes.gradeFilterBtnCancel}
                  onClick={() => {
                    setOpenConfirmationDialog((prevOpenConfirmationDialog) => !prevOpenConfirmationDialog);
                  }}>
                  Close
                </button>

                <button
                  className={classes.gradeFilterBtnApply}
                  onClick={() => {
                    handleUpdatePaperStatus();
                  }}
                  autoFocus>
                  I Agree
                </button>
              </>
            ) : (
              <button
                className={classes.gradeFilterBtnApply}
                style={{float: "right"}}
                onClick={() => {
                  setOpenConfirmationDialog((prevOpenConfirmationDialog) => !prevOpenConfirmationDialog);
                }}>
                Close
              </button>
            )}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
});

export default SubmissionFlowsGradesFilter;
