import React, {forwardRef, useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Grid from "@material-ui/core/Grid";
import Button from "components/core/Button";
//import * as Yup from "yup";
import {useHttp} from "api/core";
import api from "api";
import AddBox from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Edit from "@material-ui/icons/Edit";
import SaveAlt from "@material-ui/icons/SaveAlt";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Search from "@material-ui/icons/Search";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import MaterialTable from "material-table";
import LoadingDialog from "components/core/LoadingDialog";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";

const styles = (theme) => ({
  note: {
    fontSize: "12px",
  },
  formControl: {
    width: "100%",
  },
  dialog: {
    padding: theme.spacing(3),
  },
  dialogPaper: {
    minHeight: "calc(90vh-90px)",
    maxHeight: "calc(90vh-100px)",
    padding: "40px 30px 40px 30px",
  },
  row: {
    marginBottom: theme.spacing(2),
  },
});

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};


const PresentationsBulkImport = withStyles(styles)(({classes, presentation, sessions, handleClose, eventId, setSuccessful, Presentations, presentationTypes, file, participants, ...props}) => {

  const { enqueueSnackbar } = useSnackbar();
  
  const [putData, data, isLoading] = useHttp();
  const [saveData, dataSave, isLoadingSave] = useHttp();
  // eslint-disable-next-line


  const [presentations, setPresentations] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [hasInvalid, setHasInvalid] = useState(false);

  useEffect(() => {
    if (file) {
      let formDataObj = new FormData();
      formDataObj.append("file", file);
      putData(api.entities.presentations.uploadPresentationsPreview(formDataObj, null, {id: eventId}, true));
    }
    // eslint-disable-next-line
  }, [file]);

  useEffect(() => {
    if (data) {
 
      let presentationsData = [];
      data.forEach((presentations, index) =>
      {
        const valid = isValid(presentations);
        if (!valid) setHasInvalid(true);      
        presentationsData.push({
          title: presentations.title,
          abstract: presentations.abstract,
          type: presentations.type === null ? "" : presentationType(presentations.type),
          presentationCode:  presentations.presentationCode,
          sessionCode:  presentations.sessionCode,
          presenter: presentations.presenterId === null ? null : getPresenter(presentations.presenterId),
          speakers: presentations.participants === null ? null : getSpeakers(presentations.participants),          
          valid,
        });
      });

      setPresentations(presentationsData);
      setPreviewOpen(true);
    }
    // eslint-disable-next-line
  }, [data]);

  const presentationType = (type) => {
    switch (type) {
      case 1:
        return "oral";
      case 2:
        return "poster";    
      default:
        return "";
    }
  }

  const getPresenter = (presenterId) =>
  {    
    if (presenterId) {
      const presenter = participants.find((presenter) => presenter.id === presenterId);
      return presenter.email;
    } else
    {
      return false
    }
  }

  const getSpeakers = (speakerId) =>
  {
    let speakers = '';
    let speakersMail = '';
    speakerId.forEach((speaker) =>
    {
      speakers = participants.find((participants) => participants.id === speaker);
      speakersMail += speakers.email+','
    } )  
    return speakersMail.slice(0, -1);
  };  

  // Validation check
  const isValid = (entry) =>
  { 
    let presentationCode = false;
    let prCode = "";
    if (presentations) { 
      prCode = presentations.filter((presentation) => presentation.presentationCode === entry.presentationCode);
    } else
    {
      prCode = data.filter((presentation) => presentation.presentationCode === entry.presentationCode);
    }

    if (entry.presentationCode !== null && prCode.length > 1)
    {
      enqueueSnackbar("Presentations can not have the same presentation code.", {variant: "error", preventDuplicate: true});
      presentationCode = false
    } else
    {
      presentationCode = true;
    }
     
    if (entry.title !== null && entry.type && entry.abstract && presentationCode) {
      return true;
    } else {
      return false;
    }

  };
  
  //validate presentation table after edit
  const isValidNew = (entry) =>
  {
    let presentationCode = false;
     const prCode = presentations.filter((presentation) => presentation.presentationCode === entry.presentationCode);
    if (entry.presentationCode !== null && prCode.length > 1) {
      enqueueSnackbar("Presentations can not have the same presentation code.", {variant: "error", preventDuplicate: true});
      presentationCode = false;
    } else {
      presentationCode = true;
    }     
 
    if (entry.presenter !== null && entry.speakers !== null) {
      //check if entry is included in presenters
      let isInParticipants = false;
      isInParticipants = entry.speakers.includes(entry.presenter);

      //check if entry is included in speakers
      let isInSpeakers = false;
      const speakers = entry.speakers.split(",");
      speakers.forEach((element) => {
        const presenter = participants.find((presenter) => presenter.email === element);
        if (presenter.email) {
          isInSpeakers = true;
        } else {
          isInSpeakers = false;
        }
      });

      if (entry.title !== "" && entry.type && entry.abstract && isInParticipants && isInSpeakers && presentationCode) {
        return true;
      } else {
        return false;
      }
    } else {
      if (entry.title !== "" && entry.type && entry.abstract && presentationCode) {
        return true;
      } else {
        return false;
      }
    }
  };

  
  useEffect(() => {
    if (presentations) {
      let hasInvalidEntries = false;
      presentations.forEach((entry) => {
        if (!isValid(entry)) {
          hasInvalidEntries = true;
        }
      });
      setHasInvalid(hasInvalidEntries);
    }
    // eslint-disable-next-line
  }, [presentations]);


  useEffect(() => {
    if (dataSave) {
      handleClose(true);
      setSuccessful(true);
    }
    // eslint-disable-next-line
  }, [dataSave]);




  const handleSave = () =>
  {
    let speakerId = [];
    let newPresentation =""
    presentations.forEach((a,index) => {
      let formDataObj = new FormData();
      
      //construct new presentation dto
      newPresentation = {
        ...a,
        //eventId: parseInt(eventId),
        files: null,
        keywords: null,
        paperFiles: [],
        posterFiles: [],
        presentationFiles: []
      };
      
      //presentation type
      newPresentation = Object.assign({...newPresentation}, a.type === "oral" ? {type: 1} : {type: 2});

      if(a.presenter!==null && a.speakers!==null && a.presenter!=="" && a.speakers!==""){
      //presenter and speakers id
      const presenter = participants.find((participant) => participant.email === a.presenter); 
        if (presenter !== undefined) {
          newPresentation = Object.assign({...newPresentation}, {presenterId: presenter.id});
        } else
        {
           newPresentation = Object.assign({...newPresentation}, {presenterId: null});
        }   
      const speakers = a.speakers.split(",");
      speakers.forEach((element) => {
        const speakers = participants.find((participant) => participant.email === element);
         speakerId.push(speakers.id)
      });

      newPresentation = Object.assign({...newPresentation}, {participants: speakerId});
        speakerId = [];
      } else
      {
        newPresentation = Object.assign({ ...newPresentation }, { presenterId: null });
        newPresentation = Object.assign({...newPresentation}, {participants: []});
      }

      //get session id if exists and correct code is provided
      if (a.sessionCode !== null && a.sessionCode !== "") {
        const session = sessions.find((session) => session.code === a.sessionCode);
        if (session !== undefined) {
          newPresentation = Object.assign({ ...newPresentation }, { sessionId: session.id });          
        } else {
          newPresentation = Object.assign({ ...newPresentation }, { sessionId: null });
          newPresentation = Object.assign({...newPresentation}, {eventId: eventId});
        }
      } else {
        newPresentation = Object.assign({ ...newPresentation }, { sessionId: null });
        newPresentation = Object.assign({...newPresentation}, {eventId: eventId});
      }

      delete newPresentation.presenter;
      delete newPresentation.speakers;
 
      const blob = new Blob([JSON.stringify(newPresentation)], {type: "application/json"});
      formDataObj.append("presentation", blob);
       setTimeout(() => {
         saveData(api.entities.presentations.addNew(formDataObj, null, null, true));
       }, index * 500);  
      setTimeout(() => {
         handleClose(true);
      }, index * 550); 
      
    });
  };

  return (
    <>
      {(isLoading || isLoadingSave) && <LoadingDialog open={true} />}
      {presentations && (
        <Dialog onClose={handleClose} open={presentations && previewOpen} fullWidth maxWidth="lg" classes={{paper: classes.dialogPaper}} disableBackdropClick={isLoadingSave} disableEscapeKeyDown={isLoadingSave}>
          {hasInvalid && (
            <Alert severity="warning">
              <AlertTitle>Invalid records</AlertTitle>
              There are invalid records in this file. You won't be able to upload the file before fixing.
            </Alert>
          )}
          <Grid container className={classes.dialog}>
            <Grid item container spacing={1} className={classes.row}>
              <Typography variant="h5" component="h1">
                {"Upload presentations"}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <MaterialTable
                  icons={tableIcons}
                  columns={[
                    {title: "Paper Title *required", field: "title", cellStyle: {width: "20px", maxWidth: "20px"}},
                    {title: "Abstract *required", field: "abstract", cellStyle: {whiteSpace: "nowrap", width: "300px", maxWidth: "500px", overflow: "hidden", textOverflow: "ellipsis"}},
                    {title: "Presentation Type  *required", field: "type"},
                    {title: "Session Code", field: "sessionCode"},
                    {title: "Presenter", field: "presenter"},
                    {title: "Speakers", field: "speakers"},
                    {title: "Presentation Code", field: "presentationCode"},
                    {title: "Valid", field: "valid", type: "boolean", editable: "never", defaultSort: "asc"},
                  ]}
                  data={presentations}
                  title="Presentations List"
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise((resolve, reject) => {
                        setPresentations([...presentations, {...newData, valid: isValidNew(newData)}]);
                        resolve();
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        const dataUpdate = [...presentations];
                        const index = oldData.tableData.id;
                        dataUpdate[index] = {...newData, valid: isValidNew(newData)};
                        setPresentations([...dataUpdate]);
                        resolve();
                      }),
                    onRowDelete: (oldData) =>
                      new Promise((resolve, reject) => {
                        const dataDelete = [...presentations];
                        const index = oldData.tableData.id;
                        dataDelete.splice(index, 1);
                        setPresentations([...dataDelete]);
                        resolve();
                      }),
                  }}
                  options={{
                    actionsColumnIndex: 999,
                    headerStyle: {
                      backgroundColor: palette["brown-grey"],
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: 14,
                    },
                    rowStyle: (rowData) => ({fontSize: 12, padding: 0, backgroundColor: rowData.valid ? "" : "rgb(255, 244, 229)"}),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <DialogActions>
            <Button size="smaller" onClick={handleClose} color="primary" disabled={isLoadingSave}>
              Cancel
            </Button>
            <Button size="smaller" onClick={handleSave} color="secondary" disabled={isLoadingSave || hasInvalid}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );

});

export default PresentationsBulkImport;
