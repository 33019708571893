import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import SocialFeed from 'components/pages/Event/SocialFeed'
import {withRouter} from 'react-router-dom'
const styles = theme => ({

})

const SocialFeedPage = withStyles(styles)(({classes, history, eventId, sessionId, disabled}) => {
  return <Box p={5}>
    <SocialFeed eventId={eventId} sessionId={sessionId} disabled={disabled} />
  </Box>
})

export default withRouter(SocialFeedPage)
