import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {useHttp} from 'api/core'
import api from 'api'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import RichTextEditor from 'react-rte'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import {EMAIL_TEMPLATE, EMAIL_TEMPLATE_NAMES} from "config";

const styles = theme => ({
  editor: {
    color: palette['greyish-brown'],
    width: '100%',
    borderRadius: '6px',
    minHeight: '302px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  formControl: {
    width: '100%'
  },
  buttons: {
    height: '100%'
  },
  fieldsContainer: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '100%',
    overflowY: 'auto',
    minHeight: '448px'
  },
  listRoot: {

  }
})

const Body = withStyles(styles)(({classes, state, setState, defaultGroup})=>{

  const [getData, data, isLoading] = useHttp()
  const [getDataTemplateFields, dataTemplateFields, isLoadingTemplateFields] = useHttp()
  const [getDataTemplate, dataTemplate] = useHttp()
  const [templates, setTemplates] = useState(state.templates)
  const [templateFields, setTemplateFields] = useState(state.templateFields)
  const [selectedTemplate, setSelectedTemplate] = useState()

  const rtEditor = useRef()

  useEffect(()=>{
    if (!templates) {
      getData(api.entities.communication.getTemplates(null,null,{id:state.eventId}))
    }
    if (!templateFields) {
      getDataTemplateFields(api.entities.communication.getTemplateFields())
    }
    if (templates&&(!state.templateId)) {
      selectedTemplate(templates.system[0].id)
    }
    //eslint-disable-next-line
  },[])

  const forcedGroup = !!defaultGroup

  useEffect(()=>{
    if (data) {

      let templateName = 'generic'

      if (forcedGroup) {
        templateName = EMAIL_TEMPLATE_NAMES[defaultGroup]
      }

      setState({...state, templateId:parseInt(data[templateName].system[0].id), templates: data[templateName] })
      setTemplates(data[templateName])

      if (!selectedTemplate) {
        setSelectedTemplate(parseInt(data[templateName].system[0].id))
      }

    }
    //eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (dataTemplateFields) {
      setTemplateFields(dataTemplateFields)
      setState({...state, templateFields: dataTemplateFields })
    }
    //eslint-disable-next-line
  },[dataTemplateFields])

  useEffect(()=>{
    if (dataTemplate) {
      setState({...state, bodyEditor:{ value: RichTextEditor.createValueFromString(dataTemplate.body,'html') } , subject:dataTemplate.subject})
    }
    //eslint-disable-next-line
  },[dataTemplate])

  useEffect(()=>{
    if (selectedTemplate) {
      getDataTemplate(api.entities.communication.getTemplate(null,null,{templateId:selectedTemplate, eventId: state.eventId}))
    }
    //eslint-disable-next-line
  },[selectedTemplate])

  const selectTemplate = (e) => {
    const value = e.target.value
    setState({...state, templateId:parseInt(value)})
    setSelectedTemplate(parseInt(value))
  }

  const addField = (e, value) => {
    e.preventDefault()
    e.stopPropagation()
    // if (state.bodyEditor.value.toString('html')==='') {
    //   setState({...state, bodyEditor:{ value: RichTextEditor.createValueFromString(value,'html') }})
    // } else {
    //   document.execCommand("insertHTML", false, `${value} `)
    // }
    let cd = new DataTransfer()
    cd.setData('text/plain', value)

    if (rtEditor.current) {
      rtEditor.current.editor._onPaste({
        preventDefault : () => {},
        clipboardData  : cd
      })
    }

  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  }

  const onEditorChange = (value) => {
    setState({...state, bodyEditor:{value}})
  }

  const changeSubject = (e) => {
    const value = e.target.value
    setState({...state, subject: value})
  }

  if (isLoading||isLoadingTemplateFields||(!templates)||(!templateFields)) {
    return <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height='100%' width='100%'>
      <Box display='flex' p={6}>
        <CircularProgress size={'5rem'} color='secondary' />
      </Box></Box>
  }

  // const isPaperAcceptance = state.group === EMAIL_TEMPLATE.WAIT_LISTED_SUBMITTERS || state.group === EMAIL_TEMPLATE.REJECTED_SUBMITTERS || state.group === EMAIL_TEMPLATE.ACCEPTED_SUBMITTERS
  const isReviewers = state.groups.find((g)=>g.category==='Reviewers').groups.findIndex((g)=>g.id===state.group)!==-1
  const isSubmitters = state.groups.find((g)=>g.category==='Submitters').groups.findIndex((g)=>g.id===state.group)!==-1
  const isPresenters = state.groups.find((g)=>g.category==='Presenters').groups.findIndex((g)=>g.id===state.group)!==-1

  return <Grid item container spacing={2}>

    <Grid item xs={12} sm={9}>
      <Grid item container spacing={2} flexDirection='column'>

        <Grid item xs={12}>
          <FormControl className={classes.formControl}>
            <Select native labelId='templateIdLabel' displayEmpty name='templateId' label='Template' variant='filled' fullWidth defaultValue='' value={state.templateId} onChange={selectTemplate}>
              {templates&&templates.system.concat(templates.custom).map((template, i)=>{
                return <option value={template.id} key={`template-${i}`}>{template.label}</option>
              })}
            </Select>
            <InputLabel variant='filled'>Template</InputLabel>
            {/*<FormHelperText error variant='filled'> {errors.general.category} </FormHelperText>*/}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField type='text' name='subject' label='Subject' variant='filled' fullWidth value={state.subject} onChange={changeSubject}/>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box pt={2}>
          <RichTextEditor ref={rtEditor} className={classes.editor} value={state.bodyEditor.value} toolbarConfig={toolbarConfig} onChange={(v)=>onEditorChange(v)} placeholder={'Type your text...'}/>
        </Box>
      </Grid>

    </Grid>

    <Grid item xs={12} sm={3}>
      <Grid item container spacing={2} flexDirection='column' key={state.group}>
        {/*<Grid item xs={12}>*/}
        {/*  <Box p={2}>*/}
        {/*    Template fields*/}
        {/*  </Box>*/}
        {/*</Grid>*/}
        <Grid item xs={12}>
          <Box className={classes.fieldsContainer}>
            <Box p={2}>
              Template fields
            </Box>
            <List dense className={classes.listRoot}>
              {templateFields.filter((fieldgroup)=>{
                if (fieldgroup.group==='Reviewer'&&!isReviewers) {
                  return false
                }
                if ( fieldgroup.group==='Papers'&&(!isSubmitters&&!isPresenters) ) {
                  return false
                }
                return true
              }).map((fieldgroup,i) => {

                return <>
                  <ListItem key={`group-${i}`}>
                    <ListItemText secondary={fieldgroup.group} />
                  </ListItem>
                  {fieldgroup.fields.filter((f)=>{

                    if (f.field==='{ReviewerReplyUrl}') {
                      if (state.group===EMAIL_TEMPLATE.INVITE||state.group===EMAIL_TEMPLATE.REMINDER) {
                        return true
                      } else {
                        return false
                      }
                    }

                    if (f.field==='{AssignedPaperList}') {
                      if (state.group===EMAIL_TEMPLATE.INVITE||state.group===EMAIL_TEMPLATE.REMINDER) {
                        return false
                      } else {
                        return true
                      }
                    }

                    return true
                  }).map((field,y)=>{
                    return <ListItem key={field.field} button onMouseDown={(e)=>addField(e,field.field)}>
                      <ListItemText primary={field.name} />
                    </ListItem>
                  })}
                </>
              })}
            </List>
          </Box>
        </Grid>
      </Grid>
    </Grid>

  </Grid>
})

export default Body
