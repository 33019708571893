import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import {
  IconCamera,
  IconCameraOff,
  IconMic,
  IconMicOff, IconPresentScreen, IconPresentScreenOff,
  IconShareScreen,
  IconShareScreenOff
} from 'components/core/icons'
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
// import PanToolIcon from '@material-ui/icons/PanTool'
import TuneIcon from '@material-ui/icons/Tune'

// import {theme} from 'theme'

// import {makeStyles} from '@material-ui/styles'
const styles = theme => ({
  videoFooter: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2),
    height: theme.spacing(8),
    backgroundColor: 'black',
    color: 'white',
    position: 'relative'
  },
  button: {
    cursor: 'pointer',
    color: 'white',
    opacity: 0.8,
    transition: 'all 0.5s',
    '&:hover': {
      opacity: 1,
    }
  },
  disabledButton: {
    opacity: 0.2,
    cursor: 'default',
    '&:hover': {
      opacity: 0.2,
    }
  }
})

const VideoFooter = withStyles(styles)(({classes, sessionState, videoSource, toggleCamera, toggleMicrophone, presentingSpeaker, setPresentingSpeaker, presentingQuestion, setPresentingQuestion, inCall, setInCall, videoContainer, isFullscreen, setIsFullscreen, session, streamers, publishing, publishingCanvas, setPublishingCanvas, setPublishingScreen, setPresentingFile, publishAudio, publishVideo, presentingFile, presentingFilePage, publishingScreen, setSelectingFile, setPresentationFile, deviceSetup, setDeviceSetup, openDeviceSetup, setPresentingFilePage}) => {

  const {
    roomId,
    canRaiseHands,
    participants,
    participantId,
    isCoach,
    isChair,
    isStarted,
    layoutMode,
    autoMode,
    canPresentFile,
    canPresentQuestions,
    canPresentSpeaker,
    isViewer,
    hasOpenTokConfig,
    type,
    isOnlinePoster,
    isVirtualRoom,
    raisedHand,
    videoContainerRef
  } = sessionState

  // eslint-disable-next-line
  const [setPresent,presentData,presentLoading,presentError] = useHttp()
  // eslint-disable-next-line
  const [delPresent,delPresentData,delPresentLoading,delPresentError] = useHttp()

  // eslint-disable-next-line
  const [setShare,shareData,shareLoading,shareError] = useHttp()
  // eslint-disable-next-line
  const [delShare,delShareData,delShareLoading,delShareError] = useHttp()
  // eslint-disable-next-line
  const [putRequest, , puttingData] = useHttp()

  // eslint-disable-next-line
  // const [sendRaiseHand, raiseHandData, raiseHandLoading, raiseHandError] = useHttp()
  // eslint-disable-next-line
  const [sendDropHand, dropHandData, dropHandLoading, dropHandError ] = useHttp()

  const [anchorEl, setAnchorEl] = useState(null)

  // const [sentHand, setSentHand] = useState(false)
  // useEffect(()=>{
  //   if (raisedHand) {
  //     setSentHand(false)
  //   }
  // },[raisedHand])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClosePresent = () => {
    setAnchorEl(null)
  }

  useEffect(()=>{
    if (presentData&&presentData.status===204) {
      if (!publishingScreen&&!presentingQuestion&&!presentingSpeaker) {
        setSelectingFile(true)
      }
    }
    // eslint-disable-next-line
  },[presentData])

  useEffect(()=>{
    if (shareData&&shareData.status===204) {
      // setStreaming({...streaming, publishingScreen: true, publishingCanvas: true })
      setPublishingScreen(true)
      setPublishingCanvas(true)
    }
    // eslint-disable-next-line
  },[shareData])

  // Helpers
  const speakerObj = participants.find((s)=>s.id===participantId)
  const canBroadcast = speakerObj&&speakerObj.canBroadcast
  const isPresenter = participants.length !== 0 && participants[0].id === participantId
  const isPresentationMode = layoutMode === 3
  const someoneIsPresenting =
    participants&&participants.length!==0
    &&(streamers&&streamers.findIndex((s)=>{ return ((parseInt(s.name)===participants[0].id) && (s.videoType==='custom'||s.videoType==='screen')) } )!==-1)
  const isAttendee = (((isOnlinePoster||isVirtualRoom)&&!isChair&&!isCoach))
  const allowPresentation = !isAttendee&&((isPresentationMode && isPresenter) || (autoMode)) && publishing && !someoneIsPresenting && !publishingScreen && !isFullscreen && inCall && !isAttendee && speakerObj&&!speakerObj.promoted
  const allowScreenshare = !isAttendee&&((isPresentationMode && isPresenter) || (autoMode)) && publishing && !someoneIsPresenting && !presentingFile && !isFullscreen && inCall && !presentingQuestion && !presentingSpeaker && !isAttendee
  const sessionStarted = isStarted
  console.log(speakerObj)
  console.log(type)
  const showControls = (!!speakerObj) && !(canRaiseHands&&!canBroadcast) && hasOpenTokConfig && type!=='viewer' && layoutMode !== 1

  // Handle go Fullscreen, set state true
  const goFullscreen = () => {
    if (presentingFile||!sessionStarted) {
      return
    }
    if (isFullscreen) {
      try {
        document.exitFullscreen()
      } catch (e) {
        console.log(e)
      }
      setIsFullscreen(false)
    } else {
      setIsFullscreen(true)
      sessionState.videoContainerRef.current.requestFullscreen()
      // toggleFullscreen(true)
    }
    // if (fullscreenMode) {
    //   try {
    //     document.exitFullscreen()
    //   } catch (e) {
    //     console.log(e)
    //   }
    //   setStreaming({...streaming, fullscreenMode: false})
    // } else {
    //   setStreaming({...streaming, fullscreenMode: true})
    //   videoContainer.current.requestFullscreen()
    //     // toggleFullscreen(true)
    // }
  }


  // Handle screen sharing start/end
  const toggleScreensharing = () => {
    if (!allowScreenshare) {
      return
    }
    const newState = !publishingScreen
    if (!newState) {
      if (autoMode) {
        delShare(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
      }
      // setStreaming({...streaming, publishingScreen: newState, publishingCanvas: newState===false?false:publishingCanvas })
      setPublishingScreen(newState)
      setPublishingCanvas(newState===false?false:publishingCanvas)
    } else {
      if (autoMode) {
        setShare(api.entities.virtualRoom.present(null, null, {id: roomId}))
      } else {
        // setStreaming({...streaming, publishingScreen: true, publishingCanvas: true })
        setPublishingScreen(true)
        setPublishingCanvas(true)
      }
    }
  }

  // Open file selection for canvas sharing
  const toggleFileSelection = () => {
    if (!allowPresentation) {
      return
    }
    setAnchorEl(null)
    if (presentingFile) {
      // setStreaming({...streaming, presentingFile: false, publishingCanvas: false})
      setPresentingFile(false)
      setPublishingCanvas(false)
      if (autoMode) {
        delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
      }
    } else {
      if (autoMode) {
        setPresent(api.entities.virtualRoom.present(null,null,{id:roomId}))
      } else {
        if (!publishingScreen) {
          setSelectingFile(true)
        }
        if (presentingFilePage) {
          // setStreaming({...streaming, publishingCanvas: !publishingCanvas, presentingFilePage: publishingCanvas?0:presentingFilePage})
          setPublishingCanvas(!publishingCanvas)
          setPresentingFilePage(publishingCanvas?0:presentingFilePage)
        }
      }
    }
  }

  const toggleQuestionsPresent = () => {
    if (!allowPresentation) {
      return
    }
    setAnchorEl(null)
    if (autoMode) {
      if (presentingQuestion) {
        delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
      } else {
        setPresent(api.entities.virtualRoom.present(null,null,{id:roomId}))
      }
    }
    // setStreaming({...streaming, presentingQuestion: !presentingQuestion})
    // setStreaming((s)=>{ return {...s, presentingQuestion: !presentingQuestion} })
    setPresentingQuestion(!presentingQuestion)
  }

  const toggleSpeakerPresent = () => {
    if (!allowPresentation) {
      return
    }
    setAnchorEl(null)
    if (autoMode) {
      if (presentingSpeaker) {
        delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
      } else {
        setPresent(api.entities.virtualRoom.present(null,null,{id:roomId}))
      }
    }
    // setStreaming({...streaming, presentingQuestion: !presentingQuestion})
    // setStreaming((s)=>{ return {...s, presentingQuestion: !presentingQuestion} })
    setPresentingSpeaker(!presentingSpeaker)
  }

  // const raiseHand = () => {
  //   if (raisedHand) {
  //     sendDropHand(api.entities.virtualRoom.dropHand(null,null,{id:roomId}))
  //   } else {
  //     sendRaiseHand(api.entities.virtualRoom.raiseHand(null,null,{id:roomId}))
  //     setSentHand(true)
  //   }
  // }

  const toggleCall = () => {
    if (inCall&&raisedHand) {
      sendDropHand(api.entities.virtualRoom.dropHand(null,null,{id:roomId}))
    }
    setInCall(!inCall)
  }

  // Do not show controls if user is viewer and not able to raise hands (viewing video player)
  if (isViewer&&!canRaiseHands) {
    return <> </>
  }

  if (isViewer&&canRaiseHands&&!canBroadcast) {
    return <>
      <Grid item container className={classes.videoFooter}>
        <Grid item container justify='flex-end' alignContent='center' alignItems='center' spacing={4}>
          <Grid item>
            <Tooltip title="Go fullscreen" aria-label="full-screen">
              <Box mr={2} display='flex' onClick={goFullscreen} className={clsx(classes.button,(presentingFile||!sessionStarted)&&classes.disabledButton)}>
                {/*{fullscreenMode? <FullscreenExitIcon title='Close fullscreen' fontSize='large' style={{color:'red'}} /> : <FullscreenIcon title='Go fullscreen' fontSize='large'/> }*/}
                {isFullscreen? <FullscreenExitIcon title='Close fullscreen' fontSize='large' style={{color:'red'}} /> : <FullscreenIcon title='Go fullscreen' fontSize='large'/> }
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </>
  }

  // if (!showControls && canRaiseHands && !isChair && !isCoach && type!=='viewer') return <>
  //   <Grid item container className={classes.videoFooter}>
  //     <Grid item container justify='center' alignContent='center' alignItems='center' spacing={4}>
  //       <Grid item>
  //         <Tooltip title={raisedHand?`Drop hand`:`Raise hand`} aria-label="raise-hand">
  //           <Box display='flex' onClick={raiseHand} className={clsx(classes.button,(presentingFile||!sessionStarted)&&classes.disabledButton)}>
  //             <PanToolIcon title='Raise hand' fontSize='large' style={{color: raisedHand||sentHand?theme.palette.secondary.main:'inherit'}}/>
  //           </Box>
  //         </Tooltip>
  //       </Grid>
  //     </Grid>
  //   </Grid>
  // </>

  // Do not show if you can't broadcast
  if (!showControls) return <></>

  return <Grid item container className={classes.videoFooter}>
      <Grid item container justify='center' alignContent='center' alignItems='center' spacing={4}>

        <Grid item>
          <Tooltip title="Mute / unmute yourself" aria-label="mute-unmute">
            <Box onClick={() => toggleMicrophone()} className={clsx(classes.button,(!canBroadcast||!sessionStarted)&&classes.disabledButton)} >
              {publishAudio ? <IconMic title='Mute microphone' fontSize='large' /> : <IconMicOff title='Unmute microphone' style={{color: 'red'}} fontSize='large'/>}
            </Box>
          </Tooltip>
        </Grid>

        {/*<Grid item>*/}
        {/*  <Tooltip title="Start / stop your video" aria-label="start-stop-video">*/}
        {/*    <Box onClick={() => { if (deviceSetup&&deviceSetup.camera!==false&&canBroadcast&&sessionStarted) toggleCamera() }} className={clsx(classes.button,((deviceSetup&&deviceSetup.camera===false)||(!canBroadcast||!sessionStarted))&&classes.disabledButton)}>*/}
        {/*      {deviceSetup&&deviceSetup.startCamera? <IconCamera title='Turn off Camera' fontSize='large' /> : <IconCameraOff title='Turn on Camera' style={{color: 'red'}} fontSize='large' />}*/}
        {/*    </Box>*/}
        {/*  </Tooltip>*/}
        {/*</Grid>*/}

        <Grid item>
          <Tooltip title="Start / stop your video" aria-label="start-stop-video">
            <Box onClick={() => toggleCamera()} className={clsx(classes.button,((!videoSource)||(!canBroadcast||!sessionStarted))&&classes.disabledButton)}>
              {publishVideo? <IconCamera title='Turn off Camera' fontSize='large' /> : <IconCameraOff title='Turn on Camera' style={{color: 'red'}} fontSize='large' />}
            </Box>
          </Tooltip>
        </Grid>

        <Grid item>
          {/*<BootstrapTooltip title='The session is live, click here to join' open={sessionStarted&&canBroadcast&&!inCall}  placement="top" >*/}

          <Tooltip title={`${!canBroadcast?'You do not have broadcasting rights':inCall?'Click to stop broadcasting and leave the stage':'Click to start broadcasting and go on stage'}`} aria-label="start-stop-video">
            {<Box onClick={()=>{ if (canBroadcast&&sessionStarted) {toggleCall()} }} className={clsx(classes.button,(!canBroadcast||!sessionStarted)&&classes.disabledButton)} >
              <IconButton aria-label="Call" component="span">
              {!inCall?<CallIcon title='In call' fontSize='large' style={{color:'green'}} />:<CallEndIcon title='Exit call' fontSize='large' style={{color:'red'}} />}
              </IconButton>
            </Box>}
          </Tooltip>

          {/*</BootstrapTooltip>*/}
        </Grid>

        <Grid item>
          <Tooltip title="Share your screen" aria-label="share-your-screen">
          {<Box onClick={()=>{ if (allowScreenshare) toggleScreensharing()} } className={clsx(classes.button,(!allowScreenshare)&&classes.disabledButton)} >
            {!publishingScreen?<IconShareScreen title='Share screen' fontSize='large' />:<IconShareScreenOff title='Stop sharing screen' fontSize='large' style={{color:'red'}} />}
          </Box>}
          </Tooltip>
        </Grid>

        {(canPresentFile||canPresentQuestions||canPresentSpeaker)&&<Tooltip title="Present a pdf file, question or speaker" aria-label="share-your-screen"><Grid item>
          {!presentingFile&&!presentingQuestion&&!presentingSpeaker&&
            <React.Fragment>
            <Box onClick={(e)=>{if (allowPresentation) handleClick(e)}} className={clsx(classes.button,(!allowPresentation)&&classes.disabledButton)}>
                {!presentingFile&&<IconPresentScreen title='Present a document/presentation file' fontSize='large' />}
                {presentingFile&&<IconPresentScreenOff title='Stop presenting' fontSize='large' style={{color:'red'}} />}
              </Box>

            <Menu
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              // anchorReference={'anchorPosition'}
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)&&!presentingFile&&!presentingQuestion}
              onClose={handleClosePresent}
              videoContainerRef={videoContainerRef.current}
              disableScrollLock
            >
              {canPresentFile&&<MenuItem onClick={toggleFileSelection}>Present PDF</MenuItem>}
              {canPresentQuestions&&<MenuItem onClick={toggleQuestionsPresent}>Present question</MenuItem>}
              {canPresentSpeaker&&<MenuItem onClick={toggleSpeakerPresent}>Present speaker</MenuItem>}
            </Menu>

          </React.Fragment>}

          {(presentingFile||presentingQuestion||presentingSpeaker)&&<Box onClick={
            (e) => {
              if (presentingFile) {
                toggleFileSelection(e)
              }
              if (presentingQuestion) {
                toggleQuestionsPresent(e)
              }
              if (presentingSpeaker) {
                toggleSpeakerPresent(e)
              }
            }
          } className={clsx(classes.button,(!allowPresentation)&&classes.disabledButton)}>
            <IconPresentScreenOff title='Present' fontSize='large' style={{color:'red'}} />
          </Box>}

        </Grid></Tooltip>}

        <Grid item>
          <Tooltip title="Go fullscreen" aria-label="full-screen">
            <Box display='flex' onClick={goFullscreen} className={clsx(classes.button,(presentingFile||!sessionStarted)&&classes.disabledButton)}>
              {/*{fullscreenMode? <FullscreenExitIcon title='Close fullscreen' fontSize='large' style={{color:'red'}} /> : <FullscreenIcon title='Go fullscreen' fontSize='large'/> }*/}
              {isFullscreen? <FullscreenExitIcon title='Close fullscreen' fontSize='large' style={{color:'red'}} /> : <FullscreenIcon title='Go fullscreen' fontSize='large'/> }
            </Box>
          </Tooltip>
        </Grid>

        <Grid item style={{position:'absolute',bottom:0,right:16}}>
          <Tooltip title="Device Setup" aria-label="device_setup">
            <Box display='flex' onClick={()=>openDeviceSetup()} className={clsx(classes.button)}>
              <TuneIcon fontSize='large' />
            </Box>
          </Tooltip>
        </Grid>

      </Grid>
  </Grid>

})

export default VideoFooter
