import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'
import {FILES_URL} from 'config'
import FaceIcon from '@material-ui/icons/Face'
import Chip from '@material-ui/core/Chip'
import ParticipantToolTip from 'components/core/ParticipantToolTip'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  avatar: {
    color: 'white !important',
    background: 'none',
    '&> img':{
      maxWidth: '100%'
    }
  },
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 240,
    border: '1px solid #fff',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  chip: {
    color: 'white',
    borderColor: 'white'
  }
}))

export default function ParticipantChip({participantObj, presenter}) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
        <Chip
          avatar={<Avatar className={classes.avatar}>{participantObj.image?<img src={`${FILES_URL}/${participantObj.image}`} alt={`${participantObj.firstName} ${participantObj.lastName}`}/>:<FaceIcon />}</Avatar>}
          variant="outlined"
          color={presenter?'secondary':'primary'}
          label={`${participantObj.title?participantObj.title:''} ${participantObj.firstName} ${participantObj.lastName}`}
          onClick={handleClick} className={classes.chip} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box className={classes.tooltip}>
          <ParticipantToolTip participant={participantObj} />
        </Box>
      </Popover>
    </div>
  )
}
