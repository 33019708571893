import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {useHttp} from 'api/core'
import api from 'api'
import CircularProgress from '@material-ui/core/CircularProgress'
import RootRef from '@material-ui/core/RootRef'
import DialogContent from '@material-ui/core/DialogContent'
import FormReview from 'components/core/Forms/FormReview'
const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    // backgroundColor: palette['cerulean-blue'],
    // color: 'white',
    borderRadius: '6px'
  },
  newFormType: {
    // width: '580px'
  },
  row: {
    marginBottom: theme.spacing(5)
  },
  tabsBar: {
    backgroundColor: palette['very-light-pink']
  },
  tab: {
    width: '100%',
    color: 'white',
    backgroundColor: palette['cerulean-blue'],
    padding: theme.spacing(2)
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    '&:focus': {
      backgroundColor: palette.aquamarine
    }
  },
  tabLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  addNewItem:{
    // backgroundColor: 'white',
    // padding: theme.spacing(2),
    // borderRadius: '6px',
    border: '2px dashed !important',
    // borderColor: palette['cerulean-blue'],
    // justifyContent: 'center',
    // alignItems: 'center',
    // fontWeight: 'bold',
    // color: palette['greyish-brown'],
    // fontSize: '18px',
    opacity: 0.5,
    margin: 0,
    cursor: 'pointer',
    transition: '0.4s all',
    '&:hover': {
      opacity: 0.8
    }
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: '1px solid',
    borderRadius: '6px',
    borderColor: 'white',
    backgroundColor: 'rgba(0,0,0,0.1)',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  formControl: {
    width: '100%'
  },
  toolbar: {
    borderTop: '1px solid',
    color: 'white',
    marginTop: theme.spacing(2),
  },
  editor: {
    color: palette['greyish-brown'],
    borderRadius: '6px',
    minHeight: '300px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  dialogActions: {
    marginTop: theme.spacing(2)
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  mainBox: {
    marginTop: theme.spacing(3),
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
    minHeight: '350px',
    // overflowY: 'auto'
    // minHeight: 'calc(80vh - 300px)'
  }
})

// function NumberFormatCustom(props) {
//     const { inputRef, onChange, ...other } = props;
//
//     return (
//       <NumberFormat
//         {...other}
//         getInputRef={inputRef}
//         onValueChange={(values) => {
//             onChange({
//                 target: {
//                     name: props.name,
//                     value: values.value,
//                 },
//             });
//         }}
//         isNumericString
//         suffix="%"
//       />
//     );
// }

const ReviewersFrom = withStyles(styles)(({classes, handleClose, formPreview, eventId }) => {

  //eslint-disable-next-line
  const [getData, data, isLoading, hasError] = useHttp()
  //eslint-disable-next-line
  const [name, setName] = useState()
  const [fields, setFields] = useState()
  const [valid, setValid] = useState(true)
  const domRef = React.useRef()
  const [getThematicCodes, thematicCodes] = useHttp()

  useEffect(()=>{
    getData(api.entities.manage.getReviewerResponse(null,null,{id: formPreview.id}))
    getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setName(data.name)
      setFields(data.fields)
    }
  },[data])

  useEffect(()=>{
    if (hasError){
      handleClose()
    }
    //eslint-disable-next-line
  },[hasError])

  // const isSubmitting = isLoading&&!hasError


  return <RootRef rootRef={domRef}>
    <DialogContent><Box className={classes.container}>
      <Grid item container spacing={1} className={classes.row}>
        <Typography variant='h5' component='h1'> {'Reviewer response'}</Typography>
      </Grid>

      <Grid item container className={classes.mainBox}>
        <Grid item container spacing={2}>
          {(!(thematicCodes&&data))&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' width={'100%'} height={'100%'} p={6} minHeight={200}>
            <Box display='flex'>
              <CircularProgress size={'8rem'} color='secondary' />
            </Box>
          </Box>}
          {fields&&thematicCodes&&<FormReview fields={fields} setFields={setFields} name={name} setName={setName} valid={valid} setValid={setValid} thematicCodes={thematicCodes}/>}
        </Grid>
      </Grid>

      <Grid item container spacing={3} style={{marginTop: '16px'}}>
        <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center'>
          <Grid item>
            <Button size='small' onClick={handleClose} > Close </Button>
          </Grid>
        </Grid>
      </Grid>

    </Box></DialogContent></RootRef>
})

export default ReviewersFrom
