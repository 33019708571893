import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {FastField, Field, FieldArray, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import {
  CHART_TYPES
} from 'config'
import api from 'api'
import {useHttp} from 'api/core'
import MenuItem from '@material-ui/core/MenuItem'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import {IconAddBorderLight, IconXBorderDark,} from 'components/core/icons'
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    // backgroundColor: palette['cerulean-blue'],
    // color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  divider: {
    // backgroundColor: 'white'
  },
  newFeedback: {
    maxWidth: '768px'
  },
  formControl: {
    width: '100%'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  btns: {
    paddingTop: theme.spacing(3),
    textAlign: 'center'
  },
  ordinal: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    width: '100%',
    height: '100%'
  },
  blockTitle: {
    backgroundColor: '#d3f9ff',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(6),
    width: '140px',
    height: '60px',
    textAlign: 'center'
  },
  fieldTitleBlock: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: palette['cerulean-blue']
  },
  fieldValueBlock: {
    fontSize: '14px',
    justifyContent: 'center'
  },
  expansionPanel: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
    width: '100%',
    '&:before': {
      display: 'none'
    }
  },
  expandIcon: {
    // color: 'white',
  },
  expansionPanelFocused: {
    backgroundColor: 'transparent !important'
  },
  expansionPanelSummary: {

  },
  expanded: {
    margin: '0 !important'
  },
  dragGrip: {
    position: 'absolute',
    left: theme.spacing(1),
    top: theme.spacing(4),
    width: theme.spacing(5),
    height: theme.spacing(6),
    zIndex: 49
  },
  question: {
    position: 'relative',
    paddingLeft: theme.spacing(2)
  },
  previewBox: {
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    minHeight: '250px',
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    border: `1px solid ${palette['brown-grey']}`
  },
})

const QuestionContainer = withStyles(styles)(({classes,feedback, dragHandleProps,  isSubmitting, errors,arrayHelpers,values,index})=>{
  return <>
    <Grid container key={index} spacing={2} className={classes.question}>
      <Box className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>
      <ExpansionPanel classes={{root: classes.expansionPanel, expanded: classes.expanded}} defaultExpanded={!feedback||values.questions[index].options[0]===''}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{expandIcon: classes.expandIcon, root: classes.expansionPanelSummary, focused: classes.expansionPanelFocused}}
        >
          <Grid container item spacing={2}>

            <Grid item xs={12} sm={3}>
              {/*<FormControl className={classes.formControl}  onClick={event => event.stopPropagation()}>*/}
              {/*  <Field type='select' labelId={`questions[${index}]chartTypeLabel`} displayEmpty name={`questions[${index}].chartType`} component={Select} label='Chart type' variant='filled' fullWidth disabled={isSubmitting}>*/}
              {/*    {CHART_TYPES.map((chartType, i)=>{*/}
              {/*      return <MenuItem value={chartType.id} key={i}>{chartType.name}</MenuItem>*/}
              {/*    })}*/}
              {/*  </Field>*/}
              {/*  <InputLabel variant='filled'>Chart type</InputLabel>*/}
              {/*  {errors&&errors[`questions[${index}].chartType`]&&<FormHelperText error variant='filled'> {errors[`questions[${index}].chartType`]} </FormHelperText>}*/}
              {/*</FormControl>*/}

              <Field
                component={TextField}
                type="text"
                name={`questions[${index}].chartType`}
                label="Chart type"
                select
                variant='filled'
                fullWidth
                defaultValue={0}
                onClick={(e)=>e.stopPropagation()}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {CHART_TYPES.map((chartType, i)=>{
                  return <MenuItem value={chartType.id} key={i}>{chartType.name}</MenuItem>
                })}
              </Field>
            </Grid>
            <Grid item xs={10} sm={9}>
              <FastField type='text' name={`questions[${index}].question`} onClick={event => event.stopPropagation()} component={TextField} label='Question'
                     variant='filled' fullWidth disabled={isSubmitting} InputProps={{
                endAdornment: <InputAdornment position='end'>
                  <IconButton aria-label='delete question' onClick={() => values.questions.length!==1&&arrayHelpers.remove(index)}>
                    <IconXBorderDark/>
                  </IconButton>
                </InputAdornment>,
              }}/>
            </Grid>

          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid item container spacing={2}>
            <FieldArray
              name={`questions[${index}].options`}
              render={arrayHelpers => (
                <>
                  {values.questions[index].options&&values.questions[index].options.map((option,y)=>
                    <React.Fragment key={y}>
                      <Grid item xs={2}> &nbsp; </Grid>
                      <Grid item xs={10}>
                        <FastField type='text' name={feedback?`questions[${index}].options[${y}].option`:`questions[${index}].options[${y}]`} component={TextField} label={`Option ${y+1}`}
                               variant='filled' fullWidth disabled={isSubmitting}
                               InputProps={{
                                 endAdornment: <InputAdornment position='end'>
                                   <IconButton aria-label='delete option' onClick={() => values.questions[index].options.length!==1&&arrayHelpers.remove(y)}>
                                     <IconXBorderDark/>
                                   </IconButton>
                                 </InputAdornment>,
                               }}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid item xs={12} container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                    <Grid item>
                      <Button size='small' variant='outlined' onClick={() => arrayHelpers.push(' ')}>
                        <IconAddBorderLight/> Add Option
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            />
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>

    <Grid item xs={12}>
      <Grid item>
        <Box pb={2} pt={2}>
          <Divider classes={{root:classes.divider}} />
        </Box>
      </Grid>
    </Grid>

  </>
})

const LivePollEditNew = withStyles(styles)(({classes, feedbackId, sessionId, handleClose, ...props}) => {
  const [formData, setFormData] = useState()
  const [feedback, setFeedback] = useState()
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isSubmitting] = useHttp()
  const [getRequest, getData, isGetting] = useHttp()

  useEffect(()=>{
    if (feedbackId!==true) {
      getRequest(api.entities.feedback.getFeedback(null,null,{id:feedbackId}))
    } else {
      setFormData({questions:[], ...getData})
    }
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (getData) {
      setFeedback(getData)
      setFormData({questions:[], ...getData})
    }
    //eslint-disable-next-line
  },[getData])

  useEffect(() => {
    if (putData) {
      handleClose(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      const {questions, id, chartType, title, ...restValues} = formSave
      let payLoad = {
        title,
        questions,
        chartType,
        public: restValues.public,
        sessionId
      }

      // If existing feedback
      if (feedback) {
        sendRequest(api.entities.feedback.update(payLoad, null, {id:feedback.id}))
      } else { // else add new feedback
        sendRequest(api.entities.feedback.addNew(payLoad, null, null))
      }

      setFormSave(undefined)
    }
    //eslint-disable-next-line
  }, [formSave, sendRequest, feedback])

  const onDragEnd = (r, arrayHelpers,values, setFieldValues) => {
    if (!r.destination) return

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    // arrayHelpers.move(r.source.index,r.destination.index)
    let newArr = [...values.questions]

    array_move(newArr, r.source.index, r.destination.index)
    // newArr.splice(r.source.index,1)
    // newArr.splice(r.destination.index, 0, r.draggableId)
    setFieldValues('questions', newArr)
  }

  if (isGetting||!formData) {
    return <Box className={classes.previewFeedback}>
      <Box display='flex' flexDirection='column'>
        <Box className={classes.container}>
          <CircularProgress size={'8rem'} color='secondary' />
        </Box>
      </Box>
    </Box>
  }


  return <Box className={classes.newFeedback}><ThemeProvider theme={theme}>
    <Formik
      initialValues={{questions:[{question:'',options:['']}], chartType:1, public: false, ...feedback, sessionId:feedback?feedback.sessionId==null?'-':feedback.sessionId:'-'}}
      // validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, setFieldTouched}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>
            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {feedback ? 'Edit feedback questions' : 'Add new feedback'}</Typography>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Feedback Details</Typography>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name={'title'} component={TextField} label='Title'
                         variant='filled' fullWidth disabled={isSubmitting} />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='h6' component='h2'> Questions </Typography>
                </Grid>
                
                <Grid item xs={12}>

                  <FieldArray
                    name='questions'
                    render={arrayHelpers => (
                      <>
                      <DragDropContext onDragEnd={(r)=>onDragEnd(r,arrayHelpers,values,setFieldValue)}>
                        <Droppable droppableId={`${0}`}>
                          {(provided) => {
                            return <Grid item container ref={provided.innerRef} {...provided.droppableProps}>
                              {values.questions.map((question, index) => (
                                <Draggable key={index}
                                  draggableId={`${index}`} index={index}>
                                  {(provided) => (
                                    <Box {...provided.draggableProps}
                                         ref={provided.innerRef} width={'100%'}><QuestionContainer feedback={feedback} isSubmitting={isSubmitting} errors={errors} dragHandleProps={provided.dragHandleProps}
                                                                                    arrayHelpers={arrayHelpers} values={values} index={index}/></Box>)
                                  }
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </Grid>
                          }}
                        </Droppable>
                      </DragDropContext>

                        <Grid item container justify='flex-end' alignContent='flex-end' alignItems='flex-end'>
                          <Grid item>
                            <Button size='small' variant='outlined' onClick={() => arrayHelpers.push({ question: '', options: [''] })}>
                              <IconAddBorderLight/> Add Question
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />

                </Grid>

                <Grid container spacing={3} className={classes.btns}>
                <Grid item xs='auto' md={2}> </Grid>
                <Grid item xs={12} md={4}>
                  <Button variant='outlined' size='small' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                </Grid>
              </Grid>
              </Grid>
              {/*{feedback&&<>*/}
              {/*<Box display='flex' flexDirection='column' justifyContent='center' className={classes.blockTitle}>*/}
              {/*  <Box display='flex' className={classes.fieldTitleBlock} p={1} pb={0}> Code Number </Box>*/}
              {/*  <Box display='flex' className={classes.fieldValueBlock} p={1}> {feedback.code} </Box>*/}
              {/*</Box>*/}
              {/*</>}*/}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default LivePollEditNew
