import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import TicketCardView from 'components/core/TicketCardView'
import {useSnackbar} from 'notistack'
import TicketDialog from 'components/pages/Event/Tickets/TicketDialog'
import {Redirect} from 'react-router-dom'
import LoginSignupDialog from 'components/core/LoginSignupDialog'
import {useAuth} from 'auth/AuthController'
import {ReactComponent as NoTicket} from 'assets/placeholders/no_tickets.svg'
import { palette } from 'theme'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Button from "components/core/Button";
import language from "components/language";
import moment from 'moment'

const ticketStyles = theme => ({
  noOfTickets: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '50%',
    fontSize: '24px',
    userSelect: 'none'
  },
  noSelect: {
    userSelect: 'none'
  },
  clickable: {
    cursor: 'pointer'
  },
  dialog: {
    backgroundColor: 'white'
  },
  disabled: {
    cursor: 'default',
    opacity: 0.3
  }
})

export const Tickets = withStyles(ticketStyles)(({ classes, event, isPreview, canManage = false }) =>
{
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  
  useEffect(() =>  {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  },[])
  useEffect(() =>
  {
    if (event && timeZones) {
      const zone = timeZones.find((x) => x.id === event.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timeZones]);

  const [tickets, setTickets] = useState()
  const [getTicketsData, ticketsData, , hasError] = useHttp()

  const [isEnabled, setEnabled] = useState(false);
  const [selectOption, setSelectOption] = useState();
  
  	useEffect(() => {
      setSelectOption(language.showInstructions);
      //eslint-disable-next-line
    }, [ticketsData]);  
  
	useEffect(() => {
		isEnabled
			? setSelectOption(language.hideInstructions)
			: setSelectOption(language.showInstructions);
		//eslint-disable-next-line
	}, [isEnabled]);  

  useEffect(() => {
    if (isPreview) {
      getTicketsData(api.entities.registrations.getTickets(null, null, {id: event.eventInfo.id})) // TODO: PREVIEW ENDPOINT
    } else {
      getTicketsData(api.entities.registrations.getTickets(null, null, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (ticketsData) {
      setTickets(ticketsData)
    }
  }, [ticketsData])

  useEffect(()=>{
    if (hasError) {
      if (hasError.status===401) {

      }
    }
  },[hasError])

  const [openDialog, setOpenDialog] = useState(false)
  const [buyingTicket, setBuyingTicket] = useState()
  const [openLoginSignup, setOpenLoginSignUp] = useState(false)
  const [isAuthenticated] = useAuth()
  const { enqueueSnackbar } = useSnackbar()

  const handleClose = (shouldReload) => {
    setOpenDialog(false)
    if (shouldReload===true) {
      enqueueSnackbar('Success! An email with your ticket information is on the way', {variant:'success'})

      if (isPreview) {
        getTicketsData(api.entities.registrations.getTickets(null, null, {id: event.eventInfo.id})) // TODO: PREVIEW ENDPOINT
      } else {
        getTicketsData(api.entities.registrations.getTickets(null, null, {id: event.eventInfo.id}))
      }

    }
    setBuyingTicket(null)
  }

  const handleOpen = (ticket) => {
    setBuyingTicket(ticket)
    if (!isAuthenticated) {
      setOpenLoginSignUp(true)
    } else {
      setOpenDialog(true)
    }
  }

  const handleCloseLogin = () => {
    setOpenLoginSignUp(false)
  }

  useEffect(()=>{
    if (isAuthenticated&&buyingTicket) {
      setOpenDialog(true)
    }
  },[buyingTicket, isAuthenticated])

  if (hasError&&hasError.status===401) {
    return <Redirect to={`/login/?returnUrl=/event/${event.eventInfo.id}/tickets`}/>
  }

  if (!tickets) { // Loading
    return <> </>
  }

  const createMarkup = (value) => {
    return {__html: value};
  };

  return <>
    {openLoginSignup&&<LoginSignupDialog open={openLoginSignup} onClose={handleCloseLogin}/>}
    {openDialog&&buyingTicket&&<TicketDialog open={openDialog} handleClose={handleClose} event={event} buyingTicket={buyingTicket}/>}

    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pb={12} flexDirection='column'>
        <Box pb={3}>
          <Box component='h2' fontWeight='bolder' fontSize={24} mb={1}>{language.registration}</Box>
        </Box>
        <Box pb={1}>
          <Box component='h3' fontWeight='bolder' fontSize={15} mb={1}>{event.registrationInfoDesc}</Box>
        </Box>
        <Box pb={5}>
          <Box component='h3' fontWeight='bolder' fontSize={15} mb={2}>{event.registrationInfoCost}</Box>
          {event.registrationInstructions !== null && (<>
            <Box component='h2' fontWeight='bolder' fontSize={12} mb={1}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isEnabled}
                      onChange={(e) => { setEnabled(e.target.checked)}}                                                
                      color='primary'                                          
                    />
                  }
                  label={selectOption}
                />
            </Box> 
            {isEnabled &&
              (<Box component='p' fontSize={15} mb={1}>              
              {event.registrationInstructions ? <div dangerouslySetInnerHTML={createMarkup(event.registrationInstructions)} key={event.registrationInstructions} /> : ''}
              </Box>)
            }
          </>)}
        </Box>        
        {event.externalRegistration === false ? (<> 
          {tickets.length===0&&
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'>
          <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' alignItems='center'>
            <Box display='flex'>
              <NoTicket/>
            </Box>
            <Box display='flex' pt={4} color={palette['greyish-brown']}>
              {language.noTicketsAvailableYet}
            </Box>
          </Box></Box>}
          {tickets.map((ticket,i) => {
            return <TicketCardView ticket={ticket} event={event} handleOpen={handleOpen} p={1} index={i}/>;
          })}
          </>)        
        :        
         (<Box display='flex' flexDirection='row' mt={2} p={3}>
            <Box display='flex' p={2} pb={6} pl={22} flexDirection='row'>
              <Box display='flex' mr={2}>
                <Button type='submit' variant='outlined' color='primary' onClick={()=> window.open(event.externalRegistrationUrl, "_blank")}>
                {event.externalRegistrationButtonLabel}
                </Button>
              </Box>
            </Box>
          </Box>)
        }
      </Box>
    </Fade>
  </>
})
