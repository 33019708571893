import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography, Grid} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Page from 'components/core/Page'
import Hero from 'components/core/Hero'
import SubFooter from 'components/core/Footer/SubFooter'
import Button from 'components/core/Button'
import Header from "components/core/Header/Header";
import HomeSplashScreen from "assets/home.png";
import Footer from "components/core/Footer/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import LiveStream from 'assets/ui-screens/ui-live-stream.png'
import VirtualEventApps from 'assets/ui-screens/ui-virtual-event-apps.png'
import VirtualEvent from 'assets/ui-screens/ui-virtual-event.png'

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  },
  OnlineSessionsImage: {
    width: '950px',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  manageImage: {
    width: '483px',
    // boxShadow: '0 10px 35px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  sectionText: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },
  sectionTitle: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },
  OnlineSessionsTitle: {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '40px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4),
    marginTop: 64
  },
  OnlineSessionsContent: {
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4)
  },
  featuresList: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    minHeight: 500
  },
  contactTitle:{
    color:'#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing:'-0.5px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactMoto:{
    color:'#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing:'0.2px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  featureGroup: {
    fontSize: 24
  }

})

const TheVirtualSession = withStyles(styles)(({classes}) => {

  return <div style={{marginTop: -64}}>
    <Header animate={true} />
    <Page title='TheVirtualSession' noFooter>
      <Hero image={HomeSplashScreen} minHeight={300} >
        <Grid container direction={'column'} justify={'center'} alignContent={'center'} alignItems={'center'}>
          <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={100} offset={0}>
            <Grid item lg={12}>
              <Typography align={'center'} className={classes.OnlineSessionsTitle}>The virtual session</Typography>
            </Grid>
          </ScrollAnimation>
          {/*<Grid item lg={5}>*/}
          {/*  <Box p={2}>*/}
          {/*    <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={200}>*/}
          {/*      <Typography align={'center'} className={classes.OnlineSessionsContent}>*/}
          {/*        An all-inclusive solution to managing your next workshop, meeting or conference.*/}
          {/*        Spend less time organizing your event, and more time participating in it!*/}
          {/*      </Typography>*/}
          {/*    </ScrollAnimation>*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Hero>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={400}>

      <Hero backgroundColor={'#ffffff'} minHeight={300}>
        <Box pt={6} pb={6}>
          <Container>
            <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                  spacing={2}>
              <Grid item lg={6}>
                <img src={LiveStream} alt='Live stream' className={classes.manageImage}/>
              </Grid>
              <Grid item lg={6}>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant={'body1'} className={classes.sectionText}>
                      There are many options to choose from when planning sessions for your event. Use our flagship virtual sessions modes Anymeets Broadcast and Anymeets Live to hold presentations, panel discussions, lectures and keynotes with an unlimited audience
                    </Typography>
                  </Box>
                </Box>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant={'body1'} className={classes.sectionText}>
                      For smaller sessions, workshops, tutorials and trainings (up to 50 participants), set up your sessions with Jitsi and take advantage of all the features of our flagship modes
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Hero>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
        <Box style={{backgroundColor: '#f8f8f8'}} pt={2} pb={2}>
          <Container>
            <Box pt={2} pb={2}>
              <Typography className={classes.sectionTitle} align={'center'}>
                Integration with popular video streaming and video conferencing services
              </Typography>
            </Box>
          </Container>
        </Box>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={800}>
      <Hero backgroundColor={'#ffffff'} minHeight={300}>
        <Box pt={8} pb={6}>
          <Container>
            <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                  spacing={2}>

              <Grid item lg={6}>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant={'body1'} className={classes.sectionText}>
                      Don’t want to move away from your favorite video conferencing service? We’ve got you covered!
                    </Typography>
                  </Box>
                </Box>
                <Box p={2}>
                  <Box mb={2}>
                    <Typography variant={'body1'} className={classes.sectionText}>
                      Do away with the emails and links and allow your event attendees to enter online sessions set up with popular video conferencing services directly from your event agenda<sup>1</sup>
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item lg={6}>
                <img src={VirtualEventApps} alt='Virtual Event Apps' className={classes.manageImage}/>
              </Grid>

            </Grid>
          </Container>
        </Box>
      </Hero>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
        <Box style={{backgroundColor: '#f8f8f8'}} pt={2} pb={2}>
          <Container>
            <Box pt={2} pb={2}>
              <Typography className={classes.sectionTitle} align={'center'}>
                Live stream and show recordings
              </Typography>
            </Box>
          </Container>
        </Box>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={800}>
        <Hero backgroundColor={'#ffffff'} minHeight={300}>
          <Box pt={8} pb={6}>
            <Container>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                    spacing={2}>

                <Grid item lg={6}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant={'body1'} className={classes.sectionText}>
                        Live stream videos or show recordings without ever leaving the Anymeets website.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6}>
                  <img src={VirtualEvent} alt='Live stream or show recording without leaving the anymeets website' className={classes.manageImage}/>
                </Grid>

              </Grid>
            </Container>
          </Box>
        </Hero>
      </ScrollAnimation>


      <Box style={{backgroundColor: '#f7f8fa'}} p={8}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item lg={6}>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
              <Typography className={classes.sectionTitle} align={'center'}>
                Hybrid events
              </Typography>
              <br/>
              <Typography className={classes.sectionText} align={'center'}>
                Hosting a hybrid event? Not a problem! Hold your session live and stream it in real-time to the rest of the world through RTMP.
              </Typography>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Box>

      {/*<Box style={{backgroundColor: '#f7f8fa'}} p={8}>*/}
      {/*  <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>*/}
      {/*    <Grid item lg={6}>*/}
      {/*      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>*/}
      {/*        <Typography className={classes.sectionText} align={'center'}>*/}
      {/*          Anymeets comes bundled with a mobile application with sharing and social features, which enables*/}
      {/*          participants*/}
      {/*          to check the progress of their submissions and gain instant access to the event program, encourages direct*/}
      {/*          interaction between participants and allows organizers to send announcements and notifications.*/}
      {/*        </Typography>*/}
      {/*      </ScrollAnimation>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*</Box>*/}


      <Hero backgroundColor={'#107AAD'} minHeight={400}>
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
            <Grid item lg={12}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
                <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item lg={8}>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={300}>
                <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a demo</Typography>
              </ScrollAnimation>
            </Grid>
            <Grid item>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
                <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
              </ScrollAnimation>
            </Grid>
        </Grid>
      </Hero>

      <Box style={{backgroundColor: '#f7f8fa', fontSize: 11}} p={2} pb={3}>
        <Container>
          <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>
            <Grid item>
              <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
                <sup>1</sup>Event organizers will need to have their own account and/or subscription in order to use video conferencing services such as Zoom. Anymeets does not provide licenses or accounts for these external services.
              </ScrollAnimation>
            </Grid>
          </Grid>
        </Container>
      </Box>


      <SubFooter/>
      <Footer />
    </Page>
  </div>
})

export default TheVirtualSession
