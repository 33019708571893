import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import clsx from 'clsx'
import SendIcon from '@material-ui/icons/Send'

import {
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  Tooltip
} from '@material-ui/core'
import api from 'api'
import {useHttp} from 'api/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {imageFile} from 'utils'
import {TIME_SESSION_HEARTBEAT} from 'config'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import Button from '@material-ui/core/Button'
import momentTz from 'moment-timezone'
import List from '@material-ui/core/List'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined'
import QnAAnswers from 'components/core/OpenTok/Tabs/QnA/QnAAnswers'

const styles = theme => ({
  container: {
    paddingTop: 0,
    // borderRadius: '6px',
    // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    // border: 'solid 1px #e6e6e6',
    color: palette['greyish-brown'],
    // height: "calc(100vh - 310px)"
    // backgroundColor: 'rgba(0, 0, 0, 0.06)'
  },
  feed: {
    width: '100%',
    fontSize: '18px',
    fontWeight: 'bold',
    height: 'calc(100vh - 320px)',
    overflow: 'hidden',
    paddingTop: 0
  },
  disabled: {
    opacity: 0.5
  },
  chatInput: {
    marginTop: theme.spacing(2)
  },
  questionBox: {
    backgroundColor: '#ffffff',
    padding: '8px 8px 4px 8px',
    borderRadius: 4,
    minWidth:'100%',
    color: '#615c5c',
    height: 'auto',
    border: '0.03em solid #dadada',
    [theme.breakpoints.down('sm')]: {
      minWidth:'250px'
    }
  },
  answerBox: {
    backgroundColor: '#f9f9f9',
    padding: '8px',
    borderRadius: 4,
    color: '#615c5c',
    minWidth: '120px',
    height: 'auto',
    border: '0.03em solid #dadada'
  },
  userTitle: {
    fontWeight: 'bolder'
  },
  userCommentLink: {
    marginLeft: 60,
    fontWeight: '400',
    cursor: 'pointer',
    color: '#0DC7E5'
  },
  userDeleteQuestion: {
    fontWeight: '400',
    cursor: 'pointer',
    color: '#e50035'
  },
  userTimeStamp: {
    fontWeight: 100,
    fontSize: '10px',
    color: '#716b6f'
  },
  userPhoto: {
    display: 'flex',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    border: '0.03em solid #dadada',
    alignContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  controls: {
    position: 'relative',
    right: theme.spacing(2),
    textAlign: 'right',
    width: '100%',
    '& img': {
      marginLeft: theme.spacing(1),
      width: '100px',
      height: 'auto'
    },
    // [theme.breakpoints.down(990)]: {
    //   width: '150px',
    //   lineHeight: '25px'
    // },
    // [theme.breakpoints.down('sm')]: {
    //   position: 'relative',
    //   width: '100%',
    //   textAlign: 'center',
    //   lineHeight: '30px',
    // }
  },
  commentsBox: {
    minHeight: '100px'
  },
  list: {
    width: '100%',
    color: 'rgba(0,0,0,0.6)'
  },
  listItem: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: 4,
    border: 'solid 1px #e6e6e6',
    marginBottom: theme.spacing(1)
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'rgba(0,0,0,0.6)'
  },
  message: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'black',
    marginBottom: theme.spacing(2),
    overflowWap: 'break-word',
    wordWrap: 'break-word',
    msWorBreak: 'break-all',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    mozHyphens: 'auto',
    webkitHyphens: 'auto',
    hyphens: 'auto'
  },
  avatarRoot: {
    minWidth: 40
  },
  votesBtn: {
    borderRadius: 16,
    fontSize: 12,
    fontWeight: 'bold',
    padding: '2px 0px',
    minWidth: 46,

  },
  btnLbl: {
    width: 5
  },
  answer: {
    borderColor: '#d0d0d0',
    backgroundColor: '#e6e6e6',
    marginTop: -theme.spacing(1),
    // borderTop: 'none',
    borderRadius: 0,
    marginBottom: 0
  },
  questionOpened: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  messageAnswer: {
    color: 'rgba(0,0,0,0.5)',
    wordBreak: 'break-all'
  },
  inputElement: {
    border: 'none'
  },
  inputQuestion: {
    border: '1px solid #e6e6e6 !important',
    borderRadius: 4,
  },
  inputRoot: {
    border: '1px solid #e6e6e6 !important',
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTop: 'none',
    marginBottom: theme.spacing(1)
  },
  answerInput: {
    backgroundColor: 'white'
  },
  buttonRoot: {
    backgroundColor: 'white'
  }
})

const QnAWall = withStyles(styles)(({classes, sessionState, disabled}) => {

  const {
    isCoach,
    isChair,
    isSpeaker,
    roomId
  } = sessionState

  // const [feedSize, setFeedSize] = useState('auto')

  const [setQuestion, questionData] = useHttp()
  const [setAnswer, answerData] = useHttp()
  const [setUpVote, upVoteData] = useHttp()
  const [undoUpVote, unVoteData] = useHttp()
  const [deleteQuestion,questionIsDeleted] = useHttp()
  const [questionsOnFeed, setQuestionsOnFeed] = useState([])
  const [getQuestions, receivedQuestions] = useHttp(true)
  const [getAnswers, receivedAnswers, answersLoading] = useHttp()
  const [openComments, setOpenComments] = useState({})
  const [canReply, setCanReply] = useState(false)
  const [localTimeZone, setLocalTimeZone] = useState()

  const [isAnonymous, setIsAnonymous] = useState(false)
  const [questionText, setQuestionText] = useState('')
  const [answerText, setAnswerText] = useState('')

  // References
  const poller = useRef(null)

  //const [scrollBar, setScrollBar] = useState()

  // const domRef = React.useRef()
  //
  // const resizeFeed = () => {
  //   const currentPos = domRef.current.getBoundingClientRect()
  //   setFeedSize(window.innerHeight - 500)
  // }
  // const debounceResizeFeed = debounce(
  //   resizeFeed,
  //   200
  // )

  // useEffect(() => {
  //   //resize listener
  //   // window.addEventListener('resize', debounceResizeFeed);
  //   // resizeFeed()
  //   // return () => {
  //   //   window.removeEventListener('resize', debounceResizeFeed);
  //   // }
  //   // eslint-disable-next-line
  // }, [])

  useEffect(() => {
    if (questionData) {
      setQuestionsOnFeed(oldArray => [...oldArray, questionData])
    }
    const timeZone = momentTz.tz.guess()
    const timeZoneDifference = parseInt(moment.tz(timeZone).format('Z'))
    setLocalTimeZone(timeZoneDifference)
    // eslint-disable-next-line
  }, [questionData])

  // Start poller
  const startPoller = () => {
    clearInterval(poller.current)
    poller.current = setInterval(() => {
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
      // receivedQuestions.forEach(question => {
      //   commentBoxes[question.id] = false
      // })
      // getAnswers(api.entities.virtualRoom.getQnaAnswers(null, null, {id: questionObj.id}))

    }, TIME_SESSION_HEARTBEAT)
  }

  useEffect(() => {
    if (answerData) {
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))

      Object.keys(openComments).forEach((key)=>{
        getAnswers(api.entities.virtualRoom.getQnaAnswers(null, null, {id: parseInt(key)}))
      })

    }
    // eslint-disable-next-line
  }, [answerData])


  useEffect(() => {
    if (upVoteData)
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    // eslint-disable-next-line
  }, [upVoteData])

  useEffect(() => {
    if (unVoteData)
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    // eslint-disable-next-line
  }, [unVoteData])

  useEffect(() => {
    if (questionIsDeleted)
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    // eslint-disable-next-line
  }, [questionIsDeleted])

  useEffect(() => {
    let commentBoxes = {...openComments}

    if (receivedQuestions) {
      setQuestionsOnFeed(receivedQuestions)
      // receivedQuestions.forEach(question => {
      //   commentBoxes[question.id] = false
      // })
      setOpenComments(commentBoxes)
    }
    // eslint-disable-next-line
  }, [receivedQuestions])

  useEffect(() => {
    if (isSpeaker || isChair || isCoach)
      setCanReply(true)

    getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    startPoller()

    return ()=> {
      clearInterval(poller.current)
    }
    // eslint-disable-next-line
  }, [])

  const handleAnonymous = () => {
    setIsAnonymous(prevState => !prevState)
  }

  const handleUpVote = (questionId, isVoted) => {
    if (isVoted)
      undoUpVote(api.entities.virtualRoom.undoQnaUpVote(null, null, {id: questionId}))
    else
      setUpVote(api.entities.virtualRoom.setQnaUpVote(null, null, {id: questionId}))
  }

  const handleAskQuestion = (e) => {
    e.preventDefault()
    const payLoad = {onlineRoomId: roomId, question: questionText, anonymous: isAnonymous}
    setQuestion(api.entities.virtualRoom.setQnaQuestion(payLoad, null, null))
    setIsAnonymous(false)
    setQuestionText('')
  }

  const handleOpenComment = (questionObj) => {

    if (questionObj.answers > 0) {
      getAnswers(api.entities.virtualRoom.getQnaAnswers(null, null, {id: questionObj.id}))
    }

    const newOpenComments = {...openComments}
    // eslint-disable-next-line
    Object.keys(newOpenComments).map(question => {
      if (parseInt(question) !== questionObj.id)
        newOpenComments[parseInt(question)] = false
    })
    newOpenComments[questionObj.id] = !newOpenComments[questionObj.id]
    // TODO: return scrollbar to previous state before opening
    // if (newOpenComments[questionObj.id] === false)
    //   scrollBar.scrollTop = 5

    setOpenComments({...newOpenComments})
    setAnswerText('')

  }

  const handleAnswerQuestion = (e, questionId) => {
    e.preventDefault()
    const payLoad = {questionId: questionId, answer: answerText}
    setAnswer(api.entities.virtualRoom.setQnaAnswer(payLoad, null, null))
    setAnswerText('')
  }

  const handleDeleteQuestion = (questionId) =>{
    deleteQuestion(api.entities.virtualRoom.deleteQuestion(null, null, {id: questionId}))
  }

  // const handleScrollRef = (ref) => {
  //   setScrollBar(ref)
  // }
  return <Grid item container className={clsx(classes.container, disabled && classes.disabled)} >
              <Grid item container className={classes.feed} >
                    <Grid item xs={12}>

                      <Box className={classes.feed}>
                        <PerfectScrollbar>

                      <List className={classes.list}>
                        {questionsOnFeed.map((question, index) => {
                          return <>
                          <ListItem alignItems="flex-start" className={clsx(classes.listItem,(openComments[question.id] && question.answers >0)&&classes.questionOpened)}  key={index}>
                            <ListItemAvatar classes={{root:classes.avatarRoot}}>
                              <Avatar alt={question.firstName ? `${question.title?question.title:''} ${question.firstName} ${question.lastName}` : 'Anonymous'} src={imageFile(question.imageId ? question.imageId : '')} style={{width:24,height:24}} />
                            </ListItemAvatar>
                            <ListItemText classes={{primary:classes.userName}}
                                          primary={<>
                                            <Grid item container justify={'space-between'} alignItems='center' alignContent='center'>
                                              <Grid item>
                                                {question.firstName ? `${question.title?question.title:''} ${question.firstName} ${question.lastName}` : 'Anonymous'}
                                              </Grid>
                                              <Grid item>
                                                <Box fontSize={10} style={{opacity:0.7}}>
                                                  {moment(question.updatedAt).add(localTimeZone,'hours').format('HH:mm')}
                                                </Box>
                                              </Grid>
                                            </Grid>
                                          </>}
                                          secondary={<>
                                            <Grid item container>

                                              <Grid item xs={12} className={classes.message}>
                                                {question.question}
                                              </Grid>

                                              <Grid item container justify='space-between' spacing={1}>

                                                <Grid item xs>
                                                  <Grid item container spacing={1}>
                                                    {question.answers!==0&&<Grid item>
                                                      <Button size='small' variant='contained' color='default' style={{backgroundColor:'#eeeeee'}} onClick={() => handleOpenComment(question)} endIcon={(openComments[question.id] && question.answers >0)?<KeyboardArrowUpIcon fontSize='small'/>:<KeyboardArrowDownIcon fontSize='small'/>} >View {question.answers} Replies </Button>
                                                    </Grid>}
                                                    {question.answers===0&&<Grid item>
                                                      <Button size='small' variant='contained' color='default' style={{backgroundColor:'#eeeeee'}} onClick={() => handleOpenComment(question)} endIcon={(openComments[question.id])?<KeyboardArrowUpIcon fontSize='small'/>:<KeyboardArrowDownIcon fontSize='small'/>} > Reply </Button>
                                                    </Grid>}
                                                    {(question.owner || (isChair || isCoach)) && !openComments[question.id] &&<Grid item>
                                                      <Button size='small' variant='contained' color='default' style={{color:'#d0021b', backgroundColor:'#eeeeee'}} onClick={() => handleDeleteQuestion(question.id)}>Delete</Button>
                                                    </Grid>}
                                                  </Grid>
                                                </Grid>

                                                <Grid item>
                                                  <Button classes={{root:classes.votesBtn, label:classes.btnLbl}}
                                                          variant={'contained'}
                                                    aria-label='Vote Up'
                                                    onClick={() => handleUpVote(question.id, question.voted)}
                                                    size={'small'}
                                                    startIcon={<ThumbUpAltOutlinedIcon color={question.voted ? 'secondary' : 'primary'}/>}
                                                  >
                                                    {question.votes}
                                                  </Button>
                                                </Grid>

                                              </Grid>

                                            </Grid>


                                          </>}
                            />
                          </ListItem>

                            {canReply && openComments[question.id] && question.answers === 0 &&
                            <form onSubmit={(event) => handleAnswerQuestion(event, question.id)} className={classes.answerInput}>
                              <OutlinedInput variant='filled' fullWidth classes={{input:classes.inputElement,notchedOutline:classes.inputRoot}}
                                             placeholder={'Reply to this question...'}
                                             endAdornment={
                                               <InputAdornment position='end'>
                                                 <IconButton
                                                   size={'small'}
                                                   aria-label='send message'
                                                   onClick={(event) => handleAnswerQuestion(event, question.id)}
                                                   edge='end'
                                                   disabled={disabled}
                                                 >
                                                   <SendIcon/>
                                                 </IconButton>
                                               </InputAdornment>
                                             } onChange={(event) => {
                                setAnswerText(event.target.value)
                              }} value={answerText} disabled={disabled}/>
                            </form>
                            }

                            {(openComments[question.id] &&  (!answersLoading) && receivedAnswers && question.answers >0) && <>
                              <QnAAnswers key={question.id} questionId={question.id} answers={receivedAnswers} classes={classes} sessionState={sessionState}
                                          isLoading={answersLoading&&!receivedAnswers} getAnswers={getAnswers} refreshQuestions = {setQuestionsOnFeed} localTimeZone={localTimeZone}/>
                                            {canReply && (!answersLoading) && receivedAnswers &&
                                            <form onSubmit={(event) => handleAnswerQuestion(event, question.id)} className={classes.answerInput}>
                                              <OutlinedInput variant='filled' fullWidth classes={{input:classes.inputElement,notchedOutline:classes.inputRoot}}
                                                             placeholder={'Reply to this question...'}
                                                             endAdornment={
                                                               <InputAdornment position='end'>
                                                                 <IconButton
                                                                   size={'small'}
                                                                   aria-label='send message'
                                                                   onClick={(event) => handleAnswerQuestion(event, question.id)}
                                                                   edge='end'
                                                                   disabled={disabled}
                                                                 >
                                                                   <SendIcon/>
                                                                 </IconButton>
                                                               </InputAdornment>
                                                             } onChange={(event) => {
                                                setAnswerText(event.target.value)
                                              }} value={answerText} disabled={disabled}/>
                                            </form>
                                            }
                                            </>
                            }
                          </>
                        })}
                      </List>

                    {/*{questionsOnFeed.map((question, index) => {*/}
                    {/*  return <Grid item xs={12} key={index}>*/}
                    {/*    <Box mb={1}>*/}
                    {/*      <Grid container direction={'column'}>*/}
                    {/*        <Grid className={classes.questionBox} item xs={10} lg = {10}>*/}
                    {/*          <Grid container direction='row'*/}
                    {/*                justify='flex-start'*/}
                    {/*                alignItems='center'*/}
                    {/*                spacing={1}>*/}
                    {/*            <Grid item xs={1}>*/}
                    {/*              <Box className={classes.userPhoto}>*/}
                    {/*                <img src={imageFile(question.imageId ? question.imageId : '')}*/}
                    {/*                     alt={'Users profile'}/>*/}
                    {/*              </Box>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={7}>*/}
                    {/*              <Typography*/}
                    {/*                variant={'subtitle2'}*/}
                    {/*                style={{color: '#afa2a2'}}>{question.firstName ? `${question.title?question.title:''} ${question.firstName} ${question.lastName}` : 'Anonymous'}</Typography>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={4}>*/}
                    {/*              <Grid container direction='row'*/}
                    {/*                    justify='flex-end'*/}
                    {/*                    alignItems='baseline'*/}
                    {/*                    spacing={1}>*/}
                    {/*                <Grid item>*/}
                    {/*                  <Typography variant={'subtitle2'}>Votes {question.votes}</Typography>*/}
                    {/*                </Grid>*/}
                    {/*                <Grid item>*/}
                    {/*                  <IconButton*/}
                    {/*                    aria-label='Vote Up'*/}
                    {/*                    onClick={() => handleUpVote(question.id, question.voted)}*/}
                    {/*                    size={'small'}*/}
                    {/*                    edge='end'*/}
                    {/*                  >*/}
                    {/*                    <ThumbUp color={question.voted ? 'primary' : 'action'}/>*/}
                    {/*                  </IconButton>*/}
                    {/*                </Grid>*/}
                    {/*              </Grid>*/}
                    {/*            </Grid>*/}
                    {/*            <Grid item xs={12}>*/}
                    {/*              <Box>*/}
                    {/*                <Typography className={classes.userTimeStamp} align={'left'}>{*/}
                    {/*                  format(*/}
                    {/*                    parseISO(question.updatedAt),*/}
                    {/*                    'hh:mmaa'*/}
                    {/*                  )}*/}
                    {/*                </Typography>*/}
                    {/*              </Box>*/}
                    {/*            </Grid>*/}
                    {/*          </Grid>*/}
                    {/*          <Box mt={2} mb={2}>*/}
                    {/*            <Typography variant={'body2'}>{question.question}</Typography>*/}
                    {/*          </Box>*/}
                    {/*          <Box mt={1} mb={2} key={question.id}>*/}
                    {/*            <Divider style={{backgroundColor: '#ececec'}}/>*/}
                    {/*            <Collapse in={openComments[question.id]}>*/}
                    {/*              <Box className={classes.commentsBox}>*/}
                    {/*                {(openComments[question.id] && question.answers >0) &&*/}
                    {/*                <QnAAnswers questionId={question.id} answers={receivedAnswers} classes={classes} session = {session}*/}
                    {/*                            isLoading={answersLoading&&!receivedAnswers} getAnswers={getAnswers} refreshQuestions = {setQuestionsOnFeed}/>*/}
                    {/*                }*/}
                    {/*              </Box>*/}
                    {/*              <Box>*/}
                    {/*                {canReply &&*/}
                    {/*                <form onSubmit={(event) => handleAnswerQuestion(event, question.id)}>*/}
                    {/*                  <OutlinedInput variant='filled' fullWidth*/}
                    {/*                                 placeholder={'Reply to this question...'}*/}
                    {/*                                 endAdornment={*/}
                    {/*                                   <InputAdornment position='end'>*/}
                    {/*                                     <IconButton*/}
                    {/*                                       size={'small'}*/}
                    {/*                                       aria-label='send message'*/}
                    {/*                                       onClick={(event) => handleAnswerQuestion(event, question.id)}*/}
                    {/*                                       edge='end'*/}
                    {/*                                       disabled={disabled}*/}
                    {/*                                     >*/}
                    {/*                                       <SendIcon/>*/}
                    {/*                                     </IconButton>*/}
                    {/*                                   </InputAdornment>*/}
                    {/*                                 } onChange={(event) => {*/}
                    {/*                    setAnswerText(event.target.value)*/}
                    {/*                  }} value={answerText} disabled={disabled}/>*/}
                    {/*                </form>*/}
                    {/*                }*/}
                    {/*              </Box>*/}
                    {/*            </Collapse>*/}
                    {/*            <Grid container direction='row'*/}
                    {/*                  justify='flex-end'*/}
                    {/*                  alignItems='center'*/}
                    {/*                  spacing={1}>*/}
                    {/*              {(question.owner || (session.status.isChair || session.status.coach)) && !openComments[question.id] &&*/}
                    {/*              <Grid item>*/}
                    {/*                <Typography variant={'subtitle2'} className={classes.userDeleteQuestion}*/}
                    {/*                            onClick={() => handleDeleteQuestion(question.id)}>Delete</Typography>*/}
                    {/*              </Grid>}*/}
                    {/*              <Grid item>*/}
                    {/*                <Typography variant={'subtitle2'} className={classes.userCommentLink}*/}
                    {/*                            onClick={() => handleOpenComment(question)}>{openComments[question.id] ? `Close` : question.answers > 0 && `View Replies (${question.answers})`}</Typography>*/}
                    {/*              </Grid>*/}
                    {/*              {(canReply && !openComments[question.id]) &&*/}
                    {/*              <Grid item>*/}
                    {/*                <Typography variant={'subtitle2'} className={classes.userCommentLink}*/}
                    {/*                            onClick={() => handleOpenComment(question)}>Add a Reply</Typography>*/}
                    {/*              </Grid>*/}
                    {/*              }*/}
                    {/*            </Grid>*/}
                    {/*          </Box>*/}
                    {/*        </Grid>*/}
                    {/*      </Grid>*/}
                    {/*    </Box>*/}
                    {/*  </Grid>*/}
                    {/*})}*/}
                        </PerfectScrollbar>

                      </Box>

                    </Grid>
              </Grid>

          <Grid item className={classes.chatInput}>
            <form onSubmit={handleAskQuestion}>
              <Grid item container>
                <Grid item xs={12}>
                  <form onSubmit={(event) => handleAskQuestion()} className={classes.answerInput}>
                   <OutlinedInput variant='filled' fullWidth placeholder={'Type a question...'} multiline rows={3} classes={{input:classes.inputElement,notchedOutline:classes.inputQuestion, root:classes.buttonRoot}} onChange={(event) => {
                    setQuestionText(event.target.value)
                  }} value={questionText} disabled={disabled}/>
                  </form>
                </Grid>

                <Grid item container justify='flex-end' alignContent='center' alignItems='center'>
                  <Grid item>
                    <Tooltip title="Ask a Question Anonymously">
                      <FormControlLabel
                        control={<Checkbox
                          value={isAnonymous}
                          onChange={handleAnonymous}
                        />}
                        label="Anonymous"
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label='send message'
                      onClick={handleAskQuestion}
                      edge='end'
                      disabled={disabled}
                    >
                      <SendIcon/>
                    </IconButton>
                  </Grid>
                </Grid>

              </Grid>

            </form>
          </Grid>

        </Grid>
})


export default QnAWall
