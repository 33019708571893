import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useState} from 'react'
import {Typography, Box} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette, theme} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import CallToActionIcon from '@material-ui/icons/CallToAction'
import Dialog from '@material-ui/core/Dialog'
import FormEditSubmission from 'components/core/Forms/FormEditSubmission'
import {useLocation} from 'react-router-dom'
import Chip from '@material-ui/core/Chip'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from 'components/core/Button'
import { useSnackbar } from "notistack";
import AddCreditsDialog from "components/pages/ManageEvent/Plans/AddCreditsDialog";
import {creditsPriceList} from "config";

const styles = (theme) => ({
  submissionFlowsContainer: {
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "calc(100vw - 300px)",
  },
  noSubmissionsYet: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "145px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  fieldTitle: {
    fontWeight: "bold",
  },
  header: {
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  addBtn: {
    textAlign: "right",
  },
  navTabs: {
    position: "relative",
    marginBottom: theme.spacing(3),
  },
  downloadBtn: {
    position: "absolute",
    top: theme.spacing(4),
    right: theme.spacing(3),
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),
  },
  totalStats: {
    textAlign: "center",
    position: "relative",
    backgroundColor: "white",
    padding: theme.spacing(2, 2, 4, 2),
    borderRadius: "6px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "1000px",
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: "bold",
  },
  chip: {
    color: "white",
    margin: theme.spacing(0.5),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",  
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem"
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const StyledTabs = withStyles({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),

    '&:focus': {
      opacity: 1,
    },
    '&:first-child': {
      minWidth: 100
    }
  },
  indicator: {
    display: 'none'
  }
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    whiteSpace: 'nowrap',
    // minHeight: theme.spacing(6),
    // minWidth: 114,
    minWidth: 'auto',
    minHeight: 40,
    // padding: 0,
    // margin: 0,
    // paddingLeft: theme.spacing(0.2),
    // paddingRight: theme.spacing(0.2),
    color: '#4a4a4a',
    borderRadius: theme.spacing(8),
    marginRight: theme.spacing(1),
    border: '1px solid rgb(239, 239, 239)',
    '&:hover': {
      backgroundColor: palette['cerulean-blue'],
      '& $wrapper': {
        color: 'white',
      },
    },
    '&$selected': {
      '& *': {
        color: 'white',
      },
    },
  },
  selected: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white'
  },
  textColorInherit: {
    opacity: 1,
  },
  wrapper: {
    textTransform: 'none',
    fontSize: 14,
    // fontWeight: 600,
    // color: palette['brown-grey'],
    color: '#4a4a4a',
  }
}))((props) => <Tab disableRipple {...props} />);


const Submissions = withStyles(styles)(({classes, history, setPageTitle, setEventInfo, setNavBack, eventInfo, flow}) => {

  // Hooks
  const [getData, data, isLoadingData] = useHttp()
  const [getFlowsData, flowsData] = useHttp(null)

  // eslint-disable-next-line
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isValid, setValid] = useState(true)

  // Deps
  const {eventId} = useParams()
  const q = useQuery()
  const subId = q.get('submission')

  // State
  const [submissionFlows, setSubmissions] = useState()
  // const [CsvData, setCsvData] = useState()
  const [formEdit, setFormEdit] = useState()
  const [getThematicCodes, thematicCodes] = useHttp()
  const [tab, setTab] = useState(subId?parseInt(subId):0)

  const {enqueueSnackbar} = useSnackbar();
  const [creditsPlan, setCreditsPlan] = useState();
  const [getCredits, credits] = useHttp();
  const [complete, setComplete] = useState(false);

  useEffect(() => {
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);

  // const [defaultFilter] = useState(filter?[filter]:[])

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  useEffect(() => {
    getFlowsData(api.entities.manage.getSubmissionFlows(null, null, {id: eventId}))

    if (subId) {
      getData(api.entities.manage.getSubmissionFlowResponses(null, null, {id: subId}))
    }
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (tab) {
      getData(api.entities.manage.getSubmissionFlowResponses(null, null, {id: tab}))
      if(!flow)
      {
        history.push(`/manage-event/${ eventId }/submissions?submission=${ tab }`)
      } else
      {
        history.push(`/manage-event/${eventId}/event-flow?submission=${tab}`)        
      }        
    }
    //eslint-disable-next-line
  },[tab])

  useEffect(()=>{
    if (flowsData&&flowsData.length&&!subId) {
      setTab(flowsData.flows[0].id)
    }
    //eslint-disable-next-line
  },[flowsData])

  const refresh = () => {
    getData(api.entities.manage.getSubmissionFlowResponses(null, null, {id: subId}))
  }

  useEffect(() => {
    if (complete) {
     if (subId) {getData(api.entities.manage.getSubmissionFlowResponses(null, null, {id: subId}));}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  const handleCloseFormEdit = (reload) => {
    setFormEdit(null)
    if (reload) {
      refresh()
    }
  }

  useEffect(() => {
    if (data) {
      setSubmissions(data.flows)
      getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:data.eventInfo.id}))

      // console.log(data.flows)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
    // eslint-disable-next-line
  }, [data])

  // Download file
  /////////////////

  const [CsvData, setCsvData,] = useState()
  const [getCSV, dataCSV, , , , csvExtra] = useHttp()

  const [ZipData, setZipData,] = useState()
  const [getZip, dataZip, , , , zipExtra] = useHttp()

  useEffect(() => {
    if (dataCSV) {
      setCsvData(dataCSV)
    }
  }, [dataCSV])

  useEffect(() => {
    if (CsvData) {
      let csvURI = window.URL.createObjectURL(dataCSV[0])
      let downloadLink = document.createElement('a')
      downloadLink.href = csvURI
      downloadLink.download = `${csvExtra.name}_flow.xlsx`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
    //eslint-disable-next-line
  },[CsvData])

  useEffect(() => {
    if (dataZip) {
      setZipData(dataZip)
    }
  }, [dataZip])

  useEffect(() => {
    if (ZipData) {
      let ZipURI = window.URL.createObjectURL(dataZip[0])
      let downloadLink = document.createElement('a')
      downloadLink.href = ZipURI
      downloadLink.download = `${zipExtra.name}_flow.zip`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setZipData(null)
    }
    //eslint-disable-next-line
  },[ZipData])

  const downloadCSV = (id,name) => {
    if (!name) return false
    getCSV(api.entities.manage.downloadCSVFlow(null, null, {id},false,{id, name}))
  }

  const downloadZip = (id,name) => {
    if (!name) return false
    getZip(api.entities.manage.downloadZIPFlow(null, null, {id},false,{id, name}))
  }

  // if (!subId&&data) return <Redirect to={`/manage-event/${data.eventInfo.id}/submission-flows`}/>

  const handleOpenAddCredits = (plan) => {
    setCreditsPlan(plan);
  };

  const handleCloseAddCredits = (success) => {
    setCreditsPlan(null);
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    if (success === true) {
      enqueueSnackbar("Credits added successfully.", {variant: "success"});
    }
  }; 

  if (!flowsData) return <> </>

  return (
    <>
      <Fade in={true} timeout={1200}>
        <>
          <Grid container spacing={2} className={classes.submissionFlowsContainer}>
            <Grid item container xs={12} justify="space-between" alignContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography component="h2" variant="h5" className={classes.title}>
                  Submitted Papers
                </Typography>
              </Grid>
              {(data === null || data?.message === null) && credits?.papersTotal - credits?.papersBought > 0 && (
                <Grid item xs={8} style={{textAlign: "right"}}>
                  <Typography component="h6" variant="h6" className={classes.creditsTitle}>
                    {data?.message !== null ? `Available papers ${credits?.papersBought} out of ${credits?.papersTotal} in total` : "There aren't any papers submitted in this flow"}
                  </Typography>{" "}
                  <Box display="inline" pt={2} ml={3}>
                    <Button autoWidth size="smaller" onClick={() => handleOpenAddCredits("papers")}>
                      {" "}
                      Unlock more papers{" "}
                    </Button>
                  </Box>
                </Grid>
              )}
              {data?.message && (
                <Grid item xs={7} style={{textAlign: "right"}}>
                  <Typography component="h6" variant="h6" className={classes.creditsTitle}>
                    {data.message}
                  </Typography>{" "}
                  <Box display="inline" pt={2} ml={3}>
                    <Button autoWidth size="smaller" onClick={() => handleOpenAddCredits("papers")}>
                      {" "}
                      Unlock more papers{" "}
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>

            {flowsData && (
              <Grid item xs={12}>
                <StyledTabs value={tab} onChange={handleChangeTab} aria-label="Flows" indicatorColor="primary" textColor="primary" variant="scrollable" scrollButtons="auto">
                  {flowsData.flows.map((f) => (
                    <StyledTab label={f.name} {...a11yProps(f.id)} value={f.id} />
                  ))}
                </StyledTabs>
              </Grid>
            )}
            {isLoadingData && (
              <Grid item container justify="center" alignContent="center" alignItems="center" style={{minHeight: "500px"}}>
                <Grid item>
                  <CircularProgress size={"6rem"} color="secondary" />
                </Grid>
              </Grid>
            )}
            {!isLoadingData && (
              <Grid item xs={12}>
                <MaterialTable
                  key={submissionFlows}
                  icons={tableIcons}
                  columns={[
                    {title: "ID", field: "answerId", editable: "never", width: 50},
                    {title: "Paper title", field: "title", editable: isLoading ? "never" : "always"},
                    {
                      title: "Paper authors",
                      field: "authors",
                      type: "string",
                      editable: isLoading ? "never" : "always",
                      render: (rowData) => (
                        <>
                          {rowData.authors.map((author, i) => {
                            return <Chip className={classes.chip} size="small" label={author} color={author !== rowData.submitter ? "default" : "secondary"} />;
                          })}
                        </>
                      ),
                    },
                    // { title: 'Paper submitter', field: 'submitter' , type: 'string', editable: isLoading?'never':'always'},
                    // { title: 'Submission flow name', field: 'name', editable: isLoading?'never':'always'},
                  ]}
                  data={submissionFlows}
                  title={`${flowsData && tab ? flowsData.flows.find((f) => f.id === tab).name : ""} ${flowsData && flowsData.length ? "(" : ""} ${submissionFlows ? submissionFlows.length : ""} ${
                    flowsData && flowsData.length ? ")" : ""
                  } `}
                  actions={[
                    {
                      icon: CallToActionIcon,
                      tooltip: "Edit form",
                      onClick: (event, rowData) => setFormEdit(rowData),
                    },
                    // {
                    //   icon: DeleteIcon,
                    //   tooltip: 'Delete reviewer',
                    //   onClick: (event, rowData) => alert("You deleted " + rowData.firstName)
                    // }
                  ]}
                  // onRowClick={(event,rowData) => {
                  //   alert(rowData.id)
                  // }}
                  style={{
                    border: "none",
                    boxShadow: "none",
                  }}
                  localization={{
                    header: {
                      actions: "Edit",
                    },
                  }}
                  options={{
                    pageSize: window.innerHeight > 1200 ? 10 : 5,
                    filtering: true,
                    actionsColumnIndex: 999,
                    addRowPosition: "first",
                    headerStyle: {
                      backgroundColor: palette["cerulean-blue"],
                      color: "#FFF",
                      fontWeight: "bold",
                      fontSize: 14,
                      // borderRadius: 4
                    },
                    rowStyle: {
                      fontSize: 12,
                      padding: 1,
                      paddingRight: 32,
                      borderRadius: 4,
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item container xs={12}>
              <Grid item container spacing={2} direction="row" display="flex" justify="flex-end">
                <Grid item>
                  <Button
                    size="smaller"
                    onClick={() => {
                      const flow = flowsData.flows.find((f) => f.id === tab);
                      if (!flow) return false;
                      downloadCSV(subId, flow.name);
                    }}>
                    {" "}
                    Download .xlsx
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="smaller"
                    onClick={() => {
                      const flow = flowsData.flows.find((f) => f.id === tab);
                      if (!flow) return false;
                      downloadZip(subId, flow.name);
                    }}>
                    {" "}
                    Download .zip{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {formEdit && (
            <Dialog onClose={handleCloseFormEdit} open={!!formEdit} fullWidth maxWidth="md">
              <FormEditSubmission handleClose={handleCloseFormEdit} sFlow={formEdit} eventId={data.eventInfo.id} thematicCodes={thematicCodes} isValid={isValid} setValid={setValid} />
            </Dialog>
          )}
        </>
      </Fade>
      {creditsPlan && (
        <AddCreditsDialog
          handleClose={handleCloseAddCredits}
          creditsPriceList={creditsPriceList}
          eventDuration={1}
          eventCredits={credits}
          open={creditsPlan}
          creditsType={creditsPlan}
          event={{...eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}
    </>
  );
})

export default withRouter(Submissions)
