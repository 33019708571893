import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {
  IconAddBorderLight,
} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {
  FILETYPES_ACCEPTED,
  FILETYPES_PAPER_ACCEPTED,
  FILETYPES_PRESENTATION_POSTER_ACCEPTED, PRESENTATION_TYPES,
  SIZE_LENGTH_DESCRIPTION
} from 'config'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import {useHttp} from 'api/core'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import api from 'api'
import ChipInput from 'material-ui-chip-input'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {FormHelperText} from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import DescriptionIcon from '@material-ui/icons/Description'
import SlideshowIcon from '@material-ui/icons/Slideshow'
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile'

const styles = theme => ({
  container: {

  },
  newPresentation: {
    position: 'relative',
    padding: '40px 50px 50px 50px',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    fontSize: 36,
    fontWeight: 'bold',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 9999
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  descriptionBox: {
    minHeight: '120px'
  },
  participantsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto'
  },
  participantsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'auto'
  },
  chipBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    color: 'white',
    '&> input':{
      color: 'white'
    }
  },
  chipInput: {
    color: 'white',
    width: '100%'
  },
  chip: {
    margin: theme.spacing(1),
    maxWidth: '135px'
  },
  chipDelete: {
    // color: 'white'
  },
  btns: {
    textAlign: 'center'
  },
  formControl: {
    width: '100%'
  },
  files: {
    display: 'flex',
    // justifyContent: 'center',
    flexWrap: 'wrap',
    marginLeft: -theme.spacing(0.5),
    // paddingTop: theme.spacing(1),
    '& > *': {
      maxWidth: '100%',
      color: 'white',
      borderColor: 'white'
    }
  },
  avatar: {
    // color: 'white !important',
    background: 'none',
    '&> img':{
      maxWidth: '100%'
    }
  },
  chipFile: {
    margin: theme.spacing(1)
  }
})

const validationSchema = Yup.object().shape({
  title: Yup.string().required('This field is required'),
  abstract: Yup.string().required('This field is required')
})

const UploadFile = withStyles(styles)(({setFormData, formData, fieldName, buttonLabel, singleUpload}) => {

  const onDrop = (acceptedFiles) => {
    if (singleUpload) return false
    if (fieldName==='files') {
      let newFiles = formData.uploadedFiles ? [...formData.uploadedFiles] : []
      newFiles.push(...acceptedFiles)
      setFormData({...formData, uploadedFiles: newFiles})
    } else {
      // Always one file, delete current file if exists, add new
      const newDeleted = formData.deletedFiles
      if (formData[fieldName][0]){
        newDeleted.push(formData[fieldName][0].id)
      }
      setFormData({
        ...formData,
        [fieldName]: [acceptedFiles[0]],
        deletedFiles: newDeleted
      })
    }
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  return <RootRef rootRef={ref}>
    <Box {...rootProps}>
      <input name='file' type='file' {...getInputProps()} accept={fieldName==='files'?`${FILETYPES_ACCEPTED.join(',')}`:fieldName==='paperFiles'?`${FILETYPES_PAPER_ACCEPTED.join(',')}`:`${FILETYPES_PRESENTATION_POSTER_ACCEPTED.join(',')}`} disabled={singleUpload}/>
        <Button size='smaller' variant='outlined' colour='white' disabled={singleUpload}><IconAddBorderLight/> {buttonLabel?buttonLabel:'Add File'} </Button>
    </Box>
  </RootRef>
})

const PresentationEditNew = withStyles(styles)(({classes, presentation, sessions, handleClose, eventId, setSuccessful, participants, presentationTypes, ...props}) => {
  const [formData, setFormData] = useState({
    ...presentation,
    participants: presentation ? presentation.participants.map((participantId)=>participants.find((participantObj)=>participantObj.id===participantId)) : [],
    deleteBackground: false,
    deletedFiles: [],
    presentationFiles: presentation ? [...presentation.presentationFiles] : [],
    posterFiles: presentation ? [...presentation.posterFiles] : [],
    paperFiles: presentation ? [...presentation.paperFiles] : []
  })

  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError,,,reqProgress] = useHttp()
  const [chips, setChips] = useState(presentation?presentation.keywords?presentation.keywords.split(','):[]:[])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uploading,setUploading] = useState(false)

  useEffect(()=>{
    setIsSubmitting(isLoading&&!hasError)
  },[isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
    // eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {

      const {title, presentationCode, sessionId, abstract, presenterId, deletedFiles, uploadedFiles, posterFiles, presentationFiles, paperFiles, type} = formSave;
      const participants = formData.participants.map((participant) => participant.id)

      let presentationPayload = {
        title,
        presentationCode,
        participants,
        presenterId,
        abstract,
        type
      }

      if (deletedFiles) {
        presentationPayload.deletedFiles = deletedFiles
      }

      if (participants.length===0) {
        presentationPayload.presenterId=null
      }

      if (chips.length!==0) {
        presentationPayload.keywords = chips.join(',')
      } else {
        presentationPayload.keywords = null
      }

      if (!presentation) {
        if (sessionId===null||sessionId==='-') {
          presentationPayload.eventId = parseInt(eventId)
        } else {
          presentationPayload.sessionId = sessionId==='-'?null:sessionId
        }
      } else {
        presentationPayload.id = presentation.id
        presentationPayload.sessionId = sessionId==='-'?null:sessionId
      }

      let formDataObj = new FormData()

      if (uploadedFiles) {
        uploadedFiles.forEach((file) => formDataObj.append('files', file))
      }

      if (paperFiles) {
        paperFiles.forEach((file) => formDataObj.append('paperFiles', file))
      }

      if (presentationFiles) {
        presentationFiles.forEach((file) => formDataObj.append('presentationFiles', file))
      }

      if (posterFiles) {
        posterFiles.forEach((file) => formDataObj.append('posterFiles', file))
      }

      const blob = new Blob([JSON.stringify(presentationPayload)], {type: 'application/json'});
      formDataObj.append('presentation', blob)

      // If existing presentation
      if (presentation) {
        sendRequest(api.entities.presentations.update(formDataObj, null, {id: presentation.id}, true))
      } else { // else add new presentation
        sendRequest(api.entities.presentations.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
    //eslint-disable-next-line
  }, [eventId, formData.participants, formSave, sendRequest, presentation])

  useEffect(()=>{
    if (reqProgress) {
      if (reqProgress!==100) {
        setUploading(true)
      } else {
        setUploading(false)
      }
    }
  },[reqProgress])

  const addParticipant = (participant, setFieldValue) => {
    let newParticipants = [...formData.participants]
      if (formData.participants.find((s)=>s.id===participant.id)) {
        return false
      }
      newParticipants.push(participant)
      setFormData({...formData, participants: newParticipants})
      setFieldValue('participants', newParticipants)
  }

  const deleteParticipant = (pos, setFieldValue) => {
    let newParticipants = [...formData.participants]
    newParticipants.splice(pos, 1)
    setFormData({...formData, participants: newParticipants})
    setFieldValue('participants', newParticipants)
  }

  const deleteFile = (pos, setFieldValue) => {
    let newFiles = [...formData.files]
    let newDeletedFiles = formData.deletedFiles ? [...formData.deletedFiles] : []
    newDeletedFiles.push(formData.files[pos].id)
    newFiles.splice(pos, 1)
    setFormData({...formData, files: newFiles, deletedFiles: newDeletedFiles})
  }

  const deleteFileFrom = (pos, fieldName) => {
    let newFiles = [...formData[fieldName]]
    let newDeletedFiles = formData.deletedFiles ? [...formData.deletedFiles] : []
    if (formData[fieldName][pos].id) {
      newDeletedFiles.push(formData[fieldName][pos].id)
    }
    newFiles.splice(pos, 1)
    setFormData({...formData, [fieldName]: newFiles, deletedFiles: newDeletedFiles})
  }

  const deleteUploadedFile = (pos) => {
    let newFiles = [...formData.uploadedFiles]
    newFiles.splice(pos, 1)
    setFormData({...formData, uploadedFiles: newFiles, deleteBackground: true})
  }

  const handleAddChip = (chip) => {
    let newChips = []
    let verifiedChips = []

    if (chip.indexOf(',')!==-1) {
      newChips = [...chip.split(',')]
    } else {
      newChips = [chip]
    }

    newChips.forEach((chip)=> {
      if (verifiedChips.indexOf(chips) === -1) verifiedChips.push(chip)

    })

    setChips([...chips, ...verifiedChips])
  }

  const handleDeleteChip = (chip, index) => {
    const newChips = [...chips]
    newChips.splice(index,1)
    setChips(newChips)
  }

  return <Box className={classes.newPresentation}><ThemeProvider theme={theme}>
    {uploading&&<div className={classes.progress}>
      <Box display='flex'>
        Uploading data
      </Box>
      <Box display='flex'>
        {reqProgress}%
      </Box>
    </div>}

    <Formik
      initialValues={{
        title: '',
        presentationCode: null,
        keywords: '',
        abstract: '',
        searchByName: '',
        type: 1,
        ...formData, sessionId: formData.sessionId?formData.sessionId:'-',
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, deletedFiles: formData.deletedFiles,
          presentationFiles: formData.presentationFiles,
          posterFiles: formData.posterFiles,
          paperFiles: formData.paperFiles
        }
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, touched}) => (
        <Form className={classes.form}>

            <Grid container spacing={2}>

              <Grid item container spacing={1} className={classes.row}>
                <Typography variant='h5'
                            component='h1'> {presentation ? 'Presentation Information' : 'New Presentation'}</Typography>
              </Grid>

              <Grid item container spacing={1} >
                <Grid item xs={12} sm={3}>
                  <Field type='text' name='presentationCode' component={TextField} label='Code' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Field type='text' name='title' component={TextField} label='Paper Title' variant='filled'
                         fullWidth disabled={isSubmitting}/>
                </Grid>

                <Grid item xs={12}>
                  <Field type='text' name='abstract' component={TextField} className={classes.descriptionBox}
                         id='Abstract' label='Abstract' variant='filled'
                         fullWidth multiline maxLength={SIZE_LENGTH_DESCRIPTION}
                         rows={4}/>
                </Grid>

              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Field type='select' labelId='typeLabel' displayEmpty name='type' component={Select} label='Presentation type' variant='filled' fullWidth disabled={isSubmitting}>
                    {Object.keys(PRESENTATION_TYPES).map((key, i)=>{
                      return <MenuItem value={parseInt(key)} key={i}>{PRESENTATION_TYPES[key]}</MenuItem>
                    })}
                  </Field>
                  <InputLabel variant='filled'>Presentation type</InputLabel>
                  {errors&&errors.type&&touched.type&&<FormHelperText error variant='filled'> {errors.type} </FormHelperText>}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl className={classes.formControl}>
                  <Field type='select' labelId='sessionIdLabel' displayEmpty name='sessionId' component={Select} label='Presenter' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                    <MenuItem value={'-'} key={-1}>None</MenuItem>
                    {sessions.map((session, i)=>{
                      return <MenuItem value={session.id} key={i}>{session.title}</MenuItem>
                    })}
                  </Field>
                  <InputLabel variant='filled'>Session</InputLabel>
                  {errors&&errors.sessionId&&touched.sessionId&&<FormHelperText error variant='filled'> {errors.sessionId} </FormHelperText>}
                </FormControl>
              </Grid>


              <Grid item container spacing={1}>

                <Grid item xs={6}>
                  <Typography variant='h6' component='h2'> Speakers/Authors </Typography>
                  Select one or more speakers from list to add.
                </Grid>

                <Grid item xs={6}>
                  <Typography variant='h6' component='h2'> Presenter </Typography>
                  Select one of the speakers as a presenter
                </Grid>

                <Grid item xs={6}>
                  <Field type='text' name='searchByName' component={TextField} label='Search by name' variant='filled'
                         fullWidth disabled={isSubmitting} />
                </Grid>

                <Grid item xs={6}>
                  <FormControl className={classes.formControl}>
                    <Field type='select' labelId='presenterIdLabel' displayEmpty name='presenterId' component={Select} label='Presenter' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                      {formData.participants.map((participant, i)=>{
                        return <MenuItem value={participant.id} key={i}>{`${participant.firstName} ${participant.lastName}`}</MenuItem>
                      })}
                      {formData.participants.length===0&&<MenuItem value={0} disabled> You need to add Speakers/Authors first, above</MenuItem>}
                    </Field>
                    <InputLabel variant='filled'>Presenter</InputLabel>
                    {errors&&errors.presenterId&&touched.presenterId&&<FormHelperText error variant='filled'> {errors.presenterId} </FormHelperText>}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>

                  {participants.length === 0 ?
                    <Box display='flex' justifyContent='center' alignItems='center' className={classes.participantsList}
                         aria-label='participants'>
                      <Box display='flex'>No speakers available</Box>
                    </Box> :
                    <List component='nav' className={classes.participantsList} aria-label='participants'>
                      {participants.filter((a)=>values.searchByName.trim()?(`${a.firstName} ${a.lastName}`).toLowerCase().includes(values.searchByName.trim().toLowerCase()):true).map((participant, i) => {
                        return <ListItem button key={i} onClick={() => {
                          addParticipant(participant, setFieldValue)
                        }}>
                          <ListItemText primary={`${participant.firstName} ${participant.lastName}`}/>
                        </ListItem>
                      })}
                    </List>}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Box display='flex' flexWrap='wrap' alignContent='flex-start' width={'100%'} height={149}
                       className={classes.participantsBox}>
                    {formData.participants && formData.participants.map((participant, i) => {
                      return <Chip
                        size='small'
                        key={i}
                        label={`${participant.firstName} ${participant.lastName}`}
                        onDelete={() => {
                          deleteParticipant(i, setFieldValue)
                        }}
                        className={classes.chip}
                      />
                    })}
                  </Box>
                </Grid>

              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Typography variant='h6' component='h2'> Keywords </Typography>
                  <Box>Adding keywords to your session helps search engines match it to specific search terms.</Box>

                </Grid>

                <Grid item xs={12} sm={12}>
                  <Box display='flex' flexWrap='wrap'  alignContent='flex-start' width={'100%'} height={180} className={classes.chipBox}>
                    <Box p={2} width={'100%'}>
                      <ChipInput
                        disabled={isSubmitting}
                        classes={{input:classes.chipInput}}
                        value={chips}
                        onAdd={(chip) => handleAddChip(chip)}
                        onDelete={(chip, index) => handleDeleteChip(chip, index)}
                        disableUnderline
                        placeholder={`Start by typing keywords separating by comma`}
                        newChipKeys={['enter',',',' ']}
                        fullWidth
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Box><Typography variant='h6' component='h2'> Files </Typography></Box>
                  <Box display='flex' p={1}>Upload one or more files in PDF / DOC / EXCEL / JPG / PNG format. Max
                    file size 5 MB</Box>
                </Grid>

                <Grid item xs={12} sm={6}>

                  <Box display='flex' flexWrap='wrap' alignContent='flex-start' width={'100%'} height={149}
                       className={classes.participantsBox}>
                    {formData.files && formData.files.map((file, i) => {
                      return <Chip
                        avatar={<Avatar className={classes.avatar}> <InsertDriveFileIcon/> </Avatar>}
                        size='small'
                        key={i}
                        component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                        label={`${file.filename}`}
                        onDelete={(e) => {
                          e.preventDefault()
                          deleteFile(i, setFieldValue)
                        }}
                        className={classes.chip}
                      />
                    })}
                    {formData && formData.uploadedFiles && formData.uploadedFiles.map((file, i) => {
                      return <Chip
                        avatar={<Avatar className={classes.avatar}> <InsertDriveFileIcon/> </Avatar>}
                        size='small'
                        key={i}
                        label={`${file.name}`}
                        onDelete={() => {
                          deleteUploadedFile(i)
                        }}
                        className={classes.chip}
                      />
                    })}

                    {formData.paperFiles.map((file,i) => {
                      return <>
                        <Chip
                          avatar={<Avatar className={classes.avatar}> <DescriptionIcon/> </Avatar>}
                          size='small'
                          label={`${file.id?file.filename:file.name}`}
                          className={classes.chipFile}
                          onDelete={(e) => {
                            e.preventDefault()
                            deleteFileFrom(i,'paperFiles')
                          }}
                          classes={{deleteIcon: classes.chipDelete}}
                          component={file.id?'a':false} href={file.id?`/files/${file.id}`:false} clickable={!!file.id} download={file.id?`${file.filename}`:false}
                        />
                      </>
                    })}

                    {formData.presentationFiles.map((file,i) => {
                      return <>
                        <Chip
                          avatar={<Avatar className={classes.avatar}> <SlideshowIcon /> </Avatar>}
                          size='small'
                          label={`${file.id?file.filename:file.name}`}
                          className={classes.chipFile}
                          onDelete={(e) => {
                            e.preventDefault()
                            deleteFileFrom(i,'presentationFiles')
                          }}
                          classes={{deleteIcon: classes.chipDelete}}
                          component={file.id?'a':false} href={file.id?`/files/${file.id}`:false} clickable={!!file.id} download={file.id?`${file.filename}`:false}
                        />
                      </>
                    })}

                    {formData.posterFiles.map((file,i) => {
                      return <>
                        <Chip
                          avatar={<Avatar className={classes.avatar}> <PhotoAlbumIcon/> </Avatar>}
                          size='small'
                          label={`${file.id?file.filename:file.name}`}
                          className={classes.chipFile}
                          onDelete={(e) => {
                            e.preventDefault()
                            deleteFileFrom(i,'posterFiles')
                          }}
                          classes={{deleteIcon: classes.chipDelete}}
                          component={file.id?'a':false} href={file.id?`/files/${file.id}`:false} clickable={!!file.id} download={file.id?`${file.filename}`:false}
                        />
                      </>
                    })}
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6}>

                  <Grid item container spacing={1} justifyContent='center' alignItems='center'>

                    <Grid item xs={12}>
                      <Box display='flex' p={1} pt={0} pl={2}>
                        <Grid item container>
                          <Grid item xs={12} className={classes.files}>
                            <UploadFile setFormData={setFormData} formData={formData} fieldName='paperFiles' buttonLabel={'Add a paper file'}/>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    {values.type===2&&<Grid item xs={12}>
                      <Box display='flex' p={1} pt={1} pl={2}>
                        <Grid item container>
                          <Grid item xs={12} className={classes.files}>
                            <UploadFile setFormData={setFormData} formData={formData} fieldName='presentationFiles' buttonLabel={'Add a presentation file'} singleUpload={formData.posterFiles.length>0}/>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>}

                    {values.type===1&&<Grid item xs={12}>
                      <Box display='flex' p={1} pt={1} pl={2}>
                        <Grid item container>
                          <Grid item xs={12} className={classes.files}>
                            <UploadFile setFormData={setFormData} formData={formData} fieldName='posterFiles' buttonLabel={'Add a poster file'} singleUpload={formData.presentationFiles.length>0}/>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>}

                    <Grid item xs={12}>
                      <Box display='flex' p={1} pt={1} pl={2}>
                        <Grid item container>
                          <Grid item xs={12} className={classes.files}>
                            <UploadFile setFormData={setFormData} formData={formData} fieldName='files' buttonLabel={'Add a miscellaneous file'} />
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>

                    {/*<Grid item xs={12}>*/}
                    {/*  <Box display='flex' p={1} pt={3} pl={2}>*/}
                    {/*    <UploadFile setFormData={setFormData} formData={formData} fieldName='presentationFiles' overwrite buttonLabel={'Add Presentation'}/>*/}
                    {/*  </Box>*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={12}>*/}
                    {/*  <Box display='flex' p={1} pt={1} pl={2}>*/}
                    {/*    <UploadFile setFormData={setFormData} formData={formData} fieldName='posterFiles' overwrite buttonLabel={'Add Poster'}/>*/}
                    {/*  </Box>*/}
                    {/*</Grid>*/}

                  </Grid>

                </Grid>

              </Grid>


              <Grid container>
                <Grid item md={3} xs='auto'> </Grid>
                <Grid container item md={6} spacing={3} className={classes.btns}>
                  <Grid item xs={12} md={6}>
                    <Button variant='outlined' size='small' colour='white' onClick={handleClose}
                            disabled={isSubmitting}> Cancel </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>

        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default PresentationEditNew
