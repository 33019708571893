import React,{useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import clsx from 'clsx'
import isPast from 'date-fns/isPast'
import parseISO from 'date-fns/parseISO'
import {format} from "date-fns"
// import SubmissionResponseCardView from 'Components/core/SubmissionResponseCardView'
import EllipsisText from 'react-ellipsis-text'
// import Chip from "@material-ui/core/Chip";
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
import parse from "html-react-parser";
import api from "api";
import {useHttp} from "api/core";
import moment from "moment";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = theme => ({

  submissionBox: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    padding: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main
    }
  },
  answeredSubmissions: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7
  },
  button: {
    height: '100%',
    position: 'relative',
    paddingTop: theme.spacing(1)
  },
  note: {
    fontSize: '11px',
    marginTop: 8,
    textAlign: 'center',
    color: palette['brown-grey'],
    fontWeight: 'bold'
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  priceColor: {
    color:theme.palette.primary.main
  },
  submissionsBox: {
    height: 'calc(100% - 16px)',
    // minHeight: '60px',
    // backgroundColor: palette['pale-sky-blue'],
    borderRadius: '4px',
    border: '1px solid #ddd',
    // boxShadow: '0 3px 5px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown'],
  },
  submissionsTitle: {
    color: 'black',
    // color: palette['cerulean-blue'],
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center'
  },
  submissionsAuthors: {
    fontSize: 12,
    fontWeight: 'normal'
  }
})

const SubmissionCardView = withStyles(styles)(({classes, handleOpen, event, submission, viewOnly,selectedTimezone, ...props}) => {

  const isClosed = isPast(parseISO(submission.closeDate))
  const addLabel = language.submit

  return (
    <>
      <Grid container spacing={1} className={classes.container}>
        <Grid item xs={12} md={viewOnly ? 12 : 9}>
          <Grid
            item
            container
            spacing={1}
            alignContent="center"
            alignItems="center"
            justify="center"
            className={clsx(classes.submissionBox, submission.answered && classes.answeredSubmissions, isClosed && classes.notAvailable)}>
            <Grid xs={5} sm={4} md={5} item>
              <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
                <Box className={classes.submissionsTitle}>
                  <EllipsisText text={submission.title ? submission.title : "-"} length={30} />
                </Box>
                <Box fontSize={13} mb={1}>
                  <EllipsisText text={submission.reviewFlowName ? submission.reviewFlowName : "-"} length={30} />
                </Box>
                <Box className={classes.submissionsAuthors} pl={1}>
                  {submission.authors && submission.authors.length && submission.authors.join(",")}
                </Box>
              </Box>
            </Grid>
            <Grid xs item>
              <Grid item container justify="flex-end" alignContent="center" alignItems="center">
                <Grid item>
                  {isClosed ? language.closed : language.closesOn} {parse("<br/>")}
                  {format(parseISO(submission.closeDate), "dd LLL yyyy hh:mm a", dateLocale[event.eventLanguage]) + " (" + moment.tz.guess().replace("_", " ") + ")"}
                  {parse("<br/>")}
                  {moment(submission.closeDate).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!viewOnly && (
          <Grid item xs={12} md={3}>
            <Grid item container justify="center" alignContent="flex-start" alignItems="center" className={classes.button}>
              <Grid item>
                <Button disabled={isClosed} autoWidth color={"secondary"} onClick={() => handleOpen({submissionId: submission.id, name: submission.name})}>
                  {" "}
                  {addLabel}{" "}
                </Button>
              </Grid>
              {submission.reviewed && (
                <Grid item xs={12} className={classes.note}>
                  {language.alreadyReviewed}
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
})

export default SubmissionCardView
