import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Form, Formik} from 'formik'
import {Checkbox, TextField, Typography} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import CreateEventNavigation from 'components/core/CreateEventNavigation'
import {withRouter} from 'react-router-dom'
import {useHttp} from 'api/core'
import api from 'api'
import {TERMS_PUBLISH, TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const styles = theme => ({
  title: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(4)
  },
  form: {
    width: '100%',
    color: 'white'
  }
})

const CreateEventTerms = withStyles(styles)(({classes, formData, setFormData, setEvent, prevRoute, nextRoute, history}) => {
  const [putRequest, putData] = useHttp()
  const [accepted, setAccepted] = useState(false)

  useEffect(() => {
    if (putData) {
      setEvent(putData)
    }
  }, [putData, setEvent])

  const handleChange = (event) => {
    setAccepted(event.target.checked)
  }

  return <Fade in={true} timeout={TIME_FADE_IN}>
    <Formik
      initialValues={formData}
      // validationSchema={Schema}
      onSubmit={(values, {setSubmitting}) => {

        setFormData({...formData, ...values, logo:formData.logo, cover:formData.cover})

        let formDataObj = new FormData();

        const {logo, cover, ...formDataItems} = {...formData, ...values, logo:formData.logo, cover:formData.cover}

        if (logo) {
          formDataObj.append('logo', logo)
        }

        if (cover) {
          formDataObj.append('cover', cover)
        }

        if (formDataItems.mailList.name==='') {
          formDataItems.mailList = null
        }

        const blob = new Blob([JSON.stringify(formDataItems)], {type: 'application/json'});
        formDataObj.append('event', blob)

        putRequest(api.entities.events.addNew(formDataObj, null, null, true))

      }}>
      {({submitForm, errors}) => (<>
          <Box className={classes.hideMobile}>
            <CreateEventNavigation submit={false} submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
          </Box>
        <Fade in={true} timeout={TIME_FADE_IN}><Form className={classes.form}>
          <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={4} minHeight={window.innerHeight-280}>
            <Box display='flex' width='600px' flexDirection='column' pb={8}>
              <Typography component='h2' variant='h5' className={classes.title}>Terms</Typography>

              <Grid container spacing={2} className={classes.terms}>
                <Grid item xs={12}>
                  <TextField multiline rowsMax={20} value={TERMS_PUBLISH} variant='filled' fullWidth/>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container justify='center' alignItems='center' spacing={2}>
                    <Grid item >
                      <FormControlLabel
                        control={<Checkbox checked={accepted} onChange={handleChange} name='accepted' />}
                        label='I have read and agree to the above Terms and Conditions'
                      />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>

              <Box mt={8} display='flex' justifyContent='center' alignContent='center' flexDirection='row'>
                <Box display='flex'><Button type='submit' disabled={!accepted}> Create Event </Button></Box>
              </Box>
            </Box>
          </Box>
          <Box className={classes.showMobile}>
            <CreateEventNavigation submit={false}  submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute}/>
          </Box>
        </Form></Fade></>
      )}
    </Formik>
  </Fade>
})

export default withRouter(CreateEventTerms)
