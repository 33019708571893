import {makeStyles} from '@material-ui/styles'

export const presentation_vert_1 = makeStyles({
  item_1: {
    top: '0%',
    left: '0%',
    width: '100%',
    height: '100%',
    opacity: '1 !important'
  }
})
