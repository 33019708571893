import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative'
  },
  ExtraCard: {},
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px'
  },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1200)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  }
})

const FieldText = withStyles(styles)(({title, value, classes}) => {
  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}> {value} </Box>
  </Box>
})

const ExtraCard = withStyles(styles)(({classes, extra, viewOnly = false, editAction, deleteAction, dragHandleProps, ...props}) => {
  return (
    <Box className={classes.ExtraCard} {...props}>
      <Box p={1} className={classes.container} display="flex" flowDirection="row" alignItems="flex-start">
        <Box display="flex">
          <Box mr={1} className={classes.dragGrip} {...dragHandleProps}>
            <DragIndicatorIcon />
          </Box>
        </Box>
        <Box display="flex">
          <Grid item xs={12}>
            <FieldText title={"Title"} value={`${extra.title ? extra.title : ""}`} />
          </Grid>
        </Box>

        {!viewOnly && (
          <Box display="flex" pt={1} width={50} justifySelf="end" justifyContent="end">
            <IconNotesBorderLight
              title="Edit Extra"
              className={classes.editIcon}
              onClick={() => {
                editAction && editAction(extra.id);
              }}
            />
            <IconXBorderLight
              title="Delete Extra"
              className={classes.deleteIcon}
              onClick={() => {
                deleteAction && deleteAction(extra.id);
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
})

export default ExtraCard
