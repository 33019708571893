import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {useHttp} from 'api/core'
import {Redirect, useLocation, withRouter} from 'react-router-dom'
// import {useMetaData} from 'Components/core/MetaProvider'
import Header from 'components/core/Header/Header'
// import api from 'api'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import api from 'api'
import FormViewer from 'components/core/Forms/FormViewer'
import {useSnackbar} from 'notistack'
import {transformForm} from 'utils'

const styles = theme => ({
  container: {
    minHeight: '50vh',
    paddingTop: theme.spacing(3)
  }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}


const ReviewResponse = withStyles(styles)(({classes, history, setProfileDialogOpen}) => {

  const { enqueueSnackbar } = useSnackbar()

  // eslint-disable-next-line
  const [canManage, setCanManage] = useState(false)
  const [form, setForm] = useState()
  // eslint-disable-next-line
  const [getData, data] = useHttp()
  const [getThematicCodes, thematicCodes] = useHttp()

  // eslint-disable-next-line
  const [putData, replyData] = useHttp()

  // eslint-disable-next-line
  // const [set] = useHttp()
  // const [, setMetaData] = useMetaData()
  const [fields, setFields] = useState()
  const [valid, setValid] = useState(false)
  const [redirect, setRedirect] = useState()

  // eslint-disable-next-line
  const q = useQuery()
  const uuid = q.get('token')

  // Initial Load
  useEffect(() => {
    getData(api.entities.reviewers.getInvitation(null, {token:uuid}))
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (data) {
      getThematicCodes(api.entities.options.getThematicCodes(null,null,{id:data.eventInfo.id}))
    }
    // eslint-disable-next-line
  },[data])

  const sendReply = () => {
    // const saveFields = []
    //
    // fields.forEach((f)=>{
    //   if (f.value!==null) {
    //     saveFields.push(f)
    //   }
    // })
    //
    // const payLoad = {
    //   token: uuid,
    //   accepted: form==='accept',
    //   form: { fields: [...saveFields] }
    // }

    let formDataObj = new FormData()
    const saveFields = transformForm(fields)

    const payLoad = { fields: [...saveFields.form] }

    const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'})
    formDataObj.append('form', blob)

    // Save files if any
    saveFields.files.forEach((file)=>formDataObj.append(file.key, file.data))

    const responsePayLoad = {
      token: uuid,
      accepted: form==='accept'
    }

    const blobResponse = new Blob([JSON.stringify(responsePayLoad)],{type: 'application/json'})
    formDataObj.append('response', blobResponse)

    putData(api.entities.reviewers.replyToInvitation(formDataObj,null,null,true))
  }

  useEffect(() => {
    if (replyData) {
      enqueueSnackbar('Successfully saved your response', {variant:'success'})
      setRedirect(true)
    }
    // eslint-disable-next-line
  }, [replyData])

  useEffect(() =>
  {
    if(form && fields.length===0) {
      sendReply();
    }
  }, [form])



  if (redirect) return <Redirect to={`/event/${data.eventInfo.id}/`}/>

  return <Page>
    <Header setProfileDialogOpen={setProfileDialogOpen} />
    <Grid item container className={classes.container} spacing={3} justify='center' alignContent='center' alignItems='center'>

      {!form && data && thematicCodes && <>
        <Grid item>
          <p>
            {data.message? data.message : "Please Accept or Reject the invitation"}
          </p>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center'>
            <Grid item>
              <Button size='small' onClick={()=>{setFields(data.acceptForm.fields); setForm('accept') }}> Accept </Button>
            </Grid>
            <Grid item>
              <Button size='small' colour={'red'} onClick={()=>{ setFields(data.rejectForm.fields); setForm('reject') }}> Reject </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
      }
      {form&&data&&fields.length!==0&&<>

        <Grid item container spacing={3} justify='center' alignContent='center' alignItems='center'>
          <Grid item container justify='center' alignContent='center' alignItems='center'>
            <Grid item xs={6}>
              <FormViewer fields={fields} setFields={setFields} valid={valid} setValid={setValid} thematicCodes={thematicCodes} />
            </Grid>
          </Grid>

          <Grid item container justify='center' alignContent='center' alignItems='center'>
            <Grid item >
              <Button type='submit'
                onClick={sendReply}
                disabled={!valid}
              > Submit </Button>
            </Grid>
          </Grid>
        </Grid>
      </>}

    </Grid>
  </Page>
})

export default withRouter(ReviewResponse)
