import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import {palette} from 'theme'
import SponsorsCarousel from 'components/core/SponsorsCarousel'

const styles = theme => ({
  container: {
    marginTop: 16,
    // borderRadius: '6px',
    // boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    // border: 'solid 1px #e6e6e6',
    color: palette['greyish-brown'],
    // overflow: 'hidden'
  },
  sponsorLogo: {
    overflow: 'hidden',
    width: '100%',
    '& img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }
  },
  slider: {
    width: 190
  }
})

const Sponsors = withStyles(styles)(({classes, sponsors, linkToPage=true, eventId}) => {

  if ((!sponsors)||(sponsors.length===0)) return <></>

  return <Grid item container className={classes.container} direction='column'>
      <Grid item className={classes.slider}>
          <SponsorsCarousel sponsors={sponsors} eventId={eventId}/>
      </Grid>
  </Grid>
})

export default Sponsors
