import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import RestaurantCardView from 'components/core/RestaurantCardView'
import language from "components/language";
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';


const restaurantStyles = theme => ({
  editButton: {
    float: "right",
		width: 175,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},  
})

export const RestaurantsList = withStyles(restaurantStyles)(({classes, event, isPreview, canManage}) => {
  const [restaurants, setRestaurants] = useState()
  const [getRestaurantsData, restaurantsData] = useHttp()
  const [restaurantCategories, setRestaurantCategories] = useState()
  const [getRestaurantCategories, restaurantCategoriesData] = useHttp()
  const history = useHistory();
  useEffect(() => {

    // Get restaurant categories
    getRestaurantCategories(api.entities.options.getRestaurantCategories())

    if (isPreview) {
      getRestaurantsData(api.entities.preview.getRestaurants(null, null, {id: event.eventInfo.id}))
    } else {
      getRestaurantsData(api.entities.events.getRestaurants(null, null, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (restaurantCategoriesData) {
      setRestaurantCategories(restaurantCategoriesData)
    }
  },[restaurantCategoriesData])

  useEffect(() => {
    if (restaurantsData) {
      setRestaurants(restaurantsData)
    }
  }, [restaurantsData])

  if (!restaurants) {
    return <> </> // Loading
  }

  if ((restaurants&&restaurants.length===0)||!restaurantCategories) return <> </>

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0} pb={12} flexDirection='column'>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/restaurants-hotels`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Restaurants</Button>}
            </Grid>          
        <Box pb={2}>
          <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.restaurants}</Box>
        </Box>
        {restaurants.map((restaurant,i) => {
          return <RestaurantCardView event={event} restaurant={restaurant} p={1} plain={restaurant.type !== 1} categories={restaurantCategories} index={i}/>
        })}
      </Box>
    </Fade>
  </>
})

export default RestaurantsList
