import {makeStyles} from '@material-ui/styles'

export const presentation_vert_9 = makeStyles({

  item_1: {
    top: '1%',
    left: '12.375%',
    width: '87.125%',
    height: '98%',
    opacity: '1 !important'
  },

  item_2: {
    left: '0.5%',
    top: '1%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_3: {
    left: '0.5%',
    top: '13.375%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_4: {
    left: '0.5%',
    top: '25.75%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_5: {
    left: '0.5%',
    top: '38.125%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_6: {
    left: '0.5%',
    top: '50.5%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_7: {
    left: '0.5%',
    top: '62.875%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_8: {
    left: '0.5%',
    top: '75.25%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  },

  item_9: {
    left: '0.5%',
    top: '87.625%',
    width: '11.375%',
    height: '11.375%',
    opacity: '1 !important'
  }
  
})
