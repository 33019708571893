import React, {useEffect,  useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {useHttp} from 'api/core'
import {useParams, withRouter} from 'react-router-dom'
import {useMetaData} from 'components/core/MetaProvider'
import Header from 'components/core/Header/Header'
import api from 'api'
import Box from '@material-ui/core/Box'
import RoomIntegration from 'components/core/RoomIntegration'
import RoomIntegrationOpenTok from 'components/core/OpenTok/RoomIntegrationOpenTok'

const styles = theme => ({
    container: {
        position: 'absolute',
        width: '100%',
        height: 'calc(100% - 128px)',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
})

const VirtualRoom = withStyles(styles)(({classes, history, setProfileDialogOpen}) => {

    const [canManage, setCanManage] = useState(false)
    const [getData, data] = useHttp()
    const [metaData, setMetaData] = useMetaData()

    const {uuid} = useParams()

    const redirectToInfo = (id) => {
        document.location.href=`/event/${id}/info`
    }

    // Initial Load
    useEffect(() => {
        getData(api.entities.virtualRooms.viewOne(null, null, {id: uuid}))

        //eslint-disable-next-line
        zE('webWidget', 'updateSettings',{
            webWidget: {
                offset: {
                    horizontal: '177px',
                    vertical: '70px'
                }
            }
        })
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (data) {
            setMetaData({title: data.eventInfo.name})
            setCanManage(data.eventInfo.role === 1 || data.eventInfo.role === 2 )
        }
        // eslint-disable-next-line
    }, [data])

    const hasData = data && metaData

    if (!hasData) return <> </>

    if (data&&metaData&&data.type!==4) {
        return <Page>
            <Header setProfileDialogOpen={setProfileDialogOpen} event={data} virtualRoomData isEvent extras={[]} canManage={canManage} isHost={data.host} />
            <Box className={classes.container}>
                {data&&metaData&&data.type!==4&&<RoomIntegration uuid={uuid} onExit={()=>redirectToInfo(data.eventInfo.id)}/>}
            </Box>
        </Page>
    }

    if (data&&metaData&&data.type===4) {
        return <Page title={data.title} p={0} noFooter backgroundColor={'#fafafa'}>
                <Header setProfileDialogOpen={setProfileDialogOpen} event={data} virtualRoomData isEvent extras={[]} canManage={canManage} isHost={data.host} />
                <RoomIntegrationOpenTok uuid={uuid} vroom={data} isVirtualRoom={true} />
        </Page>
    }
})

export default withRouter(VirtualRoom)
