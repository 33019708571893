import {withStyles} from '@material-ui/core/styles'
import {useParams} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import SessionCard from 'components/core/Sessions/SessionCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import {compareAsc, eachDayOfInterval, format, isSameDay} from 'date-fns'
import parseISO from 'date-fns/parseISO'
import SessionEditNew from 'components/core/Sessions/SessionEditNew'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import AnyTabs from 'components/core/AnyTabs'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import moment from "moment";
import SessionTypes from 'components/pages/ManageEvent/SessionTypes'
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  sessionsContainer: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(3),
  },
  sessionBox: {
    maxWidth: '1200px'
  },
  noSessionsYet: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  hours: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    height: 'calc(100% - 15px)',
    position: 'relative',
    maxWidth: '100px',
    justifySelf: 'flex-end',
    // margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
      height: 'auto',
      minHeight: theme.spacing(10),
      // padding: theme.spacing(3),
      // width: '100%',
      // textAlign: 'center'
    },
  },
  startTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
      position: 'relative',
      top: 0
    },
  },
  endTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      display: 'initial',
      position: 'relative',
      bottom: 0,
    },
  },
  day: {
    borderRadius: '8px',
    border: 'solid 1px rgba(0, 0, 0, 0.1)',
    maxHeight: '80px'
  },
  textDay: {
    fontSize: '13px'
  },
  textDate: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  textYear: {
    fontSize: '13px'
  },
  textDayTitle: {
    fontSize: '20px',
    fontWeight: 'bold'
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  inner: {
    maxWidth: '1200px',
    scrollX: 'auto',
    overflow: 'hidden'
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-23px",
    "& Button": {
      minWidth: "170px"
    }
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },    
})

const SessionsList = withStyles(styles)(({classes, participants, eventId, presentations, sessionsData, setSessionsData, tracks, day, days, handleOpen, swapSessions, handleDeleteOpen,selectedTimezone, unassigned = false}) => {
  const [putData] = useHttp()

  const onDragEnd = (e) => {

    function array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    }

    const draggedObj = JSON.parse(e.draggableId)
    let newSessionsData = [...sessionsData]

    let ids = draggedObj.ids // Ids in order
    let ordinals = draggedObj.ids.map((id) => sessionsData.find((s) => s.id === id).ordinal) // Ordinals in same order

    let originalOrdinals = []
    ids.forEach((id, i) => originalOrdinals.push({id, ordinal: ordinals[i]}))

    // Modified order of ordinals (swap places)
    array_move(ids, e.source.index, e.destination.index)

    let modifiedOrdinals = []
    ids.forEach((id, i) => modifiedOrdinals.push({id, order: ordinals[i]}))

    // Modified session data
    ids.forEach((id) => {
      newSessionsData[newSessionsData.findIndex((s) => s.id === id)].ordinal = modifiedOrdinals.find((o) => o.id === id).order
    })

    putData(api.entities.sessions.reorder({items: modifiedOrdinals}, null, {id: eventId}))

    setSessionsData([
      ...newSessionsData
    ])

  }

  return <Fade in={true} timeout={TIME_FADE_IN}>
    <>
      {days.map((day, i) => {

        // Group same hours
        let hoursArray = []
        sessionsData.filter((s) => isSameDay(parseISO(s.startDate), day)).forEach((s) => {
          if (hoursArray.indexOf(s.startDate) === -1) {
            hoursArray.push(s.startDate)
          }
        })

        // Sort descending
        hoursArray.sort((a, b) => compareAsc(parseISO(a), parseISO(b)))

        // Find end hours (get latest ending hour)
        let endHoursArray = []
        hoursArray.forEach((hour, y) => sessionsData.filter((s) => s.startDate === hour)
          .sort((a, b) => compareAsc(parseISO(a.endDate), parseISO(b.endDate)))
          .forEach((s) => endHoursArray[y] = s.endDate))

        return <Grid container className={classes.sessionBox} key={i}>
          <Grid container spacing={2} className={classes.sessionsContainer} justify='flex-end'>
            <Grid item xs={4} sm={4} md={1}>

              <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'
                   flexDirection='column' className={classes.day}>
                <Box display='flex' className={classes.textDay}> {format(day, 'LLL')} </Box>
                <Box display='flex' className={classes.textDate}> {format(day, 'dd')} </Box>
                <Box display='flex' className={classes.textYear}> {format(day, 'yyyy')} </Box>
              </Box>

              <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'
                   className={classes.textDayTitle} pt={2}>
                Day {i + 1}
              </Box>

            </Grid>

            {hoursArray.map((hour, z) => {
              return <>
                {z > 0 ? <Grid item xs={12} sm={12} md={1}> </Grid> : ''}
                <Grid item xs={12} sm={12} md={1}>
                  <Grid item className={classes.hours}>
                    <Box className={classes.startTime}>
                      {/* {format(parseISO(hour), 'hh:mmaa')} */}
                      {moment(hour).tz(selectedTimezone, true).format("hh:mmA").toString()}                                        
                    </Box>
                    <Box className={classes.endTime}>
                      {moment(endHoursArray[z]).tz(selectedTimezone, true).format("hh:mmA").toString()}   
                      {/* {format(parseISO(endHoursArray[z]), 'hh:mmaa')} */}
                    </Box>
                  </Grid>
                </Grid>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId={`${z}`}>
                    {(provided) => {
                      const groupedSessions = sessionsData
                        // .filter((s)=>isSameHour(parseISO(s.startDate),parseISO(hour)))
                        .filter((s) => s.startDate === hour)
                        .sort((a, b) => (parseInt(a.ordinal) - parseInt(b.ordinal)))
                      // .sort((a, b) => compareAsc(parseISO(a.endDate), parseISO(b.endDate)))

                      const draggableObj = {ids: groupedSessions.map((s) => s.id)}

                      return <Grid item xs={12} sm={12} md={10} ref={provided.innerRef} {...provided.droppableProps}>
                        {
                          groupedSessions.map((session, y) => <Draggable
                              draggableId={`${JSON.stringify({...draggableObj, id: session.id})}`} index={y}>
                              {(provided) => (<Box {...provided.draggableProps}
                                                   ref={provided.innerRef}><SessionCard day={0}
                                                                                        tracks={tracks}
                                                                                        dragHandleProps={provided.dragHandleProps}
                                                                                        session={session}
                                                                                        participants={participants}
                                                                                        presentations={presentations}
                                                                                        index={y} editAction={() => {
                                handleOpen(session)
                              }} deleteAction={() => {
                                handleDeleteOpen(session.id)
                              }} mb={2}/></Box>)}
                            </Draggable>
                          )}
                        {provided.placeholder}
                      </Grid>
                    }}
                  </Droppable>
                </DragDropContext>
              </>
            })}


            {hoursArray.length === 0 && <>
              <Grid item md={1}>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display='flex' className={classes.noSessionsYet}>
                  No sessions yet!
                </Box>
                <Box display='flex' justifyContent='flex-end' pt={3}>
                  <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={() => {
                    handleOpen(undefined, day.setHours(9, 0, 0, 0))
                  }}>Add Session</Button>
                </Box>
              </Grid>

            </>}

            {hoursArray.length !== 0 && <>
              <Box display='flex' justifyContent='flex-end' pt={1} pr={2}>

                <Button variant='outlined' icon={<IconAddBorderBlue/>} size='small' color='primary' onClick={() => {
                  handleOpen(undefined, parseISO(endHoursArray[endHoursArray.length - 1]))
                }}>Add Session</Button>
              </Box>
            </>}

          </Grid>

        </Grid>
      })}
    </>
  </Fade>
})

const createDaysArray = (eventInfo) => {
  return eachDayOfInterval({start: parseISO(eventInfo.startDate), end: parseISO(eventInfo.endDate)})
}

const Sessions = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack}) => {
  const [getData, data] = useHttp();
  const [getParticipantsData, participantsData] = useHttp();
  const [getPresentationData, presentationData] = useHttp();
  const [getRooms, roomData] = useHttp();
  const [getTracks, trackData] = useHttp();
  const [participants, setParticipants] = useState();
  const [delRequest, delData] = useHttp();

  const [getDataPublish, dataPublish] = useHttp();

  const [getSessionTypes, sessionTypesData] = useHttp();
  const [sessionTypes, setSessionTypes] = useState();

  const [openSessionDialog, setOpenSessionDialog] = useState(false);
  const [editingSession, setEditingSession] = useState();
  const [days, setDays] = useState();
  const [editingDay, setEditingDay] = useState();
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deletingSession, setDeletingSession] = useState();
  const [sessionsData, setSessionsData] = useState();
  const [presentations, setPresentations] = useState();
  const [rooms, setRooms] = useState();
  const [tracks, setTracks] = useState();

  const {eventId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  const [sessionTypesLoaded, setSessionTypesLoaded] = useState(false)

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    //eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (presentations && timeZones) {
      const zone = timeZones.find((x) => x.id === presentations.eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presentations, timeZones]);


  useEffect(() => {
    getData(api.entities.manage.getSessions(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);

  useEffect(() =>
  {
    getData(api.entities.manage.getSessions(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, [dataPublish]);

  useEffect(() => {
    getParticipantsData(api.entities.manage.getParticipants(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);


  useEffect(() => {
    getPresentationData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getRooms(api.entities.rooms.list(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (roomData) {
      setRooms(roomData);
    }
  }, [roomData]);

  useEffect(() => {
    getTracks(api.entities.manage.getTracks(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (trackData) {
      setTracks(trackData.tracks);
    }
  }, [trackData]);

  useEffect(() => {
    getSessionTypes(api.entities.manage.getSessionTypes(null, null, {id: eventId}));
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (sessionTypesData) {
      setSessionTypes(sessionTypesData.sessionTypes);
    }
  }, [sessionTypesData]);

  useEffect(() => {
    if (presentationData) {
      setPresentations(presentationData);
    }
  }, [presentationData]);

  useEffect(() => {
    if (data) {
      setDays(createDaysArray(data.eventInfo));
      setSessionsData(data.sessions);
      setPageTitle(data.eventInfo.name);
      setEventInfo(data.eventInfo);
      setNavBack({path: "/manage-events", name: "events"});
    }
  }, [data, setEventInfo, setNavBack, setPageTitle]);

  useEffect(() => {
    if (participantsData) {
      setParticipants(participantsData.participants);
    }
  }, [participantsData, setParticipants]);

  const handleClose = (shouldReload) => {
    setOpenSessionDialog(false);
    if (shouldReload) {
      getPresentationData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}));
      getData(api.entities.manage.getSessions(null, null, {id: eventId}));
    }
  };

  const handleOpen = (session, day) => {
    setOpenSessionDialog(true);
    setEditingDay(day);
    if (session) {
      setEditingSession(session);
    } else {
      setEditingSession(undefined);
    }
  };

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true);
    setDeletingSession(id);
  };

  const handleDeleteClose = (id) => {
    setDeleteOpen(false);
    if (id === deletingSession) {
      delRequest(api.entities.sessions.delete(null, null, {id: id}));
      setDeletingSession(undefined);
    }
  };

  useEffect(() => {
    if (delData) {
      enqueueSnackbar("Successfully deleted session", {variant: "success"});
      getData(api.entities.manage.getSessions(null, null, {id: eventId}));
    }
    // eslint-disable-next-line
  }, [delData]);

  const [ZipData, setZipData] = useState();
  const [getZip, dataZip, zipLoading] = useHttp();
  const [xlsxData, setXlsxData] = useState(); 
  const [getXlsx, dataXlsx, xlsxLoading] = useHttp();
  
  useEffect(() =>
  {
    if (dataZip) {
      setZipData(dataZip);
    }
  }, [dataZip]);

  useEffect(() => {
    if (ZipData) {
      let ZipURI = window.URL.createObjectURL(dataZip[0]);
      let downloadLink = document.createElement("a");
      downloadLink.href = ZipURI;
      downloadLink.download = `logs.zip`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setZipData(null);
    }
    //eslint-disable-next-line
  }, [ZipData]);

  const downloadZip = () => {
    getZip(api.entities.sessions.downloadLogs(null, null, {id: eventId}, false));
  };


  useEffect(() => {
    if (dataXlsx) {
      setXlsxData(dataXlsx);
    }
  }, [dataXlsx]);

  useEffect(() => {
    if (xlsxData) {
      let xlsxURI = window.URL.createObjectURL(dataXlsx[0]);
      let downloadLink = document.createElement("a");
      downloadLink.href = xlsxURI;
      downloadLink.download = `sessions.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      setXlsxData(null);
    }
    //eslint-disable-next-line
  }, [xlsxData]);

  const exportSessions = async() =>{
    await getXlsx(api.entities.manage.downloadSessions(null, null, {id: eventId}, false));  
  }

  const [stopOpen, setStopOpen] = useState(false);
  const [stopReq, stopData, stopLoading] = useHttp();
  const [openSessionType, setOpenSessionType] = useState(false);

  const handleStopClose = (stop) => {
    setStopOpen(false);
    if (stop === true) {
      stopReq(api.entities.manage.stopSessions(null, null, {id: eventId}));
    }
  };

  useEffect(() => {
    if (stopData && stopData.status && stopData.status === 204) {
      enqueueSnackbar("Successfully ended all sessions", {variant: "success"});
    }
    // eslint-disable-next-line
  }, [stopData]);

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
  };
  
  const handleSessionType = () => {
    setOpenSessionType(prevOpenSessionType => !prevOpenSessionType)
    if (openSessionType)
    {
      getSessionTypes(api.entities.manage.getSessionTypes(null, null, {id: eventId}));
    }
    setTimeout(() =>
    {
      setSessionTypesLoaded(false)
    },[500])
  }

  if (!sessionsData || !participants || !presentations || !rooms || !tracks) return <> </>;

  return (
    <Box display="flex">
      <Dialog onClose={handleClose} open={openSessionDialog} maxWidth={"md"} fullWidth>
        <SessionEditNew
          session={editingSession}
          handleClose={handleClose}
          eventId={eventId}
          eventInfo={data.eventInfo}
          setSuccessful={setSuccessful}
          presentations={presentations.presentations.filter((a) => a.sessionId === null)}
          participants={participants}
          editingDay={editingDay}
          totalSessions={sessionsData.length}
          rooms={rooms}
          tracks={tracks}
          uploadFiles={true}
          sessionTypes={sessionTypes}
          selectedTimezone={selectedTimezone}
        />
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete session</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete session?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteClose()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteClose(deletingSession);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <Dialog open={openSessionType} onClose={handleSessionType} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
            <SessionTypes setPageTitle={setPageTitle} setEventInfo={setEventInfo} setNavBack={setNavBack} setSessionTypesLoaded={setSessionTypesLoaded}/>

          {!sessionTypesLoaded &&
            <Grid item container justify='center' alignContent='center' alignItems='center' style={{minHeight: '560px',minWidth: "550px"}}>
              <Grid item >
                <CircularProgress size={'6rem'} color='secondary' />
              </Grid>
            </Grid>
          }            

          </DialogContent>        
        <DialogActions>
            <Box p={1}>
              <Button
                onClick={handleSessionType}
                size="small"
                autoFocus
                color="primary">
                Close
              </Button>
            </Box>
        </DialogActions>
      </Dialog>
      <Dialog open={stopOpen} onClose={handleStopClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">End sessions</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to end all sessions?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleStopClose()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleStopClose(true);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>

      <Grid container spacing={2}>
        <Grid item container justify="flex-end" spacing={2} className={classes.inner} style={{marginTop:20, marginBottom: 18}}>
          <Grid item xs={6}>
            <Grid item container spacing={2} justify="flex-start">
              <Grid item>
                <Button size="smaller" colour="blue" variant="contained" onClick={() => handleSessionType()} disabled={xlsxLoading}>
                  Create Sessions types
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid item container spacing={2} justify="flex-end">
              <Grid item>
                <Button size="smaller" colour="blue" variant="contained" onClick={() => exportSessions()} disabled={xlsxLoading}>
                  Export Sessions
                </Button>
              </Grid>
              <Grid item>
                <Button size="smaller" variant="contained" onClick={() => downloadZip()} disabled={zipLoading}>
                  Export Q&A/Chat logs
                </Button>
              </Grid>
              <Grid item>
                <Button size="smaller" colour="red" variant="contained" onClick={() => setStopOpen(true)} disabled={stopLoading}>
                  End all sessions
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.inner}>
          <AnyTabs
            tabs={[
              {
                label: "All",
                component: (
                  <SessionsList
                    days={days}
                    sessionsData={sessionsData}
                    participants={participants}
                    eventId={eventId}
                    presentations={presentations.presentations}
                    handleOpen={handleOpen}
                    handleDeleteOpen={handleDeleteOpen}
                    setSessionsData={setSessionsData}
                    rooms={rooms}
                    tracks={tracks}
                    selectedTimezone={selectedTimezone}
                  />
                ),
              },
              {
                label: "Unassigned",
                component: (
                  <SessionsList
                    unassigned
                    sessionsData={sessionsData}
                    participants={participants}
                    eventId={eventId}
                    presentations={presentations.presentations}
                    handleOpen={handleOpen}
                    handleDeleteOpen={handleDeleteOpen}
                    setSessionsData={setSessionsData}
                    rooms={rooms}
                    tracks={tracks}
                    selectedTimezone={selectedTimezone}
                  />
                ),
                disabled: true,
                // disabled: sessionsData.sessions.map((day,i)=>day.filter((s)=>s.startDate===null).length===0)
              },
              ...days.map((day, i) => {
                return {
                  index: i,
                  label: `Day ${i + 1}`,
                  component: (
                    <SessionsList
                      days={[day]}
                      sessionsData={sessionsData}
                      participants={participants}
                      eventId={eventId}
                      presentations={presentations.presentations}
                      handleOpen={handleOpen}
                      handleDeleteOpen={handleDeleteOpen}
                      setSessionsData={setSessionsData}
                      rooms={rooms}
                      tracks={tracks}
                      selectedTimezone={selectedTimezone}
                    />
                  ),
                };
              }),
            ]}
          />
        </Grid>
      </Grid>
      {data.eventInfo && data.eventInfo.dirty && <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons}  style={{position:"absolute" , top:  "0" }}>                   
                      <Alert className={classes.topPublish} severity="warning">
                        <AlertTitle>Unpublished changes</AlertTitle>
                        You are viewing an event with unpublished changes.
                      </Alert>                    
                    <Box display="inline-block" mt={1} mb={1}>
                      {data.eventInfo.dirty ? (
                        <Button
                          onClick={() => {
                            publishEvent();
                          }}>
                          Publish
                        </Button>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Grid>}      
    </Box>
  );
})

export default Sessions
