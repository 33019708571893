import React, {useEffect, useState} from 'react'
import {Box, Grid} from '@material-ui/core'

import {imageFile} from 'utils'
import {useHttp} from 'api/core'
import api from 'api'
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import Button from '@material-ui/core/Button'

import clsx from 'clsx'
//import {ThumbUp} from '@material-ui/icons'


const QnAAnswers = ({classes,sessionState, questionId, answers, isLoading,getAnswers,refreshQuestions, localTimeZone}) => {

  const {
    isCoach,
    isChair,
    roomId
  } = sessionState
  
  const [answersData, setAnswersData] = useState([])
  const [getQuestions, receivedQuestions] = useHttp()
  const [deleteAnswer, deletingAnswer] = useHttp()

  useEffect(()=>{
    if (deletingAnswer) {
      getAnswers(api.entities.virtualRoom.getQnaAnswers(null, null, {id: questionId}))
      getQuestions(api.entities.virtualRoom.getQnaQuestions(null, null, {id: roomId}))
    }
    // eslint-disable-next-line
  },[deletingAnswer])

  useEffect(()=>{
    if (receivedQuestions)
      refreshQuestions(receivedQuestions)
    // eslint-disable-next-line
  },[receivedQuestions])

  useEffect(() => {
    if (questionId && answers)
      setAnswersData(answers)
  }, [questionId, answers])

  const handleDeleteAnswer = (answerId) =>{
    if (answerId)
      deleteAnswer(api.entities.virtualRoom.deleteAnswer(null,null,{id:answerId}))
  }

  return <>
      <Grid container direction={'row'}>
        {isLoading && <Grid item container justify='center' alignItems='center' alignContent='center' style={{height:'180px'}}>
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>}
        {!isLoading && answersData.map((answer,index) => {
          return <><ListItem alignItems="flex-start" className={clsx(classes.listItem,classes.answer)}  key={`${questionId}-${index}`}>
            <ListItemAvatar classes={{root:classes.avatarRoot}}>
              <Avatar alt={answer.firstName ? `${answer.title?answer.title:''} ${answer.firstName} ${answer.lastName}` : 'Anonymous'} src={imageFile(answer.imageId ? answer.imageId : '')} style={{width:24,height:24}} />
            </ListItemAvatar>
            <ListItemText classes={{primary:classes.userName}}
                          primary={<>
                            <Grid item container justify={'space-between'} alignItems='center' alignContent='center'>
                              <Grid item>
                                {answer.firstName ? `${answer.title?answer.title:''} ${answer.firstName} ${answer.lastName}` : 'Anonymous'}
                              </Grid>
                              <Grid item>
                                <Box fontSize={10} style={{opacity:0.7}}>
                                  {moment(answer.createdAt).add(localTimeZone,'hours').format('HH:mm')}
                                </Box>
                              </Grid>
                            </Grid>
                          </>}
                          secondary={<>
                            <Grid item container>

                              <Grid item xs={12} className={clsx(classes.message,classes.messageAnswer)}>
                                {answer.text}
                              </Grid>

                              <Grid item container justify='flex-end' spacing={1}>
                                <Grid item>
                                  {(answer.owned || (isChair || isCoach )) &&
                                        <Button size='small' variant='contained' color='default' style={{color:'#d0021b', backgroundColor:'#eeeeee'}} onClick={() => handleDeleteAnswer(answer.id)}>Delete</Button>}
                                </Grid>
                              </Grid>

                            </Grid>

                          </>}
            />
          </ListItem>
          {/*<Grid item xs={12} key={answer.id}>*/}
          {/*  <Box mt={1} mb={1} className={classes.answerBox}>*/}
          {/*    <Grid container direction={'column'}>*/}
          {/*      <Grid container direction='row'*/}
          {/*            justify='flex-start'*/}
          {/*            alignItems='center'*/}
          {/*            spacing={1}>*/}
          {/*        <Grid item xs={1}>*/}
          {/*          <Box className={classes.userPhoto}>*/}
          {/*            <img src={imageFile(answer.imageId ? answer.imageId : '')}*/}
          {/*                 alt={'Users profile'}/>*/}
          {/*          </Box>*/}
          {/*        </Grid>*/}
          {/*        <Grid item xs={4}>*/}
          {/*          <Typography*/}
          {/*            variant={'subtitle2'}*/}
          {/*            style={{color: '#afa2a2'}}> {answer.title?answer.title:''} {answer.firstName} {answer.lastName}</Typography>*/}
          {/*        </Grid>*/}
          {/*        <Grid item xs={1}>*/}
          {/*          <Box>*/}
          {/*            <Typography className={classes.userTimeStamp} align={'right'} display={'inline'}>{*/}
          {/*              format(*/}
          {/*                parseISO(answer.createdAt),*/}
          {/*                'hh:mmaa'*/}
          {/*              )}*/}
          {/*            </Typography>*/}
          {/*          </Box>*/}
          {/*        </Grid>*/}
          {/*      </Grid>*/}
          {/*    </Grid>*/}
          {/*    <Box mt={2} mb={2}>*/}
          {/*      <Typography variant={'body2'}> {answer.text}</Typography>*/}
          {/*    </Box>*/}
          {/*    {(answer.owned || (session.status.isChair || session.status.coach )) &&*/}
          {/*    <Grid container direction='row'*/}
          {/*          justify='flex-end'*/}
          {/*          alignItems='center'*/}
          {/*          spacing={1}>*/}
          {/*      <Grid item>*/}
          {/*        <Typography variant={'subtitle2'} className={classes.userDeleteQuestion}*/}
          {/*                    onClick={() => handleDeleteAnswer(answer.id)}>Delete</Typography>*/}
          {/*      </Grid>*/}
          {/*    </Grid>}*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
          </>
        })
          
          }
      </Grid>
  </>
}

export default QnAAnswers
