import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import VisibilityIcon from '@material-ui/icons/Visibility'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import api from 'api'
import {useHttp} from 'api/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import CloseIcon from '@material-ui/icons/Close'
import parseISO from 'date-fns/parseISO'
import formatDistance from 'date-fns/formatDistance'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import isPast from 'date-fns/isPast'
import isFuture from 'date-fns/isFuture'
import momentTz from 'moment-timezone'
import moment from 'moment'
import {palette} from 'theme'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PanToolIcon from '@material-ui/icons/PanTool'
// import FormControlLabel from '@material-ui/core/FormControlLabel'
// import Checkbox from '@material-ui/core/Checkbox'

const styles = theme => ({

})

const VideoHeader = withStyles(styles)(({classes, sessionState, setInCall}) => {

  const {
    roomId,
    isCoach,
    isStarted,
    layoutMode,
    isLive,
    startDate,
    endDate,
    isChair,
    isViewer,
    isOnlinePoster,
    isVirtualRoom,
    canRaiseHands,
    type,
    raisedHand,
    participants,
    participantId,
    recording,
    useRecording
  } = sessionState

  const [sessionLive, setSessionLive] = useState(isLive)
  const [sessionStarted, setSessionStarted] = useState(isStarted)
  const [setLive, , isLoadingSetLive, hasErrorSetLive] = useHttp()
  const [delLive, , isLoadingDelLive, hasErrorDelLive] = useHttp()
  const [setStarted, , isLoadingSet, hasErrorSet] = useHttp()
  const [delStarted, , isLoadingDel, hasErrorDel] = useHttp()
  const [sessionEndDialog, setSessionEndDialog] = useState(false)

  // eslint-disable-next-line
  const [closeQA, setCloseQA] = useState()
  // eslint-disable-next-line
  const [closeChat, setCloseChat] = useState()
  // eslint-disable-next-line
  const [closePolls, setClosePolls] = useState()
  // eslint-disable-next-line
  const [localTimeZone, setLocalTimeZone] = useState()

  useEffect(()=>{
    const timeZone = momentTz.tz.guess()
    const timeZoneDifference = parseInt(moment.tz(timeZone).format('Z'))
    setLocalTimeZone(timeZoneDifference)
  },[])

  const toggleLive = () => {
    if (!sessionLive) {
      setSessionLive(true)
      setLive(api.entities.virtualRoom.setLive(null,null,{id:roomId}))
    } else {
      setSessionLive(false)
      delLive(api.entities.virtualRoom.delLive(null,null,{id:roomId}))
    }
  }

  const toggleSession = () => {
    if (!sessionStarted) {
      setSessionStarted(true)
      setStarted(api.entities.virtualRoom.setStarted(null,null,{id:roomId}))
    } else {
      setSessionEndDialog(true)
    }
  }

  useEffect(()=>{
    if (hasErrorSet) {
      setSessionStarted(false)
    }
  },[hasErrorSet])

  useEffect(()=>{
    if (hasErrorDel) {
      setSessionStarted(true)
    }
  },[hasErrorDel])

  useEffect(()=>{
    if (hasErrorSetLive) {
      setSessionLive(false)
    }
  },[hasErrorSetLive])

  useEffect(()=>{
    if (hasErrorDelLive) {
      setSessionLive(true)
    }
  },[hasErrorDelLive])

  const handleSessionEndClose = (closeSession) => {
    setSessionEndDialog(false)
    if (closeSession) {
      setSessionStarted(false)
      delStarted(api.entities.virtualRoom.delStarted({
        PollsActive: !closeSession,
        QAActive: !closeQA,
        ChatActive: !closeChat
      },null,{id:roomId}))
    }
  }

  const speakerObj = participants.find((s)=>s.id===participantId)
  const canBroadcast = speakerObj&&speakerObj.canBroadcast

  // eslint-disable-next-line
  const [sendRaiseHand, raiseHandData, raiseHandLoading, raiseHandError] = useHttp()
  // eslint-disable-next-line
  const [sendDropHand, dropHandData, dropHandLoading, dropHandError ] = useHttp()

  const [sentHand, setSentHand] = useState(false)
  useEffect(()=>{
    if (raisedHand) {
      setSentHand(false)
    }
  },[raisedHand])
  const raiseHand = () => {
    if (raisedHand) {
      sendDropHand(api.entities.virtualRoom.dropHand(null,null,{id:roomId}))
    } else {
      sendRaiseHand(api.entities.virtualRoom.raiseHand(null,null,{id:roomId}))
      setSentHand(true)
    }
  }

  // const handleChangeChatClose = (e) => {
  //   const value = e.target.checked
  //   setCloseChat(value)
  // }
  //
  // const handleChangePollClose = (e) => {
  //   const value = e.target.checked
  //   setClosePolls(value)
  // }
  //
  // const handleChangeQAClose = (e) => {
  //   const value = e.target.value.checked
  //   setCloseQA(value)
  // }

  return <Grid item container justify='space-between' alignContent='center' alignItems='center'>
    {<Dialog
      open={sessionEndDialog}
      onClose={handleSessionEndClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title">End session</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Are you sure you want to end this session?
            </Grid>
            {/*<Grid item container direction='column'>*/}
            {/*  <Grid item>*/}
            {/*    Additionally:*/}
            {/*  </Grid>*/}
            {/*  <Grid item container >*/}
            {/*    <Grid item container direction='column' alignItems='flex-end' justify='flex-end'>*/}
            {/*      <Grid item xs={5}>*/}
            {/*        <FormControlLabel*/}
            {/*          control={*/}
            {/*            <Checkbox*/}
            {/*              checked={closePolls}*/}
            {/*              onChange={handleChangePollClose}*/}
            {/*              name="polls"*/}
            {/*              color="primary"*/}
            {/*            />*/}
            {/*          }*/}
            {/*          label="Close polls"*/}
            {/*        />*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={5}>*/}
            {/*        <FormControlLabel*/}
            {/*          control={*/}
            {/*            <Checkbox*/}
            {/*              checked={closeQA}*/}
            {/*              onChange={handleChangeQAClose}*/}
            {/*              name="qa"*/}
            {/*              color="primary"*/}
            {/*            />*/}
            {/*          }*/}
            {/*          label="Close Q&A"*/}
            {/*        />*/}
            {/*      </Grid>*/}
            {/*      <Grid item xs={5}>*/}
            {/*        <FormControlLabel*/}
            {/*          control={*/}
            {/*            <Checkbox*/}
            {/*              checked={closeChat}*/}
            {/*              onChange={handleChangeChatClose}*/}
            {/*              name="chat"*/}
            {/*              color="primary"*/}
            {/*            />*/}
            {/*          }*/}
            {/*          label="Close chat"*/}
            {/*        />*/}
            {/*      </Grid>*/}
            {/*    </Grid>*/}

            {/*  </Grid>*/}


            {/*</Grid>*/}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button variant={'contained'} onClick={() => {
              handleSessionEndClose(true)
            }} autoFocus color='secondary'>
              Yes
            </Button>
          </Box>
          <Box p={1}>
            <Button onClick={() => handleSessionEndClose()}  variant='contained' color='primary'>
              No
            </Button>
          </Box>
        </DialogActions>
      </Box>

    </Dialog>}
    <Grid item>
      <Grid item container alignContent='center' alignItems='center' spacing={2}>

        {!isOnlinePoster&&!useRecording&&<Grid item>
          <Grid item container justify='center' alignContent='center' alignItems='center' id={'chair-control-session'}>
            <Grid item>
              {!isLive&&<FiberManualRecordIcon style={{color:'#7ed321',marginTop:'4px',marginRight:'4px'}} />}
              {isLive&&<FiberManualRecordIcon style={{color:'red',marginTop:'4px',marginRight:'4px'}} />}
            </Grid>
            <Grid item>
              <Grid item container direction='column' style={{marginTop: 14}}>
                <Grid item>
                  {!isLive&&<Tooltip title='Backstage means that the audience cannot see you.' aria-label="audience-does-not-see-you">
                    <Box style={{cursor:'default'}}>Backstage</Box>
                  </Tooltip>}
                  {isLive&&<>
                    {recording?<>Recording</>:<>Live</>}
                  </>}
                </Grid>
                <Grid item style={{fontSize: 12, color:palette['greyish-brown'], opacity: (isViewer&&!isCoach)?0:1}}>
                  {layoutMode===1&&<>Banner</>}
                  {layoutMode===2&&<>Discussion</>}
                  {layoutMode===3&&<>Presentation</>}
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>}

        {(( isCoach || isChair) && !isOnlinePoster )&&<Grid item>
          <Tooltip title='View the session as a normal attendee would' aria-label="view-as-attendee">
            <Button startIcon={<PersonOutlineIcon />} variant='outlined' color='secondary' size='small' style={{color:'white', borderColor:'white'}} href={`${window.location.href.endsWith('/')?window.location.href.slice(0,-1):window.location.href}/viewer`} target='_blank'>View as Attendee</Button>
          </Tooltip>
        </Grid>}

        {(( isCoach || isChair) && !isOnlinePoster )&&<>
          <Grid item>
            <>
              {!isLive&&<Tooltip title='Start live broadcasting, leaving Backstage' aria-label="start-live"><Button startIcon={<VisibilityIcon />} variant='contained' color='secondary' size='small' onClick={toggleLive} style={{ opacity:(isLoadingDelLive||isLoadingSetLive||(isLive!==sessionLive))?0.5:1 }}> Go Live</Button></Tooltip>}
              {isLive&&<Tooltip title='End live broadcasting, go to Backstage' aria-label="end-live"><Button startIcon={<VisibilityOffIcon />} variant='contained' color='secondary' size='small' onClick={toggleLive} style={{ opacity:(isLoadingDelLive||isLoadingSetLive||(isLive!==sessionLive))?0.5:1 }}> Go Backstage</Button></Tooltip>}
            </>
          </Grid>
          <Grid item>
            {(isChair||isCoach)&&!isOnlinePoster&&!isVirtualRoom&&<Tooltip
              title={isStarted?'End the session, stopping live broadcasting or backstage video':'Start the session allowing backstage video and live broadcasting'}
              aria-label="toggle-session">
              <Button startIcon={sessionStarted?<CloseIcon/>:<PlayArrowIcon />} style={{opacity: (isLoadingSet||isLoadingDel||(sessionStarted!==isStarted))?0.5:1}} size='small' variant={sessionStarted?'outlined':'contained'} color='secondary' onClick={()=>toggleSession()}> {sessionStarted?'End Session':'Start Session'} </Button>
            </Tooltip>}

          </Grid>
        </>}

      </Grid>
    </Grid>

    {!canBroadcast && canRaiseHands && !isChair && !isCoach && type!=='viewer'&&<Grid item>
      <Grid item container justify='space-between' alignItems='center' alignContent='center' spacing={1}>
        <Grid item>
          {(!raisedHand||sentHand)?<Button variant={'contained'} color='secondary' startIcon={<PanToolIcon />} onClick={raiseHand}> Raise hand </Button>:
            <Grid item container direction='column' justify='center' alignContent='center' alignItems='center' style={{position:'relative'}}>
              <Grid item>
                <Button variant={'contained'} color='primary' startIcon={<PanToolIcon />} onClick={raiseHand}> Waiting for permission...</Button>
                <Box onClick={raiseHand} style={{position:'absolute', bottom:-24, width:'100%', textAlign:'center', cursor:'pointer'}}>Click again to cancel</Box>
              </Grid>
            </Grid>}
        </Grid>
      </Grid>
    </Grid>}

    {startDate&&endDate&&<Grid item>
      <Grid item container justify='space-between' alignItems='center' alignContent='center' spacing={1}>
        <Grid item>
          <QueryBuilderIcon fontSize='large'/>
        </Grid>
        <Grid item>
          {!isStarted&&isFuture(parseISO(startDate))&&<>Starting in: {formatDistance(new Date(),parseISO(startDate))}</>}
          {!isStarted&&!isFuture(parseISO(startDate))&&<> Session ended </>}
          {isStarted&&<>Time Left: {isPast(parseISO(endDate))?<>&nbsp;-&nbsp;</>:formatDistance(new Date(),parseISO(endDate))}</>}
          {/*{moment(new Date()-new Date(parseISO(endDate))).add(localTimeZone,'hours').format('HH:mm')}*/}
        </Grid>
      </Grid>
    </Grid>}

  </Grid>

})

export default VideoHeader
