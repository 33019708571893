import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {
  IconCalendarBorderDark, IconLockBorderDark,
  IconStatusOkFillGreen16,
  IconViewBorderLight
} from 'components/core/icons'
import clsx from 'clsx'
import ButtonBase from '@material-ui/core/ButtonBase'
import {useHttp} from 'api/core'
import api from 'api'
import {withRouter} from 'react-router-dom'
import {STREAM_VIDEO_TYPES} from 'config'
import {useAuth} from 'auth/AuthController'
import Dialog from '@material-ui/core/Dialog'
import SessionExternal from 'components/core/Sessions/SessionExternal'
import {useSnackbar} from 'notistack'
import language from "components/language";

const styles = theme => ({
  root: {},
  label: {},
  goingToggle: {
    opacity: 0.4,
    borderRadius: '20.5px',
    backgroundColor: palette['very-light-pink'],
    color: palette['greyish-brown'],
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '14px',
    '&:hover': {
      opacity: 1,

    },
    '& #status_ok_green': {
      display: 'none'
    }
  },
  checked: {
    opacity: 1,
    '& #status_ok_green': {
      display: 'initial'
    },
    '& #status_ok_grey': {
      display: 'none'
    }
  },
  buttonBase: {
    borderRadius: '20.5px',
    overflow: 'hidden',
    marginRight: theme.spacing(1)
  },
  live: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  view: {
    padding: theme.spacing(0.5, 1.5)
  }
})

const GoingToggle = withStyles(styles)(({classes, checked = false, session,isRegistered, history, reload, ...restProps}) => {

  const [isGoing, setIsGoing] = useState(session.attending)
  const [setGoing, setData, isLoadingSet, hasErrorSet] = useHttp()
  const [delGoing, delData, isLoadingDel, hasErrorDel] = useHttp()
  const [isAuthenticated] = useAuth()
  const [showExternal, setShowExternal] = useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const goSession = (e) => {
    e.stopPropagation()

    if (!isAuthenticated) {
      enqueueSnackbar('You need to be signed in to Anymeets in order to attend this session', {variant: 'warning'})
      return
    }

    if (!isRegistered) {
      enqueueSnackbar('You need to be registered to this event in order to attend the session', {variant: 'warning'})
      return
    }

    // If external
    if ([STREAM_VIDEO_TYPES.ZOOM,STREAM_VIDEO_TYPES.GOOGLE,STREAM_VIDEO_TYPES.OTHER].includes(session.videoStreamType)&&session.live!==1) {
      setShowExternal(true)
      return
    }

    if (session.live === 2 || session.live === 3) {
      // history.push(`/session/${session.id}`)
      return
    }

    if (isLoadingSet || isLoadingDel) return
    if (isGoing) {
      setIsGoing(false)
      delGoing(api.entities.sessions.delAttending(null, null, {id: session.id}))
    } else {
      setIsGoing(true)
      setGoing(api.entities.sessions.setAttending(null, null, {id: session.id}))
    }
  }

  useEffect(() => {
    if (hasErrorSet) {
      setIsGoing(false)
    }
  }, [hasErrorSet])

  useEffect(() => {
    if (hasErrorDel) {
      setIsGoing(true)
    }
  }, [hasErrorDel])

  useEffect(() => {
    if (setData || delData) {
      if (reload) {
        reload()
      }
    }
    // eslint-disable-next-line
  }, [setData, delData])

  const handleClose = ()=> { setShowExternal(false) }

  const live = session.live

  const externalLink = ([STREAM_VIDEO_TYPES.ZOOM,STREAM_VIDEO_TYPES.GOOGLE,STREAM_VIDEO_TYPES.OTHER].includes(session.videoStreamType))

  const isAllowed = isAuthenticated&&isRegistered

  let linkToGoTo = ((session.live === 2 || session.live === 3)&&!externalLink&&isAllowed)?`/session/${session.id}`:false

  if (externalLink) {
    if (live===3) {
      return <> </>
    }
  }

  if (live === 3 && session.recordingUrl) return <> </>

  return <>
    {showExternal&&<Dialog onClose={handleClose} open={showExternal} maxWidth='md'>
      <SessionExternal session={session} />
    </Dialog>}

    <ButtonBase className={clsx(classes.buttonBase)} onClick={goSession}
                       href={linkToGoTo} disabled={session.private && !session.hasPrivateTicket} target='_blank'>

    <Box display='flex' alignContent='center' alignItems='center' justifyContent='space-between'
         className={clsx(classes.goingToggle, isGoing && classes.checked, live === 2 && classes.live)}>
      <EventButtonPerCase session={session} classes={classes}/>
    </Box>

  </ButtonBase>
  </>
})

export default withRouter(GoingToggle)

const EventButtonPerCase = ({classes, session,...restProps})=>{
  const [buttonIcon,setButtonIcon] = useState(<IconStatusOkFillGreen16/>)
  const [buttonText,setButtonText] = useState('null')
  const live = session.live

  useEffect(()=>{
    if (session)
    {
      if (session.private && !session.hasPrivateTicket)
      {
        setButtonIcon(<IconLockBorderDark/>)
        setButtonText(`${language.private}`)
      }
      else{
        switch(live){
          case 1 :
            setButtonText(`${language.going}`);
            setButtonIcon(<IconStatusOkFillGreen16/>)
            break
          case 2 :
            setButtonText(`${language.live}`);
            setButtonIcon(<IconViewBorderLight/>)
            break
          case 3 :
            setButtonText(`${language.past}`);
            setButtonIcon(<IconCalendarBorderDark/>)
            break
          default: break
        }
      }
    }
    // eslint-disable-next-line
  },[session])

  return <>
    <Box display='flex' pl={1} pr={1}>
      {buttonIcon}
    </Box>
    <Box display='flex' pr={1}>
      {buttonText}
    </Box>
    </>
}
