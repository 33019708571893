import {withStyles} from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import {IconBookmarkBorderGreen} from 'components/core/icons'
import ButtonBase from '@material-ui/core/ButtonBase'
import clsx from 'clsx'

const styles = theme => ({
  bookmarkIcon: {
    '& > svg > g > g':{
      stroke: theme.palette.secondary.main
    },
    '&:hover > svg > g': {
      fill: theme.palette.secondary.main
    }
  },
  bookmarked: {
    '& > svg > g': {
      fill: theme.palette.secondary.main
    }
  }
})
const BookmarkButton = withStyles(styles)(({classes, event, refreshList, ...rest})=>{
  const [isBookmarked, setIsBookmarked] = useState(event.bookmarked)
  const [setBookmark, setData, isLoadingSet, hasErrorSet] = useHttp()
  const [delBookmark, delData, isLoadingDel, hasErrorDel] = useHttp()

  const bookmarkEvent = () => {
    if (isLoadingSet||isLoadingDel) return
    if (isBookmarked) {
      setIsBookmarked(false)
      delBookmark(api.entities.events.delBookmark(null, null,{id: event.id}))
    } else {
      setIsBookmarked(true)
      setBookmark(api.entities.events.setBookmark(null, null,{id: event.id}))
    }
  }

  useEffect(()=>{
    if (hasErrorSet) {
      setIsBookmarked(false)
    }
  },[hasErrorSet])

  useEffect(()=>{
    if (hasErrorDel) {
      setIsBookmarked(true)
    }
  },[hasErrorDel])

  useEffect(()=>{
    if (setData||delData) {
      if (refreshList) refreshList()
    }
    // eslint-disable-next-line
  }, [setData, delData])

  return <ButtonBase {...rest} centerRipple className={clsx(classes.bookmarkIcon,isBookmarked&&classes.bookmarked)} onClick={()=>{bookmarkEvent()}}>
    <IconBookmarkBorderGreen className={classes.setBookmark} alt={'Bookmark event'}/>
  </ButtonBase>
})

export default BookmarkButton
