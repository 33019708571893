import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import HeaderTitle from 'components/core/HeaderTitle'
import Page from 'components/core/Page'

const styles = theme => ({

})

const Support = withStyles(styles)(({classes}) => {

  return <Page title='Support'> <HeaderTitle>FAQ</HeaderTitle> </Page>
})

export default Support
