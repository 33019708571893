import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {SPONSOR_TYPES, TIME_FADE_IN} from 'config'
import SponsorCardView from 'components/core/SponsorCardView'
import {useParams} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import NotFound from 'components/pages/NotFound'
import Chip from 'components/core/Chip'
import {imageFile, withHttp} from 'utils'
import { palette } from 'theme'
import language from "components/language";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const sponsorStyles = theme => ({
  logo: {
    display: 'flex',
    width: '80px',
    height: '80px',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  sponsorUrl: {
    color: palette['cerulean-blue']
  },
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

export const SponsorsList = withStyles(sponsorStyles)(({classes, event, isPreview, canManage}) => {
  const [sponsors, setSponsors] = useState()
  const [getSponsorsData, sponsorsData] = useHttp()
  const { pageId } = useParams()
  const history = useHistory();

  useEffect(() => {
    if (isPreview) {
      getSponsorsData(api.entities.preview.getSponsors(null, null, {id: event.eventInfo.id}))
    } else {
      getSponsorsData(api.entities.events.getSponsors(null, null, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sponsorsData) {
      setSponsors(sponsorsData.sponsors)
    }
  }, [sponsorsData])

  const createMarkup = (value) => {
    return {__html: value}
  }

  if (!sponsors) {
    return <> </> // Loading
  }

  if (sponsors&&sponsors.length===0) return <> </>

  if (pageId) {
    const sponsor = sponsors.find((s)=>parseInt(s.id)===parseInt(pageId))
    if (!sponsor) return <NotFound />
    return <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid item container>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/sponsors`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Event</Button>}
            </Grid> 
        <Grid item container spacing={2}>
          <Grid item>
            <Box className={classes.logo}><img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} /> </Box>
          </Grid>
          <Grid item >
            <Grid item xs={12}>
              <Box component='h1' fontWeight='bolder' fontSize={28} mb={1}>{sponsor.name}</Box>
            </Grid>
            <Grid item xs={12}>
              <Chip label={SPONSOR_TYPES[sponsor.type]}/>
            </Grid>
            <Grid item xs={12}>
              <Box p={1} pl={0} >
                <a href={withHttp(sponsor.url)} className={classes.sponsorUrl}> {language.visitUs} </a>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              {sponsor.summary ? <div dangerouslySetInnerHTML={createMarkup(sponsor.summary)} key={sponsor.summary} /> : ''}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

    </Fade>
  }

  const sponsorsPerType = {11:[], 1: [], 2: [], 3: [], 4:[], 5:[], 6:[], 7:[], 8:[], 9:[], 10:[],12:[],13:[]}
  sponsors.forEach((sponsor) => sponsorsPerType[sponsor.type].push(sponsor))

  return <Box p={4}><Grid container spacing={2} style={{marginBottom:'64px'}}>
    <Grid item container>
    <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/sponsors`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Sponsors</Button>}
            </Grid>       
      <Grid item xs={12}>
        <Box component='h1' fontWeight='bolder' fontSize={26} mb={1}>{language.sponsors}</Box>
      </Grid>
    </Grid>

    {
      [11,1,2,3,4,5,6,7,8,9,10,12,13].map((key,i)=>{
        if (sponsorsPerType[key].length===0) return <></>
        return <Grid item container spacing={2}>
          {sponsorsPerType[key].map((sponsor, y) =>
            <SponsorCardView event={event} sponsor={sponsor} p={1} plain={sponsor.type !== 1} index={i} key={i}/>
          )}
        </Grid>
      })
    }
  </Grid>
  </Box>
})
