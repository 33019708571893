import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import BlockText from 'components/core/BlockText'
import FieldText from 'components/core/FieldText'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import DescriptionIcon from '@material-ui/icons/Description'
import EllipsisText from 'react-ellipsis-text'
import ParticipantChip from 'components/core/ParticipantChip'
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
    position: "relative",
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(6),
    },
  },
  posterContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: palette["brown-grey"],
    color: "white",
    borderRadius: "6px",
    position: "relative",
    paddingRight: theme.spacing(11),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(6),
    },
  },
  PresentationCard: {
    maxWidth: "960px",
  },
  userPhoto: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  presentationBox: {
    minWidth: "135px",
    height: "60px",
    backgroundColor: palette["pale-sky-blue"],
    borderRadius: "8px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    padding: theme.spacing(1),
    color: palette["greyish-brown"],
  },
  presentationTitle: {
    color: palette["cerulean-blue"],
    fontWeight: "bold",
  },
  presentationsBox: {
    overflowX: "scroll",
  },
  editIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: "pointer",
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    },
  },
  deleteIcon: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  hiddenIcon:{
    position: "absolute",
    top: theme.spacing(6),
    right: theme.spacing(2), 
  },
  participantsList: {},
  presentationTimes: {
    fontSize: "14px",
  },
  arrowButton: {
    opacity: 0.2,
  },
  enabled: {
    cursor: "pointer",
    opacity: 0.7,
    "&:hover": {
      opacity: 1,
    },
  },
  arrowBtns: {
    position: "absolute",
    left: theme.spacing(1.5),
    top: theme.spacing(2),
  },
  participants: {
    display: "flex",
    // justifyContent: 'center',
    flexWrap: "wrap",
    marginLeft: -theme.spacing(0.5),
    paddingTop: theme.spacing(1),
    "& > *": {
      color: "white",
      margin: theme.spacing(0.5),
      borderColor: "white",
    },
  },
  avatar: {
    color: "white !important",
    background: "none",
    "&> img": {
      maxWidth: "100%",
    },
  },
  fileList: {
    margin: 0,
    color: "white",
  },
  listItem: {
    paddingLeft: 0,
    "&>div": {
      minWidth: "30px",
      color: "white",
    },
    "&>a": {
      color: "white",
    },
    "&>div>a": {
      color: "white",
    },
  },
});

const PresentationCard = withStyles(styles)(({classes, presentation,  sessions, participants, editAction, deleteAction, ...props}) => {
  return (
    <Box className={classes.PresentationCard} {...props}>
      <Box p={2} className={presentation.posterMessage === null ? classes.container : classes.posterContainer} spacing={1}>
        <Grid container spacing={1} alignItems="stretch">
          <Grid item xs={12} sm={3}>
            <BlockText title="Presentation Code" value={presentation.presentationCode} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <BlockText title="Presentation title" value={presentation.title} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <BlockText title="Type" value={presentation.type === 1 ? "Oral" : "Poster"} />
          </Grid>
          <Grid item xs={12}>
            {presentation.sessionId && <FieldText title="Session" value={sessions.find((s) => s.id === presentation.sessionId).title} />}
            {!presentation.sessionId && <FieldText title="Session" value={"-"} />}
          </Grid>

          {/*<Grid item xs={2}>*/}
          {/*  <FieldText title='Files' value={presentation.files.length}/>*/}
          {/*</Grid>*/}

          {presentation.abstract && (
            <Grid item xs={12}>
              <FieldText title="Abstract" value={<EllipsisText text={presentation.abstract} length={280} />} />
            </Grid>
          )}

          {presentation.participants && presentation.participants.length !== 0 && (
            <Grid item xs={12} sm={6}>
              <FieldText
                title="Authors"
                value={
                  <Box className={classes.participants}>
                    {presentation.participants.map((participant, i) => {
                      const participantObj = participants.find((s) => s.id === participant);
                      return (
                        <>
                          <ParticipantChip participantObj={participantObj} />
                        </>
                      );
                    })}
                  </Box>
                }
              />
            </Grid>
          )}

          {presentation.keywords && (
            <Grid item xs={12} sm={6}>
              <FieldText
                title="Keywords"
                value={presentation.keywords.split(",").map((keyword, i) => {
                  return (
                    <>
                      {keyword}
                      {i + 1 < presentation.keywords.split(",").length ? ", " : ""}
                    </>
                  );
                })}
              />
            </Grid>
          )}

          {presentation.files && presentation.files.length !== 0 && (
            <Grid item xs={12} sm={6}>
              <FieldText
                title="Files"
                value={
                  <Box className={classes.participants}>
                    {presentation.files.map((file, i) => {
                      return (
                        <>
                          <Chip
                            avatar={
                              <Avatar className={classes.avatar}>
                                {" "}
                                <DescriptionIcon />{" "}
                              </Avatar>
                            }
                            variant="outlined"
                            label={`${file.filename}`}
                            component="a"
                            href={`/files/${file.id}`}
                            clickable
                            download={`${file.filename}`}
                          />
                        </>
                      );
                    })}
                  </Box>
                }
              />
            </Grid>
          )}

          {/*{presentation.files&&presentation.files.length!==0&&<Grid item xs={12} sm={6}>*/}
          {/*  <FieldText title='Files' value={<>*/}
          {/*    /!*<List dense={true} className={classes.fileList}>*!/*/}
          {/*    <Box className={classes.fileList}>*/}
          {/*      {presentation.files.map((file,i)=> <Box component='span' className={classes.listItem}>*/}
          {/*          <ListItemIcon>*/}
          {/*            <a href={`/files/${file.id}`} download={file.filename} title={file.filename}>*/}
          {/*              <DescriptionIcon/>*/}
          {/*            </a>*/}
          {/*          </ListItemIcon>*/}
          {/*        <a href={`/files/${file.id}`} download={file.filename} title={file.filename}>*/}
          {/*          <ListItemText*/}
          {/*            primary={file.filename}*/}
          {/*          />*/}
          {/*        </a>*/}
          {/*        </Box>*/}
          {/*      )}*/}
          {/*    </Box>*/}
          {/*    /!*</List>*!/*/}
          {/*  </>*/}
          {/*  } />*/}
          {/*</Grid>}*/}

          <IconNotesBorderLight
            className={classes.editIcon}
            title="Edit presentation"
            onClick={() => {
              editAction && editAction(presentation.id);
            }}
          />
          <IconXBorderLight
            className={classes.deleteIcon}
            title="Delete presentation"
            onClick={() => {
              deleteAction && deleteAction(presentation.id);
            }}
          />
          {presentation.posterMessage!=null&&<VisibilityOffOutlinedIcon className={classes.hiddenIcon} title="Hidden presentation" />}
        </Grid>
      </Box>
    </Box>
  );
})

export default PresentationCard
