import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Chip from 'components/core/Chip'
import {format} from 'date-fns'
import {
  IconCalendarBorderDark,
  IconMapPinBorderGray,
 // IconTagBorderGray
} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import clsx from 'clsx'
import {CATEGORIES,/* CURRENCY,*/ DOMAIN} from 'config'
import {Link, withRouter} from 'react-router-dom'
import Button from 'components/core/Button'
import {imageFile} from 'utils'
import parseISO from 'date-fns/parseISO'
import Grid from '@material-ui/core/Grid'
import BookmarkButton from 'components/core/BookmarkButton'
import ShareButton from 'components/core/ShareButton'
import EllipsisText from 'react-ellipsis-text'

const styles = theme => ({
  card: {
    maxWidth: 324,
    backgroundColor: 'white',
    border: '2px solid rgba(0,0,0,0)',
    borderRadius: '6px',
    '&:hover': {
      border: `2px solid ${palette.aquamarine}`
    },
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'pointer'
  },
  cardImage: {
    borderRadius: '5px',
    marginBottom: theme.spacing(1),
    backgroundColor: palette.imageBackgroundColor
  },
  cardContent: {
    padding: theme.spacing(2),
    maxWidth: 324,
    minWidth: '290px',
    [theme.breakpoints.down('xs')]: {
      minWidth: `calc(100vw - ${theme.spacing(9)}px)`
    },
    backgroundColor: 'white',
    border: '2px solid rgba(0,0,0,0)',
    borderRadius: '6px',
    '&:hover': {
      border: `2px solid ${palette.aquamarine}`
    },
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    position: 'relative'
  },
  title: {
    color: palette['greyish-brown'],
    fontWeight: 'bold',
    position: 'relative',
    width: '100%',
    paddingRight: '48px',
    '& a': {
      textDecoration: 'none',
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    }
  },
  listRow: {
    marginBottom: theme.spacing(1.5)
  },
  bookmarkIcon: {
    position: 'absolute',
    right: 0,
    top: 0
  },
  tags: {
    marginBottom: theme.spacing(1.5)
  },
  promotedLabel: {
    backgroundColor: palette.aquamarine,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: 'white',
    borderRadius: '0 0 5px 5px',
    fontWeight: 'bold'
  },
  hidden: {
    visibility: 'hidden'
  },
  shown: {
    visibility: 'visible'
  },
  manageBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 0
  },
  upgradeBtn: {

  },
  shareIcon: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(4)
  },
  linkToEvent: {
    position :' absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }
})

const ListItem = withStyles(styles)(({classes, icon, text}) => {
  return <Grid item container alignItems={'center'} spacing={1}>
    <Grid item xs={1}>{icon}</Grid>
    <Grid item xs={11}><Box p={1} pl={0.5}>{text}</Box></Grid>
  </Grid>
})

const PromotedLabel = withStyles(styles)(({classes, promoted = false}) => {
  const promotedStyle = clsx({
    [classes.promotedLabel]: true,
    [classes.hidden]: !promoted,
    [classes.shown]: promoted
  })

  return <>
    <Box className={promotedStyle}> PROMOTED </Box>
  </>
})

const ConferenceCardBox = withStyles(styles)(({classes, staticContext, promoted = false, manageEvents = false, event, history, match, location, ...restProps}) => {

  //const ticketPrice = (price) => price===0?'Free':`From ${CURRENCY}${price}`

  const canManage = event.role===1||event.role===2
  const linkTo = manageEvents?`/manage-event/${event.id}/general`:`/event/${event.id}`

  return <>{event && <><Box display='flex' className={classes.cardContent} {...restProps}  flexDirection='column' justifyContent='space-between'>
    <Link to={linkTo} className={classes.linkToEvent}> </Link>
    <Box display='flex' width={'100%'} flexDirection='column'>
      <Box display='flex' >
        <CardMedia
          component='img'
          alt={event.name}
          height='180'
          image={imageFile(event.coverId)}
          title={event.name}
          className={classes.cardImage}
        /></Box>
      <Box display='flex'>
        <Typography variant='h6' component='h2' className={classes.title}>
          <Link to={linkTo}><EllipsisText text={event.name} length={60} /></Link>
          <Box className={classes.shareIcon}>
            <ShareButton shareLink={`https://${DOMAIN}/event/${event.id}`} />
          </Box>
          <Box className={classes.bookmarkIcon}>
            <BookmarkButton event={event} />
          </Box>
        </Typography>
      </Box>
    </Box>

    <Box display='flex' width={'100%'} flexDirection='column' pt={2}>
      <Box className={classes.tags}><Chip label={CATEGORIES[event.category]}/></Box>
      <Grid container alignItems='center' justify='flex-start' spacing={1}>
        <ListItem icon={<IconCalendarBorderDark/>} text={format(parseISO(event.startDate), 'LLL do | hh:mmaa')}/>
        <ListItem icon={<IconMapPinBorderGray/>} text={event.venueName}/>
        {/*<ListItem icon={<IconTagBorderGray />} text={ticketPrice(event.ticketsFrom)} />*/}
      </Grid>
      <PromotedLabel promoted={promoted}/>
      {canManage&&<Box className={classes.manageBtn} p={2}>
        <Box display='flex'>
          {/* {event.upgraded!==null&&event.upgraded===false&&<Button size='smaller' variant='outlined' component={Link} to={`/pricing`}>Upgrade Tier</Button>} */}
        </Box>
        <Box display='flex'>
          <Button size='smaller' variant='outlined' component={Link} to={`/manage-event/${event.id}/general`}> Manage</Button>
        </Box>
      </Box>}
    </Box>

  </Box>
  </>}</>
})

export default withRouter(ConferenceCardBox)
