import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import {Field, Form, Formik} from 'formik'
import Box from '@material-ui/core/Box'
import {Typography} from '@material-ui/core'
import {palette, themesEvent} from 'theme'
import Palettes from 'components/pages/ManageEvent/Palettes'
// import OptionCard from 'Components/core/OptionCard'
// import {IconFileBorderDark, IconSidebarBorderDark} from 'Components/core/icons'
import UploadImage from 'components/core/UploadImage'
import {FILES_URL, SIZE_COVER, SIZE_LOGO} from 'config'
import Button from 'components/core/Button'
import Fade from '@material-ui/core/Fade'
import {useSnackbar} from 'notistack'
import Grid from '@material-ui/core/Grid'
import PalettePreview from 'components/pages/ManageEvent/PalettePreview'
import {Switch} from 'formik-material-ui'
import { CompactPicker } from 'react-color'
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  form: {
    color: palette["greyish-brown"],
  },
  dropBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    color: palette["greyish-brown"],
    overflow: "hidden",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  note: {
    fontSize: "12px",
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-15px",
    "& Button": {
      minWidth: "170px",
    },
  },
  title: {
    fontWeight: "bolder",
  },
  subtitle: {
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.1rem",
    marginLeft: "10px",
  },
  ptitle: {
    fontSize: "0.8rem",
    marginLeft: "10px",
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important",
    },
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },    
});

const Layout = withStyles(styles)(({classes, setPageTitle, setEventInfo, eventInfo, setNavBack, history, flow}) => {
  const [formData, setFormData] = useState()
  const [formSave, setFormSave] = useState()
  const [putRequest, putData, , hasErrorRequest] = useHttp()
  const [getDataPublish, dataPublish] = useHttp()
  const [getData, data] = useHttp()
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  const [templateBackgrounds, setTemplateBackgrounds] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [getTemplateBackgrounds, backgroundsData] = useHttp()
  const [templateLogos, setTemplateLogos] = useState()
  const [getTemplateLogos, logosData] = useHttp()

  useEffect(() => {
    getTemplateBackgrounds(api.entities.options.getBackgrounds())
  }, [getTemplateBackgrounds])

  useEffect(() => {
    if (backgroundsData) {
      setTemplateBackgrounds(backgroundsData.content)
    }
  }, [backgroundsData])

  useEffect(() => {
    getTemplateLogos(api.entities.options.getLogos())
  }, [getTemplateLogos])

  useEffect(() => {
    if (logosData) {
      setTemplateLogos(logosData.content)
    }
  }, [logosData])

  useEffect(() => {
    getData(api.entities.manage.getLayout(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    if (hasErrorRequest) {
      setIsSubmitting(false)
    }
  },[hasErrorRequest])

  useEffect(() => {
    if (data) {
      const logoId = data.logoId ? `${FILES_URL}/${data.logoId}` : null
      const coverId = data.coverId ? `${FILES_URL}/${data.coverId}` : null
      setFormData({...data, coverId, logoId, titleColor:data.titleColor?data.titleColor:'#000000'})
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({path: '/manage-events', name: 'events'})
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  useEffect(() => {
    if (putData) {
      setIsSubmitting(false)
      getData(api.entities.manage.getLayout(null, null, {id: eventId}))
      enqueueSnackbar('Successfully saved event', {variant: 'success'})
    }
    //eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {
      setIsSubmitting(true)
      let formDataObj = new FormData();

      const {logo, cover, ...formDataItems} = formSave

      if (cover) {
        formDataObj.append('cover', cover)
      }

      if (logo) {
        formDataObj.append('logo', logo)
      }

      const blob = new Blob([JSON.stringify(formDataItems)], {type: 'application/json'});
      formDataObj.append('page', blob)

      putRequest(api.entities.manage.putLayout(formDataObj, null, {id: eventId}, true))

      setFormSave(undefined)
    }
  }, [formSave, putRequest, eventId])


  const ViewEvent = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={() => {
    history.push(`/event/${eventId}`)
  }}> View Event </Button>, [eventId, history])

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}))
  }

  useEffect(() => {
    if (dataPublish) {
      if (dataPublish.status && dataPublish.status === 201) {
        setFormData(f => ({...f, eventInfo: {...f.eventInfo, dirty: false}}))
        setEventInfo(f => ({...f, dirty: false}))
        enqueueSnackbar('Your event was successfully published!', {variant: 'success', action: ViewEvent})
      } else {
        enqueueSnackbar('Unexpected Error', {variant: 'error'})
      }
    }
    // eslint-disable-next-line
  }, [dataPublish])

  if (!formData || !backgroundsData) return <> </>

  return <Box name={formData.name} navBack={{path: '/manage-events', name: 'events'}}>
    <Formik
      enableReinitialize
      initialValues={formData}
      // validationSchema={InformationSchema}
      onSubmit={(values, {setSubmitting,resetForm}) => {
        const newFormData = {
          layout: values.layout,
          theme: values.theme,
          publish: false,
          titleColor: values.titleColor,
          showTitle: values.showTitle
        }

        if (formData.logo) {
          newFormData.logo = formData.logo
        }

        if (formData.cover) {
          newFormData.cover = formData.cover
        }

        if (formData.deleteCover) {
          newFormData.deleteCover = true
        }

        if (formData.deleteLogo) {
          newFormData.deleteLogo = true
        }

        setFormSave(newFormData)
        resetForm();
      }}>
      {({dirty, errors, submitForm, setFieldValue, values}) => (
        <Fade in={true} timeout={1200}><Form className={classes.form}>

          <Box mt={4} maxWidth={1180}>

            <Grid container spacing={2} className={classes.section}>

              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.title}>Layout
                <Tooltip classes={{tooltip: classes.tooltip}} title="Select the layout of event's public page." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                  </Tooltip>                
                </Typography>
              </Grid>

              <Grid item xs={12} md={10}>
                <Box mt={3} mb={2} maxWidth={880}>
                  <Grid container>
                    <ThemeProvider theme={themesEvent[`theme${values.theme}`]}>
                    <Grid item xs={12} sm={3}>
                      <PalettePreview layout={1} selected={values.layout === 1} themeSelected={values.theme} onClick={() => {
                        setFieldValue('layout', 1)
                      }}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <PalettePreview layout={2} selected={values.layout === 2} themeSelected={values.theme} onClick={() => {
                        setFieldValue('layout', 2)
                      }}/>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <PalettePreview layout={3} selected={values.layout === 3} themeSelected={values.theme} onClick={() => {
                        setFieldValue('layout', 3)
                      }}/>
                    </Grid>
                      <Grid item xs={12} sm={3}>
                        <PalettePreview layout={4} selected={values.layout === 4} themeSelected={values.theme} onClick={() => {
                          setFieldValue('layout', 4)
                        }}/>
                      </Grid>
                    </ThemeProvider>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.title}>Theme</Typography>
                <Box className={classes.note} pt={1}>Choose the right theme based on your needs </Box>
              </Grid>
              <Grid item xs={12} md={10}>
                <Palettes selectedTheme={values.theme} changeTheme={setFieldValue}/>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.title}>Logo Image</Typography>
                <Box className={classes.note} pt={1}>Image should be at
                  least {SIZE_LOGO.width}x{SIZE_LOGO.height}px</Box>
              </Grid>

              <Grid item xs={12} md={10}>
                <UploadImage images={templateLogos} setFormData={setFormData} formData={formData} name={'Logo'}
                             size={SIZE_LOGO} fieldName={'logo'} existingFieldName={'logoId'} placeholderSizer={1}
                             deleteKey={'deleteLogo'}/>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.title}>Cover Image
                <Tooltip classes={{tooltip: classes.tooltip}} title="This is the background image on the top header of event's page." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                      </Tooltip>                
                </Typography>
                <Box className={classes.note} pt={1}>Image should be at
                  least {SIZE_COVER.width}x{SIZE_COVER.height}px</Box>
              </Grid>

              <Grid item xs={12} md={10}>
                <UploadImage images={templateBackgrounds} setFormData={setFormData} formData={formData}
                             name={'Cover Image'}
                             size={SIZE_COVER} fieldName={'cover'} existingFieldName={'coverId'} placeholderSizer={4}
                             deleteKey={'deleteCover'}/>
              </Grid>

              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.title}>Header</Typography>
              </Grid>

              <Grid item xs={12} md={10}>
              </Grid>
              <Grid item xs={12} md={12}> </Grid>
              <Grid item xs={12} md={2} mt={2}>
                <Typography component='h2' variant='h6' className={classes.subtitle}>Visibility</Typography>
                <Box className={classes.note} pt={1}> Hide/show the event title </Box>
              </Grid>

              <Grid item xs={12} md={10}>
                  <Field type='checkbox' name='showTitle' component={Switch} disabled={isSubmitting}/>
                  Show title in event page cover
              </Grid>
              <Grid item xs={12} md={12}> </Grid>
              <Grid item xs={12} md={2}>
                <Typography component='h2' variant='h6' className={classes.subtitle}>Title color</Typography>
                <Box className={classes.note} pt={1}>Set the color of text in the event header</Box>
              </Grid>

              <Grid item xs={12} md={10}>
                  <CompactPicker
                    color={ values.titleColor }
                    onChangeComplete={ (v)=>setFieldValue('titleColor', v.hex) }
                  />
              </Grid>
              <Grid item xs={12} md={12}> </Grid>
              <Grid item sm={2}> </Grid> 
              {(dirty || eventInfo.dirty) && (
                <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons} style={{ position: flow ? "absolute" : null, top: flow ? "0" : null }}>
                  {eventInfo && eventInfo.dirty ? (
                    <Alert className={classes.topPublish} severity="warning">
                      <AlertTitle>Unpublished changes</AlertTitle>
                      You are viewing an event with unpublished changes.
                    </Alert>
                  ) : (
                    <Alert className={classes.topPublish} severity="warning">
                      <AlertTitle>Save changes</AlertTitle>
                      Remember to save your changes before you leave this page.
                    </Alert>
                  )}
                  {dirty && <Box display="inline-block" mt={1} mb={1}>
                    <Button type="submit" variant="outlined" color="primary">
                      Save Changes
                    </Button>
                  </Box>}
                  &nbsp;&nbsp;&nbsp;
                  <Box display="inline-block" mt={1} mb={1}>
                    {eventInfo.dirty ? (
                      <Button
                        onClick={() =>
                        {
                          publishEvent();
                        }}>
                        Publish
                      </Button>
                    ) : (
                      ""
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>

        </Form></Fade>
      )}
    </Formik>
  </Box>
})

export default withRouter(Layout)
