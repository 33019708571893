import React, {useState} from 'react'
import {Box, Grid} from '@material-ui/core'
import {withStyles} from '@material-ui/core'

import {imageFile} from 'utils'
import {palette} from 'theme'
import Typography from '@material-ui/core/Typography'
// import {Link} from 'react-router-dom'
import EllipsisText from 'react-ellipsis-text'
import {format} from "date-fns"
import parseISO from "date-fns/parseISO"
import {CURRENCY} from 'config'
// import {breakpoints} from 'npm/docs/src/theme'
import CropFreeIcon from '@material-ui/icons/CropFree'
import QRDialog from 'components/pages/MyTickets/QRDialog'

const styles = theme => ({
  ticketBox: {
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    padding: theme.spacing(2)
  },
  cardImage: {
    width: '180px',
    height: '101px',
    borderRadius: '6px',
    backgroundColor: palette.imageBackgroundColor,
    [theme.breakpoints.down('sm')] :{
      width: '140px',
      height: '79px',
    },
    [theme.breakpoints.down(1440)] :{
      width: '160px',
      height: '90px',
    }
  },
  title: {
    position: 'relative',
    fontWeight: 'bold',
    paddingRight: theme.spacing(4),
    minHeight: '45px',
    marginTop: theme.spacing(0),
    '& a': {
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    },
    '& svg': {
      fontSize: '4.5rem',
      cursor: 'pointer'
    }
  },
  qr: {
    position: 'relative',
    fontWeight: 'bold',
    minHeight: '45px',
    marginTop: 10,
    textAlign: 'center',
    fontSize: '16px',
    '& a': {
      color: palette['greyish-brown'],
      width: '100%',
      display: 'block'
    },
    '& svg': {
      fontSize: '4.5rem',
      cursor: 'pointer'
    }
  },
  deEmphasized: {
    color: palette['greyish-brown'],
    fontWeight: 'bold'
  }
})

const MyTicket = withStyles(styles)(({classes, ticketInfo}) => {
  const [showQR, setShowQR] = useState(false)

  const ticketPrice = (price) => price===0?'Free':`From ${CURRENCY}${price}`
  const handleClose = () => {
    setShowQR(false)
  }
  return <Box className={classes.ticketBox} mb={2}>
    {showQR&&<QRDialog qr={ticketInfo.qrCode} info={ticketInfo.eventName} open={showQR} handleClose={handleClose}/>}
    <Grid container
          direction='row'
          justify='flex-start'
          alignItems='flex-start'
          spacing={2}>
      <Grid item sm={4} md={3} lg={4}>
        <Box>
          <img src={imageFile(ticketInfo.logoId)} alt={ticketInfo.eventName} title={ticketInfo.eventName}
               className={classes.cardImage}/>
        </Box>
      </Grid>
        <Grid item sm={4} md={7} lg={6}>
          <Box variant='h6' component='h2' className={classes.title}>
            <EllipsisText text={ticketInfo.eventName} length={25} />
          </Box>
          <Box>
            <Grid container alignItems={'flex-end'} justify={'flex-start'}>
              <Grid item>
                <Typography variant='body2' >{format(parseISO(ticketInfo.startDate), 'LLL do | hh:mmaa')}</Typography>
                <Typography variant='body2' className={classes.deEmphasized}>{ticketInfo.venueName}</Typography>
                <Typography variant='body2' className={classes.deEmphasized}>{ticketPrice(ticketInfo.price)}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      <Grid item sm={4} md={2} lg={2}>
        <Box variant='h6' component='h2' className={classes.qr}>
          <CropFreeIcon onClick={()=>setShowQR(true)}/> <br/>
          QR Code
        </Box>
      </Grid>
    </Grid>
  </Box>
})

export default MyTicket
