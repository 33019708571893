import React from 'react'
import ConferenceCardBox from 'components/core/ConferenceCardBox'
import ConferenceCardList from 'components/core/ConferenceCardList'

const ConferenceCard = ({list, ...props}) => {
  if (list) return <ConferenceCardList {...props} />
  return <ConferenceCardBox {...props} />
}

export default ConferenceCard
