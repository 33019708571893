import Grid from '@material-ui/core/Grid'
import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {useHttp} from 'api/core'
import api from 'api'
import TextField from '@material-ui/core/TextField'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
  label: {
    fontSize: 12,
    marginRight: theme.spacing(1)
  }
})

const StyledTabs = withStyles({
  root: {
    minHeight: 40,
    fontWeight: 600
  },
  indicator: {
    display: 'none',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabsSmall = withStyles({
  root: {
    minHeight: 30,
    fontWeight: 600
  },
  indicator: {
    display: 'none',
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTabSmall = withStyles((theme) => ({
  root: {
    minWidth: 'auto',
    minHeight: 30,
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#0077af',
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    // background: '#cdcdcd',
    opacity: 1,
  },
  selected: {
    // borderBottomWidth: 0,
    backgroundColor: 'rgba(0, 119, 175, 0.1)',
    color: '#0077af',
    fontWeight: 600,
    '& $wrapper': {
      opacity: 1,
    },
  },
  wrapper: {
    opacity: 0.7,
    textTransform: 'none'
  },
}))((props) => <Tab disableRipple {...props} />);


const StyledTab = withStyles((theme) => ({
  root: {
    minWidth: 'auto',
    padding: 0,
    margin: 0,
    border: `1px solid ${theme.palette.primary.main}`,
    color: '#0077af',
    fontWeight: 200,
    '&:not(:first-of-type)': {
      marginLeft: -1,
    },
    '&:first-of-type': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4
    },
    '&:last-of-type': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4
    },
    // background: '#cdcdcd',
    opacity: 1,
  },
  selected: {
    // borderBottomWidth: 0,
    backgroundColor: 'rgba(0, 119, 175, 0.1)',
    fontWeight: 600,
    color: '#0077af',
    '& $wrapper': {
      opacity: 1,
    },
  },
  wrapper: {
    opacity: 0.7,
    textTransform: 'none'
  },
}))((props) => <Tab disableRipple {...props} />);


const LayoutControl = withStyles(styles)(({classes, session, sessionState}) => {

  const {
    cancelHeartbeat,
    layoutMode,
    autoMode,
    roomText,
    showRooms,
    roomId
  } = sessionState

  const [layout, setLayout] = useState(layoutMode)
  const [listRooms, setListRooms] = useState(showRooms)
  const [bannerText, setBannerText] = useState(roomText)
  const [putLayout, putData, isLoadingPut, hasErrorPut] = useHttp()
  const [setAuto, , isLoadingSetAuto, hasErrorSetAuto] = useHttp()
  const [delAuto, , isLoadingDelAuto, hasErrorDelAuto] = useHttp()
  const [sessionAuto, setSessionAuto] = useState(autoMode)

  const toggleAuto = (event,value) => {
    if (value) {
      setSessionAuto(true)
      setAuto(api.entities.virtualRoom.setAuto(null,null,{id:roomId}))
    } else {
      setSessionAuto(false)
      delAuto(api.entities.virtualRoom.delAuto(null,null,{id:roomId}))
    }
  }

  useEffect(()=>{
    if (hasErrorSetAuto) {
      setSessionAuto(false)
    }
  },[hasErrorSetAuto])

  useEffect(()=>{
    if (hasErrorDelAuto) {
      setSessionAuto(true)
    }
  },[hasErrorDelAuto])

  const saveLayout = () => {
    const payLoad = {
      mode: layout,
      bannerText,
      showRooms: listRooms
    }
    cancelHeartbeat()
    putLayout(api.entities.virtualRoom.setLayout(payLoad,null,{id:roomId}))
  }

  const handleChangeListRooms = (event) => {
    const value = event.target.checked
    setListRooms(value)
  }

  useEffect(()=>{
    if (hasErrorPut) {
      cancelHeartbeat()
      setLayout(layoutMode)
      setBannerText(roomText)
      setListRooms(showRooms)
    }
    //eslint-disable-next-line
  },[hasErrorPut])

  useEffect(()=>{
    if (putData) {
      setSessionAuto(false)
    }
  },[putData])

  useEffect(()=>{
    if (layout!==1&&layout!==layoutMode) {
      saveLayout()
    }
    //eslint-disable-next-line
  },[layout])

  return <Grid item container spacing={1}>

    <Grid item container justify='space-between' alignItems='center' alignContent='center'>
      <Grid item>
        <Typography variant='h3'>Session content</Typography>
      </Grid>
      <Grid item style={{minWidth:130}}>
        <StyledTabsSmall
          variant='fullWidth'
          value={sessionAuto}
          onChange={toggleAuto}
          scrollButtons='auto'
          disabled={isLoadingSetAuto||isLoadingDelAuto||(sessionAuto!==autoMode)}
        >
          <StyledTabSmall label='Auto' value={true}/>
          <StyledTabSmall label='Manual' value={false}/>
        </StyledTabsSmall>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <StyledTabs
        variant='fullWidth'
        value={layout}
        onChange={(e,newValue)=>setLayout(newValue)}
        scrollButtons='auto'

      >
        <StyledTab disabled={sessionAuto} label={ 'Banner' } value={1}/>
        <StyledTab disabled={sessionAuto} label={ 'Discussion' } value={2}/>
        <StyledTab disabled={sessionAuto} label={ 'Presentation' } value={3}/>
      </StyledTabs>
    </Grid>

    {layout===1&&<Grid item xs={12}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField variant='filled' fullWidth label='Banner text' value={bannerText} onChange={(e)=>setBannerText(e.target.value)} />
        </Grid>
        <Grid item xs={12}>
          <Grid item container justify='flex-end' alignItems='center' spacing={2}>
            <Grid item>
              <FormControlLabel
                style={{margin:0}}
                classes={{label:classes.label}}
                control={
                  <Switch
                    style={{margin:0}}
                    checked={listRooms}
                    onChange={handleChangeListRooms}
                    color='secondary'
                    size='small'
                  />
                }
                label='List breakout rooms'
                labelPlacement='start'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify='flex-end' alignItems='center' alignContent='center'>
          <Grid item>
            <Button size='small' variant='contained' color='secondary' onClick={()=>saveLayout()} disabled={isLoadingDelAuto||isLoadingSetAuto||isLoadingPut||(layoutMode===layout&&layout!==1)}> Apply layout</Button>
          </Grid>
        </Grid>
      </Grid>

    </Grid>}

  </Grid>
})

export default LayoutControl