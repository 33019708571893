import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import Hero from 'components/core/Hero'
import HomeSplashScreen from 'assets/home.png'

const styles = theme => ({
  root: {
    width: theme.spacing(92),
    fontSize: '1rem',
    fontFamily: "'Roboto','Karla', sans-serif;",
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
    fontWeight: 400,
    color: palette['greyish-brown'],
    '& a': {
      color: palette['cerulean-blue']
    }
  }
})

const Terms = withStyles(styles)(({classes}) => {

  return <Page title='Terms of Service'>
    <Hero tagLine='Terms of Service' image={HomeSplashScreen} minHeight={300}/>
    <Box display='flex' justifyContent='center' mt={4} p={2}>
      <Box className={classes.root}>
        <p className="p1"><span className="s1">1. Terms</span></p>
        <p className="p2"><span className="s1">By accessing the website at <a
          href="http://www.anymeets.com/">https://www.anymeets.com</a>, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</span>
        </p>
        <p className="p3"><span className="s1">You must be at least 18 years of age to use this website. By using this website and by agreeing to these terms and conditions you warrant and represent that you are at least 18 years of age.</span>
        </p>
        <p className="p4"><span className="s1">This website uses cookies. By using this website and agreeing to these terms and conditions, you consent to our Company&#39;s use of cookies in accordance with the terms of Company&#39;s<a
          href="https://www.crowdcast.io/privacy-policy"><span
          className="s2">&nbsp;privacy policy</span></a></span><span className="s3">.</span></p>
        <p className="p5"><br/></p>
        <p className="p6"><span className="s1">2. Use License</span></p>
        <ol className="ol1">
          <li className="li7"><span className="s4">Permission is granted to temporarily download one copy of the materials (information or software) on Datawise Data Engineering LLC&#39;s website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</span>
            <ol className="ol1">
              <li className="li7"><span className="s1">modify or copy the materials;</span></li>
              <li className="li7"><span className="s1">use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</span>
              </li>
              <li className="li7"><span className="s1">attempt to decompile or reverse engineer any software contained on Datawise Data Engineering LLC&#39;s website;</span>
              </li>
              <li className="li7"><span className="s1">remove any copyright or other proprietary notations from the materials; or</span>
              </li>
              <li className="li7"><span
                className="s1">transfer the materials to another person or &quot;mirror&quot; the materials on any other server.</span>
              </li>
            </ol>
          </li>
          <li className="li8"><span className="s4">This license shall automatically terminate if you violate any of these restrictions and may be terminated by Datawise Data Engineering LLC at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</span>
          </li>
        </ol>
        <p className="p3"><span className="s1">Acceptable use</span></p>
        <p className="p3"><span className="s1">You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.</span>
        </p>
        <p className="p3"><span className="s1">You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.</span>
        </p>
        <p className="p3"><span className="s1">You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without Datawise Data Engineering LLC&#39;s express written consent.</span>
        </p>
        <p className="p3"><span className="s1">You must not use this website to transmit or send unsolicited commercial communications.</span>
        </p>
        <p className="p9"><span className="s1">You must not use this website for any purposes related to marketing without Datawise Data Engineering LLC&#39;s express written consent.</span>
        </p>
        <p className="p3"><span className="s1">User content</span></p>
        <p className="p3"><span className="s1">In these terms and conditions, &ldquo;your user content&rdquo; means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.</span>
        </p>
        <p className="p3"><span className="s1">Your user content must not be illegal or unlawful, must not infringe any third party&#39;s legal rights, and must not be capable of giving rise to legal action whether against you or Data Engineering LLC or a third party (in each case under any applicable law).</span>
        </p>
        <p className="p3"><span className="s1">You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.</span>
        </p>
        <p className="p3"><span className="s1">Data Engineering LLC reserves the right to edit or remove any material submitted to this website, or stored on Data Engineering LLC&#39;s servers, or hosted or published upon this website.</span>
        </p>
        <p className="p10"><br/></p>
        <p className="p3"><span className="s1">No warranties</span></p>
        <p className="p3"><span className="s1">This website is provided &ldquo;as is&rdquo; without any representations or warranties, express or implied. Data Engineering LLC makes no representations or warranties in relation to this website or the information and materials provided on this website.</span>
        </p>
        <p className="p3"><span className="s1">Without prejudice to the generality of the foregoing paragraph, Data Engineering LLC does not warrant that:</span>
        </p>
        <ul className="ul1">
          <li className="li7"><span
            className="s4">this website will be constantly available, or available at all; or</span></li>
          <li className="li7"><span className="s4">the information on this website is complete, true, accurate or non-misleading.</span>
          </li>
          <li className="li11"><span className="s4">Data Engineering LLC is not responsible for loss or damages of your event organization in case of website failure or outage. An alternative/backup option should always be provided by the event organizers.</span>
          </li>
        </ul>
        <p className="p12"><span className="s1">Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.</span>
        </p>
        <p className="p1"><span className="s1">3. Disclaimer</span></p>
        <ol className="ol1">
          <li className="li7"><span className="s4">The materials on Datawise Data Engineering LLC&#39;s website are provided on an &#39;as is&#39; basis. Datawise Data Engineering LLC makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</span>
          </li>
          <li className="li13"><span className="s4">Further, Datawise Data Engineering LLC does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</span>
          </li>
        </ol>
        <p className="p6"><span className="s1">4. Limitations</span></p>
        <p className="p9"><span className="s1">In no event shall Datawise Data Engineering LLC or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Datawise Data Engineering LLC&#39;s website, even if Datawise Data Engineering LLC or a Datawise Data Engineering LLC authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</span>
        </p>
        <p className="p3"><span className="s1">Indemnity</span></p>
        <p className="p1"><span className="s1">You hereby indemnify<span
          className="Apple-converted-space">&nbsp;&nbsp;</span>Datawise Data Engineering LLC and undertake to keep<span
          className="Apple-converted-space">&nbsp;&nbsp;</span>Datawise Data Engineering LLC indemnified against any losses, damages, costs, liabilities and expenses (including without limitation legal expenses and any amounts paid by Datawise Data Engineering LLC to a third party in settlement of a claim or dispute on the advice of<span
          className="Apple-converted-space">&nbsp;&nbsp;</span>Datawise Data Engineering LLC&#39;s legal advisers) incurred or suffered by<span
          className="Apple-converted-space">&nbsp;&nbsp;</span>Datawise Data Engineering LLC arising out of any breach by you of any provision of these terms and conditions[, or arising out of any claim that you have breached any provision of these terms and conditions.</span>
        </p>
        <p className="p5"><br/></p>
        <p className="p6"><span className="s1">5. Accuracy of materials</span></p>
        <p className="p12"><span className="s1">The materials appearing on Datawise Data Engineering LLC&#39;s website could include technical, typographical, or photographic errors. Datawise Data Engineering LLC does not warrant that any of the materials on its website are accurate, complete or current. Datawise Data Engineering LLC may make changes to the materials contained on its website at any time without notice. However Datawise Data Engineering LLC does not make any commitment to update the materials.</span>
        </p>
        <p className="p6"><span className="s1">6. Links</span></p>
        <p className="p12"><span className="s1">Datawise Data Engineering LLC has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Datawise Data Engineering LLC of the site. Use of any such linked website is at the user&#39;s own risk.</span>
        </p>
        <p className="p6"><span className="s1">7. Modifications</span></p>
        <p className="p12"><span className="s1">Datawise Data Engineering LLC may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</span>
        </p>
        <p className="p6"><span className="s1">8. Governing Law</span></p>
        <p className="p2"><span className="s1">These terms and conditions are governed by and construed in accordance with the laws of Georgia and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</span>
        </p>
      </Box>
    </Box>
  </Page>
})

export default Terms
