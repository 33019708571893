import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import React from 'react';
//import { IconDollarSignBorderLight, IconTicketBorderLight,} from 'components/core/icons';
//import {format} from 'date-fns';
import {CURRENCY} from 'config';
import {palette} from 'theme';
import parseISO from 'date-fns/parseISO';
import isFuture from 'date-fns/isFuture';
import isPast from 'date-fns/isPast';
import Button from 'components/core/Button';
import {Link, useParams} from 'react-router-dom';
import { checkHost } from 'utils'
import language from "components/language";

const styles = (theme) => ({
    info: {
        backgroundColor: theme.palette.background.primary,
        color: theme.dark?'white':theme.palette.text.primary,
		fontWeight: 'bold',
		textAlign: "center",
        borderRadius: '12px',
        zIndex: 999
    },
});

const ListItem = withStyles(styles)(({classes, icon, text}) => {
    return (
        <Box
            display="flex"
            flexDirection="row"
            flexBasis={'100%'}
            width="100%"
            mb={2}
            alignItems="center"
            justifyContent="flex-start"
            className={classes.listRow}
        >
            <Box display="flex">{icon}&nbsp;&nbsp;</Box>
            <Box display="flex">{text}</Box>
        </Box>
    );
});

const ticketPrice = (price) =>
    price === 0 ? 'Free' : `From ${CURRENCY}${price}`;

const RegistrationInfo = withStyles(styles)(
    ({title, value, event, classes}) => {
      const registrationsOpenAt = parseISO(event.eventInfo.registrationsOpenAt)
      const registrationsCloseAt = parseISO(event.eventInfo.registrationsCloseAt)
      const registrationsClosed = isPast(registrationsCloseAt)
      const registrationsWillOpen = isFuture(registrationsOpenAt)
      const registrationsOpen = isPast(registrationsOpenAt) && isFuture(registrationsCloseAt)

      const {slug} = useParams()
      const host = checkHost()

      let baseURL = `/event/${event.eventInfo.id}`

      if (slug) {
        baseURL = `/${slug}`
      } else if (host) {
        baseURL = ``
      }

    if (registrationsClosed && event.registration !== 1) return (
			<>
				{/* <Box
					display="flex"
					className={classes.info}
					p={palette.infoPadding}
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					m={palette.infoPadding}
				>
					{event.registrationInfoDesc}
					<br />
					{language.cost}: {event.registrationInfoCost}
				</Box> */}
				<Box
					display="flex"
					className={classes.info}
					p={palette.infoPadding}
					flexDirection="column"
					justifyContent="center"
					alignItems="center"
					m={palette.infoPadding}
				>
					{language.registrationsClosed}
				</Box>
			</>
		);
      return (
				<>
					{event.registration !== 1 && <Box
						display="flex"
						className={classes.info}
						p={palette.infoPadding}
						flexDirection="column"
						justifyContent="left"
						alignItems="left"
						m={palette.infoPadding}
					>						
						<span style={{fontWeight:'400',fontSize: '15px',marginTop:'5px'}}>{event.registrationInfoDesc}</span>
						<br />
						<span style={{fontWeight:'400',fontSize: '15px',marginTop:'5px'}}>{event.registrationInfoCost}</span>
						<br />
						{event.externalRegistration ?
						(<Button 
							size="smaller"
							fullWidth							
							color="secondary"
							//component={Link}
							//onClick={() => window.open(event.externalRegistrationUrl, "_blank")}
							href={`${event.externalRegistrationUrl}`}
							target="_blank"
						>
						{event.externalRegistrationButtonLabel}
						</Button>)
						:					  
						(<Button
							size="smaller"
							fullWidth
							color="secondary"
							component={Link}
							to={`${baseURL}/tickets`}
						>
							{language.registration}
						</Button>)
							
						}
				  
					</Box>}
					{/*<Box
						display="flex"
						className={classes.info}
						p={palette.infoPadding}
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						m={palette.infoPadding}
					>
						{event.eventInfo.registrationsOpenAt && (
							<ListItem
								icon={<IconTicketBorderLight />}
								text={
									<>
										Registration <br />{" "}
										{registrationsWillOpen && (
											<>
												Opens on:
												{format(
													parseISO(event.eventInfo.registrationsOpenAt),
													"LLL do"
												)}
											</>
										)}
										{registrationsOpen && !registrationsClosed && (
											<>
												Closes on:
												<br />{" "}
												{format(
													parseISO(event.eventInfo.registrationsCloseAt),
													"LLL do"
												)}
											</>
										)}
									</>
								}
							/>
						)}
						<ListItem
							icon={<IconDollarSignBorderLight />}
							text={ticketPrice(event.eventInfo.ticketsFrom)}
						/>
						<Box display="flex">
							<Button
								size="smaller"
								fullWidth
								color="secondary"
								component={Link}
								to={`${baseURL}/tickets`}
							>
								Get your tickets
							</Button>
						</Box>
					</Box>*/}
				</>
			);
    }
);

export default RegistrationInfo;
