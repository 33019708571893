import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import {Box, Grid, Typography} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { palette } from "theme";
import { withRouter } from "react-router-dom";
import { useMetaData } from "components/core/MetaProvider";
import SubFooter from "components/core/Footer/SubFooter";
import Header from "components/core/Header/Header";
import BottomHero from "components/core/BottomHero";
import TopImage from "assets/post-event-top.png";
import MidImage from "assets/post-event-mid.png";
import BottomImage from "assets/post-event-bottom.png";
import Tick from "assets/Tick_icon.svg";
import MIddlepart_graphic_left from "assets/MIddlepart_graphic_left.svg";

// import downloadFromAppStore from 'assets/download-on-the-app-store-apple.svg'
// import downloadFromPlayStore from 'assets/google-play-download-android-app.svg'
// import anyMeetsRunningOnPhone from 'assets/anymeets_on_mobile.png'
//
// import {APP_ANDROID_INFO, APP_APPLE_INFO} from 'config'

const styles = (theme) => ({
	homeTimeLine: {
		padding: "65px 10px",
		marginTop: "65px",
		marginBottom: "25px",
		backgroundColor: "#f0f9ff",
		backgroundImage: "url(" + MIddlepart_graphic_left + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "left bottom",
		backgroundSize: "contain",
	},
	hero: {
		maxWidth: "768px",
		backgroundSize: "cover",
		backgroundColor: "#fff",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	tagLine: {
		maxWidth: "590px",
		color: palette.aquamarine,
		fontSize: "46px",
		textAlign: "left",
		fontWeight: "bold",
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(3),
			marginBottom: 0,
		},
	},
	title: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "45px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		letterSpacing: "-2px",
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down("xs")]: {
			fontSize: "40px",
		},
	},
	secondaryTitle: {
		maxWidth: "600px",
		color: "#000",
		fontSize: "36px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		lineHeight: "1.2em",
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "27px",
		},
	},
	subtitle: {
		maxWidth: "535px",
		margin: "0px auto 10px",
		padding: "5px",
		fontFamily: "Karla",
		fontSize: "20px",
		letterSpacing: "0",
		textAlign: "center",
		color: "#4a4a4a",
		display: "block",
		fontWeight: "normal",
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down("xs")]: {
			fontSize: "18px",
			padding: theme.spacing(3),
		},
	},
	anyHomeImage: {
		width: "100%",
		height: "auto",
		maxWidth: 900,
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "32px auto",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
	tabImages: {
		width: "80%",
		maxWidth: "450px",
		margin: "40px auto",
		borderRadius: "20px",
		display: "block",
	},
	pageContent: {
		marginTop: "100px",
	},
	homeMiddle: {
		padding: "0px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	iconSet: {
		maxWidth: "580px",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "20px 25px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "10px 0",
			fontFamily: "Karla",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		margin: "5px",
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	subList: {
		paddingLeft: "30px",
		"& span": {
			fontFamily: "Karla",
			color: "#4a4a4a",
			fontSize: "21px",
			letterSpacing: "-1.05px",
			lineHeight: "24px",
		},
	},
	tickIcon: {
		width: "15px",
		lineHeight: "15px",
		margin: "0 4px 0 11px",
	},
});

const Post = withStyles(styles)(({ classes, history }) => {
	const [, setMetaData] = useMetaData();

	useEffect(() => {
		setMetaData({ title: "Post Events" });
		// eslint-disable-next-line
	}, []);

	return (
		<div>
			<Header />
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.pageContent}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item>Collect metrics and plan smarter</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle}
				>
					<Grid item>
						Access information to help you monitor your event in real-time and
						make decisions after its conclusion.
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Box style={{maxWidth: "768px", width: "100%"}}>
						<img
							style={{maxWidth: "768px", width: "100%", typography: "body1"}}
							src={TopImage}
							alt="Anymeets statistics from post event"
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid
				className={classes.homeTimeLine}
				item
				container
				justify="center"
				alignContent="center"
				alignItems="center"
				style={{padding: "50px 15px", backgroundColor: "#f0f9ff"}}
			>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
				>
					<Grid item className={classes.secondaryTitle}>
						Gather feedback for future events
					</Grid>
				</Grid>
				<Grid
					item
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					style={{maxWidth: "768px"}}
				>
					<Grid item container lg={12} sm={12} xs={12}>
						<Grid item lg={4} sm={4} xs={12}>
							<Typography className={classes.subList}>
								<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />
								<span
									style={{
										display: "inline-block",
										verticalAlign: "top",
										marginLeft: "10px",
									}}
								>
									Event-wide surveys
								</span>
							</Typography>
						</Grid>
						<Grid item lg={4} sm={4} xs={12}>
							<Typography className={classes.subList}>
								<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />
								<span
									style={{
										display: "inline-block",
										verticalAlign: "top",
										marginLeft: "10px",
									}}
								>
									In-session surveys
								</span>
							</Typography>
						</Grid>
						<Grid item lg={4} sm={4} xs={12}>
							<Typography className={classes.subList}>
								<img src={Tick} alt ="anymeets tick icon" className={classes.tickIcon} />
								<span
									style={{
										display: "inline-block",
										verticalAlign: "top",
										marginLeft: "10px",
									}}
								>
									Polls
								</span>
							</Typography>
						</Grid>
					</Grid>
					<Grid item container lg={12} sm={12} xs={12}>
						<Box style={{maxWidth: "768px", width: "100%"}}>
							<img
								className={classes.tabImages}
								style={{maxWidth: "768px", width: "100%", typography: "body1"}}
								src={MidImage}
								alt="Anymeets feedback from post event"
							/>
						</Box>
					</Grid>
					<Grid item container lg={12} sm={12} xs={12}>
						<Box style={{maxWidth: "768px", width: "100%"}}>
							<img
								className={classes.tabImages}
								style={{maxWidth: "768px", width: "100%", typography: "body1"}}
								src={BottomImage}
								alt="Anymeets feedback from post event"
							/>
						</Box>
					</Grid>
				</Grid>
			</Grid>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.title}
				>
					<Grid item style={{color: "#3d4d59", marginBottom: "30px"}}>
						Why Anymeets
					</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Statistics</h3>
								<p>
									Get a behind-the-scenes look at the numbers that make up your
									event.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Analytics</h3>
								<p>Use data and metrics to make improvements for the future.</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Certificate of attendance</h3>
								<p>
									Email certificates of attendance to all your attendees with
									one click.
								</p>
							</Paper>
						</Grid>
						<Grid item lg={6} sm={6} xs={12}>
							<Paper className={classes.iconSet}>
								<h3 className={classes.iconTag}>Agenda</h3>
								<p>
									Your event Agenda will stay active for two years. Need more
									time? Let us know!
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />

			<SubFooter />
			{/*<Footer/>*/}
		</div>
	);
});

export default withRouter(Post);
