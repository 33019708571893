import React from 'react'
import {withStyles} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import clsx from 'clsx'
// import UpgradeOverlay from 'components/core/UpgradeOverlay'

const styles = theme => ({
  optionBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '200px',
    height: '90px',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    cursor: 'pointer',
    position: 'relative',
    overflow:'hidden'
  },
  viewOnly: {
    cursor: 'default',
    height: 55,
  },
  withIcon: {
    height: '140px',
  },
  selected: {
    backgroundColor: palette.aquamarine,
    color: 'white',
    '& svg *': {
      stroke: 'white'
    }
  },
  text: {
    display: 'flex',
  },
  icon: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  wrapper: {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    flexBasis: '100%'
  },
  disabled: {
    opacity: 0.2,
    cursor: 'default',
    pointerEvents: "none"
  }
})

const OptionCard = withStyles(styles)(({classes, text, icon, selected=false, onClick, disabled, tierUpgrade, upgradeTextOnly=false, viewOnly})=>{
  const boxStyle = clsx({
    [classes.optionBox] : true,
    [classes.withIcon] : icon,
    [classes.selected] : selected,
    [classes.disabled] : disabled,
    [classes.viewOnly] : viewOnly
  })

  const innerBoxStyle = clsx({
    [classes.wrapper] : true
  })

  return <Box className={boxStyle} onClick={onClick}>
    <Box className={innerBoxStyle}>
      {icon&&<Box className={classes.icon}>
        {icon}
      </Box>}
      <Box className={classes.text}>
        {text}
      </Box>
    </Box>
    {/* {tierUpgrade&&<UpgradeOverlay textOnly={upgradeTextOnly}/>} */}
  </Box>
})

export default OptionCard
