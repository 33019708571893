import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FieldText from 'components/core/FieldText'
import {format} from "date-fns"
import parseISO from "date-fns/parseISO"
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import Box from '@material-ui/core/Box'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const styles = theme => ({
  container: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative',
    padding: theme.spacing(1),
    maxWidth: '1000px',
  },
  ReviewFlowCard: {
    width: '100%',
  },
  viewIcon: {
    color: 'white !important',
    // position: 'absolute',
    // top: theme.spacing(2.1),
    // right: theme.spacing(11),
    cursor: 'pointer',
    // [theme.breakpoints.down(1200)]: {
    //   top: theme.spacing(6),
    //   right: theme.spacing(6),
    // }
  },
  editIcon: {
    // position: 'absolute',
    // top: theme.spacing(2),
    // right: theme.spacing(6),
    cursor: 'pointer',
    // [theme.breakpoints.down(1200)]: {
    //   top: theme.spacing(6),
    //   right: theme.spacing(2),
    // }
  },
  deleteIcon: {
    // position: 'absolute',
    // top: theme.spacing(2),
    // right: theme.spacing(2),
    cursor: 'pointer'
  },
  downloadIcon: {
    cursor: 'pointer'
  }
})

const ReviewFlowCard = withStyles(styles)(({classes, dragHandleProps, reviewFlow, viewAction, eventId, index, viewOnly=false, editAction, deleteAction, ...props}) => {

  return <Grid item container spacing={1} lassName={classes.ReviewFlowCard} key={index} {...props}><ThemeProvider theme={theme}>
    <Grid item container className={classes.container} justify={'space-between'} alignContent='center' alignItems='center'>

      <Grid item>
        <Box mr={1} className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>
      </Grid>
        <Grid item xs={4}>
          <FieldText title={'Name'} value={reviewFlow.name} />
        </Grid>
        <Grid item xs={3}>
          <FieldText title={'Open Date'} value={format(parseISO(reviewFlow.openDate), 'LLL do yyyy')} />
        </Grid>
        <Grid item xs={3}>
          <FieldText title={'End Date'} value={format(parseISO(reviewFlow.endDate), 'LLL do yyyy')} />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Grid item container justify={'flex-end'}>*/}
        {/*    <Grid item>*/}
        {/*      <Button size='small' component={Link} to={`/manage-event/${eventId}/submissions?submission=${reviewFlow.id}`}> View submissions </Button>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}

      {!viewOnly&&<Grid item xs>
        <Grid item container justify='center' alignContent='center' alignItems='flex-end' spacing={1}>
          {/*<Grid item>*/}
          {/*  <GetAppIcon title='Edit Flow' className={classes.downloadIcon} onClick={()=>{*/}
          {/*    downloadCSV(reviewFlow.id,reviewFlow.name)*/}
          {/*  }}/>*/}
          {/*</Grid>*/}
          {/*<Grid item>*/}
          {/*  <Link to={`/manage-event/${eventId}/submissions?submission=${reviewFlow.id}`}><ListAltIcon title='ViewFlow' className={classes.viewIcon}  /> </Link>*/}
          {/*</Grid>*/}
          <Grid item>
            <EditIcon title='Edit Flow' className={classes.editIcon} onClick={()=>{editAction&&editAction(reviewFlow.id)}}/>
          </Grid>
          <Grid item>
            <CloseIcon title='Delete Flow' className={classes.deleteIcon} onClick={()=>{deleteAction&&deleteAction(reviewFlow.id)}}/>
          </Grid>
        </Grid>
</Grid>}

    </Grid>
  </ThemeProvider>
  </Grid>
})

export default ReviewFlowCard
