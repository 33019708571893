import {withStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import React, {useState} from 'react';
import {palette} from 'theme';
import {withRouter} from 'react-router-dom';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ResultsDialog from 'components/core/OpenTok/Components/Feedback/ResultsDialog'
import language from "components/language";

const styles = (theme) => ({
  info: {
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    '& a': {
      color: palette['cerulean-blue'],
      fontWeight: 'bolder',
    },
  },
  form: {
    width: '100%',
  },
  optionBox: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justify: 'center',
    marginBottom: theme.spacing(1),
    boxSizing: 'borderBox',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    cursor: 'pointer',
    overflow:'hidden',
    width: '100%',
    '&:hover': {
      backgroundColor: palette.aquamarine,
      color: 'white'
    }
  },
  optionValues: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  link: {
    width: '100%',
    borderRadius: '6px',
    boxShadow: '0 0px 2px 2px rgba(0, 0, 0, 0.1), 0 -1px 4px 0 rgba(0, 0, 0, 0.1)',
    fontWeight: 'bold',
    background: 'white',
    color: palette['greyish-brown'],
    padding: theme.spacing(1),
    borderColor: palette['pale-grey'],
    border: '1px solid',
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
    // color: palette['cerulean-blue'],
    // fontWeight: 'bolder',
  },
  list: {
    width: '100%'
  }
});

const FeedbackInfo = withStyles(styles)(({classes, sessionState, feedbackHandlers}) => {

  const {
    hasFeedback,
    feedback
  } = sessionState

  const {setOpenFeedback} = feedbackHandlers

  const [code, setCode] = useState('');
  const [selectedFeedbackResult, setSelectedFeedbackResult] = useState(false)

  const handleChange = (e) => {
    setCode(e.target.value)
  }

  const handleCloseResults = () => {
    setSelectedFeedbackResult(false)
  }

  const handleOpenResults = (code) => {
    setSelectedFeedbackResult(code)
  }

  const handleOpenFeedback = (code) => {
    setOpenFeedback(code)
  }

  return (
    <>
      <Dialog
        open={!!selectedFeedbackResult}
        onClose={handleCloseResults}
        maxWidth='lg'
      >
        <ResultsDialog feedbackCode={selectedFeedbackResult} />
      </Dialog>

      {/*{session.status.activeFeedback&&answeredFeedback!==session.status.activeFeedback&&!closedDialog&&<>*/}
      {/*  Show Dialog </>}*/}
      {/*  <Button onClick={()=>setClosedDialog(true)}> close dialog </Button>*/}
      {/*<Button onClick={()=>setAnsweredFeedback(session.status.activeFeedback)}> set answered feedback </Button>*/}

      <Grid item container>
        <Grid item xs={12}>
          <Typography variant='h3'>{language.livePolls}</Typography>
        </Grid>

        <Grid item xs={12}>
          {feedback && feedback.length !== 0 && (
            <Box width={'100%'}>
              <List classes={{root:classes.list}}>
                {feedback && feedback.map((f, i) => {
                  return (
                    <ListItem key={i} className={classes.optionBox} onClick={() => handleOpenFeedback(f.code)} >
                      <ListItemText primary={f.title}/>
                      {f.publicResults&&<ListItemSecondaryAction>
                        <Button size="small" color='primary' variant='contained' onClick={()=> handleOpenResults(f.code)}> {language.viewResults} </Button>
                        {/*<IconButton edge="end" aria-label="comments" onClick={()=> handleOpenResults(f.code)}>*/}
                        {/*  <InsertChartIcon />*/}
                        {/*</IconButton>*/}
                      </ListItemSecondaryAction>}
                    </ListItem>
                  )
                })}
              </List>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          {hasFeedback&&<Grid item container spacing={2} justify='space-between' alignItems='center' alignContent='center'>

            <Grid item xs>
              <TextField variant="outlined" label={language.insertCode} onChange={handleChange} fullWidth value={code}/>
            </Grid>

            <Grid item>
              <Button fullWidth size="small" variant='contained' color='secondary' onClick={() => handleOpenFeedback(code)}>
                &nbsp; {language.go} &nbsp;
              </Button>
            </Grid>
          </Grid>}

          {!hasFeedback&&<>
            <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' alignItems='center' pt={2}>
              <Box display='flex' color={palette['greyish-brown']} fontWeight={200}>
                {language.thereAreCurrentlyNoLivePollsAvailable}
              </Box>
            </Box>
          </>}
        </Grid>

      </Grid>



      {/*<Box display='flex' width='100%'>*/}
      {/*  <Box display='flex' ml={1}>*/}
      {/*    <Tooltip*/}
      {/*      title={*/}
      {/*        <Box fontWeight='bold' fontSize={14}>*/}
      {/*          A code will be provided <br/> during the session*/}
      {/*        </Box>*/}
      {/*      }*/}
      {/*    >*/}
      {/*      <InfoIcon fontSize='small'/>*/}
      {/*    </Tooltip>*/}
      {/*  </Box>*/}
      {/*</Box>*/}


    </>
  );
});

export default withRouter(FeedbackInfo);