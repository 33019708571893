import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import OpenTokRoom from 'components/core/OpenTok/OpenTokRoom'
import {useParams} from 'react-router-dom'
import Header from 'components/core/Header/Header'
import Page from 'components/core/Page'

const styles = theme => ({

})

// const CustomTab = withStyles(styles)(({classes, sessionState}) => {
//   return <> Test {sessionState.roomId} </>
// })

const SessionAnymeetsLive = withStyles(styles)(({classes, type, session, isAnymeetsLive, isWorkShop}) => {

  // Route parameters
  const {sessionId} = useParams()

  return <>
    <Header isSession={true} session={{...session, id: sessionId}} />

    <Page title={session.title} p={0} noFooter backgroundColor={'#fafafa'} mt={4}>
      <OpenTokRoom room={{...session, id: sessionId}} customTabs={[]} type={type} isAnymeetsLive={isAnymeetsLive} isWorkShop={isWorkShop}/>
    </Page>
  </>

})
// customTabs={label:'label',component:comp}
export default SessionAnymeetsLive
