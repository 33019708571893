import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'
import Paper from '@material-ui/core/Paper'
import TagFacesIcon from '@material-ui/icons/TagFaces'
import {IconAddBorderLight} from 'components/core/icons'
import Grid from '@material-ui/core/Grid'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipAdd: {
    margin: theme.spacing(0.5),
    color: 'white'
  },
  selected: {
    '& > *': {
      color: 'white'
    }
  }
}))

const ChipsArray = ({entries,onClickHandler,onDeleteHandler,onNewHandler,selectedId}) => {
  const classes = useStyles()

  return (
    <Paper component="ul" elevation={0} variant={'outlined'} className={classes.root}>
      {entries.map((data) => {
        let icon

        if (data.label === 'React') {
          icon = <TagFacesIcon />
        }

        return (
          <li key={data.id}>
            <Chip
              color={selectedId===data.id?'secondary':'default'}
              clickable={!!onClickHandler}
              icon={icon}
              label={data.name}
              onDelete={()=>{ onDeleteHandler&&onDeleteHandler(data)}}
              onClick={()=>{ onClickHandler&&onClickHandler(data) }}
              className={clsx(classes.chip,selectedId===data.id&&classes.selected)}
            />
          </li>
        )
      })}
      <li key={'new-key'}>
        <Chip
          clickable
          onClick={()=>{ onNewHandler&&onNewHandler(true) }}
          label={<Grid container justify={'center'} alignContent={'center'} alignItems={'center'} spacing={1}><Grid item><IconAddBorderLight /></Grid><Grid item>Add new </Grid></Grid>}
          className={classes.chipAdd}
          color='primary'
        />
      </li>
    </Paper>
  )
}

export default ChipsArray
