import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
//import {Typography} from '@material-ui/core'
//import {SIZE_ORGANIZER_LOGO} from 'config'
//import {imageFile} from 'utils'
import React from 'react'
import { palette } from 'theme'
import Grid from '@material-ui/core/Grid'
import OrganizerCardView from 'components/core/OrganizerCardView'
import language from "components/language";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
    organizerLogo: {
        overflow: 'hidden',
        '& img': {
            width: '100%',
            height: 'auto',
            backgroundColor: palette.imageBackgroundColor
        }
  },
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

const OrganizerList = withStyles(styles)(({ classes, event, isPreview }) =>
{
  const history = useHistory();
    if (event.organizers.length === 0) return <> </>
    return (
      <Box display="flex" p={4} pb={12} flexDirection="column">
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/organizers`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Organizers</Button>}
            </Grid>         
        <Box component="h2" fontWeight="bolder" fontSize={26} mb={1}>
          {event.organizers.length > 1 ? language.organizers : language.organizer}
        </Box>

        <Grid container>
          {event.organizers.map((organizer, i) => {
            return (
              <Grid item container spacing={0} m={2} lg={12} md={12} sm={12} xs={12}>
                <OrganizerCardView event={event} organizer={organizer} p={1} index={i} key={i} />
              </Grid>
            );
          })}

          {/*<Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Box display='flex' justifyContent='center' alignItems='center' className={classes.organizerLogo} pt={2}
                     pb={2}
                     width={SIZE_ORGANIZER_LOGO.width * 0.2}>
                    <img src={imageFile(organizer.logoId)} alt={`${organizer.name}`}/>
                </Box>
                <Box display='flex'>
                    <Typography variant='body2'>
                        {organizer.summary}
                    </Typography>
                </Box>
            </Box>*/}
        </Grid>
      </Box>
    );
})

export default OrganizerList
