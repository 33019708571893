import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {compareAsc, format} from 'date-fns'
import {SPONSOR_TYPES} from 'config'
import Grid from '@material-ui/core/Grid'
import {checkHost, imageFile, whichDayInInterval, withHttp} from 'utils'
import parseISO from 'date-fns/parseISO'
import {NavLink, useParams} from 'react-router-dom'
import Chip from 'components/core/Chip'
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
const dateLocale = [{ locale: enUS }, { locale: enUS }, { locale: el }, { locale: de }, { locale: fr }, { locale: it }];

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    '& a': {
      color: palette['cerulean-blue']
    },
    width: '100%',
    margin: '8px',
    padding: '16px'
  },
  ParticipantCardView: {
    transition: 'opacity 0.7s'
  },
  logo: {
    display: 'flex',
    width: '100px',
    height: '100px',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    padding: theme.spacing(1),
    '& img': {
      display: 'flex',
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
      , [theme.breakpoints.down('sm')]: {
        margin: '0 auto'
      }
    }
  },
  fieldTitle: {
    fontSize: '14px',
  },
  fieldValue: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  sessionBox: {
    minHeight: '60px',
    backgroundColor: palette['very-light-pink'],
    borderRadius: '8px',
    padding: theme.spacing(1),
    color: palette['greyish-brown'],
    height: '100%'
  },
  sessionTitle: {
    fontWeight: 'bold'
  },
  sessionsBox: {
    paddingTop: theme.spacing(1),
  },
  sessionWrapper: {
    minWidth: '160px'
  },
  sessionTimes: {
    fontSize: '14px'
  },
  summary: {
    marginTop: -theme.spacing(2)
  },
  sponsorUrl: {
    color: `${palette.aquamarine} !important`
  },
  link: {
    cursor: 'pointer'
  }
})

const Session = withStyles(styles)(({classes, day, ordinal, startDate, endDate, event, ...restProps}) => {
  return (
    <Grid item container className={classes.sessionBox} {...restProps}>
      <Grid item xs={12} className={classes.sessionTitle}>
        {language.day} {day}, {language.session} {ordinal}{" "}
      </Grid>
      <Grid item xs={12} className={classes.sessionTimes}>
        {format(parseISO(startDate), "LLL do y", dateLocale[event.eventInfo.eventLanguage])} <br />
        {format(parseISO(startDate), "hh:mmaa", dateLocale[event.eventInfo.eventLanguage])} - {format(parseISO(endDate), "hh:mmaa", dateLocale[event.eventInfo.eventLanguage])} <br />
      </Grid>
    </Grid>
  );
})

const SponsorCardView = withStyles(styles)(({classes, sponsor, event, index, plain, linkToPage=true, ...props}) => {

  const {slug} = useParams()
  const host = checkHost()

  let baseURL = `/event/${event.eventInfo.id}`

  if (slug) {
    baseURL = `/${slug}`
  } else if (host) {
    baseURL = ``
  }

  const createMarkup = (value) => {
    return {__html: value}
  }

  const sponsorType = (type) =>{
    
    let sponsor = "";
    switch (type)
    {
      case 'Platinum':
        sponsor = language.platinum
      break;
      case 'Gold':
        sponsor = language.gold
          break;
      case 'Silver':
        sponsor = language.silver
          break;
      case 'Bronze':
        sponsor = language.bronze
          break;
      case 'Exhibitor':
        sponsor = language.exhibitor
          break;
      case 'Media Partner':
        sponsor = language.mediaPartner
          break;
      case 'Research Sponsor':
        sponsor = language.researchSponsor
          break;
      case 'Diversity Sponsor':
        sponsor = language.diversitySponsor
          break;
      case 'Lanyard Sponsor':
        sponsor = language.lanyardSponsor
          break;
      case 'Community Partner':
        sponsor = language.communityPartner
          break;
      case 'Presenting Sponsor':
        sponsor = language.presentingSponsor
          break;
      case 'Partner':
        sponsor = language.partner
          break;
      case 'With the support of':
        sponsor = language.withTheSupportOf
          break;                
      default:
        sponsor =""
    }
      return sponsor
  }

  return <Box className={classes.container} {...props} key={index}>
    {/*<Box p={2} className={clsx(classes.container,plain&&classes.plain)}>*/}

    <Grid container spacing={2}>

      <Grid item xs={12}>
        <Box fontSize={18} fontWeight={'bold'} pb={3}>
          { sponsorType(SPONSOR_TYPES[sponsor.type])}
        </Box>
      </Grid>

      <Grid item container spacing={2}>
        <Grid item>
          <Box className={classes.logo}>
            {linkToPage?<NavLink to={sponsor.online?`/sponsor/${sponsor.id}`:`${baseURL}/sponsors/${sponsor.id}`}> <img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} className={classes.logoImg} /> </NavLink>:<img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} className={classes.logoImg} />}
          </Box>
        </Grid>
        <Grid item>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <Box fontSize={24} fontWeight={'bold'}>
                {linkToPage?<NavLink to={sponsor.online?`/sponsor/${sponsor.id}`:`${baseURL}/sponsors/${sponsor.id}`}>  {sponsor.name} </NavLink>:sponsor.name}
              </Box>
            </Grid>
            {sponsor.online&&<Grid item xs={12}>
              <NavLink to={`/sponsor/${sponsor.id}`}><Chip clickable label={'Online booth available'} /></NavLink>
            </Grid>}
            {sponsor.url!="" && <Grid item xs={12}>
              <Box p={1} pl={0}>
                <a href={withHttp(sponsor.url)} className={classes.sponsorUrl}> {language.visitUs} </a>
              </Box>
            </Grid>}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box p={2} pl={0}>
          {sponsor.summary ? <div dangerouslySetInnerHTML={createMarkup(sponsor.summary)} key={sponsor.summary} /> : ''}
        </Box>
      </Grid>


      <Grid item xs={12}>
        <Grid item container className={classes.sessionsBox} spacing={1}>
          {sponsor.sessions
            .sort((a, b) => compareAsc(parseISO(a.startDate), parseISO(b.startDate)))
            .sort((a, b) => (parseInt(a.ordinal) - parseInt(b.ordinal)))
            .map((session, i) => {
              return <Grid item xs={12} sm={6} md={4} lg={2} className={classes.sessionWrapper}>
                <Session day={whichDayInInterval(event.eventInfo.startDate, event.eventInfo.endDate, session.startDate)} ordinal={session.ordinal}
                         startDate={session.startDate}
                         endDate={session.endDate} key={i} event={event}/>
              </Grid>
            })}
        </Grid>
      </Grid>
    </Grid>

    {/*</Box>*/}

  </Box>
})

export default SponsorCardView
