import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import {palette} from 'theme'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import Button from 'components/core/Button'
import {withRouter} from 'react-router-dom'

const styles = theme => ({

})

const EventCode = withStyles(styles)(({classes,history}) => {
  return <Page title='Search by event code' display='flex' backgroundColor={palette['pale-grey']} justifyContent='center' alignContent='center' alignItems='center'>
    <Box m={2} mb={24} display='flex' flexDirection='column'>
      <Typography variant={'h6'} align='center'>Please enter your event code</Typography>
      <Formik
        initialValues={{query:''}}
        // validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          if (values.query.includes('-')) {
            history.push(`/feedback/${encodeURI(values.query.trim())}`)
          } else {
            history.push(`/event/${encodeURI(values.query.trim())}`)
          }
        }}>
        {({ isSubmitting, errors, submitForm }) => (
          <Form className={classes.form}>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
              <Box mt={2} mb={5} width='330px' display='flex'>
                <Field type='text' name='query' component={TextField} label='Type code' variant='filled' fullWidth disabled={isSubmitting}/>
              </Box>
              <Button type='submit' color='primary' size='small'>Submit code</Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  </Page>
})

export default withRouter(EventCode)
