import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import React from "react";
// import {format} from 'date-fns'
import {palette} from "theme";
// import parseISO from 'date-fns/parseISO'
import Button from "components/core/Button";
import {Link, useParams} from "react-router-dom";
// import PostAddIcon from '@material-ui/icons/PostAdd'
import Grid from "@material-ui/core/Grid";
import {checkHost} from "utils";
import language from "components/language";

const styles = (theme) => ({
  info: {
    backgroundColor: theme.palette.background.primary,
    color: theme.dark ? "white" : theme.palette.text.primary,
    fontWeight: "500",
    borderRadius: "12px",
    zIndex: 999,
    textAlign: "center"
  },
});

// const ListItem = withStyles(styles)(({classes, icon, text}) => {
//     return (
//         <Box
//             display="flex"
//             flexDirection="row"
//             flexBasis={'100%'}
//             width="100%"
//             mb={2}
//             alignItems="center"
//             justifyContent="flex-start"
//             className={classes.listRow}
//         >
//             <Box display="flex">{icon}&nbsp&nbsp</Box>
//             <Box display="flex">{text}</Box>
//         </Box>
//     )
// })

const CameraReadyInfo = withStyles(styles)(({title, value, event, classes}) => {
  const {slug} = useParams();
  const host = checkHost();

  let baseURL = `/event/${event.eventInfo.id}`;

  if (slug) {
    baseURL = `/${slug}`;
  } else if (host) {
    baseURL = ``;
  }

  return (
    <>
      <Box display="flex" className={classes.info} p={palette.infoPadding} flexDirection="column" justifyContent="center" alignItems="center" m={palette.infoPadding}>
        <Grid item container justify={"center"} alignContent={"center"} alignItems={"center"}>
          <Grid item style={{fontSize: 16, fontWeight: "500", marginLeft: "8px"}}>
            {language.cameraReadySubmissionsAreOpen}
          </Grid>
        </Grid>

        <Box display="flex" mt={2}>
          <Button size="smaller" color="secondary" component={Link} to={`${baseURL}/camera-ready`}>
            <span style={{lineHeight: "15px"}}>{language.uploadCameraReady}</span>
          </Button>
        </Box>
      </Box>
    </>
  );
});

export default CameraReadyInfo;
