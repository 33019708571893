import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useRef, useState} from 'react'
import OpenTokRoom from 'components/core/OpenTok/OpenTokRoom'
import {useParams} from 'react-router-dom'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const styles = theme => ({

})
const RoomIntegrationOpenTok = withStyles(styles)(({classes, uuid, vroom, isOnlinePoster, isVirtualRoom, posterPresentation}) => {
  //eslint-disable-next-line
  const [getData, roomData, isLoading, hasError] = useHttp()
  const [room, setRoom] = useState()
  const [getAttendeeData, attendeeData] = useHttp(true)
  const [attendee, setAttendee] = useState()
  const [reqAddToWaitingList, , , hasErrorAddToWaitList] = useHttp()
  const [addedToWaitList, setAddedToWaitList] = useState(false)

  const [wait, setWait] = useState(true)
  const poller = useRef(null)

  const {type} = useParams()

  const getAttendee = () => {
    getAttendeeData(api.entities.virtualRooms.getAttendee(null, null, {code: uuid}))
  }

  useEffect(()=>{
    if (vroom.host) {
      setWait(false)
      getData(api.entities.virtualRoom.get(null, null, {id: uuid}))
    } else {
      getAttendee()
      clearInterval(poller.current)
      poller.current = setInterval(getAttendee,6000)
    }

    return () => {
      clearInterval(poller.current)
    }
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (attendeeData) {
      setAddedToWaitList(attendeeData.state===1)
      if (attendeeData.state>=2) {
        clearInterval(poller.current)
        setWait(false)
        getData(api.entities.virtualRoom.get(null, null, {id: uuid}))
      }
      setAttendee(attendeeData)
    }
    //eslint-disable-next-line
  },[attendeeData])

  useEffect(()=>{
    if (roomData) {
      setRoom(roomData)
      clearInterval(poller.current)
    }
    //eslint-disable-next-line
  },[roomData])

  const addToWaitingList = () => {
    setAddedToWaitList(true)
    reqAddToWaitingList(api.entities.virtualRooms.addToWaitingList(null,null,{code:uuid}))
    clearInterval(poller.current)
    poller.current = setInterval(getAttendee,6000)
  }

  useEffect(()=>{
    if (hasErrorAddToWaitList) {
      setAddedToWaitList(false)
    }
  },[hasErrorAddToWaitList])

  if (vroom.host===false&&!attendee) return <> </>

  // if (!attendee) return <> </>

  if (attendee&&attendee.hostJoined===false) return <>
    <Box display='flex' justifyContent={'center'} position='absolute' top={0} left={0} height={'100%'} width={'100%'}>
      <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
        <Box display='flex'>
          The host has not joined the room. Please wait.
        </Box>
      </Box>
    </Box>
  </>

  const waitingRoom = !!(vroom.capacity&&(vroom.totalJoined>=vroom.capacity)&&attendee&&(attendee.state<=1))
  // const waitingHost = (attendee&&attendee.hostJoined===false)

  if (waitingRoom&&wait) return <>
      <Box display='flex' justifyContent={'center'} position='absolute' top={0} left={0} height={'100%'} width={'100%'}>
        <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
          <Box display='flex' maxWidth={600} style={{textAlign:'center'}}>
            {addedToWaitList?
              <>You are currently on the waitlist to enter this room.<br/> If you leave this page, as you will lose your place in line! <br/> If you want to look at a different poster, please open a tab in your browser.</>:`This room is full, do you wish to be added to the waiting list?`}
          </Box>
          {addedToWaitList===false&&<Box display='flex' pt={2}>
            <Button color='secondary' onClick={addToWaitingList} variant={'contained'}> Add to waiting list </Button>
          </Box>}
        </Box>
      </Box>
  </>

  return <> {room&&<OpenTokRoom room={{...room, id: uuid}} customTabs={[]} type={type} isVirtualRoom={isVirtualRoom} isOnlinePoster={isOnlinePoster} posterPresentation={posterPresentation} isWorkShop={room.status.videoStreamType===7}/>} </>

})
// customTabs={label:'label',component:comp}
export default RoomIntegrationOpenTok
