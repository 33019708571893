import {format, parseISO, eachDayOfInterval, isSameDay, compareAsc} from 'date-fns'
import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useRef, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
// import {FilledInput, InputLabel, TextField} from '@material-ui/core'
import SessionCardView from 'components/core/Sessions/SessionCardView'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {TIME_AGENDA_INTERVAL, TIME_FADE_IN} from 'config'
import AnyTabs from 'components/core/AnyTabs'
import {filterList} from 'utils'
import {Alert} from "@material-ui/lab";
import {ReactComponent as NoSearchResult} from 'assets/placeholders/no_search results.svg'
import { SearchInput } from 'components/core/SearchInput'
import CircularProgress from "@material-ui/core/CircularProgress";
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
import moment from "moment";
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const sessionStyles = theme => ({

  results: {
    transition: 'opacity 0.7s'
  },
  hasNoResults: {
    opacity: 0.2
  },
  matched: {
    opacity: 1
  },
  notMatched: {
    opacity: 0.2
  },
  hours: {
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    borderRadius: '8px',
    height: 'calc(100% - 15px)',
    position: 'relative',
    maxWidth: '100px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {
      display: 'none',
      height: 'auto',
      minHeight: theme.spacing(10),
      // padding: theme.spacing(3),
      // width: '100%',
      // textAlign: 'center'
    },
  },
  startTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    top: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      display: 'initial',
      position: 'relative',
      top:0
    },
  },
  endTime: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    position: 'absolute',
    bottom: theme.spacing(1),
    left: 0,
    fontSize: '14px',
    [theme.breakpoints.down('md')]: {
      display: 'initial',
      position: 'relative',
      bottom: 0,
    },
  },
  day: {
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 0, 0, 0.06)',
    maxHeight: '80px',
    minHeight: '38px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  textDay: {
    fontSize: '11px',
  },
  textDate: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  textYear: {
    fontSize: '11px'
  },
  textDayTitle: {
    fontSize: '14px',
    fontWeight: 'bolder'
  },
  sessionBox: {
    // borderRadius: '8px',
    // border: 'solid 1px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    // boxShadow: '0px 0px 2px 1px rgba(0,0,0,0.03)'
  },
  sessionsContainer:{
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      // alignItems: 'center',
      alignContent: 'center'
    }
  },
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	},
})

const SessionsList = withStyles(sessionStyles)(({classes, event, sessionsData, days, going, byTrack, trackId, day, reload, selectedTimezone}) => {
  const currentDay = day;

  if (sessionsData.length === 0) {
    return (
      <Grid item container justify="center" alignContent="center" alignItems="center" style={{minHeight: "30vh"}}>
        <Grid item>
          <Box display="flex" width={200}>
            <NoSearchResult />
          </Box>

          <Box pt={4} style={{textAlign: "center", width: "100%"}}>
            {language.noResultsFound}
          </Box>
        </Grid>
      </Grid>
    );
  }
  const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];
  return (
    <Fade in={true} timeout={TIME_FADE_IN}>
      <>
        {days.map((day, i) => {
          return (
            <Grid container className={classes.sessionBox} key={i}>
              <Grid container spacing={2} className={classes.sessionsContainer}>
                <Grid item xs={1} md={1} lg={1}>
                  <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" flexDirection="column" className={classes.day}>
                    <Box display="flex" className={classes.textDay}>
                      {" "}
                      {format(day, "LLL", dateLocale[event.eventInfo.eventLanguage])}{" "}
                    </Box>
                    <Box display="flex" className={classes.textDate}>
                      {" "}
                      {format(day, "dd")}{" "}
                    </Box>
                    <Box display="flex" className={classes.textYear}>
                      {" "}
                      {format(day, "yyyy")}{" "}
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" className={classes.textDayTitle} pt={0.5}>
                    {language.day} {currentDay ? currentDay : i + 1}
                  </Box>
                </Grid>

                <Grid item xs={11} md={11} lg={11}>
                  {sessionsData
                    .filter((s) => {
                      const sameDay = isSameDay(parseISO(s.startDate), day);
                      const isGoing = going ? s.attending === true : true;
                      const isTrack = byTrack ? s.track && s.track.id === trackId : true;
                      return sameDay && isGoing && isTrack;
                    })
                    // .filter((s)=>{ if (going) { return s.attending===true } else return true })
                    // .filter((s)=>{if (byTrack){ if (s.track) {return s.track.id===trackId} else return false} else return true})
                    // .sort((a,b)=>a.ordinal-b.ordinal)
                    // .sort((a,b)=>compareAsc(parseISO(a.endDate),parseISO(b.endDate)))
                    .map((session, y) => (
                      <SessionCardView event={event} day={0} session={session} index={y} mb={2} reload={reload} selectedTimezone={selectedTimezone} />
                    ))}
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </>
    </Fade>
  );
});

export const Agenda = withStyles(sessionStyles)(({ classes, event, isPreview, canManage }) =>
{
  const history = useHistory();
  const [getAgendaData, agendaData, isLoading] = useHttp()
  const [getAgendaDataRefresh, agendaDataRefresh] = useHttp(true)
  const [agenda, setAgenda] = useState()
  const [days, setDays] = useState(0)
  const [search, setSearch] = useState('')
  const agendaInterval = useRef()

   const [getTimeZones, timeZones] = useHttp();
   const [selectedTimezone, setSelectedTimezone] = useState("UTC");

   useEffect(() => {
     getTimeZones(api.entities.timezones.getTimeZones());
     // eslint-disable-next-line
   }, []);

   useEffect(() => {
     if (event && timeZones) {
       const zone = timeZones.find((x) => x.id === event.timezoneId);
       moment.tz.setDefault(zone.name);
       setSelectedTimezone(zone.name);
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [event, timeZones]); 
  
  const getAgenda = () => {
    if (isPreview) {
      getAgendaData(api.entities.preview.getAgenda(null, null, {id: event.eventInfo.id}))
    } else {
      getAgendaData(api.entities.events.getAgenda(null, null, {id: event.eventInfo.id}))
    }
  }

  const updateAgenda = () => {
    if (isPreview) {
      getAgendaDataRefresh(api.entities.preview.getAgenda(null, null, {id: event.eventInfo.id}))
    } else {
      getAgendaDataRefresh(api.entities.events.getAgenda(null, null, {id: event.eventInfo.id}))
    }
  }

  useEffect(() => {

    getAgenda()

    clearInterval(agendaInterval.current)
    agendaInterval.current = setInterval(()=>{
      updateAgenda()
    },TIME_AGENDA_INTERVAL)

    return () => {
      clearInterval(agendaInterval.current)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (agendaData) {
      setAgenda({
        ...agendaData,
        sessions: agendaData.sessions.sort((a,b)=>a.ordinal-b.ordinal).sort((a,b)=>compareAsc(parseISO(a.endDate),parseISO(b.endDate)))
      })
      setDays(eachDayOfInterval({start: parseISO(event.eventInfo.startDate), end: parseISO(event.eventInfo.endDate)}))
    }
    // eslint-disable-next-line
  }, [agendaData])

  useEffect(() => {
    if (agendaDataRefresh) {
      setAgenda({
        ...agendaDataRefresh,
        sessions: agendaDataRefresh.sessions.sort((a, b) => a.ordinal - b.ordinal).sort((a, b) => compareAsc(parseISO(a.endDate), parseISO(b.endDate)))
      })
    }
    // eslint-disable-next-line
  }, [agendaDataRefresh])

  const reload = () => {
    if (isPreview) {
      getAgendaData(api.entities.preview.getAgenda(null, null, {id: event.eventInfo.id}))
    } else {
      getAgendaData(api.entities.events.getAgenda(null, null, {id: event.eventInfo.id}))
    }
  }

  if (!agenda) {
    return (
      <>
        {" "}
        <Grid item xs={12} md={12} alignItems="center">
          <Box position="relative" minHeight={300} justifyContent="center" alignContent="center" alignItems="center" display="flex">
            <Box display="flex">
              <CircularProgress size={"8rem"} color="secondary" />
            </Box>
          </Box>
        </Grid>{" "}
      </>
    ); // Loading
  }

  const sessions = search?filterList(search,['title','description','participants','track'], agenda.sessions).filter((s)=>s.matched===true):agenda.sessions
 
  return (
    <>
      <Fade timeout={TIME_FADE_IN} in={true}>
        <Box display="flex" p={4} pt={0} pb={12} flexDirection="column">

          <Grid container>
            <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/sessions`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Agenda</Button>}
              {/* <Alert severity="info">{language.allTimesDisplayedHaveBeenConvertedToYourLocalTimezone}</Alert> */}
            </Grid>

            <Grid item xs={12} md={8}>
              <Box component="h1" fontWeight="bolder" fontSize={26} mb={1}>
                {language.agenda}
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Box pb={2} pr={1}>
                {agendaData.sessions && agendaData.sessions.length !== 1 && <SearchInput doSearch={setSearch} label={language.searchSessions} charLengthSearch={3} />}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <AnyTabs
                white={true}
                tabs={[
                  {
                    label: language.all,
                    component: <SessionsList event={event} days={days} sessionsData={sessions} reload={reload} selectedTimezone={selectedTimezone} />,
                  },
                  {
                    label: language.mySchedule,
                    component: <SessionsList event={event} days={days} going sessionsData={sessions} reload={reload} selectedTimezone={selectedTimezone} />,
                  },
                  ...days.map((day, i) => {
                    return {
                      index: i,
                      label: language.day + " " + (i + 1),
                      component: <SessionsList event={event} day={i + 1} days={[day]} sessionsData={sessions} reload={reload} selectedTimezone={selectedTimezone} />,
                    };
                  }),
                  ...agenda.tracks.map((track, i) => {
                    return {
                      index: i,
                      label: `${track.name}`,
                      track: track.color,
                      component: <SessionsList event={event} days={days} byTrack trackId={track.id} sessionsData={sessions} reload={reload} selectedTimezone={selectedTimezone} />,
                    };
                  }),
                ]}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </>
  );
})
