import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Input, Select, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette, theme} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import {FIELD_TYPES, TIME_FADE_IN} from 'config'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import {filterList, imageFile} from 'utils'
import TextField from '@material-ui/core/TextField'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import PersonIcon from '@material-ui/icons/Person'
import AssignmentIcon from '@material-ui/icons/Assignment'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {useSnackbar} from "notistack";
import Slider from "@material-ui/core/Slider";

const styles = theme => ({
  reviewsContainer: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    // width: 'calc(100vw - 300px)',
    maxWidth: 1800
  },
  noAssignReviewersYet: {
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '130px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  addBtn: {
    textAlign: 'right'
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(3)
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(4)
  },
  dragGrip: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(3.2),
    width: theme.spacing(5),
    height: theme.spacing(6),
    zIndex: 49
  },
  root: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #cdcdcd',
    backgroundColor: theme.palette.background.paper,
    '&>li': {
      minHeight: 64
    },
    height: '520px',
    overflowY: 'auto',
    padding: 0
  },
  inline: {
    display: 'inline',
  },
  component: {
    borderRadius: 4,
  },
  circle: {
    backgroundColor: '#cdcdcd',
    color: 'white',
    fontWeight: '400',
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
    border: '1px solid #cecece'
  },
  reviewerName: {
    '& span': {
      fontSize: 14
    }
  },
  avatarList: {
    width: 30,
    height: 30
  },
  userPhoto: {
    display: 'flex',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }, [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px'
  },
  papersBox: {
    width: '100%',
    borderRadius: 4,
    border: '1px solid #cdcdcd',
    backgroundColor: theme.palette.background.paper,
    '&>li': {
      minHeight: 64
    },
    height: '520px',
    overflowY: 'auto'
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  codeBox: {
    border: '1px solid #cdcdcd',
    borderRadius: 4,
    backgroundColor: 'white',
    height: '180px',
    overflowY: 'auto',
    padding: 16,
    marginBottom: 16
  },
  codeBoxEmpty: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  assignBox: {
    border: '1px solid #cdcdcd',
    borderRadius: 4,
    backgroundColor: 'white',
    height: '325px'
  },
  paperList: {
    padding: 0
  },
  firstAction: {
    left: 0,
    width: 42
  },
  paperTitle: {
    wordBreak: 'break-word',
    paddingLeft: 38,
    '& span': {
      fontSize: 14
    }
  },
  selectedPaperTitle: {
    wordBreak: 'break-word',
    '& span': {
      fontSize: 14
    }
  },
  autocomplete: {
    overflowY: 'auto',
    maxHeight: 105,
    padding: 4,
    border: '1px solid #cdcdcd',
    borderRadius: 4,
    backgroundColor: 'white'
  }
})

const SubmissionPaper = withStyles(styles)(({
                                              classes,
                                              paperId,
                                              selected,
                                              cachedPapers,
                                              thematicLabels,
                                              selectedPaper
                                            }) => {
  const cachedPaper = cachedPapers.find((p) => p.id === paperId)
  const [getUserInfo, userData] = useHttp()
  const [authorsInfo, setAuthorsInfo] = useState()
  const [authors, setAuthors] = useState()

  useEffect(() => {
    if (cachedPaper && cachedPaper.fields && !authors) {
      setAuthors(cachedPaper.fields.find((field) => field.type === FIELD_TYPES.AUTHORS).value.authors)
    }
    //eslint-disable-next-line
  }, [cachedPaper])

  useEffect(() => {
    if (!authorsInfo && authors) {
      getUserInfo(api.entities.user.getByEmailMultiple(null, {email: authors.join(',')}))
    }
    //eslint-disable-next-line
  }, [authors])

  useEffect(() => {
    if (userData) {
      let obj = {}
      userData.forEach((user, i) => {
        obj[user.email] = user
      })
      setAuthorsInfo(obj)
    }
  }, [userData])

  // const thematicCodes = cachedPaper&&cachedPaper.fields&&cachedPaper.fields.find((field)=>field.type===FIELD_TYPES.THEMATIC_CODE).value
  const thematicCodes = selectedPaper && selectedPaper.codes
  // const title = cachedPaper&&cachedPaper.fields&&cachedPaper.fields.find((field)=>field.type===FIELD_TYPES.LABEL)
  const title = selectedPaper && selectedPaper.title

  return <>

    <Grid item container>

      {!cachedPaper && paperId !== null &&
      <Grid item container spacing={2} justify='center' alignItems='center' alignContent='center'>
        <Grid item>
          <CircularProgress size={'6rem'} color='secondary'/>
        </Grid>
      </Grid>}

      {cachedPaper && paperId !== null && <Grid item xs={12}>
        <Box display='flex' fontSize={16} fontWeight='bold' textAlign={'left'}
             style={{overflowWrap: 'anywhere'}}>{title}</Box>
      </Grid>}

      <Grid item xs={12}>
        {cachedPaper && paperId !== null && <Box>
          {thematicCodes && thematicCodes.length !== 0 &&
          <Box display='flex' fontSize={12} fontWeight='bold' textAlign={'left'} style={{overflowWrap: 'anywhere'}}>Thematic
            codes:</Box>}
          {thematicCodes && thematicCodes.map((value, fi) => {
            return <Chip
              key={fi}
              size="small"
              label={thematicLabels && thematicLabels[value]}
              color="primary"
              className={classes.chip}
              variant={selected.codes && selected.codes.includes(value) ? 'default' : 'outline'}
            />
          })}
        </Box>}
      </Grid>

      <Grid item xs={12}>
        {cachedPaper && paperId !== null && <>
          {authorsInfo && authors && authors.length !== 0 &&
          <Box display='flex' fontSize={12} fontWeight='bold' textAlign={'left'}
               style={{overflowWrap: 'anywhere'}}>Authors:</Box>}
          <Box>
            {authorsInfo && authors && authors.map((author, ind) => {
              const user = authorsInfo[author]
              return <Chip
                key={ind}
                size="small"
                label={`${user.title ? user.title : '-'} ${user.firstName} ${user.lastName}`}
                color="secondary"
                className={classes.chip}
                variant='outline'
              />
            })}
          </Box>
        </>
        }
      </Grid>

    </Grid>

  </>

})

const Circle = withStyles(styles)(({classes, children}) => {
  return <div className={classes.circle}><Box display='flex' mr={0.1}>{children}</Box></div>
})

const ParticipantInfo = withStyles(styles)(({classes, participant}) => {

  return <Grid container spacing={1}>

    {/*<Grid item xs={12} justify='center'>*/}
    {/*  <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'}>*/}
    {/*    <Box className={classes.userPhoto}>*/}
    {/*      <img src={imageFile(participant.pictureId)} alt={`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`} />*/}
    {/*    </Box>*/}
    {/*    <Box display='flex' fontSize={14} fontWeight='bold' p={1} textAlign={'left'}>*/}
    {/*      {`${participant.title?participant.title:''} ${participant.firstName} ${participant.lastName}`}*/}
    {/*    </Box>*/}
    {/*  </Box>*/}
    {/*</Grid>*/}

    <Grid item container spacing={1} alignContent='center'>
      <Grid item>
        <img className={classes.userPhoto} src={imageFile(participant.pictureId)}
             alt={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}/>
      </Grid>
      <Grid item xs={8}>
        <Grid item container direction='column'>
          <Grid item>
            <Box display='flex' fontSize={16} fontWeight='bold' textAlign={'left'} style={{overflowWrap: 'anywhere'}}>
              {`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}
            </Box>
          </Grid>
          <Grid item>
            <Box fontSize={14} fontWeight={'bold'}
                 textAlign={'left'}> {participant.profession ? participant.profession : '-'} </Box>
          </Grid>
          <Grid item>
            <Box fontSize={14} fontWeight={'bold'}
                 textAlign={'left'}> {participant.instituteOrCompany ? participant.instituteOrCompany : '-'} </Box>
          </Grid>
          {<Grid item xs={12}>
            <Box ml={-2}><IconButton href={participant.linkedinUrl} target='_blank'><LinkedInIcon/></IconButton></Box>
          </Grid>}
        </Grid>
      </Grid>
    </Grid>

    {participant.linkedinUrl && <Grid item xs={12}>
      <Box ml={-2}><IconButton href={participant.linkedinUrl} target='_blank'><LinkedInIcon/></IconButton></Box>
    </Grid>}

  </Grid>
})

const ReviewersList = withStyles(styles)(({
                                            classes,
                                            reviewers,
                                            setReviewers,
                                            calcNumbersOfReviews,
                                            papers,
                                            setPapers,
                                            selected,
                                            setSelected,
                                            thematicLabels,
                                            cachedPapers,
                                            setCachedPapers
                                          }) => {

  const [getSubmissionData, submissionData, , , , extras] = useHttp()
  const [sendDeAssignPaper, , , hasErrorAssignData] = useHttp()
  const [selectedPaper, setSelectedPaper] = useState()

  const changeSelected = (reviewer) => {
    setSelectedPaper(false)
    setSelected(reviewer)
  }

  const removePaper = (paper, e) => {

    e.stopPropagation()

    const selectedReviewerIndex = reviewers.findIndex((r) => r.id === selected.id)
    const selectedPaperIndex = selected.papers.findIndex((p) => p === paper.id)

    // remove from selected reviewer
    const newReviewers = [...reviewers]
    newReviewers[selectedReviewerIndex].papers.splice(selectedPaperIndex, 1)
    setSelected(newReviewers[selectedReviewerIndex])
    setReviewers(newReviewers)

    if (selectedPaper && selectedPaper.id === paper.id) {
      setSelectedPaper(false)
    }

    // remove selected reviewer from paper
    const newPapers = [...papers]
    const paperIndex = newPapers.findIndex((p) => p.id === paper.id)
    const reviewerIndex = newPapers[paperIndex].reviewers.findIndex((r) => r.id === selected.id)
    newPapers[paperIndex].reviewers.splice(reviewerIndex, 1)
    setPapers(newPapers)

    // calcNumbersOfReviews(newPapers)

    // update BE
    const payLoad = {
      type: 'removePaper',
      reviewer: selected.id,
      paper: paper.id
    }

    sendDeAssignPaper(api.entities.manage.deAssignReviewer(null, null, {
      reviewerId: selected.id,
      paperId: paper.id
    }, false, payLoad))
  }

  const changeSelectedPaper = (paper) => {
    if (selectedPaper && selectedPaper.id === paper.id) {
      setSelectedPaper(false)
    } else {
      setSelectedPaper(paper)
    }
  }

  useEffect(() => {
    if (hasErrorAssignData) {
      // revert
      if (hasErrorAssignData.reqData && hasErrorAssignData.reqData.type === 'removePaper') {
        const selectedReviewerIndex = reviewers.findIndex((r) => r.id === hasErrorAssignData.reqData.reviewer)
        const newReviewers = [...reviewers]
        newReviewers[selectedReviewerIndex].papers.push(hasErrorAssignData.reqData.paper)
        setReviewers(newReviewers)
      }

      // add selected reviewer to paper
      const paperIndex = papers.findIndex((p) => p.id === hasErrorAssignData.reqData.paper)
      const newPapers = [...papers]
      newPapers[paperIndex].reviewers.push(hasErrorAssignData.reqData.reviewer)
      setPapers(newPapers)

    }
    // eslint-disable-next-line
  }, [hasErrorAssignData])

  useEffect(() => {
    if (selectedPaper) {
      if (!cachedPapers.find((p) => p.id === selectedPaper.id)) {
        getSubmissionData(api.entities.manage.viewSubmission(null, null, {
          id: selectedPaper.submissionFlowId,
          answerId: selectedPaper.id
        }, false, {id: selectedPaper.submissionFlowId, answerId: selectedPaper.id}))
      }
    }
    //eslint-disable-next-line
  }, [selectedPaper])

  useEffect(() => {
    if (submissionData) {
      if (extras) {
        let newCachedPapers = [...cachedPapers]
        newCachedPapers.push({id: extras.answerId, fields: [...submissionData.fields]})
        setCachedPapers(newCachedPapers)
      }
    }
    //eslint-disable-next-line
  }, [submissionData])

  return <Grid item container spacing={2}>
    <Grid item xs={6}>

      <List className={classes.root}>
        {reviewers.map((reviewer) =>
          <ListItem button selected={selected && reviewer.id === selected.id} onClick={() => changeSelected(reviewer)}>
            <ListItemAvatar>
              <Avatar className={classes.avatarList}
                      alt={`${reviewer.title ? reviewer.title : ''} ${reviewer.firstName} ${reviewer.lastName}`}
                      src={imageFile(reviewer.pictureId)}/>
            </ListItemAvatar>
            <ListItemText
              primary={`${reviewer.title ? reviewer.title : ''} ${reviewer.firstName} ${reviewer.lastName}`}
              className={classes.reviewerName}
            />
            <ListItemSecondaryAction>
              <Circle>{reviewer.papers.length}</Circle>
            </ListItemSecondaryAction>
            <Divider variant="inset" component="li"/>
          </ListItem>
        )}
      </List>
      <Grid item container spacing={2} justify='flex-end' alignContent='center' alignItems='center'>
        <Grid item>
          <Box pt={1} style={{fontSize: 12, fontWeight: 'bold', color: palette["brown-grey"]}}> TOTAL
            REVIEWERS: {reviewers.length} </Box>
        </Grid>
      </Grid>
      <Grid item container className={classes.codeBox} justify='center' alignContent='center' alignItems='center'>
        {!selected &&
        <Grid item container justify='center' alignContent='center' alignItems='center' style={{opacity: 0.6}}>
          <Grid item container justify='center' alignContent='center' alignItems='center'>
            <Grid item>
              <PersonIcon fontSize='large'/>
            </Grid>
          </Grid>
          <Grid item style={{textAlign: 'center'}}>
            Select a reviewer
          </Grid>
        </Grid>}

        <Grid item xs={12}>
          {selected && <ParticipantInfo participant={selected}/>}
        </Grid>

        <Grid item xs={12}>
          {selected && selected.codes.length !== 0 && <Box>
            {selected.codes.map((value, fi) => {
              return <Chip
                key={fi}
                size="small"
                label={thematicLabels && thematicLabels[value]}
                color="primary"
                className={classes.chip}
              />
            })}
          </Box>}
        </Grid>

      </Grid>      
    </Grid>

    <Grid item xs={6}>

      <Grid item container justify='center' alignContent='center' alignItems='center'>
        <Grid item container justify='center' alignContent='center' alignItems='center'>
          <Grid item xs={12} className={classes.papersBox}>
            {selected && selected.papers.length !== 0 &&
            <List className={classes.paperList}>{papers.filter((p) => selected.papers?.includes(p.id)).map((paper) =>
              <ListItem button onClick={() => changeSelectedPaper(paper)}
                        selected={selectedPaper && selectedPaper.id === paper.id}>
                <ListItemText className={classes.selectedPaperTitle}
                              primary={`${paper.title}`}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={(e) => removePaper(paper, e)}>
                    <ChevronRightIcon/>
                  </IconButton>
                </ListItemSecondaryAction>
                <Divider variant="inset" component="li"/>
              </ListItem>
            )}
            </List>}

            {selected && selected.papers.length === 0 && <>
              <Grid item container justify='center' alignContent='center' alignItems='center'
                    style={{marginTop: 100, opacity: 0.8}}>
                <Grid item>
                  <AssignmentIcon fontSize='large'/>
                </Grid>
              </Grid>
              <Grid item style={{textAlign: 'center'}}>
                No papers assigned to {selected.firstName} {selected.lastName}
              </Grid>
            </>}

          </Grid>

        </Grid>
      </Grid>
      <Grid item container spacing={2} justify='flex-end' alignContent='center' alignItems='center'>
        <Grid item>
          <Box pt={1} style={{fontSize: 12, fontWeight: 'bold', color: palette["brown-grey"]}}>&nbsp; </Box>
        </Grid>
      </Grid>
      <Grid item container className={classes.codeBox} justify='center' alignContent='center' alignItems='center'>

        {selected &&
        <SubmissionPaper key={selectedPaper ? selectedPaper.id : null} paperId={selectedPaper ? selectedPaper.id : null}
                         cachedPapers={cachedPapers} thematicLabels={thematicLabels} selectedPaper={selectedPaper}
                         selected={selected}/>}

        {!selectedPaper &&
        <Grid item container justify='center' alignContent='center' alignItems='center' style={{opacity: 0.6}}>
          <Grid item container justify='center' alignContent='center' alignItems='center'>
            <Grid item>
              <AssignmentIcon fontSize='large'/>
            </Grid>
          </Grid>
          <Grid item style={{textAlign: 'center'}}>
            Selected submission
          </Grid>
        </Grid>}
      </Grid>
    </Grid>

  </Grid>

})

const Papers = withStyles(styles)(({
                                     classes,
                                     reviewers,
                                     setReviewers,
                                     papers,
                                     calcNumbersOfReviews,
                                     setPapers,
                                     selected,
                                     setSelected,
                                     cachedPapers,
                                     setCachedPapers,
                                     thematicLabels
                                   }) => {

  const [getSubmissionData, submissionData, , , , extras] = useHttp()
  const [sendAssignPaper, , , hasErrorAssignData] = useHttp()
  const [selectedPaper, setSelectedPaper] = useState()

  const addPaper = (paper, e) => {

    e.stopPropagation()

    // add to selected reviewer
    const selectedReviewerIndex = reviewers.findIndex((r) => r.id === selected.id)
    const newReviewers = [...reviewers]
    newReviewers[selectedReviewerIndex].papers.push(paper.id)
    setReviewers(newReviewers)

    if (selectedPaper && selectedPaper.id === paper.id) {
      setSelectedPaper(false)
    }

    // add selected reviewer to paper
    const paperIndex = papers.findIndex((p) => p.id === paper.id)
    const newPapers = [...papers]
    newPapers[paperIndex].reviewers.push(selected.id)
    setPapers(newPapers)

    calcNumbersOfReviews(newPapers)

    // update BE
    const payLoad = {
      type: 'addPaper',
      reviewer: selected.id,
      paper: paper.id
    }

    sendAssignPaper(api.entities.manage.assignReviewer(null, null, {
      reviewerId: selected.id,
      paperId: paper.id
    }, false, payLoad))
  }

  const changeSelectedPaper = (paper) => {
    if (selectedPaper && selectedPaper.id === paper.id) {
      setSelectedPaper(false)
    } else {
      setSelectedPaper(paper)
    }
  }

  useEffect(() => {
    if (hasErrorAssignData) {
      if (hasErrorAssignData.reqData && hasErrorAssignData.reqData.type === 'addPaper') {
        // revert
        const selectedReviewerIndex = reviewers.findIndex((r) => r.id === hasErrorAssignData.reqData.reviewer)
        const selectedPaperIndex = selected.papers.findIndex((p) => p === hasErrorAssignData.reqData.paper)
        const newReviewers = [...reviewers]
        newReviewers[selectedReviewerIndex].papers.splice(selectedPaperIndex, 1)
        setSelected(newReviewers[selectedReviewerIndex])
        setReviewers(newReviewers)

        // remove selected reviewer from paper
        const newPapers = [...papers]
        const paperIndex = newPapers.findIndex((p) => p.id === hasErrorAssignData.reqData.paper)
        const reviewerIndex = newPapers[paperIndex].reviewers.findIndex((r) => r.id === hasErrorAssignData.reqData.reviewer)
        newPapers[paperIndex].reviewers.splice(reviewerIndex, 1)
        setPapers(newPapers)
      }
    }
    //eslint-disable-next-line
  }, [hasErrorAssignData])

  useEffect(() => {
    if (selected) {
      setSelectedPaper(false)
    }
  }, [selected])

  useEffect(() => {
    if (selectedPaper) {
      if (!cachedPapers.find((p) => p.id === selectedPaper.id)) {
        getSubmissionData(api.entities.manage.viewSubmission(null, null, {
          id: selectedPaper.submissionFlowId,
          answerId: selectedPaper.id
        }, false, {id: selectedPaper.submissionFlowId, answerId: selectedPaper.id}))
      }
    }
    //eslint-disable-next-line
  }, [selectedPaper])

  useEffect(() => {
    if (submissionData) {
      if (extras) {
        let newCachedPapers = [...cachedPapers]
        newCachedPapers.push({id: extras.answerId, ...submissionData.fields})
        setCachedPapers(newCachedPapers)
      }
    }
    //eslint-disable-next-line
  }, [submissionData])

  return <>

    <Grid item xs={12}>
      <List className={classes.root}>
        {selected && papers.filter((p) => !selected.papers?.includes(p.id)).map((paper) =>
          <ListItem button onClick={() => changeSelectedPaper(paper)}
                    selected={selectedPaper && selectedPaper.id === paper.id}>
            <ListItemSecondaryAction onClick={(e) => addPaper(paper, e)} className={classes.firstAction}>
              <IconButton>
                <ChevronLeftIcon/>
              </IconButton>
            </ListItemSecondaryAction>
            <ListItemText
              className={classes.paperTitle}
              primary={`${paper.title}`}
            />
            <ListItemSecondaryAction>
              <Circle>{paper.reviewers.length}</Circle>
            </ListItemSecondaryAction>
            <Divider variant="inset" component="li"/>
          </ListItem>
        )}
      </List>
      <Grid item container spacing={2} justify='flex-end' alignContent='center' alignItems='center'>
        <Grid item>
          <Box pt={1} style={{fontSize: 12, fontWeight: 'bold', color: palette["brown-grey"]}}> TOTAL
            PAPERS: {papers.filter((p) => !selected.papers?.includes(p.id)).length} </Box>
        </Grid>
      </Grid>
    </Grid>
    <Grid item container className={classes.codeBox}>

      {selected && <SubmissionPaper selected={selected} key={selectedPaper ? selectedPaper.id : null}
                                    paperId={selectedPaper ? selectedPaper.id : null} cachedPapers={cachedPapers}
                                    thematicLabels={thematicLabels} selectedPaper={selectedPaper}/>}

      {!selectedPaper &&
      <Grid item container justify='center' alignContent='center' alignItems='center' style={{opacity: 0.6}}>
        <Grid item container justify='center' alignContent='center' alignItems='center'>
          <Grid item>
            <AssignmentIcon fontSize='large'/>
          </Grid>
        </Grid>
        <Grid item style={{textAlign: 'center'}}>
          Submission preview
        </Grid>
      </Grid>}

    </Grid>    
  </>
})

const AssignReviewers = withStyles(styles)(({classes}) => {

  const {enqueueSnackbar} = useSnackbar()

  const [getThematicCodes, thematicData] = useHttp()
  const [getData, data] = useHttp()
  //eslint-disable-next-line
  const [sendData, dataSend, isLoading, dataSendError] = useHttp()
  const [reqReset, dataReset, isLoadingReset, resetError] = useHttp()
  const [reqAssign, dataAssign, isLoadingAssign, assignError] = useHttp()

  // States
  const [isPublished, setIsPublished] = useState()
  const [papers, setPapers] = useState()
  const [reviewers, setReviewers] = useState()
  const [selected, setSelected] = useState()
  const [thematicLabels, setThematicLabels] = useState()
  const [reviewerQuery, setReviewerQuery] = useState('')
  const [paperQuery, setPaperQuery] = useState('')
  const [thematicList, setThematicList] = useState()
  const [cachedPapers, setCachedPapers] = useState([])
  const [value, setValue] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [numberOfReviewers, setNumberOfReviewers] = useState('none')
  const [numberOfReviews, setNumberOfReviews] = useState([])
  const [numberOfSubmissions, setNumberOfSubmissions] = useState('All')
  const [numbersOfSubmissions, setNumbersOfSubmissions] = useState([])
  const [reviewersThematicCodes, setReviewersThematicCodes] = useState([])
  //eslint-disable-next-line
  const [reviewersThematicCodesInput, setReviewersThematicCodesInput] = useState()
  const [resetOpen, setResetOpen] = useState()
  const [assignOpen, setAssignOpen] = useState()
  const [maxReviewersPerPaper, setMaxReviewersPerPaper] = useState(2)
  const [maxPapersPerReviewer, setMaxPapersPerReviewer] = useState(5)
  const [buckets, setBuckets] = useState([])
  const [weights, setWeights] = useState([])
  const {eventId} = useParams()

  useEffect(() => {
    getThematicCodes(api.entities.manage.getThematicCodes(null, null, {id: eventId}))
    getData(api.entities.manage.getAssignedReviewers(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data) {
      setReviewers(data.reviewers)
      setPapers(data.papers)
      setSelected(data.reviewers[0] ? data.reviewers[0] : false)
      setIsPublished(data.published)
    }
  }, [data])

  useEffect(() => {
    if (thematicData) {
      const labels = {}
      const arrLabels = []
      const bucketNames = []
      const weights = []
      thematicData.lists.forEach((bucket) => {
        bucketNames.push(bucket.name)
        weights.push({
          id: bucket.id,
          value: 1
        })
        bucket.codes.forEach((code => {
          labels[code.id] = code.label
          arrLabels.push({
            code: code.id,
            label: code.label
          })
        }))
      })
      setThematicLabels(labels)
      setThematicList(arrLabels)
      setBuckets(bucketNames)
      setWeights(weights)
    }
  }, [thematicData])

  // if (!thematicCodesData) return <> </>
  const filterCodes = (papers) => {
    if (value.length === 0) return filterPapers(papers)
    const filteredPapers = []
    papers.forEach((paper) => {
      paper.codes.forEach((code) => {
        if (value.find((c) => c.code === code)) {
          filteredPapers.push(paper)
        }
      })
    })
    return filterPapers(filteredPapers)
  }

  const filterCodesReviewers = (reviewers) => {
    if (reviewersThematicCodes.length === 0) return filterReviewers(reviewers)
    const filteredPapers = []
    reviewers.forEach((r) => {
      r.codes.forEach((code) => {
        if (reviewersThematicCodes.find((c) => c.code === code)) {
          if (!filteredPapers.find((p) => p.id === r.id)) {
            filteredPapers.push(r)
          }
        }
      })
    })
    return filterReviewers(filteredPapers)
  }

  const calcNumbersOfReviews = (papers) => {
    if (!papers) return []
    const numbers = {}
    papers.forEach((p) => {
      numbers[p.reviewers.length] = ''
    })

    const newList = Object.keys(numbers)

    if (!newList.includes(numberOfReviewers)) {
      setNumberOfReviewers('none')
    }

    setNumberOfReviews(newList)
  }

  const filterReviewers = (reviewers) => {
    if (!numberOfSubmissions || numberOfSubmissions === 'All') return reviewers
    return reviewers.filter((r) => r.papers.length === +numberOfSubmissions)
  }

  const calcNumbersOfPapers = (reviewers) => {
    if (!reviewers) return []
    const numbers = {}
    reviewers.forEach((r) => {
      numbers[r.papers.length] = ''
    })

    const newList = Object.keys(numbers)

    if (!newList.includes(numberOfSubmissions)) {
      setNumbersOfSubmissions('All')
    }

    setNumbersOfSubmissions(newList)
  }

  const filterPapers = (papers) => {
    if (!numberOfReviewers || numberOfReviewers === 'none') return papers
    return papers.filter((p) => p.reviewers.length === +numberOfReviewers)
  }

  useEffect(() => {
    calcNumbersOfReviews(papers)
    //eslint-disable-next-line
  }, [reviewers])

  useEffect(() => {
    calcNumbersOfPapers(reviewers)
    //eslint-disable-next-line
  }, [papers])

  const doPublishUnpublish = () => {
    if (isPublished) {
      setIsPublished(false)
      sendData(api.entities.manage.unpublishAssignments(null, null, {id: eventId}))
    } else {
      setIsPublished(true)
      sendData(api.entities.manage.publishAssignments(null, null, {id: eventId}))
    }
  }

  useEffect(() => {
    if (dataSendError) {
      setIsPublished(!isPublished)
    }
    //eslint-disable-next-line
  }, [dataSendError])

  const resetAssignments = () => {
    reqReset(api.entities.manage.resetAssignments(null, null, {id: eventId}))
  }

  useEffect(() => {
    if (dataReset) {
      if (dataReset.status === 204) {
        getData(api.entities.manage.getAssignedReviewers(null, null, {id: eventId}))
        enqueueSnackbar(`Successfully reset assignments`, {variant: 'success'})
        setTimeout(setResetOpen(false), 1000)
      }
    }
    // eslint-disable-next-line
  }, [dataReset])

  useEffect(() => {
    if (resetError) {
      enqueueSnackbar(`There was an error when trying to reset assignments. Please try again.`, {variant: 'error'})
      setResetOpen(false)
    }
    // eslint-disable-next-line
  }, [resetError])

  const handleResetClose = (reset) => {
    if (reset === true) {
      resetAssignments()
    }
  }

  const autoAssign = () => {
    const payLoad = {
      maxPapersPerReviewer: parseInt(maxPapersPerReviewer),
      maxReviewersPerPaper: parseInt(maxReviewersPerPaper),
      weights
    }
    reqAssign(api.entities.manage.autoAssign(payLoad, null, {id: eventId}))
  }

  useEffect(() => {
    if (dataAssign) {
      if (dataAssign.status === 204) {
        getData(api.entities.manage.getAssignedReviewers(null, null, {id: eventId}))
        enqueueSnackbar(`Successfully assigned reviewers`, {variant: 'success'})
        setTimeout(setAssignOpen(false), 1000)
      }
    }
    // eslint-disable-next-line
  }, [dataAssign])

  useEffect(() => {
    if (assignError) {
      enqueueSnackbar(`There was an error when trying to assign reviewers. Please try again.`, {variant: 'error'})
      setAssignOpen(false)
    }
    // eslint-disable-next-line
  }, [assignError])

  const handleAssignClose = (assign) => {
    if (assign === true) {
      autoAssign()
      // automatic assignment
    }
  }

  const changeMaxReviewersPerPaper = (e) => {
    const value = e.target.value
    setMaxReviewersPerPaper(value)
  }

  const changeMaxPapersPerReviewer = (e) => {
    const value = e.target.value
    setMaxPapersPerReviewer(value)
  }

  const handleWeightChange = (i, newValue) => {
    const currentWeights = [...weights]
    currentWeights[i].value = (newValue === '' ? '' : Number(newValue))
    if (currentWeights[i].value > 1) {
      currentWeights[i].value = 1
    }
    if (currentWeights[i].value < 0) {
      currentWeights[i].value = 0
    }
    setWeights(currentWeights)
  }

  if (!reviewers) {
    return <> </>
  }

  return <>

    <Dialog
      open={assignOpen}
      onClose={() => {
        setAssignOpen(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={isLoadingAssign}
      disableEscapeKeyDown={isLoadingAssign}
    ><Box p={3} pt={1}>
      <Box p={3}>
        <Grid item xs={12}>
          <h3>Assignment limits</h3>
        </Grid>
        <Grid item container spacing={2} style={{minWidth: 400}}>
          <Grid item xs={12}>
            <FormControl style={{width: '100%'}}>
              <Select variant='outlined' labelId='optionsLabel' displayEmpty label='Max reviewers per paper' fullWidth
                      disabled={isLoadingAssign} value={maxReviewersPerPaper} onChange={changeMaxReviewersPerPaper}>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>
              <InputLabel variant='outlined'>Max reviewers per paper</InputLabel>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField fullWidth label="Max papers per reviewer" variant="outlined" value={maxPapersPerReviewer}
                       onChange={changeMaxPapersPerReviewer}/>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12}>
            <h3>Thematic code weights</h3>
          </Grid>
          {
            weights&&weights.map((weight, i) => {
              return <Grid item container spacing={2} style={{padding: theme.spacing(2)}}>

                <Grid item xs={12}>
                  {buckets[i]}
                </Grid>
                <Grid item xs>
                  <Slider
                    value={weight.value?weight.value:0}
                    onChange={(e,value)=>handleWeightChange(i,value)}
                    aria-labelledby="input-slider"
                    step={0.01}
                    min={0}
                    max={1}
                  />
                </Grid>
                <Grid item>
                  <Input
                    className={classes.input}
                    value={weight.value}
                    margin="dense"
                    onChange={(e)=>handleWeightChange(i,e.target.value)}
                    // onBlur={handleBlur}
                    inputProps={{
                      step: 0.01,
                      min: 0,
                      max: 1,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
              </Grid>
            })
          }

        </Grid>


      </Box>
      <DialogActions style={{marginTop: 16}}>
        <Grid item container spacing={2} justify='center' alignContent='center' alignItems='center'>
          <Grid item>
            <Button onClick={() => setAssignOpen(false)} variant='outlined' color='primary' disabled={isLoadingAssign}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => {
              handleAssignClose(true)
            }} autoFocus variant='contained' color='primary' disabled={isLoadingAssign}>
              Auto-assign
            </Button>
          </Grid>
        </Grid>

      </DialogActions>
    </Box>
    </Dialog>

    <Dialog
      open={resetOpen}
      onClose={() => {
        setResetOpen(false)
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      disableBackdropClick={isLoadingReset}
      disableEscapeKeyDown={isLoadingReset}
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Reset assignments</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to reset all of the assignments?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={() => setResetOpen(false)} size='small' variant='outlined' color='primary'
                  disabled={isLoadingReset}>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={() => {
            handleResetClose(true)
          }} size='small' autoFocus variant='contained' color='primary' disabled={isLoadingReset}>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>

    <Fade in={true} timeout={TIME_FADE_IN}>
      <Grid container className={classes.reviewsContainer} spacing={2}>

        <Grid item container justify='space-between' alignContent='space-between' alignItems='space-between'>
          <Grid item xs={8}>
            <Typography component='h2' variant='h5' className={classes.title}>
              Reviewer assignments
            </Typography>
          </Grid>
          <Grid item>
            <Button variant='contained' sizeLarge color={isPublished ? 'primary' : 'secondary'}
                    onClick={doPublishUnpublish}> {isPublished ? 'Unpublish' : 'Publish'} </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={4}>
            <Grid item xs={12}>
              {thematicList && <Autocomplete
                style={{width: 'auto'}}
                value={reviewersThematicCodes}
                onChange={(event, newValue) => {
                  setReviewersThematicCodes(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setReviewersThematicCodesInput(newInputValue);
                }}
                className={classes.autocomplete}
                fullWidth
                multiple
                id="thematic-codes"
                options={thematicList}
                getOptionLabel={(option) => option.label}
                defaultValue={[]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Filter reviewers by thematic code"
                    placeholder="Thematic code"
                  />
                )}
              />}
            </Grid>
            <Grid item container alignContent='flex-end' alignItems='center' style={{marginTop: 8}} spacing={2}>
              <Grid item xs={8}>
                <TextField fullWidth label="Search reviewers" variant="outlined"
                           onChange={(e) => setReviewerQuery(e.target.value)}/>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="outlined" style={{width: '100%'}}>
                  <InputLabel># of Submissions</InputLabel>
                  <Select label="# of Submissions"
                          variant='outlined'
                          displayEmpty
                          fullWidth
                          value={numberOfSubmissions}
                          onChange={(e) => setNumberOfSubmissions(e.target.value)}>
                    <MenuItem value={'All'} key={0}> All </MenuItem>
                    {numbersOfSubmissions.map((p) => <MenuItem value={p} key={p}> {p} </MenuItem>)}
                  </Select>

                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item container spacing={2} justify='center' alignContent='center' alignItems='center'>
              <Grid item>
                <Button variant='contained' color='primary' onClick={() => {
                  setAssignOpen(true)
                }}> Auto-assign </Button>
              </Grid>
              <Grid item>
                <Button variant='contained' color='secondary' onClick={() => {
                  setResetOpen(true)
                }}> Reset assignments </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                {thematicList && <Autocomplete
                  style={{width: 'auto'}}
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                  }}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);

                  }}
                  className={classes.autocomplete}
                  fullWidth
                  multiple
                  id="thematic-codes"
                  options={thematicList}
                  getOptionLabel={(option) => option.label}
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Filter submissions by thematic code"
                      placeholder="Thematic code"
                    />
                  )}
                />}
              </Grid>
              <Grid item xs={8}>
                <TextField fullWidth label="Search submissions" variant="outlined"
                           onChange={(e) => setPaperQuery(e.target.value)}/>
              </Grid>
              <Grid item xs={4}>

                <FormControl variant="outlined" style={{width: '100%'}}>
                  <InputLabel># of Reviewers</InputLabel>
                  <Select label="# of Reviewers"
                          variant='outlined'
                          displayEmpty
                          fullWidth
                          value={numberOfReviewers}
                          onChange={(e) => setNumberOfReviewers(e.target.value)}>
                    <MenuItem value={'none'} key={0}> - </MenuItem>
                    {numberOfReviews.map((p) => <MenuItem value={p} key={p}> {p} </MenuItem>)}
                  </Select>

                </FormControl>

              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={2} className={classes.component}>
          <Grid item xs={8}>
            <Grid item container spacing={2}>
              <ReviewersList cachedPapers={cachedPapers} setCachedPapers={setCachedPapers}
                             reviewers={reviewerQuery !== '' ? filterList(reviewerQuery, ['firstName', 'lastName'], filterCodesReviewers(reviewers)).filter((r) => r.matched) : filterCodesReviewers(reviewers)}
                             setReviewers={setReviewers} papers={papers} setPapers={setPapers} selected={selected}
                             setSelected={setSelected} thematicLabels={thematicLabels}
                             calcNumbersOfReviews={calcNumbersOfReviews}/>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid item container>
              <Papers cachedPapers={cachedPapers} setCachedPapers={setCachedPapers} setPapers={setPapers}
                      papers={paperQuery !== '' ? filterList(paperQuery, ['title'], filterCodes(papers)).filter((r) => r.matched) : filterCodes(papers)}
                      reviewers={reviewers} setReviewers={setReviewers} selected={selected} setSelected={setSelected}
                      thematicLabels={thematicLabels} calcNumbersOfReviews={calcNumbersOfReviews}/>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </Fade>
  </>
})

export default withRouter(AssignReviewers)
