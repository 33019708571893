import React, {forwardRef, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {
  IconCalendarBorderDark,
  IconClockBorderDark, IconClockBorderLight, IconFileBorderLight, IconSpeakersBorderLight,
} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, FieldArray, Form, Formik} from 'formik'
import {Select, Switch, TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {
  TIME_DURATION,
  SIZE_COVER,
  FILETYPES_ACCEPTED,
  SIZE_LENGTH_DESCRIPTION,
  STREAM_OPTIONS,
  STREAM_OPTIONS_POSTER,
  VIEWER_TYPES, SESSION_TYPES, STREAM_VIDEO_TYPES
} from 'config'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import {useHttp} from 'api/core'
import {DatePicker, TimePicker} from '@material-ui/pickers'
import InputAdornment from '@material-ui/core/InputAdornment'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import api from 'api'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {FormHelperText} from '@material-ui/core'
import {addMilliseconds, differenceInMilliseconds, parseISO} from 'date-fns'
import clsx from 'clsx'
import LiveTvIcon from '@material-ui/icons/LiveTv'
import EventNoteIcon from '@material-ui/icons/EventNote'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import FindUserDialog from 'components/core/FindUserDialog'
import ProfileView from 'components/core/ProfileView'
import {useSnackbar} from 'notistack'
import EllipsisText from 'react-ellipsis-text'
import {TextField as MUITextField} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import moment from "moment";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    minHeight: 800
  },
  newSession: {},
  formControl: {
    width: '100%'
  },
  row: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(4)
  },
  descriptionBox: {
    '& textarea': {
      minHeight: '84px',
    }
  },
  participantsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    marginTop: theme.spacing(1)
  },
  participantsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'auto',
    marginTop: theme.spacing(1)
  },
  presentationsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
    marginTop: theme.spacing(1)
  },
  presentationsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    minHeight: '135px',
    maxheight: '151px',
    overflowY: 'auto',
    marginTop: theme.spacing(1)
  },
  chip: {
    margin: theme.spacing(1),
    // maxWidth: '135px'
  },
  chipPresentation: {
    margin: theme.spacing(1)
  },
  tab: {
    width: '100%',
    color: 'white',
    backgroundColor: 'rgba(255, 255, 255, 0.17)',
    padding: theme.spacing(2)
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    '&:focus': {
      backgroundColor: palette.aquamarine
    }
  },
  disabled: {
    cursor: 'default',
    opacity: 0.6
  },
  tabLabel: {
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  btns: {
    textAlign: 'center'
  },
  divider: {
    backgroundColor: 'white',
    marginBottom: theme.spacing(2)
  },
  attendeesDialog: {
    padding: theme.spacing(2),
    overflow: 'none'
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },  
})

const validationSchema = Yup.object().shape({
  // ordinal: Yup.string().required('This field is required'),
  title: Yup.string().required('This field is required'),
  type: Yup.string().required('This field is required'),
  // location: Yup.string().required('This field is required'),
  // description: Yup.string().required('This field is required'),
  startDate: Yup.string().required('This field is required'),
  // endDate: Yup.string().required('This field is required'),
})

const UploadFile = withStyles(styles)(({setFormData, formData, uploadFiles}) => {

  const onDrop = (acceptedFiles) => {
    if (!uploadFiles) return false
    let newFiles = formData.uploadedFiles ? [...formData.uploadedFiles] : []
    newFiles.push(...acceptedFiles)
    setFormData({...formData, uploadedFiles: newFiles})
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  return <RootRef rootRef={ref}>
    <Box {...rootProps}>
      <input name='file' type='file' {...getInputProps()} accept={`${FILETYPES_ACCEPTED.join(',')}`}
             disabled={!uploadFiles}/>
      <Button size='smaller' variant='outlined' colour='white' disabled={!uploadFiles}>Add Files</Button>
    </Box>
  </RootRef>
})

const UploadBackground = withStyles(styles)(({setFormData, formData, uploadFiles}) => {

  const onDrop = (acceptedFiles) => {
    if (!uploadFiles) return false
    setFormData({...formData, uploadedBackground: acceptedFiles[0]})
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  return <RootRef rootRef={ref}>
    <Box {...rootProps}>
      <input name='file' type='file' {...getInputProps()} accept={`${FILETYPES_ACCEPTED.join(',')}`}
             disabled={!uploadFiles}/>
      <Button size='smaller' variant='outlined' colour='white' disabled={!uploadFiles}> Add image</Button>
    </Box>
  </RootRef>
})

const SessionEditNew = withStyles(styles)(({classes, session, editingDay, editingStartEnd, uploadFiles, eventInfo, rooms, roomId, tracks, trackId, totalSessions, handleClose, eventId, setSuccessful, participants, presentations = [], sessionTypes,selectedTimezone, ...props}) => {
  const [formData, setFormData] = useState({
    ...session,
    presentations: session ? session.presentations : [],
    participants: session ? session.participants : [],
    deleteBackground: false,
    deletedFiles: [],
    endDate: session ? session.endDate : editingDay ? addMilliseconds(parseISO(editingDay), 30 * 60 * 1000) : editingStartEnd ? editingStartEnd.end : addMilliseconds(parseISO(eventInfo.startDate), 30 * 60 * 1000),
    startDate: session ? session.startDate : editingDay ? editingDay : editingStartEnd ? editingStartEnd.start : eventInfo.startDate,
    videoStreamType: session && session.videoStreamType !== null ? session.videoStreamType : 0
  })

  const [formSave, setFormSave] = useState()
  const [selectCoach, setSelectCoach] = useState(false)
  const [coach, setCoach] = useState()
  const [openAttendees, setOpenAttendees] = useState(false)
  const [openClearChat, setOpenClearChat] = useState(false)
  const [openClearEmotion, setOpenClearEmotion] = useState(false)

  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [clearChatRequest, clearChatData] = useHttp()
  const [clearEmotionRequest, clearEmotionData] = useHttp()

  const [tab, setTab] = useState(0)

  const {enqueueSnackbar} = useSnackbar()

  useEffect(() => {
    if (putData) {
      handleClose(true)
      if (setSuccessful) setSuccessful(true)
    }
    // eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    moment.tz.setDefault(selectedTimezone);
    // console.log(selectedTimezone);
    //eslint-disable-next-line
  }, [eventInfo]);

  useEffect(() => {
    if (formSave) {

      const {ordinal,code, title, type, location, roomId, trackId, description, startDate, duration, url, recordingUrl, deleteBackground, deletedFiles, isPrivate, uploadedBackground, uploadedFiles, videoStreamType, chairId, rtmps, recordVideoStream, playRecording,sessionTypeId,urlJson} = formSave
      const participants = formData.participants.map((participant) => participant.id)
      const presentations = formData.presentations.map((presentation) => presentation.id)

      // WARNING: If payload changes, change in Schedule.js too
      let sessionPayload = {
        order: session ? ordinal : totalSessions + 1,
        code,
        title,
        type,
        location,
        roomId,
        trackId,
        description,
        startDate,
        participants,
        presentations,
        url: videoStreamType <= 1 ? '' : url,
        recordingUrl,
        chairId: chairId ? chairId === '-' ? null : chairId : null,
        videoStreamType,
        rtmps,
        isPrivate,
        recordVideoStream,
        playRecording,
        coachEmail: formData.coach ? formData.coach.email : null,
        sessionTypeId,
        urlJson
      }

      // sessionPayload.endDate = addMilliseconds((typeof formSave.startDate === 'string' || formSave.startDate instanceof String) ? parseISO(formSave.startDate) : formSave.startDate, duration, videoStreamType !== 0)
      sessionPayload.endDate = moment(formSave.startDate).add(Math.floor(formSave.duration / 60000), "minutes");
      
      if (deleteBackground)
      {
        sessionPayload.deleteBackground = deleteBackground
      }

      if (deletedFiles) {
        sessionPayload.deletedFiles = deletedFiles
      }

      if (!session) {
        sessionPayload.eventId = eventId
      }

      sessionPayload.roomId = roomId === '-' ? null : roomId
      sessionPayload.trackId = trackId === '-' ? null : trackId

      let formDataObj = new FormData()

      if (uploadedFiles) {
        uploadedFiles.forEach((file) => formDataObj.append('files', file))
      }

      if (uploadedBackground) {
        formDataObj.append('background', uploadedBackground)
      }

      const blob = new Blob([JSON.stringify(sessionPayload)], {type: 'application/json'});
      formDataObj.append('session', blob)

      // If existing session
      if (session) {
        sendRequest(api.entities.sessions.update(formDataObj, null, {id: session.id}, true))
      } else { // else add new session
        sendRequest(api.entities.sessions.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
    // eslint-disable-next-line
  }, [eventId, formData.participants, formSave, sendRequest, session])

  const addParticipant = (participantObj, setFieldValue) => {
    let newParticipants = [...formData.participants]

    if (newParticipants.findIndex((participant) => participant.id === participantObj.id) === -1) {
      newParticipants.push(participantObj)
      setFormData({...formData, participants: newParticipants})
      setFieldValue('participants', newParticipants)
    }
  }

  const deleteParticipant = (pos, setFieldValue, values) => {
    let newParticipants = [...formData.participants]

    if (newParticipants.length === 0 || formData.participants[pos].id === values.chairId) {
      setFieldValue('chairId', '-')
    }

    newParticipants.splice(pos, 1)
    setFormData({...formData, participants: newParticipants})
    setFieldValue('participants', newParticipants)

  }

  const deleteFile = (pos, setFieldValue) => {
    let newFiles = [...formData.files]
    let newDeletedFiles = formData.deletedFiles ? [...formData.deletedFiles] : []
    newDeletedFiles.push(formData.files[pos].id)
    newFiles.splice(pos, 1)
    setFormData({...formData, files: newFiles, deletedFiles: newDeletedFiles})
    setFieldValue('files', newFiles)
  }

  const deleteUploadedFile = (pos) => {
    let newFiles = [...formData.uploadedFiles]
    newFiles.splice(pos, 1)
    setFormData({...formData, uploadedFiles: newFiles, deleteBackground: true})
  }

  const deleteBackground = () => {
    setFormData({...formData, deleteBackground: true})
  }

  const deleteUploadedBackground = () => {
    const {uploadedBackground, ...restData} = formData
    setFormData(restData)
  }

  const addPresentation = (presentationObj) => {
    let newPresentations = [...formData.presentations]

    if (newPresentations.findIndex((presentation) => presentation.id === presentationObj.id) === -1) {
      newPresentations.push(presentationObj)
      setFormData({...formData, presentations: newPresentations})
    }
  }

  const deletePresentation = (pos) => {
    let newPresentations = [...formData.presentations]
    newPresentations.splice(pos, 1)
    setFormData({...formData, presentations: newPresentations})
  }

  const handleDeleteCoach = () => {
    setFormData({...formData, coach: null})
  }

  const handleCoach = () => {
    setSelectCoach(true)
  }

  const associateCoach = () => {
    setFormData({
      ...formData,
      coach: {
        email: coach.email,
        title: coach.title,
        firstName: coach.firstName,
        lastName: coach.lastName,
        pictureId: coach.image
      }
    })
    setCoach()
    setSelectCoach(false)
  }

  const handleCloseAttendees = () => {
    setOpenAttendees(false)
  }

  const handleClearChatClose = (clear) => {
    if (clear===true) {
      clearChatRequest(api.entities.sessions.clearChat(null,null,{id:session.id}))
    }
    setOpenClearChat(false)
  }

  const handleClearEmotionClose = (clear) => {
    if (clear===true) {
      clearEmotionRequest(api.entities.sessions.clearEmotion(null,null,{id:session.id}))
    }
    setOpenClearEmotion(false)
  }

  useEffect(()=>{
    if (clearChatData&&clearChatData.status) {
      if (clearChatData.status===204) {
        enqueueSnackbar('Successfully cleared chat', {variant: 'success'})
      }
    }
    // eslint-disable-next-line
  },[clearChatData])

  useEffect(()=>{
    if (clearEmotionData&&clearEmotionData.status) {
      if (clearEmotionData.status===204) {
        enqueueSnackbar('Successfully cleared emotion', {variant: 'success'})
      }
    }
    // eslint-disable-next-line
  },[clearEmotionData])

  const isSubmitting = (isLoading && !hasError)
  const urlJson = session&&session.urlJson?JSON.parse(session.urlJson):{}

  return <Box className={classes.newSession}><ThemeProvider theme={theme}>

    {openClearChat&&<>
      <Dialog
        open={openClearChat}
        onClose={handleClearChatClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title">Clear chat</DialogTitle>
        <DialogContent>
          <Box p={1} pl={0} pb={2}>
            Are you sure you want to clear this session's chat?
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button onClick={()=>handleClearChatClose()}  size='small' variant='outlined' color='primary'>
              No
            </Button>
          </Box>
          <Box p={1}>
            <Button onClick={()=>{handleClearChatClose(true)}}  size='small' autoFocus color='primary'>
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Box>
      </Dialog>
    </>}

    {openClearEmotion&&<>
      <Dialog
        open={openClearEmotion}
        onClose={handleClearEmotionClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      ><Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title">Clear emotion</DialogTitle>
        <DialogContent>
          <Box p={1} pl={0} pb={2}>
            Are you sure you want to clear this session's emotion counters?
          </Box>
        </DialogContent>
        <DialogActions>
          <Box p={1}>
            <Button onClick={()=>handleClearEmotionClose()}  size='small' variant='outlined' color='primary'>
              No
            </Button>
          </Box>
          <Box p={1}>
            <Button onClick={()=>{handleClearEmotionClose(true)}}  size='small' autoFocus color='primary'>
              Yes
            </Button>
          </Box>
        </DialogActions>
      </Box>
      </Dialog>
    </>}

    <Formik
      initialValues={{
        ordinal: '',
        code: null,
        title: '',
        type: 1,
        location: '',
        description: 'TBD',
        searchByName: '',
        searchByNameSpeaker: '',
        url: '',
        recordingUrl: '',
        recordVideoStream: false,
        rtmps: [],
        playRecording: false,
        isPrivate: false,
        sessionTypeId: '',
        ...formData,
        duration: session ? differenceInMilliseconds(parseISO(formData.endDate), parseISO(formData.startDate)) : editingStartEnd ? differenceInMilliseconds(formData.endDate, formData.startDate) : 30 * 60 * 1000,
        roomId: session ? session.room ? session.room.id : roomId ? roomId : '-' : roomId ? roomId : '-',
        trackId: session ? session.track ? session.track.id : trackId ? trackId : '-' : trackId ? trackId : '-',
        chairId: session ? session.chairId ? session.chairId : '-' : '-',
        passcode: urlJson.passcode?urlJson.passcode:'',
        invitation: urlJson.invitation?urlJson.invitation:'',
        showURL: !!urlJson.showURL
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {

        // Separate not needed values from form data
        const {passcode, invitation, showURL, ...formValues} = values

        // Add needed values
        const newValues = {...formData, ...formValues, deletedFiles: formData.deletedFiles}

        // JSON urlJson fields
        newValues.urlJson = JSON.stringify({
          passcode,
          invitation,
          showURL
        })

        let errors = false

        // If we're streaming we need to have a chair or a coach
        const hasChair = (values.chairId !== null && values.chairId !== '-')
        const hasCoach = !!formData.coach
        const hasEither = hasChair || hasCoach

        if ((values.videoStreamType === 1 || values.videoStreamType === 7) && !hasEither) {
          errors = true
          enqueueSnackbar('Cannot have an online session without a chair or coach present.', {variant: 'error'})
        }

        if (!errors) setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, touched, dirty}) => {

        // useEffect(()=>{
        //   if (values.videoStreamType===1) {
        //     setFieldValue('type', VIEWER_TYPES.ONLINE)
        //   }
        //   if (values.videoStreamType===7) {
        //     setFieldValue('type',VIEWER_TYPES.WORKSHOP)
        //   }
        //   //eslint-disable-next-line
        // },[values.videoStreamType])

        useEffect(() => {
          if (dirty&&(values.type === VIEWER_TYPES.SOCIAL || values.type === VIEWER_TYPES.BREAK || values.type === VIEWER_TYPES.POSTER)) {
            setFieldValue('videoStreamType', 0)
          }
          //eslint-disable-next-line
        }, [values.type])

        return <>
          <Form className={classes.form}>
            <FindUserDialog open={selectCoach && !coach} setOpenAssociate={setSelectCoach} setUser={setCoach}/>
            {coach && <ProfileView open={!!coach} setUser={setCoach} user={coach} associateUser={associateCoach}/>}


            <Box display='flex' flexDirection='row'>
              <Grid container className={classes.container}>

                <Grid item container spacing={1} className={classes.row}>
                  <Typography variant='h5'
                              component='h1'> {session ? 'Session Information' : 'New Session'}</Typography>
                </Grid>

                <Grid item container spacing={1} className={classes.Tabs}>

                  <Grid item xs>
                    <Chip size='medium' className={clsx(classes.tab, tab === 0 && classes.activeTab)} onClick={() => {
                      setTab(0)
                    }} label={<Box className={classes.tabLabel}><EventNoteIcon/> &nbsp; Session </Box>}
                          color={'secondary'}/>
                  </Grid>

                  <Grid item xs>
                    <Chip size='medium' className={clsx(classes.tab, tab === 2 && classes.activeTab)} onClick={() => {
                      setTab(2)
                    }} label={<Box className={classes.tabLabel}><IconSpeakersBorderLight/> &nbsp; Speakers </Box>}
                          color={'secondary'}/>
                  </Grid>

                  <Grid item xs>
                    <Chip size='medium'
                          className={clsx(classes.tab, tab === 3 && classes.activeTab)}
                          onClick={() => {
                            // if (eventInfo.upgraded === false) return false
                            setTab(3)
                          }}
                          label={<Box className={classes.tabLabel}><IconClockBorderLight/> &nbsp; Presentations </Box>}
                          color={'primary'}/>
                  </Grid>

                  <Grid item xs>
                    <Chip size='medium' className={clsx(classes.tab, tab === 4 && classes.activeTab)} onClick={() => {
                      setTab(4)
                    }} label={<Box className={classes.tabLabel}><IconFileBorderLight/> &nbsp; Files </Box>}
                          color={'primary'}/>
                  </Grid>

                  <Grid item xs>
                    <Chip size='medium' className={clsx(classes.tab, tab === 1 && classes.activeTab)}
                          disabled={(values.type === VIEWER_TYPES.BREAK || values.type === VIEWER_TYPES.SOCIAL)}
                          onClick={() => {
                            setTab(1)
                          }} label={<Box className={classes.tabLabel}><LiveTvIcon/> &nbsp; Live options </Box>}
                          color={'secondary'}/>
                  </Grid>                  
                </Grid>

                {tab === 0 && <>

                  <Grid item container spacing={1} className={classes.row}>
                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Details </Typography>
                    </Grid>

                    {/*<Grid item xs={3} sm={3} md={2}>*/}
                    {/*  <Field type='text' name='ordinal' component={TextField} label='Order' variant='filled'*/}
                    {/*         fullWidth disabled={isSubmitting}/>*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={3}>
                      <Field type='text' name='code' component={TextField} label='Session Code' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                      <Field type='text' name='title' component={TextField} label='Session Title' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>

                    <Grid item xs={12} sm={3}>

                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='sessionType' displayEmpty name='type' component={Select}
                               label='Viewer type'
                               variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          {SESSION_TYPES.map((session, i) => {
                            return <MenuItem value={session.id} key={i}>{session.option}</MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled'>Viewer type</InputLabel>
                        {errors && errors.type && touched.type &&
                        <FormHelperText error variant='filled'> {errors.type} </FormHelperText>}
                      </FormControl>

                      {/*<Autocomplete*/}
                      {/*  id="combo-box-demo"*/}
                      {/*  freeSolo*/}
                      {/*  options={sessionTypes}*/}
                      {/*  getOptionLabel={option => option}*/}
                      {/*  value={values.type}*/}
                      {/*  onChange={(event, newValue) => {*/}
                      {/*    setFieldValue('type', newValue)*/}
                      {/*    // if (newValue === VIEWER_TYPES.WORKSHOP) {*/}
                      {/*    //   setFieldValue('videoStreamType', VIEWER_TYPES.WORKSHOP)*/}
                      {/*    // }*/}
                      {/*  }}*/}
                      {/*  renderInput={params => (*/}
                      {/*    <Field type='text' name='type' component={TextField} {...params} label='Session Type'*/}
                      {/*           variant='filled' fullWidth*/}
                      {/*           disabled={isSubmitting}/>*/}
                      {/*  )}*/}
                      {/*/>*/}

                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='roomLabel' displayEmpty name='roomId' component={Select}
                               label='Room'
                               variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          <MenuItem value={'-'} key={-1}>None</MenuItem>
                          {rooms.filter((r) => r.id >= 0).map((room, i) => {
                            return <MenuItem value={room.id} key={i}>{room.name}</MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled'>Room</InputLabel>
                        {errors && errors.roomId && touched.roomId &&
                        <FormHelperText error variant='filled'> {errors.roomId} </FormHelperText>}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='trackLabel' displayEmpty name='trackId' component={Select}
                               label='Track'
                               variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          <MenuItem value={'-'} key={-1}>None</MenuItem>
                          {tracks.filter((r) => r.id >= 0).map((track, i) => {
                            return <MenuItem value={track.id} key={i}>{track.name} <Box ml={2} style={{
                              backgroundColor: track.color,
                              width: '20px',
                              height: '20px',
                              borderRadius:'4px',
                              float: 'right'
                            }} display='flex' alignItems='flex-end'/></MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled'>Track</InputLabel>
                        {errors && errors.trackId && touched.trackId &&
                        <FormHelperText error variant='filled'> {errors.trackId} </FormHelperText>}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='sessionTypeLabel' displayEmpty name='sessionTypeId' component={Select}
                               label='Session Type'
                               variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          <MenuItem value={null} key={-1}>None</MenuItem>
                          {sessionTypes.filter((r) => r.id >= 0).map((sessionType, i) => {
                            return <MenuItem value={sessionType.id} key={i}>{sessionType.name} <Box ml={2} style={{
                              backgroundColor: sessionType.color,
                              width: '20px',
                              height: '20px',
                              borderRadius:'4px',
                              float: 'right'
                            }} display='flex' alignItems='flex-end'/></MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled'>Session Type</InputLabel>
                        {errors && errors.sessionTypeId && touched.sessionTypeId &&
                        <FormHelperText error variant='filled'> {errors.sessionTypeId} </FormHelperText>}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} className={classes.descriptionBox}>
                      <Field type='text' name='description' component={TextField} id='Summary'
                             label='Session Description'
                             variant='filled'
                             fullWidth multiline maxLength={SIZE_LENGTH_DESCRIPTION}
                             rows={4}/>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      Private Session
                      <Field type='checkbox' name='isPrivate' component={Switch} disabled={isSubmitting}/>
                    </Grid>

                    <Grid item xs={8}>
                      <Grid item container justify='flex-end' spacing={2}>
                        {session && session.isPrivate && session.privateAttendees.length > 0 &&
                        <Grid item>
                          <Button variant='contained' size='smaller' onClick={() => setOpenAttendees(true)}>
                            View Attendees
                          </Button>
                        </Grid>
                        }
                        <Grid item>
                          <Button variant='contained' size='smaller' onClick={() => setOpenClearChat(true)}>
                            Clear chat
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant='contained' size='smaller' onClick={() => setOpenClearEmotion(true)}>
                            Clear emotion
                          </Button>
                        </Grid>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid item container spacing={1} className={classes.row}>

                    <Grid item xs={12} md={8} spacing={1} container>
                      <Grid item xs={12}>Timeframe</Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <DatePicker format="DD/MM/yyyy" name='startDate' label='Start Date' inputVariant='filled' onChange={(value) => {
                          setFieldValue("startDate", value);
                          //console.log(value.toString());
                        }} value={values.startDate} fullWidth disabled={isSubmitting} InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconCalendarBorderDark/>
                            </InputAdornment>
                          )
                        }}/>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TimePicker name='startDate' label='Start Time' inputVariant='filled' onChange={(value) => {
                          setFieldValue("startDate",value.toISOString());
                          //console.log(value.toString());
                        }} value={values.startDate} fullWidth disabled={isSubmitting} InputProps={{
                          endAdornment: (
                            <InputAdornment position='end'>
                              <IconClockBorderDark/>
                            </InputAdornment>
                          )
                        }}/>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <Field type='select' labelId='durationLabel' displayEmpty name='duration' component={Select}
                                 label='Duration' variant='filled' fullWidth disabled={isSubmitting}
                                 defaultValue={60 * 30 * 1000}>
                            {TIME_DURATION.map((time, i) => {
                              return <MenuItem value={time.value} key={i}>{time.label}</MenuItem>
                            })}
                            {/*{!TIME_DURATION.find((time)=>time.value===differenceInMilliseconds(parseISO(values.endDate), parseISO(values.startDate)))&&<MenuItem value={differenceInMilliseconds(parseISO(values.endDate), parseISO(values.startDate))}>-</MenuItem>}*/}
                          </Field>
                          <InputLabel variant='filled'>Duration</InputLabel>
                          {errors && errors.duration && touched &&
                          <FormHelperText error variant='filled'> {errors.duration} </FormHelperText>}
                        </FormControl>

                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} container>
                      <Grid item xs={12}> Image Background </Grid>
                      <Box display='flex' width={'100%'} minHeight={54} className={classes.participantsBox}>
                        {session && !formData.deleteBackground && !formData.uploadedBackground ? <Chip
                          size='small'
                          label='Background'
                          onDelete={() => deleteBackground()}
                          className={classes.chip}
                        /> : formData.uploadedBackground && <Chip
                          size='small'
                          label={formData.uploadedBackground.name}
                          onDelete={() => {
                            deleteUploadedBackground()
                          }}
                          className={classes.chip}
                        />}
                      </Box>

                      <Grid item container spacing={1} justifyContent='center' alignItems='center'>
                        <Grid item sm={12} md={6}>
                          <Box display='flex' p={1} pt={1} pl={0}>
                            <UploadBackground setFormData={setFormData} formData={formData} uploadFiles={uploadFiles}/>
                          </Box>
                        </Grid>
                        <Grid item sm={12} md={6}>
                          <Box display='flex' p={1} pl={2} fontSize={11}>Image should be at
                            least {SIZE_COVER.width}x{SIZE_COVER.height}px</Box>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>

                </>}

                {tab === 1 && <>
                  <Grid item container spacing={1} className={classes.row}>

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Streaming </Typography>
                      If your session is being streamed live, select your streaming service.
                    </Grid>

                    <Grid item container spacing={2} className={classes.row}>

                      <Grid item xs={12} sm={12} md={6}>
                        <FormControl className={classes.formControl}>
                          <Field type='select' labelId='videoStreamTypeLabel' displayEmpty component={Select}
                                 name='videoStreamType'
                                 label='Streaming service' variant='filled' fullWidth
                                 disabled={isSubmitting || (values.type === VIEWER_TYPES.BREAK || values.type === VIEWER_TYPES.SOCIAL)}
                                 defaultValue=''>

                            {values.type === VIEWER_TYPES.STANDARD && STREAM_OPTIONS.map((option, i) =>
                              <MenuItem value={option.id} key={i}> {option.name} </MenuItem>)}

                            {values.type === VIEWER_TYPES.POSTER && STREAM_OPTIONS_POSTER.map((option, i) =>
                              <MenuItem value={option.id} key={i}> {option.name} </MenuItem>)}

                            {(values.type === VIEWER_TYPES.BREAK || values.type === VIEWER_TYPES.SOCIAL) &&
                            <MenuItem value={0} key={0}> None </MenuItem>}

                          </Field>
                          <InputLabel variant='filled'>Streaming service</InputLabel>
                          {errors && errors.videoStreamType && touched.videoStreamType &&
                          <FormHelperText error variant='filled'> {errors.videoStreamType} </FormHelperText>}
                        </FormControl>
                      </Grid>

                      {(values.videoStreamType === 1 || values.videoStreamType === 9) && <>
                        <Grid item xs={12}>
                          Record video stream
                          <Field type='checkbox' name='recordVideoStream' component={Switch} disabled={isSubmitting}/>
                        </Grid>
                      </>}

                      {values.videoStreamType > 1 && values.videoStreamType !== 7 && values.videoStreamType !== 8 && values.videoStreamType !== 9 && <>
                        <Grid item xs={12} sm={6}>
                          <Field type='text' name='url' component={TextField} label='URL' variant='filled'
                                 fullWidth disabled={isSubmitting}/>
                        </Grid>
                      </>}

                      {[STREAM_VIDEO_TYPES.ZOOM,STREAM_VIDEO_TYPES.GOOGLE,STREAM_VIDEO_TYPES.OTHER].includes(values.videoStreamType)&&<>

                        <Grid item xs={12}>
                          <Typography variant='h6' component='h2'> Additional Online Options </Typography>
                          This information will appear to the attendees before launching the external URL
                        </Grid>

                        <Grid item xs={12}>
                          <Field type='text' name='passcode' component={TextField} label='Passcode' variant='filled'
                                 fullWidth disabled={isSubmitting}/>
                        </Grid>

                        <Grid item xs={12}>
                          <Field type='text' name='invitation' multiline maxLength={SIZE_LENGTH_DESCRIPTION} rows={5}
                                 component={TextField} label='Invitation text' variant='filled'
                                 fullWidth disabled={isSubmitting}/>
                        </Grid>

                        <Grid item container justify='flex-end'>
                          <Grid item>
                            <Field type='checkbox' name='showURL' component={Switch} disabled={isSubmitting}/>
                            Show online meeting URL
                          </Grid>
                        </Grid>

                      </>}

                      {values.videoStreamType === 8 && <>

                        <Grid item xs={12} sm={6}>
                          <Grid item container flexDirection='column' spacing={2}>
                            <Grid item xs={12}>
                              <MUITextField label='RTMP url' variant='filled'
                                            value={eventInfo.rtmpServerUrl ? eventInfo.rtmpServerUrl : ''}
                                            fullWidth disabled={true}/>
                            </Grid>
                            <Grid item xs={12}>
                              <MUITextField label='Stream key' variant='filled'
                                            value={session.rtmpStreamName ? session.rtmpStreamName : ''}
                                            fullWidth disabled={true}/>
                            </Grid>
                          </Grid>

                        </Grid>

                      </>}

                    </Grid>

                    {(values.videoStreamType === 1 || values.videoStreamType === 9) && <>
                      <Grid item xs={12}>
                        <Typography variant='h6' component='h2'> Broadcasting </Typography>
                        If you need to broadcast your session, add a new broadcast RTMP URL/Key pair below
                      </Grid>

                      <Grid item container spacing={2} className={classes.row}>

                        <FieldArray
                          name='rtmps'
                          render={arrayHelpers => (
                            <React.Fragment>
                              {values.rtmps && values.rtmps.length > 0 ? (
                                values.rtmps.map((rtmp, index) => (
                                  <React.Fragment key={index}>

                                    <Grid item xs={6}>
                                      <Field type='text' name={`rtmps.${index}.url`} component={TextField}
                                             label='RTMP url' variant='filled'
                                             fullWidth disabled={isSubmitting}/>
                                    </Grid>

                                    <Grid item xs={5}>
                                      <Field type='text' name={`rtmps.${index}.key`} component={TextField}
                                             label='Stream key' variant='filled'
                                             fullWidth disabled={isSubmitting}/>
                                    </Grid>

                                    <Grid item xs={1}>
                                      <Box pt={1}>
                                        <Button variant='contained' size='smaller'
                                                onClick={() => arrayHelpers.remove(index)}>
                                          Remove
                                        </Button>
                                      </Box>
                                    </Grid>

                                  </React.Fragment>
                                ))
                              ) : (
                                <> </>
                              )}
                              <Grid item>
                                <Button variant='contained' size='smaller' onClick={() => arrayHelpers.push('')}>
                                  Add a{values.rtmps.length > 0 ? 'nother' : ''} broadcast
                                </Button>
                              </Grid>
                            </React.Fragment>
                          )}
                        />


                      </Grid>
                    </>}

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Recording URL </Typography>
                      If you have a recording of the session enter the URL of your video below
                    </Grid>

                    <Grid item container spacing={2} className={classes.row} alignItems={'stretch'}>

                      <Grid item xs={6}>
                        <Field type='text' name='recordingUrl' component={TextField} label='Recording URL'
                               variant='filled'
                               fullWidth disabled={isSubmitting}/>
                      </Grid>

                      <Grid item xs={6}>
                        <Grid item container alignContent='center' alignItems='center'>
                          <Grid item>
                            <Field type='checkbox' name='playRecording' component={Switch} disabled={isSubmitting}/>
                            Play recording video in session viewer
                          </Grid>
                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                </>}

                {tab === 2 && <>
                  <Grid item container spacing={2} className={classes.row}>

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Speakers </Typography>
                      Select one or more speakers from list to add session speakers.
                    </Grid>

                    <Grid item xs={4}>
                      <Field type='text' name='searchByNameSpeaker' component={TextField} label='Search by name'
                             variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>

                    <Grid item xs={8}> </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      {participants.length === 0 ? <Box display='flex' justifyContent='center' alignItems='center'
                                                        className={classes.participantsList} aria-label='participants'>
                          <Box display='flex'>No speakers available</Box>
                        </Box> :
                        <List component='nav' className={classes.participantsList} aria-label='participants'>
                          {participants.filter((a) => values.searchByNameSpeaker.trim() ? (`${a.firstName} ${a.lastName}`).toLowerCase().includes(values.searchByNameSpeaker.trim().toLowerCase()) : true).map((participant, i) => {
                            return <ListItem button key={i} onClick={() => {
                              addParticipant(participant, setFieldValue)
                            }}>
                              <ListItemText primary={`${participant.firstName} ${participant.lastName}`}/>
                            </ListItem>
                          })}
                        </List>}
                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                      <Box display='flex' flexWrap='wrap' alignContent='flex-start' width={'100%'} height={149}
                           className={classes.participantsBox}>
                        {formData.participants && formData.participants.map((participant, i) => {
                          return <Chip
                            size='small'
                            key={i}
                            label={`${participant.firstName} ${participant.lastName}`}
                            onDelete={() => {
                              deleteParticipant(i, setFieldValue, values)
                            }}
                            className={classes.chip}
                          />
                        })}
                      </Box>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Typography variant='h6' component='h2'> Session chair
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Select a user to be session chair" placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="secondary" variant="outlined" fontSize="large" />
                      </Tooltip>                       
                      </Typography>
                      Select one speaker from the list to add as session chair.
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography variant='h6' component='h2'> Session Coach
                      <Tooltip classes={{tooltip: classes.tooltip}} title="Select a user to be session coach" placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="secondary" variant="outlined" fontSize="large" />
                      </Tooltip>                       
                      </Typography>
                      Select a registered user as a session coach.
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='chairLabel' displayEmpty name='chairId' component={Select}
                               label='Session Chair' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          <MenuItem value={'-'} key={-1}>None</MenuItem>
                          {formData.participants.map((participant, i) => {
                            return <MenuItem value={participant.id}
                                             key={i}>{`${participant.firstName} ${participant.lastName}`}</MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled'>Session Chair</InputLabel>
                        {errors && errors.chairId && touched.chairId &&
                        <FormHelperText error variant='filled'> {errors.chairId} </FormHelperText>}
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      {!formData.coach && <>
                        <Grid item>
                          <Button variant='contained' size='smaller' onClick={handleCoach}> Select a user to act as
                            coach </Button>
                        </Grid></>}
                      {formData.coach && <>
                        <Grid item>
                          <Chip avatar={<Avatar
                            src={formData.coach.pictureId ? `${imageFile(formData.coach.pictureId)}` : null}
                            alt={`${formData.coach.title ? formData.coach.title : ''} ${formData.coach.firstName} ${formData.coach.lastName}`}/>}
                                label={`${formData.coach.title ? formData.coach.title : ''} ${formData.coach.firstName} ${formData.coach.lastName}`}
                                onDelete={handleDeleteCoach}/>
                        </Grid></>}
                    </Grid>

                  </Grid>
                </>}

                {tab === 3 && <>
                  <Grid item container spacing={1} className={classes.row}>

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Presentations </Typography>
                      Select one or more presentations from list to add to session.
                    </Grid>

                    <Grid item xs={4}>
                      <Field type='text' name='searchByName' component={TextField} label='Search by name'
                             variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>

                    <Grid item xs={8}> </Grid>

                    <Grid item xs={12} sm={12} md={4}>

                      {presentations.length === 0 ?
                        <Box display='flex' justifyContent='center' alignItems='center'
                             className={classes.presentationsList}
                             aria-label='presentations'>
                          <Box display='flex'>No presentations available</Box>
                        </Box> :
                        <List component='nav' className={classes.presentationsList} aria-label='presentations'>
                          {presentations.filter((a) => values.searchByName.trim() ? (`${a.title}`).toLowerCase().includes(values.searchByName.trim().toLowerCase()) : true).map((presentation, i) => {
                            return <ListItem button key={i} onClick={() => {
                              addPresentation(presentation)
                            }}>
                              <ListItemText primary={`${presentation.title}`}/>
                            </ListItem>
                          })}
                        </List>}

                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                      <Box display='flex' flexWrap='wrap' alignContent='flex-start' width={'100%'} height={149}
                           className={classes.presentationsBox}>
                        {formData.presentations && formData.presentations.map((presentation, i) => {
                          return <Chip
                            size='small'
                            key={i}
                            label={<EllipsisText length={40} text={`${presentation.title}`}/>}
                            onDelete={() => {
                              deletePresentation(i)
                            }}
                            className={classes.chipPresentation}
                          />
                        })}
                      </Box>
                    </Grid>

                  </Grid>
                </>}

                {tab === 4 && <>
                  <Grid item container spacing={1} className={classes.row}>

                    <Grid item xs={12}>
                      <Typography variant='h6' component='h2'> Files </Typography>
                    </Grid>

                    <Grid item xs={12}>

                      <Box display='flex' flexWrap='wrap' alignContent='flex-start' width={'100%'} height={149}
                           className={classes.participantsBox}>
                        {formData.files && formData.files.map((file, i) => {
                          return <Chip
                            size='small'
                            key={i}
                            label={`${file.filename}`}
                            onDelete={() => {
                              deleteFile(i, setFieldValue)
                            }}
                            className={classes.chip}
                          />
                        })}
                        {formData && formData.uploadedFiles && formData.uploadedFiles.map((file, i) => {
                          return <Chip
                            size='small'
                            key={i}
                            label={`${file.name}`}
                            onDelete={() => {
                              deleteUploadedFile(i)
                            }}
                            className={classes.chip}
                          />
                        })}
                      </Box>

                      <Grid item container spacing={1} justifyContent='center' alignItems='center'>
                        <Grid item sm={12}>
                          <Box display='flex' p={1} pt={1} pl={0}>
                            <UploadFile setFormData={setFormData} formData={formData} uploadFiles={uploadFiles}/>
                            <Box display='flex' p={1}>Upload one or more files in PDF / DOC / EXCEL / JPG / PNG format.
                              Max file size 5 MB</Box>
                          </Box>

                        </Grid>
                        <Grid item sm={12} md={8}>

                        </Grid>
                      </Grid>

                    </Grid>

                  </Grid>
                </>}


                <Grid item container>
                  <Grid item md={3} xs='auto'> </Grid>
                  <Grid item container md={6} spacing={3} className={classes.btns}>

                    <Grid item xs={12} md={6}>
                      <Button variant='outlined' size='small' colour='white' onClick={()=>handleClose(false)}
                              disabled={isSubmitting}> Cancel </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Box>
          </Form>
        </>
      }}
    </Formik>
    <Dialog onClose={handleCloseAttendees} open={openAttendees} fullWidth maxWidth='xl'
            classes={{paper: classes.dialogPaper}}>
      <PrivateAttendees session={session} handleClose={handleCloseAttendees}/>
    </Dialog>
  </ThemeProvider>
  </Box>
})

export default SessionEditNew

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const PrivateAttendees = withStyles(styles)(({classes, session, handleClose,...props}) => {

  return <Box className={classes.attendeesDialog}>
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        {session.privateAttendees && session.privateAttendees.length > 0 &&
        <MaterialTable
          icons={tableIcons}
          columns={[
            {title: 'Title', field: 'title', cellStyle: {width: '20px', maxWidth: '20px'}},
            {title: 'First name', field: 'firstName', editable: 'never'},
            {title: 'Last name', field: 'lastName', editable: 'never'},
            {title: 'Email', field: 'email', editable: 'never'},
            {title: 'Position', field: 'profession', editable: 'never'},
            {title: 'Affiliation / Company', field: 'institute', editable: 'never'},
            {
              title: 'LinkedIn',
              field: 'linkedinUrl',
              cellStyle: {width: '80px', maxWidth: '80px', wordBreak: 'break-all'},
              editable: 'never'
            },
          ]}
          data={session.privateAttendees}
          title="Attendees"
          options={{
            actionsColumnIndex: 999,
            headerStyle: {
              backgroundColor: palette['brown-grey'],
              color: '#FFF',
              fontWeight: 'bold',
              fontSize: 14
            },
          }}
        />
        }
      </Grid>
    </Grid>
    <Grid container spacing={3} className={classes.btns}>
      <Grid item xs={12} md={12}>
        <Button variant='outlined' size='small' onClick={handleClose}> Cancel </Button>
      </Grid>
    </Grid>
  </Box>

})
