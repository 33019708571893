import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {CURRENCY} from 'config'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import clsx from 'clsx'
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton'

const styles = theme => ({

  planBox: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 2px #e6e6e6',
    position: 'relative',
    padding: theme.spacing(2),
    color: palette['greyish-brown'],
    '& a': {
      color: theme.palette.primary.main
    }
  },
  purchasedPlan: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7
  },
  button: {
    height: '100%',
    position: 'relative'
  },
  note: {
    left: 0,
    fontSize: '11px',
    position: 'absolute',
    width: '100%',
    bottom: '-5px',
    textAlign: 'center',
    color: palette['brown-grey'],
    fontWeight: 'bold'
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  priceColor: {
    color:theme.palette.primary.main
  },
  selected: {
    border: `2px solid ${palette.aquamarine}`
  }
})

const PlanCardSelect = withStyles(styles)(({classes, handleOpen, event, plan, upgrade, existing, selectAction, selected }) => {


  return <Grid container spacing={1} className={clsx(classes.container)} justify='space-between'>
        <Grid item xs={12} md={12}>
           <Grid item className={clsx(classes.planBox,selected&&classes.selected)} justify='space-between'>
             <Grid item>
             <Box display='flex' flexDirection='column' alignContent='center' justifyContent='center'>
               <Box display='flex' fontSize='14px'>
                 Plan
               </Box>
               <Box display='flex' fontSize='20px' fontWeight='bold' alignItems='center'>
                 <Box display='flex'>{plan.name}</Box>
                 <Box display='flex'> <IconButton color="primary" aria-label="View plan features" component='a' target='_blank' href={'/pricing'} alt='View plan features'>
                   <InfoIcon/>
                 </IconButton> </Box>
               </Box>
               <Box display='flex' fontSize='14px' fontWeight='bold' pt={1}>
                 {plan.billingPolicy===2?'10 one-day events':plan.name==='Free'?'Limited features':'All features included'}
               </Box>
             </Box>
           </Grid>

             <Grid item>
               <Box display='flex' fontSize='14px'>
                 Max. Attendees
               </Box>
               <Box display='flex' fontSize='16px' fontWeight='bold'>
                 {plan.maxAttendees?plan.maxAttendees:'-'}
               </Box>
             </Grid>

           <Grid item xs={3}>
             <Box display='flex' flexDirection='column'  justifyContent='center'>
               {!existing&&<Box display='flex' fontSize='14px' justifyContent='center'>
                 Price {plan.billingPolicy===2?'/mo':''}
               </Box>}
               {!existing&&<Box display='flex' fontSize='24px' fontWeight='bold' justifyContent='center' className={classes.priceColor}>
                 {plan.price?`${CURRENCY} ${plan.price}`:'-'}
               </Box>}
               <Box display='flex' pt={2} justifyContent='center' alignContent='flex-end'>
                 {handleOpen&&<Button autoWidth onClick={()=>handleOpen(plan, !!upgrade)}> {upgrade?'Use':'Buy'} </Button>}
                 {selectAction&&<Button disabled={selected} autoWidth onClick={()=>selectAction(plan.id)}> Select </Button>}
               </Box>
             </Box>
           </Grid>
         </Grid>
        </Grid>
        {/*{handleOpen&&<Grid item xs={12} md={3}>*/}
        {/*  <Grid item container justify='center' alignContent='center' alignItems='center' className={classes.button}>*/}
        {/*    <Grid item>*/}
        {/*      <Button autoWidth onClick={()=>handleOpen(plan)}> Upgrade </Button>*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>}*/}
      </Grid>
})

export default PlanCardSelect
