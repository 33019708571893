import React, {useState} from 'react';
import {ThemeProvider} from '@material-ui/core/styles'
import {theme} from 'theme.js'
import {BrowserRouter as Router} from 'react-router-dom'
import Routes from 'components/Routes'
import {ProvideAuth} from 'auth/AuthController'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {SnackbarProvider} from 'notistack'
import LoadingIndicator, {ProvideLoadingIndicator} from 'components/core/LoadingIndicator'
import {UserInfoProvider} from 'components/UserInfoProvider'
import MetaProvider, {ProvideMeta} from 'components/core/MetaProvider'
import CookieComponent from 'components/core/CookieComponent'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

// Set default timezone application-wide
let launchMoment = require('moment')
require('moment-timezone')
moment.tz.setDefault('UTC')

const App = () => {
  const [profileDialogOpen, setProfileDialogOpen] = useState(false)

  return <>
      <MuiPickersUtilsProvider libInstance={launchMoment} utils={MomentUtils}>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}>
            <ProvideAuth>
              <ProvideLoadingIndicator>
                <ProvideMeta>
                  <MetaProvider />
                  <UserInfoProvider profileDialogOpen={profileDialogOpen} setProfileDialogOpen={setProfileDialogOpen} />
                  <LoadingIndicator />
                  <Router>
                    <Routes navHandler={setProfileDialogOpen} />
                  </Router>
                </ProvideMeta>
              </ProvideLoadingIndicator>
            </ProvideAuth>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiPickersUtilsProvider>
      <CookieComponent/>
    </>
}

export default App;
