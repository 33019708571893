import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import PresentationCard from 'components/core/PresentationCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue, IconUploadBorderDark} from "components/core/icons";
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import PresentationEditNew from 'components/core/PresentationEditNew'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import {filterList} from 'utils'
import {Pagination} from '@material-ui/lab'
import { SearchInput } from 'components/core/SearchInput'
import {useDropzone} from "react-dropzone";
import PresentationsBulkImport from "components/core/PresentationsBulkImport";
import { set } from 'date-fns'
import AddCreditsDialog from "components/pages/ManageEvent/Plans/AddCreditsDialog";
import {creditsPriceList} from "config";

const styles = (theme) => ({
  presentationsContainer: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(3),
  },
  presentationBox: {
    maxWidth: "1000px",
  },
  noPresentationsYet: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "130px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",  
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem"
  },
});


const Presentations = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack})=>{

  const [getData, data] = useHttp()
  const [getParticipantsData, participantsData] = useHttp()
  const [participants, setParticipants] = useState()
  const [sessions, setSessions] = useState()
  const [delRequest, delData] = useHttp()
  const [getSessions, sessData] = useHttp()
  const [openPresentationDialog, setOpenPresentationDialog] = useState(false)
  const [editingPresentation, setEditingPresentation] = useState()
  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deletingPresentation, setDeletingPresentation] = useState()
  const [presentationsData, setPresentationsData] = useState()
  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const {eventId} = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [creditsPlan, setCreditsPlan] = useState();
  const [getCredits, credits] = useHttp();
  const [creditMessage, setCreditMessage] = useState("")
  
  const [complete, setComplete] = useState(false);

  const [openBulk, setOpenBulk] = useState(false);
  const [fileData, setFileData] = useState([]);
  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) return false;

    setFileData(file);
    setOpenBulk(true);
    //  console.log(file);
  };
  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps();  

  // useEffect(()=>{
  //   getData(api.entities.presentations.getAllByEvent(null,null,{id:eventId}))
  //   //eslint-disable-next-line
  // },[])
  useEffect(() => {
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);  

  useEffect(()=>{
    getParticipantsData(api.entities.manage.getParticipants(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(()=>{
    getSessions(api.entities.manage.getSessions(null,null,{id:eventId}))
    //eslint-disable-next-line
  },[])

  useEffect(() => {
    if (complete) {
      getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);
  
  useEffect(()=>{
    if (sessData) {
      setSessions(sessData)
    }
  },[sessData])

  useEffect(() =>
  {
    if (data){
      

      let presentations = [...data.presentations];

      presentations.forEach((p, i) =>
      {
        const coauthors = []
        p.participants.forEach((participantId) =>
        {
          const participantObj = participantsData.participants.find((participant) => participant.id === participantId)
          const participantName = `${ participantObj.title } ${ participantObj.firstName } ${ participantObj.lastName }`
          coauthors.push(participantName)
        })
        presentations[i].coauthors = coauthors
      })
      //console.log(presentations)

      const presentationsData = {
        ...data,
        presentations
      }
      setPresentationsData(presentationsData)
      setPageTitle(data.eventInfo.name)
      setEventInfo(data.eventInfo)
      setNavBack({ path: '/manage-events', name: 'events' })
    
      const countMessages = data.presentations.filter((item) => item.posterMessage !== null);
      setCreditMessage(countMessages[0]?.posterMessage);
    }
    // eslint-disable-next-line
  },[data, setEventInfo, setNavBack, setPageTitle, credits])

  useEffect(() => {
    if (participantsData) {
      setParticipants(participantsData.participants);
      getData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}));
    }
    // eslint-disable-next-line
  }, [participantsData, setParticipants, complete]);

  const handleClose = (shouldReload) => {
    setOpenPresentationDialog(false)
    if (shouldReload) {
      getData(api.entities.presentations.getAllByEvent(null,null,{id:eventId}))
    }
  }

  const handleOpen = (presentation) => {
    setOpenPresentationDialog(true)
    if (presentation) {
      setEditingPresentation(presentation)
    } else {
      setEditingPresentation(undefined)
    }
  }

  const handleDeleteOpen = (id) => {
    setDeleteOpen(true)
    setDeletingPresentation(id)
  }

  const handleDeleteClose = (id) => {
    setDeleteOpen(false)
    if (id===deletingPresentation) {
      delRequest(api.entities.presentations.delete(null,null,{id:id}))
      setDeletingPresentation(undefined)
    }
  }

  useEffect(()=>{
    if (delData) {
      enqueueSnackbar('Successfully deleted presentation', {variant: 'success'})
      getData(api.entities.presentations.getAllByEvent(null,null,{id:eventId}))
    }
    // eslint-disable-next-line
  },[delData])

  if (!presentationsData||!participants||!sessions) return <> </>

  const presentationList = search?filterList(search,null,presentationsData.presentations).filter((p)=>p.matched===true):presentationsData.presentations

  // FE Paging
  const presentationsPerPage = 30
  const totalPresentations = presentationList.length
  const totalPages = Math.ceil(totalPresentations/presentationsPerPage)
  const handleChangePage = (event, value) => {
    setCurrentPage(value)
  }

  const from = (currentPage-1)*presentationsPerPage
  const to = from + presentationsPerPage
  const presentationPagedList = presentationList.slice(from,to)

  const doSearch = (value) => {
    setCurrentPage(1)
    setSearch(value)
  }
  
  
  const handleCloseBulk = (shouldReload) => {
    setOpenBulk(false);
    if (shouldReload) {
      getData(api.entities.presentations.getAllByEvent(null, null, {id: eventId}));
    }
  };  

  const handleOpenAddCredits = (plan) => {
    setCreditsPlan(plan);
  };

  const handleCloseAddCredits = (success) => {
    setCreditsPlan(null);
    if (success === true) {
      enqueueSnackbar("Credits added successfully.", {variant: "success"});
    }
  };
  
  return (
    <Box display="flex">
      <Dialog
        onClose={handleClose}
        open={openPresentationDialog}
        maxWidth={"md"}
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: palette["cerulean-blue"],
            color: "white",
            borderRadius: "6px",
          },
        }}>
        <PresentationEditNew presentation={editingPresentation} handleClose={handleClose} eventId={eventId} setSuccessful={setSuccessful} participants={participants} sessions={sessions.sessions} />
      </Dialog>

      <Dialog open={deleteOpen} onClose={handleDeleteClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <Box p={3} pt={1}>
          <DialogTitle id="alert-dialog-title">Delete presentation</DialogTitle>
          <DialogContent>
            <Box p={1} pl={0} pb={2}>
              Are you sure you want to delete the presentation?
            </Box>
          </DialogContent>
          <DialogActions>
            <Box p={1}>
              <Button onClick={() => handleDeleteClose()} size="small" variant="outlined" color="primary">
                No
              </Button>
            </Box>
            <Box p={1}>
              <Button
                onClick={() => {
                  handleDeleteClose(deletingPresentation);
                }}
                size="small"
                autoFocus
                color="primary">
                Yes
              </Button>
            </Box>
          </DialogActions>
        </Box>
      </Dialog>
      <Fade in={true} timeout={TIME_FADE_IN}>
        <Grid container className={classes.presentationBox}>
          {presentationsData.presentations.length !== 0 ? (
            <Grid container spacing={2} className={classes.presentationsContainer}>
              <Grid item container xs={12}>
                <Grid item xs={5}>
                  <Typography component="h2" variant="h5" className={classes.title}>
                    Oral/Poster Presentations
                  </Typography>
                </Grid>
                <Grid item xs={7} style={{textAlign: "right"}}>
                  {creditMessage && (
                    <>
                      <Typography component="h6" variant="h6" className={classes.creditsTitle}>
                        {creditMessage}
                      </Typography>{" "}
                      <Box display="inline" pt={2} ml={3}>
                        <Button autoWidth size="smaller" onClick={() => handleOpenAddCredits("posters")}>
                          {" "}
                          Unlock more posters{" "}
                        </Button>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={9}></Grid>
              <Grid item xs={3}>
                <SearchInput doSearch={doSearch} label="Find a presentation..." charLengthSearch={3} />
              </Grid>
              <Grid item xs={12}>
                {presentationPagedList.map((presentation, y) => {
                  return (
                    <PresentationCard
                      sessions={sessions.sessions}
                      presentation={presentation}
                      participants={participants}
                      key={y}
                      editAction={() => {
                        handleOpen(presentation);
                      }}
                      deleteAction={() => {
                        handleDeleteOpen(presentation.id);
                      }}
                      mb={2}
                    />
                  );
                })}
              </Grid>

              <Grid item container xs={12} className={classes.presentationBox}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end" pt={3}>
                    <Button
                      variant="outlined"
                      icon={<IconAddBorderBlue />}
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleOpen();
                      }}>
                      Add Presentation
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box {...rootProps} className={classes.dropBox} ml={1} pt={3}>
                    <input type="file" {...getInputProps()} accept=".xls,.xlsx" />
                    <Button variant="outlined" icon={<IconUploadBorderDark />} size="small" color="primary">
                      Import from file
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              {presentationPagedList.length !== 0 && (
                <Grid item container justify="center" alignContent="center" alignItems="center">
                  <Grid item>
                    <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} />
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            ""
          )}

          {presentationsData.presentations.length === 0 ? (
            <Grid container spacing={2} className={classes.presentationsContainer}>
              <Grid item xs={12}>
                <Typography component="h2" variant="h5" className={classes.title}>
                  Oral/Poster Presentations
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid item container className={classes.noPresentationsYet} justify="center" alignItems="center">
                  <Grid item>No presentations yet!</Grid>
                </Grid>
              </Grid>
              <Grid item container xs={12} className={classes.presentationBox}>
                <Grid item md={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end" pt={3}>
                    <Button
                      variant="outlined"
                      icon={<IconAddBorderBlue />}
                      size="small"
                      color="primary"
                      onClick={() => {
                        handleOpen();
                      }}>
                      Add Presentation
                    </Button>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box {...rootProps} className={classes.dropBox} ml={1} pt={3}>
                    <input type="file" {...getInputProps()} accept=".xls,.xlsx" />
                    <Button variant="outlined" icon={<IconUploadBorderDark />} size="small" color="primary">
                      Import from file
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Fade>
      <Dialog onClose={handleCloseBulk} open={openBulk} fullWidth maxWidth="md" classes={{paper: classes.dialogPaper}}>
        <PresentationsBulkImport handleClose={handleCloseBulk} sessions={sessions.sessions} eventId={eventId} eventInfo={data.eventInfo} setSuccessful={setSuccessful} file={fileData} participants={participants} />
      </Dialog>
      {creditsPlan && (
        <AddCreditsDialog
          handleClose={handleCloseAddCredits}
          creditsPriceList={creditsPriceList}
          eventDuration={1}
          eventCredits={credits}
          open={creditsPlan}
          creditsType={creditsPlan}
          event={{...data.eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}
    </Box>
  );
})

export default withRouter(Presentations)
