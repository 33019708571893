import React, {useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import SplashImage from 'assets/eventScreenIllustration.png'
import {palette} from 'theme'
import Button from 'components/core/Button'
import QRCode from 'qrcode.react'
import {useHttp} from 'api/core'
import api from 'api'
import {withRouter} from 'react-router-dom'
import {useSnackbar} from 'notistack'
import {DOMAIN, TIME_FADE_IN} from 'config'
import Fade from '@material-ui/core/Fade'

const styles = theme => ({
  title: {
    color: 'white',
    fontSize: '48px',
    fontWeight: 'bold'
  },
  form: {
    width: '100%',
  },
  eventInfo: {
    backgroundColor: palette.aquamarine,
    minHeight: '50px',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white'
  },
  description: {
    fontSize: '14px'
  },
  eventCode: {
    width: '200px',
    height: '100px',
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown'],
    fontWeight: 'bold',
    letterSpacing: '1px',
    fontSize: '20px'
  },
  eventQrCode: {
    width: '200px',
    height: '200px',
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: palette['greyish-brown']
  },
  splashImage: {
    backgroundImage: `url(${SplashImage})`,
    minWidth: '540px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }
})

const CreateEventPublish = withStyles(styles)(({classes, event, history}) => {

  const [fetchData, data] = useHttp()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(()=>{
    if (!event) {
      history.push('/create-event')
    }
    // eslint-disable-next-line
  },[])

  const downloadQR = () => {
    const canvas = document.getElementById('qrCode')
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    let downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${event.code}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const publishEvent = () => {
    fetchData(api.entities.events.publish(null,null,{id:event.id}))
  }

  useEffect(()=>{
    if (data) {
      if (data.status&&data.status===201) {
        enqueueSnackbar('Your event was successfully published!', {variant: 'success'})
        history.push(`/event/${event.id}`)
      } else {
        enqueueSnackbar('Unexpected Error', {variant: 'error'})
      }
    }
  },[data, history, enqueueSnackbar, event])

  if (!event) return <></>

  return <Fade in={true} timeout={TIME_FADE_IN}><Box><Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' pt={8} minHeight={window.innerHeight-280}>
    <Box display='flex' width='1024px' alignContent='center' justifyContent='center' flexDirection='row'>
      <Box display='flex' flexDirection='column' alignContent='center' justifyContent='center' >
        <Box textAlign='center' className={classes.title} mb={2}>Your event is ready! </Box>
        <Box display='flex' alignContent='center' justifyContent='center' flexDirection='column' className={classes.eventInfo} p={3}>
          <Box display='flex' alignContent='center' justifyContent='center' flexDirection='row' mb={2}>
              <Box width={250}>
                <Box fontWeight='bold' fontSize='20px' mb={1}>ID code</Box>
                <Box className={classes.description} pr={6}>
                  Share this code with your attendee list to help them join your event
                </Box>
              </Box>
            <Box display='flex' alignContent='center' justifyContent='center'>
              <Box className={classes.eventCode} display='flex' alignItems='center' alignContent='center' justifyContent='center' flexDirection='column'>
                <Box display='flex'>{event.code}</Box>
              </Box>
            </Box>
          </Box>
          <Box display='flex' alignContent='center' justifyContent='center' flexDirection='row'>
            <Box width={250}>
                <Box fontWeight='bold' fontSize='20px' mb={1}>QR code</Box>
                <Box className={classes.description} pr={6}>
                  Use this code to your promo material to help attendees find your event
                </Box>
              <Box mt={2}>
                <Button colour='white' size='small' variant='outlined' onClick={()=>{downloadQR()}}>Download</Button>
              </Box>
              </Box>
            <Box display='flex' alignContent='center' justifyContent='center'>
              <Box className={classes.eventQrCode} display='flex' alignItems='center' alignContent='center' justifyContent='center' flexDirection='column'>
                <Box display='flex'>
                  <QRCode value={`https://${DOMAIN}/event/${event.code}`} size={160} id='qrCode'/>
                </Box>
              </Box>
            </Box>
            </Box>
        </Box>
        </Box>
        <Box display='flex' p={3} className={classes.splashImage}>
        </Box>
      </Box>
  </Box>
    <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center' mt={1} pb={12}>
      <Box mr={4}><Button variant='outlined' colour='white' size='small' onClick={()=>{history.push(`/manage-event/${event.id}/general`)}}>Manage Event</Button></Box>
      <Box><Button size='small' onClick={()=>{ publishEvent(event.id)}}>Publish Event</Button></Box>
    </Box>
  </Box>
  </Fade>
})

export default withRouter(CreateEventPublish)
