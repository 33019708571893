import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import {IconNotesBorderLight, IconXBorderLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import clsx from 'clsx'
import Grid from '@material-ui/core/Grid'
import {imageFile} from 'utils'
import EllipsisText from 'react-ellipsis-text'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: palette['cerulean-blue'],
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    color: 'white',
    borderRadius: '6px',
    position: 'relative',
    maxWidth: '860px',
    '& a':{
      color: 'white'
    }
  },
  plain: {
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    '& a':{
      color: palette['greyish-brown'],
    }
  },
  OrganizerCard: {

  },
  organizerLogo: {
    width: '80px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img' : {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }
  },
  fieldTitle: {
    fontSize: '16px',
    fontWeight: 'bold'
  },
  fieldValue: {
    fontSize: '14px',
    overflowWrap: 'break-word',
    '& > a': {
      color: 'white',
      maxWidth: '90%'
    }
  },
  sessionBox: {
    minWidth: '135px',
    height: '60px',
    backgroundColor: palette['pale-sky-blue'],
    borderRadius: '8px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    color: palette['greyish-brown']
  },
  sessionTitle: {
    color: palette['cerulean-blue'],
    fontWeight: 'bold'
  },
   sessionsBox: {
    overflowX: 'auto'
   },
  editIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(6),
    cursor: 'pointer',
    [theme.breakpoints.down(1180)]: {
      top: theme.spacing(6),
      right: theme.spacing(2),
    }
  },
  deleteIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer'
  },
  logoBox: {
    backgroundColor: 'white',
    borderRadius: '6px',
    overflow: 'hidden',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)'
  }
})

const FieldText = withStyles(styles)(({title, value, url, classes, ellipsis, size}) => {
  return <Box display='flex' flexDirection='column' justifyContent='center' pr={1}>
    <Box display='flex' className={classes.fieldTitle}> {title} </Box>
    <Box display='flex' mb={1} className={classes.fieldValue}  >
      {url?<a href={value} target='_blank' rel='noopener noreferrer'>{ellipsis?<EllipsisText text={value} length={size} />:value}</a>:ellipsis?<EllipsisText text={value} length={size} />:value}
    </Box>
  </Box>
})


const OrganizerCard = withStyles(styles)(({classes, dragHandleProps, organizer, viewOnly=false, index, plain, editAction, deleteAction, content, ...props}) => {
  return <Box className={classes.OrganizerCard} {...props} key={index}><ThemeProvider theme={theme}>
    <Box p={2} className={clsx(classes.container,plain&&classes.plain)}>

      <Box display='flex' flexDirection='row' alignItems='flex-start'>

          <Box display='flex' mb={2} >
            <Box mr={1} className={classes.dragGrip} {...dragHandleProps}><DragIndicatorIcon /></Box>
          </Box>

        <Box width={100}>
          <Box display='flex' mb={2} className={clsx(classes.organizerLogo,viewOnly&&classes.logoBox)}>
              <img src={imageFile(organizer.logoId)} alt={`${organizer.name}`} />
          </Box>
        </Box>

        <Box display='flex' p={2} pt={0} flexDirection='column' width={'100%'}>

          <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={6} md={4}>
              <FieldText title='Name' value={organizer.name} />
            </Grid>
          </Grid>

          {!content && <> <Grid container alignItems='flex-start'>
            <Grid item xs={12} sm={6} md={4}>
              <FieldText title='Website' value={organizer.url} />
            </Grid>
          </Grid>

          <Grid container display='flex' alignItems='flex-start'>
            <Grid item xs={12} sm={6} md={8}>
              <FieldText title='Summary' value={organizer.summary} />
            </Grid>
          </Grid></>}
        </Box>

        <Box display='flex' pt={1} width={50} justifySelf='end' justifyContent='end' >
          <IconNotesBorderLight title='Edit Organizer' className={classes.editIcon} onClick={()=>{editAction&&editAction(organizer.id)}}/>
          <IconXBorderLight title='Delete Organizer' className={classes.deleteIcon} onClick={()=>{deleteAction&&deleteAction(organizer.id)}}/>
        </Box>
      </Box>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' width={270}>

        </Box>
      </Box>
    </Box>

  </ThemeProvider>
    </Box>
      })

      export default OrganizerCard
