import React, {useState} from 'react'
import {Field, Form, Formik} from 'formik'
import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {FILES_URL, SIZE_PROFILE_IMAGE, TITLES} from 'config'
import FormControl from '@material-ui/core/FormControl'
import {Select, TextField} from 'formik-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {COUNTRIES} from 'assets/countries'
import Button from 'components/core/Button'
import {withStyles} from '@material-ui/core/styles'
import {IconUserPicAddLight} from 'components/core/icons'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import CropImage from 'components/core/CropImage'
import * as Yup from 'yup'
import {palette} from 'theme'
import {getUserId} from 'utils'

const styles = theme => ({
  container: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    maxWidth: '430px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial'
    }
  },
  row: {
    paddingTop: '40px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  rowLast: {
    paddingTop: '40px',
    paddingBottom: theme.spacing(2),
    textAlign: 'center'
  },
  passwordRow: {
    backgroundColor: palette.aquamarine,
    padding: '20px 50px',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto'
    }
  }
})

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('This field is required'),
  lastName: Yup.string().required('This field is required'),
  title: Yup.string().required('This field is required'),
  institute: Yup.string().required('This field is required'),
  // country: Yup.string().required('This field is required'),
  // email: Yup.string().email('Invalid email address').required('This field is required'),
  // password: Yup.string().min(8,'Password cannot be less than 8 characters').required('This field is required'),
})


const UploadProfileImage = withStyles(styles)(({setFormData, formData, values, profile, size}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [cropImage, setCropImage] = useState(false)

  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width) && (img.height < size.height)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }
    fr.readAsDataURL(acceptedFiles[0])
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const setImage = (image) => {
    setFormData({...formData, pictureId: image})
    setCropImage(false)
  }

  return <RootRef rootRef={ref}>
    {cropImage && <CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage}
                             setOpen={setCropImage} setImage={setImage}/>}
    <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' {...rootProps}>
      <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
      <Button size='small' variant='outlined' colour='secondary'>{profile ? 'Change' : 'Select'} Image</Button>
      <Box mt={2}> Image should be at least 180x180px </Box>
    </Box>
  </RootRef>
})

const ProfileImage = withStyles(styles)(({classes, formData, values}) => {
  return <>
    {formData.hasOwnProperty('pictureId') && formData.pictureId !== null ? <Box className={classes.userPhoto}><img
      src={(typeof formData.pictureId.name == 'string') ? URL.createObjectURL(formData.pictureId) : `${FILES_URL}/${formData.pictureId}`}
      alt={``}/></Box> : !formData.pictureId ? <IconUserPicAddLight/> : ''}
  </>
})

const ProfileSettings = ({classes,formData,setFormData,setFormSave,isSubmitting,profile,...props}) =>{
  return <Grid item xs={12} md={6}>
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        countryId: '',
        profession: '',
        institute: '',
        email: '',
        linkedinUrl: '',
        ...formData, title: formData.title === null ? '-' : formData.title
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {
          ...formData, ...values,
          pictureId: formData.pictureId,
        }
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form>
          <Grid item container className={classes.row}>
            <Typography variant='h5' component='h1'>Profile Settings </Typography>
          </Grid>

          <Grid itemcontainer spacing={1}>
            <Grid item xs={12}>
              <Box display='flex' alignSelf='center' flexDirection='column' alignItems='center'>
                <Box display='flex' mb={2}>
                  <ProfileImage formData={formData} values={values}/>
                </Box>
                <Box display='flex'>
                  <UploadProfileImage setFormData={setFormData} setFieldValue={setFieldValue} values={values}
                                      profile={profile} size={SIZE_PROFILE_IMAGE}/>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Grid item container className={classes.row} spacing={1}>
            <Grid item xs={12}>
              <Typography variant='h6' component='h2'> Details </Typography>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='email' component={TextField} label='Email' variant='filled' fullWidth
                     disabled/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth>
                <Field type='select' labelId='categoryLabel' displayEmpty name='title' component={Select}
                       label='Title' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                  {TITLES.map((title, i) => {
                    return <MenuItem value={title} key={i}>{title}</MenuItem>
                  })}
                </Field>
                <InputLabel variant='filled'>Title</InputLabel>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Field type='text' name='firstName' component={TextField} label='First Name' variant='filled'
                     fullWidth disabled={isSubmitting}/>
            </Grid>
            <Grid item sm={3} xs={12}> </Grid>
            <Grid item sm={9} xs={12}>
              <Field type='text' name='lastName' component={TextField} label='Last Name' variant='filled'
                     fullWidth
                     disabled={isSubmitting}/>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='profession' component={TextField} label='Position' variant='filled'
                     fullWidth disabled={isSubmitting}/>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='institute' component={TextField} label='Affiliation / Company' required
                     variant='filled' fullWidth disabled={isSubmitting}/>
            </Grid>
            <Grid item xs={12}>
              <Field type='text' name='linkedinUrl' component={TextField} label='LinkedIn Profile'
                     variant='filled' id='linkedInProfile' autocomplete='false'
                     inputProps={{autocomplete: 'falselinkedin'}}
                     fullWidth disabled={isSubmitting}/>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Field type='select' labelId='countryIdLabel' displayEmpty name='countryId' component={Select}
                       label='Country' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                  {COUNTRIES.map((country, i) => {
                    return <MenuItem value={country.id} key={i}>{country.name}</MenuItem>
                  })}
                </Field>
                <InputLabel variant='filled'>Country</InputLabel>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item container spacing={1} className={classes.rowLast} direction={'column'} justify='center'
                alignItems='center'
                alignContent='center'>
            <Grid item xs={12}>
              <Box mt={2}>
                <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>

      )}
    </Formik>
    <Box style={{color:'rgba(0,0,0,0)'}}>
      {getUserId().sub}
    </Box>
    </Grid>
}

export default  ProfileSettings
