import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {palette} from "theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "components/core/Button";
import FormViewer from "components/core/Forms/FormViewer";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "api";
import {useHttp} from "api/core";
import {useSnackbar} from "notistack";
import Dialog from "@material-ui/core/Dialog";
import FormReview from "components/core/Forms/FormReview";
import FormDropZone from "components/core/Forms/FormDropZone";
import RootRef from "@material-ui/core/RootRef";
import DialogContent from "@material-ui/core/DialogContent";
import {transformForm} from "utils";
import language from "components/language";

const styles = (theme) => ({
  container: {
    padding: "40px 50px 50px 50px",
    // backgroundColor: palette['cerulean-blue'],
    // color: 'white',
    borderRadius: "6px",
  },
  newFormType: {
    // width: '580px'
  },
  row: {
    marginBottom: theme.spacing(5),
  },
  tabsBar: {
    backgroundColor: palette["very-light-pink"],
  },
  tab: {
    width: "100%",
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.17)",
    padding: theme.spacing(2),
  },
  Tabs: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  activeTab: {
    backgroundColor: palette.aquamarine,
    "&:focus": {
      backgroundColor: palette.aquamarine,
    },
  },
  tabLabel: {
    fontWeight: "bold",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  addNewItem: {
    // backgroundColor: 'white',
    // padding: theme.spacing(2),
    // borderRadius: '6px',
    border: "2px dashed !important",
    // borderColor: palette['cerulean-blue'],
    // justifyContent: 'center',
    // alignItems: 'center',
    // fontWeight: 'bold',
    // color: palette['greyish-brown'],
    // fontSize: '18px',
    opacity: 0.3,
    cursor: "pointer",
    "&:hover": {
      opacity: 0.5,
    },
  },
  field: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: "1px solid",
    borderRadius: "6px",
    borderColor: "white",
    backgroundColor: "rgba(0,0,0,0.1)",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  formControl: {
    width: "100%",
  },
  toolbar: {
    borderTop: "1px solid",
    color: "white",
    marginTop: theme.spacing(2),
  },
  editor: {
    color: palette["greyish-brown"],
    borderRadius: "6px",
    minHeight: "300px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  dialogActions: {
    marginTop: theme.spacing(2),
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  mainBox: {
    marginTop: theme.spacing(3),
    justifyContent: "flex-start",
    alignContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "350px",
    // overflowY: 'auto'
    // minHeight: 'calc(80vh - 300px)'
  },
});

const FormUploadCameraReady = withStyles(styles)(({classes, handleClose, setSuccessful, eventId, addingSubmission, open, submissions, cameraReadyStatus}) => {
  const {enqueueSnackbar} = useSnackbar();
  const [getThematicCodes, thematicCodes] = useHttp();
  const [getSubmissionData, submissionData] = useHttp();
  const [addSubmissionData, dataPut, loading] = useHttp();
  const [valid, setValid] = useState(false);
  const [isReview, setReview] = useState(false);
  const domRef = React.useRef();

  // eslint-disable-next-line
  const [name, setName] = useState();
  const [fields, setFields] = useState();

  useEffect(() => {
    getThematicCodes(api.entities.options.getThematicCodes(null, null, {id: eventId}));
    if (addingSubmission.submissionId) {
      getSubmissionData(api.entities.submissions.getSubmissionAnswer(null, null, {id: addingSubmission.submissionFlowId, answerId: addingSubmission.submissionId}));
    }
    //  const currentSubmission = submissions.filter((submission) => submission.id === addingSubmission.submissionFlowId)

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (submissionData) {
      setName(submissionData.name);
      setFields(submissionData.fields);
      if (submissionData.fields && submissionData.fields.length && submissionData.fields[0].value) {
        setValid(true);
      }
    }
  }, [submissionData]);

  useEffect(() => {
    if (dataPut) {
      enqueueSnackbar("Successfully added your submission", {variant: "success"});
      handleClose(true);
    }
    // eslint-disable-next-line
  }, [dataPut]);

  useEffect(() => {
    if (domRef.current) domRef.current.scrollTo(0, 0);
  }, [isReview]);

  const addSubmission = () => {
    let formDataObj = new FormData();

    const saveFields = transformForm(fields);

    const payLoad = {fields: [...saveFields.form]};

    const blob = new Blob([JSON.stringify(payLoad)], {type: "application/json"});
    formDataObj.append("form", blob);

    // Save files if any
    saveFields.files.forEach((file) => formDataObj.append(file.key, file.data));

    // const blobRequest = new Blob([JSON.stringify({})],{type:'application/json'})
    // formDataObj.append( 'request', blobRequest)

    addSubmissionData(api.entities.submissions.updateSubmissionAnswer(formDataObj, null, {id: addingSubmission.submissionFlowId, answerId: addingSubmission.submissionId}, true));
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md" disableBackdropClick disableEscapeKeyDown>
      <RootRef rootRef={domRef}>
        <DialogContent>
          <Box className={classes.container}>
            <Grid item container spacing={1} className={classes.row}>
              <Typography variant="h5" component="h1">
                {" "}
                {cameraReadyStatus === "upload"
                  ? language.uploadCameraReadyPdf + " " + language.for + " " + addingSubmission.title
                  : cameraReadyStatus === "edit"
                  ? language.editSubmissionForm + " " + language.for + " " + addingSubmission.title
                  : language.uploadCopyrightForm + " " + language.for + " " + addingSubmission.title}
              </Typography>
            </Grid>

            <Grid item container className={classes.mainBox}>
              <Grid item container spacing={2}>
                {(!fields || !thematicCodes) && (
                  <Box display="flex" justifyContent="center" alignContent="center" alignItems="center" width={"100%"} height={"100%"} p={6} minHeight={200}>
                    <Box display="flex">
                      <CircularProgress size={"8rem"} color="secondary" />
                    </Box>
                  </Box>
                )}

                {cameraReadyStatus === "edit" && fields && thematicCodes ? (
                  <FormViewer fields={fields} cameraReadyStatus={cameraReadyStatus} setFields={setFields} thematicCodes={thematicCodes} valid={valid} setValid={setValid} />
                ) : cameraReadyStatus === "upload" && fields && thematicCodes ? (
                  <FormDropZone fields={fields} cameraReadyStatus={15} setFields={setFields} thematicCodes={thematicCodes} valid={valid} setValid={setValid} />
                ) : ( cameraReadyStatus === "copyright" && fields && thematicCodes &&
                  <FormDropZone fields={fields} cameraReadyStatus={16} setFields={setFields} thematicCodes={thematicCodes} valid={valid} setValid={setValid} />
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={3} style={{marginTop: "16px"}}>
              <Grid item container spacing={3} justify="center" alignContent="center" alignItems="center">
                <Grid item>
                  <Button size="small" onClick={handleClose} disabled={loading}>
                    {" "}
                    {language.close}{" "}
                  </Button>
                </Grid>
                <Grid item>
                  <Button size="small" color="primary" onClick={addSubmission} disabled={loading || !valid}>
                    {" "}
                    {language.submit}{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </RootRef>
    </Dialog>
  );
});

export default FormUploadCameraReady;
