import React, {useEffect} from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import {useHttp} from 'api/core'
import api from 'api'
import {withStyles} from '@material-ui/styles'
import {palette} from 'theme'
import Button from 'components/core/Button'


const styles = theme => ({
  paper: {
    backgroundColor: 'white',
    color: palette['greyish-brown']
  },
  content: {
    color: palette['greyish-brown']
  }
})

const VisibilityDialog = withStyles(styles)(({classes,open, handleClose, sessionId, roomId}) => {

  const [setVisible, setData, isLoadingSet, hasErrorSet] = useHttp()
  const [delVisible, delData, isLoadingDel, hasErrorDel] = useHttp()

  const handleDisagree = () => {
    delVisible(api.entities.virtualRoom.delVisible(null, null,{id: roomId}))
  }

  const handleAgree = () => {
    setVisible(api.entities.virtualRoom.setVisible(null, null,{id: roomId}))
  }

  useEffect(()=>{
    if (setData&&!hasErrorSet) {
      handleClose(true)
    }
    // eslint-disable-next-line
  },[setData])

  useEffect(()=>{
    if (delData&&!hasErrorDel) {
      handleClose(false)
    }
    // eslint-disable-next-line
  },[delData])


  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="visibility-selection"
        disableBackdropClick
        disableEscapeKeyDown
        classes={{paper:classes.paper}}
      >
        <DialogTitle id="alert-dialog-title">{"Do you wish to be visible as a viewer?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.content}>
            Let's us display that you're attending this session live as a viewer.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} size='smaller' variant='contained'  color="primary" disabled={isLoadingDel||isLoadingSet}>
            Disagree
          </Button>
          <Button onClick={handleAgree} size='smaller' color="secondary"  variant='contained' autoFocus disabled={isLoadingDel||isLoadingSet}>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
  )
})

export default VisibilityDialog
