import React, {Fragment} from 'react'
import {Route, Switch} from 'react-router-dom'

import About from 'components/pages/About'
import Pricing from 'components/pages/Pricing'
import Help from 'components/pages/Help'
import Login from 'components/pages/Login'
import CreateEvent from 'components/pages/CreateEvent/CreateEvent'
import PackageSelection from 'components/pages/CreateEvent/0-Packages'
import PrivateRoute from 'auth/PrivateRoute'
import Register from 'components/pages/Register'
import SignOut from 'components/pages/SignOut'
import Favorites from 'components/pages/Favorites'
import Notes from 'components/pages/Notes'
import MyTickets from 'components/pages/MyTickets'
import MyProfile from 'components/pages/MyProfile'
import JoinedEvents from 'components/pages/JoinedEvents'
import Support from 'components/pages/Support'
import Contact from 'components/pages/Contact'
import Terms from 'components/pages/Terms'
import Notifications from 'components/pages/Notifications'
import AccountConfirm from 'components/pages/AccountConfirm'
import Search from 'components/pages/Search'
import EventCode from 'components/pages/EventCode'
import NotFound from 'components/pages/NotFound'
import Event from 'components/pages/Event/Event'
import Dashboard from 'components/pages/Dashboard'
import ManageEvents from 'components/pages/ManageEvents'
import ManageEvent from 'components/pages/ManageEvent/index.js'
import ScrollToTop from 'components/core/ScrollToTop'
import ForgotPassword from 'components/pages/ForgotPassword'
import ResetPassword from 'components/pages/PasswordReset'
import SetPassword from 'components/pages/PasswordSet'
import Feedback from 'components/pages/Feedback'
import LiveFeedback from 'components/pages/LiveFeedback'
import Header from 'components/core/Header/Header'
import SessionIndex from 'components/pages/SessionViewer'
//import Footer from 'components/core/Footer/Footer'
import Privacy from 'components/pages/Privacy'
import VirtualRoom from 'components/pages/VirtualRoom'
import GetThisPlan from 'components/pages/GetThisPlan'
import Sponsor from 'components/pages/Sponsor'
import Review from 'components/pages/Review'
import Admin from 'components/pages/Admin'
import ReviewResponse from 'components/pages/ReviewResponse'
import Features from "components/pages/Features";
import SettingUp from 'components/pages/SettingUp'
import Submissions from 'components/pages/Submissions'
import TheVirtualSession from 'components/pages/TheVirtualSession'
import Conference from 'components/pages/Conference'
import Papers from 'components/pages/Papers'
import Events from 'components/pages/VirtualEvents'
import Post from 'components/pages/PostEvents'
import Demo from 'components/pages/DemoPage'
import Home from 'components/pages/Home'
import HowItWorks from "components/pages/HowItWorks";
import {checkHost} from 'utils'

// import asyncComponent from 'Components/core/AsyncComponent'
//
// const AsyncHome = asyncComponent(()=>import('Components/pages/Home'))
// const About = asyncComponent(()=>import( 'Components/pages/About'))
// const Pricing = asyncComponent(()=>import( 'Components/pages/Pricing'))
// const Help = asyncComponent(()=>import( 'Components/pages/Help'))
// const Login = asyncComponent(()=>import( 'Components/pages/Login'))
// const CreateEvent = asyncComponent(()=>import( 'Components/pages/CreateEvent/CreateEvent'))
// const PrivateRoute = asyncComponent(()=>import( 'auth/PrivateRoute'))
// const Register = asyncComponent(()=>import( 'Components/pages/Register'))
// const SignOut = asyncComponent(()=>import( 'Components/pages/SignOut'))
// const Favorites = asyncComponent(()=>import( 'Components/pages/Favorites'))
// const Notes = asyncComponent(()=>import( 'Components/pages/Notes'))
// const MyTickets = asyncComponent(()=>import( 'Components/pages/MyTickets'))
// const JoinedEvents = asyncComponent(()=>import( 'Components/pages/JoinedEvents'))
// const Support = asyncComponent(()=>import( 'Components/pages/Support'))
// const Contact = asyncComponent(()=>import( 'Components/pages/Contact'))
// const Terms = asyncComponent(()=>import( 'Components/pages/Terms'))
// const Notifications = asyncComponent(()=>import( 'Components/pages/Notifications'))
// const AccountConfirm = asyncComponent(()=>import( 'Components/pages/AccountConfirm'))
// const Search = asyncComponent(()=>import( 'Components/pages/Search'))
// const EventCode = asyncComponent(()=>import( 'Components/pages/EventCode'))
// const NotFound = asyncComponent(()=>import( 'Components/pages/NotFound'))
// const Event = asyncComponent(()=>import( 'Components/pages/Event/Event'))
// const ManageEvents = asyncComponent(()=>import( 'Components/pages/ManageEvents'))
// const ManageEvent = asyncComponent(()=>import( 'Components/pages/ManageEvent/ChairControls.js'))
// const ScrollToTop = asyncComponent(()=>import( 'Components/core/ScrollToTop'))
// const ForgotPassword = asyncComponent(()=>import( 'Components/pages/ForgotPassword'))
// const ResetPassword = asyncComponent(()=>import( 'Components/pages/PasswordReset'))
// const Feedback = asyncComponent(()=>import( 'Components/pages/Feedback'))
// const LiveFeedback = asyncComponent(()=>import( 'Components/pages/LiveFeedback'))
// const Header = asyncComponent(()=>import( 'Components/core/Header/Header'))
// const SessionViewer = asyncComponent(()=>import( 'Components/pages/SessionViewer/SessionViewer'))
// const Footer = asyncComponent(()=>import( 'Components/core/Footer/Footer'))
// const Privacy = asyncComponent(()=>import( 'Components/pages/Privacy'))
// const VirtualRoom = asyncComponent(()=>import( 'Components/pages/VirtualRoom'))

const RouteWithNav = ({navHandler, component: Component, ...rest}) => {

    return (
      // Show the page with navigation
      <Route {...rest} render={props => (
        <Fragment>
            <Header setProfileDialogOpen={navHandler}/>
            <Component {...rest} {...props} />
            {/*<Footer/>*/}
        </Fragment>
      )} />
    )
}

const RouteWithProps = ({setProfileDialogOpen, component: Component, ...rest}) => {

    return (
      // Show the page with added props
      <Route {...rest} render={props => (
        <Component {...rest} {...props} setProfileDialogOpen={setProfileDialogOpen}/>
      )} />
    )
}

const Routes = ({navHandler}) => {
    const isUsingHost = checkHost()

  return <ScrollToTop><Switch>
      {!isUsingHost&&<Route exact path='/' component={Home}/>}

      <Route exact path='/setting-up' component={SettingUp}/>
      <Route exact path='/submissions-reviews' component={Submissions}/>
      <Route exact path='/the-virtual-session' component={TheVirtualSession}/>

      <RouteWithNav navHandler={navHandler} exact path='/reviewer-response' component={ReviewResponse}/>
      <RouteWithNav navHandler={navHandler} exact path='/reviews/invitation' component={ReviewResponse}/>
      <RouteWithNav navHandler={navHandler} exact path='/reviews/submission/:submissionId' component={Review}/>
      <RouteWithProps navHandler={navHandler} exact path='/virtual-room/:uuid/:type?' component={VirtualRoom}/>
      <Route navHandler={navHandler} path='/about' component={About} />
      <Route path='/features' component={Features} />
      <RouteWithNav navHandler={navHandler} path='/pricing' component={Pricing}/>
      <RouteWithNav navHandler={navHandler} path='/help' component={Help}/>
      <RouteWithNav navHandler={navHandler} path='/login/:to?' component={Login}/>
      <RouteWithNav navHandler={navHandler} path='/register' component={Register}/>
      <RouteWithNav navHandler={navHandler} path='/support' component={Support}/>
      <RouteWithNav navHandler={navHandler} path='/contact' component={Contact}/>
      <RouteWithNav navHandler={navHandler} path='/getplan/:plan' component={GetThisPlan}/>
      <RouteWithNav navHandler={navHandler} path='/terms' component={Terms}/>
      <RouteWithNav navHandler={navHandler} path='/privacy' component={Privacy}/>
      <RouteWithNav navHandler={navHandler} path='/pricing' component={Pricing}/>
      <RouteWithNav navHandler={navHandler} path='/search/:query?' component={Search}/>
      <RouteWithNav navHandler={navHandler} path='/eventcode' component={EventCode}/>
      <RouteWithProps setProfileDialogOpen={navHandler} path='/sponsor/:id' component={Sponsor}/>
      <RouteWithProps setProfileDialogOpen={navHandler} path='/event/:eventId/:eventPage?/:pageId?' component={Event}/>
      <PrivateRoute setProfileDialogOpen={navHandler} path='/session/:sessionId/:type?' component={SessionIndex}/>
      <RouteWithNav navHandler={navHandler} path='/forgot-password' component={ForgotPassword}/>
      <RouteWithNav navHandler={navHandler} exact path='/account/reset-password' component={ResetPassword}/>
      <RouteWithNav navHandler={navHandler} exact path='/account/set-password' component={SetPassword}/>
      <RouteWithNav navHandler={navHandler} exact path='/account/confirm' component={AccountConfirm}/>
      <RouteWithNav navHandler={navHandler} exact path='/feedback/:feedbackId?' component={Feedback}/>
      <PrivateRoute navHandler={navHandler} path='/live-feedback/:feedbackId' component={LiveFeedback}/>
      <PrivateRoute navHandler={navHandler} path='/select-package' component={PackageSelection}/>
      <PrivateRoute navHandler={navHandler} path='/create-event/:step?' component={CreateEvent}/>
      <PrivateRoute navHandler={navHandler} path='/sign-out' component={SignOut}/>
      <PrivateRoute navHandler={navHandler} path='/favorites' component={Favorites}/>
      <PrivateRoute navHandler={navHandler} path='/notes' component={Notes}/>
      <PrivateRoute navHandler={navHandler} path='/joined-events/:eventType?' component={JoinedEvents}/>
      <PrivateRoute navHandler={navHandler} path='/dashboard' component={Dashboard}/>
      <PrivateRoute navHandler={navHandler} path='/my-tickets/:ticketType?' component={MyTickets}/>
      <PrivateRoute navHandler={navHandler} path='/profile/:profilePage' component={MyProfile}/>
      <PrivateRoute navHandler={navHandler} path='/notifications' component={Notifications}/>
      <PrivateRoute navHandler={navHandler} path='/manage-events' component={ManageEvents} />
      <PrivateRoute navHandler={navHandler} path='/manage-event/:eventId/:page/:action?' component={ManageEvent} />
      <PrivateRoute navHandler={navHandler} path='/admin-console/:page?' component={Admin} />
      <RouteWithNav navHandler={navHandler} path='/how-it-works' component={HowItWorks} /> 
      <RouteWithNav navHandler={navHandler} path='/post-events' component={Post} />
      <RouteWithNav navHandler={navHandler} path='/conference' component={Conference} />
      <RouteWithNav navHandler={navHandler} path='/papers' component={Papers} />
      <RouteWithNav navHandler={navHandler} path='/virtual-events' component={Events} />
      <RouteWithNav navHandler={navHandler} path='/post-events' component={Post} />
      <RouteWithNav navHandler={navHandler} path='/demo' component={Demo} />

      {/*{manageEventRoutes}*/}
      {!isUsingHost&&<RouteWithProps setProfileDialogOpen={navHandler} path='/:slug/:eventPage?/:pageId?' component={Event}/>}
      {isUsingHost&&<RouteWithProps setProfileDialogOpen={navHandler} path='/:eventPage?/:pageId?' component={Event}/>}

      <RouteWithNav navHandler={navHandler} component={NotFound} />
  </Switch></ScrollToTop>
}

export default Routes
