export const COUNTRIES = [
  {
    id: 1,
    name: "Afghanistan"
  },
  {
    id: 2,
    name: "Åland Islands"
  },
  {
    id: 3,
    name: "Albania"
  },
  {
    id: 4,
    name: "Algeria"
  },
  {
    id: 5,
    name: "American Samoa"
  },
  {
    id: 6,
    name: "Andorra"
  },
  {
    id: 7,
    name: "Angola"
  },
  {
    id: 8,
    name: "Anguilla"
  },
  {
    id: 9,
    name: "Antarctica"
  },
  {
    id: 10,
    name: "Antigua and Barbuda"
  },
  {
    id: 11,
    name: "Argentina"
  },
  {
    id: 12,
    name: "Armenia"
  },
  {
    id: 13,
    name: "Aruba"
  },
  {
    id: 14,
    name: "Australia"
  },
  {
    id: 15,
    name: "Austria"
  },
  {
    id: 16,
    name: "Azerbaijan"
  },
  {
    id: 17,
    name: "Bahamas"
  },
  {
    id: 18,
    name: "Bahrain"
  },
  {
    id: 19,
    name: "Bangladesh"
  },
  {
    id: 20,
    name: "Barbados"
  },
  {
    id: 21,
    name: "Belarus"
  },
  {
    id: 22,
    name: "Belgium"
  },
  {
    id: 23,
    name: "Belize"
  },
  {
    id: 24,
    name: "Benin"
  },
  {
    id: 25,
    name: "Bermuda"
  },
  {
    id: 26,
    name: "Bhutan"
  },
  {
    id: 27,
    name: "Bolivia (Plurinational State of)"
  },
  {
    id: 28,
    name: "Bonaire, Sint Eustatius and Saba"
  },
  {
    id: 29,
    name: "Bosnia and Herzegovina"
  },
  {
    id: 30,
    name: "Botswana"
  },
  {
    id: 31,
    name: "Bouvet Island"
  },
  {
    id: 32,
    name: "Brazil"
  },
  {
    id: 33,
    name: "British Indian Ocean Territory"
  },
  {
    id: 37,
    name: "Brunei Darussalam"
  },
  {
    id: 38,
    name: "Bulgaria"
  },
  {
    id: 39,
    name: "Burkina Faso"
  },
  {
    id: 40,
    name: "Burundi"
  },
  {
    id: 44,
    name: "Cabo Verde"
  },
  {
    id: 41,
    name: "Cambodia"
  },
  {
    id: 42,
    name: "Cameroon"
  },
  {
    id: 43,
    name: "Canada"
  },
  {
    id: 45,
    name: "Cayman Islands"
  },
  {
    id: 46,
    name: "Central African Republic"
  },
  {
    id: 47,
    name: "Chad"
  },
  {
    id: 48,
    name: "Chile"
  },
  {
    id: 49,
    name: "China"
  },
  {
    id: 50,
    name: "Christmas Island"
  },
  {
    id: 51,
    name: "Cocos (Keeling) Islands"
  },
  {
    id: 52,
    name: "Colombia"
  },
  {
    id: 53,
    name: "Comoros"
  },
  {
    id: 54,
    name: "Congo"
  },
  {
    id: 55,
    name: "Congo (Democratic Republic of the)"
  },
  {
    id: 56,
    name: "Cook Islands"
  },
  {
    id: 57,
    name: "Costa Rica"
  },
  {
    id: 107,
    name: "Côte d'Ivoire"
  },
  {
    id: 58,
    name: "Croatia"
  },
  {
    id: 59,
    name: "Cuba"
  },
  {
    id: 60,
    name: "Curaçao"
  },
  {
    id: 61,
    name: "Cyprus"
  },
  {
    id: 62,
    name: "Czech Republic"
  },
  {
    id: 63,
    name: "Denmark"
  },
  {
    id: 64,
    name: "Djibouti"
  },
  {
    id: 65,
    name: "Dominica"
  },
  {
    id: 66,
    name: "Dominican Republic"
  },
  {
    id: 67,
    name: "Ecuador"
  },
  {
    id: 68,
    name: "Egypt"
  },
  {
    id: 69,
    name: "El Salvador"
  },
  {
    id: 70,
    name: "Equatorial Guinea"
  },
  {
    id: 71,
    name: "Eritrea"
  },
  {
    id: 72,
    name: "Estonia"
  },
  {
    id: 73,
    name: "Ethiopia"
  },
  {
    id: 74,
    name: "Falkland Islands (Malvinas)"
  },
  {
    id: 75,
    name: "Faroe Islands"
  },
  {
    id: 76,
    name: "Fiji"
  },
  {
    id: 77,
    name: "Finland"
  },
  {
    id: 78,
    name: "France"
  },
  {
    id: 79,
    name: "French Guiana"
  },
  {
    id: 80,
    name: "French Polynesia"
  },
  {
    id: 81,
    name: "French Southern Territories"
  },
  {
    id: 82,
    name: "Gabon"
  },
  {
    id: 83,
    name: "Gambia"
  },
  {
    id: 84,
    name: "Georgia"
  },
  {
    id: 85,
    name: "Germany"
  },
  {
    id: 86,
    name: "Ghana"
  },
  {
    id: 87,
    name: "Gibraltar"
  },
  {
    id: 88,
    name: "Greece"
  },
  {
    id: 89,
    name: "Greenland"
  },
  {
    id: 90,
    name: "Grenada"
  },
  {
    id: 91,
    name: "Guadeloupe"
  },
  {
    id: 92,
    name: "Guam"
  },
  {
    id: 93,
    name: "Guatemala"
  },
  {
    id: 94,
    name: "Guernsey"
  },
  {
    id: 95,
    name: "Guinea"
  },
  {
    id: 96,
    name: "Guinea-Bissau"
  },
  {
    id: 97,
    name: "Guyana"
  },
  {
    id: 98,
    name: "Haiti"
  },
  {
    id: 99,
    name: "Heard Island and McDonald Islands"
  },
  {
    id: 100,
    name: "Holy See"
  },
  {
    id: 101,
    name: "Honduras"
  },
  {
    id: 102,
    name: "Hong Kong"
  },
  {
    id: 103,
    name: "Hungary"
  },
  {
    id: 104,
    name: "Iceland"
  },
  {
    id: 105,
    name: "India"
  },
  {
    id: 106,
    name: "Indonesia"
  },
  {
    id: 108,
    name: "Iran (Islamic Republic of)"
  },
  {
    id: 109,
    name: "Iraq"
  },
  {
    id: 110,
    name: "Ireland"
  },
  {
    id: 111,
    name: "Isle of Man"
  },
  {
    id: 112,
    name: "Israel"
  },
  {
    id: 113,
    name: "Italy"
  },
  {
    id: 114,
    name: "Jamaica"
  },
  {
    id: 115,
    name: "Japan"
  },
  {
    id: 116,
    name: "Jersey"
  },
  {
    id: 117,
    name: "Jordan"
  },
  {
    id: 118,
    name: "Kazakhstan"
  },
  {
    id: 119,
    name: "Kenya"
  },
  {
    id: 120,
    name: "Kiribati"
  },
  {
    id: 166,
    name: "Korea (Democratic People's Republic of)"
  },
  {
    id: 211,
    name: "Korea (Republic of)"
  },
  {
    id: 121,
    name: "Kuwait"
  },
  {
    id: 122,
    name: "Kyrgyzstan"
  },
  {
    id: 123,
    name: "Lao People's Democratic Republic"
  },
  {
    id: 124,
    name: "Latvia"
  },
  {
    id: 125,
    name: "Lebanon"
  },
  {
    id: 126,
    name: "Lesotho"
  },
  {
    id: 127,
    name: "Liberia"
  },
  {
    id: 128,
    name: "Libya"
  },
  {
    id: 129,
    name: "Liechtenstein"
  },
  {
    id: 130,
    name: "Lithuania"
  },
  {
    id: 131,
    name: "Luxembourg"
  },
  {
    id: 132,
    name: "Macao"
  },
  {
    id: 133,
    name: "Macedonia (the former Yugoslav Republic of)"
  },
  {
    id: 134,
    name: "Madagascar"
  },
  {
    id: 135,
    name: "Malawi"
  },
  {
    id: 136,
    name: "Malaysia"
  },
  {
    id: 137,
    name: "Maldives"
  },
  {
    id: 138,
    name: "Mali"
  },
  {
    id: 139,
    name: "Malta"
  },
  {
    id: 140,
    name: "Marshall Islands"
  },
  {
    id: 141,
    name: "Martinique"
  },
  {
    id: 142,
    name: "Mauritania"
  },
  {
    id: 143,
    name: "Mauritius"
  },
  {
    id: 144,
    name: "Mayotte"
  },
  {
    id: 145,
    name: "Mexico"
  },
  {
    id: 146,
    name: "Micronesia (Federated States of)"
  },
  {
    id: 147,
    name: "Moldova (Republic of)"
  },
  {
    id: 148,
    name: "Monaco"
  },
  {
    id: 149,
    name: "Mongolia"
  },
  {
    id: 150,
    name: "Montenegro"
  },
  {
    id: 151,
    name: "Montserrat"
  },
  {
    id: 152,
    name: "Morocco"
  },
  {
    id: 153,
    name: "Mozambique"
  },
  {
    id: 154,
    name: "Myanmar"
  },
  {
    id: 155,
    name: "Namibia"
  },
  {
    id: 156,
    name: "Nauru"
  },
  {
    id: 157,
    name: "Nepal"
  },
  {
    id: 158,
    name: "Netherlands"
  },
  {
    id: 159,
    name: "New Caledonia"
  },
  {
    id: 160,
    name: "New Zealand"
  },
  {
    id: 161,
    name: "Nicaragua"
  },
  {
    id: 162,
    name: "Niger"
  },
  {
    id: 163,
    name: "Nigeria"
  },
  {
    id: 164,
    name: "Niue"
  },
  {
    id: 165,
    name: "Norfolk Island"
  },
  {
    id: 167,
    name: "Northern Mariana Islands"
  },
  {
    id: 168,
    name: "Norway"
  },
  {
    id: 169,
    name: "Oman"
  },
  {
    id: 170,
    name: "Pakistan"
  },
  {
    id: 171,
    name: "Palau"
  },
  {
    id: 172,
    name: "Palestine, State of"
  },
  {
    id: 173,
    name: "Panama"
  },
  {
    id: 174,
    name: "Papua New Guinea"
  },
  {
    id: 175,
    name: "Paraguay"
  },
  {
    id: 176,
    name: "Peru"
  },
  {
    id: 177,
    name: "Philippines"
  },
  {
    id: 178,
    name: "Pitcairn"
  },
  {
    id: 179,
    name: "Poland"
  },
  {
    id: 180,
    name: "Portugal"
  },
  {
    id: 181,
    name: "Puerto Rico"
  },
  {
    id: 182,
    name: "Qatar"
  },
  {
    id: 183,
    name: "Republic of Kosovo"
  },
  {
    id: 184,
    name: "Réunion"
  },
  {
    id: 185,
    name: "Romania"
  },
  {
    id: 186,
    name: "Russian Federation"
  },
  {
    id: 187,
    name: "Rwanda"
  },
  {
    id: 188,
    name: "Saint Barthélemy"
  },
  {
    id: 189,
    name: "Saint Helena, Ascension and Tristan da Cunha"
  },
  {
    id: 190,
    name: "Saint Kitts and Nevis"
  },
  {
    id: 191,
    name: "Saint Lucia"
  },
  {
    id: 192,
    name: "Saint Martin (French part)"
  },
  {
    id: 193,
    name: "Saint Pierre and Miquelon"
  },
  {
    id: 194,
    name: "Saint Vincent and the Grenadines"
  },
  {
    id: 195,
    name: "Samoa"
  },
  {
    id: 196,
    name: "San Marino"
  },
  {
    id: 197,
    name: "Sao Tome and Principe"
  },
  {
    id: 198,
    name: "Saudi Arabia"
  },
  {
    id: 199,
    name: "Senegal"
  },
  {
    id: 200,
    name: "Serbia"
  },
  {
    id: 201,
    name: "Seychelles"
  },
  {
    id: 202,
    name: "Sierra Leone"
  },
  {
    id: 203,
    name: "Singapore"
  },
  {
    id: 204,
    name: "Sint Maarten (Dutch part)"
  },
  {
    id: 205,
    name: "Slovakia"
  },
  {
    id: 206,
    name: "Slovenia"
  },
  {
    id: 207,
    name: "Solomon Islands"
  },
  {
    id: 208,
    name: "Somalia"
  },
  {
    id: 209,
    name: "South Africa"
  },
  {
    id: 210,
    name: "South Georgia and the South Sandwich Islands"
  },
  {
    id: 212,
    name: "South Sudan"
  },
  {
    id: 213,
    name: "Spain"
  },
  {
    id: 214,
    name: "Sri Lanka"
  },
  {
    id: 215,
    name: "Sudan"
  },
  {
    id: 216,
    name: "Suriname"
  },
  {
    id: 217,
    name: "Svalbard and Jan Mayen"
  },
  {
    id: 218,
    name: "Swaziland"
  },
  {
    id: 219,
    name: "Sweden"
  },
  {
    id: 220,
    name: "Switzerland"
  },
  {
    id: 221,
    name: "Syrian Arab Republic"
  },
  {
    id: 222,
    name: "Taiwan"
  },
  {
    id: 223,
    name: "Tajikistan"
  },
  {
    id: 224,
    name: "Tanzania, United Republic of"
  },
  {
    id: 225,
    name: "Thailand"
  },
  {
    id: 226,
    name: "Timor-Leste"
  },
  {
    id: 227,
    name: "Togo"
  },
  {
    id: 228,
    name: "Tokelau"
  },
  {
    id: 229,
    name: "Tonga"
  },
  {
    id: 230,
    name: "Trinidad and Tobago"
  },
  {
    id: 231,
    name: "Tunisia"
  },
  {
    id: 232,
    name: "Turkey"
  },
  {
    id: 233,
    name: "Turkmenistan"
  },
  {
    id: 234,
    name: "Turks and Caicos Islands"
  },
  {
    id: 235,
    name: "Tuvalu"
  },
  {
    id: 236,
    name: "Uganda"
  },
  {
    id: 237,
    name: "Ukraine"
  },
  {
    id: 238,
    name: "United Arab Emirates"
  },
  {
    id: 239,
    name: "United Kingdom of Great Britain and Northern Ireland"
  },
  {
    id: 34,
    name: "United States Minor Outlying Islands"
  },
  {
    id: 240,
    name: "United States of America"
  },
  {
    id: 241,
    name: "Uruguay"
  },
  {
    id: 242,
    name: "Uzbekistan"
  },
  {
    id: 243,
    name: "Vanuatu"
  },
  {
    id: 244,
    name: "Venezuela (Bolivarian Republic of)"
  },
  {
    id: 245,
    name: "Viet Nam"
  },
  {
    id: 35,
    name: "Virgin Islands (British)"
  },
  {
    id: 36,
    name: "Virgin Islands (U.S.)"
  },
  {
    id: 246,
    name: "Wallis and Futuna"
  },
  {
    id: 247,
    name: "Western Sahara"
  },
  {
    id: 248,
    name: "Yemen"
  },
  {
    id: 249,
    name: "Zambia"
  },
  {
    id: 250,
    name: "Zimbabwe"
  }
]
