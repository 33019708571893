import React from 'react'
import {withStyles} from '@material-ui/core/styles'

import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Button from 'components/core/Button'
import FormControl from '@material-ui/core/FormControl'
import {FilledInput, InputLabel} from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import clsx from 'clsx'
import IconButton from '@material-ui/core/IconButton'
import {useSnackbar} from 'notistack'
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    // backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    minWidth: 560
  },
  externalSession: {
    // maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  textField: {
    width: '100%'
  }
})

const SessionExternal = withStyles(styles)(({classes, session, handleClose, ...props}) => {

  const details = JSON.parse(session.urlJson)
  const {enqueueSnackbar} = useSnackbar()

  const copyURL = () => {
    navigator.clipboard.writeText(session.url)
    enqueueSnackbar('Link copied to clipboard!', {variant: 'success'})
  }

  const copyPasscode = () => {
    navigator.clipboard.writeText(details.passcode)
    enqueueSnackbar('Passcode copied to clipboard!', {variant: 'success'})
  }

  return <ThemeProvider>

  <Grid container spacing={2} className={classes.container} onClick={(e)=>e.stopPropagation()}>

    <Grid item xs={12}>

      {!!details.showURL&&<FormControl className={clsx(classes.textField)} variant="filled">
        <InputLabel htmlFor="filled-adornment-password">URL</InputLabel>
        <FilledInput
          id="filled-adornment-password"
          type={session.url}
          value={session.url}
          readOnly
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy to clipboard"
                onClick={copyURL}
                edge="end"
              >
              <FileCopyOutlinedIcon/>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>}

    </Grid>

    {details.passcode&&<Grid item xs={12}>

      <FormControl className={clsx(classes.textField)} variant="filled">
        <InputLabel htmlFor="filled-adornment-passcode">Passcode</InputLabel>
        <FilledInput
          id="filled-adornment-passcode"
          type={details.passcode}
          value={details.passcode}
          readOnly
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="copy to clipboard"
                onClick={copyPasscode}
                edge="end"
              >
                <FileCopyOutlinedIcon/>
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>

    </Grid>}

    {details.invitation&&<Grid item xs={12}>

      <FormControl className={clsx(classes.textField)} variant="filled">
        <InputLabel htmlFor="filled-adornment-invitation">Invitation</InputLabel>
        <FilledInput
          id="filled-adornment-invitation"
          type={details.invitation}
          value={details.invitation}
          readOnly
          multiline
          rows={5}
        />
      </FormControl>

    </Grid>}

    <Grid item container style={{paddingTop: 32}} justify='center' alignContent='center' alignItems='center'>
      <Grid>
        <Button href={session.url} target={'_blank'}> Launch session </Button>
      </Grid>
    </Grid>

  </Grid></ThemeProvider>
})

export default SessionExternal
