import {withStyles} from '@material-ui/core/styles'
import {useLocation, useParams, withRouter} from 'react-router-dom'
import React, {forwardRef, useEffect, useState} from 'react'
import {Menu, MenuItem, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import MaterialTable from 'material-table'
import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import Chip from '@material-ui/core/Chip'
import Button from 'components/core/Button'
import {EMAIL_TEMPLATE} from 'config'
import Dialog from '@material-ui/core/Dialog'
import CommunicationEditNew from 'components/core/CommunicationEditNew'

const styles = theme => ({
  presentersContainer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    // boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: 'calc(100vw - 300px)'
  },
  noPresentersYet: {
    display: 'flex',
    backgroundColor: 'white',
    padding: theme.spacing(2),
    borderRadius: '6px',
    border: '2px dashed',
    borderColor: palette['cerulean-blue'],
    minHeight: '145px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    fontSize: '18px',
    opacity: 0.3
  },
  fieldTitle: {
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  addBtn: {
    textAlign: 'right'
  },
  navTabs: {
    position: 'relative',
    marginBottom: theme.spacing(3)
  },
  downloadBtn: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(3)
  },
  title: {
    fontWeight: 'bolder',
    paddingBottom: theme.spacing(4)
  },
  totals: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3)
  },
  totalStats: {
    textAlign: 'center',
    position: 'relative',
    backgroundColor: 'white',
    padding: theme.spacing(2,2,4,2),
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(4),
    maxWidth: '1000px'
  },
  statValue: {
    paddingTop: theme.spacing(1),
    fontWeight: 'bold'
  },
})
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Presenters = withStyles(styles)(({classes, setPageTitle, setEventInfo, setNavBack, eventInfo}) => {

  // Hooks
  const [getData, data, isLoading] = useHttp()
  // eslint-disable-next-line
  const [getExcel, dataExcel] = useHttp()

  // State
  const [presenters, setPresenters] = useState()
  const [CsvData, setCsvData] = useState()

  // Deps
  const {eventId, page} = useParams()
  const q = useQuery()
  const filter = q.get('filter')

  const [defaultFilter] = useState(filter?[filter]:[])

  const endpoints = {
    'presenters' : api.entities.manage.getPresenters,
    'coauthors' : api.entities.manage.getCoAuthors,
    'submitters' : api.entities.manage.getSubmitters,
  }

  const getDataEndpoint = endpoints[page]

  useEffect(() => {
    getData(getDataEndpoint(null, null, {id: eventId}))
    // eslint-disable-next-line
  }, [page])

  // useEffect(() => {
  //   if (putData) {
  //     getData(getDataEndpoint(null, null, {id: eventId}))
  //   }
  //   // eslint-disable-next-line
  // }, [putData])

  // const refresh = () => {
  //   getData(getDataEndpoint(null, null, {id: eventId}))
  // }

  useEffect(() => {
    if (data) {
      // let presentersTransformed = []
      // data.presenters.forEach((r)=>{
      //   let accepted = 0 // pending
      //   if (r.accepted === true) {
      //     accepted = 1
      //   }
      //   if (r.accepted === false) {
      //     accepted = 2
      //   }
      //   presentersTransformed.push({
      //     ...r,
      //     status: accepted
      //   })
      // })
      setPresenters(data.presenters)
      setPageTitle(data.eventInfo.name)
      setNavBack({path: '/manage-events', name: 'events'})
      setEventInfo(data.eventInfo)
    }
  }, [data, setEventInfo, setNavBack, setPageTitle])

  const downloadEndpoints = {
    'presenters' : api.entities.manage.downloadPresenters,
    'coauthors' : api.entities.manage.downloadCoAuthors,
    'submitters' : api.entities.manage.downloadSubmitters,
  }

  const downloadEndPoint = downloadEndpoints[page]

  const downloadPresenters = () => {
    getExcel(downloadEndPoint(null, null, {id: eventId}))
  }

  // const handleChangePcm = (item) => {
  //   let items = [...presenters]
  //   let itemIndex = items.findIndex((e)=>e.id===item.id)
  //
  //   items[itemIndex].pcm = !!item.pcm
  //   setPresenters([...items])
  //
  //   if (item.pcm) {
  //     delPcm(api.entities.manage.delPcm(null, null, {id: item.id}))
  //   } else {
  //     setPcm(api.entities.manage.setPcm(null, null, {id: item.id}))
  //   }
  // }

  // const handleChangeAcceptance = (e, id) => {
  //   let items = [...presenters]
  //   let itemIndex = items.findIndex((e)=>e.id===id)
  //   const value = e.target.value
  //
  //   items[itemIndex].acceptedByManager = value
  //   items[itemIndex].accepted = value
  //   items[itemIndex].status = value?3:4
  //   setPresenters([...items])
  //
  //   if (!value) {
  //     delAccepted(api.entities.manage.delAccepted(null, null, {id: id}))
  //   } else {
  //     setAccepted(api.entities.manage.setAccepted(null, null, {id: id}))
  //   }
  //
  // }

  useEffect(() => {
    if (dataExcel) {
      setCsvData(dataExcel)
    }
  }, [dataExcel])

  useEffect(() => {
    if (CsvData) {
      let downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(CsvData[0])
      downloadLink.download = `${data.eventInfo.name} - ${page}.xlsx`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      setCsvData(null)
    }
  }, [CsvData, data, dataExcel,page])

  const [anchorEl, setAnchorEl] = useState(null)
  const [editCommunication, setEditCommunication] = useState(false)
  const [typeOfEmail, setTypeOfEmail] = useState(false)

  const handleClickNotify = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseNotify = (type) => {
    setAnchorEl(null)

    if (!type) {
      setTypeOfEmail(false)
      setEditCommunication(false)
      return
    }

    setTypeOfEmail(type)
    setEditCommunication(true)

  }

  const handleEditCloseCommunication = () => {
    setEditCommunication(false)
    setTypeOfEmail(false)
  }

  if (!presenters||isLoading) return <> </>

  const totalNum = presenters.length
  const acceptedNum = presenters.filter((r)=>r.status===1).length
  const rejectedNum = presenters.filter((r)=>r.status===2).length
  const pendingNum = presenters.filter((r)=>r.status===0).length
  const waitlistedNum = presenters.filter((r)=>r.status===3).length
  const withdrawnNum = presenters.filter((r)=>r.status===4).length

  const hasAccepted = acceptedNum>0
  const hasRejected = rejectedNum>0
  const hasWaitlisted = waitlistedNum>0
  const hasWithdrawn = withdrawnNum>0

  return <>
    <Fade in={true} timeout={1200}>
      <>

        <Grid container spacing={2} className={classes.presentersContainer}>

        <Grid item xs={12} sm={12} md={3}>
          <Typography component='h2' variant='h5' className={classes.title}>
            {page==='presenters'&&<>Presenters</>}
            {page==='coauthors'&&<>Co-Authors</>}
            {page==='submitters'&&<>Submitters</>}
          </Typography>
        </Grid>

          <Grid item xs>
            <Grid item container justify='flex-end' spacing={2}>
              <Grid item>
                <Chip label={`Total ${totalNum}`} variant='outlined'/>
              </Grid>

              <Grid item>
                <Chip label={`Accepted ${acceptedNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Rejected ${rejectedNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Pending ${pendingNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Wait-listed ${waitlistedNum}`} variant='outlined'/>
              </Grid>
              <Grid item>
                <Chip label={`Withdrawn ${withdrawnNum}`} variant='outlined'/>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Button size='smaller' color='primary' onClick={() => {
              downloadPresenters()
            }}> Download Excel file </Button>
          </Grid>

          <Grid item>

            <Button size='smaller' color='secondary' onClick={handleClickNotify}>
              Notify presenters
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={()=>handleCloseNotify(false)}
            >
              <MenuItem onClick={()=>hasAccepted&&handleCloseNotify(EMAIL_TEMPLATE.ACCEPTED_PRESENTERS)} disabled={!hasAccepted}>Accepted presenters</MenuItem>
              <MenuItem onClick={()=>hasRejected&&handleCloseNotify(EMAIL_TEMPLATE.REJECTED_PRESENTERS)} disabled={!hasRejected}>Rejected presenters</MenuItem>
              <MenuItem onClick={()=>hasWaitlisted&&handleCloseNotify(EMAIL_TEMPLATE.WAIT_LISTED_PRESENTERS)} disabled={!hasWaitlisted}>Waitlisted presenters</MenuItem>
              <MenuItem onClick={()=>hasWithdrawn&&handleCloseNotify(EMAIL_TEMPLATE.WITHDRAWN_PRESENTERS)} disabled={!hasWithdrawn}>Withdrawn presenters</MenuItem>

              <MenuItem onClick={()=>hasAccepted&&handleCloseNotify(EMAIL_TEMPLATE.NEW_ACCEPTED_PRESENTERS)} disabled={!hasAccepted}>New accepted presenters</MenuItem>
              <MenuItem onClick={()=>hasRejected&&handleCloseNotify(EMAIL_TEMPLATE.NEW_REJECTED_PRESENTERS)} disabled={!hasRejected}>New rejected presenters</MenuItem>
              <MenuItem onClick={()=>hasWaitlisted&&handleCloseNotify(EMAIL_TEMPLATE.NEW_WAIT_LISTED_PRESENTERS)} disabled={!hasWaitlisted}>New waitlisted presenters</MenuItem>
              <MenuItem onClick={()=>hasWithdrawn&&handleCloseNotify(EMAIL_TEMPLATE.NEW_WITHDRAWN_PRESENTERS)} disabled={!hasWithdrawn}>New withdrawn presenters</MenuItem>

              <MenuItem onClick={()=>hasWaitlisted&&handleCloseNotify(EMAIL_TEMPLATE.REGISTERED_ACCEPTED_PRESENTERS)} disabled={!hasWaitlisted}>Registered presenters (all accepted)</MenuItem>
              <MenuItem onClick={()=>hasWithdrawn&&handleCloseNotify(EMAIL_TEMPLATE.NEW_REGISTERED_ACCEPTED_PRESENTERS)} disabled={!hasWithdrawn}>Registered presenters (new accepted)</MenuItem>

            </Menu>

          </Grid>

          {/*<Grid item>*/}
          {/*  /!*<Button size='smaller' onClick={() => {*!/*/}
          {/*  /!*  uploadExcel()*!/*/}
          {/*  /!*}}> Upload Excel file </Button>*!/*/}
          {/*  <UploadExcel eventId={eventId} refresh={refresh}/>*/}
          {/*</Grid>*/}

        {/*<Grid item xs={12}>*/}

        {/*    <Grid container item className={classes.navTabs}>*/}
        {/*      <Grid item xs={12} sm={12}>*/}

        {/*      </Grid>*/}
        {/*    </Grid>*/}
        {/*    /!*<Box className={classes.downloadBtn} >*!/*/}
        {/*    /!*  <Button size='smaller'  upgradeTier={!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)} onClick={() => {*!/*/}
        {/*    /!*    if (!hasPerm(PERM_ACCESS_LEAD_RETRIEVAL,data.eventInfo.tierId)) return false*!/*/}
        {/*    /!*    downloadPresenters()*!/*/}
        {/*    /!*  }}> Download Excel </Button>*!/*/}
        {/*    /!*</Box>*!/*/}
        {/*</Grid>*/}

        {presenters&&<Grid item xs={12}>
          <MaterialTable
            key={presenters}
            icons={tableIcons}
            columns={[
              { title: 'Paper ID', field: 'paperId', editable: 'never'},
              { title: 'Title', field: 'title', editable: 'never'},
              { title: 'First name', field: 'firstName' , editable: 'never'},
              { title: 'Last name', field: 'lastName' , editable: 'never'},
              { title: 'Email', field: 'email', editable: 'never'},
              { title: 'Status', field: 'status', type: 'boolean', editable: 'never',
                lookup: { 0: 'Pending', 1: 'Accepted', 2: 'Rejected', 3: 'Wait-listed', 4: 'Withdrawn' }, render: rowData => {

                  // let color = 'inherit'
                  // let message = ''
                  //
                  // if (rowData.accepted === false) {
                  //   color = 'red'
                  //   message = 'Rejected by presenter'
                  //   if (rowData.acceptedByManager) {
                  //     message = 'Rejected by manager'
                  //   }
                  // }
                  // if (rowData.accepted === true) {
                  //   color = 'green'
                  //   message = 'Accepted by presenter'
                  //   if (rowData.acceptedByManager) {
                  //     color = 'blue'
                  //     message = 'Accepted by manager'
                  //   }
                  // }
                  return <>
                    <Grid container>
                      <Grid item xs={12}>
                        {rowData.status===0&&'Pending'}
                        {rowData.status===1&&'Accepted'}
                        {rowData.status===2&&'Rejected'}
                        {rowData.status===3&&'Wait-listed'}
                        {rowData.status===4&&'Withdrawn'}
                      </Grid>
                    </Grid>
                  </>
                },defaultFilter: defaultFilter},
              { title: 'Paper Title', field: 'paperTitle', editable: 'never'},
              { title: 'Registered', field: 'registered', editable: 'never',
                lookup: { true: 'Yes', false: 'No'}, render: rowData => {
                return <div>{rowData.registered?'Yes':'No'}</div>
                },defaultFilter: defaultFilter
              },
              // { title: 'Country code', field: 'countryCode', editable: 'never'},
              // { title: 'Affiliation / Company', field: 'institute', editable: 'never'},
              // { title: 'LinkedIn', field: 'linkedinUrl', editable: 'never', render: rowData => <IconButton aria-label="linked in profile" href={rowData.linkedinUrl} target={'_blank'}> <LinkedInIcon /> </IconButton>},
              // { title: 'Accepted', field: 'status', type: 'boolean', editable: 'never',
              //   lookup: { 0: 'Pending', 1: 'Accepted', 2: 'Rejected'}, render: rowData => {
              //
              //     let color = 'inherit'
              //     let message = ''
              //
              //     if (rowData.accepted === false) {
              //       color = 'red'
              //       message = 'Rejected by presenter'
              //       if (rowData.acceptedByManager) {
              //         message = 'Rejected by manager'
              //       }
              //     }
              //     if (rowData.accepted === true) {
              //       color = 'green'
              //       message = 'Accepted by presenter'
              //       if (rowData.acceptedByManager) {
              //         color = 'blue'
              //         message = 'Accepted by manager'
              //       }
              //     }
              //   return <>
              //       <Grid container>
              //         <Grid item xs={11}>
              //           <FormControl className={classes.formControl}>
              //             <Select type='select' name='accepted' fullWidth disabled={isLoading} value={rowData.accepted===null?'-':rowData.accepted} onChange={(e)=>handleChangeAcceptance(e,rowData.id)}>
              //               <MenuItem value={'-'} key={null} disabled>Pending</MenuItem>
              //               <MenuItem value={true} key={true}>Accepted</MenuItem>
              //               <MenuItem value={false} key={false}>Rejected</MenuItem>
              //             </Select>
              //             {/*{rowData.acceptedByManager&&<FormHelperText> set by manager </FormHelperText>}*/}
              //           </FormControl>
              //         </Grid>
              //         <Grid item xs={1}>
              //           <Box mt={1} ml={1} fontSize={'1.2em'} color={color}>
              //             <Tooltip title={message} aria-label={message}>
              //               <FiberManualRecordIcon fontSize={'inherit'} />
              //             </Tooltip>
              //           </Box>
              //         </Grid>
              //       </Grid>
              //     </>
              //   },defaultFilter: defaultFilter},
              // { title: 'PCM', field: 'pcm', type: 'boolean', editable: 'never', render: rowData => <>
              //     <Checkbox
              //       disabled={delPcmLoading||pcmLoading||isLoading||dataLoading}
              //       checked={rowData.pcm}
              //       onChange={()=>handleChangePcm(rowData)}
              //       inputProps={{ 'aria-label': 'Change PCM' }}
              //     />
              //   </>},
            ]}
            data={presenters}
            title=''
            options={{
              pageSize: 10,
              filtering: true,
              actionsColumnIndex:999,
              addRowPosition: 'first',
              headerStyle: {
                backgroundColor: palette['brown-grey'],
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: 14
              },
              rowStyle: {
                fontSize: 12,
                padding: 0
              }
            }}
          />
        </Grid>}

        {/*{(presenters&&presenters.length===0)&&<Grid item container className={classes.noPresentersYet}>*/}
        {/*  <Grid item>No presenters yet!</Grid>*/}
        {/*</Grid>}*/}

      </Grid>

        {/*<Grid container spacing={2} className={classes.totalStats}>*/}
        {/*  <Grid item xs={12}>*/}
        {/*    <Typography component='h2' variant='h5' className={classes.title} align='left'>*/}
        {/*      Totals*/}
        {/*    </Typography>*/}
        {/*  </Grid>*/}
        {/*  <Grid item container>*/}
        {/*    <Grid item xs> Presenters </Grid>*/}
        {/*    <Grid item xs> Tickets </Grid>*/}
        {/*    <Grid item xs> Amount </Grid>*/}
        {/*  </Grid>*/}
        {/*  <Grid item container>*/}
        {/*    <Grid item xs>*/}
        {/*      <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalPresenters} </Typography>*/}
        {/*      </Grid>*/}
        {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>{totals.totalTickets} </Typography></Grid>*/}
        {/*    <Grid item xs> <Typography component='h4' variant='h4' className={classes.statValue}>${totals.ticketsValue} </Typography></Grid>*/}
        {/*  </Grid>*/}
        {/*</Grid>*/}
    </>
    </Fade>
    <Dialog onClose={handleEditCloseCommunication} open={editCommunication} fullWidth maxWidth='md'>
      <CommunicationEditNew handleClose={handleEditCloseCommunication} eventId={eventId} eventInfo={data.eventInfo} defaultGroup={typeOfEmail} />
    </Dialog>
  </>
})

export default withRouter(Presenters)
