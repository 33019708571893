import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import {withRouter} from 'react-router-dom'
import {useAuth} from 'auth/AuthController'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import WhatshotIcon from '@material-ui/icons/Whatshot';
import {useHttp} from 'api/core'
import api from 'api'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button'
import {ReactComponent as Logo} from 'assets/anymeets-logo.svg'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import RootRef from '@material-ui/core/RootRef'
import {imageFile} from 'utils'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import PeopleIcon from '@material-ui/icons/People'
import ReactPlayer from 'react-player'
import Sponsors from 'components/core/OpenTok/Components/Sponsors'
import slugify from 'react-slugify'

const styles = theme => ({
  mainContainer: {
    height: '100%'
  },
  mainPage: {
    color: palette['greyish-brown']
  },
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'black'
  },
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
  list: {
    position: 'absolute',
    top: '65px',
    right: '0px',
    overflowY: 'auto',
    height: 'calc(100% - 85px)',
    width: '100%',
  },
  sponsorsList: {
    top: 0,
    position: 'relative',
    height: 'auto',
  },
  main: {
    position: 'relative'
  },
  sidebar: {
    position: 'relative',
    borderLeft: `1px solid rgba(0, 0, 0, 0.12)`
  },
  redBtn: {
    '&:hover': {
      borderColor: 'red',
      backgroundColor: 'rgba(255,0,0,1)'
    },
    backgroundColor: 'rgba(255,0,0,0.8)',
    color: 'white',
    borderColor: 'red'
  },
  header: {
    minHeight: '80px'
  },
  host: {
    color: 'orange'
  },
  bottomBar: {
    height: 'calc(100% - 160px)'
  },
  collapsedRoom: {
    minWidth: 'calc(100% - 68px)'
  },
  collapsedList: {
    maxWidth: '68px',
    overflow: 'hidden'
  },
  margin: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    color: palette['greyish-brown'],
  },
  toggleBox: {
    borderRight: `1px solid rgba(0, 0, 0, 0.12)`
  },
  sponsorPlaceholder: {
    height: '100%',
    alignSelf: 'center'
  },
  bottomSponsor: {
    marginTop: theme.spacing(4)
  },
  reactPlayer: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  overMessage: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 9,
    width: '100%',
    height: 64,
    backgroundColor: palette['cerulean-blue'],
    color: 'white'
  },
  withMessage: {
    top: 64,
    height: 'calc(100% - 64px) !important'
  }
})

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

// Default Config
const roomConfig = {
  width: '100%',
  height: '100%',
  configOverwrite: {
    enableClosePage: true,
  },
  interfaceConfigOverwrite: {
    enableClosePage: true,
    filmStripOnly: false,
    MOBILE_APP_PROMO: false,
    DEFAULT_REMOTE_DISPLAY_NAME: 'Anymeets Participant',
    PROVIDER_NAME: 'Anymeets',
    NATIVE_APP_NAME: 'Anymeets App',
    APP_NAME: 'Anymeets Rooms',
    JITSI_WATERMARK_LINK: 'https://anymeets.com',
    SUPPORT_URL: 'https://docs.anymeets.com',
    TOOLBAR_BUTTONS: ['microphone', 'camera', 'closedcaptions', 'desktop', 'fullscreen',
      'fodeviceselection', 'hangup', 'profile', 'recording',
      'etherpad', 'sharedvideo', 'settings', 'raisehand',
      'videoquality', 'filmstrip', 'invite', 'feedback', 'stats', 'shortcuts',
      'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone', 'chat',
      'e2ee'],
    INVITATION_POWERED_BY: false
  }
}

const HostActions = withStyles(styles)(({classes, attendee, room, userId})=>{

  const [reqAdmit, , isLoadingAdmit, hasErrorAdmit] = useHttp()
  const [reqRemove, , isLoadingRemove, hasErrorRemove] = useHttp()
  const [attendeeState, setAttendeeState] = useState(attendee.state)

  const removeAttendee = (id) => {
    reqRemove(api.entities.virtualRooms.remove(null,null,{code: room.onlineRoomId, id:attendee.id}))
    setAttendeeState(0)
  }

  useEffect(()=>{
    if (hasErrorRemove) {
      setAttendeeState(attendee.state)
    }
    //eslint-disable-next-line
  },[hasErrorRemove])

  const admit = () => {
    reqAdmit(api.entities.virtualRooms.admit(null,null,{code: room.onlineRoomId, id:attendee.id}))
    setAttendeeState(2)
  }

  useEffect(()=>{
    if (hasErrorAdmit) {
      setAttendeeState(attendee.state)
    }
    //eslint-disable-next-line
  },[hasErrorAdmit])

  const loading = isLoadingRemove || isLoadingAdmit

  return <>
    {attendeeState>1&&attendee.id!==userId&&<Box display='flex' pr={1}>
      <IconButton aria-label="delete" className={classes.margin} onClick={removeAttendee} disabled={loading}>
        <HighlightOffIcon />
      </IconButton>
    </Box>}
    {attendeeState===1&&<Box display='flex'><Button color='secondary' variant='contained' onClick={admit} disabled={loading}> Admit </Button></Box>}
    </>
})

const AutoAdmitControl = withStyles(styles)(({classes, hostData,room})=>{
  const [allow, setAllow] = useState(hostData.autoAdmit)
  const [reqAutoAdmit, , isLoading, hasError] = useHttp()

  const toggleAllow = () => {
    if (allow) {
      reqAutoAdmit(api.entities.virtualRooms.removeAutoAdmit(null,null,{code:room.uuid}))
    } else {
      reqAutoAdmit(api.entities.virtualRooms.autoAdmit(null,null,{code:room.uuid}))
    }
    setAllow(!allow)
  }

  useEffect(()=>{
    if (hasError) {
      setAllow(hostData.autoAdmit)
    }
    //eslint-disable-next-line
  }, [hasError])

  return <FormControlLabel
    control={<Switch size="small" checked={allow} onChange={toggleAllow} disabled={isLoading} />}
    label="Auto admit"
  />
})

const attendeeSort = (a,b) => {
  if (a.host) return -1
  // if (`${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}`) return -1
  // if (a.state===3) return -1
  return 1
}

const RoomIntegration = withStyles(styles)(({classes, history, setProfileDialogOpen, uuid, onExit, sponsors, placement, customWaitMessage, youtubeUrl, isSponsor}) => {

  const [getHostData, hostData] = useHttp(true)
  const [getAttendeeData, attendeeData] = useHttp(true)
  const [reqAddToWaitingList, , , hasErrorAddToWaitList] = useHttp()
  const [getData, data] = useHttp()

  const [room, setRoom] = useState()
  const [parentElement, setParentElement] = useState()
  const [, , , userInfo] = useAuth()
  const roomAPI = useRef(null)
  const [isHost, setIsHost] = useState(null)
  const [host, setHost] = useState()
  const [attendee, setAttendee] = useState()
  const [addedToWaitList, setAddedToWaitList] = useState(false)
  const [userId, setUserId] = useState()
  const domRef = React.useRef()
  const poller = useRef(null)
  const [listSize, setListSize] = useState()
  const [isCollapsed, setCollapsed] = useState(false)
  const [reconnect,setReconnect] = useState(0)
  const [joinedRoom, setJoinedRoom] = useState(false)

  // Initial Load
  useEffect(() => {
    getData(api.entities.virtualRooms.viewOne(null, null, {id: uuid}))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (data) {
      setRoom({...data, uuid})
      setIsHost(data.host)
    }
    // eslint-disable-next-line
  }, [data])

  const getHost = () => {
    getHostData(api.entities.virtualRooms.getHost(null, null, {code: uuid}))
  }

  const getAttendee = () => {
    getAttendeeData(api.entities.virtualRooms.getAttendee(null, null, {code: uuid}))
  }

  const exitRoom = () => {
    if (onExit) onExit()
  }

  useEffect(()=>{
    if (isHost===true) {
      // Call immediately once
      getHostData(api.entities.virtualRooms.getHost(null, null, {code: uuid}))
      clearInterval(poller.current)
      poller.current = setInterval(getHost,6000)
    } else if (isHost===false) {
      // Poll immediately once
      getAttendeeData(api.entities.virtualRooms.getAttendee(null, null, {code: uuid}))

      clearInterval(poller.current)
      poller.current = setInterval(()=>{
        getAttendee()
      },6000)
    }
    //eslint-disable-next-line
  },[isHost])

  useEffect(()=>{
    if (hostData) {
      setHost(hostData)
    }
  },[hostData])

  useEffect(()=>{
    //if chair leaves the room then after 3 polls
    // all users will be exited/kicked from the room
    if (reconnect === 3)
      exitRoom()
    //eslint-disable-next-line
  },[reconnect])

  useEffect(()=>{
    if (attendeeData) {
      setAttendee(attendeeData)
      setIsHost(attendeeData.host)
      if (attendeeData.state === 0 && attendeeData.hostJoined===true && joinedRoom) {
        exitRoom()
      }
      if ((attendeeData.hostJoined===false) && (roomAPI.current!==null)) {
        exitRoom()
      }
      if (attendeeData.state === 3) {
        setJoinedRoom(true)
      }
      if ((attendeeData.hostJoined===false) && (roomAPI.current!==null)) {
        setReconnect(reconnect+1)
      }
      if (attendeeData.state===1) {
        setAddedToWaitList(true)
      }
      if (attendeeData.state===3)
        setReconnect(0)
    }
    //eslint-disable-next-line
  },[attendeeData])

  const cleanUp = (string) => {
    const slug = string.replace(/\W/g, '')
    return slugify(slug)
  }

  useEffect(()=>{
    if (userInfo&&room&&parentElement) {
      // Load when ready, if is a host , attendee allowed, not waiting for host
      if (!roomAPI.current && ( (isHost) || (attendee && (attendee.state>1) && (!waitingHost))) ) {
        if (isHost) {
          roomConfig.interfaceConfigOverwrite.TOOLBAR_BUTTONS = [...roomConfig.interfaceConfigOverwrite.TOOLBAR_BUTTONS, 'recording', 'livestreaming']
        }
        // eslint-disable-next-line no-undef
        roomAPI.current = new JitsiMeetExternalAPI(room.domain, {
          ...roomConfig,
          roomName: cleanUp(room.label),
          parentNode: parentElement,
          configOverwrite: {
            startWithAudioMuted: isSponsor&&(!isHost),
            startWithVideoMuted: isSponsor&&(!isHost)
          },
          userInfo: {
            email: userInfo.info.email,
            displayName: `${userInfo.info.title ? userInfo.info.title : ''} ${userInfo.info.firstName} ${userInfo.info.lastName}`
          },
          jwt: room.jwt
        })
        roomAPI.current.on('readyToClose', (e) => {
          exitRoom()
        })
      }
    }
    // eslint-disable-next-line
  },[room, userInfo, parentElement, attendee])

  const addToWaitingList = () => {
    setAddedToWaitList(true)
    reqAddToWaitingList(api.entities.virtualRooms.addToWaitingList(null,null,{code:uuid}))
  }

  useEffect(()=>{
    if (hasErrorAddToWaitList) {
      setAddedToWaitList(false)
    }
  },[hasErrorAddToWaitList])

  function decodeToken(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const resizeList = () => {
    if (!domRef.current) {
      debounceResizeList()
      return
    }
    const currentPos = domRef.current.getBoundingClientRect()
    setListSize(window.innerHeight - currentPos.top - 25)
  }
  const debounceResizeList = debounce(
    resizeList,
    200
  )

  useEffect(() => {

      //resize listener
      window.addEventListener('resize', debounceResizeList);
      resizeList()
      return () => {
        window.removeEventListener('resize', debounceResizeList);
      }


    // eslint-disable-next-line
  }, [])

  useEffect(()=>{
    const token = localStorage.getItem('access_token')
    let info
    if (token) {
      info = decodeToken(token)
    }
    setUserId(parseInt(info.sub))

    return ()=> {
      clearInterval(poller.current)
    }
  },[])

  if (!uuid) return <> </>

  if (!room&&isHost===null) return <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height={'100%'} width={'100%'}>
      <Box display='flex' p={6}>
        <CircularProgress size={'5rem'} color='secondary' />
      </Box>
  </Box>

  if (room&&room.type!==1) {
    window.location.replace(room.link)
    return <> </>
  }

  const waitingRoom = !!(room.capacity&&(room.totalJoined>=room.capacity)&&attendee&&(attendee.state<=1))
  const waitingHost = (attendee&&attendee.hostJoined===false)
  const isBottom = placement==='bottom'

  return <Grid container className={classes.mainContainer}>
      <Grid item xs={isHost?isBottom?12:6:12} md={isHost?isBottom?12:8:12} lg={isHost?isBottom?12:9:12} className={clsx(classes.main, isCollapsed&&classes.collapsedRoom)}>

        <div ref={(elem)=>setParentElement(elem)} className={clsx(classes.container, placement==='bottom'&&classes.bottomBar)}>
          {roomAPI.current!==null&&<Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height={'100%'} width={'100%'} position={'absolute'} top={0} left={0} zIndex={-1}>
            <Box display='flex' p={6}>
              <CircularProgress size={'5rem'} color='secondary' />
            </Box>
          </Box>}
        </div>

        {placement==='bottom'&&<Box position='absolute' display='flex' bottom='0' left='0' height={'164px'} width={'100%'} style={{color: 'black'}}>

          <Box display='flex' width={360}>
            {sponsors && sponsors.length !== 0 &&
              <Sponsors sponsors={sponsors} eventId={room.eventInfo.id}/>
            }
            {((sponsors && sponsors.length === 0 )||!sponsors)&&<Grid item xs={12} className={clsx(classes.sponsorPlaceholder,placement==='bottom'&&classes.bottomSponsor)}>
              <Grid item container justify='center' alignContent='center' alignItems='center'>
                <Grid item>
                  <Logo/>
                </Grid>
              </Grid>
            </Grid>}
          </Box>

          <Box display='flex' width={'100%'}>
            {isHost&&host&&placement==='bottom'&&<Grid item container className={clsx()}>

              <Grid item xs={6} spacing={2} justify='center' className={clsx()} alignItems='center'>
                <Grid item container spacing={2} justify='center' alignItems='center' style={{paddingTop:'24px'}}>
                  <Grid item xs={6}>
                      <Grid item container justify='center' alignItems={'center'} alignContent={'center'} spacing={1}>
                        <Grid item>
                          <Box mt={0.5} color={palette['greyish-brown']}><PeopleIcon/></Box>
                        </Grid>
                        <Grid item>
                          {host.attendees.length}
                        </Grid>
                      </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Grid item container justify='center'>
                      <Grid item>
                        <AutoAdmitControl hostData={host} room={room}/>
                      </Grid>
                    </Grid>

                  </Grid>
                </Grid>

              </Grid>

              <Grid item xs={6}>
                <RootRef rootRef={domRef}>
                  <List className={clsx(classes.list,sponsors&&classes.sponsorsList)} style={{height: listSize}}>
                    {host.attendees.sort(attendeeSort).map((attendee, i)=>{
                      return <ListItem key={attendee.id}>
                        <ListItemAvatar>
                          {(attendee.state===1||attendee.state===2)&&<Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            badgeContent={attendee.host?<WhatshotIcon className={classes.host}/>:<></>}
                          ><Avatar alt={`${attendee.title} ${attendee.firstName} ${attendee.lastName}`} src={attendee.profilePic} />
                          </Badge>}

                          {attendee.state===3&&<Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            badgeContent={attendee.host?<WhatshotIcon className={classes.host}/>:<></>}
                          >
                            <StyledBadge
                              overlap="circle"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              variant="dot"
                            >
                              <Avatar alt={`${attendee.title} ${attendee.firstName} ${attendee.lastName}`} src={attendee.profilePic} />
                            </StyledBadge>
                          </Badge>}

                        </ListItemAvatar>
                        <ListItemText primary={`${attendee.firstName} ${attendee.lastName}`} />
                        <ListItemSecondaryAction>
                          <Box display='flex'>
                            <HostActions attendee={attendee} room={room} userId={userId} key={`${userId}-${attendee.state}`}/>
                          </Box>
                        </ListItemSecondaryAction>
                      </ListItem>
                    })}

                  </List>
                </RootRef>

              </Grid>

            </Grid>}

          </Box>




        </Box>}

        {waitingRoom&&(roomAPI.current===null)&&<>
          <Box display='flex' justifyContent={'center'} position='absolute' top={0} left={0} height={'100%'} width={'100%'}>
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
                <Box display='flex' maxWidth={600} style={{textAlign:'center'}}>
                  {addedToWaitList?
                    <>You are currently on the waitlist to enter this room.<br/> If you leave this page, as you will lose your place in line! <br/> If you want to look at a different poster, please open a tab in your browser.</>:`This room is full, do you wish to be added to the waiting list?`}
                </Box>
              {addedToWaitList===false&&<Box display='flex' pt={2}>
                <Button color='secondary' onClick={addToWaitingList} variant={'contained'}> Add to waiting list </Button>
              </Box>}
            </Box>
          </Box>
        </>}

        {waitingHost&&roomAPI.current===null&&<>
          <Box display='flex' justifyContent={'center'} position='absolute' top={0} left={0} height={'100%'} width={'100%'}>
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
              <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column' className={clsx(youtubeUrl&&classes.overMessage)}>
                {customWaitMessage?<Box display='flex'>{customWaitMessage}</Box>:`This session will begin shortly. Thank you for your patience.`}
              </Box>
              {youtubeUrl&&<Box display='flex'>
                  <ReactPlayer
                    key={youtubeUrl}
                    className={clsx(classes.reactPlayer, customWaitMessage&&classes.withMessage)}
                    url={youtubeUrl}
                    width='100%'
                    height='100%'
                    playing={true}
                    controls={true}
                  />
              </Box>}
            </Box>
          </Box>
        </>}

      </Grid>

    {isHost&&host&&placement!=='bottom'&&<Grid item xs={6} md={4} lg={3} className={clsx(classes.sidebar, sponsors&&classes.sponsorsSidebar, isCollapsed&&classes.collapsedList)}>

        <Grid item container spacing={2} justify='center' className={clsx(classes.header,sponsors&&classes.sponsorsHeader)} alignItems='center'>

          {sponsors&&!isCollapsed&&<Grid item xs={12}>
            <Sponsors sponsors={sponsors} eventId={room.eventInfo.id}/>
          </Grid>}

          {!isCollapsed&&<Grid item xs={6}>
            <Grid item container justify='center' alignItems={'center'} alignContent={'center'}>

              <Grid item className={classes.toggleBox} xs={6}>
                <IconButton aria-label="collapse" className={classes.margin}>
                  <KeyboardArrowRightIcon fontSize={'large'} onClick={()=>setCollapsed(true)} />
                </IconButton>
              </Grid>

              <Grid item xs={6}>
                <Grid item container justify='center' alignItems={'center'} alignContent={'center'} spacing={1}>
                  <Grid item>
                    <Box mt={0.5} color={palette['greyish-brown']}><PeopleIcon/></Box>
                  </Grid>
                  <Grid item>
                    {host.attendees.length}
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </Grid>}

          {!isCollapsed&&<Grid item xs={6}>
            <Grid item container justify='center'>
              <Grid item>
                <AutoAdmitControl hostData={host} room={room}/>
              </Grid>
            </Grid>
          </Grid>}

          {isCollapsed&&<Grid item xs={12} className={classes.toggleBox}>
            <IconButton aria-label="collapse" className={classes.margin}>
              <KeyboardArrowLeftIcon fontSize={'large'} onClick={()=>setCollapsed(false)} />
            </IconButton>
          </Grid>}

        </Grid>
        <Divider />
      <RootRef rootRef={domRef}>
        <List className={clsx(classes.list,sponsors&&classes.sponsorsList)} style={{height: listSize}}>
          {host.attendees.sort(attendeeSort).map((attendee, i)=>{
            return <ListItem key={attendee.id}>
              <ListItemAvatar>
                {(attendee.state===1||attendee.state===2)&&<Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={attendee.host?<WhatshotIcon className={classes.host}/>:<></>}
                ><Avatar alt={`${attendee.title} ${attendee.firstName} ${attendee.lastName}`} src={imageFile(attendee.imageId)} />
                </Badge>}

                {attendee.state===3&&<Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  badgeContent={attendee.host?<WhatshotIcon className={classes.host}/>:<></>}
                >
                  <StyledBadge
                    overlap="circle"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    variant="dot"
                  >
                    <Avatar alt={`${attendee.title} ${attendee.firstName} ${attendee.lastName}`} src={imageFile(attendee.imageId)} />
                  </StyledBadge>
                </Badge>}

              </ListItemAvatar>
              <ListItemText primary={`${attendee.firstName} ${attendee.lastName}`} />
              <ListItemSecondaryAction>
                <Box display='flex'>
                  <HostActions attendee={attendee} room={room} userId={userId} key={`${userId}-${attendee.state}`}/>
                </Box>
              </ListItemSecondaryAction>
            </ListItem>
          })}

        </List>
      </RootRef>
      </Grid>}



    </Grid>
})

export default withRouter(RoomIntegration)
