import React from 'react'
import Popover from '@material-ui/core/Popover'
import Avatar from '@material-ui/core/Avatar'
import {FILES_URL} from 'config'
import FaceIcon from '@material-ui/icons/Face'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import ParticipantToolTipView from 'components/core/ParticipantToolTipView'
import withStyles from '@material-ui/styles/withStyles'
import clsx from 'clsx'

const styles = theme => ({
  typography: {
    padding: theme.spacing(2),
  },
  avatar: {
    color: `${palette['greyish-brown']} !important`,
    background: 'none',
    backgroundColor: 'transparent !important',
    '&> img':{
      maxWidth: '100%'
    }
  },
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 240,
    border: '1px solid #fff',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  },
  chip: {
    color: palette['greyish-brown'],
    // fontWeight: 'bold',
    border: 0
  },
  bold: {
    fontWeight: 'bold'
  }
})

const ParticipantChipView = withStyles(styles)(({classes,participantObj, presenter, chair, index}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div key={index}>
        <Chip size={'medium'}
          avatar={<Avatar className={classes.avatar}>{participantObj.image?<img src={`${FILES_URL}/${participantObj.image}`} alt={`${participantObj.firstName} ${participantObj.lastName}`}/>:<FaceIcon />}</Avatar>}
          variant="outlined"
          color={presenter?'secondary':'primary'}
        label={<Box className={clsx((chair || presenter) && classes.bold)}>
          {
          // ${ participantObj.title ? participantObj.title : '' } 
          `${ participantObj.firstName } ${ participantObj.lastName } ${ chair ? ' (Chair)' : '' } ${ presenter ? ' (Presenter)' : '' }`}</Box>}
          onClick={handleClick} className={classes.chip} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box className={classes.tooltip}>
          <ParticipantToolTipView participant={participantObj} />
        </Box>
      </Popover>
    </div>
  )
})

export default ParticipantChipView
