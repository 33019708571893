import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Grid from "@material-ui/core/Grid";
// import ConferenceCardOverview from "components/core/ConferenceCardOverview";
import Button from "components/core/Button";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {useHttp} from "api/core";
import api from "api";
import {useSnackbar} from "notistack";
// import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Input from "@material-ui/core/Input";
import AssignmentReturnedOutlined from "@material-ui/icons/AssignmentReturnedOutlined";
import BuyCreditsDialog from "components/pages/ManageEvent/Plans/BuyCreditsDialog";

const styles = (theme) => ({
  noOfTickets: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: "white",
    fontWeight: "bold",
    borderRadius: "50%",
    fontSize: "24px",
    userSelect: "none",
  },
  noSelect: {
    userSelect: "none",
  },
  clickable: {
    cursor: "pointer",
  },
  dialog: {
    backgroundColor: "white",
  },
  disabled: {
    cursor: "default",
    opacity: 0.3,
  },
  couponBox: {
    padding: "0px 16px 32px",
  },
  textField: {
    width: "100%",
  },
  formControl: {
    width: "100%",
  },
  root: {
    width: "100%",
  },
  input: {
    width: "70px",
    textAlign: "right",
    border: "none",
    "&.MuiInput-underline:before": {
      borderBottom: "1px solid #ccc",
    },
  },
  checkoutForm: {
    marginTop: theme.spacing(2),
  },
});

const AddCreditsDialog = withStyles(styles)(({classes, handleClose, open, event, creditsType, creditsPriceList, eventCredits, eventDuration, setComplete}) => {
  const [putData, dataPut, isLoading, hasError] = useHttp();
  const {enqueueSnackbar} = useSnackbar();
  //const stripePromise = useRef()
  const [disabled, setDisabled] = useState(false);
  const [credits, setCredits] = useState({});
  const [value, setValue] = useState(0);
  const [balance, setBalance] = useState(0);
  const [confirm, setConfirm] = useState(false);
  const [openBalance, setOpenBalance] = useState();

  const handleConfirmOpen = () => {
    setConfirm(true);
  };

  const handleConfirmClose = () => {
    setConfirm(false);
  };
  
  useEffect(() => {
    if (eventCredits) {
        setBalance(eventCredits.balance);
        setComplete(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCredits]);

  useEffect(() => {
    if (creditsType) {
      const creditItem = creditsPriceList.find((item) => item.label === creditsType);
      setCredits(creditItem);
      if (credits.value * eventDuration <= eventCredits?.balance) {
        setValue(credits.value * eventDuration);
      } else {
        setValue(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsType]);

  useEffect(() => {
    if (credits) {
      if (credits.value < 1) {
        setValue(1);
      } else {
        if (credits.value * eventDuration <= eventCredits?.balance) {
          setValue(credits.value * eventDuration);
        } else {
          setValue(0);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credits, eventCredits]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    if(event.target.value.toString().length <= 7)
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 1) {
      setValue(1);
    } else {
      const checkValue = Math.floor(value / (credits?.value * eventDuration)) * (credits?.value * eventDuration);
      setValue(checkValue);
    }
  };

  const addCredit = (type) => {
    const boughtType = `${type}Bought`;
    const payLoad = {
        emailsBought: eventCredits.emailsBought,
        papersBought: eventCredits.papersBought,
        postersBought: eventCredits.postersBought,
        sponsorsBought: eventCredits.sponsorsBought,
        tokboxEnabled: eventCredits.tokboxEnabled,
        //   ...eventCredits,
        balance: eventCredits.balance - value,
        [boughtType]: eventCredits[boughtType] + Math.round((value / credits?.value) * 100) / (100 * eventDuration),
    };
      let formDataObj = new FormData();
      const blob = new Blob([JSON.stringify(payLoad)], {type: "application/json"});
      formDataObj.append("credits", blob);

      putData(api.entities.credits.updateCredits(formDataObj, null, {id: event.id}, true));
  };

  useEffect(() => {
    if (dataPut) {
      setConfirm(false);
        handleClose();
        setComplete(true)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataPut]);

  const handleOpenBuyCredits = (plan) => {
    setOpenBalance(plan);
  };
 
  const handleCloseBuyCredits = (success) => {
    setOpenBalance(null);
    
      if (success === true){
        setTimeout(() => {
          enqueueSnackbar("Credits added successfully.", { variant: "success" });     
        }, 1500);     
      }
    };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{paper: classes.dialog}}
      fullWidth
      disableBackdropClick={disabled}
      disableEscapeKeyDown={disabled}>
      <Box p={3} pt={1}>
        <DialogTitle id="alert-dialog-title" style={{padding: "16px"}}>
          <Grid container xs={12}>
            <Grid item xs={6}>
              <Box display="flex" fontSize={18} fontWeight={"bold"}>
                {" "}
                Add {creditsType}
              </Box>
              <Box display="flex" fontSize={14} alignItems="end" justify="flex-end">
                {" "}
                {creditsType === "sponsors" && (
                  <small>
                    Current event duration is {eventDuration} {eventDuration > 1 ? "days" : "day"}
                  </small>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <small style={{fontSize: "12px", float: "right", textAlign: "right", color: credits.value * eventDuration > eventCredits?.balance || value > balance ? "red" : "#000"}}>
                Event credit balance : {balance} <br />
                {credits.value * eventDuration > eventCredits?.balance ? <span>Not enough credits available</span> : ""}
              </small>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent style={{minHeight: "180px"}}>
          <Grid container spacing={2} className={classes.planBuy}>
            <Grid item xs={12}>
              <div className={classes.root}>
                <Grid container spacing={2} alignItems="center" justify="space-between">
                  <Grid item>
                    <AssignmentReturnedOutlined />
                  </Grid>
                  <Grid item xs>
                    <Slider
                      value={typeof value === "number" ? value : 0}
                      step={credits.value < 1 ? 1 : credits?.value * eventDuration}
                      max={Math.floor(balance / (credits?.value * eventDuration)) * (credits?.value * eventDuration)}
                      onChange={handleSliderChange}
                      aria-labelledby="input-slider"
                    />
                  </Grid>
                  <Grid item>
                    <Input
                      fullWidth
                      className={classes.input}
                      value={value}
                      margin="dense"
                      onChange={handleInputChange}
                      onBlur={handleBlur}
                      inputProps={{
                        step: credits?.value < 1 ? 1 : credits?.value * eventDuration,
                        min: credits?.value < 1 ? 1 : credits?.value <= balance ? 0 : 0,
                        max: Math.floor(balance / (credits?.value * eventDuration)) * (credits?.value * eventDuration),
                        type: "number",
                        "aria-labelledby": "input-slider",
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box pt={2}>
                  <Divider />
                </Box>
              </Grid>
              <Grid item container justify="space-between" alignItems="center" spacing={2} xs={12}>
                <Grid item>
                  <Box fontSize={14}>
                    Selected credits{" "}
                    <Box fontWeight="bold" fontSize={17}>
                      {value}{" "}
                      <small>
                        ({credits?.value} credits per {creditsType?.slice(0, -1)}
                        <small>{creditsType === "sponsors" && " per event day"})</small>
                      </small>
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  {credits !== null && (
                    <Box fontWeight="bolder" color="rgb(0, 120, 173)" fontSize={17}>
                      <small>Additional {creditsType}:</small> {~~(Math.round((value / (credits?.value * eventDuration)) * 100) / 100)}
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} style={{textAlign: "center"}}>
                <Box display="inline-block" pt={1} mr={1} justifyContent="left" alignContent="flex-start">
                  <Button autoWidth size="smaller" variant="outlined" onClick={handleClose}>
                    Close
                  </Button>
                </Box>
                <Box display="inline-block" pt={1} mr={1} justifyContent="right" alignContent="flex-end">
                  <Button autoWidth size="smaller" disabled={value === 0 || value > balance ? true : false} onClick={handleConfirmOpen}>
                    Add {creditsType}
                  </Button>
                </Box>
                {/* {(value === 0 || value > balance) && <Box display="inline-block" pt={1} mr={1} justifyContent="right" alignContent="flex-end">
                <Button autoWidth size="smaller" onClick={() => handleOpenBuyCredits("balance")}>
                  Buy credits
                </Button>
                </Box> }                */}
              </Grid>
            </Grid>
            <Dialog open={confirm} onClose={handleConfirmClose} aria-labelledby="draggable-dialog-title">
              <DialogTitle style={{fontSize: "14px"}} id="draggable-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <span style={{color: "#000", fontSize: "16px", margin: "5px 20px"}}>
                    Are you sure you want to add <span style={{fontSize: "17px", fontWeight: "bold"}}>{~~(Math.round((value / (credits?.value * eventDuration)) * 100) / 100)} </span>
                    {creditsType} for <span style={{fontSize: "17px", fontWeight: "bold"}}>{value}</span> credits?
                  </span>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Grid item xs={12} style={{textAlign: "center"}}>
                  <Box display="inline-block" pt={1} pb={2} mr={1} justifyContent="left" alignContent="flex-start">
                    <Button autoFocus autoWidth size="smaller" onClick={handleConfirmClose} variant="outlined">
                      Cancel
                    </Button>
                  </Box>
                  <Box display="inline-block" pt={1} pb={2} mr={1} justifyContent="right" alignContent="flex-end">
                    <Button autoWidth size="smaller" onClick={() => addCredit(creditsType)}>
                      Confirm
                    </Button>
                  </Box>
                </Grid>
              </DialogActions>
            </Dialog>
          </Grid>
        </DialogContent>
      </Box>
      {openBalance && (
        <BuyCreditsDialog
          handleClose={handleCloseBuyCredits}
          creditsPriceList={creditsPriceList}
          eventCredits={credits}
          open={openBalance}
          creditsType={openBalance}
          event={{...event}}
          setComplete={setComplete}
        />
      )}      
    </Dialog>
  );
});

export default AddCreditsDialog;
