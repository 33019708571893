import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import ConferenceCardOverview from 'components/core/ConferenceCardOverview'
import TicketCardView from 'components/core/TicketCardView'
import clsx from 'clsx'
import {IconMinusCircleBorderGray, IconPlusCircleBorderGray} from 'components/core/icons'
import Button from 'components/core/Button'
import {Button as MUIButton} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import {Elements} from '@stripe/react-stripe-js'
import CheckoutForm from 'components/pages/Event/Tickets/CheckoutForm'
import Dialog from '@material-ui/core/Dialog'
import React, {useEffect, useRef, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import api from 'api'
import {loadStripe} from "@stripe/stripe-js/pure"
import {useHttp} from 'api/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import language from "components/language";
import {enUS, el, de, fr, it} from "date-fns/esm/locale";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = theme => ({
  noOfTickets: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '50%',
    fontSize: '24px',
    userSelect: 'none'
  },
  noSelect: {
    userSelect: 'none'
  },
  clickable: {
    cursor: 'pointer'
  },
  dialog: {
    backgroundColor: 'white'
  },
  disabled: {
    cursor: 'default',
    opacity: 0.3
  },
  couponBox: {
    padding: '0px 16px 32px'
  },
  textField: {
    width: '100%'
  }
})

const TicketDialog = withStyles(styles)(({classes, handleClose, open, event, buyingTicket}) => {
  const [numberOfTickets, setNumberOfTickets] = useState(1)
  const [cannotAddTickets, setCannotAddTickets] = useState(true)
  const [cannotRemoveTickets, setCannotRemoveTickets] = useState(false)
  const [publishableKey, setPublishableKey] = useState()
  const [clientSecret, setClientSecret] = useState()
  const [stripePromise, setStripePromise] = useState()  
  //const stripePromise = useRef()
  const [reqRegister, registerData, isLoading, hasError] = useHttp()
  const [reqDiscount, discountData, , hasErrorDiscount] = useHttp()
  const [disabled, setDisabled] = useState()
  const [discount, setDiscount] = useState(0)
  const [discountCode, setDiscountCode] = useState()

  useEffect(() => {
    setCannotAddTickets(((buyingTicket && buyingTicket.remaining !== null && buyingTicket.remaining - numberOfTickets === 0) || (buyingTicket && numberOfTickets === buyingTicket.maxQuantityToBuy)))
    setCannotRemoveTickets((numberOfTickets === 1))
  }, [buyingTicket, numberOfTickets])

  const addTickets = () => {
    if (isLoading) return
    if (cannotAddTickets) return
    setNumberOfTickets(numberOfTickets + 1)
  }

  const removeTickets = () => {
    if (isLoading) return
    if (cannotRemoveTickets) return
    setNumberOfTickets(numberOfTickets - 1)
  }

  const buyTickets = () => {
    const payLoad = {
      ticketId: buyingTicket.id,
      quantity: numberOfTickets,
      code: discountCode ? discountCode : null,
      ticketPriceConfirmation: buyingTicket.price,
      discountPercentageConfirmation: discount,
      attendeesPerTicketConfirmation: buyingTicket.attendeesPerTicket
    }
    reqRegister(api.entities.registrations.register(payLoad))
  }

  const applyCode = () => {
    const payLoad = {
      ticketId: buyingTicket.id,
      code: discountCode
    }
    reqDiscount(api.entities.registrations.getDiscount(null, payLoad))
  }

  useEffect(() => {
    if (discountData) {
      setDiscount(discountData.discountPercentage)
    }
  }, [discountData])

  // useEffect(() => {
  //   if ((!registerData && hasError)) {
  //     handleClose()
  //     return
  //   }
  //   if (registerData) {
  //     if (buyingTicket.price !== 0) {
  //       setPublishableKey(registerData.publishableKey)
  //       setClientSecret(registerData.clientSecret)
  //       if (!stripePromise.current) {
  //       //  stripePromise.current = async () => {await loadStripe(registerData.publishableKey)}
  //       }
  //     } else {
  //       handleClose(true)
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [registerData, hasError])

  const initializeStripe = async (registerData, hasError) => {
    if (!registerData && hasError) {
      handleClose(false);
      return;
    }
    if (registerData) {
      if (buyingTicket.price !== 0) {
        setPublishableKey(registerData.publishableKey);
        setClientSecret(registerData.clientSecret);
        if (!stripePromise) {
          setStripePromise(await loadStripe(registerData.publishableKey));
        }
      } else {
        handleClose(true);
      }
    }
  };

  useEffect(() => {
    initializeStripe(registerData, hasError);
    // eslint-disable-next-line
  }, [registerData, hasError]);    

  useEffect(()=>{
    if (hasErrorDiscount) {
      setDiscount(0)
      setDiscountCode('')
    }
    //eslint-disable-next-line
  },[hasErrorDiscount])

  return <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    classes={{paper: classes.dialog}}
    fullWidth
    disableBackdropClick={disabled}
    disableEscapeKeyDown={disabled}
  ><Box p={3} pt={1}>

    <DialogTitle id="alert-dialog-title">
      <Box display='flex' fontSize={18} fontWeight={'bold'}> {language.buyTickets} </Box>
    </DialogTitle>

    <DialogContent>

      <Grid container spacing={2} className={classes.ticketsBuy}>

        <Grid xs={12}>
          <Box p={2}>
            <ConferenceCardOverview event={event.eventInfo} viewOnly={true}/>
          </Box>
        </Grid>

        <Grid xs={12}>
          <Box p={2}>
            <TicketCardView event={event} discount={discount} ticket={{
              ...buyingTicket,
              remaining: buyingTicket && buyingTicket.remaining !== null ? buyingTicket.remaining - numberOfTickets : null
            }} viewOnly={true} p={1}/>
          </Box>
        </Grid>

        {(!publishableKey) && (!clientSecret) && <><Grid xs={12} className={classes.noSelect}>

          <Grid item container justify='flex-end' alignContent='center' alignItems='center' spacing={2} className={classes.couponBox}>
            <Grid item xs={12}>
              <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{language.enterCouponCode}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={'text'}
                  value={discountCode} onChange={(e) => setDiscountCode(e.target.value)}
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <MUIButton onClick={applyCode} variant='contained'> {language.applyCode} </MUIButton>
                    </InputAdornment>
                  }
                  labelWidth={150}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' flexDirection='column'>
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'>
              <Box display='flex' ml={5} m={1} fontWeight='bold' fontSize='24px'>
                  {language.get}
              </Box>
              <Box display='flex' m={1} className={clsx(classes.clickable, cannotAddTickets && classes.disabled)}>
                <IconPlusCircleBorderGray onClick={() => addTickets()}/>
              </Box>
              <Box display='flex' m={1}>
                <Box display='flex' alignContent='center' alignItems='center' justifyContent='center'
                     className={classes.noOfTickets}>
                  <Box display='flex'>
                    {numberOfTickets}
                  </Box>
                </Box>
              </Box>
              <Box display='flex' m={1} className={clsx(classes.clickable, cannotRemoveTickets && classes.disabled)}>
                <IconMinusCircleBorderGray onClick={() => removeTickets()}/>
              </Box>
              <Box display='flex' m={1} fontWeight='bold' fontSize='24px'>
                {language.tickets}
              </Box>
            </Box>
            <Box display='flex' pt={3} flexDirection='row' justifyContent='flex-end'>
              <Box display='flex'>
                <Button onClick={buyTickets} disabled={isLoading} size='small' color='secondary'>
                  {buyingTicket && buyingTicket.price === 0 ? language.getNow : language.proceedToPayment}
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>

        </>
        }


      </Grid>

      {publishableKey && clientSecret && <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          <Grid item container justify='space-between' alignItems='center'>
            <Grid item>
              <Box fontWeight='bold'>
                <Box color='rgb(0, 120, 173)' fontSize={26} display='inline'> {numberOfTickets}<Box fontSize={14}
                                                                                                    display='inline'>x </Box></Box>{buyingTicket.name}
              </Box>
            </Grid>
            <Grid item>
              <Box fontWeight='bolder' color='rgb(0, 120, 173)' fontSize={26}>
                {language.total}:
                ${buyingTicket.price * numberOfTickets - ((discount / 100) * buyingTicket.price * numberOfTickets)}
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider/>
          </Grid>

          <Grid item xs={12}>
            {stripePromise && <Elements stripe={stripePromise}>
              <CheckoutForm clientSecret={clientSecret} handleClose={handleClose} setDisabled={setDisabled}
                            disabled={disabled}/>
            </Elements>}
          </Grid>
        </Grid>
      </>}

    </DialogContent>
  </Box>
  </Dialog>
})

export default TicketDialog
