import {withStyles} from '@material-ui/core/styles'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {palette} from 'theme'
import {OTPublisher, OTSubscriber, createSession} from 'opentok-react'
import Box from '@material-ui/core/Box'
import RootRef from '@material-ui/core/RootRef'
import {withRouter} from 'react-router-dom'
import clsx from 'clsx'
import api from 'api'
import {useHttp} from 'api/core'
import {useSnackbar} from 'notistack'
// import {presentation_1} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_1'
// import {presentation_2} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_2'
// import {presentation_3} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_3'
// import {presentation_4} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_4'
// import {presentation_5} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_5'
// import {presentation_6} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_6'
// import {presentation_7} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_7'
// import {presentation_8} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_8'
// import {presentation_9} from 'components/core/OpenTok/Components/Layouts/Presentation/presentation_9'
import {discussion_1} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_1'
import {discussion_2} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_2'
import {discussion_3} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_3'
import {discussion_4} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_4'
import {discussion_5} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_5'
import {discussion_6} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_6'
import {discussion_7} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_7'
import {discussion_8} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_8'
import {discussion_9} from 'components/core/OpenTok/Components/Layouts/Discussion/discussion_9'
import {presentation_vert_2} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_2'
import {presentation_vert_3} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_3'
import {presentation_vert_4} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_4'
import {presentation_vert_5} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_5'
import {presentation_vert_6} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_6'
import {presentation_vert_7} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_7'
import {presentation_vert_8} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_8'
import {presentation_vert_9} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_9'
import {presentation_vert_1} from 'components/core/OpenTok/Components/Layouts/PresentationVertical/presentation_vert_1'
import {DEV_MODE, TEST_MODE} from 'config'
import {imageFile} from 'utils'

const styles = theme => ({
  container: {
    color: palette['greyish-brown'],
    overflow: 'hidden'
  },
  box: {
    position: 'relative',
    overflow: 'hidden',
    zIndex: 3,
  },
  video: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
    opacity:1,
    borderRadius: 4
  },
  videoHidden: {
    display: 'none'
  },
  animated: {
    'transition-property': 'all',
    'transition-duration' : '0.5s',
    transition: 'clip-path 1s',
    opacity: 0
  },
  presentationViewContainer: {
    justifyContent: 'center',
    height: '30%',
    marginTop: '40%',
    opacity: 1,
    zIndex: 3
  },
  presentationViewParticipant: {
    width: '20%',
    height: '100%',
    opacity: 1,
    zIndex: 3
  },
  singleViewContainer: {
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 3
  },
  singleViewParticipant: {
    width: '75%',
    height: '100%',
    opacity: 1,
    zIndex: 3
  },
  dualViewContainer: {
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    opacity: 1,
    zIndex: 3
  },
  dualViewParticipant: {
    width: '100%',
    height: '75%',
    opacity: 1,
    zIndex: 3
  },
  quadViewContainer: {
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    opacity: 1,
    zIndex: 3
  },
  quadViewParticipant: {
    width: '50%',
    height: '50%',
    opacity: 1,
    zIndex: 3
  },
  multiViewContainer: {
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    opacity: 1,
    zIndex: 3
  },
  multiViewParticipant: {
    width: `${100 / 3}%`,
    height: `${100 / 3}%`,
    opacity: 1,
    zIndex: 3
  },
  presenter: {
    position: 'absolute',
    top: 0,
    left: '15.5%',
    width: '69%',
    height: '70%',
    opacity: 1,
    zIndex: 3
  },
  videoItem: {
    boxSizing: 'border-box',
    borderRadius: '32px',
    padding: theme.spacing(1),
    overflow: 'hidden',
    zIndex: 3
  },
  circleClip: {
    clipPath: 'circle(40% at 50% 50%)'
  },
  screenShare: {
    width: '68.5%',
    left: '15.7%',
    zIndex: 3
  },
  openTokItem: {
    position: 'absolute',
    border: '1px solid #121212',
    borderRadius: 4,
    opacity:0
  },
  presentation: {

  },
  nameTag: {
    position:'absolute',
    fontSize:'11px',
    fontWeight: 600,
    textShadow: '0 0 1px black',
    zIndex: 1,
    bottom:0,
    left:0,
    color:'white',
    whiteSpace:'nowrap',
    textOverflow:'ellipsis',
    overflow:'hidden',
    boxSizing:'border-box',
    width: '100%',
    padding: 8,
  },
  videoAvatar: {
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    '& img' : {
      display: 'flex',
      maxWidth: '25%',
      height: 'auto',
      borderRadius: '50%',
      boxSizing: 'border-box',
      border: '1px solid #232323',
      overflow: 'hidden',
      zIndex: 1
    }
  },

})

const OpenTok = withStyles(styles)(({classes, sessionState, history, audioSource, videoSource, setPublishing, publishVideo, publishAudio, publishingScreen, presentingFile, publishingCanvas, setPublishingScreen, setPublishingCanvas, presentingQuestion, presentingSpeaker, setPresentingQuestion, setPresentingSpeaker, session, setStreamers, setPresentingFile, canvasVideoTrack, deviceSetup, presentationFile, inCall}) => {

  //.........
  const {
    // roomSessionId,
    participants,
    participantId,
    isCoach,
    isStarted,
    layoutMode,
    autoMode,
    apiKey,
    sessionId,
    token,
    isChair,
    isViewer,
    roomId,
    canRaiseHands,
    bannerStreamerUserId,
    // isLive,
    type,
    sessionHelper,
  } = sessionState

  const {enqueueSnackbar} = useSnackbar()

  // References
  const domRef = useRef()
  const otPublisher = useRef()
  const [streams, setStreams] = useState([])

  // Requests
  const [sendStream, , , setStreamError] = useHttp(true)

  // eslint-disable-next-line
  const [delPresent,delPresentData,delPresentLoading,delPresentError] = useHttp()

  // Keep log
  useEffect(()=>{
    if (setStreamError) {
      console.log('stream error')

      if (setStreamError.reqData) {
        console.log(`Failed to set stream for: ${setStreamError.reqData.id} / ${setStreamError.reqData.streamId}`)
      }
      if (setStreamError.reqData&&setStreamError.reqData.try!==3) {
        const payLoad = {
          id : setStreamError.reqData.id,
          streamId: setStreamError.reqData.streamId
        }
        sendStream(api.entities.virtualRoom.setStream(null, null, payLoad, false, {...payLoad,try:setStreamError.reqData.try+1}))
        console.log(`Retrying for ${setStreamError.reqData.try===1?'2nd':'3rd'} time`)
      }
    }
    // eslint-disable-next-line
  },[setStreamError])

  // send request when stream created
  const onStreamCreated = useCallback(stream => {
    sendStream(api.entities.virtualRoom.setStream(null, null, {id: roomId, streamId: stream.id}, false, {id: roomId, streamId: stream.id,try:1}))
    // eslint-disable-next-line
  }, [sendStream])

  // send request when stream destroyed
  const onStreamDestroyed = useCallback(stream => {
    if (stream.videoType==='screen') {
      // setStreaming({...streaming, publishingScreen: false})
      setPublishingScreen(false)
    }
    sendStream(api.entities.virtualRoom.clearStream(null, null, {id: roomId, streamId: stream.id}))
    // eslint-disable-next-line
  }, [sendStream])

  const publisherPropertiesCamera = useCallback(()=>{
    return {
      publishVideo: videoSource?publishVideo:false,
      publishAudio: audioSource?publishAudio:false,
      resolution: '1280x720',
      videoSource: videoSource?videoSource:undefined, // use predefined
      audioSource: audioSource?audioSource:undefined,  // use predefined
      showControls: false,
      name: participantId,
      // style: {
      //   nameDisplayMode: 'off',
      //   buttonDisplayMode: 'off',
      // },
      mirror: false,
      height: '100%',
      width: '100%',
      zIndex: 3
    }},[videoSource,publishVideo,audioSource,publishAudio,participantId])

  useEffect(()=>{
    if (otPublisher.current) {
      const publisher = otPublisher.current.getPublisher()
      if (publisher) {
        publisher.setAudioSource(audioSource)
      }
    }
  },[audioSource])

  // Publisher Properties ( For streaming Screen )
  const publisherPropertiesScreensharing = {
    // audioFallbackEnabled: false,
    resolution: '1280x720',
    frameRate: publishingScreen?15:canvasVideoTrack?1:undefined,
    publishVideo: true,
    publishAudio: false,
    showControls: false,
    videoSource: publishingScreen?'screen':canvasVideoTrack?canvasVideoTrack:false,
    audioSource: false,
    name: participantId,
    style: {
      nameDisplayMode: 'off',
      buttonDisplayMode: 'off'
    },
    mirror: false,
    height: '100%',
    width: '100%',
    zIndex: 3,
    fitMode: 'contain'
  }

  // Publisher Properties ( For streaming Banner)
  const publisherPropertiesBanner = {
    publishAudio: false,
    showControls: false,
    videoSource: canvasVideoTrack?canvasVideoTrack:undefined,
    audioSource: undefined
  }

  const subscriberProperties = (participantId) => ({
    showControls: true,
    style: {
      nameDisplayMode: 'off',
      buttonDisplayMode: 'off',
    },
    mirror: false,
    height: '100%',
    width: '100%',
    zIndex: 3
  })

  const publisherEventHandlers = {
    streamCreated: event => {
      onStreamCreated && onStreamCreated(event.stream);
    },
    streamDestroyed: event => {
      console.log('Stream Destroyed')
      if (!event.stream) return false
      if (event.stream.videoType==='screen') {
        // setStreaming({...streaming, publishingScreen: false})
        setPublishingScreen(false)
      } else if (presentingFile||presentingQuestion||presentingSpeaker) {
        // setStreaming({...streaming, publishingCanvas: false, presentingFile: false})
        setPublishingCanvas(false)
        setPresentingFile(false)
        if (presentingQuestion) {
          setPresentingQuestion(false)
        }
        if (presentingSpeaker) {
          setPresentingSpeaker(false)
        }
      }else{
        // setStreaming({...streaming, publishing: false})
        setPublishing(false)
      }
      onStreamDestroyed && onStreamDestroyed(event.stream);
    },
    videoElementCreated: event => {

    },
    onPublisherError: error => {
      if (error.code === 1500) {
        alert('Please allow camera access first')
        // setStreaming({...streaming, publishing: false})
        setPublishing(false)
      }
      console.log('PUBLISHER ERROR')
    },
    onError: event => {
      console.log('onError Publisher Error')
      console.log(event)
    },
    mediaStopped: event => {
      console.log('Media Stopped')
      console.log(event)
    }
  }

  const shareScreenPublisherEvents = {
    accessDenied: event => {
      if (publishingScreen) {
        // setStreaming({...streaming, publishingScreen: false})
        setPublishingScreen(false)

        if (autoMode) {
          delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
        }
      } else if (presentingFile) {
        // setStreaming({...streaming, publishingCanvas: false, presentingFile: false})
        setPublishingCanvas(false)
        setPresentingFile(false)
        if (autoMode) {
          delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
        }
      } else {
        if (presentingQuestion) {
         setPresentingQuestion(false)
        }
        if (presentingSpeaker){
          setPresentingSpeaker(false)
        }
        // setStreaming({...streaming, publishingCanvas: false})
        setPublishingCanvas(false)
        if (autoMode) {
          delPresent(api.entities.virtualRoom.stopPresent(null,null,{id:roomId}))
        }
      }
      onStreamDestroyed && onStreamDestroyed(event.stream)
    }
  }

  const subscriberEventHandlers = {
    videoElementCreated: event => {

    }
  }

  const onStreamsUpdate = (streams) => {
    console.log('set streams')
    setStreams(streams)
  }

  const onError = (err) => {
    enqueueSnackbar(err.message, {variant: 'error'})
  }

  const createNewSession = useCallback(() => {
    (DEV_MODE||TEST_MODE)&&console.log('OpenTok:: session connecting')
    sessionHelper.current = createSession({
      apiKey: apiKey,
      sessionId: sessionId,
      token: token,
      onStreamsUpdated: onStreamsUpdate,
      onError: onError
    })
    // eslint-disable-next-line
  },[apiKey,sessionId,token])

  useEffect(()=>{
    if (!sessionHelper.current) {
      (DEV_MODE||TEST_MODE)&&console.log('OpenTok:: No existing session');
      createNewSession()
    }

    return () => {
      console.log('OpenTok Component Unmounted')
      if (sessionHelper.current) sessionHelper.current.disconnect()
      if (sessionHelper.current&&sessionHelper.current.session) sessionHelper.current.session.off('sessionDisconnected sessionConnected')
      sessionHelper.current = null
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (sessionHelper&&sessionHelper.current&&sessionHelper.current.session) {
      sessionHelper.current.session.on('sessionDisconnected',()=>{
        (DEV_MODE||TEST_MODE)&&console.log('OpenTok:: session disconnected.');
        // createNewSession()
      })
      sessionHelper.current.session.on('sessionConnected',()=>{
        (DEV_MODE||TEST_MODE)&&console.log('OpenTok:: session connected.');
      })
    }
    // eslint-disable-next-line
  },[sessionHelper])

  // useEffect(()=>{
  //   if (sessionHelper&&sessionHelper.current&&sessionHelper.current.session) {
  //     if (token!==sessionHelper.current.session.token) {
  //       (DEV_MODE||TEST_MODE)&&console.log('OpenTok:: token changed, disconnecting.');
  //       sessionHelper.current.disconnect()
  //     }
  //   }
  //   // eslint-disable-next-line
  // },[token])

  const presentation_layout = [
    presentation_vert_1(),
    presentation_vert_2(),
    presentation_vert_3(),
    presentation_vert_4(),
    presentation_vert_5(),
    presentation_vert_6(),
    presentation_vert_7(),
    presentation_vert_8(),
    presentation_vert_9()
  ]

  const discussion_layout = [
    discussion_1(),
    discussion_2(),
    discussion_3(),
    discussion_4(),
    discussion_5(),
    discussion_6(),
    discussion_7(),
    discussion_8(),
    discussion_9()
  ]

  // Modes & State
  const participant = participants.find((p) => p.id === participantId)
  const broadcasting = ((!isViewer) || isCoach || canRaiseHands) && isStarted !== false && (participant&&participant.canBroadcast) && inCall && type!=='viewer'
  const bannerMode = layoutMode===1
  // const discussionMode = layoutMode===2
  const presentationMode = layoutMode===3

  const hasChairControls = isChair || isCoach
  const isPresenter = presentationMode&&participants.findIndex((p) => p.id === participantId) === 0
  const canSpeak = (!isViewer&&participant&&participant.canBroadcast && type!=='viewer' ) || (isViewer&&participant&&participant.canBroadcast && type!=='viewer'  && inCall)
  const isSharingScreen = publishingScreen
  // const isNotSharingScreen = !isSharingScreen
  const otherIsScreenSharing = streams.findIndex((s) => s.videoType === 'screen') !== -1
  const otherIsPresenting = streams.findIndex((s)=>s.videoType==='custom')!==-1
  // const otherIsNotScreenSharing = !otherIsScreenSharing
  const isSharingCanvas = (publishingCanvas && (presentingFile && presentationFile)) || presentingQuestion || presentingSpeaker
  // const isNotSharingCanvas = !isSharingCanvas
  const sessionStarted = isStarted
  const shouldBroadcastBanner = bannerStreamerUserId === participantId

  const findStreamable = useCallback( (p) => {
    const hasStream = !!streams.find((s)=>parseInt(s.name)===p.id)
    const isSelf = p.id===participantId && broadcasting
    return hasStream||isSelf
  },[streams, broadcasting, participantId])

  const layouts = [
    discussion_layout,
    discussion_layout,
    discussion_layout,
    presentation_layout
  ]

  const layout = layouts[layoutMode]

  useEffect(() => {
    if (streams) {
      // setStreaming({
      //   ...streaming,
      //   streamers: streams,
      // })
      setStreamers(streams)
    }
    // eslint-disable-next-line
  }, [streams, session])

  const calcTotalStreams = () => {
    // let totalStreams = participants.filter(findStreamable).length
    let totalStreams = streams.length
    // console.log('found streams',totalStreams)

    // Add if we are sharing anything
    totalStreams += ((isSharingScreen || isSharingCanvas)?1:0)
    // console.log('we are sharing',totalStreams)

    totalStreams += (broadcasting?1:0)
    // console.log('i am broadcasting', totalStreams)

    // Add if not presenter in presentation
    if (presentationMode && !autoMode) {
      const firstParticipant = participants[0]
      const presenterStream = firstParticipant&&streams.find((s)=>parseInt(s.name)===firstParticipant.id)
      if (!presenterStream) {
        totalStreams += 1
      }
    }
    // totalStreams += ((presentationMode&&!isPresenter)?1:0)
    // console.log('not presenter in presentation',totalStreams)

    // If in call
    // totalStreams += (presentationMode?(inCall?0:-1):0)
    // console.log('if in call', totalStreams)

    return totalStreams>9?9:totalStreams
  }

  const [totalStreams,setTotalStreams] = useState(calcTotalStreams())

  useEffect(()=>{
    setTotalStreams(calcTotalStreams())
  //eslint-disable-next-line
  },[findStreamable, broadcasting, canSpeak, inCall, isPresenter, isSharingCanvas, isSharingScreen, isViewer, participants,presentationMode])

  // if (!participantsOrder) return <> </>

  const findSelfPosition = useCallback( (type) => {
    const streamable = participants.filter(findStreamable)
    // console.log('findSelfPosition::',type)
    // console.log(streamable)
    let pos = streamable.findIndex((p)=>p.id===participantId)+1
    // console.log(pos)

    // if first person in streaming is not really first in list (not presenter) add one
    if (streamable&&streamable[0]&&presentationMode && participants.findIndex((p)=>p.id===streamable[0].id)!==0) {
      pos++
      // console.log('if first person in streaming is not really first in list (not presenter) add one',pos)
    }

    // if anyone is presenting +1
    if (presentationMode && (isSharingScreen || isSharingCanvas || otherIsPresenting || otherIsScreenSharing)) {
      pos++
      // console.log('    // if anyone is presenting',pos)
    }

    if (((isViewer&&!participants.find((p)=>p.id===participantId)))) {
      pos++
      // console.log('isViewer+notInList',pos)
    }

    return pos
  },[findStreamable, isSharingCanvas, isSharingScreen, isViewer, otherIsPresenting, otherIsScreenSharing, participantId, participants, presentationMode])

  const findStreamPosition = useCallback( (stream) => {
    const streamId = parseInt(stream.name)
    const streamable = participants.filter(findStreamable)
    const presentingOrSharing = (isSharingScreen || isSharingCanvas || otherIsPresenting || otherIsScreenSharing)
    const shareScreenOrPresentation = stream.videoType === 'custom' || stream.videoType === 'screen'

    // console.log('ID:',stream.name,'shareScreenOrPresentation',shareScreenOrPresentation)
    let pos = streamable.findIndex((p)=>p.id===streamId)+1
    // console.log(pos)

    // if first person in streaming is not really first in list (not presenter) add one
    if (streamable&&streamable[0]&&presentationMode && participants.findIndex((p)=>p.id===streamable[0].id)!==0) {
      pos++
      // console.log(1,pos)
    }

    // if anyone is presenting +1
    if (presentationMode && presentingOrSharing && !shareScreenOrPresentation ) {
      pos++
      // console.log(2,pos)
    }

    // if (isViewer) {
    //   pos++
    //   console.log(3,pos)
    // }

    return pos
  },[findStreamable, isSharingCanvas, isSharingScreen, otherIsPresenting, otherIsScreenSharing, participants, presentationMode])

  const hasSession = (sessionHelper.current&&sessionHelper.current.session)
  // const hasConnection = hasSession && sessionHelper.current.session.connection

  if (!hasSession) return <></>

  const layoutClass = layout[totalStreams-1]?layout[totalStreams-1]:layout[0]

  // Chair broadcasting Canvas
  if (bannerMode) {
    return <>
      {hasChairControls&&shouldBroadcastBanner&&bannerMode&&sessionStarted&&canvasVideoTrack&&
      <OTPublisher session={sessionHelper.current.session}
                   className={classes.videoHidden}
                   properties={publisherPropertiesBanner}
                   eventHandlers={publisherEventHandlers}/>
      }
    </>
  }

  return <>

    <RootRef rootRef={domRef}>
      <Box width={'100%'} height={'100%'} className={clsx(classes.box)} id='videoContainer'>

        {broadcasting && canSpeak && ((isSharingScreen || isSharingCanvas) && layoutMode === 3) &&
        <Box participantId={participantId} pos={findSelfPosition('share')-1} className={clsx(classes.openTokItem,layoutClass[`item_${findSelfPosition()-1}`])} >
          {participant&&<Box className={classes.nameTag} p={0.5}>{`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}</Box>}
          <OTPublisher session={sessionHelper.current.session}
                       properties={publisherPropertiesScreensharing}
                       eventHandlers={{...publisherEventHandlers,...shareScreenPublisherEvents}}
                       className={clsx(classes.video,classes.presentation)}
          />
        </Box>}

        {!!sessionHelper.current.session.capabilities.publish && broadcasting && canSpeak && <Box participantid={participantId} totalstreams={totalStreams} pos={findSelfPosition('camera')} className={clsx(classes.openTokItem,layoutClass[`item_${findSelfPosition()}`])}>
          {participant&&<Box className={classes.nameTag} p={0.5} title={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}>{`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}</Box>}
          {!publisherPropertiesCamera().publishVideo&&participant&&<Box className={classes.videoAvatar}><img src={imageFile(participant.image)} alt={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}/></Box>}
        <OTPublisher session={sessionHelper.current.session}
                     properties={publisherPropertiesCamera()}
                     eventHandlers={publisherEventHandlers}
                     className={classes.video} ref={otPublisher}
                     onError={publisherEventHandlers.onError}
        />
        </Box>}

        {streams.map((stream,i)=>{
          const pos = findStreamPosition(stream)
          const itemName=`item_${pos}`
          const participant = participants.find((p)=>p.id===parseInt(stream.name))
          return <Box participantid={stream.name} totalstreams={totalStreams} pos={pos} className={clsx(classes.openTokItem,layoutClass[itemName])} key={stream.id}>
            {participant&&<Box className={classes.nameTag} p={0.5} title={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}>{`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}</Box>}
            {!stream.hasVideo&&participant&&<Box className={classes.videoAvatar}><img src={imageFile(participant.image)} alt={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}/></Box>}
            <OTSubscriber
              key={stream.id}
              session={sessionHelper.current.session}
              stream={stream}
              eventHandlers={subscriberEventHandlers}
              properties={subscriberProperties(+stream.name)}
              className={clsx(classes.video)}
            />
          </Box>
        })}

      </Box></RootRef>
  </>
})

export default withRouter(OpenTok)
