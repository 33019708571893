import { withStyles } from "@material-ui/core/styles";
import { useParams, withRouter } from "react-router-dom";
import React, {useEffect, useState, useCallback} from "react";
import { Box, Typography } from "@material-ui/core";
import { useHttp } from "api/core";
import api from "api";
import { palette } from "theme";
import ContentCard from "components/core/ContentCard";
import Button from "components/core/Button";
import { IconAddBorderBlue } from "components/core/icons";
import Dialog from "@material-ui/core/Dialog";
import ContentEditNew from "components/core/ContentEditNew";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { useSnackbar } from "notistack";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import { TIME_FADE_IN } from "config";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import language from "components/language";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';

const styles = (theme) => ({
  contentsContainer: {
    // backgroundColor: "white",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(5),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    maxWidth: "1000px",
    marginTop:theme.spacing(3)
  },
  noContentsYet: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minHeight: "130px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem",
  },
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-23px",
    "& Button": {
      minWidth: "170px",
    },
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },     
});

const Content = withStyles(styles)(
  ({ classes, setPageTitle, setEventInfo, setNavBack, eventInfo, history, flow }) => {
    const [getData, data, isLoadingData] = useHttp();
    const [delRequest, delData, isLoadingDelete] = useHttp();
    const [sendData,dataSend] = useHttp();
    const [openContentDialog, setOpenContentDialog] = useState(false);
    const [editingContent, setEditingContent] = useState();
    // eslint-disable-next-line
    const [successful, setSuccessful] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deletingContent, setDeletingContent] = useState();
    const [contentsData, setContentsData] = useState();
    const { eventId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [complete, setComplete] = useState(false);
    const [contentTypeList, setContentTypeList] = useState([]);
    const [getDataPublish, dataPublish] = useHttp();
    const [getContent, content] = useHttp();

    useEffect(() => {
      getData(
        api.entities.manage.getContents(null, null, { eventId: eventId })
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    useEffect(() => {
      if (data) {
        setContentsData(data.content);
        setPageTitle(data.eventInfo.name);
        setEventInfo(data.eventInfo);
        setNavBack({ path: "/manage-events", name: "events" });
        const typeList = data.content.map((item) => item.contentEnumType);
        setContentTypeList(typeList);
      }
    }, [data, setEventInfo, setNavBack, setPageTitle]);

    const handleClose = (shouldReload) => {
      setOpenContentDialog(false);
      if (shouldReload === true) {
        getData(
          api.entities.manage.getContents(null, null, { eventId: eventId })
        );
      }
    };

    const handleOpen = (content) => {
      setOpenContentDialog(true);
      if (content) {
        setEditingContent(content);
      } else {
        setEditingContent(undefined);
      }
    };

    const handleDeleteOpen = (id) => {
      setDeleteOpen(true);
      setDeletingContent(id);
    };

    const handleDeleteClose = (id) => {
      setDeleteOpen(false);
      if (id === deletingContent) {
        delRequest(
          api.entities.manage.deleteContent(null, null, {
            eventId: eventId,
            id: id,
          })
        );
        setDeletingContent(undefined);
      }
    };

    useEffect(() => {
      if (delData) {
        enqueueSnackbar("Successfully deleted Content", { variant: "success" });
        getData(
          api.entities.events.getContents(null, null, { eventId: eventId })
        );
      }
    }, [delData, enqueueSnackbar, eventId, getData]);

    const onDragEnd = (r) => {
      if (!r.destination) return;

      function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
          var k = new_index - arr.length + 1;
          while (k--) {
            arr.push(undefined);
          }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      // arrayHelpers.move(r.source.index,r.destination.index)
      let newArr = [...contentsData];

      array_move(newArr, r.source.index, r.destination.index);

      setContentsData(newArr);

      const newArrOrder = [...newArr.map((sF) => sF.id)];

      sendData(
        api.entities.manage.reorderContents(newArrOrder, null, {
          eventId: eventId,
        })
      );
    };

    useEffect(() => {
      getContent(api.entities.manage.getContents(null, null, { eventId: eventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSend])  

    useEffect(() => {
      if (content) {
        setEventInfo(content.eventInfo);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);      

    const publishEvent = () => {
      getDataPublish(api.entities.events.publish(null, null, {id: eventId}));
    };

    const ViewEvent = useCallback(
      () => (
        <Button
          size="smaller"
          variant="outlined"
          colour="white"
          onClick={() => {
            history.push(`/event/${eventId}`);
          }}>
          {" "}
          View Event{" "}
        </Button>
      ),
      [eventId, history],
    );

    useEffect(() => {
      if (dataPublish) {
        if (dataPublish.status && dataPublish.status === 201) {
          enqueueSnackbar("Your event was successfully published!", {variant: "success", action: ViewEvent});
          setEventInfo((f) => ({...f, dirty: false}));
        } else {
          enqueueSnackbar("Unexpected Error", {variant: "error"});
        }
      }
      // eslint-disable-next-line
    }, [dataPublish]);
    
    if (!contentsData || isLoadingData) return <> </>;

    return (
      <Grid container>
        <Dialog onClose={handleClose} open={openContentDialog} maxWidth="lg">
          <ContentEditNew
            setPageTitle={setPageTitle}
            setEventInfo={setEventInfo}
            setNavBack={setNavBack}
            eventInfo={eventInfo}
            contentItem={editingContent}
            contentTypeList={contentTypeList}
            handleClose={handleClose}
            eventId={eventId}
            setSuccessful={setSuccessful}
          />
        </Dialog>

        <Dialog
          open={deleteOpen}
          onClose={handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box p={3} pt={1}>
            <DialogTitle id="alert-dialog-title">Delete content</DialogTitle>
            <DialogContent>
              <Box p={1} pl={0} pb={2}>
                Are you sure you want to delete content?
              </Box>
            </DialogContent>
            <DialogActions>
              <Box p={1}>
                <Button
                  onClick={() => handleDeleteClose()}
                  size="small"
                  variant="outlined"
                  color="primary"
                >
                  No
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  onClick={() => {
                    handleDeleteClose(deletingContent);
                  }}
                  size="small"
                  autoFocus
                  color="primary"
                >
                  Yes
                </Button>
              </Box>
            </DialogActions>
          </Box>
        </Dialog>
        <Fade in={true} timeout={TIME_FADE_IN}>
          <Grid container className={classes.contentsContainer} >
            <Grid container item xs={12}>
              <Grid item xs={12} sm={5}>
                <Typography
                  component="h2"
                  variant="h5"
                  className={classes.title}
                >
                  Content
                  <Tooltip classes={{tooltip: classes.tooltip}} title="Content allows you to customize the events page menu. Drag and drop sections to rearrange the order." placement="top" arrow>
                        <InfoIcon style={{ fontSize: "22px", marginLeft: "10px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
                  </Tooltip>                  
                </Typography>
              </Grid>
              <Grid item xs={12} sm={7} style={{ textAlign: "right" }}>
                <Box display="inline" pt={2} ml={3}>
                  <Button
                    variant="outlined"
                    icon={<IconAddBorderBlue />}
                    size="small"
                    color="primary"
                    onClick={() => handleOpen()}
                  >
                    Add new section
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={`${0}`}>
                  {(provided) => {
                    return (
                      <Grid
                        item
                        container
                        spacing={1}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {contentsData.map((content, index) => (
                          <Draggable
                            key={index}
                            draggableId={`${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <Grid
                                item
                                xs={12}
                                {...provided.draggableProps}
                                ref={provided.innerRef}
                                width={"100%"}
                              >
                                <ContentCard
                                  dragHandleProps={provided.dragHandleProps}
                                  content={content}
                                  key={index}
                                  editAction={() => {
                                    handleOpen(content);
                                  }}
                                  deleteAction={() => {
                                    handleDeleteOpen(content.id);
                                  }}
                                />
                              </Grid>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Grid>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </Grid>
          </Grid>
        </Fade>
        {eventInfo && eventInfo.dirty && 
          <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons} style={{ position: flow ? "absolute" : null, top: flow ? "0" : null}}>
          {eventInfo && eventInfo.dirty && 
            <Alert className={classes.topPublish} severity="warning">
              <AlertTitle>Unpublished changes</AlertTitle>
              You are viewing an event with unpublished changes.
            </Alert>
          }
          <Box display="inline-block" mt={1} mb={1}>
            {eventInfo.dirty ? (
              <Button
                onClick={() => {
                  publishEvent();
                }}>
                Publish
              </Button>
            ) : (
              ""
            )}
          </Box>
        </Grid>}
      </Grid>
    );
  }
);

export default withRouter(Content);
