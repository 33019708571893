import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@material-ui/core'
import {useHttp} from 'api/core'
import api from 'api'
import {palette} from 'theme'
import CommunicationCard from 'components/core/CommunicationCard'
import Button from 'components/core/Button'
import {IconAddBorderBlue} from 'components/core/icons'
import Dialog from '@material-ui/core/Dialog'
import CommunicationEditNew from 'components/core/CommunicationEditNew'
// import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import {useSnackbar} from "notistack";
import AddCreditsDialog from "components/pages/ManageEvent/Plans/AddCreditsDialog";
import {creditsPriceList} from "config";

const styles = (theme) => ({
  communicationContainer: {
    backgroundColor: "white",
    padding: theme.spacing(2),
    borderRadius: "6px",
    // boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    marginBottom: theme.spacing(4),
    maxWidth: "1000px",
  },
  noCommunicationYet: {
    display: "flex",
    backgroundColor: "white",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "6px",
    border: "2px dashed",
    borderColor: palette["cerulean-blue"],
    minWidth: "645px",
    minHeight: "80px",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "bold",
    color: palette["greyish-brown"],
    fontSize: "18px",
    opacity: 0.3,
  },
  addBtn: {
    textAlign: "right",
  },
  title: {
    fontWeight: "bolder",
    paddingBottom: theme.spacing(4),
  },
  creditsTitle: {
    fontWeight: "normal",
    padding: theme.spacing(1),
    fontSize: "1rem",
    display: "inline-block",
    backgroundColor: "red",
    borderRadius: "4px",
    color: "#fff",
    lineHeight: "1rem",
  },
});

const Communication = withStyles(styles)(({classes,setPageTitle,setEventInfo, setNavBack, eventInfo})=>{

  // Hooks
  const [getData, data] = useHttp()

  // State
  const [communications, setCommunication] = useState()
  const [editingCommunication, setEditingCommunication] = useState()
  const [editCommunication, setEditCommunication] = useState(false)

  // eslint-disable-next-line
  const [successful, setSuccessful] = useState(false)

  const {enqueueSnackbar} = useSnackbar();
  const [creditsPlan, setCreditsPlan] = useState();
  const [getCredits, credits] = useHttp();
  const [complete, setComplete] = useState(false);
  
  useEffect(() => {
    getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);
  
  // Deps
  const {eventId} = useParams()
  // const {enqueueSnackbar} = useSnackbar()

  useEffect(()=>{
    getData(api.entities.communication.getCommunications(null,null,{id:eventId}))
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setCommunication(data)
      setPageTitle(data.eventInfo.name)
      setNavBack({path:'/manage-events',name:'events'})
      setEventInfo(data.eventInfo)
    }
  },[data, setEventInfo, setNavBack, setPageTitle])
  
  useEffect(() =>{
    if (complete) {
       getCredits(api.entities.credits.getEventCredits(null, null, {id: eventId}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [complete]);

  const handleEditOpen = (communication) => {
    setEditCommunication(true)
    setEditingCommunication(communication)
  }

  const handleEditClose = () => {
    setEditCommunication(false)
  }

  const handleChange = (e, page) => {
    getData(api.entities.communication.getCommunications(null,{page: page}, {id: eventId}))
  }
  const handleOpenAddCredits = (plan) => {
    setCreditsPlan(plan);
  };

  const handleCloseAddCredits = (success) => {
    setCreditsPlan(null);
    if (success === true) {
      enqueueSnackbar("Credits added successfully.", {variant: "success"});
    }
  };

  if (!communications) return <> </>

  return (
    <>
      <Dialog onClose={handleEditClose} open={editCommunication} fullWidth maxWidth="md">
        <CommunicationEditNew
          communication={editingCommunication}
          handleClose={handleEditClose}
          credits={credits}
          eventId={eventId}
          eventInfo={data.eventInfo}
          setSuccessful={setSuccessful}
          handleOpenAddCredits={handleOpenAddCredits}
          setComplete={setComplete}
        />
      </Dialog>

      <Fade in={true} timeout={1200}>
        <Grid container spacing={2} className={classes.communicationContainer}>
          <Grid item container xs={12}>
            <Grid item xs={4}>
              <Typography component="h2" variant="h5" className={classes.title}>
                Emails
              </Typography>
            </Grid>
            <Grid item xs={8} style={{textAlign: "right"}}>
              <Typography component="h6" variant="h6" className={classes.creditsTitle}>
                Available emails: <span style={{ margin: "0 5px", fontSize: "18px", fontWeight: "bold" }}> {credits?.emailsBought - credits?.emailsSent >= 0 ? credits?.emailsBought - credits?.emailsSent : "0"} </span>
                {/* out of <span style={{margin: "0 5px", fontSize: "18px", fontWeight: "bold"}}> {credits?.emailsBought} </span> in total */}
              </Typography>{" "}
              <Box display="inline" pt={2} ml={3}>
                <Button autoWidth size="smaller" onClick={() => handleOpenAddCredits("emails")}>
                  {" "}
                  Unlock more emails{" "}
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {communications.messages.content.map((communication, i) => {
              return (
                <CommunicationCard
                  communication={communication}
                  key={i}
                  editAction={() => {
                    handleEditOpen(communication);
                  }}
                  mb={2}
                />
              );
            })}
            {communications.messages.content.length === 0 ? (
              <Grid item container height={130} className={classes.noCommunicationYet}>
                <Grid item> No emails sent yet! </Grid>
              </Grid>
            ) : (
              ""
            )}
            {communications.messages && communications.messages.content.length !== 0 && (
              <Box pt={2} pb={2} display="flex" flexDirection="row" justifyContent="flex-end">
                <Box display="flex">
                  <Pagination
                    hideNextButton={communications.messages.last}
                    hidePrevButton={communications.messages.first}
                    count={communications.messages.totalPages}
                    page={communications.messages.pageable.pageNumber + 1}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} className={classes.addBtn}>
            <Button
              variant="outlined"
              icon={<IconAddBorderBlue />}
              size="small"
              color="primary"
              onClick={() => {
                handleEditOpen();
              }}              
              eventId={eventId}>
              Send email
            </Button>
          </Grid>
        </Grid>
      </Fade>
      {creditsPlan && (
        <AddCreditsDialog
          handleClose={handleCloseAddCredits}
          creditsPriceList={creditsPriceList}
          eventDuration={1}
          eventCredits={credits}
          open={creditsPlan}
          creditsType={creditsPlan}
          event={{...eventInfo, id: eventId}}
          setComplete={setComplete}
        />
      )}
    </>
  );
})

export default withRouter(Communication)
