import Grid from '@material-ui/core/Grid'
import RootRef from '@material-ui/core/RootRef'
import {DEV_MODE, SERVICE_URL, STREAM_OPTIONS, STREAM_VIDEO_TYPES, TEST_MODE, TIME_SESSION_HEARTBEAT} from 'config'

import React, {useEffect, useRef, useState} from 'react'
import VideoHeader from 'components/core/OpenTok/Components/VideoHeader'
import VideoContainer from 'components/core/OpenTok/Components/VideoContainer'
import VideoFooter from 'components/core/OpenTok/Components/VideoFooter'
import FilePresenter from 'components/core/OpenTok/Components/FilePresenting/FilePresenter'
import QuestionPresenter from 'components/core/OpenTok/Components/QuestionPresenter'
import SpeakerPresenter from 'components/core/OpenTok/Components/SpeakerPresenter'
import {ThemeProvider, withStyles} from '@material-ui/core/styles'
import RoomsBanner from 'components/core/OpenTok/Components/RoomsBanner'
import PreviewDialog from 'components/core/OpenTok/Components/PreviewDialog'
import Canvas from 'components/core/OpenTok/Components/Canvas'
import VisibilityDialog from 'components/core/OpenTok/Components/VisibilityDialog'
import api from 'api'
import {themeSession} from 'theme'
import Navigation from 'components/core/OpenTok/Components/Navigation'
import Fade from '@material-ui/core/Fade'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ViewerTabs from 'components/core/OpenTok/Components/Tabs'
import {NotificationViewer} from 'components/core/Notifications'
import {useHttp} from 'api/core'
import {getUserId, getUserToken} from 'utils'
import ViewerFooter from 'components/core/OpenTok/Components/ViewerFooter'
import FileSelectionDialog from 'components/core/OpenTok/Components/FilePresenting/FileSelectionDialog'
import * as SockJS from 'sockjs-client'
import * as StompJs from 'stompjs'
import PopoutPoll from 'components/core/OpenTok/Components/Feedback/PopoutPoll'
import {Redirect} from 'react-router-dom'
import {Resizable} from 're-resizable'
import clsx from 'clsx'
import RoomIntegration from 'components/core/RoomIntegration'

const styles = theme => ({
  content: {
    padding: theme.spacing(1)
  },
  contentInner: {
    width: '100%',
    maxHeight: `calc(100vh - 140px)`,
    // overflowY: 'auto',
    padding: theme.spacing(2)
  },
  video: {
    flexGrow: 1,
    backgroundColor: 'black',
    color: 'white',
  },
  videoWorkShop: {
    backgroundColor: 'white',
    color: 'black'
  },
  videoHeader: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    height: theme.spacing(8),
    backgroundColor: 'black',
    color: 'white'
  },
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 72px)',
    backgroundColor: 'white'
    // overflow: 'hidden'
  },
  leftColumn: {
    height: '100%'
  },
  navigation: {
    width: '100%',
    // minHeight: theme.spacing(5),
  },
  bottomContainer: {
    // flexGrow: 1,
    paddingLeft: theme.spacing(2),
    minHeight: 175
  },
  videoContainer: {
    flexGrow: 1
  }
})

const OpenTokRoom = withStyles(styles)(({classes, room, posterPresentation, customTabs = [], type = '', isOnlinePoster = false, isAnymeetsLive = false, isVirtualRoom = false, isWorkShop = false}) => {

  const sessionHelper = useRef()
  const sessionActive = useRef()

  const publisherRef = useRef()
  const videoContainerRef = useRef()
  const poller = useRef(null)
  const viewerMode = type === 'viewer'

  const [tab, setTab] = useState('info')
  const [exit, setExit] = useState(false)

  // Chat state
  const RECONNECT_TIMEOUT = 4000 // affects error / disconnection reconnect
  const RECONNECT_DELAY = 2000 // affects automatic reconnect (can be faster)

  const [hasChatMessage, setHasChatMessage] = useState()
  // const [messages, sendMessage, setMessagesAny] = useAnyChat([`/app/session/${room.id}`,`/app/session/${room.id}/speakers`],[`/topic/online-room/${room.id}`])
  const [chatType, setChatType] = useState(0)
  // eslint-disable-next-line
  const [initializedChat, setInitializedChat] = useState(false)
  const [messageReceived, setMessageReceived] = useState([])
  const [messageReceivedSpeakers, setMessageReceivedSpeakers] = useState([])
  const [notification, setNotification] = useState()

  // States
  const [clientHandler, setClientHandler] = useState({}) // This is also used to send a message
  const [chatConnected, setChatConnected] = useState()

  // References
  const chatClient = useRef()
  const reconnectTimeout = useRef()

  // Constants
  const chatHeaders = {
    'Content-Type': 'text/plain',
    'Authorization': `Bearer ${getUserToken()}`
  }

  // Socket Events
  const onConnected = () => {
    setChatConnected(true)
    clearTimeout(reconnectTimeout.current)
  }

  const onError = () => {
    setChatConnected(false)
    clearTimeout(reconnectTimeout.current)
    console.log('DISCONNECTED:: Reconnecting in 4sec...')
    reconnectTimeout.current = setTimeout(connectToWs, RECONNECT_TIMEOUT)
  }

  const onMessageReceived = (message) => {
    const newMessage = JSON.parse(message.body)

    if (newMessage.type === 0) {
      setMessageReceived(oldArray => [...oldArray, newMessage])
    } else if (newMessage.type === 1) {
      setMessageReceivedSpeakers(oldArray => [...oldArray, newMessage])
    } else if (newMessage.type === 2) {
      setNotification(newMessage)
    }
  }

  // Connection runtime
  const connectToWs = () => {
    const socket = new SockJS(`${SERVICE_URL}/stompws`)
    chatClient.current = StompJs.over(socket)
    // show debug in development
    if (!DEV_MODE && !TEST_MODE) chatClient.current.debug = null

    chatClient.current.connect(chatHeaders, onConnected, onError)
    chatClient.current.reconnect_delay = RECONNECT_DELAY

    setClientHandler(chatClient.current)
  }


  useEffect(() => {
    if (clientHandler.connected === true)
      clientHandler.subscribe(`/topic/online-room/${roomId}`, onMessageReceived)
    // eslint-disable-next-line
  }, [clientHandler.connected])

  useEffect(() => {
    if ((messageReceived || messageReceivedSpeakers) && initializedChat && tab !== 'chat') {
      setHasChatMessage(true)
    }
    if (!initializedChat) {
      setInitializedChat(true)
    }
    // eslint-disable-next-line
  }, [messageReceived])

  // Init Chat
  useEffect(() => {
    if (isChair) {
      setTab('controls')
    }
    connectToWs()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (tab === 'chat') {
      setHasChatMessage(false)
    }
  }, [tab])

  const [streamers, setStreamers] = useState([])
  const [publishing, setPublishing] = useState([])
  const [publishingScreen, setPublishingScreen] = useState(false)
  const [publishingCanvas, setPublishingCanvas] = useState(false)
  const [presentingFile, setPresentingFile] = useState()
  const [presentingFilePage, setPresentingFilePage] = useState(0)

  const raisedHands = useRef([])

  //eslint-disable-next-line
  const [getData, roomData, isLoading, hasError] = useHttp()
  const [getHeartbeat, heartbeatData, , heartbeatError, cancelHeartbeat] = useHttp(true)
  const [session, setSession] = useState(room)

  // On heartbeat data, set status update
  useEffect(() => {
    if (heartbeatData) {
      setSession({...session, status: {...session.status, ...heartbeatData}})
    }
    // eslint-disable-next-line
  }, [heartbeatData])

  useEffect(() => {
    if (heartbeatError) {
      if (heartbeatError.status === 403) {
        setExit(true)
      }
    }
  }, [heartbeatError])

  const handleKeyDown = (event) => {
    if (event.code === `Backquote`) {
      console.warn(sessionState)
    }
  }

  const [getWorkShopData, workShopData] = useHttp()

  const goBacktoInfo = () => {
    document.location.href=`/event/${session.eventId}/info`
  }

  // On mount, get Session
  useEffect(() => {

    const isCoach = room.status.coach
    const isChair = room.status.chair
    const isSpeaker = room.status.speaker
    const isViewer = (!isChair) && (!isSpeaker) && (!isCoach)

    setSession({
      ...room,
      status: {
        ...room.status,
        coach: viewerMode ? false : room.status.coach,
        chair: viewerMode ? false : room.status.chair,
        speaker: viewerMode ? false : room.status.speaker
      },
      participantId: parseInt(getUserId().sub)
    })

    if ((isChair || isCoach) && token && !viewerMode) {
      setTab('controls')
    }

    if (!room.status.started) {
      setInCall(false)
    }
    if (isViewer || room.status.coach) {
      setChatType(0)
    }
    setTimeout(startPoller, TIME_SESSION_HEARTBEAT)

    //eslint-disable-next-line
    zE('webWidget', 'updateSettings', {
      webWidget: {
        offset: {
          horizontal: '0px',
          vertical: '135px'
        }
      }
    })

    const boarded = !!localStorage.getItem('boarded')
    // setNeedsOnBoarding(!boarded)
    if (!boarded) {
      localStorage.setItem('boarded', true)
    }
    document.addEventListener('keydown', handleKeyDown)

    if (isWorkShop) {
      getWorkShopData(api.entities.sessions.getWorkshop(null, null, {sessionId: session.id}))
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      clearInterval(poller.current)
    }
    //eslint-disable-next-line
  }, [])

  // On Session Data, set Session data, start poller
  useEffect(() => {
    if (roomData) {
      const isCoach = roomData.status.coach
      const isChair = roomData.status.chair
      const isSpeaker = roomData.status.speaker
      const isViewer = (!isChair) && (!isSpeaker) && (!isCoach)

      setSession({
        ...roomData,
        status: {
          ...roomData.status,
          coach: viewerMode ? false : roomData.status.coach,
          chair: viewerMode ? false : roomData.status.chair,
          speaker: viewerMode ? false : roomData.status.speaker
        },
        participantId: parseInt(getUserId().sub)
      })

      if ((isChair || isCoach) && token && !viewerMode) {
        setTab('controls')
      } else if (isViewer || isSpeaker) {
        setTab('chat')
      }

      if (!roomData.status.started) {
        setInCall(false)
      }
      if (isViewer || roomData.status.coach) {
        setChatType(0)
      }
      setTimeout(startPoller, TIME_SESSION_HEARTBEAT)
    }
    // eslint-disable-next-line
  }, [roomData])

  // Start poller
  const startPoller = () => {
    clearInterval(poller.current)
    poller.current = setInterval(() => {
      getHeartbeat(api.entities[(isVirtualRoom || isOnlinePoster) ? `virtualRoom` : `sessions`].heartbeat(null, null, {id: room.id}))
    }, TIME_SESSION_HEARTBEAT)
  }

  const updateStreaming = (status) => {

    if (status.attendeeState && status.attendeeState.state === 0) {
      setExit(true)
    }

    const bannerMode = status.layout.mode === 1
    const participant = status.participants.find((s) => s.id === session.participantId)
    const notAllowedToBroadcast = bannerMode || (status.started === false || ((participant && !participant.canBroadcast) || !participant))
    const notAllowedToPresent = (status.layout.mode === 3 && publishingScreen && session.participantId !== status.participants[0].id && status.auto === false) || (status.layout.mode !== 3)
    const notAllowedToCanvasScreen = (status.layout.mode === 3 && publishingCanvas && presentingFile && status.participants.findIndex((p) => p.id === session.participantId) !== 0 && status.auto === false)

    const newPublishing = notAllowedToBroadcast ? false : (status.started && publishing) || (status.started && inCall)

    setPublishing(newPublishing)
    setPublishingScreen((notAllowedToPresent || notAllowedToBroadcast) ? false : publishingScreen)
    setPublishingCanvas(notAllowedToCanvasScreen ? false : publishingCanvas)
    setPresentingFile((notAllowedToCanvasScreen || notAllowedToPresent) ? false : presentingFile)

    if (!status.started||bannerMode) {
      setInCall(false)
    }

    if (participant && !participant.canBroadcast) {
      setInCall(false)
    }

  }

  // On heartbeat data, set status update
  useEffect(() => {
    if (heartbeatData) {

      setSession({
        ...session,
        status: {
          ...session.status,
          ...heartbeatData,
          coach: viewerMode ? false : heartbeatData.coach,
          chair: viewerMode ? false : heartbeatData.chair,
          speaker: viewerMode ? false : heartbeatData.speaker
        }
      })

      updateStreaming(heartbeatData)

    }
    // eslint-disable-next-line
  }, [heartbeatData])


  const [isFullscreen, setIsFullscreen] = useState(false)
  const [inCall, setInCall] = useState(true)

  // Speakers related
  const [presentingSpeaker, setPresentingSpeaker] = useState(false)
  const [selectedSpeaker, setSelectedSpeaker] = useState(false)

  // Questions related
  const [selectedQuestion, setSelectedQuestion] = useState()
  const [presentingQuestion, setPresentingQuestion] = useState(false)

  // Presentation related
  const [selectingFile, setSelectingFile] = useState(false)
  const [presentationFile, setPresentationFile] = useState(false)

  // File select

  // eslint-disable-next-line
  const [delPresent, delPresentData, delPresentLoading, delPresentError] = useHttp()
  const closeSelectFileDialog = (e, b, file) => {
    setSelectingFile(false)
    if (autoMode && !file) {
      delPresent(api.entities.virtualRoom.stopPresent(null, null, {id: roomId}))
    }
    if (file) {
      // setStreaming({...streaming, presentingFile: file, publishingCanvas: true})
      setPresentingFile(file)
      setPublishingCanvas(true)
    }
  }

  // Feedback related
  const [openFeedback, setOpenFeedback] = useState(false)

  // Device setup
  const [deviceSetup, setDeviceSetup] = useState()

  const [changeDeviceSetup, setChangeDeviceSetup] = useState( false )

  const openDeviceSetup = () => setChangeDeviceSetup(true)
  const closeDeviceSetup = () => setChangeDeviceSetup(false)

  // References
  const [canvasVideoTrack, setCanvasVideoTrack] = useState()
  const videoContainer = useRef(null)

  // Handle Mute/Unmute microphone
  const toggleMicrophone = () => {
    setPublishAudio(!publishAudio)
  }

  const toggleCamera = () => {
    setPublishVideo(!publishVideo)
  }

  const [publishAudio, setPublishAudio] = useState(false)
  const [publishVideo, setPublishVideo] = useState(false)
  const [videoSource, setVideoSource] = useState()
  const [audioSource, setAudioSource] = useState()

  // Shorthands
  const streamingHandlers = {
    streamers,
    setStreamers: (nstate) => setStreamers(nstate),
    publishing,
    setPublishing: (nstate) => setPublishing(nstate),
    publishingScreen,
    setPublishingScreen: (nstate) => setPublishingScreen(nstate),
    publishingCanvas,
    setPublishingCanvas: (nstate) => setPublishingCanvas(nstate),
    presentingFile,
    setPresentingFile: (nstate) => setPresentingFile(nstate),
    presentingFilePage,
    setPresentingFilePage: (nstate) => setPresentingFilePage(nstate),
    presentingQuestion,
    setPresentingQuestion: (nstate) => setPresentingQuestion(nstate),
    isFullscreen,
    setIsFullscreen : (nstate) => setIsFullscreen(nstate),
    inCall,
    setInCall : (nstate) => setInCall(nstate),
    setHasChatMessage : (nstate) => setHasChatMessage(nstate),
    hasChatMessage,
    canvasVideoTrack,
    setCanvasVideoTrack : (nstate) => setCanvasVideoTrack(nstate),
    videoSource,
    setVideoSource: (nstate) => setVideoSource(nstate),
    audioSource,
    setAudioSource: (nstate) => setAudioSource(nstate),
    publishVideo,
    setPublishVideo: (nstate) => setPublishVideo(nstate),
    publishAudio,
    setPublishAudio: (nstate) => setPublishAudio(nstate)
  }
  const speakerPresentHandlers = {
    presentingSpeaker,
    setPresentingSpeaker : (nstate) =>setPresentingSpeaker(nstate),
    selectedSpeaker,
    setSelectedSpeaker : (nstate) => setSelectedSpeaker(nstate),
    setCanvasVideoTrack : (nstate) => setCanvasVideoTrack(nstate),
  }
  const questionPresentHandlers = {
    selectedQuestion,
    setSelectedQuestion  : (nstate) =>setSelectedQuestion(nstate),
    presentingQuestion,
    setPresentingQuestion  : (nstate) => setPresentingQuestion(nstate),
    setCanvasVideoTrack
  }

  const presentationHandlers = {selectingFile, setSelectingFile, presentationFile, setPresentationFile}
  const feedbackHandlers = {setOpenFeedback, openFeedback}
  const chatHandlers = {
    messageReceived,
    messageReceivedSpeakers,
    setMessageReceivedSpeakers : (nstate) => setMessageReceivedSpeakers(nstate),
    setMessageReceived  : (nstate) => setMessageReceived(nstate),
    clientHandler,
    chatType,
    setChatType : (nstate) => setChatType(nstate),
    chatConnected
  }
  const raisedHandHandlers = {raisedHands: raisedHands.current}
  const deviceHandlers = {deviceSetup, setDeviceSetup, toggleCamera, toggleMicrophone, openDeviceSetup, closeDeviceSetup}
  const allHandlers = {...streamingHandlers, ...speakerPresentHandlers, ...questionPresentHandlers, ...presentationHandlers, ...feedbackHandlers, ...raisedHandHandlers, ...deviceHandlers}



  const hasVideoHeader = true
  const hasVideoFooter = true

  const roomSessionId = room.id
  const roomId = room.status.onlineRoomId

  const roomTitle = room.title
  const participants = session.status.participants
  const participantId = parseInt(getUserId().sub)
  const audience = session.status.audience


  // Roles
  const isCoach = session.status.coach
  const isChair = session.status.chair
  const isSpeaker = session.status.speaker
  const isViewer = (!isChair) && (!isSpeaker) && (!isCoach)

  // Capabilities of viewer
  const hasWaitingList = isOnlinePoster || isVirtualRoom
  const canRaiseHands = isAnymeetsLive || isVirtualRoom // Allows raising hands, adhoc temporary participants
  const hasQnA = true
  const hasPolls = true && !isOnlinePoster && !isVirtualRoom
  const hasChat = true
  const hasInfo = !isVirtualRoom
  const hasSponsors = true && !isOnlinePoster && !isVirtualRoom
  const canPresentFile = true
  const canUploadFile = true
  const canSelectExistingFile = !isOnlinePoster && !isVirtualRoom
  const canPresentSpeaker = true
  const canPresentQuestions = hasQnA
  const audienceObj = audience.find((a)=>a.id===participantId)
  const raisedHand = audienceObj&&audienceObj.raisedHand
  const hasSpeakerRights = participants.find((p)=>p.id===participantId)

  const hasSetupDevices = videoSource || audioSource
  const isStarted = session.status.started
  const layoutMode = session.status.layout.mode
  const autoMode = session.status.auto
  const showRooms = session.status.layout.showRooms
  const isLive = session.status.live
  const startDate = session.startDate
  const endDate = session.endDate
  const background = session.backgroundId // This is a imageURL (uuid)
  const videoURL = session.status.streamUrl
  const recordingUrl = session.recordingUrl
  const roomText = session.status.layout.bannerText
  const userVisibility = session.status.visible
  const files = session.files && [...session.files, ...session.presentations.map((p) => p.files.map((file, i) => file)).flat()].filter((f) => f.filename.substr(f.filename.lastIndexOf('.') + 1).toLowerCase() === 'pdf')
  const description = session.description
  const physicalRoom = session.room
  const allFeedback = session.status.allFeedback
  const activeFeedback = session.status.activeFeedback
  const hasFeedback = session.status.hasFeedback
  const feedback = session.status.feedback
  const presentations = session.presentations
  const audienceTotalJoined = session.status.totalJoined
  const audienceTotalOnline = session.status.totalOnline
  const bannerStreamerUserId = session.status.bannerStreamerUserId
  const recording = session.status.recording

  const hasChairControls = isChair || isCoach
  const sponsors = session.sponsors
  const eventId = session.eventId
  const audienceParticipated = session.status.participated
  const likeClicks = session.status.likeClicks
  const dislikeClicks = session.status.dislikeClicks
  const likes = session.status.likes
  const dislikes = session.status.dislikes



  // These are used for showing links (if external service, the name of the service appears, otherwise the link)
  const isExternalService = [
    STREAM_VIDEO_TYPES.GOOGLE,
    STREAM_VIDEO_TYPES.VIMEO,
    STREAM_VIDEO_TYPES.YOUTUBE,
    STREAM_VIDEO_TYPES.ZOOM].includes(session.status.videoStreamType)
  const serviceName = session.status.videoStreamType && STREAM_OPTIONS.find((o) => o.id === session.status.videoStreamType).name

  // OpenTok Configuration
  /////////////////////////
  const apiKey = session.status.openTokKey
  const sessionId = session.status.videoSessionId
  const token = session.status.connectionToken
  const hasOpenTokConfig = apiKey && sessionId && token
  const useOpenTok = !!(hasOpenTokConfig && (isSpeaker||hasSpeakerRights||(isAnymeetsLive&&isLive))) && layoutMode !== 1

  // Camera-Mic Configuration
  const raisedHandAndAccepted = (canRaiseHands && isViewer && participants.find((p) => p.id === participantId))
  const shouldBroadcastButHaveNoSetupDevices = raisedHandAndAccepted && !hasSetupDevices
  const showPreviewDialog = (!hasSetupDevices && isStarted && type !== 'viewer' && hasOpenTokConfig && participants.find((p) => p.id === participantId)) || (shouldBroadcastButHaveNoSetupDevices && isStarted)

  // Logic for Video Container
  /////////////////////////////
  // Use HLS for types 2,3 (YT,Vimeo), 8 (own RTMP) or if viewer
  const useHLS = (isViewer && session.status.streamUrl && !canRaiseHands) || ((session.status.videoStreamType === 2 || session.status.videoStreamType === 3 || session.status.videoStreamType === 8) && session.status.streamUrl)

  // Show URL on screen if any of the other custom types
  const useURL = [4, 5, 6].includes(session.status.videoStreamType)

  // Play recorded URL
  const useRecording = (!isStarted) && session.status.playRecording && session.recordingUrl && !useHLS && !useURL

  // Chat/QA/Polls
  const pollsActive = !useRecording&&session.status.PollsActive
  const qaActive = !useRecording&&session.status.QAActive
  const chatActive = !useRecording&&session.status.chatActive

  // Visibility
  const [openVisibilityDialog, setOpenVisibilityDialog] = useState(userVisibility === null)

  const closeVisibilityDialog = () => {
    setOpenVisibilityDialog(false)
  }

  // Passed down state
  const sessionState = {
    hasWaitingList,
    roomTitle,
    roomId,
    isAnymeetsLive,
    isVirtualRoom,
    customTabs,
    cancelHeartbeat,
    canRaiseHands,
    roomSessionId,
    participants,
    participantId,
    isCoach,
    isStarted,
    layoutMode,
    autoMode,
    apiKey,
    sessionId,
    token,
    useHLS,
    useURL,
    useOpenTok,
    useRecording,
    isLive,
    startDate,
    endDate,
    background,
    videoURL,
    roomText,
    isExternalService,
    serviceName,
    userVisibility,
    files,
    description,
    physicalRoom,
    showRooms,
    allFeedback,
    activeFeedback,
    hasFeedback,
    feedback,
    audience,
    audienceTotalJoined,
    audienceTotalOnline,
    audienceParticipated,
    hasChairControls,
    presentations,
    pollsActive,
    qaActive,
    chatActive,
    chatConnected,
    publisherRef,
    sponsors,
    eventId,
    likeClicks,
    dislikeClicks,
    likes,
    dislikes,
    // Capabilities
    hasQnA,
    hasPolls,
    hasChat,
    hasInfo,
    canPresentFile,
    canUploadFile,
    canSelectExistingFile,
    canPresentQuestions,
    canPresentSpeaker,
    hasSponsors,
    isChair,
    isSpeaker,
    isViewer,
    videoContainerRef,
    isOnlinePoster,
    posterPresentation,
    hasOpenTokConfig,
    raisedHands: raisedHands.current,
    type,
    hasSetupDevices,
    bannerStreamerUserId,
    recordingUrl,
    isBackstage: !isLive,
    sessionHelper,
    sessionActive,
    raisedHand,
    isWorkShop,
    recording
  }

  if (exit) return <Redirect to={`/event/${room.eventId}/info`}/>

  return <ThemeProvider theme={themeSession}>

    <NotificationViewer notification={notification}/>

    {hasPolls && <PopoutPoll sessionState={sessionState} {...feedbackHandlers} />}

    <RootRef rootRef={videoContainerRef}>
      <Grid container className={classes.root}>

        <Grid item xs>
          <Grid item container direction='column' justify='space-between' className={classes.leftColumn}>
            <>
              {!useRecording&&<VisibilityDialog open={openVisibilityDialog} handleClose={closeVisibilityDialog}
                                sessionId={roomSessionId}
                                roomId={roomId}/>}
              <FileSelectionDialog open={selectingFile} handleClose={closeSelectFileDialog}
                                   files={files} sessionState={sessionState} {...presentationHandlers} />

              {layoutMode === 1 && <Canvas session={session} {...streamingHandlers} />}

              <Grid item container direction='column' justify='space-between' className={classes.leftColumn}>
                <Grid item container className={clsx(classes.video, isWorkShop && classes.videoWorkShop)}
                      justify='space-between' direction='column'>

                  {!isWorkShop && <>
                    {hasVideoHeader &&
                    <Grid item container className={classes.videoHeader}>
                      <VideoHeader sessionState={sessionState} {...streamingHandlers}/>
                    </Grid>
                    }

                    <Grid item container justify='center' alignContent='center' alignItems='center'
                          className={classes.videoContainer}>
                      <Grid item xs={12} style={{height: '100%', position: 'relative'}}>
                        {layoutMode === 1 && showRooms && <RoomsBanner sessionId={roomSessionId}/>}
                        {sessionState &&
                        <VideoContainer sessionState={sessionState} videoContainer={videoContainer} {...allHandlers}
                                        allHandlers={allHandlers}/>}
                      </Grid>
                    </Grid>

                    {hasVideoFooter && <VideoFooter sessionState={sessionState} {...allHandlers} />}
                  </>}


                </Grid>

                {!isWorkShop &&
                <Grid item container className={classes.bottomContainer} justify='space-between' alignContent='center'
                      alignItems='center' spacing={2}>

                  {presentingFile && participants[0].id === participantId &&
                  <FilePresenter sessionId={roomSessionId} file={presentingFile} {...allHandlers} />
                  }

                  {presentingQuestion && <>
                    <QuestionPresenter
                      sessionId={roomSessionId}
                      roomId={roomId}
                      {...allHandlers}
                    />
                  </>}

                  {presentingSpeaker && <SpeakerPresenter participants={participants} {...allHandlers} />}

                  <ViewerFooter sessionState={sessionState}/>

                </Grid>}

                {isWorkShop && <>
                  {workShopData && workShopData.code &&
                  <RoomIntegration uuid={workShopData.code} onExit={goBacktoInfo} sponsors={session.sponsors}
                                   placement={'bottom'}/>}
                  {isOnlinePoster && room.id &&
                  <RoomIntegration uuid={room.id} onExit={goBacktoInfo} sponsors={session.sponsors}
                                   placement={'bottom'}/>}
                </>}

                {((showPreviewDialog && type !== 'viewer') || changeDeviceSetup) && !isWorkShop && <PreviewDialog
                  handleClose={() => setChangeDeviceSetup(false)}
                  open={((!hasSetupDevices) && isStarted) || changeDeviceSetup}
                  isViewer={isViewer}
                  isChair={isChair}
                  isOnlinePoster={isOnlinePoster}
                  {...allHandlers}
                  videoContainerRef={videoContainerRef}
                />}
              </Grid>
            </>


          </Grid>
        </Grid>

        <Resizable minWidth={300} maxWidth={'60%'} enable={{
          top: false,
          right: false,
          bottom: false,
          left: true,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false
        }} defaultSize={{
          width: 380
        }}>

          <Grid item className={classes.content}>

            <Grid item container direction='column' spacing={2}>

              <Grid item container className={classes.navigation} direction='column'>
                <Grid item xs={12} id={'chair-control-Tabs'}>
                  <Navigation setTab={setTab} tab={tab} sessionState={sessionState} hasChatMessage={hasChatMessage}/>
                </Grid>
              </Grid>
              <PerfectScrollbar>
                <Grid item container className={classes.contentInner}>
                  <Fade in={true} timeout={200} key={tab}>
                    <Grid item container>
                      <ViewerTabs sessionState={sessionState} tab={tab} allHandlers={allHandlers}
                                  feedbackHandlers={feedbackHandlers} chatHandlers={chatHandlers}/>
                    </Grid>
                  </Fade>
                </Grid>
              </PerfectScrollbar>
            </Grid>

          </Grid>
        </Resizable>

      </Grid>
    </RootRef>
  </ThemeProvider>

})

export default OpenTokRoom
