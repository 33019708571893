import {makeStyles} from '@material-ui/styles'

export const presentation_vert_7 = makeStyles({

  item_1: {
    top: '1%',
    left: '16%',
    width: '83.5%',
    height: '98%',
    opacity: '1 !important'
  },

  item_2: {
    left: '0.5%',
    top: '2.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  },

  item_3: {
    left: '0.5%',
    top: '18.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  },

  item_4: {
    left: '0.5%',
    top: '34.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  },

  item_5: {
    left: '0.5%',
    top: '50.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  },

  item_6: {
    left: '0.5%',
    top: '66.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  },

  item_7: {
    left: '0.5%',
    top: '82.5%',
    width: '15%',
    height: '15%',
    opacity: '1 !important'
  }

})
