import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {FormHelperText, Select} from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import React, {useEffect, useState} from 'react'
import {palette, theme} from 'theme'
import Typography from '@material-ui/core/Typography'
import RichTextEditor from 'react-rte'
import {FIELD_TYPES} from 'config'
import BlockText from 'components/core/BlockText'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
        {label: 'Italic', style: 'ITALIC'},
        {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
        {label: 'Normal', style: 'unstyled'},
        {label: 'Heading Large', style: 'header-one'},
        {label: 'Heading Medium', style: 'header-two'},
        {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
        {label: 'UL', style: 'unordered-list-item'},
        {label: 'OL', style: 'ordered-list-item'}
    ]
}

const styles = theme => ({
    addNewItem:{
        border: '2px dashed !important',
        opacity: 0.3,
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.5
        }
    },
    field: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        border: '1px solid',
        borderRadius: '6px',
        borderColor: 'white',
        backgroundColor: 'rgba(0,0,0,0.1)',
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    formControl: {
        width: '100%'
    },
    toolbar: {
        padding: theme.spacing(2),
        borderTop: '1px solid',
        color: 'white',
        marginTop: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0
    },
    editor: {
        color: palette['greyish-brown'],
        borderRadius: '6px',
        minHeight: '300px',
        fontFamily: "'Roboto','Karla', sans-serif;",
        boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
    },
    dialogActions: {
        marginTop: theme.spacing(2)
    },
    darkMode: {
        backgroundColor: palette['cerulean-blue']
    }
})

const FieldError = ({field}) => {

    if (field.error) {
        return <FormHelperText error variant='filled'> {field.error} </FormHelperText>
    } else {
        return <> </>
    }

}

const FieldRenderer =  withStyles(styles)(({classes,field, index, changeValue, thematicCodes}) => {
    const [editorValue] = useState({    value: RichTextEditor.createValueFromString(field.type===6&&field.value?field.value:'','html') })

    const [thematicLabels, setThematicLabels] = useState()

    const calcThematicLabels = () => {
        const labels = {}
        thematicCodes.forEach((bucket)=>{
            bucket.codes.forEach((code=>{
                labels[code.id] = code.label
            }))
        })
        setThematicLabels(labels)
    }

    useEffect(()=>{
        if (field.type===FIELD_TYPES.THEMATIC_CODE) {
            calcThematicLabels()
        }
        // eslint-disable-next-line
    },[])

    switch (field.type) {
      case FIELD_TYPES.LABEL: {
        // Label
        return (
          <>
            <Typography variant={"h6"}>{field.label}</Typography>
          </>
        );
      }
      case FIELD_TYPES.TEXT: {
        // Text
        return (
          <>
            <BlockText title={field.label} value={field.value} withBorder />
          </>
        );
      }
      case FIELD_TYPES.SINGLE_SELECT: {
        // Select
        const selected = field.options.find((o) => o.id === field.value);
        return (
          <>
            <BlockText title={field.label} value={selected ? selected.option : ""} withBorder />
          </>
        );
      }
      case FIELD_TYPES.MULTI_SELECT: {
        // Multiple Select
        const selected = [];
        field.value &&
          field.value.length &&
          field.value.forEach((v) => {
            const option = field.options.find((o) => o.id === v);
            if (option) {
              selected.push(option.option);
            }
          });
        return (
          <>
            <BlockText title={field.label} value={selected.join(",")} withBorder />
          </>
        );
      }
      case FIELD_TYPES.TEXT_AREA: {
        // Text area
        return (
          <>
            <BlockText title={field.label} value={field.value} withBorder />
          </>
        );
      }
      case FIELD_TYPES.RICH_TEXT: {
        // Rich Text
        return (
          <>
            <Box fontSize={14} mb={1} mt={1}>
              {field.label}
            </Box>
            <RichTextEditor disabled={true} className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} />
          </>
        );
      }
      case FIELD_TYPES.BOOLEAN: {
        // Boolean (checkbox)
        return (
          <>
            <BlockText title={field.label} value={field.value ? "Yes" : "No"} withBorder />
          </>
        );
      }
      case FIELD_TYPES.NUMBER: {
        // Number
        return (
          <>
            <BlockText title={field.label} value={field.value} withBorder />
          </>
        );
      }

      case FIELD_TYPES.THEMATIC_CODE: {
        // Thematic Code
        const codes = [];
        thematicLabels &&
          field.value &&
          field.value.length &&
          field.value.forEach((v) => {
            codes.push(thematicLabels[v]);
          });
        return (
          <>
            <BlockText title={field.label} value={codes.join(",")} withBorder />
          </>
        );
      }
      case FIELD_TYPES.AUTHORS: {
        return (
          <>
            <Box mb={1}>
              <BlockText
                title={"Authors"}
                value={
                  <Box style={{overflowWrap: "anywhere"}}>
                    {field.value.authors.join(",")}
                    {field.value.newAuthors ? `,${field.value.newAuthors.map((a) => a.email).join(",")}` : ""}
                  </Box>
                }
                withBorder
              />
            </Box>
            <Box>
              <BlockText title={"Presenter"} value={field.value.presenter} withBorder />
            </Box>
          </>
        );
      }
      case FIELD_TYPES.FILE: {
        return (
          <>
            {field.value && field.value.file && <BlockText title={"File"} value={field.value.file.name} />}
            {field.value && field.value.file && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
            {field.value && field.value.name && <BlockText title={"File"} value={field.value.name} withBorder />}
            {field.value && field.value.name && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        );
      }
      case FIELD_TYPES.ABSTRACT: {
        // Text area
        return (
          <>
            <BlockText title={field.label} value={field.value} withBorder />
          </>
        );
      }
      case FIELD_TYPES.TITLE: {
        // Text
        return (
          <>
            <BlockText title={field.label} value={field.value} withBorder />
          </>
        );
      }
      case FIELD_TYPES.FILE_CAMERA_READY: {
        return (
          <>
            {field.value && field.value.file && <BlockText title={"File"} value={field.value.file.name} />}
            {field.value && field.value.file && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
            {field.value && field.value.name && <BlockText title={"File"} value={field.value.name} withBorder />}
            {field.value && field.value.name && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        );
      }
      case FIELD_TYPES.COPYRIGHT: {
        return (
          <>
            {field.value && field.value.file && <BlockText title={"File"} value={field.value.file.name} />}
            {field.value && field.value.file && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
            {field.value && field.value.name && <BlockText title={"File"} value={field.value.name} withBorder />}
            {field.value && field.value.name && (
              <Grid item container justify="flex-end" style={{marginTop: theme.spacing(2)}}>
                <Grid item>
                  <Button size="smaller" href={`/files/${field.value.id}`} download={`${field.value.name}`}>
                    Download file
                  </Button>
                </Grid>
              </Grid>
            )}
          </>
        );
      }
      case FIELD_TYPES.GRADE: {
        return (
          <>
            <Select disabled required={field.required} labelId={`grade`} label={`${field.label} ${field.required ? "*" : ""}`} variant="filled" displayEmpty fullWidth value={field.value}>
              {field.options.map((option, i) => {
                return (
                  <MenuItem value={option.id} key={i}>
                    {option.option}
                  </MenuItem>
                );
              })}
            </Select>
            {field.description && <FormHelperText className={classes.helperText}>{field.description}</FormHelperText>}
            <InputLabel id={`grade`} variant="filled">{`${field.label} ${field.required ? "*" : ""}`}</InputLabel>
          </>
        );
      }

      default: {
        return <> </>;
      }
      // 1: 'Label',
      //   2: 'Text',
      //   3: 'Single select',
      //   4: 'Multi select',
      //   5: 'Text area',
      //   6: 'Rich text',
      //   7: 'Boolean',
      //   8: 'Number',
      //   9: 'Thematic code'
    }
})

const FormViewer = withStyles(styles)(({classes, fields=[], setFields, darkMode=false, thematicCodes}) => {

    return <>
        <Grid container spacing={2}>
            {
                fields.map((field,i)=>{
                    return <Grid item xs={12}>
                        <FormControl className={classes.formControl}>
                            <FieldRenderer key={i} field={field} index={i} thematicCodes={thematicCodes}/>
                            <FieldError field={field} />
                        </FormControl>
                    </Grid>
                })
            }
        </Grid>
    </>

})

export default FormViewer
