import {withStyles} from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'
import {imageFile} from 'utils'
import IconButton from '@material-ui/core/IconButton'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import DialogActions from '@material-ui/core/DialogActions'
import Button from 'components/core/Button'
import React from 'react'
import {palette} from 'theme'


const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  formControl: {
    width: '100%',
    textAlign: 'left'
  },
  newSponsor: {
    maxWidth: '770px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto'
    }
  },
  sessionsBox: {
    border: '1px solid white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    overflowY: 'scroll'
  },
  sessionsList: {
    color: palette['greyish-brown'],
    backgroundColor: 'white',
    borderRadius: '6px',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    height: '135px',
    overflowY: 'scroll'
  },
  chip: {
    margin: theme.spacing(1),
    // maxWidth: '135px'
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: 'center'
  },
  userPhotoImage: {
    display: 'flex',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img' : {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    },    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
})

const ProfileView = withStyles(styles)(({classes, user, open, setUser, associateUser})=> {

  return <Dialog
    open={open}
    onClose={()=>{setUser(false)}}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  ><Box p={3} pt={1}>
    <DialogTitle id="alert-dialog-title">User Profile</DialogTitle>
    <DialogContent>

      <Grid container spacing={1}>
        <Grid item xs={12} justify='center'>
          <Box display='flex' alignItems='center' justifyContent='center' alignContent='center' flexDirection={'column'}>
            <Box className={classes.userPhotoImage}>
              <img src={imageFile(user.image)} alt={`${user.title?user.title:''} ${user.firstName} ${user.lastName}`} />
            </Box>
            <Box display='flex' fontSize={18} fontWeight='bold' p={2} textAlign='center'>
              {`${user.title?user.title:''} ${user.firstName} ${user.lastName}`}
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box fontSize={14}>Position</Box>
          <Box fontSize={16} fontWeight={'bold'}> {user.profession?user.profession:'-'} </Box>
        </Grid>

        <Grid item xs={12}>
          <Box fontSize={14}>Affiliation / Company</Box>
          <Box fontSize={16} fontWeight={'bold'}> {user.instituteOrCompany?user.instituteOrCompany:'-'} </Box>
        </Grid>

        {user.linkedinUrl&&<Grid item xs={12}>
          <Box ml={-2}><IconButton href={user.linkedinUrl} target='_blank'><LinkedInIcon /></IconButton></Box>
        </Grid>}

      </Grid>

    </DialogContent>
    <DialogActions>
      <Grid container spacing={2} justify={'center'}>
        <Grid item>
          <Button size='small' variant='outlined' color='primary' onClick={()=>setUser()}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button size='small' variant='contained' color='primary' onClick={associateUser} >
            Associate user
          </Button>
        </Grid>
      </Grid>
      {/*<Box p={1}>*/}
      {/*  <Button onClick={()=>{handleDeleteClose(deletingCollaborator)}}  size='small' autoFocus color='primary'>*/}
      {/*    Yes*/}
      {/*  </Button>*/}
      {/*</Box>*/}
    </DialogActions>
  </Box>
  </Dialog>
})

export default ProfileView
