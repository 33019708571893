import React, {forwardRef, useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import * as Yup from 'yup'

import api from 'api'
import {useHttp} from 'api/core'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import {FormHelperText} from '@material-ui/core'

import AddBox from '@material-ui/icons/AddBox'
import Check from '@material-ui/icons/Check'
import Clear from '@material-ui/icons/Clear'
import DeleteOutline from '@material-ui/icons/DeleteOutline'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Edit from '@material-ui/icons/Edit'
import SaveAlt from '@material-ui/icons/SaveAlt'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import Search from '@material-ui/icons/Search'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Remove from '@material-ui/icons/Remove'
import ViewColumn from '@material-ui/icons/ViewColumn'
import MaterialTable from 'material-table'
import Typography from '@material-ui/core/Typography'
import LoadingDialog from 'components/core/LoadingDialog'

const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newParticipant: {
    maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto',
      backgroundColor: palette.imageBackgroundColor
    }
  },
  btns: {
    textAlign: 'center'
  },
  textFieldToInput: {
    borderRadius: '6px',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    borderBottomLeftRadius: '6px',
    borderBottomRightRadius: '6px',
    width: '100%',
    border: `1px solid #2c3d4f`,
    padding: theme.spacing(2)
  },
  userFound:
    {
      backgroundColor: 'white',
      height: '30px',
      color: 'black',
      zIndex: 1,
      borderLeft: `1px solid #2c3d4f`,
      borderRight: `1px solid #2c3d4f`,
      borderBottom: `1px solid #2c3d4f`,
      borderBottomLeftRadius: '6px',
      borderBottomRightRadius: '6px',
      padding: theme.spacing(2),
      userSelect: 'none',
      cursor: 'pointer',
      textAlign: 'center'
    },
  bulkDialog: {
    padding: theme.spacing(2),
    overflow: 'none'
  }
})

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const validationSchema = Yup.object().shape({
  ticketId: Yup.string().required('This field is required'),
  quantity: Yup.string().required('This field is required')
})

const AttendessBulkImport = withStyles(styles)(({classes, tickets, handleClose, eventId, setSuccessful, file, ...props}) => {

  const [putData, data, isLoading] = useHttp()
  const [saveData, dataSave, isLoadingSave] = useHttp()
  // eslint-disable-next-line
  const [formData, setFormData] = useState([])
  const [formSave, setFormSave] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (file) {
      let formDataObj = new FormData()
      formDataObj.append('file', file)
      putData(api.entities.attendees.uploadAttendeesPreview(formDataObj, null, {id: eventId}, true))
    }
// eslint-disable-next-line
  }, [file])

  useEffect(() => {
    setIsSubmitting(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (dataSave) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [dataSave])
  useEffect(() => {
    if (formSave) {

      const {...formDataItems} = formSave

      let filteredAttendees = []
      data.forEach(function(item,index)
      {
        filteredAttendees.push({title:item.title,firstName:item.firstName,lastName:item.lastName,email:item.email,profession:item.profession,institute:item.institute,linkedinUrl:item.linkedinUrl})
      });

      const payLoad = {
        registrations: filteredAttendees.filter((a)=>!!a.email&&!!a.firstName&&!!a.lastName),
        ticketId: formDataItems.ticketId,
        quantity: formDataItems.quantity * filteredAttendees.length, //quantity multiplied by total attendees
      }

      saveData(api.entities.attendees.addNew(payLoad, null, {id: eventId}))
      setFormSave(undefined)
    }
    // eslint-disable-next-line
  }, [eventId, formSave, saveData])

    return <Box className={classes.bulkDialog}>
    <ThemeProvider theme={theme}>
      <Box>
        {(isLoading || isLoadingSave) && <LoadingDialog open={true}/>}
        <Formik
          initialValues={{
            ...formData,
            quantity: '',
            ticketId: '',
          }}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(values, {setSubmitting}) => {
            const newValues = {...formData, ...values}
            setFormSave(newValues)
          }}>
          {({errors, submitForm, setFieldValue, values, touched}) => {

            return <>
              <Form className={classes.form}>
                <Grid item container spacing={2}>
                  <Grid item xs={8}>
                    {data && data.length > 0 &&
                    <MaterialTable
                      icons={tableIcons}
                      columns={[
                        {title: 'Title', field: 'title', cellStyle: {width: '20px', maxWidth: '20px'}},
                        {title: 'First name', field: 'firstName', editable: 'never'},
                        {title: 'Last name', field: 'lastName', editable: 'never'},
                        {title: 'Email', field: 'email', editable: 'never'},
                        {title: 'Position', field: 'profession', editable: 'never'},
                        {title: 'Affiliation / Company', field: 'institute', editable: 'never'},
                        {
                          title: 'LinkedIn',
                          field: 'linkedinUrl',
                          cellStyle: {width: '80px', maxWidth: '80px', wordBreak: 'break-all'},
                          editable: 'never'
                        },
                      ]}
                      data={data}
                      title="Attendees"
                      options={{
                        actionsColumnIndex: 999,
                        headerStyle: {
                          backgroundColor: palette['brown-grey'],
                          color: '#FFF',
                          fontWeight: 'bold',
                          fontSize: 14
                        },
                      }}
                    />
                    }
                  </Grid>
                  <Grid item xs={4}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant='h5'
                                    component='h1'>Ticket</Typography>
                        <FormControl fullWidth>
                          <Field type='select' labelId='ticketLabel' displayEmpty name='ticketId' component={Select}
                                 label='Ticket'
                                 variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                            <MenuItem value={'-'} key={-1}>None</MenuItem>
                            {tickets && tickets.filter((r) => r.id >= 0).map((ticket, i) => {
                              return <MenuItem value={ticket.id} key={i}>{ticket.name}</MenuItem>
                            })}
                          </Field>
                          <InputLabel variant='filled' required>Ticket</InputLabel>
                          {errors && errors.trackId && touched.ticketId &&
                          <FormHelperText error variant='filled'> {errors.ticketId} </FormHelperText>}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Box mt={2}>
                        <Typography variant='h5'
                                    component='h1'>Quantity</Typography>
                        <Field type='number' label='Quantity' name='quantity' component={TextField} fullWidth
                               disabled={isSubmitting} variant={'filled'} required/>
                        </Box>
                      </Grid>

                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.btns}>
                  <Grid item xs={12} md={6}>
                    <Button variant='outlined' size='small' onClick={handleClose}> Cancel </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button size='small' type='submit'> Save Changes </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          }}
        </Formik>
      </Box>
    </ThemeProvider>
  </Box>
})

export default AttendessBulkImport
