import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {IconUserPicAddLight} from 'components/core/icons'
import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import * as Yup from 'yup'
import {FILES_URL, SIZE_SPONSOR_LOGO, SPONSOR_TYPES} from 'config'
import {useSnackbar} from 'notistack'
import {useDropzone} from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import api from 'api'
import {useHttp} from 'api/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Chip from '@material-ui/core/Chip'
import InputLabel from '@material-ui/core/InputLabel'
import CropImage from 'components/core/CropImage'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import FindUserDialog from 'components/core/FindUserDialog'
import ProfileView from 'components/core/ProfileView'
import {FormHelperText} from '@material-ui/core'
import RichTextEditor from 'react-rte'
import CameraAlt from '@material-ui/icons/CameraAlt'

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "40px 50px 50px 50px",
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  newSponsor: {
    maxWidth: "770px",
  },
  row: {
    marginBottom: theme.spacing(4),
  },
  userPhoto: {
    width: "80px",
    height: "80px",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "100%",
      height: "auto",
    },
  },
  sessionsBox: {
    border: "1px solid white",
    borderRadius: "6px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    overflowY: "scroll",
  },
  sessionsList: {
    color: palette["greyish-brown"],
    backgroundColor: "white",
    borderRadius: "6px",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
    height: "135px",
    overflowY: "scroll",
  },
  chip: {
    margin: theme.spacing(1),
    // maxWidth: '135px'
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  userPhotoImage: {
    display: "flex",
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    backgroundColor: "white",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
    "& > img": {
      width: "100%",
      height: "auto",
      backgroundColor: palette.imageBackgroundColor,
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  editor: {
    color: "black",
    minHeight: "210px",
  },
  cameraIcon: {
    padding: theme.spacing(2),
    fontSize: "33px",
    border: "1px solid #bbb",
    borderRadius: "40px"    
  },
});

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required').max(255),
  url: Yup.string().max(255),
  offlineBannerText: Yup.string().max(140).nullable(),
  youtubeUrl: Yup.string().max(2000).nullable(),
  type: Yup.string().required('This field is required'),
  summary: Yup.string().required('This field is required').max(4000)
})

const UploadLogoImage = withStyles(styles)(({setFormData, formData, values, sponsor, size}) => {
  const {enqueueSnackbar} = useSnackbar()
  const [cropImage, setCropImage] = useState(false)

  const onDrop = (acceptedFiles) => {

    const fr = new FileReader()

    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        if ((img.width < size.width) && (img.height < size.height)) {
          enqueueSnackbar('The image file has the wrong dimensions', {variant: 'error'})
        } else {
          setCropImage(acceptedFiles[0])
        }
      }
      img.src = fr.result
    }

    fr.readAsDataURL(acceptedFiles[0])
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  const setImage = (image) => {
    setFormData({...formData, ...values, logo: image})
    setCropImage(false)
  }

  return <RootRef rootRef={ref}>
    {cropImage&&<CropImage file={cropImage} image={URL.createObjectURL(cropImage)} size={size} open={cropImage} setOpen={setCropImage} setImage={setImage} />}
    <Box {...rootProps} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
      <input name='image' type='file' {...getInputProps()} accept='.png, .jpg, .jpeg'/>
      <Button size='smaller' variant='outlined' colour='white'>{sponsor ? 'Change' : 'Select'} Image</Button>
      <Box display='flex' mt={2} mb={2}> Image should be at least {SIZE_SPONSOR_LOGO.width}x{SIZE_SPONSOR_LOGO.height} </Box>
    </Box>
  </RootRef>
})

const LogoImage = withStyles(styles)(({classes, formData, values}) => {
  return <>
    {(formData.hasOwnProperty('logo') || formData.logoId) ? <Box className={classes.userPhoto}><img
      src={(formData&&typeof formData.logo.name == 'string') ? URL.createObjectURL(formData.logo) : `${FILES_URL}/${formData.logoId}`}
      alt={``}/></Box> : !formData.logoId ? <IconUserPicAddLight/> : ''}
  </>
})

const SponsorEditNew = withStyles(styles)(({classes, sponsor, sessions=[], handleClose, eventId, setSuccessful, ...props}) => {
  const [formData, setFormData] = useState({...sponsor, sessions:sponsor?sponsor.sessions:[], logo:{}, associatedUsers:sponsor?sponsor.associatedUsers:[]})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [openAssociate, setOpenAssociate] = useState()
  const [user, setUser] = useState()
  const [editorValue, setEditorValue] = useState({    value: RichTextEditor.createValueFromString(sponsor?sponsor.summary:'','html') })


  useEffect(()=>{
    setIsSubmitting(isLoading&&!hasError)
  },[isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {
      let formDataObj = new FormData();

      const {logo, sessions, logoId, id, associatedUsers, ...formDataItems} = formSave

      if (typeof logo.name == 'string') formDataObj.append('logo', logo)
      // console.log('asdf')
      const sessionIds = sessions?sessions.map((session)=>session.id):[]
      const userEmails = []
      formData.associatedUsers.forEach((user)=>userEmails.push(user.email))
      let payLoad = {...formDataItems, sessionIds: sessionIds, deleteLogo: false, eventId, userEmails}

      const blob = new Blob([JSON.stringify(payLoad)], {type: 'application/json'});
      formDataObj.append('sponsor', blob)

      // If existing sponsor
      if (sponsor) {
        sendRequest(api.entities.sponsors.update(formDataObj, null, {id: sponsor.id}, true))
      } else { // else add new sponsor
        sendRequest(api.entities.sponsors.addNew(formDataObj, null, null, true))
      }

      setFormSave(undefined)
    }
    //eslint-disable-next-line
  }, [eventId, formSave, sendRequest, sponsor])

  const addSession = (pos,setFieldValue) => {
    let newSessions = [...formData.sessions]

    if (newSessions.findIndex((session)=>session.id===sessions[pos].id)===-1) {
      newSessions.push(sessions[pos])
      setFormData({...formData, sessions:newSessions})
      setFieldValue('sessions', newSessions)
    }
  }

  const deleteSession = (pos,setFieldValue) => {
    let newSessions = [...formData.sessions]
    newSessions.splice(pos,1)
    setFormData({...formData, sessions:newSessions})
    setFieldValue('sessions', newSessions)
  }

  const handleDelete = (i) => {
    const associatedUsers = [...formData.associatedUsers]
    associatedUsers.splice(i,1)
    setFormData({...formData, associatedUsers})
  }

  const handleAssociate = () => {
    setOpenAssociate(true)
  }

  const associateUser = (setFieldValue) => {
    const associatedUsers = [...formData.associatedUsers]

    if (associatedUsers.find((u)=>u.email===user.email)) {
      setUser()
      setOpenAssociate(false)
      return
    }

    associatedUsers.push({email: user.email, title: user.title, firstName: user.firstName, lastName: user.lastName, pictureId: user.image})

    setFormData({...formData, associatedUsers})
    setUser()
    setOpenAssociate(false)
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: 'Normal', style: 'unstyled'},
      {label: 'Heading Large', style: 'header-one'},
      {label: 'Heading Medium', style: 'header-two'},
      {label: 'Heading Small', style: 'header-three'}
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: 'UL', style: 'unordered-list-item'},
      {label: 'OL', style: 'ordered-list-item'}
    ]
  }

  const onEditorChange = (value, setFieldValue) => {
    setEditorValue({ value })
    if (value.toString("html") !== "<p><br></p>")
    {
      setFieldValue("summary", value.toString("html"));
    }
    else
    {
      setFieldValue("summary", "");
    }
  }

  return <Box className={classes.newSponsor}>

    <FindUserDialog open={openAssociate&&!user} setOpenAssociate={setOpenAssociate} setUser={setUser} />

    <Formik
      initialValues={{
        name: '',
        url: '',
        summary: '',
        type: '',
        eventSponsor: false,
        online: false,
        offlineBannerText: '',
        associatedUsers:'',
        ...formData
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values, logo: formData.logo}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values, touched}) => (
        <Form className={classes.form}>
          {user&&<ProfileView open={!!user} setUser={setUser} user={user} associateUser={()=>associateUser(setFieldValue)}/>}

          <Grid container className={classes.container}>

              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {sponsor ? 'Sponsor Information' : 'New Sponsor'}</Typography>
              </Grid>

              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box display='flex' alignSelf='center' flexDirection='column' alignItems='center'>
                    <Box display='flex' mb={2}>
                      {formData.logo.name?  (<LogoImage formData={formData} values={values} />) : (<CameraAlt className={classes.cameraIcon}/>)}
                    </Box>
                    <Box display='flex' mb={2}>
                      <UploadLogoImage setFormData={setFormData} setFieldValue={setFieldValue} values={values}
                                       sponsor={sponsor} size={SIZE_SPONSOR_LOGO}/>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} >

                  <Grid container spacing={1} className={classes.row}>
                    <Grid item xs={12} sm={12}>
                      <Field type='text' name='name' required component={TextField} label='Name' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field type='text' name='url' component={TextField} label='URL' variant='filled'
                             fullWidth
                             disabled={isSubmitting}/>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      {/*<Field type='text' name='summary' required component={TextField} id='Summary' label='Summary'*/}
                      {/*       variant='filled'*/}
                      {/*       fullWidth multiline*/}
                      {/*       rows={4} disabled={isSubmitting}/>*/}
                      <FormControl className={classes.formControl}>
                      <RichTextEditor name='summary' required className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v)=>onEditorChange(v,setFieldValue)}/>
                        {values.summary.length>4000&&<FormHelperText error variant='filled'> Sponsor summary cannot be longer than 4000 characters. </FormHelperText>}
                        {(values.summary==="<p><br></p>" || (errors&&errors.summary))&&<FormHelperText error variant='filled'>{errors.summary?errors.summary: "This field is required"} </FormHelperText>}
                    </FormControl>
                      </Grid>

                    <Grid item xs={12} sm={12}>
                      <FormControl className={classes.formControl}>
                        <Field type='select' labelId='type' displayEmpty name='type' component={Select} label='Type' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                          {Object.keys(SPONSOR_TYPES).map((key, i)=>{
                            return <MenuItem value={key} key={i}>{SPONSOR_TYPES[key]}</MenuItem>
                          })}
                        </Field>
                        <InputLabel variant='filled' required>Type</InputLabel>
                        {errors&&errors.type&&touched.type&&<FormHelperText error variant='filled'> {errors.type} </FormHelperText>}
                      </FormControl>

                    </Grid>

                    <Grid item container spacing={2} justify={'center'}>
                        {/*Online sponsor page*/}
                        {/*<Field type='checkbox' name='online' component={Switch} disabled={isSubmitting}/>*/}
                        <Grid item xs={12} sm={12}>
                          <FormControl className={classes.formControl}>
                            <Field type='select' labelId='type' displayEmpty name='online' component={Select} label='Sponsor page' variant='filled' fullWidth disabled={isSubmitting} defaultValue={false}>
                              <MenuItem value={false} key={false}>Simple information page</MenuItem>
                              <MenuItem value={true} key={true}>Page with online features</MenuItem>
                            </Field>
                            <InputLabel variant='filled'>Sponsor page</InputLabel>
                            {errors&&errors.online&&touched.online&&<FormHelperText error variant='filled'> {errors.online} </FormHelperText>}
                          </FormControl>
                      </Grid>
                    </Grid>

                    {values.online&&<Grid item container spacing={1}>
                      <Grid item xs={12}>
                        <Field type='text' name='offlineBannerText' component={TextField} label='Offline banner message' variant='filled'
                               fullWidth
                               disabled={isSubmitting}/>
                      </Grid>
                      <Grid item xs={12}>
                        <Field type='text' name='youtubeUrl' component={TextField} label='YouTube video url' variant='filled'
                               fullWidth
                               disabled={isSubmitting}/>
                      </Grid>
                    </Grid>}

                    <Grid item container justify='center' spacing={2} alignContent='center' alignItems='center'>
                      {/*{!formData.associatedUsers&&formData.associatedUsers.length===0&&<>*/}
                      {/*  <Grid item xs={12}>*/}
                      {/*    <Box pt={2}>*/}
                      {/*      Associated users:*/}
                      {/*    </Box>*/}
                      {/*  </Grid>*/}
                      {/*<Grid item>*/}
                      {/*    <Button variant='contained' size='smaller' onClick={handleAssociate}> Associate a user with this sponsor </Button>*/}
                      {/*</Grid></>}*/}
                      <Grid item xs={12}>
                        <Box pt={2}>
                        Associated users:
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box minHeight={149} width={'100%'} className={classes.sessionsBox} p={1}>
                          {formData.associatedUsers.map((associatedUser,i)=>{
                            return <Chip className={classes.chip} avatar={<Avatar src={associatedUser.pictureId?`${imageFile(associatedUser.pictureId)}`:null} alt={`${associatedUser.title?associatedUser.title:''} ${associatedUser.firstName} ${associatedUser.lastName}`} />} label={`${associatedUser.title?associatedUser.title:''} ${associatedUser.firstName} ${associatedUser.lastName}`} onDelete={()=>handleDelete(i)}/>
                          })}
                        </Box>
                      </Grid>
                      <Grid item container justify='flex-end'>
                        <Grid item>
                          <Button variant='contained' size='smaller' onClick={handleAssociate}> Associate a user with this sponsor </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*<Grid container spacing={2} alignItems='center' alignContent='center'>*/}
              {/*  <Grid item> Sponsorship &nbsp; Is this an event sponsorship? <Field type='checkbox' name='eventSponsor' component={Switch} disabled={isSubmitting}/> </Grid>*/}
              {/*</Grid>*/}

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>If a specific session(s) is being sponsored, please select the session(s) from the list. The sponsor details will appear in the program in the specific session slot(s)</Grid>
                <Grid item xs={12} sm={4}>
                  {sessions.length===0?<Box display='flex' justifyContent='center' alignItems='center' className={classes.sessionsList} aria-label='sessions'>
                      <Box display='flex' >No sessions available</Box>
                    </Box>:
                    <List component='nav' className={classes.sessionsList} aria-label='sessions'>
                      {sessions.map((session,i)=>{
                        return <ListItem button key={i} onClick={()=>{addSession(i,setFieldValue)}}>
                          <ListItemText primary={`${session.title}`} />
                        </ListItem>
                      })}
                    </List>}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Box minHeight={149} width={'100%'} className={classes.sessionsBox}>
                    {formData.sessions&&formData.sessions.map((session,i)=>{
                      return <Chip
                        size='small'
                        key={i}
                        label={`${session.title}`}
                        onDelete={()=>{deleteSession(i, setFieldValue)}}
                        className={classes.chip}
                      />
                    })}
                  </Box>
                </Grid>
              </Grid>

            <Grid container spacing={3} className={classes.btns}>
              <Grid item lg={2} md={2}> </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Button variant='outlined' size='small' colour='white' onClick={handleClose} disabled={isSubmitting}> Cancel </Button>
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
              </Grid>
              <Grid item lg={2} md={2}> </Grid>
            </Grid>

          </Grid>
        </Form>
      )}
    </Formik>
  </Box>
})

export default SponsorEditNew
