import {withStyles} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog'
import api from 'api'
import {useHttp} from 'api/core'
import Feedback from 'components/core/OpenTok/Components/Feedback/Feedback'

const styles = (theme) => ({
 dialogPaper: {
     backgroundColor: 'white',
     // color: 'white',
     borderRadius: '6px',
     padding: '16px 24px',
     transition: 'all 0.4s',
     minHeight: '300px',
     marginTop: '-100px',
     marginLeft: '60%',
     position: 'relative',
     [theme.breakpoints.down('md')]: {
       marginTop: 'initial',
       marginLeft: 'initial',
     }
 }
});

const PopoutPoll = withStyles(styles)(({classes, sessionState, openFeedback, setOpenFeedback}) => {

  const {
    activeFeedback,isChair,isCoach
  } = sessionState

  const [selectedFeedback, setSelectedFeedback] = useState(openFeedback)
  const [notAnswered, setNotAnswered] = useState(false)
  const [closedDialog, setClosedDialog] = useState(false)

  const [getData, data] = useHttp()

  const handleClose = () => {
    if (selectedFeedback) {
      setSelectedFeedback(false)
    } else {
      setNotAnswered(false)
    }

    setOpenFeedback(false)
    if (activeFeedback === null) {
      setClosedDialog(false)
    } else {
      setClosedDialog(true)
    }

  }

  useEffect(() => {
    if (activeFeedback === null) {
      setClosedDialog(false)
      setNotAnswered(false) // keep it closed until we are sure we haven't answered yet (get)
    } else {
      getData(api.entities.feedback.getQuestions(null, {q: 1}, {code:activeFeedback}))
    }
    //eslint-disable-next-line
  }, [activeFeedback])

  useEffect(() => {
    if (data) {
      if (data.selectedOptionId === null) {
        setNotAnswered(true)
      } else {
        setNotAnswered(false)
      }
    }
    //eslint-disable-next-line
  }, [data])

  useEffect(()=>{
    if (openFeedback) {
      setSelectedFeedback(openFeedback)
    }
  },[openFeedback])

  const shouldOpen = (activeFeedback && notAnswered && (!closedDialog) && ((!isChair) && isCoach !== true)) || (!!selectedFeedback)

  return (
    <>
      <Dialog onClose={handleClose}
              hideBackdrop
              open={shouldOpen}
              maxWidth={'sm'} fullWidth PaperProps={{className:classes.dialogPaper}}>
        <Feedback key={selectedFeedback ? selectedFeedback : activeFeedback}
                  feedbackCode={selectedFeedback ? selectedFeedback : activeFeedback}
                  handleClose={handleClose}/>
      </Dialog>
    </>
  );
});

export default withRouter(PopoutPoll);
