import {Redirect, useParams, withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import EventDescription from 'components/pages/Event/EventDescription'
import EventLocation from 'components/pages/Event/EventLocation'
import OrganizerList from 'components/pages/Event/OrganizerList'
import SponsorsLogosList from 'components/pages/Event/SponsorsLogoList'
import {Tickets} from 'components/pages/Event/Tickets'
import {Agenda} from 'components/pages/Event/Agenda'
import {ParticipantsList} from 'components/pages/Event/ParticipantsList'
import {SponsorsList} from 'components/pages/Event/SponsorsList'
import Extra from 'components/pages/Event/Extra'
import HotelsList from 'components/pages/Event/HotelsList'
import RestaurantsList from 'components/pages/Event/RestaurantsList'
import {Proceedings} from 'components/pages/Event/Proceedings'
import React from 'react'
import ShareEventButton from 'components/core/ShareEventButton'
import Button from 'components/core/Button'
import {KeynoteSpeakers} from 'components/pages/Event/KeynoteSpeakers'
import SocialFeedPage from 'components/pages/Event/SocialFeedPage'
import {Submissions} from 'components/pages/Event/Submissions'
import { ReviewSubmissions } from 'components/pages/Event/ReviewSubmissions'
import {CameraReady} from "components/pages/Event/CameraReady";
import {checkHost} from 'utils'
import GeneralPage from "components/pages/Event/GeneralPage";

const styles = theme => ({

})

const EventLink = withStyles(styles)(({classes, event}) => {
  if (!event.conferenceLink) return <> </>
  return <Box display='flex' p={4} pb={12} flexDirection='column'>
    <a href={`//${event.conferenceLink}`} target='_blank' rel='noopener noreferrer'><Button size='small'> Visit online
      conference link </Button></a>
  </Box>
})

const WithMenu = withRouter(withStyles(styles)(({classes, event, slug,previewSuffix, isPreview, canManage, pageId}) => {
  let {eventPage} = useParams()
  const isUsingHost = checkHost()

  if (!eventPage) {
    if (isUsingHost) {
      return <Redirect to={`/info${previewSuffix}`} canManage={canManage}/>
    } else if (slug) {
      return <Redirect to={`/${slug}/info${previewSuffix}`} canManage={canManage}/>
    } else {
      return <Redirect to={`/event/${event.eventInfo.id}/info${previewSuffix}`} canManage={canManage}/>
    }
  }

  switch (eventPage) {
    case 'info' :
      return <Box pl={2} pr={4} pb={4}>
        <EventDescription event={event} isPreview={isPreview}/>
        {!event.online ? <EventLocation event={event}/> : <EventLink event={event}/>}
        <OrganizerList event={event}/>
        <KeynoteSpeakers event={event} isPreview={isPreview} canManage={canManage}/>
        <SponsorsLogosList event={event}/>
        <ShareEventButton/>
      </Box>
    case 'tickets':
      return <>
        <Tickets event={event}/>
      </>
    case 'agenda':
      return <>
        <Agenda event={event} isPreview={isPreview} canManage={canManage} />
      </>
    case 'participants':
      return <>
        <ParticipantsList event={event} isPreview={isPreview} canManage={canManage}/>
      </>
    case 'keynotespeakers':
      return <>
        <KeynoteSpeakers event={event} isPreview={isPreview} canManage={canManage}/>
     </>
    case 'sponsors':
      return <>
        <SponsorsList event={event} isPreview={isPreview} canManage={canManage}/>
      </>
    case 'extra':
      return <>
        <Extra event={event} isPreview={isPreview} canManage={canManage} pageId={pageId}/>
      </>
    case 'hotels':
      return <>
        <HotelsList event={event} isPreview={isPreview} canManage={canManage}/>
      </>
    case 'restaurants':
      return <>
        <RestaurantsList event={event} isPreview={isPreview} canManage={canManage}/>
      </>
    case 'general-page':
      return <GeneralPage event={event} isPreview={isPreview} canManage={canManage} pageId={pageId}/>  
    case 'organizers':
      return <OrganizerList event={event}  isPreview={isPreview}/>     
    case 'proceedings':
      return <>
        <Proceedings event={event} isPreview={isPreview} canManage={canManage}/>
      </>
    case 'social-feed':
      return <SocialFeedPage eventId={event.eventInfo.id} disabled={false} />
    case 'submissions':
      return <Submissions event={event} />
    case 'review-submissions':
      return <ReviewSubmissions event={event} />
    case 'camera-ready':
      return <CameraReady event={event}/>
    default:
      if (isUsingHost) {
        return <Redirect to={`/info${previewSuffix}`} canManage={canManage}/>
      } else if (event.slug) {
        return <Redirect to={`/${slug}/info${previewSuffix}`} canManage={canManage}/>
      } else {
        return <Redirect to={`/event/${event.eventInfo.id}/info${previewSuffix}`} canManage={canManage}/>
      }
  }
}))

export default WithMenu
