import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {Field, Form, Formik} from 'formik'
import {Select, TextField} from 'formik-material-ui'
import MenuItem from '@material-ui/core/MenuItem'
import Button from 'components/core/Button'
import {CATEGORIES} from 'config'
import FormControl from '@material-ui/core/FormControl'
import {withRouter} from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

const styles = theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    backgroundColor: palette['cerulean-blue'],
    zIndex: 1000,
    [theme.breakpoints.down('sm')]: {
      position: 'relative'
    }
  },
  container: {
    padding: theme.spacing(2)
  },
  formControl: {
    minWidth: '200px',
    width: '100%'
  },
  text: {
    fontWeight: 'bold',
    fontSize: '18px',
    color: 'white',
    textAlign: 'center'
  }
})

const SearchToolbar = withStyles(styles)(({classes, value, setSearchQuery, setSearchCategory, category=' ', history}) => {
  return <Box className={classes.root} >
    <Box className={classes.searchSection}>
      <Formik
        initialValues={{query:value, category:category}}
        // validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          setSearchQuery(values.query)
          setSearchCategory(values.category)
          history.push(`?query=${values.query}${values.category?`&category=${values.category}`:''}`)
          setSubmitting(false)
        }}>
        {({ isSubmitting, errors, submitForm }) => (
          <Form className={classes.form}>
            <Container className={classes.container}>
            <Grid container spacing={1} justify='center' alignContent='center' alignItems='center'>
              <Grid item xs={12} md={2} className={classes.text}>
                I am looking for
              </Grid>
              <Grid item xs={12} md={3}>
                <Field type='text' name='query' component={TextField} label='Type event name' variant='filled' fullWidth disabled={isSubmitting}/>
              </Grid>
              <Grid item xs={12} md={1} className={classes.text}>
                that is
              </Grid>
              <Grid item xs={12} md={3} className={classes.text}>
                <FormControl className={classes.formControl}>
                  <Field type='select' labelId='categoryLabel' displayEmpty name='category' component={Select} label='Category' variant='filled' fullWidth disabled={isSubmitting} defaultValue=''>
                    {Object.keys(CATEGORIES).map((key, i)=>{
                      return <MenuItem value={key} key={i}>{CATEGORIES[key]}</MenuItem>
                    })}
                  </Field>
                  <InputLabel variant='filled'>Category</InputLabel>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} className={classes.text}>
                <Button color='secondary' size='small' type='submit'> Search </Button>
              </Grid>
            </Grid>
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  </Box>
})

export default withRouter(SearchToolbar)
