import {withStyles} from '@material-ui/core/styles'
import {useDropzone} from 'react-dropzone'
import Box from '@material-ui/core/Box'
import RootRef from '@material-ui/core/RootRef'
import Button from 'components/core/Button'
import {IconUploadBorderDark} from 'components/core/icons'
import React, {useEffect, useState} from 'react'
import {palette} from 'theme'
import {useHttp} from 'api/core'
import api from 'api'
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = theme => ({
  note: {
    fontSize: '12px'
  },
  dropBox: {
    width: '100%',
    borderRadius: '6px',
    border: 'dashed 3px #e6e6e6',
    color: palette['greyish-brown'],
    overflow: 'hidden',
    backgroundColor: 'white',
    '& a': {
      color: palette['greyish-brown']
    },
    outline: 'none',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(4)
  },
  formControl: {
    width: '100%'
  },
  progress: {
    display: 'flex',
    alignContent:'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'absolute',
    fontWeight: 'bold',
    fontSize: 18
  }
})

const UploadFile = withStyles(styles)(({contained, color='primary', classes, setPresentationFile, sessionId, handleClose, setIsLoading, roomId}) => {


  const [getData, data, isLoading, hasError,,,dataProgress] = useHttp()
  const [file, setFile] = useState()

  useEffect(()=>{
    if (data) {
      setPresentationFile(data)
      setIsLoading(false)
      handleClose(null,null,{
        filename: file.name
      })
    }
    // eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (hasError) {

    }
  //eslint-disable-next-line
  },[hasError])

  useEffect(()=>{
    setPresentationFile(undefined)
    // eslint-disable-next-line
  },[])

  const onDrop = (acceptedFiles) => {
    const fr = new FileReader()
    fr.readAsDataURL(acceptedFiles[0])
    setFile(acceptedFiles[0])
    let formDataObj = new FormData()
    formDataObj.append('presentation', acceptedFiles[0])
    getData(api.entities.virtualRoom.getPDFPagesFromFile(formDataObj, null, {id:roomId}, true))
    setIsLoading(true)
  }

  const {getRootProps, getInputProps} = useDropzone({onDrop});
  const {ref, ...rootProps} = getRootProps()

  return <Box display='flex' flexDirection='column' alignContent='center' className={classes.dropBox} mb={2}>
    <RootRef rootRef={ref}>
      <Box {...rootProps}>
        <input type='file' {...getInputProps()} accept='.pdf, .PDF'/>
        <Box display={'flex'} justifyContent='center' flexDirection='column' alignItems='center' alignContent='center' height='100%'>
          {isLoading&&<>
            <Box display='flex' position='relative'>
              {dataProgress!==100&&<div className={classes.progress}>{dataProgress}%</div>}
              <CircularProgress size={'4rem'} color='secondary' />
            </Box>
          {dataProgress===100&&<Box display='flex'>
            Processing file...
            </Box>}
          </>
          }

          {!isLoading&&<>
              <Box display={'flex'} mb={2}><IconUploadBorderDark/></Box>
              <Box display={'flex'}>Drag & Drop File</Box>
            <Box display={'flex'} mt={1} fontSize={11} color={palette['brown-grey']}>(Maximum file size: 20MB)</Box>
          </>}
        </Box>
      </Box>
    </RootRef>
    <Box display='flex' flexDirection='column' alignContent='center' mt={3} mb={1}>
      <RootRef rootRef={ref}>
        <Box display='flex' flexDirection='row' alignItems='center' alignContent='center' justifyContent='center'>
          <Box {...rootProps} display='flex' flexDirection='row' alignItems='center' alignContent='center' justifyContent='center'>
            <input name='file' type='file' {...getInputProps()} accept='.pdf, .PDF'/>
            <Button size='smaller' variant={contained?'contained':'outlined'} colour={color} fullWidth disabled={isLoading}>Select File</Button>
          </Box>
        </Box>
      </RootRef>
    </Box>
  </Box>
})

export default UploadFile
