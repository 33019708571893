import React, {useEffect, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {Form, Formik} from "formik";
import {Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CreateEventNavigation from "components/core/CreateEventNavigation";
import {withRouter} from "react-router-dom";
import {useHttp} from "api/core";
import api from "api";
import {TIME_FADE_IN} from "config";
import Fade from "@material-ui/core/Fade";
import PlanCardSelect from "components/core/PlanCardSelect";
import clsx from "clsx";
import List from "@material-ui/core/List";
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Check from '@material-ui/icons/Check';

const styles = (theme) => ({
  title: {
    color: "white",
    textAlign: "center",
    marginBottom: theme.spacing(4),
  },
  form: {
    width: "100%",
    color: "black",
  },
  container: {
    padding: theme.spacing(2),
  },
  planBox: {
    backgroundColor: "white",
    borderRadius: "6px",
    border: "solid 1px #e6e6e6",
    position: "relative",
    padding: theme.spacing(3),
    "& a": {
      color: theme.palette.primary.main,
    },
  },
  notAvailable: {
    opacity: 0.7,
  },
  button: {
    height: "100%",
    position: "relative",
  },
});

const CreateEventPlan = withStyles(styles)(({classes, formData, setFormData, setEvent, prevRoute, nextRoute, history}) => {
  // const [getData, data] = useHttp();
  // const [subscriptions, setSubscriptions] = useState([
  //   {
  //     id: null,
  //     name: "Free",
  //   },
  // ]);

  // useEffect(()=>{
  //   getData(api.entities.plans.getAvailableSubscriptions())
  //   // eslint-disable-next-line
  // },[])

  // useEffect(()=>{
  //   if (data) {
  //     setSubscriptions([{
  //       "id": null,
  //       "name": "Free",
  //     },...data])
  //   }
  // },[data])

  // const selectAction = (subscriptionId) => {
  //   setFormData({...formData, subscriptionId})
  // }

  // if (!subscriptions) {
  //   return <> </>
  // }
  const [getDefaultCredits, defaultCredits] = useHttp();
  const [eventFeatures,setEventFeatures] = useState({})

  useEffect(()=>{
    getDefaultCredits(api.entities.credits.getDefaultCredits());
    // eslint-disable-next-line
  },[])
  useEffect(() =>{
     if (defaultCredits) {
       const eventFeatures = {
         papersToShow: defaultCredits?.papersDefault,
         postersToShow: defaultCredits?.postersDefault,
         emailsToSend: defaultCredits?.emailsDefault,
         sponsors: defaultCredits?.sponsorsDefault,
         tokboxEnabled: defaultCredits?.tokboxDefault,
         balance: defaultCredits?.balanceDefault,
       };
       setEventFeatures(eventFeatures);
     }
}, [defaultCredits]);  



  useEffect(() => {
    setFormData({...formData, general: {...formData.general, ...eventFeatures}});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventFeatures]);

  return (
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Formik
        initialValues={formData}
        // validationSchema={Schema}
        onSubmit={(values, {setSubmitting}) => {
          history.push(nextRoute);
        }}>
        {({submitForm, errors}) => (
          <>
            <Box className={classes.hideMobile}>
              <CreateEventNavigation submit={true} submitForm={submitForm} errors={errors} prevRoute={prevRoute} nextRoute={nextRoute} />
            </Box>
            <Fade in={true} timeout={TIME_FADE_IN}>
              <Form className={classes.form}>
                <Box display="flex" flexDirection="row" justifyContent="center" alignContent="center" pt={4} minHeight={window.innerHeight - 280}>
                  <Box display="flex" width="600px" flexDirection="column" pb={8}>
                    <Typography component="h2" variant="h5" className={classes.title}>
                      For every event you get:
                    </Typography>

                    {/* <Grid container spacing={2} className={classes.terms}>
                <Grid item xs={12}>
                  {subscriptions.map((plan, i)=>{
                    return <PlanCardSelect plan={plan} select existing selectAction={selectAction} selected={formData.subscriptionId===plan.id}/>
                  })}
                </Grid>
              </Grid> */}

                    <Grid container spacing={2} className={classes.terms}>
                      <Grid item xs={12}>
                        <Grid container spacing={2} className={classes.container} justify="space-between">
                          <Grid item container className={clsx(classes.planBox)} justify="space-between" spacing={2}>
                            <Grid item>
                              <Box display="flex" flexDirection="column" alignContent="center" justifyContent="center">
                                <Box display="flex" fontSize="17px" fontWeight="bold" alignItems="center">
                                  <Box display="flex" style={{textTransform: "capitalize"}}>
                                    <List dense={false}>
                                      <ListItem>
                                        <ListItemIcon>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000"}}}
                                          primary="Zoom/Vimeo/Youtube etc streaming options for free"
                                        />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}} primary="Real-time low latency stream contact info@anymeets.com" />
                                      </ListItem>
                                      <ListItem>
                                        <ListItemIcon style={{height:"40px"}}>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000", marginLeft: "20px", textTransform: "initial"}}}
                                          primary={`Paper submission flows (40 submissions for free)`}
                                          secondary="- $3 per additional submission"
                                        />
                                      </ListItem>{" "}
                                      <ListItem>
                                        <ListItemIcon  style={{height:"40px"}}>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>                                        
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000", marginLeft: "20px", textTransform: "initial"}}}
                                          primary={`Posters (40 posters for free)`}
                                          secondary="- $8 per additional poster"
                                        />
                                      </ListItem>{" "}
                                      <ListItem>
                                        <ListItemIcon>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000", marginLeft: "20px", textTransform: "initial"}}}
                                          primary="Sponsors booth for $120 per sponsor per day"                     
                                        />
                                      </ListItem>{" "}
                                      <ListItem>
                                        <ListItemIcon  style={{height:"40px"}}>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000", marginLeft: "20px", textTransform: "initial"}}}
                                          primary={`Communicate with your audience. The First 500 emails are free.`}
                                          secondary="- $0.1 per additional email"
                                        />
                                      </ListItem>{" "}
                                      <ListItem>
                                        <ListItemIcon  style={{height:"40px"}}>
                                          <Check fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText
                                          primaryTypographyProps={{style: {color: "#000", fontSize: "16px", textTransform: "initial", fontWeight: "500"}}}
                                          secondaryTypographyProps={{style: {color: "#000", marginLeft: "20px", textTransform: "initial"}}}
                                          primary="Zero commission for free event registration"
                                          secondary="- $3 commission flat for every ticket (plus stripe charges)"
                                        />
                                      </ListItem>
                                    </List>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className={classes.showMobile}>
                  <CreateEventNavigation submit={true} submitForm={submitForm} prevRoute={prevRoute} nextRoute={nextRoute} />
                </Box>
              </Form>
            </Fade>
          </>
        )}
      </Formik>
    </Fade>
  );
});

export default withRouter(CreateEventPlan);
