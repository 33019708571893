import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import {imageFile} from 'utils'
import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {useHttp} from 'api/core'
import api from 'api'
import clsx from 'clsx'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Badge from '@material-ui/core/Badge'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PanToolIcon from '@material-ui/icons/PanTool'
import Button from '@material-ui/core/Button'
// import {useSnackbar} from 'notistack'

const styles = theme => ({
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  list: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    maxHeight: 240,
    overflow: 'hidden',
    border: '1px solid #e6e6e6',
    borderRadius: 4
  },
  fullHeight: {
    maxHeight: '50vh'
  },
  innerList: {
    width: '100%'
  },
  speakerName: {
    paddingRight: 32,
    '& span': {
      fontSize: 13
    }
  },
  raisedHand: {
    color: theme.palette.secondary.main,
    marginTop: 2,
    position: 'absolute',
    left: 38,
    background: 'white',
    borderRadius: '50%',
    padding: 3,
    bottom: -1,
      animationName: 'wobble',
      webkitAnimationName: 'wobble',
      webkitAnimationDuration:'1s',
      animationDuration: '1s',
      webkitAnimationFillMode: 'both',
      animationFillMode: 'both'
  }
})

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: -1,
      left: -1,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);

// function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const SpeakerSwitch = withStyles(styles)(({participant, participants, disabled, roomId, canRaiseHands})=>{

  // const {enqueueSnackbar} = useSnackbar()
  const [isSpeaker, setIsSpeaker] = useState(participant.canBroadcast)

  const [setSpeaker, , isLoadingSetSpeaker, hasErrorSetSpeaker] = useHttp()
  const [delSpeaker, , isLoadingDelSpeaker, hasErrorDelSpeaker] = useHttp()

  const toggleSpeaker = () => {

    if (!isSpeaker) {
      // if (participants.filter((p)=>p.canBroadcast).length>=8) {
      //   enqueueSnackbar('There can only be up to 8 speakers active at a time.', {variant: 'error'})
      //   return
      // }
      setIsSpeaker(true)
      setSpeaker(api.entities.virtualRoom.setSpeaker(null,null,{id:roomId,participantId:participant.id}))
    } else {
      setIsSpeaker(false)
      delSpeaker(api.entities.virtualRoom.delSpeaker(null,null,{id:roomId,participantId:participant.id}))
    }
  }

  useEffect(()=>{
    if (hasErrorSetSpeaker) {
      setIsSpeaker(false)
    }
  },[hasErrorSetSpeaker])

  useEffect(()=>{
    if (hasErrorDelSpeaker) {
      setIsSpeaker(true)
    }
  },[hasErrorDelSpeaker])

  return <>
    <Switch
      style={{margin:0}}
      checked={isSpeaker}
      color='secondary'
      size='small'
      onClick={toggleSpeaker}
      disabled={isLoadingSetSpeaker||isLoadingDelSpeaker||disabled}
    />
  </>
})

const SpeakerControl = withStyles(styles)(({classes, sessionState, streamers,publishing,setPublishing, raisedHands}) => {

  const {
    roomId,
    participants,
    participantId,
    canRaiseHands,
    isStarted,
    layoutMode,
    autoMode,
    audience,
    hasPolls
  } = sessionState

  const [participantsList, setParticipantsList] = useState(participants)
  const [putParticipantsList, , , hasErrorPut] = useHttp()
  const [setPublisher] = useHttp()

  useEffect(()=>{
    if (hasErrorPut) {
      setParticipantsList(participants)
    }
    //eslint-disable-next-line
  },[hasErrorPut])

  const onDragEnd = (result) => {
    // dropped outside the list

    if (!result.destination) {
      return
    }

    // From Live ParticipantsList to anywhere (REMOVE FROM LIVE SPEAKERS)
    if (result.destination.droppableId !== 'droppableParticipantsList' && (result.source.droppableId === 'droppableParticipantsList') ) {

      // If removed self , stop publishing
      if (participants[result.source.index] === participantId) {
        // setStreaming({...streaming, publishing: false})
        setPublishing(false)
      }

      const newItems = [...participantsList]
      newItems.splice(result.source.index,1)
      setParticipantsList(newItems)

      const payLoad = []
      newItems.forEach((i)=>payLoad.push(i.id))

      putParticipantsList(api.entities.virtualRoom.setParticipants(payLoad,null, {id: roomId}))
    }

    // To Live ParticipantsList (ADD/RE-ORDER LIVE SPEAKERS)
    if (result.destination.droppableId === 'droppableParticipantsList') {

      let newItems = [...participantsList]

      // From ParticipantsList
      if (result.source.droppableId === 'droppable') {

        // if (participantsList.length === 5) {
        //   return
        // }

        newItems = reorder(
          [...participantsList, parseInt(result.draggableId)],
          participantsList.length,
          result.destination.index
        )
      }

      // From Live ParticipantsList
      if (result.source.droppableId === 'droppableParticipantsList') {
        newItems = reorder(
          participantsList,
          result.source.index,
          result.destination.index,
        )
      }

      setParticipantsList(newItems)
      const payLoad = []
      newItems.forEach((i)=>payLoad.push(i.id))
      putParticipantsList(api.entities.virtualRoom.setParticipants(payLoad,null, {id: roomId}))
    }

  }

  useEffect(()=>{
    setParticipantsList(participants)
  },[participants])

  const handleHand = (participantId) => {
    if (!participants.find((p)=>p.id===participantId)) {
      setPublisher(api.entities.virtualRoom.setPublisher(null,null,{id:roomId,userId:participantId}))
    }
  }

  if (!participantsList) return <> </>

  return <Grid item container spacing={2}>

    <Grid item container justify='space-between' alignItems='center'>
      <Grid item>
        <Typography variant='h3'>Participants List</Typography>
      </Grid>
      <Grid item>
        <Box fontSize={12} fontWeight={'bold'}>Broadcasting</Box>
      </Grid>
    </Grid>

    <Grid item xs={12}>
      <Box className={clsx(classes.list,!hasPolls&&classes.fullHeight)}>

        <DragDropContext onDragEnd={onDragEnd}>

          <Droppable droppableId='droppableParticipantsList'>
            {(provided, snapshot) => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={classes.innerList}
                dense
              >
                <PerfectScrollbar>
                  {participantsList.map((participant, i) => {
                    // const speaker = session.status.participantsList.find((p)=>p.id===speaker)
                    const isStreaming = (streamers&&streamers.findIndex((s)=>parseInt(s.name)===participant.id)!==-1) || (participant.id===participantId && publishing && isStarted)
                    return (<React.Fragment key={i} >
                        <Draggable isDragDisabled={autoMode} key={participant.id} draggableId={`${participant.id}`} index={i}>
                          {(provided, snapshot) => (<Box ref={provided.innerRef}
                                                         {...provided.draggableProps}
                            >
                              <ListItem key={i} className={clsx(classes.speaker, i === 0 && layoutMode===3 && classes.presenter, snapshot.isDragging&&classes.dragging)} {...provided.dragHandleProps} >
                                {/*<ListItemIcon {...provided.dragHandleProps} classes={{root:classes.listItemDragIconRoot}}>*/}
                                {/*  <DragIndicatorIcon/>*/}
                                {/*</ListItemIcon>*/}
                                <ListItemAvatar className={classes.avatar}>
                                  {isStreaming?<StyledBadge
                                    overlap="circle"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    variant="dot"
                                  >
                                    <Avatar alt={`${participant.lastName} ${participant.firstName}`}
                                            src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />
                                  </StyledBadge>:<Avatar alt={`${participant.lastName} ${participant.firstName}`}
                                                         src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />}
                                </ListItemAvatar>
                                <ListItemText className={classes.speakerName} id="switch-list-label"
                                              primary={<>
                                              {(raisedHands.find((p)=>p===participant.id)||participant.promoted)&&<PanToolIcon fontSize='small' className={classes.raisedHand} />}
                                                    {`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}
                                              </>}/>
                                {/*{isStreaming&&<Box position='absolute' top={11} right={10} width={12} height={12} display='flex' alignContent='center' justifyContent='center' alignItems='center'>*/}
                                {/*  <IconLive />*/}
                                {/*</Box>}*/}
                                <ListItemSecondaryAction>
                                  <SpeakerSwitch canRaiseHands={canRaiseHands} participants={participantsList} participant={participant} disabled={autoMode&&(!participant.promoted)} roomId={roomId} />
                                </ListItemSecondaryAction>
                              </ListItem>
                            </Box>
                          )}
                        </Draggable>

                      </React.Fragment>
                    )})}
                  {provided.placeholder}
                </PerfectScrollbar>

              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

    </Grid>
    {canRaiseHands&&<Grid item xs={12}>
      <Box className={classes.list}>
              <List
                className={classes.innerList}
                dense
              >
                <PerfectScrollbar>
                  {audience.filter((a)=>a.raisedHand).length===0&&<>
                  <ListItem className={clsx(classes.speaker)} >
                    <ListItemText style={{textAlign:'center'}}>
                      No hands raised
                    </ListItemText>
                  </ListItem>
                  </>}
                  {audience.filter((a)=>a.raisedHand).map((participant, i) => {
                    // const speaker = session.status.participantsList.find((p)=>p.id===speaker)
                    const isStreaming = (streamers&&streamers.findIndex((s)=>parseInt(s.name)===participant.id)!==-1) || (participant.id===participantId && publishing && isStarted)
                    participant.image = participant.imageId
                      return (<Box key={i}>
                              <ListItem key={i} className={clsx(classes.speaker, i === 0 && layoutMode===3 && classes.presenter)} >
                                {/*<ListItemIcon {...provided.dragHandleProps} classes={{root:classes.listItemDragIconRoot}}>*/}
                                {/*  <DragIndicatorIcon/>*/}
                                {/*</ListItemIcon>*/}
                                <ListItemAvatar className={classes.avatar}>
                                  {isStreaming?<StyledBadge
                                    overlap="circle"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    variant="dot"
                                  >
                                    <Avatar alt={`${participant.lastName} ${participant.firstName}`}
                                            src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />
                                  </StyledBadge>:<Avatar alt={`${participant.lastName} ${participant.firstName}`}
                                                         src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />}
                                </ListItemAvatar>
                                <ListItemText className={classes.speakerName} id="switch-list-label"
                                              primary={<>
                                                <PanToolIcon fontSize='small' className={classes.raisedHand} />
                                                {`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}
                                              </>}/>
                                {/*{isStreaming&&<Box position='absolute' top={11} right={10} width={12} height={12} display='flex' alignContent='center' justifyContent='center' alignItems='center'>*/}
                                {/*  <IconLive />*/}
                                {/*</Box>}*/}
                                <ListItemSecondaryAction>
                                  <Button variant='outlined' size='small' onClick={()=>handleHand(participant.id)}> {participants.find((p)=>p.id===participant.id)?`Disallow`:`Allow`} </Button>
                                </ListItemSecondaryAction>
                              </ListItem>
                            </Box>)
                  }
                    )}
                </PerfectScrollbar>

              </List>
      </Box>

    </Grid>}
  </Grid>
})

export default SpeakerControl
