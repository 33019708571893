import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette, theme} from 'theme'
import {ThemeProvider} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Box from '@material-ui/core/Box'
import Button from 'components/core/Button'
import {Field, Form, Formik} from 'formik'
import {TextField} from 'formik-material-ui'
import * as Yup from 'yup'
import {SIZE_LENGTH_PARTICIPANT_SUMMARY} from 'config'
import { SketchPicker } from 'react-color';
import api from 'api'
import {useHttp} from 'api/core'


const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  newTrack: {
    // maxWidth: '500px'
  },
  row: {
    marginBottom: theme.spacing(4)
  }
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required('This field is required')
})

const TrackEditNew = withStyles(styles)(({classes, track, handleClose, eventId, setSuccessful, ...props}) => {
  const [formData, ] = useState({...track})
  const [formSave, setFormSave] = useState()
  const [sendRequest, putData, isLoading, hasError] = useHttp()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    setIsSubmitting(isLoading && !hasError)
  }, [isLoading, hasError])

  useEffect(() => {
    if (putData) {
      handleClose(true)
      setSuccessful(true)
    }
// eslint-disable-next-line
  }, [putData])

  useEffect(() => {
    if (formSave) {
      const {...formDataItems} = formSave

      const payLoad = {
        ...formDataItems,
        eventId: eventId,
        publish:false,
        title: formDataItems.title === '-' ? null : formDataItems.title
      }
      const dataObj = new Blob([JSON.stringify(payLoad)], { type: 'application/json'});
      // If existing track
      if (track) {
        sendRequest(api.entities.tracks.update(dataObj, null, {id: track.id}, true))
      } else { // else add new track
        sendRequest(api.entities.tracks.addNew(dataObj, null, null, true))
      }

      setFormSave(undefined)
    }
  }, [eventId, formSave, sendRequest, track])


  return <Box className={classes.newTrack}><ThemeProvider theme={theme}>
    <Formik
      initialValues={{
        name: '',
        description: '',
        color: '#FFFFFF',
        ...formData
      }}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        const newValues = {...formData, ...values}
        setFormSave(newValues)
      }}>
      {({errors, submitForm, setFieldValue, values}) => (
        <Form className={classes.form}>
          <Box display='flex' flexDirection='row'>

            <Box className={classes.container}>
              <Grid container spacing={1} className={classes.row}>
                <Typography variant='h5' component='h1'> {track ? 'Track Information' : 'New Track'}</Typography>
              </Grid>

              <Grid container spacing={2} className={classes.row}>
                  <Grid item container spacing={1}>
                    <Grid item xs={8}>
                      <Box mr={1}>
                      <Field type='text' name='name' component={TextField} label='Name' variant='filled'
                             fullWidth disabled={isSubmitting}/>
                      </Box>
                      <Box mt={1} mr={1}>
                      <Field type='text' name='description' component={TextField} id='Description' label='Details'
                             variant='filled'
                             fullWidth multiline maxLength={SIZE_LENGTH_PARTICIPANT_SUMMARY}
                             rows={4} disabled={isSubmitting}/>

                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <SketchPicker color={values.color} onChange={(color)=>{setFieldValue('color',color.hex)}} name='color' id='Color' disabled={isSubmitting}/>
                    </Grid>
                  </Grid>

                <Grid container justify='center' alignContent='center' alignItems='center'>
                  <Grid container item md={8} spacing={3} className={classes.btns}>
                    <Grid item xs={'auto'} md={6}>
                      <Button variant='outlined' size='small' colour='white' onClick={handleClose}
                              disabled={isSubmitting}> Cancel </Button>
                    </Grid>
                    <Grid item xs={'auto'} md={6}>
                      <Button size='small' type='submit' disabled={isSubmitting}> Save Changes </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  </ThemeProvider>
  </Box>
})

export default TrackEditNew
