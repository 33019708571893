import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {IconFileBorderDark, IconMapPinBorderGray} from 'components/core/icons'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import clsx from 'clsx'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import {imageFile, truncateFilename} from 'utils'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import ParticipantToolTipView from 'components/core/ParticipantToolTipView'
import PresentationsBlock from 'components/core/OpenTok/Tabs/Info/PresentationsBlock'

const styles = theme => ({
  descriptionBox: {
    width: '100%',
    minHeight: 200,
    fontSize: 14,
    // maxHeight: 150,
    // overflowY: 'scroll',
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    msHyphens: 'auto',
    mozHyphens: 'auto',
    webkitHyphens: 'auto',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap'
  },
  list: {
    display: 'flex',
    width: '100%',
    backgroundColor: 'white',
    // maxHeight: 300,
    overflow: 'hidden',
    border: '1px solid #e6e6e6',
    borderRadius: 4
  },
  listInner: {
    width: '100%'
  },
  chip: {
    backgroundColor: '#eee',
    border: 'none'
  },
  avatar: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#eee'
  },
  root: {
    fontSize: 14,
    backgroundColor: 'white',
    border: '1px solid #e6e6e6',
    borderRadius: 4,
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5)
  },
  treeItem: {
    backgroundColor: 'white',
  },
  label: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'white'
    },
    '&:focus': {
      backgroundColor: 'white'
    }
  },
  tooltip: {
    backgroundColor: 'transparent',
    padding: 0,
    maxWidth: 240,
    border: '1px solid #fff',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)'
  }

})


const Info = withStyles(styles)(({classes, sessionState}) => {

  const {
    participants,
    files,
    description,
    physicalRoom,
  } = sessionState

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(null)

  const handleClick = (event,participant) => {
    setAnchorEl(event.currentTarget)
    setOpen(participant)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(()=>{
    if (!anchorEl) {
      setTimeout(()=>setOpen(false),200)
    }
  },[anchorEl])

  // const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined


  return <Grid item container justify='center' alignContent='center' alignItems='center' spacing={2}>

    {description &&<Grid item xs={12}>
      <Typography variant='h3'>Description</Typography>
    </Grid>}

    {description&&<Grid item xs={12}>
      <Grid item container spacing={1}>
        <Grid item  className={classes.descriptionBox}>
          {description}
        </Grid>
      </Grid>
    </Grid>}

      {/*{description && <Grid item xs={12}>*/}
      {/*  <Box fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']}>Description</Box>*/}
      {/*  <Box>{description}</Box>*/}
      {/*</Grid>}*/}

      {physicalRoom && <Grid item xs={12}>
        <Box pb={2} fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']}> Room</Box>
        <Box display='flex' alignContent='center' alignItems='center'>
          <Box display='flex' pr={1}>
            <IconMapPinBorderGray/>
          </Box>
          <Box display='flex'>
            {physicalRoom.name}
          </Box>
        </Box>
      </Grid>}


    {participants && participants.length !== 0 && <Grid item xs={12}>
      <Typography variant='h3'>Speakers List</Typography>
    </Grid> }

    {participants && participants.length !== 0 && <Grid item xs={12}>
      <Box width={'100%'} className={classes.list}>
      <List
        className={classes.listInner} dense
      >
          {participants.filter((a)=>a.visible).sort().filter((s)=>!s.promoted).map((participant, i) => {
            return<ListItem key={i} className={clsx(classes.speaker)} button onClick={(e)=>handleClick(e,participant)}>
              <ListItemAvatar>
                <Avatar alt={`${participant.lastName} ${participant.firstName}`}
                        src={participant.image?`${imageFile(participant.image)}`:null} className={classes.smallAvatar} />
              </ListItemAvatar>
              <ListItemText id="switch-list-label"
                            primary={`${participant.title ? participant.title : ''} ${participant.firstName} ${participant.lastName}`}/>
            </ListItem>})}
      </List>
        <Popover
          id={id}
          open={!!open&&anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
        >
          <Box className={classes.tooltip}>
            <ParticipantToolTipView participant={open} />
          </Box>
        </Popover>
    </Box>
    </Grid>}

      {/*{participants && participants.length !== 0 && <Grid item xs={12}>*/}
      {/*  <Box pb={1} fontSize={16} fontWeight={'bold'} color={palette['greyish-brown']}>Speakers</Box>*/}
      {/*  <Box className={classes.participants}>*/}
      {/*    <ScrollableContainer items={*/}
      {/*      participants.map((participant, i) => {*/}
      {/*        return <SpeakerCard participant={participant} key={i}*/}
      {/*                            streaming={streamers.findIndex((s) => parseInt(s.name) === participant.id) !== -1}/>*/}
      {/*      })}/>*/}
      {/*  </Box>*/}
      {/*</Grid>}*/}

      {files && files.length !== 0 &&  <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h3'>Files</Typography>
        </Grid>
        <Grid item container spacing={1}>
          {files.map((file,i) => {
            return <Grid item xs={12} key={i}>
              <Chip
                avatar={<Avatar className={classes.avatar}> <IconFileBorderDark/> </Avatar>}
                variant="outlined"
                label={`${truncateFilename(file.filename,40)}`}
                component='a' href={`/files/${file.id}`} clickable download={`${file.filename}`}
                className={classes.chip}
              />
            </Grid>
          })}
        </Grid>
      </Grid>}

      {/*{(session.url || session.recordingUrl) && <Grid item xs={12}>*/}
      {/*  <Box pb={1} fontSize={18}*/}
      {/*       fontWeight={'bold'}>{session.recordingUrl ? 'Recording Link' : 'Online Link'}</Box>*/}
      {/*  <Box>{<a*/}
      {/*    href={session.recordingUrl ? session.recordingUrl : session.url}> {session.recordingUrl ? session.recordingUrl : session.url} </a>}</Box>*/}
      {/*</Grid>}*/}

      <PresentationsBlock sessionState={sessionState} classes={classes}/>
  </Grid>
})

export default Info
