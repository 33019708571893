import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import { TIME_FADE_IN } from 'config'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';

const extraStyles = theme => ({
  editButton: {
    float: "right",
		width: 155,
		opacity: 0.6,
		borderRadius: "20.5px",
		backgroundColor: "#0dc7e5",
		color: "#fff",
		padding: theme.spacing(1),
		fontWeight: "bold",
		fontSize: "14px",
		"&:hover": {
      opacity: 1,
      backgroundColor: "#0dc7e5",
		},
	}, 
})

export const Extra = withStyles(extraStyles)(({classes, event, isPreview, canManage, pageId}) => {
  const [extra, setExtra] = useState()
  const [getExtraData, extraData] = useHttp()
  const history = useHistory();

  useEffect(() => {
    if (isPreview) {
      getExtraData(api.entities.preview.getExtra(null, null, {id: pageId}))
    } else {
      getExtraData(api.entities.events.getExtra(null, null, {id: pageId}))
    }

    // eslint-disable-next-line
  }, [pageId])

  useEffect(() => {
    if (extraData) {
      setExtra(extraData)
    }
  }, [extraData])

  if (!extra) {
    return <> </> // Loading
  }

  if (extra&&extra.length===0) return <> </>

  const createMarkup = () => {
    return {__html: extra.content}
  }

  return <>
    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pt={0} pb={12} flexDirection='column'>
      <Grid item xs={12} style={{marginBottom: "8px", paddingRight: "6px"}}>
          {isPreview && <Button size='smaller' className={classes.editButton} color="primary" onClick={() => { history.push(`/manage-event/${ event.eventInfo.id }/extras`) }}>
          <EditIcon style={{fontSize: "17px", marginRight: "5px"}}/>
            Edit Extras</Button>}
            </Grid>         
        <Box fontSize={20} fontWeight={'bold'} pb={3}>
          {extra.title}
        </Box>
        <Box>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </Box>
      </Box>
    </Fade>
  </>
})

export default Extra
