import React from 'react'
import {withStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import {Typography, Grid} from '@material-ui/core'
import Container from '@material-ui/core/Container'
import Page from 'components/core/Page'
import Hero from 'components/core/Hero'
import SubFooter from 'components/core/Footer/SubFooter'
import Button from 'components/core/Button'
import Header from "components/core/Header/Header";
import HomeSplashScreen from "assets/home.png";
import Footer from "components/core/Footer/Footer";
import ScrollAnimation from "react-animate-on-scroll";
import SubmissionForm from 'assets/ui-screens/ui-submission.png'
import ReviewerList from 'assets/ui-screens/ui-reviewers.png'
import ReviewerAssignment from 'assets/ui-screens/ui-reviewer-assignment.png'

const styles = theme => ({
  root: {
    width: theme.spacing(128),
  },
  SubmissionsImage: {
    width: '950px',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  manageImage: {
    width: '483px',
    boxShadow: '0 10px 35px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down('md')]: {
      width: '400px'
    }
  },
  sectionText: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '18px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },
  sectionTitle: {
    fontFamily: "'Roboto','Karla', sans-serif;",
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.67',
    letterSpacing: '0.15px',
    color: '#666666'
  },
  SubmissionsTitle: {
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '40px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(4),
    marginTop: 64
  },
  SubmissionsContent: {
    color: '#fff',
    fontWeight: 'normal',
    fontSize: '24px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    marginBottom: theme.spacing(3)
  },
  featuresList: {
    color: '#666666',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    fontFamily: "'Roboto','Karla', sans-serif;",
    minHeight: 500
  },
  contactTitle:{
    color:'#fff',
    fontSize: '36px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.11',
    letterSpacing:'-0.5px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  contactMoto:{
    color:'#fff',
    fontSize: '24px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25',
    letterSpacing:'0.2px',
    fontFamily: "'Roboto','Karla', sans-serif;"
  },
  featureGroup: {
    fontSize: 24
  }

})

const Submissions = withStyles(styles)(({classes}) => {

  return <div style={{marginTop: -64}}>
    <Header animate={true} />
    <Page title='Submissions and reviews' noFooter>
      <Hero image={HomeSplashScreen} minHeight={300} >
        <Grid container direction={'column'} justify={'center'} alignContent={'center'} alignItems={'center'}>
          <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={100} offset={0}>
            <Grid item lg={4}>
              <Typography align={'center'} className={classes.SubmissionsTitle}>Submissions</Typography>
            </Grid>
          </ScrollAnimation>
          <Grid item lg={7}>
            <Box p={2}>
              <ScrollAnimation animateIn="fadeInDown" initiallyVisible={false} animateOnce={true} delay={200}>
                <Typography align={'center'} className={classes.SubmissionsContent}>
                  Abstracts, Full papers, Posters and Applications
                </Typography>
              </ScrollAnimation>
            </Box>
          </Grid>
        </Grid>
      </Hero>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
        <Hero backgroundColor={'#ffffff'} minHeight={300}>
          <Box pt={8} pb={6}>
            <Container>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                    spacing={2}>

                <Grid item lg={6}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant={'body1'} className={classes.sectionText}>
                        Take care of all your submission needs through our fully customizable submission forms! You can create multiple submission forms and set different deadlines for each.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6}>
                  <img src={SubmissionForm} alt='Submissions' className={classes.manageImage}/>
                </Grid>

              </Grid>
            </Container>
          </Box>
        </Hero>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={800}>
        <Box style={{backgroundColor: '#f8f8f8'}} pt={2} pb={2}>
          <Container>
            <Box pt={2} pb={2}>
              <Typography className={classes.sectionTitle} align={'center'}>
                Reviews and reviewers
              </Typography>
            </Box>
          </Container>
        </Box>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={400}>
        <Hero backgroundColor={'#ffffff'} minHeight={300}>
          <Box pt={8} pb={6}>
            <Container>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                    spacing={2}>

                <Grid item lg={6}>
                  <img src={ReviewerList} alt='Reviewers Listing' className={classes.manageImage}/>
                </Grid>

                <Grid item lg={6}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant={'body1'} className={classes.sectionText}>
                        Add and manage reviewers, send reminders with one click and track the status of the reviewing process with our “at a glance” dashboard.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

              </Grid>
            </Container>
          </Box>
        </Hero>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
        <Box style={{backgroundColor: '#f8f8f8'}} pt={2} pb={2}>
          <Container>
            <Box pt={2} pb={2}>
              <Typography className={classes.sectionTitle} align={'center'}>
                Grading and decision
              </Typography>
            </Box>
          </Container>
        </Box>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={400}>
        <Hero backgroundColor={'#ffffff'} minHeight={300}>
          <Box pt={8} pb={6}>
            <Container>
              <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}
                    spacing={2}>


                <Grid item lg={6}>
                  <Box p={2}>
                    <Box mb={2}>
                      <Typography variant={'body1'} className={classes.sectionText}>
                        Set up grading forms to allow your reviewers to evaluate submissions and make comments. You can use single- or double-blind reviewing. Once all the grades are in, you can accept, reject or waitlist submissions. We provide email templates for decisions, which you can use as they are or alter to meet your specific needs.
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item lg={6}>
                  <img src={ReviewerAssignment} alt='Reviewers Assignment Screen' className={classes.manageImage}/>
                </Grid>

              </Grid>
            </Container>
          </Box>
        </Hero>
      </ScrollAnimation>
      {/*<Hero backgroundColor={'#ffffff'} minHeight={300}>*/}
      {/*  <Box pt={6}>*/}
      {/*    <Container>*/}
      {/*      <Grid container direction={'row'} justify={'center'} alignContent={'center'} alignItems={'center'}*/}
      {/*            spacing={2}>*/}
      {/*        <Grid item lg={6}>*/}
      {/*          <img src={ManageLayout} alt='ManageLayout' className={classes.manageImage}/>*/}
      {/*        </Grid>*/}
      {/*        <Grid item lg={6}>*/}
      {/*          <Box p={2}>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                Anymeets is a state-of-the-art event management software for any professional event. It supports*/}
      {/*                small*/}
      {/*                workshops, medium-sized events and large-scale conferences with thousands of participants.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                With anymeets you can keep track of your participants, share announcements and documents, collect*/}
      {/*                registration fees, create an event program and much more.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*            <Box mb={2}>*/}
      {/*              <Typography variant={'body1'} className={classes.sectionText}>*/}
      {/*                Anymeets can also be utilized to conduct real-time surveys and polls to engage your audience and*/}
      {/*                to*/}
      {/*                provide you participant feedback to enhance future events.*/}
      {/*              </Typography>*/}
      {/*            </Box>*/}
      {/*          </Box>*/}
      {/*        </Grid>*/}
      {/*      </Grid>*/}
      {/*    </Container>*/}
      {/*  </Box>*/}
      {/*</Hero>*/}

      <Box style={{backgroundColor: '#f7f8fa'}} p={8}>
        <Grid container direction={'row'} justify={'center'} alignItems={'center'} alignContent={'center'}>
          <Grid item lg={6}>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
              <Typography className={classes.sectionText} align={'center'}>
                Anymeets comes bundled with a mobile application with sharing and social features, which enables
                participants
                to check the progress of their submissions and gain instant access to the event program, encourages direct
                interaction between participants and allows organizers to send announcements and notifications.
              </Typography>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Box>

      <Hero backgroundColor={'#107AAD'} minHeight={400}>
        <Grid container direction={'column'} justify={'center'} alignItems={'center'} spacing={5}>
          <Grid item lg={12}>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={100}>
              <Typography align={'center'} className={classes.contactTitle}>Interested in learning more?</Typography>
            </ScrollAnimation>
          </Grid>
          <Grid item lg={8}>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={300}>
              <Typography align={'center'} className={classes.contactMoto}>Please contact us today to arrange a demo</Typography>
            </ScrollAnimation>
          </Grid>
          <Grid item>
            <ScrollAnimation animateIn="fadeIn" initiallyVisible={false}  animateOnce={true} delay={600}>
              <Button href={'/contact'} variant={'contained'} fullWidth color={'secondary'}>Let's talk</Button>
            </ScrollAnimation>
          </Grid>
        </Grid>
      </Hero>
      <SubFooter/>
      <Footer />
    </Page>
  </div>
})

export default Submissions
