import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import {imageFile} from 'utils'
import clsx from 'clsx'

const styles = theme => ({
  container: {
    borderRadius: '6px',
    boxShadow: '0px 0px 2px #cdcdcd',
    // border: 'solid 1px #e6e6e6',
    color: palette['greyish-brown'],
    // backgroundColor: '#f6f6f6',
    overflow: 'hidden',
    // backgroundColor: 'white',
    width: '250px',
    minHeight: '150px',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    position: 'relative',
    '&:hover': {
      backgroundColor: '#e6e6e6'
    },
    transition: 'background-color 0.5s ease'
  },
  userPhoto: {
    display: 'flex',
    width: '84px',
    height: '84px',
    // borderRadius: '50%',
    // backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    '& > img' : {
      width: '100%',
      height: 'auto',
      // backgroundColor: palette.imageBackgroundColor
    },[theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  onlineBooth: {
    position: 'absolute',
    top: '4px',
    right: '-12px',
    fontWeight: 'bold',
    color: palette['cerulean-blue']
  },
  ribbon: {
    '& span': {
      width: 130,
      background: palette['cerulean-blue']
    }
  }
})

const SponsorBox = withStyles(styles)(({classes, sponsor}) => {

  return <Grid item className={classes.container}>

    <Grid item container justify='center' spacing={2} alignContent='center' alignItems='center' direction='column'>

      {sponsor.online&&<Box className={classes.onlineBooth}>
        <div className={clsx('ribbon',classes.ribbon)}><span>Online Booth</span></div>
      </Box>}

      <Grid item>
        <Box className={classes.userPhoto}><img src={imageFile(sponsor.logoId)} alt={`${sponsor.name}`} /></Box>
      </Grid>
      <Grid item>
        <Box display='flex' fontSize={17} fontWeight='bold' p={1} textAlign='center'>
          {`${sponsor.name}`}
        </Box>
      </Grid>
      </Grid>
    
  </Grid>
})

export default SponsorBox
