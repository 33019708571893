import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { palette } from "theme";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "components/core/Button";
import { Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-material-ui";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import * as Yup from "yup";
import { CONTENT_LIST } from "config";
import { useSnackbar } from "notistack";
import RootRef from "@material-ui/core/RootRef";
import api from "api";
import { useHttp } from "api/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from "@material-ui/core/InputLabel";
import { FormHelperText } from "@material-ui/core";
import RichTextEditor from "react-rte";
import Organizers from "components/pages/ManageEvent/Organizers";
import RestaurantsAndHotels from "components/pages/ManageEvent/RestaurantsAndHotels";
import Sponsors from "components/pages/ManageEvent/Sponsors";

import Fade from "@material-ui/core/Fade";

import { TIME_FADE_IN } from "config";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "40px 50px 50px 50px",
    backgroundColor: palette["cerulean-blue"],
    color: "white",
    borderRadius: "6px",
    maxWidth: "1024px",
    minWidth: "768px",
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
  newContent: {
    //width: "1024px",
    maxWidth: "1200px",
  },
  row: {
    marginBottom: theme.spacing(4),
  },
  btns: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  editor: {
    color: palette["greyish-brown"],
    borderRadius: "6px",
    minHeight: "300px",
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
});

const validationSchema = Yup.object().shape({
  	label: Yup.string().required("This field is required").test('len', 'Must be up to 25 characters', val => val.length <= 25),
	contentEnumType: Yup.string().required("This field is required"),
	// content: Yup.string().nullable().test('len', 'Must be up to 10.000 characters', val => val.toString().length <= 10000)
});

const ContentEditNew = withStyles(styles)(
  ({
    classes,
    handleClose,
    contentItem,
    contentTypeList,
    eventId,
    setSuccessful,
    setPageTitle,
    setEventInfo,
    setNavBack,
    eventInfo,
    ...props
  }) => {
    const [formData, setFormData] = useState({ ...contentItem });
    const [formSave, setFormSave] = useState();
    const [sendRequest, putData, isLoading, hasError] = useHttp();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [editorValue, setEditorValue] = useState({
      value: RichTextEditor.createValueFromString(
        contentItem ? contentItem.content : "",
        "html"
      ),
    });

    useEffect(() => {
      setIsSubmitting(isLoading && !hasError);
    }, [isLoading, hasError]);

    useEffect(() => {
      if (putData) {
        handleClose(true);
        setSuccessful(true);
      }
      // eslint-disable-next-line
    }, [putData]);

    useEffect(() => {
      if (formSave) {
        // let formDataObj = new FormData();
        const { id, content, ...formDataItems } = formSave;

        let payLoad = { ...formDataItems, content, eventId };

        const formDataObj = new Blob([JSON.stringify(payLoad)], {
          type: "application/json",
        });
        // formDataObj.append('content', blob)

        //If existing content
        if (contentItem) {
          sendRequest(
            api.entities.manage.updateContent(
              formDataObj,
              null,
              { eventId: eventId, id: contentItem.id },
              true
            )
          );
        } else {
          // else add new content
          sendRequest(
            api.entities.manage.addContent(
              formDataObj,
              null,
              { eventId: eventId },
              true
            )
          );
        }

        setFormSave(undefined);
      }
      //eslint-disable-next-line
    }, [eventId, formSave, sendRequest]);

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN",
        "HISTORY_BUTTONS",
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" },
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" },
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" },
      ],
    };

    const onEditorChange = (value, setFieldValue) => {
      setEditorValue({ value });
      setFieldValue("content", value.toString("html"));
    };

    return (
      <Fade in={true} timeout={TIME_FADE_IN}>
        <Box className={classes.newContent}>
          <Formik
            initialValues={{
              label: formData.label ? formData.label : "",
              contentEnumType: formData.contentEnumType
                ? formData.contentEnumType
                : "",
              content: formData.content ? formData.content : " ",
              id: formData.id ? formData.id : "",
              ...formData,
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              const isUnique = contentTypeList.find(
                (content) =>
                  content === values.contentEnumType &&
                  values.contentEnumType !== 10
              );
              if (!isUnique || contentItem) {
                const newValues = { ...formData, ...values };
                setFormSave(newValues);
              } else {
                enqueueSnackbar("This content type has already been used", {
                  variant: "error",
                });
              }
            }}
          >
            {({ errors, submitForm, setFieldValue, values, touched }) => (
              <Form className={classes.form}>
                <Grid container className={classes.container}>
                  <Grid container spacing={1} className={classes.row}>
                    <Typography variant="h5" component="h1">
                      {" "}
                      {contentItem ? "Edit section" : "New section"}
                    </Typography>
                  </Grid>

                  <Grid container spacing={1}>
                    <Grid item container xs={12}>
                      <Grid container spacing={1} className={classes.row}>
                        <Grid item xs={12} sm={12}>
                          <Field
                            type="text"
                            name="label"
                            required
                            component={TextField}
                            label="Label"
                            variant="filled"
                            fullWidth
                            disabled={isSubmitting}
                          />
                        </Grid>
                        {!contentItem && (
                          <Grid item container spacing={2} justify={"center"}>
                            <Grid item xs={12} sm={12}>
                              <FormControl className={classes.formControl}>
                                <Field
                                  type="select"
                                  labelId="type"
                                  name="contentEnumType"
                                  component={Select}
                                  label="Section Type"
                                  variant="filled"
                                  fullWidth
                                  disabled={isSubmitting}
                                  defaultValue={false}
                                >
                                  {CONTENT_LIST.map((item, index) => {
                                    return (
                                      <MenuItem value={item.value} key={index}>
                                        {item.label}
                                      </MenuItem>
                                    );
                                  })}
                                </Field>
                                <InputLabel variant="filled">
                                  Section Type
                                </InputLabel>
                                {errors &&
                                  errors.contentEnumType &&
                                  touched.contentEnumType && (
                                    <FormHelperText error variant="filled">
                                      {" "}
                                      {errors.contentEnumType}{" "}
                                    </FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                        {contentItem && contentItem.contentEnumType === 10 && (
                          <Grid item container spacing={2} justify={"center"}>
                            <Grid item xs={12}>
                              <RichTextEditor
                                className={classes.editor}
                                value={editorValue.value}
                                toolbarConfig={toolbarConfig}
                                onChange={(v) =>
                                  onEditorChange(v, setFieldValue)
                                }
                              />
                            </Grid>
                          </Grid>
                        )}
                        {contentItem && contentItem.contentEnumType === 6 && (
                          <Grid item spacing={2} p={2} xs={12} sm={12}>
                            <RestaurantsAndHotels
                              setPageTitle={setPageTitle}
                              setEventInfo={setEventInfo}
                              setNavBack={setNavBack}
                              eventInfo={eventInfo}
                              content={true}
                              contentType={6}
                            />
                          </Grid>
                        )}
                        {contentItem && contentItem.contentEnumType === 7 && (
                          <Grid item spacing={2} p={2} xs={12} sm={12}>
                            <RestaurantsAndHotels
                              setPageTitle={setPageTitle}
                              setEventInfo={setEventInfo}
                              setNavBack={setNavBack}
                              eventInfo={eventInfo}
                              content={true}
                              contentType={7}
                            />
                          </Grid>
                        )}
                        {contentItem && contentItem.contentEnumType === 8 && (
                          <Grid item spacing={2} p={2} xs={12} sm={12}>
                            <Organizers
                              setPageTitle={setPageTitle}
                              setEventInfo={setEventInfo}
                              setNavBack={setNavBack}
                              eventInfo={eventInfo}
                              content={true}
                            />
                          </Grid>
                        )}
                        {contentItem && contentItem.contentEnumType === 5 && (
                          <Grid item spacing={2} p={2} xs={12} sm={12}>
                            <Sponsors
                              setPageTitle={setPageTitle}
                              setEventInfo={setEventInfo}
                              setNavBack={setNavBack}
                              eventInfo={eventInfo}
                              content={true}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    className={classes.btns}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs={12} md={6} lg={6}>
                      <Button
                        variant="outlined"
                        size="small"
                        colour="white"
                        onClick={handleClose}
                        disabled={isSubmitting}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6}>
                      <Button
                        size="small"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {" "}
                        Save Changes{" "}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Fade>
    );
  }
);

export default ContentEditNew;
