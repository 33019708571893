import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Page from 'components/core/Page'
import SearchToolbar from 'components/core/SearchToolbar'
import {useLocation} from 'react-router-dom'
import {useHttp} from 'api/core'
import api from 'api'
import ConferenceCard from 'components/core/ConferenceCard'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Map from 'components/core/Map'
import Fade from '@material-ui/core/Fade'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {TIME_FADE_IN} from 'config'
import NoSearchResults from 'components/placeholders/NoSearchResults'

const styles = theme => ({
  eventsMap: {
    top: '167px',
    position: 'fixed',
    width: '38%',
    marginLeft: '30px'
  },
  page: {
    paddingTop: '120px',
    [theme.breakpoints.down(1200)]: {
      paddingTop: '450px'
    },
    [theme.breakpoints.down(960)]: {
      paddingTop: '550px'
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0'
    }
  },
  mainBox: {
    position: 'relative',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(6)
    }
  },
  mapBox: {
    position: 'relative',
    [theme.breakpoints.down(1200)]: {
      display: 'none'
    }
  },
  mapBoxMobile: {
    display: 'none',
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)',
    [theme.breakpoints.down(1200)]: {
      display: 'flex',
      top: '150px',
      left: '0',
      position: 'fixed',
      width: '100%',
      padding: 0
    },
    [theme.breakpoints.down(960)]: {
      top: '250px',
    },
    [theme.breakpoints.down('sm')]: {
      top: theme.spacing(2),
      position: 'relative'
    }
  },
  notFound: {
    color: palette['greyish-brown']
  },
   entry: {
    marginBottom: theme.spacing(2)
   }
})

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const Search = withStyles(styles)(({classes}) => {
  const q = useQuery()
  const query = q.get('query')
  const category = q.get('category')
  const [searchQuery, setSearchQuery] = useState(query?query:'')
  const [searchCategory, setSearchCategory] = useState(category?category:'')
  const [getData, searchData] = useHttp()
  const [events, setEvents] = useState()

  useEffect(()=>{
    if (searchQuery) {
      getData(api.entities.events.search(null,{term:searchQuery, category:searchCategory?searchCategory:''}))
    }
    // eslint-disable-next-line
  },[searchQuery,searchCategory])

  useEffect(()=>{
    if (!searchQuery) {
      getData(api.entities.events.search(null, {term: '', category: ''}))
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (searchData) {
      setEvents(searchData)
    }
  },[searchData])
  return <Page title='Search for an event'>
    <SearchToolbar setSearchQuery={setSearchQuery} value={searchQuery} category={searchCategory} setSearchCategory={setSearchCategory}/>
    <Container className={classes.page}>
      {events&&('content' in events)&&events.content.length!==0&&
      <Fade in={true} timeout={TIME_FADE_IN}><>
        <Box p={2} className={classes.mapBoxMobile}>
          <Map zoom={4} markers={
            events.content.filter((event)=>event.venueLat!==null).map((event,i)=>{
              return {
                name: event.name,
                id: event.id,
                lat: event.venueLat,
                lng: event.venueLon
              }
            })
          } />
        </Box>
        <Grid container className={classes.mainBox}>
          <Grid item container sm={6} spacing={2}>
            {events.content.map((event,i)=>{
              return <Grid item xs={12} className={classes.entry}><ConferenceCard list event={event} key={i}/></Grid>
              })
            }
          </Grid>
          <Grid item xs={12} sm={6} className={classes.mapBox}>
            <Box className={classes.eventsMap}>
              <Map zoom={4} markers={
              events.content.filter((event)=>event.venueLat!==null).map((event,i)=>{
                return {
                  name: event.name,
                  id: event.id,
                  lat: event.venueLat,
                  lng: event.venueLon
                }
              })
            } />
            </Box>
          </Grid>
      </Grid>
      </>
      </Fade>}
      {events && ('content' in events) && events.content.length === 0 && <NoSearchResults/>}
    </Container>
  </Page>
})

export default Search
