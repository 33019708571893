import React, {useEffect} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {useLocation, withRouter} from 'react-router-dom'
import {useHttp} from 'api/core'
import api from 'api/index'
import Page from 'components/core/Page'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {useSnackbar} from 'notistack'

const styles = theme => ({
  message : {
    fontSize: '48px',
    fontWeight: 'bold',
    color: palette['greyish-brown'],
    marginBottom: '128px'
  }
})

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AccountConfirm = withStyles(styles)(({classes, history}) => {
  const [fetchData, data,, hasError] = useHttp()
  const { enqueueSnackbar } = useSnackbar()

  let token = useQuery().get('token')

  useEffect(()=>{
    if (data) {
      if (data.status===204) {
        enqueueSnackbar('Successfully confirmed, please sign in.', {variant: 'success'})
        history.push('/login')
      }
    }
    //eslint-disable-next-line
  },[data])

  useEffect(()=>{
    if (hasError) {
      if (hasError.message) {
        enqueueSnackbar(hasError.message, {variant: 'error'})
      } else {
        enqueueSnackbar('Unexpected error', {variant: 'error'})
      }
      history.push('/')
    }
    //eslint-disable-next-line
  },[hasError])

  useEffect(() => {
    fetchData(api.entities.account.confirm({token:token}))
    //eslint-disable-next-line
  },[])

  return <Page title='Confirm account' backgroundColor={palette['pale-grey']} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
    <Box className={classes.message}> Verifying your token... </Box>
  </Page>
})

export default withRouter(AccountConfirm)
