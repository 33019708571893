import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {FormHelperText, Typography} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import api from 'api'
import {useHttp} from 'api/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import RichTextEditor from 'react-rte'
import {palette} from 'theme'

const styles = theme => ({
  container: {
  },
  editor: {
    minHeight: 180,
    backgroundColor: 'white',
    color: palette['greyish-brown'],
    borderRadius: '6px',
    fontFamily: "'Roboto','Karla', sans-serif;",
    boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
    marginBottom: theme.spacing(2)
  }
})

const Settings = withStyles(styles)(({classes,state, setState, errors})=>{
  const [getData, data, isLoading] = useHttp()
  const [settings, setSettings] = useState()
  const [editorValue, setEditorValue] = useState({ value: RichTextEditor.createValueFromString(settings&&settings.signature?settings.signature:'','html') })

  useEffect(()=>{
    if (state.from==='') {
      getData(api.entities.communication.getSettings(null,null,{id:state.eventId}))
    } else {
      setSettings({
        from: state.from,
        replyTo: state.replyTo,
        bcc: state.bcc,
        signature: editorValue.value.toString('html')
      })
      setEditorValue({ value: RichTextEditor.createValueFromString(state.signature,'html') })
    }
    // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if (data) {
      setSettings({...data.settings})
      setEditorValue({ value: RichTextEditor.createValueFromString(data.settings&&data.settings.signature?data.settings.signature:'','html') })
      setState({...state, ...data.settings})
    }
    //eslint-disable-next-line
  },[data])

  const changeValue = (value, field) => {
    setState({...state, [field]:value})
    setSettings({...settings, [field]:value})
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ['INLINE_STYLE_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
      {label: 'Bold', style: 'BOLD', className: 'custom-css-class'},
      {label: 'Italic', style: 'ITALIC'},
      {label: 'Underline', style: 'UNDERLINE'}
    ],
    // BLOCK_TYPE_DROPDOWN: [
    //   {label: 'Normal', style: 'unstyled'},
    //   {label: 'Heading Large', style: 'header-one'},
    //   {label: 'Heading Medium', style: 'header-two'},
    //   {label: 'Heading Small', style: 'header-three'}
    // ],
    // BLOCK_TYPE_BUTTONS: [
    //   {label: 'UL', style: 'unordered-list-item'},
    //   {label: 'OL', style: 'ordered-list-item'}
    // ]
  }

  const onEditorChange = (value) => {
    setEditorValue({value})
    setState({...state,signature: editorValue.value.toString('html')})
  }

  if ((!settings) || (isLoading)) return <Box display='flex' justifyContent='center' alignContent='center' alignItems='center' height='100%' width='100%'>
    <Box display='flex' p={6}>
      <CircularProgress size={'5rem'} color='secondary' />
    </Box></Box>

  return <Grid item container justify='center' alignContent='center'>

  <Grid item xs={6}>

    <Grid item container spacing={2} alignContent='center' className={classes.container}>

      <Grid item xs={12}>
        <Typography variant='h6' align='center'> Your settings for this email </Typography>
      </Grid>

      <Grid item xs={12}>
        <TextField type='email' name='from' label='From' variant='filled' fullWidth value={settings.from} onChange={(e)=>changeValue(e.target.value, 'from')}/>
        {errors&&errors.from&&<FormHelperText error variant='filled'> Please enter a valid from email address </FormHelperText>}
      </Grid>

      <Grid item xs={12} >
        <TextField type='email' name='replyTo' label='Reply to:' variant='filled' fullWidth value={settings.replyTo} onChange={(e)=>changeValue(e.target.value, 'replyTo')}/>
        {errors&&errors.replyTo&&<FormHelperText error variant='filled'> Please enter a valid Reply-To: email address </FormHelperText>}
      </Grid>

      <Grid item xs={12}>
        <TextField type='email' name='bcc' label='BCC:' variant='filled' fullWidth value={settings.bcc} onChange={(e)=>changeValue(e.target.value, 'bcc')}/>
        {errors&&errors.bcc&&<FormHelperText error variant='filled'> Please enter a valid BCC: email address </FormHelperText>}

      </Grid>

      <Grid item xs={12}>
        <Box mb={1} ml={0.5}> Email signature </Box>
        <RichTextEditor className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v)=>onEditorChange(v)}/>
      </Grid>
    </Grid>

  </Grid>

  </Grid>
})

export default Settings
