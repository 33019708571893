import {withStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import Box from '@material-ui/core/Box'
import Fade from '@material-ui/core/Fade'
import {TIME_FADE_IN} from 'config'
import {useSnackbar} from 'notistack'
import {Redirect} from 'react-router-dom'
// import {ReactComponent as NoSubmission} from 'assets/placeholders/no_submissions.png'
import {palette} from 'theme'
import ReviewSubmissionCardView from 'components/core/ReviewSubmissionCardView'
import language from 'components/language'
import moment from 'moment'

const submissionStyles = theme => ({
  noOfReviewSubmissions: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    background: theme.palette.primary.main,
    color: 'white',
    fontWeight: 'bold',
    borderRadius: '50%',
    fontSize: '24px',
    userSelect: 'none'
  },
  noSelect: {
    userSelect: 'none'
  },
  clickable: {
    cursor: 'pointer'
  },
  dialog: {
    backgroundColor: 'white'
  },
  disabled: {
    cursor: 'default',
    opacity: 0.3
  }
})

export const ReviewSubmissions = withStyles(submissionStyles)(({ classes, event, isPreview, canManage = false }) =>
{
    
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (event && timeZones) {
      const zone = timeZones.find((x) => x.id === event.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timeZones]);
  
  const [submissions, setSubmissions] = useState()
  const [getReviewSubmissionsData, submissionsData, , hasError] = useHttp()

  useEffect(() => {
    if (isPreview) {
      getReviewSubmissionsData(api.entities.submissions.getReviewSubmissions(null, null, {id: event.eventInfo.id})) // TODO: PREVIEW ENDPOINT
    } else {
      getReviewSubmissionsData(api.entities.submissions.getReviewSubmissions(null, null, {id: event.eventInfo.id}))
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (submissionsData) {
      setSubmissions(submissionsData.submissions)
    }
  }, [submissionsData])

  useEffect(()=>{
    if (hasError) {
      if (hasError.status===401) {

      }
    }
  },[hasError])

  const [addingSubmission, setAddingSubmission] = useState()
  // eslint-disable-next-line
  const { enqueueSnackbar } = useSnackbar()

  const handleOpen = (submission) => {
    setAddingSubmission(submission)
    // if (!isAuthenticated) {
    //   setOpenLoginSignUp(true)
    // } else {
    //   setOpenDialog(true)
    // }
  }


  if (hasError&&hasError.status===401) {
    return <Redirect to={`/login/?returnUrl=/event/${event.eventInfo.id}/submissions`}/>
  }

  if (!submissions) { // Loading
    return <> </>
  }

  if (addingSubmission) {
    return <Redirect to={`/reviews/submission/${addingSubmission.id}`}/>
  }

  return <>

    <Fade in={true} timeout={TIME_FADE_IN}>
      <Box display='flex' p={4} pb={12} flexDirection='column'>
        <Box pb={2}>
          <Box component='h2' fontWeight='bolder' fontSize={24} mb={1}>{language.reviews}</Box>
        </Box>
          {submissions.length===0&&
            <Box display='flex' justifyContent='center' alignContent='center' alignItems='center'>
          <Box display='flex' flexDirection='column' justifyContent='center' alignContent='center' alignItems='center'>
            {/*<Box display='flex'>*/}
            {/*  <NoSubmission/>*/}
            {/*</Box>*/}
            <Box display='flex' pt={4} color={palette['greyish-brown']}>
              {language.noSubmissionsAvailableYet}
            </Box>
          </Box></Box>}
        {submissions.map((submission,i) => {
          return <ReviewSubmissionCardView event={event} submission={submission} handleOpen={() => handleOpen(submission)} p={1} index={i} selectedTimezone={selectedTimezone} />;
        })}
      </Box>
    </Fade>
  </>
})
