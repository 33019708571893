import React from 'react'
import {Link} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import {COPYRIGHT_SHOW, COPYRIGHT_TITLE, COPYRIGHT_URL} from 'config'
import CopyrightLogo from 'assets/copyright_logo.png'

const styles = theme => ({
  navLinks: {
    '& a': {
      textDecoration: 'none',
      color: palette['greyish-brown'],
      marginRight: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  centerMobile: {
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  copyright: {
    position: 'absolute',
    right: theme.spacing(2),
    textAlign: 'right',
    width: '450px',
    '& img':{
      marginLeft: theme.spacing(1),
      width: '100px',
      height: 'auto'
    },
    [theme.breakpoints.down(990)]: {
      width: '150px',
      lineHeight: '25px'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      textAlign:'center',
      lineHeight: '30px',
    }
  }
})

const FooterBasic = withStyles(styles)(({classes}) => {

  return <>
        <Grid container className={classes.navLinks}
              direction='row'
              justify='flex-start'
              alignItems='center'
              spacing={1}
        >
          {/*<Grid item xs={4} sm='auto'>*/}
          {/*  <Link to='/about'> About </Link>*/}
          {/*</Grid>*/}
          <Grid item xs={4} sm='auto'>
            <Link to='/pricing'> Pricing </Link>
          </Grid>
          <Grid item xs={4} sm='auto'>
            <a href='https://docs.anymeets.com/en/faq' target={'blank'}> FAQ </a>
          </Grid>
          <Grid item xs={4} sm='auto'>
            <a href='https://docs.anymeets.com/' target={'blank'}> Guides </a>
          </Grid>
          <Grid item xs={4} sm='auto'>
            <Link to='/terms'> Terms </Link>
          </Grid>
          <Grid item xs={4} sm='auto'>
            <Link to='/privacy'> Privacy </Link>
          </Grid>
          <Grid item xs={4} sm='auto'>
            <Link to='/contact'> Contact </Link>
          </Grid>
          {COPYRIGHT_SHOW&&<Grid item xs={12} className={classes.copyright}>
            Copyright &copy; 2020 <a href={COPYRIGHT_URL} target='_blank' rel='noopener noreferrer'>
            <img src={CopyrightLogo} alt={COPYRIGHT_TITLE} />
          </a>
          </Grid>}
        </Grid>
  </>
})

export default FooterBasic
