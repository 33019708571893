import {withStyles} from '@material-ui/core/styles'
import {useParams, withRouter} from 'react-router-dom'
import React, {useCallback, useEffect, useState} from 'react'
import {useHttp} from 'api/core'
import api from 'api'
import {Field, Form, Formik} from 'formik'
import Box from '@material-ui/core/Box'
import Grid from "@material-ui/core/Grid";
import {Typography} from '@material-ui/core'
import {palette} from 'theme'
import OptionCard from 'components/core/OptionCard'
import {IconAddBorderBlue} from 'components/core/icons'
import { TextField } from 'formik-material-ui'
import RichTextEditor from "react-rte";
import Button from 'components/core/Button'

import {TIME_FADE_IN} from 'config'
import TicketTypeEditNew from 'components/core/TicketTypeEditNew'
import Dialog from '@material-ui/core/Dialog'
import {useSnackbar} from 'notistack'
import Fade from '@material-ui/core/Fade'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TicketCard from 'components/core/TicketCard'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";

import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info';


import * as Yup from 'yup'
import moment from "moment";

const styles = (theme) => ({
	formControl: {
		width: "100%",
		textAlign: "left",
	},
	form: {
		color: palette["greyish-brown"],
	},
	dropBox: {
		backgroundColor: "white",
		borderRadius: "6px",
		color: palette["greyish-brown"],
		overflow: "hidden",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
	},
	description: {
		width: "180px",
		padding: theme.spacing(1),
	},
	switchDescription: {
		width: "100%",
		padding: theme.spacing(1),
	},
	eventInfo: {
		backgroundColor: palette.aquamarine,
		minHeight: "50px",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: "white",
	},
	eventCode: {
		width: "200px",
		height: "100px",
		backgroundColor: "white",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: palette["greyish-brown"],
		fontWeight: "bold",
		letterSpacing: "1px",
		fontSize: "20px",
	},
	eventQrCode: {
		width: "200px",
		height: "200px",
		backgroundColor: "white",
		borderRadius: "6px",
		boxShadow:
			"0 8px 12px 0 rgba(0, 0, 0, 0.1), 0 -4px 8px 0 rgba(0, 0, 0, 0.1)",
		color: palette["greyish-brown"],
	},
	noTicketsYet: {
		display: "flex",
		backgroundColor: "white",
		padding: theme.spacing(2),
		borderRadius: "6px",
		border: "2px dashed",
		borderColor: palette["cerulean-blue"],
		minWidth: "645px",
		justifyContent: "center",
		alignItems: "center",
		fontWeight: "bold",
		color: palette["greyish-brown"],
		fontSize: "18px",
		opacity: 0.3,
	},
	title: {
		fontWeight: "bolder",
	},
	notApplicable: {
		opacity: 0.4,
	},
	animated: {
		transition: "all 0.5s",
	},
	editor: {
		color: palette["greyish-brown"],
		borderRadius: "6px",
		minHeight: "300px",
		fontFamily: "'Roboto','Karla', sans-serif;",
		boxShadow: "0 8px 12px 0 rgba(0, 0, 0, 0.1)",
  },
  topPublish: {
    float: "left",
    textAlign: "left",
    left: "0",
    position: "absolute",
    top: "6px",
    padding: "0 9px",
    "& .MuiAlert-message": {
      padding: "0 !important"
    }
  },  
  actionButtons: {
    textAlign: "center",
    position: "fixed",
    top: "64px",
    zIndex: "50",
    background: "#fff4e5",
    padding: "0 !important",
    display: "flex",
    width: "calc(100% - 241px)",
    marginLeft: "-23px",
    "& Button": {
      minWidth: "170px"
    }
  },  
  tooltip: {
    fontSize: "13px",
    lineHeight: "20px",
    fontWeight: 400
  },   
});

const Tickets = withStyles(styles)(({classes, setNavBack, setEventInfo, setPageTitle, history, eventInfo, flow}) => {

  const [formData, setFormData] = useState()
  const [formSave, setFormSave] = useState()

  const [editingTicket, setEditingTicket] = useState()
  const [newTicketDialog, setTicketDialog] = useState(false)



  const [deleteOpenTickets, setDeleteOpenTickets] = useState()

  const [deletingTicket, setDeletingTicket] = useState()

  const [sessions, setSessions] = useState([])

  const [getData, data, loadingData] = useHttp()
  const [getEventData, eventData] = useHttp()
  const [getDataEvent, dataEvent, loadingdataEvent] = useHttp()

  const [getSessions, sessionsData] = useHttp()
  const [delReqTicket, delTicketData, loadingDelTickets] = useHttp()

  const {eventId} = useParams()
  const [getDataPublish, dataPublish, loadingDataPublish] = useHttp()
  const [putRequest, putData, loadingPutData] = useHttp()
  const {enqueueSnackbar} = useSnackbar()
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");

  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    getDataEvent(api.entities.manage.getGeneral(null, null, {id: eventId}));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataEvent && eventInfo && timeZones) {
      const zone = timeZones.find((x) => x.id === dataEvent.eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventInfo, timeZones, dataEvent]);

  const [isEnabled, setEnabled] = useState(false);
  const [selectOption, setSelectOption] = useState("Anymeets will handle registration")
    useEffect(() => {
     isEnabled  ? setSelectOption("External registration") : setSelectOption("Anymeets will handle registration")
			//eslint-disable-next-line
    }, [isEnabled]);
  
  const [editorValue, setEditorValue] = useState({ value: RichTextEditor.createValueFromString(formData&&formData.registrationInstructions ? formData.registrationInstructions : '', 'html') })
   
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      {label: "Bold", style: "BOLD", className: "custom-css-class"},
      {label: "Italic", style: "ITALIC"},
      {label: "Underline", style: "UNDERLINE"},
    ],
    BLOCK_TYPE_DROPDOWN: [
      {label: "Normal", style: "unstyled"},
      {label: "Heading Large", style: "header-one"},
      {label: "Heading Medium", style: "header-two"},
      {label: "Heading Small", style: "header-three"},
    ],
    BLOCK_TYPE_BUTTONS: [
      {label: "UL", style: "unordered-list-item"},
      {label: "OL", style: "ordered-list-item"},
    ],
  };
  const onEditorChange = (value, setFieldValue) => {
    setEditorValue({value});
    setFieldValue("registrationInstructions", value.toString("html"));
  };
  
  useEffect(() => {
    getSessions(api.entities.manage.getSessions(null, null, {id: eventId}))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (sessionsData) {
      let privateSessions = []
      sessionsData.sessions.forEach((session) => {
        if (session.isPrivate)
          privateSessions.push(session)
      })
      setSessions(privateSessions)
    }
    //eslint-disable-next-line
  }, [sessionsData])


  useEffect(() => {
    getData(api.entities.manage.getTickets(null, null, {id: eventId}))
  }, [getData, eventId])

  useEffect(() => {
    if (data && dataEvent) {
      setFormData({...data})
      setPageTitle(dataEvent.eventInfo.name)
      setEventInfo(dataEvent.eventInfo)
      setNavBack({ path: '/manage-events', name: 'events' })
      setEnabled(data.externalRegistration)
      setEditorValue({ value: RichTextEditor.createValueFromString(data && data.registrationInstructions ? data.registrationInstructions : '', 'html') })
      setTitlePage(false);
    }
  }, [data,dataEvent, setEventInfo, setNavBack, setPageTitle])

  useEffect(() => {
    if (formSave) {

      let payLoad = {
        'access': formSave.access,
        'registration': formSave.registration,
        'maxRegistrations': formSave.registration !== 3 ? 0 : parseInt(formSave.maxRegistrations),
        'proceedingsAccess': formSave.proceedingsAccess,
        'sessionViewerAccess': formSave.sessionViewerAccess,
        showProceedings: formSave.showProceedings,
        presentationsAccess: formSave.presentationsAccess,
        showPresentationFiles: formSave.showPresentationFiles,
        externalRegistration: isEnabled,
        externalRegistrationUrl: formSave.externalRegistrationUrl,
        externalRegistrationButtonLabel: formSave.externalRegistrationButtonLabel,
        registrationInfoDesc: formSave.registrationInfoDesc,
        registrationInfoCost: formSave.registrationInfoCost,
        registrationInstructions: formSave.registrationInstructions,
        generateTitlePage: titlePage,
        'publish': false
      }

      // // Override in case of 'FREE ITER'
      // if (eventInfo.upgraded === false) {
      //   payLoad = {
			// 		access: 1,
			// 		registration: 1,
			// 		maxRegistrations: null,
			// 		proceedingsAccess: 2,
			// 		sessionViewerAccess: 2,
			// 		showProceedings: 2,
			// 		presentationsAccess: 2,
			// 		showPresentationFiles: 2,
			// 		externalRegistration: false,
			// 		externalRegistrationUrl: null,
			// 		externalRegistrationButtonLabel: null,
			// 		registrationInfoDesc: null,
			// 		registrationInfoCost: null,
			// 		registrationInstructions: null,
			// 		publish: false,
			// 		generateTitlePage: false,
			// 	};
      // }

      putRequest(api.entities.manage.putTickets(payLoad, null, {id: eventId}))
      setFormSave(undefined)
    }
    //eslint-disable-next-line
  }, [formSave])

  useEffect(() => {
    if (putData) {
      getData(api.entities.manage.getTickets(null, null, { id: eventId }))
      getEventData(api.entities.manage.getGeneral(null, null, {id: eventId}));
      enqueueSnackbar('Successfully saved event', {variant: 'success'})
    }
  }, [enqueueSnackbar, eventId, getData, putData])

  useEffect(() =>
  {
    if (eventData) {
      setEventInfo(eventData.eventInfo)
    }
  }, [eventData])

  const addTicket = () => {
    setEditingTicket(false)
    setTicketDialog(true)
  }

  const handleClose = () => {
    setEditingTicket(false)
    setTicketDialog(false)
    getData(api.entities.manage.getTickets(null, null, {id: eventId}))
  }


  const ViewEvent = useCallback(() => <Button size='smaller' variant='outlined' colour='white' onClick={() => {
    history.push(`/event/${eventId}`)
  }}> View Event </Button>, [eventId, history])

  const publishEvent = () => {
    getDataPublish(api.entities.events.publish(null, null, {id: eventId}))
  }

  useEffect(() => {
    if (dataPublish) {
      if (dataPublish.status && dataPublish.status === 201) {
        setFormData(f => ({...f, eventInfo: {...f.eventInfo, dirty: false}}))
        setEventInfo({...eventInfo, dirty: false})
        enqueueSnackbar('Your event was successfully published!', {variant: 'success', action: ViewEvent})
      } else {
        enqueueSnackbar('Unexpected Error', {variant: 'error'})
      }
    }
    // eslint-disable-next-line
  }, [dataPublish])


  const editTicket = (ticket) => {
    setEditingTicket(ticket)
    setTicketDialog(true)
  }

  const deleteTicket = (ticket) => {
    setDeleteOpenTickets(true)
    setDeletingTicket(ticket)
  }

  const handleDeleteCloseTickets = (ticket) => {
    if (ticket) {
      delReqTicket(api.entities.tickets.delete(null, null, {id: ticket.id}))
    } else {
      setDeleteOpenTickets(false)
      setDeletingTicket(undefined)
    }
  }

  useEffect(() => {
    if (delTicketData) {
      enqueueSnackbar('Successfully deleted ticket', {variant: 'success'})
      setDeleteOpenTickets(false)
      setDeletingTicket(undefined)
      getData(api.entities.manage.getTickets(null, null, {id: eventId}))
    }
  }, [delTicketData, enqueueSnackbar, eventId, getData])


  const setSuccessfulTicket = (refresh) => {
    enqueueSnackbar('Ticket updated successfully!', {variant: 'success'})
    if (refresh) {
      getData(api.entities.manage.getTickets(null, null, {id: eventId}))
    }
  }

  const schema = () => Yup.object().shape({
    // maxRegistrations: Yup.number().nullable().when('registration', {
    //   is: val => (val === 3),
    //   then: Yup.number()
    //     .max(formData.maxAttendees ? formData.maxAttendees : 1)
    //     .typeError('you must specify a number')
    //     .required('Max attendees is a required field')
    // })
  })

  const [cost, setCost] = useState(0)
  const handleCost = (value) =>{
    setCost(value.target.value.length);
  }
  const [desc, setDesc] = useState(0);
  const handleDesc = (value) => {
    setDesc(value.target.value.length);
  };
  const [blabel, setBlabel] = useState(0);
	const handleBlabel = (value) => {
		setBlabel(value.target.value.length);
  };
  const [titlePage, setTitlePage] = useState(false)

  
  if (!formData) return <></>

  const isSubmitting = loadingData || loadingDataPublish || loadingDelTickets || loadingPutData

  return <Box>

    <Dialog
      open={deleteOpenTickets}
      onClose={() => handleDeleteCloseTickets()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    ><Box p={3} pt={1}>
      <DialogTitle id="alert-dialog-title">Delete ticket</DialogTitle>
      <DialogContent>
        <Box p={1} pl={0} pb={2}>
          Are you sure you want to delete the ticket?
        </Box>
      </DialogContent>
      <DialogActions>
        <Box p={1}>
          <Button onClick={() => handleDeleteCloseTickets()} size='small' variant='outlined' color='primary'>
            No
          </Button>
        </Box>
        <Box p={1}>
          <Button onClick={() => {
            handleDeleteCloseTickets(deletingTicket)
          }} size='small' autoFocus color='primary'>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Box>
    </Dialog>

    <Formik
      enableReinitialize      
      initialValues={{
        sessionViewerAccess: 1, ...formData,
        access: formData.access,
        proceedingsAccess: formData.proceedingsAccess,
      }}
      validationSchema={schema}
      onSubmit={(values, {setSubmitting,resetForm}) => {
        setFormSave(values)
        resetForm();
      }}>
      {({dirty, errors, submitForm, setFieldValue, values}) => (
        <Fade in={true} timeout={1200}><Form className={classes.form}>
          <Dialog onClose={handleClose} open={newTicketDialog} fullWidth maxWidth='sm'>
            <TicketTypeEditNew unlimited={values.registration === 2} handleClose={handleClose} ticket={editingTicket}
                               eventId={eventId} eventInfo={eventInfo} setSuccessful={setSuccessfulTicket} sessions={sessions}/>
          </Dialog>

          <Box display='flex' flexDirection='row' mt={4}>
            <Box width={200}>
              <Typography component='h2' variant='h6' className={classes.title}>Registrations
              <Tooltip classes={{tooltip: classes.tooltip}} title="Setup event registration and tickets." placement="top" arrow>
                <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
              </Tooltip>               
              </Typography>
            </Box>

            <Box display='flex' flexDirection='row' justifyContent='center' alignContent='center'>
              <Box mr={2}>
                <OptionCard text='Skip registration'  onClick={() => {
                  
                  setFieldValue('registration', 1)
                  if (values.proceedingsAccess === 3) {
                    setFieldValue('proceedingsAccess', 2)
                  }
                }} selected={values.registration === 1}/>
                <Box className={classes.description}>No registration is required</Box>
              </Box>
              <Box mr={2}>
                <OptionCard text='Unlimited'
                    // disabled={formData.maxAttendees !== null}
                    onClick={() => {                              
                    // if (formData.maxAttendees !== null) return false
                        setFieldValue('registration', 2)
                    }} selected={values.registration === 2}/>
                <Box className={classes.description}>Unlimited registration number</Box>
              </Box>
              <Box mr={2}>
                <OptionCard text='Limited'  onClick={() => {
                  
                  setFieldValue('registration', 3)
                }} selected={values.registration === 3}/>
                <Box className={classes.description}>Fill in No of registrations</Box>
                <Box mt={4}>
                  {values.registration === 3 &&
                  <Field type='text' name='maxRegistrations' required component={TextField} label='No of Registrations'
                         variant='filled'
                         fullWidth disabled={isSubmitting }/>}
                </Box>
              </Box>
            </Box>
          </Box>
          {values.registration !== 1 &&
          <Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' flexDirection='row' mt={8} mb={2}>
            <Box width={200}>
              <Typography component='h2' variant='h6' className={classes.title}>Registration Process</Typography>              
            </Box>
            <Box display='flex' flexDirection='column'>
              <Box pb={1} width='100%' >
              Anymeets will handle registration {" "}
                  <FormControlLabel
                    style={{margin: "0"}}
                  control={
                    <Switch
                      checked={isEnabled}
                      onChange={(e) => { setEnabled(e.target.checked);}}                        
                      name= 'externalRegistration'
                      color='primary'
                      disabled={isSubmitting}                       
                    />
                  }
                  //label={selectOption}
                  />
                  External registration
                </Box>               
            </Box>
          </Box></Fade>}
          {isEnabled &&               
            <Box display='flex' flexDirection='row' mt={2} mb={4}>
              <Box width={200}>
                
              </Box>
              <Box pb={5} width='50%'>
                <Grid container spacing={1} className={classes.row}>
                  <Grid item xs={12}>
                    <Typography variant='h6' component='h4'><small> Add external registration details</small> </Typography>
                  </Grid>
                  <Grid item  xs={12}>
                    <Field type='text' name='externalRegistrationUrl' component={TextField} label='URL' variant='filled' inputProps={{ maxLength: 120 }}
                            fullWidth disabled={isSubmitting}/>
                  </Grid>
                  <Grid item  xs={12}>
                    <Field type='text' name='externalRegistrationButtonLabel' component={TextField} label='Βutton label' variant='filled' helperText={!!blabel&&`${blabel}/15`}
                            fullWidth disabled={isSubmitting} inputProps={{ maxLength: 15,onChange: (e)=>handleBlabel(e) }} />
                  </Grid>                 
                  <br/>
                </Grid>
              </Box>
            </Box>
          }     
          {values.registration !== 1 &&
            (<>
            <Box display='flex' flexDirection='row' mt={1} mb={1}>
              <Box width={200}>
                <Typography component='h2' variant='h6' className={classes.title}>Description
                <Tooltip classes={{tooltip: classes.tooltip}} title="Add a label to show to users in registration box." placement="top" arrow>
                <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
              </Tooltip>                  
                </Typography>
              </Box>
              <Box pb={1} width='50%'>
                  <Box width='100%'>
                    <Grid item  xs={12}>
                        <Field type='text' name='registrationInfoDesc' component={TextField} label='Ιnfo description' variant='filled' helperText={!!desc&&`${desc}/120`}
                                fullWidth disabled={isSubmitting} inputProps={{ maxLength: 120,onChange: (e)=>handleDesc(e) }}/>
                      </Grid>   
                  </Box>
                </Box>
            </Box>
            <Box display='flex' flexDirection='row' mt={1} mb={1}>
              <Box width={200}>
                <Typography component='h2' variant='h6' className={classes.title}>Cost
                <Tooltip classes={{tooltip: classes.tooltip}} title="Add a label about the cost of registration." placement="top" arrow>
                <InfoIcon style={{ fontSize: "22px", marginLeft: "5px", marginBottom: "2px", verticalAlign: "sub", cursor: "pointer" }} color="primary" variant="outlined" fontSize="large" />
              </Tooltip>                 
                </Typography>
              </Box>
              <Box pb={1} width='50%'>
                  <Box width='100%'>  
                      <Grid item  xs={12}>
                        <Field type='text' name='registrationInfoCost' component={TextField} label='e.g. from $50, TBD, Free' variant='filled' helperText={!!cost&&`${cost}/25`}
                                fullWidth disabled={isSubmitting} inputProps={{ maxLength: 25,onChange: (e)=>handleCost(e)}}/>
                      </Grid>                                          
                  </Box>
                </Box>
            </Box>
            <Box display='flex' flexDirection='row' mt={1} mb={1}>
              <Box width={200}>
              <Typography component='h2' variant='h6' className={classes.title}>Ιnstructions</Typography>
              </Box>
              <Box pb={1} width='50%'>
                  <Box width='100%'>
                    <Grid item  xs={12}>                         
                    <RichTextEditor fullwidth className={classes.editor} value={editorValue.value} toolbarConfig={toolbarConfig} onChange={(v) => onEditorChange(v, setFieldValue)}
                    disabled={isSubmitting}
                    />
                    </Grid>
                  </Box>
                </Box>
            </Box>                            
            </>)}
          
          {values.registration !== 1 && !isEnabled &&
          <Fade in={true} timeout={TIME_FADE_IN}><Box display='flex' flexDirection='row' mt={8} mb={4}>
            <Box width={200}>
              <Typography component='h2' variant='h6' className={classes.title}>Tickets</Typography>
            </Box>
            <Box display='flex' flexDirection='column'>
              {formData.tickets.map((ticket, i) => {
                return <TicketCard unlimited={values.registration === 2} ticket={ticket} selectedTimezone={selectedTimezone}
                                   editAction={() => editTicket(ticket)} deleteAction={() => deleteTicket(ticket)}
                                   key={i} pb={2}/>
              })}
              {formData.tickets.length === 0 ? <Box display='flex' height={130} className={classes.noTicketsYet}>
                No tickets yet!
              </Box> : ''}
              <Box pt={2} width='100%' justifyContent='flex-end' display='flex'>
                <Button size='small' variant='outlined' color='primary' onClick={() => {
                  addTicket()
                }} icon={<IconAddBorderBlue/>}> Add Ticket Type </Button>
              </Box>
            </Box>
          </Box></Fade>}
          {(dirty || eventInfo.dirty) && ( 
            <Grid container item xs={12} justify="flex-end" alignItems="flex-end" alignContent="flex-end" className={classes.actionButtons} style={{ position: flow ? "absolute" : null, top: flow ? "0" : null }}>
              {eventInfo && eventInfo.dirty ? (
                <Alert className={classes.topPublish} severity="warning">
                  <AlertTitle>Unpublished changes</AlertTitle>
                  You are viewing an event with unpublished changes.
                </Alert>
              ) : (
                <Alert className={classes.topPublish} severity="warning">
                  <AlertTitle>Save changes</AlertTitle>
                  Remember to save your changes before you leave this page.
                </Alert>
              )}
              {dirty && <Box display="inline-block" mt={1} mb={1}>
                <Button type="submit" variant="outlined" color="primary">
                  Save Changes
                </Button>
              </Box>}
              &nbsp;&nbsp;&nbsp;
              <Box display="inline-block" mt={1} mb={1}>
                {eventInfo.dirty ? (
                  <Button
                    onClick={() => {
                      publishEvent();
                    }}>
                    Publish
                  </Button>
                ) : (
                  ""
                )}
              </Box>
            </Grid>
          )}
          {/* <Box display='flex' flexDirection='row' mt={2} p={3}>
            <Box display='flex' p={2} pb={6} pl={22} flexDirection='row'>
              
              <Box display='flex' mr={2}><Button type='submit' variant='outlined' color='primary'>Save Changes</Button></Box>
              {eventInfo.dirty ? <Box display='flex'><Button onClick={() => {
                publishEvent()
              }}>Publish</Button></Box> : ''}
            </Box>
          </Box> */}

        </Form></Fade>
      )}
    </Formik>
  </Box>
})

export default withRouter(Tickets)
