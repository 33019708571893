import {Link} from 'react-router-dom'
import Button from 'components/core/Button'
import AlertTitle from '@material-ui/lab/AlertTitle'
import Alert from '@material-ui/lab/Alert'
import React from 'react'
import {LAYOUT_TYPES} from 'config'
import Box from '@material-ui/core/Box'
import { withStyles } from '@material-ui/core/styles'
import language from "components/language";

const styles = (theme) => ({
  alert: {
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  uppercase: {
      textTransform: "uppercase"
  },
});

const AlertPreview = withStyles(styles)(({classes, event, eventId, published, openDrawer, eventLayout}) => {

    return <>
        <Box ml={(eventLayout === LAYOUT_TYPES.WITH_DRAWER) || (eventLayout === LAYOUT_TYPES.WITH_DRAWER_CONTAINED) ? openDrawer ? 30.5 : 6 : 0} className={classes.alert}>
            <Alert severity='warning' action={published && <Link to={`/event/${eventId}/info`}>
                <Button color='primary' size='smaller' className={classes.uppercase}>
                   {language.viewEvent}
                </Button>
                &nbsp;
            </Link>}>
                <AlertTitle>{language.previewEvent}</AlertTitle>
                {event.eventInfo.dirty? language.youAreViewingAnEventWithUnpublishedChanges: "This is a preview of your event. There aren't any unpublished changes."}
            </Alert>
        </Box>
    </>
})

export default AlertPreview
