import {withRouter} from 'react-router-dom'
import {withStyles} from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import Box from '@material-ui/core/Box'
import EventInfo from 'components/pages/Event/EventInfo'
import RegistrationInfo from 'components/pages/Event/RegistrationInfo'
import FeedbackInfo from 'components/pages/Event/FeedbackInfo'
import {LAYOUT_TYPES} from 'config'
import SubmissionsInfo from 'components/pages/Event/SubmissionsInfo'
import ReviewSubmissionInfo from 'components/pages/Event/ReviewSubmissionInfo'
import CameraReadyInfo from "components/pages/Event/CameraReadyInfo";

const styles = theme => ({
    infoBar: {
        [theme.breakpoints.down(1300)]: {
            paddingLeft: 240
        }
    },
    infoBarSingle: {
        paddingTop: theme.spacing(2)
    }
})

const InfoBar = withRouter(withStyles(styles)(({classes, event, isLeaving, isJoining, isLoading, joinEvent, leaveEvent, hideJoin, hasCameraReady}) => {
  

    return (
      <>
        <Grid item container className={event.eventInfo.layout === LAYOUT_TYPES.SINGLE_PAGE ? classes.infoBarSingle : classes.infoBar}>
          <Grid item xs={12} xl={9}>
            <Box flexDirection="column">
              <EventInfo event={event} isLeaving={isLeaving} isJoining={isJoining} isLoading={isLoading} joinEvent={joinEvent} leaveEvent={leaveEvent} hideJoin={hideJoin} />
              
              {event.activateSubmissions && hasCameraReady && <CameraReadyInfo event={event} />}
              {event.activateSubmissions && event.eventInfo.layout !== LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.registrationsOpenAt !== null && <RegistrationInfo event={event} />}
              {event.activateSubmissions && event.eventInfo.enableSubmissions && event.eventInfo.layout !== LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.hasSubmissionFlows && <SubmissionsInfo event={event} />}

              {event.activateSubmissions && event.eventInfo.layout !== LAYOUT_TYPES.SINGLE_PAGE && event.eventInfo.hasReviews && (
                <>
                  <ReviewSubmissionInfo event={event} />
                </>
              )}

              {event.eventInfo.layout !== LAYOUT_TYPES.WITH_DRAWER && event.eventInfo.layout !== LAYOUT_TYPES.WITH_DRAWER_CONTAINED ? (
                event.eventInfo && event.eventInfo.hasFeedback && <FeedbackInfo event={event} />
              ) : (
                <></>
              )}
            </Box>
          </Grid>
        </Grid>
      </>
    );

}))

export default InfoBar
