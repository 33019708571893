import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import EventsIcon from "assets/Events_icon.png";
import FlexibleIcon from "assets/Flexible_icon.png";
import MetricsIcon from "assets/Metrics_icon.png";
import SupportIcon from "assets/Support_icon.png";
import Paper from "@material-ui/core/Paper";
import BottomHero from "./BottomHero";

const styles = (theme) => ({
	homeMiddle: {
		padding: "65px 0px",
		marginTop: "65px",
		marginBottom: "65px",
		backgroundColor: "#fff",
	},
	hero: {
		maxWidth: "768px",
		backgroundSize: "cover",
		[theme.breakpoints.down("xs")]: {
			minHeight: "initial",
		},
	},
	title: {
		maxWidth: "800px",
		color: "#666666",
		fontSize: "28px",
		textAlign: "center",
		fontWeight: "normal",
		marginTop: theme.spacing(6),
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down("xs")]: {
			fontSize: "32px",
			padding: theme.spacing(1),
			marginBottom: 0,
		},
	},
	subtitle2: {
		maxWidth: "800px",
		color: "#3d4d59",
		fontSize: "48px",
		fontFamily: "Nunito , Karla",
		textAlign: "center",
		fontWeight: "600",
		marginBottom: theme.spacing(5),
		[theme.breakpoints.down("xs")]: {
			fontSize: "36px",
		},
	},
	iconSet: {
		maxWidth: "580px",
		width: "calc(100% - 100px)",
		boxShadow: "0 0 25px 4px rgb(0 0 0 / 7%)",
		borderRadius: "10px",
		padding: "25px 32px 25px 32px",
		margin: "5px 25px",
		textAlign: "center",
		minHeight: "180px",
		"& li": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			fontFamily: "Karla",
			textAlign: "left",
		},
		"& p": {
			fontSize: "16px",
			letterSpacing: "-0.8px",
			color: "#9b9b9b",
			margin: "0px auto",
			fontFamily: "Karla",
			textAlign: "center",
		},
		"& ul": {
			paddingLeft: "17px",
			margin: "10px 0",
		},
		[theme.breakpoints.down("xs")]: {
			width: "unset",
		},
	},
	iconTag: {
		fontSize: "24px",
		fontFamily: "Karla",
		letterSpacing: "-1.2px",
		fontWeight: "500",
		marginBottom: "5px",
	},
	horizontalHero: {
		width: "100%",
		margin: "96px 0 0",
		padding: "55px 10% 70px 10%",
		borderRadius: "0px",
		backgroundImage:
			"linear-gradient(to bottom, rgba(2, 68, 117, 0.67), rgba(2, 68, 117, 0.67)), linear-gradient(55deg, #035899 20%, #000 100%)",
		"& h2": {
			color: "#fff",
			fontFamily: "Karla",
			fontWeight: "normal",
			fontSize: "30px",
			lineHeight: "50px",
			letterSpacing: "-1.5px",
			margin: "0px",
		},
	},
	buttonTry: {
		height: "50px",
		fontFamily: "Karla",
		fontSize: "18px",
		backgroundColor: "#a3ce47",
		color: "#fff",
		width: "250px !important",
		minWidth: "unset !important",
		border: "1px solid #a3ce47",
		borderRadius: "50px",
		margin: "25px 5px",
		display: "block",
		cursor: "pointer",
		"&&:hover": {
			backgroundColor: "#71912e",
			border: "1px solid #71912e",
		},
	},
});

const HomeMiddle = withStyles(styles)(({ classes }) => {
	return (
		<>
			<Grid
				container
				direction="column"
				justify="center"
				alignItems="center"
				className={classes.homeMiddle}
			>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.subtitle2}
				>
					<Grid item>Want More?</Grid>
				</Grid>
				<Grid
					container
					justify="center"
					alignContent="center"
					alignItems="center"
					className={classes.hero}
				>
					<Grid container>
						<Grid item lg={6} md={6} xs={12}>
							<Paper className={classes.iconSet}>
								<img src={FlexibleIcon} alt="Anymeets Flexible site layouts" />
								<h3 className={classes.iconTag}>Flexible site</h3>
								<p>Different colors and layouts</p>
								<p>All information in one place</p>
								<p>Interactive conference program</p>
							</Paper>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<Paper className={classes.iconSet}>
								<img src={MetricsIcon} alt="Anymeets events metrics" />
								<h3 className={classes.iconTag}>Post-event metrics</h3>
								<p>Suggestions for future events</p>
								<p>Which sessions were the most popular?</p>
								<p>What did attendees like the most?</p>
							</Paper>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<Paper className={classes.iconSet}>
								<img src={EventsIcon} alt="Anymeets live event stream " />
								<h3 className={classes.iconTag}>Online events</h3>
								<p>Live stream or record your sessions</p>
								<p>Attract a global audience</p>
								<p>Increase your participants</p>
							</Paper>
						</Grid>
						<Grid item lg={6} md={6} xs={12}>
							<Paper className={classes.iconSet}>
								<img src={SupportIcon} alt="Anymeets support" />
								<h3 className={classes.iconTag}>24/7 support</h3>
								<p>
									Our highly skilled team is available round-the-clock to help
									you with your conference!{" "}
								</p>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<BottomHero />
		</>
	);
});

export default HomeMiddle;
