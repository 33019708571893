import React from 'react'
import {Grid} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import {palette} from 'theme'
import { getUserId } from 'utils'
import { withStyles } from '@material-ui/styles';


const styles = theme => ({
  container: {
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    maxWidth: '430px',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'initial'
    }
  },
  row: {
    paddingTop: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  rowLast: {
    paddingTop: '40px',
    paddingBottom: theme.spacing(2),
    textAlign: 'center'
  },
  passwordRow: {
    backgroundColor: palette.aquamarine,
    padding: '20px 50px',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  userPhoto: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: 'white',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    '& > img': {
      width: '100%',
      height: 'auto'
    }
    },
    parentList: {
        textTransform: "capitalize",
        lineHeight: "30px",
        marginBottom: "25px",
    },
    childList: {
        textTransform: "capitalize",
        lineHeight: "30px"        
    },
    
})




const UserOccurrences = withStyles(styles)(({classes,formData,setFormData,setFormSave,isSubmitting,profile,occurrencesData, ...props }) =>
{
    const child = (value) => {
      const keys = Object.keys(value).filter((key) => value[key] === true);
        keys.sort();
      const subList = keys.map((keys) => <li className={classes.childList}>{keys}</li>);

      return subList;
    };

     
    return (
      <Grid item xs={12} md={12}>
        <Grid item container className={classes.row}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1">
              Privacy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="p" component="p">
              User occurrences
            </Typography>
          </Grid>
        </Grid>
        <ul>
          {occurrencesData &&
            occurrencesData.map((occurence) => {
              return (
                <li className={classes.parentList} key={occurence.eventId}>
                  {(occurence.eventName ? occurence.eventName : occurence.eventId)}
                  <ul>{child(occurence.userOccurrence)}</ul>
                </li>
              );
            })}
        </ul>
        <Box style={{color: "rgba(0,0,0,0)"}}>{getUserId().sub}</Box>
      </Grid>
    );

})

export default  withStyles(styles)(UserOccurrences)
