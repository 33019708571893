import React, {useEffect, useRef} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {Stage, Layer, Image} from 'react-konva'
import useImage from 'use-image'
import {SIZE_CANVAS_HEIGHT, SIZE_CANVAS_WIDTH} from 'config'

const styles = theme => ({

})

const ImageFile = ({url}) => {
  const [image] = useImage(url)

  if (!image) return <></>

  var wrh = image.width / image.height
  var newWidth = SIZE_CANVAS_WIDTH
  var newHeight = newWidth / wrh
  if (newHeight > SIZE_CANVAS_HEIGHT) {
    newHeight = SIZE_CANVAS_HEIGHT
    newWidth = newHeight * wrh
  }
  var xOffset = newWidth < SIZE_CANVAS_WIDTH? ((SIZE_CANVAS_WIDTH - newWidth) / 2) : 0
  var yOffset = newHeight < SIZE_CANVAS_HEIGHT ? ((SIZE_CANVAS_HEIGHT - newHeight) / 2) : 0

  return <Image image={image} height={newHeight} width={newWidth} x={xOffset} y={yOffset} />
}

const FileCanvas = withStyles(styles)(({classes, setCanvasVideoTrack, presentationFile, pageNumber=0}) => {
  const canvasRef = useRef()
  const refreshRef = useRef()

  useEffect(()=>{

    const canvasVideoTrack = canvasRef.current.getCanvas()._canvas.captureStream(1).getVideoTracks()[0]
    setCanvasVideoTrack(canvasVideoTrack)

    refreshRef.current = setInterval(()=>{
      canvasRef.current.getStage().draw()
    },1000)

    return () => {
      clearInterval(refreshRef.current)
    }

    //eslint-disable-next-line
  },[canvasRef])

  return <Stage width={SIZE_CANVAS_WIDTH} height={SIZE_CANVAS_HEIGHT} style={{display:'none'}}>
    <Layer ref={canvasRef}>
      {presentationFile.pages[pageNumber]&&<ImageFile url={presentationFile.pages[pageNumber]} key={pageNumber}/>}
    </Layer>
  </Stage>
})

export default FileCanvas
