import React, {useEffect, useState} from 'react'
import {useHttp} from "api/core";
import api from "api";
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Box from '@material-ui/core/Box'
import {CURRENCY} from 'config'
import Grid from '@material-ui/core/Grid'
import Button from 'components/core/Button'
import clsx from 'clsx'
import isFuture from 'date-fns/isFuture'
import isPast from 'date-fns/isPast'
import parseISO from 'date-fns/parseISO'
import isSameDay from 'date-fns/isSameDay'
import { format } from "date-fns"
import language from "components/language";
import { enUS, el, de, fr, it } from "date-fns/esm/locale";
import moment from "moment";
import parse from "html-react-parser";
const dateLocale = [{locale: enUS}, {locale: enUS}, {locale: el}, {locale: de}, {locale: fr}, {locale: it}];

const styles = theme => ({

  ticketBox: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '12px',
    border: 'solid 1px #e6e6e6',
    position: 'relative',
    padding: theme.spacing(2),
    '& a': {
      color: theme.palette.primary.main
    }
  },
  purchasedTicket: {
    // backgroundColor: palette.aquamarine
  },
  notAvailable: {
    opacity: 0.7
  },
  button: {
    height: '100%',
    position: 'relative'
  },
  note: {
    left: 0,
    fontSize: '11px',
    position: 'absolute',
    width: '100%',
    bottom: '-5px',
    textAlign: 'center',
    color: palette['brown-grey'],
    fontWeight: 'bold'
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  priceColor: {
    color:theme.palette.primary.main
  }
})

const TicketCardView = withStyles(styles)(({classes, index, handleOpen, event, ticket, viewOnly, unlimited, discount=0, ...props}) => {
 
  const [getTimeZones, timeZones] = useHttp();
  const [selectedTimezone, setSelectedTimezone] = useState("UTC");
  
  useEffect(() => {
    getTimeZones(api.entities.timezones.getTimeZones());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (event && timeZones) {
      const zone = timeZones.find((x) => x.id === event.eventInfo.timezoneId);
      moment.tz.setDefault(zone.name);
      setSelectedTimezone(zone.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event, timeZones]);

   
  const available = (isFuture(parseISO(ticket.availableTo)) && isPast(parseISO(ticket.availableFrom)))
  let canBuy = ticket.remaining!==null&&ticket.remaining>=ticket.maxQuantityToBuy+ticket.purchased
  canBuy = ticket.remaining===null?true:canBuy
  const buyLabel = available ? (canBuy ? language.buy + (ticket.purchased ? " " + language.more : "") + " " + language.tickets : language.soldOut) : language.notAvailable;
  return (
    <Grid container spacing={1} className={classes.container} key={index}>
      <Grid item xs={12} md={viewOnly ? 12 : 9}>
        <Grid item container spacing={1} alignContent="center" alignItems="flex-start" className={clsx(classes.ticketBox, ticket.purchased && classes.purchasedTicket, !available && classes.notAvailable)}>
          <Grid xs={5} sm={4} md={5} item>
            <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
              <Box display="flex" fontSize="14px">
                {language.type}
              </Box>
              <Box display="flex" fontSize="20px" fontWeight="bold">
                {ticket.name}
              </Box>
            </Box>
          </Grid>
          <Grid xs item>
            <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
              <Box display="flex" fontSize="14px">
                {language.availableTickets}
              </Box>
              <Box display="flex" fontSize="20px" fontWeight="bold">
                {ticket.remaining ? ticket.remaining : "-"}
              </Box>
            </Box>
          </Grid>
          <Grid xs item>
            <Box display="flex" flexDirection="column" alignContent="center" justifyContent="flex-start">
              <Box display="flex" fontSize="14px">
                {language.price} / {ticket.attendeesPerTicket} {language.pers}.
              </Box>
              <Box display="flex" fontSize="24px" fontWeight="bold" className={classes.priceColor}>
                {ticket.price ? `${CURRENCY} ${ticket.price - ticket.price * (discount / 100)}` : language.free}
              </Box>
              {/* <Box display="flex" fontSize="12px" fontWeight="bold">
                {!isSameDay(parseISO(ticket.availableTo), parseISO(ticket.availableFrom))
                  ? language.availableFrom +
                    " " +
                    format(parseISO(ticket.availableFrom), "dd LLL yyyy hh:mm a") +
                    " " +
                    language.to +
                    " " +
                    format(parseISO(ticket.availableTo), "dd LLL yyyy hh:mm a") +
                    " (" +
                    moment.tz.guess().replace("_", " ") +
                    ")"
                  : language.availableOnlyOn + " " + format(parseISO(ticket.availableTo), "dd LLL yyyy hh:mm a") + " (" + moment.tz.guess().replace("_", " ") + ")"}
              </Box> */}
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box display="flex" flexDirection="row" alignContent="center" justifyContent="flex-start">
              <Box display="flex" fontSize="12px" fontWeight="bold">
                {!isSameDay(parseISO(ticket.availableTo), parseISO(ticket.availableFrom))
                  ? language.availableFrom +
                    " " +
                    format(parseISO(ticket.availableFrom), "dd LLL yyyy hh:mm a") +
                    " " +
                    language.to +
                    " " +
                    format(parseISO(ticket.availableTo), "dd LLL yyyy hh:mm a") +
                    " (" +
                    moment.tz.guess().replace("_", " ") +
                    ")"
                  : language.availableOnlyOn + " " + format(parseISO(ticket.availableTo), "dd LLL yyyy hh:mm a") + " (" + moment.tz.guess().replace("_", " ") + ")"}
                {parse("<br/>")}
                {!isSameDay(parseISO(ticket.availableTo), parseISO(ticket.availableFrom))
                  ? " " +
                    moment(ticket.availableFrom).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") +
                    " " +
                    language.to +
                    " " +
                    moment(ticket.availableTo).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") +
                    " (" +
                    selectedTimezone.replace("_", " ") +
                    ")"
                  : language.availableOnlyOn + " " + moment(ticket.availableTo).tz(selectedTimezone).format("DD MMM yyyy hh:mm A") + " (" + selectedTimezone.replace("_", " ") + ")"}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {!viewOnly && (
        <Grid item xs={12} md={3}>
          <Grid item container justify="center" alignContent="center" alignItems="center" className={classes.button}>
            <Grid item>
              <Button style={{lineHeight: "15px"}} disabled={!available || !canBuy} autoWidth color={`${ticket.purchased !== 0 ? "primary" : "secondary"}`} onClick={() => handleOpen(ticket)}>
                {" "}
                {buyLabel}{" "}
              </Button>
              {ticket.purchased !== 0 && (
                <Box className={classes.note}>
                  {language.alreadyBought} {ticket.purchased} {ticket.purchased > 1 ? language.tickets : language.ticket}
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
})

export default TicketCardView
