import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core/styles'
import {palette} from 'theme'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import BlockText from 'components/core/BlockText'
import Divider from '@material-ui/core/Divider'
import {DUMMY_CHART_DATA} from 'config'
import {Bar, Pie} from 'react-chartjs-2'

const styles = theme => ({
  container: {
    padding: '40px 50px 50px 50px',
    backgroundColor: palette['cerulean-blue'],
    color: 'white',
    borderRadius: '6px'
  },
  previewFeedback: {
    maxWidth: '640px'
  },
  row: {
    // marginBottom: theme.spacing(2)
  },
  contentBox: {

  },
  previewBox: {
    position: 'relative',
  backgroundColor: 'white',
  width: '100%',
  minHeight: '250px',
  borderRadius: '8px',
  boxShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden'
},
  divider: {
    backgroundColor: 'white',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  noResponses: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)',
    fontWeight: 'bold',
    fontSize: '16px',
    textShadow: '0 8px 12px 0 rgba(0, 0, 0, 0.1)',

  }
})




const transformDataToChart = (question) => {
  let answers = question.options.reduce((a,b)=>a+b.total,0)

  let chartData = question.chartType===1?question.options.map((o, i) =>o.total):question.options.map((o, i) => ((o.total * question.options.length) / 100))
  let labelData = question.options.map((o, i) =>o.option)

  if (answers===0) {
    labelData=['Option 1','Option 2','Option 3']
    chartData=DUMMY_CHART_DATA[question.chartType]
  }

  return {
    labels: labelData,
    datasets: [{
      barPercentage: 0.5,
      // barThickness: 6,
      maxBarThickness: 80,
      minBarLength: 2,
      data: chartData,
      backgroundColor: palette.graphColors
    }]
  }

}

const Preview = withStyles(styles)(({classes, question, noData})=>{
  const [stats, setStats] = useState()

  useEffect(()=>{
    setStats(transformDataToChart(question))
    // eslint-disable-next-line
  },[])

  return  <Box display='flex' className={classes.previewBox}>
    <Box display='flex'>
      {noData&&<Box className={classes.noResponses}> No responses </Box>}
      {stats&&question.chartType===2&&<Pie data={stats} />}
      {stats&&question.chartType===1&&<Bar
        data={stats}
        // height={'98%'}
        options={{
          legend: {display: false},
          scales: {
            xAxes: [
              {gridLines: {display: false}},
              // {ticks:{beginAtZero:true}}
              ],
            yAxes: [
              {display: false, gridLines: {display: false}, ticks:{beginAtZero:true}},
              ]}
        }}
      />}
    </Box>
  </Box>
})

const PreviewFeedback = withStyles(styles)(({classes, feedback, handleClose, ...props}) => {


  return <Box className={classes.previewFeedback}>

    <Box display='flex' flexDirection='column'>

      <Box className={classes.container}>

        <Grid container spacing={1} className={classes.row}>
          <Box pb={1}>
            <Typography variant='h5' component='h1'> Feedback Results </Typography>
          </Box>
        </Grid>

        <Grid container spacing={2}>

          <Grid item xs={12} className={classes.row}>
            <BlockText title={'Title'} value={feedback.title} />
          </Grid>

          <Grid item xs={12}>
            <Box pt={1} pb={1} fontWeight='bold'>
              Questions
            </Box>
          </Grid>

          {feedback.questions.map((question,i)=>{
            return <React.Fragment key={i}>
              <Grid item xs={12} className={classes.row}>
                <BlockText title={'Question'} value={`${i+1}. ${question.question}`} />
                <Box pt={1}>
                  <Typography variant='body1' component='h5'>{`${question.options.reduce((a,b)=>a+b.total,0)} Responses`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} className={classes.row}>
                <Preview question={question} noData={question.options.reduce((a,b)=>a+b.total,0)===0}/>
              </Grid>
                {i+1!==feedback.questions.length&&<Grid item xs={12}>
                <Divider classes={{root:classes.divider}} />
              </Grid>}
            </React.Fragment>
          })}

        </Grid>

      </Box>
    </Box>
  </Box>
})

export default PreviewFeedback
